import React from 'react';
import { Image } from 'react-bootstrap';
import { CircleFlag } from 'react-circle-flags';
import countries from '../../../constants/countries';
import colors from '../../../constants/colors';
import placeholder from '../../../assets/bottle.png';

const tastes = ['acidic', 'bodied', 'sweet', 'tannic'];

function capitalizeFirstLetter(string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const imageLink = (link) => {
  let productNumber = link.split('/')[5];
  let productName = link.split('/')[6];
  return `https://images.alko.fi/images/cs_srgb,f_auto,t_products/cdn/${productNumber}/${productName}.jpg`;
};

const wineOrigin = (wine) => {
  return (
    <>
      <CircleFlag
        countryCode={countries[wine.country]?.toLowerCase()}
        height='11'
        style={{ marginRight: '4px' }}
      />
      {wine.region}
      {wine.subregion ? ', ' + wine.subregion : ''} {wine.year ? wine.year : ''}
    </>
  );
};

const wineTypeRow = (wine) => {
  if (wine.typeL1 !== undefined) {
    return ['typeL1', 'typeL2', 'typeL3']
      .filter((t) => wine[t])
      .map((type) => capitalizeFirstLetter(wine[type]))
      .join(' • ');
  }
  if (!wine.type) {
    return '';
  }
  return capitalizeFirstLetter(wine.type);
};

const wineQualsCollapsed = (wine) => {
  return tastes
    .map((t) => wine[t + 'Taste'])
    .filter((e) => e && e !== 'NOT ASSIGN')
    .join(' • ');
};

const Row = ({ children }) => (
  <div
    className='text-xxs'
    style={{
      flex: 1,
      textAlign: 'start',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
      color: colors.DARK,
    }}
  >
    {children}
  </div>
);

export default function WineContent({ wine, hovered = false, ...props }) {
  return (
    <div
      {...props}
      id='wine-content'
      className='d-flex'
      style={{ maxWidth: '300px', height: '100%' }}
    >
      <div className='center-content'>
        <Image
          alt='wine-img'
          className='nodrag py-2'
          style={{
            float: 'left',
            transform: hovered ? 'scale(1.1)' : '',
            transition: 'transform 0.3s',
            display: 'inline-block',
            paddingRight: '6px',
          }}
          height='100%'
          src={imageLink(wine.url)}
          onError={(e) => {
            e.target.onError = null;
            e.target.src = placeholder;
          }}
        />
      </div>
      <div
        className='text-xxs text-truncate'
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span
          style={{ maxWidth: '300px' }}
          className='d-block w-100 text-start hover-underline clickable text-truncate'
          onClick={() => {
            window.open(`/wine?id=${wine.wineId || wine.id}`, '_blank');
          }}
        >
          <b>{wine.title}</b>
        </span>
        <Row>{wineOrigin(wine)}</Row>
        <Row>{wineTypeRow(wine)}</Row>
        <Row>{wineQualsCollapsed(wine)}</Row>
      </div>
    </div>
  );
}
