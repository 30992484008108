import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import colors from '../../../constants/colors';
import {
  selectActiveRecipe,
  selectActiveIngredients,
  selectActiveSteps,
} from '../../../redux/selectors';
import { motion } from 'framer-motion';
import { spawnAnimation, tabAnimation } from '../../../constants/animations';
import { useEffect, useState } from 'react';
import { loadImage } from '../../../helpers/image';

export default function Overview() {
  const recipe = useSelector(selectActiveRecipe);
  const ingredients = useSelector(selectActiveIngredients);
  const steps = useSelector(selectActiveSteps);
  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    setImgLoading(true);

    Promise.all(
      recipe.ddgImages.slice(0, 3).map(({ thumbnail }) => loadImage(thumbnail))
    )
      .then(() => {
        setImgLoading(false);
      })
      .catch(() => {
        setImgLoading(false);
      });
  }, [recipe?.title]);

  return (
    <motion.div
      {...tabAnimation}
      className='overflow-hidden tab w-100 h-100 serif'
    >
      <div
        className='px-4'
        style={{
          height: '300px',
          overflow: 'hidden',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {imgLoading
          ? null
          : recipe?.ddgImages?.slice(0, 3).map((thumbnail) => (
              <div
                key={thumbnail}
                className='h-100 p-1'
                style={{ width: '33%' }}
              >
                <motion.img
                  {...spawnAnimation}
                  className='h-100 w-100'
                  style={{ objectFit: 'cover' }}
                  src={thumbnail}
                />
              </div>
            ))}
      </div>
      <div className='w-100 d-flex'>
        <div
          id='ingredient-container'
          className='p-4 text-sm'
          style={{
            width: '450px',
            float: 'left',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className='p-4 rounded'
            style={{
              // flex: 1,
              overflowY: 'auto',
              borderColor: colors.OFF_WHITE,
              backgroundColor: colors.LIGHTER,
              color: colors.BLACK,
            }}
          >
            <div style={{ height: '40px' }}>
              <span className='text-lg serif-bold'>Ingredients</span>
            </div>
            {ingredients?.map((ing, i) => (
              <span
                className='serif'
                key={`ingredient_${i}`}
                style={{ display: 'block', fontWeight: 200 }}
              >
                • {ing}
              </span>
            ))}
          </div>
        </div>
        <div
          id='steps-container'
          className='p-4 text-sm serif-light'
          style={{
            flex: 1,
            height: '100%',
            float: 'left',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className='p-4'
            style={{ width: '100%', flex: 1, overflowY: 'auto' }}
          >
            <div style={{ height: '40px' }}>
              <span className='text-lg serif-bold'>Steps</span>
            </div>
            {steps.map((step, i) => (
              <ListGroup.Item key={`recipe_step_${i}`}>
                <span style={{ display: 'block', marginBottom: '10px' }}>
                  <b>{i + 1}.</b> {step}
                </span>
              </ListGroup.Item>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
