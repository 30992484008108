import { Outlet, useNavigate } from 'react-router-dom';
import Logo from '../assets/sommify.svg';
import { Button } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';

export const HEADER_HEIGHT = 60;

const Header = ({ showBackButton, backButtonContent, backButtonPath }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: '100%',
        height: HEADER_HEIGHT,
        position: 'fixed',
        top: 0,
        left: 0,
        //   boxShadow: '0 0 20px rgba(0,0,0,0.1)',
        borderBottom: '1px solid #eee',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
        background: 'white',
      }}
    >
      {showBackButton && (
        <Button
          onClick={() => {
            navigate(backButtonPath);
          }}
          variant='outline-primary'
          style={{
            position: 'absolute',
            left: 20,
            top: 0,
            bottom: 0,
            margin: 'auto',
            height: 40,
            fontSize: 14,
          }}
        >
          <BsArrowLeft /> {backButtonContent}
        </Button>
      )}
      <img
        src={Logo}
        alt='logo'
        style={{ height: 25 }}
      />
    </div>
  );
};

export { Header };
