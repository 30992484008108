import { countryToAlpha2, countryToAlpha3 } from 'country-to-iso';
import _ from 'lodash';
import { CircleFlag } from 'react-circle-flags';

const regions = {
  Algeria: [
    {
      name: 'Algiers',
    },
    {
      name: 'B\u00e9ja\u00efa',
    },
    {
      name: 'Chlef Province',
      subregions: [
        {
          name: 'Dahra',
        },
      ],
    },
    {
      name: 'Mascara',
    },
    {
      name: 'M\u00e9d\u00e9a',
    },
    {
      name: 'Tlemcen',
    },
    {
      name: 'Zaccar',
    },
  ],
  'Cape Verde': [
    {
      name: 'Ch\u00e3 das Caldeiras',
    },
  ],
  Morocco: [
    {
      name: 'Atlas Mountains',
    },
    {
      name: 'Benslimane',
    },
    {
      name: 'Mekn\u00e8s',
    },
  ],
  'South Africa': [
    {
      name: 'Breede River Valley',
    },
    {
      name: 'Constantia',
    },
    {
      name: 'Durbanville',
    },
    {
      name: 'Elgin',
    },
    {
      name: 'Elim',
    },
    {
      name: 'Franschhoek',
    },
    {
      name: 'Little Karoo',
    },
    {
      name: 'Orange River Valley',
    },
    {
      name: 'Paarl',
    },
    {
      name: 'Robertson',
    },
    {
      name: 'Stellenbosch',
    },
    {
      name: 'Swartland',
    },
    {
      name: 'Tulbagh',
    },
  ],
  Tunisia: [
    {
      name: 'Arianah',
    },
    {
      name: 'Nabul',
    },
    {
      name: 'Sousse',
    },
  ],
  Argentina: [
    {
      name: 'Buenos Aires Province',
      subregions: [
        {
          name: 'M\u00e9danos',
        },
      ],
    },
    {
      name: 'Catamarca Province',
    },
    {
      name: 'La Rioja Province',
    },
    {
      name: 'Mendoza Province',
    },
    {
      name: 'Neuqu\u00e9n Province',
    },
    {
      name: 'R\u00edo Negro Province',
    },
    {
      name: 'Salta Province',
    },
    {
      name: 'San Juan Province',
    },
  ],
  Bolivia: [
    {
      name: 'Tarija DepBoliviartment',
    },
  ],
  Brazil: [
    {
      name: 'Bahia',
      subregions: [
        {
          name: 'Cura\u00e7\u00e1',
        },
        {
          name: 'Irec\u00ea',
        },
        {
          name: 'Juazeiro',
        },
      ],
    },
    {
      name: 'Mato Grosso',
      subregions: [
        {
          name: 'Nova Mutum',
        },
      ],
    },
    {
      name: 'Minas Gerais',
      subregions: [
        {
          name: 'Andradas',
        },
        {
          name: 'Caldas',
        },
        {
          name: 'Pirapora',
        },
        {
          name: 'Santa Rita de Caldas',
        },
      ],
    },
    {
      name: 'Paran\u00e1',
      subregions: [
        {
          name: 'Bandeirantes',
        },
        {
          name: 'Marialva',
        },
        {
          name: 'Maring\u00e1',
        },
        {
          name: 'Ros\u00e1rio do Ava\u00ed',
        },
      ],
    },
    {
      name: 'Pernambuco',
      subregions: [
        {
          name: 'Casa Nova',
        },
        {
          name: 'Petrolina',
        },
        {
          name: 'Santa Maria da Boa Vista',
        },
      ],
    },
    {
      name: 'Rio Grande do Sul',
      subregions: [
        {
          name: 'Bento Gon\u00e7alves',
        },
        {
          name: 'Caxias do Sul',
        },
        {
          name: 'Cotipor\u00e3',
        },
        {
          name: 'Farroupilha',
        },
        {
          name: 'Flores da Cunha',
        },
        {
          name: 'Garibaldi',
        },
        {
          name: 'Pinto Bandeira',
        },
      ],
    },
    {
      name: 'Santa Catarina',
      subregions: [
        {
          name: 'Pinheiro Preto',
        },
        {
          name: 'S\u00e3o Joaquim',
        },
        {
          name: 'Tangar\u00e1',
        },
      ],
    },
    {
      name: 'S\u00e3o Paulo',
      subregions: [
        {
          name: 'Jundia\u00ed',
        },
        {
          name: 'S\u00e3o Roque',
        },
      ],
    },
  ],
  Canada: [
    {
      name: 'British Columbia',
      subregions: [
        {
          name: 'Fraser Valley',
        },
        {
          name: 'Gulf Islands',
        },
        {
          name: 'Okanagan Valley',
        },
        {
          name: 'Similkameen Valley',
        },
        {
          name: 'Thompson Valley',
        },
        {
          name: 'Vancouver Island',
        },
      ],
    },
    {
      name: 'Nova Scotia',
      subregions: [
        {
          name: 'Annapolis Valley',
        },
      ],
    },
    {
      name: 'Ontario',
      subregions: [
        {
          name: 'Lake Erie',
        },
        {
          name: 'Niagara Peninsula',
        },
        {
          name: 'Prince Edward County',
        },
        {
          name: 'Toronto',
        },
      ],
    },
    {
      name: 'Quebec',
      subregions: [
        {
          name: 'Eastern Townships',
        },
      ],
    },
  ],
  Colombia: [
    {
      name: 'Villa de Leyva',
    },
    {
      name: 'Valle del Cauca',
      subregions: [
        {
          name: 'La Uni\u00f3n',
        },
      ],
    },
  ],
  Chile: [
    {
      name: 'Aconcagua',
      subregions: [
        {
          name: 'Aconcagua Valley',
        },
        {
          name: 'Casablanca Valley',
        },
      ],
    },
    {
      name: 'Atacama',
      subregions: [
        {
          name: 'Copiap\u00f3 Valley',
        },
        {
          name: 'Huasco Valley',
        },
      ],
    },
    {
      name: 'Central Valley',
      subregions: [
        {
          name: 'Cachapoal Valley',
        },
        {
          name: 'Maipo Valley',
        },
        {
          name: 'Mataquito Valley',
        },
        {
          name: 'Maule Valley',
        },
      ],
    },
    {
      name: 'Coquimbo',
      subregions: [
        {
          name: 'Choapa Valley',
        },
        {
          name: 'Elqui Valley',
        },
        {
          name: 'Limar\u00ed',
        },
      ],
    },
    {
      name: 'Pica',
    },
    {
      name: 'Southern Chile',
      subregions: [
        {
          name: 'B\u00edo-B\u00edo Valley',
        },
        {
          name: 'Itata Valley',
        },
        {
          name: 'Malleco Valley',
        },
        {
          name: 'Bueno Valley',
        },
      ],
    },
  ],
  Mexico: [
    {
      name: 'Aguascalientes',
      subregions: [
        {
          name: 'Aguascalientes',
        },
      ],
    },
    {
      name: 'Baja California',
      subregions: [
        {
          name: 'Valle de Guadalupe',
          subregions: [
            {
              name: 'Valle de Calafia',
            },
            {
              name: 'Valle de Mexicali',
            },
            {
              name: 'Valle de San Vicente',
            },
            {
              name: 'Valle de Santo Tom\u00e1s',
            },
            {
              name: 'Zona Tecate',
            },
          ],
        },
      ],
    },
    {
      name: 'Coahuila',
      subregions: [
        {
          name: 'Valle de Parras',
        },
      ],
    },
    {
      name: 'Guanajuato',
      subregions: [
        {
          name: 'Guanajuato City',
        },
        {
          name: 'San Miguel de Allende',
        },
        {
          name: 'Dolores Hidalgo',
        },
        {
          name: 'San Felipe',
        },
        {
          name: 'Salvatierra',
        },
        {
          name: 'San Francisco del Rinc\u00f3n',
        },
      ],
    },
    {
      name: 'Hidalgo',
    },
    {
      name: 'Nuevo Le\u00f3n',
      subregions: [
        {
          name: 'Valle de Las Maravillas',
        },
      ],
    },
    {
      name: 'Quer\u00e9taro',
      subregions: [
        {
          name: 'Valle de Tequisquiapan',
        },
      ],
    },
    {
      name: 'Sonora',
      subregions: [
        {
          name: 'Caborca',
        },
        {
          name: 'Hermosillo',
        },
      ],
    },
    {
      name: 'Zacatecas',
      subregions: [
        {
          name: 'Valle de las Arcinas',
        },
      ],
    },
  ],
  Peru: [
    {
      name: 'Arequipa',
    },
    {
      name: 'Huaral District',
    },
    {
      name: 'Ica Region',
    },
    {
      name: 'Pica',
    },
  ],
  'United States': [
    {
      name: 'Arizona',
    },
    {
      name: 'California',
      subregions: [
        {
          name: 'Central Coast',
          subregions: [
            {
              name: 'Livermore Valley',
            },
            {
              name: 'Paso Robles',
            },
            {
              name: 'York Mountain',
            },
          ],
        },
        {
          name: 'Central Valley',
          subregions: [
            {
              name: 'Lodi',
            },
          ],
        },
        {
          name: 'North Coast',
          subregions: [
            {
              name: 'Mendocino',
            },
            {
              name: 'Napa Valley',
            },
            {
              name: 'Sonoma County',
            },
          ],
        },
        {
          name: 'Sierra Foothills',
        },
        {
          name: 'South Coast',
        },
      ],
    },
    {
      name: 'Colorado',
    },
    {
      name: 'Idaho',
    },
    {
      name: 'Michigan',
    },
    {
      name: 'Missouri',
    },
    {
      name: 'New Jersey',
    },
    {
      name: 'New Mexico',
    },
    {
      name: 'New York',
      subregions: [
        {
          name: 'Finger Lakes',
        },
        {
          name: 'Hudson River Region',
        },
        {
          name: 'Long Island',
        },
      ],
    },
    {
      name: 'Oregon',
      subregions: [
        {
          name: 'Willamette Valley',
        },
      ],
    },
    {
      name: 'Pennsylvania',
    },
    {
      name: 'Texas',
      subregions: [
        {
          name: 'Texas High Plains',
        },
        {
          name: 'Texas Hill Country',
        },
        {
          name: 'Trans-Pecos',
        },
      ],
    },
    {
      name: 'Virginia',
    },
    {
      name: 'Washington',
      subregions: [
        {
          name: 'Columbia Valley',
        },
      ],
    },
  ],
  Uruguay: [
    {
      name: 'Montevideo',
    },
    {
      name: 'Canelones',
    },
    {
      name: 'Maldonado',
      subregions: [
        {
          name: 'Garz\u00f3n',
        },
        {
          name: 'Jos\u00e9 Ignacio',
        },
      ],
    },
    {
      name: 'Colonia',
    },
    {
      name: 'San Jos\u00e9',
    },
  ],
  Venezuela: [
    {
      name: 'Carora',
    },
  ],
  Albania: [
    {
      name: 'Berat',
    },
    {
      name: 'Kor\u00e7a',
    },
    {
      name: 'Leskovik',
    },
    {
      name: 'Lezh\u00eb',
    },
    {
      name: 'P\u00ebrmet',
    },
    {
      name: 'Shkoder',
    },
    {
      name: 'Tirana County',
    },
  ],
  Austria: [
    {
      name: 'Burgenland',
    },
    {
      name: 'Northeastern and eastern',
      subregions: [
        {
          name: 'Kamptal',
        },
        {
          name: 'Kremstal',
        },
        {
          name: 'Wachau',
        },
        {
          name: 'Wagram',
        },
        {
          name: 'Weinviertel',
        },
      ],
    },
    {
      name: 'Southern',
    },
    {
      name: 'Vienna',
    },
  ],
  Belgium: [
    {
      name: 'C\u00f4tes de Sambre et Meuse',
    },
    {
      name: 'Hagelandse wijn',
    },
    {
      name: 'Haspengouw',
    },
    {
      name: 'Heuvelland',
    },
  ],
  'Bosnia and Herzegovina': [
    {
      name: '\u010capljina',
    },
    {
      name: '\u010citluk',
    },
    {
      name: 'Ljubu\u0161ki',
    },
    {
      name: 'Me\u0111ugorje',
    },
    {
      name: 'Mostar',
    },
    {
      name: 'Stolac',
    },
    {
      name: 'Trebinje',
    },
  ],
  Bulgaria: [
    {
      name: 'Black Sea',
    },
    {
      name: 'Danubian Plain',
    },
    {
      name: 'Rose Valley',
    },
    {
      name: 'Thrace',
    },
    {
      name: 'Valley of the',
    },
  ],
  Croatia: [
    {
      name: 'Continental Croatia:',
      subregions: [
        {
          name: 'Moslavina',
        },
        {
          name: 'Ple\u0161ivica',
        },
        {
          name: 'Podunavlje',
        },
        {
          name: 'Pokuplje',
        },
        {
          name: 'Prigorje',
        },
        {
          name: 'Slavonia',
        },
        {
          name: 'Zagorje',
        },
      ],
    },
    {
      name: 'Littoral Croatia:',
      subregions: [
        {
          name: 'Croatian Coast',
        },
        {
          name: 'Dalmatian Interior',
        },
        {
          name: 'Central and South',
        },
        {
          name: 'Northern',
        },
        {
          name: 'Istria',
        },
      ],
    },
  ],
  Cyprus: [
    {
      name: 'Commandaria',
    },
    {
      name: 'Laona \u2013',
    },
    {
      name: 'Vouni Panagias \u2013 Ambelitis',
    },
    {
      name: 'Krasochoria Lemesou',
    },
    {
      name: 'Pitsilia',
    },
    {
      name: 'Diarizos Valley',
    },
  ],
  'Czech Republic': [
    {
      name: 'Moravia',
      subregions: [
        {
          name: 'Mikulov',
        },
        {
          name: 'Slov\u00e1cko',
        },
        {
          name: 'Velk\u00e9 Pavlovice',
        },
        {
          name: 'Znojmo',
        },
      ],
    },
    {
      name: 'Bohemia',
      subregions: [
        {
          name: 'Litom\u011b\u0159ice',
        },
        {
          name: 'M\u011bln\u00edk',
        },
      ],
    },
    {
      name: 'Prague',
      subregions: [
        {
          name: "Gazebo at Gr\u00f6be's Villa",
        },
        {
          name: "St. Clare's",
        },
        {
          name: "St. Wenceslas' Vineyard at Prague castle",
        },
        {
          name: 'Salabka, Troja',
        },
      ],
    },
  ],
  France: [
    {
      name: 'Alsace',
    },
    {
      name: 'Bordeaux',
      subregions: [
        {
          name: 'Barsac',
        },
        {
          name: 'Entre-Deux-Mers',
        },
        {
          name: 'Fronsac',
        },
        {
          name: 'Graves',
        },
        {
          name: 'Haut-M\u00e9doc',
        },
        {
          name: 'Margaux',
        },
        {
          name: 'M\u00e9doc',
        },
        {
          name: 'Pauillac',
        },
        {
          name: 'Pessac-L\u00e9ognan',
        },
        {
          name: 'Pomerol',
        },
        {
          name: 'Saint-\u00c9milion',
        },
        {
          name: 'Saint-Est\u00e8phe',
        },
        {
          name: 'Saint-Julien',
        },
        {
          name: 'Sauternes',
        },
      ],
    },
    {
      name: 'Burgundy',
      subregions: [
        {
          name: 'Beaujolais',
        },
        {
          name: 'Bugey',
        },
        {
          name: 'Chablis',
        },
        {
          name: 'C\u00f4te Chalonnaise',
        },
        {
          name: "C\u00f4te d'Or",
          subregions: [
            {
              name: 'C\u00f4te de Beaune',
              subregions: [
                {
                  name: 'Aloxe-Corton',
                },
                {
                  name: 'Auxey-Duresses',
                },
                {
                  name: 'Beaune',
                },
                {
                  name: 'Chassagne-Montrachet',
                },
                {
                  name: 'Meursault',
                },
                {
                  name: 'Santenay',
                },
              ],
            },
            {
              name: 'C\u00f4te de Nuits',
              subregions: [
                {
                  name: 'Chambolle-Musigny',
                },
                {
                  name: 'Gevrey-Chambertin',
                },
                {
                  name: 'Nuits-Saint-Georges',
                },
                {
                  name: 'Vosne-Roman\u00e9e',
                },
              ],
            },
          ],
        },
        {
          name: 'M\u00e2connais',
          subregions: [
            {
              name: 'Pouilly-Fuiss\u00e9',
            },
          ],
        },
      ],
    },
    {
      name: 'Champagne',
    },
    {
      name: 'Corsica',
      subregions: [
        {
          name: 'Ajaccio',
        },
        {
          name: 'Cap Course',
        },
        {
          name: 'Patrimonio',
        },
        {
          name: 'Vin de Corse',
          subregions: [
            {
              name: 'Calvi',
            },
            {
              name: 'Figari',
            },
            {
              name: 'Porto-Vecchio',
            },
            {
              name: 'Sart\u00e8ne',
            },
          ],
        },
      ],
    },
    {
      name: 'Jura',
    },
    {
      name: 'Languedoc-Roussillon',
      subregions: [
        {
          name: 'Banyuls',
        },
        {
          name: 'Blanquette de Limoux',
        },
        {
          name: 'Cabard\u00e8s',
        },
        {
          name: 'Collioure',
        },
        {
          name: 'Corbi\u00e8res',
        },
        {
          name: 'C\u00f4tes du Roussillon',
        },
        {
          name: 'Fitou',
        },
        {
          name: 'Maury',
        },
        {
          name: 'Minervois',
        },
        {
          name: 'Rivesaltes',
        },
      ],
    },
    {
      name: 'Loire Valley',
      subregions: [
        {
          name: 'Anjou',
        },
        {
          name: 'Cognac',
        },
        {
          name: 'Muscadet',
        },
        {
          name: 'Pouilly-Fum\u00e9',
        },
        {
          name: 'Sancerre',
        },
        {
          name: 'Touraine',
        },
      ],
    },
    {
      name: 'Lorraine',
    },
    {
      name: 'Madiran',
    },
    {
      name: 'Provence',
    },
    {
      name: 'Rh\u00f4ne',
      subregions: [
        {
          name: 'Beaumes-de-Venise',
        },
        {
          name: 'Ch\u00e2teau-Grillet',
        },
        {
          name: 'Ch\u00e2teauneuf-du-Pape',
        },
        {
          name: 'Condrieu',
        },
        {
          name: 'Cornas',
        },
        {
          name: 'C\u00f4te du Rh\u00f4ne-Villages',
        },
        {
          name: 'C\u00f4te-R\u00f4tie',
        },
        {
          name: 'C\u00f4tes du Rh\u00f4ne',
        },
        {
          name: 'Crozes-Hermitage',
        },
        {
          name: 'Gigondas',
        },
        {
          name: 'Hermitage',
        },
        {
          name: 'St. Joseph',
        },
        {
          name: 'Saint-P\u00e9ray',
        },
        {
          name: 'Vacqueyras',
        },
      ],
    },
    {
      name: 'Savoy',
    },
  ],
  Georgia: [
    {
      name: 'Abkhazia',
    },
    {
      name: 'Kakheti',
    },
    {
      name: 'Kartli',
    },
    {
      name: 'Imereti',
    },
    {
      name: 'Racha-Lechkhumi and Kvemo Svaneti',
    },
  ],
  Germany: [
    {
      name: 'Ahr',
    },
    {
      name: 'Baden',
    },
    {
      name: 'Franconia',
    },
    {
      name: 'Hessische Bergstra\u00dfe',
    },
    {
      name: 'Mittelrhein',
    },
    {
      name: 'Mosel',
    },
    {
      name: 'Nahe',
    },
    {
      name: 'Palatinate',
    },
    {
      name: 'Rheingau',
    },
    {
      name: 'Rheinhessen',
    },
    {
      name: 'Saale-Unstrut',
    },
    {
      name: 'Saxony',
    },
    {
      name: 'W\u00fcrttemberg',
    },
  ],
  Greece: [
    {
      name: 'Aegean islands',
      subregions: [
        {
          name: 'Crete',
        },
        {
          name: 'Limnos',
        },
        {
          name: 'Paros',
        },
        {
          name: 'Rhodes',
        },
        {
          name: 'Samos',
        },
        {
          name: 'Santorini',
        },
      ],
    },
    {
      name: 'Central Greece',
      subregions: [
        {
          name: 'Attica',
        },
        {
          name: 'Epirus',
          subregions: [
            {
              name: 'Zitsa',
            },
          ],
        },
        {
          name: 'Thessaly',
          subregions: [
            {
              name: 'Nea Anchialos',
            },
            {
              name: 'Rapsani',
            },
          ],
        },
      ],
    },
    {
      name: 'Ionian Islands',
      subregions: [
        {
          name: 'Kefalonia',
        },
      ],
    },
    {
      name: 'Macedonia',
      subregions: [
        {
          name: 'Amyntaion',
        },
        {
          name: 'Goumenissa',
        },
        {
          name: 'Naousa, Imathia',
        },
      ],
    },
    {
      name: 'Peloponnesus',
      subregions: [
        {
          name: 'Mantineia',
        },
        {
          name: 'Nemea',
        },
        {
          name: 'Patras',
        },
      ],
    },
  ],
  Hungary: [
    {
      name: 'Balaton',
      subregions: [
        {
          name: 'Badacsony',
        },
        {
          name: 'Balaton-felvid\u00e9k',
        },
        {
          name: 'Balatonbogl\u00e1r',
        },
        {
          name: 'Balatonf\u00fcred-Csopak',
        },
        {
          name: 'Nagy-Soml\u00f3',
        },
        {
          name: 'Zala',
        },
      ],
    },
    {
      name: 'Duna',
      subregions: [
        {
          name: 'Csongr\u00e1d',
        },
        {
          name: 'Haj\u00f3s-Baja',
        },
        {
          name: 'Kuns\u00e1g',
        },
      ],
    },
    {
      name: 'Eger',
      subregions: [
        {
          name: 'B\u00fckk',
        },
        {
          name: 'Eger',
        },
        {
          name: '\u00c9szak-Dun\u00e1nt\u00fal',
        },
        {
          name: 'Etyek-Buda',
        },
        {
          name: 'M\u00e1tra',
        },
        {
          name: 'M\u00f3r',
        },
        {
          name: 'Neszm\u00e9ly',
        },
        {
          name: 'Pannonhalma',
        },
      ],
    },
    {
      name: 'Pannon',
      subregions: [
        {
          name: 'P\u00e9cs',
        },
        {
          name: 'Szeksz\u00e1rd',
        },
        {
          name: 'Tolna',
        },
        {
          name: 'Vill\u00e1ny',
        },
      ],
    },
    {
      name: 'Sopron',
    },
    {
      name: 'Tokaj',
    },
  ],
  Ireland: [
    {
      name: 'Cork',
    },
  ],
  Italy: [
    {
      name: 'Apulia',
      synonyms: ['Puglia'],
      subregions: [
        {
          name: 'Bianco di Locorotondo e Martina Franca',
        },
        {
          name: 'Primitivo di Manduria',
        },
      ],
    },
    {
      name: 'Calabria',
      subregions: [
        {
          name: 'Bivongi',
        },
        {
          name: 'Cir\u00f2',
        },
        {
          name: 'Gaglioppo',
        },
        {
          name: 'Greco di Bianco',
        },
        {
          name: 'Lamezia',
        },
        {
          name: 'Melissa',
        },
        {
          name: "Sant'Anna di Isola Capo Rizzuto",
        },
        {
          name: 'Savuto',
        },
        {
          name: 'Scavigna',
        },
        {
          name: 'Terre di Cosenza',
        },
      ],
    },
    {
      name: 'Campania',
      subregions: [
        {
          name: 'Avellino',
          subregions: [
            {
              name: 'Aglianico',
            },
            {
              name: 'Falanghina',
            },
            {
              name: 'Fiano',
            },
            {
              name: 'Greco di Tufo',
            },
          ],
        },
        {
          name: 'Benevento',
          subregions: [
            {
              name: 'Aglianico',
            },
            {
              name: 'Falanghina',
            },
            {
              name: 'Solopaca',
            },
          ],
        },
        {
          name: 'Caserta',
        },
        {
          name: 'Napoli',
        },
        {
          name: 'Salerno',
        },
      ],
    },
    {
      name: 'Emilia-Romagna',
      subregions: [
        {
          name: 'Colli Cesenate',
        },
        {
          name: 'Sangiovese Superiore di Romagna',
        },
        {
          name: 'Trebbiano di Romagna',
        },
      ],
    },
    {
      name: 'Liguria',
      subregions: [
        {
          name: 'Cinque Terre',
        },
      ],
    },
    {
      name: 'Lombardy',
      synonyms: ['Lombardia'],
      subregions: [
        {
          name: 'Franciacorta',
        },
        {
          name: 'Oltrepo Pavese',
        },
      ],
    },
    {
      name: 'Marche',
      subregions: [
        {
          name: 'Castelli di Jesi',
        },
        {
          name: 'Conero',
        },
        {
          name: 'Piceno',
        },
      ],
    },
    {
      name: 'Piedmont',
      synonyms: ['Piemonte'],
      subregions: [
        {
          name: 'Acqui',
        },
        {
          name: 'Alba',
        },
        {
          name: 'Asti',
        },
        {
          name: 'Barolo',
        },
        {
          name: 'Colli Tortonesi',
        },
        {
          name: 'Gattinara',
        },
        {
          name: 'Gavi',
        },
        {
          name: 'Ghemme',
        },
        {
          name: 'Langhe',
        },
        {
          name: 'Monferrato',
        },
        {
          name: 'Nizza',
        },
        {
          name: 'Ovada',
        },
      ],
    },
    {
      name: 'Sardinia',
      synonyms: ['Sardegna'],
      subregions: [
        {
          name: 'Cagliari',
        },
        {
          name: 'Cannonau',
        },
        {
          name: 'Monti',
        },
        {
          name: 'Nuragus',
        },
        {
          name: 'Ogliastra',
        },
        {
          name: 'Vermentino di Gallura',
        },
      ],
    },
    {
      name: 'Sicily',
      synonyms: ['Sicilia'],
      subregions: [
        {
          name: 'Etna',
        },
        {
          name: 'Noto',
        },
        {
          name: 'Pantelleria',
        },
      ],
    },
    {
      name: 'Trentino-Alto Adige',
      subregions: [
        {
          name: 'South Tyrol',
        },
        {
          name: 'Trentino',
        },
      ],
    },
    {
      name: 'Tuscany',
      synonyms: ['Toscana'],
      subregions: [
        {
          name: 'Bolgheri',
        },
        {
          name: 'Chianti',
        },
        {
          name: 'Chianti Classico',
        },
        {
          name: 'Colli Apuani',
        },
        {
          name: 'Colli Etruria Centrale',
        },
        {
          name: 'Colline Lucchesi',
        },
        {
          name: 'Elba',
        },
        {
          name: 'Montalcino',
        },
        {
          name: 'Montescudaio',
        },
        {
          name: 'Parrina',
        },
        {
          name: 'Pitigliano',
        },
        {
          name: 'San Gimignano',
        },
        {
          name: 'Scansano',
        },
        {
          name: 'Val di Chiana',
        },
        {
          name: 'Val di Cornia',
        },
        {
          name: 'Valdinievole',
        },
        {
          name: 'Valle di Arbia',
        },
      ],
    },
    {
      name: 'Umbria',
      subregions: [
        {
          name: 'Montefalco',
        },
        {
          name: 'Orvieto',
        },
        {
          name: 'Torgiano',
        },
      ],
    },
    {
      name: 'Veneto',
      subregions: [
        {
          name: 'Arcole',
        },
        {
          name: 'Bagnoli',
        },
        {
          name: 'Bardolino',
        },
        {
          name: 'Bianco di Custoza',
        },
        {
          name: 'Breganze',
        },
        {
          name: 'Colli Berici',
        },
        {
          name: 'Colli di Conegliano',
        },
        {
          name: 'Colli Euganei',
        },
        {
          name: 'Gambellara',
        },
        {
          name: 'Garda',
        },
        {
          name: 'Lessini Durello',
        },
        {
          name: 'Lison Pramaggiore',
        },
        {
          name: 'Lugana',
        },
        {
          name: 'Montgello e Colli Asolani',
        },
        {
          name: 'Piave',
        },
        {
          name: 'Prosecco',
        },
        {
          name: 'Soave',
        },
        {
          name: 'Valdadige',
        },
        {
          name: 'Valpolicella',
        },
      ],
    },
  ],
  Latvia: [
    {
      name: 'Sabile',
    },
  ],
  Lithuania: [
    {
      name: 'Anyk\u0161\u010di\u0173 vynas',
    },
    {
      name: 'M\u0117melio vynas',
    },
  ],
  Luxembourg: [
    {
      name: 'Moselle Valley',
    },
  ],
  Moldova: [
    {
      name: 'Bardar',
    },
    {
      name: 'Codri',
    },
    {
      name: 'Cricova',
    },
    {
      name: 'H\u00eence\u0219ti',
    },
    {
      name: 'Purcari',
    },
  ],
  Montenegro: [
    {
      name: 'Crmnica',
    },
    {
      name: 'Planta\u017ee',
    },
  ],
  Netherlands: [
    {
      name: 'Groesbeek',
    },
  ],
  'North Macedonia': [
    {
      name: 'Povardarie',
    },
    {
      name: 'Skopsko vinogorje',
    },
    {
      name: 'Tikve\u0161',
    },
  ],
  Norway: [
    {
      name: 'Mostly',
    },
    {
      name: 'Sognefjord',
    },
  ],
  Poland: [
    {
      name: 'Dolny \u015al\u0105sk',
    },
    {
      name: 'Kazimierz Dolny',
    },
    {
      name: 'Ma\u0142opolska',
    },
    {
      name: 'Podkarpacie',
    },
    {
      name: 'Warka',
    },
    {
      name: 'Zielona G\u00f3ra',
    },
  ],
  Portugal: [
    {
      name: 'Alentejo',
    },
    {
      name: 'Bairrada',
    },
    {
      name: 'Bucelas',
    },
    {
      name: 'Carcavelos',
    },
    {
      name: 'Colares',
    },
    {
      name: 'D\u00e3o',
    },
    {
      name: 'Lagoa',
    },
    {
      name: 'Lagos',
    },
    {
      name: 'Madeira',
    },
    {
      name: 'Portim\u00e3o',
    },
    {
      name: 'Porto e Douro',
    },
    {
      name: 'Set\u00fabal',
    },
    {
      name: 'Tavira',
    },
    {
      name: 'Vinhos Verdes',
    },
  ],
  Romania: [
    {
      name: 'Banat',
      subregions: [
        {
          name: 'Arad',
        },
        {
          name: 'Jamu Mare',
        },
        {
          name: 'M\u0103derat',
        },
        {
          name: 'Mini\u0219',
        },
        {
          name: 'Moldova Nou\u0103',
        },
        {
          name: 'Reca\u0219',
        },
        {
          name: 'Silagiu',
        },
        {
          name: 'Teremia',
        },
        {
          name: 'Tirol',
        },
      ],
    },
  ],
  Russia: [
    {
      name: 'Caucasus',
    },
    {
      name: 'Krasnodar',
    },
    {
      name: 'Stavropol',
    },
  ],
  Serbia: [
    {
      name: 'Banat',
    },
  ],
  Slovakia: [
    {
      name: 'Malokarpatsk\u00e1',
    },
    {
      name: 'Ju\u017enoslovensk\u00e1',
    },
    {
      name: 'Nitrianska',
    },
    {
      name: 'Stredoslovensk\u00e1',
    },
    {
      name: 'Tokaj',
    },
    {
      name: 'V\u00fdchodoslovensk\u00e1',
    },
    {
      name: 'The whole of southern Slovakia',
    },
  ],
  Slovenia: [
    {
      name: 'Podravje',
    },
    {
      name: 'Posavje',
    },
    {
      name: 'Primorska',
    },
  ],
  Spain: [
    {
      name: 'Andalusia',
      subregions: [
        {
          name: 'Condado de Huelva',
        },
        {
          name: 'Jerez-Xeres-Sherry',
        },
        {
          name: 'M\u00e1laga and Sierras de M\u00e1laga',
        },
        {
          name: 'Manzanilla',
        },
        {
          name: 'Montilla-Moriles',
        },
      ],
    },
    {
      name: 'Aragon',
      subregions: [
        {
          name: 'Calatayud',
        },
        {
          name: 'Campo de Borja',
        },
        {
          name: 'Campo de Cari\u00f1ena',
        },
        {
          name: 'Cava',
        },
        {
          name: 'Somontano',
        },
      ],
    },
    {
      name: 'Balearic Islands',
      subregions: [
        {
          name: 'Binissalem-Mallorca',
        },
        {
          name: 'Pl\u00e0 i Llevant',
        },
      ],
    },
    {
      name: 'Basque Country',
      subregions: [
        {
          name: 'Alavan Txakoli',
        },
        {
          name: 'Biscayan Txakoli',
        },
        {
          name: 'Cava',
        },
        {
          name: 'Getaria Txakoli',
        },
        {
          name: 'Rioja',
        },
      ],
    },
    {
      name: 'Canary Islands',
      subregions: [
        {
          name: 'Abona',
        },
        {
          name: 'El Hierro',
        },
        {
          name: 'Gran Canaria',
        },
        {
          name: 'La Gomera',
        },
        {
          name: 'La Palma',
        },
        {
          name: 'Lanzarote',
        },
        {
          name: 'Tacoronte-Acentejo',
        },
        {
          name: 'Valle de G\u00fc\u00edmar',
        },
        {
          name: 'Valle de la Orotava',
        },
        {
          name: 'Ycoden-Daute-Isora',
        },
      ],
    },
    {
      name: 'Castile and Le\u00f3n',
      subregions: [
        {
          name: 'Arlanza',
        },
        {
          name: 'Arribes del Duero',
        },
        {
          name: 'Bierzo',
        },
        {
          name: 'Cava',
        },
        {
          name: 'Cigales',
        },
        {
          name: 'Espumosos de Castilla y Le\u00f3n',
        },
        {
          name: 'Ribera del Duero',
        },
        {
          name: 'Rueda',
        },
        {
          name: 'Tierra del Vino de Zamora',
        },
        {
          name: 'Toro',
        },
        {
          name: 'Valles de Benavente',
        },
        {
          name: 'Tierra de Le\u00f3n',
        },
        {
          name: 'Valtiendas',
        },
        {
          name: 'Vino de la Tierra Castilla y Le\u00f3n',
        },
      ],
    },
    {
      name: 'Castile\u2013La Mancha',
      subregions: [
        {
          name: 'Almansa',
        },
        {
          name: 'Dominio de Valdepusa',
        },
        {
          name: 'Guijoso',
        },
        {
          name: 'Jumilla',
        },
        {
          name: 'La Mancha',
        },
        {
          name: 'Manchuela',
        },
        {
          name: 'M\u00e9ntrida',
        },
        {
          name: 'Mond\u00e9jar',
        },
        {
          name: 'Ribera del J\u00facar',
        },
        {
          name: 'Valdepe\u00f1as',
        },
      ],
    },
    {
      name: 'Catalonia',
      subregions: [
        {
          name: 'Alella',
        },
        {
          name: 'Catalunya',
        },
        {
          name: 'Cava',
        },
        {
          name: 'Conca de Barber\u00e0',
        },
        {
          name: 'Costers del Segre',
        },
        {
          name: 'Empord\u00e0',
        },
        {
          name: 'Montsant',
        },
        {
          name: 'Pened\u00e8s',
        },
        {
          name: 'Pla de Bages',
        },
        {
          name: 'Priorat',
        },
        {
          name: 'Tarragona',
        },
        {
          name: 'Terra Alta',
        },
      ],
    },
    {
      name: 'Extremadura',
      subregions: [
        {
          name: 'Cava',
        },
        {
          name: 'Ribera del Guadiana',
        },
      ],
    },
    {
      name: 'Galicia',
      subregions: [
        {
          name: 'Monterrey',
        },
        {
          name: 'R\u00edas Baixas',
        },
        {
          name: 'Ribeira Sacra',
        },
        {
          name: 'Ribeiro',
        },
        {
          name: 'Valdeorras',
        },
      ],
    },
    {
      name: 'La Rioja',
      subregions: [
        {
          name: 'Cava',
        },
        {
          name: 'Rioja',
        },
      ],
    },
    {
      name: 'Community of Madrid',
      subregions: [
        {
          name: 'Vinos de Madrid',
        },
      ],
    },
    {
      name: 'Regi\u00f3n de Murcia',
      subregions: [
        {
          name: 'Bullas',
        },
        {
          name: 'Jumilla',
        },
        {
          name: 'Yecla',
        },
      ],
    },
    {
      name: 'Navarre',
      subregions: [
        {
          name: 'Cava',
        },
        {
          name: 'Navarra',
        },
        {
          name: 'Rioja',
        },
      ],
    },
    {
      name: 'Valencian Community',
      subregions: [
        {
          name: 'Alicante',
        },
        {
          name: 'Cava',
        },
        {
          name: 'Utiel-Requena',
        },
        {
          name: 'Valencia',
        },
      ],
    },
  ],
  Sweden: [
    {
      name: 'Gutevin',
    },
  ],
  Switzerland: [
    {
      name: 'Aargau',
    },
    {
      name: 'Bern',
      subregions: [
        {
          name: 'Shores of Lake Biel',
        },
        {
          name: 'Shores of Lake Thun',
        },
      ],
    },
    {
      name: 'Freiburg',
    },
    {
      name: 'Geneva',
    },
    {
      name: 'Grisons',
    },
    {
      name: 'Neuch\u00e2tel',
    },
    {
      name: 'St. Gallen',
    },
    {
      name: 'Schaffhausen',
    },
    {
      name: 'Thurgau',
    },
    {
      name: 'Ticino',
    },
    {
      name: 'Valais',
    },
    {
      name: 'Vaud',
      subregions: [
        {
          name: 'La C\u00f4te',
        },
        {
          name: 'Lavaux',
        },
      ],
    },
    {
      name: 'Z\u00fcrich',
    },
  ],
  Turkey: [
    {
      name: 'Alt\u0131nta\u015f',
    },
    {
      name: 'Beylerce',
    },
    {
      name: 'Bornova Misketi',
    },
    {
      name: 'Emir',
    },
    {
      name: 'Hasandede',
    },
    {
      name: 'Narince',
    },
    {
      name: 'Rumi',
    },
    {
      name: 'Sultaniye',
    },
    {
      name: 'Yap\u0131ncak',
    },
    {
      name: 'Adakaras\u0131',
    },
    {
      name: 'Bo\u011fazkere',
    },
    {
      name: '\u00c7alkaras\u0131',
    },
    {
      name: 'Dimrit',
    },
    {
      name: 'Horozkaras\u0131',
    },
    {
      name: 'Kalecik Karas\u0131',
    },
    {
      name: 'Karalahna',
    },
    {
      name: 'Karasak\u0131z',
    },
    {
      name: '\u00d6k\u00fczg\u00f6z\u00fc',
    },
    {
      name: 'Papazkaras\u0131',
    },
  ],
  Ukraine: [
    {
      name: 'Autonomous Republic of Crimea',
    },
    {
      name: 'Kherson Oblast',
    },
    {
      name: 'Mykolaiv Oblast',
    },
    {
      name: 'Odesa Oblast',
    },
    {
      name: 'Zakarpattia Oblast',
    },
    {
      name: 'Zaporizhzhia Oblast',
    },
  ],
  Armenia: [
    {
      name: 'Ararat Valley',
    },
    {
      name: 'Areni',
    },
    {
      name: 'Ijevan',
    },
  ],
  Azerbaijan: [
    {
      name: 'Aghdam',
    },
    {
      name: 'Baku',
    },
    {
      name: 'Ganja',
    },
    {
      name: 'Madrasa',
    },
    {
      name: 'Tovuz',
    },
  ],
  Burma: [
    {
      name: 'Shan State',
    },
  ],
  China: [
    {
      name: "Chang'an",
    },
    {
      name: 'Gaochang',
    },
    {
      name: 'Luoyang',
    },
    {
      name: 'Qiuci',
    },
    {
      name: 'Yantai',
    },
  ],
  India: [
    {
      name: 'Nashik',
    },
    {
      name: 'Bangalore',
    },
    {
      name: 'Vijayapura',
    },
    {
      name: 'Narayangaon',
    },
    {
      name: 'Pune',
    },
    {
      name: 'Sangli',
    },
  ],
  Indonesia: [
    {
      name: 'Bali',
    },
  ],
  Iran: [
    {
      name: 'Malayer',
    },
    {
      name: 'Shiraz',
    },
    {
      name: 'Takestan',
    },
    {
      name: 'Urmia',
    },
    {
      name: 'Qazvin',
    },
    {
      name: 'Quchan',
    },
  ],
  Israel: [
    {
      name: 'Galilee',
      subregions: [
        {
          name: 'Upper Galilee',
        },
      ],
    },
    {
      name: 'Golan Heights',
    },
    {
      name: 'Judean Hills',
      subregions: [
        {
          name: 'Jerusalem',
        },
        {
          name: 'Bet Shemesh',
        },
        {
          name: 'Latrun',
        },
      ],
    },
    {
      name: 'Mount Carmel',
    },
    {
      name: 'Rishon LeZion',
    },
  ],
  Japan: [
    {
      name: 'Yamanashi',
    },
    {
      name: 'Hokkaido',
    },
    {
      name: 'Nagano',
    },
  ],
  'South Korea': [
    {
      name: 'Anseong',
    },
    {
      name: 'Gimcheon',
    },
    {
      name: 'Gyeongsan',
    },
    {
      name: 'Yeongcheon',
    },
    {
      name: 'Yeongdong',
    },
  ],
  Lebanon: [
    {
      name: 'Bekaa Valley',
      subregions: [
        {
          name: 'Anjar',
        },
        {
          name: 'Chtoura',
        },
        {
          name: 'Rashaya',
        },
        {
          name: 'Zahl\u00e9',
        },
      ],
    },
    {
      name: 'Mount Lebanon',
      subregions: [
        {
          name: 'Aley',
        },
        {
          name: 'Baabda',
        },
        {
          name: 'Beit Mery',
        },
        {
          name: 'Bhamdoun',
        },
        {
          name: 'Brummana',
        },
        {
          name: 'Byblos',
        },
        {
          name: 'Chouf',
        },
        {
          name: 'Keserwan District',
        },
      ],
    },
    {
      name: 'North Governorate',
      subregions: [
        {
          name: 'Chekka',
        },
        {
          name: 'Ehden',
        },
        {
          name: 'Koura',
        },
        {
          name: 'Qadisha Valley',
        },
        {
          name: 'Tripoli',
        },
        {
          name: 'Zgharta',
        },
      ],
    },
    {
      name: 'South Governorate',
      subregions: [
        {
          name: 'Jezzine',
        },
        {
          name: 'Marjayoun',
        },
        {
          name: 'Rmaich',
        },
      ],
    },
  ],
  'Palestinian territories': [
    {
      name: 'Beit Jala',
    },
    {
      name: 'Hebron',
    },
  ],
  Syria: [
    {
      name: 'Bloudan',
    },
    {
      name: 'Homs District',
    },
    {
      name: 'Jabal el Druze',
    },
    {
      name: 'Latakia',
    },
    {
      name: 'Sednaya',
    },
    {
      name: 'Syrian Golan',
    },
    {
      name: 'Tartous',
    },
  ],
  Vietnam: [
    {
      name: 'Da Lat',
    },
  ],
  Australia: [
    {
      name: 'NSW',
      subregions: [
        {
          name: 'Big Rivers',
          subregions: [
            {
              name: 'Murray Darling',
            },
            {
              name: 'Perricoota',
            },
            {
              name: 'Riverina',
            },
            {
              name: 'Swan Hill',
            },
          ],
        },
        {
          name: 'Central Ranges',
          subregions: [
            {
              name: 'Cowra',
            },
            {
              name: 'Mudgee',
            },
            {
              name: 'Orange',
            },
          ],
        },
        {
          name: 'Hunter Valley',
          subregions: [
            {
              name: 'Hunter',
              subregions: [
                {
                  name: 'Broke Fordwich',
                },
                {
                  name: 'Pokolbin',
                },
                {
                  name: 'Upper Hunter Valley',
                },
              ],
            },
          ],
        },
        {
          name: 'Northern Rivers',
          subregions: [
            {
              name: 'Hastings River',
            },
          ],
        },
        {
          name: 'Northern Tablelands',
          subregions: [
            {
              name: 'New England',
            },
          ],
        },
        {
          name: 'South Coast',
          subregions: [
            {
              name: 'Shoalhaven Coast',
            },
            {
              name: 'Southern Highlands',
            },
          ],
        },
        {
          name: 'Southern New South Wales',
          subregions: [
            {
              name: 'Canberra District',
            },
            {
              name: 'Gundagai',
            },
            {
              name: 'Hilltops',
            },
            {
              name: 'Tumbarumba',
            },
          ],
        },
      ],
      synonyms: ['New South Wales'],
    },
    {
      name: 'Queensland',
      subregions: [
        {
          name: 'Granite Belt',
        },
        {
          name: 'South Burnett',
        },
      ],
    },
    {
      name: 'SA',
      subregions: [
        {
          name: 'Barossa',
          subregions: [
            {
              name: 'Barossa Valley',
            },
            {
              name: 'Eden Valley',
              subregions: [
                {
                  name: 'High Eden',
                },
              ],
            },
          ],
        },
        {
          name: 'Far North',
          subregions: [
            {
              name: 'Southern Flinders Ranges',
            },
          ],
        },
        {
          name: 'Fleurieu',
          subregions: [
            {
              name: 'Currency Creek',
            },
            {
              name: 'Kangaroo Island',
            },
            {
              name: 'Langhorne Creek',
            },
            {
              name: 'McLaren Vale',
            },
            {
              name: 'Southern Fleurieu',
            },
          ],
        },
        {
          name: 'Limestone Coast',
          subregions: [
            {
              name: 'Coonawarra',
            },
            {
              name: 'Mount Benson',
            },
            {
              name: 'Mount Gambier',
            },
            {
              name: 'Padthaway',
            },
            {
              name: 'Robe',
            },
            {
              name: 'Wrattonbully',
            },
          ],
        },
        {
          name: 'Lower Murray',
          subregions: [
            {
              name: 'Riverland',
            },
          ],
        },
        {
          name: 'Mount Lofty Ranges',
          subregions: [
            {
              name: 'Adelaide Hills',
              subregions: [
                {
                  name: 'Lenswood',
                },
                {
                  name: 'Piccadilly Valley',
                },
              ],
            },
            {
              name: 'Adelaide Plains',
            },
            {
              name: 'Clare Valley',
            },
          ],
        },
        {
          name: 'The Peninsulas',
        },
      ],
      synonyms: ['South Australia'],
    },
    {
      name: 'Tasmania',
      subregions: [
        {
          name: 'Coal River',
        },
        {
          name: 'Derwent Valley',
        },
        {
          name: 'East Coast',
        },
        {
          name: 'North West',
        },
        {
          name: 'Pipers River',
        },
        {
          name: 'Southern',
        },
        {
          name: 'Tamar Valley',
        },
      ],
    },
    {
      name: 'Victoria',
      subregions: [
        {
          name: 'Central Victoria',
          subregions: [
            {
              name: 'Bendigo',
            },
            {
              name: 'Goulburn Valley',
              subregions: [
                {
                  name: 'Nagambie Lakes',
                },
              ],
            },
            {
              name: 'Heathcote',
            },
            {
              name: 'Strathbogie Ranges',
            },
            {
              name: 'Upper Goulburn',
            },
          ],
        },
        {
          name: 'Gippsland',
        },
        {
          name: 'North East Victoria',
          subregions: [
            {
              name: 'Alpine Valleys',
            },
            {
              name: 'Beechworth',
            },
            {
              name: 'Glenrowan',
            },
            {
              name: 'King Valley',
            },
            {
              name: 'Rutherglen',
            },
          ],
        },
        {
          name: 'North West Victoria',
          subregions: [
            {
              name: 'Murray Darling',
            },
            {
              name: 'Swan Hill',
            },
          ],
        },
        {
          name: 'Port Phillip',
          subregions: [
            {
              name: 'Geelong',
            },
            {
              name: 'Macedon Ranges',
            },
            {
              name: 'Mornington Peninsula',
            },
            {
              name: 'Sunbury',
            },
            {
              name: 'Yarra Valley',
            },
          ],
        },
        {
          name: 'Western Victoria',
          subregions: [
            {
              name: 'Grampians',
            },
            {
              name: 'Henty',
            },
            {
              name: 'Pyrenees',
            },
          ],
        },
      ],
    },
    {
      name: 'Western Australia',
      subregions: [
        {
          name: 'Greater Perth',
          subregions: [
            {
              name: 'Peel',
            },
            {
              name: 'Perth Hills',
            },
            {
              name: 'Swan Valley',
            },
          ],
        },
        {
          name: 'SWA',
          synonyms: ['South Western Australia'],
          subregions: [
            {
              name: 'Blackwood Valley',
            },
            {
              name: 'Geographe',
            },
            {
              name: 'Great Southern',
              subregions: [
                {
                  name: 'Albany',
                },
                {
                  name: 'Denmark',
                },
                {
                  name: 'Frankland River',
                },
                {
                  name: 'Mount Barker',
                },
                {
                  name: 'Porongurup',
                },
              ],
            },
            {
              name: 'Manjimup',
            },
            {
              name: 'Margaret River',
            },
            {
              name: 'Pemberton',
            },
          ],
        },
      ],
    },
  ],
  'New Zealand': [
    {
      name: 'Auckland',
      subregions: [
        {
          name: 'Henderson',
        },
        {
          name: 'Kumeu',
        },
        {
          name: 'Matakana',
        },
        {
          name: 'Waiheke Island',
        },
      ],
    },
    {
      name: 'Canterbury',
      subregions: [
        {
          name: 'North Canterbury',
        },
        {
          name: 'Waipara Valley',
        },
      ],
    },
    {
      name: 'Central Otago',
      subregions: [
        {
          name: 'Bendigo',
        },
        {
          name: 'Bannockburn',
        },
        {
          name: 'Gibbston',
        },
        {
          name: 'Wanaka',
        },
      ],
    },
    {
      name: 'Gisborne',
    },
    {
      name: "Hawke's Bay",
      subregions: [
        {
          name: "Central Hawke's Bay",
        },
        {
          name: 'Gimblett Gravels',
        },
        {
          name: 'Bridge Pa Triangle',
        },
      ],
    },
    {
      name: 'Marlborough',
      subregions: [
        {
          name: 'Wairau Valley',
        },
        {
          name: 'Southern Valleys',
        },
        {
          name: 'Awatere Valley',
        },
      ],
    },
    {
      name: 'Nelson',
    },
    {
      name: 'Northland',
    },
    {
      name: 'Waikato',
    },
    {
      name: 'Wairarapa',
      subregions: [
        {
          name: 'Martinborough',
        },
        {
          name: 'Gladstone',
        },
      ],
    },
    {
      name: 'Waitaki Valley',
    },
  ],
};

const getRegionList = ({ name, subregions, synonyms = [], icon }) => {
  return [
    {
      label: name,
      value: _.last(name.split(' \u2022 ')),
      icon,
      synonyms,
    },
    ...(_.flattenDeep(
      subregions?.map((s) =>
        getRegionList({
          ...s,
          synonyms: s.synonyms ? [...synonyms, ...s.synonyms] : synonyms,
          name: name + ' \u2022 ' + s.name,
          icon,
        })
      )
    ) ?? []),
  ];
};

const regionlist = _.flatten(
  _.chain(regions)
    .map((values, key) =>
      values.map((value) => ({
        ...value,
        icon: (
          <span>
            <CircleFlag
              countryCode={countryToAlpha2(key)?.toLowerCase()}
              width={12}
              style={{ marginRight: 3 }}
            />
            <span style={{ fontSize: 10, color: '#c0c5ca' }}>
              {countryToAlpha3(key)}
            </span>
          </span>
        ),
      }))
    )
    .value()
).reduce((acc, region) => {
  return [...acc, ...getRegionList(region)];
}, []);

console.log(regionlist);

export default regionlist;
