import { useDispatch, useSelector } from "react-redux";
import {
  categoryChanged,
  cpuWinesFetched,
  fetchRecipe,
  indexChanged,
  recipeFetched,
} from "../../redux/pairingSlice";
import {
  recipeIsLoading,
  selectActiveCounts,
  selectActiveMaxCounts,
  selectCategories,
  selectViews,
  selectViewsByCategory,
} from "../../redux/selectors";
import { ProgressBar } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import colors from "../../constants/colors";
import { formatLabelStr } from "../../helpers/string";
import { AnimatePresence, motion } from "framer-motion";
import { spawnAnimation } from "../../constants/animations";
import { CHANGED, FULFILLED } from "../../constants/statusTypes";
import { IoCheckmarkSharp, IoAlertSharp } from "react-icons/io5";
import _ from "lodash";
import { SIDEBAR_WIDTH } from "../../constants/dimensions";
import IconButton from "../IconButton";
import { useState } from "react";
import Logo from "../../assets/full_white.svg";
import api from "../../api";
import * as feedback from "../../constants/feedbackTypes";

function SidebarHeader({ children, ...props }) {
  return (
    <div
      {...props}
      style={{ width: "100%", height: "60px", marginTop: "19px" }}
    >
      {children}
    </div>
  );
}

const RecipeRow = ({ view, index }) => {
  const { recipe } = view;
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.index) === index;
  const [hovered, setHovered] = useState(false);

  const handleRecipeClick = () => {
    dispatch(indexChanged({ index }));
  };

  const StatusIcon = ({ props }) => {
    const statuses = [view.pairingStatus, view.validationStatus];

    return (
      <div className="center-content" style={{ width: "18px" }}>
        {_.some(statuses, (status) => status === CHANGED) ? (
          <IoAlertSharp size={14} />
        ) : _.some(statuses, (status) => status === FULFILLED) &&
          _.every(statuses, (status) => status !== CHANGED) ? (
          <IoCheckmarkSharp size={14} />
        ) : null}
      </div>
    );
  };

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "30px", opacity: 1 }}
      transition={{
        ease: "easeOut",
        height: { duration: 0.2 },
        opacity: { delay: 0.15, duration: 0.2 },
      }}
      // exit={{ opacity: 0 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleRecipeClick}
      className="clickable overflow-hidden d-flex align-items-center"
      style={{
        // borderLeft: isActive ? '1px solid ' + colors.SECONDARY : '',
        backgroundColor: isActive ? "#f9f9f9" : hovered ? "#fcfcfc" : "#fff",
        opacity: isActive ? 1 : 0.6,
      }}
    >
      <StatusIcon className="d-inline-block" />
      <motion.span
        animate={{ x: hovered ? 2 : 0 }}
        className="d-inline-block text-truncate"
        style={{
          maxWidth: "160px",
          color: isActive ? "" : "#cbcbcb",
        }}
      >
        {recipe.title}
      </motion.span>
    </motion.div>
  );
};

const CategoryRow = ({ category }) => {
  const dispatch = useDispatch();
  const count = useSelector(selectViewsByCategory(category)).length;
  const isActive = useSelector((state) => state.category) === category;

  const handleCategoryClick = (category) => () => {
    dispatch(categoryChanged({ category }));
  };

  return (
    <div
      style={{
        borderLeft: isActive ? "1px solid " + colors.SECONDARY_LIGHTER : "",
      }}
    >
      <motion.div
        initial={{ x: 0 }}
        whileHover={{ x: isActive ? 0 : 2 }}
        className="px-2 clickable center-vertical"
        onClick={handleCategoryClick(category)}
        style={{
          width: "100%",
          height: "25px",
          color: isActive ? colors.SECONDARY_LIGHTER : "",
        }}
      >
        <span>{formatLabelStr(category)}</span>
        {count > 0 && (
          <div className="badge mx-1" style={{ background: colors.SECONDARY }}>
            {count}
          </div>
        )}
      </motion.div>
    </div>
  );
};

const AddButton = ({ ...props }) => {
  const loading = useSelector(recipeIsLoading);
  const category = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const recipeIds = useSelector(selectViews).map((view) => view.recipe.id);

  const handleAddButtonClick = async () => {
    // const index = recipeIds.length;
    // const recipe = await api.Pantry.fetchRandom(category, recipeIds);
    // dispatch(
    //   recipeFetched({
    //     category,
    //     recipe,
    //   })
    // );

    // let cpuWines = [];
    // try {
    //   cpuWines = await api.Sommelier.fetchWines(
    //     recipe.title,
    //     [recipe.steps],
    //     recipe.ingredients
    //   );
    //   console.log(cpuWines);
    //   cpuWines = cpuWines.map((w) => ({ ...w, feedback: feedback.NONE }));
    // } catch (error) {
    //   const { message } = await error.response.json();
    //   console.log(message);
    // }

    // dispatch(
    //   cpuWinesFetched({
    //     cpuWines,
    //     category,
    //     index,
    //   })
    // );

    dispatch(fetchRecipe(category));
  };

  return (
    <div
      // layout
      className="center-content"
      style={{ width: "100%", height: "50px" }}
    >
      <IconButton
        size="xs"
        disabled={loading}
        loading={loading}
        onClick={handleAddButtonClick}
        icon={<AiOutlinePlus size={17} />}
      >
        New Recipe
      </IconButton>
    </div>
  );
};

export default function Sidebar() {
  const categories = useSelector(selectCategories);
  const reviewCategories = categories.filter((c) => c.status === "review");
  const pairingCategories = categories.filter((c) => c.status === "pairing");

  const views = useSelector(selectViews);
  const activeCategory = useSelector((state) => state.category);

  const counts = useSelector(selectActiveCounts);
  const maxCounts = useSelector(selectActiveMaxCounts);

  const Progress = () =>
    counts && (
      <>
        <span className="text-xs text-center">
          {formatLabelStr(activeCategory)} recipes {counts}/300
        </span>
        <ProgressBar
          style={{ height: "6px", marginBottom: "30px" }}
          animated
          className="w-100 rounded-0 text-xxs"
          variant="secondary"
          now={(counts / 300) * 100}
        />
      </>
    );

  return (
    <div
      id="sidebar"
      className="text-xs position-fixed d-flex h-100"
      style={{ width: SIDEBAR_WIDTH + "px" }}
    >
      <div
        id="category-sidebar"
        key="category-sidebar"
        style={{
          height: "100%",
          width: "120px",
          backgroundColor: colors.BLACK,
          flexDirection: "column",
          color: "#e0e0e0",
          zIndex: 2,
        }}
      >
        <motion.div key="sss" {...spawnAnimation}>
          <SidebarHeader className="center-content mb-3">
            {/* <img width={82} src={Logo} style={{ objectFit: "contain" }} /> */}
          </SidebarHeader>
          {pairingCategories.length > 0 && (
            <div className="mb-5">
              <h5 className="px-2">PAIRING</h5>
              {pairingCategories
                .filter((c) => c.status === "pairing")
                .map(({ name, status }) => (
                  <CategoryRow key={"category_row_" + name} category={name} />
                ))}
            </div>
          )}
          {reviewCategories.length > 0 && (
            <div>
              <h5 className="px-2">REVIEW</h5>
              {reviewCategories.map(({ name, status }) => (
                <CategoryRow key={"category_row_" + name} category={name} />
              ))}
            </div>
          )}
        </motion.div>
      </div>
      {categories.length ? (
        <motion.div
          id="recipe-sidebar"
          key="recipe-sidebar"
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.75 }}
          style={{
            height: "100%",
            width: "180px",
            backgroundColor: "white",
            borderRight: "solid 1px " + colors.LIGHT,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            zIndex: 0,
          }}
        >
          <SidebarHeader className="d-flex justify-content-end flex-column"></SidebarHeader>
          <div
            style={{
              minHeight: "80px",
              // borderTop: "1px solid " + colors.OFF_WHITE,
              borderBottom: "1px solid " + colors.OFF_WHITE,
            }}
          >
            <motion.div {...spawnAnimation} key={activeCategory}>
              {views.length ? (
                views.map((view, i) => (
                  <RecipeRow
                    id={"rec_row_" + i}
                    key={"rec_row_" + i}
                    view={view}
                    index={i}
                  />
                ))
              ) : (
                <div
                  key="empty-msg"
                  className="p-2 center-content text-xs"
                  style={{
                    width: "100%",
                    color: colors.LIGHT,
                    textAlign: "center",
                    height: "50px",
                  }}
                >
                  Generate recipes by clicking the button below.
                </div>
              )}
              <AddButton key="sidebar-add-btn" />
            </motion.div>
          </div>
          <div className="flex-grow-1" />

          <AnimatePresence id="progress-tracker">
            {counts >= 0 && (
              <motion.div
                {...spawnAnimation}
                transition={{ duration: 0.2 }}
                className="px-1"
              >
                <div className="w-100 center-content">
                  <span className="text-xs text-center w-100">
                    {formatLabelStr(activeCategory)} recipes {counts}/
                    {maxCounts}
                  </span>
                </div>
                <ProgressBar
                  style={{ height: "6px", marginBottom: "10px" }}
                  animated
                  className="w-100 rounded-0 text-xxs"
                  variant="secondary"
                  now={(counts / maxCounts) * 100}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      ) : null}
    </div>
  );
}
