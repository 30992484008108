// export const OFF_WHITE = '#f0f0f0'
export default {
    OFF_WHITE: '#f0f0f0',
    LIGHT: '#e5e5e5',
    LIGHTER: '#fafafa',
    DARK: '#707070',
    DARKER: '#000000',

    PRIMARY: '#000000',
    PRIMARY_LIGHTER: '#eeeeee',

    SECONDARY: '#8c2359',
    SECONDARY_LIGHTER: '#f5d5e6',

    TERTIARY: '#E1BB80',

    BLACK: '#10141c',

    ACCEPTED: 'rgb(18, 163, 86)',
    ACCEPTED_BG: 'rgba(18, 163, 86, 0.1)',

    DECLINED: 'rgb(230, 20, 20)',
}