export const loadImage = (image) => {
  return new Promise((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = image;
    // wait 2 seconds to simulate loading time
    loadImg.onload = () =>
      setTimeout(() => {
        resolve(image);
      }, 1000);

    loadImg.onerror = (err) => reject(err);
  });
};

export const alkoLinkToImageUrl = (link) => {
  let productNumber = link.split('/')[5];
  let productName = link.split('/')[6];
  return `https://images.alko.fi/images/cs_srgb,f_auto,t_medium/cdn/${productNumber}/${productName}.jpg`;
};
