import _, { capitalize } from 'lodash';

export default [
  {
    name: 'ABBUOTO',
    synonyms: ['Aboto', 'Cecubo'],
    description:
      'Rare, central Italian variety producing plenty of alcohol, generally blended.',
    'origin and parentage': [
      'Abbuoto comes from the Frosinone area of Lazio, central Italy. The synonym Cecubo derives from the erroneous belief that this was the variety that produced the famous Caecubum, one of the best wines in Italy in Roman times, mentioned by Horace, Pliny the Elder and so on. However, Caecubum was a white wine, the name of a cru south east of Roma, and it had disappeared by the second century AD.',
      'Recent DNA parentage analysis has suggested that Abbuoto may be a natural PIEDIROSSO \u00d7 CASAVECCHIA\ncross (Cipriani et al. 2010) but this is contradicted by the inclusion of Abbuoto in Casavecchia\u2019s parentage.',
    ],
    'where its grown': [
      'Abbuoto, grown mainly in north-west and central Italy, particularly in Fiuggi, Sessa Aurunca, Fondi, Formia, Monte San Biagio and Terracina in Lazio, produces generous wines generally high in alcohol and with good ageing potential but which tend quickly to lose their colour. The Campanian producer Villa Matilde has about 2 ha (5 acres) of Abbuoto, which is the main component in their Cecubo blend, with PIEDIROSSO and TRIBIDRAG, known as Primitivo in Italy. There were 717 ha (1,772 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'ABOURIOU',
    synonyms: [
      'Beaujolais',
      'Early Burgundy',
      'Gamay Beaujolais',
      'Gamay-Saint-Laurent',
      'Loub\u00e9jac',
      'Malbec Argent\u00e9',
      'Plant Abouriou',
      'Pr\u00e9coce Naug\u00e9',
      'Pr\u00e9coce Noir',
      'Pressac de Bourgogne',
    ],
    description:
      'Declining, early-ripening south-west French variety making tannic red.',
    'origin and parentage': [
      'Abouriou, probably from around Viller\u00e9al, was widespread in the Lot-et-Garonne until the mid nineteenth century. It had almost disappeared when it was rescued by Numa Naug\u00e9, a vine breeder in S\u00e9n\u00e9zelles near Casseneuil (between Bordeaux and Toulouse), who presented it in 1882 as a chance seedling found by a local farmer some forty years earlier, growing up the wall of a ruined castle in the area of Viller\u00e9al (Lot-et-Garonne). That is why Abouriou, meaning \u2018early\u2019 in the local dialect, is also called Pr\u00e9coce Naug\u00e9.',
      'Abouriou has no relationship with GAMAY NOIR but DNA profiling has recently discovered a possible parent\u2013offspring relationship between Abouriou and Magdeleine Noire des Charentes, the mother of MERLOT and COT (Boursiquot et al. 2009). As a consequence, Abouriou is either a grandparent or a half-sibling of both Merlot and Cot (see PINOT pedigree diagram).',
      'Abouriou was used to breed EDERENA and EGIODOLA and was crossed with CASTETS in the 1970s in Slovakia to breed HRON, NITRANKA, RIMAVA and V\u00c1H.',
    ],
    'where its grown': [
      'Total plantings in France in 2008 were 338 ha (835 acres), about half what they were fifty years ago, mainly in the Lot-et-Garonne (200 ha/494 acres) and the Loire-Atlantique (100 ha/247 acres) d\u00e9partements and especially around Cocumont south west of Marmande. The wines are deeply coloured, spicy and tannic but lack acidity and the vine was valued mostly for its precocity and resistance to disease. The variety is authorized in the C\u00f4tes du Marmandais, where Elian Da Ros makes a varietal wine as well as including it in his Clos Baquey blend. Ch\u00e2teau Lassolle\u2019s Le Rouge qui Tache (meaning \u2018the red that stains\u2019) is another example and Abouriou is found in several south-western vins de pays.',
      'The variety once grown in California under the name Early Burgundy was identified by Paul Truel as identical to Abouriou, and Luddite make a varietal version from the Gibson-Martinelli vineyard in Russian River Valley. However, recent DNA analysis has shown that some Early Burgundy vines in California are actually BLAUER PORTUGIESER (Mike Officer, personal communication).',
    ],
  },
  {
    name: 'ABRUSCO',
    synonyms: [
      'Abrostalo',
      'Abrostine',
      'Abrostino',
      'Abrusco Nero',
      'Abrusio',
      'Colore',
      'Colorino',
      'Lambrusco',
      'Raverusto',
    ],
    description:
      'Obscure and endangered late-ripening Italian red adding colour to blends.',
    'origin and parentage': [
      'Abrusco is an ancient Tuscan variety used in Chianti blends. An ampelographic and DNA study carried out in 2009 at the University of Siena has shown it to be identical to Abrostine (Rita Vignani, personal communication), contrary to what had previously been published by Vignani et al. (2008). Soderini\u2019s (1600) treatise on grapevine cultivation mentions Abrostino or Colore, used even then to add colour to pale reds. Abrusco is distinct from COLORINO DEL VALDARNO, another Tuscan grape valued for its deep colour that is often cultivated with, and mistaken for, Abrusco/Abrostine. In addition, some sources have suggested that Muscat Rouge de Mad\u00e8re (not commercially cultivated) could also be a synonym for Abrusco but this has been disproved by DNA analysis (Vouillamoz). The names Abrusco and Abrostine supposedly derive from the Latin lambrusco, meaning \u2018wild vine\u2019.',
    ],
    'where its grown': [
      'Le Tre Stelle\u2019s Agino, an IGT Toscana, is the only 100% Abrusco in production. Twenty Abrusco plants were discovered in their old vineyards and the producer has established a vine adoption scheme to help save the variety. The 2000 census recorded just 6 ha (15 acres) in Italy under the name Abrusco, and Abrostino is mentioned only (and incorrectly) as a synonym for COLORINO DEL VALDARNO.',
      'Although the berries have pale flesh, they produce deeply coloured, well-structured, slightly spicy wines that are usually blended.',
    ],
  },
  {
    name: 'ACITANA',
    synonyms: ['Citana Nera'],
    description:
      'Rare dark-skinned variety from north-east Sicilia used in blends.',
    'origin and parentage': [
      'The variety and the name probably come from a group of communes north-east of Catania in Sicilia with names beginning with \u2018Aci\u2019: Aci Bonaccorsi, Acireale, Aci Castello, Aci Catena, Aci Platani, Aci San Filippo, Aci Sant\u2019Antonio, Aci Santa Lucia and Aci Trezza.',
    ],
    'where its grown': [
      'Very rare variety formerly cultivated in the provinces of Messina and Catania in north-east Sicilia, Italy. Today it is grown around Messina, where it finds its way into Palari\u2019s Faro blend (though the Faro regulations do not specifically authorize it). It is generally blended with NERELLO MASCALESE, NERELLO CAPPUCCIO and other local varieties.',
    ],
  },
  {
    name: 'ACOLON',
    synonyms: ['Weinsberg 71-816-102'],
    description:
      'Recently authorized German cross favoured for its colour and early ripening.',
    'origin and parentage': [
      'A BLAUFR\u00c4NKISCH \u00d7 DORNFELDER cross created at the Weinsberg research centre in Baden-W\u00fcrttemberg in Germany by Bernd Hill in 1971 and included on the official register in 2002.',
    ],
    'where its grown': [
      'Despite its short life on the official list, there are already 478 ha (1,181 acres) in Germany, just under half in W\u00fcrttemberg and about a quarter in the Pfalz. The wines are fairly similar to those of its parent BLAUFR\u00c4NKISCH (or Limberger): quite full-bodied with good fruit and moderate tannins. The more concentrated versions can benefit from restrained use of oak. Varietal wines are made by Medinger and Sturmfeder in W\u00fcrttemberg, Anselmann and Karl Dennhardt in the Pfalz, and Kochertalkellerei in Baden.',
      'There are also nearly 3 ha (7 acres) in Switzerland and the variety has been planted in England, eg at Biddenden Vineyards in Kent.',
    ],
  },
  {
    name: 'ADAKARASI',
    synonyms: ['Ada Karas\u0131'],
    description:
      'Vine grown mainly on the Turkish island of Av\u015fa, making soft, dark reds.',
    'origin and parentage': [
      'Adakaras\u0131, literally \u2018black of the islands\u2019, most likely originates from the Turkish island of Av\u015fa in the Sea of Marmara.',
    ],
    'where its grown': [
      'Adakaras\u0131 is found mainly on the island of Av\u015fa in the Sea of Marmara, Turkey, where there were 92 ha (227 acres) in 2010, and also to a very limited extent around Bal\u0131kesir in the province of the same name, in that part of the Marmara region south of the Black Sea, and in the \u015eark\u00f6y district of the Tekirda\u011f province on the Sea of Marmara. Wines are generally soft and dark but still have good ageing potential. Producers of varietal wines include Borta\u00e7\u0131na and B\u00fcy\u00fcl\u00fcba\u011f.',
    ],
  },
  {
    name: 'ADALMIINA',
    synonyms: ['Aldemina', 'ES 6-16-30'],
    description:
      'Minor but complex, cold-hardy American hybrid grown mostly in Qu\u00e9bec.',
    'origin and parentage': [
      'An Elmer Swenson 2-3-17 \u00d7 Elmer Swenson 35 hybrid created in the mid to late 1980s by grape breeder Elmer Swenson in Osceola, Wisconsin, US, in which:',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 2-3-17 is an Elmer Swenson 283 \u00d7 Elmer Swenson 193 hybrid',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 283 is an Elmer Swenson 114 \u00d7 SEYVAL BLANC hybrid (see ST PEPIN for the pedigree of Elmer Swenson 114)',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 193 is a Minnesota 78 \u00d7 Seneca hybrid (see BRIANNA for the complete pedigree of Minnesota 78)',
      '\u2013\u00a0\u00a0\u00a0Seneca is a LUGLIENGA \u00d7 Ontario hybrid (see CAYUGA WHITE for the pedigree of Ontario)',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 35 is a Minnesota 78 \u00d7 Dunkirk hybrid',
      '\u2013\u00a0\u00a0\u00a0Dunkirk is a Brighton \u00d7 Jefferson hybrid (see BRIANNA for the pedigree of Brighton)',
      '\u2013\u00a0\u00a0\u00a0Jefferson is a CONCORD \u00d7 Iona hybrid (see MOORE\u2019S DIAMOND for the pedigree of Iona).',
      'Adalmiina is therefore a complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis lincecumii and Vitis rupestris hybrid. In 1997 the variety was sent to Meeri Saario, a horticulturist then at the University of Helsinki, who named it Adalmiina and began to propagate it along with several other Swenson varieties.',
    ],
    'where its grown': [
      'Adalmiina is grown to a limited extent in Qu\u00e9bec, Canada, where it is generally used in blends to compensate for its low acidity. Producers of such blended wines include Le Chat Bott\u00e9, Coteau St-Paul, Domaine Mont V\u00e9zeau and Vignoble Le Nordet. Varietal wines have no foxy flavour and are not unlike Muscadet except in their slight lack of acidity, sometimes also with mineral and tropical-fruit notes.',
      'Meeri Saario and a few others in Finland and the Baltic States grow Adalmiina under plastic but for the table only.',
    ],
  },
  {
    name: 'ADDORACA',
    synonyms: ['Odoacra'],
    description:
      'Extremely rare Calabrian white that contributes to an equally rare dessert wine.',
    'origin and parentage': [
      'Addoraca comes from the province of Cosenza in Calabria, southern Italy. Its name means \u2018perfumed\u2019 in Calabrian dialect.',
    ],
    'where its grown': [
      'Extremely rare variety cultivated exclusively in the commune of Saracena in the province of Cosenza in Calabria, Italy. It is blended with CODA DI VOLPE BIANCA (under its Calabrian synonym Guarnaccia Bianca), MALVASIA BIANCA DI CANDIA and MUSCAT BLANC \u00c0 PETITS GRAINS (known locally as Moscatello di Saracena) in the historical Moscato di Saracena passito-style dessert wine made by a small number of producers such as Cantine Viola (supported by Slow Food) and Feudo dei Sanseverino.',
    ],
  },
  {
    name: 'AFFENTHALER',
    synonyms: [
      'Blauer Affenthaler',
      'Kleiner Trollinger',
      'S\u00e4uerlicher Burgunder',
    ],
    description: 'Very rare old variety from southern Germany.',
    'origin and parentage': [
      'Affenthaler is an old variety from Affental near Baden-Baden in Baden-W\u00fcrttemberg, southern Germany, recently resurrected from two old vines discovered in Kaisersbach and Steinheim in 2004. Affenthaler was first mentioned by Johann Michael Sommer in 1791 (Kr\u00e4mer 2006). It used to be cultivated along the Neckar, in Enztal, Remstal and Hohenlohe, together with BLAUFR\u00c4NKISCH (aka Limberger) and SCHIAVA GROSSA (aka Trollinger). That\u2019s probably why Affenthaler is sometimes called Kleiner Trollinger.',
      'Although it is also called S\u00e4uerlicher Burgunder (meaning \u2018acidic Pinot\u2019), DNA parentage analysis has shown that Affenthaler is not related to PINOT, but has a parent\u2013offspring relationship with GOUAIS BLANC (Boursiquot et al. 2004). Given Gouais Blanc\u2019s ancestry, Affenthaler is most likely a natural cross between Gouais Blanc and another, unknown (extinct?) variety.',
    ],
    'where its grown': [
      'Affenthaler\u2019s cultivation was almost abandoned after the Second World War and it has now become very rare even in its homeland of Baden-W\u00fcrttemberg in southern Germany. It produces dark, tannic wines with significant acidity. The Affenthaler Winzergenossenschaft B\u00fchl in Baden make one example.',
      'The variety should not be confused with a wine known as Affentaler or Affenthaler, a regional speciality of the area around Baden-Baden, made from Sp\u00e4tburgunder (aka PINOT NOIR).',
    ],
  },
  {
    name: 'AGIORGITIKO',
    synonyms: [
      'Aghiorghitiko',
      'Mavro Nemeas',
      'Mavrostaphylo Mavraki',
      'Mavroudi Nemeas',
      'Nemeas Mavro',
    ],
    description:
      'Greece\u2019s most widely planted dark-skinned variety, capable of both high volumes and high quality as well as a wide range of styles.',
    'origin and parentage': [
      'This variety probably comes from Argol\u00edda and Korinth\u00eda in the eastern part of the Pelop\u00f3nnisos (Peloponnese) in Greece. Agiorgitiko, literally meaning \u2018St George\u2019s grape\u2019, was apparently named after the tiny chapel of St George in Nem\u00e9a in the north-eastern part of the Pelop\u00f3nnisos (Manessis 2000) or after a village of that name.',
    ],
    'where its grown': [
      'Agiorgitiko is capable of making a wide range of styles in Greece, depending mainly on the vineyard location and the extent to which the yields are controlled \u2013 from fresh, fruity ros\u00e9 to concentrated, tannic and ageworthy reds, as well as just about everything in between, including light-bodied versions made by carbonic maceration and large volumes of easy-drinking soft reds. The most serious examples are deeply coloured, have aromas of spicy red fruits, marry well with oak and are rich and full-bodied without excessive alcohol. Vines planted on the valley floor may sometimes produce wines that are very soft, lacking in acidity and high in alcohol, which is why the most highly regarded sites are at higher altitudes, eg on the hillsides in Koutsi at around 500\u2013600 m (1,640\u20131,970 ft) or up to 900 m (2,950 ft) on the Asprokampos plateau. There is much current debate about how to incorporate some form of subregional classification into the existing legislation.',
      'In recent years growers have been held back by the fact that virtually all Agiorgitiko vines planted in Greece are virused, causing problems with yield, ripening and quality. Current research should result in new clones being available in 2012, clones that not only are virus-free and therefore able to attain higher levels of sugar and phenolics in the berries but also show preferred characteristics such as smaller berries with thicker skins that will have greater resistance to botrytis bunch rot.',
      'Agiorgitiko is the most widely planted red wine grape in Greece, found mainly in the Pelop\u00f3nnisos (3,204 ha/7,917 acres) and in Attik\u00ed on the southern mainland (5,202 ha/12,854 acres). It is the sole variety authorized for the Nem\u00e9a appellation in the north west of the Pelop\u00f3nnisos \u2013 for reds (both dry and sweet) but not for ros\u00e9 \u2013 and it has shown itself to be a good blending partner for CABERNET SAUVIGNON grown much further north in the Metsovo region in \u00cdpeiros (Epirus) on the mainland. Recommended producers in Nem\u00e9a include Driopi, Gaia, Parparoussis (based in P\u00e1tra but fruit from Gymno in Nem\u00e9a) and Skouras. While Agiorgitiko and Nem\u00e9a are virtually synonymous, the variety is also planted in much of the central and northern Pelop\u00f3nnisos, in Attik\u00ed to the east and Makedon\u00eda in the north of the country. Biblia Chora make a very good example in this last region.',
    ],
  },
  {
    name: 'AGLIANICO',
    synonyms: [
      'Aglianco di Puglia',
      'Aglianica',
      'Aglianichella',
      'Aglianichello',
      'Aglianico Amaro',
      'Aglianico del Vulture',
      'Aglianico di Castellaneta',
      'Aglianico di Taurasi',
      'Aglianico Nero',
      'Aglianico Pannarano',
      'Aglianicuccia',
      'Agliano',
      'Agliatica',
      'Agliatico',
      'Agnanico',
      'Agnanico di Castellaneta',
      'Cascavoglia',
      'Cerasole',
      'Ellanico',
      'Ellenico',
      'Fiano Rosso',
      'Fresella',
      'Gagliano',
      'Ghiandara',
      'Ghianna',
      'Ghiannara',
      'Glianica',
      'Gnanica',
      'Gnanico',
      'Granica',
      'Olivella di S. Cosmo',
      'Ruopolo',
      'Spriema',
      'Tringarulo',
      'Uva Catellaneta',
      'Uva dei Cani',
      'Uva di Castellaneta',
      'Uva Nera',
    ],
    description:
      'High-quality, late-ripening, tannic and ageworthy southern Italian red.',
    'origin and parentage': [
      'On the basis of arguable Greek etymology, many authors have suggested that Aglianico was introduced by the Greeks when they colonized the Tirrenian coast (seventh and sixth centuries BC). However, unlike the vast majority of sources, we do not support the hypothesis of a Greek origin for Aglianico, neither the name nor the grape.',
      'The earliest written mention of the name Aglianico appeared in 1520: an archive document testifies that Giulio Antonio Acquaviva d\u2019Aragona, Count of Conversano, owned fields planted with grapevines called Aglianiche (plural of Aglianico). The supposed Greek origin of Aglianico dates back to 1581, when the Neapolitan scholar Giambattista della Porta (1535\u20131615) tentatively identified the Helvola grape mentioned by Pliny the Elder with a hypothetical hellanico or hellenic grape. Subsequently, the shortcut Aglianico = hellanico = grape from Greece (Hellas) has been blindly assumed by most authors since the sixteenth century, which is revealed in the use of its modern synonyms Ellanico/Hellanico or Ellenico/Hellenico. However, this etymology is highly uncertain because:',
      '\u2013\u00a0\u00a0\u00a0Helvola was a yellowish wine, while Aglianico has black berries.',
      '\u2013\u00a0\u00a0\u00a0Linguists have established that it is impossible to obtain the word aglianico from ellenico or hellenico.',
      '\u2013\u00a0\u00a0\u00a0In Pliny the Elder\u2019s and other Roman authors\u2019 works, the adjective used to indicate a Greek origin was graecus and never hellenicus, not least because this adjective simply didn\u2019t exist at this time.',
      '\u2013\u00a0\u00a0\u00a0No Roman author ever used the name \u2018hellenic grapes\u2019, but always \u2018Greek grapes\u2019, because the concept of Hellenism came into use only at the Renaissance period to designate the Grecian realm or culture.',
      'The name Aglianico most likely derives from the Spanish llano, meaning \u2018plain\u2019, since the Spanish occupied central Italy during the fifteenth and sixteenth centuries, a period consistent with the first appearance of the name Aglianico. In addition, the Spanish lla is pronounced \u2018glia\u2019 in Italian. Thus, Aglianico would be \u2018the grape of the plain\u2019, which brings to mind its old dialect name A Glianica or La Glianica.',
      'Another less likely hypothesis suggests that the name Aglianico could come from the Greek aglaos, meaning \u2018beautiful\u2019 or \u2018bright\u2019, supposedly in contrast to other dark wines. Another even less likely hypothesis claims that the name Aglianico could come from the month of July (Luglio in Italian), when the grape ripens. However, this makes no sense given that Aglianico is a very late-ripening variety. Finally, little credibility is given to some attempts to derive Aglianico from Gauranum or Gauranicum, a type of Falerno wine described by Pliny the Elder and other Roman writers.',
      'DNA profiling has shown that Aglianico is not closely related to any modern Greek grape variety (Scienza and Boselli 2003). It has also shown that Aglianico is closely related to many varieties cultivated in Campania or Basilicata (Costantini et al. 2005), in particular to AGLIANICONE, of which Aglianico could be a parent (Vouillamoz).',
      'Neither linguistic nor genetic evidence supports a Greek etymology and origin for Aglianico. Therefore, it seems most likely that Aglianico is an ancient grape from southern Italy.',
    ],
    'where its grown': [
      'Aglianico is grown almost exclusively in southern Italy, where it is the most widespread black variety, in particular in the provinces of Avellino and Benevento in Campania, and in the provinces of Potenza and Matera in Basilicata, where it thrives in cool but dry and sunny mountainous conditions between 200 and 600 m above sea level. There\u2019s also a scattering of vines in Calabria, Puglia, Molise, and on the island of Procida near Napoli. Total vineyard plantings in 2000 were around 9,890 ha (24,400 acres).',
      'In the volcanic soils of Taurasi DOCG, north east of Avellino in Campania, and Aglianico del Vulture DOC, just over the border in Basilicata, the vine gives its best results: dark, concentrated and tannic wines that improve with age. The long ripening season is essential to tame these tannins. The best wines are deep in colour with aromas suggestive of the volcanic origin of the vineyards or of chocolate and plum. They have fine-grained tannins and marked acidity on the palate, becoming more subtle and tarry as they mature. The high levels of tannin and acidity have earned this variety the sobriquet \u2018Barolo of the south\u2019. Taurasi may include up to 15% of other permitted local red varieties whereas Aglianico del Vulture and the more recent Aglianico del Taburno DOC must be 100% Aglianico. Best producers include d\u2019Angelo, Galardi, Mastroberardino, Paternoster, Odoardi, Pietracupa and Villa Matilde.',
      'Aglicanico\u2019s love of the southern Italian climate had encouraged a handful of growers in Australia concerned by climate change to begin experimental plantings. Westend Estate in Griffith, New South Wales (owned by the Calabria family, descended from Italian immigrants who arrived in the late 1920s), have been particularly successful, winning the trophy for best red wine in the 2010 Australian Alternative Varieties Wine Show. It is also found in California, where Kenneth Volk in Paso Robles, for example, makes an Italianate but more rounded version.',
    ],
  },
  {
    name: 'AGNI',
    synonyms: ['PE-11/47'],
    description: 'Recent Czech cross producing unusually aromatic reds.',
    'origin and parentage': [
      'Agni is an ANDR\u00c9 \u00d7 IRSAI OLIV\u00c9R cross obtained recently by Jan Havl\u00edk, Franti\u0161ek Zatloukal and Ludv\u00edk Michlovsk\u00fd, grape breeders at the research centres of \u0160SV in Velk\u00e9 Pavlovice and of Pern\u00e1, both in southern Morava in the Czech Republic, and included in the official Czech register of varieties in 2001.',
    ],
    'where its grown': [
      'Agni produces full-bodied, fresh, aromatic wines with Muscat and rose-like aromas and red fruit flavours. It may have potential for dessert as well as dry red wines. Producers of varietal wines include Franti\u0161ek Foretn\u00edk, Michlovsk\u00fd and Jakub \u0160am\u0161ula. There were 6 ha (15 acres) in Morava in 2009, the majority in Mikulovsk\u00e1 in the south east of the Czech Republic.',
    ],
  },
  {
    name: 'A\u00cfDANI',
    synonyms: [
      'Adani',
      'Aedano Leyko',
      'A\u00efdani Aspro',
      'A\u00efdani Blanc',
      'A\u00efdani Lefko',
      'Aspaedano',
      'Aspra\u00efdano',
      'Moscha\u00efdano',
    ],
    description:
      'Minor, aromatic Greek variety that plays a small but significant role in the wines of Santor\u00edni.',
    'origin and parentage': [
      'A\u00efdani or A\u00efdani Aspro (aspro means \u2018white\u2019) comes from the Kykl\u00e1des (Cyclades) islands in Greece, possibly from Santor\u00edni, where it has traditionally been cultivated (Galet 2000).',
      'Contrary to local belief, recent genetic investigations have suggested that the black-berried A\u00efdani Mavro (mavro means \u2018black\u2019) is not a distinct variety but simply a colour mutation of A\u00efdani Aspro (or vice versa; Biniari and Stavrakakis 2007). This dark-skinned mutation is referred to by various synonyms such as Aedano Mavro, A\u00efdani Noir, A\u00efdano Mavro, Ithani Mavro, Mavra\u00efdano, Mayraedano and Santorin.',
    ],
    'where its grown': [
      'A\u00efdani of Greece produces wines marked by floral and sometimes tropical-fruit aromas but it is rarely found in a starring role. Lower in alcohol and acidity than ASSYRTIKO, its most common use is as a minor player, along with ATHIRI, to Assyrtiko\u2019s lead in the dry, steely, volcanic whites of Santor\u00edni, although it is also found on the smaller neighbouring islands of P\u00e1ros, N\u00e1xos and Amorg\u00f3s. It is also blended with Assyrtiko to produce Vinsanto, at its best \u2013 in the hands of producers such as Sigalas and Hatzidakis \u2013 one of the most concentrated and exotically complex styles of sweet wine made from grapes harvested late once they have started to shrivel and then further dried off the vine. Argyros Estate on Santor\u00edni make a rare dry varietal A\u00efdani using cryoextraction to concentrate the juice.',
      'There are very limited plantings of A\u00efdani Mavro on the island of P\u00e1ros, where Moraitis blend it with CABERNET SAUVIGNON or MANDILARIA. It is also found on neighbouring N\u00e1xos and Santor\u00edni and was traditionally used to make a sweet wine known as Liasto (Manessis 2000), meaning \u2018sun dried\u2019.',
    ],
  },
  {
    name: 'AIR\u00c9N',
    synonyms: [
      'Aid\u00e9n',
      'Blanc\u00f3n',
      'Burra Blanca',
      'Colgadera',
      'Forcallada',
      'Forcallat',
      'Forcallat Blanca',
      'Manchega',
      'Valdepe\u00f1era Blanca',
      'Valdepe\u00f1as',
    ],
    description:
      'Spain\u2019s most widely planted light-skinned variety making brandy and rather neutral, fresh white wines.',
    'origin and parentage': [
      'Air\u00e9n is a very old variety from the region of Cuenca in Castilla-La Mancha in central Spain, as suggested by the synonym Manchega.',
      'The name Forcallat Blanca is often used as a synonym for Air\u00e9n, especially in Catalunya, but it is also the name of a distinct variety from Valencia that is no longer cultivated commercially but has a unique DNA profile (Iba\u00f1ez et al. 2003), which means that it is not a white mutation of FORCALLAT TINTA from Castilla-La Mancha. It is nevertheless authorized in Vino de la Tierra Murcia in south-east Spain.',
      'Air\u00e9n has often been confused with the variety called LAIR\u00c9N in Andaluc\u00eda that was already mentioned by the Spanish agronomist Gabriel Alonso de Herrera (1470\u20131539) in 1513 in his description of varieties of Castilla, Extremadura and Andaluc\u00eda (Alonso de Herrera 1790), but DNA profiling has shown that Lair\u00e9n and Air\u00e9n have distinct DNA profiles (Iba\u00f1ez et al. 2003; Santana et al. 2010).',
    ],
    'where its grown': [
      'A staggering total of 284,623 ha (703,319 acres) of Spain was planted with Air\u00e9n in 2008, almost 26% of the national vineyard. This means that the variety is grown on more land than any vine variety in the world thanks to the low-density bushvine plantings in arid La Mancha, where the variety\u2019s drought resistance is a huge advantage and probably its saving grace. Nevertheless, recent vine-pull schemes aimed at reducing the European wine surplus have resulted in a reduction in the overall area \u2013 down from 305,000 ha (753,671 acres) in 2004. The vast majority of the vines (276,796 ha/683,978 acres) are in Castilla-La Mancha but there are also plantings in the autonomous communities of Madrid, Murcia and Andaluc\u00eda.',
      'Air\u00e9n plays a major role in the Spanish brandy industry and was traditionally blended with the red variety Cencibel (TEMPRANILLO) to produce light red wines in Valdepe\u00f1as and La Mancha, for example. Today, however, it is increasingly vinified as a rather neutral, crisp dry white, playing a similar role to France\u2019s Ugni Blanc (TREBBIANO TOSCANO). It also blends well with MACABEO and Malvar (LAIR\u00c9N). Producers who take the variety reasonably seriously include F\u00e9lix Sol\u00eds and Jos\u00e9 Mar\u00eda Gal\u00e1n Le\u00f3n in Valdepe\u00f1as; Bodegas 1890, Huertas and Pedro Luis Mart\u00ednez in Jumilla; and Evaristo Mateos, Naranjo and Santa Rita in La Mancha. The first white wine to carry the name Pesquera, Alejandro Fern\u00e1ndez\u2019 famous Ribera del Duero bodega, was Alejair\u00e9n, an oak-aged 100% Air\u00e9n from La Mancha.',
      'It is difficult to see why anyone would want to plant this variety outside Spain.',
    ],
  },
  {
    name: 'AKHTANAK',
    synonyms: ['2-18-23', 'Akchtanak', 'Haghtanak', 'Hakhtanak'],
    description: 'Versatile, late-ripening Armenian complex cross.',
    'origin and parentage': [
      'Akhtanak is a complex cross obtained in 1977 by P K Aivazyan at the Armenian viticultural research centre in Merdzavan, just west of Armenia\u2019s capital Yerevan, by crossing Sorok Lyet Oktyabrya with SAPERAVI, in which Sorok Lyet Oktyabrya is a Kopchak \u00d7 ALICANTE HENRI BOUSCHET cross, and Kopchak is a Moldovan variety that is no longer cultivated. The synonym Haghtanak means \u2018victory\u2019.',
    ],
    'where its grown': [
      'Akhtanak, one of Armenia\u2019s most promising new varieties, is grown in the Ararat region in westcentral Armenia and is used to produce a wide range of wine styles \u2013 dry, sweet, fortified and sparkling \u2013 as well as being grown for the table. Producers of semi-sweet, sweet and fortified wines include the Ijevan Wine Factory in Tavush and VAN 777 in Ararat.',
    ],
  },
  {
    name: 'ALADASTURI',
    synonyms: ['Aladastouri', 'Anadassaouli', 'Anadastouri'],
    description: 'Minor Georgian variety responsible for alcoholic reds.',
    'origin and parentage': [
      'Aladasturi originates from the Guria region on the Black Sea in western Georgia.',
    ],
    'where its grown': [
      'Aladasturi is planted mainly in Imereti in central western Georgia, producing wines that are typically high in alcohol and moderate in colour, often made as a ros\u00e9. Khareba produce a varietal example although the variety is often blended. There were 44 ha (109 acres) in Georgia in 2004.',
    ],
  },
  {
    name: 'ALARIJE',
    synonyms: [
      'Acer\u00eda',
      'Alarije Dorada',
      'Alarije Dorado',
      'Alarije Verdoso',
      'Ar\u00edn',
      'Ar\u00eds',
      'Barcelon\u00e9s',
      'Cagazal',
      'Coloraillo Gordo',
      'Coloraillo Pequeno',
      'Esclafacherri',
      'Malfar',
      'Malvas\u00eda',
      'Malvas\u00eda Riojana',
      'Pirul\u00e9s Dorada  and Pirul\u00e9s Verde',
      'Rojal',
      'Subirat',
      'Subirat Parent',
    ],
    description:
      'Light-skinned variety from south-west Spain travelling under many aliases.',
    'origin and parentage': [
      'Alarije is a very old variety from Extremadura in south-west Spain, where it was first mentioned in 1448 in the manuscript Libro de Oficios of the Real Monasterio de Santa Mar\u00eda de Guadalupe (Asensio S\u00e1nchez 2000) as the main white variety in the area. In 1513, Gabriel Alonso de Herrera described this variety under the synonym Alarize, using the plural Alarixes (Alonso de Herrera 1790): \u2018Las Alarixes son uvas que hacen las cepas altas, a manera de las Albillas .\u00a0.\u00a0. son uvas muy bermejas, y que las abejas las comen mucho\u2019 (\u2018The Alarixes are grapes that make tall vines, like the Albillos .\u00a0.\u00a0. the grapes are reddish and the bees eat them a lot\u2019).',
      'Although Alarije has several synonyms based on the name Malvasia, it is genetically quite distinct from the true Malvasias grown in other Spanish regions: MALVASIA DI LIPARI (under the names Malvas\u00eda de Sitges and Malvas\u00eda Rosado) and MALVAS\u00cdA DE LANZAROTE (Rodr\u00edguez-Torres et al. 2009).',
      'In Madrid, Alarije is sometimes called Torront\u00e9s or Turront\u00e9s, which is very confusing because DNA profiling has shown that, in Portugal, Torront\u00e9s is also the name for Arinto (ARINTO DE BUCELAS), DONA BRANCA, FERN\u00c3O PIRES and MALVASIA FINA (under the names Assario and Boal Branco; Lopes et al. 1999; Pinto-Carnide et al. 2003; Gago et al. 2009; Vouillamoz). The risk of confusion is further increased because TORRONT\u00c9S MENDOCINO, TORRONT\u00c9S RIOJANO and TORRONT\u00c9S SANJUANINO are distinct varieties in South America.',
      'DNA parentage analysis has recently shown that Alarije (under the synonym Subirat Parent) is the result of a cross between two Spanish table grapes: GIBI, once cultivated in Spain and the south of France, and PLANTA NOVA (under the synonym Tortoz\u00f3n) from the Valencia region (Lacombe et al. 2007). Since Gibi is also the likely mother of PEDRO XIM\u00c9NEZ (Vargas et al. 2007), Alarije and Pedro Xim\u00e9nez turn out to be half-siblings.',
    ],
    'where its grown': [
      'Alarije is recommended and grown in many parts of Extremadura in south-west Spain but especially around Ca\u00f1amero in the north east of the region. It is authorized in the Ribera del Guadiana appellation but is less widely planted than Pardina (CAYETANA BLANCA). Wines are yellowish green and have a propensity to oxidize so that they become golden as they age. They seem well suited to oak but are also used to make sweet and fortified wines. Official Spanish statistics do not seem to have caught up with DNA findings and record 30 ha (74 acres) of Alarije in Castilla-La Mancha (none in Extremadura) and 90 ha (222 acres) of Subirat Parent in Catalunya. According to Rioja\u2019s Abel Mendoza, possibly the only producer of a varietal Malvas\u00eda Riojana in the region, there are about 70 ha (173 acres) of Malvas\u00eda Riojana in Rioja but few producers pay it any attention. Abel Mendoza\u2019s is a golden, rich yet fresh, barrel-fermented version. In Catalunya, Jan\u00e9 Ventura includes Subirat Parent in his white Pened\u00e8s blend, along with XARELLO and other varieties, and it may be used in the production of Cava.',
    ],
  },
  {
    name: 'ALBALONGA',
    synonyms: ['W\u00fcrzburg B 51-2-1'],
    description:
      'Fresh and aromatically complex German cross planted and valued mainly in Rheinhessen.',
    'origin and parentage': [
      'A RIESLANER \u00d7 SILVANER cross, initially called W\u00fcrzburg B 51-2-1, obtained in 1951 by Hans Breider at the W\u00fcrzburg horticulture institute and included on the official register in 1971. Its name means \u2018long white\u2019, in reference to the shape of the bunches. Albalonga is genetically very similar to Silvaner since the latter appears twice in its pedigree.',
    ],
    'where its grown': [
      'Germany has a total of 13 ha (32 acres), mostly in Rheinhessen and the Pfalz. The wines range from medium to very sweet, retaining good acidity and tasting of exotic fruits. Sometimes the aromas are more floral and black-fruited. Wittmann in Westhofen, Rheinhessen, have championed the variety and regularly produce an impressive TBA version from grapes that ripen early. Krebs-Grode, also in Rheinhessen, produce another good varietal example.',
      'It is also planted to a very limited extent in England, at Bothy Vineyard in Oxfordshire.',
    ],
  },
  {
    name: 'ALBANA',
    synonyms: [
      'Albana a Grappolo Fitto',
      'Albana a Grappolo Lungo',
      'Albana della Forcella',
      'Albana di Romagna',
      'Albana Gentile',
      'Albana Grossa',
      'Albanone',
      'Ribona',
      'Rimin\u00e8se',
      'Sforcella',
    ],
    description:
      'Ancient but inherently neutral Italian variety that can produce very good sweet wines.',
    'origin and parentage': [
      'From Emilia-Romagna in central Italy, including the provinces of Bologna, Ravenna and Forl\u00ec-Cesena, this variety has long been widely cultivated in different places under various names, as suggested by its long list of synonyms. The earliest mention of Albana is the Italian jurist and oenophile Pietro de Crescenzi\u2019s reference to it in c. 1305 as a white-berried variety producing powerful Romagna wine, the best being made in Forl\u00ec. The name Albana might derive from the Alban Hills (Colli Albani), a volcanic hill complex in Lazio, south of Roma. This etymology seems more likely than the simplistic derivation from the Latin word alba, meaning \u2018white\u2019. Recent DNA profiling has confirmed that the variety cultivated on the French island of Corse under the name Rimin\u00e8se is Albana and parentage analysis has shown that Albana has a parent\u2013offspring relationship with GARGANEGA, one of the most widespread and ancient white varieties of Italy (Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008). Since Garganega also has parent\u2013offspring relationships with eight other varieties (CATARRATTO BIANCO, DORONA DI VENEZIA, MALVASIA BIANCA DI CANDIA, MARZEMINA BIANCA, MONTONICO BIANCO, MOSTOSA, SUSUMANIELLO and TREBBIANO TOSCANO), Albana is either their half-sibling or their grandparent (see Garganega for an illustrated pedigree).',
      'Albana has several clones, the most widespread ones being Albana Gentile di Bertinoro, followed by Albana della Serra or Albana della Forcella, Albana della Compadrana, Albana della Bagarona and Albana della Gaiana. However, some of these may be the result of seed propagation, which means they are not true Albana vines but offspring of Albana. DNA profiling has not yet been applied to these clones.',
    ],
    'where its grown': [
      'According to the agricultural census in Italy, in 2000 total plantings were 2,800 ha (6,900 acres), mainly in Emilia-Romagna but also in La Spezia (Liguria) and Mantova (Lombardia), though the area has declined considerably since then. This has led the town of Imola in the province of Bologna to initiate a conservation programme involving the evaluation and promotion of old clones. The best Albana comes mainly from the red clay soils of the hills between Faenza and the River Ronco to the east of Forl\u00ec, together with the calcareous subzone of Bertinoro.',
      'Albana doesn\u2019t have that much flavour but its thin yet resistant skins make it a ready candidate for drying and it is used to produce wines of varying degrees of sweetness, from dry to a sweet passito version made from grapes dried on or off the vine. The sweet wines are the most promising and some producers have experimented with botrytized and barrel-aged versions. Fattoria Zerbina\u2019s Scaccomatto is a good example of the passito style. There\u2019s also a sparkling Albana Spumante. Albana di Romagna was the first Italian white wine to be promoted to DOCG status, in 1986 \u2013 a surprise to all but close students of vini-politics.',
      'Rimin\u00e8se has virtually disappeared from the French island of Corse but it is still included in Comte Abbatucci\u2019s Cuv\u00e9e du G\u00e9n\u00e9ral blend.',
    ],
  },
  {
    name: 'ALBANELLO',
    synonyms: ['Albanello di Siracusa'],
    description: 'Disappearing Sicilian variety once used for sweet wines.',
    'origin and parentage': [
      'Albanello is one the most ancient white wine varieties in Sicilia, historically cultivated in the provinces of Siracusa, Ragusa, Caltanissetta and Catania. Its sweet wine, obtained from grapes sun-dried on mats for several days, was already rare and expensive in the eighteenth century. Albanello is similar to but distinct from ALBANELLA from Pesaro (in the Marche, Italy) and is also morphologically similar to ALBARANZEULI BIANCO from Sardegna.',
    ],
    'where its grown': [
      'The late-ripening Albanello is slowly disappearing but in 2000 was still cultivated in Italy on 125 ha (309 acres) in the Sicilian provinces of Siracusa, Caltanissetta and Enna, where it was once used to produce Ambrato di Comiso, a Marsala-like wine abandoned many years ago (Alla 2003). Albanello is part of the light dry blend that makes up Eloro Bianco DOC, together with CATARRATTO BIANCO, GRILLO, etc.',
    ],
  },
  {
    name: 'ALBARANZEULI BIANCO',
    synonyms: ['Laconari', 'Lacconargiu', 'Lacconarzu'],
    description: 'Virtually extinct white grape from Sardegna.',
    'origin and parentage': [
      'Like many Sardinian varieties, Albaranzeuli Bianco may have been introduced from Spain during Spanish rule of the island (1323\u20131720), as suggested by its Spanish-sounding name and the confusion with ALBILLO REAL. However, its Spanish origin has recently been challenged: Cipriani et al. (2010) have suggested through DNA parentage analysis that Albaranzeuli Bianco is a possible natural cross between GIR\u00d2 and the table grape Molinera (under the name Panse Rosa di M\u00e1laga). This parentage showed some DNA discrepancies and needs further investigation. A possible genetic link between Albaranzeuli Bianco and ALBARANZEULI NERO as well as ALBANELLA has been suggested but not yet confirmed.',
    ],
    'where its grown': [
      'Albaranzeuli Bianco is on the brink of extinction in Italy and is cultivated only with other local varieties in old vineyards in the Nuoro and Oristano provinces of Sardegna, where it is called Laconari, Lacconargiu or Lacconarzu. Usually blended with other varieties, Albaranzeuli Bianco is authorized in several IGT wines (dry and sparkling). There were just 75 ha (185 acres) remaining in 2000.',
    ],
  },
  {
    name: 'ALBARANZEULI NERO',
    synonyms: ['Albarenzelin Nero', 'Uva Melone'],
    description:
      'A pinky-red-skinned Sardinian variety still very occasionally used in blends.',
    'origin and parentage': [
      'Like many Sardinian varieties, Albaranzeuli Nero may have been introduced from Spain during Spanish rule (1323\u20131720), as suggested by its Spanish-sounding name. A possible genetic link with the white ALBARANZEULI BIANCO has not yet been studied.',
    ],
    'where its grown': [
      'Recommended in the province of Nuoro but it is little planted and always blended with other varieties. It is mostly used to produce ros\u00e9 wines and has typically moderate levels of alcohol and acidity. In 2000 there were just 42 ha (104 acres) left in Italy.',
    ],
  },
  {
    name: 'ALBAR\u00cdN BLANCO',
    synonyms: [
      'Blanco Leg\u00edtimo',
      'Blanco Pa\u00eds',
      'Blanco Verd\u00edn',
      'Branco Lex\u00edtimo',
      'Raposo',
      'Tinta Fina',
    ],
    description: 'Very minor variety from the far north of Spain.',
    'origin and parentage': [
      'Albar\u00edn Blanco is a traditional variety from Asturias in the far north west of Spain, also known in Galicia in the north west under various synonyms. Comparison of the DNA profiles of Albar\u00edn Blanco (Mart\u00edn et al. 2006; Gago et al. 2009; Santana et al. 2010) and Blanco Lex\u00edtimo (Vilanova et al. 2009) from Betanzos in north-western Spain shows that they are identical varieties but the name Blanco Lex\u00edtimo, or Branco Lex\u00edtimo in Galician, is given to more than one variety in Galicia (Jes\u00fas Yuste Bomb\u00edn, personal communication).',
      'DNA parentage analysis has suggested that Albar\u00edn Blanco and Budelho (also known as Pedro Ximenes Canario but no longer cultivated) are siblings (Santana et al. 2010).',
      'Albar\u00edn Blanco has been confused with Albari\u00f1o (ALVARINHO; Galet 2000) and with ALBILLO REAL but DNA profiling has shown them to be distinct varieties (Iba\u00f1ez et al. 2003). In addition, Albar\u00edn Blanco should not be confused with ELBLING or GOUAIS BLANC, even though all three have the synonym Blanco Verdin in the Vitis International Variety Catalogue. Like Albari\u00f1o, Albar\u00edn Blanco has been and still is mistaken for SAVAGNIN BLANC (introduced to Galicia after the phylloxera epidemic in the late nineteenth century) due to a labelling or identification error in Misi\u00f3n Biol\u00f3gica de Galicia\u2019s collection (Santiago et al. 2005).',
      'Albar\u00edn Negro from the Asturias is not a colour mutation of Albar\u00edn Blanco but rather a synonym for ALFROCHEIRO from Portugal (Gonz\u00e1lez-Andr\u00e9s et al. 2007).',
    ],
    'where its grown': [
      'Albar\u00edn Blanco is still planted to a limited extent in Asturias in north-west Spain. It is provisionally included in the official Spanish register of commercial varieties and recommended in Vino de la Tierra de Cangas. There were about 40 ha (99 acres) around Cangas del Narcea in 2008. Wines can be floral and spicy like Moscatel but the acidity is higher and there are fewer phenolics in the skins. Pardevalles make a varietal version in Tierra de Le\u00f3n, where Albar\u00edn Blanco is recommended, and Gordonzello, in the same region, made their first varietal wine in 2011.',
      'Under the Galician name Branco Lex\u00edtimo, the variety is authorized in Betanzos but it tends to be used in blended bulk wines for strictly local consumption.',
    ],
  },
  {
    name: 'ALBAROLA',
    synonyms: ['Bianchetta Genovese', 'Calcatella'],
    description: 'Neutral white grape of Liguria.',
    'origin and parentage': [
      'Several authors locate its origin in Val Polcevera on the Ligurian coast west of Genova in north-west Italy. Morphological and DNA studies have shown that Bianchetta Genovese is identical to Albarola, and that in the region of Lavagna a mysterious and local Albarola has a unique DNA profile (Botta, Scott et al. 1995; Torello Marinoni, Raimondi, Ruffa et al. 2009).',
    ],
    'where its grown': [
      'This late-ripening variety is called Bianchetta Genovese in the neighbourhood of Genova and Albarola along the rest of Italy\u2019s Ligurian coast. Albarola used to be widely cultivated from Liguria all the way down to Toscana, for table grapes as well as for wine, but is now found only on the Ligurian coast, where it is blended with BOSCO and VERMENTINO in the highly soughtafter Cinque Terre Sciacchetr\u00e0 dessert wine, such as that made by Possa. It is the major grape in Bianchetta del Golfo di Tigullio DOC (Vini Bisson produce a varietal version) and it is authorized in DOCs such as Cinque Terre, Candia dei Colli Apuani and Colline di Levanto. Total vineyard area was 470 ha (1,160 acres) in 2000.',
    ],
  },
  {
    name: 'ALBAROSSA',
    synonyms: ['Incrocio Dalmasso XV/31'],
    description:
      'Minor Piemontese dark-skinned cross recently replanted by Michele Chiarlo.',
    'origin and parentage': [
      'From the Veneto in northern Italy. Italian vine breeder Giovanni Dalmasso claimed that Albarossa was a NEBBIOLO \u00d7 BARBERA cross made in 1938 at the Conegliano research centre with the aim of creating a variety that combined the quality of Nebbiolo with the resistance and high yields of Barbera. However, DNA profiling has recently shown that the Nebbiolo used by Dalmasso was in fact Nebbiolo di Dronero, which is not Nebbiolo at all but a synonym for CHATUS (Torello Marinoni, Raimondi, Ruffa et al. 2009). Albarossa is therefore a Chatus \u00d7 Barbera cross, just like its siblings CORNAREA, NEBBIERA, SAN MICHELE and SOPERGA. Albarossa entered the Italian national register of varieties in 1977.',
    ],
    'where its grown': [
      'Total plantings were fewer than 10 ha (25 acres) in Italy in 2000, although the Piemonte producer Michele Chiarlo recently planted 1 ha and made his first varietal Albarossa in 2006.',
    ],
  },
  {
    name: 'ALBILLO MAYOR',
    synonyms: ['Albilla', 'Pardina', 'Turruntes', 'Turrunt\u00e9s'],
    description:
      'Ribera del Duero variety used mostly in blends and now partly disentangled from the Albillo confusion.',
    'origin and parentage': [
      'The name Albillo has been applied to a wide range of distinct and unrelated varieties (see ALBILLO REAL). Among those with a unique DNA profile, only Albillo Real and Albillo Mayor are cultivated. The latter comes from Ribera del Duero in north-central Spain.',
      'According to Santana et al. (2010), Albillo Mayor most likely has a parent\u2013offspring relationship with TEMPRANILLO from Rioja and DORADILLA from M\u00e1laga.',
    ],
    'where its grown': [
      'In 2008 there were 1,443 ha (3,566 acres) in Spain, all in Castilla y Le\u00f3n. This is one of the few Albillo Somethings that have been identified but it is still likely to be confused with ALBILLO REAL since this (and other varieties called Albillo Something) is often referred to simply as Albillo. At the moment, neither DO regulations nor wine labels distinguish between Albillo Mayor and Albillo Real (and it is therefore impossible to recommend specific wines or producers). Albillo Mayor produces wines that are typically golden yellow, low in acidity, aromatic, fairly alcoholic, full-bodied and used mainly in blends, eg with the more acidic MACABEO.',
      'Both Albillo Mayor and ALBILLO REAL have been planted in California\u2019s San Joaquin Valley as part of a University of California at Davis field trial to identify new varieties that could thrive in the state.',
    ],
  },
  {
    name: 'ALBILLO REAL',
    synonyms: ['Albillo', 'Albillo de Cebreros', 'Albillo de Madrid'],
    description:
      'Aromatic Spanish variety producing smooth wines that are used mostly in blends, both red and white.',
    'origin and parentage': [
      'The name Albillo was mentioned by the Spanish agronomist Gabriel Alonso de Herrera (1470\u20131539) as early as 1513 in his description of the varieties of Castilla, Extremadura and Andaluc\u00eda (Alonso de Herrera 1790), under the plural form Albillas (see ALARIJE for the full citation). Indeed, several distinct varieties have been named Albillo (Cervera et al. 2001), derived from alba, meaning \u2018white\u2019, and significant confusion reigns over Albillo\u2019s identity, as demonstrated by recent DNA profiling studies:',
      '\u2013\u00a0\u00a0\u00a0Albilla from Guadalajara in Iba\u00f1ez et al. (2003) is identical to Malvasia Rei (PALOMINO FINO) in Lacombe et al. (2007)',
      '\u2013\u00a0\u00a0\u00a0an accession called Albillo in Iba\u00f1ez et al. (2003) is identical to ALBILLO DE ALBACETE in Mart\u00edn et al. (2003), a variety from the province of Albacete in Castilla-La Mancha that has a unique DNA profile but is no longer cultivated',
      '\u2013\u00a0\u00a0\u00a0accessions called Albillo from Salamanca in Iba\u00f1ez et al. (2003) and Albillo Negro from Cigales in Santana et al. (2007) turned out to be identical to CHASSELAS',
      '\u2013\u00a0\u00a0\u00a0accessions called Albillo in Iba\u00f1ez et al. (2003) and in Santana et al. (2010) correspond to Albillo Real in Santana et al. (2007)',
      '\u2013\u00a0\u00a0\u00a0Albillo Blanco from Vizcaya in Iba\u00f1ez et al. (2003) has a unique DNA profile',
      '\u2013\u00a0\u00a0\u00a0Albillo Criollo from the Islas Canarias has a unique DNA profile (Jorge Zerolo Hern\u00e1ndez, personal communication; Vouillamoz)',
      '\u2013\u00a0\u00a0\u00a0accessions called Albillo Real in Iba\u00f1ez et al. (2003) and in Mart\u00edn et al. (2003) correspond to Albillo Real Extremadur, a variety from the Valle del Ti\u00e9tar and Zamora but no longer cultivated, in Santana et al. (2010)',
      '\u2013\u00a0\u00a0\u00a0ALBILLO MAYOR from several sources (Iba\u00f1ez et al. 2003; Mart\u00edn et al. 2003; Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007; Santana et al. 2010) has a unique DNA profile',
      '\u2013\u00a0\u00a0\u00a0Albillo de Nava from Rueda in Yuste et al. (2006) and in Santana et al. (2007) is in fact VERDEJO',
      '\u2013\u00a0\u00a0\u00a0Albillo di Toro found in Tunisia was identified as MUSCAT OF ALEXANDRIA (Di Vecchi Staraz, This et al. 2007).',
      'Among the varieties listed above that were not identified with another well-known variety, only Albillo Real, from the Valladolid area in north-central Spain, and ALBILLO MAYOR, from Ribera del Duero, from Madrid and from Toledo in Castilla y Le\u00f3n, are still cultivated. Since only Albillo Real is referred to as simply Albillo, it seems likely that most references to Albillo since Alonso de Herrera in 1513 designate Albillo Real (Cervera et al. 2001) from Valladolid.',
      'According to Schneider et al. (2010), Albillo Real could have a parent\u2013offspring relationship with Legiruela, a variety from the Provincia de \u00c1vila in Castilla y Le\u00f3n that unexpectedly turned out to be identical to PRI\u00c9, cultivated over centuries in the Valle d\u2019Aosta, which makes it a half-sibling (or grandchild or grandparent) of LAIR\u00c9N, LUGLIENGA, MAYOLET and PRIMETTA (see the complete pedigree of PRI\u00c9). Albillo Real may also be genetically close to PARELLADA (Iba\u00f1ez et al. 2003).',
    ],
    'where its grown': [
      'Until the middle of the last century, Albillo was used mainly for the table but now it is also used to make soft, aromatic golden wines with a slightly bitter aftertaste, sometimes fermented in barrique but not generally for long ageing. It may also be used to add perfume to blended wines. Official wine-grape statistics in Spain perpetuate the confusion described above by listing not only Albillo Real (415 ha/1,025 acres, all in Castilla y Le\u00f3n) and ALBILLO MAYOR (1,443 ha/3,566 acres, also in Castilla y Le\u00f3n) but also plain old Albillo (893 ha/2,207 acres, mostly in Madrid but also in Castilla-La Mancha, Extremadura, Islas Canarias and Galicia). As suggested above, we can only assume that the figure for Albillo refers to Albillo Real and/or any of the others described above. Similarly, the Wines from Spain website lists only Albillo, describing it as rather neutral but with a smooth texture from high levels of glycerol, and the regulations for the various DOs in which Albillo is a principal (Vi\u00f1os de Madrid) or permitted (Ribeiro and Ribera del Duero) ingredient specify nothing more precise than \u2018Albillo\u2019.',
      'In Ribera del Duero, a very small amount of Albillo is sometimes blended with TEMPRANILLO, eg in Reyes\u2019 T\u00e9ofilo Reyes Reserva (2%) and in Bodegas del Campo\u2019s Pagos de Quintana Reserva (5%). Lezcano-Lacalle do the same thing in Cigales. Recommended producers of white blends containing Albillo (most likely Albillo Real) include Quinta de la Quietud in Toro, as well as Vi\u00f1o Mein and Manuel Formigo de la Fuente further north in Galicia\u2019s Ribeiro. Albillo wines are soft and aromatic.',
      'Both ALBILLO MAYOR and Albillo Real have been planted in California\u2019s San Joaquin Valley as part of a University of California at Davis trial to identify new varieties that could thrive in the state.',
    ],
  },
  {
    name: 'ALCA\u00d1\u00d3N',
    synonyms: ['Blanco Castellano', 'Bobal Blanca'],
    description:
      'Rare variety producing light but characterful whites in Somontano.',
    'origin and parentage': [
      'Originating from the Somontano area in north-east Spain, Alca\u00f1\u00f3n is widely considered to be identical to MACABEO, but a comparison of the DNA profiles of Alca\u00f1\u00f3n (Mart\u00edn et al. 2003; Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007) and Macabeo (Iba\u00f1ez et al. 2003; Mart\u00edn et al. 2003) clearly shows that they are distinct varieties (Vouillamoz). In addition, the DNA profile of Blanco Castellano in Iba\u00f1ez et al. (2003) matches Alca\u00f1\u00f3n\u2019s (Vouillamoz), which means that Blanco Castellano cultivated in Castell\u00f3 in Valencia and Alca\u00f1\u00f3n are probably one and the same. A possible genetic link with ZALEMA from Andaluc\u00eda, south-west Spain, has been suggested (Iba\u00f1ez et al. 2003).',
    ],
    'where its grown': [
      'According to official statistics, there were just 11 ha (27 acres) of Alca\u00f1\u00f3n in Arag\u00f3n in northeastern Spain in 2008, but since producers in Somontano (and further afield) seem frequently to confuse MACABEO with Alca\u00f1\u00f3n, it is hard to tell who has the real thing. Wines are light and characterful but increasingly rare.',
    ],
  },
  {
    name: 'ALEATICO',
    synonyms: [
      'Aleaticu',
      'Hal\u00e1pi',
      'Moscatello Nero',
      'Moscato Nero',
      'Vernaccia di Pergola',
      'Vernaccia Moscatella',
    ],
    description: 'Unusually perfumed and Muscat-like Italian red.',
    'origin and parentage': [
      'Native to Toscana in Italy, whence it has spread to southern Italy. The name Aleatico might derive from Luglio (Italian for \u2018July\u2019), the month in which the berry matures.',
      'DNA profiling has shown that it is not the same as the modern Greek variety LIATIKO (Vouillamoz) and does not have Greek origins. In fact, DNA paternity testing recently demonstrated that Aleatico is not a dark-berried mutant of MUSCAT BLANC \u00c0 PETITS GRAINS but instead is either a parent or a progeny of it (Crespan and Milani 2001). It also showed that Aleatico is genetically close to GRECO (aka Greco di Tufo of Campania; Costantini et al. 2005). DNA tests also proved that Aleatico is the same as Moscatello Nero (Filippetti et al. 2002) and the same as Vernaccia di Pergola (Crespan et al. 2003), named after the village of Pergola in the province of Pesaro in the Marche. More surprisingly, comparison of several DNA profiling sources shows that the obscure Hungarian variety Hal\u00e1pi is identical to Aleatico (Vouillamoz). In addition, DNA comparative studies showed that Aleatico might be genetically close to LACRIMA DI MORRO D\u2019ALBA, SANGIOVESE and GAGLIOPPO (Filippetti, Silvestroni, Thomas and Intrieri 2001).',
      'Aleatico was used to breed BESSARABSKY MUSKATNY and PERVOMAISKY.',
    ],
    'where its grown': [
      'Aleatico has a strong Muscat flavour and produces a generally pale but fine, unusually fragrant red wine.',
      'In Italy it\u2019s grown mainly in Lazio, in the Tuscia area, and also in Puglia. It also has a long tradition of making sweet, grapey red on the island of Elba. Under the name Vernaccia di Pergola, the grape almost disappeared from the province of Pesaro in the Marche but is now undergoing a revival thanks to the efforts of local viticulturists and scholars at Ascoli Piceno and is producing the locally acclaimed Pergola DOC. The 2000 Italian agricultural census shows total plantings of just over 500 ha (1,235 acres).',
      'Formerly widespread on the island of Corse, France, today it is grown mainly near Aleria (10 ha/25 acres), and is authorized in Vin de Pays de l\u2019\u00cele de Beaut\u00e9. Aleatico is also planted in California (mainly in Sonoma, for late-harvest wines) and, less predictably, in Kazakhstan and Uzbekistan.',
    ],
  },
  {
    name: 'ALEKSANDROULI',
    synonyms: ['Aleksandroouly', 'Aleksandrouli Shavi', 'Alexandrouli'],
    description:
      'Rare but highly promising Georgian variety often late harvested for violet-scented semi-sweet wines.',
    'origin and parentage': [
      'Aleksandrouli comes from the Racha-Lechkhumi region in north-western Georgia. Although it is also known as Kabistoni, Aleksandrouli is not the black-skinned version of KAPISTONI TETRI, as shown by morphological and genetic studies (Chkhartishvili and Betsiashvili 2004; Maghradze et al. 2009).',
    ],
    'where its grown': [
      'Aleksandrouli is the main variety used to produce the distinctive, late-harvest, violet-scented, naturally semi-sweet wines entitled to the Khvanchkara appellation in Georgia, as well as for dry red wines that typically have flavours of cherry and mulberry as well as that marked floral aroma. For both, Aleksandrouli is often blended with MUJURETULI. There were 163 ha (403 acres) in Georgia in 2004, almost all in Racha-Lechkhumi and especially on the right bank of the Rioni River in villages such as Khvanchkara, Chorjo, Bostana, Chrebalo, Joshkhi, Sadmeli and Tola. Recommended producers include Bugeuli and Rachuli Wine.',
    ],
  },
  {
    name: 'ALEXANDER',
    synonyms: [
      'Alexandria',
      'Black Cape',
      'Buck Grape',
      'Cape',
      'Clifton\u2019s Constantia',
      'Clifton\u2019s Lombardia',
      'Columbian',
      'Constantia',
      'Farkers Grape',
      'Madeira of York',
      'Rothrock',
      'Rothrock of Prince',
      'Schuylkill',
      'Schuylkill Muscadel',
      'Schuylkill Muscadine',
      'Springmill Constantia',
      'Tasker\u2019s Grape',
      'Vevay',
      'Vevay Winne',
      'Winne',
      'York Lisbon',
    ],
    description: 'American hybrid of purely historical significance in the US.',
    'origin and parentage': [
      'This variety was the earliest named American hybrid and was used to make the first commercial wines in the United States. It is a spontaneous hybrid between Vitis labrusca and Vitis vinifera that was found growing in the woods along the Schuylkill River in the vicinity of Springettsbury, just north west of Philadelphia, Pennsylvania, around 1740 by James Alexander, then gardener to Governor Thomas Penn (although some accounts suggest it was named after his son, John Alexander, gardener to Governor John Penn). It was found near a vineyard planted in the early 1680s with vines from Europe by Andrew Doz for William Penn, hence the vinifera parent. About fifteen years later, probably in 1756, Colonel Benjamin Tasker Jr planted it on his sister\u2019s estate in Prince George\u2019s County, Maryland, whence it quickly spread to other regions, thanks to Tasker\u2019s successful wine (Pinney 1989). According to Galet (2000), it was planted in Indiana in the early nineteenth century but was gradually replaced by the more disease-resistant CATAWBA.',
    ],
  },
  {
    name: 'ALFROCHEIRO',
    synonyms: [
      'Albar\u00edn Negro',
      'Albar\u00edn Tinto',
      'Alfrocheiro Preto',
      'Alfrucheiro',
      'Baboso Negro',
      'Bastardo Negro',
      'Bru\u00f1al',
      'Cai\u00f1o Gordo',
      'Tinta Bastardinha',
      'Tinta Francesa de Viseu',
    ],
    description:
      'Portuguese variety with intense fruit aromas and high quality potential.',
    'origin and parentage': [
      'Alfrocheiro, also known as Alfrocheiro Preto (preto means \u2018black\u2019 in Portuguese), originates from the D\u00e3o region of central Portugal or from the Alentejo further south. Because it shows very little morphological diversity, Alfrocheiro is thought to be a relatively young variety, perhaps introduced to Portugal during the post-phylloxera replanting at the beginning of the twentieth century (Rolando Faustino, personal communication). However, recent DNA parentage studies have shown that Alfrocheiro has its roots deep in Iberian soil:',
      '\u2013\u00a0\u00a0\u00a0CORNIFESTO, MALVASIA PRETA, CAMARATE and CASTEL\u00c3O from Portugal as well as JUAN GARC\u00cdA from Spain (also found in Portugal under the name Gorda or Tinta Gorda) are all sibling varieties born from natural crosses between Alfrocheiro and CAYETANA BLANCA (Zinelabidine et al. 2012), the latter a white-berried variety that is widespread in the southern central Iberian peninsula (see Cayetana Blanca for the pedigree diagram).',
      '\u2013\u00a0\u00a0\u00a0It is genetically close to TROUSSEAU (under the name Bastardo), a French variety that was introduced to the Iberian peninsula a long time ago (Almadanim et al. 2007). This relationship explains why the two varieties are sometimes confused, Alfrocheiro Preto often being called Bastardo Negro.',
      '\u2013\u00a0\u00a0\u00a0It is a possible sibling of PRIETO PICUDO from Tierra de Le\u00f3n in Spain (Santana et al. 2010).',
      '\u2013\u00a0\u00a0\u00a0It is also genetically close to CARRASQU\u00cdN from Asturias in the far north west of Spain (Mart\u00edn et al. 2006).',
      'The name Tinta Francesa, still in use in the Viseu region, might indicate that this variety was introduced from France, which would be consistent with the genetic relationship with TROUSSEAU, but there is no other historical background to confirm this origin.',
    ],
    'where its grown': [
      'Alfrocheiro was until recently grown mainly in D\u00e3o, central Portgual, and to a lesser extent in the Alentejo. However, appreciation of its quality and potential has encouraged growers elsewhere to try their luck with this variety \u2013 in the Bairrada and Tejo regions, for example. There were 1,492 ha (3,687 acres) in Portugal in 2010. Wines are richly coloured, show good balance between body and freshness and have fine-grained tannins, but they are distinguished by their aroma: intense notes of blackberries and ripe strawberries that are still evident when the variety is occasionally made in a ros\u00e9 style or, even more occasionally, as a white wine. Red wines produced in D\u00e3o are generally smooth, for easy drinking and not designed for long ageing since those intense fresh-fruit aromas soon turn into something more complex but less typical. In the heat and sunshine of the Alentejo, where water stress is common, the wines tend to be paler in colour, richer and more alcoholic, a little rustic and less redolent of strawberries. Alfrocheiro also blends well with varieties such as Aragonez (aka TEMPRANILLO) and TOURIGA NACIONAL. Recommended producers include Adega Cooperativa de Borba and Ervideira in the Alentejo, Quinta da Lagoalva de Cima in the Tejo and Quinta dos Roques in D\u00e3o.',
      'On Spain\u2019s Islas Canarias, Baboso Negro is usually blended with other local varieties, although producers such as Frontos, Tananjara and Vi\u00f1\u00e1tigo make varietal wines.',
    ],
  },
  {
    name: 'ALICANTE HENRI BOUSCHET',
    synonyms: [
      'Alicante',
      'Alicante Bouschet',
      'Alicante Bouschet no. 2',
      'Dalmatinka',
      'Garnacha Tintorera',
      'Kambu\u0161a',
      'Sumo Tinto',
      'Tintorera',
    ],
    description:
      'Productive, widely dispersed, red-fleshed southern French cross on the wane in France but waxing in southern Portugal.',
    'origin and parentage': [
      'At the Domaine de la Calmette in Mauguio (H\u00e9rault), Henri Bouschet created several varieties by crossing Grenache (GARNACHA) with PETIT BOUSCHET (an ARAMON NOIR \u00d7 TEINTURIER cross obtained by Henri\u2019s father, Louis). In 1855, he obtained Alicante Henri Bouschet and Alicante Bouschet no. 1, and in 1865 he obtained Alicante Bouschet nos. 2, 5, 6, 7, 12 and 13. All these sibling varieties were morphologically very similar but produced wines of varying quality. While some of them were propagated in the vineyards under the misleading generic name Alicante Bouschet, others remained solely in grape collections. By the end of the nineteenth century, all but the most productive one \u2013 Alicante Henri Bouschet \u2013 had disappeared. However, since it was impossible to distinguish the Alicante Henri Bouschet obtained in 1855 from the Alicante Bouschet no. 2 obtained in 1865, Alicante Henri Bouschet vineyards today are a mix of two similar but distinct sibling varieties. DNA profiling could be used to distinguish them. In practice, it is always referred to simply as Alicante Bouschet.',
      'Alicante Henri Bouschet was used to breed a host of other red-fleshed varieties, of which only ODESSKY CHERNY is still cultivated today.',
    ],
    'where its grown': [
      'There was a surge in Alicante Bouschet\u2019s popularity in France after phylloxera, in the late 1880s, due especially to its deep colour and impressive yields (up to 200 hl/ha or 11 tons/acre) but it has been declining since the 1980s and now ranks thirteenth in France, where there was a total of 5,680 ha (14,036 acres) in 2008, down from 15,769 ha (38,966 acres) in 1988. The majority of plantings are in the south, from the Lot-et-Garonne in the west to the Dr\u00f4me in the east, but also further north in the Jura and in the Val de Loire. The greatest concentration remains in its H\u00e9rault homeland (2,455 ha/6,066 acres). It has a major advantage in being one of the few red-fleshed Vitis vinifera varieties, which means it is sanctioned by the French authorities for the production of quality wine (unlike hybrids).',
      'Wines are deeply coloured and, if the yields are controlled, it can produce soft, fruity wines, but even today it tends to be used to darken the colour in blends. Rare examples of French varietal wines include Domaine La Sorga\u2019s All\u00e9 Canto and Domaine Zelige-Caravent\u2019s Nuit d\u2019Encre from Pic-Saint-Loup (though the latter includes 5% Grenache).',
      'Spain has an even greater area given over to this variety than France: in 2008 there were 22,251 ha (54,983 acres) of Garnacha Tintorera, more than half of that total in Castilla-La Mancha but also significant plantings in Galicia in the north west (6,319 ha/15,615 acres) and Valenciana (1,168 ha/2,886 acres). In 1990 there were only 16,000 ha (39,537 acres).',
      'Alicante Bouschet\u2019s popularity is also increasing in Portugal (total plantings in 2010 were 2,203 ha/5,444 acres), especially in the arid Alentejo in the south, where an increasing number of producers are taking the variety more seriously and producing impressive, deeply coloured and deep-fruited varietal wines. The variety is thought to have been introduced to the region before the 1950s by John Reynolds at his family property, Herdade do Mouch\u00e3o. The success of the property\u2019s top wine, Mouch\u00e3o, a blend of Alicante Bouschet with some TRINCADEIRA, encouraged others to plant this variety in the Alentejo. Recommended producers include Caves Bonif\u00e1cio and F\u00e9lix Rocha in the Lisboa region, Espor\u00e3o, Francisco Nunes Garcia, Herdade dos Grous and Terras de Alter in the Alentejo.',
      'In Italy, the official census of 2000 distinguishes between Alicante (488 ha/1,206 acres) and the less widely planted Alicante Bouschet (73 ha/180 acres, mainly in Sardegna, Toscana and Calabria) but Alicante is likely to refer to GARNACHA, not to Alicante Henri Bouschet.',
      'Turkey had 550 ha (1,359 acres) in 2010 and the variety is also found to a limited extent in Hungary (25 ha/62 acres in 2008), the former Yugoslavia, Cyprus (81 ha/200 acres in 2010) and Switzerland. Some has been grown in Croatia, Bosnia and Herzegovina, Israel and North Africa.',
      'Outside Europe, in North America, California still had 1,175 acres (476 ha) in 2008, mostly in the hot Central Valley counties of Fresno, San Joaquin and Tulare, where this variety was planted during Prohibition; and in South America in 2008, Chile had 438 ha (1,082 acres), Argentina 215 ha (531 acres) and Uruguay 11 ha (27 acres).',
    ],
  },
  {
    name: 'ALIGOT\u00c9',
    synonyms: [
      'Aligotte',
      'Alligotay',
      'Alligot\u00e9',
      'Alligotet',
      'Beauni\u00e9',
      'Beaunois',
      'Carcairone',
      'Chaudenet Gras',
      'Giboulot',
      'Giboudot',
      'Griset Blanc',
      'Mahranauli',
      'Plant de Trois',
      'Plant Gris',
      'Troyen Blanc',
      'Vert Blanc',
    ],
    description:
      'Burgundy\u2019s tart \u2018other\u2019 white wine grape, particularly popular in Eastern Europe.',
    'origin and parentage': [
      'This variety was probably first cited by Dupr\u00e9 de Saint-Maur in 1780 under the name Plant de Trois, an old synonym referring to the fact that its branches usually bear three bunches each. Under its modern name, Aligot\u00e9, a document from the C\u00f4te d\u2019Or dated 1807 proposed pulling it up rather than planting it. Various subsequent mentions showed different spellings such as Alligotay, Aligott\u00e9, Alligot\u00e9 or Alligotet in the Vall\u00e9e de la Sa\u00f4ne where Aligot\u00e9 most likely originated, according to Durand and Pacottet (1901b).',
      'DNA parentage analysis has established that Aligot\u00e9 is a natural progeny of PINOT and GOUAIS BLANC, just like its siblings CHARDONNAY, GAMAY NOIR, MELON and other minor varieties of north-eastern France (Bowers et al. 1999; see Pinot pedigree diagram). This is consistent with the birthplace of Aligot\u00e9 being in north-eastern France, most likely in Burgundy.',
      'The name Aligot\u00e9 probably derives from G\u00f4t, an old synonym for GOUAIS BLANC in the Arri\u00e8res-C\u00f4tes de Beaune, where the mother was replaced by its child, Aligot\u00e9. Aligot\u00e9 is a member of the same ampelographic group as Gouais Blanc (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'The total area of Aligot\u00e9 vineyards in France is almost exactly the same as it was fifty years ago, despite a decline in the 1980s. In 2008 there were 1,946 ha (4,809 acres), almost entirely in Burgundy, both in the C\u00f4te d\u2019Or and further south, and to a lesser extent in the Chablis region, which explains why it is often thought of as Burgundy\u2019s \u2018other white variety\u2019.',
      'Top-quality wines, in which the variety\u2019s naturally high acidity is tempered by all-important ripeness, are fine and full of nerve but demand the best sites, and it is more profitable to use such precious land for CHARDONNAY or PINOT NOIR. It is therefore generally planted on the highest and lowest slopes, producing relatively neutral, sometimes eye-wateringly tart, everyday wines. These are greatly improved by a very small addition of cr\u00e8me de cassis (blackcurrant liqueur) to make the ap\u00e9ritif known as kir. The two main appellations are Bourgogne Aligot\u00e9 and the finer Aligot\u00e9 Bouzeron, where yields are restricted to 45 hl/h (2.5 tons/acre) rather than the usual 60 hl/ha (3.3 tons/acre). Top producers include Michel Bouzereau, Arnaud Ente, Michel Lafarge, Ponsot (in Monts Luisants, Morey-Saint-Denis) and A & P de Villaine. Aligot\u00e9 used to be planted in the cru of Meursault in the nineteenth century, and in 1930 a legal judgement authorized the production of Corton-Charlemagne with Aligot\u00e9',
      'Switzerland has 21 ha (52 acres), mainly in the canton of Gen\u00e8ve. Recommended producers include Domaine des Balisiers and Domaine Les Perri\u00e8res.',
      'The variety is widely planted in Eastern Europe. Ukraine had 9,625 ha (23,784 acres) in 2009 (11% of the total vineyard area); the Republic of Moldova had 15,790 ha (39,018 acres) in 2009 in the south and centre of the country; Romania had 7,203 ha (17,799 acres) in the Moldova region in the east, in Vrancea, Tulcea on the Black Sea coast, and Ia\u015fi and Vaslui in the north east; Bulgaria had 1,095 ha (2,706 acres) in 2009; Russia\u2019s Krasnodar Krai region had 414 ha (1,023 acres) capable of making some of the most confident dry whites made on the Black Sea coast and it is also widely planted in other Russian wine regions such as Rostov (620 ha/1,532 acres in 2010). Aligot\u00e9 seems to thrive in much of Eastern Europe, with an excess of acidity rarer than in Burgundy. In Russia it has also been used, often in blends, for sweet wines but in general Aligot\u00e9 is best drunk in the first few years.',
      'A little is also planted for devotees of burgundy in California (Au Bon Climat, Calera), Washington (Steele Wines), Canada (Ch\u00e2teau des Charmes in Ontario) and Australia (Hickinbotham in South Australia).',
    ],
  },
  {
    name: 'ALIONZA',
    synonyms: [
      'Aleonza',
      'Alionga Bianca del Bolognese',
      'Allionza',
      'Glionza',
      'Leonza',
      'Uva Lonza',
      'Uva Schiava',
    ],
    description: 'Rare but ancient white on the verge of extinction.',
    'origin and parentage': [
      'From Bologna and Modena provinces in Emilia-Romagna in north-central Italy; mentioned as early as the fourteenth century by Pietro de Crescenzi. DNA profiling suggests that Alionza could be closely related to TREBBIANO TOSCANO (Filippetti, Silvestroni, Thomas and Intrieri 2001). The synonym Uva Schiava was probably given because both Alionza and the original SCHIAVA GROSSA were traditionally grown on horizontal wires.',
    ],
    'where its grown': [
      'In Italy, Alionza is used for blending in the provinces of Bologna and Modena, but, with a mere 43 ha (106 acres) for the whole of Italy, Alionza is unfortunately slowly disappearing. Some French ampelographers suggested in the late nineteenth century that Alionza was also present in the vineyards of southern France, which seems unlikely. Formerly cultivated in the region of Brescia and Mantova (Lombardia). In the past, Alionza was also considered to be a good table grape.',
    ],
  },
  {
    name: 'ALLEGRO',
    synonyms: ['Geisenheim 8331-1'],
    description: 'German hybrid with good disease resistance.',
    'origin and parentage': [
      'A complex hybrid obtained by crossing CHANCELLOR \u00d7 RONDO at Geisenheim in 1983. An application for its inclusion in the official list of varieties was filed in 2002.',
    ],
    'where its grown': [
      'Wines have aromas of spice and ripe red fruits and rounded tannins. The Bergstr\u00e4sser Winzergenossenschaft (co-op) in Hessische-Bergstrasse, Germany, produce a varietal wine.',
    ],
  },
  {
    name: 'ALTESSE',
    synonyms: [
      'Anet',
      'Fusette d\u2019Amb\u00e9rieu',
      'Marestel',
      'Plant d\u2019Altesse',
      'Prin Blanc',
      'Roussette',
      'Roussette de Montagnieu',
      'Roussette de Seyssel',
      'Roussette Haute',
    ],
    description: 'Savoie\u2019s finest grape making delicate whites.',
    'origin and parentage': [
      'The earliest mention of Altesse was provided by the Marquis Costa de Beauregard in 1774: \u2018le vin d\u2019Altesse, qu\u2019on prend pour un vin \u00e9tranger excellent [.\u00a0.\u00a0.] est un plant de Chypre, qu\u2019un de nos Princes a fait venir en Savoye\u2019 (\u2018Altesse wine, which is considered to be an excellent foreign wine [.\u00a0.\u00a0.] is a vine from Cyprus that was brought to Savoie by one of our princes\u2019). The Marquis reported an ancient local tradition saying that Altesse took its name from the title of the Dukes of Savoie (the French altesse means \u2018highness\u2019), who allegedly brought it back from the Orient (see below).',
      'Many other authors support an eastern origin for Altesse, including Galet (1990; 2000), who claimed that it had no link with any other French varieties and suggested it could be identical to the Hungarian variety FURMINT. However, based on morphological observations, Fo\u00ebx (1901) had reservations about an eastern origin for Altesse, believing it to come from the Coteau des Altesses near the Lac du Bourget, Altesses being the local name of the \u2018terraces\u2019 where the variety was cultivated. Although this toponym could not be located (R\u00e9zeau 1997), the synonym Plant d\u2019Altesse that was widely used in the nineteenth century largely supports this etymology.',
      'Recent genetic studies back up Fo\u00ebx in rejecting an eastern origin for Altesse because it is genetically close to CHASSELAS, whose origin was shown to be around Lac L\u00e9man (Lake Geneva; Vouillamoz and Arnold 2009). In addition, there is no genetic link between Altesse and FURMINT (Vouillamoz), and Altesse can therefore be considered to be an old autochthonous variety of Savoie.',
    ],
    'where its grown': [
      'Varietal examples are produced within the Roussette de Savoie appellation in France and its more specific crus or sub-appellations Frangy, Marestel, Monterminod and Monthoux. Recommended producers include Dupasquier, Prieur\u00e9 Saint-Christophe, Chevalier-Bernard and Jean Perrier. Less well known and slightly further north is the Altesse de Bugey appellation, well represented by Franck Peillot. Such wines may be nutty or more exotically perfumed \u2013 even violet-scented \u2013 with flavours of honey and almonds, good acidity and the potential to age in bottle. Altesse is often blended with varieties such as JACQU\u00c8RE, ALIGOT\u00c9, GRINGET or CHARDONNAY within the Vin de Savoie appellation and its more specific crus, including Abymes, Apremont, Ayze, Cr\u00e9py and so on. The Seyssel appellation is best known for sparkling wines made from Altesse, possibly with CHASSELAS and MOLETTE, though its still wines must be 100% Altesse.',
      'There were 357 ha (882 acres) in eastern France in 2008, mainly in Savoie, Ain, Is\u00e8re and Haute-Savoie, where it is officially recommended, although it is also authorized further south in the Var and the Vaucluse. Henri Cruchon makes a rare varietal example in Switzerland.',
    ],
  },
  {
    name: 'ALVARELH\u00c3O',
    synonyms: [
      'Albarello',
      'Alvarelhao',
      'Alvarelho',
      'Alvarellao',
      'Brancelho',
      'Brancellao',
      'Broncellao',
      'Locaia',
      'Pilongo',
      'Pirruivo',
      'Serradelo',
      'Serradillo',
      'Uva Gallega',
      'Varancelha',
      'Verancelha',
    ],
    description:
      'Declining but potentially high-quality variety from northern Portugal.',
    'origin and parentage': [
      'Alvarelh\u00e3o probably comes from the Douro or D\u00e3o in Portugal. The level of genetic diversity suggests that it is a moderately old variety (Rolando Faustino, personal communication). According to DNA studies, Alvarelh\u00e3o could be genetically very close to SERCIAL (under the name Esgana C\u00e3o; Lopes et al. 1999).',
      'Although the white-berried PLANTA NOVA from Valencia in eastern Spain is also known as Alvarelh\u00e3o Branco, it is not a colour mutation of Alvarelh\u00e3o (Vouillamoz).',
    ],
    'where its grown': [
      'Until the 1970s, Alverelh\u00e3o was an important and highly regarded variety in Portugal, for port in the Douro and for unfortified wine in D\u00e3o and the surrounding area of Beiras and, to a lesser extent, in the Vinho Verde region, where it is particularly recommended in the Mon\u00e7\u00e3o subregion close to the Spanish border. However, it has been a victim of changes in consumer preferences and total Portuguese plantings were just 67 ha (166 acres) by 2010, although there has been recent renewed interest for the production of ros\u00e9 (Rolando Faustino, personal communication). Wines are typically fresh, with low extract but moderate to high alcohol, delicately aromatic and best drunk young. In Minho, the Adega Cooperativa de Mon\u00e7\u00e3o make a good ros\u00e9 and Alvaianas a good red. Campolargo also make a varietal red in Bairrada.',
      'Alvarelh\u00e3o is known as Brancellao in Galicia in north-west Spain, particularly in R\u00edas Baixas, where there were 17 ha (42 acres) in 2008 and where Coto de Gomariz and Sameir\u00e1s use it in blends. It is highly likely that Quinta da Muradella\u2019s Albarello is also Alvarelh\u00e3o.',
      'In California\u2019s Central Valley, a few producers of port-style wines use Alvarelh\u00e3o in their blend and Yarra Yering in Australia\u2019s Yarra Valley include it in their Dry Red No. 3.',
    ],
  },
  {
    name: 'ALVARINHO',
    synonyms: [
      'Albari\u00f1o',
      'Albelleiro',
      'Alvarin Blanco',
      'Azal Blanco',
      'Galego',
      'Galeguinho',
      'Padern\u00e3',
    ],
    description:
      'Fashionable, high-quality, fresh, aromatic variety straddling the Spanish\u2013Portuguese border.',
    'origin and parentage': [
      'This variety originates from north-eastern Portugal or over the border in Galicia in north-west Spain, where it was once exclusively cultivated \u2013 as Alvarinho and Albari\u00f1o respectively. In Portugal, Alvarinho shows great morphological diversity, which usually indicates that the variety is quite old, and in Spain it is considered to be among the oldest varieties of the north west (Santiago et al. 2005). Even though the variety was not mentioned in Galicia until 1843, a parcel of forty 200- to 300-year-old Albari\u00f1o vines has been observed there (Boso et al. 2005; Santiago et al. 2007).',
      'DNA parentage analysis has suggested that AMARAL (under the name Ca\u00ed\u00f1o Bravo) and Alvarhino (under the name Albari\u00f1o) gave birth to CA\u00cd\u00d1O BLANCO (D\u00edaz-Losada et al. 2011), all three traditionally grown in the north-western Iberian peninsula, which explains why Alvarinho and Ca\u00ed\u00f1o Blanco were often confused.',
      'DNA profiling has established that Alvarinho/Albari\u00f1o is not identical to ALBAR\u00cdN BLANCO, also known as Blanco Lex\u00edtimo, and is not a colour mutation of ALFROCHEIRO, traditionally called Albar\u00edn Negro in the Asturias in Spain (Iba\u00f1ez et al. 2003; Vouillamoz).',
      'In Spain, both Albari\u00f1o and ALBAR\u00cdN BLANCO have long been mistaken for SAVAGNIN BLANC due to a labelling or identification error in the collection of the Misi\u00f3n Biol\u00f3gica de Galicia but this can be rejected by DNA analysis (Santiago et al. 2005). How much Savagnin Blanc is still grown in the Spanish vineyards under the names Albar\u00edn Blanco and Albari\u00f1o is not yet known. Comparison of DNA profiles indicates a possible close relationship with LOUREIRO (Iba\u00f1ez et al. 2003).',
    ],
    'where its grown': [
      'Alvarinho is widely planted in north-west Portugal, having spread from its original heartland in the municipality of Mon\u00e7\u00e3o, right on the country\u2019s northern border with Spain, where it is known as Albari\u00f1o. However, the quality of the variety and the wind of fashion have taken the variety beyond north-west Iberia as far as North America and the Antipodes. The best varietal wines combine aromas and flavours that are both fruity and floral \u2013 from linden, orange and acacia blossom through lemongrass and honeysuckle to orange, dried orange peel, grapefruit, bergamot, peach and, in some cases, green apple. Fresh acidity balances the full body and firm structure and there can often be a marine note, reminding the taster how well these wines go with seafood. There are nevertheless still plenty of rather weedier examples in the region, especially where yields are not controlled on traditional pergolas.',
      'There were 2,340 ha (5,782 acres) in Portugal in 2010, mostly in the Minho region in the north west but now also further south in D\u00e3o, the Pen\u00ednsula de Set\u00fabal peninsula and Lisboa. Within the Vinho Verde DOC in the north west, there is specific recognition for the quality of Alvarinho in that varietal wines may be produced in the subregions of Mon\u00e7\u00e3o and Melga\u00e7o even though most Vinho Verde wines are blends, generally led by LOUREIRO and TRAJADURA. It is also used to make sparkling wine. Recommended producers include Anselmo Mendes, Quinta do Feital and Quinta da Soalheiro.',
      'In Spain, there were 5,320 ha (13,146 acres) of Albari\u00f1o in 2008, virtually all in Galicia, where it is was one of the first Spanish wines to be labelled by variety. It dominates the R\u00edas Baixas DO and is becoming increasingly popular and fashionable at home and abroad. Recommended producers include Castro Mart\u00edn, Do Ferreiro (notably the Cepas Vellas from a 200-year-old vineyard), Fillaboa, Pazo de Se\u00f1orans (especially the late-released Selecci\u00f3n An\u00e3da), Pazos de Lusco, Ra\u00fal P\u00e9rez, Tric\u00f3 and La Val; Palacio de Fefi\u00f1anes make a successful unoaked version as well as 1583 Albari\u00f1o de Fefi\u00f1anes, one of the very few notable barrel-fermented examples.',
      'Under the name Albari\u00f1o, the variety is also grown in California, Oregon and Washington State and until 2009 (see below) was thought to be the source of a growing number of fresh, peachy wines in Australia.',
      'California\u2019s acreage rose from 28 acres (11 ha) in 2008 to 128 acres (52 ha) in 2009, confirming that this variety is on a roll. Recommended producers include Bokisch (Lodi), Bonny Doon (Monterey), Mahoney (Carneros), Tangent (Edna Valley). Oregon\u2019s pioneer of Albari\u00f1o is Abacela in the south of the state but others are starting to plant in their wake, and in Washington State a few producers are following suit, eg Coyote Canyon.',
      'In Uruguay, Bouza have planted Albari\u00f1o in their vineyards not far from Montevideo and make a pure, fragrant wine.',
      'The unwelcome revelation that the majority of Australia\u2019s Albari\u00f1o vines were in fact SAVAGNIN BLANC, owing to a labelling error in a Spanish vine collection, perpetuated in France and again once the vines arrived and were propagated in Australia, means that no one really knows how much true Albari\u00f1o is planted there. However, New Zealand\u2019s leading vine nursery has assured growers across the Tasman Sea that their Albari\u00f1o is true to type and Cooper\u2019s Creek, a pioneer of alternative varieties in New Zealand, produced their first vintage of Albari\u00f1o in 2011.',
      'Despite all the confusion over Albari\u00f1o in Australia, the qualities and \u2013 possibly more importantly \u2013 market potential of this Portuguese variety have persuaded the CTPS, the official French committee in charge of plant selection and authorization, to agree to its inclusion in the French national register, which currently includes 288 different vine varieties. Unusually, it will not be subject to an experimental period although it is unlikely that French growers will be granted a subsidy to plant it.',
    ],
  },
  {
    name: 'AMARAL',
    synonyms: [
      'Azal Tinto',
      'Azar',
      'Cainho Bravo',
      'Cainho Mi\u00fado',
      'Ca\u00ed\u00f1o Bravo',
      'Cainzinho',
    ],
    description: 'High-acid Portuguese red found mainly in Vinho Verde.',
    'origin and parentage': [
      'Officially named Amaral but frequently called Azal Tinto, even though it is not related to the light-skinned AZAL, this variety most likely originates in western Portugal, where it has had a significant genetic influence: recent DNA parentage analyses have suggested possible parent\u2013offspring relationships with LOUREIRO, TINTA CASTA\u00d1AL and VINH\u00c3O as well as with Melhorio and Sous\u00e3o Galego, two local and no longer cultivated varieties, and that Amaral (under the name Ca\u00ed\u00f1o Bravo) and ALVARINHO (under the name Albari\u00f1o) gave birth to CAI\u00d1O BLANCO, indicating that Amaral is a fairly old variety (Castro et al. 2011; D\u00edaz-Losada et al. 2011).',
    ],
    'where its grown': [
      'There were 123 ha (304 acres) of Amaral in Portugal in 2010 making acidic, well-coloured, relatively full-bodied but generally dull wines. It is authorized in the Vinho Verde region and particularly recommended in southern subregions such as Amarante but it is usually part of a blend; producers include Casa de Vilacetinho and Casa do Valle.',
    ],
  },
  {
    name: 'AMIGNE',
    synonyms: [
      'Amigne Blanche',
      'Grande Amigne',
      'Grosse Amigne',
      'Petite Amigne',
    ],
    description:
      'Ancient but minor Swiss variety making mainly sweeter styles of wine.',
    'origin and parentage': [
      'Amigne is indigenous to the Valais in Switzerland, where it was first mentioned in 1686 as growing between Sion and Sierre, a dozen kilometres away from V\u00e9troz, its current preferred location. DNA parentage analysis has suggested a genetic relationship (grandchild, nephew/niece or half-sibling) with AVAN\u00c0 from Piemonte in Italy and has established that Amigne is a likely grandchild of PETIT MESLIER from Champagne and the Franche-Comt\u00e9 in France (Vouillamoz and Moriondo 2011). In this case, Amigne would be a great-grandchild of Petit Meslier\u2019s parents, SAVAGNIN and GOUAIS BLANC, both also cultivated in the Valais since at least the sixteenth century. It is therefore quite possible that one or both of Amigne\u2019s extinct parents were introduced from north-eastern France together with Savagnin and Gouais Blanc.',
      'Labra et al. (2002) have suggested a close genetic relationship between Amigne and ROUSSIN from the Valle d\u2019Aosta in Italy but this has been disproved by more detailed DNA analyses (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Almost all the 43 ha (106 acres) of Amigne in 2009 were planted around the town of V\u00e9troz in the Valais, south-west Switzerland. The wines are complex with citrus notes of orange zest and tangerine and crisp acidity. They used to be dry but most today are made sweet and occasionally too heavy. Amigne fl\u00e9trie, made from grapes left to shrivel and concentrate on the vine, such as that from Cave des Tilleuls, can be voluptuous but well balanced. Recommended producers of dry or medium-dry wines include La Madeleine, Les Ruinettes and Vieux-Moulin, and some of the best sweet wines are made by Jean-Ren\u00e9 Germanier and La Tine.',
    ],
  },
  {
    name: 'ANCELLOTTA',
    synonyms: ['Ancellotta di Massenzatico', 'Lancellotta'],
    description:
      'Useful, deeply coloured blending ingredient valued more for its colour, tannins and acidity than for its flavour.',
    'origin and parentage': [
      'From Emilia-Romagna in north-central Italy. Its name might derive from the Lancellotti family of Modena (fourteenth-fifteenth centuries), who were responsible for the spread of this variety.',
      'Ancellotta was used to breed GALOTTA.',
    ],
    'where its grown': [
      'In Italy, Ancellotta is cultivated mainly in the province of Reggio Emilia (eg for Reggiano Rosso DOC) and used mostly for the production of concentrated musts to reinforce the colour of lighter wines such as those made from LAMBRUSCO SALAMINO and LAMBRUSCO MARANI. After the First World War, Ancellotta was introduced in the province of Trentino (in Mori and Avio), as well as in small areas in Friuli, Lombardia, Veneto, Toscana, Apulia and Sardegna. There were nearly 4,500 ha (11,120 acres) in Italy in 2000.',
      'Ancellotta is probably more highly valued in Brazil (180 ha/445 acres in 2007) than it is in Italy, although even there it is still used mainly in blends. Don Guerino\u2019s very successful, rich, deeply flavoured but fresh varietal example is an exception. Also found in the Valais in Switzerland (19 ha/47 acres), where it is used to bring additional colour to some PINOT-based reds.',
    ],
  },
  {
    name: 'ANDR\u00c9',
    synonyms: ['Andrea', 'Semenac A 16-76'],
    description:
      'Late-ripening Czech cross producing well-structured reds if harvested fully ripe.',
    'origin and parentage': [
      'Andr\u00e9 is a BLAUFR\u00c4NKISCH \u00d7 SANKT LAURENT cross bred in 1961 by Jaroslav Horak at the \u0160SV research centre in Velk\u00e9 Pavlovice, southern Morava, in Czechoslovakia (now the Czech Republic), which makes it a sibling of ZWEIGELT. Andr\u00e9 was named after the French naturalist Christian C Andr\u00e9 (1763\u20131831), who introduced grape-breeding techniques to the research centre. Andr\u00e9 was included in the official Czechoslovak register of varieties in 1980.',
      'Andr\u00e9 was used to breed AGNI and RUBINET.',
    ],
    'where its grown': [
      'If the grapes are fully ripe, wines are full-bodied, well structured, with aromas of ripe blackberries, and suited to barrel ageing and a few years in bottle, but growers looking for high yields produce harsh, astringent wines. Total plantings in the Czech Republic in 2009 amounted to 265 ha (655 acres), almost all in Morava in the south east. Recommended producers include Patria Kobyl\u00ed.',
      'Slightly more hectares of Andr\u00e9 are planted in Slovakia (283 ha/699 acres in 2009), where Jozef Kotu\u010dek and Karpatsk\u00e1 Perla produce varietal wines, the latter a late-harvest version.',
    ],
  },
  {
    name: 'ANT\u00c3O VAZ',
    synonyms: ['Antonio Vaz'],
    description:
      'High-quality Portuguese variety at home in the hot, dry south.',
    'origin and parentage': [
      'Ant\u00e3o Vaz is an old variety that probably comes from the south of the Alentejo in Portugal, where it has long been cultivated, particularly in the region of Vidigueira, south east of Lisboa.',
      'DNA parentage analysis has revealed a parent\u2013offspring relationship with the Iberian variety CAYETANA BLANCA (Zinelabidine et al. 2012; see pedigree diagram in Cayetana Blanca).',
    ],
    'where its grown': [
      'Until recently, Ant\u00e3o Vaz was grown almost exclusively in Vidigueira in the southern Alentejo, south east of Lisboa in Portugal, but it has now spread to subregions such as Reguengos, \u00c9vora and Moura to become one of the region\u2019s main varieties, very popular with growers because it is productive, resilient and capable of producing good-quality white wine in the heat of the Alentejo. It is also grown to a lesser extent closer to Lisboa and in the Pen\u00ednsula de Set\u00fabal. There were 1,209 ha (2,987 acres) in 2010. Wines typically have tropical-fruit flavours and a good balance between alcohol and acidity. Picked early, the wines are fresh and crisp and low in alcohol, but riper styles have broader flavours and the structure to benefit from barrel fermentation and/or blending with more acidic varieties such as ARINTO DE BUCELAS or S\u00cdRIA (Mayson 2003). Recommended producers of varietal wines include Exquisite Wine, Herdade da Calada, Herdade Fonte Paredes and Quinta do Quetzal in the Alentejo.',
    ],
  },
  {
    name: 'ANTEY MAGARACHSKY',
    synonyms: ['Antei', 'Antei Magarachskii', 'Magarach 70-71-52'],
    description: 'Ukrainian hybrid used to make sweet red wines.',
    'origin and parentage': [
      'Antey Magarachsky is a hybrid obtained by P Y Golodriga, V T Usatov, L P Troshin, Y A Malchikov and N Dubovenco in 1971 at the Magarach research centre in Yalta, southern Ukraine. It was initially thought to be a cross between RUBINOVY MAGARACHA and Magarach 85-64-16, the latter being a progeny of Perle Noire (or Seyve-Villard 20-347) fertilized with a pollen mixture of unknown Vitis vinifera varieties. However, recent DNA parentage analysis has shown that Perle Noire is the parent not the grandparent (Goryslavets et al. 2010), so that the parentage of Antey Magarachsky was corrected to Rubinovy Magaracha \u00d7 Perle Noire (see MUSCAT BLEU for the pedigree of Perle Noire). Antey Magarachsky was included in Ukraine\u2019s official register of varieties in 1988.',
    ],
    'where its grown': [
      'Antey Magarachsky is grown for the table as well as for the vat in Ukraine. The Magarach Institute produce a strong (16%) sweet red wine by blending it with other Magarach-bred hybrids such as BASTARDO MAGARACHSKY.',
    ],
  },
  {
    name: 'ARAKLINOS',
    synonyms: ['Araclinos', 'Araklino', 'Raklino'],
    description: 'Rare Greek variety used mainly in red blends.',
    'origin and parentage': [
      'Araklinos may be from Greece\u2019s western Pelop\u00f3nnisos (Peloponnese) or from the island of Kefaloni\u00e1. According to Myles et al. (2011), the reference sample of Araklinos (under the name Araclinos) in the United States Department of Agriculture\u2019s National Clonal Germplasm Repository could be identical to VRANAC from Montenegro, but this needs to be verified with ampelographic observations and with DNA profiling of local reference samples in Greece and Montenegro.',
    ],
    'where its grown': [
      'Araklinos is cultivated in Greece to a very limited extent in the western Pelop\u00f3nnisos and also on Kefaloni\u00e1, where Domaine Foivos include a small percentage of both Araklinos and THEIAKO MAVRO in a blend with MAVRODAFNI. Varietal wines have high alcohol and medium acidity.',
    ],
  },
  {
    name: 'ARAMON NOIR',
    synonyms: [
      'Amor-N\u00e3o-Me-Deixes',
      'Aramonen',
      'Gros Bouteillan',
      'Pisse-Vin',
      'Plant Riche',
      'Rabala\u00efr\u00e9',
      'Ramonen',
      'R\u00e9balla\u00efr\u00e9',
      'Ugni Noir',
    ],
    description:
      'Very productive vine once responsible for much of France\u2019s least noble wine.',
    'origin and parentage': [
      'Like many old varieties, Aramon appears in three different colours \u2013 Noir, Gris and Blanc \u2013 all found in southern France, where this variety most likely originates. Surprisingly, the white version (which actually has rather pink berries) was the first one to be mentioned: the eminent Swiss botanist Augustin-Pyramus listed \u2018Aramon Blanc, H\u00e9rault\u2019 in the catalogue of the numerous varieties he had gathered in his collection at the Botanic Garden in Gen\u00e8ve. Even more surprisingly, the black version was first mentioned in the UK in the Royal Horticultural Society\u2019s Catalogue of fruits (1826), before being described by the famous grape breeder Louis Bouschet (1829).',
      'Aramon is said to take its name from the village of Aramon in the Gard near N\u00eemes, though it has never been widely cultivated there. A more convincing hypothesis is that the name Aramon derives from rameux (meaning \u2018branched\u2019), which would explain its synonyms Aramonen and Ramonen.',
      'DNA profiling has established that the Malvoisie Espagnole in the INRA (Institut National de la Recherche Agronomique) grape collection at Vassal in Montpellier, in southern France, is identical to Aramon Gris (Lacombe et al. 2007), which has the same profile as Aramon Noir. In addition, the DNA profile of the strangely named Portuguese (Alentejo) variety Amor-N\u00e3o-Me-Deixes (meaning \u2018my love, don\u2019t leave me\u2019) published in Almadanim et al. (2004) turned out to be identical to that of Aramon (Vouillamoz). DNA analysis has also discovered a natural parent\u2013offspring relationship with GOUAIS BLANC (Boursiquot et al. 2004), which means that Aramon is a half-sibling of at least eighty Western European varieties.',
      'Although Aramon Noir is also called Gros Bouteillan and Ugni Noir, it is not related to either BOUTEILLAN NOIR or TREBBIANO TOSCANO, known as Ugni Blanc in France.',
      'Aramon Noir was used to breed GRAMON, GRAND NOIR, MONERAC and PETIT BOUSCHET.',
    ],
    'where its grown': [
      'In France all three colours are listed separately in the official register of varieties and all have declined considerably in terms of planting area in the last fifty years, with the Gris and Blanc versions virtually extinct. Aramon was widely embraced in the Languedoc in the late nineteenth century for its resistance to powdery mildew and huge yields of extremely neutral wines often tinted with ALICANTE HENRI BOUSCHET. Carignan (MAZUELO) overtook it only after the 1958 census, which found 150,230 ha (371,226 acres) of Aramon, but by 2008 there were only 3,304 ha (8,164 acres), mainly in the H\u00e9rault. In its heyday, Aramon Noir was also planted in Algeria. In 2006 there were 22 ha (54 acres) of Aramon Blanc and 41 ha (101 acres) of Aramon Gris in the Languedoc, where they tend to disappear in very ordinary white-wine blends, although since 2006 Paul Reder of Domaine Hautes Terres de Comberousse has been producing a varietal Aramon Gris called GriGri from vines planted in 2000. Domaine La Sorga\u2019s Ah Ramon! is a rare example of varietal Aramon Noir.',
    ],
  },
  {
    name: 'ARANY S\u00c1RFEH\u00c9R',
    synonyms: [
      'Feh\u00e9r Kadarka',
      'Feh\u00e9r Muskot\u00e1ly',
      'Husz\u00e1r Sz\u0151l\u0151',
      'Izs\u00e1ki',
      'Izs\u00e1ki Feh\u00e9r',
      'Izs\u00e1ki S\u00e1rfeh\u00e9r',
      'N\u00e9met Dinka',
      'V\u00e9konyh\u00e9j\u00fa',
    ],
    description:
      'Hungarian variety making simple but useful, high-acid wines and fizz on the Alf\u00f6ld (Great Plain).',
    'origin and parentage': [
      'Arany S\u00e1rfeh\u00e9r, literally \u2018gold white mud\u2019, is a Hungarian variety that until 2000 was known as Izs\u00e1ki S\u00e1rfeh\u00e9r, after the town of Izs\u00e1k in the county of B\u00e1cs-Kiskun, its possible place of origin.',
      'S\u00e1rfeh\u00e9r is a distinct but now almost extinct variety, no longer grown commercially, but at one time more widely planted in Sopron, Neszm\u00e9ly and Soml\u00f3 in the north of the country.',
      'Arany S\u00e1rfeh\u00e9r has sometimes been considered to be a white mutation of KADARKA because it was selected from a Kadarka vineyard but this has been disproved by DNA profiling (Galb\u00e1cs et al. 2009). Arany S\u00e1rfeh\u00e9r was used to breed M\u00c1TRAI MUSKOT\u00c1LY.',
    ],
    'where its grown': [
      'Arany S\u00e1rfeh\u00e9r is grown mainly in Kuns\u00e1g and to a far lesser extent in Haj\u00f3s-Baja in central southern Hungary, the area traditionally known as the Alf\u00f6ld, and was once more popular than it is today, particularly during the first half of the twentieth century, when it was exported to Germany as a table grape, although it is starting to experience a return to fashion. Producers of varietal wines in that region include Gedeon and Birk\u00e1s. Izs\u00e1ki Arany S\u00e1rfeh\u00e9r is a specific appellation for varietal wines produced from a group of villages around the town of Izs\u00e1k in the Kuns\u00e1g region. Wines are typically low in alcohol and very high in acidity and are therefore used mostly to add freshness to blends or in the production of sparkling wines. There were 1,634 ha (4,038 acres) in Hungary in 2008.',
    ],
  },
  {
    name: 'ARBANE',
    synonyms: [
      'Albane',
      'Arbanne',
      'Arbenne',
      'Arbone',
      'Cr\u00e8ne',
      'Cr\u00e9nillat',
      'Darbanne',
    ],
    description:
      'Old, aromatic but virtually extinct variety from southern Champagne.',
    'origin and parentage': [
      'Arbane is an old variety of the Aube d\u00e9partement, where it has long been cultivated, mainly in Bar-sur-Aube. Its earliest mention allegedly appeared in 1388 under the name Alban in Les Riceys but this is questionable. The first reliable citation was found in Chaptal et al. (1801), who mentioned this variety in Morveaux near Baroville (Bar-sur-Aube): \u2018Albane et Fromenteau, esp\u00e8ces dont le vin est consid\u00e9r\u00e9 dans le pays\u2019 (\u2018Albane and Fromenteau, species whose wine is highly regarded in the region\u2019). The name Arbane most likely comes from the medieval Latin albana, meaning \u2018white grape\u2019, which is corroborated by its well-known synonym Albane. Arbane is a member of the Tressot ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Despite its potential, less than 1 ha (2.5 acres) of France was planted with it in 2006. The champagne house Moutard-Diligent in Buxeuil continue to make an extremely rare Vieilles Vignes bottling from 100% Arbane, as well as a cuv\u00e9e that includes Arbane, PETIT MESLIER, PINOT BLANC, CHARDONNAY, PINOT NOIR and PINOT MEUNIER.',
    ],
  },
  {
    name: 'ARENI',
    synonyms: [
      'Areni Chernyi',
      'Areni Noir',
      'Areny',
      'Areny Tcherny',
      'Malai Sev',
      'Malayi',
      'Sev Areni',
      'Urza Sev',
    ],
    description:
      'Armenia\u2019s signature variety making top-quality dry and sweet reds.',
    'origin and parentage': [
      'Areni is a very old variety that most likely originates in the village of the same name in the west of the province of Vayots Dzor in southern Armenia, on the border with Azerbaijan, where it has traditionally been cultivated and where the oldest winery remains (4000 BC) have recently been excavated (Barnard et al. 2011). Areni was used to breed TIGRANI.',
      'DNA profiling could easily determine whether the white-berried Areni Spitak, considered by Galet (2000) to be a distinct variety, is a colour mutation of Areni.',
    ],
    'where its grown': [
      'Areni, Armenia\u2019s best variety, is widely used to make high-quality, medium- to full-bodied, ageworthy, dry and semi-sweet table wines as well as brandy and the occasional ros\u00e9 in the east and south of Armenia and in Azerbaijan. Armenian producers include 365 Wines, Areni Winery, Brest, Getnatun, Ginekar, Ginetas, Kimley, Maran and Vedi Alco, as well as at the Shahbuz Winery in Azerbaijan (using the synonym Malayi). The Italian consultant Alberto Antonini, who likens the variety to a cross between SANGIOVESE and PINOT NOIR, is working with Zorik Gharibian at Zorah to make a fresh, sinewy, minerally wine from fruit planted at 1,300 m (4,265 ft) above sea level.',
    ],
  },
  {
    name: 'ARILLA',
    synonyms: ['Agrilla', 'Arillo', 'Rille', 'Uva Rilla'],
    description: 'Rare but productive island white from southern Italy.',
    'origin and parentage': [
      'Arilla has long been cultivated on the Italian island of Ischia, near Napoli, in particular on south-facing slopes. It is thought to originate on Sicila.',
    ],
    'where its grown': [
      'Grown exclusively on the island of Ischia, Italy, Arilla can produce up to 10 kg per plant \u2013 a level of production unlikely to produce much flavour, which is probably why it is usually blended with other local varieties such as FORASTERA, BIANCOLELLA and Don Lunardo (SAN LUNARDO).',
    ],
  },
  {
    name: 'ARINTO DE BUCELAS',
    synonyms: [
      'Arinto',
      'Arinto Branco',
      'Arinto Cercial',
      'Arinto d\u2019Anadia',
      'Arinto Galego',
      'Arintho',
      'Azal Espanhol',
      'Chapeludo',
      'Pedern\u00e3',
      'Terrantez da Terceira',
    ],
    description:
      'High-quality, high-acid, adaptable Portuguese variety from just north of Lisboa.',
    'origin and parentage': [
      'As its name suggests, Arinto de Bucelas most likely comes from the Bucelas area north of Lisboa, Portugal. It was first mentioned under the name Arinto in 1712 by Vic\u00eancio Alarte in his Agricultura das vinhas e tudo o que pertence ao lias at\u00e9 o perfeito recolhimento do vinho, the first viticultural treatise ever written in Portugal. This means it is among the oldest varieties in Portugal, confirmed by the morphological diversity observed in this variety (Rolando Faustino, personal communication). From the area around Lisboa, Arinto de Bucelas first expanded north to Bairrada and then to the Vinho Verde region, where it is today considered to be a traditional variety. Lopes et al. (1999) have found that Arinto de Bucelas is genetically very close to TRAJADURA, an old variety cultivated in Galicia in north-west Spain and in the Douro region in northeast Portugal.',
      'Arinto Roxo is a colour mutation of Arinto de Bucelas (roxo meaning \u2018purple\u2019 in Portuguese).',
      'Arinto de Bucelas should not be confused with the following white-berried varieties: Arinto Branco (synonym for LOUREIRO), Arinto de Alcoba\u00e7a (synonym for BICAL), Arinto de Colares (no longer cultivated), Arinto do D\u00e3o (synonym for MALVASIA FINA), Arinto dos A\u00e7ores (synonym for SERCIAL), Arinto Gordo (synonym for TAMAREZ), or Arinto no Douro (now officially known as DORINTO but also known as Arinto de Tr\u00e1s-os-Montes, Arinto Cachudo, Arinto do Douro and Arinto do Interior).',
      'Although TEMPRANILLO is sometimes called Arinto Tinto, it is not a red mutation of Arinto de Bucelas.',
    ],
    'where its grown': [
      'Arinto de Bucelas is more often referred to as Arinto but this causes a good deal of confusion, as explained above. Thanks to Arinto\u2019s ability to retain acidity in a wide range of sites and its attractively fresh lemony character, it is widely planted throughout Portugal\u2019s wine-growing regions: from the temperate and humid regions of Vinho Verde in the north west (under the name Pedern\u00e3 and often partnered by LOUREIRO and TRAJADURA) to the Alentejo in the hot, dry south, where it generally adds freshness to a blend. It is also planted in Bairrada, for both still and sparkling wines, as well as in the Tejo and in Lisboa.',
      'However, the best Arinto wines are still mostly found in Bucelas, where the maritime influence helps to produce the most elegant wines, which have not only lemon, lime or green-apple freshness but also a steely, mineral backbone, thanks also to the calcareous soils of the region, resulting in wines that age well and gain in complexity but may also be drunk young and fresh. Arinto has particularly benefited from the introduction of longer, cooler fermentation styles. It must constitute at least 75% of wines labeled Bucelas DOC, with SERCIAL and RABO DE OVELHA as optional blending partners. Arinto plus SAUVIGNON BLANC is a more recent blend to appear in Portugal.',
      'Despite this popularity and usefulness in blends, some fine varietal wines are being made, eg by Casa de Laraias in the Vinho Verde region, Quinta da Romeira (part of the Companhia das Quintas) in Bucelas, Quinta de Chocapalha in the Tejo and Julian Reynolds in the Alentejo. There were 3,175 ha (7,846 acres) in Portugal in 2010. How long before it crops up in quality-conscious vineyards elsewhere?',
    ],
  },
  {
    name: 'ARNA-GRNA',
    synonyms: ['Alagura', 'Arha-Grna', 'Khana Crna'],
    description:
      'Light-skinned Azeri variety found mainly in the Nakhchivan Autonomous Republic.',
    'origin and parentage': [
      'Arna-Grna is indigenous to the Nakhchivan Autonomous Republic, an exclave of Azerbaijan bordering on Armenia, Iran and Turkey. Galet (2000) suggests that it could be identical to Arevik from Armenia but this has not yet been tested.',
    ],
    'where its grown': [
      'Producers such as Tumbul Sharab and Babek Wine-2 Yeddiler in the Babek region of Nakhchivan, Azerbaijan, make dry white wines with this variety, although it is also used for juice.',
    ],
  },
  {
    name: 'ARNEIS',
    synonyms: ['Bianchetta di Alba', 'Bianchetto', 'Nebbiolo Bianco'],
    description: 'Piemonte\u2019s scented and full-bodied signature dry white.',
    'origin and parentage': [
      'From Roero in Piemonte, north-west Italy, Arneis was possibly first mentioned in 1432 in Chieri in the province of Torino under the Latin name Ranaysii, which could derive from the toponym Bric Renesio near Canale, already known in 1478 as Reneysium. On the other hand, the place may have taken its name from the presence of Arneis in the vineyards (Comba and Dal Verme 1990). During the seventeenth and eighteenth centuries, the contraction of renesi supposedly produced Arneis. Alternatively, the local dialect arn\u00e8is, used to describe a wily and temperamental person, could have given its name to the grape, reflecting how difficult it is to grow and vinify. However, the earliest written mention of Arneis was in Di Rovasenda (1877). Despite its synonym Nebbiolo Bianco, Arneis is not related to the black-berried NEBBIOLO.',
    ],
    'where its grown': [
      'Mainly cultivated in the Roero and Langhe areas of Piemonte in north-west Italy but also in small amounts in Liguria and on Sardegna. According to local tradition, Arneis used to be planted together with NEBBIOLO to attract birds with its strong flavour, thus protecting Nebbiolo, which had (and still has) a better market value. It was also used to soften Nebbiolo, which may explain the synonym Nebbiolo Bianco.',
      'Arneis virtually disappeared in the early 1970s, when only Vietti and Bruno Giacosa were bottling it. However, it was saved from extinction by a welcome revival of white Piemonte wines in the 1980s. The wines are generally unoaked, subtly fruit-scented, full-bodied and tasting of ripe pears but can lack acidity, especially when later picked; best drunk young.',
      'The Italian census of 2000 recorded 745 ha (1,840 acres) and the best producers in Piemonte are Malvir\u00e0, Deltetto, Cascina Chicco and Bruno Giacosa.',
      'Beyond Europe, it is provoking growing interest in Australia, particularly in Victoria, where it has been planted by producers such as Yandoit Hill, Chrismont, Pizzini, Dal Zotto, Gary Crittenden, Yarra Loch and Rutherglen Estates. Other producers include Tertini in the Southern Highlands (NSW). Coopers Creek in Gisborne, New Zealand, have produced a fresh, off-dry version of this wine with fine, pure floral and citrus flavours. There are minor plantings in the US, notably by Seghesio in Sonoma County, California, and Ponzi in the Willamette Valley, Oregon.',
    ],
  },
  {
    name: 'ARNSBURGER',
    synonyms: ['Geisenheim 22-74'],
    description:
      'Scarce German cross used to make base wines for inexpensive fizz.',
    'origin and parentage': [
      'Arnsburger was described at its introduction as a selfling (see p 1182) of two clones of RIESLING (Riesling 88 GM \u00d7 Riesling 64 GM) developed in 1939 by Heinrich Birk at the Geisenheim research centre in the Rheingau, Germany, and put on the official list of varieties in 1984. If it is a selfling of RIESLING, then Arnsburger would reach the maximum level of consanguinity. However, recent genetic analyses based on newly developed DNA markers have suggested that Arnsburger is more likely to be a M\u00dcLLER-THURGAU \u00d7 CHASSELAS (under the name Chasselas Tokay) cross (Myles et al. 2011). This parentage still needs to be confirmed by standard DNA markers (microsatellites) but it would be consistent with the purpose of the cross, bred to provide a better alternative to M\u00fcller-Thurgau for the production of sparkling white wines.',
      'The name Arnsburger is intended to reflect the wine-growing tradition of the Cistercians, who founded the Abbey of Arnsburg in the Wetterau, north of Frankfurt, and played a major part in the vinous history of the Rheingau.',
      'Arnsburger was crossed with the hybrid Seyve-Villard 1-72 to create SAPHIRA.',
    ],
    'where its grown': [
      'Developed in Germany for its high yields and high acidity, suitable for making the base wines for sparkling wine, although German plantings are only around 3 ha (7 acres). Sch\u00f6nhals in Rheinhessen uses it but only in a blend.',
      'Introduced to the Portuguese island of Madeira in the mid 1980s, Arnsburger is now more common on the north side of that Atlantic island than in Germany, thanks to an EU-funded project (with a German consultant). There are currently 14 ha (35 acres) used to make rather light but dull unfortified dry white wines for the local market and classified as Terras Madeirenses. When the fruit is picked fully ripe in Madeira\u2019s much hotter climate, the wines can lack freshness. It is not authorized for the production of Madeira. Recommended producers include Adega de S\u00e3o Vicente, Justino Henriques and Jo\u00e3o Mendes.',
      'In New Zealand, 5 ha (12 acres) were planted in 2007, of which only 2 ha (5 acres) remain, mainly in the Gisborne region.',
    ],
  },
  {
    name: 'ARROUYA',
    synonyms: ['Dourec Noir', 'Eremachaoua', 'Erematxahua'],
    description: 'Virtually extinct old Juran\u00e7on vine.',
    'origin and parentage': [
      'Arrouya is an old variety from the Juran\u00e7on region in the Pyr\u00e9n\u00e9es-Atlantiques, south-west France, where it was mentioned as early as 1783 (R\u00e9zeau 1997): \u2018Arrouya rouge .\u00a0.\u00a0. il m\u00fbrit tard\u2019 (\u2018red Arrouya .\u00a0.\u00a0. it ripens late\u2019), and again in many subsequent citations in the nineteenth century. The name Arrouya derives from the B\u00e9arnese dialect word arro(u)y, meaning \u2018red\u2019. Ampelographers consider that Arrouya has some morphological similarities with CAMARAOU NOIR, FER and PETIT COURBU (Bordenave et al. 2007) and belongs to the Carmenet ampelographic group (Bisson 2009; see p XXVII), together with CABERNET FRANC, which is why Arrouya was often confused with Cabernet Franc in the Hautes- and Basses-Pyr\u00e9n\u00e9es up to the end of the nineteenth century.',
    ],
    'where its grown': [
      'Isolated vines are still occasionally found in mixed plantings with Camaraou Blanc and CAMARAOU NOIR in old vineyards in the Juran\u00e7on region, France. Wines tend to be rather pale, acidic and sometimes astringent or bitter, and it is not hard to see why the variety is on the way out, even though it is still authorized in the Pyr\u00e9n\u00e9es-Atlantiques.',
    ],
  },
  {
    name: 'ARRUFIAC',
    synonyms: [
      'Arrefiat',
      'Arruffiac',
      'Arruffiat',
      'Arrufiat',
      'Bouisselet',
      'Raffiac',
      'Raffiat',
      'R\u00e9fiat',
      'Rufiat',
      'Zurizerratia',
    ],
    description:
      'South-west France relic starting to regain a little local respect.',
    'origin and parentage': [
      'Arrufiac is a traditional variety of the Juran\u00e7on region and the Vall\u00e9e de l\u2019Adour in the Pyr\u00e9n\u00e9es-Orientales, south-west France, where it was mentioned for the first time in 1802, in Flourens just east of Toulouse in the Haute-Garonne, under its old synonym Bouisselet in the proceedings of an agricultural meeting. A few years later, Baron Picot de Lapeyrouse (1814) wrote: \u2018J\u2019ai choisi dans nos c\u00e9pages les plus fins, les plus h\u00e2tifs: le bouisselet, le n\u00e9gret, le redondal, l\u2019escessalaire blanc\u2019 (\u2018I have chosen from our finest vines the earliest ripening: bouisselet, n\u00e9gret, redondal, escessalaire blanc\u2019). The name Arrufiac could derive from the Languedocien word rufe or the Gascon word arrufe, meaning \u2018rough\u2019 or \u2018proud\u2019 but also \u2018gathered\u2019 or \u2018wrinkled\u2019, perhaps hinting at the character of the resultant wine (Lavignac 2001).',
      'Recent genetic and ampelographic studies have suggested that Arrufiac could be genetically close to PETIT VERDOT (Bordenave et al. 2007). Indeed, Bisson (2009) places Arrufiac within the Carmenet ampelographic group (see p XXVII), and also indicates similarities with the Courbu group.',
      'Although they share some synonyms, Arrufiac and RAFFIAT DE MONCADE are not related.',
    ],
    'where its grown': [
      'Arrufiac is today cultivated mainly in the Pacherenc du Vic-Bilh appellation of south-west France, in which it may be blended with the principal varieties PETIT MANSENG, GROS MANSENG, COURBU BLANC, PETIT COURBU, as well as further north and west in the C\u00f4tes de Saint-Mont. Rescued from obscurity by Andr\u00e9 Dubosc of the Plaimont co-operative in the 1980s, Arrufiac is now being treated with greater respect and used in greater measure by certain producers, for example: Cru du Paradis (30%), Ch\u00e2teau Viella (20%) and Producteurs Plaimont\u2019s Saint-Mont Les Vignes Retrouv\u00e9es (20%). Wines are typically slightly tannic with fresh acidity and a hint of almonds. They can be bitter on the finish and are best in a blend. There were 93 ha (230 acres) in France in 2006.',
    ],
  },
  {
    name: 'ARVESINIADU',
    synonyms: [
      'Argu Ingianau',
      'Arvesimiadu Bianco',
      'Arvu Siniadu',
      'Uva Oschirese',
    ],
    description: 'Rare Sardinian white that disappears into blends.',
    'origin and parentage': [
      'Obscure and almost extinct variety from the Italian island of Sardegna. The earliest and only historical reference to this vigorous and late-ripening variety is found in Manca dell\u2019Arca (1780).',
    ],
    'where its grown': [
      'There are very limited plantings in the provinces of Sassari and Campidano in Sardegna, Italy, and the 2000 census recorded just 155 ha (380 acres). Arvesiniadu is generally blended with other Sardinian varieties to produce local whites but, within the IGT Isola dei Nuraghi, Mulas produce both dry and sweet varietal wines.',
    ],
  },
  {
    name: 'ARVINE',
    synonyms: ['Arvena', 'Arvina', 'Petite Arvine'],
    description:
      'The finest indigenous Valais variety making lively, sometimes intense, whites both dry and sweet.',
    'origin and parentage': [
      'Arvine is indigenous to the Valais in Switzerland, where it was first mentioned in Sion in 1602 under the dialect name Arvena, which could derive from the old Latin arvena, meaning \u2018just arrived\u2019, a name that was possibly given to this variety at its introduction or birth (Vouillamoz and Moriondo 2011).',
      'Since the late nineteenth century, it has often been called Petite Arvine (\u2018small Arvine\u2019) to distinguish it from Grosse Arvine (\u2018big Arvine\u2019), a variety that has been shown to be a progeny of R\u00c8ZE and a possible grandchild or nephew/niece of Arvine but is no longer cultivated (Vouillamoz and Moriondo 2011). No other close genetic relationships could be uncovered by DNA parentage analysis but it is possible to speculate about some distant ancestors in the Valle d\u2019Aosta, Savoie and north-eastern France.',
      'Arvine has no genetic link with Arvino Nero, a synonym of GAGLIOPPO in Calabria, or with Alvino Verde, a synonym of VERDECA in Campania, but they might all share a common etymology (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Arvine is the finest of the Valais varieties and produces wines that are nervy but also have substance, typically with flavours of grapefruit and a mineral saltiness. There were 154 ha (378 acres) in Switzerland in 2009, almost all in the Valais. Recommended producers of dry varietal wines include John and Mike Favre, Mandol\u00e9, Domaine des Muses and La Rayettaz; good sweet versions are made by producers such as G\u00e9rald Besse and La Liaudisaz (Marie-Th\u00e9r\u00e8se Chappaz).',
      'In 2010, the French CTPS (Comit\u00e9 Technique Permanent de la S\u00e9lection des Plantes Cultiv\u00e9es) authorized Arvine in France, some years after Michel Chapoutier abandoned his experimental vineyard in the northern Rh\u00f4ne, and it was included in the official register of varieties in 2011. Before that Angelo Gaja had tried his hand at Arvine in the Langhe in north-west Italy but had severe problems with coulure and broken shoots. Arvine seems happier at home. Nevertheless, there are just a few hectares in the Valle d\u2019Aosta in the far north west of Italy, where Grosjean Fr\u00e8res and Les Cr\u00eates are making good examples of varietal wines.',
    ],
  },
  {
    name: 'ASHUGHAJI',
    synonyms: ['Achougage', 'Ashugazh'],
    description: 'Minor Georgian variety producing fresh, aromatic reds.',
    'origin and parentage': [
      'Ashughaji is indigenous to Apkhazeti, north-western Georgia.',
    ],
    'where its grown': [
      'Ashughaji produces deeply coloured, aromatic, dry red wines with high acidity and is grown mainly in Apkhazeti, north-western Georgia.',
    ],
  },
  {
    name: 'ASPIRAN BOUSCHET',
    synonyms: ['Grand Noir de Laques'],
    description: 'Deeply coloured but almost extinct.',
    'origin and parentage': [
      'A Gros Bouschet \u00d7 RIVAIRENC (under the name Aspiran Noir) cross obtained in 1865 by Henri Bouschet at the Domaine de la Calmette in Mauguio near Montpellier. Aspiran Bouschet is therefore a grandchild of Gros Bouschet\u2019s parents, ARAMON NOIR and TEINTURIER.',
    ],
    'where its grown': [
      'At the turn of the century there were just a few hectares left in France according to Galet (2000), but in 2008 there were a surprising 1,912 ha (4,725 acres) in Argentina (where it is known as Aspirant Bouchet), mainly in Mendoza. Here and in Chile very small amounts may be added to deepen the colour of some blends.',
    ],
  },
  {
    name: 'ASSYRTIKO',
    synonyms: ['Assirtico', 'Assyrtico', 'Asyrtico', 'Asyrtiko'],
    description:
      'Top-quality, intense, highly structured Greek island white that retains acidity even in the heat.',
    'origin and parentage': [
      'Assyrtiko is a variety of the Greek Aegean Islands that most likely comes from Santor\u00edni. Recent DNA profiling has suggested a possible parent\u2013offspring relationship with two other Aegean varieties, GAIDOURIA (under the name Guydouria) and PLATANI (Myles et al. 2011).',
      'Although some growers refer to FlaskAssyrtiko and Arseniko simply as different clones, the former is a distinct and currently unidentified variety and the latter is virus-affected Assyrtiko (Lazarakis 2005).',
    ],
    'where its grown': [
      'Assyrtiko is most closely associated with the powerful, intense yet fresh and mineral-scented whites of the volcanic island of Santor\u00edni, although its excellent quality potential and ability to retain acidity in hot climates have encouraged growers in most parts of Greece to try their hand at this variety, despite its tendency to oxidize. There were 1,704 ha (4,211 acres) in Greece in 2008, making it the third most planted white wine variety in the country after SAVATIANO and RODITIS.',
      'On Santor\u00edni it represents around 70% of the vineyard area (Lazarakis 2005) and is used to make not only taut, mineral dry whites, sometimes softened by a small percentage of ATHIRI and/or A\u00cfDANI, but also Vinsanto (for details see A\u00efdani). Argyros, Hatzidakis and Sigalas make particularly fine examples of dry whites, sometimes oaked, along with Boutaris, Gaia and the Santor\u00edni Co-op. Assyrtiko vines on Santor\u00edni can reach a tremendous age because they are ungrafted and because of the way they are trained, the canes woven nest-like around the fruit to protect it from the wind. It is said that some vines may be as old as 500 years, although this refers to the roots since the canes are annually pruned back.',
      'When Assyrtiko is planted in Attik\u00ed or further north in Dr\u00e1ma and Halkidik\u00ed, the wines tend to be broader and more fruity but they are still both full-bodied and fresh. Here they are more often blended than is the case on Santor\u00edni. Biblia Chora, for example, blend Assyrtiko with SAUVIGNON BLANC and, in Ovilos, with S\u00c9MILLON but their Areti white is 100% Assyrtiko.',
      'It is surely only a matter of time before this exciting variety goes travelling \u2013 it has already arrived in Australia, thanks to Jim Barry Wines in the Clare Valley.',
    ],
  },
  {
    name: 'ASURETULI SHAVI',
    synonyms: ['Asuretuli', 'Schalltraube', 'Shadi Traube', 'Shala'],
    description: 'Minor Georgian variety once popular with German colonists.',
    'origin and parentage': [
      'Asuretuli Shavi, meaning \u2018black from Asureti\u2019, is indigenous to the the Kartli region of southern central Georgia, and was discovered growing wild in woods near the German settlement of Asureti by the German colonist Schall some time between 1825 and 1845, hence the synonym Schalltraube (\u2018Schall\u2019s grape\u2019). The variety was propagated mainly in German colonies prior to the colonists\u2019 expulsion in 1941 and decreased significantly thereafter (Ortoidze et al. 2010).',
    ],
    'where its grown': [
      'Asuretuli Shavi wines typically have moderate alcohol and acidity with an interesting varietal aroma, and are best drunk young, not unlike lighter styles of Beaujolais. The variety is grown mainly in the village of Asureti in Kartli, southern central Georgia (hence the name), where the Asuretian Wine Cellar produce a dry varietal version. It may also be made in a medium-dry ros\u00e9 style. There were just 5 ha (12 acres) planted in Georgia in 2004.',
    ],
  },
  {
    name: 'ATHIRI',
    synonyms: [
      'Asprathiri',
      'Asprathiro',
      'Athiri Aspro',
      'Athiri Lefko',
      'Athiri Leyko',
    ],
    description:
      'An old, easy-to-grow Greek variety producing soft, dry, fruity whites.',
    'origin and parentage': [
      'Athiri or Athiri Aspro (aspro means \u2018white\u2019) probably comes from the Aegean region of Greece. It has long been thought to be close or identical to THRAPSATHIRI but this has been contradicted by DNA profiling (Biniari and Stavrakakis 2007).',
      'Athiri Mavro, which is said to be a black mutation of Athiri Aspro (or vice versa), is found to a very limited extent in the Kykl\u00e1des (Cyclades; Manessis 2000).',
    ],
    'where its grown': [
      'Athiri\u2019s popularity is not surprising: it is easy to grow, is adaptable and produces good-quality whites even at high yields. Wines are lemony, fresh and fruity even though the acidity is quite soft and the alcohol moderate, making it a good choice to slightly soften ASSYRTIKO\u2019s steeliness on Santor\u00edni. It is authorized for quality wines in the Santor\u00edni, R\u00f3dos and C\u00f4tes de Meliton appellations as well as for various regional wines. Although it is not officially allowed in Vinsanto, many of the vineyards are interplanted so it may well be included anyway.',
      'In 2008 there were 671 ha (1,658 acres) in Greece, mainly on the southern Aegean Islands, making Athiri the second most planted white variety there after ASSYRTIKO, notably on Santor\u00edni, R\u00f3dos and Kr\u00edti (Crete). According to Lazarakis (2005), the total plantings in Greece in the early part of this century were almost 1,000 ha (2,471 acres), an indication of its spread to other parts of the country such as Lakon\u00eda (southern Pelop\u00f3nnisos/Peloponnese) and Halkidik\u00ed and Makedon\u00eda (northern mainland Greece). Tsantali\u2019s easy-drinking Athiri epitomizes the variety\u2019s fresh, fruity, crowd-pleasing style. More serious varietal wines are fairly rare but the best come from the higher vineyards on the island of R\u00f3dos, eg Emery\u2019s Mountain Slopes Athiri.',
    ],
  },
  {
    name: 'AUBIN BLANC',
    synonyms: [
      'Albin Blanc',
      'Aubain',
      'Aubier',
      'Aubin',
      'Blanc de Creu\u00eb',
      'Blanc de Magny',
      'Gros Vert de Crenay',
    ],
    description: 'Virtually extinct Lorraine speciality.',
    'origin and parentage': [
      'Aubin Blanc is an old Lorraine variety, formerly cultivated in the Moselle region, where it was first mentioned in a ruling of the parliament at Metz in August 1722 (R\u00e9zeau 1997): \u2018toutes les Vignes .\u00a0.\u00a0. seront arrach\u00e9es incessamment; s\u00e7avoir .\u00a0.\u00a0. toutes les Plantes de Raisins blancs, soit qu\u2019elles soient s\u00e9par\u00e9es ou entrem\u00eal\u00e9es, dans les Vignes, de Raisins noirs ou Auxerrois, \u00e0 la reserve des Fromentaux de Sainte Ruffine, & des Aubins des Villages de Peltre, Crepy & Magny\u2019 (\u2018all vineyards .\u00a0.\u00a0. will be incessantly pulled up; that is .\u00a0.\u00a0. all the white-berried varieties, either in separate vineyards or interplanted in the vineyards with black-berried varieties or Auxerrois, with the exception of the Fromentaux of Sainte Ruffine, & of the Aubins in the villages of Peltre, Crepy & Magny\u2019). The plural form Aubins most likely refers not only to Aubin Blanc but also to AUBIN VERT, mentioned in 1829. The name Aubin derives from the Latin albanus, meaning \u2018whitish\u2019.',
      'DNA parentage analysis has indicated that Aubin Blanc is a natural GOUAIS BLANC \u00d7 SAVAGNIN cross (Bowers et al. 2000), which makes it a sibling of PETIT MESLIER and RA\u00dcSCHLING, as well as a half-sibling of AUBIN VERT and of the numerous other progenies of either Gouais Blanc or Savagnin (see PINOT pedigree). This explains why Aubin Blanc was considered by Mouillefert (1902a) to be close to Petit Meslier and MESLIER SAINT-FRAN\u00c7OIS. Aubin Blanc belongs to the Salvanien ampelographic group (see p XXVII), named after Savagnin (Bisson 2009). Aubin Blanc has no relationship with the AUBUN variety from the Vaucluse.',
    ],
    'where its grown': [
      'This is a very rare variety with less than a hectare (2.5 acres) in France in 2006 (down from 3 ha/7 acres in 1958). It is recommended in the Meurthe-Moselle, Meuse and Moselle d\u00e9partements, and the C\u00f4tes de Toul appellation west of Nancy in Lorraine is a blend of Aubin Blanc and AUXERROIS. Wines are not particularly aromatic with only moderate acidity.',
    ],
  },
  {
    name: 'AUBIN VERT',
    synonyms: ['Blanc d\u2019Euv\u00e9zin', 'Vert Blanc'],
    description: 'Virtually extinct Lorraine variety.',
    'origin and parentage': [
      'Like AUBIN BLANC, Aubin Vert is an old variety of Lorraine, formerly cultivated in the Moselle area (near Metz), where it was first mentioned in 1722 in the plural form Aubins (see Aubin Blanc), and later in 1829 as Aubin Vert.',
      'DNA parentage analysis has established that Aubin Vert is a natural PINOT \u00d7 GOUAIS BLANC cross (Bowers et al. 1999), which makes it a sibling of CHARDONNAY, GAMAY NOIR, ALIGOT\u00c9, MELON, etc., as well as a half-sibling of AUBIN BLANC and of the numerous other progenies of Gouais Blanc and Pinot (see PINOT pedigree). Aubin Vert was included with Aubin Blanc in the Salvanien ampelographic group (see p XXVII), named after SAVAGNIN (Bisson 2009) but this is questionable now that the parentage of Aubin Vert is known.',
    ],
    'where its grown': [
      'Although this variety is authorized in the Meurthe-Moselle, Meuse and Moselle d\u00e9partements of France, plantings are negligible.',
    ],
  },
  {
    name: 'AUBUN',
    synonyms: [
      'Carignan de B\u00e9doin',
      'Carignan de Gigondas',
      'Grosse Rogettaz',
      'Morescola',
      'Murescola',
      'Moustardier',
      'Moutardier',
    ],
    description: 'Minor blending component from the southern Rh\u00f4ne.',
    'origin and parentage': [
      'The first mention of Aubun appeared in 1885 in the region of B\u00e9doin near Mont Ventoux in the Vaucluse, where it was supposedly growing as a wild grape. Like AUBIN BLANC and AUBIN VERT from Lorraine, with which it has no relationship, Aubun was named after the Latin albanus, meaning \u2018whitish\u2019, supposedly referring to a now extinct white mutation. Contrary to local belief, Aubun is not identical to COUNOISE, and both belong to the Piquepoul ampelographic group (see p XXVII).',
    ],
    'where its grown': [
      'Aubun was widely planted in France during the phylloxera infestations in the late nineteenth century because it was thought to show some resistance to this pest but it turned out that this was due simply to the fact that the local soils in which it was planted were relatively resistant to it (Galet 2000).',
      'Wines tend to be rather ordinary, sometimes like a softer version of Carignan (MAZUELO), hence the synonyms Carignan de B\u00e9doin and Carignan de Gigondas, and the variety may be better suited to ros\u00e9, such as the varietal wines produced by Domaine de Lansac north of Arles and Domaine des Moulines between Lunel and Montpellier. Antony Tortul\u2019s La Sorga L\u2019Aubunite (a clever pun on l\u2019eau b\u00e9nite, meaning \u2018holy water\u2019), however, is a Languedoc red made from 85% Aubun (biodynamically grown near Carcassonne). There were 720 ha (1,779 acres) in France in 2008, mainly in the Gard and the Aude, although it is authorized throughout the Languedoc and in Provence, including Ch\u00e2teauneuf-du-Pape. This is considerably less than the 5,882 ha (14,535 acres) of thirty years ago.',
      'Pockets of Aubun are found in California. Copain and Donkey & Goat, for example, both produce a McDowell Valley Syrah from Mendocino which is made from a field blend that includes a small amount of Aubun. The variety was part of the original collection taken by James Busby to Australia in 1833.',
    ],
  },
  {
    name: 'AURORE',
    synonyms: [
      'Aurora',
      'Feri Sz\u00f6l\u00f6',
      'Financ Sz\u00f6l\u00f6',
      'Redei',
      'Seibel 5279',
    ],
    description:
      'Complex hybrid more successful in North America than in its homeland France.',
    'origin and parentage': [
      'Albert Seibel in Aubenas south west of Valence in the Ard\u00e8che obtained this by crossing Seibel 788 \u00d7 Seibel 29, where:',
      '\u2013\u00a0\u00a0\u00a0Seibel 788 is a Sicilien \u00d7 Clairette Dor\u00e9e Ganzin hybrid (see HELIOS for the complete pedigree of Clairette Dor\u00e9e Ganzin)',
      '\u2013\u00a0\u00a0\u00a0Sicilien is a Bicane \u00d7 PASCAL BLANC cross, the former a French wine and table grape that is no longer cultivated',
      '\u2013\u00a0\u00a0\u00a0Seibel 29 is a Munson \u00d7 \u2018unknown\u2019 Vitis vinifera subsp. vinifera variety hybrid',
      '\u2013\u00a0\u00a0\u00a0Munson is a Jaeger 43 \u00d7 Vitis rupestris hybrid, with Jaeger 43 being a variety of Vitis lincecumii Buckley.',
      'It was originally bred for the table as well as for wine but it turned out to be a poor table grape because it did not travel well and the grapes tended to fall off the stems. Aurore is named after Aurora, the Roman goddess of the dawn.',
    ],
    'where its grown': [
      'According to Galet (2000), there were once 288 ha (712 acres) in France, in both the north east and the south west. It was once widely planted across the north-eastern US and Minnesota and is still found in some colder American states with short growing seasons such as New York (3,530 tons crushed in 2009, mostly for wine). In Canada most of the vines in British Columbia were pulled up at the end of the 1980s and Ontario growers have harvested just 198 tons over the last five years. Producers of varietal wines include Jewell Towne Vineyards (New Hampshire), Schilling Bridge (Nebraska) and Domaine des C\u00f4tes d\u2019Ardoise (Qu\u00e9bec). Wines are light and relatively neutral but may have a slight foxy character.',
    ],
  },
  {
    name: 'AUXERROIS',
    synonyms: [
      'Aucerot',
      'Auxera',
      'Auxerrois de Laquenexy',
      'Auxois',
      'Auzerrois Blanc',
      'Blanc de Kienzheim',
      'Ericey de la Mont\u00e9e',
      'Kleiner Heunisch',
      'Okseroa',
      'Pinot Auxerrois',
      'Riesling Jaune de la Moselle',
    ],
    description: 'Low-acid variety useful in cool climates.',
    'origin and parentage': [
      'Auxerrois is a variety from Alsace-Lorraine that was allegedly mentioned as a wine name in the thirteenth-century fable \u2018La Borgoise d\u2019Orliens\u2019: \u2018Et puis burent a grant plentez / Et des blans et des auchorrois\u2019 (\u2018And they drank large amounts / Of white wines and auchorrois\u2019). However, this reference should be treated with caution since Auxerrois is a white variety, and since the name Auxerrois has been given to several distinct varieties of north-eastern France, such as PINOT (BLANC or GRIS) and CHARDONNAY. The earliest reliable reference to this variety appeared in 1816 in the Moselle: \u2018les esp\u00e8ces de raisins de grosse race sont beaucoup moins bonnes que nos maurillons, nos francs-pineaux, nos me\u00fbniers et nos auxerrois\u2019 (\u2018the very vigorous grape varieties are far less good than our maurillons, our francs-pineaux, our me\u00fbniers and our auxerrois\u2019).',
      'DNA parentage analysis has shown that Auxerrois is one of the numerous natural progenies of PINOT and GOUAIS BLANC (Bowers et al. 1999). It is therefore a sibling of CHARDONNAY, GAMAY NOIR, ALIGOT\u00c9, etc., which explains the confusion with PINOT BLANC and CHARDONNAY as well as its synonyms Pinot Auxerrois in Alsace and Kleiner Heunisch (little Gouais Blanc) in Baden-W\u00fcrttemberg. Auxerrois is a member of the Noirien ampelographic group (see p XXVII).',
      'The name Auxerrois does not refer to the city of Auxerre in the Yonne but most likely derives from Auxois, the old name for Alsace as well as an old synonym for Auxerrois, giving further evidence for an Alsace-Lorraine origin for this variety. Curiously, Auxerrois has also been a synonym for the red COT in Cahors since at least 1589, because it was said to have been brought from Auxerre.',
    ],
    'where its grown': [
      'In 2008 there were 2,330 ha (5,758 acres) in France, mostly in Alsace, where it is the second most planted white variety after RIESLING, and the French Moselle, although there are still a few vines in the Loire. It is also significant in Luxembourg (184 ha/455 acres in 2008), can be useful in the Netherlands and a few hectares were recorded in England in 1990. Wines tend to be relatively neutral and low in acidity, a valued attribute in cool climes, but if yields are curbed, wines can be positively rich and honeyed.',
      'In Alsace, Auxerrois is rarely seen on the label, though that would be perfectly legal; instead it is generally blended into wine sold as Pinot Blanc (occasionally Clevner/Klevner), or goes into either Cr\u00e9mant or the multi-varietal Edelzwicker, to which it can add weight and substance. Bizarrely in a region which prides itself on varietal labelling, a wine labelled Pinot Blanc could comprise 100% Auxerrois. Since Auxerrois is not permitted in Alsace Grand Cru wines, some of the best sites have been grubbed up in favour of the more noble varieties. Similarly, this often hidden variety is not allowed in the sweet Alsace styles Vendange Tardive or S\u00e9lection de Grains Nobles.',
      'Alsace producers who take Auxerrois seriously include Philippe Blanck, Josmeyer, Kientzler and Louis Sipp. Armand Hurst has produced an SGN-style sweet Auxerrois.',
      'The C\u00f4tes de Toul appellation west of Nancy specifies a blend of AUBUN and Auxerrois for white wines and Auxerrois may play a supporting role to GAMAY NOIR and PINOT NOIR in the region\u2019s vin gris.',
      'Germany\u2019s 285 ha (704 acres) are mostly in Baden and the Pfalz, although there have been some recent plantings by top growers in the middle Nahe and Rheinhessen.',
      'In Canada, Ontario\u2019s plantings are in decline and in 2006 were about half what they were in 2002 (roughly 45 acres/18 ha compared with 85 acres/34 ha). There were 43 acres (17 ha) in BC in 2008.',
      'In South Africa in the 1980s it was discovered that vines purported to be early CHARDONNAY plantings were in fact Auxerrois.',
    ],
  },
  {
    name: 'AVAN\u00c0',
    synonyms: [
      'Aven\u00e0',
      'Avenai',
      'Avanale',
      'Avanas',
      'Avanato',
      'Avan\u00e8',
      'Hibou Noir',
      'Vermaglio',
    ],
    description:
      'Light, early-drinking Piemontese red, rarely made as a varietal.',
    'origin and parentage': [
      'From Val di Susa in Piemonte, north-west Italy, Avan\u00e0 was most probably the variety referred to as Avanato by the agronomist Croce in 1606. Some early authors found morphological similarities with the French varieties GAMAY NOIR (Gamay d\u2019Orl\u00e9ans) and Troyen (Troyan), the latter no longer cultivated. Although DNA profiling has not confirmed this precise relationship, it has proved the link with France since Avan\u00e0 is identical to Hibou Noir, an old variety from Savoie and Dauphin\u00e9 that has almost disappeared (Schneider et al. 2001). In addition, DNA parentage analysis strongly suggests that Avan\u00e0 is either a parent or an offspring of CACABOU\u00c9, another old, and almost extinct, Savoie variety, and a grandchild, nephew/niece or half-sibling of AMIGNE, an old variety from the Valais in Switzerland (Vouillamoz and Moriondo 2011).',
      'These genetic links between Savoie, the Valais and Piemonte are not really surprising given that Piemonte was part of the Duchy of Savoy, also including the Valais, between 1418 and 1713, when Hibou Noir could have been brought to Piemonte and have taken the name Avan\u00e0, or vice versa. Moreover, Avan\u00e0 is mostly located in the Val di Susa, the valley linking Torino to the French Savoie and Hautes-Alpes.',
    ],
    'where its grown': [
      'Still cultivated today in north-west Italy, often on pergolas, in the Val di Susa and Val Chisone in the Pinerolese in Piemonte, Avan\u00e0 is usually blended with other local varieties (BARBERA, PERSAN under the name Becu\u00e9t, FREISA, NERETTA CUNEESE). Since 1996, Avan\u00e0 has been authorized in the Pinerolese DOC, in particular in the Pinerolese\u2019s traditional blend called Ram\u00ece, which is light, fruity, crisply fresh and slightly astringent. Since 1997, it has been authorized on its own or in a blend (with Barbera, DOLCETTO, Neretta Cuneese, etc.) in the Valsusa DOC. A few wineries such as Sibille, Martina and Clarea make a 100% Avan\u00e0. It produces fresh, light-bodied wines with light tannins and moderate acidity for early drinking.',
      'Just over 30 ha (75 acres) were recorded in the 2000 census.',
    ],
  },
  {
    name: 'AVARENGO',
    synonyms: [
      'Amarene',
      'Avarenc',
      'Avarena',
      'Must\u00e8r',
      'Riondosca',
      'Riundasca',
    ],
    description: 'Low-yielding, perfumed Piemontese red.',
    'origin and parentage': [
      'Avarengo is an old variety from Val Chisone to Pomaretto in the Pinerolese area of Piemonte, north-west Italy. DNA parentage analysis has clearly established a parent\u2013offspring relationship between Avarengo and GRISA NERA, another old Piemontese variety, thus anchoring the origin of both grapes in this area (Anna Schneider and Jos\u00e9 Vouillamoz, unpublished data). In addition, DNA parentage analysis suggests a close genetic link between Avarengo and NER D\u2019ALA from northern Piemonte (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Blended with other local varieties in Pinerolese Ram\u00ece DOC (minimum 15%), north-west Italy. A few vineyards remain in the Canavese but it is being slowly abandoned because of its low productivity, as suggested by the derivation of its name from avaro, Latin for \u2018stingy\u2019. There were 1,680 ha (4,151 acres) recorded in the Italian census of 2000. The wines are fresh, pale and perfumed.',
    ],
  },
  {
    name: 'AVASIRKHVA',
    synonyms: ['Ajiche', 'Ajishi', 'Auasirkhva', 'Avasarkhva', 'Avassirkhva'],
    description: 'Rare Georgian variety grown in Apkhazeti.',
    'origin and parentage': [
      'Avasirkhva is indigenous to Apkhazeti, north-western Georgia.',
    ],
    'where its grown': [
      'Although Avasirkhva shows very good potential for high-quality dry whites in a subtropical climate, there were just 7 ha (17 acres) in the Apkhazeti region of Georgia in 2004. The wines are mostly produced for home consumption.',
    ],
  },
  {
    name: 'AVESSO',
    synonyms: ['Bornal', 'Born\u00e3o', 'Borral'],
    description:
      'Portuguese variety with more body than others making Vinho Verde.',
    'origin and parentage': [
      'Avesso, meaning \u2018contrary\u2019 in Portuguese (Mayson 2003), perhaps because of its ability to achieve higher alcohol levels than other varieties in its Vinho Verde homeland in northern Portugal, was first mentioned by Menezes in 1896 (Cunha et al. 2009). This reference and the low level of genetic diversity (Rolando Faustino, personal communication) suggest it is not a particularly old variety.',
    ],
    'where its grown': [
      'Avesso is found in the Minho region of northern Portugal, predominantly in Bai\u00e3o, Cinf\u00e3es and Resende near the lower reaches of the Douro, where it is an important ingredient in the Vinho Verde appellation and can often achieve higher alcohol levels (12\u201313%) than the 11.5% maximum permitted in the regulations (Mayson 2003). This fuller body is accompanied by fresh acidity and fruit, leaning towards stone fruit and almost tropical-fruit flavours. Total plantings in 2010 were 730 ha (1,804 acres). Recommended producers of varietal wines include Anselmo Mendes, Quinta do Ferro and Casa Santa Eul\u00e1lia, all in the Vinho Verde region.',
    ],
  },
  {
    name: 'AVGOUSTIATIS',
    synonyms: ['Avgoustella', 'Agoustiates'],
    description:
      'Minor Greek variety found mainly on the island of Z\u00e1kynthos and in the western Pelop\u00f3nnisos (Peloponnese).',
    'origin and parentage': [
      'Avgoustiatis probably comes from the Greek Ionian Islands or the Kykl\u00e1des (Cyclades). It was named after the month of August, when the berries ripen. Although some of its synonyms in the Vitis International Variety Catalogue overlap with those given for the white-skinned variety GOUSTOLIDI, they do not appear to be related. DNA profiling with only eight markers has suggested a possible genetic relationship with MAVRODAFNI (Boutaris 2000) but this would need to be confirmed with many more markers.',
    ],
    'where its grown': [
      'Avgoustiatis produces deeply coloured wines with soft tannins and highish acidity. It is grown in Greece on the island of Z\u00e1kynthos and slightly further east on the western side of the Pelop\u00f3nnisos, near P\u00fdrgos, where Mercouri have in the past made a varietal wine and currently make a blend with MONASTRELL (Mourv\u00e8dre). The nearby estate of Brintziki also make a varietal wine and Comoutos (on Z\u00e1kynthos) include Avgoustiatis in their red blends. In 2008 it was the second most planted red grape on the Ionian Islands (108 ha/267 acres) after KORINTHIAKI. Also found on the island of K\u00e9rkyra (Corfu).',
    ],
  },
  {
    name: 'AZAL',
    synonyms: [
      'Asal',
      'Azal Branco',
      'Asal da Lixa',
      'Azal da Lixa',
      'Carvalha',
      'Carvalhal',
      'Gadelhudo',
      'Pinheira',
    ],
    description:
      'High-acid variety losing ground but still widespread in northern Portugal.',
    'origin and parentage': [
      'Azal, probably from the Vinho Verde region in northern Portugal, is genetically closely related to TRAJADURA, ARINTO DE BUCELAS and LOUREIRO, all from northern Portugal or north-western Spain (Lopes et al. 1999; Almadanim et al. 2007; Castro et al. 2011).',
      'Azal is not a white mutation of Azal Tinto, which is a synonym for AMARAL (Castro et al. 2011).',
    ],
    'where its grown': [
      'Azal produces delicate, fresh wines with flavours of lemon and green apple but fear of rot sometimes spurs growers to pick too early, resulting in screeching acidity. It is found mainly in the Penafiel, Amarante and Basto subregions of Vinho Verde in the Minho region of northern Portugal. In the early twentieth century it was the most important light-skinned variety here but the area had declined to 1,480 ha (3,657 acres) by 2010, probably because it is one of the least interesting in the Vinho Verde appellation. Recommended producers include Quinta de Linhares, Casa de Vilacetinho and Manuel Nunes da Costa Camiz\u00e3o.',
    ],
  },
  {
    name: 'B\u0102BEASC\u0102 NEAGR\u0102',
    synonyms: [
      'As\u00eel Kara',
      'B\u0103beasc\u0103',
      'C\u0103ld\u0103ru\u015f\u0103',
      'Chernyi Redkii',
      'Cr\u0103can\u0103',
      'Cr\u0103c\u0103nat\u0103',
      'Poama Rar\u0103 Neagr\u0103',
      'Rar\u0103neagr\u0103',
      'Rar\u0103 Neagr\u0103',
      'R\u0103\u015fchirat\u0103',
      'Rastriopa',
      'Serecsia Ciornaia',
      'Sereksia',
      'Sereksiya',
    ],
    description: 'High-acid Romanian variety producing fairy light reds.',
    'origin and parentage': [
      'B\u0103beasc\u0103 Neagr\u0103, literally \u2018grandmother\u2019s black\u2019, is a very old Romanian variety that most likely originated in the Nicore\u015fti vineyards in the county of Gala\u0163i in Romanian Moldova, bordering Ukraine, where it was allegedly mentioned in documents as early as the fourteenth century and where it still shows significant intravarietal diversity (an indication of its age), including colour mutations with white (B\u0103beasc\u0103 Alb\u0103) and green-grey or pink berries (B\u0103beasc\u0103 Gri) (Dejeu 2004).',
    ],
    'where its grown': [
      'Wines are typically high in acidity, not very deeply coloured, with red-fruit flavours, mostly sour cherry. In Romania, there were 4,516 ha (11,159 acres) in 2008, meaning it was quite widely planted but with the majority in Gala\u0163i in the east. Most wines are ordinary and consumed locally but some higher-quality examples come from Nicore\u015fti and Odobe\u0219ti, the former giving its name to an appellation for this variety. Senator are unusual in producing a more serious varietal wine from the sandy terraces north of the Dun\u0103rea (Danube) in the far south of the country.',
      'Senator have just a few hectares of the pink-berried B\u0103beasc\u0103 Gri in Hu\u015fi in the far east of Romania and make a clean, very fresh white wine with more mineral than fruit flavours and a note of lime on the finish.',
      'The Republic of Moldova had 80 ha (198 acres) of Rar\u0103 Neagr\u0103 in 2009. Recommended producers include Dionysos Mereni. According to Galet (2000), also planted in Ukraine.',
    ],
  },
  {
    name: 'BABI\u0106',
    synonyms: ['Babi\u0107 Crni', 'Rogozni\u010dka'],
    description:
      'Lively and potentially high-quality Croatian variety well suited to barrel ageing.',
    'origin and parentage': [
      'Babi\u0107 comes from the southern part of the Dalmatian coast, where it has been cultivated mainly near \u0160ibenik and Primo\u0161ten, north west of Split. Recent DNA profiling has shown that Rogozni\u010dka is a local synonym for Babi\u0107 but that, contrary to common belief, BABICA, a local variety grown in the Ka\u0161tela area, is not a synonym for Babi\u0107 (Zduni\u0107, Peji\u0107 et al. 2008). In addition, parentage analysis has found a possible parent\u2013offspring relationship between Babi\u0107 and DOBRI\u010cI\u0106 and has shown that Babica is a progeny of PLAVAC MALI (Zduni\u0107, Peji\u0107 et al. 2008). Since Dobri\u010di\u0107 is a parent of Plavac Mali, Babi\u0107 is either a grandparent or half-sibling of Plavac Mali, and either a great-grandparent or an uncle/aunt of Babica (see TRIBIDRAG for the full pedigree).',
    ],
    'where its grown': [
      'The quality of the wines varies enormously from site to site. If the vines are planted on deep, fertile soils and yields are high, the wine is rather ordinary. But with lower yields and in the best, old-vine, windy sites in Primo\u0161ten, for example, wines are deeply coloured and full-bodied with dense, peppery berry fruit and soft tannins, making them approachable in youth, although they tend to be much more interesting after some time in oak. There were around 600 ha (1,483 acres) in Croatia in 2009, 1.85% of the vineyard area, mostly in the \u0160ibenik area north west of Split, although it is also found in old interplanted vineyards in the rest of Dalmacija (Dalmatia), including the island of Kor\u010dula. The best wines come from the rocky terraces of Primo\u0161ten, between Split and \u0160ibenik. Recommended producers include Leo Gracin, Pili\u017eota, Suha Punta and Tomi\u0107.',
    ],
  },
  {
    name: 'BABICA',
    synonyms: ['Ka\u0161telanka'],
    description: 'Rare variety from Croatia\u2019s Adriatic coast.',
    'origin and parentage': [
      'Babica is an indigenous variety from the Ka\u0161tela region north of Split on the Adriatic coast of Croatia. Recent DNA profiling has shown that Babica is not a synonym for BABI\u0106, and DNA parentage analysis has found that Babica is a progeny of PLAVAC MALI (Zduni\u0107, Peji\u0107 et al. 2008), which makes it a grandchild of TRIBIDRAG (see TRIBIDRAG for the pedigree diagram).',
    ],
    'where its grown': [
      'In the last fifty years, Babica has become the main variety in Ka\u0161tela, around the town of Split, Croatia. It is also found to a lesser extent further north along the coast in Trogir, Benkovac and Zadar (Zduni\u0107 2005). It is one of the varieties used in the Ka\u0161tela region to produce a light red wine known as opolo, often made from PLAVAC MALI or a blend of dark-skinned varieties, opolo being a wine description rather than an appellation. Babica, along with several other autochthonous varieties, is part of a revitalization project involving clonal selection and replanting.',
    ],
  },
  {
    name: 'BACCHUS',
    synonyms: ['Bacchus Weiss', 'Geilweilerhof 33-29-133'],
    description:
      'One of the most important German crosses producing highly aromatic whites, not least in England.',
    'origin and parentage': [
      'This (SILVANER \u00d7 RIESLING) \u00d7 M\u00dcLLER-THURGAU cross was bred in 1933 by Peter Morio of the Geilweilerhof research centre at Siebeldingen in the Pfalz in Germany (now part of the Julius K\u00fchn-Institut). It was authorized in Germany in 1972. Its parentage was confirmed by DNA profiling (Grando and Frisinghelli 1998). Bacchus and OPTIMA have the same parents, including Riesling twice, and are therefore siblings with a significant level of consanguinity. The name obviously refers to the Greek God of wine. Bacchus was subsequently used to breed BIRSTALER MUSKAT, PHOENIX and R\u00c9SELLE.',
    ],
    'where its grown': [
      'Germany has 2,016 ha (4,982 acres), mainly in Rheinhessen and Franken but with smaller areas in the Nahe and the Pfalz, even smaller in Baden. The area devoted to Bacchus has declined quite significantly since 1990 when plantings reached their peak at around 3,500 ha (8,649 acres). When fully ripe, the grapes produce highly aromatic wines which bear some grassy similarity to SAUVIGNON BLANC, though they tend to be more floral and exuberant, sometimes with strong notes of elderflower. In Germany it is often blended with the more grapey M\u00dcLLER-THURGAU in basic off-dry whites but recommended varietal wines are made in Franken by Schmitt\u2019s Kinder and Waldemar Braun.',
      'It was first planted in England in 1973 and became a recommended variety in 1998. There were around 130 ha (321 acres) in 2009, which is just under 10% of the total UK vineyard, making it the second most planted white variety in England and Wales after CHARDONNAY. It is most often made as a varietal wine and is valued for its ability to produce good yields of fully ripe grapes and make distinctively aromatic fresh wines, the UK\u2019s aromatic alternative to SAUVIGNON BLANC. The cooler UK climate means the wines tend to have better freshness than in Germany. Camel Valley, Chapel Down and Three Choirs all make good examples.',
      'It is also found to a very limited extent in Switzerland (fewer than 2 ha/5 acres), where Jauslin make a varietal wine. There are limited plantings in Japan (25 ha/62 acres in 2009), eg by Furano Winery in Hokkaido.',
    ],
  },
  {
    name: 'BACHET NOIR',
    synonyms: ['Bachey', 'Fran\u00e7ois Noir', 'Gris Bachet'],
    description: 'Ancient and almost extinct.',
    'origin and parentage': [
      'Bachet Noir is an old and almost extinct variety from the Aube d\u00e9partement in the south of the Champagne region where it was first mentioned in a less than complimentary way in 1821: \u2018Le fran\u00e7ais, ou bachet rouge, passe pour donner du vin mediocre\u2019 (\u2018The fran\u00e7ais, or bachet rouge, is said to give a mediocre wine\u2019). It has been suggested that the name Bachet could derive from Buchey, a village in the Haute-Marne. However, it is more likely that this variety was named after the family name Bachet that is widespread in north-eastern France.',
      'Ampelographers have placed Bachet Noir in the Tressot group (see p XXVII; Bisson 2009). However, DNA parentage analysis contradicts this in establishing that Bachet Noir is a natural PINOT \u00d7 GOUAIS BLANC cross, just like its siblings CHARDONNAY, GAMAY NOIR, ALIGOT\u00c9, etc. (Bowers et al. 1999). These findings also explain its morphological similarity with its brother BEAUNOIR.',
    ],
    'where its grown': [
      'Wines are reputed to have quite high alcohol and acidity levels and a certain herbaceous character. Even though the variety is officially authorized in the Aube, it has more or less disappeared, with less than a hectare (2.5 acres) in France in 2006.',
    ],
  },
  {
    name: 'BACO BLANC',
    synonyms: ['Baco 22 A', 'Maurice Baco', 'Piquepoul du Gers'],
    description:
      'Light-skinned French hybrid still allowed in Armagnac but declining.',
    'origin and parentage': [
      'A hybrid obtained in 1898 by Fran\u00e7ois Baco in B\u00e9lus in the Landes, south-west France, by crossing FOLLE BLANCHE with NOAH. It is therefore a half-sibling of BACO NOIR. Both crosses were made with the intention of creating a hybrid with resistance to black rot. Its synonym Maurice Baco was given by Fran\u00e7ois Baco in memory of his son, who died at seventeen, whereas Piquepoul du Gers is a name used in the Charente misleadingly suggesting all-vinifera origins.',
    ],
    'where its grown': [
      'Baco Blanc is the only hybrid used in an appellation d\u2019origine contr\u00f4l\u00e9e: it is one of the ten varieties authorized to make the base wine for armagnac, the dark spirit from south-west France. There were 827 ha (2,044 acres) in France in 2008 but it is gradually declining in favour of Ugni Blanc (TREBBIANO TOSCANO). However, the threat to ban it altogether was reversed in 2005. It was once grown in New Zealand to a limited extent.',
    ],
  },
  {
    name: 'BACO NOIR',
    synonyms: [
      'Baco 1',
      'Baco 24-23',
      'Bacoi',
      'Bago',
      'Bako Speiskii',
      'Bakon',
    ],
    description:
      'Dark-skinned French hybrid faring better across the Atlantic than at home.',
    'origin and parentage': [
      'Hybrid obtained in 1902 by Fran\u00e7ois Baco in B\u00e9lus in the Landes, south-west France, by crossing FOLLE BLANCHE (under the name Piquepoul du Gers) with Vitis riparia Grand Glabre. It is therefore a half-sibling of BACO BLANC. However, as noted by Galet (1988), since Vitis riparia Grand Glabre has female-only flowers with sterile pollen, Darrigan considered this hybrid to be FOLLE BLANCHE \u00d7 Vitis riparia Grand Glabre + Vitis riparia ordinaire, which suggests that Baco used a mix of Vitis riparia pollen taken from two distinct varieties.',
      'It was first called Baco 24-23 and then renamed Baco 1 by Prosper Gervais after his visit to Baco in 1910. It has been known as Baco Noir since 1964 (Galet 1988). It was used to breed GR7.',
    ],
    'where its grown': [
      'Baco Noir was at one time quite widely planted in France, in regions as diverse as Burgundy, Anjou and its home in the Landes, but the area has dwindled considerably, down to around 11 ha (27 acres) in 2008 because it is not included in the French register of authorized varieties. There\u2019s just 1 ha (2.5 acres) over the border in Switzerland. Varietal wines are deeply coloured and, more importantly, they are not marked by the foxy flavours generally associated with North American vine species. Rare examples are made by Bosshardt Weine in Switzerland and by Domaine Mondon-Demeur\u00e9 in France.',
      'The variety has fared better in cooler parts of North America since its introduction in the early 1950s. New York State crushed 820 tons in 2009 (almost double the figure for 2007) in both the Hudson River and Finger Lakes regions and in southern Oregon, but the variety is most popular in Ontario, Canada, where there were around 565 acres (229 ha) in 2006. Notable producers of these fresh, berry-flavoured reds include Henry of Pelham and Peller Estates. MAR\u00c9CHAL FOCH is a more popular hybrid in British Columbia but Summerhill are still making a varietal Baco Noir there. It is also found in both Novia Scotia and Qu\u00e9bec.',
    ],
  },
  {
    name: 'BAGA',
    synonyms: [
      'Baga de Louro',
      'Carrasquenho',
      'Carrego Burros',
      'Poeirinho',
      'Tinta Bairrada',
      'Tinta da Bairrada',
      'Tinta de Baga',
    ],
    description:
      'Controversial, demanding northern Portuguese variety capable of making great, ageworthy wines.',
    'origin and parentage': [
      'Baga probably originates from D\u00e3o in the Beiras region of Portugal, where it shows the greatest morphological diversity, and from there it spread west towards the coast and the neighbouring Barraida region, where the vines show much less variability (Rolando Faustino, personal communication). The name Baga simply means \u2018berry\u2019 in Portuguese.',
    ],
    'where its grown': [
      'Baga tends to be either loved or loathed \u2013 by growers as well as consumers. Growers face the dilemma of leaving the grapes on the vine long enough to achieve full ripeness, particularly of the tannins, versus the high risk of rot if it rains in September, as it often does in this Atlantic-influenced part of Portugal. Baga can therefore make the best of wines and the worst of wines: the best, generally those allowed to reach around 13% alcohol, have aromas of forest fruits when young but develop depth and complexity of black plums, herbs, olives, smoke and tobacco as the tannins soften in the bottle; the worst are thin, pale, green and astringent, especially if the wines have been fermented on the stalks, as used to be the norm. Early-picked fruit may nevertheless be useful for ros\u00e9 or as the base wine for fizz.',
      'Baga dominates the Bairrada region and DOC, the part of Beiras closest to the coast where Baga represents as much as 90% of the region\u2019s red grapes, producing its best wines in calcareous-clay soils. In D\u00e3o, further inland and higher, it is still the predominant red grape variety although the area is now declining and Baga is not authorized in D\u00e3o DOC wines, only in IGP Beiras. It is also planted elsewhere in Beiras as well as in the northern part of the Lisboa region and the Tejo, and is authorized for table wines in the Douro. There were 9,885 ha (24,426 acres) in total in Portugal in 2010.',
      'One of the most passionate and successful proponents of Baga is Lu\u00eds Pato, who makes not only fine ageworthy reds, one \u2013 Quinta do Ribeirinho P\u00e9 Franco \u2013 from pre-phylloxera vines, but also an interesting sparkling red Baga. Other recommended producers in Bairrada include Campolargo, Casa de Saima, Caves S\u00e3o Jo\u00e3o, Adega Cooperativa de Cantanhede, Dulcinea dos Santos Ferreira and Kompassus.',
    ],
  },
  {
    name: 'BAGRINA',
    synonyms: [
      'Bagrina Crvena',
      'Bagrina Krajinska',
      'Bagrina Ro\u0161ie',
      'Braghina',
      'Braghin\u0103',
      'Braghin\u0103 de Dr\u0103g\u0103\u0219ani',
      'Bragina',
      'Bragina Rara',
    ],
    description:
      'Pink-skinned Balkan variety making fresh and ageworthy whites.',
    'origin and parentage': [
      'Bagrina is a very old Balkan variety, possibly originating from the Banat, the historical region that is today part of Romania, Serbia and Hungary although it was once all part of Hungary.',
    ],
    'where its grown': [
      'Bagrina is found mostly in Dr\u0103g\u0103\u015fani in south-west Romania, in Timok in eastern Serbia and, to a lesser extent, in Bulgaria. It needs to be planted near another variety that will act as a pollinator. Wines are said to be fresh and harmonious and to age well.',
    ],
  },
  {
    name: 'BALZAC BLANC',
    synonyms: [
      'Balzard Blanc',
      'Balzat',
      'Blanc Limousin',
      'Margnac Blanc',
      'Ressi\u00e8re',
    ],
    description: 'Almost extinct variety from Cognac country.',
    'origin and parentage': [
      'Balzac Blanc was first mentioned in the Charente by Boutard (1842), who considered it to be a variant of Balzac Noir (a synonym of MONASTRELL). However, this has been disproved by DNA profiling because Balzac Blanc has been shown to be a natural GOUAIS BLANC \u00d7 CHENIN BLANC cross, just like its siblings MESLIER SAINT-FRAN\u00c7OIS and COLOMBARD (Bowers et al. 2000). Balzac Blanc is morphologically similar to Colombard and is a member of the Folle ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Balzac Blanc is virtually extinct and has never been included in the official register of varieties in France.',
    ],
  },
  {
    name: 'BARATUCIAT',
    synonyms: ['Bertacuci\u00e0t'],
    description:
      'Rare but recovered Piemontese white that tastes a bit like Sauvignon Blanc.',
    'origin and parentage': [
      'From the Val di Susa in Piemonte, north-west Italy, Baratuciat does not appear in local historical or ampelographic documents but was recovered here a few years ago. Its strange name supposedly comes from the local dialect meaning \u2018cat\u2019s testicles\u2019.',
    ],
    'where its grown': [
      'Baratuciat, used for both table and wine grapes, is traditionally and exclusively cultivated in the Val di Susa, mainly in Almese, Villardora, Rubiana, Rosta and Buttigliera in north-west Italy. Until recently, it was grown and vinified on a very small scale, for home consumption, sometimes resulting in good late-harvest wines. However, since the early 2000s, Giorgio Falca in Almese has been producing a commercial bottling, albeit in tiny quantities, and the variety entered the official register in 2008, so other examples may follow. The wine\u2019s aromas can be quite similar to those of SAUVIGNON BLANC (box and elderflower), with a slightly bitter finish.',
    ],
  },
  {
    name: 'BARBAROUX',
    synonyms: ['Barbarons', 'Grec Rose', 'Rouss\u00e9e'],
    description:
      'Almost extinct, relatively undistinguished pink-skinned Proven\u00e7al variety.',
    'origin and parentage': [
      'Barbaroux is an old table and wine grape already cited in 1667 in the area of Toulon in Provence, southern France, under its synonym Barbarons: \u2018le Maroquin ou barbarons, est un gros Raisin violet, qui a des grappes d\u2019une grosseur extraordinaire\u2019 (\u2018Maroquin or barbarons is a big violet-berried grape that has extraordinarily large bunches\u2019). Several other references were recorded between the seventeenth and nineteenth centuries in Provence, where its modern spelling first appeared in 1715. The name Barbaroux supposedly derives from the Provence dialect barbarous, meaning \u2018variety with reddish berries\u2019.',
      'DNA profiling recently showed that Barbaroux is different from all the various grape varieties collectively called BARBAROSSA in Liguria and in Piemonte (Torello Marinoni, Raimondi, Ruffa et al. 2009). However, it is not yet known whether Barbaroux is identical to the variety cultivated on the French island of Corse under the name Barbarossa. It should also be noted that Barbaroux is sometimes called G\u00e4nsf\u00fcssler, although it is not genetically related to true G\u00c4NSF\u00dcSSER from Rheinland-Pfalz in Germany (Vouillamoz).',
    ],
    'where its grown': [
      'Barbaroux vineyards are becoming increasingly rare in Provence, France, and there remained just 38 ha (94 acres) in 2008. Wines are generally deep gold in colour, aromatic but light and soft. The variety is authorized as a minor component in the red and ros\u00e9 versions of the Cassis appellation. It may also play a minor role in C\u00f4tes de Provence red and ros\u00e9 but only if the vines were planted before 1994, suggesting the variety\u2019s demise is pretty certain.',
    ],
  },
  {
    name: 'BARBERA',
    synonyms: [
      'Barbera a Peduncolo Rosso',
      'Barbera a Raspo Verde',
      'Barbera Amaro',
      'Barbera d\u2019Asti',
      'Barbera Dolce',
      'Barbera Fina',
      'Barbera Grossa',
      'Barbera Nera',
      'Barbera Nostrana',
      'Barbera Vera',
      'Barberone',
      'Gaietto',
      'Lombardesca',
      'Sciaa',
    ],
    description:
      'With origins still unknown, this versatile variety makes crisp, sweet Italian reds in a wide range of styles and locations.',
    'origin and parentage': [
      'Some authors have suggested that the name Barbera derives from Vinum Berberis, a medieval fermented drink made from barberry with an intense red colour and an acidic and astringent taste, a bit like Barbera wines.',
      'Barbera is usually believed to have originated in the hills of Monferrato in central Piemonte (see below). However, to the best of our knowledge, the first reliable reference to the name Barbera came at the end of the eighteenth century, when Conte Nuvolone Pergamo (1787\u201398) mentioned a variety known as Barbera near Asti in north-west Italy, as Ughetta near Vercelli and in the Canavese, and as VESPOLINA near Novara. (Ughetta is used as a synonym for both Barbera and Vespolina, which are completely distinct varieties.) The Count also mentioned a Vitis vinifera Montisferratensis that was often identified with Barbera, which is doubtful because this geographical name could be applied to any other historical grape from the same region (eg FREISA).',
      'Since the nineteenth century, Barbera has often been described as typical of the Asti region. However, it didn\u2019t become important in Piemonte as a whole until after phylloxera. The lack of trustworthy records for Barbera in Piemonte before 1798 and its relatively recent expansion suggest that its origin might be elsewhere. Indeed, a recent DNA study unexpectedly showed that Barbera bears little relation to the other grapes of Piemonte, which suggests that Barbera was probably introduced to the region relatively recently (Schneider et al. 2003). In addition, the lack of any other record of Barbera in the rest of Italy before the eighteenth century suggests that it began life relatively recently through a natural cross in a vineyard somewhere, though probably not in Piemonte, for the reasons given above. Additional genetic research is needed to uncover Barbera\u2019s birthplace.',
      'DNA profiling has also shown that BARBERA BIANCA and BARBERA DEL SANNIO are not related to Barbera (Sefc et al. 2000; Costantini et al. 2005). In Piemonte, Barbera is also distinct from the Barber\u00f9n scattered in old vineyards (Schneider and Mannini 2006).',
      'Barbera was used to breed ALBAROSSA, CORNAREA, ERVI, INCROCIO TERZI 1, NEBBIERA, NIGRA, PRODEST, SAN MICHELE and SOPERGA.',
    ],
    'where its grown': [
      'Barbera is the most typical and widespread variety in Piemonte. It is also cultivated in Lombardia, Emilia-Romagna as well as in some spots in southern Italy. The Italian census of 2000 shows total plantings of 28,365 ha (70,090 acres), a dramatic decrease since the mid-1980s when more than thirty people died after drinking methanol-enhanced Barbera, but it is still Italy\u2019s third most planted black variety after SANGIOVESE and MONTEPULCIANO. More than 60% of the total is in Piemonte.',
      'At their best, Barbera wines are deeply coloured, fresh and relatively soft wines with bright cherry fruit flavours.',
      'In its Piemonte heartland, Barbera gives its name to Barbera d\u2019Asti DOCG (elevated from the 2008 vintage and allowing up to 15% FREISA, GRIGNOLINO and/or DOLCETTO), Barbera d\u2019Alba DOC and Barbera di Monferrato DOC (which also allows up to 15% Freisa, Grignolino and/or Dolcetto). The best areas are thought to be the hills immediately to the north and south of Alba and Monforte d\u2019Alba in the Alba DOC and, in the province of Asti, the area from Nizza Monferrato north west towards Vinchio, Castelnuovo Calcea, Agliano, Belveglio and Rocchetta Tanaro. In recognition of this, Nizza, Tinella and Colli Astiano (or simply Astiano) have been recognized as specific subzones of Barbera d\u2019Alba, and Barbera Monferrato Superiore had been granted DOCG status from the 2008 vintage. Growers in Asti argue that Barbera d\u2019Asti can be superior to the generally higher-priced Barbera d\u2019Alba because the best vineyards around the town of Alba are given over to NEBBIOLO (for the likes of Barolo and Barbaresco), whereas those around Asti are more likely to be planted with the local speciality Barbera.',
      'Where the vine is overcropped (the yield allowances are generous \u2013 up to 70 hl/ha in Alba), the wines can be thin and mean. However, Barbera has been taken more seriously in the last twenty or so years and is now made in a wide range of styles, from cheap, young, simple, tart and fruity to far more expensive, dense oaked versions that benefit from ageing. This is partly due to the size and heterogeneity of the area but also to the divergent ambitions of the producers. The French wine consultant \u00c9mile Peynaud had suggested the use of oak in the 1970s but the first to experiment with this style was Giacomo Bologna with his pioneering Bricco dell\u2019Uccellone. The use of new barriques \u2013 not to mention the more concentrated wines that tend to accompany this approach \u2013 certainly firms up the structure of such wines and adds extra body and spicy flavours but it is not universally admired and tends to significantly change the more traditional bright cherry flavours of the variety. The pure sweet fruit of the Barbera grape is arguably more apparent in less heavily oaked examples.',
      'Most producers of great Barolo and Barbaresco in Piemonte are also able to produce fine Barbera so there is a host of possibilities (see NEBBIOLO), but some of those whose Barberas are obviously superior are Elio Altare, Domenico Clerico, Conterno-Fantino, Corregia and Scavino.',
      'In Lombardia, and more specifically in Oltrep\u00f2 Pavese, Barbera is widely planted and produces easy varietal wines, some of them fizzy, or is often blended with CROATINA or BONARDA PIEMONTESE. It is also permitted though used less and less in the Terre di Franciacorta DOC and appears in many a vino da tavola. In Emilia-Romagna, it constitutes 55\u201370% of the Guttornio version of Colli Piacentini, the hills above Piacenza, up to 60% in the Novello version and 85\u2013100% in the varietal wines. Lighter versions, frequently fizzy, are produced in the Colli Bolognesi and Colli di Parma. In central and southern Italy its role is generally to add acidity to blends.',
      'Further from home, Italian immigrants made sure Barbera had a home in California (more than 17,300 acres/7,000 ha in 2008). It has proved more popular than the noble NEBBIOLO in California with older vines in the Sierra Foothills proving particularly successful, as witness the wines of Yorba and Jeff Rundquist. The variety benefited from the Cal-Ital vogue.',
      'Argentina had nearly 800 ha (1,977 acres) in 2007, with Norton making one of the better versions.',
      'Along with several other Italian varieties, Barbera\u2019s reputation has recently begun to climb in Australia, despite slow progress since it was introduced in the 1960s. Recommended producers include Brown Bros, Gary Crittenden, Dal Zotto, Gapsted, Mount Langi Ghiran and Michelini. Centennial Vineyards in New South Wales make an Amarone-style wine.',
      'Limited plantings are also found in South Africa (50 ha/124 acres in 2008), Slovenia (139 ha/343 acres), Greece (0.5 ha/1.24 acres on Alpha Estate in Makedon\u00eda) and Israel.',
    ],
  },
  {
    name: 'BARBERA BIANCA',
    synonyms: ['Bertolino', 'Peis\u00ecn'],
    description: 'Rare Piemontese variety unrelated to dark-skinned Barbera.',
    'origin and parentage': [
      'Barbera Bianca supposedly has clusters and berry shape similar to those of the red BARBERA, as well as similarly high acidity. However, it has a distinct DNA profile and therefore cannot be considered a colour mutation of it (Sefc et al. 2000; Schneider and Mannini 2006). Barbera Bianca has been known in Piemonte in north-west Italy since only 1825.',
    ],
    'where its grown': [
      'Barbera Bianca is now cultivated mainly in old vineyards in the Acqui and Alessandria regions in Piemonte, north-west Italy, where it is allowed in the Colli Tortonesi Bianco DOC. Barbera Bianca is usually blended with MUSCAT BLANC \u00c0 PETITS GRAINS (Moscato) and other local varieties such as CORTESE, VERMENTINO (Favorita) and TIMORASSO. Some producers use it for a light sparkling wine because of its high acidity. The 2000 census records a total of 280 ha (690 acres) in Italy.',
    ],
  },
  {
    name: 'BARBERA DEL SANNIO',
    synonyms: ['Barbetta'],
    description:
      'Increasingly well-made central Italian variety unrelated to more northerly Barberas.',
    'origin and parentage': [
      'Barbera del Sannio is local to Castelvenere in the Valle Telesina, in the province of Benevento, Campania, central Italy. It was possibly first described in 1844 by Gasparrini under the vague name Lugliese or Lugliatica (Luglio means \u2018July\u2019 in Italian) and later renamed Barbera by Froio (1875) because of its similarity to the variety that is today famous in Piemonte and with which it is still confused in the wine regulations (Manzo and Monaco 2001). However, DNA profiling has shown that Barbera del Sannio is distinct fromBARBERA and is genetically related to CASAVECCHIA, CATALANESCA and Summariello (the last no longer cultivated), although they are all quite different from other Campanian varieties and might well represent recent introductions (Costantini et al. 2005).',
    ],
    'where its grown': [
      'Barbera del Sannio used be a rather anonymous colouring ingredient in blends. Today, however, an increasing number of producers are making deeply coloured, medium-bodied, perfumed varietal wines which have been authorized within the Sannio appellation in Campania, Italy, since 1997. Recommended producers include \u2019A Canc\u2019llera and Antica Masseria Venditti (using the synonym Barbetta) and Anna Bosco.',
    ],
  },
  {
    name: 'BARCELO',
    synonyms: ['Barcello', 'Barcelos'],
    description: 'Minor Portuguese found in white D\u00e3o blends.',
    'origin and parentage': [
      'Barcelo is a Portuguese variety that may well come from the D\u00e3o region in Portugal. Its DNA profile (Veloso et al. 2010) is almost identical to that of AMARAL (Pinto-Carnide et al. 2003) but further genetic and ampelographic analysis is needed to confirm their identity.',
    ],
    'where its grown': [
      'Barcelo is found mainly in the Beiras region of central Portugal and is authorized in D\u00e3o DOC, usually as part of a blend, and in IGP Beiras. Wines are typically fruity, fresh, perfumed and well balanced \u2013 not unlike ENCRUZADO but with lower alcohol. There were 35 ha (86 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'BARIADORGIA',
    synonyms: [
      'Bariadorgia Bianca',
      'Bariadorza',
      'Barria Dorgia',
      'Barriadorgia',
      'Carcaghjolu Biancu',
      'Carcajola',
      'Carcajola Bianco',
      'Carcajolo Blanc',
      'Gregu Bianco',
    ],
    description:
      'Virtually extinct Sardinian white, surviving on the French island of Corse as Carcajolo Blanc.',
    'origin and parentage': [
      'Bariadorgia is from the area of Alghero on the Italian island of Sardegna and identical to Carcajolo Blanc, specific to the area of Sart\u00e8ne in the south west of the French island of Corse, where it was first mentioned in 1822 under the name Carcajola. DNA profiling suggests that Bariadorgia is also identical to Gregu Bianco, specific to the Campidano area on Sardegna, because the DNA profile of Gregu Bianco published by De Mattia et al. (2007) turned out to be identical to that of Carcajolo Blanc (Vouillamoz). This is consistent with the theory that Carcajolo was introduced from Sardegna (Fo\u00ebx 1904). The name Carcajolo comes from the Italian caricagiola, meaning \u2018heavily laden\u2019. On Corse, Carcajolo Blanc is often confused with another Corsican variety, BIANCU GENTILE.',
      'Carcajolo Blanc is not a colour mutation of Carcajolo Noir, the latter being identical to PARRALETA (called Caricagiola Nera on Sardegna).',
    ],
    'where its grown': [
      'In Sardegna, Italy, Bariadorgia is almost extinct. Within France, Bariadorgia is authorized only on the island of Corse, where it is known as Carcajolo Blanc or Carcajola Bianco but where only a few vines survive in Figari and Sart\u00e8ne in the south. Wines tend to be high in acidity with only moderate alcohol levels. Plantings are so limited that the variety does not appear in the French official statistics for 2008.',
    ],
  },
  {
    name: 'BAROQUE',
    synonyms: [
      'Baroca',
      'Barroque',
      'Bordal\u00e8s',
      'Bordelais Blanc',
      'Bordeleza zuria',
    ],
    description:
      'South-west French variety given an injection of life from a renowned local chef.',
    'origin and parentage': [
      'Baroque is a variety of the Landes d\u00e9partement, south-west France, most likely originating from the area of Tursan and Chalosse in the Adour basin. Its earliest mention was in 1894 in a local vine nursery. The name Baroque could derive from the name of a hamlet near Eyres-Moncube, where there were significant plantings. Lavignac (2001) offers an alternative etymology: Baroque is a French adaptation of the Gascon var\u00f2c or var\u00f2ca, from vara, meaning \u2018pole\u2019 or \u2018stick\u2019. In Castilian, vara means a long cane left during pruning and the suffix \u2013\u00f2c points to the variety\u2019s tendency to produce stick-like buds. The variety is usually pruned to long canes.',
      'It belongs to the Courbu ampelographic group (see p XXVII), and is morphologically close to LAUZET and to TANNAT (Bordenave et al. 2007; Bisson 2009).',
      'Baroque was used to breed LILIORILA.',
    ],
    'where its grown': [
      'Plantings of Baroque, which became popular in the late nineteenth and early twentieth centuries because of its resistance to powdery mildew, have declined significantly in the last fifty years. There were 112 ha (277 acres) in France in 2008 compared with 5,656 ha (13,976 acres) in 1958. The Tursan appellation requires between 30% and 80% of this variety, blended with local varieties PETIT MANSENG or GROS MANSENG or international varieties SAUVIGNON BLANC and CHENIN BLANC. Recent investment in the appellation by the local chef Michel Gu\u00e9rard of Eug\u00e9nie-les-Bains has saved this characterful variety from extinction and it now represents about 60% of the white wine vines in Tursan. The variety may also be a minor component in the vin de liqueur Floc de Gascogne. Varietal wines tend to be slightly floral or nutty and fresh although Gu\u00e9rard\u2019s Baron de Bachen has high alcohol and a fine aroma of ripe pears.',
    ],
  },
  {
    name: 'BARSAGLINA',
    synonyms: ['Barsullina', 'Bersaglina', 'Massareta', 'Massaretta'],
    description: 'Deeply coloured Tuscan red that has all but disappeared.',
    'origin and parentage': [
      'Some have suggested that this Tuscan variety from Massa-Carrara, hence the synonym Massaretta in Liguria (Torello Marinoni, Raimondi, Ruffa et al. 2009), shares certain characteristics with SANGIOVESE.',
    ],
    'where its grown': [
      'The main area of cultivation used to be in the province of Massa-Carrara in Toscana, Italy, where it had almost disappeared but was rescued by Paolo Storchi from a few vines at Podere Scurtarola, where it makes up around 15% of the Scurtarola Rosso blend. Wines have an intense ruby colour, fruity aromas and hints of violets and are quite tannic. Barsaglina is authorized as part of a blend in Toscana\u2019s Colli di Luni DOC and in Val di Magra IGT. Fewer than 30 ha (75 acres) were recorded in the Italian census of 2000. It is also said to be grown in Liguria under the name Massaretta.',
    ],
  },
  {
    name: 'BASTARDO MAGARACHSKY',
    synonyms: [
      'Bastard de Magaraci',
      'Bastardo Magarach',
      'Magaratch 217',
      'Magarach Bastardo',
    ],
    description: 'Versatile Ukrainian cross.',
    'origin and parentage': [
      'Bastardo Magarachsky is a TROUSSEAU \u00d7 SAPERAVI cross obtained in 1949 by N Paponov and V Zotov at the Magarach research centre in Yalta, southern Ukraine, and named after the breeding institute and Bastardo, a synonym for Trousseau. Included in the official register of varieties in 1969.',
    ],
    'where its grown': [
      'Bastardo Magarachsky is widely planted in Ukraine, with a total of 1,330 ha (3,287 acres) in 2009. It is used to produce wines at various sweetness levels and for the table. Magarach Winery make a sweet varietal version and use it in dry, sweet and fortified blends with other local varieties or CABERNET SAUVIGNON. Inkerman make a dry varietal wine and Massandra also blend it with CABERNET SAUVIGNON to make an oak-aged dessert wine. It is said to have chocolate-like flavours. Sun Valley (Solnechnaya Dolina) make a dry blend with PINOT NOIR called Meganom.',
      'The variety is also found in the Republic of\nMoldova, Romania, Russia and Central Asia, apparently.',
    ],
  },
  {
    name: 'BATIKI',
    synonyms: [
      'Bantiki',
      'Dembatiki',
      'Deve Baliki',
      'Dimbatiki',
      'Dimi Batiki',
      'Timbi Batiki',
    ],
    description:
      'Variety grown mainly in central Greece making soft whites for blending, retsina and the table.',
    'origin and parentage': [
      'Batiki is said to originate in the region of \u0130zmir, historically known as Smyrna, on Turkey\u2019s Aegean coast (Nikolau and Michos 2004).',
    ],
    'where its grown': [
      'Batiki, found mainly in Tirnavos in Thessal\u00eda, central Greece, but also further north in Makedon\u00eda, Thr\u00e1ki (Thrace), Dr\u00e1ma and Thessalon\u00edki, produces lightly aromatic wines low in alcohol and acidity, used mainly in blends and in resinated wines such as retsina. Dougos in Lar\u00edsa (Thessal\u00eda) blend Batiki with RODITIS and Yannis Voyatzis has recently planted Batiki at his family vineyard in Velvendos. Batiki has traditionally been used for the table as well as for wine.',
    ],
  },
  {
    name: 'BATOCA',
    synonyms: [
      'Alvadurao Portalegre',
      'Alvara\u00e7a',
      'Alvar\u00e7a',
      'Alvaro\u00e7a',
      'Alvaroco',
      'Asal Espanhol',
      'Batoco',
      'Blanca Mar',
      'Espadeiro Branco',
      'Sa Douro',
      'Sedouro .',
    ],
    description: 'Minor, unexceptional white from Vinho Verde country.',
    'origin and parentage': [
      'Batoca is a variety from the Vinho Verde region in north-western Portugal. Comparison of DNA profiles reveals that Batoca is identical to the Spanish variety Blanca Mar in Vilanova et al. (2009). Although it is called Espadeiro Branco in Lousada, it is ampelographically distinct from, and not a colour mutation of, ESPADEIRO (Veloso et al. 2010).',
    ],
    'where its grown': [
      'Batoca produces soft, moderate-quality wines and although it is authorized in Portugal in the Vinho Verde DOC (except in the Mon\u00e7\u00e3o, Lima and Amarante subregions), there were just 15 ha (37 acres) in Portugal in 2010. Quinta Santa Cristina make a rare varietal example.',
    ],
  },
  {
    name: 'BAYANSHIRA',
    synonyms: [
      'Ag Shirei',
      'Ag \u00dcz\u00fcm',
      'Bah\u00efan Chire\u00ef',
      'Bajac Shirei',
      'Banants',
      'Bayan Shirei',
      'Bayan Shirey',
      'Bayanshire',
      'Shirei',
      'Spitak Khagog',
    ],
    description:
      'Lacklustre Azeri variety with high acidity and generally low alcohol.',
    'origin and parentage': [
      'Bayanshira most likely originates in the village of Bayan (Banants in Armenian, hence the synonym) in the Dashkasan region of Azerbaijan (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Bayanshira, which makes wines in Azerbaijan which are typically crisp, moderate in alcohol but overall pretty inferior, is grown for the table and for juice as well as for a range of wine styles. Zaman in the Samukh region, for example, use it to make sparkling, dry, dessert and fortified wines. It is sometimes distilled into brandy and is also found in other former Soviet republics.',
    ],
  },
  {
    name: 'BEAUNOIR',
    synonyms: [
      'Beaunoire',
      'Cep Gris',
      'Mourillon',
      'Pinot d\u2019A\u00ef',
      'Pinot d\u2019Ailly',
      'Pinot d\u2019Orl\u00e9ans',
      'Sogris',
    ],
    description:
      'Virtually extinct black-skinned variety with one illustrious and one promiscuous parent.',
    'origin and parentage': [
      'Beaunoir is an old and almost extinct variety of the Aube d\u00e9partement, in the south of the Champagne region in north-east France. The literal meaning of the name is \u2018beautiful black\u2019 but it may also derive from the city of Beaune in Burgundy, where the variety could originate. Indeed, DNA parentage analysis has shown that Beaunoir is a natural PINOT \u00d7 GOUAIS BLANC cross, just like its siblings CHARDONNAY, GAMAY NOIR, ALIGOT\u00c9, BACHET NOIR, etc. (Bowers et al. 1999). This probably explains why Beaunoir is also called Mourillon, Pinot d\u2019A\u00ef, Pinot d\u2019Ailly and Pinot d\u2019Orl\u00e9ans, and also why Bisson (2009) described Beaunoir as morphologically close to its sibling Bachet Noir. Misleadingly, the name Beaunoir is sometimes given to PINOT BLANC.',
    ],
    'where its grown': [
      'Beaunoir is not included in France\u2019s official register of varieties and is not therefore authorized for quality wines. It has more or less disappeared from the Aube and from its other erstwhile home further to the south and east in Ch\u00e2tillon-sur-Seine in the far north of Burgundy. According to Galet (2000), the wines tend to be light in colour and alcohol and rather ordinary.',
    ],
  },
  {
    name: 'BEBA',
    synonyms: [
      'Beba de los Santos',
      'Beba Dorada de Jerez',
      'Blanca de Mesa',
      'Blanca Superior Parral',
      'Boal de Pra\u00e7a',
      'Breval',
      'Calop Blanco',
      'Eva',
      'Malvoisie de la Chartreuse',
      'Malvoisie des Chartreux',
      'Teta de Vaca',
      'Uva de Planta',
      'Valenc\u00ed Blanco',
    ],
    description: 'Andalusian variety grown mainly in Extremadura today.',
    'origin and parentage': [
      'Beba was first mentioned by Clemente y Rubio (1807) as a table and wine grape cultivated in Sanl\u00facar de Barrameda, Jerez, Trebujena, Chipionia and Conil in Andaluc\u00eda, southern Spain. It is often called Beba Dorada de Jerez because the berries take on a golden (dorada) tint under the sun. Although Beba is sometimes called Chelva, it should not be confused with the true CHELVA from Extremadura that has a distinct DNA profile (Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007). Recent DNA analyses have mistakenly identified the reference sample of Beba in the United States Department of Agriculture\u2019s National Clonal Germplasm Repository as TROUSSEAU (Myles et al. 2011) but other published DNA profiles of Beba contradict this (Vouillamoz).',
      'The red-berried Calop Rojo is a colour mutation of Calop Blanco, and both have the same DNA profile as Beba (Mart\u00edn et al. 2003; Laiadi et al. 2009). Neither is grown commercially today.',
    ],
    'where its grown': [
      'Beba is grown mainly in the province of Badajoz in Extremadura, south-west Spain, in particular in Los Santos de Maimona, and to the north in the province of C\u00e1ceres and south around Huelva and Sevilla (Asensio S\u00e1nchez 2000). There was a total of 3,547 ha (8,765 acres) in Spain in 2008 but it is difficult to know how much is grown for wine and how much for the table \u2013 and it is authorized in several DOs in the Islas Canarias and in Andaluc\u00eda. Wines are fresh and fruity, often produced within the Ribera del Guadiana DO.',
      'It is also found to a very limited extent over the border in Portugal\u2019s Algarve \u2013 less than a hectare (2.5 acres), according to official 2010 statistics.',
    ],
  },
  {
    name: 'B\u00c9CLAN',
    synonyms: [
      'Baclan',
      'Baclans',
      'Becclan',
      'Petit B\u00e9clan',
      'Petit Dureau',
      'Petit Margillin',
      'Roussette Noire',
      'Saunoir',
    ],
    description: 'Virtually extinct Jura vine.',
    'origin and parentage': [
      'B\u00e9clan is a variety from the Jura in eastern central France, where it was first mentioned in 1732 under its old synonym Baclans in a decree of the Besan\u00e7on parliament: \u2018ne laisser subsister en plants noirs ou blancs que le pulsard noir, le gros & le petit noirin dit pinot, le baclans .\u00a0.\u00a0. qui sont les seuls bons plants\u2019 (\u2018for black or white grape varieties, keep only pulsard noir, gros & petit noirin known as pinot, baclans .\u00a0.\u00a0. which are the only good varieties\u2019). The name B\u00e9clan could derive from the local dialect b\u00e8cle, meaning \u2018climbing vine\u2019 (French treille). In the Jura, this variety is called Petit B\u00e9clan to distinguish it from Gros B\u00e9clan, a synonym for PELOURSIN. The similarity between the two varieties is confirmed by ampelographers who place B\u00e9clan in the Pelorsien ampelographic group (see p XXVII), named after Peloursin (Bisson 2009).',
      'B\u00e9clan has been confused with PELOURSIN (called Gros B\u00e9clan), DURIF (under the name Petite Sirah in California) and PINOT NOIR (Rouget 1897) but all these hypotheses have been shown by DNA profiling to be incorrect (Meredith et al. 1999).',
    ],
    'where its grown': [
      'Officially less than 1 ha (2.5 acres) is left in France. Domaine Jean Bourdy planted some B\u00e9clan vines in 2006 as part of a project to conserve local varieties and Domaine Ganevat in Rotalier have replanted a small area and include the grapes in an early-drinking red blend. It can produce lively wines with moderate alcohol.',
    ],
  },
  {
    name: 'BEICHUN',
    synonyms: ['Bei Chun'],
    description: 'Winter-hardy Chinese hybrid.',
    'origin and parentage': [
      'Beichun is a hybrid obtained in 1954 at the Institute of Botany of the Chinese Academy of Sciences by crossing MUSCAT OF HAMBURG with a Vitis amurensis Ruprecht vine, a Chinese wild species. Its name is a contraction of Beijing and chun, meaning \u2018alcohol\u2019. It is one in a series of hybrids in the so-called \u2018Bei\u2019 series of crossings of vinifera with amurensis varieties, along with Beimei, Beihong and Beiquan, for example.',
    ],
    'where its grown': [
      'In the 1970s and 1980s Beichun\u2019s popularity in China increased considerably. With national total plantings of around 7,000 ha (17,297 acres; Wang et al. 2010), it is widely planted around Beijing in the province of Hebei as well as in Shandong, Henan and Liaoning. It can survive without being buried in winter in northern China and fares better in the humid conditions of southern China than any pure Vitis vinifera variety (Li et al. 2007). Wines are ruby-coloured, soft and refreshing but the flavour is dominated by the Vitis amurensis genes.',
    ],
  },
  {
    name: 'BEKARI',
    synonyms: [
      'Bekaro',
      'Mbekari Mavro',
      'Mpekari Mavro',
      'Mbekaro',
      'Mpekaro',
    ],
    description:
      'Minor variety local to \u00cdpeiros (Epirus) in western Greece.',
    'origin and parentage': [
      'Bekari probably originates from Io\u00e1nnina or Thesprot\u00eda in the \u00cdpeiros region in the west of mainland Greece.',
    ],
    'where its grown': [
      'Grown to a limited extent in \u00cdpeiros in western Greece, where it was traditionally blended with the white variety DEBINA and the red VLACHIKO to make a sparkling ros\u00e9, still produced by, for example, the Io\u00e1nnina co-operative and Glinavos, both in Z\u00edtsa, although such wines do not qualify for the Z\u00edtsa appellation. Glinavos also use it in a red blend with AGIORGITIKO and Vlachiko and in another that adds CABERNET SAUVIGNON to the mix. Wines have moderate acidity and colour and are generally fairly alcoholic.',
    ],
  },
  {
    name: 'BELLONE',
    synonyms: ['Arciprete Bianco', 'Cacchione', 'Fagotto and many more'],
    description: 'Vigorous Roman white that rarely appears solo.',
    'origin and parentage': [
      'Possibly originating from Lazio in central Italy, it is considered by some to be the Uva Pantastica mentioned by Pliny the Elder but no convincing evidence can be found to support this hypothesis.',
    ],
    'where its grown': [
      'Cultivated mainly in Lazio, Italy, particularly south of Roma in Anzio, Marino, Nettuno and Velletri, it produces light, fresh wines that are quite delicate but finish slightly bitter and are traditionally softened with a little residual sugar. It frequently disappears into blends such as Frascati or Castelli Romani, although a 100% Bellone is authorized in the Marino and Nettuno DOCs. Terre delle Ginestre\u2019s Lentisco and the Cantina Cincinnato\u2019s Bellone are examples of the few varietal examples available.',
      'One particular clone of Bellone, oddly nicknamed Arciprete Bianco (meaning \u2018white archpriest\u2019), is used by Azienda Agricola Marco Carpineti to produce Ludum, a singular dessert wine made from grapes dried on the vine, as well as their dry Collesanti, which has aromas of apples and apricots. Bellone was also used in the past to make Cannellino, a delicate late-harvest Frascati. Total plantings in Italy according to the 2000 census were 1,480 ha (3,660 acres).',
    ],
  },
  {
    name: 'B\u00c9QUIGNOL NOIR',
    synonyms: [
      'B\u00e9quin Rouge',
      'Breton',
      'Cabernet',
      'Chalosse Noire',
      'Embalouzat',
      'Man\u00e7ais Noir',
    ],
    description:
      'Rare dark-skinned French variety much more common in Argentina.',
    'origin and parentage': [
      'The first mention of B\u00e9quignol Noir, from the Gironde, south-west France, appeared in the \u2018Livre de raison d\u2019Antoine Feuilhade\u2019, an account book written between 1763 and 1777 by the mayor of Libourne (Garde 1946). Its name comes from the Gascon b\u00e8c with the suffix inhol, literally meaning \u2018little beak\u2019, referring to the shape of the berries (R\u00e9zeau 1997).',
      'B\u00e9quignol Noir belongs to the Messile ampelographic group (see p XXVII). Grey and white mutations of B\u00e9quignol Noir have been observed. Recent DNA profiling has suggested a possible parent\u2013offspring relationship with SAVAGNIN (Myles et\nal. 2011).',
      'B\u00e9quignol Noir has often been confused with FER (eg in Viala and Vermorel 1901\u201310), CABERNET FRANC, DURIF and PRUNELARD but DNA profiling has shown them to be distinct (Bowers et al. 1999).',
    ],
    'where its grown': [
      'There was less than 1 ha (2.5 acres) in France in 2008 (down from 223 ha/551 acres in 1958), though it is still a recommended variety in the Gironde and the Vend\u00e9e. Wines are light and destined for early drinking.',
      'There were a surprising 919 ha (2,271 acres) in Argentina in 2008, mostly in Mendoza. It is known locally and colloquially as \u2018red Chenin\u2019 because of its ampelographic similarity \u2013 eg the leaf and berry shapes \u2013 with that variety. Like Argentina\u2019s Bonarda (ie DOUCE NOIRE), it is not very highly regarded and tends to be used to add colour to blends.',
    ],
  },
  {
    name: 'BERDOMENEL',
    synonyms: ['Berdanel'],
    description: 'Virtually extinct Ari\u00e8ge variety recently replanted.',
    'origin and parentage': [
      'Berdomenel is a variety exclusive to Pamiers in the Ari\u00e8ge d\u00e9partement in the French Pyr\u00e9n\u00e9es, between Toulouse and the Spanish border, where it is today threatened with extinction. Its name could derive from the Occitan verdau men\u00e8l, meaning \u2018small greenish-berried\u2019, but there is no link with PETIT VERDOT (Lavignac 2001), which has roughly the same meaning.',
    ],
    'where its grown': [
      'Berdomenel was almost extinct in France when Les Vignerons Ari\u00e9geois in Mont\u00e9gut-Plantaurel (just west of Pamiers) came to the rescue by planting 30\u201340 vines of this and other endangered local varieties, including CAMARALET DE LASSEUBE, CANARI NOIR, MANDR\u00c8GUE and Tortozon (PLANTA NOVA; Carbonneau 2005). They aim to multiply these and to produce a commercial wine if their experiments yield encouraging results. Berdomenel is not included in the French official register of varieties.',
    ],
  },
  {
    name: 'BESGANO BIANCO',
    synonyms: ['Colombana Bianca'],
    description:
      'Very rare northern Italian white once used to produce Vin Santo.',
    'origin and parentage': [
      'Besgano Bianco originated in either the Bobbio region in the province of Piacenza, Emilia-Romagna, or around Voghera in the province of Pavia, Lombardia. It is also known as Colombana Bianca but is not a colour mutation of COLOMBANA NERA.',
    ],
    'where its grown': [
      'Until the 1940s, Besgano Bianco was quite widely planted in Val Trebbia and Val Nure in the provinces of Genova and Piacenza in Italy to produce a Vino Santo, together with MALVASIA BIANCA LUNGA and VERDEA. Today it is such a rarity that it is not even mentioned in the 2000 Italian census. Used for both table and wine grapes.',
    ],
  },
  {
    name: 'BIANCA',
    synonyms: ['Bianka', 'Ec 40', 'Ecs 40', 'Egri Csillagok 40', 'May Rot'],
    description:
      'Hungarian hybrid producing generally unexciting wines, also in Russia.',
    'origin and parentage': [
      'Bianca is an Eger 2 \u00d7 BOUVIER hybrid obtained in 1963 by J\u00f3zsef Csizmazia and L\u00e1szl\u00f3 Bereznai at the K\u00f6lyuktet\u00f6 research centre in the Eger region of northern Hungary, in which Eger 2 is a selfling of VILLARD BLANC called Seyve-Villard 12-375 SP. Initially named Egri Csillagok 40 (meaning \u2018star of Eger 40\u2019), Bianca was registered in 1982. It was used to breed BA\u010cKA and RUBINKA.',
    ],
    'where its grown': [
      'Hungary\u2019s 1,280 ha (3,163 acres) of Bianca are quite widely dispersed but the vast majority (1,137 ha/2,810 acres) are in Kuns\u00e1g in the centre of the country. Wine styles vary considerably, depending on harvest date and winemaking, but varietal wines are typically rather neutral, occasionally slightly flowery, with moderate alcohol, becoming a little more exotic but losing freshness if harvested later. The variety is prone to oxidation in the winery but its disease resistance endears it to organic growers. Recommended producers of varietal wines include H\u00e1rs and Hilltop Neszm\u00e9ly but Bianca is mostly used for inexpensive everyday wines.',
      'Bianca is widely planted in Russia\u2019s Krasnodar Krai wine region (2,731 ha/6,748 acres in 2009) and there are 15 ha (37 acres) in the Republic of Moldova.',
    ],
  },
  {
    name: 'BIANCHETTA TREVIGIANA',
    synonyms: [
      'Bianca Gentile di Fonzaso',
      'Bianchetta Gentile',
      'Bianchetta Semplice',
      'Senese',
      'Vernanzina',
      'Vernassina',
    ],
    description:
      'Vigorous, thick-skinned northern Italian white producing rather ordinary, slightly astringent wines that are generally blended.',
    'origin and parentage': [
      'Numerous and distinctively different varieties have been cultivated under the name Bianchetta, meaning \u2018little white\u2019. Bianchetta Trevigiana takes its name from Treviso in the Veneto in northern Italy, where it is considered an indigenous variety and where it is said to have first been mentioned under the name Bianchetta Gentile by Agostinetto di Cimadolmo in Treviso in 1679.',
      'Isoenzymatic and DNA profiling provided evidence that Bianchetta Trevigiana, Senese, Vernanzina and Vernassina are synonyms (Crespan et al. 2003). On the other hand, comparison of the DNA profiles showed that Bianchetta Trevigiana is distinct from the supposedly synonymous ALBAROLA and the non-cultivated Pavana Bianca and Vernaccia Trentina (Vouillamoz). In addition, Cipriani et al. (2010) have recently suggested through DNA parentage analysis that Bianchetta Trevigiana is a possible Brambana \u00d7 DURELLA natural cross, the former a black-berried variety that is not commercially cultivated. This parentage showed some DNA discrepancies and needs further investigation.',
    ],
    'where its grown': [
      'In the provinces of Belluno and Verona, Bianchetta Trevigiana has been cultivated in Italy since the seventeenth century but it has declined since then because of its susceptibility to powdery mildew and its lack of frost resistance. Bianchetta Trevigiana was supposedly also cultivated in Trentino-Alto Adige under the name Vernaccio or Vernazza, as well as in Val Sacra, Valsugana and in the Val d\u2019Adige up to Bolzano. It is never made as a varietal wine but it is part of the blends of a wide variety of DOC wines, such as Colli di Conegliano (including Torchiato di Fregona dry and sweet aged whites), Lugana, Montello\u2013Colli Asolani, Prosecco and Valdadige. It was also formerly used in the preparation of vermouth. It makes relatively simple, sometimes slightly astringent, wines. Total plantings in Italy in 2000 were 65 ha (160 acres).',
    ],
  },
  {
    name: 'BIANCO D\u2019ALESSANO',
    synonyms: [
      'Acchiappapalmento',
      'Bianco di Lessame',
      'Iuvarello',
      'Verdurino',
      'Vuiono',
    ],
    description:
      'Southern Italian white frequently blended with Verdeca, enjoying success in Australia.',
    'origin and parentage': [
      'A recent ampelographic study has shown that Iuvarello from Calabria is identical to Bianco d\u2019Alessano from Puglia (Schneider, Raimondi, Grando et al. 2008).',
    ],
    'where its grown': [
      'Grown throughout Puglia in southern Italy but especially in Murgia (near Taranto) and in the Valle d\u2019Itria. It contributes to DOC wines such as Gravina, Martinafranca, Locorotondo, Ostuni and Lizzano and is often blended with the more widespread and fertile VERDECA. Its recent revival gave rise to interest in producing monovarietal Bianco d\u2019Alessano wines. Sometimes used for vermouth production. 960 ha (2,370 acres) were recorded in the Italian census of 2000.',
      'Salena Estate in the Riverland, Australia, has had great success with this variety. After blending it away for several years, it has now been bottled as a varietal wine and scooped three trophies at the Australian Alternative Varieties Show in 2010. It ripens late here but retains high acidity levels, making it ideal for this warmer inland region. There are currently just 4 ha (10 acres) planted.',
    ],
  },
  {
    name: 'BIANCOLELLA',
    synonyms: ['Bianculillo', 'Jancolella', 'San Nicola'],
    description:
      'Light white grape from Napoli and the neighbouring islands inspires some heroic production.',
    'origin and parentage': [
      'Biancolella is indigenous to the island of Ischia in the bay of Napoli in Campania, southern Italy, where it was not mentioned until the nineteenth century. DNA profiling has established that the San Nicola grape variety traditionally cultivated on the nearby island of Capri is identical to Biancolella (Costantini et al. 2005). Recent DNA parentage analysis has suggested that Biancolella is a possible parent of SAN LUNARDO, also from the island of Ischia (Cipriani et al. 2010). It is also closely related to FALANGHINA BENEVENTANA (Costantini et al. 2005).',
    ],
    'where its grown': [
      'Cultivated mainly on the island of Ischia in the bay of Napoli, southern Italy, but also grown on other volcanic soils in the provinces of Napoli and Caserta, and also possibly near Bastia and San Martino di Lota on Corse, France, under the name Petite Blanche (identity yet to be verified). It is authorized in DOCs such as Ischia, Capri, Campi Flegrei, Penisola Sorrentina and Costa d\u2019Amalfi. Only 293 ha (725 acres) were recorded in the 2000 census of Italian vineyards.',
      'On Ischia, Biancolella is usually blended with other local varieties but a few growers produce 100% Biancolella wines. Some of them represent perfect examples of \u2018heroic viticulture\u2019. Casa d\u2019Ambra\u2019s Biancolella Frassitelli, for example, is harvested with the help of a monorail train on the steep hillside vineyards of Monte Epomeo at an elevation of 600 m above sea level. The most extreme example must be Cantine Antonio Mazzella\u2019s Vigna del Lume, a 100% Biancolella from a vineyard 150 m above the sea that is reachable only from an old footpath. Late-picked grapes are pressed and vinified in old cellars dug in soft tuffeau rock nearby. The wine is then transported by sea on wooden boats and bottled on the mainland. This \u2018impossible\u2019, straw-coloured wine has aromas of pineapple, almonds and white flowers, good balance, nice minerality (volcanic soil) and good structure with medium to high alcohol content.',
    ],
  },
  {
    name: 'BIANCONE DI PORTOFERRAIO',
    synonyms: ['Biancone', 'Pagadebiti di Porto S. Stefano'],
    description: 'Ordinary white exclusive to Elba.',
    'origin and parentage': [
      'Indigenous to the island of Elba in Toscana, or possibly originating from the French island of Corse under the name Uva Bianca or Biancone. Parentage analysis has revealed a possible parent\u2013offspring relationship between MAMMOLO and the Corsican Biancone as well as with several other Tuscan varieties, CALORIA, COLOMBANA NERA and POLLERA NERA, which turn out to be half-siblings of Biancone (Di Vecchi Staraz, This et al. 2007), although it is not sure that Biancone from Corse in their study is truly identical to Biancone di Portoferraio.',
    ],
    'where its grown': [
      'This late-ripening variety is grown exclusively on Elba, Italy, to produce a simple table wine.',
    ],
  },
  {
    name: 'BIANCU GENTILE',
    synonyms: ['Biancone Gentile'],
    description: 'Rare, aromatic Corsican.',
    'origin and parentage': [
      'Biancu Gentile is an old variety from the French island of Corse, mentioned as far back as 1822 as growing in Pila Canale in the southern part of the island, south east of Ajaccio. It is often confused with another Corsican variety, Carcajolo Blanc (BARIADORGIA), as well as with BIANCONE DI PORTOFERRAIO and ROLLO (under the name Livornese Bianco), both having Biancone among their synonyms, with which Biancu Gentile is supposedly closely related (Galet 2000). Biancu Gentile literally means \u2018nice white\u2019 or \u2018noble white\u2019 but gentile also has the sense \u2018grafted\u2019 or the opposite of wild.',
    ],
    'where its grown': [
      'Biancu Gentile is exclusive to the island of Corse, in France, where it is a recommended variety. There are very limited plantings in Patrimonio and a little more in the south, in Sart\u00e8ne and Figari, but the total vineyard area was just 6 ha (15 acres) in 2008. Varietal wines may be produced within the \u00cele de Beaut\u00e9 classification but it is often successfully blended with VERMENTINO. It was rediscovered during a viticultural census of the island and interest in the variety revived in the mid 1990s thanks to Antoine Arena in Patrimonio and Yves Canarelli in Figari, both of whom make varietal examples.',
      'Biancu Gentile produces wines that have intense aromas of exotic fruits and citrus and tend to be full-bodied and powerful, but the grapes need to be harvested before the acidity starts to drop to avoid heaviness.',
      'There have been some experimental plantings of Biancu Gentile, along with a wide range of other French, Italian, Portuguese and Spanish varieties, in California\u2019s San Joaquin Valley, in trials by the University of California at Davis viticulture extension programme.',
    ],
  },
  {
    name: 'B\u00cdBORKADARKA',
    synonyms: ['B\u00edbor Kadarka', 'Biborkadarsa', 'Cs 4'],
    description: 'Minor red-fleshed Hungarian cross used mainly for colour.',
    'origin and parentage': [
      'B\u00edborkadarka, meaning \u2018purple Kadarka\u2019, registered in 1974, is a KADARKA \u00d7 Muscat Bouschet cross obtained in 1948 by P\u00e1l Kozma at the Szent Istv\u00e1n University in Hungary, in which Muscat Bouschet is a black-berried Muscat Rouge de Mad\u00e8re \u00d7 PETIT BOUSCHET cross that is no longer commercially cultivated.',
    ],
    'where its grown': [
      'Wines are typically deeply coloured and high in acidity, produced in the regions of Eger and M\u00e1tra in north-east Hungary and Szeksz\u00e1rd and Kuns\u00e1g in the centre and south. They are generally used to add colour to blends. There was a total of 154 ha (381 acres) across the country in 2008. B\u00e1lint Losonci in M\u00e1tra is one of the rare producers of a successful varietal wine.',
    ],
  },
  {
    name: 'BICAL',
    synonyms: [
      'Arinto de Alcoba\u00e7a',
      'Bical de Bairrada',
      'Borrado das Moscas',
      'Fern\u00e3o Pires Galego',
      'Pintado das Moscas',
      'Pintado dos Pardais',
    ],
    description:
      'Aromatic, early-ripening variety widely planted in western central Portugal.',
    'origin and parentage': [
      'The origins of Bical lie somewhere within the Bairrada and D\u00e3o regions in central Portugal, its traditional area of cultivation (Rolando Faustino, personal communication). Its synonym Borrado das Moscas, literally \u2018fly droppings\u2019, derives from the little brown dots that appear on fully ripe berries.',
      'Bical is also called Arinto de Alcoba\u00e7a in the Alentejo and Tejo regions, but in Alcoba\u00e7a in northern Estremadura the variety that is known simply as Arinto is in fact ARINTO DE BUCELAS, which is also grown further south, closer to Lisboa. Similarly, Bical should not be confused with FERN\u00c3O PIRES in the An\u00e7\u00e3-Cantanhede region near Coimbra, where it is known as Fern\u00e3o Pires Galego. Just to add to the confusion, Bical is sometimes used as a synonym for TOURIGA NACIONAL, even though the latter has black berries. The alternative Bical Tinto is marginally less confusing.',
    ],
    'where its grown': [
      'Bical is grown mostly in its traditional homelands of Bairrada and D\u00e3o in Portugal but is also found further north. There were 1,465 ha (3,620 acres) in Portugal in 2010. Ripening early allows Bical to escape the September rains that are common in the region and wines therefore have respectable alcohol levels, fresh acidity as well as attractive peachy aromas, sometimes verging on tropical fruits. With time in bottle, the best examples can develop aromas akin to those of aged Riesling. Wines with lower acidity may benefit from blending with ARINTO DE BUCELAS or CERCEAL BRANCO. The variety also seems well suited to barrel fermentation and ageing on the lees. The best wines are grown on the east-facing calcareous soils of \u00d3is do Bairro north of Coimbra. On its own or blended with Arinto, it is often used to produce sparkling wines.',
      'Bical is authorized in the Bairrada, Beira Interior, D\u00e3o, T\u00e1vora-Varosa and Douro DOCs. Recommended producers in Bairrada include Alian\u00e7a, Campolargo, Filipa Pato, Lu\u00eds Pato and Quinta do Encontro.',
    ],
  },
  {
    name: 'BIGOLONA',
    synonyms: [
      'Bigolara',
      'Bigolona Bianca',
      'Bigolona Veronese',
      'Sampagna',
      'Smarzirola',
    ],
    description:
      'Rare white from the Valpolicella region of northern Italy used in sweet-wine blends.',
    'origin and parentage': [
      'Bigolona was mentioned among the varieties of the Valpolicella region in 1818\u201323 (Costacurta et al. 1980).',
    ],
    'where its grown': [
      'Since the 1970s, Bigolona and other rare local varieties have been rescued from extinction and studied at the Conegliano and Verona research centres in the Veneto, northern Italy. Bigolona is not included in the national register of varieties and is grown to only a very limited extent in Valpolicella and Illasi for the production of late-harvest and passito wines. For example, Tommaso Bussola includes about 3% Bigolona in his Peagn\u00e0 Passito Bianco, IGT Veneto, with GARGANEGA and other white varieties.',
    ],
  },
  {
    name: 'BIRSTALER MUSKAT',
    synonyms: ['Birchstaler Muscat', 'Muscat de la Birse', 'VB 86-6'],
    description: 'Light-skinned Swiss hybrid used mainly for the table.',
    'origin and parentage': [
      'Birstaler Muskat is a SEYVAL BLANC \u00d7 BACCHUS hybrid obtained in 1986 by Valentin Blattner in Soyhi\u00e8res in the Jura, north-west Switzerland, and therefore a sibling of R\u00c9SELLE. It is named after the River Birs (Tal is German for \u2018valley\u2019), a tributary of the Rhein.',
    ],
    'where its grown': [
      'Birstaler Muskat is typically a table grape but it is occasionally made into wine (officially less than half a hectare was recorded for winemaking in Switzerland in 2009): eg the sweet Muscat de la Birse bottling by the variety\u2019s breeder, Valentin Blattner. Wines have a light Muscat taste and low acidity.',
      'It is also planted in Belgium for the table.',
    ],
  },
  {
    name: 'BLACK QUEEN',
    synonyms: ['Pokdum', 'Pok Dum'],
    description: 'Japanese complex hybrid widely used for winemaking in Asia.',
    'origin and parentage': [
      'Complex hybrid obtained by Zenbei Kawakami (1868\u20131944) at his Iwanohara Winery in Niigata Prefecture in Japan by crossing Bailey and Golden Queen (see MUSCAT BAILEY A for the pedigree of Bailey), in which:',
      '\u2013\u00a0\u00a0\u00a0Golden Queen is a Black Alicante \u00d7 Ferdinand de Lesseps hybrid',
      '\u2013\u00a0\u00a0\u00a0Black Alicante is a Spanish table grape variety that must not be confused with TRINCADEIRA, which is called Black Alicante in Australia',
      '\u2013\u00a0\u00a0\u00a0Ferdinand de Lesseps is a CHASSELAS \u00d7 ISABELLA hybrid.',
      'Black Queen is therefore a Vitis vinifera, Vitis labrusca and Vitis\nlincecumii hybrid.',
      'In Thailand, Pokdum is a mutation of Black Queen (pok dum in Thai) found in the 1980s by wine grower Nong Pok in Pak Chong in the Nakhon Ratchasima province. It should be noted that this origin is not consistent with the theory that Pokdum is identical to Pione, a Kyoho \u00d7 MUSCAT OF ALEXANDRIA table grape hybrid from Japan.',
    ],
    'where its grown': [
      'As one might expect, Black Queen is one of the dozens of vine varieties used for winemaking in Japan (469 ha/1,159 acres in 2009). Black Queen is the dominant grape used in Taiwan\u2019s fledgling wine industry and is also important in those of Vietnam and Cambodia. As Pokdum, it accounts for about 10% of Thailand\u2019s vineyards and is grown, for ex-ample, by the Siam Winery in \u2018floating vineyards\u2019 in the Chao Praya Delta.',
    ],
  },
  {
    name: 'BLANC DAME',
    synonyms: [
      'Blanc Madame',
      'Blanquette Grise',
      'Clairette de Gascogne',
      'Claret',
      'Claret de Gascogne',
      'Plan de Dame',
    ],
    description:
      'Almost abandoned by south-west French vignerons but not quite by armagnac distillers.',
    'origin and parentage': [
      'Blanc Dame is a variety of south-west France that has virtually disappeared. It was possibly mentioned in 1840 under the name Plan de Dame in Rabastens in the Hautes-Pyr\u00e9n\u00e9es, and definitely in 1887 in the B\u00e9arn under its modern name blandame or Blanc-madame. The suffix -dame or -madame has often been used in French to designate various fruits, eg the pear called Cuisse-madame or Cuisse de Dame.',
      'Like more than eighty other varieties of Western Europe, Blanc Dame is likely to be a natural progeny of the prolific GOUAIS BLANC (Boursiquot et al. 2004). Blanc Dame belongs to the Gras ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Blanc Dame is authorized in France in the Gers and in the Pyr\u00e9n\u00e9es-Atlantiques but there were only 20 ares (half an acre) remaining in 2008, no doubt in part thanks to the subsidies offered to growers by the EU to grub up varieties such as this. What remains plays a very minor part in the base wines for armagnac, although it is also suited to the table.',
    ],
  },
  {
    name: 'BLANC DU BOIS',
    synonyms: ['Blanc DuBois', 'Florida H18-37'],
    description:
      'Potentially high-quality, aromatic hybrid from Florida with useful resistance to Pierce\u2019s disease.',
    'origin and parentage': [
      'A complex Vitis\naestivalis, Vitis\nlabrusca, Vitis\nsimpsonii and Vitis\nvinifera hybrid obtained in 1968 by John A Mortensen at the Central Florida Research and Education Center of the University of Florida in Leesburg by crossing Florida D6-148 with CARDINAL (Mortensen 1987), where:',
      '\u2013\u00a0\u00a0\u00a0Florida D6-148 is a self-pollination of Florida A4-23 that is resistant to Pierce\u2019s disease',
      '\u2013\u00a0\u00a0\u00a0Florida A4-23 is a Florida 449 \u00d7 Florida W907 hybrid obtained in 1956',
      '\u2013\u00a0\u00a0\u00a0Florida 449 is a seedling of Vitis aestivalis subsp. smalliana with an unknown parent, found in 1946',
      '\u2013\u00a0\u00a0\u00a0Florida W907 is a Florida W381 \u00d7 CARDINAL hybrid obtained in 1949',
      '\u2013\u00a0\u00a0\u00a0Florida W381 is a Pixiola \u00d7 GOLDEN MUSCAT hybrid obtained in 1945',
      '\u2013\u00a0\u00a0\u00a0Pixiola is a seedling of Vitis simpsonii Munson with an unknown parent.',
      'At the suggestion of Lafayette Vineyards & Winery, the variety was named after the French winemaker \u00c9mile Dubois, a prominent grape grower and wine producer in Tallahassee, Florida, in the late nineteenth century. It was released for commercial planting in 1987.',
    ],
    'where its grown': [
      'Lakeridge in Florida were the first to plant Blanc du Bois and Haak Winery near Galveston in Texas were one of the first in Texas in the late 1980s. The latter make both dry and off-dry varietal wines, including a barrel-fermented version, as well as wines in the style of Madeira and white port. As an unadorned dry varietal wine, Blanc du Bois has light citrus aromas and no foxiness. Other Texan producers such as Chisholm Trail, La Cruz de Comal, Enoch\u2019s Stomp, Loan Oak, Los Pinos Ranch, Pleasant Hill, Tara and Tehuacana Creek have followed suit, as well as Feliciana, Landry and Ponchartrain in Louisana, and San Sebastian in Florida. Wines are typically aromatic, ranging from floral and occasionally Muscat-like to citrus-flavoured, and the drier wines show the freshness of SAUVIGNON BLANC, ALVARINHO or TORRONT\u00c9S. According to Raymond Haak, there are currently around 100 acres (40 ha) planted in the US, mainly in Texas and Florida, but there are also minor plantings in Louisiana, Georgia and South Carolina.',
    ],
  },
  {
    name: 'BLANQUEIRO',
    synonyms: ['Blanqueirol', 'Blanqueiron', 'Blanquerel', 'Pignairon'],
    description: 'Very minor Proven\u00e7al variety.',
    'origin and parentage': [
      'Blanqueiro probably comes from the vineyards of Saint-Jeannet and La Gaude in the Alpes-Maritimes.',
    ],
    'where its grown': [
      'Blanqueiro is authorized as a secondary variety in the Bellet appellation in Provence, south-east France, but is today rarely found and does not feature in the official French vineyard statistics.',
    ],
  },
  {
    name: 'BLATINA',
    synonyms: [
      'Blathina',
      'Blatina Crna',
      'Blatina Hercegovacka',
      'Blatina Mala',
      'Blatina Velika',
      'Praznoba\u010dva',
      'Zlorod',
    ],
    description: 'Bosnia and Herzegovina\u2019s most important red variety.',
    'origin and parentage': [
      'Blatina is the main indigenous red wine variety in Bosnia and Herzegovina, possibly originating from the region of Mostar in the south of the country. Croatia\u2019s CETINKA is also known as Blatinka but they are distinct varieties (Goran Zduni\u0107, personal communication). DNA profiling has shown that Blatina is distinct from TRIBIDRAG (Nata\u0161a \u0160tajner, personal communication), as it is sometimes called in the Metkovi\u0107 region of Croatia, just over the border.',
    ],
    'where its grown': [
      'In Bosnia and Herzegovina, Blatina is often produced as a varietal wine, or with a small percentage of other varieties such asALICANTE HENRI BOUSCHET (under the name Kambu\u0161a) or TRNJAK that are interplanted in the vineyard to ensure pollination, as in \u010citluk Winery\u2019s oaked and unoaked versions. Wines are typically medium-bodied and full-flavoured but retain good acidity even this far south. Other producers include Hepok in Ljubu\u0161ki, south west of Mostar, who age their deeply coloured, berry-fruited, firmly structured example in oak, and \u0160kegro.',
    ],
  },
  {
    name: 'BLAUER PORTUGIESER',
    synonyms: [
      'Autrichien',
      'Badner',
      'Early Burgundy',
      'Modr\u00fd Portugal',
      'Oporto',
      'Portugais Bleu',
      'Portugalkja',
      'Portugieser',
      'Portugieser Blau',
      'Portugizac Crni',
      'Portugu\u00eas Azul',
      'V\u00f6slauer ; K\u00e9koport\u00f3 is no longer officially used in Hungary',
    ],
    description:
      'Prolific Austrian red wine variety producing rather dull, low-acid wines.',
    'origin and parentage': [
      'Despite its name, Blauer Portugieser is not likely to be of Portuguese origin. Grauer Portugieser (grey berries) and Gr\u00fcner Portugieser (green berries) could not be distinguished through DNA profiling and simply represent colour mutations of Blauer Portugieser (Sefc, Steinkellner et al. 1998). The existence in Austria of colour mutations and numerous synonyms tends to favour this country as the geographical origin.',
      'Breeding reports and parentage analysis showed that Grauer Portugieser and FR\u00dcHROTER VELTLINER are the parents of JUBIL\u00c4UMSREBE (Sefc, Steinkellner et al. 1998). Blauer Portugieser was a parent in several other crosses that are relatively important today: BLAUBURGER, DAKAPO, DOMINA, HEROLDREBE, PORTAN and SEGALIN.',
    ],
    'where its grown': [
      'Thanks to Portugieser\u2019s generous yields, it has spread to much of Central Europe and was even at one time quite widespread in south-west France. Domaine Ganevat in Rotalier in the Jura have replanted a small area of Portugais Bleu and include it in an early-drinking red blend.',
      'The variety was taken to Germany in the nineteenth century and plantings are more extensive there than in Austria, totalling 4,354 ha (10,759 acres), mainly in the Pfalz (2,178 ha/5,382 acres) and Rheinhessen (1,661 ha/4,104 acres). A good deal of the crop goes into pink Weissherbst and most of the red wines, wherever they are produced, are soft, pale, sometimes thin; ordinary at best. They tend to need significant chaptalization to give them body and are best drunk young.',
      'Blauer Portugieser is the third most planted dark-berried variety in Austria, with 2,223 ha (5,490 acres) in 2007, down very slightly on 1999. The vast majority of vines are in Nieder\u00f6sterreich (Lower Austria), particularly in the Thermenregion south of Wien (Vienna) and north west of the capital in Kamptal.',
      'In Hungary, where it was once more commonly known as K\u00e9koport\u00f3, there were 1,250 ha (3,090 acres) in 2008, with the majority of vines in Vill\u00e1ny and Kuns\u00e1g in the south and Eger in the north east. Producers in Vill\u00e1ny seem to be able to coax the most concentration from the variety, presumably by restricting its yields, and some examples have enough body to benefit from oak ageing. Top producers include G\u00e1bor Kiss, J\u00f3zsef Szemes and B\u00e9la Jekl.',
      'It is also grown in northern Croatia as Portugizac Crni, or Portugaljka, and in the Czech Republic, which recorded 670 ha (1,655 acres) in 2008, mostly in Velkopavlovick\u00e1 and Slov\u00e1ck\u00e1. In California, recent DNA tests have shown that some Early Burgundy vines are in fact Blauer Portugieser (Mike Officer, personal communication), although it seems likely that others are ABOURIOU, as suggested by the ampelographer Paul Truel in the 1970s.',
    ],
  },
  {
    name: 'BLAUER URBAN',
    synonyms: ['Schwarzer Urban', 'Urban', 'Urban Blau', 'Urban Blauer'],
    description: 'Rarity just about surviving in southern Germany.',
    'origin and parentage': [
      'This variety, probably distinct from Roter Urban (no longer cultivated), may originate in the Alto Adige in northern Italy.',
    ],
    'where its grown': [
      'At one time Blauer Urban was widely planted in Central Europe, Italy and France but now it is scantily planted in southern Germany, where Graf Adelmann in W\u00fcrttemberg produces a good example. It was traditionally used as table or wine grape, planted together with CHASSELAS (Gutedel) and GR\u00dcNER VELTLINER.',
    ],
  },
  {
    name: 'BLAUER WILDBACHER',
    synonyms: ['Schilcher', 'Wildbacher', 'Wildbacher Blau'],
    description:
      'Distinctively perfumed Austrian red wine grape producing eye-watering pinks.',
    'origin and parentage': [
      'Blauer Wildbacher is an ancient traditional variety named after the small village of Wildbach south of Graz in south-eastern Austria. The name Wildbacher should be handled with care: in a recent DNA study, the more widespread and early-ripening Blauer Wildbacher and the almost-extinct and late-ripening Wildbacher Sp\u00e4tblau turned out to have distinct DNA profiles, as well as possibly having a parent\u2013offspring relationship (Meneghetti et al. 2009). Further parentage analysis has also suggested that Blauer Wildbacher has a parent\u2013offspring relationship with GOUAIS BLANC, the second parent supposedly being an extinct wild vine (Meneghetti et al. 2009).',
    ],
    'where its grown': [
      'There are about 10 ha (25 acres) in Italy, where it is known as Wildbacher. Producers of varietal Wildbacher include Cantine Collato (Colli Trevigiani IGT) and Tenuta Col Sandago.',
      'This high-acid, spicily perfumed variety with aromas of nettles is ideally suited to be the basis for Schilcher, the eye-wateringly tart pink speciality of the Weststeiermark in central south Austria, where the variety is also known as Schilcher. About 3% is sparkling and even less is made into a still red. Virtually all the Austrian plantings are in the Steiermark and largely in its western enclave (357 ha/880 acres out of a total 454 ha/1,120 acres in 2008). Dom\u00e4ne M\u00fcller even make a TBA version. The best producers include Langmann vulgo Lex, Franz Strohmeier, Christian Reiterer and Friedrich Christian.',
    ],
  },
  {
    name: 'BLAUFR\u00c4NKISCH',
    synonyms: [
      'Blauer Limberger',
      'Borgonja',
      'Burgund Mare',
      'Franconia',
      'Franconia Nera',
      'Frankovka',
      'Frankovka Modr\u00e1',
      'Frankovna Crna',
      'Gam\u00e9',
      'K\u00e9kfrankos',
      'Limberger',
      'Lemberger',
      'Nagyburgundi',
      'Sura Lisicina',
    ],
    description:
      'Dark-skinned, dark-fruited Austro-Hungarian variety increasingly well grown and vinified, gaining ground.',
    'origin and parentage': [
      'From the Middle Ages onwards, the name Fr\u00e4nkisch was given to several high-quality varieties, indicating their superiority over the inferior Heunisch varieties. The word Fr\u00e4nkisch derives from Franconia, a historic region of Germany that today straddles parts of northern Bavaria, southern Th\u00fcringen and Heilbronn-Franken in Baden-W\u00fcrttemberg. One of these high-quality varieties might have been Blaufr\u00e4nkisch, whose name did not appear until 1862 at a grape-variety exhibition in Wien (Vienna; Aeberhard 2005) and was officially adopted by the international ampelographic commission in Colmar, France, in 1875. It was later mentioned in Germany under the synonyms Lemberger (1877) and Limberger (end of the nineteenth century), both deriving from the Austrian locations from which the variety was exported to Germany: Lemberg in the Steiermark (Styria) and Limburg in Maissau (Nieder\u00f6sterreich/Lower Austria). In Hungary, the variety was first mentioned in 1890 under the name K\u00e9kfrankos, the literal translation of Blaufr\u00e4nkisch.',
      'DNA profiling has recently confirmed that the Hungarian K\u00e9kfrankos is identical to Blaufr\u00e4nkisch (Jahnke et al. 2009) and has shown that Blaufr\u00e4nkisch is also cultivated in Croatia under the name Borgonja (Edi Maleti\u0107 and Ivan Peji\u0107, personal communication; Vouillamoz), long thought to be identical to GAMAY NOIR or PINOT (Borgonja means \u2018Bourgogne\u2019). This finding makes more sense in the light of DNA parentage analysis: Blaufr\u00e4nkisch has a parent\u2013offspring relationship with GOUAIS BLANC (Regner et al. 1998; see Pinot pedigree diagram), which is a parent of Gamay Noir (Bowers et al. 1999). As a consequence, the birthplace of Blaufr\u00e4nkisch is most likely to be within the area now demarcated as Dalmacija, Austria and Hungary, although the exact location is still unknown.',
      'The use of the synonym Gam\u00e9 in Bulgaria has often led to \u2013 and may well be due to \u2013 the mistaken belief that Blaufr\u00e4nkisch was a clone of GAMAY NOIR.',
      'Two crosses between Blaufr\u00e4nkisch and SANKT LAURENT produced ANDR\u00c9 and ZWEIGELT, while another one with BLAUER PORTUGIESER produced BLAUBURGER. Blaufr\u00e4nkisch was a parent in the crosses that created ACOLON, CABERNET CUBIN, CABERNET MITOS, HEROLDREBE, MAGYARFRANKOS, RUBINTOS and REBERGER.',
    ],
    'where its grown': [
      'Italy has strictly limited plantings of this variety (127 ha/310 acres), called Franconia Nera, in Friuli in the north east, where it can be turned into fruity, zesty, varietal wines in the Friuli Isonzo and Friuli Latisana DOCs. The German-descended Friedrich Schatz of M\u00e1laga, in southern Spain, makes a varietal Lemberger called Acinipo, which is sweet and lively with good acidity and benefits from a few years in bottle.',
      'In Germany, as (Blauer) Lemberger or Limberger, the variety is grown on 1,729 ha (4,272 acres) of vineyard, mainly in W\u00fcrttemberg in the south around Stuttgart. The wines tend to be lighter and a little softer than their Austrian counterparts.',
      'Blaufr\u00e4nkisch is Austria\u2019s second most planted dark-berried variety after ZWEIGELT, with total plantings in 2008 of 3,340 ha (8,250 acres), up from 2,640 ha (6,524 acres) in 1999, though this is still little more than 6% of the total vineyard area. The vast majority of the vines are in Burgenland, particularly in Mittelburgenland (1,194 ha/2,950 acres), where the warmth and shelter provided by the hills to the west, north and south of the region, in addition to the influence of the warm dry winds from the Pannonian Plain to the east, create ideal conditions for fine, well-structured reds. There are also significant plantings in Neusiedlersee (807 ha/1,994 acres) and Neusiedlersee-H\u00fcgelland (962 ha/2,377 acres).',
      'Blaufr\u00e4nkisch can produce deeply coloured, racy and characterful wines, encouraging more ambitious producers to make oak-aged versions, which are more likely to qualify for the Burgenland DAC Reserve classification, with lighter, unoaked versions designated Mittleburgenland DAC Classic. Indeed, the variety is capable of a wide range of styles that express their very specific origins, from light, fresh and fruity to deeply flavoured, dark-fruited, peppery and firmly structured but still fresh and ageworthy wines. Producers in the foothills of the Leithaberg mountains are able to produce particularly exciting, nervy and elegant examples within the Leithaberg DAC (minimum 50% Blaufr\u00e4nkisch) thanks to the cooler sites further away from the Neusiedlersee and the complex slate and limestone soils. Closer to the lake, wines tend to be richer and more full-bodied. South of the lake, in the northern part of S\u00fcdburgenland, clay-rich soils produce spicy, full-bodied wines that in the past were often overly oaky but this region has some exceptional sites that benefit from Pannonian warmth as well as cooler, higher slopes, giving a long ripening season and a perfect combination for finesse and complexity, exemplified by the wines of Roland Velich at Moric. More recently, fine wines with distinctive regional characteristics are being produced in the iron-rich Eisenberg DAC area in southern S\u00fcdburgenland and in the slaty soils of Spitzerberg in Carnuntum. Particularly fine examples of Austrian Blaufr\u00e4nkisch are made by K+K Kirnbauer, Nittnaus, Pichler-Kruzler, Pittnauer, Prieler, Triebaumer and Wohlmuth.',
      'Hungary has just over 8,000 ha (19,770 acres) of K\u00e9kfrankos, a direct translation of Blaufr\u00e4nkisch (though it is occasionally known as Nagyburgundi), spread across most wine regions but with the greatest presence in Kuns\u00e1g in the south and centre, Eger to the north east and Sopron on the border with Austria. It is considered to be one of Hungary\u2019s best red wine varieties and the Austrian Franz Weninger makes fine examples in a range of styles on both sides of the border, in Burgenland and in Sopron.',
      'Blaufr\u00e4nkisch, known locally as Frankovka Modr\u00e1, is the most widely planted dark-skinned variety in Slovakia, where it is grown on 1,742 ha (4,305 acres) and 9% of the total vineyard area. Recommended producers include Villa Vino Ra\u010da, who organize an annual international Frankovka Modr\u00e1 festival, and Ch\u00e2teau Topol\u2019\u010dianky. Mrva & Stanko is a smaller producer with a fine reputation for this variety.',
      'In Bulgaria it is known as Gam\u00e9, probably a reflection of the fact that for many years the variety was thought to be the same as the GAMAY NOIR of Beaujolais. In Romania, where it goes by the name of Burgund Mare, there are 891 ha (2,202 acres), mainly in the south. It is authorized in south-central regions such as Dealu Mare and \u015etef\u0103ne\u015fti but also further east towards the Black Sea.',
      'The variety is called Frankovka in the Czech Republic and in Vojvodina, Serbia, where it can produce lively, fruity, vigorous wines for early consumption.',
      'In Croatia, Frankovka represented 2.7% of the total vineyard area in 2009, ie around 880 ha (2,175 acres) but this does not include the Borgonja plantings as these are limited and have only recently been shown to be Blaufr\u00e4nkisch (see above). Frankovka is found mainly in Kontinentalna Hrvatska (Continental Croatia) in the north east and Borgonja in the north-western Istra peninsula, where it produces relatively light wines.',
      'As Lemberger, Blaufr\u00e4nkisch is also planted in British Columbia, Canada, and was at one time quite popular in Washington State\u2019s Yakima Valley, although by 2011 Washington\u2019s total plantings had fallen back to just 73 acres (30 ha). There are limited plantings in New York State\u2019s Finger Lakes region, where it may be blended with CABERNET FRANC to add richer, darker fruit flavours or made as a varietal. Peter Bell at Fox Run notes that in some vintages the wines need to be deacidified a little. Other producers of varietal wines include Anthony Road, Goose Watch and Swedish Hill in New York State. On Long Island, Channing Daughters label their wine Blaufr\u00e4nkisch.',
      'In Australia, one lone producer in the Adelaide Hills, Hahndorf Hill, have made a very successful Blaufr\u00e4nkisch, having for some years blended it with Trollinger (SCHIAVA GROSSA) to make a ros\u00e9. Mac Forbes also has plans to make an Australian Blaufr\u00e4nkisch.',
      'Lemberger is also found in Japan.',
    ],
  },
  {
    name: 'BOBAL',
    synonyms: [
      'Balau',
      'Benicarl\u00f3',
      'Bobos',
      'Carignan Espagnol',
      'Carignan d\u2019Espagne',
      'Coreana',
      'Espagnol',
      'Moravio',
      'Pobret\u00f3n',
      'Provech\u00f3n',
      'Rajeno',
      'Requena',
      'Terret d\u2019Espagne',
      'Tinta Madrid',
      'Tonto de Zurra',
      'Valenciana',
    ],
    description:
      'Important and increasingly respected variety producing velvety wines in Spain\u2019s Levante.',
    'origin and parentage': [
      'Bobal comes from the region of Utiel-Requena in central eastern Spain, where it is the predominant variety and where it is said to have been mentioned as early as the fifteenth century in Jaume Roig\u2019s misogynist diatribe Espill o llibre de les dones. The old Spanish synonym Benicarl\u00f3, named after a port on the Mediterranean coast, is no longer in use and is best avoided because the name Beni Carlo is mistakenly used for MONASTRELL in the Dordogne.',
      'Bobal Blanca is not a colour mutation of Bobal because it is identical to ALCA\u00d1\u00d3N (Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007). Although Bobal is called Prunelar in the Ari\u00e8ge, it should not be confused with the true French variety PRUNELARD.',
    ],
    'where its grown': [
      'Bobal is grown mainly in the provinces of Valencia, Cuenca and Albacete in eastern central Spain. It is particularly important in the Utiel-Requena and Manchuela DOs, where it is the dominant variety. Total plantings in Spain in 2007 were officially 85,124 ha (210,346 acres), mostly in Castilla-La Mancha (51,277 ha/126,708 acres) and Valencia (33,389 ha/82,506 acres), but the real total is probably a good deal higher. It tends to ripen unevenly so the wines can be slightly rustic, but the best of them are deeply coloured and smooth. Bobal has traditionally been relegated to bulk-wine production but producers working at higher altitudes (700\u2013770 m above sea level), such as Mustiguillo in Utiel-Requena and Finca Sandoval in Manchuela, have been taking this variety more seriously and giving it the attention it deserves, particularly since it retains its acidity better than MONASTRELL and tends to be lower in alcohol, producing well-structured but velvety and fresh reds. At Finca Sandoval, Victor de la Serna includes it in his SYRAH-dominated blends. Bobal is authorized in the Utiel-Requena, Valencia, Manchuela and Ribera del J\u00facar DOs. Quaffable, fresh Bobal ros\u00e9s are also on the increase.',
    ],
  },
  {
    name: 'BO\u011eAZKERE',
    synonyms: ['\u015earapl\u0131k Siyah'],
    description:
      'Tannic but potentially high-quality, quite common Turkish variety.',
    'origin and parentage': [
      'Bo\u011fazkere probably comes from the El\u00e2z\u0131\u011f or Diyarbak\u0131r provinces in eastern Anatolia. DNA profiling has shown that \u015earapl\u0131k Siyah, once cultivated in the \u00c7ermik region near Diyarbak\u0131r in south-eastern Anatolia, is in fact identical to Bo\u011fazkere, and DNA parentage analysis has suggested a parent\u2013offspring relationship between Bo\u011fazkere and Morek, an almost extinct variety from the Ergani region near Diyarbak\u0131r (Vouillamoz et al. 2006). A recent genetic study based on forty-four varieties from Turkey and elsewhere suggested that Bo\u011fazkere could be genetically close to Kabarc\u0131k Beyaz (no longer cultivated) and \u00d6K\u00dcZG\u00d6Z\u00dc (Sabir et al. 2009), but this is not consistent with the analysis of sixty-two varieties from Turkey compared with fifty-four additional ones from neighbouring Armenia and Georgia that clustered Bo\u011fazkere with three extinct varieties: Morek and Belel\u00fck from the \u00c7\u00fcng\u00fc\u015f region near Diyarbak\u0131r and Khupishizh from Apkhazeti in Georgia (Vouillamoz et al. 2006). Bo\u011fazkere means \u2018burns the throat\u2019, hinting at the variety\u2019s marked tannins.',
    ],
    'where its grown': [
      'Bo\u011fazkere is found mainly in the \u00c7ermik and \u00c7\u00fcng\u00fc\u015f districts of Diyarbak\u0131r, south-eastern Anatolia, but also in the neighbouring provinces of El\u00e2z\u0131\u011f and Malatya as well as further to the south west in the province of Gaziantep. Total plantings in Turkey in 2010 were 1,486 ha (3,672 acres). The variety\u2019s increasing popularity has encouraged growers in western Anatolia to plant it, both on the Denizli plateau in south-western Turkey and further north and west in Manisa, with varying degrees of success. Varietal wines are not very deeply coloured, and have marked tannins, flavours of dark or dried fruit, and moderate acidity. They can be rather rustic or tough but the best develop complex, earthy flavours such as leather and tobacco as the tannins calm down with time in bottle. The variety marries well with oak and is often blended with Turkey\u2019s other widely planted indigenous variety, \u00d6K\u00dcZG\u00d6Z\u00dc, which has softer tannins and fresher acidity. Producers include Corvus, Doluca, Kavaklidere, Kayra and Sevilen.',
    ],
  },
  {
    name: 'BOGDANU\u0160A',
    synonyms: [
      'Bogdanju\u0161a',
      'Bogdanu\u0161a Bijela',
      'Bojdanu\u0161a',
      'Hvarka',
      'Vrbanjka',
    ],
    description: 'Makes light, fresh white on the Croatian island of Hvar.',
    'origin and parentage': [
      'Bogdanu\u0161a comes from the Croatian island of Hvar, south of Split. The name Bogdanu\u0161a, meaning \u2018given by God\u2019 in Croatian, was supposedly chosen because this wine was drunk during religious celebrations.',
    ],
    'where its grown': [
      'Bogdanu\u0161a is officially authorized only in the Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine region in Croatia and most of the plantings are on the island of Hvar, mainly in the Stari Grad and Jelsa regions and more often than not for sweet wines or for use in blends. Varietal wines tend to have fresh acidity, moderate alcohol and sometimes a slight bitterness on the finish, and are produced by, for example, Plan\u010di\u0107 (Hvar) and Ivo Cari\u010d (Hvar). Plan\u010di\u0107\u2019s Ager is an oaked blend of Bogdanu\u0161a and PR\u010c, both indigenous to the island, while Zlatan Otok\u2019s Zavala blends PO\u0160IP BIJELI, Mara\u0161tina (MALVASIA BIANCA LUNGA) and Bogdanu\u0161a. Plenkovi\u010d and Tomi\u0107 use the variety in blends.',
    ],
  },
  {
    name: 'BOMBINO BIANCO',
    synonyms: [
      'Bambino',
      'Buonvino',
      'Ottonese',
      'Pagadebit',
      'Straccia Cambiale',
    ],
    description:
      'Productive Italian white found mainly in the south and only rarely distinguished.',
    'origin and parentage': [
      'While some authors argue that Bombino Bianco came from Spain, most consider it to be indigenous to Italy, possibly Puglia. Bombino means \u2018small bomb\u2019, probably related to the shape of the bunches. DNA profiling has confirmed that Ottonese cultivated in Lazio is identical to Bombino Bianco (Manna Crespan, personal communication). Although Bombino Bianco is not a white mutation of BOMBINO NERO, DNA analysis suggests they could be closely related (Vouillamoz). It is not yet known whether Bombino Bianco and TREBBIANO D\u2019ABRUZZO are distinct varieties (see Trebbiano d\u2019Abruzzo) but both are unrelated to TREBBIANO TOSCANO and Trebbiano di Soave, the latter being identical to VERDICCHIO BIANCO. Recent DNA parentage analysis has suggested that Bombino Bianco is a possible parent of both IMPIGNO and MOSCATELLO SELVATICO from Puglia (Cipriani et al. 2010).',
    ],
    'where its grown': [
      'Widely planted in southern Italy, mainly in Puglia and particularly in the provinces of Foggia (where it constitutes 40\u201360% of the San Severo DOC), Bari and Lecce, but also in Emilia-Romagna (at least 85% required for Pagadebit di Romagna DOC), Lazio (where it is called Ottonese in the province of Frosinone, is part of a traditional white blend such as Frascati DOC, and may be a varietal in the Marino DOC), the Marche and Abruzzo. The Trebbiano d\u2019Abruzzo DOC is particularly confusing because it can be made from 85\u2013100% Bombino Bianco \u2013 ie TREBBIANO D\u2019ABRUZZO \u2013 or from 85\u2013100% TREBBIANO TOSCANO.',
      'The variety produces relatively neutral whites but is a useful ingredient in EU table wine and in cheap German Sekt, where it is generally blended with a more aromatic partner such as MORIO-MUSKAT. Better producers coax aromas of exotic fruits, occasional herbaceous or citrus notes, and a light minerality from this variety. Producers who make a varietal wine labelled Ottonese include Proietti and the Cantina Sociale Olevano Romano. The variety is also used in the production of vermouth. There were almost 3,000 ha (7,410 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'BOMBINO NERO',
    synonyms: ['Bambino', 'Buonvino'],
    description:
      'Southern Italian dark-skinned variety much less common than its pale namesake.',
    'origin and parentage': [
      'Bombino Nero is most likely from Puglia. Although Bombino Nero is not the black version of BOMBINO BIANCO, DNA profiling suggests they could be closely related (Vouillamoz). Bombino means \u2018small bomb\u2019, probably a reference to the shape of the bunches.',
    ],
    'where its grown': [
      'Cultivated mainly in Puglia, Italy (alone or as part of a blend in Castel del Monte DOC and optional in Lizzano DOC), for red and especially ros\u00e9 wines, but also in Basilicata, Lazio and on Sardegna. The 2000 census records plantings of 1,170 ha (2,890 acres). Wines tend to be light in colour with simple, soft, red-fruit flavours.',
    ],
  },
  {
    name: 'BONAMICO',
    synonyms: ['Giacomino', 'Uva di Palaia'],
    description:
      'High-yielding Italian black variety hanging on in the Pisa region.',
    'where its grown': [
      'Bonamico, literally \u2018good friend\u2019, was planted to a limited extent all over Toscana, Italy, until the 1960s but is now found only in old vineyards near Pisa, Pistoia and Lucca in Toscana. The 2000 census registered just over 100 ha (250 acres). High yields and late maturation (towards the end of September) mean that it is sometimes used as a table grape. In the winery, it is usually blended with other local grapes. Its naturally high yields result in musts low in alcohol and high in acid, and the risk of unripe tannins. However, if yields are restricted, Bonamico can produce medium-bodied, balanced wines with good acidity and floral aromas.',
    ],
  },
  {
    name: 'BONARDA PIEMONTESE',
    synonyms: [
      'Bonarda del Monferrato',
      'Bonarda dell\u2019Astigiano',
      'Bonarda di Chieri',
    ],
    description:
      'Aromatic, well-coloured Italian red with small bunches and soft tannins.',
    'origin and parentage': [
      'Bonarda is a misleading name applied to at least six distinct varieties in northern Italy (see above). In Piemonte in north-west Italy, Bonarda Piemontese was first mentioned by Nuvolone Pergamo (1787\u201398), Conte di Scandaluzza, who refers to its presence in the Torino hills. For details of the commonly planted Bonarda of Argentina, see DOUCE NOIRE.',
    ],
    'where its grown': [
      'Bonarda Piemontese is cultivated mainly in the Pinerolese, in Astigiano, and in the Collina Torinese in northern Italy, and used in blends to provide colour, or sugar to increase alcohol levels, and the variety is often used to soften the tannins of NEBBIOLO. A varietal Bonarda is authorized in DOCs such as Collina Torinese, Colline Novaresi, Pinerolese and Piemonte, although the regulations do not always make it clear which Bonarda is being referred to. Such wines are characterized by fresh floral aromas, low acidity, powdery tannins and a balanced structure. Paolo de Marchi at Villa Sperino has been instrumental in reviving Coste della Sesia DOC, which allows for a maximum of 50% Bonarda Piemontese (though a varietal Bonarda delle Coste della Sesia is also possible). Benotto, Carlo Daniele Ricci and Cascina Gilli all produce varietal Bonarda Piemontese, though it is rarely exported.',
      'Piemonte regional government statistics recorded 218 ha (540 acres) in 2001.',
    ],
  },
  {
    name: 'BONDOLA',
    synonyms: ['Bondola Nera', 'Briegler', 'Brieger', 'Bundula', 'Longobardo'],
    description:
      'Old, rare Ticino variety that has been overwhelmed by Merlot.',
    'origin and parentage': [
      'Bondola was once the most important grape variety in Ticino, the southern, Italian-speaking canton of Switzerland, where it was first mentioned in 1785 by H R Schinz, who described it as a very fine variety (Marcel Aeberhard, personal communication). DNA profiling has revealed that Briegler, an almost extinct variety once cultivated in German-speaking Swiss cantons (mainly Z\u00fcrich, Aargau, Luzern and Bern), is identical to Bondola (Aeberhard 2005; Frei et al. 2006). DNA parentage analysis shows that Bondola and COMPLETER from Graub\u00fcnden in the south east are the parents of BONDOLETTA and HITZKIRCHER, two minor Swiss varieties that have sometimes been confused with Bondola (Vouillamoz and Moriondo 2011).',
      'A white mutation called Bondola Bianca has been mentioned (Marcel Aeberhard, personal communication) but what the Ticino grape grower Stefano Haldemann has recently called Bondola Bianca is a completely distinct variety (Vouillamoz).',
    ],
    'where its grown': [
      'Bondola used to be widely cultivated in the Sopraceneri, the northern part of Ticino, Italian Switzerland, especially in the areas of Bellinzona, Biasca and Giornico (Marcel Aeberhard personal communication), but MERLOT has bulldozed its way across the region in the last century and plantings of Bondola and other old local varieties have been drastically reduced. Bondola gives typically deeply coloured, fruity wines with hints of red cherries and blueberries, showing rustic yet pleasant tannins and a vinous, slightly acidic, bitter and refreshing finish. In some ways, Bondola, lacking structure and complexity, is to Merlot in Ticino what DOLCETTO is to NEBBIOLO in Piemonte.',
      'There were just 13 ha (32 acres) in Ticino in 2009 and only a handful of producers of varietal wines, including Azienda Mond\u00f2 and Stefano Haldemann at La Segrisola. Nostrano, meaning \u2018ours\u2019 in Italian, is a locally popular Ticino wine that blends Bondola with up to thirty other varieties, including BARBERA, FREISA and American hybrids such as ISABELLA and CLINTON.',
      'The variety is even more elusive under the name Briegler but the Rebbaugesellschaft Hitzkirch in the canton of Luzern have some in a mixed vineyard that is used to make their Schillerwein, a ros\u00e9 made from a mix of red and white grapes.',
    ],
  },
  {
    name: 'BORD\u00d4',
    synonyms: ['Grana d\u2019Oro'],
    description:
      'American hybrid used to make cheap, sweetish reds and juices in Brazil.',
    'origin and parentage': [
      'Bord\u00f4 is a variety grown in Brazil that is genetically very close to the American hybrids ISABELLA, CONCORD and NIAGARA (Schuck et al. 2009), suggesting it is hybrid of American origin too. It would be very useful to compare its DNA profile with those of IVES from Ohio and York Madeira from Pennsylvania, two American hybrids with the synonym Bordo for which DNA information is not available. Bordo is also a synonym for both CABERNET SAUVIGNON and CABERNET FRANC in Romania and for CARMEN\u00c8RE in Italy.',
    ],
    'where its grown': [
      'Bord\u00f4 is the second most planted variety in Brazil, where it is valued for its deep colour and used mainly to produce inexpensive, slightly sweet table wines as well as juice. There were 6,726 ha (16,620 acres) in the state of Rio Grande do Sul alone in 2007.',
    ],
  },
  {
    name: 'BORRA\u00c7AL',
    synonyms: [
      'Azedo',
      'Bogalhal',
      'Ca\u00ed\u00f1o Tinto',
      'Espadeiro Redondo',
      'Olho de Sapo',
      'Tinta Femia',
    ],
    description:
      'Makes potent, tart, sometimes tough wines in north-west Spain as well as in its native Vinho Verde.',
    'origin and parentage': [
      'Borra\u00e7al is a variety from the Vinho Verde region in north-west Portugal, where it was mentioned in the Minho region as early as 1790 by Lacerda Lobo (Galet 2000).',
    ],
    'where its grown': [
      'Borra\u00e7al wines are typically ruby coloured, high in both alcohol and acidity, and in the Minho region of northern Portugal, where it is generally recommended (but not in the Mon\u00e7\u00e3o subregion), it is often blended with VINH\u00c3O. Recommended producers include the Cooperativa de Ponte da Barca and the Quinta da Lixa. There were 187 ha (462 acres) in Portugal in 2010.',
      'As Ca\u00ed\u00f1o Tinto in Spain, the variety is found mainly in Galicia in the north west, where it is said to be productive and have small compact bunches of thick-skinned berries. Wines are generally high in alcohol and are often made using carbonic maceration to overcome the variety\u2019s characteristically astringent, often bitter, tannins. Older vines and limited yields can improve wine quality.',
    ],
  },
  {
    name: 'BOSCO',
    synonyms: ['Bosco Bianco del Genovese', 'Bosco Bianco di Savona'],
    description: 'Ligurian white speciality making excellent sweet wines.',
    'origin and parentage': [
      'Bosco, one of the most traditional grapes of Liguria in north-west Italy, where it probably comes from, means \u2018woodland\u2019, supposedly because it was introduced to Cinque Terre in the south-east of Liguria from cuttings taken in the woods around Villa dei Marchesi Durazzo in nearby Genova.',
    ],
    'where its grown': [
      'Cultivated now in the provinces of Genova and La Spezia, north-west Italy, Bosco is the main variety in the locally famous and expensive Cinque Terre Sciacchetr\u00e0 dessert wine, often blended with ALBAROLA and VERMENTINO. Amber-coloured, with aromas of nuts, almonds, honey and dried figs and tasting of apricot jam, this rich wine shows excellent balance between sweetness and acidity. Walter de Batt\u00e8 and Possa make very good examples. It is also authorized in Colline di Levanto and one or two other local DOC white wines. Just over 100 ha (250 acres) were recorded in the 2000 census.',
    ],
  },
  {
    name: 'BOUCHAL\u00c8S',
    synonyms: [
      'Bouchalets',
      'Bouissalet',
      'Gros Bouchal\u00e8s',
      'Gros Bouchar\u00e8s',
      'Grappu',
      'Grapput',
      'Picardan Noir',
      'Prolongeau',
    ],
    description: 'Declining dark-skinned variety surviving around Bordeaux.',
    'origin and parentage': [
      'Bouchal\u00e8s was mentioned in 1783\u20134 in several places in south-west France:',
      '\u2013\u00a0\u00a0\u00a0Estillac (Lot-et-Garonne): \u2018Bouchalets .\u00a0.\u00a0. il est extraordinairement noir, et tr\u00e8s bon\u2019 (\u2018Bouchalets .\u00a0.\u00a0. is extraordinarily black, and very good\u2019)',
      '\u2013\u00a0\u00a0\u00a0Auch (Gers): \u2018Gros bouchal\u00e8s, raisin noir .\u00a0.\u00a0. de tr\u00e8s bon gout, excellent pour le vin\u2019 (\u2018Gros bouchal\u00e8s, black variety .\u00a0.\u00a0. tastes good, excellent for wine\u2019)',
      '\u2013\u00a0\u00a0\u00a0Agen (Lot-et-Garonne): \u2018Bouchal\u00e8s grand .\u00a0.\u00a0. Bouchal\u00e8s petit .\u00a0.\u00a0. Le vin en seroit excellent\u2019 (\u2018Bouchal\u00e8s grand .\u00a0.\u00a0. Bouchal\u00e8s petit .\u00a0.\u00a0. The wine is said to be excellent\u2019)',
      '\u2013\u00a0\u00a0\u00a0Saint-Barth\u00e9l\u00e9my (Dordogne): \u2018Gros Bouchar\u00e8s. Petit Bouchar\u00e8s\u2019',
      '\u2013\u00a0\u00a0\u00a0Tonneins (Lot-et-Garonne): \u2018Gros Bouchal\u00e8s, tr\u00e8s bon, son vin est bien noir\u2019 (\u2018Gros Bouchal\u00e8s, very good, its wine is really black\u2019)',
      'Several subsequent references in the south west support the Vall\u00e9e de la Garonne as Bouchal\u00e8s\u2019 place of origin. Although it is also called Bouissalet in the Dordogne, it is not a colour mutation of ARRUFIAC, which is called Bouisselet in Pacherenc.',
      'According to some scholars, the name Bouchal\u00e8s could come from the Occitan boish meaning \u2018box tree\u2019 (Lavignac 2001). However, many varieties may resemble box and a simple alternative etymology is that since Bouchal\u00e8s is a family name that still exists in the Lot-et-Garonne, the likely birthplace of this variety, it is quite possible that the variety inherited its name from its propagator.',
      'Bouchal\u00e8s was often confused with COT, perhaps because both belong to the Cot ampelographic group (see p XXVII; Bisson 2009).',
      'Picardan Noir is an old and widespread synonym for Bouchal\u00e8s but it is a source of confusion because it is also a synonym for the dark-skinned CINSAUT.',
    ],
    'where its grown': [
      'Bouchal\u00e8s is still cultivated to a limited extent in the Vall\u00e9e de la Garonne, France, a total of 110 ha (272 acres) in 2008 compared with nearly 5,000 ha (12,355 acres) fifty years ago. It is not easy to graft or grow and therefore continues to decline. At the Ch\u00e2teau de la Vieille Chapelle, north west of Libourne in Bordeaux, a few very old vines from their oldest vineyard were identified as Bouchal\u00e8s by DNA profiling at the Institut Fran\u00e7ais de la Vigne et du Vin P\u00f4le Sud-Ouest in 2009. These centenarian vines might have survived phylloxera thanks to the ancient tradition of flooding the vineyards every winter with the waters of the neighbouring Dordogne, disrupting the pest\u2019s life cycle. The grapes from these vines are blended with MERLOT from the same vineyard in the special Cuv\u00e9e A&A (Amis & Associ\u00e9s, \u2018Friends and Associates\u2019).',
    ],
  },
  {
    name: 'BOURBOULENC',
    synonyms: [
      'Blanquette',
      'Bourboulenco',
      'Bourbouleng',
      'Bourboulenque',
      'Bourbounenco',
      'Clairette \u00e0 Grains Ronds',
      'Clairette Dor\u00e9e',
      'Doucillon',
      'Malvoisie',
      'Picardan',
    ],
    description:
      'Useful vine making interesting white throughout the south of France.',
    'origin and parentage': [
      'Bourboulenc is an old variety from the Vaucluse in Provence, southern France, probably first mentioned in 1515 in Cavaillon as Borbolenques: \u2018ex bona et fertile vinea videlicet espace, buguera nigra, espagnens, ribiers, brossonaulx, rodeilhac, borbolenques\u2019 (\u2018from a good and fertile vineyard, that is to say .\u00a0.\u00a0.\u2019), and in 1538 near Avignon, also under the name Borbollenque. Numerous additional references have been recorded in the Vaucluse and in the southern Rh\u00f4ne. The name Bourboulenc is likely to derive from Barbolenquiera, the old name of a small vineyard in Aubignan near Avignon. Bourboulenc is a member of the Claret ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'There were 622 ha (1,537 acres) in France in 2008, where it is authorized or recommended in many d\u00e9partements of southern France. This is about half the area compared with fifty years ago but plantings see-sawed in the 1970s and 1980s, during a period of re-evaluation and greater appreciation of its ability to retain acidity in the heat. It may be included in many an appellation, eg Ch\u00e2teauneuf-du-Pape, Costi\u00e8res de N\u00eemes, Tavel in the southern Rh\u00f4ne or Cassis and Bandol in Provence, though it rarely contributes more than 20\u201330% to the blend. However, it is listed as a principal variety for whites such as Languedoc, Corbi\u00e8res and Minervois, where it is usually blended with other regional varieties such as Grenache Blanc (GARNACHA BLANCA), Macabeu (MACABEO), MARSANNE or CLAIRETTE. It is particularly successful on the La Clape massif south of Narbonne, where it takes on a marine note.',
      'Wines are generally moderate in alcohol, and semi-aromatic with citrus aromas and a hint of smoke as long as the grapes are picked late enough, though they are sometimes a little rustic. Recommended producers include Ch\u00e2teau d\u2019Angl\u00e8s and Ch\u00e2teau des Karantes, both using 50% Bourboulenc in their white La Clape blends. Christophe Barbier of Ch\u00e2teau Bouisset goes one step further and makes two varietal wines: Les Terres Sal\u00e9es, made from ungrafted vines and aged in new oak, and the unoaked Bourboulenc Classique.',
    ],
  },
  {
    name: 'BOURRISQUOU',
    synonyms: ['Bourriscou', 'Mourrisquou de Romani', 'Romanet'],
    description:
      'Very rarely makes wine; better known as the parent of various rootstocks.',
    'origin and parentage': [
      'There are several stories about the origin of Bourrisquou. According to Rougier (1904), a Dr Silhol wrote in 1887 that Bourrisquou was found in a wood in Lanas near the village of Vog\u00fc\u00e9 (west of Mont\u00e9limar in the Ard\u00e8che) by a vine grower named Romanet, hence the local synonym. However, Silhol did not believe that Bourrisquou originated from the region and suggested instead that it was introduced from Spain to Aubenas, north of Vog\u00fc\u00e9, by a soldier coming back from the war. Then birds ate the berries in Aubenas and ejected the seeds in Lanas, where Romanet found the grapevine. It should be noted that this hypothesis does not make sense because if the vine had grown from the grape seed, the Lanas vine would have been a progeny of the Aubenas vine, and thus a distinct variety.',
      'After a thorough in situ investigation, Rougier confirmed that it was Bourrisquou that Romanet found, and suggested a convincing story for its etymology: the vine was propagated in the Aubenas area by a hawker named Bonnand, who was selling various objects carried on the back of his donkey. Bonnand heard many vine growers complaining about their vines being attacked by powdery mildew and persuaded them to buy and plant cuttings of Romanet\u2019s disease-free vines, thus propagating Bourriquou in the whole region. The link is that bourrisquou means \u2018donkey\u2019 in the local dialect (corresponding to the French bourrique) and the vine that Bonnand used to sell with his donkey was simply called Bourrisquou, which was also Bonnand\u2019s nickname .\u00a0.\u00a0.',
      'The Spanish origin of Bourrisquou is highly questionable, and Rougier wisely considers that Bourrisquou was born in the Lanas wood. The true origin of Bourrisquou still has to be established by DNA analysis.',
      'Bourrisquou was used by Couderc to breed several hybrids, often used as rootstocks.',
    ],
    'where its grown': [
      'Bourrisquou is not included in the official register of varieties in France and has almost disappeared from the Ard\u00e8che. Only a few producers still have it in their vineyards but Mas de La Salle in the C\u00e9vennes have recently planted a few experimental ares of Bourrisquou, CHICHAUD and Vituaigne and will increase the area if the results of vinification are encouraging.',
    ],
  },
  {
    name: 'BOUTEILLAN NOIR',
    synonyms: ['Fouiral', 'Moulas', 'Psalmodi Noir', 'Sigoyer'],
    description: 'Virtually extinct Proven\u00e7al variety.',
    'origin and parentage': [
      'The earliest mention of this variety appeared in 1715 in the Vaucluse, in southern France: \u2018Bouteillan. Quoique cette espece soit assez commune dans n\u00f4tre [sic] terroir, elle l\u2019est encore plus dans ceux de Rians, de Pertuis, de Cadenet, de Cucuron, etc.\u2019 (\u2018Bouteillan. Although this species is rather common in our region, it is even more common in Rians, Pertuis, Cadenet, Cucuron, and so on\u2019). Several other mentions of Bouteillan Noir were later recorded, mainly in the Vaucluse. Its name could derive from the shape of its cluster, which resembles a small bottle (bouteille).',
      'Although ARAMON NOIR is also called Gros Bouteillan, it is not related to Bouteillan Noir. According to Bisson (2009), Bouteillan Noir is ampelographically close to COLOMBAUD, which might explain why this latter is sometimes erroneously considered a white mutation of the former.',
    ],
    'where its grown': [
      'Bouteillan Noir has more or less disappeared from the vineyard in France but, according to Galet (2000), the wines are pale and low in alcohol.',
    ],
  },
  {
    name: 'BOUVIER',
    synonyms: [
      'Bela Ranina',
      'Bouvier Blanc',
      'Bouvierovo Hrozno',
      'Bouviertraube',
      'Radgonska Ranina',
      'Ranina',
      'Ranina Bela',
    ],
    description:
      'Low-acid Central European variety used for many styles of wine.',
    'origin and parentage': [
      'The Austrian-born banker and winery owner Clotar Bouvier (1853\u20131930) discovered this and other varieties in 1900 in his vineyards in Hercegov\u0161\u010dak (Herzogenberg) near Gornja Radgona (Oberradkersburg), in north-east Slovenia. Bouvier first used it to breed other varieties in his own nursery and later started to sell it in Austria and Slovenia. DNA parentage analysis has suggested that Bouvier could be a natural cross between PINOT and an unknown variety (Regner 2000a).',
      'Bouvier was used by its discoverer and others to breed BIANCA, KABAR, VERITAS, ZENG\u0150, ZENIT, Z\u00c9TA and ZEUSZ, all grown in Hungary.',
    ],
    'where its grown': [
      'Bouvier, grown in Central Europe for the table and for wine, is used for several very different wines in Austria: the frothy, part-fermented Sturm wine sold soon after harvest; lightly Muscat-scented dessert wines up to TBA sweetness levels, which are aromatic but tend to lack acidity and are therefore often blended; as well as for simple early-drinking whites. There were 234 ha (578 acres) in Austria in 2009 \u2013 a decline of nearly 40% in the last ten years \u2013 the vast majority (204 ha/504 acres) being in Burgenland and more especially around the Neusiedlersee but also further north in the Weinviertel. Recommended producers include Heribert and Pamela Brandl, Laurer, Willi Opitz, Franz Schindler and Wenzl-Kast.',
      'Recommended producers in Slovenia (40 ha/99 acres in 2009), where it is planted mostly in the Podravje region in the north east, include Radgonske Gorice and Steyer. Bouvier is also planted in Slovakia, under the name Bouvierovo Hrozno, and to a limited extent in Eger in north-western Hungary.',
    ],
  },
  {
    name: 'BRACCIOLA NERA',
    synonyms: ['Bracciuola', 'Braciola', 'Braciuola', 'Brassola'],
    description:
      'Obscure, high-yielding, late-ripening black Italian variety found in Toscana and Liguria.',
    'origin and parentage': [
      'It is often assumed that Bracciola Nera was first mentioned by Soderini (1600) under the name Bracciuola. However, Soderini\u2019s Bracciuola was cited among the best white-berried varieties, together with Trebbiano Perugino (no longer commercially cultivated) and COLOMBANA NERA, and there\u2019s no evidence that Bracciuola was a white version of Bracciola Nera. This means that the earliest mention of the latter appears in Acerbi (1825), under the name Braciola in Cinque Terre in Liguria, in north-west Italy, suggesting its origins may lie somewhere on the Levant coast.',
    ],
    'where its grown': [
      'Cultivation of this variety with good acidity is today limited to the provinces of Massa-Carrara in Toscana and La Spezia in Liguria, Italy, where it is found only in the Colli di Luni DOC, traditionally blended with VERMENTINO NERO and with the white varieties TREBBIANO TOSCANO and VERMENTINO. 110 ha (270 acres) were recorded in the 2000 census.',
    ],
  },
  {
    name: 'BRACHETTO DEL PIEMONTE',
    synonyms: ['Bracchetto', 'Brachetto d\u2019Acqui'],
    description:
      'Rose-scented Piemontese variety making very light, sweet, frothy reds.',
    'origin and parentage': [
      'The name Brachetto has been given to many distinct varieties, which is why we opted for the more specific Brachetto del Piemonte for this variety of uncertain origins, possibly originating from the hills of Monferrato spanning the provinces of Asti and Alessandria, where it was first mentioned by Di Rovasenda (1877).',
    ],
    'where its grown': [
      'Cultivated especially around Acqui Terme in the provinces of Asti and Alessandria in the south east of Piemonte in north-west Italy, supposedly since the Roman period under the name Vinum Acquense. It is also recommended in the Piemonte provinces of Cuneo and Torino. In the 1970s, the producer Arturo Bersano invented Brachetto Spumante, a dessert sparkling wine fermented in pressurized tanks that has now become popular as the sweet, low-alcohol Brachetto d\u2019Aqui DOCG. Bersano\u2019s Brachetto d\u2019Acqui Castelgaro has aromas of wild roses and fresh red fruit, with a sweet yet fresh palate \u2013 a light red equivalent of Moscato d\u2019Asti. Dry, aromatic varietal Brachetto \u2013 both still and sparkling \u2013 is also produced within the Piemonte DOC but is much less common. There were 1,280 ha (3,160 acres) in Piemonte in 2001.',
      'Pizzini produce some in the King Valley, Victoria, Australia.',
    ],
  },
  {
    name: 'BRAQUET NOIR',
    synonyms: ['Brachet'],
    description:
      'Very minor but historic, vine making aromatic light reds around Nice.',
    'origin and parentage': [
      'Braquet Noir is an old variety from the regions of Bellet, Nice, Grasse and Cannes, where it was already mentioned in 1783\u20134: \u2018Braquet, grain de moyenne grosseur, rond, et noir clair, bon \u00e0 manger et \u00e0 faire du bon vin par son m\u00e9lange avec d\u2019autres raisins\u2019 (\u2018Braquet, medium-sized, round and light black berries, good to eat and to make good wine in a blend with other varieties\u2019). This variety is often confused with CALITOR NOIR, and also with BRACHETTO DEL PIEMONTE aka Brachetto d\u2019Acqui from Piemonte (Galet 1990). Braquet Noir belongs to the Calitor ampelographic group (see p XXVII; Bisson 2009). Its name could derive from an old local family name.',
      'Braquet Blanc de Nice is a white mutation of Braquet Noir.',
    ],
    'where its grown': [
      'There were just 12 ha (30 acres) in France in 2008, mainly in the area around Nice. Under the names Braquet and Brachet, the latter used in the French official catalogue of varieties, it is today cultivated mainly in the Bellet appellation and blended with FUELLA NERA. These two principal varieties must contribute at least 60% but Grenache (GARNACHA) and CINSAUT may also be added to produce red and ros\u00e9 wines. The variety is aromatic, often floral, but low in colour, which is probably why the rare varietal wines, such as those made by Ch\u00e2teau de Bellet and Le Clos Saint-Vincent, are ros\u00e9s.',
    ],
  },
  {
    name: 'BRATKOVINA BIJELA',
    synonyms: ['Brabkovica', 'Mesnac'],
    description:
      'Rare Croatian island white adding freshness to Po\u0161ip blends.',
    'origin and parentage': [
      'Bratkovina Bijela most likely originates from the island of Kor\u010dula in Dalmacija (Dalmatia), roughly halfway between Split and Dubrovnik in Croatia. DNA parentage analysis has established that it is a parent of PO\u0160IP BIJELI (Piljac et al. 2002).',
      'The black-berried Bratkovina Crna from the same island is a distinct variety but is not commercially cultivated, not least because its female flowers make pollination difficult and yields therefore unpredictable.',
    ],
    'where its grown': [
      'Bratkovina Bijela is officially authorized only in the Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine region of Croatia, which includes the island of Kor\u010dula. Here it is often blended with its progeny PO\u0160IP BIJELI to add freshness. The Saint-Mareli\u0107 Winery in Cara, for example, include 15% Bratkovina Bijela in their top Po\u0161ip Sveti Ivan.',
    ],
  },
  {
    name: 'BRAUNER VELTLINER',
    synonyms: ['Todtr\u00e4ger', 'Veltliner Braun'],
    description: 'Almost extinct Austrian, unrelated to Roter Veltliner.',
    'origin and parentage': [
      'Brauner Veltliner is a variety from Nieder\u00f6sterreich (Lower Austria) with brownish-greyish berries that is used to make white wines. It is often erroneously considered to be a colour mutation of ROTER VELTLINER or a synonym for \u00d6STERREICHISCH WEISS but this is rejected by DNA profiling (Regner et al. 1996).',
    ],
    'where its grown': [
      'Formerly widespread in Nieder\u00f6sterreich (Lower Austria) and in Hungary, Brauner Veltliner has today almost disappeared. A rare varietal Brauner Veltliner is crafted by Alois Raubal (Bioweinbau-Raubal) in Austria\u2019s Thermenregion.',
    ],
  },
  {
    name: 'BREIDECKER',
    synonyms: ['Geisenheim 49-84'],
    description:
      'Relatively neutral German hybrid that has found limited favour in New Zealand.',
    'origin and parentage': [
      'Complex hybrid (RIESLING \u00d7 SILVANER) \u00d7 CHANCELLOR bred in 1949 by Heinrich Birk at Geisenheim and released in 1962. The complete pedigree of Chancellor can be found under PRIOR. It should be noted that the female parent (Riesling \u00d7 Silvaner) is not identical to M\u00dcLLER-THURGAU (formerly and erroneously called Riesling \u00d7 Silvaner).',
    ],
    'where its grown': [
      'Named after Heinrich Breidecker, one of New Zealand\u2019s pioneer grape growers, this variety is planted to a very limited extent (7 ha/17 acres) on the South Island of New Zealand, as far apart as Marlborough in the north (Hunter\u2019s, for example, produce a varietal with strong pear and apple aromas) and Central Otago in the south. It was imported from Geisenheim in 1979, presumably on the recommendation of Helmut Becker, then advising the fledgling New Zealand wine industry.',
    ],
  },
  {
    name: 'BRESLAVA',
    synonyms: [
      'CHRT\u010c \u00d7 St Dc ALC 10/28',
      'Chrtc \u00d7 St M D#Alc 10/28',
    ],
    description: 'One of the earliest Slovakian crosses but little dispersed.',
    'origin and parentage': [
      'Breslava, originally known snappily as Chrtc \u00d7 St M D#Alc 10/28, is a (Chasselas Rose \u00d7 GEW\u00dcRZTRAMINER) \u00d7 Santa Maria d\u2019Alcantara cross (with Slovakian names: (Chrupka \u010cerven\u00e1 \u00d7 Tram\u00edn \u010cerven\u00fd) \u00d7 Santa Maria d\u2019Alcantara) obtained in 1960 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia, in which Chasselas Rose (or Chrupka \u010cerven\u00e1) is a colour mutation of CHASSELAS, and Santa Maria d\u2019Alcantara is a white-berried variety of unknown origin. The variety was later christened Breslava, the historical name of Bratislava, capital city of Slovakia, a name that was authorized in 2011.',
    ],
    'where its grown': [
      'Wines are aromatic, with fresh flavours of grapefruit, and better quality than, say, wines produced from M\u00dcLLER-THURGAU. Breslava is not yet widely planted (10 ha/25 acres in 2011) but can be found in Malokarpatsk\u00e1 in south-western Slovakia. Producers include Karol Brani\u0161 and Vino Maty\u0161\u00e1k.',
    ],
  },
  {
    name: 'BRIANNA',
    synonyms: ['ES 7-4-76'],
    description:
      'Minor but usefully winter-hardy and increasingly popular American hybrid.',
    'origin and parentage': [
      'A KAY GRAY \u00d7 Elmer Swenson 2-12-13 hybrid obtained in 1983 by Elmer Swenson (1913\u20132004), a grape breeder from Osceola, Wisconsin, US. The reconstruction of the complete pedigree of Brianna (see diagram), shows it to be an extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris, Vitis cinerea and Vitis berlandieri hybrid. Brianna was selected in 1989 as a table grape and in 2001 as a wine grape. It was named in 2002 by Ed Swanson of Cuthills Vineyards in Pierce, Nebraska.',
    ],
    'where its grown': [
      'Brianna is planted to a limited extent in the upper Midwest, particularly in Iowa and Nebraska but also in Minnesota and South Dakota, with recent plantings in New Hampshire and in New York State\u2019s Hudson Valley; it is one of the most popular and valued of the recently released American cold-hardy hybrids. Producers of varietal wines include Two Saints in Iowa, Cuthills and Mac\u2019s Creek in Nebraska, and Parley Lake and Indian Island in Minnesota. Brianna wines typically have tropical fruit flavours but the grapes need to be picked early enough to avoid increased foxy flavours from the variety\u2019s labrusca parentage (Smiley 2008).',
    ],
  },
  {
    name: 'BRONNER',
    synonyms: ['Freiburg 250-75'],
    description: 'German hybrid bred for its disease resistance.',
    'origin and parentage': [
      'Complex hybrid obtained in 1975 by Norbert Becker at the Freiburg research centre, in southern Germany, by crossing MERZLING \u00d7 Geisenheim 6494 (see PRIOR for the complete pedigree of Geisenheim 6494). It was named in honour of Johann Philipp Bronner (1792\u20131864), a pharmacist as well as a creative winemaker and a wine boffin, who is credited with having introduced SANKT LAURENT and BLAUER PORTUGIESER to Germany from Austria.',
      'The variety Bronner should not be confused with the PINOT BLANC seedling also named Bronner, in this case after its breeder in Wissloch, just south of Heidelberg, Germany, mainly used as a table grape.',
    ],
    'where its grown': [
      'This disease-resistant variety is grown to a limited extent in Italy, Belgium, Germany and Switzerland. The wines are relatively neutral on the nose, with an occasional note of ripe apples. Well ripened, it is a little like PINOT BLANC. If the yields are too high, it can taste bitter on the finish. Lieselehof in Trentino-Alto Adige in northern Italy make a varietal wine. German producers include Zang in Franken and Goldene Gans and Stadt Lahr in Baden.',
    ],
  },
  {
    name: 'BRUN ARGENT\u00c9',
    synonyms: [
      'Camar\u00e8se',
      'Camar\u00e8ze',
      'Vaccar\u00e8se',
      'Vacar\u00e8se',
      'Vaccar\u00e8ze',
    ],
    description: 'Very minor southern Rh\u00f4ne variety.',
    'origin and parentage': [
      'The earliest mention of this variety was found under its synonym Vaccar\u00e8se in 1538 in Saint-Saturnin-l\u00e8s-Avignon: \u2018vaqueyresse, unharum, borbollenque\u2019 (Vaccar\u00e8se, ???, BOURBOULENC). Additional references to Vaccar\u00e8se near Avignon strongly support this region as the birthplace of Brun Argent\u00e9. The synonym Camar\u00e8se was first mentioned in 1806, whereas the name Brun Argent\u00e9 appeared only in 1909 in Viala and Vermorel (1901\u201310), without any clear ampelographic description. However, the name Vaccar\u00e8se is specific to the area of Ch\u00e2teauneuf-du-Pape and Camar\u00e8se to the area of Chusclan in the Gard, while Brun Argent\u00e9 is used in a larger area.',
      'The name Brun Argent\u00e9 clearly refers to the dark berries (brun means \u2018brown\u2019) and the silvery look of the down on the leaves (argent means \u2018silver\u2019). The name Vaccar\u00e8se could derive from Vaccar\u00e8s, a region in the Camargue, and the name Camar\u00e8se could come from Camar\u00e8s, a locality in the Aveyron (Midi-Pyr\u00e9n\u00e9es), whence it might have been transported to the Gard.',
      'Brun Argent\u00e9 is a member of the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'There were just 12 ha (30 acres) planted in France in 2008, cultivated mainly under the name Camar\u00e8se in Chusclan in the Gard (one of the named C\u00f4tes du Rh\u00f4ne villages), where it is generally blended with Grenache (GARNACHA) to produce ros\u00e9 wines (up to a maximum of 20% but usually less). As Vaccar\u00e8se, it is authorized in the Ch\u00e2teauneuf-du-Pape appellation but only a few producers such as Ch\u00e2teau de Beaucastel and Domaine du Pegau actually cultivate (and then blend) it. Although the appellation regulations and the French official register of varieties use the name Brun Argent\u00e9, neither it nor Vaccar\u00e8se is seen to any extent on labels.',
    ],
  },
  {
    name: 'BRUN FOURCA',
    synonyms: [
      'Brun d\u2019Auriol',
      'Brun Fourcat',
      'Farnous',
      'Flouron',
      'Man\u00e7onnet',
      'Moulan',
      'Moureau',
      'Mouzeau',
      'Mourrastel-Flourat',
    ],
    description:
      'Ancient but now virtually extinct western Proven\u00e7al vine.',
    'origin and parentage': [
      'Brun Fourca used to be cultivated mainly in Provence, southern France, its most likely place of origin, as well as in the Languedoc. The first mention of a grape called Brun Fourca appeared in 1772 but it was used as a synonym for PINOT. The earliest mention of the true Brun Fourca was found in 1783\u20134 in Aix-en-Provence and in Aubagne (Bouches-du-Rh\u00f4ne). Its name comes from the characteristic shape of its bunches: divided in two parts like a fork (French fourche).',
    ],
    'where its grown': [
      'Brun Fourca may be included as a supplementary variety, alongside the principal players Cinsault (CINSAUT), Grenache (GARNACHA) and Mourv\u00e8dre (MONASTRELL), in the Palette appellation around Aix-en-Provence, France, but there was less than 1 ha (2.5 acres) remaining in 2006. Nevertheless, Ch\u00e2teau Cr\u00e9made claim to include it in their red Palette and Ch\u00e2teau Simone still have a few old vines.',
      'According to the French official register of varieties, Brun Fourca produces wines that sound barely memorable \u2013 \u2018moderately coloured with a certain acidity\u2019.',
    ],
  },
  {
    name: 'BRUSTIANO BIANCO',
    synonyms: ['Calitrano', 'Colitrano', 'Licronaxu Bianco'],
    description:
      'Scarce white variety found on the Italian island of Sardegna and reintroduced on the French island of Corse.',
    'origin and parentage': [
      'Comparison of DNA profiles from independent sources provides evidence that Brustiano Bianco from the French island of Corse is identical to Licronaxu Bianco and Licronaxu Nero on the Italian island of Sardegna (Vouillamoz), the latter being a simple colour mutation (De Mattia et al. 2007).',
    ],
    'where its grown': [
      'It is cultivated on Sardegna and on Corse, where it has recently been tested for reintroduction and can already be found in wines such as Domaine Abbatucci\u2019s Cuv\u00e9e Collection Il Cavaliere Diplomate d\u2019Empire, a blend of BIANCU GENTILE, Brustiano Bianco, SCIMISCI\u00c0 (using the synonyms Genovese and Rossala Bianca) and VERMENTINO.',
    ],
  },
  {
    name: 'BUDAI Z\u00d6LD',
    synonyms: [
      'Budai',
      'Z\u00f6ld Budai',
      'Z\u00f6ld Sz\u0151l\u0151',
      'Z\u00f6ldfeh\u00e9r',
      'Z\u00f6ldsz\u0151l\u0151',
    ],
    description: 'Very minor Hungarian variety making fresh, simple whites.',
    'origin and parentage': [
      'Budai Z\u00f6ld, literally the \u2018green of Buda\u2019, probably originates in the region around Buda, the old capital city of Hungary on the west bank of the Duna (Danube) that now constitutes part of Budapest, where Budai Z\u00f6ld used to be one of the most important varieties.',
    ],
    'where its grown': [
      'Wines are deeply coloured, full-bodied but relatively simple with fresh green-apple acidity and a vegetal character. There were just 6 ha (15 acres) in Hungary in 2008, partly on the southern slopes of Mount Badacsony, on the northern side of Lake Balaton in western Hungary, and to a lesser extent further north in Soml\u00f3, although it was quite widely cultivated in the Buda region in northern central Hungary before phylloxera struck in the 1870s. The variety is usually planted adjacent to K\u00c9KNYEL\u0170 to improve the pollination, and therefore yields, of the latter, although Szeremley make a respectable everyday-drinking varietal wine in Badacsony.',
    ],
  },
  {
    name: 'BUDESHURI TSITELI',
    synonyms: ['Budeshuri Saperavi', 'Budeshuri Shavi', 'Tsiteli Budeshuri'],
    description: 'Very minor Georgian variety usually blended with Saperavi.',
    'origin and parentage': [
      'Budeshuri Tsiteli probably comes from eastern Georgia. The word budeshuri refers in Georgian to the oval-shaped berries. Budeshuri Tsiteli should not be confused with the white-berried Budeshuri Tetri (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Kindzmarauli Marani in Kakheti, south-eastern Georgia, blend Budeshuri Tsiteli with SAPERAVI but there were just 2 ha (5 acres) in Georgia in 2004.',
    ],
  },
  {
    name: 'BUKET',
    synonyms: ['Bouquet'],
    description:
      'Relatively unknown Bulgarian cross with potential for well-structured, full-flavoured reds.',
    'origin and parentage': [
      'A PINOT NOIR \u00d7 MAVRUD cross obtained in 1951 at the Pleven viticulture and oenology institute in northern Bulgaria.',
      'Buket was used to breed STORGOZIA.',
      'The German variety BUKETTRAUBE is also sometimes called Buket but it has white berries and a distinct DNA profile (Vouillamoz).',
    ],
    'where its grown': [
      'Grown mainly in the northern half of Bulgaria, particularly in the province of Pleven, Buket was for long planted and harvested with Gamza (KADARKA) and not separated in the winery. However, it makes wines with good acid, tannin and alcohol levels and seems to have the potential to make serious reds that take well to oak maturation. Borovitza produce a dense, well-structured, cherry-fruited and spicy varietal example. There were 259 ha (640 acres) in Bulgaria in 2008.',
    ],
  },
  {
    name: 'BUKETTRAUBE',
    synonyms: [
      'Bocksbeutel',
      'Bouquettraube',
      'Buket',
      'Bukettrebe',
      'W\u00fcrzburger',
    ],
    description:
      'Aromatic cross bred in Germany but most widely planted in South Africa for sweet wines.',
    'origin and parentage': [
      'This originated from a seedling selected by Sebastian Englert (1804\u201380) of Randersacker (close to W\u00fcrzburg/Franken) in Germany, who founded the first viticultural school in Franken. DNA parentage analysis at INRA (Institut National de la Recherche Agronomique) in Montpellier has shown that Bukettraube is a cross between SILVANER and SCHIAVA GROSSA (aka Trollinger). A variety known as BUKET is planted in Bulgaria but it has black berries and a distinct DNA profile (Vouillamoz).',
    ],
    'where its grown': [
      'Although this variety was bred in Germany, it is no longer grown there because of its susceptibility to powdery mildew. There may still be a few hectares in Alsace, France, where it is known as Bouquettraube.',
      'South Africa has more of this variety than any other country (88 ha/217 acres), the majority in Malmesbury, and only in South Africa is it bottled as a varietal wine. These are generally sweet or semi-sweet, occasionally botrytized, and have a distinctively floral, grapey, Muscat-like aroma. Cederberg produce a well-made, exotically fruited version with around 25 g/l residual sugar.',
    ],
  },
  {
    name: 'BUSSANELLO',
    synonyms: ['Incrocio Dalmasso 12/37'],
    description:
      'Aromatic white Italian cross recommended in parts of Piemonte.',
    'origin and parentage': [
      'A GRA\u0160EVINA (Riesling Italico) \u00d7 FURMINT cross obtained by the Italian vine breeder Giovanni Dalmasso at the Conegliano research centre in the 1930s which did not enter the national variety catalogue until 1977.',
    ],
    'where its grown': [
      'Bussanello was recommended in 2000 for the Piemontese provinces of Alessandria, Asti and Cuneo in Italy, partly thanks to the promising research results at the National Council for Research in Torino in the 1990s. It is also found in the Langhe Bianco DOC of Piemonte and in Friuli-Venezia Giulia. It has inherited intense fruity and flowery aromas from its parents, with hints of jasmine, pear and red apples.',
    ],
  },
  {
    name: 'BUSUIOAC\u0102 DE BOHOTIN',
    synonyms: [
      'Busuioac\u0103 Neagr\u0103',
      'Busuioac\u0103 V\u00e2nat\u0103 de Bohotin',
      'T\u0103m\u00e2ioas\u0103 de Bohotin',
      'T\u0103m\u00e2ioas\u0103 Violet\u0103',
    ],
    description:
      'Aromatic pink-skinned Romanian variety making sweet, well-balanced Muscat-like wines.',
    'origin and parentage': [
      'In 1926\u20138, a pink-berried Muscat was introduced by Professor Hoga\u015f in Bohotin in Ia\u015fi, eastern Romania, and the variety was then propagated under the name Busuioac\u0103 de Bohotin (Dejeu 2004). While Busuioac\u0103 de Bohotin is often considered to be a synonym for Muscat Rouge de Mad\u00e8re, aka Moscato Violetto (Dejeu 2004), its DNA profile in Harta et al. (2010) does not correspond to that of Muscat Rouge de Mad\u00e8re in Di Vecchi Staraz, This et al. (2007) although it does correspond to MUSCAT BLANC \u00c0 PETITS GRAINS, making this reference sample of Busuioac\u0103 de Bohotin a colour mutation of the latter (Vouillamoz). This is consistent with the fact that its morphological description closely resembles that of T\u0103m\u00e2ioas\u0103 Rom\u00e2neasc\u0103 (Dejeu 2004), the Romanian name for Muscat Blanc \u00e0 Petits Grains. However, the DNA profile of the Busuioac\u0103 de Bohotin in Motoc (2009) is unique, so that the true-to-type reference variety still needs to be identified. The name Busuioac\u0103 possibly derives from busuioc, meaning \u2018basil\u2019, referring to the aroma of the wine.',
    ],
    'where its grown': [
      'Although this Muscat-flavoured variety is less well suited to late harvesting than T\u0103m\u00e2ioas\u0103 Rom\u00e2neasc\u0103 (the red-berried mutation of T\u0103m\u00e2ioas\u0103 Alba \u2013 see MUSCAT BLANC \u00c0 PETITS GRAINS), its pink wines are made in semi-sweet and sweet styles, with flavours of honeysuckle, roses and peaches, the sweetness well balanced by the acidity. The variety is grown mainly in Hu\u015fi in Romanian Moldova in the east and further south and west in Dealu Mare, with a total of 90 ha (222 acres) in Romania in 2008. Recommended producers include Basilescu and Senator.',
    ],
  },
  {
    name: 'CABERINTA',
    synonyms: ['CG 14892', 'Gargiulo 14892'],
    description: 'Productive but not very popular Argentine hybrid.',
    'origin and parentage': [
      'Caberinta is a Ruby \u00d7 RUBY CABERNET hybrid obtained by Angel Gargiulo at INTA (the National Institute of Agricultural Technology), in Buenos Aires, Argentina, in which Ruby is a Keuka \u00d7 Ontario hybrid (see CAYUGA WHITE for the pedigree of Ontario) and Keuka is a Chasselas Rose \u00d7 Mills hybrid (see GR 7 for the pedigree of Mills); Chasselas Rose is a colour mutation of CHASSELAS. The name is a contraction of Cabernet plus INTA.',
    ],
    'where its grown': [
      'This home-grown hybrid has not really caught on despite its high yields and ability to retain fresh acidity. In 2009 there were 69 ha (171 acres) in Argentina, mostly in Mendoza, down from 90 ha (222 acres) in 2008. It has also been found in Morocco.',
    ],
  },
  {
    name: 'CABERNET BLANC',
    synonyms: ['VB 91-26-1'],
    description:
      'Recent Swiss hybrid that combines fresh, clean flavours and good disease resistance.',
    'origin and parentage': [
      'Cabernet Blanc is a hybrid between CABERNET SAUVIGNON and an undisclosed disease-resistant variety (known in German as a Resistenzpartner) obtained in 1991 by the private breeder Valentin Blattner in Soyhi\u00e8res, in the Swiss Jura, possibly a Cabernet Sauvignon \u00d7 (SILVANER \u00d7 (RIESLING \u00d7 Vitis vinifera) \u00d7 (JS 12417 \u00d7 CHANCELLOR)) complex hybrid, just like PINOTIN and CABERTIN.',
    ],
    'where its grown': [
      'Wines are said to have aromas similar to those of SAUVIGNON BLANC and a flavour profile that is somewhere between those of RIESLING and Sauvignon Blanc. As well as providing good resistance to disease, the berries\u2019 thick skins mean the grapes can be picked quite late, allowing producers such as Geselmann in the Pfalz in Germany to produce sweet as well as dry varietal wines. The variety seems to have caught on mainly in the Pfalz, where there were close to 7 ha (17 acres) in 2007, and a request for inclusion in the official list of varieties was made in 2004. However, it is also found in the Netherlands, where Colonjes in Gelderland and Gelders Laren in Achterhoek make very crisp varietal wines.',
      '(The name Cabernet Blanc is sometimes seen on labels of blush (ros\u00e9) or white wines made from CABERNET SAUVIGNON, particularly in the US.)',
    ],
  },
  {
    name: 'CABERNET CARBON',
    synonyms: ['Freiburg 377-83'],
    description:
      'Recent tannic German, very complex, hybrid bred for superior disease resistance.',
    'origin and parentage': [
      'Complex hybrid obtained in 1983 by Norbert Becker at the Freiburg research centre, southern Germany, by crossing CABERNET SAUVIGNON with Freiburg 250-75, where Freiburg 250-75 is a MERZLING \u00d7 (Zarya Severa \u00d7 SANKT LAURENT) hybrid, Zarya Severa is a Seyanets Malengra \u00d7 Vitis amurensis hybrid, and Seyanets Malengra is a seedling of PR\u00c9COCE DE MALINGRE.',
    ],
    'where its grown': [
      'Like several other Cabernet Something varieties, this was bred for its enhanced disease resistance. In Germany, it entered the official register of varieties in 2003 but the plantings are too few to make it into the official German vineyard statistics. Wines are deeply coloured, have plenty of tannins and taste spicy with intense Cabernet-like cassis notes. Producers of varietal wines include Helde and Isele in Baden.',
      'After several years of trials in the experimental vineyard of Agroscope Changins-W\u00e4denswil at Pully (south east of Lausanne, on the shore of Lac L\u00e9man/Lake Geneva), it is now cultivated on less than half a hectare (about an acre) in Switzerland.',
    ],
  },
  {
    name: 'CABERNET CAROL',
    synonyms: ['Freiburg 428-82 R'],
    description: 'Barely planted disease-resistant German hybrid.',
    'origin and parentage': [
      'Complex hybrid obtained in 1982 by Norbert Becker at the Freiburg research centre, southern Germany, by crossing CABERNET SAUVIGNON with SOLARIS, which makes it a sibling of CABERNET CORTIS. (See Solaris for full pedigree.)',
    ],
    'where its grown': [
      'There are minuscule plantings in Switzerland (less than 1 ha/2.5 acres), including one tenth of a hectare (0.25 acres) planted in 2003 by a grower in the Valais, but it barely shows up on the radar anywhere else, except in Baden in southern Germany, where Kaufmann, Staatsweingut Freiburg & Blankenhornsberg and Rabenhof include it in blends.',
    ],
  },
  {
    name: 'CABERNET COLONJES',
    synonyms: ['VB 91-26-5', 'VB 91-26-05'],
    description:
      'Minor dark-skinned Swiss hybrid found mainly in the Netherlands.',
    'origin and parentage': [
      'A hybrid obtained in 1991 by Valentin Blattner in Soyhi\u00e8res, in the Swiss Jura, by crossing CABERNET SAUVIGNON with an undisclosed disease-resistant variety (known in German as a Resistenzpartner). It was initially named VB 91-26-5 by its breeder and renamed Cabernet Colonjes by Wijnhoeve Colonjes, the first producer in the Netherlands to plant this variety.',
    ],
    'where its grown': [
      'Cabernet Colonjes is grown almost exclusively in the Netherlands, eg by Colonjes in Groesbeek, who use it to add fruit to their red blend with CABERNET CORTIS, PINOTIN and REGENT, blended with Regent in their ros\u00e9, and as a blanc de noirs. Cabernet Colonjes\u2019 greatest attribute is its fruitiness and the wines have been likened to MERLOT. Also found in Sweden.',
    ],
  },
  {
    name: 'CABERNET CORTIS',
    synonyms: ['Freiburg 437-82 R'],
    description:
      'Recent tannic German hybrid bred for superior disease resistance.',
    'origin and parentage': [
      'Complex hybrid obtained in 1982 by Norbert Becker at the Freiburg research centre in Baden-W\u00fcrttemberg, southern Germany, by crossing CABERNET SAUVIGNON with SOLARIS, which makes it a sibling of CABERNET CAROL. (See Solaris for a pedigree.)',
    ],
    'where its grown': [
      'Like several other Cabernet Something varieties, this was bred for its enhanced disease resistance. Wines are deeply coloured and tannic, with herbal notes. It was authorized in Italy in 2011. Denmark had around 3 ha (7 acres) in 2010.',
      'It entered Germany\u2019s official register of varieties in 2003 but the plantings are too few to make it into the official German vineyard statistics. Wines taste generally Cabernet-like. Becker (in Malsch) and Rabenhof, both in Baden, make varietal examples.',
      'After several years of trials in the experimental vineyard of Agroscope Changins-W\u00e4denswil at Pully (south east of Lausanne, on the shore of Lac L\u00e9man/Lake Geneva), it is now cultivated on 2 ha (5 acres) in Switzerland.',
    ],
  },
  {
    name: 'CABERNET CUBIN',
    synonyms: ['Weinsberg 70-281-35'],
    description:
      'Deeply coloured, tannin-rich German cross that needs the best sites to ripen fully.',
    'origin and parentage': [
      'A BLAUFR\u00c4NKISCH \u00d7 CABERNET SAUVIGNON (Levadoux clone) cross created at the Weinsberg research centre in Baden-W\u00fcrttemberg, southern Germany, in 1970. It is therefore a sibling of CABERNET MITOS. Included on the official register in 2005.',
    ],
    'where its grown': [
      'At the end of 2006 there were nearly 70 ha (173 acres) planted in Germany, some still experimental. Wines are deeply coloured with firm tannins that need time to soften, reflecting their Cabernet inheritance, and well disposed to barrel ageing. Gemmrich in W\u00fcrttemberg produced a good varietal example.',
      'It is also found to a very limited extent in Switzerland (3 ha/7 acres). Schmidheiny\u2019s Zeus, for example, is a varietal Cabernet Cubin aged eighteen months in barrique.',
    ],
  },
  {
    name: 'CABERNET DORIO',
    synonyms: ['Weinsberg 71-817-89'],
    description: 'Recent German cross with a certain Cabernet likeness.',
    'origin and parentage': [
      'A DORNFELDER \u00d7 CABERNET SAUVIGNON cross created at the Weinsberg research centre in Baden-W\u00fcrttemberg, southern Germany, in 1971. It is therefore a sibling of CABERNET DORSA.',
    ],
    'where its grown': [
      'There were approximately 40 ha (99 acres) planted in Germany at the end of 2006, some still experimental \u2013 good progress given that it was not included in the official register of varieties until 2004. Recommended producers of varietal wines include \u00d6konomierat Geil Erben and Weingut der Stadt Mainz, both in Rheinhessen. When the grapes are fully ripe, the wine reflects its Cabernet parentage.',
      'It is also found to a very limited extent in Switzerland (less than 1 ha/2.5 acres).',
    ],
  },
  {
    name: 'CABERNET DORSA',
    synonyms: ['Weinsberg 71-817-92'],
    description: 'The most successful of the new German Cabernets.',
    'origin and parentage': [
      'A DORNFELDER \u00d7 CABERNET SAUVIGNON cross created at the Weinsberg research centre in Baden-W\u00fcrttemberg, southern Germany, in 1971. It is therefore a sibling of CABERNET DORIO. Included on the official register in 2004.',
    ],
    'where its grown': [
      'At the end of 2006 there were more than 230 ha (568 acres) planted in Germany, some still on an experimental basis. Given that it was not included on the official register of varieties until 2004, it is clearly making good progress. The wines are full-bodied with potentially firm tannins and show the fruit flavours and structural characteristics of both parents \u2013 with notable cassis aromas. Producers of varietal wines include Fuchs and Gruber in Rheinhessen, Amlinger in the Mosel, Marx in the Nahe, Anselmann and Fitz-Ritter in the Pfalz, Karl H Johner in Baden, and Angelika Knauer and Heuchelberg Weing\u00e4rtner in W\u00fcrttemberg. This variety is expected to gain ground in Germany.',
      'It is also planted to a limited extent in Switzerland (20 ha/49 acres) and in Sweden.',
    ],
  },
  {
    name: 'CABERNET FRANC',
    synonyms: [
      'Ach\u00e9ria',
      'Ardounet',
      'Bidure',
      'Bordeaux',
      'Bordo',
      'Boubet',
      'Bouchet Franc',
      'Gros Bouchet',
      'Bouchy',
      'Breton',
      'Cabernet Gris',
      'Cabrunet',
      'Capbreton Rouge',
      'Carmenet',
      'Couahort',
      'Plant Breton',
      'Plant de l\u2019Abb\u00e9 Breton',
      'Sable Rouge',
      'Trouchet',
      'Tsapournako',
      'Verdejilla Tinto',
      'V\u00e9ron',
      'Vidure',
      'Vuidure',
      'Grosse Vidure',
    ],
    description:
      'Fragrant, well-structured parent of Cabernet Sauvignon that shines in the Loire and in Bordeaux blends.',
    'origin and parentage': [
      'Cabernet Franc is undoubtedly one of the most important and ancient varieties in the Bordeaux region. However, recent genetic and historical studies point to the Spanish Pa\u00eds Vasco (Basque Country) for its place of origin:',
      '\u2013\u00a0\u00a0\u00a0DNA parentage analysis has shown that Cabernet Franc has parent\u2013offspring relationships with two very old cultivars from the Basque Country, Morenoa and HONDARRIBI BELTZA (often confused with Cabernet Franc in the Txakoli vineyards), thus supporting the hypothesis that Cabernet Franc is a native of the Spanish Pa\u00eds Vasco (Basque Country) (Boursiquot et al. 2009).',
      '\u2013\u00a0\u00a0\u00a0Founded in the twelfth century, the collegiate church of Roncesvalles (Roncevaux in French and Orreaga in Basque) in the Pa\u00eds Vasco, situated close to the French border, was an important stop-over for pilgrims on their way to Santiago de Compostela. Since their early days, Roncesvalles\u2019 priests established vineyards, from the French town of Iroul\u00e9guy to the Basque city of Hondarribia (Fontarrabie in French), using local varieties such as Ach\u00e9ria (meaning \u2018fox\u2019), which is the Basque name for Cabernet Franc and also morphologically the most archaic or primitive of the clones of Cabernet Franc (Levadoux 1956; Lavignac 2001; Bordenave et al. 2007).',
      'Yet there is no historical document mentioning Ach\u00e9ria before 1905 (R\u00e9zeau 1997), and the earliest mention of Cabernet Franc was supposedly recorded in the Val de Loire under the old local synonym Breton, referred to by the illustrious French writer Fran\u00e7ois Rabelais in his Gargantua, published in 1534 (R\u00e9zeau 1997): \u2018ce bon vin breton poinct ne croist en Bretaigne, mais en ce bon pays de Verron\u2019 (\u2018this good breton wine does not grow in Brittany, but in this good country of Verron\u2019 (today\u2019s Beaumont-en-V\u00e9ron near Chinon, at the confluence of the Loire and Vienne rivers)).',
      'Although it is far from proven that Rabelais\u2019 vin breton and Cabernet Franc are one and the same, the presence of a Bordeaux variety in this area is quite possible: around 1050, the Comte d\u2019Anjou Geoffroi Martel made a request to the Abbey of Ronceray in Angers for a piece of land near the confluence of the Loire and the Maine to plant a vineyard with grapevines from Bordeaux (vineam burdegalensem; Dion 1959).',
      'The name Breton would suggest that this Bordeaux variety came to the Val de Loire via Brittany, where vineyards existed in the Middle Ages, when the climate was milder. This is supported by the fact that Magdeleine Noire des Charentes, which gave birth to MERLOT through a natural cross with Cabernet Franc, was also found as an ancient, remnant vine near Suliac in Brittany (Boursiquot et al. 2009). However, Comte Alexandre-Pierre Odart (1845) provides another explanation for the use of the name Breton in the Val de Loire: in 1631, Cardinal de Richelieu sent out thousands of vines of the best Bordeaux variety to his steward, Abb\u00e9 Breton, who planted them in Chinon and Bourgueil. The vine was later named Plant de l\u2019Abb\u00e9 Breton, and then simply Breton. For Lavignac (2001), the name Breton was possibly given to this variety because Breton sailors used to transport wine from the Gironde, from port to port northwards along the French coast, arriving in Brittany via the Loire estuary. Alternatively, it could be a simplified version of Capbreton, a commune in the Landes.',
      'The old synonym Vidure was first mentioned in 1675 by Adrien de Valois in his Notitia Galliarum, in which he was the first to consider Vidure as a derivation of Biturica (see Other hypotheses below). For other authors, Vidure might derive from the Gascon dialect bit duro, meaning \u2018hard vine\u2019 (French vigne dure), most likely because this variety was hard to prune (R\u00e9zeau 1997). Under the old synonym Cabrunet, the variety was mentioned in 1716 in Pomerol: \u2018il seroit necessaire d\u2019arracher cette partie de vigne pour replanter le terrain en bouchet, noir de pressac, et cabrunet\u2019 (\u2018it would be necessary to grub up this part of the vineyard to replant the land with bouchet, noir de pressac, and cabrunet\u2019; R\u00e9zeau 1997).',
      'Finally, the first mention of the modern spelling Cabernet did not appear until Rozier (1823).',
      'The etymology of Cabernet has been the subject of much speculation. The most convincing hypothesis is based on the Latin carbon, meaning \u2018black\u2019, in reference to the dark colour of the berries; carbonet became carbenet and metathesis (an inversion of consonants) gave cabernet (R\u00e9zeau 1997). However, the name Cabernet and its old synonyms (Bidure, Breton, Cabrunet, Carmenet, V\u00e9ron or Vidure) were often given to several distinct varieties, as evidenced by the following entries in a list of grape varieties established in 1859 by Henry-Xavier Bouchereau (1863), then proprietor of Ch\u00e2teau Carbonnieux, where he had built up a collection of 1,242 grape varieties from France and beyond:',
      '\u2013\u00a0\u00a0\u00a0Carmenet-Sauvignon or Cabernet-Sauvignon, the modern CABERNET SAUVIGNON',
      '\u2013\u00a0\u00a0\u00a0Carmen\u00e8re, the modern CARMEN\u00c8RE',
      '\u2013\u00a0\u00a0\u00a0Gros Cabernet, possibly corresponding to Cabernet Franc but it is also the name of a distinct but no longer cultivated variety (see the CABERNET SAUVIGNON pedigree diagram)',
      '\u2013\u00a0\u00a0\u00a0Petit Cabernet, corresponding to CABERNET SAUVIGNON.',
      'As a consequence, Cabernet Franc, CABERNET SAUVIGNON and CARMEN\u00c8RE were frequently confused until the end of the nineteenth century. However, the oldest references most likely referred to Cabernet Franc since it has been established by DNA parentage analysis that Cabernet Franc is a parent of Cabernet Sauvignon (Bowers and Meredith 1997), Carmen\u00e8re and MERLOT (Boursiquot et al. 2009). It is also likely that the parents of a variety as old as Cabernet Franc have long disappeared, making it an orphan. The frequent confusion among these varieties and the parentages discovered are consistent with the Carmenet ampelographic group (see p XXVII).',
      'Cabernet Franc was used to breed CABERNET MORAVIA in the Czech Republic and INCROCIO TERZI 1 in Italy.',
    ],
    'where its grown': [
      'Wine made from Cabernet Franc is generally paler, lighter, crisper, softer and more obviously aromatic than that of its progeny CABERNET SAUVIGNON. Its aroma spans a fine line between refreshingly leafy and aggressively herbaceous, depending on ripeness level. Getting the yield right can be crucial; lack of ripeness can result in high levels of \u2018green\u2019-smelling methoxypyrazines.',
      'With 36,948 ha (91,300 acres) in 2009, it is France\u2019s sixth most planted red wine variety, a long way behind her fifth, Carignan (MAZUELO), with 53,155 ha (131,349 acres). Cabernet Franc\u2019s French heartland is the Val de Loire, where, thanks to improved viticultural techniques, not least lower yields, it is generally producing much riper wines. Although elsewhere Cabernet Franc is chiefly used as a blending grape, it is in the Val de Loire that it shines as the dominant and often only variety. There are 5,953 ha (14,710 acres) in the mid Val de Loire, mostly in the Indre-et-Loire (5,356 ha/13,235 acres) d\u00e9partement in such appellations as Chinon and Bourgueil and to a lesser extent in the Loir-et-Cher (540 ha/1,334 acres). In the Pays de Loire region even more is planted, 9,176 ha (22,674 acres), mainly in Maine-et-Loire (8,671 ha/21,427 acres) for Saumur-Champigny and Anjou-Villages, with 409 ha (1,011 acres) in Loire-Atlantique. Good-quality Cabernet Franc grown here results in fragrant, medium-bodied, well-balanced, silky, fairly early-maturing wines that can smell of pencil shavings, sometimes raspberries. Dependable examples include Chinons from Philippe Alliet, Bernard Baudry, Baudry-Dutour, Ch\u00e2teau de Coulaine, Charles Joguet (whose Les Varennes du Grand Clos, Cuv\u00e9e Cabernet Franc de Pied is made from old, ungrafted vines) and Olga Raffault; Bourgueils and Saint-Nicolas de Bourgueils from Yannick Amirault, Dom de la Butte, Catherine & Pierre Breton, Dom de la Cotellaraie and Pierre-Jacques Druet; and Saumurs and Saumur-Champignys from Ch\u00e2teau Fouquet, Ch\u00e2teau de Hureau and Clos Rougeard.',
      'Bordeaux is the world\u2019s other Cabernet Franc stronghold and here it has long been planted as an insurance policy against poor viticultural performance by CABERNET SAUVIGNON and MERLOT because it tends to bud and flower at different times from them. Cabernet Franc has been giving way to its routine blending partners, however: first Merlot and then Cabernet Sauvignon now that climate change seems to be making it easier to ripen there. In 2009 the total plantings had dropped to 12,396 ha (30,631 acres; less than half the area planted with Cabernet Sauvignon and less than a fifth Merlot\u2019s total area). It is much more popular in the cooler vineyards on the right bank of the Gironde than in the M\u00e9doc because it is so much easier to ripen there than Cabernet Sauvignon. Often called Bouchet, with Merlot it plays an important part in virtually all Saint-\u00c9milions. It constitutes the majority of the blend in some vintages of top Saint-\u00c9milion Ch\u00e2teau Cheval Blanc, which probably constitute the greatest, and certainly longest-living, examples.',
      'Like its systematic Bordeaux blending partners, Cabernet Franc is also widely planted in south-west France and is particularly popular in the relatively cool, inland vineyards of Bergerac. Throughout the south west it is used to soften more tannic varieties such as TANNAT, CABERNET SAUVIGNON and FER. It is allowed in a wide range of appellations such as (with Tannat) Iroul\u00e9guy, Madiran and Tursan and (with N\u00c9GRETTE) in Fronton. There were 385 ha (951 acres) in the Gers d\u00e9partement (where Madiran is produced) and 403 ha (996 acres) in Tarn-et-Garonne and 153 ha (378 acres) in the Lot (Cahors country). To the north of Bordeaux, 1,054 ha (2,604 acres) were planted in Poitou-Charentes.',
      'CABERNET SAUVIGNON has rarely dazzled in the Languedoc but there has been a stirring of interest in aromatic varietal Cabernet Franc, with all of 2,265 ha (5,597 acres) planted in the Aude d\u00e9partement.',
      'Italy\u2019s 2000 survey found 7,085 ha (17,507 acres) of Cabernet Franc, although it is mainly concentrated in the far north east, where there has been considerable confusion between this variety and CARMEN\u00c8RE, so the total found in the 2010 vineyard survey may well be very different \u2013 not least because the market has seemed much more enthusiastic about MERLOT. As in the Loire, vines were for long allowed to yield too heavily, producing decidedly \u2018green\u2019 wines, but this is slowly improving. An exceptional champion of true and subtle Cabernet Franc in central Italy is Tenuta di Trinoro just south of Montepulciano.',
      'In Spain in 2008 there were 736 ha (1,819 acres), mostly in Castilla-La Mancha and Catalunya, used mainly for Bordeaux blends although there are some Catalan varietal examples. Just 21 ha (52 acres) were recorded in Portugal in 2010.',
      'Cabernet Franc is planted to a limited extent in much of Eastern Europe but is generally much, much less popular than CABERNET SAUVIGNON. Over the border from Friuli in western Slovenia, Cabernet Franc tends to be blended with Cabernet Sauvignon, MERLOT and, often, the local REFOSCO, although total plantings in 2009 were only 27 ha (67 acres) in Gori\u0161ka Brda and 17 ha (42 acres) in Slovenska Istra.',
      'Romania \u2013 Cabernet-land \u2013 had just 8 ha (20 acres) in 2008, Krasnodar Krai in southern Russia just 20 ha (49 acres) in 2009. There are tiny plantings in Serbia, Kosovo, possibly Kazakhstan. The exception to this is Hungary, which had a total of 1,243 ha (3,072 acres) in 2008, most successfully in Vill\u00e1ny in the south, where some of the country\u2019s finest reds, both Bordeaux blends and varietals, depend on Cabernet Franc from the likes of Malatinszky, Attila Gere with Weninger, and Vylyan, but it is also planted further north and east in Kuns\u00e1g and Szeksz\u00e1rd and in Eger even further north. Often in Bordeaux blends but also increasingly successful as a varietal.',
      'Cabernet Franc is planted to a limited extent throughout northern Greece, nearly always blended in regional wines. However, the C\u00f4tes de Meliton appellation in Halkidik\u00ed requires 70% LIMNIO and 30% CABERNET SAUVIGNON and/or Cabernet Franc. In Velvent\u00f3s, western Makedon\u00eda, Yannis Voyatzis makes a varietal Tsapournako, shown by DNA profiling to be a clone of Cabernet Franc.',
      'In the eastern Mediterranean, Cyprus has 267 ha (660 acres), Israel (where Margalit makes a particularly fine example) 100 ha (247 acres) and Turkey 39 ha (96 acres). It is also known on Malta.',
      'In most of the US, Cabernet Franc is grown chiefly to provide a minor ingredient in Bordeaux blends, but in Virginia, New York State and to a certain extent Washington State the variety is appreciated in its own right as being particularly suitable for local conditions.',
      'California\u2019s total area of Cabernet Franc, 3,480 acres (1,408 ha) in the ground in 2009, has remained virtually unchanged for years, although there are signs of a slight increase in interest. Most of it is grown in the Napa Valley and Sonoma, although Rusack grow the fine Anacapa in Santa Ynez Valley far to the south. Exceptional wines dominated by Cabernet Franc come from Arietta, Crocker & Starr, Detert Family Vineyards, Favia, Lang & Reed and Viader in the Napa Valley and Pride Mountain and Raymond Burr in Sonoma County.',
      'In the much more continental climate of eastern Washington State, Cabernet Franc has the advantage over MERLOT of harder wood and consequent ability to withstand the extremely cold winters, but it has been losing ground, not least to the much more fashionable SYRAH, and there were only 972 acres (393 ha) in 2011. Recommended producers of varietal versions include Barrister, Spring Valley Vineyard and Tamarack Cellars. Oregon had just 98 acres (40 ha) in 2008.',
      'Bowers Harbor Vineyards\u2019 2007 varietal Cabernet Franc was voted best red in the 2010 Michigan wine competition and the state had 86 acres (35 ha) in 2006. It is also grown in Indiana. New York State\u2019s total plantings were 498 acres (202 ha) in 2008 and the maritime climes of Long Island have long been regarded as propitious for the variety, with Schneider Vineyards\u2019 Le Breton showing why. Superior varietal examples in the more continental Finger Lakes region have been made by King Family Estate, Shalestone Vineyards and Swedish Hill.',
      'In Virginia, much faith has been invested in Cabernet Franc ever since Horton showed the way with a particularly aromatic, varietally true example in 1991. Perhaps partly because of its cold hardiness, it is seen by most as the state\u2019s most promising red wine grape, although the total planted, just over 300 acres (121 ha), represents only about 10% of the state\u2019s total vineyard area. Barboursville Vineyards Reserve is one of the most successful.',
      'Canada\u2019s relatively short growing season means that Cabernet Franc can be ripened more easily than CABERNET SAUVIGNON in many areas. It plays a relatively minor role in the sun-baked Okanagan Valley in British Columbia, where there are just 391 acres (158 ha), but it is becoming increasingly popular in Ontario, where there are already 1,565 acres (633 ha).',
      'In South America, there are 301 ha (744 acres \u2013 3.6% of vines planted for quality wine) in Uruguay. Brazil has 352 ha (870 acres) in Rio Grande do Sul and, as elsewhere in the world, most of these vines\u2019 produce disappears into Bordeaux blends. Argentina had 622 ha (1,537 acres) in 2008, mainly in Mendoza (352 ha/870 acres) and to a lesser extent in San Juan (153 ha/378 acres). Do\u00f1a Paula makes an outstanding varietal version from Gualtallary in Mendoza. Chile is the prime grower of Cabernet Franc in South America with 1,143 ha (2,824 acres). O Fournier have made an exceptional varietal from old vines in Maule.',
      'In Australia, the variety\u2019s 693 ha (1,712 acres) are used mainly as minor ingredients in Bordeaux blends, although the number of varietal versions such as Redgate\u2019s gold-medal winner is increasing. Across the Tasman, New Zealand has a steady 166 ha (410 acres).',
      'Cabernet Franc is also found in China (456 ha/1,127 acres in 2009), where it plays a very subsidiary role to the dominant variety, CABERNET SAUVIGNON. There\u2019s a tiny amount in Japan, used as usual for blending purposes.',
      'South Africa\u2019s area of Cabernet Franc tripled in the first decade of this century but still represents less than 1% of the South African vineyard area: 327 ha (808 acres) in 1998 up to 979 ha (2,419 acres) in 2008. Notable producers of fine varietal versions are Raats and Warwick Estate of Stellenbosch.',
    ],
  },
  {
    name: 'CABERNET JURA',
    synonyms: ['VB 5-02'],
    description:
      'A recent dark-skinned, disease-resistant Swiss hybrid that has so far remained in its homeland.',
    'origin and parentage': [
      'Cabernet Jura is a hybrid between CABERNET SAUVIGNON and an undisclosed disease-resistant variety (known in German as a Resistenzpartner) obtained by the private breeder Valentin Blattner in Soyhi\u00e8res, in the Swiss Jura.',
    ],
    'where its grown': [
      'Wines are deeply coloured and aromatic. Even though there were just 19 ha (47 acres) planted in Switzerland in 2009, scattered across many different cantons, varietal wines are made by several producers, including Valentin Blattner, Simmendinger and Stegeler.',
    ],
  },
  {
    name: 'CABERNET MITOS',
    synonyms: ['Weinsberg 70-77-4F'],
    description:
      'Recent, deeply coloured German cross making wines useful in blends.',
    'origin and parentage': [
      'A BLAUFR\u00c4NKISCH \u00d7 CABERNET SAUVIGNON (Levadoux clone) cross created at the Weinsberg research centre in Baden-W\u00fcrttemberg, southern Germany, in 1970. It is therefore a sibling of CABERNET CUBIN.',
    ],
    'where its grown': [
      'There are currently 320 ha (791 acres) of this variety planted in Germany, mainly in the Pfalz, Baden and Reinhessen. It entered the official variety register in 2000. Wines are deeply coloured, high in extract, reasonably tannic and therefore ideal for beefing up blends of otherwise vapid reds. Producers of varietal examples include Fuchs and Dr Lawall in Rheinhessen, Anselmann and Winzergenossenschaft Edenkoben in the Pfalz, Karl H Johner in Baden, and Willy in W\u00fcrttemberg.',
      'It is also found to a very limited extent in Switzerland (less than 2 ha/5 acres).',
    ],
  },
  {
    name: 'CABERNET MORAVIA',
    synonyms: ['M-43'],
    description:
      'High-quality, late-ripening Czech cross producing Cabernet-like wines.',
    'origin and parentage': [
      'Cabernet Moravia is a CABERNET FRANC \u00d7 ZWEIGELT cross obtained in 1975 by the private breeder Lubom\u00edr Glos in Moravsk\u00e1 Nov\u00e1 Ves in southern Morava, in the Czech Republic. It was included in the official Czech register of varieties in 2001.',
    ],
    'where its grown': [
      'Although Cabernet Moravia\u2019s yields must be controlled if high-quality wine is to be produced, the best examples are deeply coloured with the cassis aromas and structure typical of the Cabernets. Recommended producers include Radomil Baloun, Vajbar Bronislav, Kub\u00edk and Patria Kobyl\u00ed. There were 199 ha (492 acres) planted in the Czech Republic in 2009, almost all in Morava.',
    ],
  },
  {
    name: 'CABERNET PFEFFER',
    synonyms: ['Pfeffer Cabernet'],
    description: 'Rare, peppery California cross.',
    'origin and parentage': [
      'Does Cabernet Pfeffer really exist? It is said to be a cross between CABERNET SAUVIGNON and an unknown parent obtained by William Pfeffer in the 1880s in the Santa Clara Valley, California. It was allegedly destroyed by phylloxera in the 1890s and Pfeffer is reported to have bred a better version as a seedling and grafted it onto resistant rootstocks (Sullivan 1998), so that this \u2018new\u2019 Cabernet Pfeffer is in reality a sibling of the original one. However, Cabernet Pfeffer is also said to be identical to the old Bordeaux variety GROS VERDOT, and recent DNA profiling by Kenneth Volk in Santa Barbara has shown this to be true in the Volk vineyards (Ken Volk, personal communication). But if Cabernet Pfeffer really is a seedling bred in California, it could not be identical to a variety that was known in the Gironde much earlier than the date that the cross was made. More research is now needed to find out if all the Cabernet Pfeffer plantings in California are identical to Gros Verdot or if the true Cabernet Pfeffer is indeed still cultivated.',
    ],
    'where its grown': [
      'Plantings of vines called Cabernet Pfeffer are so limited that the variety is not even mentioned in the official California grape acreage report for 2010 although there were apparently 14.8 tons crushed in the state in 2007. DeRose apparently have a few hectares in the Cienega Valley AVA in San Benito County, as do Casa Nuestra in the Napa Valley (as part of a field blend), and, further afield, Page Springs in Arizona also grow it. The variety seems to live up to its name \u2013 even though it was named after the breeder and not because of its taste \u2013 and produces fresh, peppery or spicy wines that are relatively light but can still be pretty tannic. Most producers use it in a blend but DeRose make a varietal wine they label Cabernet Pfeffer.',
    ],
  },
  {
    name: 'CABERNET SAUVIGNON',
    synonyms: [
      'Bidure',
      'Bordeaux',
      'Bordo',
      'Bouchet',
      'Bouchet Sauvignon',
      'Petit Bouchet',
      'Burdeos Tinto',
      'Cabernet Petit',
      'Carbonet',
      'Carbouet',
      'Carmenet',
      'Lafit',
      'Lafite',
      'Marchoupet',
      'Navarre',
      'Petit Cabernet',
      'Petit Cavernet Sauvignon',
      'Sauvignon',
      'Sauvignonne',
      'Vidure',
      'Vidure Sauvignonne',
      'Petite Vidure',
    ],
    description:
      'The world\u2019s best-travelled red wine variety making concentrated, tannic wines for particularly long ageing.',
    'origin and parentage': [
      'Cabernet Sauvignon comes from the Gironde in south-west France. The earliest mention of the variety appeared under the name Petit Cabernet in the \u2018Livre de raison d\u2019Antoine Feuilhade\u2019, an account book written between 1763 and 1777 by the mayor of Libourne (Garde 1946). It was then listed in a catalogue of grape varieties established in Pauillac by Dupr\u00e9 de Saint-Maur in 1784: \u2018Gros cavernet sauvignon, noir, premi\u00e8re qualit\u00e9 pour faire du bon vin, ass\u00e9 productif & color\u00e9, mais sujet au coulage. Petit cavernet sauvignon, noir, seconde qualit\u00e9 pour faire du bon vin, agreable & delicat, moins productif que le precedent\u2019 (\u2018Gros cavernet sauvignon, black, top quality for making good wine, rather productive and deeply coloured, but subject to coulure. Petit cavernet sauvignon, black, secondary quality for making good wine, agreeable and delicate, less productive than the former\u2019), and the first mention of the modern Cabernet Sauvignon spelling did not appear until 1840 (R\u00e9zeau 1997).',
      'Until the end of the nineteenth century, Cabernet Sauvignon was possibly confused with CABERNET FRANC under old synonyms such as Cabrunet, Carmenet, Vidure, or simply the French plural Cabernets (see Cabernet Franc). It is also possible that Cabernet Sauvignon simply did not exist before the eighteenth century.',
      'The parentage of Cabernet Sauvignon was fortuitously discovered by Carole Meredith and her PhD student John Bowers at the University of California at Davis as recently as 1996. While generating a database for the DNA profiles of the most important grape varieties in their collection, Napa Valley-born Bowers noticed that the DNA profile of Cabernet Sauvignon was perfectly consistent with its being an offspring of CABERNET FRANC and SAUVIGNON BLANC (Bowers and Meredith 1997). This was the first time anyone had identified the parents of a classic wine grape variety, and it came as a big surprise to the wine world. Although ampelographers had already noticed many morphological similarities between both Cabernets, and although Cabernet Sauvignon apparently got its name because its wood and leaves resemble those of Sauvignon Blanc (R\u00e9zeau 1997), this parentage was totally unexpected, not least because no one had ever thought that a black-berried variety could have one white-berried parent. Gagnaire (1872) wrote at length in defence of this impossibility.',
      'The cross that gave birth to Cabernet Sauvignon was most likely spontaneous and occurred somewhere in the Gironde before the mid eighteenth century. Since MERLOT is also a progeny of CABERNET FRANC, Cabernet Sauvignon and Merlot turn out to be half-siblings (see pedigree diagram overleaf). Most of these varieties belong to the Carmenet ampelographic group (see p XXVII). In addition, a probabilistic analysis of DNA data has shown that SAUVIGNON BLANC and CHENIN BLANC are siblings \u2013 the offspring of SAVAGNIN and an unknown parent (Vouillamoz). This makes Cabernet Sauvignon a nephew/niece of Chenin Blanc from the Loire and a grandchild of Savagnin from north-east France.',
      'A pinky bronze-berried mutation of Cabernet Sauvignon was observed in 1977 in the Clegett Wines vineyard in Langhorne Creek in South Australia and it was registered under the name Malian. In 1991, one of those vines produced white berries, now registered as Shalistin. Clegett make a sweet and a dry version of Shalistin.',
      'Cabernet Sauvignon was used to breed numerous varieties around the world, some of them cultivated for commercial winemaking. The following are therefore all half-siblings:',
      '\u2013\u00a0\u00a0\u00a0Australia: CIENNA and TYRIAN',
      '\u2013\u00a0\u00a0\u00a0Bulgaria: RUEN',
      '\u2013\u00a0\u00a0\u00a0France: ARINARNOA, EKIGA\u00cfNA and MARSELAN',
      '\u2013\u00a0\u00a0\u00a0Germany: CABERNET CARBON, CABERNET CAROL, CABERNET CORTIS, CABERNET CUBIN, CABERNET DORIO, CABERNET DORSA, CABERNET MITOS',
      '\u2013\u00a0\u00a0\u00a0Italy: INCROCIO MANZONI 2.15',
      '\u2013\u00a0\u00a0\u00a0Serbia: PROBUS',
      '\u2013\u00a0\u00a0\u00a0Romania: Arcas',
      '\u2013\u00a0\u00a0\u00a0Switzerland: ?CABERNET BLANC, CABERNET COLONJES, CABERNET JURA, ?CABERTIN, CARMINOIR, ?PINOTIN, RIESEL and VB 91-26-4',
      '\u2013\u00a0\u00a0\u00a0Ukraine: ODESSKY CHERNY and RUBINOVY MAGARACHA',
      '\u2013\u00a0\u00a0\u00a0US: CARMINE and RUBY CABERNET.',
    ],
    'where its grown': [
      'Because Cabernet Sauvignon is the dominant grape in the assemblages responsible for all of the most famous wines made (in quantity) in Bordeaux\u2019s M\u00e9doc and Graves regions, including all five \u2018first growths\u2019, Cabernet Sauvignon has been admired and planted almost wherever vines are grown for wine production \u2013 except in regions too cool for this relatively late ripener. This global spread was particularly marked during the late-twentieth-century\u2019s love affair with international varieties.',
      'It has a particularly powerful identity. Thanks to the small, thick-skinned grapes, the wines tend to be deep in colour, high in tannin and relatively high in acidity. There\u2019s a recognizable nobility and stability to its aroma which can vary from blackcurrant to cedar, although such is its affinity with oak that in young wines it can be difficult to dissociate the fruit from the wood. Few varieties produce wines with a higher level of phenolics and winemakers have to be particularly careful with their extraction techniques in some vintages \u2013 especially when Cabernet Sauvignon grapes have had to struggle to ripen. The upshot of all this tannin is that Cabernet-based wines can last for decades, occasionally centuries, and may need, like that other tannic wine, vintage port, to be given many years\u2019 bottle age before being palatable, or at least pleasurable. This is particularly true in climates as moderate as Bordeaux\u2019s. Cabernet Sauvignon can be a mite monolithic, and often austere, in youth.',
      'Because, unless very ripe, Cabernet Sauvignon tends to produce wines long on structure and rather shorter on flesh, it has traditionally been blended with the much fruitier, softer MERLOT. And because growing seasons can vary markedly in Bordeaux, the late Cabernet Sauvignon was traditionally planted with the more precocious Merlot and CABERNET FRANC as an insurance policy against poor fruit set. The close genetic relationship between these three varieties makes them easy blending partners and in wine parlance \u2018Bordeaux blend\u2019 tends to mean Cabernet Sauvignon with any of Merlot, Cabernet Franc and/or the other varieties grown to produce red bordeaux: PETIT VERDOT, Malbec (COT) and occasionally CARMEN\u00c8RE.',
      'In warmer climes, especially in northern California and Washington State, Cabernet Sauvignon grapes can reach such an advanced state of physiological ripeness, and have been encouraged to do so by extended \u2018hang time\u2019 on the vine, that in a typical vintage they hardly need the added flesh of a MERLOT component in the blend, although some winemakers add a seasoning of other grape varieties for aromatic complexity.',
      'What is remarkable about Cabernet Sauvignon is that it imprints its identity so firmly on any wine that includes it, even in quite small amounts, although it manages to produce great wine in only a small proportion of the hundreds of regions in which it is planted. The most obvious established candidates are the M\u00e9doc, Pessac-L\u00e9ognan, Pened\u00e8s, Napa, Bolgheri, Sonoma, Santa Cruz Mountains, parts of Washington State, Puente Alto in Chile, and Coonawarra and Margaret River in Australia.',
      'In France, Cabernet Sauvignon was the fourth most planted red wine variety after MERLOT, Grenache (GARNACHA) and SYRAH in 2009, with a total of 56,386 ha (139,333 acres). In the greater Bordeaux region, it was a poor second to the much easier to ripen Merlot: 26,790 ha (66,200 acres) to Merlot\u2019s 69,053 (170,634 acres).',
      'Throughout south-west France, it is one of the principal varieties in appellations on the fringes of Bordeaux such as Buzet, Bergerac and C\u00f4tes de Duras and also in Iroul\u00e9guy on the western Spanish border. It is a supplementary variety in the likes of B\u00e9arn, Madiran, C\u00f4tes de Saint-Mont, Tursan, Marcillac, Gaillac and Fronton (adding structure to N\u00c9GRETTE in the last two).',
      'It is the fifth most planted variety in Languedoc-Roussillon, with a total of 18,722 ha (46,263 acres) in 2009, mainly in the Languedoc. While it\u2019s a permitted ingredient in appellations such as Cabard\u00e8s and Malep\u00e8re, it is used mainly for varietal wines. Perhaps the most famous is Mas de Daumas Gassac, where Cabernet Sauvignon was planted on the advice of the late Bordeaux oenologist \u00c9mile Peynaud, no less. With Aniane neighbour Grange des P\u00e8res, these are two of the most successful Cabernet Sauvignon-based wines from the Languedoc, but too often less ambitious examples lack conviction and concentration, perhaps because of excessive yields or poor-quality clones.',
      'Cabernet Sauvignon grown to the east in Provence \u2013 3,483 ha (8,607 acres), mainly in the southern Rh\u00f4ne and the Var \u2013 seems much more convincing and generally has no shortage of concentration. Indeed it sometimes has a sort of dry-climate dustiness. It has traditionally been blended with SYRAH in reds and ros\u00e9s, although there is more Syrah planted here and much more Grenache (GARNACHA). Domaine de Tr\u00e9vallon is the standard-bearer.',
      'In the Loire and central France, Cabernet Sauvignon\u2019s total of 1,283 ha (3,170 acres) makes it very minor indeed compared with CABERNET FRANC (less than a tenth as much), partly because it is much harder to ripen this far from the equator, but Cabernet Sauvignon is the principal variety in Cabernet d\u2019Anjou ros\u00e9, can be significant in appellations such as Anjou-Villages and is permitted (usually playing a bit part to Cabernet Franc\u2019s lead) in Bourgueil and Chinon.',
      'Cabernet Sauvignon is important in Italy, where it has been grown since the 1820s, when it was introduced to Piemonte. As in Spain, Italy has seen a period of Cabernet worship, particularly in the revitalized Maremma, where the variety really does seem at home, as evinced by Sassicaia, based on cuttings from Bordeaux, as long ago as the late 1960s. The variety was enthusiastically planted all over Italy in the late twentieth and early twenty-first centuries \u2013 particularly in Toscana, where it, along with MERLOT and occasionally SYRAH, was thought to add body and international appeal to what were seen as the shortcomings of the local SANGIOVESE. This was perfectly legal in Chianti Classico, not so for Brunello di Montalcino, which in 2008 was riven by ideological dispute over this issue. Cabernet Sauvignon, long a required ingredient in the Tuscan Carmignano DOC, was a vital element in many of the so-called Supertuscans that followed in Sassicaia\u2019s footsteps and enjoyed fame in the late twentieth century, such as Solaia, Sammarco, Saffredi, Paleo Rosso, La Vigna d\u2019Alceo and hundreds more. To a much more limited extent it was also planted throughout northern Italy, where its noblest examples include Gaja\u2019s Darmagi in Piemonte, Maurizio Zanella in Lombardia, Lageder and San Leonardo in Alto Adige. Here and in Friuli it can be quite light and herbaceous.',
      'The most recent official Italian viticultural census, in 2000, found 8,042 ha (19,872 acres) but the current total is likely to be a good deal higher, especially considering the fact that Sicilia alone had 3,992 ha (9,864 acres) planted in 2008. There are many DOCs with Cabernet in their name throughout northern and central Italy as well as further south in Puglia and Sicilia, some specifying Cabernet Sauvignon or CABERNET FRANC, some leaving it to the discretion of the producer. Cabernet Sauvignon-dominated wines of great quality are relatively rare this far south but Sicilia can boast examples from Planeta and Tasca d\u2019Almerita, and the Montevetrano blend from Campania has also attracted international attention.',
      'Cabernet Sauvignon was also introduced into Spain to a limited extent in the mid nineteenth century by the Marqu\u00e9s de Riscal in Rioja and Eloy Lecanda of Vega Santa Cecilia (later Vega Sicilia) in Ribera del Duero. But it was its espousal by Miguel Torres, Jr, and Jean Le\u00f3n in the 1960s in Pened\u00e8s that encouraged Spanish wine producers to value this French intruder so highly. A Torres, Mas La Plana Cabernet Sauvignon 1970, did remarkably well in a 1979 rerun of the famous 1976 Judgement of Paris blind-tasting comparison between top French wines and those emulating them elsewhere.',
      'In 2008 Cabernet Sauvignon was the fourth most planted red wine variety in Spain, its total of 19,430 ha (48,013 acres) being a considerable increase on the 10,000 ha (24,710 acres) in the ground in 2004. It is particularly popular in such northern DOs as Navarra and Somontano and in La Mancha but it has become a significant minor ingredient with TEMPRANILLO in Ribera del Duero, and varietal Cabernet Sauvignon can be found all over the country, including in the newer high-altitude vineyards of Andaluc\u00eda. Many of those who have planted new vineyards in Spain seem to view Cabernet Sauvignon as an essential accoutrement of sophistication, although only in Pened\u00e8s does it have much of a track record. Also in Catalunya, it can be found in some Priorat blends too.',
      'The Portuguese are more inured to Cabernet-mania and there were just 2,271 ha (5,612 acres) planted in 2010 in Portugal. Palmela on the Pen\u00ednsula de Set\u00fabal is the only denomination that embraces it but it pops up in various Vinho Regionals all over the country, most successfully in the Tejo.',
      'In Eastern Europe, Cabernet Sauvignon is very important in Bulgaria, where with a 2009 total of 15,827 ha (39,109 acres) it accounts for 15% of the country\u2019s vineyard area and is almost as widely planted as the common PAMID. It is planted mainly in the south, although the wines tend to show more finesse in the north. Single-estate Bordeaux blends are the most popular wines produced by the new wave of ambitious Bulgarian producers.',
      'Cabernet Sauvignon is widely planted in Ukraine \u2013 8,468 ha (20,925 acres) in 2009 \u2013 and it is also a hugely popular variety over the Black Sea to the east in Russia, where there were 3,525 ha (8,710 acres) in Krasnodar Krai alone in 2010, as well as a more modest 68 ha (168 acres) in Rostov, and over the border to the west in the Republic of\nMoldova, where 7,590 ha (18,755 acres) were planted. Romania had 3,128 ha (7,729 acres) planted in 2008 but MERLOT is much more popular. All of these countries are capable of making very respectable wines from the variety but have until recently been hampered by poor viticulture and sometimes excessive yields.',
      'Cabernet grown in Slovenia is generally a much more serious proposition, as demonstrated by the likes of Rojac in Istra (Istria) and Scurek and Marjan Sim\u010di\u010d in Brda. In 2009 there were 470 ha (1,161 acres) planted, mainly in Vipavska Dolina and Gori\u0161ka Brda near the Italian border but also further south near the coast in Slovenska Istra.',
      'In Croatia, there were 894 ha (2,209 acres) in 2009, less than 3% of the country\u2019s vines, while Cabernet\u2019s 228 ha (563 acres) in the much, much cooler Czech Republic are predominantly in Morava in the south east, where it is most likely to stand a chance of ripening. There is a tiny amount planted in Slovakia \u2013 and indeed an increasing amount to the north\nin Germany,\nwhere global warming has allowed some growers such as Hammel and Martin Wassmer in the southern regions of the Pfalz and Baden respectively to ripen it successfully.',
      'The warmer Pannonian climate of Hungary is much more suitable for this late-ripener even though CABERNET FRANC is the favoured variety here. In 2008 Hungary had a total of 2,752 ha (6,800 acres), with the largest areas in Vill\u00e1ny (where star performers include Malatinszky and Atilla Gere) and Eger, where it is a permitted ingredient in Egri Bikav\u00e9r (Bull\u2019s Blood of Eger).',
      'Across the border in Austria, Cabernet Sauvignon\u2019s 519 ha (1,282 acres) made it the third most planted red wine grape in 2007 but it is far less important than BLAUFR\u00c4NKISCH and ZWEIGELT and is generally used as an ingredient in a blend, or cuv\u00e9e. Plantings are mainly in Burgenland, particularly in Neusiedlersee (151 ha/373 acres), where the area has almost doubled since 1999. In Switzerland, too, fully ripe Bordeaux blends, and blends with indigenous Swiss varieties, have become more prevalent thanks to global warming, not just in Ticino but in the French-speaking Swiss cantons, eg in Gen\u00e8ve, where Domaine des Balisiers make their Comte de Penay cuv\u00e9e in amphorae.',
      'Cabernet Sauvignon has played a part in the modern wine revolution in Greece, where in 2008 there were 1,743 ha (4,307 acres), particularly in Dr\u00e1ma and Kavala in north-eastern Makedon\u00eda, Messin\u00eda on the west of the Pelop\u00f3nnisos and Attik\u00ed south of Athens. Antonopoulos, Biblia Chora, Costa Lazaridi, Pavlidis, Katogi Strofilia, Tsantali and Tselepos have all made very creditable examples. It plays a more minor role on the island of Cyprus, where there are only 279 ha (689 acres). The tiny Mediterranean island of Malta boasts about 100 ha (247 acres).',
      'As Turkey\u2019s wine industry has joined the mainstream, Cabernet Sauvignon has increasingly been planted, so that by 2010 there were 493 ha (1,218 acres) and some pretty serious blends, such as those of Corvus, B\u00fcy\u00fcl\u00fcba\u011f and Kayra.',
      'Cabernet Sauvignon has played a crucial role in the modern wine industry in Lebanon, not least for providing the backbone of its best-known export, Ch\u00e2teau Musar\u2019s spicy red. The variety is also, not surprisingly, the most popular variety in Israel\u2019s cosseted vineyards, where, with a 2009 total of 1,250 ha (3,089 acres), it constitutes a quarter of all plantings and is particularly successful in the hands of Ella Valley, Galil Mountain, Golan Heights and Margalit.',
      'Cabernet\u2019s popularity in Israel is a reflection of its huge importance in the United States. With the exception of a short-term fling with MERLOT and a Sideways-inspired flirtation with PINOT NOIR, Cabernet Sauvignon is red wine for Americans. It is planted in every state that can ripen it and in California, the nation\u2019s dominant wine producer, is the most planted red wine variety by far, its 2010 total of 77,602 acres (31,404 ha) second only to CHARDONNAY. It grows like a sprawling weed in the Central Valley and is planted the length of the state but reaches its apogee in Napa Valley\u2019s sun-kissed, fog-cooled climes as well as in Alexander Valley, Sonoma Valley and various other spots protected from exaggerated Pacific influence, such as on well-exposed sites above the fog line of the Santa Cruz Mountains. If price is any measure, then California has definitively proved to be one of Cabernet Sauvignon\u2019s sweetest spots. The dotcom boom fuelled demand for limited-production, so-called cult Cabernets, so that for a time they sold like hot cakes via over-subscribed mailing lists. So ripe are many of these wines that they can be drunk on release and some taste so sweet and alcoholic that they can sometimes seem, to a palate raised on the more angular charms of red bordeaux, closer to hot chocolate than wine.',
      'The variety is also grown in Mexico, particularly in Baja California, Coahulia de Zaragoza and Zacatecas.',
      'Much of Oregon is too cool to ripen Cabernet of either sort, except for the Columbia River region in the north east, on the border with Washington State and, to a lesser extent, in the Rogue Valley in the south west, near the California border. In 2008 a total of 523 acres (212 ha) was planted. In semi-desert eastern Washington State, on the other hand, Cabernet Sauvignon has become by far the most popular variety, with plantings totalling 10,293 acres (4,167 ha) by 2011. Here Cabernet Sauvignon can really shine, the fruit often showing appetizing brightness. Leading vineyards include Champoux in Horse Heaven Hills, Ciel du Cheval and Klipsun vineyards in Red Mountain, and Wahluke Slope. Whereas in California there is an army of admired producers of Cabernet Sauvignon, Washington\u2019s constitute more of a cohort, with Leonetti, Powers, Quilceda Creek, Andrew Will and Woodward Canyon in the front line but dozens more only a step behind.',
      'Virtually all American states make Cabernet Sauvignon and the majority of them grow it, with New York State\u2019s 340 acres (138 ha) mainly on Long Island, and the Texas wine industry is dominated by it. Stylistically most states\u2019 winemakers are heavily influenced by the California style of ripe fruit given a bit of discipline in expensive French, and occasionally American, oak. Virginia\u2019s 260 acres (105 ha) of Cabernet Sauvignon are a little more difficult to ripen than the CABERNET FRANC favoured by many growers, although RdV have proved that, with care, Virginia Cabernet Sauvignon can be magnificent.',
      'In Canada, the balmy temperatures of the southern Okanagan Valley in British Columbia are much better suited to Cabernet Sauvignon than anywhere else and the BC total in 2008 was 1,683 acres (681 ha), representing nearly 15% of red wine varieties. Mission Hill\u2019s top bottling and Osoyoos Larose are outstanding Cabernet-based wines by any measure. Ontario had only about a third as much in 2006 and very careful site selection is needed here for this late-ripening variety.',
      'Cabernet Sauvignon is also hugely important to South American wine \u2013 especially in Chile, where cuttings were brought directly from Bordeaux before phylloxera. The 2008 total of 40,728 ha (100,641 acres and far more even than in California) represents about a third of all vines planted and the variety can be found in all regions, especially in Colchagua, Maule, and to a lesser extent in Curic\u00f3, Maipo and Cachapoal, where it is the most planted variety. It was notable that some of the world\u2019s most garlanded producers of Cabernet Sauvignon-dominated wines elsewhere \u2013 the likes of Robert Mondavi of California and Baroness Philippine de Rothschild, Bruno Prats and Paul Pontallier of Bordeaux \u2013 chose to invest in Chilean Cabernet Sauvignon many years ago. Chilean Cabernet, much of it grown on ungrafted vines in a disease-free environment, tastes particularly fruity and easy to appreciate young, even if there can be an edge of greenness to wines from over-burdened vines. But there is also a deepening top layer of more complex, ageworthy examples such as Almaviva, Alta\u00efr, Aurea Domus, Casa Lapostolle, Concha y Toro\u2019s Don Melchior, De Martino, Errazuriz/Vi\u00f1edo Chadwick/Se\u00f1a, and top bottlings from the likes of Gilmore, Haras de Pirque, Montes, O Fournier, Perez Cruz and others.',
      'In Argentina, Malbec (COT) and Bonarda (DOUCE NOIRE) are much more important than Cabernet Sauvignon, which is a shame since Argentine Cabernet can present a magnificent combination of ripeness and minerality (and occasionally tart greenness if yields are too high). In 2008 there was a total of 17,746 ha (43,851 acres), the majority (13,342 ha/32,968 acres) in Mendoza, but it is also planted in several other regions, notably San Juan (2,182 ha/5,392 acres), La Rioja (1,018 ha/2,515 acres) and Salta (444 ha/1,097 acres). It is also often used as a stiffener for Malbec. Some of those who produce superior Cabernet Sauvignons include Catena Zapata, Vi\u00f1a Cobos, Cuvelier Los Andes, Mendel, Terrazas de los Andes, Fabre Montmayou and Vistalba, most of them having close links to either Bordeaux or the Napa Valley.',
      'Brazil had 1,868 ha (4,616 acres) of Cabernet Sauvignon in 2007 but it can be difficult to ripen it fully in traditional damp regions, although Lidio Carraro, Miolo and Salton have all made creditable examples.',
      'TANNAT and even MERLOT are more important in Uruguay, which had 731 ha (1,806 acres) of Cabernet Sauvignon in 2008.',
      'In the 1970s and 1980s, Cabernet Sauvignon was worshipped in Australia but it is now viewed as a poor second to Shiraz (SYRAH), both qualitatively and quantitatively. In 2008 the total area planted with Cabernet Sauvignon was 27,553 ha (68,085 acres), considerably less than Shiraz (SYRAH). By far the majority of plantings are in the Riverland (3,496 ha/8,639 acres), for inexpensive, fruity varietals, and in Coonawarra (3,444 ha/8,510 acres), which was the first region in Australia ever to establish a varietal speciality, Cabernet Sauvignon. In recent years a lack of fine-tuned viticulture has tarnished Coonawarra Cabernet\u2019s reputation but the potential for top-quality wine from its terra rossa soils is indubitably there. Cabernet Sauvignon is also important in Langhorne Creek (1,921 ha/4,747 acres), which produces a rather soft style for blending, Barossa Valley (1,336 ha/3,301 acres), whose Cabernet is particularly dense and chocolatey, and Western Australia\u2019s Margaret River (1,291 ha/3,190 acres), which makes sophisticated, well-balanced examples \u2013 on a west coast just like the variety\u2019s other homes in Bordeaux, Napa Valley and Bolgheri. (There is a theory that rays from the setting sun are particularly propitious for Cabernet Sauvignon vines.) The Margaret River tradition is to blend Cabernet Sauvignon with MERLOT for less serious bottlings and to make varietal Cabernet Sauvignon for the long term. Australia\u2019s most accomplished Cabernet Sauvignon producers include Cape Mentelle, Cullen, Howard Park, Moss Wood, Vasse Felix and Woodlands in Margaret River; Balnaves, Bowen, Majella, Parker and Wynns in Coonawarra; Henschke, Penfolds and Wendouree from further north in South Australia; Mount Mary and Yarra Yering in Victoria; Lake\u2019s Folly in Hunter Valley. Cabernet Sauvignon/Shiraz blends are part of Australian wine\u2019s long and glorious history.',
      'New Zealand is PINOT NOIR not Cabernet country. There were only 517 ha (1,278 acres) of Cabernet Sauvignon vines in the ground in 2009 \u2013 much less than Pinot Noir\u2019s 4,240 ha (10,477 acres) and way behind MERLOT\u2019s 1,363 ha (3,368 acres). Most regions are too cool or wet for the variety but it clearly thrives in the appropriately named Gimblett Gravel soils and elsewhere in Hawke\u2019s Bay on the North Island. Reliable producers of varietal Cabernet Sauvignon and, generally more successful, Bordeaux blends include Cape Kidnappers, Craggy Range, Esk Valley, C J Pask, Stonecroft, Stonyridge, Te Awa Farm, Te Mata, Trinity Hill, Unison and Villa Maria.',
      'As for China, the world\u2019s fastest-growing wine producer, Cabernet Sauvignon has played the crucial role, being by far the most favoured variety since red bordeaux has been seen as the archetypal wine. By 2009 plantings were reckoned to total 20,352 ha (50,288 acres), in all of China\u2019s wine regions, and they must already be considerably more than this \u2013 very possibly more than in Bordeaux by now. Inland Ningxia is looking like the province with the greatest potential, although the Rothschilds of Ch\u00e2teau Lafite have bet on Shangdong on the coast. Much of the wine produced is extremely lean but producers such as Grace, Silver Heights and Jade Mountain have broken the mould.',
      'The variety has been planted in virtually all new territories for the vine, including Japan (469 ha/1,159 acres in 2009, mostly in Yamanashi and Hyogo), which, with extreme vine-by-vine care, can produce quite creditable copies of ripe red bordeaux from its limited plantings. In 1988 Grover pioneered Cabernet Sauvignon in India, with several harvests a year on high ground north of Bangalore in Karnataka State.',
      'Cabernet Sauvignon is taken very seriously in South Africa, even if work has been needed to eradicate the ripening difficulties associated with the leafroll virus endemic in some vineyards. Cabernet Sauvignon is traditionally blended with MERLOT here and in 2008 total plantings were 12,697 ha (31,375 acres), making Cabernet Sauvignon the Cape\u2019s most widely planted red wine variety, accounting for 28% of red plantings and second only to CHENIN BLANC overall. The main areas are Stellenbosch, Paarl and Malmesbury, and those making particularly distinctive Cabernets include Glenelly Hill, Jordan, Morgenster, Reyneke, Rustenberg, Simonsig, Thelema, Vergelegen, Vilafont\u00e9, Warwick and Waterford.',
    ],
  },
  {
    name: 'CABERNET SEVERNY',
    synonyms: ['Cabernet Szevernuej', 'Kaberne Severnyi'],
    description:
      'Winter-hardy hybrid with Asian not Cabernet genes but marked Cabernet colour and flavours.',
    'origin and parentage': [
      'Cabernet Severny, literally \u2018northern Cabernet\u2019, is a complex hybrid bred specifically for its winter hardiness by Ya I Potapenko, L I Proskurnya and A S Skripnikova at the All-Russia research and development centre for viticulture and winemaking in Novocherkassk, in the province of Rostov, Russia, by crossing a DIMYAT (under the name Galan) \u00d7 Vitis amurensis hybrid with a pollen mixture from other Vitis vinifera \u00d7 Vitis amurensis hybrids. Included in the official register of varieties in 1990. Although no Cabernet was involved in the pedigree, it was christened Cabernet Severny because its aroma and flavour were thought to be similar to those of CABERNET SAUVIGNON (Taras Hiabahov, personal communication).',
    ],
    'where its grown': [
      'Cabernet Severny\u2019s greatest attribute is its winter hardiness, which is why it is grown not only in Russia \u2013 in the Krasnodar and Rostov regions \u2013 but also in Canada, notably in Qu\u00e9bec, where producers such as Carone have shown that this deeply coloured Cabernet-like variety is well suited to oak ageing. In Russia it is used both for dry and for dessert wines. It is also being considered by Pernod Ricard as suitable for planting in their Helan Mountain project in Ningxia province, China.',
    ],
  },
  {
    name: 'CABERTIN',
    synonyms: ['VB 91-26-17'],
    description: 'Recent disease-resistant Swiss hybrid with potential.',
    'origin and parentage': [
      'Cabertin is a hybrid between CABERNET SAUVIGNON and an undisclosed disease-resistant variety (known in German as a Resistenzpartner) obtained in 1991 by the private breeder Valentin Blattner in Soyhi\u00e8res, in the Swiss Jura, possibly a Cabernet Sauvignon \u00d7 (SILVANER \u00d7 (RIESLING \u00d7 Vitis vinifera) \u00d7 (JS 12417 \u00d7 CHANCELLOR)) complex hybrid, just like PINOTIN and CABERNET BLANC.',
    ],
    'where its grown': [
      'Wines are deeply coloured, with aromas of fruits of the forest and cassis. They have sufficient structure to withstand barrel ageing. Plantings are very limited but varietal wines are produced by, for example, Kirsch in Jena, eastern Germany, and Domaine Viticole du Chenoy in Namur, Belgium, and there are further small vineyards in the Pfalz in Germany.',
    ],
  },
  {
    name: 'CACABOU\u00c9',
    synonyms: [
      'Caca d\u2019Oie',
      'Cacabois',
      'Cacabouet',
      'Persan Blanc',
      'Saint-P\u00e9ray',
    ],
    description: 'Virtually extinct Savoie vine.',
    'origin and parentage': [
      'Cacabou\u00e9 is a local and almost extinct variety from Savoie but its age is hard to determine. The name Cacabou\u00e9 is a dialect form of \u2018Caca d\u2019Oie\u2019, literally meaning \u2018goose poo\u2019, in reference to the shape of the pulp remaining on the stem after pulling off the berry. DNA parentage analysis strongly suggests that Cacabou\u00e9 is either a parent or an offspring of AVAN\u00c0 from Piemonte, in north-west Italy (Vouillamoz), a variety that used to be cultivated in Savoie under the name Hibou Noir.',
    ],
    'where its grown': [
      'Cacabou\u00e9 used to be cultivated in particular in Charpignat near the southern end of the Lac du Bourget in Savoie, eastern France. Charpignat was once an officially designated cru (like Apremont or Arbin today) but the vineyards have lost the battle with the builders and only a few vines remain. However, Domaine Langain produce tiny amounts (10 hl) of a varietal wine from a few ares planted above the Lac du Bourget, classified as Vin de Pays des Allobroges and sold in a local Michelin-starred restaurant. This and other obscure or endangered varieties are currently being studied by the Centre d\u2019Amp\u00e9lographie Alpine at Montm\u00e9lian (Savoie) and INRA (Institut National de Recherche Agronomique) in Montpellier, southern France.',
    ],
  },
  {
    name: 'CACAMOSCA',
    synonyms: ['Riciniello Bianco'],
    description: 'Rare, low-yielding Campanian white.',
    'origin and parentage': [
      'Old variety from Campania in southern Italy. The odd name of this white grape variety refers to the characteristic presence of numerous brown spots scattered on the surface of the berries that look like \u2018fly\u2019s dung\u2019. It is genetically close to FENILE, also from Campania (Costantini et al. 2005).',
    ],
    'where its grown': [
      'Cacamosca used to be cultivated on the Amalfi and Sorrento coasts as well as on the Collina di Posillipo near the bay of Napoli, in Italy, where it produced a reputedly excellent wine which was even exported in the eighteenth and nineteenth centuries. Today it survives only in old and ungrafted vineyards in Gragnano and Lettere near Amalfi, in the province of Napoli, and generally disappears into blends. The Cacamosca grapes tend to have high sugar and low acidity.',
    ],
  },
  {
    name: 'CADDIU',
    synonyms: ['Caddeo', 'Caddiu Nieddu', 'Caddu', 'P\u00e0mpinu'],
    description: 'Sardinian blending and sometimes table grape.',
    'origin and parentage': [
      'Not much is known about Caddiu\u2019s origin but it probably comes from the Italian island of Sardegna, where it has allegedly been known since 1780 under the name P\u00e0mpinu. However, the true identity of Caddiu has yet to be established because the DNA profile of its supposed synonym Nieddu Pedra Serra in Zecca et al. (2010) turns out to be identical to that of PASCALE in De Mattia et al. (2007), another Sardinian variety (Vouillamoz).',
    ],
    'where its grown': [
      'Used both for the table and for wine, Caddiu is cultivated only in the Bassa Valle del Tirso in Cagliari, Sardegna, Italy, and is always blended with other varieties, such as NIEDDERA or MONICA NERA. Contini\u2019s Barrile IGT, for example, based on Nieddera, includes 10\u201315% Caddiu. 1,100 ha (2,720 acres) were recorded in the 2000 census.',
    ],
  },
  {
    name: 'CA\u00cd\u00d1O BLANCO',
    synonyms: ['Cainho de Moreira', 'Ca\u00ed\u00f1o Branco'],
    description: 'Rare Iberian white that ripens late but keeps its acidity.',
    'origin and parentage': [
      'Ca\u00ed\u00f1o Blanco is indigenous to the area of the Iberian peninsula between Vinho Verde in northern Portugal and Galica in north-west Spain, where it was allegedly first mentioned as early as 1722 (Huetz de Lemps 2009). It has often been confused with Albari\u00f1o (ALVARINHO) but ampelographic and DNA studies have rejected this identity (Santiago et al. 2007; Gago et al. 2009). A more recent DNA parentage analysis has suggested that Ca\u00ed\u00f1o Blanco is a likely natural cross between Albari\u00f1o and Ca\u00ed\u00f1o Bravo (AMARAL) that occurred before the eighteenth century (D\u00edaz-Losada et al. 2011), but this parentage was based on twenty-seven DNA markers only and would require confirmation with additional markers, especially given the complexity of the Ca\u00ed\u00f1o group. There is some confusion in Vinho Verde, where the name Alvarinh\u00e3o is used as a synonym for both Ca\u00ed\u00f1o Blanco and FERN\u00c3O PIRES.',
    ],
    'where its grown': [
      'Ca\u00ed\u00f1o Blanco is grown to a very limited extent in the Rosal area within the R\u00edas Baixas DO, Spain, where Terras Gauda, for example, blend it with Albari\u00f1o (ALVARINHO) to add freshness and zest. Although wines have relatively high alcohol thanks to late ripening, they retain fresh acidity and noticeable minerality. Ca\u00ed\u00f1o Blanco is authorized for both the R\u00edas Baixas and Monterrei DOs. There were 58 ha (143 acres) in Spain in 2008, exclusively in Galicia, and plantings have been increasing since the 1990s (B\u00f6hm 2011). Portugal had just 7 ha (17 acres) in 2010.',
    ],
  },
  {
    name: 'CALADOC',
    synonyms: ['Kaladok'],
    description:
      'Usefully reliable cross created in southern France but now well dispersed.',
    'origin and parentage': [
      'A GARNACHA \u00d7 COT cross obtained in 1958 by Paul Truel at INRA (Institut National de la Recherche Agronomique) in Montpellier, southern France, in order to produce a variety that was resistant to coulure. The cross was so successful that Rh\u00f4ne nurserymen described it as \u2018un Grenache qui ne coule pas\u2019 (\u2018a Grenache which does not suffer from coulure\u2019). According to Galet (2000), the name is a contraction of Galabert, with a substitution of C for G, and Languedoc, Galabert being a lake in the Bouches-du-Rh\u00f4ne, where this variety was planted.',
    ],
    'where its grown': [
      'Caladoc is recommended in a dozen d\u00e9partements across southern France, where there were total plantings of 2,449 ha (6,052 acres) in 2008, but is not permitted in any appellation wines. After a slow start (just 64 ha/158 acres in 1998), the variety has spread quite rapidly and still seems to be on the increase. Wines tend to be full-bodied, deeply coloured and tannic but the variety is also useful for fruity ros\u00e9s. Mas de Rey in Provence make a varietal version.',
      'In Spain, Carlos Esteva of Can Rafols dels Caus in Pened\u00e8s planted one third of a hectare of Caladoc vines at the beginning of the 1980s, along with the CHENANSON and PORTAN varieties, which together constitute the blend of their Petit Caus red. More recently in the same region, Albet i Noya have also planted Caladoc. Portugal had 1,115 ha (2,755 acres) in 2009, mainly in Lisboa. DFJ Vinhos\u2019 Grand\u2019Arte is 100% Caladoc but they also produce several blends with ALICANTE HENRI BOUSCHET and TEMPRANILLO (Tinta Roriz) in the Lisboa region.',
      'It is also found in Lebanon, and in Morocco Dom de la Zouina make a varietal vin gris.',
      'There are minor plantings in Argentina (17 ha/42 acres in 2008), where Zuccardi make a Mendoza varietal, and a few rows in Rio Grande do Sul, Brazil.',
    ],
  },
  {
    name: 'CALAGRA\u00d1O',
    synonyms: ['Calagra\u00f1a', 'Nav\u00e8s'],
    description:
      'Virtually extinct Rioja vine making coarse, uninteresting wine.',
    'origin and parentage': [
      'Calagra\u00f1o is an old and almost extinct variety from Rioja in northern Spain, where it still survives near San Asensio. It is often considered to be a synonym for CAYETANA BLANCA (referred to by Galet (2000) as Ja\u00e9n Blanco but also known as Pardina) but their DNA profiles are distinct and Calagra\u00f1o appears to be genetically similar to GARNACHA. The name Pardina is particularly confusing because it is also used as a synonym for ALBILLO MAYOR in Ribera del Duero.',
    ],
    'where its grown': [
      'A few vines can still be found around the town of San Asensio, north west of Logro\u00f1o, and elsewhere in Rioja (Carre\u00f1o et al. 2004), although the variety is not authorized for the Rioja DO unless the vines were planted before 1970. In 2008, there were 22 ha (54 acres) in Spain: 10 ha (25 acres) in La Rioja and 6 ha (15 acres) in both the Pa\u00eds Vasco and Valencia. Wines are apparently rather hard and coarse but can respond to oak ageing.',
    ],
  },
  {
    name: 'CALANDRO',
    synonyms: ['Geilweilerhof 84-58-1233'],
    description: 'Recent German hybrid awaiting official recognition.',
    'origin and parentage': [
      'A DOMINA \u00d7 REGENT hybrid obtained in 1984 by Rudolf Eibach and Reinhard T\u00f6pfer at the Geilweilerhof research centre in Germany.',
    ],
    'where its grown': [
      'Not yet on the official variety register in Germany although the licence was applied for in 2004. Full-bodied, tannic wines with smoky, berry-fruit aromas and good ageing potential.',
    ],
  },
  {
    name: 'CALITOR NOIR',
    synonyms: [
      'Col Tor',
      'Colitor',
      'Coytor',
      'Garriga',
      'P\u00e9coui-Touar',
      'Picpoul de Fronton',
    ],
    description:
      'Declining, low-quality Proven\u00e7al variety with more synonyms than hectares under vine.',
    'origin and parentage': [
      'The earliest citation of this variety was found under its old spelling of Colitor in a list of varieties compiled by Olivier de Serres (1600): \u2018non plus aujour-d\u2019hui ne sont indiff\u00e9remment recogneus par toutes les Prouinces, les noms des raisins, d\u00f3t l\u2019on use le plus en diuers endroits de ce Roiaume, qui sont, Nigrier, Pinot, Pique-poule, Meurlon, Foirard, Brumestres, Piquardant, Ugnes, Caun\u00e8s, Samoyran, Ribier, Beccane, Pounhete, Rochelois, Bourdelois, Beaunois, Maluoisie, Meslier, Marroquin, Bourboulenc, Colitor, Veltoline, Corinthien ou Marine-noire, Grecs, Salers, Espaignols, Augibi, Clerete, Prunelat, Gouest, Abeillane, Pulceau, Tresseau, Lombard, Morillon, Sarminien, Chatus, la Bernelle, & autres infinis\u2019 (\u2018consistently distinguished today in all provinces are the grape names that are the most commonly used in this kingdom, which are .\u00a0.\u00a0.\u2019 etc.). The name Colitor was then also mentioned in 1656 in Lapalud, north of Orange in the Vaucluse.',
      'The name Calitor most likely derives from a Proven\u00e7al dialect word col, meaning \u2018peduncle\u2019 or \u2018stalk\u2019, and tor, meaning \u2018twisted\u2019, in reference to the right-angle twist of the bunch stalk, which would explain the local dialect synonyms Col Tor and P\u00e9coui Touar, literally \u2018twisted stalk\u2019.',
      'Two colour mutations are known, Calitor Blanc and Calitor Gris, the white-berried version being cited as early as 1782 as a variety giving excellent wines in N\u00eemes (R\u00e9zeau 1997). The Calitor ampelographic group (see p XXVII) also contains BRAQUET NOIR, with which Calitor Noir is sometimes confused, and Ribier Noir (Bisson 2009).',
    ],
    'where its grown': [
      'According to the official French register of varieties, it produces wines that are light, dull, low in acidity and pale \u2013 which is probably why there were only 31 ha (77 acres) in France in 2008, down from 319 ha (788 acres) in 1968 and still declining, although it was already losing ground earlier in the century in favour of the more productive ARAMON NOIR. It is authorized in the southern Rh\u00f4ne (eg in the Tavel appellation, maximum 10%) but also as far south and west as the Pyr\u00e9n\u00e9es-Orientales and in C\u00f4tes de Provence wines. However, the regulations for this last stipulate that it may be included only for vines planted before 1994 \u2013 it is clearly no longer encouraged. Similarly, it was once a permitted ingredient in Bandol but has been banned since 1992. Dom de la Mordor\u00e9e in Tavel describes it as out of fashion and had only a few vines remaining.',
    ],
  },
  {
    name: '\u00c7ALKARASI',
    synonyms: ['\u00c7al Karas\u0131'],
    description:
      'Produces light, fresh reds and ros\u00e9s in south-west Turkey.',
    'origin and parentage': [
      '\u00c7alkaras\u0131 (meaning \u2018black of \u00c7al\u2019) comes from the \u00c7al district of the province of Denizli, in western Turkey.',
    ],
    'where its grown': [
      '\u00c7alkaras\u0131 is grown on the \u00c7al peninsula in the Pamukkale region, at a height of 600\u2013850 m above sea level in the Denizli province of south-western Turkey, where the climate is moderated by the proximity of the Mediterranean and soils are generally calcareous clay. There were 833 ha (2,058 acres) planted in 2010. Although it can be pressed into service to make respectable but light reds or ros\u00e9s, it is sometimes used as a filler in inexpensive blends or distilled for the production of raki. Kavaklidere produce several ros\u00e9 versions (still, dry, sparkling and semi-sweet), Pamukkale, Erdo\u011fuz and K\u00fcp, among others, produce dry reds. Wines are typically fresh and light, red-fruit-flavoured with soft tannins.',
    ],
  },
  {
    name: 'CAMARALET DE LASSEUBE',
    synonyms: [
      'Camaralet',
      'Camaralet Blanc',
      'Camaralet de la Seube',
      'Camarau Blanc',
      'Kamarau',
      'Gentil Aromatique',
      'Moustardet',
      'Petit Camarau',
    ],
    description:
      'Low fertility threatens this south-west France vine that can produce full-bodied, aromatic whites.',
    'origin and parentage': [
      'A grape variety called Camarau Blanc, an old synonym for Camaralet de Lasseube in the Pa\u00eds Vasco (Basque Country), northern Spain, was mentioned in 1783\u20134 in the Pyr\u00e9n\u00e9es-Atlantiques (R\u00e9zeau 1997): \u2018Camarau blanc .\u00a0.\u00a0. les grains sont ronds et dor\u00e9s, le gout en est piquant mais excellent\u2019 (\u2018Camarau blanc .\u00a0.\u00a0. the berries are round and golden, the taste is spicy but excellent\u2019). However, it is not known whether Camarau Blanc corresponded to Camaralet de Lasseube or to Camaraou Blanc (barely cultivated) since both were called Camarau in the Pa\u00eds Vasco. The modern name Camaralet did not appear until 1920, in Saint-Faust (R\u00e9zeau 1997).',
      'The name Camaralet could come from the B\u00e9arnese (a Gascon dialect spoken in B\u00e9arn) camarau, possibly referring to the commune of Camal\u00e8s north of Tarbes in the Hautes-Pyr\u00e9n\u00e9es, and Lasseube is a commune in the Pyr\u00e9n\u00e9es-Atlantiques about 25 km south east of Pau. However, Lavignac (2001) suggests that the B\u00e9arnese camarau derives from the Gascon cama, meaning \u2018leg, trunk\u2019 and would designate a vine with a long trunk, possibly referring to its cultivation up trees.',
      'According to ampelographers, Camaralet de Lasseube is morphologically close to AHUMAT, ARROUYA and Penouille (Bordenave et al. 2007), and it is a member of the Mansien ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Camaralet de Lasseube is recommended in the Haute-Garonne, the Gers and the Pyr\u00e9n\u00e9es-Atlantiques and may be included in the blend in the B\u00e9arn and Juran\u00e7on appellations in south-west France but was almost abandoned because of its female flowers and consequent poor fertility and low yields. However, the wine is well regarded for its finesse and full, honeyed, peachy flavours with aromas of cinnamon, pepper and fennel. It also has the advantage of lower final alcohol levels than either Manseng, which makes it a useful blend component in dry Juran\u00e7on wines. In this appellation, Domaine Cauhap\u00e9 have 3\u20134 ha (7\u201310 acres) of Camaralet, which is blended with GROS MANSENG and COURBU BLANC in Geyser, a dry Juran\u00e7on. Domaine Nigri include 10% Camaralet de Lasseube in their version and Domaine Lapeyre use a small amount in their top dry wine, Mantoulan. There was a grand total of 4 ha (10 acres) in France in 2008.',
      'Recently, Les Vignerons Ari\u00e9geois in Mont\u00e9gut-Plantaurel (just west of Pamiers) planted 30\u201340 vines of this and other endangered local varieties (Carbonneau 2005), of which Camaralet de Lasseube shows the greatest promise so far.',
    ],
  },
  {
    name: 'CAMARAOU NOIR',
    synonyms: ['Ca\u00ed\u00f1o Redondo', 'Camarau', 'Kamarau', 'Moustardet'],
    description:
      'Vigorous south-west French vine makes basic wine, mainly in Galicia.',
    'origin and parentage': [
      'Camaraou Noir was not mentioned until Viala and Vermorel (1901\u201310): \u2018Camaraou .\u00a0.\u00a0. c\u00e9page rouge sp\u00e9cial aux Basses Pyr\u00e9n\u00e9es, nomm\u00e9 aussi camarau\u2019 (\u2018Camaraou .\u00a0.\u00a0. red grape variety specific to the Basses Pyr\u00e9n\u00e9es, also named camarau\u2019) (see CAMARALET DE LASSEUBE for the etymology).',
      'Camaraou Noir is not a black mutation of Camaraou Blanc (which has now disappeared from the vineyards of B\u00e9arn and Juran\u00e7on), and both are members of the Mansien ampelographic group (see p XXVII; Bisson 2009). In addition, Camaraou Noir is ampelographically close to ARROUYA and Penouille (Bordenave et al. 2007).',
      'Quite unexpectedly, comparison of the DNA profiles of Ca\u00ed\u00f1o Redondo (Santiago et al. 2005) and Espadeiro (Mart\u00edn et al. 2003) in Galicia, Spain (distinct from the true ESPADEIRO in Portugal), shows that they are identical to Camaraou Noir (Vouillamoz).',
    ],
    'where its grown': [
      'Camaraou Noir has now almost disappeared from the vineyards of south-west France and can be found only as isolated vines in old B\u00e9arn and Juran\u00e7on vineyards (Bordenave et al. 2007). Traditionally grown up trees, it produced a very ordinary wine.',
      'The variety also survives in Galicia, north-west Spain, under the confusing synonym Espadeiro, authorized in the R\u00edas Baixas DO and long cultivated in the provinces of Orense and Pontevedra. Producers of varietal wines include Forjas del Saln\u00e9s; others such as Coto Redondo and Francisco Alfonso Reboreda blend it with other local varieties such as MENC\u00cdA or Ca\u00ed\u00f1o Tinto (BORRA\u00c7AL). In 2008 there were 137 ha (339 acres) in Galicia.',
    ],
  },
  {
    name: 'CAMARATE',
    synonyms: [
      'Camarate Tinto',
      'Castel\u00e3o da Bairrada',
      'Castel\u00e3o do Nosso',
      'Castel\u00e3o Nacional',
      'Moreto do Douro',
      'Moreto de Soure',
      'Mort\u00e1gua',
      'Mortagua de Vide Preta',
      'Negro Mouro',
      'Vide Preta',
    ],
    description:
      'Sun-loving Portuguese variety making soft, easy-drinking reds.',
    'origin and parentage': [
      'Camarate\u2019s low level of morphological diversity observed in the vineyard suggests that it is a relatively young variety (Rolando Faustino, personal communication). Indeed, recent DNA parentage analysis has shown that Camarate is a natural cross between the Iberian variety CAYETANA BLANCA and the Portuguese ALFROCHEIRO (Zinelabidine et al. 2012), which possibly occurred in the Bairrada region of western Portugal, its main area of cultivation. Camarate is therefore a sibling of CORNIFESTO, MALVASIA PRETA, JUAN GARC\u00cdA and CASTEL\u00c3O (see Cayetana Blanca for the pedigree diagram). Camarate was used to breed \u00c1GUA SANTA.',
    ],
    'where its grown': [
      'Camarate makes relatively full-bodied but soft wines with raspberry fruit flavours and smooth tannins, good for blending or for early drinking. The variety\u2019s heartland, Bairrada in Portugal, where it is the second most planted red variety (796 ha/1,967 acres in 2010), produces the best examples but it has also adapted well to the Lisboa, Tejo and D\u00e3o regions, where it is authorized in a number of appellations such as Alenquer and Torres Vedras. Recommended producers in Bairrada include Campolargo and Caves de S\u00e3o Jo\u00e3o.',
    ],
  },
  {
    name: 'CAMPBELL EARLY',
    synonyms: ['Campbell', 'Campbell\u2019s Early', 'Island Belle'],
    description:
      'North American hybrid now more widely grown in Asia, often for the table.',
    'origin and parentage': [
      'A complex Vitis labrusca and Vitis vinifera hybrid obtained in the early 1890s by G W Campbell in Delaware, Ohio, by crossing Moore Early with (Belvidere \u00d7 MUSCAT OF HAMBURG), where Moore Early and Belvidere are both CONCORD seedlings used as table grapes (Munson 1909).',
    ],
    'where its grown': [
      'The fruit tastes rather like CONCORD but is sweeter and has much less of the foxy flavour associated with Vitis labrusca parentage. Although the variety is now rarely found in the US, it is popular in Asia. In Korea, for example, there were 15,419 ha (38,101 acres) in 1997 and in 2008 the variety represented 75% of the the total vineyard area, although almost all of this fruit is used for purposes other than winemaking. Japan had around 60 ha (148 acres) for wine in 2009, mainly in Hokkaido, but the variety is also planted more extensively for the table than for winemaking.',
    ],
  },
  {
    name: 'CANADA MUSCAT',
    synonyms: ['NY 17806'],
    description: 'Grapey hybrid just about surviving in Australia.',
    'origin and parentage': [
      'A complex Vitis vinifera and Vitis labrusca hybrid made in 1928 by Richard Wellington at New York State\u2019s Cornell University by crossing MUSCAT OF HAMBURG with Hubbard (see BRIANNA for the complete pedigree of Hubbard). Canada Muscat was selected from among twelve seedlings in 1936 but not officially introduced until 1961.',
    ],
    'where its grown': [
      'Canada Muscat was once planted to a very limited extent in Ontario, Canada (hence part of the name), but it now appears to survive \u2013 by the skin of its teeth \u2013 in Australia\u2019s McLaren Vale, in the state of South Australia, where Patritti makes a blend of Canada Muscat and MUSCAT BLANC \u00c0 PETITS GRAINS.',
    ],
  },
  {
    name: 'CANAIOLO NERO',
    synonyms: ['Cannaiola di Marta', 'Cannaiola Macchie di Marta'],
    description:
      'Soft, full-bodied, central Italian red used mainly to complement Sangiovese.',
    'origin and parentage': [
      'Canaiolo Nero was already mentioned in 1303 as Canajuola in Pietro de Crescenzi\u2019s Opus Commodorum Ruralium. It was then praised as one of the best red grapes in Toscana by the Florentine agronomist Soderini (1600), under the name Canaiuola. Canaiolo Blanco (DRUPEGGIO) is not a colour mutation of Canaiolo Nero (Storchi et al. 2011). Canaiolo Rosa, on the other hand, is a colour mutation of Canaiolo Nero, isolated during a clonal selection programme in the province of Lucca.',
    ],
    'where its grown': [
      'Canaiolo was more popular in central Italy than SANGIOVESE until the eighteenth century. Today, it is one of the major components of the 20% of other local red varieties that may be blended with Sangiovese in Chianti and Chianti Classico DOCG, the velvety texture and full body of the best examples rounding out Sangiovese\u2019s firm tannins. Small percentages are allowed in various other Tuscan and central Italian DOCs and a varietal wine is possible in the Tuscan DOC Pietraviva and in Umbria\u2019s Rosso Orvietano, for example. Canaiolo is also grown to a limited extent in Lazio, Liguria, Sardegna and the Marche but in 2000 total plantings in Italy were 2,760 ha (6,820 acres) and declining.',
      'Good-quality Canaiolo is produced at the two Ricasoli properties of Castello di Brolio and Castello di Cacchiano in Gaiole in Chianti, in Barbarino Val d\u2019Elsa (where the Castello della Paneretta and neighbour Isole e Olena both use Canaiolo in their Chianti Classico), and in the Vino Nobile di Montepulciano production zone.',
      'Recently, a few producers have begun making wines from 100% Canaiolo, such as Le Poggette\u2019s Canaiolo Umbria IGT, Castelvecchio\u2019s Numero Otto Toscano Rosso IGT, Possa\u2019s \u00dc Neigru Rosso di Possaitara from Cinque Terre in Liguria, and Bibi Graetz\u2019s rare Canaiolo di Testamatta.',
    ],
  },
  {
    name: 'CANARI NOIR',
    synonyms: ['Batista', 'Canaril', 'Canarill', 'Carcass\u00e8s', 'Luverdon'],
    description: 'Hanging on by a thread in the Ari\u00e8ge.',
    'origin and parentage': [
      'Canari has today almost disappeared from its original home in the Ari\u00e8ge and the Haute-Garonne in the French Pyr\u00e9n\u00e9es. There are two reasons to think that it was more widespread in the past. First because Luverdon, a variety found in Italy in the high-altitude vineyards of the Val di Susa and the Val Chisone in Piemonte, where it is also erroneously called Gamay Luverdon, was unexpectedly shown by DNA profiling to be identical to Canari Noir (Schneider et al. 2001). Secondly because the DNA profiles of Canari Noir and the Spanish variety Batista (Mart\u00edn et al. 2003) turned out to be identical (Vouillamoz).',
      'The colour mutations Canari Blanc and Canari Gris have been observed in the Haute-Garonne (Galet 1990).',
    ],
    'where its grown': [
      'This is one of the nearly extinct varieties planted by Les Vignerons Ari\u00e9geois in Mont\u00e9gut-Plantaurel, France (Carbonneau 2005). They aim to multiply these and to produce a commercial wine if their experiments yield encouraging results.',
    ],
  },
  {
    name: 'CA\u00d1OCAZO',
    synonyms: ['False Pedro', 'Hardskin Pedro', 'Mollar Blanco', 'Pedro'],
    description: 'Virtually extinct variety once popular in sherry country.',
    'origin and parentage': [
      'Ca\u00f1ocazo is an old variety from Jerez de la Frontera in Andaluc\u00eda, south-west Spain, where it was mentioned by the Spanish ampelographer Sim\u00f3n de Rojas Clemente y Rubio (1807) as growing in Jerez, Sanl\u00facar de Barrameda and Trebujena. Although it is also called Mollar Blanco in Sanl\u00facar de Barrameda, Ca\u00f1ocazo is not related to the dark-skinned NEGRAMOLL, for which Mollar is a widespread synonym. Further confusion is caused by the use of the name False Pedro in South Africa for what was imported as PEDRO XIM\u00c9NEZ but turned out to be the Portuguese variety GALEGO DOURADO, which is now virtually extinct (Kerridge and Antcliff 1999).',
    ],
    'where its grown': [
      'In the nineteenth century, before phylloxera devastated the Jerez region, Ca\u00f1ocazo was grown there alongside PALOMINO FINO and PEDRO XIM\u00c9NEZ, notably in Trebujena but also in Jerez and Sanl\u00facar de Barrameda. The grapes were used mainly for drying for use in sweet wines, although the sugar level in the berries was generally quite moderate. It has virtually disappeared from Spain apart from a few vines in Andaluc\u00eda but is not authorized for quality wines. Its susceptibility to disease has been a major cause of its fall from favour. However, the Rancho del Merced, the experimental centre for ampelography in Jerez, has some experimental plantings of this and other obscure varieties to assess their suitability for distillation.',
      'The name False Pedro was adopted for this variety in Australia to avoid confusion with PEDRO XIM\u00c9NEZ, with Hardskin Pedro as a colloquial alternative (Kerridge and Antcliff 1999). Wines are generally low in acidity.',
    ],
  },
  {
    name: 'CARACOL',
    synonyms: ['Olho de Pargo', 'Uva das Eiras'],
    description:
      'Very minor variety found on the Portuguese island of Porto Santo.',
    'origin and parentage': [
      'This obscure variety was supposedly introduced to Porto Santo, an island in Portugal\u2019s Madeira archipelago, under the name Olho de Pargo (meaning \u2018eye of snapper\u2019), in the 1930s by a Portuguese emigrant to South Africa. He gave some vines to his friend Jo\u00e3o da Silva, who planted them in Eiras, hence the first local name, Uva das Eiras. It was later renamed Caracol (meaning \u2018snail\u2019), da Silva\u2019s nickname.',
      'DNA profiling of Caracol is needed to determine if this is a distinct variety.',
    ],
    'where its grown': [
      'Caracol is used both for the table and for wine on the island of Porto Santo, Portugal, where there were 9 ha (22 acres) in 2010. The variety is also authorized for Madeira wines.',
    ],
  },
  {
    name: 'CARDINAL',
    synonyms: [
      'Apostoliatiko',
      'Francesa',
      'G 10-30',
      'Karaburnu Rannii',
      'Kardinal',
      'Rannii Carabournu',
    ],
    description:
      'Red-skinned North American table grape occasionally used to make wine in parts of Asia.',
    'origin and parentage': [
      'A cross obtained in 1939 by E Snyder and F Harmon at the Fresno Horticultural Field Station in California, initially said to be a crossing of Ahmeur Bou Ahmeur (or Flame Tokay) \u00d7 Alphonse Lavall\u00e9e (or Ribier, although unrelated to the French Ribier Noir) but recently corrected after DNA parentage analysis to Sz\u0151l\u0151skertek Kir\u00e1lyn\u0151je (also known as K\u00f6nigin der Weing\u00e4rten) \u00d7 Alphonse Lavall\u00e9e (Akkak et al. 2007; Iba\u00f1ez, Vargas et al. 2009), where Sz\u0151l\u0151skertek Kir\u00e1lyn\u0151je is a table grape obtained in Hungary in 1916 by crossing Afus Ali (a Lebanese table grape also known as Dattier de Beyrouth) \u00d7 CSABA GY\u00d6NGYE (aka Perle von Csaba), and Alphonse Lavall\u00e9e is another table grape obtained in France by crossing Kharistvala Kolkhuri \u00d7 MUSCAT OF HAMBURG.',
      'Cardinal was used to breed BLANC DU BOIS, CORREDERA and ZHEMCHUZHINA OSKHI.',
    ],
    'where its grown': [
      'Cardinal is widely planted around the world as a table grape and was harnessed for winemaking by Allied Domecq when they tried to establish a winery in northern Vietnam.',
    ],
  },
  {
    name: 'CARGARELLO',
    synonyms: ['Cargar\u00e8l'],
    description:
      'White variety on the verge of extinction in the Rimini area of northern Italy.',
    'where its grown': [
      'Cargarello used to be cultivated mainly in the Valle del Conca near Rimini on the east coast of Italy. Today only a few vines survive in the area of San Giovanni in Marignano (near Rimini) and San Teodoro di Mondaino (near Montefeltro), eg in the vineyards of the Rocche Malatestiane co-operative in Rimini. Some Cargarello is also found in the Republic of San Marino thanks to the Consorzio Vini Tipici di San Marino.',
    ],
  },
  {
    name: 'CARMEN\u00c8RE',
    synonyms: [
      'Bordo',
      'Cabernelle',
      'Cabernet Gernicht',
      'Cabernet Gernischet',
      'Cabernet Gernischt',
      'Cabernet Shelongzhu',
      'Carbonet',
      'Carbouet',
      'Caremenelle',
      'Carm\u00e9n\u00e8gre',
      'Carm\u00e9n\u00e8re',
      'Carmeneyre',
      'Grosse Vidure',
    ],
    description:
      'Produces deeply coloured, sometimes herbaceous wines, in Chile far more than in its native Bordeaux.',
    'origin and parentage': [
      'Carmen\u00e8re is an old variety from the Gironde, where it was first mentioned in 1783\u20134 in Bergerac (Dordogne) under the name Carmeynere, together with CABERNET FRANC (Carmenet): \u2018le Carmenet, que l\u2019on nomme de m\u00eame en M\u00e9doc. La Carmeneyre. L\u2019un et l\u2019autre de ces deux c\u00e9pages produit peu et principalement la Carmeneyre, mais le vin est tr\u00e8s bon\u2019 (\u2018Carmenet, with the same name in the M\u00e9doc. Carmeneyre. Both these varieties have low yields, in particular Carmeneyre, but the wine is very good\u2019; R\u00e9zeau 1997). Its modern spelling first appeared in Jullien (1816): \u2018le carmenet, la carmen\u00e8re, le malbek et le verdot peuplent seuls les vignobles du M\u00e9doc, qui fournissent les vins rouges de premi\u00e8re qualit\u00e9\u2019 (\u2018carmenet, carmen\u00e8re, malbek and verdot alone constitute the M\u00e9doc vineyards that give top-quality red wines\u2019). Although some have suggested a link with the dark-red colour carmin (carmine), the etymology of Carmen\u00e8re, like that of Carmenet, remains unclear.',
      'DNA parentage analysis has recently established that Carmen\u00e8re is a natural cross between CABERNET FRANC and Gros Cabernet (see the pedigree diagram in the CABERNET SAUVIGNON entry), a very old variety from the Gironde and the Tarn that was often confused with Cabernet Franc (Boursiquot et al. 2009). Gros Cabernet is no longer cultivated but is likely to be a FER \u00d7 HONDARRIBI BELTZA cross, both varieties being of Spanish Basque origin (Boursiquot et al. 2009). Hondarribi Beltza, also called Txakoli or Chacol\u00ed, is in turn a likely progeny of Cabernet Franc (Boursiquot et al. 2009). This means that Carmen\u00e8re and Cabernet Franc have a high level of consanguinity, because Carmen\u00e8re is at the same time a progeny and a great-grandchild of Cabernet Franc. Carmen\u00e8re is also a half-sibling of both Cabernet Sauvignon and MERLOT. All these close relationships explain the frequent confusion between Carmen\u00e8re, Cabernet Franc, Cabernet Sauvignon and Merlot, all belonging to the Carmenet ampelographic group (see p XXVII; Bordenave et al. 2007; Bisson 2009).',
      'In Italy, ampelographic and molecular analyses have established that most of the vines planted under the CABERNET FRANC type called \u2018Italian\u2019 in the north-eastern part of the country were in fact Carmen\u00e8re, while the Cabernet Franc type called \u2018French\u2019 was the true Cabernet Franc (Cal\u00f2, Di Stefano et al. 1991). The same confusion between Cabernet Franc and Carmen\u00e8re occurred in California. In Chile, a few vines thought to be MERLOT were discovered in 1994 to be Carmen\u00e8re, and a subsequent DNA profiling survey revealed that the majority of the \u2018Merlot Noir\u2019 then grown in Chile was actually Carmen\u00e8re (Hinrichsen et al. 2001).',
      'In China, the true identity of Cabernet Gernischt has long been debated, and it has been described as:',
      '\u2013\u00a0\u00a0\u00a0a cross between CABERNET SAUVIGNON and CABERNET FRANC brought to Changyu (or Zhangyu) Winery in China at the time of, or shortly after, the founding of the winery in 1892',
      '\u2013\u00a0\u00a0\u00a0CABERNET FRANC introduced to China by Changyu Winery in 1892, which would be consistent with the name of this variety being a misspelling of Cabernet gemischt, German for \u2018mixed Cabernet\u2019, referring to the initial mixture of imported cuttings (Luo 1999)',
      '\u2013\u00a0\u00a0\u00a0a cross developed in 1931 by Changyu Winery from newly introduced and undisclosed varieties from Europe (Zhengping 2011)',
      '\u2013\u00a0\u00a0\u00a0the French variety Carmen\u00e8re, introduced as \u2018mixed Cabernet\u2019 for the same reasons as above (Freeman 2000; Pszcz\u00f3lkowski 2004).',
      'Some ampelographic and molecular studies in China suggested that Cabernet Gernischt is identical to CABERNET FRANC (Yin et al. 1998; Song et al. 2005), while another recently suggested that it is identical to Carmen\u00e8re (Li et al. 2008). DNA profiling of genuine samples of Cabernet Gernischt from the Changyu Winery definitely shows that it is identical to Carmen\u00e8re (Vouillamoz).',
      'The name Cabernet Gemischt, of which Cabernet Gernischt is said to be a misspelling, is often believed to correspond to an old, extinct European grape variety, possibly an ancestor of CABERNET FRANC. This is not verified in any ampelographic or viticultural text in Europe, where the words \u2018Cabernet, gemischt\u2019 were used only in relation to oenological analyses of wines that were a blend of CABERNET SAUVIGNON and Cabernet Franc. In China, this variety is also widely called Cabernet Shelongzhu, literally \u2018Cabernet snake pearl\u2019, an alternative name chosen by Changyu Winery because it was easier to pronounce, at least for Chinese speakers.',
    ],
    'where its grown': [
      'Carmen\u00e8re was widely cultivated in the M\u00e9doc, south-west France, in the early eighteenth century but largely abandoned in Bordeaux after the phylloxera invasion in the 1870s because of its poor fruit set and consequently unreliable yields: there were just 21 ha (52 acres) in France in 2008. Examples of what remains can be found at classed growth Ch\u00e2teau Clerc Milon in Pauillac, which still has about 0.4 ha (1 acre or around 1% of its vineyard area, like Ch\u00e2teau Belle-Vue of the Haut-M\u00e9doc) and Clos Louie in the C\u00f4tes de Castillon, where there are some pre-phylloxera Carmen\u00e8re vines interplanted with ancient examples of other Bordeaux varieties, in its 125-year-old, ungrafted vineyard. Ch\u00e2teau Brane-Cantenac planted 0.5 ha (1.2 acres) in 2007 and have included some of the fruit in their 2011 grand vin.',
      'In Italy, the census recorded 45 ha (111 acres) in 2000, mostly in the north east, but there is probably a good deal more that goes unrecorded because in many cases growers discovered that what they thought was CABERNET FRANC was in fact Carmen\u00e8re. When the confusion became clear, Ca\u2019del Bosco in Lombardia began to produce a successful varietal wine called Carmenero, classified as a vino da tavola because the variety is not authorized for DOC or IGT varietal wines, though it is a permitted ingredient in many blendedappellation wines such as Garda Cabernet and Monti Lessini. Inama\u2019s Carmenere Pu\u00ec is a blend of Carmen\u00e8re, MERLOT and other local varieties from the Colli Berici but classified as IGT Veneto, and Ca Orologico\u2019s Rel\u00f3gia is mainly Carmen\u00e8re with 20% CABERNET SAUVIGNON.',
      'A few California producers have shown interest in Carmen\u00e8re: in 2009 plantings were 57 acres (23 ha), just slightly higher than in Bordeaux, perhaps partly encouraged by the Meritage Alliance, whose Bordeaux-blend recipe includes Carmen\u00e8re. Guenoc, for example, blend it with the usual suspects CABERNET SAUVIGNON, CABERNET FRANC, PETIT VERDOT and MERLOT. Dover Canyon make a rare varietal wine using grapes from Colbert Vineyard in East Paso Robles. Varietal Carmen\u00e8re is by no means uncommon in the warmth of Washington State, where ripening conditions are not unlike those of Chile.',
      'Black Hills, British Columbia, Canada,\nproduce a single-vineyard Carmen\u00e8re and use it in blends, though official 2008 statistics for the province show less than an acre (0.4 ha) of this variety.',
      'The first Carmen\u00e8re vines were brought to Chile, Carmen\u00e8re\u2019s current power base, in the mid nineteenth century, where it was planted as a field blend, mainly with MERLOT, though growers probably thought they had Merlot exclusively, and thrived thanks to a warmer and drier climate than south-west France, allowing a longer ripening period. The French ampelographer Claude Valat was the first to notice that not all Chilean Merlot was the real McCoy, and in 1994 J-M Boursiquot identified it as Carmen\u00e8re, which was confirmed by DNA analysis in 1997. The Chilean authorities recognized Carmen\u00e8re as an official variety for the country in 1998. Thanks to this identification process, the reported area planted with Carmen\u00e8re has shot up: from zero in 1996 to 7,183 ha (17,750 acres) in 2006, apparently declining a little thereafter to 7,054 ha (17,431 acres) in 2008, although plantings of Merlot also continued to increase after the identification of Carmen\u00e8re and it is hard to be sure at what point the varieties were accurately distinguished in the official statistics.',
      'Much has been written about this confusion between MERLOT and Carmen\u00e8re but Richards (2006) suggests that most growers recognized the difference, referring to the former as Merlot and the latter as Merlot Chileno.',
      'If the grapes are harvested before they are fully ripe, wines have strong capsicum and herbaceous flavours. These turn into red berry and sometimes black pepper or tomato when the berries are just ripe, and then at full maturity blackberry and blueberry with overtones of chocolate and coffee and soy sauce, though the variety also tends to lose acidity at this stage. There are many fine varietal examples in Chile from producers such as Antiyal, Concha y Toro, Err\u00e1zuriz, Montes and Ventisquero. The variety may also make a significantly refreshing if quantitatively minor contribution to many of Chile\u2019s top blends such as Almaviva. De Martino and others are working to find the best possible sites for the variety and their range now includes a single-vineyard wine from the Alto de Piedras vineyard in the Isla de Maipo in the Maipo Valley.',
      'Argentina had 32 ha (79 acres) in 2008, almost all in the Mendoza region.',
      'Brown Brothers produce limited quantities of varietal Carmen\u00e8re in Victoria, Australia.',
      'In China, as Cabernet Gernischt planted there in the late nineteenth century, Carmen\u00e8re wines seem to have the herbaceous flavours often associated with CABERNET FRANC and a texture similar to that of CABERNET SAUVIGNON. It is widely planted in Jiaodong in the province of Shandong and also grown in the southern part of north-east China, northern China and north-west China. Recommended producers of varietal wines include Changyu Wine Group and China Great Wall Wine. There were 1,218 ha (3,010 acres) of Cabernet Gernischt in China in 2009.',
    ],
  },
  {
    name: 'CARRICANTE',
    synonyms: ['Catanese Bianco'],
    description:
      'Potentially very fine, crisp and distinctive Sicilian white variety.',
    'origin and parentage': [
      'Local tradition refers to Viagrande in the province of Catania, on the Italian island of Sicilia, as the cradle of Carricante. Its name probably refers to the variety\u2019s abundant yields (carica means \u2018load\u2019). In 1774, Sestini tells that Etna vintners used to leave Carricante wines on their lees in barrel to induce malolactic fermentation in the spring and thus reduce their naturally high acidity (Giavedoni and Gily 2005). Cipriani et al. (2010) have recently suggested through DNA parentage analysis that Carricante is a possible Montonico Pinto \u00d7 Scacco natural cross, the former a black-berried variety that is not commercially cultivated and the latter a white-berried Forcelese d\u2019Ascoli \u00d7 DINDARELLA cross (Forcelese d\u2019Ascoli is a light-skinned but non-cultivated variety). This parentage showed some DNA discrepancies and needs further investigation.',
    ],
    'where its grown': [
      'Carricante is cultivated on the eastern slopes of Etna on the eastern side of Sicilia, Italy, mainly in the province of Catania (Viagrande, Trecastagni, Zafferana Etnea and Milo). In other parts of Sicilia, Carricante is sometimes erroneously called Catarratto. It is often blended with other Sicilian white varieties such as CATARRATTO BIANCO, INZOLIA and MINELLA BIANCA, though there must be at least 60% Carricante in the blend for Etna Bianco and 80% for Etna Bianco Superiore (for which the grapes can come only from Milo). It may occasionally be used to soften red varieties such as NERELLO MASCALESE. However, 100% Carricante wines can be impressive and delicious, showing aromas of orange and grapefruit, orange flowers, aniseed and white fruits, with fresh acidity and notable minerality within a sinuous framework. Even in young wines there may be a lightly honeyed note. Total Italian plantings in 2000 were 264 ha (652 acres) and Sicilian plantings totalled 101 ha (250 acres) by 2008 but the variety is increasingly appreciated.',
      'Barone di Villagrande\u2019s organically grown Etna Bianco Superiore is one example of a varietal Carricante. Benanti make two fine Etna Biancos: the less expensive Bianco di Casele and the more concentrated, minerally and ageworthy old-vine Pietramarina Superiore. Both display those characteristic orange and aniseed notes but with a herbal, even cedary, edge and a modest 12\u201312.5% alcohol.',
    ],
  },
  {
    name: 'CASCAROLO BIANCO',
    synonyms: ['Cascarala', 'Cascarecul', 'Cascarelbo'],
    description: 'Ancient and barely surviving white variety from Piemonte.',
    'origin and parentage': [
      'Cascarolo Bianco is a very old Piemontese variety cited by Croce in the Torino hills as early as 1606. Its name probably comes from its susceptibility to coulure, the Italian word cascolare meaning \u2018to fall\u2019. It has been suggested that the non-cultivated Feh\u00e9r Goh\u00e9r (or Augster Weisser) from Hungary could be a synonym for Cascarolo Bianco (Goethe 1887) but DNA profiling strongly rejects this hypothesis (Vouillamoz).',
      'Recent DNA studies provided evidence that Cascarolo Bianco is a likely progeny of R\u00c8ZE, a very old variety from the Swiss Alps (Vouillamoz, Schneider et al. 2007), and a close relationship with ERBALUCE is suspected (Vouillamoz).',
    ],
    'where its grown': [
      'Cascarolo Bianco used to be widespread in Piemonte (around Pinerolo and Chieri, in Casale Monferrato and in Moncalvo), north-west Italy, where its wine was highly esteemed. Today it has almost disappeared, scattered throughout old vineyards, especially at the foot of the Alps.',
    ],
  },
  {
    name: 'CASCULHO',
    synonyms: ['Cascudo'],
    description: 'Minor northern Portuguese variety generally found in blends.',
    'origin and parentage': [
      'Casculho probably originates in the Tr\u00e1s-os-Montes region in northern Portugal. Although Casculho is also a synonym for CAMARATE from the Bairrada region, they have distinct DNA profiles (Veloso et al. 2010) and are therefore not identical.',
    ],
    'where its grown': [
      'Casculho is authorized for Douro table wines as well as for regional wines from the Pen\u00ednsula de Set\u00fabal but is rarely found as a varietal. Quinta do Carrenho\u2019s Dona Berta Vinha Centen\u00e1ria, made from an old-vine field blend in the Douro, is an exception. There were 730 ha (1,804 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'CASETTA',
    synonyms: ['Lambrusco a Foglia Tonda', 'Lambrusco Casetta', 'Maranela'],
    description:
      'Northern Italian dark-skinned variety snatched from the jaws of extinction and making highly structured and distinctive wines.',
    'origin and parentage': [
      'Casetta is local to the Terra dei Forti, a valley in the Vallagarina region south of Trento in the far north of Italy. The name Casetta is most likely to come not from its simple Italian meaning (\u2018small house\u2019) but from the name of a family in Marani di Ala, which also explains the variety\u2019s synonym Maranela.',
      'Casetta is often confused with ENANTIO, another autochthonous variety from Vallagarina. Indeed, Enantio is a recent name given to the traditional Lambrusco a Foglia Frastagliata (meaning \u2018toothed leaf\u2019) and, in the local dialect, Casetta is often called Lambrusco a Foglia Tonda, while Enantio is called Foja Tonda (meaning \u2018round leaf\u2019). However, Enantio is morphologically quite different from Casetta (Cal\u00f2 et al. 2006) and comparison of their DNA profiles therefore rules out any parent\u2013offspring relationship (Vouillamoz).',
      'Although it is sometimes called Lambrusco Casetta or Lambrusco a Foglia Tonda, Casetta is genetically distinct from all other Lambruscos in Italy.',
    ],
    'where its grown': [
      'Casetta was once widespread in Trentino and the Veneto in Italy, mainly in Val Cipriana and Val San Valentino (in particular in Marani and in San Margherita near Ala). Due to its susceptibility to fungal diseases, Casetta was virtually abandoned in the 1960s but was rescued from near extinction by the northern Italian producer Albino Armani, who discovered ungrafted vines that were more than 100 years old and began new plantings in the 1990s with the help of the Istituto Agrario di San Michele all\u2019Adige. Today, Casetta\u2019s vineyards cover a mere 12 ha (30 acres), a mix of new and old plantings, often ungrafted, in the Vallagarina region.',
      'Since 2006, Casetta has been authorized in the Terra dei Forti DOC, often blended with other local varieties and occasionally made as a varietal wine. Casetta wine is rich in anthocyanins, tannins, acidity and alcohol, and needs time to gain balance. Typical aromas include dried plums, maraschino cherry, cinnamon, tobacco and musk.',
      'Albino Armani was the first to produce a 100% Casetta wine, called Foja Tonda, in 1994, and is still the most important producer of this variety.',
    ],
  },
  {
    name: 'CASTAGNARA',
    synonyms: ['Santa Maria Nera', 'Sarnese'],
    description: 'Low-acid Italian red wine variety improved by blending.',
    'origin and parentage': [
      'DNA analysis at the University of Portici did not find any genetic relationship with other Campanian varieties (Giavedoni and Gily 2005).',
    ],
    'where its grown': [
      'Castagnara had a good reputation in the nineteenth century and was widespread in Italy\u2019s Campanian provinces of Napoli and Avellino. Today it is scantily cultivated in the province of Napoli, especially in Lettere, Casola, Gragnano and Sant\u2019Antonio Abate. It is authorized in the Penisola Sorrentina Rosso DOC, where it is usually blended with PIEDIROSSO and/or SCIASCINOSO and/or AGLIANICO. Wines are notably low in acidity.',
    ],
  },
  {
    name: 'CASTEL\u00c3O',
    synonyms: [
      'Bastardo Castico',
      'Bastardo Espanhol',
      'Castel\u00e3o Franc\u00eas',
      'Castellao Portugues',
      'Castico',
      'Jo\u00e3o Santar\u00e9m',
      'Jo\u00e3o de Santar\u00e9m',
      'Periquita',
      'Piriquita',
      'Piriquito',
    ],
    description:
      'Portugal\u2019s most common variety: hardy, adaptable and widely grown under many different aliases.',
    'origin and parentage': [
      'Castel\u00e3o is a very old Portuguese variety first mentioned in the Douro in 1531 by Rui Fernandes (1531\u20132), in the \u2018Descrip\u00e7\u00e3o do terreno em roda da cidade de Lamego duas leguas\u2019 under the name Catel\u00e3o, which is undoubtedly a misspelling of Castel\u00e3o. Recent DNA parentage analyses have revealed that Castel\u00e3o is a natural cross between CAYETANA BLANCA, widespread throughout the south and centre of the Iberian peninsula, and ALFROCHEIRO from the south or centre of Portugal (Zinelabidine et al. 2012). Castel\u00e3o is therefore a sibling of CORNIFESTO, MALVASIA PRETA, JUAN GARC\u00cdA and CAMARATE (see Cayetana Blanca for the pedigree diagram), and also possibly of JAMPAL (Myles et al. 2011).',
      'In some regions, Castel\u00e3o used to be called Trincadeira, which is a misleading synonym because it is the official name of a distinct black variety (see TRINCADEIRA) and is also used for several other black- or white-berried varieties (eg TAMAREZ, Trincadeiro Branco). Similarly, the synonyms Bastardo Castico and Bastardo Espanhol could create confusion with TROUSSEAU (known as Bastardo in D\u00e3o and the Douro) and should be avoided. The origin of the synonym Periquita (literally, \u2018parakeet\u2019) is unknown.',
      'Castel\u00e3o was used to breed numerous varieties, including \u00c1GUA SANTA and possibly AGRON\u00d3MICA (see that entry).',
    ],
    'where its grown': [
      'Castel\u00e3o is the most widely planted variety in Portugal: 18,550 ha (45,838 acres) in 2010, down from around 20,000 ha (49,420 acres) in 2004. Its popularity is due in large measure to its adaptability to a wide range of environments, from the arid south to the damper, Atlantic-influenced west, and to its general hardiness. Particularly dominant in the south and centre of the country \u2013 Tejo, Lisboa, Set\u00fabal and Alentejo \u2013 it is also grown further north in the Douro. This versatility extends to winemaking since it is used not only for reds, both fortified and not, but also for ros\u00e9 and as a base for fizz. Wines vary in style, even within the same region. In the Pen\u00ednsula de Set\u00fabal, south of Lisboa, for example, in the sandy soils of Poceir\u00e3o and Fernando P\u00f3, in good years Castel\u00e3o produces respectable yields of around 50 hl/ha (c.3 tonnes/acre) and wines that are deeply coloured, aromatic, full-bodied, fleshy, ageworthy, well suited to oak maturation, and tasting of red fruits and fruits of the forest, whereas vines planted in the calcareous-clay soils of the Arr\u00e1bida mountains have lower yields and produce much lighter, paler wines with overly high acidity and little capacity to age in bottle. Recommended producers include Casa Ermelinda Freitas and Bacalh\u00f4a Vinhos de Portugal in the Pen\u00ednsula de Set\u00fabal plus Adega Cooperativa de Peg\u00f5es and Casa Agr\u00edcola Hor\u00e1cio Sim\u00f5es in Palmela. Jose Maria da Fonseca\u2019s brand Periquita, made in Terras do Sado, combines a majority of Castel\u00e3o with TOURIGA NACIONAL and TOURIGA FRANCA to impressive and ageworthy effect.',
    ],
  },
  {
    name: 'CASTETS',
    synonyms: ['Engrunat', 'Machouquet', 'Nicouleau'],
    description:
      'Very minor western Pyreneen variety clinging on in France; a parent in Slovakia.',
    'origin and parentage': [
      'According to Cazeaux-Cazalet (1901), this variety was found in a wood in the region of Saint-Macaire in the Gironde, south-west France, by a certain M Nicouleau around 1870. Later on, it was propagated by a M Castets from Saint-Andr\u00e9-du-Bois (Gironde), most likely between 1870 and 1874 because the earliest mention of the name Castets for this variety appeared in 1874 in Saint-Pierre d\u2019Aurillac (Gironde). However, Castets-en-Dorthe is also the name of a village very close to Saint-Macaire, so it is quite possible that the variety was named after the village where it was found.',
      'From 1882, Castets was recognized for its resistance to downy mildew and was widely propagated by Dr Guilbert across all of Aquitaine and to the centre and south of France. According to Lavignac (2001), Castets belongs to the Carmenet ampelographic group (see p XXVII), which also includes FER, and explains why they have often been confused, and locates its birthplace in the Pyr\u00e9n\u00e9es-Atlantiques rather than the Gironde, where it has virtually disappeared.',
      'Castets was crossed with ABOURIOU and other varieties in the 1970s to breed several new varieties in Slovakia, of which HRON, NITRANKA, RIMAVA, RUDAVA, TORYSA and V\u00c1H are currently commercially cultivated.',
    ],
    'where its grown': [
      'Wines are deeply coloured, relatively high in alcohol and able to age in bottle, though the acidity may be only moderate. The variety is recommended in the Aude and Cantal d\u00e9partements of France and specifically authorized for inclusion as a minor optional ingredient in the red and ros\u00e9 wines of Vins d\u2019Estaing north of Rodez, blended with varieties such as FER, GAMAY NOIR, etc., and in the Palette appellation around Aix-en-Provence (with CINSAUT, Grenache/GARNACHA, Mourv\u00e8dre/MONASTRELL, etc.) but in 2008 there was less than a hectare remaining in France. Varietal wines appear not to exist but Ch\u00e2teau Simone include Castets in their red Palette.',
      'Castets has recently been identified in an old vineyard in the Russian River Valley, California.',
    ],
  },
  {
    name: 'CASTIGLIONE',
    synonyms: [
      'Castigliono',
      'Mantonico Nero',
      'Marchesana',
      'Zagarese',
      'Zagarolese',
    ],
    description:
      'Dark-skinned Italian variety almost exclusive to Calabria and usually blended.',
    'origin and parentage': [
      'Said to be a recent introduction to Calabria (Cal\u00f2 et al. 2006). A DNA study showed that the Castiglione of Calabria also exists in Campania, near Lago d\u2019Averno, where it is simply called Calabrese (Costantini et al. 2005), a name confusingly used as a synonym for several different varieties. It is sometimes locally confused with MAGLIOCCO CANINO (Schneider, Raimondi et al. 2009).',
    ],
    'where its grown': [
      'Cultivated mainly in the provinces of Reggio Calabria and Cosenza in Calabria, southern Italy. Rarely made as varietal and usually blended with other varieties, such as PRUNESTA, Castiglione is authorized in the red and ros\u00e9 versions of Bivongi DOC, in which it is blended with varieties such as GAGLIOPPO, the obscure GRECO NERO, NOCERA, NERO D\u2019AVOLA and Prunesta. The Italian census of 2000 records a total of 93 ha (230 acres).',
    ],
  },
  {
    name: 'CATALANESCA',
    synonyms: ['Catalana', 'Uva Catalana'],
    description:
      'Very old, high-acid white Italian variety suited to both table and glass and exclusive to Campania.',
    'origin and parentage': [
      'Although Catalanesca is listed as a table grape in the Italian agricultural census, its ability to make wine in the Vesuvio area of Campania in southern Italy has been mentioned by ampelographers since the sixteenth century. The name of this variety suggests a Spanish origin, and local tradition says it was introduced in 1450 on the slopes of Monte Somma, between Somma Vesuviana and Terzigno, by Alfonso, king of Arag\u00f3n. However, the DNA profile of Catalanesca does not match any known Spanish variety but suggests that it could be genetically close to CASAVECCHIA, Summariello and BARBERA DEL SANNIO (Costantini et al. 2005). That being said, all those varieties appear to be genetically completely separate from other Campanian cultivars, suggesting that they might represent recent introductions.',
    ],
    'where its grown': [
      'Catalanesca is recommended in Campania and authorized on Sardegna, Italy. Since October 2006, Catalanesca has been officially included among the wine grapes of Campania but is not yet allowed for IGT wines.',
      'The best wines usually come from the north-western slopes of Vesuvio (in communes such as Somma Vesuviana, Sant\u2019Anastasia, Ottaviano, San Sebastiano, Massa di Somma and Pollena Trocchia). Dry-tasting Catalanesca wines usually have some residual sugar to balance the marked acidity, and offer aromas of acacia, apricot and honey. Varietal wines are produced by Casa Barone and Sorrentino, for example. The 2000 census notes total plantings of 99 ha (245 acres).',
    ],
  },
  {
    name: 'CATANESE NERO',
    synonyms: ['Vesparola'],
    description: 'Minor Sicilian red variety.',
    'origin and parentage': [
      'The name suggests that this variety comes from the province of Catania, around Etna in north-east Sicilia.',
    ],
    'where its grown': [
      'Although it may have originated from the north-eastern part of Sicilia, Italy, there is no Catanese Nero in this area today and the variety is mainly cultivated in the north-western part of Sicilia, in the provinces of Palermo, Trapani and Agrigento. It is always blended with other varieties, mainly for ros\u00e9, and is not authorized for any DOC wines. There were 80 ha (198 acres) in 2000.',
    ],
  },
  {
    name: 'CATARRATTO BIANCO',
    synonyms: [
      'Catarratteddu',
      'Catarratto Bertolaro',
      'Catarratto Bianco Comune',
      'Catarratto Bianco Lucido',
      'Catarratto Bianco Lucido Serrato',
      'Catarratto Corteddaro',
      'Catarratto Latino',
      'Catarrattu Lu Nostrum.',
    ],
    description:
      'Widely planted and variously named Sicilian white with potential for quality in the right hands.',
    'origin and parentage': [
      'As early as 1696, Catarratto Bianco was described as a Sicilian wine grape in Cupani\u2019s Hortus catholicus. A distinction is usually made between two types: Catarratto Bianco Comune and Catarratto Bianco Lucido, the former thought to derive from the latter. These two types are often considered to be distinct varieties (as in the vineyard statistics below) but a recent ampelographic study provided evidence that they are both simply clones of the same variety (Di Vecchi Staraz, This et al. 2007), which is why it is best to use the name Catarratto Bianco for this variety. In addition, outside the typical CARRICANTE cultivation area, Carricante is the name wine growers in Etna often give their Catarratto Bianco.',
      'Like most ancient varieties, several different types of Catarratto Bianco have been described, often with local names such as Bagascedda, Catarratto Ammantiddatu, Fimminedda, Mattu, etc. Because of this biodiversity, some say that Catarratto Bianco has a polyclonal origin, ie originates from several grape seeds. However, since every grape seed gives birth to a distinct variety, it seems most likely that Catarratto Bianco vineyards are interplanted with other, unidentified varieties. DNA studies could easily verify whether they are clonal variations or distinct varieties (most probably progenies of self-fertilized Catarratto Bianco).',
      'Recent parentage analysis (Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008) unexpectedly revealed that GRILLO, another important Sicilian white grape, is most likely the result of a natural cross between Catarratto Bianco and MUSCAT OF ALEXANDRIA, and that Catarratto Bianco most likely has a parent\u2013offspring relationship with GARGANEGA, one of the most ancient and widespread white grapes in Italy. Since Garganega also has parent\u2013offspring relationships with eight other varieties (ALBANA, DORONA DI VENEZIA, MALVASIA BIANCA DI CANDIA, MARZEMINA BIANCA, MONTONICO BIANCO, MOSTOSA, SUSUMANIELLO and TREBBIANO TOSCANO), Catarratto Bianco is either their half-sibling or their grandparent (see Garganega for an illustrated pedigree).',
    ],
    'where its grown': [
      'Catarratto Bianco is cultivated all over Sicilia, Italy, but mainly in the provinces of Trapani, Palermo and Agrigento. It is the island\u2019s most widely planted variety and the second most planted variety in all of Italy. The Italian agricultural census distinguishes between Comune and Lucido (see above), recording total plantings in 2000 of 43,247 ha (106,866 acres) for the former and 7,548 ha (18,652 acres) for the latter, even though Lucido seems to have the potential to make higher-quality wine. In Sicilia in 2008 there were 38,079 ha (94,095 acres) of Comune and 3,543 ha (8,755 acres) of Lucido.',
      'Much of the wine made from Catarratto Bianco is very ordinary and surplus to requirements: in the past it went into the production of sweet, fortified marsala but today it is more likely to be compulsorily distilled or turned into grape concentrate to boost the sugar levels of grape juice in cooler parts of Europe.',
      'But Catarratto Bianco can make more interesting wine. It is authorized in quite a few DOCs, as a varietal or as part of a blend in Alcamo, Contea di Sclafani, Erice, Monreale, Salaparuta and Santa Margherita di Belice and in yet more as part of a blend. And some of the best examples are IGT wines made by those who have shown the variety greater respect than it is locally accustomed to thanks to its profusion. At its best, it has bright aromas of citrus and herbs with both body and freshness and a minerally aftertaste. It sometimes has a nutty flavour and shows some resemblance to VIOGNIER when fully ripe. Recommended producers include Calatrasi, who make a barrel-aged version, Feudo Montoni and Porta del Vento.',
      'California had 197 acres (80 ha) in 2008, down from 388 acres (157 ha) in 2007.',
    ],
  },
  {
    name: 'CATAWBA',
    synonyms: [
      'Arkansas',
      'Catawba Rosa',
      'Cherokee',
      'Francher',
      'Lincoln',
      'Mammoth Catawba',
      'Meads Seedling',
      'Mecleron',
      'Merceron',
      'Michigan',
      'Munipale Red',
      'Omega',
      'Rose of Tennessee',
      'Saratoga',
      'Singleton',
      'Tekomah',
      'Tokay',
    ],
    description:
      'Historically important American or part-American variety that was very popular in the nineteenth century but ripens inconveniently late and is now in decline.',
    'origin and parentage': [
      'The much-debated history of Catawba was cleared up by Mosher (1853), Bailey (1906) and Sondley (1918). In 1802, the recently settled Samuel Murray (1739\u20131817), who was the owner of an inn fronting the Howard Gap road on Cane Creek, about half a mile away from what later became Fletcher near Ashville, Henderson County, North Carolina, had found several grapevines growing wild in the woods near the summit of Black Ridge, just above his inn. One of those vines developed particularly well after the trees were cut down and Murray planted it next to his farm. In 1807, General Davy, a Senator, transplanted some of these vines to his residence on the Catawba River in Rocky Mount in North Carolina, not far from Murray\u2019s home. Some time between 1807 and 1816, Davy propagated the variety in Washington DC and Maryland and christened it Catawba after the river of that name. The first ever wine was made in 1822 in Georgetown, Washington DC, by Major John Adlum. After a few years, Adlum sent cuttings to Nicholas Longworth of Cincinnati, who developed and popularized Catawba\u2019s cultivation in the west, using it to make dry, sweet or sparkling wines.',
      'While the historical origin of Catawba is more or less clear, its genetic origin is not. Three hypotheses have been proposed (Galet 2000):',
      '\u2013\u00a0\u00a0\u00a0Catawba is a pure, wild Vitis labrusca native variety',
      '\u2013\u00a0\u00a0\u00a0Catawba is a Vitis labrusca \u00d7 Vitis aestivalis natural hybrid',
      '\u2013\u00a0\u00a0\u00a0Catawba is a natural seedling produced by a cross between a native Vitis labrusca vine and an undetermined Vitis vinifera subsp. vinifera variety that had previously been imported to the United States. In this case, it is assumed that vinifera pollen from neighbouring vineyards \u2013 carried by the wind or insects \u2013 pollinated a wild labrusca somewhere in the area, and that the berries of this vine were eaten by birds who deposited the seeds on the Black Ridge hill.',
      'The results of ampelographic studies on the question of Catawba\u2019s pedigree are contradictory, and these hypotheses have unfortunately not yet been tested by genetic analysis.',
    ],
    'where its grown': [
      'Catawba was one of the first significant varieties to be planted in North America, even before CONCORD, and was very popular in the nineteenth century \u2013 in 1865, for example, 20,000 bottles of sparkling Catawba were made at the Pleasant Valley wine company, the oldest winery in the Finger Lakes region in New York State. Although it is now in decline, due in large part to its unsuitability for high-quality dry wines, with limited plantings in states such as Pennsylvania, Missouri, Indiana, Iowa, Michigan and Illinois, as well as in Ontario, Canada, it is still widely planted in New York State, for juice as well as for wine, with a total of 1,291 acres (522 ha) in 2006. Wine styles range from dry to very sweet (including icewine), still to sparkling, and pink to white, typically with some foxy labrusca flavours, a certain spiciness and high acidity. Lakewood in New York State produce a good example.',
    ],
  },
  {
    name: 'CAVRARA',
    synonyms: [
      'Bassanese dal Peduncolo Rosso',
      'Caprara',
      'Cavarada',
      'Cavarara',
    ],
    description: 'Virtually extinct red of the Veneto.',
    'origin and parentage': [
      'Cavrara was mentioned in the Veneto as early as 1754 (Giavedoni and Gily 2005) but was more or less replaced by other varieties during the nineteenth and twentieth centuries.',
    ],
    'where its grown': [
      'Used to be widespread in the province of Vicenza, mainly in Colli Berici in north-eastern Italy. Cavrara was considered to be extinct by the mid twentieth century but since then it has been rediscovered in several old vineyards in the Veneto. Always blended.',
    ],
  },
  {
    name: '\u00c7AVU\u015e',
    synonyms: [
      '\u010cau\u0161 Beli',
      '\u010cau\u0161 Bijeli',
      'Ceaus Alb',
      'Chaouch',
      'Damascenka',
      'Feher T\u00f6ksz\u00f6l\u00f6',
      'Panse de Constantinople',
      'Parc de Versailles',
      'Tchaouch',
      'Tsaousi',
      'Tsaoussi',
      'Turceasca',
    ],
    description:
      'Eastern table grape showing potential for citrus-flavoured wines in Turkey and Greece.',
    'origin and parentage': [
      '\u00c7avu\u015f is a widespread variety known under many names in the Mediterranean and the Near East, where it is used mostly for table grapes and only sometimes to make wine. According to Galet (2000), the origins of this variety are controversial, but the most plausible hypothesis is that a Turkish sergeant (\u00e7avu\u015f in Turkish) brought it back from Ta\u00eff near Mecca, in the seventeenth century, to present it to the Sultan, who had the vines multiplied and propagated. This hypothesis is backed up by the thirty-seven vines of \u00c7avu\u015f (under the spelling Tchaouch) that were sent by Sultan Ahmet III in 1720 to the first Ottoman embassy in France, vines that were transplanted to Versailles on the order of Louis XV, hence the French synonym of Parc de Versailles.',
      'The Eastern origin of \u00c7avu\u015f is supported by DNA profiling, which has shown that the variety Mor \u00dcz\u00fcm, previously considered to be indigenous to U\u00e7hisar and Nev\u015fehir in Central Anatolia in Turkey, is in fact identical to \u00c7avu\u015f and is closely related to other varieties from Turkey or neighbouring Armenia and Georgia (Vouillamoz et al. 2006). Indeed, although Lefort and Roubelakis-Angelakis (2001) have shown close genetic relationships between \u00c7avu\u015f (under the name Tsaousi) and some Greek varieties, they consider this variety to be of Eastern origin.',
      'A pink-skinned mutation has also been observed.',
    ],
    'where its grown': [
      '\u00c7avu\u015f is grown mainly as a table grape in Turkey. However, Resit Soley at Corvus on the windy and sandy Turkish island of Bozcaada, off the west coast, was one of the first to vinify it. His wine, aged on the lees, is rich in melon, citrus and green-apple fruit flavour. It is not generally considered to be a variety that benefits from bottle ageing but Soley suggests that the best examples maintain their structure as they age. There were just under 30 ha (74 acres) planted for wine grapes in Turkey in 2010.',
      'This variety is also grown for the table in Algeria, Bulgaria, Russia,\nUkraine, Serbia, Romania and Greece. The variety known as TSAOUSSI on the\nGreek island of Kefaloni\u00e1, and to a lesser extent in Makedon\u00eda and Thr\u00e1ki (Thrace), is a distinct variety but there is frequent confusion because of the use of the synonym Tsaoussi to refer to the table grape \u00c7avu\u015f.',
    ],
  },
  {
    name: 'CAYETANA BLANCA',
    synonyms: [
      'Amor Blanco',
      'Aujubi',
      'Baladi',
      'Baladi-Verdejo',
      'Balay',
      'Belledy',
      'Blanca Cayetana',
      'Blanco Ja\u00e9n',
      'Cagazal',
      'Cayetana',
      'Cazagal',
      'Charello',
      'Charelo',
      'Chaselo',
      'Cheres',
      'Cirial',
      'Dedo',
      'Dedro',
      'Djiniani',
      'Doradillo',
      'Farta Gosos',
      'Garillo',
      'Garrida',
      'Garrido',
      'Garriga',
      'Garrilla',
      'Hoja Vuelta',
      'Ja\u00e9n Blanco',
      'Ja\u00e9n Doradillo',
      'Ja\u00e9n Empinadillo',
      'Ja\u00e9n Prieto Blanco',
      'Jaenes',
      'Jainas',
      'Jarime',
      'Jean de Castilla',
      'Jean de Letur',
      'Jean de Letur de Maratella',
      'Jean Doradillo',
      'Jean Dore',
      'Jean Prieto',
      'Machuenco',
      'Maizancho',
      'Malvasia',
      'Malvoisie Espagnole',
      'Mariouti',
      'Morisco',
      'Mourisco Arsello',
      'Mourisco Branco',
      'Mourisco Portalegre',
      'Naves',
      'Naves Cazagal',
      'Neruca',
      'Padero',
      'Parda',
      'Pardina',
      'Pirulet',
      'Plateadillo',
      'Robal',
      'Sarigo',
      'Tierra de Barros',
    ],
    description:
      'Lesser-quality Iberian variety known in Portugal as Mourisco Branco but more common in Spain under various synonyms.',
    'origin and parentage': [
      'Cayetana Blanca is an old variety from the Iberian peninsula that is widespread in Spain, often under the names Pardina and Ja\u00e9n Blanco, and also found in Portugal as Mourisco Branco. The name Ja\u00e9n, which comes from the city of that name in north-eastern Andaluc\u00eda, is a source of confusion. For example, in 1513 in Agricultura general Spanish agronomist Alonso de Herrera (1790) described both Cayetana Blanca in Extremadura and Ja\u00e9n Blanco in Castilla along with a dozen other varieties; and Spanish ampelographer Sim\u00f3n de Rojas Clemente y Rubio (1807) said: \u2018El primero que d\u00e9 \u00e1 conocer con ex\u00e2ctitud todos los vidue\u00f1os que llaman Jaenes en Espa\u00f1a har\u00e1 \u00e1 su patria un servicio real\u2019 (\u2018The first person to know precisely all the varieties called Ja\u00e9n in Spain will do his country a real service\u2019).',
      'A first DNA parentage analysis had suggested that Cayetana Blanca (under the name Cayetana tout court) was an offspring of ANT\u00c3O VAZ and RABO DE OVELHA, both originating from the Alentejo in southern Portugal (Lopes et al. 2006), which would have suggested a possible Alentejo birthplace for Cayetana Blanca, even though it is now quite rare in that region under the name Mourisco Branco. However, a more recent DNA parentage analysis based on a new kind of marker (Single Nucleotide Polymorphism or SNP) has rejected this parentage and has revealed, in addition to other previously discovered genetic relationships (Lopes et al. 2006; Santana et al. 2010), a complex network of relationships for Cayetana Blanca throughout the Iberian peninsula (Zinelabidine et al. 2012; see pedigree diagram overleaf):',
      '\u2013\u00a0\u00a0\u00a0Five varieties that are currently cultivated in the Iberian peninsula (mainly in Portugal) are the results of natural crosses that occurred at different times and places between Cayetana Blanca and the Portuguese variety ALFROCHEIRO: CORNIFESTO, MALVASIA PRETA, CAMARATE, Mourat\u00f3n (JUAN GARC\u00cdA) and Periquita (CASTEL\u00c3O), making these varieties siblings.',
      '\u2013\u00a0\u00a0\u00a0Ja\u00e9n Tinto, an old and almost extinct variety from southern Spain that should not be confused with Menc\u00eda (also called Ja\u00e9n Tinto in Bierzo in Castilla y Le\u00f3n), is a natural cross between Cayetana Blanca (father) and LIST\u00c1N PRIETO (mother) from Castilla-La Mancha. This parentage might explain why Cayetana Blanca is also called Ja\u00e9n Blanco all over Spain.',
      '\u2013\u00a0\u00a0\u00a0Cayetana Blanca has parent\u2013offspring relationships with at least ten additional grape varieties from the Iberian peninsula, many of them no longer cultivated: in Spain, Castillo de Arcos, Plateado (DORADILLA), Garrido Macho, Rocia and Puerto Alto from Andaluc\u00eda, as well as Castellana Blanca from Castilla y Le\u00f3n; in Portugal, Gouveio Estimado from the Douro, Cig\u00fcente (S\u00cdRIA) from D\u00e3o as well as ANT\u00c3O VAZ and RABO DE OVELHA from the Alentejo. These varieties are therefore all half-siblings, although, in theory, one of them could be a parent of Cayetana Blanca and the grandparent of the other nine.',
      'This complex pedigree illustrates the central genetic role played by Cayetana Blanca in both Portugal and Spain and suggests that it originated many centuries ago from unknown (and probably extinct) parents somewhere in the south-west Iberian peninsula, where it has had so many progenies (Zinelabidine et al. 2012).',
      'Under the name Ja\u00e9n Blanco, this variety has a pink-berried mutation called Ja\u00e9n Rosado with the same DNA profile as Cayetana Blanca, whereas the white-berried Ja\u00e9n Colorado and the black-berried Ja\u00e9n Tinto are distinct varieties (Iba\u00f1ez et al. 2003; Mart\u00edn et al. 2003). Ja\u00e9n Blanco was generally thought to be a synonym for CALAGRA\u00d1O from La Rioja until DNA profiling proved them to be distinct (Carre\u00f1o et al. 2004).',
      'Under the name Pardina, this variety should not be confused with ALBILLO MAYOR, which is also known as Pardina in Ribera del Duero in north-west Spain.',
      'Mourisco Branco is not related to the Minho variety AVESSO, even though the Vitis International Variety Catalogue lists Avesso do Minho as a synonym for Cayetana Blanca and Galet gives it as a synonym for Ja\u00e9n Blanco.',
      'In Australia, Cayetana Blanca was introduced from Spain in the nineteenth century under the name Doradillo (Galet 2000) but it should not be confused with the Andalusian variety DORADILLA, which is quite distinct.',
    ],
    'where its grown': [
      'Cayetana Blanca is now mainly cultivated in Spain under the names Ja\u00e9n Blanco, Pardina and Blanca Cayetana. Official Spanish statistics have not yet caught up with DNA discoveries and still list Pardina, Ja\u00e9n Blanco and Blanca Cayetana separately: there were 4,973 ha (12,289 acres) of Ja\u00e9n Blanco in 2008, mostly in Castilla-La Mancha, Andaluc\u00eda and around Madrid; Pardina notched up a massive 31,440 ha (77,690 acres) in 2008, almost entirely in Extremadura in south-west Spain, especially in the province of Badajoz, where it is authorized in the Ribera del Guadiana DO but used more widely for Extremadura regional wines or for distillation into brandy de Jerez; the same is true for the 11,850 ha (29,282 acres) of Blanca Cayetana in Extremadura, also predominantly in Badajoz. Wines are typically neutral and moderate in alcohol with medium to high acidity and they are prone to oxidation.',
      'Varietal wines labelled Pardina are produced by the Nuestra Se\u00f1ora de la Soledad and Montevirgen co-operatives in Badajoz and by Marcelino D\u00edaz and San Antonio (Ribera del Guadiana). Varietal wines labelled Cayetana are produced by, for example, the Bodegas San Marcos (though the label states 50% Pardina and 50% Cayetana), Vinificaciones Extreme\u00f1as and Viticultores de Barros.',
      'Mourisco Branco vines are now relatively few in the Alentejo, Portugal \u2013 just 141 ha (348 acres) in 2010.',
      'There were still 74 ha (183 acres) of Doradillo in Australia in 2008, mostly in the hot, irrigated Riverland, where it produces fruit better suited to distillation or to the production of fortified wines.',
    ],
  },
  {
    name: 'CAYUGA WHITE',
    synonyms: ['Geneva White 3', 'GW 3', 'New York 33403', 'NY 33403'],
    description: 'Versatile New York hybrid.',
    'origin and parentage': [
      'A SEYVAL BLANC \u00d7 Schuyler hybrid obtained by John Einset and Willard B Robinson in 1945 at NYSAES, the New York State Agricultural Experiment Station in Geneva, where:',
      '\u2013\u00a0\u00a0\u00a0Schuyler is a TRIBIDRAG (Zinfandel) \u00d7 Ontario hybrid',
      '\u2013\u00a0\u00a0\u00a0Ontario is a Winchell \u00d7 MOORE\u2019S DIAMOND hybrid',
      '\u2013\u00a0\u00a0\u00a0Winchell is a Vitis labrusca \u00d7 Vitis vinifera subsp. vinifera (unknown cultivar) hybrid obtained by J M Clough in the US.',
      'This complex Vitis\nlabrusca, Vitis\nvinifera, Vitis\nlincecumii and Vitis\nrupestris hybrid was selected in 1952, tested under the names New York 33403 or GW 3, and released in 1972 as Cayuga White (Einset and Robinson 1972).',
    ],
    'where its grown': [
      'Cayuga White is generally used to produce off-dry to medium varietals or blended wines, sometimes even icewine, but also a few very good dry whites. They tend to be light-bodied and a little like Muscat; the best have RIESLING-like citrus notes. Harvested early, the fruit is well suited to sparkling wines; harvesting overripe increases the foxy aromas. Cayuga White is planted mainly in the eastern United States, with the biggest area in the State of New York (404 acres/163 ha in 2006), where Deer Run produce a better example than most, but the variety is also found in Pennsylvania, Missouri, Illinois and Michigan. Minor plantings can be found in Canada, notably in Qu\u00e9bec, but the opportunities are limited because Cayuga is only moderately hardy.',
    ],
  },
  {
    name: 'CENTESIMINO',
    synonyms: [
      'Alicante del Faentino',
      'Savignon Rosso',
      'Savign\u00f4n Rosso',
    ],
    description:
      'Red variety resurrected in a garden in Emilia-Romagna in the mid twentieth century.',
    'origin and parentage': [
      'Until the nineteenth century, this grape from the province of Ravenna in Emilia-Romagna, north-east Italy, was called Savign\u00f4n Rosso, although it is not a red mutation of SAUVIGNON BLANC. In the mid twentieth century, during the reconstruction of vineyards after phylloxera, Pietro Pianori propagated this obscure variety from old vines growing in his walled garden at Podere Terbato in Faenza. Those vines had fortunately escaped phylloxera, and all modern plantings of this variety derive from cuttings taken from Pianori\u2019s garden. Pianori was nicknamed \u2018Centesimino\u2019 (meaning \u2018little cent\u2019), which gave the grape its modern name. Both names were given on the Italian official register of varieties when it was included in 2004.',
    ],
    'where its grown': [
      'This grape variety was officially registered under the nickname of its discoverer. Today it is cultivated by a consortium of eight wineries in Oriolo dei Fichi near Faenza in the province of Ravenna, eastern Italy, where wines are marketed under the misleading synonym of Savign\u00f4n (or Savignon) Rosso. It is also cultivated in Forl\u00ec and in the Brisighella Valley. Wines may be classified as Ravenna Rosso IGT and appear in both oaked and unoaked guises. Wines can have both floral and spicy notes. Some producers such as Poderi Morini and Il Pratello make a passito version. They tend to have good acidity.',
    ],
  },
  {
    name: 'CENTURIAN',
    synonyms: ['Centurion'],
    description: 'California cross as unsuccessful as its sibling Carnelian.',
    'origin and parentage': [
      'A cross between (MAZUELO (under the name Carignan) \u00d7 CABERNET SAUVIGNON) and GARNACHA \u2013 the same as CARNELIAN \u2013 obtained by Harold P Olmo in 1974 at the University of California at Davis. It is sometimes mistakenly spelt Centurion.',
    ],
    'where its grown': [
      'As with its sibling CARNELIAN, this cross was intended to produce a variety that tolerated the climate of California\u2019s hot Central Valley but retained the characteristics of CABERNET SAUVIGNON. In both instances, the aim was not achieved and the lack of success of Carnelian did nothing to encourage growers to plant Centurian. The area planted to Centurian in California peaked at about 1,000 acres (405 ha) but continues to decline, down to around 300 acres (121 ha) by the early 2000s and down further to 87 acres (35 ha) in 2008, mostly in the San Joaquin Valley. No varietal versions encountered.',
    ],
  },
  {
    name: 'CERCEAL BRANCO',
    synonyms: ['Cercial', 'Cercial do Douro'],
    description:
      'Fresh Douro variety not to be confused with Madeira\u2019s Sercial.',
    'origin and parentage': [
      'Cerceal Branco grown in the Douro and D\u00e3o is identical to the variety known as Cercial in Bairrada but distinct from both SERCIAL on Madeira and Cercial in Pinhel, although the local spelling variants cause considerable confusion, as evidenced by various DNA studies:',
      '\u2013\u00a0\u00a0\u00a0Cerceal Branco grown in D\u00e3o (Almadanim et al. 2007) is identical to Cercial in Bairrada (Mart\u00edn et al. 2006) and to Cercial do Douro (Lopes et al. 2006); Cercial in Bairrada is probably a progeny of MALVASIA FINA and SERCIAL (Lopes et al. 2006)',
      '\u2013\u00a0\u00a0\u00a0Cercial from Madeira is more commonly spelt SERCIAL and is identical to Esgana C\u00e3o (Lopes et al. 2006)',
      '\u2013\u00a0\u00a0\u00a0Cercial from Pinhel in Beiras is identical to JAMPAL on Madeira (Lopes et al. 2006).',
    ],
    'where its grown': [
      'Cerceal Branco, grown mostly in the Douro but also found in D\u00e3o and Bairrada, produces wines that are light-bodied and fresh, generally adding acidity to white blends. It is also authorized further south in the Tejo and Alentejo DOCs. There were 113 ha (279 acres) in Portugal in 2010. Recommended producers in Bairrada include Campolargo, who include it in the blend of one of their sparkling wines, and Vadio.',
    ],
  },
  {
    name: 'CESANESE',
    synonyms: [
      'Bonvino Nero',
      'Cesanese Comune',
      'Cesanese di Affile',
      'Cesanese Nostrano',
      'Nero Ferrigno',
      'Sanguinella',
      'Uva di Affile',
    ],
    description:
      'Lazio\u2019s prime red wine grape, aromatic and difficult to ripen.',
    'origin and parentage': [
      'The variety may have been noted in 1600 under the name Cesenese around Firenze in Toscana by Soderini in his treatise on grapevines and wines, and more certainly in Lazio by Acerbi (1825) and subsequent authors. Its name comes from Cesano, a village in the Castelli Romani, south of Roma.',
    ],
    'where its grown': [
      'It is still grown almost exclusively in Lazio, Italy. Two main clones, morphologically slightly different, are generally distinguished: Cesanese Comune, the most widespread, and the potentially better quality Cesanese di Affile, a version with the aroma of lychees supposedly brought in 1820 by a Sicilian monk and planted in Affile (south of Roma), where it is still mainly cultivated now. The Italian agricultural census of 2000 recorded 477 ha (1,179 acres) of the former and 615 ha (1,520 acres) of the latter. Before the growth in popularity of local white wines, Cesanese was far more widely planted. Cesanese is not easy to grow or vinify and high expectations are only occasionally realized, both historically and today. The best wines come from the areas around the towns of Anagni and Piglio in the province of Frosinone and Olevano Romano in the province of Roma.',
      'Three DOCs allow 100% Cesanese wines in the Castelli Romani region: Cesanese del Piglio, Cesanese di Affile and Cesanese di Olevano Romano. Cesanese is also blended in other Lazio DOCs, eg Lago di Corsara and Rosso Orvietano. Traditionally sweetish frothy Cesanese was common but today they are more usually dry and fruity, with velvety tannins and a distinctive perfume reminiscent of mulberries and pimento. Varietal versions are produced by Terre Rubre, Marcella Giuliani, Coletti Conti and Cantine Ciolli, among others.',
      'Andrea Franchetti, who experimented with the variety at Tenuta di Trinoro in southern Toscana but abandoned it there because it either failed to ripen or was too emphatically aromatic, grows some on Etna, Sicilia.',
    ],
  },
  {
    name: 'C\u00c9SAR',
    synonyms: [
      'C\u00e9ear',
      'C\u00e9elar',
      'C\u00e9lar',
      'Picarniau',
      'Romain',
      'R\u00f6mer',
    ],
    description:
      'Tannic variety playing a very minor and local role in northern Burgundy.',
    'origin and parentage': [
      'C\u00e9sar is local to the Yonne d\u00e9partement, south and west of Paris in north-central France (Chablis territory), where it was first mentioned in 1783 under its synonym Romain, in Auxonne, south west of Dijon in the C\u00f4te d\u2019Or, and in Irancy, south east of Auxerre, in 1783\u20134 as \u2018C\u00e9sar ou Romain\u2019 (R\u00e9zeau 1997). Its cradle is said to be in nearby Coulanges-la-Vineuse, a village which may take its name from the Latin colongiae vinosae (\u2018vinous colonies\u2019).',
      'DNA parentage analysis has revealed that C\u00e9sar is a natural PINOT \u00d7 G\u00c4NSF\u00dcSSER (aka Argant) cross (Bowers et al. 2000), which speaks in favour of a north-eastern France origin for this variety. However, C\u00e9sar is also known in Rheinland-Pfalz under the name R\u00f6mer, and the French ampelographer Adrien Berget found that Argant/G\u00e4nsf\u00fcsser was morphologically distinct from any French variety and considered it to be of German origin. The birthplace of C\u00e9sar is therefore likely to be somewhere between the Yonne and Rheinland-Pfalz.',
    ],
    'where its grown': [
      'There were just 10 ha (25 acres) remaining in France in 2008. It is officially recommended in northern Burgundy around the towns of Chablis and Auxerre for generic burgundy (eg Bourgogne-Chitry, Bourgogne-\u00c9pineuil) and for Irancy. It is not authorized for burgundy produced in d\u00e9partements other than the Yonne. It generally produces wines that have red-fruit flavours and sufficient tannins to allow ageing in bottle, and therefore can add power and longevity to PINOT NOIR in basic red burgundy. In practice this happens mainly, but still only occasionally, in Irancy. Simmonet Febvre used to make a varietal C\u00e9sar but now just use the grape in their Irancy blend (maximum 10%).',
      'Domaine Sorin-Coquard\u2019s Cuv\u00e9e Antique is a rare example of a varietal C\u00e9sar, as is Cave de la Tourelle\u2019s Bourgogne C\u00e9sar at Saint-Bris le Vineux.',
      'There are a few hectares of \u2018C\u00e9sar\u2019 in Isla de Maipo in Chile, known locally as Romano, but it is uncertain if this is the same variety. It used to be more widely planted but was mostly grubbed up because of its excessive yields (although that was why it was introduced) and consequent poor ripening.',
    ],
  },
  {
    name: 'CETINKA',
    synonyms: [
      'Blajka',
      'Blatinka',
      'Blatka',
      'Blatska',
      'Cetinjka',
      'Cetinjka Bijela',
      'Cetinka Bijela',
      'Posera\u010da',
      'Potomkinja',
    ],
    description: 'Extremely rare Croatian island white.',
    'origin and parentage': [
      'Cetinka most likely comes from the islands of Kor\u010dula and Hvar, off the Adriatic coast of Croatia, just south of Split.',
    ],
    'where its grown': [
      'Cetinka is officially authorized in the Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine region of Croatia but it is found mainly on the islands of Kor\u010dula and Hvar, and to a lesser extent on the Peljesac peninsula and the islands of Mljet, Lastovo and Vis. Cetinka is often blended with Mara\u0161tina (MALVASIA BIANCA LUNGA) or GRK. The Blato co-operative on Kor\u010dula produces a varietal wine. Wines tend to be light, fresh and slightly acidic.',
    ],
  },
  {
    name: 'CEVAT KARA',
    synonyms: [
      'Djevat Kara',
      'Dshevat-Kara',
      'Dzhevat Kara',
      'Jewath',
      'Polkovnik Kara',
    ],
    description: 'Southern Ukrainian variety used in fortified blends.',
    'origin and parentage': [
      'Cevat Kara, literally \u2018colonel\u2019s black\u2019, comes from Solnechnaya Dolina in eastern Krym (Crimea), in the far south of Ukraine.',
    ],
    'where its grown': [
      'Used mostly in blended fortified wines such as Massandra\u2019s eponymous Black Colonel and their Black Doctor (see EKIM KARA) in Ukraine.',
    ],
  },
  {
    name: 'CHAMBOURCIN',
    synonyms: ['Joannes Seyve 26-205'],
    description:
      'French humidity-tolerant hybrid popular in the US and Australia but on the decline in its homeland.',
    'origin and parentage': [
      'Chambourcin is a complex hybrid obtained after 1945 by Joannes Seyve by crossing Seyve-Villard 12-417 with CHANCELLOR, where Seyve-Villard 12-417 is a Seibel 6468 \u00d7 Sub\u00e9reux cross (for the complete pedigrees, see BRIANNA for Seibel 6468, and PRIOR for Sub\u00e9reux). It was named after the lieu-dit Chambourcin in the village of Boug\u00e9-Chambalud in Is\u00e8re, south-eastern France, where Seyve owned an experimental vineyard. It was first commercialized in 1963 and was used to breed REGENT.',
    ],
    'where its grown': [
      'There were 800 ha (1,977 acres) in France in 2008 (down from 948 ha (2,343 acres) in 2006), predominantly in the Pays de Loire regions of central France, although it is authorized in many French d\u00e9partements. Although the overall trend is down, from a peak of 3,363 ha (8,310 acres) in the late 1970s, when it was particularly popular in the Muscadet region, it is still one of the most widely planted hybrids in France. Wines are full-flavoured and aromatic and do not have the intrusive flavours sometimes associated with hybrids.',
      'The variety\u2019s cold hardiness endears it to growers in Canada and in America\u2019s Northeast and Midwest. There are around 120 acres (49 ha) in Canada, mostly in Ontario (85 acres/34 ha in 2006, down from 128 acres/52 ha in 2002) but also in Qu\u00e9bec. There were 154 acres (62 ha) in Missouri in 2009, 146 acres (59 ha) in Pennsylvania in 2008, 31 acres (13 ha) in New York State in 2006 and 42 acres (17 ha) in Michigan in the same year. Minor plantings are found elsewhere in the US, including North Carolina, where Davest\u00e9 Vineyards make a varietal Chambourcin, Virginia (96 acres/39 ha in 2008), Illinois (105 acres/42 ha in 2009), Indiana (65 acres/26 ha in 2009), Ohio (15 acres/6 ha) and Nebraska (5 acres/2 ha in 2007). It is also recommended in the southern half of Iowa. Butler Winery in Indiana produce a good example.',
      'Chambourcin is apparently the most successful hybrid in Australia. According to the Australian Wine Industry Directory 2010, there are eighty-six producers in Australia, many in warm and humid regions such as the Hunter Valley in New South Wales because of its disease resistance but also up the east coast to Queensland. Cassegrain at Port Macquarie in the Hastings River region of NSW, credited with pioneering Chambourcin in Australia, produce two separate varietal wines. By contrast, there are just a few hectares in New Zealand. It also shows promise in Vietnam, thanks not only to its ability to cope with humidity but also to the influence of Australian consultants.',
    ],
  },
  {
    name: 'CHANCELLOR',
    synonyms: ['Seibel 7053'],
    description:
      'Minor French-American hybrid now more widely planted in North America than in France.',
    'origin and parentage': [
      'Complex hybrid obtained by Albert Seibel, most likely in the early twentieth century, in Aubenas in the Ard\u00e8che between N\u00eemes and Lyon, France, by crossing Seibel 5163 \u00d7 Seibel 880 (see PRIOR for the complete pedigree of Chancellor). It was given the name Chancellor in New York State in 1970.',
      'Chancellor was used to breed ALLEGRO, BREIDECKER, CHAMBOURCIN and GEISENHEIM 318-57.',
    ],
    'where its grown': [
      'There were 46 acres (19 ha) in New York State and 35 acres (14 ha) in Michigan in 2006, and more limited plantings in US states such as Indiana and Nebraska. In Canada, there were 10 acres (4 ha) in British Columbia in 2008 and minor plantings in Qu\u00e9bec and Ontario. It produces deeply coloured wines of better quality than many French-American hybrids but they are sometimes slightly bitter. Joseph\u2019s Estate in Niagara-on-the-Lake, Ontario, make a Chancellor/MERLOT blend.',
    ],
  },
  {
    name: 'CHARDONEL',
    synonyms: ['GW 9', 'NY 45010'],
    description:
      'New York hybrid that is more hardy and productive than its Chardonnay parent, adopted in some northern US states.',
    'origin and parentage': [
      'Chardonel is a complex Vitis\nrupestris, Vitis\nvinifera and Vitis\nlincecumii hybrid obtained by Bruce Reisch, Robert Pool and John Einset in 1953 at the Geneva research centre, New York State, by crossing SEYVAL BLANC with CHARDONNAY. The first fruits were observed in 1958 and the original vine was propagated in 1960 under the number NY 45010 (New York 45010). In later trials it was renamed GW 9 (Geneva White 9) for ease of identification (Reisch et al. 1990). The first wines were made in 1966 and the variety was released in 1990.',
    ],
    'where its grown': [
      'Since its introduction in 1990, Chardonel has proved popular and is now planted in US states such as Missouri (190 acres/77 ha in 2009), Arkansas, Michigan (24 acres/10 ha in 2006), Pennsylvania and Ohio (6 acres/2.4 ha in 2008). In New York State, it is recommended only for warmer sites where the growing season is longer than average. Wines are generally light and pleasant, with the better examples being fuller-bodied and resembling CHARDONNAY while the lesser ones are more like SEYVAL BLANC. In some years or sites wines can be herbaceous but the high level of acidity also makes the variety suitable for the production of sparkling wine. The best examples benefit from barrel fermentation. Chardonel is produced in a variety of syles, ranging from dry and oaked through medium dry to sweet.',
      'Superior producers of varietal wines include Chaumette in Ozark Mountain AVA, Missouri; St James Winery in Ozark Highlands AVA, Missouri; Tabor Hill in Lake Michigan Shore AVA, Michigan; and Butler Winery in Indiana.',
    ],
  },
  {
    name: 'CHARDONNAY',
    synonyms: [
      'Aubaine',
      'Auvernat',
      'Auxerrois',
      'Auxois',
      'Beaunois',
      'Chaudenay',
      'Clevner',
      'Cl\u00e4vner',
      'Gamay Blanc',
      'Luisant',
      'Melon \u00e0 Queue Rouge',
      'Melon d\u2019Arbois',
      'Obaideh',
      'Pinot Blanc Chardonnay',
      'Wais Edler',
      'Waiser Clevner',
    ],
    description:
      'Hugely popular, versatile and widely planted international white variety equally capable of extreme mediocrity and regal splendour.',
    'origin and parentage': [
      'The historical cultivation area of Chardonnay, which comes from the Sa\u00f4ne-et-Loire, between Lyon and Dijon in eastern central France, ranged from Burgundy to Champagne, mainly in the C\u00f4te d\u2019Or, Sa\u00f4ne-et-Loire and Marne. Under its synonym Beaunois, \u2018from Beaune\u2019, this variety was supposedly first mentioned in 1583 but the same name was also given to other varieties such as ALIGOT\u00c9.',
      'The earliest reliable mention of Chardonnay appeared in 1685\u201390 in the village of Saint-Sorlin, today called La Roche-Vineuse in the Sa\u00f4ne-et-Loire, where \u2018chardonnet\u2019 was said to make the best wine. Indeed, the variety takes its name from the village of Chardonnay near Uchizy in the M\u00e2connais, southern Burgundy. Various spellings have been used (Chardenet, Chardonnet, Chardenay, etc.) and the modern spelling, Chardonnay, did not become common until the twentieth century.',
      'Chardonnay was confused with PINOT BLANC until the end of the nineteenth century due to their morphological similarities. It was often called Chardonnet Pinot Blanc or Pinot Blanc Chardonnet in France; and in Germany it was called Clevner or Cl\u00e4vner, or even Rul\u00e4nder, the current name for PINOT GRIS in western Germany.',
      'DNA parentage analysis has unexpectedly shown that Chardonnay is a natural PINOT \u00d7 GOUAIS BLANC cross (Bowers et al. 1999), just like its siblings GAMAY NOIR, MELON, ALIGOT\u00c9, etc. (see PINOT pedigree diagram). This parentage explains the confusion with Aligot\u00e9 and PINOT BLANC, and also the synonyms Gamay Blanc and Melon d\u2019Arbois in the Jura. Chardonnay unsurprisingly belongs to the ampelographic group called Noirien (see p XXVII; Bisson 2009).',
      'Chardonnay Rose is a colour mutation found in vineyards around the village of Chardonnay. Chardonnay Musqu\u00e9 is a mutation that has grapey Muscat-like aromas and was first observed in Pouilly and Arbois (Galet 1990). Certain clones of Chardonnay, notably 77 and 809, have similar grapey aromas.',
      'Chardonnay has been used as a parent of several different varieties: in France, it was crossed with BAROQUE to breed LILIORILA, with a Seibel hybrid to produce RAVAT BLANC, and with PALOMINO FINO to breed CHASAN; in the US, it was bred with SEYVAL BLANC to produce CHARDONEL; in Switzerland, it was crossed with CHASSELAS to breed CHARMONT and DORAL; in Serbia, it was crossed with K\u00d6VIDINKA to produce SILA; with Plava\u00ef in Ukraine to produce SUKHOLIMANSKY BELY.',
    ],
    'where its grown': [
      'Chardonnay, loved or reviled, is arguably the most versatile white wine grape. Without a dominant flavour of its own, it can take on a wide array of aromas depending on where it is grown and, particularly, how it is made. Although the grapes generally reach relatively high sugar levels, the wines can taste lean, even tart, almost like a SAUVIGNON BLANC in its refreshing effect, in a cool climate such as that of Chablis. But whatever the style, through various swings of fashion, Chardonnay has shown itself a particularly malleable partner in such winemaking operations as malolactic fermentation, lees stirring, barrel fermentation and all manner of oak-related practices. It runs the gamut from subtle and savoury to rich and spicy still, dry wines, as well as providing unparalleled base wine for top-quality fizz and, occasionally, in the M\u00e2connais and Burgen-land for example, submitting to botrytis to produce interesting sweet wines.',
      'As the wine world expanded dramatically in the late twentieth century, and the fashion then was for white wine, plantings of Chardonnay increased enormously. Chardonnay became the dominant white wine grape in both North and South America and in Australia, and its popularity with consumers was such that Chardonnay was virtually synonymous with white wine. But for every action there is a reaction and, by the end of the first decade of the twenty-first century, Chardonnay was considered distinctly old hat by many consumers, having been replaced by Pinot Grigio (PINOT GRIS) in the mass market and with the reputation of Chardonnay in its most glamorous form as fine white burgundy somewhat tarnished by the high incidence of premature oxidation.',
      'France, for example, saw total plantings rise from just 7,325 ha (18,100 acres) in 1958, mainly in Burgundy and Champagne, to 44,593 ha (110,191 acres) in 2009. Chardonnay is now France\u2019s second most planted white variety, although Ugni Blanc (TREBBIANO TOSCANO), planted chiefly for distillation into cognac and armagnac, is planted on twice as much land as Chardonnay. Today Chardonnay\u2019s French strongholds are Burgundy, Languedoc-Roussillon and Champagne, in that order in terms of area.',
      'Chardonnay is the white grape of Burgundy, even if some ALIGOT\u00c9 and a little bit of PINOT BLANC is also grown there. The total in greater Burgundy was 14,753 ha (36,455 acres; 4,048 ha/10,003 acres in 1958), of which only 2,120 ha (5,239 acres) were on the narrow strip of C\u00f4te d\u2019Or vineyards, 6,929 ha (17,122 acres) in the C\u00f4te Chalonnaise and M\u00e2connais in the Sa\u00f4ne-et-Loire, and 5,648 ha (13,957 acres) in Chablis country in the Yonne d\u00e9partement.',
      'Chardonnay shows its versatility in Chablis, where it produces its most mouth-wateringly appetizing, longest-lived wines, especially when grown on the local kimmeridgean clay-limestone soils. The best wines are steely and precise, with notably high acidity, and can develop interest in bottle for decades.',
      'Just as top-quality red bordeaux inspired CABERNET SAUVIGNON\u2019s spread around the world, so top-quality white burgundy powered Chardonnay\u2019s extraordinary popularity \u2013 and Chardonnay, unlike Cabernet Sauvignon, is not difficult to ripen, so it can thrive in a much wider range of climates. There are few more nuanced, longer-lived, full-bodied dry white wines than a great wine from the tiny Montrachet vineyard and its grand cru neighbours, even if overpriced, unambitious examples are not difficult to find. Ditto for the grand cru Corton-Charlemagne. Excellent, tense, similarly savoury white burgundies can be found carrying less grand but classic appellations such as Puligny-Montrachet, Chassagne-Montrachet and Meursault; and the likes of Saint-Aubin and Pernand-Vergelesses, which once struggled to ripen Chardonnay fully, are also making some very fine white wine in the warmer summers the C\u00f4te d\u2019Or has been experiencing. The southern half of the C\u00f4te d\u2019Or, the C\u00f4te de Beaune, is Chardonnay\u2019s most famous heartland and the vine is found only exceptionally in the C\u00f4te de Nuits to the north.',
      'The C\u00f4te d\u2019Or may hold the crown for Chardonnay quality (under constant siege by ambitious producers elsewhere) but far more is grown to the south in the C\u00f4te Chalonnaise, where white wines can be good value though rarely have the tension and density of the best from the C\u00f4te d\u2019Or, and especially in the M\u00e2connais, where the potential is so obvious that it is systematically being invaded by top C\u00f4te d\u2019Or producers. Here wines can have an extra dimension of ripeness and are certainly much more precocious than their northern counterparts. Flavours in these bumptious, friendly wines can vary from melons through to ripe apples, although an increasing proportion very obviously reflect the varied soils of such appellations as Pouilly-Fuiss\u00e9, Vir\u00e9-Cless\u00e9 and Saint-V\u00e9ran. And Chardonnay is also responsible for the generally rather lighter wine sold as Beaujolais Blanc.',
      'Chardonnay is significant to the small Jura wine region, where the wines could be described as high-altitude white burgundies.',
      'Champagne has even more Chardonnay planted than the M\u00e2connais and C\u00f4te Chalonnaise combined \u2013 9,781 ha (24,169 acres), mostly in the Marne d\u00e9partement (dramatically more than the 2,664 ha/6,583 acres growing in 1958) rather than in the Aube to the south. Chardonnay is virtually the only white wine variety grown, and an increase in demand for Blanc de Blancs (all pale-skinned grapes) champagne together with the general expansion of the Champagne vignoble explain the dramatic increase. All-Chardonnay traditional-method sparkling wine, whether champagne or not, tends to exhibit a certain lemony quality, developing into something more biscuity or brioche-like, especially if it is given extended bottle ageing on the lees.',
      'But the real surprise is the extent to which Chardonnay is planted in Languedoc-Roussillon \u2013 to a total of 12,156 ha (30,038 acres), making Chardonnay the most widely planted white wine variety there. With the exception of the Chardonnay grown around Limoux for sparkling wine, the great majority of this Chardonnay finds its way into relatively inexpensive varietals with varying degrees of varietal expression. Yields are often high and the resulting wines more dry white than ineluctably Chardonnay.',
      'There are also less significant plantings in cognac and armagnac country, where it produces everyday wines, the Ard\u00e8che, where it makes full-bodied southern answers to M\u00e2con Blanc, and the Val de Loire, where it is grown for the sparkling Cr\u00e9mant de Loire as well as for some lively, if fairly lightweight, still wines. It is allowed up to 20% in Anjou Blanc and largely replaced Tressallier (SACY) in Saint-Pour\u00e7ain in the late twentieth century.',
      'For over a century Italy grew a lot of Pinot Bianco (PINOT BLANC) and a bit of Chardonnay and wasn\u2019t too sure which was which but in the late twentieth century Italians fell prey to Chardonnay mania, and learnt the difference, so that by 2000 there were 11,772 ha (29,089 acres) of it. Ambitious producers all over the country, particularly in Piemonte and in central Italy, felt they had to produce small volumes of expensively oaked Chardonnay \u2013 and the early-ripening variety has been useful for those exposures in the Langhe hills too cool to ripen NEBBIOLO or indeed any red wine grape. Gaja\u2019s Gaia e Rey is arguably Italy\u2019s most famous Chardonnay, although Querciabella\u2019s Bat\u00e0r grown in Chianti Classico country and Antinori\u2019s Umbrian Cervaro, into which some GRECHETTO DI ORVIETO is blended, are other very superior wines made in the image of white burgundy.',
      'Italy\u2019s greatest concentrations of Chardonnay plantings are in Lombardia (much of it for sparkling wine such as Franciacorta), Trentino (also largely for fizz) and the Alto Adige, where much of the Chardonnay seems to lack the conviction of other white wine varietals. Exceptions include Tiefenbrunner\u2019s top bottling and Elena Walch\u2019s Cardellino. Other varieties also seem to suit Friuli better, although Jermann made waves with his de luxe \u2018Dreams\u2019 bottling of Chardonnay at one stage. Much of southern Italy is a bit hot for the variety but that has not stopped large tracts of Puglia being planted to produce varietal Chardonnay \u2013 an Italian answer to the generally rather anodyne Languedoc version. The variety has reached greater heights in Sicilia, notably chez Planeta, whose world-class, if not exactly quintessentially Sicilian, example inspired others to follow suit to such an extent that 4% of the island\u2019s vineyards, 4,960 ha (12,256 acres), were planted with Chardonnay in 2008.',
      'Much of Spain, where there were 5,423 ha (13,401 acres) in 2008, is too hot to imbue such an early-ripening variety as Chardonnay with much flavour and interest, although Torres\u2019 Milmanda from the hills of Conca de Barber\u00e0 and Chivite\u2019s Colecci\u00f3n 125 bottling from their cooler vineyards in Navarra have had some success with varietal Chardonnay, as have Enate of Somontano.',
      'Chardonnay is a very small player in Portugal, where there were just 566 ha (1,399 acres) in 2010.',
      'Luxembourg, which in a cooler era depended on the much earlier-ripening AUXERROIS, had 16 ha (40 acres) of Chardonnay in 2008. In Belgium, a copy of fine white burgundy has been produced in the walled Clos d\u2019Opleeuw in Haspengouw. Thanks to recent plantings for sparkling wine, the area of Chardonnay vines in the UK had risen to 225 ha (556 acres) by 2009.',
      'The extent to which Chardonnay is planted in the land of RIESLING, Germany, may surprise many. By 2008 there were already 1,171 ha (2,894 acres), mainly in the Pfalz and Rheinhessen and to a lesser extent in Baden. It was perhaps a natural development in those who reckoned they had already mastered PINOT BLANC and PINOT GRIS \u2013 and certainly German consumers are far from immune to the charms of Chardonnay. Recommended producers of Germany\u2019s decidedly full-bodied Chardonnays include Knipser and Rebholz in the Pfalz, Dr Heger and Bernhard Huber in Baden, and F\u00fcrst in Franken.',
      'Austria has more limited plantings and those mainly in the Steiermark (Styria) in the south (268 ha/662 acres in 2007), where it is generally known as Morillon. Neumeister, Tinnacher, Polz, Sabathi, Sattlerhof and Tement all have a stab at it, although their Sauvignon Blancs are arguably more successful. Kracher of Burgenland has made much-admired Trockenbeerenauslesen from both pure Chardonnay and Chardonnay blended with the crisper Welschriesling (GRA\u0160EVINA).',
      'Chardonnay is also planted in Switzerland, where there were 321 ha (793 acres) in 2009. Recommended producers of a refined style of Chardonnay include Davaz in Graub\u00fcnden, Domaine des Balisiers in Gen\u00e8ve and Raymond Paccot at Domaine La Colombe in Vaud.',
      'In Eastern Europe, Chardonnay is most important in the Republic of Moldova,\nwhere 5,134 ha (12,686 acres) were logged in 2009, but little of note is exported. Bulgaria also has considerable plantings \u2013 4,624 ha (11,426 acres) in 2009 \u2013 but has had a tendency to overdo the yields and, often, oak chips. Over the border in Romania, plantings seem much more limited, just 704 ha (1,740 acres) in 2008, mostly around Constan\u0163a on the Black Sea coast.',
      'Hungary had 2,862 ha (7,072 acres) of Chardonnay in 2008, spread throughout the wine regions but with the most substantial areas in M\u00e1tra, Etyek-Buda and Nezm\u00e9ly in the central north and around Lake Balaton in the west. The best examples are barrel fermented and oak aged from the likes of Hilltop (Neszm\u00e9ly), Ott\u00f3 L\u00e9gli (Balatonbogl\u00e1r), J\u00f3zsef Bock and Malatinszky (Vill\u00e1ny-Sikl\u00f3s), M\u00e1ty\u00e1s Sz\u0151ke (M\u00e1tra) and Tibor G\u00e1l (Eger).',
      'Slovenia, with 1,215 ha (3,002 acres) in 2009, has some very fine Chardonnay made with great care in the image of white burgundy, typically in Gori\u0161ka Brda near the Italian border by Marjan Sim\u010di\u010d, Edi Sim\u010di\u010d and Klinec, although some is also grown in \u0160tajerska Slovenija (Slovenian Styria) in the north east.',
      'In Croatia, where there were 910 ha (2,249 acres) in 2009, Chardonnay is often blended with MALVAZIJA ISTARSKA, especially in Istra (Istria), as in Ivica Mato\u0161evi\u0107\u2019s Grimalda, and the variety is presumably grown in Serbia too. There were 400 ha (988 acres) in 2009 in Montenegro, where Chardonnay was the most important non-indigenous variety. The Czech Republic had 756 ha (1,868 acres) in 2008, mainly in Morava in the south east.',
      'Ukraine has significant plantings of Chardonnay, 2,984 ha (7,374 acres) in 2009, while Russia had just 300 ha (741 acres), most of it at Fanagoria in Krasnodar Krai on the Black Sea. Georgia is more interested in RKATSITELI and other local specialities and logged just 40 ha (99 acres) of Chardonnay in 2009.',
      'White wine in general is a minority interest in the warmth of the eastern Mediterranean, although the likes of Castel and Golan Heights make some quite impressive \u2018international-style\u2019 Chardonnay from some cooler vineyards in Israel, which is estimated to have about 200 ha (494 acres) of Chardonnay. Greece had 140 ha (346 acres) in 2007, almost all of it in the north (eastern Makedon\u00eda and Thr\u00e1ki/Thrace). Turkey had 183 ha (452 acres), Cyprus 130 ha (321 acres) and Malta about 100 ha (247 acres) in 2010.',
      'Chardonnay\u2019s hold on white-wine selections in California has been vice-like. The Golden State\u2019s plantings of the golden grape reached a peak of 94,164 acres (38,107 ha) in 2003. Thanks to greater interest in red wines, the total had fallen slightly by 2008 to 91,522 acres (37,038 ha) but rose again to 95,271 acres (38,555 ha) in 2010 so that Chardonnay is still by a sizeable margin the most widely planted white wine grape variety in the state. Thanks to its versatility, it can be grown virtually everywhere vines are grown but it ripens so early in the hottest parts such as in the Central Valley that it has no time to develop much flavour and often has to rely instead on residual sugar to make it sell. California\u2019s Chardonnays have in general tasted sweeter than most \u2013 often because of relatively high alcohol \u2013 but as cooler territories in Sonoma Coast and the Central Coast are colonized by the vine, the proportion of nervy, mineral Chardonnays produced is increasing. Although much of Napa Valley proper is too hot to produce exciting Chardonnay, Carneros has considerable acreage of the variety, which was often planted with sparkling-wine production in mind. Russian River Valley is another hotspot for Chardonnay quality, sometimes too hot nowadays. Monterey has produced more than its fair share of fine, sometimes grassy Chardonnays, and the variety spearheaded the extraordinary expansion of the vine in the Central Coast. Virtually every California wine producer makes Chardonnay, often a wide array of them. Those who have enjoyed real acclaim for theirs include Au Bon Climat, Chalone, DuMol, Dutton Goldfield, Kistler, Kongsgaard, Marcassin, Ramey, Ridge, Sandhi and Stony Hill.',
      'Wente of Livermore played a notable part in the history of California Chardonnay, producing the first varietal example in 1936, and being responsible for the so-called Wente clone, or field selection of the variety, that was widely planted all along the Pacific coast and is well suited to warm climates. Robert Young of Alexander Valley later propagated and then sold widely his own successful clone based on a Wente selection.',
      'Oregon\u2019s climate proved too cool for this and other selections of Chardonnay imported from California and for years Oregon\u2019s Chardonnay was wildly outshone by its PINOT GRIS. But this century, plantings of Chardonnay clones imported from Burgundy known generically as \u2018Dijon clones\u2019 have increased dramatically, as has the quality of the state\u2019s Chardonnay, which can display appetizing stone-fruit quality. Dijon clones 95 and 96 have smallish berries that can impart useful intensity of flavour even at relatively low ripeness levels. The state had 1,008 acres (408 ha) of Chardonnay in 2008, with those who have already proved superior Chardonnay producers including Bergstr\u00f6m, Chehalem, Domaine Drouhin, Eyrie, Ponzi and Rex Hill.',
      'In Washington State,\nChardonnay is still the most planted white wine grape, with a total of 7,654 acres (3,098 ha) in the ground in 2011 \u2013 but RIESLING (6,320 acres/2,558 ha), supposedly the state\u2019s white-wine speciality, has been catching up. There has been much less experimentation with clonal diversity than in Oregon but the likes of Abeja, Arbor Crest and the dominant Ch\u00e2teau Ste Michelle have produced superior examples, helped by the crisp acidity that characterizes the state\u2019s wines.',
      'Chardonnay is grown in virtually every American state with vineyards of note. Idaho has a long tradition of making particularly open examples. Texas had 360 acres (146 ha) in 2008 and Virginia 474 acres (192 ha) in 2010, making it the state\u2019s most planted variety by quite a margin. Chardonnay is even more widely planted in New York State (981 acres/397 ha in 2006), where it makes rather richer wines in Long Island than in the cooler climes of the Finger Lakes, where RIESLING is better suited to surviving the coldest winters. The style of American Chardonnay \u2013 big, bold, oaked and vaguely sweet \u2013 can be remarkably similar no matter where it was grown.',
      'Across the border in Canada, Chardonnay, with about 2,180 acres (882 ha) in 2009, is the single most planted and most admired variety in Ontario. Indeed, Canada has long seized the global high ground for its Chardonnay with relatively subtle, sophisticated offerings from the likes of Clos Jordanne, Closson Chase, Norman Hardie and Southbrook vindicating this. Prince Edward County\u2019s newish plantings are especially promising. There are minor plantings in Nova Scotia and Qu\u00e9bec and there were 866 acres (350 ha) of the variety in British Columbia in the west in 2008 but PINOT GRIS was more widely planted, PINOT BLANC arguably more successful.',
      'Chardonnay is also grown to a limited extent in Mexico, mainly in Baja California and Coahulia de Zaragoza, but the climate tends to be a bit hot for this early-ripening variety.',
      'In Argentina in 2008 there were 6,342 ha (15,671 acres), mostly in Mendoza, with lesser plantings in San Juan, La Rioja and Neuqu\u00e9n, making Chardonnay the second most widely planted white wine variety after the country\u2019s speciality TORRONT\u00c9S (if you exclude the much more basic PEDRO GIM\u00c9NEZ). Argentine Chardonnays can show surprising finesse, which superior practitioners such as Catena put down to altitude but the Californian Paul Hobbs of Vi\u00f1a Cobos suggests is down to the particularly stony soils.',
      'In Chile Chardonnay\u2019s total of 8,549 ha (21,125 acres) in 2008 made it the most widely planted white variety, although SAUVIGNON BLANC was almost as widely planted. The most extensive plantings are in cool Casablanca (1,845 ha/4,559 acres) but also Maule (1,597 ha/3,946 acres), Curic\u00f3 (1,440 ha/3,558 acres) and Colchagua (1,288 ha/3,183 acres). It is planted in all the main wine-growing valleys to some extent, even as far south as Malleco, where Vi\u00f1a Aquitania\u2019s Sol de Sol has shown the potential for Chardonnay at Chilean viticulture\u2019s southern limit. The quality of Chilean Chardonnay improved considerably in the early years of this century with oak increasingly mastered. Some of the most successful have been Matetic\u2019s in San Antonio, Amayna in Leyda, Maycas del Limar\u00ed in Limar\u00ed, Haras de Pirque in Maipo and Err\u00e1zuriz Wild Ferment in Aconcagua.',
      'Brazil\u2019s 642 ha (1,586 acres) of Chardonnay in 2007 were grown mainly for sparkling wine. Uruguay had 135 ha (334 acres) in 2008 and Bolivia 21 ha (52 acres) in 2009. It was also grown to a limited extent in Peru.',
      'Australia and Chardonnay have been inextricably linked ever since the 1980s, when predictable, often heavily oak-chipped, Chardonnay was Australia\u2019s chief weapon in conquering the world. By 2008 there were 31,564 ha (77,996 acres) in the ground and Chardonnay was the country\u2019s most planted white wine grape by far \u2013 although the Chardonnay explosion did not begin until 1971, when Tyrrell\u2019s launched their Vat 47 varietal called, with remarkable prescience, Pinot Chardonnay. Today the biggest plantings are in the hot, inland volume-producing regions of Riverland, Murray Darling and Riverina. Perhaps the single quickest national transformation of a wine style was the refinement of Australian Chardonnay in the mid 2000s, when it became clear that \u2018oaky\u2019 had become a pejorative term for the world\u2019s wine drinkers. Australian Chardonnay today is crisp, occasionally even lean and angular, but some of the finest, and certainly most technically dependable, Chardonnays in the world now come from the state of Victoria from the likes of Curly Flat and Bindi in the Macedon Ranges; Giaconda in Beechworth; Giant Steps, De Bortoli, PHI in the Yarra Valley; growers in Henty who contribute to Penfolds\u2019 Yattarna; and many small estates in the Mornington Peninsula. But subtle, sophisticated, ageworthy Chardonnays are also made in Western Australia (Cullen, Leeuwin Estate, Moss Wood), the Adelaide Hills (Grosset, Petaluma, Shaw and Smith), Tasmania (Derwent Estate, Freycinet) and cooler spots in New South Wales such as Orange.',
      'New Zealand had 3,769 ha (9,313 acres) of Chardonnay planted in 2008, making it the second most planted white but way behind SAUVIGNON BLANC. With its naturally high acidities, the country should produce fine Chardonnay but Sauvignon has been so very much more fashionable that Chardonnay has languished. Growers in its New Zealand base, the east coast of the North Island, especially Gisborne, have been struggling to find buyers for their estimable grapes. Millton are one of the few to have established a reputation for their own bottlings. Most of New Zealand\u2019s best Chardonnays are characteristically fresh, and many of them distinctly burgundian. Those (too few) producers who seem to take Chardonnay seriously also include, in the North Island, Kumeu River north of Auckland, Dry River and Ata Rangi in Martinborough, Craggy Range and Trinity Hill in Hawke\u2019s Bay. In the South Island, Neudorf of Nelson, Sacred Hill and St Clair of Marlborough, Mountford and Bell Hill in Canterbury, and Felton Road in Central Otago keep the Chardonnay fire burning.',
      'South Africa, like New Zealand, has several other white-wine distractions, notably CHENIN BLANC, its most planted variety of all, as well as COLOMBARD and SAUVIGNON BLANC, the latter recently overtaking Chardonnay in terms of total vineyard area. In\n2008 in Cape winelands there were 8,255 ha (20,399 acres) of Chardonnay, about 15% of the white-wine vineyard area. Cooling Antarctic influence keeps much Cape Chardonnay refreshing and in some cases remarkably long lived, even if it is not hugely valued in South Africa itself. It is planted in most wine regions, though the biggest concentrations are in Robertson, Paarl, Stellenbosch and Breedekloof, in that order. Particularly refined versions come from cooler areas such as Walker Bay (Ataraxia, Bouchard Finlayson, Hamilton Russell) and Elgin (Oak Valley and Paul Cluver). Other successes, usually a little richer, include Chamonix and Boschendal in Franschhoek, and Jordan, Rustenberg and Sterhuis in Stellenbosch.',
      'China is the land of red wine and so only 498 ha (1,231 acres) of Chardonnay were recorded in 2009, while Japan has a much longer history of growing Chardonnay, and the big producers such as Suntory and Ch\u00e2teau Mercian make really quite creditable copies of white burgundy from some of the country\u2019s 602 ha (1,488 acres) of the variety, mainly in the Nagano and Yamagata prefectures. A little is also grown in India \u2013 and virtually everywhere else in the world that claims a wine industry.',
    ],
  },
  {
    name: 'CHARENTSI',
    synonyms: ['Charentzi'],
    description: 'Cold-hardy Armenian hybrid.',
    'origin and parentage': [
      'Charentsi is a complex hybrid obtained in 1961 by S A Pogosyan at the Armenian viticultural research centre in Merdzavan, just west of the capital, Yerevan, by crossing Seyanets C 1262 with KARMRAHYUT, in which Seyanets C 1262 is a hybrid between a vine of Vitis amurensis Ruprecht and CSABA GY\u00d6NGYE. It is therefore a sibling of NERKARAT.',
    ],
    'where its grown': [
      'Kazumoff, based in western Armenia, blend Charentsi with its parent KARMRAHYUT to make a sweet fortified wine.',
    ],
  },
  {
    name: 'CHASAN',
    synonyms: ['INRA 1527-78'],
    description: 'Low-acid French cross grown mainly in the Languedoc.',
    'origin and parentage': [
      'A PALOMINO FINO \u00d7 CHARDONNAY cross obtained by Paul Truel in 1958 at the INRA (Institut National de la Recherche Agronomique) research centre in Montpellier, southern France, and named after its progenitors (Palomino Fino is known as List\u00e1n in France).',
    ],
    'where its grown': [
      'Recommended in every French winemaking d\u00e9partement except the Rh\u00f4ne yet there were just 862 ha (2,130 acres) in France in 2008, about half of that area in the Aude in the Languedoc. The wines bear some resemblance to CHARDONNAY, with which it is often blended, and may be lightly aromatic but they are prone to oxidation in the cellar and acidity tends to be uncomfortably low unless it is planted in cooler sites. Producers of varietal wines include Domaine Hautes Terres de Comberousse and Domaine de Mairan in the Languedoc. Domaine de Genouillac in Gaillac, south-west France, used to produce a varietal wine but now include it in their Burgale Blanc blend.',
    ],
  },
  {
    name: 'CHASSELAS',
    synonyms: [
      'Amber Chasselas',
      'Bar-sur-Aube',
      'Bassiraube',
      'Blanchette',
      'Bois Rouge',
      'Bon Blanc',
      'Chasselas Bianco',
      'Chasselas Blanc',
      'Chasselas Cioutat',
      'Chasselas Croquant',
      'Chasselas de Bar-Sur-Aube',
      'Chasselas de Fontainebleau',
      'Chasselas de Moissac',
      'Chasselas de Montauban',
      'Chasselas de Thomery',
      'Chasselas Dorada',
      'Chasselas Dorato',
      'Chasselas Dor\u00e9',
      'Chasselas Fendant',
      'Chasselas Giclet',
      'Chasselas Jaune Cire',
      'Chasselas Musqu\u00e9',
      'Chasselas Piros',
      'Chasselas Plant Droit',
      'Chasselas Queen Victoria',
      'Chasselat',
      'Dorin',
      'Elba Toro',
      'F\u00e9her Chasselas',
      'Fendant',
      'Fendant Blanc',
      'Fendant Roux',
      'Fendant Vert',
      'Franceset',
      'Franceseta',
      'Frauentraube',
      'Gelber Gutedel',
      'Gutedel',
      'Gutedel Weiss',
      'Junker',
      'Krachgutedel',
      'Lausannois',
      'Mornen',
      'Mornen Blanc',
      'Moster',
      'Perlan',
      'Pinzutella',
      'Plemenka',
      'Plemenka Bela',
      'Queen Victoria White',
      'Rde\u010da \u017dlahtina',
      'Rougeasse',
      'Royal Muscadine',
      'Sch\u00f6nedel',
      'Shasla Belaya',
      'Silberling',
      'S\u00fcssling',
      'Temprana Agoste\u00f1a',
      'Temprana Media',
      'Temprana Tard\u00eda',
      'Tempranillo de Nav',
      'Temprano',
      'Temprano Blanco',
      'Viviser',
      'W\u00e4lsche',
      'Weisser Gutedel',
      'Weisser Krachgutedel',
    ],
    description:
      'French Switzerland\u2019s characteristic variety producing soft, occasionally distinguished but often pretty ordinary, whites. Widely grown for juice and the table.',
    'origin and parentage': [
      'Chasselas is a very old variety known under numerous synonyms across the world. Its earliest mention supposedly appeared in 1539 in the Kreutterbuch by the German botanist Hieronymus Bock (Aeberhard 2005): \u2018Dessgleichen gross und kleyn Fr\u00e4nkisch/eins anderen namen/Edel oder Lautterdrauben\u2019 (\u2018The same big and small Fr\u00e4nkisch/also named/Edel or Lautterdrauben\u2019). The name Edeldrauben (edel means \u2018noble\u2019 and drauben stands for Trauben, meaning \u2018grapes\u2019) is probably the precursor of Gutedel (\u2018good noble\u2019) that occurs later in W\u00fcrttemberg in the Basler naturalist Johann Bauhin\u2019s (1650) Historia Plantarum Universalis, while the name Lautterdrauben most likely corresponds to the local synonyms Luter, Wyssluter and Gutluter that were still in use for Chasselas around 1850 in Aargau in northern Switzerland (Aeberhard 2005). Bauhin was also the first to cite the common Swiss synonym Fendant, meaning \u2018splitting\u2019, in reference to the berries of this type of Chasselas that split instead of bursting when pressed between the thumb and the index finger.',
      'In Switzerland, the name Fendant was widely used from the eighteenth century in the canton of Vaud, both for the variety and for the wine it produced, and several subtypes of the variety were distinguished (Fendant Vert, Fendant Roux, etc.). At the beginning of the twentieth century, producers started using the name Chasselas and naming the wines after their village of origin. Introduced to the neighbouring canton of Valais around 1850, Chasselas under the name Fendant became so common and successful that the Valaisans have now protected the name and claimed it as their own.',
      'The official ampelographic name Chasselas first appeared in 1654 in Les d\u00e9lices de la campagne by Nicolas de Bonnefons (R\u00e9zeau 1997): \u2018Muscats, Muscadets, Genetins, Chasselats & autres qui ont le Grain plus charnu, sont bons \u00e0 faire les Vins doux & Bourus\u2019 (\u2018Muscats, Muscadets, Genetins, Chasselats and others that have fleshier berries are good to make sweet wines and cloudy, part-fermented wines straight from the barrel\u2019). This name undoubtedly derives from the village of Chasselas near M\u00e2con in Sa\u00f4ne-et-Loire in Burgundy, whence the first cuttings were probably distributed in France.',
      'A recent genetic study comparing Chasselas\u2019 DNA with that of more than 500 varieties from eighteen countries in Europe and the Near East (Vouillamoz and Arnold 2009) has ruled out any possibility of a Near East origin for Chasselas. Combining historical data with genetic clustering, the study points to the region around Lac L\u00e9man (Lake Geneva) as the most likely origin of Chasselas. This is consistent with Vavilov\u2019s (1926) theory that varieties usually come from the place where the greatest diversity of forms is observed. In addition, a parent\u2013offspring relationship was found between Chasselas and MORNEN NOIR from the Vall\u00e9e du Rh\u00f4ne (Vouillamoz and Arnold 2009), which explains why Chasselas is called Mornen Blanc near M\u00e2con, and why Mornen Noir was called Chasselas Noir by the French ampelographer Victor Pulliat.',
      'DNA profiling has also shown that Chasselas Rose Royal, Chasselas Rouge and Chasselas Violet (or K\u00f6nigsgutedel in Austria and Germany) all have the same DNA profile as the white Chasselas and are therefore colour mutations. It has also shown that Chasselas Musqu\u00e9 is an aromatic mutation of Chasselas (Vouillamoz and Arnold 2009).',
      'Chasselas was used to breed many table grapes and a few wine grape varieties: ARNSBURGER, CHARMONT, DORAL, HUXELREBE, MUSCAT FLEUR D\u2019ORANGER, MUSCAT OTTONEL and NOBLING.',
    ],
    'where its grown': [
      'Chasselas is widely planted, and also widely disparaged \u2013 except in its probable homeland of Switzerland, where it is said to be particularly expressive of its place of origin, the wine changing markedly according to soil and climate \u2013 but this may be largely due to the inherent lack of aroma and flavour in the wines.',
      'Plantings of Chasselas in France \u2013 where it is commonly grown for the table and for juice \u2013 are in decline but still extensive (2,442 ha/6,034 acres in 2009). In Alsace it is in sharp decline and tends to disappear into Edelzwicker blends, and while most of the varietal Chasselas labelled Pouilly-sur-Loire are distinctly inferior to the Pouilly-Fum\u00e9 produced in the same region, there are a few exceptions such as Serge Dagueneau et Filles\u2019 bottling from centenarian vines. In Haute-Savoie, however, on the south side of Lac L\u00e9man, it is the main variety in the Vin de Savoie crus of Cr\u00e9py, Marignan, Marin and Ripaille, where it has a long history. The best examples are from Ch\u00e2teau de Ripaille and Delalex in Marin. A little is also found in Italy and Spain.',
      'Chasselas has been known in Germany since the sixteenth century, and known as Gutedel since the seventeenth. Most of the 1,136 ha (2,807 acres) in 2008 were in the Markgr\u00e4flerland in southern Baden. Here the best wines, particularly those from old vines on calcareous soils, have flavours of almonds and hay and a distinct minerality. Ziereisen take the trouble to make several single-vineyard bottlings, the best being Steingr\u00fcble; other recommended producers include L\u00e4mmlin-Schindler and D\u00f6rflinger. There are a few outposts beyond Baden, such as the Frenchman Fr\u00e9d\u00e9ric Four\u00e9e\u2019s in Sachsen.',
      'Chasselas is the symbol of Swiss white wine, being the most widely planted white wine variety in Switzerland, with a total of 4,013 ha (9,916 acres) in 2009, although the overall area is in decline. More than half of it is planted in the canton of Vaud, where Chasselas represented 62% of the production area in 2009 (2,346 ha/5,797 acres), distributed in six main regions: Vully, Bonvillars, C\u00f4tes-de-l\u2019Orbe, La C\u00f4te, Lavaux and Chablais, each one with its own terroir. Some of the villages\u2019 or appellations\u2019 names have acquired a local reputation almost as great as that of the crus in neighbouring Burgundy. Notable examples are (from west to east) Mont-sur-Rolle, F\u00e9chy, Morges, Villette, Cully, Epesses, Calamin, D\u00e9zaley, Saint-Saphorin, Chardonne, Yvorne and Aigle. Recommended producers include La Colombe, Henri & Vincent Chollet, Henri Cruchon, Louis Bovard and Pierre Monachon. The second most important canton for Chasselas is the Valais (1,051 ha/2,597 acres in 2009), where the mosaic of terroirs combined with the warm, dry climate result in more powerful, aromatic, \u2018sunny\u2019 Chasselas, called here Fendant. The best villages for Fendant are (from west to east) Martigny, Fully, Saillon, Chamoson, Ardon, V\u00e9troz, Sion, Saint-L\u00e9onard and Sierre, and recommended producers include Cave des Bernunes, Simon Maye & Fils, Rouvinez and Domaine des Muses. Then comes the canton of Neuch\u00e2tel, with Caves du Ch\u00e2teau d\u2019Auvernier being the best-known producer. In January, just a few months after harvest, they bottle a traditional and eagerly awaited non filtr\u00e9 (\u2018unfiltered\u2019) Chasselas. Gen\u00e8ve also produces interesting Chasselas in Satigny, Peissy and Dardagny, and a much smaller amount is produced in German-speaking eastern Switzerland under the name Gutedel.',
      'The variety is also known as Gutedel in Austria, where it was once an important ingredient in the Viennese speciality Gemischter Satz, a multi-variety white blend often sourced from interplanted vineyards. However, it is now more commonly used as a table grape; there are probably fewer than 50 ha (124 acres) planted for wine and it is rarely found as a varietal.',
      'Chasselas is widely planted in Romania but mostly for the table, also quite widely in Hungary (1,804 ha/4,458 acres in 2008), and to a lesser extent in Serbia and Russia.',
      'In California, where it is known as Chasselas Dor\u00e9, that grown in the Pagani Vineyard in Sonoma is bottled as a varietal. Quails\u2019 Gate produce a Chasselas in British Columbia, western Canada, and there were 404 ha (998 acres) in Chile in 2008.',
      'A little is also grown in New Zealand.',
    ],
  },
  {
    name: 'CHATUS',
    synonyms: [
      'Bolgnino',
      'Bourgnin',
      'Brachet',
      'Brunetta',
      'Chanu',
      'Chatelos',
      'Chatelus',
      'Chatos',
      'Ch\u00e2tut',
      'Corbeil',
      'Corbel',
      'Corbelle',
      'Corb\u00e8s',
      'Corbesse',
      'Mouraud',
      'Mouret',
      'Mourre',
      'Nebbiolo di Dronero',
      'Nebbiolo Pairol\u00e8',
      'Neiret',
      'Neiret Pinerolese',
      'Neretto',
      'Ouron',
      'Houron',
      'Persagne-Gamay',
      'Scarlattin',
    ],
    description:
      'Ancient, rare tannic Ard\u00e8che variety also found in Piemonte.',
    'origin and parentage': [
      'Chatus is an old variety mentioned by Olivier de Serres (1600), the \u2018father\u2019 of agronomy, as one of the best varieties of the Vivarais (today\u2019s Ard\u00e8che). Before the arrival of phylloxera, Chatus was widespread in France, from the Alps to the Massif Central, mainly in the Ard\u00e8che, where it was the main variety in the southern part of the region, and in the Dr\u00f4me, Is\u00e8re and Savoie. Its name might derive from Chatus, a lieu-dit in Verclause in the Dr\u00f4me.',
      'DNA profiling has established that Neiret (or Bourgnin), planted from the Valle Maira to the Val d\u2019Ossola along the Alpine foothills of Piemonte, north-west Italy, is identical to Chatus (Schneider et al. 2001). DNA parentage analysis strongly suggests a parent\u2013offspring relationship with Pougnet, an old variety from the Ard\u00e8che that is no longer cultivated, and shows that S\u00e9r\u00e9n\u00e8ze de Voreppe, also no longer cultivated, is a likely natural progeny of Chatus and GOUAIS BLANC (Vouillamoz), which tends to favour south-eastern France as the centre of origin for Chatus, most likely in the Ard\u00e8che, where it used to be cultivated with Pougnet. Indeed, Chatus is a member of the of S\u00e9rine ampelographic group (see p XXVII), which does not include any Piemontese varieties (Bisson 2009).',
      'Thanks to DNA profiling, Chatus was recently revealed as the true parent in eight crosses made by the vine breeder Dalmasso in the 1930s that had previously been said to have NEBBIOLO in their pedigree: ALBAROSSA, CORNAREA, NEBBIERA, PASSAU, SAN MARTINO, SAN MICHELE, SOPERGA and VALENTINO NERO (Torello Marinoni, Raimondi, Ruffa et al. 2009).',
    ],
    'where its grown': [
      'In France, Chatus has almost disappeared, except in the Ard\u00e8che, where it is officially recommended and where the Cave Coop\u00e9rative Vinicole de Rosi\u00e8res and other local producers have recently been working towards its revival. It has been replanted on terraces in the C\u00e9vennes, between the villages of Rosi\u00e8res, Payzac and Largenti\u00e8re. Total plantings in 2006 were 57 ha (141 acres), down from 150 ha (371 acres) in 1958. The small berries produce wines that are deeply coloured, fresh, notably tannic and suited to oak maturation. It is sometimes blended with SYRAH.',
      'In Piemonte, north-west Italy, it is still cultivated under various synonyms, mainly in the Valle Maira, Saluzzo and Pinerolo regions, where it is usually blended with AVAN\u00c0, BARBERA, PERSAN (under the name Becu\u00e9t), NERETTA CUNEESE or PLASSA. At Poderi deI Palas, Ambrogio Chiotti makes a rare Nebbiolo di Dronero varietal wine called Desmentia, aged one year in barrel.',
    ],
  },
  {
    name: 'CHELOIS',
    synonyms: ['Seibel 10878'],
    description:
      'French-American hybrid found in Canada and the north-eastern US; wine is rather rustic on its own.',
    'origin and parentage': [
      'A hybrid made in Aubenas in the Ard\u00e8che between Seibel 5163 and Seibel 5593 obtained by Albert Seibel (see PRIOR for the full pedigree of Seibel 5163), where:',
      '\u2013\u00a0\u00a0\u00a0Seibel 5593 is a Seibel 880 \u00d7 Seibel 4202 cross (see PRIOR for the full pedigree of Seibel 880)',
      '\u2013\u00a0\u00a0\u00a0Seibel 4202 is a Bayard (or Couderc 28-112) \u00d7 Afus Ali (a Lebanese table grape also known as Dattier de Beyrouth) cross (see PRIOR for the full pedigree of Bayard).',
      'Chelois was used to breed VINCENT.',
    ],
    'where its grown': [
      'Wines are medium-bodied and can be rather earthy or rustic so that the variety is better blended with other hybrids such as CHANCELLOR or CHAMBOURCIN or made as a ros\u00e9. Introduced into Canada in 1946 and to the north-eastern US in 1948 and trialled around Lake Eyrie in Pennsylvania. There are now fewer than 40 acres (16 ha) in New York State, where Bear Pond produce a good example. Its fortunes tend to wax and wane with the variable demand for red wines.',
    ],
  },
  {
    name: 'CHELVA',
    synonyms: [
      'Chelva de Cebreros',
      'Chelva de Guare\u00f1a',
      'Eva',
      'Forastera Blanca',
      'Gabriela',
      'Guarena',
      'Mant\u00fao',
      'Mant\u00fao de Pilas',
      'Mont\u00faa',
      'Mont\u00fao de Villanueva',
      'Mont\u00fao Gordo',
      'Uva Rey',
      'Uva del Rey',
      'Uva de Puerto Real',
      'Villanueva',
    ],
    description: 'Spanish variety more popular for the table than for wine.',
    'origin and parentage': [
      'Chelva probably originates from the province of Badajoz in Extremadura, south-west Spain, where it used to be widespread. DNA profiling has confirmed that it is the same variety as Mant\u00fao or Mont\u00faa from various regions in continental Spain (Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007).',
      'Although Chelva is sometimes called Mant\u00fao Vigiriego or Vigiriego Blanco in Granada, it is not related to VIJARIEGO, which is found both in Granada and on the Islas Canarias, the name spelt in many different ways depending on producer and location. And it should not be confused with DORADILLA or DONA BRANCA, which share the synonym Forastera Blanca.',
    ],
    'where its grown': [
      'Chelva is widely grown in Extremadura, Spain, where it is authorized, among many other varieties, in the Ribera del Guadiana DO. It is also grown, to a much lesser extent, further south in Andaluc\u00eda, where it is authorized in regional wines such Vino de la Tierra de Sierra de Alcaraz but not in any of the autonomous community\u2019s DOs. There were 7,490 ha (18,508 acres) in Spain in 2008, the vast majority in Extremadura (6,495 ha/16,049 acres), the rest in Castilla-La Mancha (845 ha/2,088 acres) and Castilla y Le\u00f3n (150 ha/371 acres). Unusually, Chelva is used both for the table and for wine but most of these hectares are for wine grapes, producing rather neutral wines that generally disappear in blends.',
      'Under the names Mant\u00faa or Mant\u00fao Vigiriego or \u2013 more confusing still \u2013 Vigiriego Blanco, it is also planted in Granada, southern Spain, where is it one of the main white varieties. Producers of varietal wines here include Cuatro Vientos, Cortijo Fuentezuelas, Fuente Victoria (with 5% MACABEO), Los Garcia de Verdevique and Los Martos.',
    ],
  },
  {
    name: 'CHENANSON',
    synonyms: ['Chenan\u00e7on'],
    description:
      'Montpellier cross planted to a limited extent around the Mediterranean.',
    'origin and parentage': [
      'A GARNACHA \u00d7 JURAN\u00c7ON NOIR cross bred at INRA (Institut National de la Recherche Agronomique) in Montpellier, southern France, its parentage recently confirmed by DNA analysis (Di Vecchi Staraz, This et al. 2007).',
    ],
    'where its grown': [
      'Recommended throughout the French Mediterrean d\u00e9partements and in the Vaucluse with total plantings of 539 ha (1,332 acres) in 2008, although it seems to be favoured in blends rather than as a solo player. Its popularity in France most likely stems from the fact that it is more productive and more deeply coloured than its GARNACHA parent, producing supple, fruity and generous wines. Mas de l\u2019Oncle in the Pic Saint-Loup region produce a varietal wine vinified using carbonic maceration.',
      'In a rare outpost in north-east Spain, Carlos Esteva at Can Rafols dels Caus in Pened\u00e8s planted Chenanson in the 1980s and blends it with CALADOC and PORTAN in his Petit Caus red.',
    ],
  },
  {
    name: 'CHENIN BLANC',
    synonyms: [
      'Agudelo',
      'Agudillo',
      'Anjou',
      'Blanc d\u2019Aunis',
      'Capbreton Blanc',
      'Franc Blanc',
      'Gros Chenin',
      'Gros Pineau',
      'Pineau d\u2019Anjou',
      'Pineau de la Loire',
      'Plant d\u2019Anjou',
      'Ronchalin',
      'Rouchelein',
      'Rouchelin',
      'Steen',
    ],
    description:
      'Loire, and South African, speciality making crisp, sometimes long-lived, wines with varying degrees of sweetness that deserve more recognition.',
    'origin and parentage': [
      'The first mention of this variety, which comes from Anjou in the Val de Loire, France, most likely appeared under its old synonym Plant d\u2019Anjou, as suggested by historical documents from the famous Ch\u00e2teau de Chenonceau and reported by Casimir Chevalier (1864):',
      'On 3 January 1496, Thomas Bohier (often erroneously spelt Gohier) bought lands around Chenonceau, where he planted \u2018neuf arpens de vignes, de plants, qu\u2019il fit venir \u00e0 grand frais d\u2019Orl\u00e9ans, d\u2019Arbois, de Beaune et de l\u2019Anjou\u2019 (\u2018nine arpents of vineyards, with grapevines imported at great expense from Orl\u00e9ans, Arbois, Beaune and Anjou\u2019). The variety from Anjou was white and was almost certainly Chenin Blanc (Bouchard 1901a).',
      'On 18 May 1523, a vineyard on the Chenonceau estate was described as \u2018un autre cloz de vigne blanche appel\u00e9 le Plant d\u2019Anjou contenant quatre arpens ou environ\u2019 (\u2018another vineyard of a white variety called Plant d\u2019Anjou containing four arpents or so\u2019). Again, the Plant d\u2019Anjou was in all probability Chenin Blanc (Bouchard 1901a).',
      'Between 1520 and 1535, Denis Bri\u00e7onnet, the Abbot of Corm\u00e9ry in Touraine and brother-in-law of Thomas Bohier, planted various varieties from all over France in Montchenin (or Mont-Chenin), a monastery near Corm\u00e9ry. Among these varieties, the one called Plant d\u2019Anjou turned out to be the best suited to this climate.',
      'This variety was most likely named Chenin after the monastery of Montchenin, from where it was then propagated across the Touraine region, and whence it returned to its birthplace in Anjou under its new name, Chenin.',
      'This is consistent with the earliest written mention of the name Chenin Blanc in 1534 in chapter 25 of the first book of Gargantua, written by the medieval Touraine poet Fran\u00e7ois Rabelais: \u2018Et, auecques groz raisins chenins, estuuarent les iambes de Forgiez mignonnement, si bien quil feut tantoust guary\u2019 (\u2018And with large chenin grapes they carefully covered Forgiez\u2019s legs, so that he soon recovered\u2019). In the same book, Rabelais praised the \u2018vin pineau. O le gentil vin blanc! et, par mon ame, ce nest que vin de taffetas\u2019 (\u2018pineau wine. Oh the nice white wine! and for my soul, it is nothing else than taffeta\u2019), which is also undoubtedly Chenin Blanc.',
      'In Touraine, Chenin Blanc was also called Gros Pineau, as opposed to MENU PINEAU (the latter known historically as Arbois Blanc in the Val de Loire). Both are distinct from PINEAU D\u2019AUNIS, another variety of the Loire that is also confusingly called Chenin Noir. This might be the reason Comte Odart (1845) considered Chenin Blanc to be a white mutation of Pineau d\u2019Aunis, which is today strongly rejected by DNA profiling.',
      'A parent\u2013offspring relationship with SAVAGNIN has been discovered by DNA parentage analysis (Regner 1999), and a probabilistic analysis of DNA data has shown that TROUSSEAU, SAUVIGNON BLANC and Chenin Blanc are most likely siblings \u2013 the offspring of Savagnin and an unknown parent (Myles et al. 2011; Vouillamoz), which makes Chenin Blanc an uncle/aunt of CABERNET SAUVIGNON (see the Cabernet Sauvignon and PINOT pedigree diagrams)! This is consistent with Chenin Blanc being a member of the Messile ampelographic group (see p XXVII; Bisson 2009). In addition, Chenin Blanc and GOUAIS BLANC have been shown to be the natural parents of BALZAC BLANC, COLOMBARD and MESLIER SAINT-FRAN\u00c7OIS (Bowers et al. 2000).',
      'Cipriani et al. (2010) have recently suggested through DNA parentage analysis that Chenin Blanc is a possible natural cross between SAUVIGNON BLANC and SAVAGNIN (under the name Traminer Rot) but this is not consistent with Myles et al. (2011), and it can be rejected by a thorough parentage analysis using sixty DNA markers (Vouillamoz).',
      'Unexpectedly, the DNA profile of the Spanish variety Agudelo, said to be native to the Betanzos area in Galicia and also cultivated in Alella and Pened\u00e8s, matched that of Chenin Blanc (Mart\u00edn et al. 2006).',
      'Chenin Blanc was recently used to breed CHENEL in South Africa.',
    ],
    'where its grown': [
      'Chenin Blanc\u2019s influence in France is declining. Its total area of 9,828 ha (24,286 acres) in 2008 (down from 16,594 ha/41,005 acres in 1958) represented less than 1.2% of the total French vineyard. Chenin Blanc is most widespread in the d\u00e9partements of Maine-et-Loire, in the regions around Angers (5,044 ha/12,464 acres), Indre-et-Loire, around Tours (3,186 ha/7,873 acres), and Loir-et-Cher upriver towards Blois (296 ha/731 acres). Further upriver is SAUVIGNON BLANC country, whereas MELON dominates the Muscadet vineyards at the mouth of the Loire.',
      'Most of the Chenin Blanc is grown in the middle of the Val de Loire, where it produces one of the widest arrays of wine styles of any single variety. Chenin is by far the most important base of the Loire\u2019s many sparkling wines, Cr\u00e9mant de Loire and sparkling Saumur and Vouvray in particular, characterized by a tight bead, fairly light body and the slightest edge of honey. The best sparkling Vouvrays can have a long and nuanced life. This is also a feature of arguably Chenin\u2019s most glorious incarnations, the late-picked sweet wines of Vouvray, Montlouis, Bonnezeaux and Quarts de Chaume in which high sugar levels, usually concentrated by botrytis, are counterweighted by notably high acid levels that can keep the wines tangy and developing positively in bottle for decades. Common or garden Vouvray, Saumur and Anjou can be little more than a medium-dry to medium-sweet white wine with marked acidity and, sometimes, sulphur, but sulphur levels have been falling along with yields in the Loire so that more and more wines have real Chenin flavours of honey, straw and apples. There was a time when Savenni\u00e8res was virtually the only dry Chenin of real interest in the Loire but this has been changing and now most appellations are producing fine dry Chenins, too, often leaning, sometimes too heavily, on oak and lees stirring. Few of them have quite the cordite minerality of a top Savenni\u00e8res though. Producers with a long history of high quality include Foreau and Huet in Vouvray; Fran\u00e7ois Chidaine, and Jacky Blot of Taille aux Loups in Montlouis; Philippe Delesvaux in Saint-Aubin-de-Luign\u00e9; and Baumard, Closel and Nicolas Joly in Savenni\u00e8res.',
      'Just about the only other place where Chenin Blanc grows in France is Limoux in the hills of the western Languedoc, where it\u2019s a required ingredient (20\u201340%) in Cr\u00e9mant de Limoux along with CHARDONNAY, MAUZAC BLANC and PINOT NOIR, and is also part of the blend in still wines, with Chardonnay and Mauzac Blanc. It also contributes to C\u00f4tes de Duras and may play a role in Vins d\u2019Entraygues et du Fel and Vins d\u2019Estaing, again usually with Mauzac Blanc. About 60 ha (148 acres) of Chenin Blanc were grown on Corse in 2008.',
      'In Spain there were about 100 ha (247 acres) in 2008, almost exclusively in Catalunya. The variety is declining in Israel,\nwhere it is minor and less than half as important as it was in 1996.',
      'One part of the world where Chenin Blanc has been cultivated to a considerable extent is California, where there were still 7,223 acres (2,923 ha) in 2010, but this total has fallen by well over a half in the last decade and there have been almost no new plantings in recent years. Chenin Blanc has very few proponents in the US and is widely regarded as a blending variety valued only for its acidity. The majority of vineyards are in the hot Central Valley in Fresno, Madera and Kern counties where Chenin Blanc high yields \u2013 four or five times the usual yield for a fine Loire Chenin \u2013 and low flavour quotients are the norm. But superior, if not exactly world-shattering, varietal examples have emerged from the Clarksburg AVA, just south of Sacramento, where Dry Creek Vineyard and Ehrhardt source theirs. Others include Lucas and Lewellen (Santa Barbara County), Foxen\u2019s old-vine Chenin from the Ernesto Wickenden Vineyard (Santa Maria) and Casa Nuestra (Napa Valley). Chappellet for long produced a lively example from vines planted in the 1960s on Pritchard Hill high above the Napa Valley. Those vines were ripped out in the mid 2000s but some are said to have been replaced.',
      'Washington State has just over 200 acres (81 ha) of Chenin, and benefits from the support of the dominant producer, Ch\u00e2teau Ste Michelle, which produces both late-harvest and icewine versions. Kiona also make sweet wines from it. About 300 acres (121 ha) are grown in Texas but it is not regarded with much enthusiasm. Paumanok grow some successfully on New York State\u2019s Long Island and there are various small plantings in several other states but it does have an image problem \u2013 not least because the great Chenins of the Loire are, for the moment, a well-kept secret. A small amount is grown in Baja California, Aguascalientes and Coahulia de Zaragoza in Mexico, where presumably its high acidity is useful.',
      'There are very limited plantings in Chile (76 ha/188 acres), Brazil (30 ha/74 acres) and Uruguay (7 ha/17 acres) but, also in 2008, Argentina had 2,908 ha (7,186 acres) of Chenin Blanc planted, the vast majority in Mendoza and blended with other, more valuable, varieties such as CHARDONNAY.',
      'Nor do Australians have much respect for Chenin Blanc. In 2008 there were just 642 ha (1,586 acres) in Australia, mostly in the irrigated inland Riverland and in Western Australia, both the hot Swan District north of Perth and, to a lesser extent, in cooler Margaret River. For long it was an ingredient in the popular branded wine once known as Houghton\u2019s White Burgundy but nowadays it is generally used as a cheap but fresh blending partner for CHARDONNAY, S\u00c9MILLON and SAUVIGNON BLANC.',
      'In New Zealand there is virtually only one proponent of Chenin Blanc, the Millton Vineyard of Gisborne, who make the consistently superior Te Arai from it. In 2008 the country had only 50 ha (124 acres), half as much as in 2004.',
      'Chenin Blanc is grown by vine growers in the tropical vineyards of India and Thailand, presumably once again treasured for its high acidity.',
      'It is South Africa that has the biggest area of Chenin Blanc of all, 18,852 ha (46,584 acres) in 2008, making it the most widely planted variety in the country with 33% of white wine plantings and 18.6% of all plantings. It is cultivated in every wine-growing region, and is often treated as a workhorse grape, with the biggest areas in Paarl (3,326 ha/8,219 acres), Malmesbury (3,317 ha/8,196 acres) and Olifants River (2,521 ha/6,230 acres). Chenin\u2019s naturally high acidity is particularly useful in the warm Cape climate, and its relative neutrality when grown at high yields, as much of it is, means that it has been used for just about everything here, from stretching blends of more fashionable varieties to base wines for distillation. But there is a nucleus of ambitious winemakers who recognize that South Africa\u2019s old Chenin Blanc bushvines are one of the wine industry\u2019s most valuable resources and have been making truly fine, concentrated, rich but usually dry, ageworthy whites from them. Excellent examples include those of Adi Badenhorst (Swartland), De Morgenzon (Stellenbosch), De Trafford (Stellenbosch), Ken Forrester (Stellenbosch), Mullineux (Swartland, including their sumptuous sweet straw wine), Rudera (Stellenbosch) and Eben Sadie (Swartland, in blends). It is worth noting that it was only in the mid 1960s that South Africans realized that the predominant variety they called Steen, which had apparently been grown on the Cape for centuries, was in fact Chenin Blanc.',
    ],
  },
  {
    name: 'CHICHAUD',
    synonyms: ['Brunet', 'Tsints\u00e0o'],
    description:
      'Ard\u00e8che variety that can still, just, be found in southern France.',
    'origin and parentage': [
      'Chichaud is an old and almost extinct variety from the Ard\u00e8che in southern France, once grown in Aubenas, Vals, Joyeuse, Argenti\u00e8re and Privas. Chichaud belongs to the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009). Its etymology is unknown but the name could derive from the family name Chichaud.',
    ],
    'where its grown': [
      'This variety lost out in the post-phylloxera replanting in France and has more or less disappeared. However, Mas de La Salle in the C\u00e9vennes have recently planted a few experimental ares of Chichaud, BOURRISQUOU and Vituaigne and will increase the area if the results of vinification are encouraging.',
    ],
  },
  {
    name: 'CHIDIRIOTIKO',
    synonyms: ['Kalloniatiko'],
    description:
      'Greek variety revived by one producer on the island of L\u00e9svos (Lesbos).',
    'origin and parentage': [
      'Chidiriotiko is said to be an indigenous variety from the village of Chidira in the western part of the Greek island of L\u00e9svos in the Aegean, where it was almost completely destroyed by phylloxera in the 1950s. Convinced that this local variety was responsible for what was once one of the most famous and expensive wines in ancient Greece, Chidira-born Dimitris Lambrou replanted a vineyard from the few surviving vines on the island in 1985. It was claimed that the DNA profile of Chidiriotiko was unique but the analysis remained unpublished and our request to check it was turned down. It is therefore not possible to confirm that Chidiriotiko is a unique variety and, according to the viticulturist Haroula Spinthiropoulou, it may even be a blend of varieties.',
    ],
    'where its grown': [
      'Planted only on the island of L\u00e9svos, Greece,\nwhere Methymneos produce both red wines and a white (blanc de noirs) that are low in acidity. The red is light in colour with floral and mineral notes and marked tannins; the white is more citrusy. The total area planted had expanded to around 60 ha (148 acres) by 2010.',
    ],
  },
  {
    name: 'CHINURI',
    synonyms: ['Chinabuli', 'Kaspura', 'Kaspuri Tetri', 'Okroula', 'Tchinouri'],
    description:
      'High-acid Georgian variety used for both still and sparkling wines.',
    'origin and parentage': [
      'Chinuri is indigenous to the Kartli region of southern central Georgia. Its name derives from chinebuli, meaning \u2018excellent\u2019 in Georgian (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Chinuri is cultivated mainly in the Mukhrani, Ateni and Kaspi subregions of Kartli, southern central Georgia, where it is generally the dominant variety in the fresh-fruited and crisp sparkling wines of the Atenuri appellation, which may also include GORULI MTSVANE and possibly ALIGOT\u00c9 in the blend. Although the must tends to have moderate sugar levels and high acidity, it shows good potential for the production of still as well as sparkling wines. Bagrationi make a typical sparkling version while Iago Wine, Ateni Sioni Monastery and Pheasant\u2019s Tears all make wines vinified in the traditional clay vessels known as qvevri. There were 859 ha (2,123 acres) of Chinuri in Georgia in 2004.',
    ],
  },
  {
    name: 'CHKHAVERI',
    synonyms: ['Tchkhaveri', 'Vanis Chkhaveri'],
    description: 'Minor but versatile pink-skinned Georgian variety.',
    'origin and parentage': [
      'Chkhaveri is indigenous to the regions of Guria and Adjara in western Georgia and to Apkhazeti, north-western Georgia.',
    ],
    'where its grown': [
      'This versatile variety, used to produce dry, fresh, naturally sweet and sparkling white and pink wines with red-fruit flavours, is grown mainly in the regions of Guria (particularly in the Bakhvi, Askana and Sachamiaseri subregions) and Adjara in south-western Georgia, and to a lesser extent further north in Apkhazeti (Akhalisopeli, Likhni and Pshapi). There were 20 ha (49 acres) of Chkhaveri in Georgia in 2004.',
    ],
  },
  {
    name: 'CHONDROMAVRO',
    synonyms: [
      'Chondromavrouda',
      'Chondromavroudi',
      'Chondromavroudo',
      'Khondromavroud',
    ],
    description: 'Very rare Greek variety planted in Makedon\u00eda.',
    'origin and parentage': [
      'Chondromavro is local to the region of Koz\u00e1ni in western Makedon\u00eda in the northern part of mainland Greece.',
    ],
    'where its grown': [
      'Chondromavro is grown only in Siatista, south east of Koz\u00e1ni, Greece, where it is found in just a few of the region\u2019s many old interplanted vineyards alongside XINOMAVRO and MOSCHOMAVRO. Diamantis have replanted the variety at 800\u2013920 m above sea level and include it in their Liasto blend, a sweet red wine made from sun-dried grapes, as well as in a ros\u00e9 and a blanc de noirs. The high sugar level in the berries is very important for Liasto, which generally has an aroma of dried figs. Acidity is generally low and tannins soft.',
    ],
  },
  {
    name: 'CHOUCHILLON',
    synonyms: ['Faux Viognier'],
    description: 'Light-skinned variety recently rescued in central France.',
    'origin and parentage': [
      'Chouchillon was first mentioned by Rougier in 1899 in the vineyard of Chagnon, between Saint-\u00c9tienne and Lyon in central France (Galet 1990).',
    ],
    'where its grown': [
      'Only a few Chouchillon vines remain, scattered among old VIOGNIER vineyards in Condrieu, northern Rh\u00f4ne, France, which might explain its confusion with Viognier and its synonym Faux Viognier. These few vines were recently propagated by the Association pour la Restauration et le D\u00e9veloppement du Vignoble des Coteaux du Gier and an experimental 100% Chouchillon vineyard is planned for Genillac in the Coteaux du Gier area, between Lyon and Saint-\u00c9tienne. Chouchillon is not yet included in the French official register of varieties.',
      'In North Carolina, Davest\u00e9 Vineyards is notable for its cultivation of uncommon varieties such as COT, Chouchillon and RKATSITELI; the last two are often included in their Trillium blend.',
    ],
  },
  {
    name: 'CIANORIE',
    synonyms: [
      'Canore',
      'Canorie',
      'Chianorie',
      'Cianoria',
      'Cjanorie',
      'Rossarie',
      'Vinosa',
    ],
    description:
      'Old and very minor dark-skinned variety from Friuli, north-east Italy.',
    'origin and parentage': [
      'Known in the province of Pordenone in Friuli as early as the seventeenth century, Cianorie is said to originate from Gemona del Friuli in the province of Udine. Its name comes from the local dialect word cjane, Italian canna, supposedly related to the expression bere a canna, meaning \u2018to drink straight from the bottle\u2019, hinting at the lightness of the wine.',
    ],
    'where its grown': [
      'Cianorie was formerly cultivated all over Friuli in north-east Italy, in particular in the provinces of Pordenone and Udine, mainly in the communes of Gemona del Friuli, Castelnuovo del Friuli and Pinzano al Tagliamento. Together with other old local varieties CIVIDIN, FORGIARIN, PICULIT NERI, SCIAGL\u00ccN and UCEL\u00d9T, it was saved from extinction by Emilio Bulfon, with the help of the ampelographers Antonio Cal\u00f2 and Ruggero Forti. Emilio Bulfon is one of the few producers to craft varietal wines from these grapes. Cianorie is light-bodied with aromas of black fruits, cloves and flowers and moderate acidity. Together with Cividin, Cianorie was included in the Italian national register of varieties in 2006.',
    ],
  },
  {
    name: 'CILIEGIOLO',
    synonyms: [
      'Albana Nera',
      'Brunellone',
      'Canaiolo Romano',
      'Ciliegino',
      'Ciliegiolo di Spagna',
      'Mazz\u00e8se',
      'Riminese Nero',
      'Sangiovese Polveroso',
    ],
    description:
      'Cherry-flavoured Italian red with untapped potential, especially in Toscana. Parent of Sangiovese.',
    'origin and parentage': [
      'Probably the earliest mention of Ciliegiolo is in Soderini\u2019s (1600) treatise on the varieties cultivated around Firenze in Toscana, Italy, in which a Ciriegiuolo Dolce is described as a grape with long clusters, large berries and a sweet (dolce) and fragrant flavour, best grown on warm soils. This description reasonably matches the modern Ciliegiolo. The name Ciliegiolo (Italian for \u2018small cherry\u2019) comes from the cherry aroma of the berries.',
      'According to tradition, Ciliegiolo was introduced from Spain around 1870 by pilgrims coming back from Santiago de Compostela, which would justify its synonym Ciliegiolo di Spagna. There does not seem to be any relationship between Ciliegiolo and modern Spanish grapes, however (Vouillamoz).',
      'Ciliegiolo has often been mistaken for AGLIANICO, which might explain why Crespan et al. (2002) erroneously identified Ciliegiolo with AGLIANICONE by DNA profiling. In fact a Ciliegiolo plant had been mislabelled as Aglianicone in their collection, whereas the true Aglianicone has a quite distinct DNA profile (Manna Crespan, personal communication).',
      'In a study carried out in 2006 at the University of Bologna, three accessions named Albana Nera in Emilia-Romagna (in the Imola, Riolo Terme and Ravenna regions) turned out to be identical to Ciliegiolo, although the latter is not related to the white-berried ALBANA. More recently, several unexpected synonyms of Ciliegiolo were found by DNA profiling of vines found in Toscana (Di Vecchi Staraz, This et al. 2007): Brunellone, Mazz\u00e8se, Riminese Nero and Sangiovese Polveroso. This last and misleading synonym might explain why Ciliegiolo and SANGIOVESE are often wrongly considered to be identical in Emilia-Romagna.',
      'By DNA parentage analysis, Crespan et al. (2002) discovered a parent\u2013offspring relationship between Ciliegiolo and SANGIOVESE. Vouillamoz, Imazio et al. (2004) later showed that Sangiovese is a natural progeny of Ciliegiolo and CALABRESE DI MONTENUOVO (see Sangiovese for details).',
      'Recent DNA parentage studies have suggested that Ciliegiolo may be a natural cross between SANGIOVESE and Muscat Rouge de Mad\u00e8re, aka Moscato Violetto (Di Vecchi Staraz, This et al. 2007; Cipriani et al. 2010), the latter a possible natural MAMMOLO \u00d7 MUSCAT BLANC \u00c0 PETITS GRAINS cross, but this does not fit with the parentage of Sangiovese (Vouillamoz, Monaco et al. 2007), and since the proposed parentage showed some DNA discrepancies, it would need further investigation.',
    ],
    'where its grown': [
      'Mostly cultivated in Toscana, Italy, where it is often used to soften SANGIOVESE\u2019s edges in Chianti wines. Since the early 1990s, as the variety has been reappraised and not found wanting, several 100% Ciliegiolo wines have appeared on the market, especially in the Maremma, where Ciliegiolo is often made as a nouveau-style wine called Novello Toscano using carbonic maceration. Ciliegiolo is also cultivated in many other regions of Italy (Valle d\u2019Aosta, Piemonte, Liguria, the Veneto, Emilia-Romagna, Marche, Umbria, Lazio, Abruzzo, Molise, Campania, Puglia, Basilicata, Sicilia). The wines have an intense red colour, aromas of cherries, strawberries and spices; they may be juicy or full-bodied and show a certain resemblance to wines made from CINSAUT. They occasionally lack acidity, which is why they complement Sangiovese so well.',
      'The Italian census (2000) recorded 3,078 ha (7,606 acres) but only about 10% of that total is used in DOC or DOCG wines. In Toscana, these include: Parrina, Colline Lucchesi, Montecarlo, Val di Cornia, Morellino di Scansano, Chianti and Chianti Classico. The Petraviva and Val di Cornia DOCs also allow for a varietal Ciliegiolo, as does Umbria\u2019s Rosso Orvietano DOC and Abruzzo\u2019s Controguerra DOC.',
      'In Liguria, Ciliegiolo is authorized in Golfo del Tigulio (varietal or blended, still, sparkling or novello), Colli di Luni and Val Polcevera DOCs.',
      'Since the 1990s the Maremma producer Sassotondo has contributed greatly to the revival of Ciliegiolo, producing a straight Ciliegiolo plus the single-vineyard San Lorenzo made from forty-year-old vines. Other recommended producers include Antonio Camillo and Rascioni & Cecconello.',
    ],
  },
  {
    name: 'CINSAUT',
    synonyms: [
      'Black Malvoisie',
      'Black Prince',
      'Blue Imperial',
      'Cinqsaut',
      'Cinq-saou',
      'Cinsault',
      'Greca\u00f9',
      'Grecu Masculinu',
      'Hermitage',
      'Marroquin',
      'Marrouquin',
      'Ottavianello',
      'Picardan Noir',
      'Piquepoul d\u2019Uz\u00e8s',
      'Prunelat',
      'Prunellas',
      'Sams\u00f3',
      'Sins\u00f3',
      'Sins\u00f3n',
      'Sinseur',
      'Uva Spina',
    ],
    description:
      'Underrated Mediterranean-loving variety making characterful ros\u00e9s and flirtatious reds.',
    'origin and parentage': [
      'In France, the earliest mention of Cinsaut was allegedly made under its old synonym Marroquin by Olivier de Serres (1600) or as Marrouquin by Magnol (1676) in his Botanicum Monspeliense: \u2018sequitur deinde marrouquin, antiquorum duracina, acini duri\u2019 (\u2018then comes marrouquin, the duracina from the ancients, with hard berry skin\u2019). In 1829 it was mentioned under the name Sins\u00e2ou by Bouschet in the H\u00e9rault in southern France: \u2018le sins\u00e2ou, le bois ressemble \u00e0 l\u2019\u0153illade\u2019 (\u2018sins\u00e2ou, the wood looks like \u0153illade\u2019). Its modern spelling, Cinsaut, did not appear until 1888 (R\u00e9zeau 1997) and the origin of the name is unknown.',
      'There are two main reasons why the geographic origin of Cinsaut can be confidently assigned to southern France, possibly Languedoc-Roussillon:',
      '\u2013\u00a0\u00a0\u00a0It is a member of the Piquepoul ampelographic group (see p XXVII; Bisson 2009), all from the Vaucluse in Provence or from the Languedoc-Roussillon.',
      '\u2013\u00a0\u00a0\u00a0DNA parentage analysis based on twenty-three DNA markers suggests a possible parent\u2013offspring relationship with RIVAIRENC (to be confirmed with more DNA markers) from the Languedoc-Roussillon (Vouillamoz).',
      'In Italy, Cinsaut was already cultivated in the seventeenth century. Indeed, the DNA profile of Greca\u00f9 (Carimi et al. 2009), an old variety from the Etna area on the Italian island of Sicilia that was already cited by Cupani in 1696, unexpectedly turned out to be identical to Cinsaut (Vouillamoz). It has also been known in Puglia since at least the nineteenth century under the name Ottavianello (Giavedoni and Gily 2005). In Spain, the DNA profile of the variety Sins\u00f3 analysed by Mart\u00edn et al. (2003) proved not surprisingly to be identical to Cinsaut (Vouillamoz).',
      'Cinsaut was used to breed the successful South African variety PINOTAGE, named after its parents PINOT NOIR and Hermitage, as Cinsaut was once inaccurately but widely known in the Cape. South Africa was also home to two colour mutations of Cinsaut: one with grey berries and one with white berries but these seem to have disappeared and have been deleted from the official variety designations.',
      'Cinsaut should not be confused with BOUCHAL\u00c8S even though they share the misleading synonym Picardan.',
    ],
    'where its grown': [
      'Like Grenache (GARNACHA), Cinsaut is ideally suited to the warm, dry soils of southern France, although its natural tendency to high yields needs to be curbed for it to show its finer side: reds that are soft, fruity and aromatic in youth and surprisingly long-lived. It is also ideally suited to the production of fresh, perfumed and fruity ros\u00e9 wines. As a red, it is more often found in blends than alone, often with the tougher Carignan (MAZUELO), although Domaine des Terres Falmet and Domaine d\u2019Aupilhac both produce excellent examples of 100% Cinsaut in the Languedoc.',
      'When it is cultivated as a table grape in southern France, Cinsaut is often confusingly called Oeillade Noire but the true OEILLADE NOIRE is a distinct variety.',
      'Total plantings in France in 2009 were 20,800 ha (51,398 acres), making it the ninth most planted red variety. It is widely planted in Languedoc-Roussillon and Provence-C\u00f4te d\u2019Azur but the biggest areas are in the H\u00e9rault, Var, Gard and Aude d\u00e9partements. It is one of the permitted varieties in Ch\u00e2teauneuf-du-Pape but its contribution is generally limited to around 5%.',
      'It was considerably more popular in 1970s France when the vineyard area devoted to Cinsaut tripled (there were more than 50,000 ha/123,553 acres in 1979) thanks in large part to official encouragement to plant it in place of ARAMON NOIR and ALICANTE HENRI BOUSCHET. In the 1950s and 1960s, large volumes of Cinsaut wine were imported from Algeria, then constitutionally part of France, to beef up some of the more weedy burgundies of the day.',
      'In the south of Italy, Cinsaut is cultivated under the name Ottavianello around Brindisi in Puglia, notably in the tiny Ostuni DOC, where it appears as a generally light, unremarkable varietal wine (minimum 85% with other local varieties). Total plantings in Italy in 2000, according to the agricultural census, were 288 ha (712 acres).',
      'In Morocco it is still the most widely planted variety, and in the Lebanon it is probably the third most planted wine grape after CABERNET SAUVIGNON and SYRAH, having long played an important role in Ch\u00e2teau Musar there.',
      'Limited plantings are also found in parts of Eastern Europe such as Turkey, where there were 444 ha (1,097 acres) in 2010',
      'There were 115 acres (47 ha) recorded in California in 2008, treasured by the likes of Bonny Doon for light reds and ros\u00e9s. It is also planted in Washington State.',
      'Official vineyard statistics in Australia do not mention Cinsaut but there are around 60 ha (148 acres) all told. Spinifex\u2019s Papillon from the Barossa Valley includes a good whack of Cinsaut, as does Foggo\u2019s McLaren Vale blend and it can be found in several other southern-Rh\u00f4ne-style cuv\u00e9es. Foggo also produce a 100% Cinsaut ros\u00e9 from vines planted in 1958. It is used by producers such as Chambers Rosewood and Morris in Rutherglen in their fortified wines.',
      'In South Africa, Cinsaut was the country\u2019s most planted red wine variety and still occupies about one tenth of its vineyard area in France (2,241 ha/5,538 acres in 2008), mostly in Paarl, Breedekloof and Malmesbury. It arrived from France in the mid nineteenth century. Not until 1993 did it lose the top red slot to CABERNET SAUVIGNON. Once little more than a productive workhorse, today it provides producers such as Adi Badenhorst in the Swartland with lower-yielding old vines for increasingly fine-tuned wines although, perhaps because of its ubiquity, it is not highly regarded by most Cape growers.',
    ],
  },
  {
    name: 'CIURLESE',
    synonyms: ['Ciurl\u00e8s'],
    description:
      'Virtually extinct white from around Rimini in northern Italy.',
    'where its grown': [
      'A speciality of the province of Rimini, eastern Italy, Ciurlese used to be cultivated mainly in Valconca, or the Valle del Conca, near Rimini. Today only a few vines survive in the area of San Giovanni in Marignano (near Rimini), eg in the vineyards of the Rocche Malatestiane co-operative in Rimini.',
    ],
  },
  {
    name: 'CIVIDIN',
    synonyms: ['Cividin Bianco', 'Cividino'],
    description: 'Ancient, rare white from Friuli, north-east Italy.',
    'origin and parentage': [
      'Cividin is said to come from the Valli del Natisone, east of Udine in north-east Italy, and to take its name from the nearby municipality of Cividale. It was mentioned in Friuli as early as the seventeenth century.',
    ],
    'where its grown': [
      'Cividin historically spread from the Cividale region to the hillsides of Maniago, Meduno, Navarons and Vito d\u2019Asio in Friuli, north-east Italy. Today it is mainly cultivated in the province of Pordenone and in Vipava, Slovenia (Vipacco in Italian). Together with other old local varieties CIANORIE, FORGIARIN, PICULIT NERI, SCIAGL\u00ccN and UCEL\u00d9T, it was saved from extinction by Emilio Bulfon, with the help of the ampelographers Antonio Cal\u00f2 and Ruggero Forti. Emilio Bulfon is one of the few producers to craft varietal wines from these grapes, though the vines are interplanted with other varieties. The wines have spicy aromas and are light-bodied with good acidity. Together with Cianorie, Cividin was included in the Italian national register of varieties in 2006.',
    ],
  },
  {
    name: 'CLAIRETTE',
    synonyms: [
      'Blanc Laffite',
      'Blanquette',
      'Clairet',
      'Clarette',
      'Clairette Blanche',
      'Feh\u00e9r Clairette',
      'Kleret',
      'Kleret Belyi',
      'Muscade',
      'Oeillade Blanche',
      'Osianka',
      'Ovsyanka',
      'Petit Blanc',
      'Petit Kleret',
      'Uva Gijona',
      'Vivsyanka',
    ],
    description:
      'Once very popular and now useful, crisp southern French white.',
    'origin and parentage': [
      'Clairette is one of the oldest varieties of France\u2019s Midi, possibly originating in the H\u00e9rault, where it is said to have been planted as early as 1490 in the commune of Adissan but there is no historical evidence for this date. The first reliable citation of Clairette was in 1575 in Ginestas, in the Aude: \u2018vint deux pleines desques [= corbeilles] de clarette\u2019 (\u2018twenty-two full baskets of clarette\u2019) (R\u00e9zeau 1997). Clairette was later mentioned by Olivier de Serres (1600), using the spelling clerete, and also a few years later by Magnol (1676): \u2018vulgatissima alba dicitur clarette, ex qua fit optimum vinum album\u2019 (\u2018very widespread is the white called clarette, from which is made an excellent white wine\u2019).',
      'The name Clairette, meaning \u2018light white\u2019, might come from the colour of the berries, but since there were many light-white-berried varieties at that time, it is more likely that the name refers to the white hairs on the underside of the leaves, giving them a bright (clair) appearance.',
      'Clairette belongs to the Claret ampelographic group (see p XXVII). In addition, Clairette is ampelographically close to PLANT DROIT (Bisson 2009).',
      'DNA analysis has established that the PICAPOLL BLANCO cultivated in Pla de Bages in Spain\u2019s Catalunya does not correspond to the French PIQUEPOUL but it does have a possible genetic relationship with, or may even be identical to, Clairette (Puig et al. 2006).',
    ],
    'where its grown': [
      'Clairette\u2019s stronghold and most obvious home in southern France is in the Dr\u00f4me, especially around Die, north-east of Mont\u00e9limar. In this area it is the sole variety in appellations such as Coteaux de Die and the sparkling Clairette de Die (though those specified as M\u00e9thode Ancestrale are based on MUSCAT BLANC \u00c0 PETITS GRAINS) and is the majority component in Cr\u00e9mant de Die.',
      'France had 2,405 ha (5,943 acres) of Clairette in 2009 but the total has declined drastically in the last fifty or so years (14,099 ha/34,839 acres in 1958). It is planted mainly in the southern Rh\u00f4ne and the Vaucluse \u2013 and then further south west in the Gard region of Languedoc, where it is the star of the Clairette de Bellegarde appellation, and further south east in the Var. In the H\u00e9rault, it has its own appellation in the form of Clairette de Languedoc, where, in two non-contiguous areas north and south of Clermont-l\u2019H\u00e9rault, it may be used to produce dry, sweet or rancio versions.',
      'It can best be described as an old-fashioned variety, producing fairly simple but crisp, sometimes mineral, wines for relatively early drinking. Aside from its more well-known fizzy guise, it is often used as a blending partner in the southern Rh\u00f4ne, eg in Ch\u00e2teauneuf-du-Pape or C\u00f4tes du Rh\u00f4ne, generally contributing about 30\u201335% of the mix for white wines and sometimes, as at Ch\u00e2teau de Beaucastel, adding some freshness to red blends. Ch\u00e2teau Rayas\u2019 white Ch\u00e2teauneuf comprises 50% Grenache Blanc (GARNACHA BLANCA) and 50% Clairette and Le Vieux Donjon\u2019s white generally has equal parts of Clairette and ROUSSANNE. Many other southern Rh\u00f4ne appellations such as Lirac, Tavel, Vacqueyras, Grignan l\u00e8s Adh\u00e9mar and Ventoux may include Clairette.',
      'In the Languedoc it is frequently blended with Ugni Blanc (TREBBIANO TOSCANO) and TERRET; early picking is common to catch the fresh aromas and acidity. It is also a permitted ingredient in several Proven\u00e7al appellations such as C\u00f4tes de Provence, Palette, Cassis and Bellet, and in Saint-Mont in south-west France. Domaine Richeaume in the C\u00f4tes de Provence produces a fragrant, fresh but full-bodied blend of Clairette and Rolle (VERMENTINO) and, in Bandol, Bunan\u2019s Mas de la Rouvi\u00e8re is a bold, appley 100% Clairette.',
      'Its success in the seventeenth and eighteenth centuries, blended with the more acidic PIQUEPOUL in the wine known as Picardin that was exported in large volumes north from the Languedoc, may have encouraged its wider distribution to, for example, Algeria and South Africa (see below).',
      'In Italy, there were 150 ha (371 acres) at the time of the last agricultural census (2000), planted mainly in Toscana and on the island of Sardegna, where it is an officially recommended variety in the south-eastern province of Cagliari and may be found to a limited extent in Nuragus di Cagliari (which must include at least 85% NURAGUS).',
      'Although statistics are not available for the Lebanon, producers such as Massaya and Ch Kefraya include Clairette in their white blends.',
      'Under the name Clairet, this variety is still planted to a limited extent in Krasnodar on Russia\u2019s Black Sea coast. It was apparently particularly popular with the Kuban Cossacks in the nineteenth century and first half of the twentieth.',
      'The few growers in Australia who have planted Clairette (once known there as Blanquette) have been very successful: Honeytree in the Hunter Valley increased their plantings from 0.4 to 2 ha (1 to 5 acres) by top-grafting their Traminer (SAVAGNIN BLANC) vines to try to keep pace with demand for their early-drinking version. Just outside Adelaide, Primo Estate\u2019s La Biondina has been their top white for nearly thirty years and JB Wines also do well with the variety in the Barossa Valley.',
      'In 2008, there were still 328 ha (811 acres) in South Africa, quite widely dispersed but with more planted in Worcester than anywhere else. Eben Sadie\u2019s multi-variety blend Palladius includes a dash of Clairette, as does Tulbagh Mountain Vineyard\u2019s white blend and Mullineux\u2019s Swartland white.',
      'A colour mutation called Clairette Rose was already known in 1834\u20135 in the Bouches-du-Rh\u00f4ne (R\u00e9zeau 1997) and is still cultivated in the Gard, mainly in Lirac. Total French plantings of this pink version were 260 ha (642 acres) in 2009.',
    ],
  },
  {
    name: 'CLAVERIE',
    synonyms: [
      'Bouguieu',
      'Chalosse Blanche',
      'Chaloussenc',
      'Clabarien',
      'Clab\u00e9rieu',
      'Claverie Blanc',
      'Claverie Verte',
      'Galia Zuria',
    ],
    description:
      'Virtually extinct, disease-prone white from south-west France.',
    'origin and parentage': [
      'Claverie is a variety from the Landes, in the far south west of France, where it was first mentioned in 1827: \u2018le claverie produit de tr\u00e8s-bon vin, sur-tout le vin de Cap-Breton, dans le d\u00e9partement des Landes\u2019 (\u2018Claverie produces very good wines, especially the wine from Cap-Breton in the Landes d\u00e9partement\u2019). Its name could derive from the B\u00e9arnais dialect word claberia, meaning \u2018enclosure, fence\u2019, and could correspond to the names of several lieux-dits in the Landes or in the Pyr\u00e9n\u00e9es-Atlantiques, more specifically to Lasclaveries north of Pau (Lavignac 2001). Claverie belongs to the Gras ampelographic group (see p XXVII). In addition, Claverie is ampelographically close to the Cot ampelographic group (Bisson 2009). While Claverie Coulard is a distinct variety that is not commercially cultivated, a black mutation of Claverie (called Claverie Noire) has been observed (Galet 2000).',
    ],
    'where its grown': [
      'Claverie used to be cultivated mainly in Chalosse in the Landes, south-west France, but also in Vic-Bilh and Juran\u00e7on in the Pyr\u00e9n\u00e9es-Atlantiques. Although it is included in the official French register of varieties, and recommended in the Gers and the Landes, it is almost extinct, mainly because of its susceptibility to fungal diseases. It is planted in the Saint-Mont conservatoire and recently yielded a wine that was relatively neutral, thin and eye-wateringly acidic (pH 2.78), with a particularly high level of malic acid. The conservatory is probably the best place for it.',
    ],
  },
  {
    name: 'CLINTON',
    synonyms: [
      'Bacchus',
      'Clinton Rose',
      'Klinton',
      'Plant des Carmes',
      'Plant Pouzin',
      'Vorthington',
      'Worthington',
      'Zephirin',
    ],
    description:
      'Pre-Civil War natural American hybrid of historical interest only.',
    'origin and parentage': [
      'Clinton is a spontaneous Vitis riparia \u00d7 Vitis labrusca hybrid that was first planted in 1821 by Hugh White, then a student of Hamilton College, in the grounds of Professor Noyes\u2019 house on College Hill in the town of Clinton, east of Syracuse, New York State. Two years earlier, White had planted hundreds of grape seeds in his father\u2019s garden in Whitesboro, and selected the one that looked most promising, although its origin remains unknown. Noyes named it after the town of Clinton, where the original vine was still thriving eighty years later on a great elm tree (Bailey 1906).',
    ],
    'where its grown': [
      'This old American variety produces deeply coloured wines that have good acidity but are blighted by their foxy flavour. There are apparently some pockets of Clinton vines in the C\u00e9vennes in France, north-east Italy, Switzerland and in Brazil, though their fruit is not authorized for the production of quality wine in the EU. Because of its disease resistance, it was quite popular in central southern France (Galet 2000) until it was prohibited \u2013 new plantings were banned in the mid 1930s and then the variety was outlawed completely in the 1950s \u2013 along with other Vitis labrusca descendants such as ISABELLA and CONCORD. Schiavo in the Veneto use Clinton as a component in their grappa and the Association Fruits Oubli\u00e9s in the Languedoc make a varietal wine for \u2018home consumption\u2019.',
    ],
  },
  {
    name: 'COCOCCIOLA',
    synonyms: ['Cacciola', 'Cocacciara'],
    description: 'Fruity, herbal white from Abruzzo and Puglia.',
    'origin and parentage': [
      'The origins of this variety and of its strange name are unclear and its earliest mention seems to be in Viala and Vermorel\u2019s Amp\u00e9lographie (1901\u201310) under the synonym Cacciola.',
    ],
    'where its grown': [
      'The majority of vineyards are found in Abruzzo, southern Italy (mainly in the province of Chieti, in the communes of Vacri, Ari and Rocca San Giovanni), where Cococciola is often blended with TREBBIANO TOSCANO and other varieties, but it is also present in northern Puglia. Authorized in all IGTs in Abruzzo and in IGT Daunia in Puglia and also as a minor component in Trebbiano d\u2019Abruzzo DOC, Cococciola has only fairly recently been made as a varietal wine. These tend to be fruity and herbal with marked acidity and are sometimes aged in big barrels. Current producers include Cantine Ciampoli in Ortona, Cantina Frentana in Rocca San Giovanni, Masseria Coste di Brenta in Lanciano, Silene in Chieti and Valle Martello in Villamagna. There were 893 ha (2,207 acres) recorded in the Italian census of 2000.',
    ],
  },
  {
    name: 'CODA DI CAVALLO BIANCA',
    synonyms: ['Cavalla', 'Codacavallo', 'Latina'],
    description:
      'Barely cultivated and generally blended white variety from Campania, southern Italy.',
    'origin and parentage': [
      'Named after its long clusters shaped like a horse\u2019s tail. The relationship between Coda di Cavallo from the island of Ischia and Cavalla from the Napoli region has been the subject of debate but a recent DNA study has shown them to be one and the same (Migliaccio et al. 2008).',
    ],
    'where its grown': [
      'Used to be widespread on the slopes of Vesuvio in southern Italy, now scarcely cultivated on the islands of Ischia and Procida and in Campi Flegrei (west of Napoli, Campania), always ungrafted. Never vinified alone, always blended.',
    ],
  },
  {
    name: 'CODA DI VOLPE BIANCA',
    synonyms: ['Durante', 'Falerno', 'Guarnaccia Bianca'],
    description:
      'Ancient, full-bodied white from southern Italy coming into its own as a varietal wine.',
    'origin and parentage': [
      'The earliest mention of Coda di Volpe Bianca from Campania dates back to Giovan Battista della Porta (1592). The synonym Falerno probably came about because Coda di Volpe Bianca is thought by some to have produced the famous Falerno wine in Roman times.',
      'Coda di Volpe Bianca, meaning \u2018white foxtail\u2019, has sometimes been considered identical to CAPRETTONE, CODA DI PECORA, PALLAGRELLO BIANCO or TREBBIANO TOSCANO. Careful ampelographic observations and recent DNA profiling have shown that they are all distinct and unrelated cultivars, and that Coda di Volpe Bianca might be closely related to Nerella and Uva Rosa, two obscure and not commercially cultivated Campanian varieties sampled in the region of Napoli (Costantini et al. 2005). The epithet Bianca is useful to distinguish it from Coda di Volpe Nera, a synonym for PALLAGRELLO NERO.',
    ],
    'where its grown': [
      'Widespread in but exclusive to Campania in southern Italy, mainly in the provinces of Benevento and Avellino. Until recently Coda di Volpe Bianca was considered a minor grape and was generally used in blends, but over the last few years varietal wines have demonstrated real quality, eg in Sannio, Irpinia and Taburno DOCs. Coda di Volpe is recommended in the provinces of Avellino, Benevento, Caserta and Napoli; it is authorized in the province of Salerno. The wines are pale to medium gold, full-bodied, quite soft, with aromas ranging from delicate stone fruit to exotic spice, sometimes with a mineral or salty flavour. A total of 1,027 ha (2,538 acres) were recorded in the 2000 Italian agricultural census.',
      'Producers of varietal wines include D\u2019Antiche Terre, Astroni, Cantine del Taburno, La Casa dell\u2019Orco, Cavalier Pepe, Crogliano, Ocone, Vadiaperti and Vini Antico Palazzo.',
    ],
  },
  {
    name: 'C\u00d4DEGA DE LARINHO',
    synonyms: ['C\u00f4dega do Larinho'],
    description: 'Very fruity but soft variety from north-east Portugal.',
    'origin and parentage': [
      'This variety probably comes from the Douro and Tr\u00e1s-os-Montes regions in north-eastern Portugal (Rolando Faustino, personal communication). DNA profiling has shown that C\u00f4dega de Larinho and S\u00cdRIA, also called C\u00f3dega in the Douro, are distinct varieties (Vouillamoz).',
    ],
    'where its grown': [
      'C\u00f4dega de Larinho, planted mainly in the Douro and Tr\u00e1s-os-Montes regions of north-eastern Portugal, produces wines with intense aromas of tropical fruit, sometimes also floral, but they can lack freshness so are often blended with higher-acid varieties such as RABIGATO, Arinto no Douro (DORINTO) and Gouveio (GODELLO). There were 582 ha (1,438 acres) in Portugal in 2010. Recommended producers include the Adega Cooperativa de Valpa\u00e7os in Tr\u00e1s-os-Montes and VDS Vinhos do Douro Superior (Castello d\u2019Alba) in the Douro.',
    ],
  },
  {
    name: 'CODIVARTA',
    synonyms: ['Codivarte Blanc', 'Codivertola Blanc', 'Cudiverta'],
    description: 'Soft northern Corsican.',
    'origin and parentage': [
      'Codivarta is a Corsican variety apparently known in Tomino at the northern tip of the French island of Corse since 1800 but its first reliable mention dates back to 1896 in Bastia, a little to the south (R\u00e9zeau 1997). The name comes from the Corsican dialect words coda (\u2018tail\u2019), probably in reference to the shape of the bunch, and verta (\u2018green\u2019). According to Galet (2000), it could be identical to the Italian CODA DI VOLPE BIANCA, but this has not yet been confirmed by DNA profiling.',
    ],
    'where its grown': [
      'Codivarta is included in the official register of varieties in France but recommended only for the island of Corse, where it may be included in Vins de Pays de l\u2019\u00cele de Beaut\u00e9 and in the white wines of the appellation Vin de Corse and Vin de Corse-Something (eg Vin de Corse-Coteaux du Cap Corse), though only as a supplementary variety. Clos Nicrosi in Rogliano at the northernmost tip of the island produce a blend of VERMENTINO, Ugni Blanc (TREBBIANO TOSCANO) and Codivarta. Wines tend to be relatively low in acidity.',
    ],
  },
  {
    name: 'COLOBEL',
    synonyms: ['Seibel 8357'],
    description:
      'Almost extinct French hybrid that can make very dark but astringent reds.',
    'origin and parentage': [
      'A complex hybrid obtained at the beginning of the twentieth century by Albert Seibel in Aubenas in the Ard\u00e8che, southern France, by crossing Seibel 6150 \u00d7 PLANTET, where:',
      '\u2013\u00a0\u00a0\u00a0Seibel 6150 is a Seibel 405 \u00d7 Flot Rouge hybrid (see PRIOR for the pedigree of Seibel 405)',
      '\u2013\u00a0\u00a0\u00a0Flot Rouge is a Munson \u00d7 ARAMON NOIR hybrid (see PRIOR for the pedigree of Munson).',
      'The name Colobel was most likely created by putting together the beginning of the French word colorant (\u2018dye\u2019) with the end of Seibel (R\u00e9zeau 1997).',
    ],
    'where its grown': [
      'Although it is authorized in many wine regions in south and central France, there were only about 10 ha (25 acres) left in 2008 (down dramatically from 1,269 ha/3,136 acres in 1958), but it is also grown to a very limited extent in New York State even though it is only slightly cold-hardy. It tends to be bitter and astringent but very deeply coloured and therefore useful only as a good colouring agent in a blend.',
    ],
  },
  {
    name: 'COLOMBARD',
    synonyms: [
      'Colombar',
      'Colombier',
      'French Colombard',
      'Queue Tendre',
      'Tourterelle',
    ],
    description:
      'Productive workhorse variety now more valued for easy-drinking blended whites than for brandy.',
    'origin and parentage': [
      'Colombard is a variety from the Charente in western central France, with a historical centre of cultivation in Borderies, a famous brandy-producing region once in Dutch hands and now a specific appellation for cognac. The earliest mention of Colombard appeared between 1706 and 1716 in La Rochelle under the old spelling Collombar and Coulombard (R\u00e9zeau 1997). Its name probably derives from colombe (\u2018dove\u2019), as corroborated by its synonyms (colombier = dovecote, tourterelle = turtledove), referring to the colour of the berries rather than to their ability to attract doves. Colombard has sometimes been confused with S\u00c9MILLON (Mas and Pulliat 1874\u20135).',
      'DNA parentage analysis has established that Colombard is the progeny of a natural CHENIN BLANC \u00d7 GOUAIS BLANC cross, just like its siblings BALZAC BLANC and MESLIER SAINT-FRAN\u00c7OIS (Bowers et al. 2000). Indeed, Colombard is morphologically similar to Balzac Blanc (Bisson 2009). It was used to breed COLOMINO.',
    ],
    'where its grown': [
      'France had 7,790 ha (19,250 acres) in 2009, making it the seventh most planted white variety in France. Although it is recommended in much of the south-west quadrant of France, south from the Charente and the Dordogne and across to parts of the Languedoc, the biggest concentrations are in the Charente-Maritime, Landes, Charente and Lot-et-Garonne d\u00e9partements. Although it was not considered as worthy as Ugni Blanc (TREBBIANO TOSCANO) and FOLLE BLANCHE because of its higher alcohol and lower acidity, Colombard was traditionally \u2013 and still is \u2013 a component in the dry base wines distilled into cognac and armagnac. It tumbled from popularity in the 1970s, losing more than half of its vineyard area between 1968 (11,892 ha/29,386 acres) and 1979 (5,829 ha/14,404 acres), but after a low point in the 1980s the vineyard area is again increasing gradually, most likely because of the growing popularity of the fresh, inexpensive, sometimes lightly floral whites now being produced as Vins de Pays des C\u00f4tes de Gascogne and Vins de Pays Charentais. It is also a permitted ingredient in certain Bordeaux appellations such as Bourg or Bordeaux tout court.',
      'Colombard\u2019s decline in France in the 1970s contrasts with its rise to fame in California as French Colombard, particularly in the hot Central Valley where reliably high yields \u2013 anywhere between 8 and 13 tons/acre (140\u2013220 hl/ha) \u2013 gave vast quantities of blended, often rather sweet, inexpensive wine. Increasing use of stainless steel and cool fermentation techniques resulted in fresh if rather neutral wines. Here the hot, dry climate significantly reduced the risks of fungal disease faced by French growers and high yields tended to retain fresh acidity. It was seeing the popularity of such wines in the US that inspired the Plaimont co-op in south-west France to make dry white wines from it in the C\u00f4tes de Gascogne.',
      'It was first introduced to California in the 1850s and was originally and appropriately known as West\u2019s White Prolific, after George West, a grower in San Joaquin County. The annual rate of planting in California slowed to a standstill towards the end of the 1980s as CHARDONNAY became more and more popular but then picked up slightly in the mid 2000s. The 25,965 acres (10,508 ha) planted at the end of the first decade were not much more than half what they had been in 2000 and it is hard to imagine that at one time Colombard was the state\u2019s most planted white variety \u2013 more than 90,000 acres (36,420 ha) in the late 1980s, representing almost 40% of white wine varieties. In the US, Colombard\u2019s popularity was not restricted to the Golden State: it is also planted in Texas, Virginia, Alabama and Arizona.',
      'In South Africa and Australia, using the same modern fermentation methods that were so successful in California, it has served a similar purpose, providing usefully crisp blending material, generally with CHENIN BLANC or CHARDONNAY. In 2008 South Africa had 11,877 ha (29,349 acres) of what is known locally as Colombar, also used in the production of brandy; Australia\u2019s Colombard plantings totalled 2,669 ha (6,595 acres), mostly in the hot, irrigated inland regions of Riverland, Riverina and Murray Darling. However, there are some honorable exceptions to cask-wine oblivion such as Primo Estate\u2019s La Biondina from McLaren Vale.',
      'Also found in Israel.',
    ],
  },
  {
    name: 'COLOMBAUD',
    synonyms: ['Aubi\u00e9', 'Colombaud du Var'],
    description:
      'Ancient but virtually extinct white related to the Swiss variety Humagne.',
    'origin and parentage': [
      'Colombaud is an old Proven\u00e7al variety that was mentioned in 1623 in Vaison (today\u2019s Vaison-la-Romaine) near Avignon in France\u2019s southern Rh\u00f4ne as a \u2018treille de raisins colombaux\u2019 (\u2018a vine of colombaux grapes\u2019) (R\u00e9zeau 1997). Several subsequent mentions in this area were recorded under various forms of the name (Colombeau, Colonbau) and the modern spelling Colombaud appeared in 1844 (R\u00e9zeau 1997). Unlike COLOMBARD, to which it is not related, there is no suggestion that its name derives from the French colombe (\u2018dove\u2019) and its etymology is unknown.',
      'Colombaud is said to resemble Mourv\u00e8dre (MONASTRELL; Reich 1902b) but it belongs to the Claret ampelographic group (see p XXVII; Bisson 2009). In addition, it is ampelographically close to BOUTEILLAN NOIR, which might explain why Colombaud is often erroneously considered to be a white mutation of Bouteillan Noir (Bisson 2009).',
      'DNA profiling has surprisingly revealed a parent\u2013offspring relationship between Colombaud and HUMAGNE, an old variety from the Valais in Switzerland (Vouillamoz and Moriondo 2011). However, this is only half a surprise since Humagne has recently been discovered in south-east France under the name Miousat (Bordenave et al. 2007).',
    ],
    'where its grown': [
      'It is virtually extinct in France but is still authorized in the Var.',
    ],
  },
  {
    name: 'COLORINO DEL VALDARNO',
    synonyms: ['Colore', 'Colorino'],
    description:
      'Rare, dark-skinned Tuscan variety often confused with others called Colorino Something and especially useful for adding colour to blends.',
    'origin and parentage': [
      'The name Colorino comes from the dark-blue colour of the berries and has been given to varieties such as ABRUSCO, ANCELLOTTA and CANAIOLO NERO, all with distinct DNA profiles (Vignani et al. 2008).',
      'Four types of Colorino are usually distinguished in its homeland of Toscana, central Italy: Colorino Americano, Colorino del Valdarno, Colorino di Lucca and Colorino di Pisa. Ampelographic and genetic analyses have established that the four types correspond to four distinct varieties (see pp XIV\u2013XVI for a definition of variety), with Colorino Americano being the most genetically distant (Sensi et al. 1996; Vignani et al. 2008). Colorino del Valdarno is the most widespread. In addition, a parent\u2013offspring relationship between Colorino di Lucca and Colorino di Pisa cannot be ruled out (Vouillamoz).',
      'As a consequence, it is not impossible that so-called Colorino vineyards are in fact made of a mix of several distinct varieties (Colorino Americano, Colorino del Valdarno, Colorino di Lucca and Colorino di Pisa).',
    ],
    'where its grown': [
      'Traditionally used in Toscana, Italy,\nto add colour to SANGIOVESE-dominated blends such as Chianti or Vino Nobile di Montepulciano (around 5\u201310%), it became particularly popular for this purpose in the 1980s and 1990s as a local alternative to adding CABERNET SAUVIGNON. Recommended in the whole of Toscana except in the provinces of Massa-Carrara and Livorno. It is also cultivated in Umbria, Marche, Lazio and Liguria. Some rare varietal IGT wines are made in Toscana, eg by Fattoria di Bagnolo in Montemurlo, Tenuta il Corno in San Casciano Val di Pesa, Fattoria del Cerro in Acquaviva and La Spinetta in Terricciola. They are deeply coloured with firm tannins and tend to have either red- or black-fruit flavours.',
      'There were 436 ha (1,077 acres) recorded in the Italian agricultural census in 2000 \u2013 though there is no knowing which Colorino the statistics refer to.',
    ],
  },
  {
    name: 'COMPLETER',
    synonyms: ['Lindauer', 'Malanstraube', 'Z\u00fcrirebe'],
    description:
      'Rare Swiss variety making very distinctive, ageworthy whites.',
    'origin and parentage': [
      'Completer is a very old variety from the Malans region in Graub\u00fcnden, eastern Switzerland, where it was first mentioned in 1321 in a document from the Chapter of Chur cathedral (Jenny 1938): \u2018quoad vinea vini completorii sisa in Malans\u2019 (\u2018regarding the wines of the vineyard of completer based in Malans\u2019). Its name comes from completorium, the evening office during which the Benedictine monks were traditionally allowed to drink a glass of wine in silence. Completer was confused with LAFNETSCHA from the Haut-Valais until DNA parentage analysis revealed that the latter is in fact a natural cross between Completer and HUMAGNE (Vouillamoz, Maigre and Meredith 2004) \u2013 surprising since Completer had never been recorded in the Valais. A thorough search uncovered a few old vines of Completer in the region of Visp, Haut-Valais, under the name Grosse Lafnetscha, providing evidence that Completer has been unwittingly cultivated in the Valais (Vouillamoz, Frei and Arnold 2008). Furthermore, DNA parentage analysis has also shown that two distinct natural crosses between Completer and Ticino\u2019s BONDOLA gave birth to the siblings BONDOLETTA in Ticino and HITZKIRCHER in the canton of Luzern (Vouillamoz and Moriondo 2011; see pedigree diagram below).',
      'The parents of Completer are unknown and have most likely disappeared but historical data suggest that Completer was introduced from Italy by Benedictine monks from Kloster Pf\u00e4fers (Pf\u00e4fers Abbey in Sankt Gallen, north-eastern Switzerland), who used to own vineyards in Malans and in Italy (Aeberhard 2005). (This is consistent with Completer having close genetic links with the Italian varieties MARZEMINO and Pignolo Spano (no longer cultivated); Vouillamoz and Moriondo 2011.) If so, it is likely that they introduced Completer independently to the Haut-Valais, where traditional vineyards, including some old Completer vines, are still trained on Italian-style pergolas, and to Graub\u00fcnden.',
    ],
    'where its grown': [
      'Completer wines typically have complex aromas of quince, ripe apple, plums and honey, becoming more honeyed as they age gracefully, plus a powerful but supple structure, very high natural acidity and a tendency towards an (attractive) oxidative character. Alcohol levels are usually high but this may be due to the way the local F\u00f6hn wind dries and concentrates the grapes as much as to any varietal characteristic. There were just 3 ha (7 acres) of Completer in Switzerland in 2009, mainly in Graub\u00fcnden in the south east, where a dozen producers make varietal wines. Schloss Reichenau and Completer-Kellerei make traditional, often slightly oxidative versions, whereas Donatsch, Peter & Rosi Hermann, and Thomas Studach produce a more modern style using small, new oak barrels. In Ticino, MERLOT stars Werner Stucky and Daniel Huber use some Completer in their white blends; and in the canton of Z\u00fcrich, Hermann Schwarzenbach makes a varietal wine from the single tiny Completer vineyard in the area.',
    ],
  },
  {
    name: 'CONCORD',
    synonyms: [
      'Bergerac',
      'Bull\u2019s Seedling',
      'Corin',
      'Cornin',
      'Dalmadin',
      'Feherhatu',
      'Fekete Noah',
      'Furmin Noir',
      'Gorin',
      'Gurin',
      'Kek Olasz',
      'Konkordi',
      'Konkordia',
      'Nyarfalevelue',
      'Nyarlevelue',
      'Olasz Kek',
    ],
    description:
      'America\u2019s own grape. Winter-hardy, Massachusetts hybrid planted more for jelly and juice than for wine.',
    'origin and parentage': [
      'The historical origin of this variety was made clear by Liberty Hyde Bailey (1906) and Wilfrid Wheeler (1908): Ephraim Wales Bull, an inhabitant of the town of Concord in Massachusetts, US, related that in 1840 some boys brought up from the river some wild grapes and scattered them around his house. A seedling appeared and Bull obtained the first bunch of grapes from the vine in 1843. He then planted seeds from this bunch and the resulting plant first fruited in 1849. The fruit had such merit that all other seedlings were destroyed. The variety was named Concord and was first officially recorded in 1853 by the Massachusetts Horticultural Society: \u2018E W Bull exhibited his new seedling grape, which, under the name of Concord, is now so generally cultivated throughout the country.\u2019',
      'From a genetic point of view, Concord is a grandchild of an unknown grapevine that was growing wild along the Concord River in 1840, most likely deriving from Vitis labrusca that still grows abundantly in the area. However, like many other North American hybrid varieties (such as CATAWBA or NORTON), Concord is probably a cross between Vitis labrusca and an unidentified Vitis vinifera subsp. vinifera variety (Stover et al. 2009).',
      'Concord Seedless is a sterile mutation of Concord. White Concord is not a colour mutation of Concord but a synonym for NIAGARA.',
      'Concord was used to breed MOORE\u2019S DIAMOND and NIAGARA.',
    ],
    'where its grown': [
      'Concord is the most widely planted variety in the eastern United States, especially in New York State, where there were 20,217 acres (8,181 ha) in 2006 and it is grown on a greater variety of soils and under more different climatic conditions than any other American vine. However, the juice has the powerful \u2018foxy\u2019 flavour typical of Vitis labrusca and the great majority of the fruit is harvested for juice and jelly: as little as 5\u201310% of New York\u2019s total is used for wine. Pennsylvania had 1,063 acres (430 ha) planted for wine in 2008 and it is also found in Ohio (665 acres/269 ha in 2008), Missouri (140 acres/57 ha in 2009), Michigan (60 acres/24 ha in 2003), Illinois (21 acres/8.5 ha in 2007), Iowa (21 acres/8.5 ha in 2006), Indiana (20 acres/8 ha in 2010) and Nebraska (1 acre/0.4 ha in 2007), for example. In the Pacific Northwest, it is widely planted in Washington state (25,068 acres/10,145 ha in 2011).',
      'Wines are medium-bodied and dark bluish purple in colour and made in a range of styles, some decidedly sweet and some usefully kosher, but they typically have the foxy flavour found in the juice, limiting the variety\u2019s popularity beyond the eastern US, where its winter hardiness and high yields may have been more appealing than its flavour. Any American product sold as \u2018grape\u2019-flavoured is likely to exhibit Concord\u2019s powerful aroma, not unlike a blend of damp fur and wild strawberries. Stone Hill in Missouri and Oliver Winery in Indiana make good examples, both in a sweeter style.',
      'Concord is also authorized, along with three other American hybrids \u2013 Ripatella (thought to be a Vitis riparia \u00d7 Taylor (see NOAH) hybrid), ELVIRA and DELAWARE \u2013 in Uhudler, an idiosyncratic table-wine speciality of the S\u00fcdburgenland, Austria, of which around 160,000 litres are produced annually.',
      'There are also significant plantings in Brazil \u2013 2,483 ha (6,136 acres) in the state of Rio Grande do Sul in 2007 \u2013 and lesser amounts in Peru and Japan, where it is used for wine and juice.',
    ],
  },
  {
    name: 'CORDENOSSA',
    synonyms: ['Cordenos'],
    description: 'Recently rescued and still rare Friulian red.',
    'origin and parentage': [
      'Cordenossa is an old variety from Friuli-Venezia Giulia in north-east Italy, probably originating from Cordenons near Pordenone, hence the name.',
    ],
    'where its grown': [
      'Cordenossa used to be grown in the area around Cordenons, Zoppola and San Vito al Tagliamento in Friuli-Venezia Giulia, Italy. It had almost disappeared when Emilio Bulfon, Antonio Cal\u00f2 and Ruggero Forti rescued it, along with other old local varieties such as CIANORIE, FORGIARIN, PICULIT NERI, SCIAGL\u00ccN and UCEL\u00d9T. Emilio Bulfon makes a rare varietal wine, which is said to have aromas of violets, fruits of the forest and a hint of tobacco and to taste of blackcurrants and plums.',
    ],
  },
  {
    name: 'CORNALIN',
    synonyms: [
      'Broblanc',
      'Cargnola',
      'Cornalin d\u2019Aoste',
      'Cornalino',
      'Cornallin',
      'Corniola',
      'Humagne Rouge',
    ],
    description:
      'Ancient red from the Valle d\u2019Aosta in northern Italy, widespread in the Valais in Switzerland as Humagne Rouge.',
    'origin and parentage': [
      'Cornalin is an old variety from the Valle d\u2019Aosta, north-eastern Italy, on the border with Switzerland, where it was widely cultivated in the nineteenth century. Its name might derive from corniolo, the Italian name for the cornelian cherry (Cornus mas). Two types have been described by Gatta (1838), having slightly distinct morphology and distribution: Cornalin and Corniola. It is not clear whether they represented clonal variations or distinct varieties but a link with the southern Italian white Corniola di Milazzo can be ruled out.',
      'In the Valais in southern Switzerland, the variety is called Humagne Rouge, although it is not related to the white-berried HUMAGNE. Humagne Rouge was long thought to be identical to the PETIT ROUGE of the Valle d\u2019Aosta but morphological and DNA evidence has shown that Humagne Rouge is in fact identical to Cornalin (Moriondo 1999; Vouillamoz et al. 2003). In addition, DNA paternity testing has demonstrated that Cornalin is a natural progeny of ROUGE DU PAYS, which also happens to be called Cornalin in the Valais. Since Rouge du Pays is a natural offspring of Petit Rouge and MAYOLET, Cornalin is their grandchild (Vouillamoz et al. 2003; see PRI\u00c9 for the pedigree diagram).',
    ],
    'where its grown': [
      'The variety is cultivated on a little over 1 ha (2.5 acres) in the Valle d\u2019Aosta, Italy, according to the Italian agricultural census of 2000, whereas it covers ten times that area in the Valais, Switzerland, under the name Humagne Rouge. Varietal Cornalin qualifies as Valle d\u2019Aosta DOC. Producers include Maison Anselmet and Grosjean Fr\u00e8res. Valaisan producers of varietal Humagne Rouge include Cave La Romaine, Defayes & Crettenand and Maurice Zufferey. The wines are well structured with somewhat rustic tannins and aromas of smoke, bark, pepper and fresh black fruits, pairing well with game dishes.',
    ],
  },
  {
    name: 'CORNAREA',
    synonyms: ['Incrocio Dalmasso IV/28'],
    description:
      'Obscure dark-skinned Italian cross whose true parentage has only recently been revealed.',
    'origin and parentage': [
      'The Italian vine breeder Giovanni Dalmasso claimed that Cornarea was a NEBBIOLO \u00d7 BARBERA cross created in 1936 at the Conegliano research centre in the Veneto, northern Italy, with the objective of combining the quality of Nebbiolo with the resistance and naturally high yields of Barbera. However, DNA profiling recently showed that the Nebbiolo used by Dalmasso was actually Nebbiolo di Dronero, which is not Nebbiolo but a synonym for CHATUS (Torello Marinoni, Raimondi, Ruffa et al. 2009). Cornarea is therefore a Chatus \u00d7 Barbera cross, just like its siblings ALBAROSSA, NEBBIERA, SAN MICHELE and SOPERGA. It entered the official Italian register of varieties in 1977.',
      'Cipriani et al. (2010) have recently suggested through DNA parentage analysis that Cornarea was indeed a NEBBIOLO \u00d7 BARBERA cross but this parentage showed some DNA discrepancies and is not consistent with Torello Marinoni, Raimondi, Ruffa et al. (2009), possibly because the Nebbiolo used by Cipriani et al. (2010) might not be true-to-type.',
    ],
    'where its grown': [
      'In Italy just 25 ha (62 acres) were reported in the agricultural census of 2000. It is planted mainly in Piemonte and generally used to enhance colour in blends.',
    ],
  },
  {
    name: 'CORNIFESTO',
    synonyms: [
      'Cornifeito',
      'Cornifesta',
      'Cornifesto no Dao',
      'Cornifesto Tinto',
      'Cornifresco',
      'Tinta Bastardeira',
    ],
    description:
      'Minor Douro variety used for both port and unfortified wines.',
    'origin and parentage': [
      'Recent DNA parentage analysis has shown that Cornifesto is a natural cross between CAYETANA BLANCA, widespread throughout the south and centre of the Iberian peninsula, and ALFROCHEIRO from the south or centre of Portugal (Zinelabidine et al. 2012), which possibly occurred in the Douro region in north-east Portugal, its main area of cultivation. Cornifesto is therefore a sibling of CASTEL\u00c3O, MALVASIA PRETA, JUAN GARC\u00cdA and CAMARATE (see Cayetana Blanca for the pedigree diagram). Its name literally means \u2018curved (festo) horn (corni)\u2019, referring to the shape of the bunches (Galet 2000).',
      'Santana et al. (2010) have collected a variety called Cornifesto in Arribes del Duero just over the border in Spain but it is not yet known whether it is identical to its Portuguese counterpart.',
    ],
    'where its grown': [
      'The variety is grown mainly in the Douro, where it is authorized for both port and dry table wines. Varietal wines tend to have red-fruit and light floral aromas. There were 135 ha (334 acres) in Portugal in 2010.',
      'Campbells of Rutherglen in Victoria, Australia, have a small patch of Cornifesto in a vineyard block dating back to the 1960s, generally used in a port-style blend, and a very small area of this variety remains in South Africa, used for the same purpose.',
    ],
  },
  {
    name: 'COROT NOIR',
    synonyms: ['NY 70.0809.10'],
    description: 'Minor hybrid bred in upstate New York for the eastern US.',
    'origin and parentage': [
      'A complex hybrid obtained in 1970 by Bruce Reisch and Thomas Henick-Kling at New York State Agricultural Experiment Station, Cornell University, Geneva, by crossing Seyve-Villard 18-307 with STEUBEN (Reisch et al. 2006a), where Seyve-Villard 18-307 is a Chancellor \u00d7 Sub\u00e9reux hybrid (see PRIOR for the complete pedigrees of Chancellor and Sub\u00e9reux). It was selected in 1978 as NY 70.0809.10 and finally named and released in 2006 together with NOIRET and VALVIN MUSCAT (Reisch et al. 2006c). Corot Noir is therefore a complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\nlincecumii and Vitis\nrupestris hybrid.',
    ],
    'where its grown': [
      'Corot Noir is grown mainly in New York State, where there were 59 acres (24 ha) in 2006, and to a lesser extent in Pennsylvania (4 acres/1.6 ha in 2008), Ohio and Illinois. Wines are deep red with aromas of cherries and berries and big, rounded tannins. It\u2019s often used in blends but producers of varietal wines include Deere Run, Fulkerson and Otter Creek in New York and Ridge View in Illinois.',
    ],
  },
  {
    name: 'CORTESE',
    synonyms: ['Corteis', 'Courteis', 'Courtesia'],
    description:
      'Generally rather bland northern-Italian white that shows its best varietal face in Gavi.',
    'origin and parentage': [
      'Cortese was first mentioned in an inventory of the cellars at the castle of Casale Monferrato in the province of Alessandria, in south-eastern Piemonte, in 1614 (Nada Patrone 1991). It was mentioned again in 1658 in Montaldeo (also in Alessandria), where vineyards were planted with \u2018cortese\u2019, some \u2018fermentino\u2019 (ie VERMENTINO) and \u2018nebioli dolci\u2019 (\u2018sweet NEBBIOLOs\u2019; Nada Patrone 1991). In the eighteenth century, Cortese was found mainly in the province of Alessandria as well as in the province of Novara (Demaria and Leardi 1875). No parentage was found for Cortese in northern Italy (Schneider et al. 2003).',
    ],
    'where its grown': [
      'Cultivated in the provinces of Asti and Alessandria in south-eastern Piemonte, north-west Italy, but known particularly as the star of Gavi or Cortese di Gavi DOCG. It appears as a varietal in several DOCs in Piemonte such as Colli Tortonesi and Monferrato \u2013 though ripeness and quality are rarely on a par with Gavi\u2019s \u2013 and is also cultivated in Oltrep\u00f2 Pavese in Lombardia and in Garda in the Veneto. According to the Italian agricultural census of 2000, total plantings were 3,135 ha (7,747 acres). Gavi was originally produced to supply the fish restaurants of Genova and the Ligurian coast and the wines are generally rather neutral but rescued by their refreshing acidity. At their best, they can be gently aromatic with restrained mineral and citrus flavours.',
    ],
  },
  {
    name: 'CORVINA VERONESE',
    synonyms: [
      'Corvina Comune',
      'Corvina Gentile',
      'Corvina Nostrana',
      'Corvina Reale',
      'Cruina',
    ],
    description:
      'Bright, fresh, cherry-scented red encountered most often in Valpolicella and Bardolino.',
    'origin and parentage': [
      'The name Corvina might not simply derive from corvo (\u2018crow\u2019) in reference to the colour of the berries but rather from the local dialect cruina (Italian crua means \u2018unripe\u2019) in reference to its late maturation. Corvina Veronese could be identical to the Acinatico grape already mentioned in the Middle Ages for the production of Recioto.',
      'The name Corvini appeared in 1627 among the wines of Verona, and a Corvina was found in 1755 (Cal\u00f2 and Costacurta 2004), but those names could represent distinct varieties. The full name Corvina Veronese first appeared in Pollini (1818), in which it is described as widespread in Val Pullicella (Valpolicella in the province of Verona), where it is considered an autochthonous variety.',
      'Corvina Veronese has long been thought to be identical to Corbina, an ancient and almost-forgotten variety of Friuli-Venezia Giulia, rescued and included in the national register of varieties in 2007, though no commercial wine has yet been produced. However, morphological and molecular studies showed that Corbina and Corvina Veronese are distinct and unrelated varieties (Cancellier et al. 2007). Similarly, CORVINONE was generally considered to be a clonal mutation of Corvina Veronese but ampelographic, isoenzymatic and DNA profiling have shown that they are distinct varieties (Cancellier and Angelini 1993), although they might be genetically related.',
      'The genetic group of Corvina Veronese includes DINDARELLA, GARGANEGA, OSELETA and RONDINELLA (Vantini et al. 2003). Parentage analysis at sixty DNA markers provided evidence that Corvina Veronese has parent\u2013offspring relationships with at least two northern Italian cultivars: REFOSCO DAL PEDUNCOLO ROSSO and Rondinella (Grando et al. 2006). Since Refosco dal Peduncolo Rosso is a progeny of MARZEMINO (Grando et al. 2006), Corvina Veronese must be a progeny of Refosco dal Peduncolo Rosso and a parent of Rondinella (see the Refosco dal Peduncolo Rosso pedigree diagram). As a consequence, Corvina Veronese is also a grandchild of Marzemino.',
    ],
    'where its grown': [
      'Corvina Veronese is blended in the Veneto, north-west Italy, with its progeny RONDINELLA and with MOLINARA to produce Valpolicella, including Amarone and Recioto styles (ranging from 40% to 80% of the blend), and Bardolino (33\u201365%) but it is generally considered to be the superior component in such blends. Garda DOC allows for a 100% Corvina Veronese wine. A few producers such as Allegrini make varietal wines (eg the single-vineyard La Poja) that demonstrate the variety\u2019s ability to produce serious, barrel-aged reds. Varietal wines or those in which Corvina Veronese dominates are marked by their bright sour-cherry flavour, fresh acidity and occasionally a note of bitter almonds. They are light-bodied only if the yields are high, and tannins tend to be light to moderate. There were 4,867 ha (12,027 acres) in Italy in 2000.',
      'Freeman Vineyards in the Hilltops region of New South Wales have pioneered this variety in Australia. They have 4 ha (10 acres) planted and they blend it with RONDINELLA to make an Amarone-style wine.',
      'In high-altitude vineyards in Tupungato, Mendoza, Argentina, the Veneto producer Masi make a similar style of wine from Corvina Veronese and COT (Malbec).',
    ],
  },
  {
    name: 'CORVINONE',
    synonyms: ['Corvinon', 'Cruinon'],
    description:
      'A dark-skinned variety with fresh acidity that contributes to Veneto blends.',
    'origin and parentage': [
      'Corvinone has generally been considered to be a clonal mutation of CORVINA VERONESE but ampelographic, isoenzymatic and DNA profiling have shown that they are distinct varieties (Cancellier and Angelini 1993), although they might be genetically related.',
    ],
    'where its grown': [
      'In Valpolicella, in Italy\u2019s Veneto, Corvinone may be substituted for up to 50% of the 40\u201380% of CORVINA VERONESE allowed in the blends for straight Valpolicella DOC as well as for Amarone and Recioto DOCGs. It may also form part of the blend in Bardolino DOC and Bardolino Superiore DOCG. It is not generally made as a varietal wine. There were 95 ha (235 acres) recorded in the Italian agricultural census in 2000.',
    ],
  },
  {
    name: 'COT',
    synonyms: [
      'Agreste',
      'Auxerrois',
      'Bouyssales',
      'Cagors',
      'Cahors',
      'Coq Rouge',
      'Cor',
      'Cors',
      'Cos',
      'C\u00f4t',
      'Cots',
      'Estrangey',
      '\u00c9tranger',
      'Lutkens',
      'Malbec',
      'Malbeck',
      'Malbech',
      'Mancin',
      'Nuar de Presac',
      'Pied de Perdrix',
      'Pressac',
      'Noir de Pressac',
      'Prunelat',
      'Quercy',
    ],
    description:
      'Makes dark, flavoursome, well-structured reds more celebrated as Argentine Malbec than in its home Cahors.',
    'origin and parentage': [
      'Cot is an old variety from what used to be known as the province of Quercy in France, centred on Cahors and corresponding to the modern d\u00e9partement of Lot and part of the Tarn-et-Garonne. Yet it was first mentioned under the name Noir de Pressac by Fontemoing in 1761 with respect to his Ch\u00e2teau Trochau (today\u2019s Ch\u00e2teau Haut-Tropchaud) in Pomerol (Garde 1946), and then further north in 1783\u20134 in La Ch\u00e2tre (due east of Poitiers) and in Issoudun, near Bourges, under the name Cor: \u2018Cor [.\u00a0.\u00a0.] Baye grosse, ovale, cassante sous la dent, d\u2019un noir bien fonc\u00e9, grappe tr\u00e8s longue d\u2019une grosseur moyenne, tr\u00e8s l\u00e2che, donnant beaucoup de vin d\u2019une bonne qualit\u00e9\u2019 (\u2018Cor [.\u00a0.\u00a0.] Large berry, oval, easily crunched between the teeth, dark black, very long, loose, medium-sized bunch, giving a lot of good-quality wine\u2019) (R\u00e9zeau 1997). However, it is unlikely that the Indre d\u00e9partement, where Cot is also called Cau, Cor, Cors or Cos, is its birthplace, because these names are all contractions of Cahors in Quercy, the most likely cradle of this variety.',
      'From Quercy, Cot was introduced to the Gironde in the eighteenth century although prior to that \u2018the black wines of Cahors\u2019 were sometimes used to add colour and body to the more delicate products of the Bordeaux region. According to Auguste Petit-Lafitte (1868), it was first of all known as Estrangey or \u00c9tranger (\u2018stranger\u2019) in the M\u00e9doc and the Graves, suggesting it had been introduced from another wine region. Later the variety was known there as Lutkens (often misspelt Luckens) after a physician from Bordeaux who died in 1782 and who planted it in Camblanes just south of the city (today\u2019s Camblanes et Meynac). At the same time, Cot was also called Malbeck after the person who cultivated it in Sainte-Eulalie d\u2019Ambar\u00e8s in what is now the Premi\u00e8res C\u00f4tes de Bordeaux (today\u2019s Sainte-Eulalie) and who propagated it throughout the M\u00e9doc, its first citation appearing in 1783\u20134 in Pauillac: \u2018Malbeck ou Cahors. Il se multiplie beaucoup dans les nouvelles complantations des terres fortes\u2019 (\u2018Malbeck or Cahors. It is widely propagated in the new plantings in robust soils\u2019; R\u00e9zeau 1997). In Saint-\u00c9milion, Cot was called Pressac or Noir de Pressac from at least 1784, after the person who introduced this variety to that area.',
      'Cot has numerous additional names all over France and around the world, reflecting the significant clonal diversity within the same variety and its wide and ancient distribution.',
      'Cot was often confused with BOUCHAL\u00c8S, perhaps because both belong to the Cot ampelographic group (see p XXVII; Bisson 2009).',
      'Recent DNA parentage analysis has revealed that Cot is a natural progeny of PRUNELARD, an old variety from the Tarn, and Magdeleine Noire des Charentes, the mother of MERLOT (Boursiquot et al. 2009; see CABERNET SAUVIGNON and PINOT pedigree diagrams). This makes Cot a half-sibling of Merlot, its birthplace somewhere between its parents\u2019 homes (the Tarn and the Charente), which is consistent with its originating from Quercy. By a natural cross with FOLLE BLANCHE, Cot is also a parent of JURAN\u00c7ON NOIR.',
      'Cot was used to breed CALADOC.',
      'Although the Italian variety MALBO GENTILE has a name similar to Malbec, they are unrelated.',
    ],
    'where its grown': [
      'Although Cot (sometimes C\u00f4t) is a native of south-west France, its centre of gravity moved to the southern hemisphere in the late twentieth century thanks to the success of Argentine Malbec (formerly Malbeck) and the fact that the Malbec vines lost to the 1956 frosts in Bordeaux were not generally replaced. Today French plantings are mainly in the south-western d\u00e9partement of the Lot, where it must constitute at least 70% of any wine labelled Cahors (and even here there is now an increasing tendency to call the variety Malbec in recognition of Argentine Malbec\u2019s popularity in the US). It plays a more minor role in other south-western appellations such as Bergerac, Buzet, C\u00f4tes de Duras, Fronton, C\u00f4tes du Marmandais, P\u00e9charmant and C\u00f4tes du Brulhois. It is also theoretically allowed into Cabard\u00e8s and C\u00f4tes de la Malep\u00e8re but is rarely found this far from Atlantic influence. If picked before full maturity, it tastes herbaceous, and tannins may be very astringent, leading to a certain hard bitterness. Ch\u00e2teau du C\u00e8dre and Domaine Cosse Maisonneuve produce some particularly fine examples in Cahors with the drama of fully ripe Malbec given a sort of French polish. Total plantings in France in 2009 were 6,155 ha (15,209 acres).',
      'Only 934 ha (2,308 acres) remain in the Gironde (compared with nearly 5,000 ha/12,350 acres at the end of the 1960s) although it is still permitted in all major red Bordeaux appellations. It is most persistent in Bourg, Blaye and the Entre-Deux-Mers but even there the vines are being replaced by less demanding varieties such as MERLOT.',
      'Further north in the middle Loire it is even harder to get Cot to ripen fully than in Bordeaux, although it does tend to ripen about ten days earlier than CABERNET FRANC if yields are controlled. Growers have found that it is much better as a varietal than blended with GAMAY NOIR, CABERNET SAUVIGNON or Cabernet Franc. It is permitted in appellations such as Anjou, Coteaux du Loir, Touraine of various sorts and sparkling Saumur but it is being gradually replaced by Cabernets Sauvignon and Franc. Even so, there were still 560 ha (1,384 acres) planted in the region in 2009. At Domaine de la Charmoise, Henry Marionnet\u2019s Vinifera Cot, produced from 1 ha (2.5 acres) of ungrafted vines, produces one of the silkiest examples of this variety in the region. Other good producers include Jean-Fran\u00e7ois M\u00e9rieau and Le Cellier du Beaujardin.',
      'According to the 2000 agricultural census, Italy had 267 ha (660 acres) of what is known as Malbech, mainly in the north east. Paladin\u2019s Gli Aceri Malbech from the Veneto and Spinelli\u2019s Malbec from Abruzzo are rare examples of varietal wines although they are permitted in the Lison-Pramaggiore DOC in the Veneto. In the same region, it may also play a minor part in Montello\u2013Colli Ascolani and much further south, in Puglia, it may be included in the blend for Rosso Barletta and Rosso di Cerignola.',
      'Malbec is also found in Turkey (21 ha/52 acres in 2010), the Republic of\nMoldova (39 ha/96 acres in 2009) and Israel.',
      'Much of California\u2019s 1,469 acres (594 ha) is used to add colour and acidity to Meritage blends, and to soften the tannins of other Meritage components such as CABERNET SAUVIGNON, and it generally plays a bit part in Opus One. However, varietal wines are produced by, for example, Chateau St Jean (Sonoma), Ferrari Carano (Dry Creek Valley), Geyser Peak (Alexander Valley) and Rutherford Hill (Napa Valley). Producers such as Ironstone use it to make a varietal ros\u00e9. Washington State produces one of the widest ranges of varietal Malbecs in the US but the success of Argentine Malbec there has encouraged plantings in many other states.',
      'Canada\u2019s British Columbia has 72 acres (29 ha) and plantings appear to be on the rise, and the variety is bottled as a varietal in Mexico.',
      'Malbec was introduced to Argentina by the French agricultural engineer Michel Pouget in 1868. Today it is the most widely planted good-quality variety in the country with 26,845 ha (66,335 acres), way ahead of Bonarda (DOUCE NOIRE; 18,759 ha/46,354 acres) and CABERNET SAUVIGNON (17,746 ha/43,851 acres), found in just about every wine-growing region but with the vast majority of plantings in Mendoza (22,885 ha/56,550 acres). Only CEREZA, used mainly for basic-quality ros\u00e9 or grape concentrate, has a greater area under vine. Bunches are smaller and tighter and berries smaller than on the Cot vines of south-west France; wines are deeply coloured, intensely flavoured with black fruits and more than a hint of game, sometimes with a note of violets, and range in style from soft and fruity to more structured, sophisticated and ageworthy. Vineyards in Lujan de Cuyo in the upper Mendoza Valley produce some of the very best wines at altitudes between 800 and 1,100 m (2,640 and 3,630 ft) although Colom\u00e9 way up in Salta has shown that Malbec can be made in a very fine, restrained style at even higher altitudes. Top producers include Achaval Ferrer, Alta Vista, Susanna Balbo\u2019s Dominio del Plata, Catena Zapata, Colom\u00e9, Paul Hobbs\u2019 Cobos, Cuvelier Los Andes, Fabre Montmayou, Norton, O Fournier, Pulenta, Salentein, Terrazas de los Andes, Pascual Toso, Trapiche and, in a more traditional style, Weinert.',
      'Chile is a minor Malbec grower with 1,027 ha (2,538 acres) in 2008, of which the majority \u2013 385 ha (951 acres) \u2013 is in Colchagua, the home of Chile\u2019s Malbec specialist Viu Manent. On this side of the Andes it is often blended with CABERNET SAUVIGNON. Other producers of varietal wines include Montes and Valdivieso. Malbec is also grown in Bolivia and Peru \u2013\nhardly surprising in view of its importance in Argentina.',
      'Australia\u2019s 363 ha (897 acres) are mostly in South Australia (eg Clare Valley and Langhorne Creek), but also in Western Australia (eg Margaret River and Great Southern). The variety is not as popular as it once was but there are signs of a minor comeback. Some of the more notable of more than 200 Malbec producers are Bleasdale in Langhorne Creek, Henschke in Eden Valley, Tahbilk in Victoria\u2019s Nagambie Lakes and Wendouree in Clare Valley, whose Malbec blends can be wonderfully dark and elegant.',
      'New Zealand had just 156 ha (385 acres) in 2008 but has some serious supporters, including Fromm on the South Island and Kumeu River, Millton, CJ Pask and Villa Maria on the North Island. And Argentina\u2019s success with the variety has encouraged plantings in South Africa,\nwhere Diemersfontein and Signal Hill take it more seriously than most.',
    ],
  },
  {
    name: 'COUDERC NOIR',
    synonyms: ['Contassot 20', 'Couderc 7120', 'Plant Verni'],
    description: 'Undistinguished French hybrid with few remaining fans.',
    'origin and parentage': [
      'A natural hybrid of Munson (or Jaeger 70, a Vitis lincecumii \u00d7 Vitis rupestris cross) \u00d7 Vitis vinifera subsp. vinifera (ie an unknown variety) obtained in 1886 by Contassot, a pastry cook from Aubenas who gave some of his seeds to Georges Couderc (series 71) and others to Albert Seibel (series 1-254; Galet 1988). This hybrid was in series 71 and is therefore named in honour of Couderc. See PRIOR for the complete pedigree of Munson.',
    ],
    'where its grown': [
      'Along with other hybrids such as CHAMBOURCIN and PLANTET, Couderc Noir was widely planted in France in the early twentieth century. So much so that it was not until well into the 1970s, when Couderc was being determinedly uprooted, that its plantings were outnumbered by CABERNET SAUVIGNON. It is still authorized in most of southern and south-western France but there were only 214 ha (529 acres) remaining in France in 2009 compared with 26,616 ha (65,770 acres) at the end of the 1950s.',
      'A Couderc is grown in Missouri. Brazil\u2019s 498 ha (1,231 acres) in Rio Grande do Sul are Couderc 13, a different variety and grown mainly for the table.',
      'Wines are deeply coloured and often off-puttingly non-vinifera in flavour and the least worst results are achieved in ros\u00e9.',
    ],
  },
  {
    name: 'COUNOISE',
    synonyms: [
      'Coneze',
      'Connoges',
      'Connoise',
      'Coun\u00e8se',
      'Couno\u00efse',
      'Couno\u00efso',
      'Counoueiso',
      'Gu\u00e9noise',
      'Quenoise',
      'Rivier',
    ],
    description:
      'Minor but valued southern Rh\u00f4ne ingredient, occasional varietal.',
    'origin and parentage': [
      'Counoise is a very old variety from southern France, cultivated mainly in the southern Rh\u00f4ne, where it was mentioned as early as 1626 in Avignon: \u2018berardy, espagne, barboulenque, col tort et aultre bon plant sans aulcune counoyse\u2019 (\u2018berardy, espagne, barboulenque, col tort and other good varieties without any counoyse\u2019). Its name is of uncertain dialectal origin and has various old spellings. Counoise belongs to the Piquepoul ampelographic group (see p XXVII), which also includes AUBUN (Bisson 2009), which explains why Counoise is often confused with Aubun in the vineyards, although ampelography and DNA profiling show that they are distinct varieties (Galet 1990; Bowers et al. 1999).',
    ],
    'where its grown': [
      'Counoise is recommended around the southern Rh\u00f4ne, France, authorized in the d\u00e9partements further east and west, and permitted in appellations such as Ch\u00e2teauneuf-du-Pape, C\u00f4tes du Rh\u00f4ne-Villages, Gigondas, C\u00f4teaux d\u2019Aix en Provence and Ventoux. In older vineyards it is often mixed with the lesser AUBUN. Although it is one of the less used varieties in the Ch\u00e2teauneuf-du-Pape blend, producers such as Ch\u00e2teau de Beaucastel and Domaine de Pega\u00fc do include it. Wines have moderate alcohol and tannins and are generally fruity but pale, small amounts adding spice and acidity to the blend. Total French plantings in 2009 were down to 443 ha (1,095 acres). Domaine Montpertuis\u2019 La Counoise de Jeune is 90% Counoise and therefore classified as a Vin de Pays du Gard. The wine is fairly pale and light and, typically, has an aroma of white pepper. Counoise can oxidize easily and is generally better in blends than as a varietal.',
      'Thanks to the enthusiasm and original plant material of the Perrins of Beaucastel from the southern Rh\u00f4ne, Counoise has been exported to California\u2019s Central Coast. Tablas Creek (part owned by the Perrins) in Paso Robles use it in blends but also produce a varietal wine, which they liken to a Beaujolais cru. Here and further north in Santa Cruz, other Rh\u00f4ne enthusiasts such as Randall Grahm at Bonny Doon are disseminating the variety. Further north, in Livermore Valley, Wente also produce a small-lot varietal wine sold only at the cellar door.',
      'There are also limited plantings (15 acres/6 ha) in Washington State: McCrea in Red Mountain and Wines of Substance in Walla Walla both bottle varietal Counoise, the latter\u2019s vines coming from Beaucastel via Tablas Creek.',
    ],
  },
  {
    name: 'COURBU BLANC',
    synonyms: [
      'Courbeau',
      'Courbi',
      'Courbis Blanc',
      'Courbu',
      'Courbut Blanc',
      'Courtoisie',
      'Vieux Pacherenc',
    ],
    description:
      'Minor variety often consorting with the Mansengs in French Basque Country.',
    'origin and parentage': [
      'Courbu is a name given to several distinct varieties in the Pyr\u00e9n\u00e9es-Atlantiques and in the Basque Country, both in the French Pays Basque and in the Spanish Pa\u00eds Vasco. A white-berried Courbu was first mentioned in 1783\u20134 in Juran\u00e7on and its name probably alludes to its curved (courb\u00e9 in French) vine shoots. Galet (1990) considers Courbu Blanc and COURBU NOIR to be colour mutations of the same variety, and PETIT COURBU to be simply a clonal variant of Courbu Blanc, but both these hypotheses have been disproved by DNA profiling (Bowers et al. 1999).',
      'According to Bordenave et al. (2007), Courbu Blanc was introduced to the Spanish area of Hondarribia (Txakoli vineyards, Pa\u00eds Vasco), where it was given the name Hondarribi Zuri (zuri is Basque for \u2018white\u2019), a name also used for CROUCHEN. The use of this name for both Courbu Blanc and Crouchen is a source of confusion because DNA profiling has surprisingly shown that another reference sample of Hondarribi Zuri is in fact identical to NOAH, an American hybrid (Vouillamoz). In addition, Courbu Blanc is often confused with ARRUFIAC in Juran\u00e7on, an error revealed by ampelographic observations and DNA profiling (Bowers et al. 1999; Bordenave et al. 2007).',
      'Courbu Blanc belongs to the Courbu ampelographic group (see p XXVII), which also includes CROUCHEN, possibly explaining why Courbu Blanc is often confused with Crouchen in the Basque Country.',
    ],
    'where its grown': [
      'The far south-west corner of France is home to Courbu Blanc, which is generally a minor ingredient, with GROS MANSENG and/or PETIT MANSENG and other local varieties, in the Juran\u00e7on, B\u00e9arn and Iroul\u00e9guy appellations, although it may in theory play a more prominent role in Pacherenc du Vic-Bilh, again with the Mansengs. Clos Lacabe\u2019s Comp\u00e8re Lutin is one of the few varietal wines available. Clos Lapeyre, who have a few Courbu vines from the 1940s, have recently planted both Courbu Blanc and CAMARALET DE LASSEUBE. Wines are reasonably alcoholic with moderate acidity and are made in sweet or dry styles. There were 43 ha (106 acres) in France in 2008.',
      'It is likely that there are some plantings of Courbu Blanc, mistakenly known as Hondarribi Zuri, in the Pa\u00eds Vasco in Spain.',
    ],
  },
  {
    name: 'COURBU NOIR',
    synonyms: ['Courbu Rouge', 'Courbut', 'Dolceolo', 'Noir du Pays'],
    description:
      'Virtually extinct speciality of B\u00e9arn, south-west France, unrelated to Courbu Blanc.',
    'origin and parentage': [
      'Courbu, a name referring to the curved (courb\u00e9 in French) shape of the vine shoots, has been used for several distinct varieties in the Pyr\u00e9n\u00e9es, but contrary to common belief (Galet 1990), Courbu Noir and COURBU BLANC are not colour variants. The first mention of a black-berried Courbu appeared in 1880 in the Hautes-Pyr\u00e9n\u00e9es (R\u00e9zeau 1997). Interestingly, Courbu Blanc belongs to the Courbu ampelographic group (see p XXVII) while Courbu Noir belongs to the Mansien ampelographic group, named after Manseng and composed of CAMARALET DE LASSEUBE, CAMARAOU NOIR, Courbu Noir, GROS MANSENG, Mancin and PETIT MANSENG. Courbu Noir and MANSENG NOIR, the latter paradoxically a member of the Courbu group, are both called Courbu Rouge and were collectively named \u2018Noirs du Pays\u2019 (Herriko Beltza in Basque dialect, meaning \u2018blacks of the area\u2019). In the Txakoli vineyards in Hondarribia in the Pa\u00eds Vasco close to the French border, the local variety HONDARRIBI BELTZA is often confused either with CABERNET FRANC or with Courbu Noir.',
    ],
    'where its grown': [
      'Although Courbu Noir is in the official French register of varieties, there was little more than a hectare (2.5 acres) remaining in 2008 in the south-west corner of France, where it is a permitted ingredient in the red and ros\u00e9 versions of the B\u00e9arn appellation \u2013 though its partners, CABERNET FRANC and CABERNET SAUVIGNON plus TANNAT, will always dominate. Wines tend to be light in colour and alcohol but relatively tannic.',
    ],
  },
  {
    name: 'CR\u00c2MPO\u0218IE SELEC\u0162IONAT\u0102',
    synonyms: ['Cr\u00eempo\u0219ie Selec\u0163ionat\u0103'],
    description:
      'High-quality, high-acid Romanian variety producing zesty, mineral-laden whites.',
    'origin and parentage': [
      'Cr\u00e2mpo\u0219ie Selec\u0163ionat\u0103 is a seedling of Cr\u00e2mpo\u0219ie obtained in 1972 by Emilian Popescu, Marin Neagu and Petre Banita at the viticultural research centre in Dr\u0103g\u0103\u0219ani, south-west Romania, with the aim of overcoming the problem of uneven berry size caused by the functionally female flowers in Cr\u00e2mpo\u0219ie, an old variety that probably originated in the Dr\u0103g\u0103\u0219ani region in Oltenia, south-west Romania, and has now been supplanted by its more reliable progeny. The selection was obtained by open fertilization of Cr\u00e2mpo\u0219ie so the other parent is unknown.',
    ],
    'where its grown': [
      'Cr\u00e2mpo\u0219ie Selec\u0163ionat\u0103, generally referred to as Cr\u00e2mpo\u0219ie even though the full name must appear on the label, is grown mainly in the Dr\u0103g\u0103\u0219ani region and further east in Panciu and in Constan\u0163a near the Black Sea coast of Romania. The best wines are refined, zesty, high in acidity and have a strong minerality, made even more refreshing by a light bitterness on the finish. It is sometimes blended with other varieties such as SAUVIGNONASSE to increase acidity. Recommended producers include Casa Isarescu and Prince \u0218tirbey, the latter also experimenting with a sparkling version. There were 473 ha (1,169 acres) in Romania in 2008. It is also popular for the table.',
    ],
  },
  {
    name: 'CRIOLLA GRANDE',
    synonyms: ['Criolla Grande Sanjuanina'],
    description:
      'Declining but still very widely planted Argentine pink-skinned variety making humdrum wine.',
    'origin and parentage': [
      'Criolla Grande is indigenous to Argentina and is a member of the Criollas (see CRIOLLA entry for details). According to Mart\u00ednez, Cavagnaro et al. (2003, 2006), Criolla Grande could be related to CEREZA and TEMPRANILLO.',
    ],
    'where its grown': [
      'Although it is in decline in terms of total area planted for wine, Criolla Grande is still the third most planted variety in Argentina after CEREZA and Malbec (COT), with a total of 20,892 ha (51,625 acres) in 2009, the vast majority in Mendoza province. It is used mainly for inexpensive and generally mediocre pink and deeply coloured white wine for early domestic consumption, often sold in litre bottles or cardboard cartons, as well as for the table.',
    ],
  },
  {
    name: 'CROATINA',
    synonyms: [
      'Bonarda',
      'Bonarda di Rovescala',
      'Crovattina',
      'Nebbiolo di Gattinara',
      'Neretto',
      'Spanna-Nebbiolo',
      'Uga del Zio',
      'Uva Vermiglia',
    ],
    description:
      'A fruity dark-skinned variety grown in many different areas of northern Italy and used both solo and in blends.',
    'origin and parentage': [
      'Croatina is believed to originate from Rovescala in the Valle del Versa in the Oltrep\u00f2 Pavese, south of the River Po in northern Italy, where it was first mentioned in the late nineteenth century. In the Oltrep\u00f2 Pavese it is called Bonarda, but this name can be misleading because it is used for at least six distinct varieties (see BONARDA PIEMONTESE). Although Croatina and the old Croatian variety HRVATICA both mean \u2018Croatian girl\u2019, DNA profiling rejected any possible link between them (Maleti\u0107 et al. 1999). Croatina was used to breed ERVI.',
    ],
    'where its grown': [
      'It is grown in Italy, mainly in the Oltrep\u00f2 Pavese in Lombardia, in Piemonte in the provinces of Novara, Cuneo, Alessandria (eg in Colli Tortonesi DOC) and Vercelli, and also in Emilia-Romagna, in the Veneto and on Sardegna. Oltrep\u00f2 Pavese Bonarda DOC is 100% Croatina. Several DOCs such as Colline Novaresi and Coste della Sesia permit varietal Croatina wines. In the hills above Tortona, Walter Massa makes an excellent varietal example, and at Le Piane in Boca, Christophe K\u00fcnzli carefully tends his ancient Croatina vines in a mixed planting trained on the traditional maggiorina trellis. Wines are well coloured, fruity with a certain bite and best drunk young. In 2000, there were 3,280 ha (8,105 acres) in Italy.',
    ],
  },
  {
    name: 'CROUCHEN',
    synonyms: [
      'Cape Riesling',
      'Clare Riesling',
      'Cougnet',
      'Cruchen Blanc',
      'Cruchenta',
      'Messanges Blanc',
      'Paarl Riesling',
      'Sable Blanc',
      'Trouchet Blanc',
    ],
    description:
      'Widely travelled neutral French variety from the western Pyrenees that for long benefited from naming confusion.',
    'origin and parentage': [
      'Crouchen is from the Pyr\u00e9n\u00e9es-Atlantiques in south-western France, where it was first mentioned in 1783\u20134 (R\u00e9zeau 1997): \u2018Crouchen blanc [.\u00a0.\u00a0.], le go\u00fbt est tr\u00e8s doux et bon a manger\u2019 (Crouchen blanc [.\u00a0.\u00a0.] the taste is very sweet and good to eat\u2019). Its name supposedly means \u2018crunchy\u2019.',
      'It has been confused with COURBU BLANC in the Txakoli vineyards in Pa\u00eds Vasco on the north coast of Spain, where both are mistakenly called Hondarribi Zuri, and in Iroul\u00e9guy in south-west France. (See Courbu Blanc for the confusion with NOAH.)',
      'Crouchen belongs to the Courbu ampelographic group (see p XXVII; Bisson 2009). In addition, Crouchen is ampelographically close to the Carmenet group (see CABERNET FRANC). In spite of having very similar names, Crouchen and Cruixen are distinct varieties, the latter found only in vine collections. Crouchen was used to breed NOUVELLE.',
    ],
    'where its grown': [
      'Crouchen has had its time, and its time was prolonged mainly because the variety was so long, and so conveniently, confused with more noble varieties \u2013 with RIESLING in South Africa and with S\u00c9MILLON (once known as Clare Riesling) in Australia. It produces neutral wines that share only one thing with Riesling \u2013 the ability to age. It used to be cultivated as both a table and a wine grape in its home territory, the sandy vineyards in Messanges and Capbreton north of Bayonne on the Atlantic coast of France, but today only isolated vines remain there.',
      'It is likely that there are some plantings of Crouchen in the Spanish Pa\u00eds Vasco on the coast in north-west Spain, where it is mistakenly known as Hondarribi Zuri.',
      'Crouchen was introduced around 1850 to Australia under the name Sales Blanc (presumably a misspelling of Sables, referring to the sandy vineyards where the cuttings originated) and was first cultivated by the Jesuits of Sevenhill Monastery in Clare Valley (Galet 1990). Since its identity was unknown, it was named Clare Riesling (Riesling was often used by Australians to refer to S\u00c9MILLON) until 1976, when the French ampelographer Truel recognized it as Crouchen. Today in the Clare Valley it has been largely replaced by true RIESLING. Of the 117 ha (289 acres) that remain in Australia, almost all are in the warm inland regions of Swan Hill, Riverland and Murray Darling, where their produce presumably disappears into anonymous white blends. One exception is Brown Brothers in Victoria, who make a medium-sweet blend of Crouchen with Riesling. There were just five growers of the variety in 2010.',
      'South Africa has more Crouchen planted than any other country. It was probably introduced to the Cape in 1656, initially named Groenblaarsteen (\u2018Green Leaf Steen\u2019) and then renamed Cape Riesling, Paarl Riesling and South African Riesling (Beuthner 2009). True RIESLING, which became known as Weisser Riesling or Rhine Riesling in South Africa, probably reached the Cape a few years later, in 1664. In the 1950s, Christiaan Johannes Orffer realized that there were two different varieties named Riesling in South Africa but only one was the true Riesling. Although in the 1980s he identified the other, more widely planted, variety as Crouchen Blanc, it continued to be sold under the name Riesling. However, a law passed in 2009, with effect from the 2010 harvest, stipulates that Crouchen must be labelled as such and Riesling no longer needs to be qualified as Weisser or Rhine. (This was already in effect for exports but not domestically.) There were 895 ha (2,212 acres) in 2009, mostly in Breedekloof and Paarl. According to Gary Jordan, of the eponymous wine estate in Stellenbosch, good Crouchen is an oxymoron but Theuniskraal produce a serviceable varietal example, until recently labelled Riesling, of course.',
    ],
  },
  {
    name: 'CROVASSA',
    synonyms: ['Croassa'],
    description: 'Very minor red variety in the Valle d\u2019Aosta.',
    'origin and parentage': [
      'Crovassa has been cultivated in the Valle d\u2019Aosta in north-east Italy for at least two centuries but has never been an important variety. It has no genetic link with other Valle d\u2019Aosta varieties but shows some DNA similarities with NEBBIOLO, suggesting it could originate in Piemonte (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Crovassa is currently planted only in the Valle d\u2019Aosta, in the far north west of Italy, mainly around the towns of Issogne and Donnas, where it is usually blended with other local varieties. Just 15 ha (37 acres) were recorded in Italy in 2000.',
    ],
  },
  {
    name: 'CSABA GY\u00d6NGYE',
    synonyms: [
      'Cabski Biser',
      'Csabagy\u00f6ngye',
      'Julski Muskat',
      'Pearl of Csaba',
      'Perl do Saba',
      'Perla Czabanska',
      'Perla di Csaba',
      'Perle de Csaba',
      'Perle von Csaba',
      'Rindunicaz Strugurilor',
      'Vengerskii Muskatnii Rann\u00fcj',
      'Zemcug Saba',
    ],
    description:
      'Hungarian table grape occasionally turned into light, grapey whites.',
    'origin and parentage': [
      'Csaba Gy\u00f6ngye, literally Pearl of Csaba, is a cross obtained by the private breeder J\u00e1nos Mathi\u00e1sz (1838\u20131921) around 1890 in Hungary. According to Kozma et al. (2003), Mathi\u00e1sz presented the seeds to Adolf Stark (1834\u20131910), another \u2013 more famous \u2013 grape breeder, who grew it in his garden in B\u00e9k\u00e9scsaba in southern Hungary, hence the name. Csaba Gy\u00f6ngye was long assumed to be a Bronnertraube \u00d7 MUSCAT OTTONEL cross (Galet 2000) but DNA profiling has shown that Csaba Gy\u00f6ngye is a progeny of MADELEINE ANGEVINE and, putatively, MUSCAT FLEUR D\u2019ORANGER (Kozma et al. 2003; Galb\u00e1cs et al. 2009).',
      'Csaba Gy\u00f6ngye was used by Hungarian breeders to create many table grapes such as Sz\u0151l\u0151skertek Kir\u00e1lyn\u0151je (known in Germany as K\u00f6nigin der Weing\u00e4rten), as well as a few wine-grape varieties such as IRSAI OLIV\u00c9R and ZALAGY\u00d6NGYE.',
    ],
    'where its grown': [
      'Although Csaba Gy\u00f6ngye was bred for the table and is quite widely grown in Romania, Hungary and Bulgaria for that purpose, its thin skins make it difficult to transport without damage to the berries. It is occasionally used in Hungary to make light, grapey, aromatic wines, such as the semi-sweet, early-bottled example produced by Varga in Badacsony in western Hungary, but it is difficult to say what proportion of the 92 ha (227 acres) cultivated are destined for wine.',
    ],
  },
  {
    name: 'CS\u00d3KASZ\u0150L\u0150',
    synonyms: [
      'Cs\u00f3ka Sz\u0151l\u0151',
      'Cs\u00f3ka',
      'Fekete Magyarka',
      'Kleinhungar Blauer',
      'Magyarka Neagra',
      'R\u00e1cfekete',
      'Vadfekete',
    ],
    description: 'Old Hungarian variety in the throes of rediscovery.',
    'origin and parentage': [
      'Cs\u00f3kasz\u0151l\u0151, named after cs\u00f3ka, meaning \u2018jackdaw\u2019, and sz\u0151l\u0151, meaning \u2018grape\u2019, in reference to its almost black berries, is an old, almost forgotten Hungarian variety that used to be widespread in the Carpathian Basin.',
    ],
    'where its grown': [
      'Neglected and close to extinction, this old variety is currently being re-evaluated and replanted by a handful of growers in Hungary who are making wines that are well coloured, spicy and vividly fruity and have bright acidity, fairly high alcohol and marked but velvety tannins. They fall somewhere between PINOT NOIR and K\u00e9kfrankos (BLAUFR\u00c4NKISCH) in style, showing a certain elegance, although producers are still feeling their way towards a typical style. J\u00f3zsef Szentesi, just south west of Budapest, was the first to replant: his 2004 was probably the first Cs\u00f3ka wine for 150 years. Other recommended producers include Bussay in Zala in the far west of Hungary (although his production of this variety is tiny and not readily available), Kal\u00f3 Imre in Eger in the north, and Vylyan and Attila Gere in Vill\u00e1ny in the south west. Cs\u00f3ka has now also been registered for the Vill\u00e1ny appellation and is no longer relegated to table-wine status.',
    ],
  },
  {
    name: 'DAFNI',
    synonyms: ['Dafnia', 'Daphni', 'Daphnia'],
    description:
      'Distinctively laurel-scented variety from Kr\u00edti (Crete) recently rescued from oblivion.',
    'origin and parentage': [
      'Dafni is an old variety from the Greek island of Kr\u00edti. The name derives from daphne, meaning \u2018laurel\u2019, referring to the distinctive aroma of the wine.',
    ],
    'where its grown': [
      'Dafni is grown exclusively and to a very limited extent on the island of Kr\u00edti in Greece, where it has been rescued and rehabilitated by Lyrarakis, who make a distinctive laurel-scented varietal wine which is moderate in alcohol (usually around 12% or 12.5%) with flavours of rosemary, tropical fruit and ginger spice. The aroma seems to be mainly in the thick skins and intensive pruning is necessary to keep the yields down. Dafni had virtually disappeared at the end of the 1980s but there are now around 15 ha (37 acres) on the island, mostly in the centre, and a few producers are currently experimenting with this variety elsewhere in Greece.',
    ],
  },
  {
    name: 'DAKAPO',
    synonyms: ['Geisenheim 7225-8'],
    description: 'Red-fleshed German cross adds colour to blends.',
    'origin and parentage': [
      'A DECKROT \u00d7 BLAUER PORTUGIESER cross bred by Helmut Becker at Geisenheim in the Rheingau, Germany, in 1972.',
    ],
    'where its grown': [
      'The name Dakapo, based on the Italian da capo, meaning \u2018extra\u2019 or \u2018encore\u2019, hints at the purpose of this intensely coloured wine: to darken blends. This tannic but otherwise pretty neutral variety was included on the official German register of varieties in 1999 and there are now 59 ha (146 acres) planted in Germany, with the biggest plantings in Baden, Rheinhessen and the Pfalz. Haxel in Cochem in the Mosel region produce a sweet version and Rothweiler and the Staatsweingut in Hessische Bergstrasse produce dry versions.',
      'Switzerland had 12 ha (30 acres) in 2009, mainly in the canton of Schaffhausen.',
    ],
  },
  {
    name: 'DALKAUER',
    synonyms: ['Beutelrebe'],
    description: 'Rare German offspring of Riesling and A N Other.',
    'origin and parentage': [
      'This variety was allegedly imported from the Caucasus to Germany after 1945 by the owner of Weingut G\u00f6rz in Bad Kreuznach in Rheinland-Pfalz, whence it spread to the Nahe as Beutelrebe (literally \u2018bag grape\u2019; Ambrosi et al. 1997; Hillebrand et al. 2003). It was registered in 1982 by Georg Dalkowski, a private vine breeder in Bad Kreuznach, hence its name. Some think it could be a RIESLING \u00d7 GR\u00dcNER VELTLINER cross, others claim it is a cross between Riesling and an unknown variety, and others think it is a mutation of Riesling. DNA profiling could easily prove or disprove these hypotheses.',
    ],
    'where its grown': [
      'There are very limited plantings in Rheinhessen and the Nahe in Germany.',
    ],
  },
  {
    name: 'DAMASCHINO',
    synonyms: [
      'Alicante Branco',
      'Beldi',
      'Damaschena',
      'Farana  orFaranah',
      'Farranah',
      'Mayorquin',
      'Planta Fina',
      'Planta Fina de Pedralba',
      'Planta Pedralba',
    ],
    description:
      'Ancient but neutral white included in blends around the Mediterranean and in Portugal.',
    'origin and parentage': [
      'Damaschino was first documented on Sicilia in the mid nineteenth century but it is said to have been introduced at the time of Arab rule (ninth and tenth centuries). Although its name might suggest a link with the city of Damascus, there is no historical or genetic evidence so far that the variety originates from Syria; its origin is still unknown but it probably comes from somewhere on the Mediterranean coast.',
      'The belief that Planta Fina, the Spanish name for Damaschino, and VERDEJO are the same (Iba\u00f1ez et al. 2003) seems to be a mistake, because their DNA profiles are distinct.',
    ],
    'where its grown': [
      'Damaschino is planted all over the Mediterranean under various names and used both as a table grape and as a wine grape. On the island of Sicilia, Italy, it used to be widely planted but has gradually been replaced by more-resistant varieties such as CATARRATTO BIANCO and more recently by GRILLO. At present, Damaschino is cultivated mainly in the Marsala area, where it is authorized in the DOC of the same name, blended with local varieties such as Grillo, Catarratto Bianco and INZOLIA. Elsewhere it may be blended with GARGANEGA (under the name Grecanico Dorato). The Delia Nivolelli DOC, established in 1998, allows for a varietal Damaschino. The Italian agricultural census recorded 384 ha (949 acres) in 2000 but there were 444 ha (1,097 acres) on Sicilia in 2008.',
      'Mayorquin is authorized for the Bellet AC in south-east France but there was less than 1 ha (2.5 acres) planted in 2006.',
      'In Spain, it is cultivated in Valencia under the names Planta Fina or Planta Fina de Pedralba and authorized in the Valencia DO. In 2008 there was a total of 227 ha (561 acres) in Spain. Here varietal wines are light, not especially aromatic, with an appley flavour. In Portugal, under the name Alicante Branco, there were 1,167 ha (2,884 acres) in 2010 and recommended producers include Barbeito on Madeira and Vinolive in Beira Interior.',
      'Plantings in Algeria, under the names Farana, Faranah or Farranah, have decreased since independence, but Farana is still used to make dry whites in the mountainous regions of Algeria, eg by Atlasian Cellars in the Coteaux de Tlemcen appellation. In Tunisia, Beldi is among the most widespread table and wine grape varieties.',
      'Under the name Farana, a small amount of this variety was produced in Australia but not made as a varietal wine and the production has dwindled to virtually nothing since 2005.',
    ],
  },
  {
    name: 'DE CHAUNAC',
    synonyms: ['Cameo', 'Seibel 9549'],
    description: 'French hybrid now at home in Canada and New York State.',
    'origin and parentage': [
      'A hybrid between Seibel 5163 and Seibel 793 obtained by Albert Seibel (see PRIOR for the full pedigree of Seibel 5163), where:',
      '\u2013\u00a0\u00a0\u00a0Seibel 793 is a Seibel 41 \u00d7 Vitis rupestris \u2018Gigantesque\u2019 Jaeger hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 41 is a Munson \u00d7 Vitis vinifera subsp. vinifera hybrid (ie an unknown variety) (see PRIOR for the full pedigree of Munson).',
      'De Chaunac was the name of the French Canadian chemist and later director of research at Brights Wines in Niagara Falls, Ontario, between 1933 and 1961. After the Second World War, he imported and experimented with a range of hybrids, including De Chaunac.',
    ],
    'where its grown': [
      'Although De Chaunac was bred in France, its current home is quite definitely in Canada and North America. It doesn\u2019t even feature in official French vineyard statistics but there were over 100 acres (40 ha) in Ontario, Canada, in 2006 and about the same in New York State, plus minor plantings in Qu\u00e9bec and the American Northeast and Midwest. At one time it was the most widely planted hybrid in Canada. Buckingham Valley in Pennsylvania make a good example.',
    ],
  },
  {
    name: 'DEBINA',
    synonyms: [
      'Debina Metsovou',
      'Debina Palea',
      'Dempina',
      'Ntempina',
      'Z\u00edtsa',
    ],
    description:
      'Highly localized, high-acid Greek variety making light, fresh and digestible wines.',
    'origin and parentage': [
      'Debina is probably indigenous to the Z\u00edtsa area in Io\u00e1nnina in the \u00cdpeiros (Epirus) region, north-west Greece, where it has traditionally been cultivated. According to Manessis (2000), Debina was praised by Lord Byron and his companion Hobhouse in the nineteenth century.',
      'Contrary to local belief, Debina (under the synonym Dempina) is not identical to Debina Kala, nor to Debina Teki, two varieties from Skrapar in south-eastern Albania that are no longer commercially cultivated, although they may well be genetically closely related to Debina (Ladoukakis et al. 2005).',
    ],
    'where its grown': [
      'Debina is the sole authorized variety for the Z\u00edtsa appellation in \u00cdpeiros so it is not surprising that it is the most widely planted variety there (459 ha/1,134 acres in 2008). In this remote part of north-west Greece, at altitudes of 600\u2013700 m above sea level and with a climate influenced by the Ionian Sea, the wines are generally light in every way, not particularly aromatic and high in acidity, which is probably why many are made in off-dry styles or used as the base for sparkling wines. The fruit is also at risk from unpredictable September weather so that it can be difficult to ripen the grapes fully (Lazarakis 2005). However, when yields are restricted, the wines may be fresh, elegant and thirst-quenching, in some instances having an aroma of apples and a refreshing, lightly sour aftertaste. Recommended producers include Glinavos, the Io\u00e1nnina Co-operative of Z\u00edtsa, and Katogi & Strofilia. It is also found in Attalandi near Lam\u00eda, south and east of Z\u00edtsa.',
    ],
  },
  {
    name: 'DEBINE E BARDH\u00cb',
    synonyms: ['Debin'],
    description: 'Declining Albanian of mysterious origin.',
    'origin and parentage': [
      'Debine e Bardh\u00eb, literally \u2018white Debine\u2019, possibly originates from the area around the towns of P\u00ebrmet and K\u00eblcyr\u00eb in south-east Albania. However, the ampelographic identity of this variety is still obscure because two reference vines called Debina Teki and Debina Kala (berry colour not given), sampled in Skrapar in south-eastern Albania, turned out to have distinct DNA profiles in Ladoukakis et al. (2005) so it is not known which is the true-to-type reference: Debina Teki is genetically close to the Greek DEBINA, which would explain the name correspondence, while Debina Kala is genetically close to Bora, an Albanian variety no longer cultivated.',
      'DNA profiling is needed to verify whether Debine e Bardh\u00eb is a colour mutation of DEBINE E ZEZ\u00cb, and whether Debine e Bardh\u00eb is identical to Debina Teki or Debina Kala.',
    ],
    'where its grown': [
      'The variety is grown mainly in south-east Albania but is losing ground to international varieties.',
    ],
  },
  {
    name: 'DEBIT',
    synonyms: ['Belan', '\u010carapar', 'Debit Bijeli', 'Pulji\u017eanac'],
    description:
      'Vine producing alcoholic wine throughout Dalmacija (Dalmatia).',
    'origin and parentage': [
      'Debit is thought to be indigenous to Croatia. The name Debit means \u2018debt\u2019, alluding to its high yields, which supposedly allow growers to pay their debts, like Pagadebiti in Italy, although they are genetically distinct (Vouillamoz).',
    ],
    'where its grown': [
      'Debit is widespread in the whole of Dalmacija, Croatia, and authorized in all the main wine regions there: Sjeverna Dalmacija (Northern Dalmatia), Dalmatinska Zagora (Dalmatian Hinterland), Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia). Varietal wines tend to be powerful and structured but remain fresh, with flavours of green fruits; the variety can add concentration and depth to blends. It represented 1.7% of the Croatian vineyard area in 2009, about 550 ha (1,359 acres). Recommended producers include Bibich and Vinoplod.',
    ],
  },
  {
    name: 'DECKROT',
    synonyms: ['Freiburg 71-119-39'],
    description: 'Red-fleshed German cross bred for colour.',
    'origin and parentage': [
      'A PINOT GRIS (Rul\u00e4nder) \u00d7 TEINTURIER (F\u00e4rbertraube) cross created in 1938 in Freiburg by Johannes Zimmermann. It was used to breed DAKAPO.',
    ],
    'where its grown': [
      'This very deeply coloured, red-fleshed variety (Deckrot is German for teinturier) entered the official variety register in Germany in 1991 and is used mainly to darken red blends. There are 21 ha (52 acres) planted in Germany, mainly in Baden in the south, where the Winzergenossenschaft Achkarren produce a varietal wine.',
      'There are 5 ares in Switzerland \u2013 just 5% of a hectare.',
    ],
  },
  {
    name: 'DELAWARE',
    synonyms: [
      'Delavar',
      'Heath Grape',
      'Ladies Choice',
      'Powell',
      'Ruff Heath',
    ],
    description:
      'Historic variety of obscure origin once popular in the Midwest and in the eastern US states.',
    'origin and parentage': [
      'The historical origin of this variety is still obscure but it is thought to come from Frenchtown in Delaware County, Ohio, US. In 1792, a French-speaking Swiss aristocrat, Paul Henri Mallet-Prevost, fled first from France and then from Switzerland, across Europe, wanted by the French for having hidden his countrymen during the French Revolution.',
      'In 1794 he arrived in New York city and later in the same year bought from Thomas Lowrey a piece of land containing several mills in Alexandria on the Delaware River, between Milford and Stockton, at Kingwood township, Hunterdon County, New Jersey. The settlement that developed around the mills became known as Frenchtown, even though Mallet-Prevost was actually Swiss.',
      'When he first came to the US, Mallet-Prevost brought some European vines, allegedly from Burgundy, and made some experimental crossings with native American grapes. The most interesting of them was supposedly obtained from a crossing of a native vine, possibly Vitis labrusca, Vitis aestivalis or CATAWBA, with a European variety, but this has not yet been analysed by DNA profiling.',
      'Mallet-Prevost gave this vine to several of his Frenchtown neighbours, including a blacksmith and wheelwright named Benjamin Heath. When the latter moved to the township of Concord in Delaware County in 1837, he took the vine with him and cultivated it on his farm on Freshwater Road. His neighbour Abraham Thompson, editor of the Delaware Gazette and horticulturist, was so impressed by Heath\u2019s grapes that he sent a sample for study to the Massachusetts Horticultural Society in 1855, and it is said that the Society named it \u2018Grape from Delaware city, Ohio\u2019. Thompson, along with pioneers such as Campbell from Courtland County in New York, who had moved to Delaware, and Frederick P Vergon from Columbus (Delaware County) later distributed the grape around the country, where it became highly popular under the simple name Delaware.',
    ],
    'where its grown': [
      'Delaware is grown mainly in the Northeast and Midwest of the United States. The biggest area is found in New York State (265 acres/107 ha in 2006) but it is also planted in states such as Michigan, Ohio and Pennsylvania. Used for sparkling wine as well as for still wine in a wide range of sweetness levels, from dry to icewine, Delaware is losing ground to other, more disease-resistant and higher-yielding, American hybrids. The wines are generally white or slightly pink and simple but fruity.',
      'Delaware is often part of a blend but varietal wines in various styles are produced by Barrington Cellars, Keuka Lake, Mayers Lake Ontario, Thousand Islands and Wagner in New York State; Nassau Valley in Delaware; St Croix in Minnesota; and Klingshirn in Ohio (an icewine).',
      'Delaware is also authorized, along with three other American hybrids, for southern Austria\u2019s Uhudler (see CONCORD).',
      'It is quite widely planted in Japan, although the vineyards dedicated to wine grapes amounted to not much more than 50 ha (124 acres) in 2009, divided between Kagawa and Hokkaido. Korea also had around 80 ha (198 acres) in 1997.',
    ],
  },
  {
    name: 'DELISLE',
    synonyms: ['ES 7-5-41'],
    description:
      'Very minor, cold-hardy North American hybrid with marked petrol-like aromas.',
    'origin and parentage': [
      'An Elmer Swenson 2-2-22 \u00d7 ESPIRIT hybrid made by Elmer Swenson in Osceola, Wisconsin, US, in which Elmer Swenson 2-2-22 is an Elmer Swenson 283 \u00d7 Elmer Swenson 193 hybrid (see SABREVOIS for the pedigrees). Delisle is therefore an extremely complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\naestivalis, Vitis\nlincecumii, Vitis\nrupestris, Vitis\ncinerea and Vitis\nberlandieri hybrid.',
    ],
    'where its grown': [
      'Delisle is planted to a very limited extent in Canada, particularly in Ontario and Qu\u00e9bec. Domaines et Vins G\u00e9linas in Qu\u00e9bec is one of the few producers of varietal wines. Wines are said to have citrus or tropical flavours and a strong petrol-like character.',
    ],
  },
  {
    name: 'DEV\u00cdN',
    synonyms: ['D\u011bv\u00edn', 'TCVCB 15/4'],
    description:
      'Slovakian cross producing full-bodied, spicy whites in Slovakia and the Czech Republic.',
    'origin and parentage': [
      'Dev\u00edn is a GEW\u00dcRZTRAMINER \u00d7 ROTER VELTLINER cross (with Slovakian names Tram\u00edn \u010cerven\u00fd \u00d7 Veltl\u00ednske \u010cerven\u00e9) obtained in 1958 by Dorota Posp\u00ed\u0161ilov\u00e1 and Ondrej Korp\u00e1s at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia. It was initially grown in Moravian vineyards (Czech Republic) under the name Ryvola before being named after a ruined castle near Bratislava. Authorized in Slovakia in 1997 and included in the Czech register of varieties in 1998.',
    ],
    'where its grown': [
      'Dev\u00edn is planted throughout Slovakia (144 ha/356 acres in 2009) and is growing in popularity. It gives its best results in the Ju\u017enoslovensk\u00e1 and Malokarpatsk\u00e1 wine regions in the south west. It produces full-bodied, low-acid, lightly aromatic \u2013 spicy, fruity and floral \u2013 wines such as those made by Mrva & Stanko (a sweet version) and Karpatsk\u00e1 Perla (dry). Sugar levels can be very high and it is well suited to highly aromatic botrytized wines. However, if picked too late, it gives flabby wines, while high yields and poor ripening lead to a bitter aftertaste. There were 19 ha (47 acres) in Morava in the south east of the Czech Republic in 2009.',
    ],
  },
  {
    name: 'DIAGALVES',
    synonyms: [
      'Carnal',
      'Dependura',
      'Diego Alves',
      'Diogalves',
      'Fernan Fer',
      'Formosa',
      'Formosa Dourada',
      'Formosa Portalegre',
      'Pendura',
      'Pendura Amarela',
      'Villanueva',
    ],
    description:
      'Southern Portuguese variety generally used in blends or eaten fresh.',
    'origin and parentage': [
      'Not much is known about the origin of Diagalves, a Portuguese variety that should not be confused with the Spanish PALOMINO FINO, which is sometimes also called Diagalves but has a distinct DNA profile in Veloso et al. (2010). It was used to breed RIO GRANDE and SEARA NOVA.',
    ],
    'where its grown': [
      'Diagalves is grown throughout Portugal both for the table and for wine and is authorized in regional wines such as IGP Alentejano and IGP Tejo. Typically wines have a slight grassy aroma, are low in most things \u2013 acidity, alcohol and structure \u2013 and should be drunk young. In the Alentejo, Enoforum\u2019s Real Forte includes Diagalves in the blend. There were 1,102 ha (2,723 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'DIMRIT',
    synonyms: ['Dimlit', 'Dimrit Kara', 'Dirmit Kara'],
    description:
      'Poor-quality, dark-skinned Turkish variety little used for wine so far.',
    'origin and parentage': [
      'Dimrit is a variety from central Turkey, probably originating from the Nev\u015fehir, K\u0131r\u015fehir, Kayseri and Ni\u011fde regions of Kapadokya (Cappadocia) in central Turkey. Akdimrit, Burdur Dimriti and Dimrit are often considered to be members of a so-called Dimrit group but DNA profiling has recently shown that twenty-two accessions with a name based on Dimrit corresponded to twenty-one distinct and mostly unrelated varieties (\u015eelli et al. 2007).',
    ],
    'where its grown': [
      'Widely planted in Kapadokya, Turkey, but only a small proportion is used to make wine, which is generally poor quality and hidden in blends. The variety may have greater potential, however. There were 729 ha (1,801 acres) in Turkey in 2010.',
    ],
  },
  {
    name: 'DIMYAT',
    synonyms: [
      'Ahorntraube',
      'Bekasz\u0151l\u0151',
      'Dertonia',
      'Dimiat',
      'Galan',
      'Grobweisser',
      'Misket Slivenski',
      'Ro\u015fioar\u0103',
      'Semendria',
      'Smederevka',
      'Smederevka Bianca',
      'Szemendriai Feh\u00e9r',
      'T\u00f6r\u00f6klugas',
      'Yapalaki',
      'Zoumiatiko',
      'Zumiatico',
      'Zumyat',
    ],
    description: 'Productive, everyday Bulgarian white.',
    'origin and parentage': [
      'Dimyat is a very old Balkan variety, possibly originating in Bulgaria, where it shows the greatest biodiversity, with at least three main types: Dimyat Cherven with red-violet berries, Dimyat Cheren with black berries and Dimyat Edar, an oversized mutation with large leaves and berries and twice as many chromosomes (Katerov 2004). Its Bulgarian origin is supported by recent genetic analyses which place Dimyat (under the name Smederevka) among \u2018nonauthentic Macedonian grapevines\u2019 (\u0160tajner, Angelova et al. 2009) and in the middle of seventy-four other varieties from Bulgaria (Hvarleva et al. 2004). In addition, DNA parentage analysis has suggested a likely parent\u2013offspring relationship with the prolific GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram).',
      'Dimyat was used to breed CABERNET SEVERNY, MISKET VARNENSKI, NEOPLANTA  and SIRMIUM.',
    ],
    'where its grown': [
      'Dimyat produces crisp, everyday whites, at various levels of sweetness, often with a delicate vanilla perfume, although it is also made into sparkling wine, used as a table grape and distilled. It is widely planted across Bulgaria, with a total of 6,057 ha (14,967 acres) in 2009. Although the majority is in the eastern provinces of Varna and Burgas that adjoin the Black Sea coast, the better wines come not only from Varna and Pomorije on the coast but also from Preslav and Shumen in the north east and Chirpan in the centre of the country. Sparkling wines are made in Lyaskovets, Varna and Chirpan.',
      'Under the name Smederevka, it is the leading white wine variety in the Republic of Macedonia, where it produces unexceptional everyday wines. Also found in Smederevo on the Dunav (Danube) in Serbia.',
    ],
  },
  {
    name: 'DINDARELLA',
    synonyms: ['Dindarella Rizza', 'Pelada', 'Pelara'],
    description:
      'Rare, dark-skinned Veneto variety well suited to dried-grape wine styles.',
    'origin and parentage': [
      'In the nineteenth century, Dindarella and Pelara were considered to be two distinct varieties from the Veneto in northern Italy because Pelara often has far fewer berries. However, morphological studies in the twentieth century and recent DNA profiling have shown that they are one and the same variety (Vantini et al. 2003).',
    ],
    'where its grown': [
      'Formerly widespread in Valpolicella, northern Italy, Dindarella is now scarcely cultivated but a small percentage is occasionally included in Valpolicella blends, especially those for Amarone and Recioto DOCGs. Tedeschi always include about 1% Dindarella in their Monte Olmi Amarone. It is authorized in Garda Orientale, Valdadige and Valpolicella DOCs. The variety produces wines that are light ruby in colour and lightly structured but notably aromatic: floral, fruity and spicy. Cantine Aldegheri in Sant\u2019Abrogio Valpolicella produce a varietal Dindarella, sold as an IGT Rosso Veronese, for which the grapes are dried for several months after harvest and then undergo a very long, slow fermentation. There were just 9 ha (22 acres) in Italy in 2000. Brigaldara produce a Dindarella rosato.',
    ],
  },
  {
    name: 'DI\u0160E\u0106A RANINA',
    synonyms: [
      'Di\u0161e\u0107a Ranina Bijela',
      'Petrinjska Ranina',
      'Petrinjska Bijela',
    ],
    description:
      'Rare and very localized Croatian variety from south of Zagreb.',
    'origin and parentage': [
      'Di\u0161e\u0107a Ranina is a rare variety from the Moslavina and Pokuplje wine regions, just south of Zagreb, where it has been grown for centuries. This variety is very similar to BOUVIER, an unknown natural cross that has a Muscat aroma and is also called Ranina or Ranina Bela in Slovenia. Ampelographic observations and DNA profiling are needed to determine whether or not they are identical.',
    ],
    'where its grown': [
      'Di\u0161e\u0107a Ranina, which produces wines with a slight Muscat aroma, is found and officially authorized in the Moslavina and Pokuplje regions in Croatia, south of Zagreb. Producers of varietal wines include Florijanovi\u0107 in Potok and Boris Mesari\u0107 in Kutina.',
    ],
  },
  {
    name: 'DOBRI\u010cI\u0106',
    synonyms: [
      '\u010cihovac',
      'Crljenak Slatinski',
      'Dobri\u010di\u0107 Crni',
      'Dobrovoljac',
      'Krucalin',
      'Okru\u010danac',
      'Sholtanats',
      'Slatinjac',
      'Slatinski',
    ],
    description:
      'Rare Croatian variety high in sugar, rich in colour and used mainly in red blends on the island of \u0160olta.',
    'origin and parentage': [
      'Dobri\u010di\u0107 is an old variety from southern Dalmacija (Dalmatia) in Croatia, possibly originating from the island of \u0160olta, where it is still cultivated to a very limited extent though not as widely as it once was. Maleti\u0107 et al. (2004) have proved through DNA parentage analysis that Dobri\u010di\u0107 and TRIBIDRAG (also known as Primitivo or Zinfandel) are the parents of PLAVAC MALI. In addition, a possible parent\u2013offspring relationship between BABI\u0106 and Dobri\u010di\u0107 has recently been discovered (Zduni\u0107, Peji\u0107 et al. 2008; see Tribidrag for the full pedigree).',
    ],
    'where its grown': [
      'Dobri\u010di\u0107 is cultivated almost exclusively on the island of \u0160olta, south of Split and west of the island of Bra\u010d in Croatia, but since 2004 it has been authorized throughout Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia). Varietal wines, which tend to be very rich in colour and extract and low in acidity, are generally used to add colour in blends.',
    ],
  },
  {
    name: 'DOLCETTO',
    synonyms: ['Dolcetto Nero', 'Nibi\u00e8u', 'Nibi\u00f2', 'Ormeasco'],
    description: 'Deeply coloured, low-acid, fragrant Piemontese red.',
    'origin and parentage': [
      'The earliest mention of Dolcetto appears in 1593 in Dogliani near Cuneo (Comba and Dal Verme 1990). The name Dolcetto, meaning \u2018little sweet\u2019, comes from the low natural acidity of the berries but Dolcetto wines are almost always dry.',
      'Galet\u2019s (2000) contention that Dolcetto is identical to the DOUCE NOIRE of Savoie, known as Charbono in California but distinct from the true (but no longer commercially cultivated) Charbono of Piemonte, has been disproved by DNA profiling at the University of California at Davis. Some authors considered Dolcetto to be morphologically similar to Zinfandel (TRIBIDRAG) but recent DNA studies contradict this hypothesis (Cal\u00f2, Costacurta et al. 2008).',
      'Dolcetto was crossed with CHATUS to producePASSAU, SAN MARTINO and VALENTINO NERO.',
      'A white-berried Dolcetto Bianco does exist but it has, paradoxically, nothing to do with the black-berried Dolcetto (Schneider and Mannini 2006).',
    ],
    'where its grown': [
      'Formerly widely cultivated in Piemonte, north-west Italy, Dolcetto has been gradually losing ground to BARBERA since the phylloxera plague but is still widespread in the provinces of Cuneo (Langhe and Ormea regions), Alessandria (Acqui and Ovada) and Asti (Colli del Monferrato). It is authorized as a blending partner for Barbera in several Piemonte DOCs but as a varietal in many more, including Acqui, Alba, Asti, Diano d\u2019Alba, Langhe and Monferrato, and has reached the heights of DOCG in Dogliani. Alba, Ovada and Dogliani are quantitatively the most significant DOCs for varietal Dolcetto but the finest quality is generally said to come from Alba.',
      'Marcarini in La Morra (Barolo country) make a fine, unusually full-bodied wine, Boschi di Berri, from ungrafted centenarian vines \u2013 the oldest Dolcetto vineyard in Italy \u2013 that survived phylloxera thanks to the sandy soils. Other good producers include G D Vajra and Enrico e Marziano Abbona.',
      'In Liguria, Ormeasco is a particular clone of Dolcetto historically cultivated in Pornassio, where the DOC allows both a dry and a sweet passito version, and in Pieve di Teco in the province of Imperia, although it takes its name from the village of Ormea in Piemonte. Dolcetto is also grown in various other parts of Italy, including the Oltrep\u00f2 Pavese, as well as further south, in Umbria.',
      'Varietal Dolcetto wines are generally deeply coloured, soft, round and fruity; the best are fragrant with flavours of liquorice and almonds. Although some well-made and more highly structured wines \u2013 from Alba or Ovada, for example \u2013 may last five years or more, most are best drunk within two or three years of the harvest. The variety\u2019s unusual combination of notably low acidity and high tannins creates a challenge for winemakers and softer extraction and shorter fermentation times generally produce the best results.',
      'Total plantings in Italy in 2000 amounted to 7,450 ha (18,409 acres). Mas de Daumas Gassac in southern France have 0.1 ha (0.25 acres)!',
      'Dolcetto is scattered in tiny pockets around the United States, particularly in California (eg Acorn, Bonny Doon, Cartlidge & Brown, Duxoup, Jacuzzi, Palmina, Kent Rasmussen) and to a lesser extent in Washington State (Woodward Canyon) and Oregon (eg Ponzi, Stag Hill). It is also grown in Texas on a very limited scale.',
      'A variety known as Dolcetto is grown on an extremely limited scale in Argentina but it is not known if this is the true Dolcetto or not.',
      'In Australia, more than twenty producers have planted Dolcetto, in regions as diverse as the Hunter Valley, the Barossa Valley and the Adelaide Hills. However, total plantings are not significant enough to feature in official statistics. In New Zealand, Heron\u2019s Flight in Matakana is one of the few who produce a varietal Dolcetto.',
    ],
  },
  {
    name: 'DOLCIAME',
    synonyms: ['Malfiore', 'Parlano', 'Uva delle Vecchie'],
    description: 'Virtually extinct Umbrian white.',
    'where its grown': [
      'Almost extinct variety, scarcely cultivated in Umbertide and Citt\u00e0 di Castello in the province of Perugia, Italy, and used in blends. Just 7 ha (17 acres) were recorded in the Italian agricultural census in 2000.',
    ],
  },
  {
    name: 'DOMINA',
    synonyms: ['Geilweilerhof 4-25-7'],
    description: 'Modern red German cross planted mainly in Franken.',
    'origin and parentage': [
      'A BLAUER PORTUGIESER \u00d7 PINOT NOIR (Sp\u00e4tburgunder) cross created by Peter Morio and Bernhard Husfeld in 1927 at the Geilweilerhof research centre in the Pfalz in Germany. Domina, the Latin word for \u2018lady\u2019 or \u2018mistress\u2019, was used to breed CALANDRO.',
    ],
    'where its grown': [
      'Domina was added to the official register of varieties in Germany in 1974 and there are now 404 ha (998 acres) planted, 347 ha (857 acres) of those in Franken. In good years, wines are full-bodied, deeply coloured with firm tannins and blackberry or cherry flavours. They are generally fresh but lack the finesse of their PINOT NOIR parent, though they are generally well suited to barrel ageing. Recommended producers include Brennfleck and Roth in Franken.',
      'There are also minuscule plantings in Switzerland (8 ares/1,000 square yards).',
    ],
  },
  {
    name: 'DONZELINHO TINTO',
    synonyms: ['Donzelinho do Castello', 'Donzelynho', 'Tinta do Minho'],
    description:
      'Old, rare Douro variety playing a very small, inglorious part in blended wines, fortified and unfortified.',
    'origin and parentage': [
      'Donzelinho Tinto is an old Portuguese variety that was mentioned in the Douro as early as 1531 by Rui Fernandes (1531\u20132) under the spelling Donzelyhno but, since the berry colour was not given, this could refer to DONZELINHO BRANCO, which is a distinct variety.',
    ],
    'where its grown': [
      'Donzelinho Tinto, like the unrelated but equally old and rare DONZELINHO BRANCO, is found mainly in field blends in north-eastern Portugal, where it is authorized for appellation wines such as Douro and Tr\u00e1s-os-Montes and regional wines such as Duriense and Trasmontano but is used mainly as a minor ingredient in port. The Real Companhia Velha recently planted the variety separately but were disappointed with the results and pulled the vines out after a few years. There were 87 ha (215 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'DORADILLA',
    synonyms: ['Forastera Blanca', 'Plateado'],
    description:
      'Very minor variety from M\u00e1laga in Andaluc\u00eda frequently confused with other varieties.',
    'origin and parentage': [
      'Doradilla is a variety from M\u00e1laga that is distinct from Doradillo, the name under which CAYETANA BLANCA (Ja\u00e9n Blanco or Pardina in Spain) was introduced from Spain to Australia in the nineteenth century (Galet 2000). Indeed, recent DNA parentage analysis has found a parent\u2013offspring relationship between Doradilla (under the synonym Plateado) and CAYETANA BLANCA, a variety that is widespread throughout the south and centre of the Iberian peninsula (Zinelabidine et al. 2012; see Cayetana Blanca for the pedigree diagram). Moreover, Doradilla most likely has a parent\u2013offspring relationship with ALBILLO MAYOR from Ribera del Duero in north-central Spain (Santana et al. 2010). In addition, it should not be confused with CHELVA, BEBA or DONA BRANCA, which are also sometimes called Forastera Blanca though they are not commonly confused in the vineyard.',
    ],
    'where its grown': [
      'Doradilla is permitted in both the M\u00e1laga and Sierras de M\u00e1laga DOs in Spain\u2019s far south but is planted only to an extremely limited extent in the north of that area.',
    ],
  },
  {
    name: 'DORINTO',
    synonyms: [
      'Arinto Branco',
      'Arinto do Douro',
      'Arinto do Interior',
      'Arinto no Douro',
      'Arinto de Tr\u00e1s-os-Montes',
    ],
    description: 'Recently renamed Portuguese white.',
    'origin and parentage': [
      'There are several varieties with names or synonyms referring to Arinto (see MALVASIA FINA and BICAL, for example) but the DNA profile of Arinto no Douro (Lopes et al. 1999; Veloso et al. 2010), recently renamed Dorinto, from Portugal\u2019s D\u00e3o region, is distinct from all of them although it has often been confused with ARINTO DE BUCELAS (Lopes et al. 1999). The new name Dorinto, a contraction of Douro and Arinto, is an attempt to reduce the confusion.',
    ],
    'where its grown': [
      'Dorinto wines are typically citrus-scented and fruity with a long aftertaste. The variety is authorized in the Trasmontano and Duriense IGPs in northern Portugal, where the ability of its large leaves to resist the heat is much appreciated. Most of the 20 ha (49 acres) remaining in 2010 are found in old vineyards in Baixo Corgo, western Douro.',
    ],
  },
  {
    name: 'DORNFELDER',
    synonyms: ['Weinsberg S 341'],
    description:
      'The most successful of the modern red German crosses, making dark, velvety wines.',
    'origin and parentage': [
      'A HELFENSTEINER \u00d7 HEROLDREBE cross bred in 1956 by August Herold at the Weinsberg research centre in Baden-W\u00fcrttemberg, southern Germany. Its extended pedigree is therefore: (PINOT NOIR PR\u00c9COCE \u00d7 SCHIAVA GROSSA) \u00d7 (BLAUER PORTUGIESER \u00d7 BLAUFR\u00c4NKISCH). Named after Immanuel August Ludwig Dornfeld (1796\u20131869), one of the founders of the Weinsberg school of viticulture, it was used to breed ACOLON, CABERNET DORIO, CABERNET DORSA and MONARCH.',
    ],
    'where its grown': [
      'Dornfelder\u2019s popularity increased steadily after its official authorization in Germany in 1980 and then surged ahead in the first five or six years of the twenty-first century, during which time the planted area doubled. The German total is now fairly stable at 8,101 ha (20,018 acres), dominated by plantings of over 3,000 ha (7,400 acres) in both Rheinhessen and the Pfalz. It produces dark, rich, velvety wines with good acidity and occasionally an attractively floral note. However, yields must be controlled to get good fruit concentration if the wine is to be aged in barrel. In a country where red wines are often pale, the variety is prized for its deep colour. Recommended producers include Deutzerhof in the Ahr and Gutzler, Knipser and Lingenfelder in the Pfalz.',
      'Switzerland had 21 ha (52 acres) in 2009, with producers such as Fredi Clerc and Brigitte Bamert in Nuolen (Schwyz), Gottlieb Welti in K\u00fcsnacht (Z\u00fcrich) and Landoldt Weine (Z\u00fcrich) making varietal wines.',
      'The Czech Republic has a little. In 2007 there were 16 ha (40 acres) in England, where it was first planted in the late 1980s and it is sometimes made as a ros\u00e9. Camel Valley, for example, produce a sparkling version. It can also produce lightly spicy reds but on the whole is less successful than REGENT. Some is planted in Santa Rita Hills in California\u2019s Central Coast and also in Pennsylvania. If you look hard enough, you may find a few rows of Dornfelder in Brazil\u2019s Rio Grande do Sul and Japan had around 5 ha (12 acres) in Hokkaido in 2009.',
    ],
  },
  {
    name: 'DORONA DI VENEZIA',
    synonyms: ['Dorona', 'Dorona Veneziana', 'Uva d\u2019Oro'],
    description: 'Recently rescued but not especially ancient Venetian white.',
    'origin and parentage': [
      'Dorona di Venezia is local to the Venetian lagoon, where it has almost exclusively been cultivated, mainly as a table grape. It was thought to be identical to GARGANEGA but DNA analysis has both disproved this and explained the confusion (Di Vecchi Staraz, This et al. 2007): Dorona di Venezia was shown to be a natural Garganega \u00d7 Bermestia Bianca cross, where Bermestia Bianca is a table grape from Emilia-Romagna (see Garganega for the complete pedigree). The name Dorona derives from the Italian d\u2019oro, meaning \u2018gold\u2019, referring to the golden colour of the berries at harvest. Although there is documentary evidence of vines planted in the Republic of Venice at the time of its settlement and a reference to a vinea, or vineyard, on the Venetian island of Mazzorbo in the mid fifteenth century, the first reference to Dorona was in Eden (1903).',
    ],
    'where its grown': [
      'In Italy, just a few vines of Dorona di Venezia remained on the Venetian island of Sant\u2019Erasmo before Gianluca Bisol, of the eponymous Prosecco producer, restored the ancient estate now known as Venissa on the neighbouring island of Mazzorbo and replanted the variety in the warm, saline environment of the small estate vineyard. It was traditionally grown pergola-style both for the table and for wine but the Bisol vines are vertically trained and a single-vineyard Dorona wine is produced. It is hard to speak of a typical style when there is just one producer but Bisol\u2019s example has a strong minerality and notes of dried peach and apricot as well as iodine and a deliberate rusticity, intensified by fermentation on the skins without added sulphites.',
    ],
  },
  {
    name: 'DOUCE NOIRE',
    synonyms: [
      'Bathiolin',
      'Bonarda',
      'Charbonneau',
      'Charbono',
      'Corbeau',
      'Corbeau Noir',
      'Mauvais Noir',
      'Plant de Montm\u00e9lian',
      'Plant de Turin',
      'Plant Noir',
      'Turca',
      'Turin',
    ],
    description:
      'Savoie variety with several aliases causing considerable confusion, most widely planted in Argentina.',
    'origin and parentage': [
      'Until the end of the nineteenth century, Douce Noire was one of the most widespread red varieties of Savoie in eastern France, notably in the region of Montm\u00e9lian and Arbin south east of Chamb\u00e9ry. Outside of Savoie, it was called Corbeau (meaning \u2018crow\u2019) because of the deeply coloured wine it produced. The earliest mention of this variety was found in a letter dated 24 November 1803 (24 Brumaire XII in the Republican calendar) and sent by the mayor of Saint-Pierre-d\u2019Albigny to the prefect of Savoie, in which Douce Noire was cited as the main variety in his commune (Durand 1901b).',
      'Douce Noire was often said to originate from Piemonte in north-west Italy, hence its synonym Plant de Turin or simply Turin. Furthermore, for most of the twentieth century, Douce Noire (meaning \u2018sweet black\u2019 (see DOLCETTO)) was erroneously identified with Dolcetto Nero (meaning \u2018small sweet black\u2019) from Piemonte, until careful ampelographic observations and DNA profiling definitively rejected this hypothesis (Schneider and Mannini 2006; Vouillamoz, Frei and Arnold 2008). Consequently, the birthplace of Douce Noire is most likely to be Savoie, where the variety was historically found and where it has so many synonyms.',
      'Recent DNA profiling has also shown that the variety cultivated in California under the name Charbono is distinct from the true (but no longer commerically cultivated) Charbono from Piemonte, and identical to Douce Noire (Mart\u00ednez et al. 2008), which was probably introduced to California under the synonym Charbonneau once used in the Jura, eastern France.',
      'In Argentina, DNA profiling has established that the variety known there as Bonarda, the second most common red variety after Malbec (COT), is identical to neither the Italian BONARDA PIEMONTESE nor any of the five other distinct varieties that are misleadingly called Bonarda in Italy because it is in fact identical to Douce Noire (Mart\u00ednez et al. 2008). In addition, comparison of the DNA profiles of Turca (Grando 2000; Crespan, Giannetto et al. 2008), cultivated in the Veneto in north-eastern Italy since the early twentieth century, and Douce Noire has unexpectedly shown them to be one and the same variety (Vouillamoz).',
      'Galet (2000) mentions a variety called Douce Noire Grise, which is no longer cultivated but is unrelated to Douce Noire.',
    ],
    'where its grown': [
      'The very limited plantings remaining in France (about 2 ha/5 acres in 2007), where it is officially known as Corbeau, are in Savoie, where producers use the most ancient name of all, Douce Noire. Domaine Ganevat in Rotalier in the Jura have replanted a small area of Corbeau and include it in an early-drinking red blend. Most include it in blends (it was traditionally used to soften wines made from PERSAN) but Jean-Paul Finas has made a varietal Vin de Pays d\u2019Allobrogie.',
      'In California, under the name Charbono, there were 88 acres (36 ha) in 2008 and there are almost no new plantings. It is thought to have been imported by Italian immigrants in the 1800s but it came into the country as BARBERA and was planted and bottled as such by wineries including Inglenook and Parducci until the 1930s. Charbono was at one stage a speciality of the historic Napa Valley winery Inglenook, whose first vintage labelled Charbono was 1941 (Savoie 2003). About half the California Charbono vines are in Napa, particularly in the hotter parts of Calistoga, with the rest in Mendocino, Monterey and Madera. The best vineyards are Frediani, Heitz, Andriana (Summers), Tofanelli, Meyer and Cooke; the biggest producers are Summers and Pacific Star and others include Chameleon, Coturri, Duxoup, Robert Foley, Thomas Michael, Shypoke and Turley.',
      'But these totals are swamped by the 18,759 ha (46,354 acres) planted under the name Bonarda in Argentina, the vast majority in Mendoza and San Juan, where it generally produces good-value fruity quaffing wines, although if allowed to ripen fully, there is more quality potential than is generally realized in the bottle, and alcohol tends to remain within bounds. Argentine producers of varietal Bonarda include Anubis, Chakana, Crist\u00f3bal, Augusto Pulenta, R J Vinedos and Zuccardi.',
    ],
  },
  {
    name: 'DOUX D\u2019HENRY',
    synonyms: ['Doux d\u2019Enry', 'Gros d\u2019Henry'],
    description:
      'Rare, light-bodied red exclusive to the Pinerolese region of Piemonte.',
    'origin and parentage': [
      'This curiously named variety is typical of the Pinerolese region and the Val Chisone in Piemonte, north-west Italy, where it is sometimes confused with CILIEGIOLO (Schneider and Mannini 2006). It is said to have been named in honour of Henri IV of France, who allegedly praised this wine when he met the Duke of Savoy, Charles Emanuel I, in the early seventeenth century. The epithet doux (meaning \u2018sweet\u2019) probably refers to the residual sugar that producers used to leave in Doux d\u2019Henry wines.',
    ],
    'where its grown': [
      'Cultivated exclusively in the Pinerolese area (mainly in San Secondo di Pinerolo and in Prarostino) and in the Val Chisone and the Val Germanasca in Piemonte, north-west Italy, Doux d\u2019Henry is authorized in the Pinerolese Rosso DOC and in the varietal Pinerolese Doux d\u2019Henry DOC. The wines are light and fresh with floral aromas. Producers of the varietal version include Bruno Daniela, Cantina Dora Renato and Il Tralcio. Just 28 ha (69 acres) were recorded in the Italian agricultural census in 2000.',
    ],
  },
  {
    name: 'DRNEKU\u0160A',
    synonyms: [
      'Darneku\u0161a',
      'Darneku\u0161a Mala',
      'Darneku\u0161a Vela',
      'Dernaku\u0161a',
    ],
    description: 'Rare vine from the Croatian island of Hvar.',
    'origin and parentage': [
      'Drneku\u0161a is from the island of Hvar off Croatia\u2019s Dalmatian coast and south of Split.',
    ],
    'where its grown': [
      'Drneku\u0161a is grown almost exclusively on the island of Hvar in Croatia although it is authorized for the whole of Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia). Plan\u010di\u0107 is one of the few producers to make a varietal wine, this one from fruit grown at 580 m (1,900 ft) above the Adriatic.',
    ],
  },
  {
    name: 'DRUPEGGIO',
    synonyms: [
      'Bottaio Bianco',
      'Cacinello',
      'Cacciumo',
      'Canaiolo Bianco',
      'Canajola',
      'Canina',
      'Uva dei Cani',
      'Drupeccio',
      'Lupeccio',
      'Trupeccio',
      'Volpicchio',
    ],
    description:
      'Neutral, central Italian white also known as Canaiolo Bianco.',
    'origin and parentage': [
      'Known as Drupeggio in the Orvieto region of Umbria, this variety has also been known in Toscana as Canaiolo Bianco since at least 1817. However, a recent DNA profiling survey by Storchi et al. (2011) has established that the name Canaiolo Bianco was mistakenly given to at least six distinct varieties in Toscana, not only to Drupeggio but also to VERNACCIA DI SAN GIMIGNANO and to Zuccaccio, a very old local variety that is no longer cultivated. Since the official clone currently available in nurseries \u2013 Canaiolo Bianco ARSIAL-CRA 402 \u2013 is in fact Drupeggio, and since Canaiolo Bianco is a source of confusion, we have chosen to follow Storchi et al. (2011) by using Drupeggio as the prime name for this variety.',
    ],
    'where its grown': [
      'Drupeggio is grown mainly in Orvieto in Umbria, central Italy, where it is generally blended with other local white varieties. It is also planted in other parts of Umbria and in Lazio. Under the name Canaiolo Bianco, it is also grown in Toscana in the provinces of Firenze, Grosseto and Pistoia, where it is usually blended with TREBBIANO TOSCANO and MALVASIA BIANCA LUNGA (Malvasia Toscana), although a maximum of 10% is permitted in the red and ros\u00e9 Barco Reale di Carmignano DOC. Occasionally included in Vin Santo, though the grapes are difficult to raisin. A full 674 ha (1,665 acres) were recorded in the 2000 Italian census under the name Canaiolo Bianco. However, given the possible confusion of Canaiolo Bianco with other varieties such as VERNACCIA DI SAN GIMIGNANO, it is difficult to know exactly which variety contributes to a given DOC.',
    ],
  },
  {
    name: 'DUNAJ',
    synonyms: ['MBOP \u00d7 SV 6/10'],
    description:
      'Minor but potentially high-quality Slovak cross responsible for soft, full-bodied reds.',
    'origin and parentage': [
      'Dunaj is a (Muscat Bouschet \u00d7 BLAUER PORTUGIESER) \u00d7 SANKT LAURENT cross (with Slovakian names: (Mu\u0161k\u00e1t Bouchet \u00d7 Oporto) \u00d7 Sv\u00e4tovavrineck\u00e9) obtained in 1958 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia, in which Muscat Bouschet is a Muscat Rouge de Mad\u00e8re \u00d7 PETIT BOUSCHET cross and Muscat Rouge de Mad\u00e8re is a likely natural progeny of MUSCAT BLANC \u00c0 PETITS GRAINS \u00d7 MAMMOLO from Toscana (Di Vecchi Staraz, This et al. 2007) that is no longer commercially cultivated. It was christened with the Slovakian and Czech name for the Danube and officially registered in 1997.',
    ],
    'where its grown': [
      'Wines are typically deeply coloured and full-bodied with soft tannins and flavours of ripe cherries and chocolate. It is adaptable to a range of sites in Slovakia but it does need a good supply of water. There were 62 ha (153 acres) in Slovakia in 2011. Producers include Joseph K\u00e9pe\u0161, Kmet\u2019o, Masaryk, Chateau Modra and Mrva & Stanko.',
    ],
  },
  {
    name: 'DUNKELFELDER',
    synonyms: ['Fr\u00f6hlich V. 4.4', 'Fr\u00f6hlich V 4', 'Purpur'],
    description:
      'Dark-skinned German cross usefully adding colour and body to blends.',
    'origin and parentage': [
      'Unknown cross made by Gustav Adolf Fr\u00f6hlich (1847\u20131912), a private vine breeder and wine producer in Edenkoben in the Pfalz, Germany. It was transplanted to several grape collections under the name Fr\u00f6hlich V. 4.4 in the early twentieth century, notably at the Alzey research centre. In 1948, several vines were introduced to the vine improvement nursery at Rhodt, south of Neustadt in the Pfalz, by Fritz Uhl, whence the selections 276a and 276b were further passed on to the Neustadt and Geisenheim research centres. Ampelographical study was carried out in Geisenheim, where Helmut Becker (1927\u201390) named this variety Dunkelfelder in the 1970s, with reference to its unknown origin and deep colour (Felder means \u2018fields\u2019 and dunkel means \u2018dark\u2019).',
    ],
    'where its grown': [
      'Germany has 352 ha (870 acres), including 182 ha (450 acres) in the Pfalz, 67 ha (124 acres) in Rheinhessen and 50 ha in Baden, but the total is currently pretty stable, perhaps slightly in decline. Wines are very deeply coloured, full-bodied but rather neutral in flavour, making them useful for adding colour to blends. Minges in the Pfalz produce a good varietal example and Aloisiushof in the same region produce a slightly sweet version.',
      'Dunkelfelder was also planted on 24 ha (59 acres) in Switzerland in 2009, and to a limited extent in England and British Columbia in Canada.',
    ],
  },
  {
    name: 'DURANIJA',
    synonyms: ['Brajdenica', 'Duranija Bijela', 'Duronija'],
    description: 'Almost extinct Croatian variety from the Istrian peninsula.',
    'where its grown': [
      'Duranija is a very rare Istrian variety from Croatia, on the verge of extinction, and is also grown to a limited extent near Koper in Slovenia under the name Brajdenica. It is sometimes blended with MALVAZIJA ISTARSKA in central Istra, mainly around the town of Pazin. Producers include Antun Ivanini\u0107 and the beekeeper Boris Hrvatin.',
    ],
  },
  {
    name: 'DURAS',
    synonyms: ['Durade', 'Duras Rouge', 'Duraz\u00e9'],
    description:
      'Structured and peppery variety playing a minor role in the Gaillac appellation.',
    'origin and parentage': [
      'Duras is an old variety from the Midi-Pyr\u00e9n\u00e9es, where it has long been cultivated in the Ari\u00e8ge and the Tarn d\u00e9partements. Its first mention in the region \u2013 as Duras Noir \u2013 was in 1842 in Gaillac (R\u00e9zeau 1997). According to Tallavignes (1902), Duras had already been referred to in a vineyard lease held by Ma\u00eetre de Pachino, notary in Lavaur (Tarn), dated 8 November 1484, but there is no reliable historical evidence for this document.',
      'The name Duras derives from the French dur, meaning \u2018hard\u2019, referring to the hard wood of the vine.',
      'According to DNA parentage analysis, Duras is a parent of TRESSOT (Bowers et al. 2000; see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'France had 923 ha (2,281 acres) in 2009, almost exclusively in the Tarn d\u00e9partement. It is one of the obligatory main varieties in the Gaillac appellation, along with FER and SYRAH, and a secondary variety in C\u00f4tes de Millau and Vins d\u2019Estaing, both red and ros\u00e9. Wines are deeply coloured, well structured with highish alcohol and a peppery taste. Since the Gaillac appellation does not allow a varietal Duras, Patrice Lescarret at Domaine de Causse-Marines has to label his version, Rasdu, as a vin de France.',
    ],
  },
  {
    name: 'DURELLA',
    synonyms: ['Cagnina', 'Caina', 'Durella Gentile', 'Rabiosa'],
    description: 'Makes crisp, light-bodied Veneto whites.',
    'origin and parentage': [
      'Durella comes from the Monti Lessini area in the provinces of Verona and Vicenza in the Veneto region of north-east Italy. Its earliest mention is found in Acerbi (1825), as a grape cultivated in the province of Vicenza and in the Oltrep\u00f2 Pavese. Its name may refer to the thickness of the berries\u2019 skin. Durella has often been confused with NOSIOLA from Trentino but ampelographic (Giavedoni and Gily 2005) and DNA studies (Vouillamoz) have shown that they are distinct varieties. Recent DNA parentage analysis has suggested that Durella is a possible parent of BIANCHETTA TREVIGIANA from Treviso in the Veneto (Cipriani et al. 2010).',
    ],
    'where its grown': [
      'Durella appears as a varietal wine in the Monti Lessini (or Lessini) DOC in the hills between Verona and Vicenza in north-east Italy. Marcato in Ronc\u00e0 produce a range of sparkling wines from 85% Durella blended with PINOT NOIR and/or CHARDONNAY as well as an excellent sweet passito version which is 100% Durella. Other producers include Casa Cecchin (still and dry), Fongaro (sparkling), Colli Vicentini, Cantina di Montecchia di Crosara, Cantina di Monteforte d\u2019Alpone, Cantina di Gambellara and Cantina de Vallegora. The variety is also found in Lombardia and Toscana. Wines are light-bodied with high acidity and therefore well suited for sparkling-wine production. There were 723 ha (1,787 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'DUREZA',
    synonyms: [
      'Dur\u00e9',
      'Duret',
      'Durezza',
      'Petit Duret',
      'Ser\u00e8ne',
      'Serine',
    ],
    description:
      'Virtually extinct Ard\u00e8che variety most famous as a parent of Syrah.',
    'origin and parentage': [
      'Dureza is an almost extinct variety from the northern Ard\u00e8che in France\u2019s greater Vall\u00e9e du Rh\u00f4ne, whence it spread east and north to the northern Dr\u00f4me and to the Is\u00e8re. Although PELOURSIN is sometimes called Duresa, Dureza or Durezi, the two varieties are distinct, even if both belong to the Pelorsien ampelographic group (see p XXVII; Bisson 2009). Dureza\u2019s greatest claim to fame, revealed by DNA parentage analysis, is its offspring SYRAH (Bowers et al. 2000; see the Syrah entry for the pedigree diagram), a relationship which explains why they share the synonyms Ser\u00e8ne and Serine, and why Dureza and Syrah used to be cultivated together in the northern Ard\u00e8che (Rougier 1905).',
    ],
    'where its grown': [
      'Dureza is virtually extinct in France but the first varietal version is eagerly awaited from the Saint-Joseph producer Pascal Jamet. It qualifies as Collines Rhodaniennes.',
    ],
  },
  {
    name: 'DURIF',
    synonyms: [
      'Dure',
      'Duret',
      'Gros Noir',
      'Petite Sirah',
      'Petite Syrah',
      'Pinot de l\u2019Hermitage',
      'Pinot de Romans',
      'Plant Durif',
      'Serine des Mauves',
      'Sirane Fourchue',
    ],
    description:
      'Originally French but particularly famous, as Petite Sirah, in California.',
    'origin and parentage': [
      'This variety was discovered in the 1860s in the canton of Tullins in the Is\u00e8re, eastern France, in the experimental vineyard of Fran\u00e7ois Durif (often incorrectly spelt Duriff), a French botanist and grape breeder. It was first mentioned in 1868 by the ampelographer Victor Pulliat under the name Plant du Rif as a variety propagated in Tullins by said Durif (Di Rovasenda 1877; Goethe 1878; R\u00e9zeau 1997). Durif was praised for its resistance to downy mildew, a fungal disease that had been a serious threat to French vineyards since 1878. In 1884 Charles McIver of Linda Vista Winery near San Jose in California inadvertently imported it under the name Petite Sirah, a name he is said to have used for marketing purposes, but the name could also be the result of a mislabelling and a misspelling because Petite Syrah is the name commonly used in the Is\u00e8re and the Ard\u00e8che for the true SYRAH, a variety that had been introduced to California in 1876.',
      'Through DNA profiling, Meredith et al. (1999) of California established that the vast majority of the vines called Petite Sirah in California vineyards were identical to Durif, and although a few of them from old vineyards were identical to PELOURSIN, Petite Sirah and Durif can be considered synonymous. While Durif apparently knew that Peloursin was the mother of his variety, he did not know the identity of the father. The same California researchers have also established by DNA parentage analysis that Durif is most likely a Peloursin \u00d7 SYRAH natural cross that occurred by chance in Durif\u2019s nursery. This explains why Durif has often been confused with both its parents, and also why Durif belongs to the Pelorsien ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Durif has virtually disappeared from its homeland in France but thrives in California. In 2008 there were 6,584 acres (2,664 ha) misleadingly known as Petite Sirah, occasionally Petite Syrah, a considerable increase on the 1999 total of 2,282 acres (923 ha).',
      'The variety\u2019s fans in California are active proselytes, as their PS I Love You website reveals. The largest areas are in the San Joaquin Valley, San Luis Obispo, Napa and Sonoma. Wines are deeply coloured and full-bodied and have good ageing potential, making them useful blending components as well as varietal wines. The most vocal and active supporter of the variety is Foppiano in Healdsburg, which produces both a straight Petite Sirah and a reserve bottling. Old-vine Petite Sirah is the distinguishing mark of the giant Concannon of Livermore Valley. Others who take this variety seriously include Guenoc, Ridge, Lava Cap and J C Cellars.',
      'Petite Sirah has travelled from California to a wide range of other American states, particularly Washington.',
      'It also spread south to Mexico, where L A Cetto is a keen proponent, Brazil (12 ha/30 acres in Rio Grande do Sul in 2007) and Chile (31 ha/77 acres in 2008). Perhaps partly because of its reputation in the US, and its suitability for hot, dry climates, it is also quite popular in Israel (721 tons harvested in 2007).',
      'The plantings in Australia (433 ha/1,070 acres) and South Africa (14 ha/35 acres in 2008) appear on stage under their original French name. In Australia, the majority of plantings are in Riverina and in Rutherglen, Victoria, where the climate is ideally suited to this late-ripening variety. Here varietals can be intense, structured, inky wines with masses of dark fruit and well suited to oak ageing. Although the variety is officially known as Durif, Rutherglen Estates produce a Durif and a more ambitious \u2018Petit Sirah\u2019 (sic) as well as blending it with Shiraz (SIRAH). De Bortoli make several varietal wines from Riverina fruit.',
    ],
  },
  {
    name: 'DUTCHESS',
    synonyms: ['Duchess'],
    description:
      'Disappearing, low-yielding, sensitive North American hybrid also found in Brazil.',
    'origin and parentage': [
      'Named after its county of origin, Dutchess is a hybrid created when a seedling of NIAGARA (under the synonym White Concord) was pollinated by mixed pollen from DELAWARE and Walter vines by a commercial nurseryman, Andrew Jackson Caywood, in 1868 in Poughkeepsie, NY (Hedrick 1919). Since Walter is itself a DELAWARE \u00d7 Diana hybrid (Diana being a CATAWBA seedling), Dutchess\u2019 pedigree therefore comprises Vitis labrusca, Vitis aestivalis and Vitis vinifera.',
    ],
    'where its grown': [
      'Planted to a very limited extent in New York State (only 27 acres/11 ha remained in 1996), where it has largely been replaced by hybrids better suited to the north-eastern United States, and, much further from home, in Brazil.',
      'Quai du Vin in Elgin County, Ontario, Canada, like others, have pulled up their Dutchess vines to unsatisfactory yields and quality but they describe the wines as having a range of citrus flavours, much like VIDAL or SEYVAL BLANC, and with less foxy character than ELVIRA or CONCORD, plus a surprising ability to age in bottle for four or five years.',
    ],
  },
  {
    name: 'DZVELSHAVI OBCHURI',
    synonyms: ['Zelscavi'],
    description: 'Mediocre Georgian variety producing intensely coloured reds.',
    'origin and parentage': [
      'Dzvelshavi Obchuri is an old variety from the Imereti region in central western Georgia. Its name combines dzveli, meaning \u2018old\u2019, and shavi, meaning \u2018black\u2019 (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Dzvelshavi is grown mainly in the Imereti and Racha regions of Georgia, where in the past it was occasionally blended with SAPERAVI for everyday wines. On its own, it produces wines that are very deeply coloured and fresh and have red-fruit flavours but the average quality is low, making it generally unpopular.',
    ],
  },
  {
    name: 'EARLY MUSCAT',
    synonyms: ['California K 4-19'],
    description:
      'California cross bred for the table but also producing aromatic wines in Oregon.',
    'origin and parentage': [
      'A cross of MUSCAT OF HAMBURG \u00d7 Sz\u0151l\u0151skertek Kir\u00e1lyn\u0151je (a Hungarian table grape also known as K\u00f6nigin der Weing\u00e4rten) obtained by Harold P Olmo in 1943 at the University of California at Davis, US, and released in 1958 (Walker 2000). This parentage has been confirmed by DNA profiling (Myles et al. 2011).',
    ],
    'where its grown': [
      'Early Muscat was bred as a table grape in California but has had some commercial success as a wine grape in Oregon\u2019s generally cooler climate. In the Willamette Valley, for example, Silvan Ridge produce a medium-sweet varietal with flavours of peach, orange and grape; Sokol Blosser\u2019s off-dry bottling is floral and citrus-flavoured; Bridgeview make a lightly sweet, semi-sparkling version; and Ribbon Ridge in Yamhill County produce a sweet, fortified style.',
    ],
  },
  {
    name: 'EDELWEISS',
    synonyms: ['Elmer Swenson', 'ES 40'],
    description:
      'Minor, usefully disease-resistant and winter-hardy, not too foxy American hybrid.',
    'origin and parentage': [
      'A Minnesota 78 \u00d7 Ontario hybrid obtained by Elmer Swenson (1913\u20132004), a grape breeder from Osceola, Wisconsin, US (see BRIANNA for the pedigree of Minnesota 78 and see CAYUGA WHITE for the pedigree of Ontario). This complex Vitis labrusca, Vitis vinifera and Vitis riparia hybrid was selected in 1955 and released by Elmer Swenson and the University of Minnesota in 1978 together with SWENSON RED (Swenson et al. 1980). It was used to breedESPIRIT and SWENSON WHITE.',
    ],
    'where its grown': [
      'Small areas of Edelweiss are found in the Midwest, notably 53 acres (21 ha) in Iowa in 2006 and around 10 acres (4 ha) in Nebraska in 2007, and also in Pennsylvania. It may be used both for wine and for the table. Producers of varietal wines include Canon River in Minnesota; Cuthills and Mac\u2019s Creek in Nebraska; and Snus Hill, Tassel Ridge and Two Saints in Iowa. Wines are pleasant if the fruit is not picked too late and the wine is made semi-sweet (Swenson et al. 1980).',
    ],
  },
  {
    name: 'EDERENA',
    synonyms: ['\u00c9d\u00e9r\u00e9na'],
    description: 'Bordeaux cross being trialled in California.',
    'origin and parentage': [
      'A MERLOT \u00d7 ABOURIOU cross obtained in 1952 by Pierre Marcel Durqu\u00e9ty at INRA (Institut National de la Recherche Agronomique) in Bordeaux, south-west France. Ederena means \u2018the most beautiful\u2019, from the Basque word ederrena (eder means \u2018beautiful\u2019; R\u00e9zeau 1997).',
    ],
    'where its grown': [
      'Ederena is not in the official French catalogue of varieties and does not appear to be planted to any significant extent in France (less than 1 ha/2.5 acres in 2008). In California it is available from Sunridge Nurseries in Bakersfield, who have sold it to Gallo for experimental plantings, and it is one of the many varieties being trialled by the University of California at Davis in the San Joaquin Valley. Closer to its home, La Toveyre, an adventurous Swiss producer in Chardonne in Vaud, crafts an obscure blend of Malbec (COT), CALADOC, MARSELAN, ARINARNOA, EGIODOLA, Ederena and CARMINOIR called, less obscurely, Num\u00e9ro 2. Ederena produces wines that are light and perfumed, sometimes slightly herbaceous.',
    ],
  },
  {
    name: 'EGIODOLA',
    synonyms: ['\u00c9giodola'],
    description:
      'Recent productive Bordeaux cross starting to show some potential at home and abroad. Quite tannic.',
    'origin and parentage': [
      'Egiodola was presented as a FER \u00d7 ABOURIOU cross obtained in 1954 by Pierre Marcel Durqu\u00e9ty at INRA (Institut National de la Recherche Agronomique) in Bordeaux, south-west France, but recent DNA parentage analysis at INRA in Montpellier corrected it to an Abouriou \u00d7 NEGRAMOLL (under the name Tinta da Madeira) cross. The name Egiodola is a contraction of two Basque words, egiazko odola, meaning \u2018true blood\u2019 (R\u00e9zeau 1997).',
    ],
    'where its grown': [
      'Wines tend to be deeply coloured, generous, tannic and low in acidity, although Domaine de la Chevrue in the Muscadet region in France describe their wine as light with raspberry aromas. The variety is reported to be relatively high in catechins, phenolic compounds that are said to help prevent coronary heart disease (Teissedre and Landrault 2000).',
      'Egiodola has been recommended in just about every wine-growing d\u00e9partement in France since 1983. Total plantings in 2009 were 271 ha (670 acres), down from 342 ha (845 acres) in 2006, and a few producers are making varietal wines, eg Domaine Millet in Gascogne, who produce a ros\u00e9 version. In the south west, it is more often blended with FER and/or CABERNET FRANC, eg in Coteaux de Chalosse or C\u00f4tes de Gascogne. In Ch\u00e2teauneuf-du-Pape, Domaine Roger Perrin blend it with MERLOT, Grenache (GARNACHA), Cinsault (CINSAUT), CABERNET SAUVIGNON, etc.',
      'In Jumilla, south-eastern Spain, Casa de la Ermita have planted some Egiodola vines in their experimental vineyard.',
      'In Switzerland, La Toveyre in Chardonne in Vaud craft an obscure blend of Malbec (COT), CALADOC, MARSELAN, ARINARNOA, Egiodola, EDERENA and CARMINOIR, called simply Num\u00e9ro 2.',
      'Brazil had 56 ha (138 acres) in the state of Rio Grande do Sul in 2007, where producers such as Cave de Pedra and Pizzato in the Vale dos Vinhedos wine region, near the city of Bento Gon\u00e7alves, make varietal wines.',
    ],
  },
  {
    name: 'EHRENFELSER',
    synonyms: ['Geisenheim 9-93'],
    description:
      'Frost-resistant German cross bred as an alternative to Riesling.',
    'origin and parentage': [
      'Ehrenfelser is said to be a RIESLING \u00d7 SILVANER cross bred at Geisenheim in 1929 by Heinrich Birk. However, DNA profiling has shown that Silvaner is not one of the parents (Grando and Frisinghelli 1998) so one side of the family remains unknown. Ehrenfelser was used to breed PRINZIPAL.',
    ],
    'where its grown': [
      'Ehrenfelser, named after the ruined Schloss Ehrenfels near R\u00fcdesheim in the Rheingau, was bred with the aim of creating a RIESLING-like grape that would ripen in a wider range of sites but it was only partially successful: it does ripen better and more productively but the acidity is much lower than in Riesling and the wines therefore develop quickly in bottle even though they are attractively fruity at the outset. It also lacks Riesling\u2019s aromatic complexity.',
      'Total German plantings stand at 91 ha (225 acres), scattered across the German wine regions, with the biggest areas in Rheinhessen and the Pfalz, but the overall area is in decline, probably because the more recently developed KERNER is a better alternative to RIESLING since it has higher acidity and is more versatile with regard to site. Varietal wines are made by Hermann Jourdan & S\u00f6hne, Lohm\u00fchle and Rothweiler, all in Hessische Bergstrasse.',
      'A few plantings exist in the US, mainly in California and in New York State.',
      'Ehrenfelser was first imported into British Columbia, western Canada, in 1968, along with RIESLING, and together they constituted the first major planting of vinifera in the southern Okanagan Valley. Today there are 74 acres (30 ha); Cedar Creek\u2019s version is off dry, clean and refreshing.',
      'One small producer in Australia, Palmara in Tasmania, has had Ehrenfelser planted since 1984 but usually blends it with S\u00c9MILLON and SAUVIGNON BLANC to improve body and acidity.',
    ],
  },
  {
    name: 'EKIGA\u00cfNA',
    synonyms: ['\u00c9kiga\u00efna'],
    description: 'Recent Bordeaux cross yet to make inroads in the vineyard.',
    'origin and parentage': [
      'A TANNAT \u00d7 CABERNET SAUVIGNON cross obtained at INRA (Institut National de la Recherche Agronomique) in Bordeaux, south-west France, in 1955, most probably by Pierre Marcel Durqu\u00e9ty. The name derives from two Basque words, ek(h)i (\u2018sun\u2019) and ga\u00efn (\u2018top\u2019), meaning \u2018quintessence of the sun\u2019 (R\u00e9zeau 1997).',
    ],
    'where its grown': [
      'Although it is officially recommended for several d\u00e9partements in the south and south west of France, it certainly doesn\u2019t seem to have caught on yet; in 2008 there were fewer than 3 ha (7 acres) planted. The wines are generally deeply coloured \u2013 not surprising given the pedigree \u2013 warm and relatively low in acidity. Ekiga\u00efna could be a useful blending partner with the highly structured TANNAT, eg in vins de pays such as Terres Landais.',
      'There are minuscule plantings in Argentina, thanks to experiments by Zuccardi in Mendoza.',
    ],
  },
  {
    name: 'EKIM KARA',
    synonyms: ['Echim Kara'],
    description:
      'Late-ripening Ukrainian variety making fortified red dessert wines.',
    'origin and parentage': [
      'Ekim Kara, literally \u2018doctor\u2019s black\u2019, is a variety from Solnechnaya Dolina in eastern Krym (Crimea), in the far south of Ukraine. It was allegedly brought here at the end of the nineteenth century by an unknown Russian doctor.',
      'Ekim Kara is sometimes confused with KEFESSIYA, occasionally known as Doktorsky Chernyi, which also means \u2018doctor\u2019s black\u2019.',
    ],
    'where its grown': [
      'Ekim Kara is grown in Ukraine (27 ha/67 acres in 2009) and is often blended with CEVAT KARA in rich, sweet fortified wines such as Massandra\u2019s and Solnechnaya Dolina Winery\u2019s popular Black Doctor labels.',
    ],
  },
  {
    name: 'ELBLING',
    synonyms: [
      'Aelbinen',
      'Albich',
      'Burger',
      'Elbling Weiss',
      'Els\u00e4sser',
      'Grobriesling',
      'Grossriesling',
      'Haussard',
      'Raisin Blanc des Allemands',
      'Rheinelbe',
      'Weisser Silvaner',
      'Ys\u00e8le .',
    ],
    description:
      'Ancient, once ubiquitous, now unfashionable German variety used mainly as a base wine for fizz.',
    'origin and parentage': [
      'As suggested by its numerous synonyms, Elbling is one of the most ancient German grape varieties. Its name is thought to derive either from the Latin alba (\u2018white\u2019), which is also the etymology of the River Elbe, or from the Latin helvus (\u2018yellow\u2019). Just like RIESLING, Elbling was most likely born in the Rheintal (Rhine Valley), as suggested by the synonym Rheinelbe used in France.',
      'Nevertheless, the earliest mention of Elbling is thought to be under another name, near Z\u00fcrich in Switzerland at the end of the thirteenth century: a Hofrodel (a type of register) of the Engelberg Abbey records the request of a visiting local landowner for meat and good Elseser wine, because he didn\u2019t like the local Z\u00fcrich wine (Landwein; Bluntschli 1838; Grimm 1840; Aeberhard 2005).',
      'Elseser, meaning Alsatian, is an attested old synonym for Elbling in Switzerland that can be found in many later sources such as Daniel Rhagor\u2019s (1639) Wein-G\u00e4rten, in which the variety is described in Oberhofen am Thunersee (in the German-speaking canton of Bern) under the names Elber, Elbelen or Elsisser. In addition, DNA profiling recently confirmed that the variety still known as Els\u00e4sser near the Bielersee is identical to Elbling (Vouillamoz, Frei and Arnold et al. 2008), suggesting that it most likely came to Switzerland from Germany via Alsace, where it was formerly cultivated.',
      'In Germany, the earliest mention of Elbling appeared under the old name Aelbinen on 8 August 1483. The Bebenhausen monastery allotted vineyards in Kriegsberg near Stuttgart to local citizens, stipulating that only Frennsch, Traminer and Aelbinen should be grown (Kr\u00e4mer 2006). Numerous additional mentions of this variety occurred later in Germany, eg in Hieronymus Bock\u2019s (1539) famous Kreutterbuch, where it appears under the name Albich in Laudau in the Pfalz.',
      'Regner et al. (1998) established by DNA profiling that Elbling has a parent\u2013offspring relationship with GOUAIS BLANC, one of the most ancient and prolific wine grapes of Western Europe. Since Gouais Blanc also has parent\u2013offspring relationships with at least eighty additional grape varieties, including CHARDONNAY, GAMAY NOIR, FURMINT, R\u00c4USCHLING and RIESLING, they all turn out to be half-siblings, grandparents or grandchildren of Elbling; see PINOT for the complete pedigree. The close genetic link with Riesling probably explains why Elbling is sometimes called Grobriesling or Grossriesling. Elbling has also been shown to be in some way genetically close to Pinot (Imazio et al. 2002).',
      'The variety named Blauer Elbling or Elbling Blau, once cultivated in Germany and in Switzerland, is morphologically and genetically distinct from the white Elbling, while the variety called Roter Elbling, Elbling Rot or Elbling Rose is a simple colour mutation of Elbling (Galet 2000; Vouillamoz, Frei and Arnold 2008).',
    ],
    'where its grown': [
      'Elbling used to be planted in north-east France but has now effectively disappeared and it is not authorized in any appellation contr\u00f4l\u00e9e wines, although it is still authorized in the Moselle and Bas-Rhin and Haut-Rhin d\u00e9partements.',
      'At one time Elbling was effectively the only variety planted in Luxembourg but it is in decline here too: there are currently 116 ha (287 acres) with virtually no new plantings since the end of the 1980s. However, at Ch\u00e2teau Pauqu\u00e9, Abi Duhr is experimenting with a still varietal version.',
      'Elbling apparently constituted three quarters of all vines in Germany in the first half of the nineteenth century (Maul 2006) but today there are only 578 ha (1,428 acres) of this ancient variety, planted almost entirely in the upper reaches of the Mosel-Saar-Ruwer region, where it is difficult to ripen RIESLING. Even there it is declining, although Steinmetz produce a good varietal example. The wines are light and fresh with high acidity and low alcohol and are therefore often used to make sparkling wine.',
      'In Switzerland, Elbling was the major variety near the Biel, Spiez and Thun lakes until the end of the nineteenth century, and it used to be widespread across German-speaking Switzerland, where plantings have now shrunk to just 65 ares (1.6 acres). Producers of varietal wines include Schmidheiny in the canton of Sankt Gallen and Reinhard and Bettina Bachmann at Weinbau zum St\u00e4cker\u00f6sseler in the canton of Aargau, who also make a 100% Roter Elbling in good vintages.',
      'The pink-skinned mutation Roter Elbling is rarely found in Germany, though Frieden-Berg in the Mosel produce a varietal wine. Switzerland has the grand total of 10 ares, or one tenth of a hectare (1,200 square yards).',
    ],
  },
  {
    name: 'EMERALD RIESLING',
    synonyms: ['California 1139E29', 'Emerald Rizling'],
    description:
      'California cross that has travelled widely but not gloriously and turns out to be no Riesling.',
    'origin and parentage': [
      'Emerald Riesling was originally said to be a Muscadelle (CA) \u00d7 RIESLING cross obtained by Harold P Olmo in 1935 at the University of California at Davis and released in 1948 (Walker 2000), but DNA parentage analysis by Dangl (2006) has shown that GARNACHA and not RIESLING is the second parent. Muscadelle (CA) is an unidentified variety labelled Muscadelle du Bordelais in the University of California at Davis grape collection and is distinct from the French MUSCADELLE.',
    ],
    'where its grown': [
      'Since its initial success in the 1960s and 1970s, when it was valued for adding freshness and aroma to white blends in California\u2019s hot San Joaquin Valley, it has declined in acreage although a few better-quality varietal wines are produced in slightly cooler locations such as Monterey. California\u2019s 235 acres (95 ha) in 2008 were mainly in Kern and Fresno counties.',
      'It is also found in South Africa (139 ha/343 acres in 2008) and in Israel (150 ha/371 acres in 2009), where 2,041 tons were harvested in 2007, down from a peak of 3,508 tons in 2004, mainly for an off-dry style. Producers include Barkan, Binyamina, Carmel and Tishbi. A few growers in Australia have experimented with this variety but it doesn\u2019t seem to have caught on.',
    ],
  },
  {
    name: 'ENANTIO',
    synonyms: [
      'Foja Tonda',
      'Lambrusco a Foglia Frastagliata',
      'Lambrusco Nostrano',
    ],
    description:
      'Fairly recent official name for a Trentino variety once called Lambrusco a Foglia Frastagliata.',
    'origin and parentage': [
      'Formerly called Lambrusco a Foglia Frastagliata (meaning \u2018toothed leaf\u2019), although it is distinct from other Lambruscos of Italy, this variety was officially renamed Enantio in the mid 1980s by Mario Fregoni, in reference to a grape name used by Pliny the Elder. The renaming was felt necessary in order to distinguish it from Lambrusco a Foglia Tonda (meaning \u2018round leaf\u2019), another autochthonous variety from Vallagarina now called CASETTA. Indeed, those two varieties differ considerably in morphology (Cal\u00f2 et al. 2006), and comparison of their DNA profiles rules out any parent\u2013offspring relationship between Casetta and Enantio (Vouillamoz). Genetic studies suggested a close relationship between Enantio and other Trentino varieties such as MARZEMINO, TEROLDEGO, LAGREIN and GROPPELLO GENTILE (Grando and Frisinghelli 1998; Grando 2000). In addition, a more recent DNA parentage analysis has shown with great confidence that Enantio is either a parent or a progeny of NEGRARA TRENTINA (Grando et al. 2006), which anchors Enantio\u2019s roots in the Trentino region. On the other hand, Enantio does not show any relationship with traditional varieties of the province of Verona, where it was probably introduced relatively recently (Vantini et al. 2003), most likely from Trentino.',
    ],
    'where its grown': [
      'Traditionally cultivated in lower Vallagarina (between the Veneto and Trentino in northern Italy), Enantio is today the typical grape of Terra dei Forti between Monte Baldo and the Lessinia mountains. Letrari and Masso Roveri make creditable varietals in Terra dei Forti.',
      'It is registered as a monovarietal wine in Valdadige-Terra dei Forti Enantio DOC and in blends in Valdadige-Terra dei Forti Rosso o Casteller DOC. Wines have a medium and balanced structure and must be drunk young.',
      'The Italian agricultural census of 2000 recorded 1,184 ha (2,926 acres) of Enantio, then called Lambrusco a Foglia Frastagliata.',
    ],
  },
  {
    name: 'ENCRUZADO',
    synonyms: ['Salgueirinho'],
    description:
      'High-quality, well-structured Portuguese variety with the potential to produce ageworthy wines.',
    'origin and parentage': [
      'This rare variety probably comes from the D\u00e3o region in central northern Portugal since it is almost exclusively grown here.',
    ],
    'where its grown': [
      'Encruzado has great potential \u2013 possibly more than any other white variety in D\u00e3o \u2013 but is a challenge in the winery since it is very prone to oxidation and the loss of its delicate aromas, which can be a complex mix of capsicum, roses and violets, flinty minerality and lemon (Rolando Faustino, personal communication). As these full-bodied wines age, the wines develop aromas of hazelnut and pine resin. Encruzado also marries well with oak in barrel fermentation and lees ageing, giving a well-structured wine a little like white burgundy. The best examples age well in bottle \u2013 up to thirty years in some cases \u2013 although most of today\u2019s wines are intended for early consumption. There were just 295 ha (729 acres) in Portugal in 2010 and the variety is authorized in the D\u00e3o DOC and in various regional wines. Growing recognition for the variety\u2019s quality potential seems likely to lead to increased plantings further afield. Recommended producers include Casa de Mouraz, Julia Kemper, Filipa Pato, Quinta dos Carvalhais, Quinta das Marias and Quinta dos Roques.',
    ],
  },
  {
    name: 'ENFARIN\u00c9 NOIR',
    synonyms: ['Enfarin\u00e9 du Jura', 'Gaillard', 'Gouais Noir', 'Grison'],
    description:
      'Ancient and virtually extinct variety that survives in the Jura.',
    'origin and parentage': [
      'The first mention of Enfarin\u00e9 appeared in an order of the parliament of Besan\u00e7on dated 3 February 1731 (Bousson de Mairet 1856): \u2018Ordre du Parlement d\u2019arracher toutes les vignes plant\u00e9es depuis l\u2019an 1702, et d\u2019extirper dans les vieilles vignes tous les plants d\u00e9nomm\u00e9s: grapenoux, foirard noir et blanc, maldoux, gam\u00e9 noir et blanc, valet noir, enfarin\u00e9, mesy, plant qui tache, bregin, pinot ou plant des haies, et barclan blanc. Il est aussit\u00f4t proc\u00e9d\u00e9 \u00e0 l\u2019ex\u00e9cution de l\u2019arr\u00eat; pr\u00e8s du tiers du vignoble est arrach\u00e9 et rendu \u00e0 la culture des c\u00e9r\u00e9ales; l\u2019op\u00e9ration n\u2019est achev\u00e9e qu\u2019au mois de septembre de l\u2019ann\u00e9e suivante\u2019 (\u2018Order of Parliament to pull out all the vineyards planted since 1702 and to eradicate from old vineyards the old vines of all the plants known as: .\u00a0.\u00a0. enfarin\u00e9 .\u00a0.\u00a0. The execution of the decree was immediately carried out; almost a third of the vineyard is pulled out and dedicated to the cultivation of cereals; the process is not completed until September of the next year\u2019). The name Enfarin\u00e9 Noir is based on the French word farine (\u2018flour\u2019) in reference to the bloom covering the berries. It has also long been known as Gouais Noir, although it is not a colour mutation of GOUAIS BLANC. Similarly, the pink-berried mutation Enfarin\u00e9 Gris is also called Gouais Gris. According to Guicherd, Enfarin\u00e9 could originate in the Aube and is similar to GUEUCHE NOIR (Galet 1990).',
    ],
    'where its grown': [
      'This ancient variety is almost extinct \u2013 less than 1 ha (2.5 acres) in France in 2008 \u2013 but there are a few vines in the Jura, mostly interplanted with POULSARD. However, the variety, which is usefully high in natural acidity, is not forgotten: Domaine Jean Bourdy planted some in their conservation vineyard in 2006; Domaine Ganevat in Rotalier have deliberately replanted a small area and include about 20% in an early-drinking red blend (and occasionally in a sparkling wine); and, at Domaine des Cavarodes, \u00c9tienne Thiebaud uses some in his red Vin de Pays de Franche-Comt\u00e9 blend.',
    ],
  },
  {
    name: 'EONA',
    synonyms: ['\u00c9ona'],
    description: 'South Dakotan hybrid just about surviving in Qu\u00e9bec.',
    'origin and parentage': [
      'A Lady Washington \u00d7 Beta hybrid obtained in the early twentieth century by the Danish-born Niels Ebbesen Hansen (1866\u20131950) at the South Dakota Agricultural Experiment Station in Brookings. Lady Washington is a hybrid of CONCORD and Allen\u2019s Hybrid, which is an ISABELLA \u00d7 CHASSELAS hybrid, and Beta is a hybrid of Carver and CONCORD, where Carver is a selected Vitis riparia vine. Eona, a complex Vitis riparia, Vitis labrusca and Vitis vinifera hybrid introduced commercially in 1925 (Plocher 1993), has a native American name, like many of Hansen\u2019s creations.',
    ],
    'where its grown': [
      'Eona, like Hensen\u2019s other new varieties, never really prospered, probably as a result of being released at the peak of Prohibition (Plocher 1993). It is planted to a limited extent in Qu\u00e9bec, Canada,\nwhere Isle de Bacchus use it in several blends and Domaine F\u00e9libre make a dry varietal wine. Sainte P\u00e9tronille have just a few vines and use the fruit in a blend. Wines are said to have flavours of flowers and tropical fruit. It is also used for the table and for juice and is planted by home winemakers.',
    ],
  },
  {
    name: 'ERBALUCE',
    synonyms: [
      'Albaluce',
      'Bian Roust\u00ec',
      'Bianchera',
      'Greco Bianco di Novara',
      'Uva Rust\u00eca',
    ],
    description:
      'Minor white Piemontese variety, occasionally making distinctive sweet wines.',
    'origin and parentage': [
      'The earliest mention of Erbaluce appears in Croce (1606) under the name Elbalus, possibly deriving from alba luce (meaning \u2018dawn light\u2019) because the berries gleam when they are ripe. Erbaluce is thought to originate in the Canavese region in the province of Torino, in the foothills of the Alps. Genetic analysis suggests a close relationship with CASCAROLO BIANCO from Piemonte (Vouillamoz). Although the synonyms Greco and Greco Bianco are used in Ghemme in the province of Novara, probably in reference to its ability to make sweet wines (ie similar to historic Greek wine styles), Erbaluce has no relationship with the Campanian variety GRECO.',
    ],
    'where its grown': [
      'Erbaluce is cultivated in the provinces of Torino (in the Canavese area and in Serra d\u2019Ivrea and Lago di Viverone), Biella and Novara (where it is called Greco) but it is a speciality of Caluso, north east of the city of Torino, Italy. The variety is also authorized in the following DOCs: Canavese, Colline Novaresi, Coste della Sesia and Erbaluce di Caluso (or just Caluso). In the last it is used to make dry, sparkling and sweet passito wines. In 2000 there were 342 ha (845 acres) in Italy, according to the agricultural census.',
      'Although the dry wines used to be rather light-bodied and acidic, there are now some impressive, vibrant examples that challenge the local Arneis and Gavi from n\u00e9gociant houses such as Orsolani and Bava and from Paolo de Marchi\u2019s Propriet\u00e0 Sperino in Lessona. The passito version, which must be aged in barrels for at least four years from the harvest, is sweet, golden and relatively rare. Some producers allow botrytis to develop towards the end of the drying period, which lasts until March following the harvest, to add further complexity. Cieck\u2019s Alladium combines aromas of saffron, almonds, acacia honey and fresh figs, its sweetness counterbalanced by light bitterness and acidity.',
    ],
  },
  {
    name: 'ERBAMAT',
    synonyms: ['Albamatto', 'Erbamatto', 'Verdealbara'],
    description: 'Virtually extinct local white from Brescia, northern Italy.',
    'origin and parentage': [
      'Erbamat was already mentioned in the sixteenth century as one of the best white varieties in Valt\u00e8nesi, a region in the Lago di Garda area in northern Italy. The name Erbamat probably derives from the green colour of the berries (erba means \u2018herb\u2019). DNA profiling unexpectedly showed that Erbamat is identical to Verdealbara (Vouillamoz), an ancient and almost extinct variety of Avio in the province of Trento. The name Verdealbara also comes from the green colour of the berries at dawn (verde is Italian for \u2018green\u2019 and alba means \u2018dawn\u2019).',
    ],
    'where its grown': [
      'In Lombardia, northern Italy, Erbamat was almost extinct but a few adventurous producers in the Lago di Garda region have persisted with it. Comincioli\u2019s vino da tavola Perl\u00ec, for example, is a blend of 40% Erbamat with 60% Trebbiano Valtenesi (VERDICCHIO BIANCO). In addition, the Franciacorta consorzio (wine growers\u2019 association) has recently introduced experimental plantings. Varietal wines are lightly aromatic.',
      'As Verdealbara in Trentino, Erbamat was included in the national register of varieties, together with LAGARINO BIANCO, in 2007. Historically it was cultivated in Val Lagarina, north of Verona, but also in Vallarsa and in the Valle di Terragnolo. Today, Verdealbara is still found in old vineyards near Avio, closer to the Lago di Garda, although there don\u2019t appear to be any commercial examples of a varietal Verdealbara wine.',
    ],
  },
  {
    name: 'ERVI',
    synonyms: ['Barbera \u00d7 Bonarda 108', 'Incrocio Fregoni 108'],
    description:
      'Minor and relatively recent dark-skinned cross from Emilia-Romagna.',
    'origin and parentage': [
      'Ervi was obtained in 1970 by Mario Fregoni at the Universit\u00e0 Cattolica di Piacenza by crossing BARBERA and CROATINA, the latter under its misleading synonym Bonarda, as used in Oltrep\u00f2 Pavese and Roero. The aim was to avoid the weaknesses of the parents: poor ripening, high acidity and poor bud fertility.',
    ],
    'where its grown': [
      'Ervi tends to be blended with BARBERA to add colour and increase alcoholic strength. The 2000 agricultural census in Italy recorded just 6 ha (15 acres).',
    ],
  },
  {
    name: 'ESCURSAC',
    synonyms: [
      'Cor\u00e7ac',
      'Cursach',
      'Escor\u00e7ac',
      'Escursag',
      'Excursach',
    ],
    description: 'Recently rescued Mallorcan making promising reds.',
    'origin and parentage': [
      'Not much is known about Escursac, a variety indigenous to Mallorca in the Islas Baleares, Spain, where it was already mentioned in 1871 (Fav\u00e0 i Agud 2001) and then almost eradicated when phylloxera hit the island in 1891. It was recently rescued from extinction by Bodegas Galm\u00e9s i Ribot, who isolated cuttings from Col\u00f2nia de Sant Pere d\u2019Art\u00e0 to replant a vineyard in 2006.',
    ],
    'where its grown': [
      'Thanks to Galm\u00e9s i Ribot, there is now about half a hectare (1.2 acres) of this variety in Santa Margalida in north-east Mallorca and they produce a varietal wine, although by late 2011 Escursac had not yet been included in the official register of varieties in Spain. It seems well suited to the island and produces an elegant, aromatic wine that has red-fruit flavours, moderate alcohol, soft tannins and noticeable persistence.',
    ],
  },
  {
    name: 'ESPADEIRO',
    synonyms: ['Espadeiro Tinto', 'Padeiro Tinto'],
    description: 'Minho vine producing ros\u00e9.',
    'origin and parentage': [
      'Espadeiro is from the Minho region in north-western Portugal. It should not be confused with TRINCADEIRA, known as Espadeiro in Set\u00fabal, nor with PADEIRO, sometimes called Espadeiro in Minho.',
      'Espadeiro is also regarded as an old indigenous variety in Galicia but its DNA profile in Mart\u00edn et al. (2003; 2006), confirmed by the sample at the Misi\u00f3n Biol\u00f3gica de Galicia (Maria-Carmen Mart\u00ednez, personal communication), is different from the Portuguese Espadeiro in Almadanim et al. (2007), and the Espadeiro in Galicia could well be a mistake because it is most probably identical to CAMARAOU NOIR from south-west France (Vouillamoz).',
    ],
    'where its grown': [
      'Espadeiro is grown in Portugal\u2019s Vinho Verde region for the production of crisp ros\u00e9s, such as the one made by Quinta de Gomariz, or to contribute to a red blend. There were 295 ha (729 acres) in Portugal in 2010. It was traditionally grown up trees in an attempt to moderate its vigour.',
    ],
  },
  {
    name: 'ESPIRIT',
    synonyms: ['ES 422', 'Esprit'],
    description:
      'Very minor but cold-hardy American hybrid producing mild, fruity wines.',
    'origin and parentage': [
      'A VILLARD BLANC \u00d7 EDELWEISS hybrid bred by Elmer Swenson in Osceola, Wisconsin, US. This extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris, Vitis cinerea and Vitis berlandieri hybrid was named and released in 1984, and patented to Swenson Smith Vines in 1986 (Smiley 2008). It was used to breed DELISLE.',
    ],
    'where its grown': [
      'Espirit is grown to a very limited extent in the American Midwest. Wines are said to be free of the foxy character typical of Vitis riparia (Smiley 2008) and to have a mild fruity flavour. Two Saints Winery in Iowa produce a varietal wine. It has also been recommended for Colorado.',
    ],
  },
  {
    name: 'ETRAIRE DE L\u2019ADU\u00cf',
    synonyms: [
      'Beccu de l\u2019Adu\u00ef',
      'Betu',
      'Etraire de la Dot',
      'Etraire de la D\u00fb',
      'Due',
      'Etraire de la Du\u00ef',
      'Etraire de la Duy',
      'Grosse Etraire',
    ],
    description:
      'Is\u00e8re speciality still cultivated to a limited extent in Savoie.',
    'origin and parentage': [
      'According to Rougier (1902b), this variety was found, in the form of an enormous and productive vine, at the beginning of the nineteenth century by the inhabitants of Saint-Ismier in the Vall\u00e9e de Gr\u00e9sivaudan north east of Grenoble while they were fetching water from a spring near the Mas de l\u2019Adu\u00ef. Its olive-shaped berries looked very much like those of PERSAN, and since Persan was called Etraire in Is\u00e8re, probably based on the local Dauphinois dialect word eitre\u00efri, meaning \u2018grape producing a lot of must\u2019 (R\u00e9zeau 1997), this vine was named Etraire de l\u2019Adu\u00ef. Initially propagated in Saint-Ismier-en-Gr\u00e9sivaudan by a certain Monsieur Fourrier, Etraire de l\u2019Adu\u00ef was subsequently propagated throughout Is\u00e8re, where \u2013 under several different spellings \u2013 it became one of the most important varieties. It also spread to the Ain, Savoie and Ard\u00e8che d\u00e9partements. Its first written mention appeared in 1842 under the name Etraire de la Due (R\u00e9zeau 1997).',
      'Rougier (1902b) suggested that Etraire de l\u2019Adu\u00ef could be a natural seedling of PERSAN, which has now been confirmed by DNA parentage analysis that strongly suggests a parent\u2013offspring relationship between Etraire de l\u2019Adu\u00ef and Persan (Vouillamoz). Indeed, both varieties belong to the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Etraire de l\u2019Adu\u00ef used to be widespread in Is\u00e8re, between the Rh\u00f4ne and Savoie, eastern France, in particular after the phylloxera crisis in 1880 because it was erroneously thought to be resistant to this pest. Although it is still a recommended variety in Is\u00e8re, and has been one of the main varieties permitted in red and ros\u00e9 versions of Vin de Pays des Coteaux du Gr\u00e9sivaudan, in 2008 there were just 6 ha (15 acres) compared with 406 ha (1,003 ha) in 1958. Only one clone has been approved but a dozen more were planted in Savoie in 1999. Domaine Grisard, also in Savoie, planted Etraire de l\u2019Adu\u00ef in their nursery in 2004 along with other local varieties such as CHATUS, PERSAN and VERDESSE. Wines may be deeply coloured, full-flavoured and tannic, sometimes even astringent. Rare varietal wines are produced by the Cave Coop\u00e9rative de Bernin and by Domaine Michel Magne, who make a ros\u00e9 version.',
    ],
  },
  {
    name: 'EVMOLPIA',
    synonyms: ['Evmolpiya', 'Thracian Mavrud'],
    description:
      'Recent Bulgarian cross valued for its productivity and early ripening as well as for its easy-drinking reds.',
    'origin and parentage': [
      'Evmolpia is a MAVRUD \u00d7 MERLOT cross recently made by Dimitar Babrikov and C Kukunov at the Vasil Kolarov Institute of the University of Plovdiv, Bulgaria, and officially registered in 1991.',
    ],
    'where its grown': [
      'There have been many new plantings of this increasingly (economically) important Bulgarian variety in the last few years, with a total of 92 ha (227 acres) in Bulgaria in 2009. The variety is also patented in Australia and the US. Wines have fresh acidity, medium to full body, well-balanced tannins and moderate alcohol and may be drunk young but are not particularly aromatic.',
    ],
  },
  {
    name: 'EYHOLZER ROTE',
    synonyms: ['Eyholzer Roter', 'Gross Roth', 'Grossroter'],
    description:
      'Ancient and extremely rare variety vinified by just one southern Swiss producer.',
    'origin and parentage': [
      'Eyholzer Rote is a rare variety from the area of Visp in the Haut-Valais, Switzerland, named after the village of Eyholz, between Visp and Brig. Although DNA analysis failed to identify its parents, Eyholzer Rote could have some genetic relationship with VIEN DE NUS from the neighbouring Valle d\u2019Aosta and with FREISA from Piemonte, suggesting it may have been introduced long ago from northern Italy, which would be consistent with the use of pergola training in the Haut-Valais (Vouillamoz and Moriondo 2011). One ancient vine, 150\u2013250 years old, has been found near Visp in the village of Stalden, and an even older one in the middle of the town of Sion, some 50 km from Visp (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Just 0.25 ha (0.6 acres) of Eyholzer Rote existed around Visp in the Haut-Valais, Switzerland, in 2009. Chanton, the only commercial producer of this variety, make a dry version, with high acidity and strong aromas of strawberries, as well as an extremely rare Eiswein that takes 6\u20137 months to ferment in barrique, with notes of cr\u00e8me br\u00fbl\u00e9e, dried cherries and very high acidity.',
    ],
  },
  {
    name: 'EZERF\u00dcRT\u0170',
    synonyms: ['Kecskem\u00e9t 5', 'Mikl\u00f3stelepi 5'],
    description: 'Neutral Hungarian white cross generally hidden in blends.',
    'origin and parentage': [
      'Ezerf\u00fcrt\u0171 is a H\u00c1RSLEVEL\u0170 \u00d7 SAVAGNIN ROSE (under the name Piros Tramini) cross obtained in 1950 by Andr\u00e1s Kurucz and Istv\u00e1n Kwaysser at the Kecskem\u00e9t research centre in Hungary.',
    ],
    'where its grown': [
      'Ezerf\u00fcrt\u0171 was particularly popular in large-scale vineyards in Hungary in the 1970s and 1980s and can still be found\non the north shore of Lake Balaton and on the Alf\u00f6ld (Great Plain). It produces neutral, medium-bodied wines with a slight vegetal aroma and is generally blended, with Olaszrizling (GRA\u0160EVINA), for example, and rarely bottled on its own. Total plantings in 2008 in Hungary were 478 ha (1,181 acres).',
    ],
  },
  {
    name: 'EZERJ\u00d3',
    synonyms: [
      'Budai Feh\u00e9r',
      'Feh\u00e9r Bakator',
      'Kolmreifler',
      'Korponai',
      'Tausendgute',
      'Tausent G\u00fcte',
      'Trummertraube',
      'Z\u00e1toki',
    ],
    description:
      'Once widely planted Hungarian variety making high-acid whites from dry to sweet.',
    'origin and parentage': [
      'Ezerj\u00f3, meaning \u2018a thousand boons\u2019, as attested by its German synonym Tausendgute, is an old Hungarian variety from the region of M\u00f3r between Budapest (hence the synonym Budai Feh\u00e9r, ie \u2018white of Buda\u2019) and Lake Balaton, where wine has been made since the Middle Ages. It used to be one of the most common varieties in Hungary, well known throughout the Carpathian Basin. Ezerj\u00f3 has often been used to breed new varieties, including GENEROSA (HUNGARY), ZENG\u0150, ZENIT and ZEUSZ.',
    ],
    'where its grown': [
      'Ezerj\u00f3 was one of the most common varieties in Hungary in the first half of the twentieth century (Roh\u00e1ly et al. 2003) and, although the area is in decline, there were still 1,655 ha (4,090 acres) in 2008. Today it is grown mainly in Kuns\u00e1g on the Alf\u00f6ld (Great Plain) in the central south of Hungary but also in M\u00f3r and Neszm\u00e9ly in the north west. Varietal wines are high in acidity, potentially also in alcohol, but are otherwise typically neutral. The most refreshing and lively dry wines come from the deeper subsoils of the small wine region of M\u00f3r, which are richer in clay, marl and limestone. In the best locations, berries left on the vine tend to shrivel and develop botrytis, allowing producers such as Boz\u00f3ky to make high-quality sweet wines known as M\u00f3r Asz\u00fa. Other recommended producers of varietal wines include Frittmann in Kuns\u00e1g and Maurus and Mikl\u00f3scsabi in M\u00f3r.',
    ],
  },
  {
    name: 'FABERREBE',
    synonyms: ['Alzey 10375', 'Faber'],
    description: 'High-yielding, lightly aromatic German cross losing ground.',
    'origin and parentage': [
      'Faberrebe is said to be either a PINOT BLANC \u00d7 M\u00dcLLER-THURGAU or a SILVANER \u00d7 PINOT BLANC cross bred by Georg Scheu in 1929, an uncertainty which could easily be resolved by DNA analysis. Its original name was Faber, the Latin for German \u2018Schmied\u2019, or \u2018Smith\u2019 in English, in honour of Karl Schmitt in Landau in the Pfalz, who carried out the field trials. It was later officially renamed Faberrebe (Rebe is German for \u2018vine\u2019).',
    ],
    'where its grown': [
      'Germany has 587 ha (1,450 acres), principally in Rheinhessen and to a much lesser extent in the Pfalz and the Nahe, but the area is fast declining, down from 1,657 ha (4,095 acres) in 1998 as part of German vintners\u2019 general disaffection with twentieth-century crosses. Wines have a greenish tinge and tend to be fruity with light Muscat-like aromas. Acidity levels are a little higher than in M\u00dcLLER-THURGAU (a common blending partner) and high ripeness levels are possible. Hilgert and Metzler in Rheinhessen make varietal wines at various sweetness levels.',
      'There are a few hectares in England.',
    ],
  },
  {
    name: 'FALANGHINA FLEGREA',
    synonyms: ['Falanghina Pigna Piccola', 'Falernina', 'Uva Falerna'],
    description: 'Campania\u2019s signature white wine grape.',
    'origin and parentage': [
      'First mentioned in 1666 in a poem (Canto VII) in Neapolitan dialect by Giulio Cesare Cortese, Falanghina is one of the oldest and most widespread varieties of Campania. The name Falanghina could derive from the Latin falangae, the stakes supporting the vines. Until recently, the Falanghina of Campi Flegrei and that of Benevento in Campania were thought to be one and the same, although ampelographers had noticed some morphological differences. Thanks to DNA profiling, Costantini et al. (2005) discovered that Falanghina Flegrea, the most widespread white cultivar in the province of Napoli, and FALANGHINA BENEVENTANA, more limited and recently rediscovered in the province of Benevento, are two distinct varieties. Genetic comparison also suggested that Falanghina Flegrea is closely related to SUPPEZZA, while Falanghina Beneventana is closely related to BIANCOLELLA.',
    ],
    'where its grown': [
      'The leafily scented Falanghina from Campi Flegrei, west of Napoli in Campania, southern Italy, usefully retains its acidity. After the devastating effect of phylloxera at the beginning of the twentieth century, cultivation of Falanghina Flegrea decreased dramatically until Francesco Avallone, local historian and founder of Villa Matilde, recuperated old vines in the Massico region in the 1970s. His Falerno del Massico is 100% Falanghina Flegrea and has flavours of stone fruit, considerable weight in the mouth, and a fine apricot-kernel aftertaste and fresh acidity. The Caracci version, which includes a very small percentage fermented in oak, is a bigger wine, a little less aromatic, but still elegant.',
      'Falanghina Flegrea is recommended throughout Campania and Molise and in the province of Foggia in Puglia. It is authorized as a varietal wine in DOCs such as Falerno del Massico, Galluccio and Campi Flegrei and may contribute to the blend in such DOCs as Campi Flegrei, Capri, Penisola Sorrentina, Lacryma Christi del Vesuvio and Costa d\u2019Amalfi.',
      'The official Italian census of 2000 lists just \u2018Falanghina\u2019 so it is impossible to tell how much of the 1,721 ha (4,253 acres) is Falanghina Flegrea.',
    ],
  },
  {
    name: 'FER',
    synonyms: [
      'Braucol',
      'Caillaba',
      'Camaralet Noir',
      'Camirouch',
      'Estronc',
      'Fer Servadou',
      'Gragnelut',
      'H\u00e8re',
      'Mansois',
      'Samen\u00e7ois',
      'Mourac',
      'Pinenc',
      'Servadou',
    ],
    description: 'The untamed, tannic variety of Marcillac, south-west France.',
    'origin and parentage': [
      'The earliest references to this variety appeared in 1783\u20134 under no fewer than five distinct synonyms (R\u00e9zeau 1997):',
      '\u2013\u00a0\u00a0\u00a0as Fer in Montauban, north of Toulouse in the Tarn-et-Garonne: \u2018Fer noir .\u00a0.\u00a0. il donne une bonne qualit\u00e9 au vin rouge pourvu qu\u2019il ne domine pas\u2019 (\u2018Fer noir .\u00a0.\u00a0. it gives good quality to red wine as long as it does not dominate\u2019), as well as further north and west in Bergerac in the Dordogne and in Caumont (now Caumont-sur-Garonne) in the Lot-et-Garonne',
      '\u2013\u00a0\u00a0\u00a0as Servadou in Agen in the Lot-et-Garonne, between Bordeaux and Toulouse: \u2018Servadou gros, Servadou petit\u2019',
      '\u2013\u00a0\u00a0\u00a0as Samen\u00e7ois in Rodez in Aveyron, between Toulouse and Saint-\u00c9tienne: \u2018Le Samen\u00e7ois est aussi bon \u00e0 manger, le vin qui en provient est de seconde qualit\u00e9\u2019 (\u2018Samen\u00e7ois is also good to eat, the wine made from it is of secondary quality\u2019)',
      '\u2013\u00a0\u00a0\u00a0as Pinenc and H\u00e8re in Condom in the Gers, south west of Agen, although with rather strange spellings: \u2018Piek. On le nomme haire dans bien des cantons\u2019 (\u2018Piek. It is called haire in many a canton\u2019).',
      'The name Fer and its synonym H\u00e8re both derive from the Latin ferus, meaning \u2018wild, savage\u2019, which is consistent with the common belief that Fer was domesticated from local wild grapevines (Lavignac 2001). This etymology is more plausible than the unconvincing derivation from the French fer (\u2018iron\u2019), supposedly referring to the hardness of its wood and stem (Galet 1990). In the local dialect, servadou means \u2018well suited to conservation\u2019, which most likely refers to the grapes\u2019 keeping well during winter (R\u00e9zeau 1997).',
      'Confusingly, Fer is described in Viala and Vermorel (1901\u201310) under the name Mourac, while B\u00c9QUIGNOL NOIR is described under the name Fer.',
      'DNA parentage analysis has established that Fer and HONDARRIBI BELTZA, a variety from the Pa\u00eds Vasco, ie Spain\u2019s Basque Country, spontaneously crossed to give birth to Gros Cabernet, which in turn naturally crossed with CABERNET FRANC to produce CARMEN\u00c8RE (Boursiquot et al. 2009; see CABERNET SAUVIGNON\u2019s pedigree). Fer and Hondarribi Beltza are therefore grandparents of Carmen\u00e8re, which confirms Fer\u2019s membership of the Carmenet ampelographic group (see p XXVII; Bisson 2009). Consequently, although Fer is usually said to come from the Gironde, the parentage of Gros Cabernet corroborates Lavignac\u2019s (2001) hypothesis that both its parents, Fer and Hondarribi Beltza, originate in the Spanish Basque Country.',
      'Curiously enough, the DNA profile of five distinct plants called Gragnelut in Friuli-Venezia Giulia in northern Italy (Crespan et al. 2011) turned out to be identical to Fer (Vouillamoz).',
      'Fer was used to breed EGIODOLA.',
    ],
    'where its grown': [
      'After a dip in the 1970s and 1980s, French plantings have risen thanks to better clonal selection, particularly in the last fifteen years or so. In 2009 there were 1,610 ha (3,978 acres), mainly in the Tarn and the Gers in south-west France, although it is authorized or recommended across much of southern France. It is the most widely planted variety in the Aveyron d\u00e9partement in southern central France, home to the Marcillac appellation, near the town of Rodez, the only one in which Fer \u2013 or Mansois as it is known locally \u2013 is the dominant or sole variety (regulations permit up to 10% CABERNET SAUVIGNON or MERLOT or PRUNELARD but most growers stick to 100% Fer). In the nearby appellations of Entraygues et le Fel and Estaing, it plays a more minor role. Beyond the Aveyron, the variety is most likely to surface in blended appellation wines such as Gaillac (with DURAS and SYRAH), Madiran, Fronton, Tursan, Saint-Mont and several others from C\u00f4te du Marmandais in the Lot-et-Garonne down to B\u00e9arn in the Pyr\u00e9n\u00e9es-Atlantiques. In the Languedoc it may play third fiddle to GAMAY NOIR and Syrah in the C\u00f4tes de Millau.',
      'Wines are generally well coloured, relatively tannic and sometimes rather rustic, a little on the wild side, with some similarity to CABERNET FRANC, though if the berries are fully ripe, they give flavours of cassis and red fruits. Research by the Institut National de la Recherche Agronomique\u2019s Institut des Produits de la Vigne in Montpellier, southern France, has shown that Fer is rich in catechins and procyanidols, said to help inhibit heart disease (Strang 2009).',
      'Domaine Laurens in Marcillac make varietal red and ros\u00e9 wines as well as a fortified ratafia (grape must fortified with grape spirit). Gaillac\u2019s Domaine Genouillac have bottled a varietal Fer Servadou as a Vin de Pays du Comt\u00e9 Tolosan because the local appellation regulations do not permit 100% Fer. Other good producers in Marcillac include Domaine du Cros, the Cave Coop\u00e9rative des Vignerons du Vallon, Le Vieux Porche, Domaine des Costes Rouges and Domaine du Mioula.',
      'The vine is rarely found outside France but Hillsborough Vineyards in Virginia, US, apparently blends Fer with TANNAT and PETIT VERDOT.',
    ],
  },
  {
    name: 'FERN\u00c3O PIRES',
    synonyms: [
      'Fernao Pirao',
      'Fern\u00e3o Pires de Beco',
      'Gaeiro',
      'Gaieiro',
      'Maria Gomes',
      'Molinha',
      'Torront\u00e9s',
    ],
    description:
      'Most common light-skinned grape in Portugal. Adaptable, high-yielding, making aromatic whites.',
    'origin and parentage': [
      'Fern\u00e3o Pires is an old Portuguese variety that was mentioned in writing before the end of the eighteenth century (Rolando Faustino, personal communication). Until recently, received opinion was that Fern\u00e3o Pires originated in the Tejo region but the greater morphological diversity found in Bairrada, in the west of Beiras, northern Portugal, suggests the vine has been planted here even longer. This theory is supported by historical records showing significant migration from Bairrada to the Tejo region in the eighteenth century (Rolando Faustino, personal communication). Fern\u00e3o Pires used to be a common surname in Portugal but it is not known after whom the variety was named.',
      'DNA profiling has established that Fern\u00e3o Pires is identical to Maria Gomes, both cultivated in Bairrada (Lopes et al. 1999). Fern\u00e3o Pires was used to breed GENEROSA (PORTUGAL), RIO GRANDE and SEARA NOVA.',
      'A pink-berried mutation called Fern\u00e3o Pires Rosado has also been observed.',
      'Fern\u00e3o Pires should not be confused with TREBBIANO TOSCANO, with which it shares the synonym Fern\u00e3o Pires de Beco, nor with Fern\u00e3o Pires de Colares, which is a distinct but extinct variety, nor with BICAL, which is called Fern\u00e3o Pires Galego in the An\u00e7\u00e3-Cantanhede region.',
    ],
    'where its grown': [
      'Fern\u00e3o Pires, more commonly known as Maria Gomes in the north of the country, is the most widely planted white grape variety in Portugal, with a total of 16,800 ha (41,514 acres) in 2010. It is particularly important in Bairrada in the west and in the Tejo but also plays a significant role throughout central and southern Spain, eg in Lisboa, in the Pen\u00ednsula de Set\u00fabal and in the Alentejo, as well as in the Douro. It is authorized in many different DOCs, from the Douro in the north to Palmela the south of Lisboa.',
      'Its popularity is due in large part to its high yields and easy ripening but also to its distinctive aromatic character \u2013 from oranges, orange and linden blossom, mimosa and laurel to spice and honey \u2013 whereas its detractors point to low acidity, susceptibility to oxidation, lack of ageing potential and over-intense, sometimes heavy, aromas. Much of the produce of the fertile plains used to be distilled but the arrival of temperature-controlled, stainless-steel fermentation tanks has allowed producers to make inexpensive, simple, fruity dry wines (Mayson 2003). Recommended producers include Companhia das Lez\u00edrias in the Tejo and Quinta das B\u00e1geiras (M\u00e1rio S\u00e9rgio Alves Nuno) in Bairrada.',
      'Fern\u00e3o Pires is one of the few Portuguese varieties to have escaped Portugal\u2019s borders: there were 165 ha (408 acres) in South Africa in 2009 and limited plantings in California and Australia.',
    ],
  },
  {
    name: 'FERTILIA',
    synonyms: ['Incrocio Cosmo', 'Merlot \u00d7 Raboso Veronese 108'],
    description:
      'Very minor red blending component in Treviso, northern Italy.',
    'origin and parentage': [
      'Obtained in 1976 at the Conegliano research centre in the Veneto in northern Italy by Italo Cosmo, who crossed MERLOT with RABOSO VERONESE.',
    ],
    'where its grown': [
      'Fertilia is authorized in the province of Treviso in the Veneto region of northern Italy, where it is cultivated to a limited extent in Conegliano and Pieve di Soglio. The 2000 Italian census recorded just 15 ha (37 acres). It is generally blended with Bordeaux varieties.',
    ],
  },
  {
    name: 'FETEASC\u0102 ALB\u0102',
    synonyms: [
      'Dievcie Hrozno',
      'Fetiasca Belii',
      'Feti\u0219oar\u0103',
      'Fetyaska Alba',
      'M\u00e4dchentraube',
      'P\u0103s\u0103reasc\u0103 Alb\u0103',
      'Poam\u0103 Fetei Alb\u0103',
      'V\u0103ratic',
    ],
    description:
      'Second most planted Feteasc\u0103 and is even more important in Romania than in its Moldovan birthplace.',
    'origin and parentage': [
      'Feteasc\u0103 Alb\u0103, meaning \u2018white young girl\u2019, is an old variety that probably originates in the historic region of Moldavia (including today\u2019s Republic of Moldova and the Romanian region of Moldova), where it has traditionally been cultivated, then spreading west to Transilvania and Hungary. Feteasc\u0103 Alb\u0103 is genetically close to other varieties from Moldavia such as GRAS\u0102 DE COTNARI (Bodea et al. 2009).',
      'According to Dejeu (2004), Feteasc\u0103 Alb\u0103 was \u2018obtained from\u2019 (not clear if this means a clone or a descendant) FETEASC\u0102 NEAGR\u0102 in historic Moldavia between the third and thirteenth centuries but, although recent studies have suggested that they are morphologically and genetically close (Bodea et al. 2009), comparison of the DNA profiles of Feteasc\u0103 Alb\u0103 and Feteasc\u0103 Neagr\u0103 in Ghe\u0163ea et al. (2010) tends to disprove any parent\u2013offspring relationship (Vouillamoz).',
      'The Hungarian variety LE\u00c1NYKA has often been considered to be identical to Feteasc\u0103 Alb\u0103 (Galet 2000; Roh\u00e1ly et al. 2003) but comparison of their DNA profiles (Galb\u00e1cs et al. 2009; Ghe\u0163ea et al. 2010) disproves this hypothesis (Vouillamoz).',
    ],
    'where its grown': [
      'The Republic of Moldova\u2019s 4,334 ha (10,710 acres) of Feteasc\u0103 Alb\u0103 are in the centre and south of the country, producing wines with medium acidity, floral and citrus flavours that are often used to make sparkling wine. Recommended producers include Chateau Vartely.',
      'Feteasc\u0103 Alb\u0103 is even more important in neighbouring Romania, where there were 10,529 ha (26,018 acres) in 2008, more than 10% of the total vineyard area and second only to FETEASC\u0102 REGAL\u0102. It is spread throughout most of the wine regions but the greatest concentrations are in Transilvania in the centre of the country and in Romanian Moldova in the east. Wines, both still and sparkling, are generally dry or medium dry but some excellent sweet, still versions, alone or blended with GRAS\u0102 DE COTNARI and other local varieties, are produced in Cotnari in the far north east. Styles of wine vary considerably but dry wines are typically more full-bodied than Feteasc\u0103 Regal\u0103 and combine citrus and light peach or apricot flavours. Vines planted in the south often produce wines lacking in acidity. Producers of varietal wines include Budureasca, Crama Basilescu, Crama Garbiu, Davino, Dominiile S\u0103h\u0103teni, Senator and SERVE.',
      'The variety is said to be planted in Hungary but this may be due to the commonly held but incorrect belief that it and LE\u00c1NYKA are one and the same.',
      'Ukraine\u2019s official vineyard statistics recorded approximately 1,600 ha (3,954 acres) in 2009 but they do not distinguish between the various Feteasc\u0103s.',
    ],
  },
  {
    name: 'FETEASC\u0102 NEAGR\u0102',
    synonyms: [
      'Coada R\u00e2ndunicii',
      'Fetyaska Chernaya',
      'P\u0103s\u0103reasc\u0103 Neagr\u0103',
      'Poam\u0103 Fetei Neagr\u0103',
      'Schwarze M\u00e4dchentraube',
    ],
    description:
      'The red-wine Feteasc\u0103, a resurgent Moldovan producing good-quality wine mainly in Romania.',
    'origin and parentage': [
      'Feteasc\u0103 Neagr\u0103, literally \u2018black young girl\u2019, is an old variety that probably originates in the historical region of Moldavia (including today\u2019s Republic of Moldova and the Romanian region of Moldova), where it has been traditionally cultivated, then spreading west to Transilvania and Hungary. Contrary to Roy-Chevrier\u2019s (1903a) hypothesis, Feteasc\u0103 Neagr\u0103 is not a colour mutation of FETEASC\u0102 ALB\u0102, as evidenced by DNA profiling (Ghe\u0163ea et al. 2010). Feteasc\u0103 Neagr\u0103 shows significant biodiversity, with at least four distinct types (Dejeu 2004), suggesting it is relatively old.',
    ],
    'where its grown': [
      'Although Feteasc\u0103 Neagr\u0103 is indigenous to what is now the Republic of Moldova, it was not planted during the Soviet era and more or less disappeared. However, producers such as Cricova, Equinox, Et Cetera, Purcari and Chateau Vartely began replanting the variety in the late 2000s.',
      'In Romania, Feteasc\u0103 Neagr\u0103 produces some of the country\u2019s best reds from the 1,088 ha (2,689 acres) planted mostly in Romanian Moldova in the east and in Muntenia in the south. They are typically dry and full-bodied with intense, complex aromas of spice, both red and black fruit, especially ripe plums, and tannins that become velvety as they age. The variety has some similarity to BLAUFR\u00c4NKISCH and can easily be over-oaked. Some semi-sweet and sweet wines are also produced, particularly for the domestic market. Recommended producers include Prince \u0218tirbey, SERVE and Vinterra.',
      'Ukraine\u2019s official vineyard statistics recorded approximately 1,600 ha (3,954 acres) in 2009 but they do not distinguish between the various Feteasc\u0103s.',
    ],
  },
  {
    name: 'FETEASC\u0102 REGAL\u0102',
    synonyms: [
      'D\u0103n\u0103\u015fan\u0103',
      'Danesana',
      'Dunesd\u00f6rfer K\u00f6nigsast',
      'Galben\u0103 de Ardeal',
      'K\u00f6nigliche M\u00e4dchentraube',
      'Peseck\u00e1 Le\u00e1nka .',
    ],
    description:
      'The most planted Feteasc\u0103, responsible for fresh, aromatic whites in Romania.',
    'origin and parentage': [
      'Feteasc\u0103 Regal\u0103, literally \u2018royal young girl\u2019, was first observed in the 1920s in the village of Dane\u0219 near Sighi\u0219oara, Transilvania, Romania, hence its synonym D\u0103n\u0103\u0219an\u0103. It was initially cultivated by a nurseryman called Ga\u0219pari from the town of Media\u0219, who distributed it under the German name Dunesd\u00f6rfer K\u00f6nigsast (Dejeu 2004), literally \u2018king\u2019s branch from Dunes village\u2019. The wine obtained from this variety was then presented by Ga\u0219pari at the National Wine and Fruit Exhibition in Bucharest in 1928 under the name Feteasc\u0103 Regal\u0103, which was then adopted (Galet 2000). Two morphological types have been distinguished: one with long clusters and yellowish, thin-skinned berries, the other with winged clusters and yellow-green, thick-skinned berries (Dejeu 2004).',
      'It has long been suggested that Feteasc\u0103 Regal\u0103 is a natural cross between FETEASC\u0102 ALB\u0102 and GRAS\u0102 DE COTNARI (aka K\u00f6v\u00e9rsz\u0151l\u0151) obtained in the 1930s (Galet 2000; \u0162\u00e2rdea and Rotaru 2003) but, even if recent studies have suggested that Feteasc\u0103 Regal\u0103 and Feteasc\u0103 Alb\u0103 are morphologically and genetically close (Bodea et al. 2009), comparison of their DNA profiles (Ghe\u0163ea et al. 2010; Galb\u00e1cs et al. 2009) tends to contradict any strict parent\u2013offspring relationship between Feteasc\u0103 Regal\u0103 and either of its alleged parents (Vouillamoz).',
    ],
    'where its grown': [
      'Feteasc\u0103 Regal\u0103, the most widely planted variety in Romania (16,363 ha/40,434 acres in 2008), tends to produce aromatic wines that are dry and fresh, occasionally floral, sometimes with more exotic-fruit flavours \u2013 both still and sparkling but also used for distillation into brandy. Thanks to the tannins in the skins, it takes better to oak than does FETEASC\u0102 ALB\u0102. Recommended producers include Recas and Prince \u0218tirbey.',
      'In the Republic of Moldova it has been common to make no distinction between the less common Feteasc\u0103 Regal\u0103 and the more widely planted FETEASC\u0102 ALB\u0102. The former is not even listed in the official statistics and is almost always blended with Feteasc\u0103 Alb\u0103, the final wine labelled simply Feteasc\u0103.',
      'There has been some confusion in Slovakia because Feteasc\u0103 Regal\u0103 (391 ha/966 acres in 2009) and Peseck\u00e1 Le\u00e1nka (516 ha/1,275 acres, named after the region of Pesek, where it has traditionally been grown) have been thought by some to be separate varieties even though growers are now beginning to recognize them as one and the same (no DNA evidence yet). Producers of varietal wines include \u0160tiglic, Josef Yhn\u00e1k and Josef Zalaba. Hacaj also make a sparkling version.',
      'Ukraine\u2019s official vineyard statistics recorded approximately 1,600 ha (3,954 acres) in 2009 but they do not distinguish between the various Feteasc\u0103s.',
    ],
  },
  {
    name: 'FIANO',
    synonyms: ['Fiano di Avellino'],
    description:
      'Rich, waxy, strongly flavoured, fashionable southern Italian.',
    'origin and parentage': [
      'Fiano is an old variety from Campania in southern Italy whose presence near Foggia was mentioned as early as 1240 in a register of purchases by the Emperor Frederic II: \u2018de vino greco saumas III, de vino grecisco saumas III, de vino fiano saumas III\u2019 (Huillard-Br\u00e9holles 1859), saumas being an old unit of measurement. The name Fiano is said to derive from a place named Appia (today\u2019s Lapi\u00f2) near Avellino (Scienza and Boselli 2003), where the grape supposedly has its origin.',
      'Ampelographic studies and DNA profiling established that Fiano Aromatico or Fiano di Puglia cultivated in Puglia (specifically in Ostuni in the province of Brindisi and in the Valle d\u2019Itria) is distinct from Fiano or Fiano di Avellino cultivated in Campania (Cal\u00f2 et al. 2001). This prompted a proposal that Fiano Aromatico be renamed MINUTOLO and included as a distinct variety in the Italian national register of grape varieties.',
    ],
    'where its grown': [
      'Fiano was widespread before the arrival of phylloxera in the early twentieth century but since then its cultivation has been drastically reduced. It was revived in the 1970s by Antonio Mastro-berardino, whose winery in the Taurasi zone has been a pioneer in the cultivation of traditional and forgotten varieties, and plantings are now on the increase.',
      'It is recommended throughout Campania and in Potenza in Basilicata in southern Italy and also in the provinces of Ancona, Macerata and Ascoli Piceno in the Marche in central Italy. It is responsible for Fiano di Avellino DOCG and may be a varietal or blending component in DOCs such as Irpinia and Sannio. It has also been enthusiastically planted on Sicilia for varietal wine by the likes of Planeta and Settesoli, so that there were 236 ha (583 acres) on the island by 2008.',
      'Wines are strongly flavoured and full-bodied but less aromatic than those made from GRECO or MINUTOLO. They have an attractively waxy texture and subtle aromas and the best have the capacity to age. Feudi di San Gregorio in Campania produce a textbook example of this style. Di Majo Norante produce a varietal version in Molise. There was a national total of 783 ha (1,935 acres) in the Italian census of 2000.',
      'Fiano has more recently found favour in Australia with growers looking for varieties that withstand the heat, and there are now at least ten producers who claim to have the variety planted, including Jeffrey Grosset in the Clare Valley. Coriole\u2019s rich, dense but fresh example from McLaren Vale was one of the first and has flavours of lemon and pineapple and some complexity.',
    ],
  },
  {
    name: 'FIOLETOVY RANNY',
    synonyms: ['Filetovyi Ranii'],
    description:
      'Russian variety used for the table, grape juice and nutmeg-flavoured red wine.',
    'origin and parentage': [
      'Fioletovy Ranny, meaning \u2018early violet\u2019, is a SEVERNY \u00d7 MUSCAT OF HAMBURG hybrid bred by Ya I Potapenko, I P Potapenko and E L Zakharova at the all-Russia research and development centre for viticulture and winemaking in Novocherkassk, in the province of Rostov, Russia. It was included in the official register of varieties in 1965.',
    ],
    'where its grown': [
      'Grown in Russia (50 ha/124 acres in Rostov in 2010) and across southern Ukraine, Fioletovy Ranny is said to produce wines with a certain nutmeg-like flavour. Tavria in Kherson include it with RKATSITELI and ALIGOT\u00c9 in a ros\u00e9 blend but it is generally found in sweet reds, as well as in juice and on the table.',
    ],
  },
  {
    name: 'FLAVIS',
    synonyms: ['Incrocio Cosmo 76'],
    description: 'Minor white northern Italian cross.',
    'origin and parentage': [
      'A VERDISO \u00d7 Welschriesling (GRA\u0160EVINA) cross obtained in 1976 by Italo Cosmo at the Conegliano research centre in the Veneto in northern Italy.',
    ],
    'where its grown': [
      'Authorized in the province of Treviso, Italy, where it may be used as a replacement for VERDISO. The Italian agricultural census of 2000 recorded just 13 ha (32 acres).',
    ],
  },
  {
    name: 'FLORA',
    synonyms: ['California H59-90'],
    description:
      'Minor, pink-skinned California cross capable of producing aromatic, full-bodied, sometimes flabby wines.',
    'origin and parentage': [
      'A S\u00c9MILLON \u00d7 GEW\u00dcRZTRAMINER cross bred by Harold P Olmo in 1938 at the University of California at Davis, US, and released in 1958 (Walker 2000). This parentage has been confirmed by DNA profiling (Myles et al. 2011).',
    ],
    'where its grown': [
      'Of all Olmo\u2019s crossings, this is the most delicately aromatic, though it shows more S\u00c9MILLON characteristics in warmer climates. It was bred for the hot San Joaquin Valley in California and, although the wine is full-bodied and retains its aromatic qualities well, it lacks acidity, particularly in warmer sites, and fares better in cooler sites such as Mendocino\u2019s Yorkville Highlands. Since it was first produced in 1972, Schramsberg\u2019s Napa Valley Cr\u00e9mant Demi-Sec has had Flora as its core component (currently 85%, with 15% CHARDONNAY).',
      'Its other well-known expression, this time as the minority (20%) component, is Brown Brothers\u2019 delicate dessert wine Orange Muscat (MUSCAT FLEUR D\u2019ORANGER) and Flora. Brown Brothers have the only 7 ha (17 acres) of the variety in Australia, near Swan Hill, Victoria, and say its floral lightness, even when the fruit is picked late and shrivelled, is a foil to the powerful citrus character of Orange Muscat. The distinctive variety also found limited favour in New Zealand in the 1970s but only 2 ha (5 acres) remained in 2009.',
    ],
  },
  {
    name: 'FLORENTAL',
    synonyms: ['Burdin 7705'],
    description:
      'Minor eastern French hybrid widely recommended but apparently hardly grown.',
    'origin and parentage': [
      'A hybrid obtained by father and son Joanny and R\u00e9my Burdin around 1925 in Iguerande, north west of Lyon, in the Sa\u00f4ne-et-Loire, central France, by crossing Seibel 8365 \u00d7 GAMAY NOIR, in which:',
      '\u2013\u00a0\u00a0\u00a0Seibel 8365 is a Seibel 5410 \u00d7 Seibel 4643 hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 5410 is a Seibel 405 \u00d7 Seibel 3004 hybrid (see PRIOR for the complete pedigree of Seibel 405)',
      '\u2013\u00a0\u00a0\u00a0Seibel 3004 is a Bayard (ie Couderc 28-112) \u00d7 Afus Ali (a Lebanese table grape also known as Dattier de Beyrouth) hybrid',
      '\u2013\u00a0\u00a0\u00a0Bayard is an Emily \u00d7 Vitis rupestris hybrid',
      '\u2013\u00a0\u00a0\u00a0Emily is a Vitis vinifera subsp. vinifera \u00d7 Vitis labrusca hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 4643 or Roi des Noirs is a Seibel 29 \u00d7 Danugue hybrid, the latter being a French black-berried table grape',
      '\u2013\u00a0\u00a0\u00a0Seibel 29 is a Munson \u00d7 Vitis vinifera subsp. vinifera hybrid (see PRIOR for the complete pedigree of Munson).',
    ],
    'where its grown': [
      'There were only 28 ha (69 acres) in France in 2008 even though it is officially recommended in about thirty d\u00e9partements. Galet (2000) suggests plantings are mainly in Burgundy and the Loire but no producers seem to own up to it in their vineyards. Wines tend to taste rather similar to GAMAY NOIR and have relatively high levels of colour and alcohol.',
    ],
  },
  {
    name: 'FO\u00c7A KARASI',
    synonyms: ['Bilgi Yok', 'Fo\u00e7a', 'Fo\u00e7akaras\u0131'],
    description: 'Almost-extinct variety from Turkey\u2019s Aegean coast.',
    'origin and parentage': [
      'Fo\u00e7a Karas\u0131, literally the \u2018black of Fo\u00e7a\u2019, is an almost extinct variety that was named after the Fo\u00e7a district near \u0130zmir on Turkey\u2019s Aegean coast.',
    ],
    'where its grown': [
      'There have been recent attempts to replant and re-evaluate this variety with promising results. The \u00d6k\u00fczg\u00f6z\u00fc Winery (sharing a name with the grape variety) in \u0130zmir, Turkey, makes a varietal version.',
    ],
  },
  {
    name: 'FOGARINA',
    synonyms: ['Fogarina di Gualtieri', 'Fugarina'],
    description:
      'Mysterious, twice-rescued northern Italian red that adds colour and acidity in blends.',
    'origin and parentage': [
      'Fogarina probably comes from Emilia-Romagna. According to local tradition, it was found in 1820 by a certain Carlo Simonazzi, who was fishing in the Crostolina, a small tributary of the River Crostolo near Gualtieri, about 70 km north west of Bologna. Another legend has it that Fogarina was dumped a few centuries ago at a place called Fogarin when the River Po flooded. (There is a place north of Gualtieri called Fogare.)',
      'DNA profiling has shown that it is distinct from the non-cultivated Uva Fogarina from Bologna and that Fogarina could be closely related to LAMBRUSCO MARANI (Boccacci et al. 2005); it has also suggested a possible parent\u2013offspring relationship with RABOSO PIAVE (Myles et al. 2011).',
    ],
    'where its grown': [
      'Fogarina was one of the most widely planted varieties in the province of Reggio Emilia in northern Italy at the beginning of the twentieth century, especially in the commune of Gualtieri, and also in those of Boretto and Brescello. Fogarina then spread to the provinces of Modena and Reggio Emilia in Emilia-Romagna and Mantova in Lombardia. It is even the subject of a popular Italian folk song.',
      'Although Fogarina was included in the national register of varieties in the 1960s, by the 1970s it had almost disappeared from Reggio Emilia and, although it did still exist around Modena, Mantova and Verona, it was considered to be extinct and was taken off the register. At the end of the 1990s, the Cantina Sociale di Gualtieri rescued Fogarina with the help of local scholars and public authorities and it has now been reinserted in the register and experimental plantings are in progress.',
      'In 2001, Fabio Simonazzi (no relation to Carlo), one of the producers who helped rescue the almost-forgotten grape, planted a vineyard of Fogarina from cuttings taken from an old, ungrafted vine in Pieve di Gualtieri. He is now one of the major producers of Fogarina, which is generally used to increase colour and acidity in blends. The variety also has firm tannins. The Gualtieri co-op produce both a passito and a semi-sparkling dry ros\u00e9 wine.',
    ],
  },
  {
    name: 'FOGONEU',
    synonyms: [
      'Fogoneau',
      'Fogonet',
      'Fogonetxo',
      'Fogoneu Frances',
      'Fogoneu Mallorqu\u00ed',
    ],
    description: 'Minor Mallorcan, usually blended.',
    'origin and parentage': [
      'Fogoneu originates from the Spanish island of Mallorca and is said to have some similarities with GAMAY NOIR, which might explain its synonym Fogoneu Frances. Recent DNA parentage analysis has suggested that Fogoneu is a natural cross between two obscure varieties from Mallorca, namely Excursach (ESCURSAC) and Mans\u00e9s de Capdell, and a parent of CALLET (Garc\u00eda-Mu\u00f1oz et al. 2012). However, these parentages were based on too few DNA markers (twenty) and need to be confirmed.',
      'The name Fogoneu is said to be a diminutive of fogo, meaning \u2018oven\u2019, but there\u2019s no rational explanation for that etymology.',
    ],
    'where its grown': [
      'Fogoneu is the most important variety in the Felanitx area on the island of Mallorca, Spain, where it is authorized in both the Binissalem and Pl\u00e0 i Llevant appellations as well as in the regional wine Vino de la Tierra de Formentera on the island of the same name. On Mallorca it is often blended with its putative parent CALLET and with MANTO NEGRO, eg in \u00c0n Negra wines, or with international varieties in Cap de Barbaria\u2019s blends. Official statistics suggest there are also 5 ha (12 acres) planted in Extremadura.',
    ],
  },
  {
    name: 'FOKIANO',
    synonyms: [
      'Damaskino',
      'Fokiana',
      'Fokiano Kokkino',
      'Fokiano Mavro',
      'Giouroukiko',
      'Ghiouroukiko',
      'Phokiano',
      'Phokiano Kokkineli',
      'Phokiano Mavro',
    ],
    description:
      'Rare Greek variety almost lost in the confusion over its name and synonyms.',
    'origin and parentage': [
      'According to Lefort and Roubelakis-Angelakis (2001), several grape varieties with black, red or white berries are cultivated in Greece under the name Fokiano \u2013 in the Kykl\u00e1des, the Dodek\u00e1nisa (Dodecanese), the western and northern Aegean Islands, Makedon\u00eda and Thr\u00e1ki (Thrace). Its name is supposedly based on Phocaea or Phokaia, an ancient Ionian Greek city on the western coast of Anatolia corresponding to the modern-day Fo\u00e7a in Turkey.',
      'The \u2018true\u2019 Fokiano discussed here is a black-berried variety that probably originates from the Kykl\u00e1des, where it has been shown by DNA profiling to be identical to Giouroukiko (Lefort and Roubelakis-Angelakis 2001). In addition, comparison of the DNA profiles in the Greek Vitis Database for Skiadopoulo, a white-berried variety that is cultivated in the regions of Il\u00eda, Aitol\u00eda-Akarnan\u00eda and Z\u00e1kynthos (Nikolau and Michos 2004), and for Giouroukiko shows that they are identical (Vouillamoz). Skiadopoulo is therefore likely to be a colour mutation of the Fokiano described here.',
    ],
    'where its grown': [
      'Given the confusion over this variety and all its synonyms, it is difficult to be sure where the \u2018real\u2019 Fokiano is grown. However, official statistics show that there were just 52 ha (128 acres) in Attik\u00ed in the south of mainland Greece in 2008, and it is also found on various Aegean Islands. For example, Afianes on the island of Ikar\u00eda, south west of S\u00e1mos, blend Fokiano with a small amount of MANDILARIA for their red and with Begleri (ie THRAPSATHIRI) for their ros\u00e9. Varietal wines generally lack colour, have moderate to low acidity and are prone to oxidation, so that they often taste of plums, figs and raisins.',
      'The light-skinned Skiadopoulo features along with other local varieties such as GOUSTOLIDI in the traditional white Verdea blends of both Comoutos and Callinico on the island of Z\u00e1kynthos in the Ionian Sea.',
    ],
  },
  {
    name: 'FOLGAS\u00c3O',
    synonyms: ['Cagarrizo', 'Silveiri\u00f1a'],
    description: 'Robust Iberian variety with complex origins.',
    'origin and parentage': [
      'Folgas\u00e3o is said to originate in the D\u00e3o region but comparison of the DNA profile of Folgas\u00e3o in Almadanim et al. (2007) with other published data shows that it is probably identical to two Spanish varieties that are no longer cultivated \u2013 Silveiri\u00f1a in Gago et al. (2009) and Cagarrizo in Santana et al. (2010) \u2013 thereby undermining the theory that Folgas\u00e3o is Portuguese. In addition, Folgas\u00e3o was until recently considered to be identical to TERRANTEZ on the island of Madeira, and with TERRANTEZ DA TERCEIRA and TERRANTEZ DO PICO in the A\u00e7ores, but this has been disproved by DNA profiling (Veloso et al. 2010).',
    ],
    'where its grown': [
      'Folgas\u00e3o wines are generally full-bodied and high in acid, with good ageing potential. In the Tr\u00e1s-os-Montes region in northern Portugal, where the vine is most commonly planted, it is usually blended, and there are some remaining vines in the Douro to the south. There were 357 ha (882 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'FOLIGNAN',
    synonyms: ['INRA 8476'],
    description:
      'Recent Bordeaux cross authorized for the production of cognac.',
    'origin and parentage': [
      'A TREBBIANO TOSCANO \u00d7 FOLLE BLANCHE cross obtained at INRA (Institut National de la Recherche Agronomique), Bordeaux, south-west France, in 1965. Its name seems to be a contraction of the names of its parents, Folle and Ugni Blanc (the name of TREBBIANO TOSCANO in the Charente).',
    ],
    'where its grown': [
      'After forty years of research, Folignan was authorized for the production of cognac in 2005 and was therefore the first INRA cross to be authorized in an appellation contr\u00f4l\u00e9e, though its contribution to any blend is capped at 10%. There were 44 ha (109 acres) planted in France in 2008, all intended for the production of the base wine for cognac.',
    ],
  },
  {
    name: 'FOLLE BLANCHE',
    synonyms: [
      'Chalosse Blanche',
      'Dame Blanche',
      'Enrageat',
      'Enrag\u00e9',
      'Folle',
      'Gros Plant',
      'Matza Zuri',
      'Mune Mahatsa',
      'Piquepoul',
      'Piquepoul du Gers',
    ],
    description: 'Productive but rot-prone and very acidic white variety.',
    'origin and parentage': [
      'Folle Blanche was first mentioned in 1696 in Ch\u00e9rac in the Charente-Maritime, western France, where it has traditionally been used to make cognac and armagnac: \u2018lequel plan sera tout de visan blanc, savoir un tiers de folle blanche, un autre de Gros Bouillau et Gros-Blanc, l\u2019autre tiers de Blanche-Ram\u00e9e\u2019 (\u2018which vineyard will be all visan blanc, ie one third folle blanche, another Gros Bouillau and Gros-Blanc, the other third Blanche-Ram\u00e9e\u2019; R\u00e9zeau 1997). The name Folle (folle is the feminine form of the French word fou, meaning \u2018mad\u2019) probably refers to the vigour and productivity of the vine, as do its synonyms Gros Plant (meaning \u2018big vine\u2019), widely used in the Loire-Atlantique and first mentioned in 1732, and Enrageat (French enrag\u00e9 meaning \u2018mad\u2019 or \u2018rabid\u2019), widely used in the south west of France, where it was first mentioned in 1736 (R\u00e9zeau 1997).',
      'DNA parentage analysis has shown that Folle Blanche is a likely progeny of GOUAIS BLANC as well as a parent of MONBADON (aka Burger) from the Charente and of Merlot Blanc (no longer commercially cultivated) from the Gironde (Bowers et al. 2000; Boursiquot et al. 2009; see CABERNET SAUVIGNON and PINOT pedigree diagrams), two regions where Folle Blanche has traditionally been cultivated. However, Folle Blanche is a member of the Folle ampelographic group (see p XXVII) and is morphologically close to PETIT MESLIER and MESLIER SAINT-FRAN\u00c7OIS (Bisson 2009), varieties from south-west France. This is consistent with Lavignac\u2019s (2001) hypothesis that Folle Blanche originates from the Gers or the Landes in south-west France, whence it spread to the Charente and later to the Pays Nantais at the Atlantic end of the Val de Loire. This theory is supported by parent\u2013offspring and sibling (or half-sibling) relationships recently discovered with JURAN\u00c7ON BLANC from the Gers and the Pyr\u00e9n\u00e9es-Atlantiques, JURAN\u00c7ON NOIR from the Lot-et-Garonne, as well as with MONTILS from the Charente and Meslier Saint-Fran\u00e7ois (under its Charente synonym Blanc Ram\u00e9) from the Val de Loire (Myles et al. 2011).',
      'Folle Blanche is not a colour mutation of JURAN\u00c7ON NOIR nor of FUELLA NERA, both often called Folle Noire.',
      'Folle Blanche was used to breed BACO BLANC, BACO NOIR and FOLIGNAN.',
    ],
    'where its grown': [
      'Folle Blanche was once much more widely planted for the production of eau de vie, particularly in regions near the coast that were easily accessible to the Dutch merchants. Today it is recommended in several d\u00e9partements in the Loire and the south west of France and authorized as far south as the Pyr\u00e9n\u00e9es. Folle Blanche is nevertheless declining considerably: down from 15,865 ha (39,203 acres) in 1958 to a total of 1,770 ha (4,374 acres) in 2009. Most of the vines are at the western end of the Loire, where it is used to produce the varietal Gros Plant du Pays Nantais in roughly the same area as Muscadet. Wines have searingly high acidity and may be described as Muscadet\u2019s country cousin. In south-west France, Folle Blanche is more likely to find its way into the base wines for cognac or armagnac, although it was generally replaced by the more disease-resistant hybrid BACO BLANC after the region was hit by phylloxera. Tariquet, Domaine de Mirail, Godet and a few other producers make a varietal armagnac from Folle Blanche.',
      'As Mune Mahatsa, the variety is sometimes blended with other local varieties such as Hondarribi Zuri (see COURBU BLANC) in the Pa\u00eds Vasco, Spain\u2019s Basque Country on the northern coast, although it is not recorded (under any name) in Spain\u2019s official vineyard statistics. It is also used to a limited extent in the production of Catalan brandy by the likes of Torres.',
      'There are also minor plantings in California.',
    ],
  },
  {
    name: 'FONTE CAL',
    synonyms: ['Fonte de Cal'],
    description: 'Minor Portuguese variety that rarely takes a leading role.',
    'origin and parentage': [
      'Under the old original name Fonte de(a) Cal that was in use until the end of the nineteenth century, this variety was first mentioned in 1790 by Lacerda Lobo in the region of Pinhel in Beira Interior in north-east Portugal, quite close to the Spanish border, where it most likely originates (Rolando Faustino, personal communication).',
    ],
    'where its grown': [
      'Although Fonte Cal is rarely made as a varietal wine, the few examples that exist indicate that it produces wines that are aromatic (both fruity and floral), a little like ALVARINHO, with moderate acidity, although the grapes are often harvested late, after the acidity has dropped a little too far. Harvested earlier, the fruit produces well-structured wines with good ageing potential that become interesting once the primary aromas have been replaced by more complex tertiary ones. It is usually blended with S\u00cdRIA and occasionally with ARINTO DE BUCELAS.',
      'Fonte Cal expanded from Pinhel throughout the Beira Interior region in Portugal in the twentieth century but total Portuguese plantings in 2010 were just 134 ha (331 acres). It is authorized for regional wines in Beiras and in the Pen\u00ednsula de Set\u00fabal, as well as in Beira Interior DOC, but varietal wines are extremely rare. Alcohol and acidity are slightly higher than in FERN\u00c3O PIRES wines although the acidity is still relatively low.',
    ],
  },
  {
    name: 'FORASTERA',
    synonyms: ['Forastiera', 'Forestiera', 'Frastera', 'Uva dell\u2019Isola'],
    description: 'Ischian speciality.',
    'origin and parentage': [
      'Conte di Rovasenda (1877) was the first to mention Forastera, planted on the island of Ischia, just off Napoli in Campania, southern Italy, as well as in Grumello di Monte in the province of Bergamo, whence it has now disappeared. A year later, the ampelographer Giuseppe Froio described Forastera as a variety especially grown in the vineyards of the island of Ischia (Migliaccio et al. 2008). Some authors have suggested that the variety was introduced from Grumello to Ischia in the nineteenth century, as indicated by its name (forestiero means \u2018foreign\u2019).',
      'The variety known as Forastera on the Islas Canarias but no longer commercially cultivated is not the same variety and has a distinct and unique DNA profile (Vouillamoz).',
    ],
    'where its grown': [
      'Recommended throughout Campania and provisionally authorized on Sardegna, Forastera is in practice cultivated only on the islands of Ischia and Procida in southern Italy. Ischia DOC allows for a varietal wine (minimum 85%) as well as a blend of Forastera (45\u201370%) with BIANCOLELLA, which may be still or sparkling. Varietal wines are made by producers such as Casa d\u2019Ambra and Antonio Mazzella. Wines tend to be light and fresh.',
      'There was a total of 102 ha (252 acres) in the Italian census of 2000.',
    ],
  },
  {
    name: 'FORCALLAT TINTA',
    synonyms: [
      'Alcabril di Gualadin',
      'Forcalla',
      'Forcalla Negra',
      'Forcalla Prieta',
      'Verdal',
      'Verdalejo',
      'Verdalla',
    ],
    description: 'Late-ripening central Spanish variety used in blends.',
    'origin and parentage': [
      'Forcallat Tinta comes from Castilla-La Mancha. Forcallat Blanca is not a white mutation of Forcallat Tinta, since it is a distinct variety and its DNA profile (Mart\u00edn et al. 2003) does not match any other known variety (Vouillamoz). (It is also used as a synonym for AIR\u00c9N.) Verdal is also used as a synonym for other Spanish varieties and may therefore be misleading.',
    ],
    'where its grown': [
      'There were 930 ha (2,298 acres) of Forcallat Tinta in Spain in 2008, the majority of the vines in the provinces of Murcia and Valencia. It is authorized in various regional wines such as Vino de la Tierra de Abanilla and Vino de la Tierra Campo de Cartagena in Murcia and Vino de la Tierra el Terrerazo in Valencia. Wines tend to be pale in colour and to go into blends.',
    ],
  },
  {
    name: 'FORSELLINA',
    synonyms: ['Forcelina', 'Forselina', 'Forsella', 'Forsellana', 'Forzelina'],
    description: 'Rarity occasionally blended into Valpolicella or Bardolino.',
    'origin and parentage': [
      'Mentioned in the early nineteenth century in the Valle d\u2019Illasi near Verona in northern Italy.',
    ],
    'where its grown': [
      'Historically the variety has been limited to Valpantena, Valpolicella, the Val d\u2019Illasi, the Val Tramigna and south of Lago di Garda in the Veneto region of northern Italy. Its cultivation decreased drastically during the twentieth century but in the 1970s the research centre at Conegliano rescued some plants from old vineyards and in 1987 the variety was added to the Italian national register. A small percentage may be blended with CORVINA VERONESE, CORVINONE and RONDINELLA and other local varieties in DOCs such as Bardolino (maximum 10%) and Valpolicella, including Amarone and Recioto styles (maximum 15%). Wines are lightly aromatic and fruity.',
    ],
  },
  {
    name: 'FORTANA',
    synonyms: [
      'Can\u00e8na',
      'Canina Nera',
      'Cannina',
      'Costa d\u2019Oro',
      'Oliva',
      'Rapa',
      'Uva Canina',
      'Uva Cornetta',
      'Uva d\u2019Oro',
      'Uva d\u2019Oro di Comacchio',
      'Uva Francese Nera',
      'Uva Vecchia',
    ],
    description:
      'Late-ripening, declining Italian red prized for its high yields.',
    'origin and parentage': [
      'This variety was known in Emilia-Romagna in northern central Italy as early as 1550 as Uva d\u2019Oro (\u2018golden grape\u2019). Some have suggested that this rather strange name for a black-berried variety may have something to do with the variety\u2019s high yields, which meant that it was cherished as if it were golden. The name Fortana is much more recent, chosen when the variety was included in the national register in 1970, and some argue for a related etymology since Fortana means \u2018very fruitful\u2019.',
      'DNA profiling and ampelographic observations unexpectedly proved that Fortana is identical to Canina Nera and possibly has a parent\u2013offspring relationship with LAMBRUSCO MAESTRI, and that it could also be genetically close to MALBO GENTILE from Modena (Boccacci et al. 2005). Canina Nera is not related to the white-berried Canina, a synonym for DRUPEGGIO in Ascoli Piceno. Fortana was also shown to be identical to Rapa, a rare variety of the Italian-speaking Swiss canton of Ticino (Vouillamoz).',
    ],
    'where its grown': [
      'Formerly cultivated in the Oltrep\u00f2 Pavese and the Veronese, Fortana is now limited to the areas of Ravenna (Lugo, Russi, Bagnacavallo, Alfonsine) and Ferrara in Italy. Varietal Fortana wines can be found only in the Bosco Eliceo DOC but a small percentage is allowed in the blend of the various Lambrusco DOCs further west. Fortana is also cultivated to a very limited extent in the provinces of Reggio Emilia and Parma. Under the name Canina Nera, it is on the verge of extinction and used only in blends. Fortana wines can be dry, sparkling or sweet. They have aromas of red fruits, not much alcohol, good acidity that occasionally gives a sour note and somewhat rustic tannins, softened by residual sugar in the sweet version. The Italian census of 2000 recorded 1,109 ha (2,740 acres).',
    ],
  },
  {
    name: 'FRANCAVIDDA',
    synonyms: ['Francavilla'],
    description:
      'Minor and dwindling vine found in Brindisi, generally blended.',
    'origin and parentage': [
      'Most likely originates from Francavilla Fontana in the province of Brindisi in Puglia, southern Italy, since Francavidda is the dialect version of the place name.',
    ],
    'where its grown': [
      'Cultivation in Italy is restricted to the province of Brindisi (notably in Francavilla Fontana, Carovigno, San Vito dei Normanni, San Michele Salentino and Ostuni) but Francavidda is slowly disappearing. Up to 50% Francavilla is allowed in Ostuni DOC, usually blended with IMPIGNO, BIANCO D\u2019ALESSANO and/or VERDECA. Francavidda wines tend to have a delicate perfume and only moderate alcohol.',
    ],
  },
  {
    name: 'FR\u00c2NCU\u015e\u0102',
    synonyms: [
      'Fr\u00e2ncu\u015fe',
      'Fr\u00eencu\u015f\u0103',
      'Mustoas\u0103',
      'Mustoas\u0103 de Moldova',
      'Poam\u0103 Crea\u0163\u0103',
      'Poam\u0103 Franchie',
      'Poam\u0103 Mu\u015ftei',
      'T\u00e2r\u0163\u0103r\u0103',
      'Vinoas\u0103',
    ],
    description:
      'North-east Romanian variety producing fresh and relatively light, dry whites for early drinking.',
    'origin and parentage': [
      'Fr\u00e2ncu\u015f\u0103 was cultivated in the areas of Cotnari, Odobe\u0219ti, Panciu and Hu\u015fi in Romanian Moldova well before phylloxera hit the region at the turn of the twentieth century. Two different types have been distinguished morphologically: one in Odobe\u0219ti with evenly sized berries and one in Cotnari, called T\u00e2r\u0163\u0103r\u0103, with higher sugar content and berries of irregular size due to coulure (Dejeu 2004).',
      'Recent DNA parentage analysis has suggested that Fr\u00e2ncu\u015f\u0103 is one of the numerous progenies of GOUAIS BLANC, like FURMINT in Hungary (Boursiquot et al. 2004).',
      'Fr\u00e2ncu\u015f\u0103 should not be confused with KREACA from Serbia, which is sometimes known as Fr\u00e2ncu\u015f\u0103.',
    ],
    'where its grown': [
      'Fr\u00e2ncu\u015f\u0103 is today found only in the northern part of Romanian Moldova, where there were 352 ha (870 acres) in 2008. Cotnari Winery is the main proponent of varietal wines, which are typically crisp, light and direct with flavours of lemon and green apple.',
    ],
  },
  {
    name: 'FRAPPATO',
    synonyms: [
      'Frappato di Vittoria',
      'Frappato Nero',
      'Frappatu',
      'Nero Capitano',
      'Surra',
    ],
    description:
      'Fruity, fresh and floral Sicilian often blended with Nero d\u2019Avola.',
    'origin and parentage': [
      'Frappato was first mentioned in 1760 in Vittoria in the province of Ragusa in the south of Sicilia, where it is said to have originated. The name Frappato could derive from a corruption of the adjective fruttato, meaning \u2018fruitful\u2019. Recent DNA studies suggested a parent\u2013offspring relationship between Frappato and SANGIOVESE (Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008), making it a likely grandchild of CILIEGIOLO and CALABRESE DI MONTENUOVO. In addition, Frappato and GAGLIOPPO from Calabria are likely to be siblings (Di Vecchi Staraz, This et al. 2007).',
    ],
    'where its grown': [
      'Frappato is cultivated mainly in the province of Ragusa in southern Sicilia, Italy, in particular near Vittoria, but also in the province of Trapani. It tends to be blended with other Sicilian varieties such as NERO D\u2019AVOLA, NERELLO MASCALESE, NOCERA, and even white-berried grapes such INZOLIA and CATARRATTO BIANCO. Frappato contributes 30\u201350% of the blend in Cerasuolo di Vittoria DOCG and is authorized as a component in the Alcamo, Eloro, Erice and Vittoria DOCs but may also be produced as a varietal wine in the last two (or as IGT Sicilia from elsewhere on the island). The Italian census of 2000 recorded total plantings of 785 ha (1,940 acres) but there were 846 ha (2,091 acres) on Sicilia in 2008.',
      'Producers of varietal wines include: Avide, Cal\u00f2 Giordano, Casaventura, Cos, Cantine Rallo and Le Terre del Gattopardo. Wines are far from heavy but are generally fresh and succulent and can taste of strawberries \u2013 often both fruity and floral.',
    ],
  },
  {
    name: 'FRAUELER',
    synonyms: ['Frauler', 'Vezzaner'],
    description: 'Rare, high-yielding and acidic Tyrolean white.',
    'origin and parentage': [
      'Obscure and old variety of the Val Venosta (Vinschgau in German) in the Alto Adige (S\u00fcdtirol) in northern Italy. Its name could derive from Friaul (German for Friuli), suggesting a north-eastern Italian origin. Fraueler is thought to be identical to Gr\u00fcner Barthainer, an ancient Styrian variety from southern Austria that is no longer cultivated, but DNA profiling has not yet been done.',
    ],
    'where its grown': [
      'Fraueler is cultivated to a very limited extent in the Val Venosta/Vinschgau, where, only just in Italy, a few producers continue to make a varietal wine, eg Schnalshuberhof and Befehlhof. Unterortl\u2019s Juval Glimmer blend includes both Fraueler and another rare variety, BLATTERLE, with either RIESLING or M\u00dcLLER-THURGAU. Varietal wines are rather neutral, light-bodied and high in acid, though they can have a certain minerality when fully ripe, which is why Fraueler is generally better as a blending partner.',
    ],
  },
  {
    name: 'FREDONIA',
    synonyms: ['Early Concord'],
    description: 'New York hybrid found mainly in Pennsylvania.',
    'origin and parentage': [
      'A Champion \u00d7 Lucille hybrid obtained in 1915 by Fred E Gladwin at the Department of Pomology and Viticulture at New York State Agricultural Experiment Station, Geneva, where:',
      '\u2013\u00a0\u00a0\u00a0Champion is a CONCORD \u00d7 Hartford hybrid, in which Hartford (or Hartford Prolific) is a likely spontaneous ISABELLA \u00d7 Vitis labrusca hybrid found around 1846 in the garden of Paphro Steele & Son in West Hartford, Connecticut (see BRIANNA for the complete pedigree of Champion)',
      '\u2013\u00a0\u00a0\u00a0Lucille (or Lucile) is a seedling of Wilmington Red',
      '\u2013\u00a0\u00a0\u00a0Wilmington Red is a selected Vitis labrusca vine.',
      'This Vitis labrusca and Vitis vinifera hybrid was released in 1927.',
      'Confusingly, Fredonia is also the name of a tetraploid CONCORD (ie with artificially doubled chromosomes). Fredonia was used to breed VALIANT and VAN BUREN.',
    ],
    'where its grown': [
      'Fredonia is used mainly for the table and for jelly and juice but also occasionally to make mostly ros\u00e9 or light-red wines, sometimes sweet, with a foxy labrusca flavour. Pennsylvania can boast the largest area \u2013 35 acres (14 ha) in 2008. Michigan\u2019s total is down from 21 acres (8 ha) in 1997 to just 7 acres (3 ha) in 2006. Varietal wines are produced by, among others, Arrowhead, Heritage Wine Cellars, Montgomery Underground Cellars, Star Hill and Winfield, all in Pennsylvania.',
    ],
  },
  {
    name: 'FREISA',
    synonyms: [
      'Freisa di Chieri',
      'Freisa Piccola',
      'Freisetta',
      'Fresia',
      'Monferrina',
      'Mounfrina',
      'Spannina',
    ],
    description:
      'Light, fragrant, unusual Nebbiolo relative, made in a wide range of styles, including frothy.',
    'origin and parentage': [
      'Freisa is one of the oldest and most important varieties of Piemonte. Although there is some doubt, the earliest mention of Freisa is thought to be in a 1517 document speaking of a toll fee in Pancalieri, about 30 km south west of Torino; twice as much was paid for fresearum wine compared with other wines (Nada Patrone 1988). The first reliable ampelographic description of Freisa was made by Giuseppe Nuvolone Pergamo (1787\u201398), Count of Scandaluzza.',
      'DNA profiling showed that Freisa and NEBBIOLO have a parent\u2013offspring relationship, and that Freisa could have a close genetic relationship with VIOGNIER from the northern Rh\u00f4ne (Schneider, Boccacci et al. 2004). In addition, Freisa might be closely related to R\u00c8ZE, an old variety from the Valais in Switzerland (Vouillamoz, Schneider et al. 2007).',
    ],
    'where its grown': [
      'Freisa is cultivated in various regions of Piemonte in north-west Italy, particularly in the provinces of Asti, Torino and Cuneo and in the alpine regions, including the subalpine areas. East of Piemonte, there are scattered plantings of Freisa in the province of Vicenza in the Veneto. The Italian agricultural census of 2000 records total plantings of 1,453 ha (3,590 acres).',
      'It is permitted as a minor component in various NEBBIOLO-, BARBERA- or GRIGNOLINO-dominant DOCs and as part of the blend in a wide range of Piemonte DOCs. It also has several DOCs carrying its own name, eg Freisa d\u2019Asti and Freisa di Chieri.',
      'Varietal examples are generally quite light in colour and unexpectedly high in acidity and tannins but they are typified by their distinctive wild-red-fruit fragrance, especially strawberries and raspberries, which, together with a certain bitterness, is not universally appreciated. The Freisa d\u2019Asti and Freisa di Chieri DOCs allow for a bewildering range of permutations: dry or sweet, still, lightly sparkling (frizzante), or fizzy (spumante). The most traditional style, lightly sparkling from a secondary fermentation, has some residual sugar to balance that lightly bitter note. Modern winemaking now allows for better control of fermentation and the level of residual sweetness. Freisa is also allowed as a minor component in several other DOCs such as Valle d\u2019Aosta, Colli Tortonese and Barbera d\u2019Asti.',
      'Borgogno make good examples of still, dry, perfumed Freisa, and other worthwhile examples are made by Aldo Vajra and Coppo.',
      'Freisa has been bottled by the likes of Bonny Doon and Viansa in California.',
    ],
  },
  {
    name: 'FREISAMER',
    synonyms: ['Freiburg 25-1', 'Freiburger'],
    description: 'Early-twentieth-century German cross in severe decline.',
    'origin and parentage': [
      'A SILVANER \u00d7 PINOT GRIS cross created in 1916 by Karl M\u00fcller in Freiburg in southern Germany, hence its original name, Freiburger. DNA profiling has confirmed this parentage (Vouillamoz). Its later name is a combination of Freiburg and Dreisam, the river running through that town.',
    ],
    'where its grown': [
      'In Germany, Freisamer is still grown to a very limited extent (4 ha/10 acres) in its home state of Baden-W\u00fcrttemberg but is nowhere near as popular as in its heyday in the early 1970s. A rare varietal example is produced by Dr Lawall in Rheinhessen.',
      'About the same area is planted to this high-acid variety in German-speaking Switzerland and in Fribourg/Freiburg, and sweeter versions are a speciality of the B\u00fcndner Herrschaft in Graub\u00fcnden. Domaine Chervet in Vully in the canton of Fribourg produce a varietal wine. Wines are crisp, rich in extract but relatively neutral and the grapes need to reach high sugar levels to produce good-quality wine.',
      'It is also planted to a very limited extent in England.',
    ],
  },
  {
    name: 'FRONTENAC',
    synonyms: ['MN 1047'],
    description:
      'Successfully cold-hardy American hybrid thriving particularly in its home state of Minnesota.',
    'origin and parentage': [
      'Bred in 1978 by Peter Hemstad, James Luby and Patrick Pierquet at the University of Minnesota, Frontenac is a hybrid of LANDOT NOIR and Vitis riparia selection #89, where Vitis riparia #89 (also known as MN 89) was a vine found growing wild near Jordan in Minnesota. An extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris, Vitis cinerea and Vitis berlandieri hybrid, Frontenac was selected in 1983 and first released in 1996, one of the first of a new wave of cultivar introductions.',
      'Frontenac Gris is a colour mutation of Frontenac first observed by Peter Hemstad at the University of Minnesota in 1992 and released in 2003.',
      'Frontenac Blanc is a more recent and more promising colour mutation first observed at Vignoble Les Blancs Coteaux near Dunham, Qu\u00e9bec, in 2005, and later found further afield, in Minnesota, for example.',
    ],
    'where its grown': [
      'Thanks especially to its winter resilience and good yields, allied with better quality than most hybrids and an absence of foxy flavour, Frontenac is the mostly widely planted red wine grape in Minnesota (116 acres/47 ha in 2007), and is popular in the northern Midwest, particularly in Illinois (58 acres/23 ha in 2007), Iowa (47 acres/19 ha in 2006), Nebraska (14 acres/6 ha in 2007) and Indiana (10 acres/4 ha in 2010). It is also thriving in northern Nevada. Wines are deeply coloured with flavours of cherry and some blackcurrant or plum, even chocolate as they age, but the acidity can be very high. Frontenac is used to make a wide variety of styles, including ros\u00e9, red and port-like wines, with the last style gaining in popularity since the grapes can be harvested later with lower acidity. Successful varietal wines are made by Falconer, Fieldstone and St Croix in Minnesota; 5 Trails in Nebraska; and Snus Hill and Summerset in Iowa. Despite its popularity, it may well lose ground to newer, lower-acid varieties such as MARQUETTE for the production of dry table wines.',
      'Frontenac is also planted in Canada, notably in Qu\u00e9bec (52 acres/21 ha in 2009).',
      'The grey-berried mutation Frontenac Gris has the same useful viticultural characteristics as the dark-skinned Frontenac, yielding wines that have aromas of peach, apricot and citrus together with refreshing acidity, although the wines are quite deeply coloured. There were 75 acres (30 ha) in Minnesota in 2007 and more limited plantings in Iowa. Fieldstone, Indian Island, Parley Lake and St Croix in Minnesota and Prairie Berry in South Dakota make varietal Frontenac Gris, which is also found in Qu\u00e9bec, Canada.',
      'Commercial plantings of Frontenac Blanc began in US states such as Minnesota, Iowa, Illinois, North Dakota and Montana in around 2009\u201310, earlier in Qu\u00e9bec. Thanks to its high acidity, it is best suited to dessert and fortified, port-style wines and may well start to displace Frontenac Gris because its wines are not pink-tinged.',
    ],
  },
  {
    name: 'FR\u00dcHROTER VELTLINER',
    synonyms: [
      'Fr\u00fcher Roter Malvasier',
      'Korai Piros Veltelini',
      'Malvasier',
      'Malvoisie Rouge d\u2019Italie',
      'Velteliner Rouge Pr\u00e9coce',
      'Veltl\u00ednsk\u00e9 \u010cerven\u00e9 Ran\u00e9',
      'Veltl\u00ednske \u010cerven\u00e9 Skor\u00e9',
    ],
    description:
      'Tentacular in its relations but Gr\u00fcner Veltliner is not one of them.',
    'origin and parentage': [
      'DNA parentage analysis has shown this Austrian variety to be a natural cross between ROTER VELTLINER and SILVANER, just like its sibling NEUBURGER (Sefc, Steinkellner et al. 1998). Fr\u00fchroter Veltliner is consequently a half-sibling of ROTGIPFLER and ZIERFANDLER, as well as a grandchild of SAVAGNIN (see PINOT pedigree diagram). Surprisingly, Roter Veltliner and its progeny Fr\u00fchroter Veltliner are not related to GR\u00dcNER VELTLINER. In addition, Fr\u00fchroter Veltliner was crossed with Grauer Portugieser (see BLAUER PORTUGIESER) to produce JUBIL\u00c4UMSREBE, and with M\u00dcLLER-THURGAU to produce MORAVA and PANONIA.',
    ],
    'where its grown': [
      'In France, Velteliner Rouge Pr\u00e9coce \u2013 also confusingly known as Malvoisie Rouge d\u2019Italie \u2013 is an authorized variety in the Savoie and Haute-Savoie d\u00e9partements and listed in the Vin de Savoie regulations but is little planted, with only 5 ha (12 acres) remaining in 2008. In Italy, where it is known as Fr\u00fchroter Veltliner or simply Veltliner, there are a few old vineyards in the far north. Valle Isarco Veltliner is an authorized variant of the Alto Adige appellation but here it has been largely replaced by GR\u00dcNER VELTLINER (Veltliner Verde). In Germany, where this red-skinned white wine variety is known as Fr\u00fcher Roter Malvasier, there are extremely limited plantings (4 ha/10 acres) in Rheinhessen.',
      'Plantings in Austria are gradually declining and currently stand at 605 ha (1,495 acres), the vast majority of which are in Nieder\u00f6sterreich (Lower Austria), particularly in the Weinviertel north of Wien (Vienna) but also west along the Donau (Danube) in Donauland and Kamptal, for example. The wines it produces are relatively neutral, for early consumption, and tend to have lower acidity than those made from the more widespread and valued GR\u00dcNER VELTLINER.',
      'It is also sparsely grown in Sopron in north-west Hungary and occasionally appears as Veltlinsk\u00e9 \u010cerven\u00e9 Ran\u00e9 in the Czech Republic (251 ha/620 acres) and as Veltlinske Cervene Skore in Slovakia (363 ha/897 acres).',
    ],
  },
  {
    name: 'FUBIANO',
    synonyms: ['Incrocio Dalmasso II/32'],
    description: 'Barely significant white cross from northern Italy.',
    'origin and parentage': [
      'A FURMINT \u00d7 TREBBIANO TOSCANO cross \u2013 hence the name \u2013 obtained by the Italian vine breeder Giovanni Dalmasso in 1936 at the Conegliano research centre in the Veneto, northern Italy, in order to improve quality and reduce the risk of millerandage.',
    ],
    'where its grown': [
      'There were just 3 ha (7 acres) in Italy in 2000 according to the agricultural census.',
    ],
  },
  {
    name: 'FUELLA NERA',
    synonyms: [
      'Dame Noire',
      'Folle de Nice',
      'Folle Noire',
      'Fuella',
      'Fuola',
      'Juran\u00e7on Rouge',
    ],
    description: 'Minor but aromatic variety at home on the French Riviera.',
    'origin and parentage': [
      'Fuella Nera is an old variety from the French Alpes-Maritimes d\u00e9partement (mainly around Grasse, Antibes, La Gaude and Bellet), where it was first mentioned under its synonym Folle Noire in 1783\u20134 in Cannes: \u2018Folles, grain de moyenne grosseur, rond et noir. Pas bon \u00e0 manger, faisant de mauvais vin, mais produisant beaucoup, la grappe est ronde et serr\u00e9e\u2019 (\u2018Folles, medium-sized berry, round and black. Not good to eat, making bad wine, but very productive, the bunch is round and compact\u2019; R\u00e9zeau 1997). However, the name Folle Noire was also given to JURAN\u00c7ON NOIR, N\u00c9GRETTE and N\u00e9gret Castrais (no longer cultivated), three varieties with which Fuella Nera is still often confused. According to R\u00e9zeau (1997), the first occurrence of the local spelling Fuella appeared in Odart in 1859 (see FOLLE BLANCHE for the etymology).',
      'Fuella Nera is a member of the Folle ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'In 2008 there were 19 ha (47 acres) of Fuella Nera \u2013 more often called Folle Noire \u2013 in France\u2019s Alpes-Maritimes, the only French d\u00e9partement in which it is authorized. The tiny Bellet appellation around Nice stipulates a minimum of 60% Fuella Nera and BRAQUET NOIR combined, along with CINSAUT and Grenache (GARNACHA). The best-known producers in Bellet are Ch\u00e2teaux de Bellet and de Cr\u00e9mat but Clos Saint-Vincent make a rare varietal Fuella Nera called Vino di Gio. Wines are generally deeply coloured and aromatic, especially when blended with Braquet Noir, with moderate acidity but still with the ability to age for several years \u2013 so rather better than the eighteenth-century assessment above.',
    ],
  },
  {
    name: 'FURMINT',
    synonyms: [
      'Feh\u00e9r Furmint',
      'Lazaf\u00fcrt\u0171 Furmint',
      'Moslavac',
      'Moslavac Bijeli',
      'Mosler',
      '\u0160ipon',
      'Som',
      'Szigeti',
      'Tokay',
      'V\u00e1ltoz\u00f3 Furmint',
      'Zapfner',
      'Zopfner',
    ],
    description:
      'The principal variety of the fiery, high-acid wines of Tokaj in Hungary.',
    'origin and parentage': [
      'Furmint originates from the Tokaj region in north-eastern Hungary, the home of the famous sweet Asz\u00fa wines (made from grapes affected by the benevolent form of botrytis bunch rot), whose existence was first documented on 15 May 1571 in the H\u00e9tsz\u0151l\u0151 vineyard in the village of Tokaj (Zelen\u00e1k 2002). This wine most probably contained Furmint grapes because this variety was described as \u2018die eigentliche Tokayer Trockenbeertraube\u2019 (\u2018the genuine Tokaji Asz\u00fa grape\u2019) by J\u00e1nos Dercs\u00e9nyi (1796) and also because the variety name was recorded as early as 1611 in the Gyep\u0171 Valley near Erd\u0151b\u00e9nye in the Zempl\u00e9ni Mountains, about 20 km north of the village of Tokaj (Dienes 2001): \u2018A Pr\u00e9dik\u00e1tors\u00e1ghoz vagyon h\u00e1rom sz\u0151l\u0151. Egyik Bakf\u0171 sz\u0151l\u0151, m\u00e1sik Furmint sz\u0151l\u0151 a Gi\u00f6p\u00fc v\u00f6lgy\u00f6n, harmadik Hossz\u00fa sz\u0151l\u0151\u2019 (\u2018There are three vineyards for the preacher\u2019s salary. One is the Bakf\u0171 vineyard, the other is the Furmint vineyard in the Gyep\u0171 Valley, the third one is the Hossz\u00fa vineyard\u2019). According to the historian D\u00e9nes Dienes of the S\u00e1rospatak Calvinist College, who kindly provided translations and historical data, the first vineyard was named Bakf\u0171, meaning \u2018Purple Betony\u2019 (Stachys officinalis), because this herb was grown on the land; the second vineyard, in the Gyep\u0171 Valley near Erd\u0151b\u00e9nye, was named Furmint because this variety was planted there; and the third vineyard was named Hossz\u00fa, meaning \u2018long\u2019, referring to its size. (In the seventeenth century the word sz\u0151l\u0151 meant not only \u2018grape\u2019 but also \u2018vineyard\u2019.)',
      'However, and despite a few dubious hypotheses (see below), the etymology of Furmint is still unknown.',
      'Several DNA parentage analyses (see PINOT pedigree diagram) have established that:',
      '\u2013\u00a0\u00a0\u00a0Furmint has a parent\u2013offspring relationship with GOUAIS BLANC (Boursiquot et al. 2004). Since Gouais Blanc was mentioned much earlier and was much more widespread throughout Europe in the Middle Ages, it is more likely that Furmint is its offspring, making Furmint a half-sibling of at least eighty other progenies of Gouais Blanc such as CHARDONNAY, GAMAY NOIR, ELBLING and RIESLING.',
      '\u2013\u00a0\u00a0\u00a0Furmint has a parent\u2013offspring relationship with H\u00c1RSLEVEL\u0170 (Cal\u00f2, Costacurta et al. 2008; Vouillamoz), the other key variety in Tokaji wines. Since DNA profiling is not consistent with Furmint\u2019s being a putative cross between GOUAIS BLANC and H\u00e1rslevel\u0171, Furmint is therefore a parent of H\u00e1rslevel\u0171.',
      '\u2013\u00a0\u00a0\u00a0The same applies to the obscure PLANTSCHER in Switzerland, which is a natural progeny of Furmint (Vouillamoz, Maigre and Meredith 2004) and is a likely sibling of H\u00c1RSLEVEL\u0170.',
      'Studying the old varieties from Tokaj, Varga et al. (2008) have found four distinct types of Furmint with the same DNA profile: Feh\u00e9r (\u2018white\u2019), Lazaf\u00fcrt\u0171 (\u2018loose-bunched\u2019), Piros (\u2018pink-berried\u2019) and V\u00e1ltoz\u00f3 (\u2018variable\u2019). This high degree of genetic diversity speaks strongly in favour of the Tokaj region as the birthplace of Furmint.',
      'Furmint was used to breed the wine-grape varieties BUSSANELLO, FUBIANO and VEGA in Italy and Z\u00c9TA (formerly Or\u00e9mus) in Hungary.',
    ],
    'where its grown': [
      'Furmint is an intense variety producing world-class, fiery, full-bodied, high-acid, long-lived sweet and dry wines almost exclusively in Central Europe, especially Hungary. By 2010 there were just 9 ha (22 acres) of Furmint in Austria, mainly in Rust, near the Neusiedlersee in the far east of the country, where producers such as Heidi Schr\u00f6ck use it to make fine, sweet Ausbruch wines. Although it is still a rarity here, Furmint seems to appreciate the humidity, and plantings are likely to increase. Both Schr\u00f6ck and Triebaumer also make even rarer dry versions.',
      'In Hungary it is the leading variety in Tokaj, with Soml\u00f3 in the far west of Hungary the only other area in which it is significant. Total plantings amounted to 4,006 ha (9,894 acres) in 2006, more than 97% in Tokaj. The combination of high acid, susceptibility to botrytis bunch rot and high sugar levels in the berries makes Furmint ideal raw material for intensely rich, long-lived Asz\u00fa dessert wines, often blended with the more aromatic H\u00c1RSLEVEL\u0170 and sometimes also a lesser amount of S\u00e1rga Muskot\u00e1ly (MUSCAT BLANC \u00c0 PETITS GRAINS) and/or the more recent Z\u00c9TA.',
      'Furmint\u2019s powerful dry wines, perhaps better able to reflect their exact place of origin than the sweet marvels, have received increased attention and renewed recognition in recent years. In Tokaj the best sites for this style \u2013 around M\u00e1d, Tolcsva and R\u00e1tka \u2013 have long been recognized, even before the sweet wines gained such a strong foothold. Recommended producers of both sweet and dry wines include Istv\u00e1n Balassa, Zolt\u00e1n Demeter, Diszn\u00f3k\u0151, Kir\u00e1lyudvar, the Royal Tokaji Wine Company and Istv\u00e1n Szepsy in Tokaj plus Holl\u00f3v\u00e1r in Soml\u00f3.',
      'There is a good deal of clonal variation and the better clones, generally those with smaller bunches that produce more concentrated wines, tend to be those planted before the Second World War: Mad\u00e1rk\u00e1s, Holyagos and F\u00e9her Furmint (Roh\u00e1ly et al. 2003).',
      'Just over the border from Tokaj, in Slovakia\u2019s Tokajsk\u00e1, similar if more muted styles of sweet wines are produced from the more scattered vineyards there.',
      'In Slovenia there were 694 ha (1,714 acres) of \u0160ipon in 2009, mainly in \u0160tajerska Slovenija (Slovenian Styria), in Podravje in the north east. Traditionally made as a dry wine, the variety can in good years yield sweet wines but not in the Tokaj style. Producers of fine dry wines include Dveri-Pax, Jeruzalem Ormo\u017e, Kupljen, Ljutomer\u010dan, Pullus and Verus; \u010curin-Prapotnik in the south east of the country and a few other smaller producers make sweet varietal wines.',
      'Furmint is known as both Moslavac and \u0160ipon in Croatia, where there were 422 ha (1,043 acres) in 2008 and the wines are almost always dry. It is generally known as \u0160ipon in Medjimurje in the north, close to the Slovenian border. The Moslavac synonym derives from the Moslavina region, south east of Zagreb, where a handful of producers such as Miklav\u017ei\u0107 occasionally make a varietal wine, although it is more often found in blends and frequently overcropped. Miklav\u017ei\u0107 is also experimenting with a sparkling blend of Moslavac, CHARDONNAY and PINOT BLANC.',
      'In South Africa, where Furmint was imported in tandem with the other Tokaji grape, H\u00c1RSLEVEL\u0170, some fine wine has been made from low-yielding vines in the Swartland, notably Signal Hill\u2019s Eszencia 2002.',
      'Such a high-quality variety seems ripe for experimentation in other regions with a suitably long growing season.',
    ],
  },
  {
    name: 'GAGLIOPPO',
    synonyms: [
      'Arvino Nero',
      'Cirotana',
      'Gaglioppo di Cir\u00f2',
      'Galloppo',
      'Morellino Pizzuto',
      'Navarna',
    ],
    description: 'The characterful red wine grape of Calabria.',
    'origin and parentage': [
      'The earliest mention of Gaglioppo as a wine appeared in 1240, when on 21 January Emperor Frederic II ordered a hundred barrels of good \u2018galloppo\u2019 wine of Napoli (\u2018centum barrilia de bono vino de galloppo usque Naepolim\u2019; Huillard-Br\u00e9holles 1859). The name Gaglioppo is said to have Greek roots, kal\u00f3s podos meaning \u2018good/beautiful foot\u2019 (the \u2018foot\u2019 referring to the grape cluster).',
      'DNA profiling has shown that GALLIOPPO DELLE MARCHE is not identical to Gaglioppo (Schneider, Raimondi, Grando et al. 2008). Morellino Pizzuto was once thought to be a clone of SANGIOVESE in Toscana, just like Morellino di Scansano (Scalabrelli et al. 2001), but the same authors recently discovered by DNA profiling that Morellino Pizzuto is in fact identical to Gaglioppo (Scalabrelli et al. 2008). This confusion is explained by recent DNA studies suggesting a parent\u2013offspring relationship between Gaglioppo and Sangiovese (Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008), which was confirmed recently by the discovery of the parentage of Gaglioppo (Cipriani et al. 2010): it is a possible Sangiovese \u00d7 MANTONICO BIANCO natural cross, the latter being a typical variety of the Locride in Calabria, southern Italy. This makes Gaglioppo a sibling of NERELLO MASCALESE from Sicilia. As a consequence, Gaglioppo is a grandchild of CILIEGIOLO and CALABRESE DI MONTENUOVO, the parents of Sangiovese; and Gaglioppo and FRAPPATO from Sicilia are likely to be siblings (Di Vecchi Staraz, This et al. 2007). A possible genetic relationship with ALEATICO has also been suggested (Filippetti, Silvestroni, Thomas and Intrieri 2001).',
    ],
    'where its grown': [
      'Gaglioppo is the predominant red variety in Calabria in southern Italy, where it comprised 95\u2013100% of Cir\u00f2 DOC (until the rules were changed in 2011 to allow the addition of other, generally \u2018international\u2019 varieties) and is authorized as a significant part of the blend in many other Calabrian reds such as Bivongi, Donnici and Lamezia. It is also planted in the Marche, Umbria, Abruzzo and Campania and on Sicilia. The Italian agricultural census of 2000 recorded a total of 3,703 ha (9,150 acres).',
      'The wines are characterized by a ruby colour of only moderate intensity with quite pronounced dusty tannins and, often, haunting aromas of roses. Librandi is the most famous exponent of Cir\u00f2 but other good examples have been made by Francesco e Laura de Franco and Ippolito. Cerraudo have made dramatic 100% Gaglioppo IGT Val di Neto.',
    ],
  },
  {
    name: 'GAIDOURIA',
    synonyms: ['Gaidouricha', 'Gaidouriha', 'Gaydura', 'Guydourina'],
    description:
      'Very rare variety just surviving on the Greek island of Santor\u00edni.',
    'origin and parentage': [
      'Gaidouria comes from the Kykl\u00e1des (Cyclades) in Greece. Its name derives from gaidouri, the Greek word for \u2018donkey\u2019, as the animal is apparently partial to this vine\u2019s foliage (Galet 2000). Recent DNA profiling (under the name Guydouria) has suggested a possible parent\u2013offspring relationship with ASSYRTIKO (Myles et al. 2011).',
    ],
    'where its grown': [
      'Gaidouria is rare even on the island of Santor\u00edni, Greece, but Gavalas, a producer intent on protecting and promoting local indigenous varieties, blend about 15% Gaidouria with the equally rare KATSANO. Gavalas describe it as \u2018a stubborn variety\u2019. Wines are aromatic, have lower acidity than ASSYRTIKO and may have a lightly tannic texture.',
      'In the Urla region of western Turkey, near the coast west of \u0130zmir, Can Ortaba\u015f of Urla Sarap\u00e7\u0131l\u0131k has propagated this variety from a single vine and is now expanding the cultivation to higher vineyards (1,050 m/3,445 ft). It is difficult to graft and here the berries are very small.',
    ],
  },
  {
    name: 'GALBEN\u0102 DE ODOBE\u0218TI',
    synonyms: [
      'Bucium de Poam\u0103 Galben\u0103',
      'Galben\u0103 di C\u0103p\u0103tanu',
      'Galben\u0103 Gras\u0103',
      'Galben\u0103 Uria\u0219\u0103',
      'Poam\u0103 Galben\u0103',
    ],
    description:
      'Gradually declining Romanian variety producing everyday whites.',
    'origin and parentage': [
      'Galben\u0103 de Odobe\u0219ti is an old variety that probably originates in C\u0103p\u0103tanu, near Odobe\u0219ti in eastern Romania. At least three berry-colour variations have been described, suggesting that the variety is very old: Galben\u0103 M\u0103runt\u0103 (pale yellow), Galben\u0103 Verde (yellow-green) and Galben\u0103 Aurie (golden-yellow). Galben\u0103 de Odobe\u0219ti is said to be morphologically similar to other obscure or almost extinct local varieties: Alb Rom\u00e2nesc, Berbecel, Cruciuli\u0163\u0103, B\u0103tut\u0103 Neagr\u0103, Cabasma Neagr\u0103, Cabasma Alb\u0103 and ZGHIHAR\u0102 DE HU\u015eI (Dejeu 2004).',
    ],
    'where its grown': [
      'During the Soviet era, Galben\u0103 de Odobe\u0219ti was valued in Romania for its productivity rather than its quality and today is still used mainly for bulk wine and/or blended, although Vincon Vrancea bottle a varietal version. Wines are light, high in acidity and designed for early drinking. Total plantings in 2008 were 407 ha (1,006 acres), almost all in the southern half of Romanian Moldova in the east of the country, down from 500 ha (1,236 acres) in 1995.',
    ],
  },
  {
    name: 'GALEGO DOURADO',
    synonyms: [
      'Dourado',
      'False Pedro',
      'Gallego',
      'Moscato Galego Dourado',
      'Olho de Lebre',
      'Pedro Luis',
      'Rutherglen Pedro',
    ],
    description: 'Rare Portuguese clings on.',
    'origin and parentage': [
      'Galego Dourado, from Carcavelos and Colares near Lisboa in western Portugal, has since 2004 been part of a rescue programme at the national agronomy centre in Oeiras (Braz\u00e3o et al. 2005).',
      'Now also virtually extinct in South Africa, it was imported as PEDRO XIM\u00c9NEZ, later called Pedro Luis and finally named False Pedro, which is also the name used for CA\u00d1OCAZO in Australia (Kerridge and Antcliff 2009).',
    ],
    'where its grown': [
      'Galego Dourado continues to survive on Portugal\u2019s Atlantic coast around Lisboa despite severe urban encroachment on its vineyards. It is one of the principal varieties in the sweet, fortified blended wines from the Carcavelos appellation, such as that produced by Quinta dos Pesos. Wines are generally rounded, full-bodied and aromatic. There were just 6 ha (15 acres) in Portugal in 2010 even though it is also authorized in Lisboa regional wines.',
    ],
  },
  {
    name: 'GALLIOPPO DELLE MARCHE',
    synonyms: [
      'Balsamina Galloppa',
      'Balsamina Grossa',
      'Gaglioppa',
      'Galloppa',
      'Lancianese Nero',
      'Moretta',
    ],
    description:
      'Recently rescued and replanted central Italian vine that may be related to Sangiovese.',
    'origin and parentage': [
      'DNA profiling has shown that Gallioppo delle Marche, local to the provinces of Ascoli Piceno and Macerata in the Marche, central Italy, is not identical to GAGLIOPPO from Calabria (Schneider, Raimondi, Grando et al. 2008), as had long been thought. DNA profiling at the University of Bologna suggests a possible parent\u2013offspring relationship with SANGIOVESE.',
    ],
    'where its grown': [
      'On the brink of extinction in the 1990s, Gallioppo delle Marche was rescued by the Agenzia Servizi Settore Agroalimentare delle Marche, central Italy, a local-government agency supporting the food services industry. There have been limited replantings recently by Lino Silvestri in Cossignano, the Aurora co-op in Affida, Helmut Kindermann in Monterinaldo and Dante Marucci in Pontano.',
    ],
  },
  {
    name: 'GALLIZZONE',
    synonyms: ['Gallazzone'],
    description: 'Tuscan red on the verge of extinction.',
    'origin and parentage': [
      'Gallizzone is a rare variety that most likely originates from the region between Castelnuovo Magra and Arcola in La Spezia (Liguria, Italy), where it was mentioned in the nineteenth century (Torello Marinoni, Raimondi, Ruffa et al. 2009).',
    ],
    'where its grown': [
      'Gallizzone is not (yet) included in the official register of varieties in Italy but it is being grown by at least one producer: Alessandro Cagnasso, owner of La Fontanina in Massa-Carrara, includes Gallizzone in his red blend of SCHIAVA GROSSA, MERLOT, CILIEGIOLO and BONAMICO. It is also said to be found in the provinces of Lucca and La Spezia.',
    ],
  },
  {
    name: 'GAMARET',
    synonyms: ['Pully B-13'],
    description:
      'Increasingly popular Swiss cross valued for its early ripening and resistance to rot.',
    'origin and parentage': [
      'Gamaret is a GAMAY NOIR \u00d7 REICHENSTEINER cross obtained in 1970 by Andr\u00e9 Jaquinet at the Caudoz viticultural research centre in Pully, just outside Lausanne, Switzerland, today part of Agroscope Changins-W\u00e4denswil (Vouillamoz 2009c). It was initially labelled Pully B-13 before being selected and christened by its breeder. Gamaret is therefore a sibling of GARANOIR and MARA. It was officially released in 1990 together with Garanoir.',
    ],
    'where its grown': [
      'Gamaret provides deep purple colour, spice, power and tannins to blends, eg with PINOT NOIR or GAMAY NOIR, but it is also often made as a barrel-aged varietal wine. It is planted mostly in the cantons of Vaud, Gen\u00e8ve and Valais (total 380 ha/939 acres in Switzerland in 2009) and is increasing in popularity. Producers of varietal wines include Klosterhof in the canton of Luzern, Domaine Les Perri\u00e8res in the canton of Gen\u00e8ve, Domaine Rosset in Vaud and Saint-Gothard in the Valais.',
      'Gamaret was added to the official French catalogue of varieties in 2008 and is authorized in Beaujolais, France; it is also found in Italy.',
    ],
  },
  {
    name: 'GAMAY NOIR',
    synonyms: [
      'Beaujolais',
      'Bourguignon Noir',
      'Gamai',
      'Gamai Chatillon',
      'Gamay Beaujolais',
      'Gamay Charmont',
      'Gamay d\u2019Arcenant',
      'Gamay d\u2019Auvergne',
      'Gamay de la D\u00f4le',
      'Gamay de Liverdun',
      'Gamay de Saint-Romain',
      'Gamay de Sainte-Foix',
      'Gamay de Toul',
      'Gamay de Vaux',
      'Gamay d\u2019Orl\u00e9ans',
      'Gamay du G\u00e2tinais',
      'Gamay Labronde',
      'Gamay Noir \u00e0 Jus Blanc',
      'Gamay Ovo\u00efde',
      'Gamay Pr\u00e9coce',
      'Gam\u00e9',
      'Gamm\u00e9',
      'Grosse D\u00f4le',
      'Liverdun Grand',
      'Lyonnais',
      'Petit Gamay',
      'Plant Robert',
    ],
    description:
      'Greater Burgundian refresher making wines generally but not always for early consumption.',
    'origin and parentage': [
      'Gamay Noir is a very old Burgundian variety whose name first appeared in an official ban promulgated by Duc Philippe le Hardi in Dijon on 31 July 1395 (Rossignol 1854; Vermorel 1902): \u2018un tr\u00e8s-mauvaiz et tr\u00e8s-desloyaulx plant nomm\u00e9z Gaamez, duquel mauvaiz plant vient tr\u00e8s-grant habondance de vins .\u00a0.\u00a0. Et lequel vin de Gaamez est de tel nature qu\u2019il est moult nuysible a creature humaine, mesmement que plusieurs, qui au temps pass\u00e9 en ont us\u00e9, en ont est\u00e9 infest\u00e9s de griez maladies, si comme entendu avons; car le dit vin qui est yssuz du dit plant, de sa dite nature, est plein de tr\u00e8s-grant et horrible amertume .\u00a0.\u00a0. Pour quoi nous .\u00a0.\u00a0. vous mandons .\u00a0.\u00a0. sollempnellement \u00e0 touz cilz qui ont les diz plans de vigne des diz Gaamez, que yceulx coppent ou fassent copper en quelque part qu\u2019ilz soient en nostre dit pa\u00efs dedens cing mois\u2019 (\u2018a very bad and disloyal variety called Gaamez, from which come abundant quantities of wine .\u00a0.\u00a0. And this wine of Gaamez is of such a kind that it is very harmful to human creatures, so much so that many people who had it in the past were infested by serious diseases, as we\u2019ve heard; because said wine from said plant of said nature is full of significant and horrible bitterness. For this reason we solemnly command you .\u00a0.\u00a0. all who have said vines of said Gaamez to cut them down or have them cut down, wherever they may be in our country, within five months\u2019).',
      'After that, numerous subsequent orders were issued with the aim of banning plantings of Gamay Noir (in 1567, 1725 and in 1731), underlining the poor reputation that this variety has today only partially overcome. Numerous spellings were used (Gaamez in 1395, Gamey in 1444, Gam\u00e9 in 1496 and 1650, Gamay in 1564, Gamez in 1567, Gamet in 1667, etc.) until 1896, when the spelling Gamay was adopted by the ampelographic congress in Chalon-sur-Sa\u00f4ne (R\u00e9zeau 1997). Although this variety is usually said to have been named after the village of Gamay near Saint-Aubin in the C\u00f4te d\u2019Or, where vineyards still exist, it would be interesting to see whether the name of the village followed the same spelling evolution as that of the grape variety: Gaamez to Gamey to Gam\u00e9 to Gamay.',
      'Gamay Noir is a member of the Noirien ampelographic group (see p XXVII), and is ampelographically close to SACY (Levadoux 1956; Bisson 2009). These ampelographic observations were confirmed in 1999 by researchers at the University of California at Davis and at INRA (Institut National de la Recherche Agronomique) in Montpellier, southern France, who found by DNA parentage analysis that Gamay Noir is a natural progeny of PINOT and GOUAIS BLANC (Bowers et al. 1999), just like at least twenty other sibling varieties (see the pedigree diagram in the PINOT entry). Gamay Noir was therefore born before the fourteenth century, somewhere in Burgundy, where both its parents were already cultivated in the Middle Ages.',
      'Gamay Teinturier Fr\u00e9aux, sometimes known as Gamay Fr\u00e9aux, Teinturier de Couchey or Teinturier Fr\u00e9aux, is said to be a mutation of Gamay Teinturier de Bouze found in 1841 in Couchey in the C\u00f4te d\u2019Or by Antoine Fr\u00e9aux, who then brought it back home to Saint-Denis-de-Vaux, west of Chalon-sur-Sa\u00f4ne, and propagated it in the area (Galet 1990). However, bunches of the white-juiced \u2018regular\u2019 GAMAY NOIR, or Gamay Noir \u00e0 Jus Blanc, have often been observed on Gamay Teinturier Fr\u00e9aux vines, which indicates that it is a mutation and not an offspring of Gamay Noir (Galet 1990).',
      'Gamay Blanc Gloriod, no longer cutlivated today, is not a colour mutation of Gamay Noir but its brother, since it is also a natural progeny of PINOT and GOUAIS BLANC (Bowers et al. 1999).',
      'Gamay Noir was used to breed FLORENTAL,GALOTTA, GAMARET, GARANOIR, MARA and REGNER.',
    ],
    'where its grown': [
      'France had 30,443 ha (75,226 acres) of Gamay in 2009, making it the seventh most planted red variety and it is recommended or authorized in almost every wine-growing region with the exception of the Bordeaux area, the island of Corse and Alsace. About two thirds of total plantings are in the greater Vall\u00e9e du Rh\u00f4ne and particularly in Beaujolais, where it is the red variety. In 2009, of the 18,682 ha (46,164 acres) of red wine varieties in the Rh\u00f4ne d\u00e9partement (not to be confused with the Vall\u00e9e du Rh\u00f4ne), only 312 ha (771 acres) were not Gamay (they were in fact SYRAH). A few other varieties are permitted in Beaujolais\u2019 red and ros\u00e9 wines but only in small amounts: GAMAY TEINTURIER DE BOUZE and Gamay Teinturier de Chaudenay (no more than 10% separately or together), ALIGOT\u00c9, CHARDONNAY, MELON, PINOT GRIS and PINOT NOIR (only if the vines are interplanted with Gamay in the vineyard and up to a maximum of 15%). In practice, most Beaujolais is 100% Gamay, or Gamay Noir \u00e0 Jus Blanc as it is sometimes called to distinguish it from the quite different, red-fleshed varieties Gamay Teinturier de Bouze and Gamay Teinturier de Chaudenay (the latter a mutation of the former).',
      'Until the early years of the twenty-first century, recommended Gamay producers would have been entirely made up of those producers with a long tradition in Beaujolais such as Jean-Marc Burgaud, Lapierre, Lapalu, Ch\u00e2teau de Thivin, Vissoux and Jean-Paul Brun. But there is now a perceptible influx of serious, hopeful newcomers, several of them from further north, in Burgundy proper, following in the footsteps of Louis Jadot, who took over the Ch\u00e2teau des Jacques.',
      'In the C\u00f4te d\u2019Or d\u00e9partement, Gamay is generally considered inferior to PINOT NOIR, as the vineyard area and grape prices show. In the half-century since 1958, the area planted to Pinot Noir has doubled to 6,579 ha (16,257 acres) whereas that for Gamay is little more than one fifth of what it was (193 ha/477 acres). In greater Burgundy, hectares planted to Pinot Noir outnumber those of Gamay by about five to one. The long-standing confusion between Beaujolais and Bourgogne appellations \u2013 used by some producers, it was suggested, to give the impression their wines were Pinot Noir rather than Gamay \u2013 is being resolved by changes to labelling regulations. Beaujolais wines may state the main variety on the label and the C\u00f4teaux Bourguignons appellation may be applied to any red Beaujolais.',
      'In the past, Gamay was generally light and fruity, and those rushed through the cellar by vinification techniques such as carbonic maceration in order to reach the market in November after the harvest as Beaujolais Nouveau were often thin and tasted like bananas and bubble-gum. However, with the decline in fashion and market demand for this style and the increasing number of well-made and ambitious wines vinified more traditionally, sometimes with oak ageing, Gamay is showing more purely its fine, refreshing, sometimes peppery, red fruit \u2013 and surprising longevity in the case of some wines from the ten crus of Beaujolais, in approximately ascending order of substance: Chiroubles, Saint-Amour, Fleurie, R\u00e9gni\u00e9, Brouilly, C\u00f4te de Brouilly, Juli\u00e9nas, Ch\u00e9nas, Morgon and Moulin-\u00e0-Vent.',
      'The vine\u2019s precocious behaviour means that it is at risk of spring frosts in the cooler vineyards of the Loire but it is easier to ripen than, say, CABERNET SAUVIGNON. It is planted across the region but especially in the Loir-et-Cher d\u00e9partement upstream of Tours, where there were 1,707 ha (4,218 acres) in 2009. Although the Gamay de Touraine appellation is the variety\u2019s most self-evident expression here, providing a light and generally cheaper alternative to Beaujolais, the variety is widely planted further west, alongside SAUVIGNON BLANC, to produce the likes of Cheverny and Coteaux de Vend\u00f4mois. Further upstream and around Lyon, the variety contributes to a number of less well-known appellations such as Ch\u00e2teaumeillant, Coteaux du Lyonnais, Coteaux du Giennois, C\u00f4tes d\u2019Auvergne, C\u00f4tes du Forez, C\u00f4tes Roannaises and Saint-Pour\u00e7ain. On the whole, Gamay has been planted for quantity rather than quality but Henry Marionnet at Domaine de la Charmoise, Domaine Jacky Marteau and Domaine Robert Serol et Fils are exceptions. To the east, Gamay is the most widely planted red variety in Savoie, with the best examples coming from the cru of Chautagne.',
      'It is also known in Germany and in England, where Biddenden have made a competent one.',
      'Gamay is the second most planted red in Switzerland, after PINOT NOIR, with which it is often blended \u2013 most famously in the wine known as D\u00f4le in the Valais. Swiss plantings in 2009 totalled 1,514 ha (3,741 acres) and were mainly in the cantons of Vaud, Valais and Gen\u00e8ve. Good examples are made by G\u00e9rald Besse (Martigny, Valais), Fr\u00e9d\u00e9ric Dumoulin at L\u2019Orpailleur (Uvrier, Valais), Domaine Les Hutins (Dardagny, Gen\u00e8ve), especially their La Briva Vieille Vigne, and Domaine Le Grand Clos (Satigny, Gen\u00e8ve). In Vaud, producers such as Henri & Vincent Chollet and Domaine du Daley are particularly proud to label their wine Plant Robert. The best producers of D\u00f4le include Marie-Th\u00e9r\u00e8se Chappaz (Fully), Denis Mercier (Sierre) and the Cave du Rhodan (Salgesch). The variety is also grown in the Valle d\u2019Aosta in the far north west of Italy, where there were 159 ha (393 acres) in 2000.',
      'Gamay has been confused with BLAUFR\u00c4NKISCH in Eastern Europe, not helped by the use of the synonym Gam\u00e9 for Blaufr\u00e4nkisch in Bulgaria. There are limited plantings of the real Gamay in Serbia, Kosovo and the Republic of Macedonia. Turkey had a total of 236 ha (583 acres) in 2010 and it is known in both Lebanon and Israel.',
      'In California in the 1980s, Charles F Shaw (who was to give his name to the trail-blazing recession-beater Two Buck Chuck) tried to launch a \u2018Gamay\u2019 but it turned out that neither the variety known there as Napa Gamay nor that called Gamay Beaujolais was in fact Gamay Noir. Today, Edmunds St John, V Sattui, J Rochioli but few others persist with Gamay.',
      'Oregon is a more successful exponent of true Gamay, notably with examples from Brick House and Evening Land. It also pops up in bottles from Michigan, Texas and New York State.',
      'Gamay is grown and produced in Canada as a varietal from a wide array of producers in both British Columbia (which had 144 acres/58 ha in 2009) and Ontario (185 acres/75 ha in 2006). It is not a South American phenomenon, however.',
      'In Australia there are about twenty producers in various parts of the country, with Sorrenberg of Beechworth, Victoria, having the best track record, although William Downie has had recent success in Yarra Valley. Plantings are limited. Te Mata is one of the few New Zealand producers to take this variety (12 ha/30 acres in 2008) seriously.',
      'South Africa had 19 ha (47 acres) in 2009 with Klein Zalze taking it more seriously than most.',
    ],
  },
  {
    name: 'GAMAY TEINTURIER DE BOUZE',
    synonyms: [
      ': Gamay de Bouze',
      'Moureau',
      'Mourot',
      'Plant de Bouze',
      'Rouge de Bouze',
    ],
    description:
      'Red-fleshed variety possibly related to the more common Gamay Noir but much less widely planted.',
    'origin and parentage': [
      'Gamay Teinturier de Bouze was the first red-juiced (teinturier means \u2018dyer\u2019) Gamay to be observed, in the village of Bouze, near Beaune, in the nineteenth century. Its origin is disputed: some suggest it is a mutation of GAMAY NOIR, while others believe it could be a natural offspring of Gamay Noir and TEINTURIER because the latter was often planted with the former to enhance the colour of the wines. DNA parentage analysis would resolve the debate. It might better be described as a \u2018semi-teinturier\u2019 because the juice is lighter than that of Gamay Teinturier Fr\u00e9aux.',
      'Gamay Teinturier de Bouze is a member of the Noirien ampelographic group (see p XXVII; Bisson 2009).',
      'Gamay Teinturier de Chaudenay is a red-fleshed mutation of Gamay Teinturier de Bouze observed in 1832 in Chaudenay in the Sa\u00f4ne-et-Loire d\u00e9partement by Jean-Marie Bidault, who had introduced some vines of red-juiced Gamay from Puligny in the C\u00f4te d\u2019Or. From 1850, Bidault commercialized this mutation across the Sa\u00f4ne-et-Loire under the name Gros Mourot. This variety was so productive that it was called Gamay Six Pi\u00e8ces, pi\u00e8ce being a measure corresponding to 300 hl/ha (Galet 1990).',
    ],
    'where its grown': [
      'Official statistics for France for 2008 list the mutations separately: Gamay Teinturier de Bouze 232 ha (573 acres), Gamay Teinturier de Chaudenay 183 ha (452 acres) and Gamay Teinturier Fr\u00e9aux (see GAMAY NOIR) 55 ha (136 acres). They also list \u2018Gamay Teinturiers\u2019, whatever that may be: 81 ha (200 acres).',
      'Henry Marionnet at Domaine de la Charmoise makes not only a very good GAMAY NOIR but also one of the rare varietal examples of Gamay Teinturier de Bouze, under the brand name Les C\u00e9pages Oubli\u00e9s, or the Forgotten Varieties. He notes that the wine has predominantly red-fruit flavours and tends to be more tannic than Gamay Noir \u00e0 Jus Blanc, which is why he vinifies it using carbonic maceration.',
      'The Republic of Moldova had a small amount of both Gamay Teinturier Fr\u00e9aux and Gamay Teinturier de Bouze in 2009.',
    ],
  },
  {
    name: 'GAMBA DI PERNICE',
    synonyms: ['Neretto degli Alteni', 'Pernice'],
    description: 'Rare, aromatic and spicy Piemontese.',
    'origin and parentage': [
      'From Astigiano in the province of Asti, Gamba di Pernice is an old and rare Piemontese variety, mentioned by Nuvolone Pergamo, Conte di Scandaluzza (1787\u201398). Its name, meaning \u2018partridge\u2019s leg\u2019, points to the intense red colour of the stalks before veraison. DNA profiling has established that Gamba di Pernice is cultivated under the name Neretto degli Alteni around Saluzzo in the province of Cuneo in Piemonte (Raimondi et al. 2006).',
    ],
    'where its grown': [
      'Gamba di Pernice is cultivated mainly in Calosso, Costiglioe and Asti Canelli in the province of Asti in north-west Italy. A group of six producers \u2013 Tenuta dei Fiori, Maurizio Domanda, La Canova, Fea, Carlo Benotto and Villa Giada \u2013 are working together to promote this ancient variety and obtain recognition for it as a DOC. However, it is argued that Gamba di Pernice cannot be included in the national register of varieties because there is a risk of confusion with Occhio di Pernice, a protected name for an expensive Vin Santo in Toscana. Plantings are so limited that the variety did not feature in the Italian agricultural census of 2000. The Cantina Collina d\u2019Oro, also make varietal Gamba di Pernice. Wines are usually spicy, peppery and fruity, with hints of tar as well as notes of green pepper. They generally benefit from a year or more in bottle.',
    ],
  },
  {
    name: 'G\u00c4NSF\u00dcSSER',
    synonyms: [
      'Argan',
      'Argant',
      'Bockshorn',
      'Buchser',
      'Espagnol',
      'Gros Margillien',
      'Margillien',
    ],
    description: 'Barely surviving ancient variety, probably German.',
    'origin and parentage': [
      'G\u00e4nsf\u00fcsser, meaning \u2018goose-feet\u2019, referring to the shape of the leaves, is an old variety from Rheinland-Pfalz in Germany. Its wine was mentioned as early as 1505, when citizens of Deidesheim offered G\u00e4nsef\u00fcsserwein to their bishop (Schumann 1983). The variety was also mentioned by the famous German botanist Hieronymus Bock (1546) in his Kreutterbuch: \u2018Genssf\u00fcssel wachsen umb die Newenstatt\u2019 (\u2018G\u00e4nsf\u00fcsser grows around Neustadt [an der Weinstrasse, in W\u00fcrttemberg]\u2019). In 1584, Pfalzgrafen (Count Palatine) Johann Kasimir ordered that no G\u00e4nsf\u00fcsser vineyard be grubbed up if no new one was to be planted. Numerous subsequent mentions in Rheinland-Pfalz and in W\u00fcrttemberg indicate that G\u00e4nsf\u00fcsser was widely cultivated in south-western Germany in the Middle Ages (Schumann 1983).',
      'The French ampelographers Adrien Berget and Paul Pacottet established that G\u00e4nsf\u00fcsser and Argant, considered an old variety of the Franche-Comt\u00e9 in north-east France, were one and the same variety (Pacottet 1904), which was recently confirmed by DNA profiling (Vouillamoz, Frei and Arnold 2008). Pacottet rejected the hypothesis of a Franche-Comt\u00e9 origin for Argant, arguing that neither Argant nor any of its synonyms had been mentioned in the Franche-Comt\u00e9 before the eighteenth century. Indeed, the earliest mention of this variety in France is likely to have been in 1774 under the name Margillien in a list of the seven best varieties for winemaking in Arbois (Franche-Comt\u00e9). However, the name Margillien has been used for several distinct varieties in the Jura, and the name Argant appeared much later.',
      'DNA parentage analysis has revealed that C\u00c9SAR, a variety cultivated in the Yonne (west of the Franche-Comt\u00e9), is a natural progeny of G\u00e4nsf\u00fcsser \u00d7 PINOT (Bowers et al. 2000; see Pinot pedigree diagram), which could support a north-eastern France origin. However, C\u00e9sar is also known in Rheinland-Pfalz under the name R\u00f6mer, and Berget found that Argant (G\u00e4nsf\u00fcsser) was morphologically distinct from any French variety and considered it to be of German origin.',
      'DNA profiling has disproved the synonymy between the Proven\u00e7al variety BARBAROUX, sometimes called G\u00e4nsf\u00fcssler, and G\u00e4nsf\u00fcsser (Vouillamoz).',
      'More recent DNA profiling (under the name Argant) has suggested a possible parent\u2013offspring relationship with LE\u00c1NYKA (although the reference sample might be FETEASC\u0102 ALB\u0102; Myles et al. 2011).',
    ],
    'where its grown': [
      '\u00c9tienne Thiebaud, who established Domaine des Cavarodes in the Jura, eastern France, at the age of twenty, has rescued an old vineyard in Liesle, south west of Besan\u00e7on in the Doubs region, and produces a red Vin de Pays de Franche-Comt\u00e9 which is a blend of PINOT NOIR, TROUSSEAU, PINOT MEUNIER, GAMAY NOIR, POULSARD, GUEUCHE NOIR, G\u00e4nsf\u00fcsser (ie Argant), BLAUER PORTUGIESER, ENFARIN\u00c9 NOIR and M\u00c9ZY, all from interplanted vines aged 50\u2013100 years. Domaine Ganevat in Rotalier have replanted a small area and include it in an early-drinking red blend.',
      'Plantings of G\u00e4nsf\u00fcsser, like those of many other late-ripening varieties in south-western Germany (AFFENTHALER, GOUAIS BLANC, ORLEANS GELB, ELBLING, etc.), have declined since the Middle Ages, probably because the climate became cooler, and it had almost disappeared from Rheinland-Pfalz as well as from the Franche-Comt\u00e9 across the French border in the twentieth century. In the village of Hassloch in Rheinland-Pfalz, G\u00e4nsf\u00fcsser vines several centuries old are still growing on the walls of houses in the Gillergasse. A Hasslocher \u2018Edition Gillergasse\u2019 special cuv\u00e9e was made from these vines in 2007 for the first time and sold in aid of the local museum. The same year, 1,500 vines were planted in the Leisb\u00f6hl vineyard by Fritz Braun and an equal number by the DLR viticulture school in Mussbach. A varietal wine is already produced by the Staatsweingut mit Johannitergut in Neustadt-Mussbach in the Pfalz.',
    ],
  },
  {
    name: 'GARA IKENI',
    synonyms: ['Gara Lkeni', 'Qara Lkeni', 'Lkeny Tchernyi'],
    description: 'Azeri grape used for both dry and sweet reds.',
    'origin and parentage': ['Gara Ikeni is indigenous to Azerbaijan.'],
    'where its grown': [
      'Gara Ikeni is used to make dry, dessert and fortified wines and also for juice. Producers of the first, which typically have just 9.5\u201310.5% alcohol, include Ganja Sharab 2 in the Ganja region of north-western Azerbaijan.',
    ],
  },
  {
    name: 'GARANDMAK',
    synonyms: ['Alani Chagog', 'Alivoruk', 'Dik Chardji', 'Garan Dmak'],
    description: 'Old, popular and multi-purpose Armenian variety.',
    'origin and parentage': [
      'Garandmak is an old variety from the province of Armavir in western central Armenia that is said still to be propagated by seeds (Chkhartishvili and Betsiashvili 2004), which would result in vineyards composed of Garandmak and many of its progenies. Garan Dmak means \u2018fatty tail\u2019.',
    ],
    'where its grown': [
      'One of Armenia\u2019s most popular varieties, Garandmak is used in blended table wines and dessert wines as well as for brandy and grape juice. According to Galet (2000), it is also found in Ukraine.',
    ],
  },
  {
    name: 'GARANOIR',
    synonyms: ['Pully B-28'],
    description: 'Swiss cross producing fruity but low-acid wines.',
    'origin and parentage': [
      'Garanoir is a GAMAY NOIR \u00d7 REICHENSTEINER cross obtained in 1970 by Andr\u00e9 Jaquinet at the Caudoz viticultural research centre in Pully, just outside Lausanne, Switzerland, today part of Agroscope Changins-W\u00e4denswil (Vouillamoz 2009c). It was initially labelled Pully B-28 before being selected and christened Gastar, then Granoir and finally Garanoir by its breeder. Garanoir is therefore a sibling of GAMARET and MARA. It was officially released in 1990 together with Gamaret.',
    ],
    'where its grown': [
      'Garanoir is fruitier and less concentrated than its sibling GAMARET and has not been as widely planted: 203 ha (502 acres) in Switzerland in 2009, mostly in the cantons of Vaud, Gen\u00e8ve and Valais. Acidity can be low so it is generally blended with varieties such as GAMAY NOIR or PINOT NOIR or Gamaret, and contributes colour, fruit and spice. It is sometimes made as a varietal wine, eg by Cidis in Vaud, Cave du Paradou in the Valais and Domaine de la Vigne Blanche in the canton of Gen\u00e8ve.',
      'It is also found to a very limited extent in Germany, where Weingut Kuhnle in the Schwaben (Swabia) region, just west of Stuttgart, make a popular varietal wine.',
    ],
  },
  {
    name: 'GARGANEGA',
    synonyms: ['Grecanico Dorato', 'Malvas\u00eda de Manresa'],
    description:
      'A delicate white closely associated with Soave whose vigour and yields need strict control.',
    'origin and parentage': [
      'Garganega is an old variety from the Veneto in north-east Italy, first mentioned in the thirteenth-century treatise of the Italian agronomist Pietro de Crescenzi as a grape cultivated in the Bologna and Padova regions.',
      'DNA profiling provided surprising evidence that Garganega is identical to Grecanico Dorato from Sicilia and, even more surprising, to Malvas\u00eda de Manresa from Catalunya in Spain (Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008), which is no longer cultivated. Comparison of DNA profiles also suggested some genetic similarities between Garganega and several other old varieties from the province of Verona: CORVINA VERONESE, OSELETA, RONDINELLA and DINDARELLA (Vantini et al. 2003), thereby confirming that Garganega has its roots in this area. Garganega is also a parent of SUSUMANIELLO through a natural crossing with Uva Sogra (Di Vecchi Staraz, This et al. 2007), a variety that is no longer cultivated but has the same DNA profile as Uva Sacra, a local table grape of Puglia (Zulini et al. 2002).',
      'In addition, DNA parentage analysis has shown that parent\u2013offspring relationships exist between Garganega and at least eight other varieties: ALBANA, CATARRATTO BIANCO, DORONA DI VENEZIA, MALVASIA BIANCA DI CANDIA, MARZEMINA BIANCA, MONTONICO BIANCO, MOSTOSA and TREBBIANO TOSCANO (Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008). Theoretically, any of the varieties shown in the diagram overleaf as progenies of Garganega could also be a parent of Garganega, except Dorona di Venezia and SUSUMANIELLO, for which the full parentage has been established. Although those possible relationships have yet to be confirmed, they illustrate the importance of Garganega throughout the length of Italy.',
      'Garganega was used to breed INCROCIO BIANCO FEDIT 51.',
    ],
    'where its grown': [
      'Garganega, from the Veneto region in Italy, is most well known as the mainstay (minimum 70%) of Soave in all its forms, including Classico and Recioto. It is also the predominant variety in Gambellara DOC and is an authorized ingredient in several others such as Bianco di Custoza, Colli Berici and Colli Euganei. Its most common blending partner is Trebbiano di Soave (VERDICCHIO BIANCO) but many producers include a small percentage of CHARDONNAY to fill it out. Varietal versions are allowed in the Veneto DOCs of Arcole, Colli Berici and Garda and it is authorized in the blend of a few DOCs further afield, in Lombardia and Trentino-Alto Adige. Further south, in Umbria, it can reach higher ripeness levels than in its more familiar territory in the north.',
      'The best examples of Soave, generally from the Classico zone when yields have been restricted to allow the grapes to ripen fully, are characterized by lemon and almond flavours and a fine grainy texture like that of fresh pears. This delicacy combines with fresh acidity to give wines that may be steely and yet still tantalizingly spicy. The best exponents of the variety include Anselmi (who choose the greater freedom of working outside the Soave DOC regulations), Ca\u2019 Rugate, Inama, Cantina di Monteforte and Pieropan.',
      'Under the name Grecanico Dorato, or sometimes just Grecanico, this variety has been cultivated on Sicilia for several centuries, mainly in the north west and west, around Trapani, Palermo and Agrigento. It features in the Alcamo, Contea di Sclafani, Contessa Entellina, Delia Nivolelli, Erice, Menfi, Monreale, Santa Margherita di Belice and Sciacca DOCs. There were 5,358 ha (13,240 acres) on Sicilia in 2008. COS make an excellent blend of 50% Grecanico, 50% INZOLIA, which is rich and nutty and full of flavour.',
      'A total of 11,637 ha (28,756 acres) was recorded in the Italian agricultural census in 2000.',
      'Further afield in Australia, Politini Wines in King Valley, Victoria, harvested their first Grecanico crop in 2009 and are experimenting with both still and sparkling versions, although Domain Day at Mount Crawford in the Barossa were the first to plant this variety outside Italy (first vintage 2004).',
    ],
  },
  {
    name: 'GARNACHA TINTA',
    synonyms: [
      ': Abundante',
      'Alicante',
      'Licante',
      'Aragones',
      'Bernacha Negra',
      'Bois Jaune',
      'Cannonao',
      'Cannonau',
      'Crannaxia',
      'Granaxia',
      'Vrannaxia',
      'Garnacha',
      'Garnaxa',
      'Gironet',
      'Granache',
      'Granaxa',
      'Grenache',
      'Grenache Crni',
      'Grenache Noir',
      'Lladoner',
      'Redondal',
      'Ranaccio',
      'Roussillon',
      'Sans Pareil',
      'Tinto Basto',
      'Tocai Rosso',
      'Vernaccia Nera',
    ],
    description:
      'Very widely planted, long-lived vine making strong, sweetish reds and some successful ros\u00e9s.',
    'where its grown': [
      'This is one of the world\u2019s most planted grape varieties, not least because it can thrive in hot, dry climates \u2013 so it is likely to maintain this position in a world experiencing climate change and where drought seems an increasingly common phenomenon. Considering how common the variety is, it is remarkable that the words Garnacha and Grenache are not seen more often on labels, although the variety is admittedly often blended, particularly with SYRAH (Shiraz) and Mourv\u00e8dre (MONASTRELL) \u2013 so often that \u2018GSM\u2019 has become an established name for such a blend.',
      'As Grenache Noir, this is France\u2019s second most planted variety after MERLOT, with a total of 94,240 ha (232,872 acres) in 2009, restricted by its heat- and sun-loving temperament to the south of the country, especially the Vaucluse d\u00e9partement (27,466 ha/67,870 acres), where most of the southern Rh\u00f4ne wines are grown. There were also 3,675 ha (9,081 acres) in the Bouches-du-Rh\u00f4ne to its immediate south, 8,928 ha (22,062 acres) in the Dr\u00f4me, 2,325 ha (5,745 acres) in the Ard\u00e8che, and 8,359 ha (20,656 acres) in the Var, centre of Provence\u2019s wines.',
      'The single wine that most obviously represents southern French Grenache is Ch\u00e2teauneuf-du-Pape, most of which is made substantially and sometimes exclusively of Grenache Noir, with some of the many other varieties allowed in red Ch\u00e2teauneuf. In these dry vineyards, berries are small, grape skins are thick and the resulting wines are deep-coloured and often tannic in youth. They also dance along the spectrum between herby and spicy, perhaps as much because of the terroir as the grape itself, but if the variety\u2019s wines have a characteristic it is high alcohol and a certain ripe sweetness. This can be seen, sometimes in rather more dilute form, in all the satellite southern Rh\u00f4ne wines such as Gigondas, Vacqueyras and C\u00f4tes du Rh\u00f4ne (Villages). There are literally hundreds of producers of fine examples of these wines, some of which can be great value. Substantially north of this area in the Dr\u00f4me and the Ard\u00e8che, the wines are a little lighter and less sweet. To the south east in the heart of Provence, Grenache is more likely to be grown to produce herby dry ros\u00e9, some of the most characterful in the world, as well as some of the least distinguished. And to the west, in the Gard d\u00e9partement, where 16,987 ha (41,976 acres) grew in 2009, Grenache is responsible for some of the world\u2019s most substantial ros\u00e9s in Tavel and its neighbour Lirac. In the Gard it seems to take on an extra juiciness and polish, perhaps because rainfall is a little higher.',
      'In Languedoc-Roussillon proper, total plantings of Grenache are now greater than those of Carignan (MAZUELO) but rather less than those of fashionable SYRAH. In 2009 there were 10,372 ha (25,639 acres) in the H\u00e9rault, 8,494 ha (20,989 acres) in the Aude, and 6,769 ha (16,727 acres) in the Pyr\u00e9n\u00e9es-Orientales, where Grenache of all three colours has long been grown to produce strong vins doux naturels from appellations such as Maury, Banyuls and Rivesaltes, often made in a rancio (ie oxidative) style by ageing in glass demi-johns or old barrels in the sun. Throughout the warmer, more Mediterranean parts of Languedoc-Roussillon, Grenache Noir is also increasingly used for table wines as an ingredient with Syrah, Carignan, Cinsault (CINSAUT) and Mourv\u00e8dre (MONASTRELL) in the likes of Faug\u00e8res, Saint-Chinian, Minervois, Corbi\u00e8res, Fitou and C\u00f4tes Catalanes. Varietal Grenache Noir is a relative rarity here but if yields are too high and the land unpromising, Grenache can taste a little too jammy.',
      'In Italy, Sardegna is Garnacha\u2019s (and Carignano\u2019s/MAZUELO\u2019s) Italian stronghold. Known here as Cannonau, it is the island\u2019s most important grape and is grown mostly in the province of Nuoro in the east and is particularly successful on the hillside areas of Barbagia, Ogliastra, Sarrabus and Romangia and also in the Nurra and Campidano areas. The 2000 agricultural census found a total of 6,288 ha (15,538 acres). Most wines are sold as varietal Cannonau di Sardegna, a DOC which may be produced anywhere on the island and accounts for about 20% of total production. It is made in dry and sweet, both fortified and not, but alcohol levels are naturally high, often more than 15%, anyway. Superior producers include Argiolas, whose Turriga blend is probably the single most exciting Italian expression of the grape, Dettori and Alberto Loi.',
      'As Tocai Rosso it is authorized in Colli Berici DOC in the Veneto, where the census found a total of 384 ha (949 acres) in 2000.',
      'A grand total of 75,399 ha (186,315 acres) of Spain were planted with Garnacha Tinta in 2008, less than 7% of the Spanish vineyard area, predominantly in the autonomous communities of Arag\u00f3n in the north east (21,202 ha/52,391 acres, which represented 45% of the region\u2019s vineyard area) and Castilla-La Mancha in the centre (23,518 ha/58,114 acres; only 4.5% of vineyard area) but also in Castilla y Le\u00f3n, Catalunya, La Rioja, Madrid, Extremadura, Navarra and Valencia, in fact pretty much everywhere except the north west and the deep south. It is now in third place among the dark-skinned varieties in Spain, after TEMPRANILLO (206,988 ha/511,478 acres) and BOBAL (85,124 ha/210,346 acres).',
      'Overall plantings are in decline (eg 89,045 ha/220,035 acres in 2001) but it is particularly significant for ros\u00e9 production in Navarra. For years Garnacha\u2019s ubiquity meant that Spaniards viewed it as necessarily inferior to TEMPRANILLO but, partly because it is so important in Priorat, which since the 1990s has managed to command some of the highest wine prices in Spain, its reputation has been reassessed. It is now much more likely to feature on Spanish wine labels than it was even ten years ago, and many export markets treasure the concentrated, sweet fruit in wines made from old Garnacha bushvines which proliferate in regions such as Empord\u00e0, Campo de Borja, Cari\u00f1ena, Costers del Segre, Madrid, La Mancha, M\u00e9ntrida, Pened\u00e8s, Somontano, Tarragona, Terra Alta, Utiel-Requena and Valdeorras.',
      'Even in Rioja, where the more structured TEMPRANILLO reigns supreme, it is now recognized that Garnacha, the signature grape of the lower, warmer Rioja Baja subregion, can provide the flesh to complement the bones of Tempranillo.',
      'Older plantings of Garnacha Tinta \u2013 and average vine age of this variety is high for it belongs to another era \u2013 are often intermingled with some Garnacha Peluda, or \u2018downy Garnacha\u2019.',
      'Producers who make superior Garnacha, or Garnacha-based blends, include Celler Mas Doix, Celler Val Llach, Clos i Terrasses, Mas Alta, Mas Martinet, Alvaro Palacios and Terroir al Limit in Priorat; Torres in Catalunya; Chivite and Guelbenzu in Navarra; Can Blau and Porto del Montsant in Montsant; Baigorri, Bret\u00f3n and Dinast\u00eda Vivanco for varietal versions in Rioja; and the Australian-run Alto Moncaya plus Bodegas Aragonesas in Campo de Borja.',
      'Grenache is also found in Croatia (292 ha/722 acres of Grenache Crni in 2009), in Turkey (34 ha/84 acres in 2010), on Cyprus (95 ha/235 acres in 2010) and on Malta (24 ha/59 acres in 2010) as well as, to a strictly limited extent, in Morocco, Algeria and Israel.',
      'Grenache Noir is said to have been introduced to California by Charles Lefranc, a Santa Clara grower, in the late 1850s and its versatility made it a popular choice during the planting boom towards the end of the nineteenth century. After Prohibition, plantings increased in the Central Valley, mainly for dessert and ros\u00e9 wines, and some Grenache-based commercial pink wines have more recently been marketed as an alternative to White Zinfandel. Today, however, it is being taken increasingly seriously, particularly by those with treasured old vines, as witness Keplinger\u2019s various bottlings.',
      'The state total in 2010 was 6,170 acres (2,497 ha), mostly in the Sierra Foothills, especially El Dorado and Madera counties. Favia\u2019s Rompecabezas from the Sierra Foothills is a good example of a GSM blend while superior varietal or Grenache-dominant wines are produced by Ridge in Dry Creek Valley, Sine Qua Non in Santa Barbara and Tablas Creek in Paso Robles.',
      'Grenache, one of the first varieties to be planted in the state, has recently blossomed in Washington as a cult for Rh\u00f4ne wines has developed, and total plantings had crept up to 261 acres (106 ha) by 2011. McCrae Cellars (who also make a varietal Grenache Blanc), Cayuse and K Vintners are some of the leading exponents.',
      'It is also grown to a limited extent in southern Oregon, Arizona and Texas. And in Mexico, some drought-proof Grenache is grown mainly in Baja California. It is not of much importance in South America although it is known in Argentina (22 ha/54 acres in 2008, mainly for ros\u00e9), Chile (1 ha/2.5 acres in 2008) and Peru.',
      'Grenache has known at least two reversals of fortune in Australia in modern times. Until the 1960s it was the country\u2019s most important variety. It was then overtaken by Shiraz (SYRAH), which then made way for CABERNET SAUVIGNON for a French-influenced while. A global re-evaluation of Rh\u00f4ne varieties has led to a fashion for Grenache varietals and, especially, GSM blends, with considerable tracts of old-vine Grenache having unfortunately already been pulled out of the inland regions and some new plantings in more admired, but necessarily warm, regions, mainly in South Australia. In 2008 plantings totalled 2,011 ha (4,969 acres), predominantly in the Barossa Valley, McLaren Vale, Riverland and Langhorne Creek, with lesser plantings in the Clare Valley, Swan District, Adelaide Hills and Plains, Eden Valley and Heathcote.',
      'Recommended producers of varietal Grenache include Schwarz Wine Company, Torbreck, Turkey Flat and Yalumba in the Barossa Valley, d\u2019Arenberg in McLaren Vale, Kilikanoon in the Clare Valley, Longhop on the Adelaide Plains and Jasper Hill in Heathcote. However, as in the southern Rh\u00f4ne, some of the finest expressions of Grenache are in classic blends, usually with Shiraz (SYRAH) or with Shiraz and Mourv\u00e8dre (MONASTRELL), such as those produced by Glaetzer, Henschke, Charlie Melton, Steve Pannell, Rockford, Teusner and Spinifex.',
      'As in southern France, Grenache is also an important ingredient in some of Australia\u2019s finest fortified wines such as the venerable \u2018Tawny\u2019 wines produced by Grant Burge, Penfolds and Seppeltsfield.',
      'Grenache is not a particularly significant variety in South Africa, where 144 ha (356 acres) were found in 2009 quite widely dispersed around the wine regions. Varietal wines are produced by Citrusdal, Neil Ellis, Sequillo and Tierhoek, among others, but Grenache Noir is more often found in blends, eg with Shiraz (SYRAH), on the Australian model, or with a whole range of other varieties, including Carignan (MAZUELO), Mourv\u00e8dre (MONASTRELL) and sometimes even CABERNET SAUVIGNON.',
    ],
  },
  {
    name: 'GARNACHA BLANCA',
    synonyms: [
      'Alicant Belyi',
      'Alicante Blanca',
      'Belan',
      'Belon',
      'Bernacha Blanca',
      'Feh\u00e9r Grenache',
      'Garnacha',
      'Garnacho Blanco',
      'Garnatxa',
      'Garnatxa Blanca',
      'Gkrenas Mplan',
      'Grenache Blanc',
      'Grenash Beli',
      'Grenash Belyi',
      'Grenash Bjal',
      'Lladanor Blanca',
      'Rool Grenache',
      'Silla Blanc',
      'Sillina Lanc',
    ],
    description: 'Full-bodied whites, pale version of the reds.',
    'where its grown': [
      'The big, sometimes fat, wines are rich in extract but can be prone to premature oxidation. Aromas are likely to be of very ripe green fruits (greengages?) with some floral notes. Alcohol levels tend to be high so blending in crisper varieties can be helpful.',
      'It is recommended, like Grenache Noir, in much of southern France, including the southern Rh\u00f4ne. There were 4,976 ha (12,296 acres) in 2009, spread right around the Mediterranean basin and up into the southern Rh\u00f4ne, with the biggest area in Roussillon, where it has long been grown for vins doux naturels. Its importance has declined so that it is now the ninth most planted white wine variety in France, just ahead of VIOGNIER. But the variety\u2019s qualities are increasingly recognized in a Rh\u00f4ne-besotted era, even though it is more often encountered in a blend than as a varietal wine. If yields are checked and it is vinified carefully, often in oak, it can produce rich, substantial wine. In Roussillon, Clos des F\u00e9es produce an impressively fragrant and densely textured old-vine Grenache Blanc (including 10% Grenache Gris) and Gauby\u2019s high-toned Coume Gineste is a 50/50 blend of Blanc and Gris. Diffonty\u2019s white Ch\u00e2teauneuf blends a significant proportion of Grenache Blanc with ROUSSANNE while Ch\u00e2teau Rayas combine 50% Grenache Blanc with 50% CLAIRETTE in their dense and savoury rendition of white Ch\u00e2teauneuf.',
      'Grenache Blanc is still a significant ingredient in various vins doux naturels such as Rasteau from the southern Rh\u00f4ne and Maury, Rivesaltes and Banyuls from Roussillon, particularly in the white version of Maury, where it is blended with Grenache Gris, Macabeu (MACABEO) and Tourbat (TORBATO).',
      'The 2008 census found 2,100 ha (5,189 acres) of Garnacha Blanca in Spain, mostly in Catalunya and Arag\u00f3n in the north east, and especially in the provinces of Tarragona, Zaragoza and Teruel, where it produces full-bodied wines with a high alcohol content. It is classified as one of the principal varieties in DOs such as Alella, Costers del Segre, Priorat, Rioja, Tarragona and Terra Alta, although it is the least planted of Rioja\u2019s authorized white varieties.',
      'In Priorat, Vi\u00f1edos de Ithaca produce a sweet varietal version of Garnacha Blanca, while Gran Clos\u2019 Vinya Llisarda is a blend based on Garnacha Blanca.',
      'It is found in Croatia and, under the name Belan, in the Republic of Macedonia, where it tends to be planted only in the warmer vineyards because of its sensitivity to cold winter temperatures. It is also grown to a limited extent in California (266 acres/108 ha in 2010) with Santa Ynez Valley being a hotspot. There is also a little in the Sierra Foothills. The number of exuberantly varietal California Grenache Blancs has been increasing in line with CHARDONNAY fatigue.',
      'South Africa had 46 ha (114 acres) of the variety in 2008. The Foundry and Signal Hill take it seriously.',
    ],
  },
  {
    name: 'GARNACHA ROJA (GRIS)',
    synonyms: [
      'Garnacha Gris',
      'Garnacha Rioja',
      'Garnacho Rojo',
      'Garnatxa Gris',
      'Grenache Gris',
      'Grenache Rouge',
      'Grey Grenache',
      'Piros Grenache',
      'Rosco dos Pinheiro',
      'Szuerke Grenache',
    ],
    description: 'Perfumed and full-bodied.',
    'where its grown': [
      'Grenache Gris, almost a combination of Grenache Blanc and PINOT GRIS, is authorized in exactly the same d\u00e9partements of southern France as Grenache Blanc, with total plantings of 1,699 ha (4,198 acres) of Grenache Gris in 2009, mostly in Roussillon and some in the Languedoc and the southern Rh\u00f4ne. More often than not it contributes to white blends, particularly with Grenache Blanc or Macabeu (MACABEO) but also with any number of different varieties from the Ch\u00e2teauneuf-du-Pape recipe. In Roussillon, Matassa blend 70% Grenache Gris with 30% Macabeu to create a racy, complex white while Le Roc des Anges\u2019 Iglesia Vella is a perfumed and polished varietal Grenache Gris. Domaine Jones sometimes add a tiny percentage of Muscat to the majority Grenache Gris to create a rich and expressive slightly off-dry version.',
      'Spain grew just 71 ha (175 acres) of this grey- or pink-skinned Garnacha in 2008, mainly in Navarra and Castilla-La Mancha. Producers such as Espelt in Catalunya blend Garnatxa Gris with Garnatxa Blanca as well as Garnatxa Negra; Ac\u00fastic in Montsant make a white blend that includes Garnacha Blanca and Garnacha Gris; Castell de Biart in Empord\u00e0 make a rancio-style blend of Garnatxa Roja and Gris. Rare varietal wines made from Garnacha Roja include the Pau i Roses co-operative\u2019s sweet, fortified version from Empord\u00e0, Mag\u00ed Baiget\u2019s dessert wine (Montsant) and Vinyes dels Aspres\u2019 dried-grape dessert wine made in a rancio style in Empord\u00e0.',
      'It is also grown to a limited extent in California, mainly in the Central Coast and McDowell Valley, and in South Africa (3 ha/7 acres in 2008).',
    ],
  },
  {
    name: 'GARNACHA PELUDA',
    synonyms: [
      'Garnatxa Pelud',
      'Garnatxa Peluda',
      'Grenache d\u2019Afrique',
      'Grenache Noir Tomenteux',
      'Grenache Poilu',
      'Grenache Velu',
      'Lladoner Pelud',
      'Lledoner Pelut',
    ],
    description: 'Downy-leaved variant of Garnacha Tinta.',
    'where its grown': [
      'The grapes reach phenolic ripeness at a lower sugar levels than Garnacha Tinta so the wines are generally lower in alcohol (no more than 13.5%), and this hairy-leaved version was not widely replanted after phylloxera when vins doux naturels were all the rage. The \u2018fur\u2019 (pelut, which gives it its French name) evolved to protect the vine from transpiration in the heat, conserve moisture and reduce stress, just like the corresponding fuzz on rosemary or other Mediterranean plants. Another adaptation is that it never breaks in high winds, so it will survive in even the most exposed sites. Its wine tends to have a higher acid level and, as it ages, spicy and savoury characters develop more quickly than with Grenache Noir.',
      'In 2008 there were 433 ha (1,070 acres) of Lledoner Pelut in France. It is treated as a separate variety from Grenache Noir in the French official register of varieties but it tends to be recommended alongside it and is authorized in such southern French appellations as Corbi\u00e8res, Languedoc, C\u00f4tes du Roussillon and C\u00f4tes du Roussillon-Villages, Faug\u00e8res, Minervois and Saint-Chinian. In the Languedoc, Domaine La Colombette produce a varietal version while the likes of Les Clos Perdus and Domaine du Comte del Roc make significant use of it in blends.',
      'Spain has much more, 903 ha (2,231 acres) in total in 2008, in Castilla-La Mancha, Arag\u00f3n, Catalunya and, to a lesser extent, in Castilla y Le\u00f3n. It is often blended with CABERNET SAUVIGNON or TEMPRANILLO in oak-aged wines. Lighter, younger wines are prone to oxidation and should be drunk young. Varietal versions are rare but Vi\u00f1edos de Ithaca in Priorat produce an exception and Capafons-Oss\u00f3 in Montsant include it their Mas de Massos blend.',
    ],
  },
  {
    name: 'GARRIDO FINO',
    synonyms: [
      'Charrido Fino',
      'Garrido',
      'Garrido Fino de Villanueva',
      'Garrio Fino',
      'Palomino Garrio',
    ],
    description: 'Very minor, acidic variety from Huelva in southern Spain.',
    'origin and parentage': [
      'Garrido Fino is an old Andalusian variety from the province of Huelva. It should not be confused with the almost-extinct Garrido Macho as the two varieties have distinct DNA profiles (Mart\u00edn et al. 2003).',
    ],
    'where its grown': [
      'Garrido Fino is found in the Condado de Huelva DO in south-western Andaluc\u00eda in Spain\u2019s deep south, where its fresh acidity, smooth texture and moderate alcohol make it a valued but minor blending component, particularly in sweeter styles and with the variety ZALEMA. So little is now planted that it does not feature in official Spanish statistics but Bodegas G\u00f3ngora (founded 1682) make both dry and off-dry varietal wines.',
    ],
  },
  {
    name: 'GASCON',
    synonyms: [
      'Franc Noir de l\u2019Yonne',
      'Franc Noir du G\u00e2tinais',
      'Noirien',
      'Plant de Moret',
    ],
    description: 'Rare Touraine vine.',
    'origin and parentage': [
      'In spite of its name, Gascon is unlikely to come from Gascogne, that part of south-west France which corresponds to the modern Aquitaine and Midi-Pyr\u00e9n\u00e9es regions, but from around Orl\u00e9ans in the Loiret d\u00e9partement in northern central France, where this variety was first mentioned by Olivier Jullien (1816): \u2018les plants que l\u2019on cultive plus g\u00e9n\u00e9ralement dans le Loiret sont, en rouge, .\u00a0.\u00a0. le gascon, le gamm\u00e9 .\u00a0.\u00a0.\u2019 (\u2018the varieties that are more generally cultivated in the Loiret are, in red, .\u00a0.\u00a0. gascon, gamm\u00e9 .\u00a0.\u00a0.\u2019). Alternatively, it may come from the Yonne d\u00e9partement, to the east of the Loiret, where it used to be widely cultivated before the phylloxera crisis and where it got its synonym Franc Noir de l\u2019Yonne (Galet 1990).',
      'Gascon is a member of the Cot ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Gascon produces deeply coloured red wines with lively acidity. In France a few limited plantings of Gascon can still be found in Touraine. Pascal Simonutti, in Mesland, south west of Blois, makes a funky vin de table varietal Gascon. Claude Courtois in Soings-en-Sologne to the west has a plot of young Gascon vines and describes the wine he makes from them in some vintages as \u2018the most beautiful of the domaine\u2019.',
    ],
  },
  {
    name: 'GEGI\u0106',
    synonyms: [
      'Debejan',
      'Debljan',
      'Gegi\u0107 Bijeli',
      'Pa\u0161ka',
      'Pa\u0161kinja',
      '\u017dutina',
    ],
    description: 'Speciality of the Croatian island of Pag.',
    'origin and parentage': [
      'Gegi\u0107 most likely originates from the islands of the Kvarner Gulf (between Istra (Istria) and the Croatian mainland), including the island of Pag, where it has long been grown to produce the locally famous Pa\u0161ka \u017dutica wine, from which the local synonyms Pa\u0161ka or Pa\u0161kinja derive.',
    ],
    'where its grown': [
      'Gegi\u0107 is officially authorized in Croatia in the Hrvatsko Primorje (Croatian Littoral) region but its irregular yields have led to a decline in the area planted. Producers include Bo\u0161kinac, Cissa and Vina Otaka, all on the island of Pag. Anton Katunar on the island of Krk blends Debejan with SYRAH and SU\u0160\u0106AN (known locally as Sansigot).',
    ],
  },
  {
    name: '\u0120ELLEW\u017bA',
    synonyms: ['Gelleuza'],
    description:
      'Second most planted Maltese variety but being edged out by international invaders.',
    'origin and parentage': [
      'A recent study has shown that \u0120ellew\u017ca has a unique DNA profile (Giannetto et al. 2010) and is therefore likely to be indigenous to Malta or Gozo. The name \u0120ellew\u017ca is said to derive from an Arabic word and is Maltese for \u2018hazelnut\u2019, perhaps a reference to the shape and small size of the berries.',
      '\u0120ellew\u017ca was said to be a seedling of the Italian MAMMOLO (Borg 1922) but this is contradicted by DNA analysis (Vouillamoz).',
    ],
    'where its grown': [
      '\u0120ellew\u017ca, the most popular indigenous red variety on Malta and Gozo, produces light, red-fruited reds and easy-drinking ros\u00e9 wines with modest alcohol levels. Ros\u00e9s tend to be particularly successful since they avoid \u0120ellew\u017ca\u2019s sometimes astringent tannins.',
      'Estimates as to the area planted range from 40 ha (99 acres) to 100 ha (247 acres), since not all vineyards are registered, and they are mainly unirrigated bushvines. The area has been in decline, \u0120ellew\u017ca being an unfortunate victim of the general preference for international varieties, especially since the EU-funded plantings began after Malta\u2019s accession in 2004. However, increasing interest in indigenous varieties may soon halt that trend. Delicata and Marsovin both make a medium-dry sparkling ros\u00e9 but several producers blend \u0120ellew\u017ca with varieties such as Shiraz (SYRAH).',
    ],
  },
  {
    name: 'GENOUILLET',
    synonyms: ['Genouilleret', 'Genouillet Noir', 'Moret Noir'],
    description: 'Recently rescued central French vine.',
    'origin and parentage': [
      'Genouillet used to be cultivated in the Indre (for example, in Issoudun, La Ch\u00e2tre and Ch\u00e2teaumeillant) and the Cher d\u00e9partements in central France until the phylloxera crisis. Its name probably derives from Genouilly, a commune near Bourges in the Cher.',
      'Genouillet is a member of the Noirien ampelographic group, to which PINOT belongs (see p XXVII; Bisson 2009). However, this ampelographic classification has been challenged by DNA parentage analysis, which has revealed that Genouillet is a natural TRESSOT \u00d7 GOUAIS BLANC cross (Bowers et al. 2000), neither of the parents being a member of the Noirien group. Since Tressot is a variety from the Yonne, a d\u00e9partement bordering the Cher, and since Gouais Blanc was cultivated almost everywhere in central and north-eastern France, Genouillet was therefore most likely born somewhere in either the Cher or the Yonne d\u00e9partement.',
    ],
    'where its grown': [
      'Genouillet was almost extinct in France when it was rescued in 1990 in the region of Issoudun, south west of Bourges in the Indre d\u00e9partement. Some 150 vines were planted in 2005 in an experimental vineyard at the Domaine de Villalin in the Quincy appellation in collaboration with the Union pour la Pr\u00e9servation et la Valorisation des Ressources G\u00e9n\u00e9tiques du Berry. The aim is to obtain official authorization to plant, and make commercial wine from, Genouillet in the near future.',
    ],
  },
  {
    name: 'GIBI',
    synonyms: [
      'Alzibib',
      'Aparia',
      'Augibi',
      'Jubi Blanc',
      'Maccabeu \u00e0 Gros Grains',
      'Panse Blanche',
      'Passerille Blanche',
      'Tercia Blanc',
    ],
    description:
      'Ancient Spanish table grape still planted in Argentina with significant offspring.',
    'origin and parentage': [
      'Gibi is an old table grape from Spain that was supposedly introduced by the Arabs under the name Alzibib, deriving from the Arabic word zab\u012bb, meaning \u2018raisin\u2019, just like Zibbibo, the name for MUSCAT OF ALEXANDRIA on Sicilia. DNA parentage analysis has shown that Gibi is the likely mother of PEDRO XIM\u00c9NEZ (Vargas et al. 2007), an old white grape variety from Andaluc\u00eda, and that Gibi and PLANTA NOVA (under the name Tortoz\u00f3n) are the parents of ALARIJE (under the name Subirat Parent; Lacombe et al. 2007), an old variety from Extremadura, where it was known as long ago as 1513. These relationships with ancient Spanish varieties suggest that Gibi originates in south-western Spain, or at least has been cultivated there since the Middle Ages.',
    ],
    'where its grown': [
      'Gibi was once grown in Spain and southern France, where it is still occasionally found as a table grape (Galet 2000), but in 2009 it was grown on 1,080 ha (2,669 acres) in Argentina. Here it is used both for wine (generally for the domestic market) and for grape juice.',
    ],
  },
  {
    name: 'GINESTRA',
    synonyms: ['Biancatenera', 'Biancazita', 'Ginestro', 'Nocella'],
    description: 'Minor but interesting and refreshing Campanian.',
    'origin and parentage': [
      'Ginestra was already mentioned by Acerbi (1825) as one of the main varieties of the Napoli area in Campania in southern Italy. Its name comes from the broom-like flavour in the grapes (Italian ginestra). The synonymy between Ginestra and Biancazita, a variety grown in the same area that is also called Biancatenera, had been suspected by Froio (1875) and was recently confirmed by DNA profiling (Costantini et al. 2005). The same DNA study also established a possible genetic relationship between Ginestra and two other Campanian varieties: CAPRETTONE and Piedirosso Napoletano, a non-cultivated variety that should not be confused with PIEDIROSSO.',
    ],
    'where its grown': [
      'Ginestra is cultivated exclusively in the province of Salerno along the Amalfi coast in southern Italy, in particular in the municipalities of Scala, Ravello, Amalfi, Minori, Maiori, Furore, Tramonti, Corbara and Positano. Ginestra wines have crisp acidity and can taste similar to FALANGHINA FLEGREA. With some ageing they tend to develop the kerosene-like aromas sometimes found in older RIESLING. Marisa Cuomo\u2019s Costa d\u2019Amalfi DOC Furore Bianco Fiorduva is a blend of FENILE, Ginestra and RIPOLO.',
    ],
  },
  {
    name: 'GIRGENTINA',
    synonyms: ['Ghirghentina', 'Insolja Tal-Girgenti'],
    description:
      'The most planted Maltese variety, producing soft, light whites but under threat from Chardonnay.',
    'origin and parentage': [
      'Girgentina probably comes from the village of Girgenti in south-west Malta. Recent DNA profiling of several Maltese varieties has shown it to be a distinct variety (Giannetto et al. 2010).',
    ],
    'where its grown': [
      'Wines are typically light, low in alcohol, often light on acidity and prone to oxidation but more modern examples are clean, fresh and delicate. Estimates of the Girgentina vineyard area on the islands of Malta and Gozo in 2010 range from 90 ha (222 acres) to more than 200 ha (500 acres), mainly in the north of Malta and typically trained as unirrigated bushvines. The overall area has been in decline thanks to competition from international varieties. Both Delicata and Marsovin make varietal wines and the former make a dry, sparkling version. The variety is also used to enliven international varieties such as CHARDONNAY.',
    ],
  },
  {
    name: 'GIR\u00d2',
    synonyms: [
      'Gir\u00f2 Comune',
      'Gir\u00f2 Rosso di Spagna',
      'Girone di Spagna',
      'Zirone',
    ],
    description:
      'Cherry-flavoured Sardinian red used to make dry, sweet and fortified wines.',
    'origin and parentage': [
      'Gir\u00f2 was most probably introduced to the Italian island of Sardegna during Spanish rule (1323\u20131720), together with MAZUELO (Bovale Grande), GRACIANO (Cagnulari) and PASCALE, as suggested by its synonym Gir\u00f2 Rosso di Spagna. Comparison of the DNA profiles of Gir\u00f2 from Sardegna (De Mattia et al. 2007) and GIR\u00d3 BLANC from Mallorca (Iba\u00f1ez et al. 2003) shows that they are not colour mutations but, as suggested by Galet (2000), distinct varieties. Recent DNA parentage analysis has suggested that Gir\u00f2 may be a natural parent of ALBARANZEULI BIANCO from Sardegna (Cipriani et al. 2010).',
    ],
    'where its grown': [
      'Widespread in Sardegna, Italy, until phylloxera struck at the end of the nineteenth century, Gir\u00f2 is now cultivated in the provinces of Cagliari and Oristano. Gir\u00f2 di Cagliari DOC allows dry and sweet wines and both may be fortified. In addition, the grapes may be dried \u2013 on or off the vine \u2013 to increase the sugar concentration. Sweet wines such as by Meloni\u2019s Donna Jolanda have cherry aromas and a firm tannic structure but may sometimes be lacking in acidity.',
      'There were 552 ha (1,364 acres) of Gir\u00f2 planted in Italy in 2000.',
    ],
  },
  {
    name: 'GIR\u00d3 BLANC',
    synonyms: ['Gir\u00f2 Roz'],
    description:
      'Recently rescued, high-quality, aromatic, full-bodied Mallorcan.',
    'origin and parentage': [
      'Gir\u00f3 Blanc is indigenous to Mallorca in the Islas Baleares, Spain, where it was recently rescued from virtual extinction by Toni Gelabert, thanks to the 200 vines that Juaquin Monserrat, a local grower with faith in the variety, had propagated from the few plants that remained on the island. Mutations have given rise to considerable variation in berry colour, hence the synonym Gir\u00f3 Roz.',
      'Gir\u00f3 Blanc is not a colour mutation of GARNACHA, called Gir\u00f3 or Gironet on the island. Similarly, comparison of the DNA profiles of GIR\u00d2 from Sardegna (De Mattia et al. 2007) and Gir\u00f3 Blanc from Mallorca (Iba\u00f1ez et al. 2003) shows that they are distinct, as suggested by Galet (2000).',
    ],
    'where its grown': [
      'The new generation of aromatic wines made on the island of Mallorca, Spain, are dry and some are barrel aged. Flavours range from citrus, pineapple and mango and through to p\u00e2tisserie, with medium acidity, quite high alcohol and noticeable power for a white wine. There are now 6 ha (15 acres) of Gir\u00f3 Blanc planted on Mallorca. Toni Gelabert was the first to produce a commercial wine, in 1995, from his vines near Manacor in the west of the island, followed by Can Majoral in Algaida, closer to Palma. Wines had to be produced on an experimental basis until the variety\u2019s official authorization in 2010 after a ten-year campaign.',
    ],
  },
  {
    name: 'GLAVINU\u0160A',
    synonyms: [
      'Carnjenak',
      'Glavanju\u0161a',
      'Glavinka',
      'Okatac',
      'Okatac Crni',
    ],
    description:
      'Very rare Croatian variety sometimes useful for the Dalmatian sweet wine Pro\u0161ek.',
    'origin and parentage': [
      'Glavinu\u0161a comes from the Split region in Dalmacija (Dalmatia), the southern part of coastal Croatia.',
    ],
    'where its grown': [
      'Glavinu\u0161a was at one time considered to be one of the finest varieties in central Dalmacija, Croatia. Today it is authorized in the Dalmatinska Zagora (Dalmatian Hinterland) and Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine regions but cultivated only sparsely around Omi\u0161, Sinj and Ka\u0161tela near Split, and to a lesser extent on the islands of \u0160olta and Vis (Zduni\u0107 2005). Varietal wines are rarely made but the variety can be a useful component in Pro\u0161ek, the sweet Dalmatian wine made from dried grapes. Along with several other autochthonous varieties, Glavinu\u0161a is part of a revitalization project involving clonal selection and replanting. (Although it is still officially known as Okatac Crni, as a result of its current re-evaluation, it is now more commonly known as Glavinu\u0161a.)',
    ],
  },
  {
    name: 'GODELLO',
    synonyms: [
      'Agodello',
      'Agodenho',
      'Agudanho',
      'Agudelha',
      'Agudelho',
      'Agudello',
      'Agudelo',
      'Agudenho',
      'Berdello',
      'Godelho',
      'Godella',
      'Godenho',
      'Gouveio',
      'Ojo de Gallo',
      'Prieto Picudo Blanco',
      'Trincadente',
      'Verdelho',
      'Verdelho do D\u00e3o',
    ],
    description:
      'Very high-quality variety undergoing a revival, under several names, in north-western Iberia.',
    'origin and parentage': [
      'Godello most likely originates from the banks of the R\u00edo Sil in Galicia, north-west Spain. Its earliest mention was in the Douro in 1531 by Rui Fernandes (1531\u20132) in the \u2018Descrip\u00e7\u00e3o do terreno em roda da cidade de Lamego duas leguas\u2019 under the synonyms Agudelho and Trincadente. Comparison of the DNA profiles of Godello (Iba\u00f1ez et al. 2003; Mart\u00edn et al. 2003), Gouveio and Gouveio Roxo from the Douro (Lopes et al. 2006) shows that they are identical (Mart\u00edn et al. 2006; Vouillamoz). Gouveio Roxo is therefore a colour mutation of Gouveio, and in consequence the same variety as Godello.',
      'The vine called Verdelho in D\u00e3o has been shown to be identical to Gouveio (Lopes et al. 2006) and therefore also to Godello while the true VERDELHO planted on Madeira (also planted in Australia), which was also thought to be identical to Gouveio (Galet 2000) and Godello, has a distinct DNA profile (Vouillamoz).',
      'However, it should be noted that the reference variety named GOUVEIO REAL in Lopes et al. (2006) has a unique DNA profile and it is not known to what extent the Douro vineyards are composed of Godello (under the name Gouveio) or of Gouveio Real. Another DNA study has shown that the variety named Prieto Picudo Blanco in the Tierra de Le\u00f3n DO (Yuste et al. 2006; Santana et al. 2007) is not a colour mutation of PRIETO PICUDO profiled in other studies (Iba\u00f1ez et al. 2003; Mart\u00edn et al. 2003) but it is identical to Godello, which illustrates the wide distribution of this variety in the Iberian peninsula. DNA parentage analysis has also recently suggested that Godello is a sibling of VERDEJO from the Valladolid region (Santana et al. 2010).',
      'According to Myles et al. (2011), the reference sample of Gouveio in the United States Department of Agriculture\u2019s National Clonal Germplasm Repository, which most probably corresponds to Godello, could be identical to H\u00c1RSLEVEL\u0170 from Hungary but this can be rejected by comparison with other published DNA profiles for both GOUVEIO REAL and Godello (Vouillamoz).',
    ],
    'where its grown': [
      'Godello was on the verge of extinction in the 1970s, down to little more than several hundred vines, but thanks in large part to Horacio Fern\u00e1ndez and Luis Hidalgos\u2019 Godello Revival (REstructuring of the VIneyards of VALdeorras) project, Godello continues to gain in popularity and reputation in its homeland of Galicia, north-west Spain, where the vast majority of Spain\u2019s 1,153 ha (2,849 acres) were found in 2008 (up from 880 ha/2,175 acres in 2004), notably in the Valdeorras DO, where distinctive, high-quality varietal wines are increasingly common. This resurgence is all the more impressive given that the first varietal Godello wine was released only in the mid 1980s by Godeval, founded by Fern\u00e1ndez. It is also authorized in other Galician DOs such as Ribeiro, Ribeira Sacra and Monterrei and in the nearby Bierzo DO in Castilla y Le\u00f3n, although here it may well be part of a blend with varieties such as Treixadura (TRAJADURA; Coto de Gomoriz in Ribeiro is a good example). Fine, mineral-rich wines are produced by Godeval, Rafael Palacios, Quinta do Buble, Telmo Rodriguez and La Tapada. The best wines are intense and concentrated with sleek minerality, well suited to barrel fermentation, and are often long-lived due to the highly reductive nature of the variety.',
      'In Portugal in 2010 there were 970 ha (2,397 acres) of Gouveio, mainly in the Douro in the north, but also in D\u00e3o, a little further south, sometimes under the misleading name Verdelho. Wines are generally complex and quite full-bodied, in the Douro typically part of a rewarding blend with other local varieties such as VIOSINHO, RABIGATO, C\u00f3dega (S\u00cdRIA), C\u00d4DEGA DE LARINHO and MALVASIA FINA, although it is also sometimes used in a white port blend. Recommended Douro producers of dry table wines that have Gouveio in the mix include Quinta do Crasto, Quinta de la Rosa, Quinta do Vallado and Wine and Soul. Caves Transmontas make still and sparkling varietal wines.',
      'There were 487 ha (1,203 acres) of the red mutation Gouveio Roxo in Portugal in 2009.',
    ],
  },
  {
    name: 'GOLDBURGER',
    synonyms: ['Klosterneuburg 16-8', 'Orangeriesling'],
    description:
      'Generally unexciting pale Austrian cross used mainly for sweet wines.',
    'origin and parentage': [
      'A GRA\u0160EVINA (under the name Welschriesling) \u00d7 ORANGETRAUBE cross obtained by Fritz Zweigelt in 1922 at the Klosterneuburg research centre in Austria.',
    ],
    'where its grown': [
      'Austria has 246 ha (608 acres), the majority planted in Neusiedlersee in Burgenland but also scattered across Nieder\u00f6sterreich (Lower Austria) and the Steiermark (Styria). Wines made from these golden-skinned grapes are relatively neutral-tasting but full-bodied and high in extract with moderate acidity. They are used mainly for sweet wines.',
    ],
  },
  {
    name: 'GOLDEN MUSCAT',
    synonyms: ['New York 10303'],
    description: 'Minor American hybrid producing grapey dessert wines.',
    'origin and parentage': [
      'This is a complex Vitis labrusca and Vitis vinifera hybrid obtained in 1915 at Cornell University in New York State by crossing MUSCAT OF HAMBURG with MOORE\u2019S DIAMOND (see BRIANNA for Moore\u2019s Diamond\u2019s pedigree), released in 1927.',
    ],
    'where its grown': [
      'Golden Muscat is found to a limited extent in the eastern US, in states such as Indiana and Illinois and even in the more southerly state of Tennessee. Wines tend to be rich, grapey and usually sweet and may have a foxy (labrusca) flavour. Producers of varietal wines include Carousel in Indiana, Simon Creek in Wisconsin, and August Hill and Inheritance Valley in Illinois.',
    ],
  },
  {
    name: 'GOLDRIESLING',
    synonyms: [
      'Franzosentraube',
      'Gelbriesling',
      'Goldmuskat',
      'Riesling Dor\u00e9',
      'Risling Khativ',
      'Risling Zolotistyi',
    ],
    description:
      'Alsace cross producing light, white quaffing wine, now a speciality of Sachsen in eastern Germany.',
    'origin and parentage': [
      'Goldriesling was obtained in 1893 by Christian Oberlin in the Institut Viticole Oberlin in Colmar in Alsace, north-eastern France, by crossing RIESLING with an as yet unidentified variety. Indeed, Goldriesling was said by its breeder to be a cross between Riesling and Muscat Pr\u00e9coce de Saumur (or Courtiller Musqu\u00e9 Pr\u00e9coce), a French table grape, but this has been disproved by DNA profiling (Regner et al. 2001).',
      'Goldriesling was used to breed L\u00c9ON MILLOT,LUCIE KUHLMANN and MAR\u00c9CHAL FOCH.',
      'In Austria, a GRA\u0160EVINA (ie Welschriesling) \u00d7 M\u00dcLLER-THURGAU cross obtained by David Schantl was also and erroneously named Goldriesling but this variety has not yet been cultivated.',
    ],
    'where its grown': [
      'Goldriesling barely survives in France (less than 10 ares, or one tenth of a hectare/1,200 square yards, in 2006) but is a little more widely planted in eastern Germany, exclusively in the state of Sachsen, where there were 17 ha (42 acres) in 2008. Varietal wines are made by producers such as Karl Friedrich Aust, Joachim Lehmann, Steffen Loose, Schloss Proschwitz, Schloss Wackerbarth, Weinhaus Schuh, Jan Ulrich and by the Winzergenossenschaft Meissen. Wines are generally light, easy-drinking, dry or off-dry, gently aromatic with a range of citrus flavours, sometimes slightly herbaceous, sometimes with a hint of Muscat.',
    ],
  },
  {
    name: 'GOLUBOK',
    synonyms: ['Golubuk'],
    description: 'Red-fleshed Ukrainian hybrid also found in Russia.',
    'origin and parentage': [
      'Golubok, meaning \u2018pigeon\u2019, is a hybrid obtained in 1958 by P K Ayvazyan, E N Dokuchaeva, A P Ablyazova, M I Tulaeva, L F Meleshko and A K Samborskaya at the Tairov research centre in Odessa, Ukraine, by fertilizing the Russian hybrid SEVERNY with a mixture of pollen from several varieties, 40 Let Oktyabrya, Odessky Ranny and 1-17-54 (ALICANTE HENRI BOUSCHET \u00d7 CABERNET SAUVIGNON), all three being non-cultivated local crosses. Officially registered in 1981, there are now three clones in common use.',
    ],
    'where its grown': [
      'Golubok is planted in the Odessa and Kherson regions of Ukraine and used here and in Russia for dry, sweet and fortified wines, which are typically deeply coloured, often spicy, rich in tannins and showing flavours that range from cassis and red berries to dried fruits. In Krasnodar, southern Russia, where there were 37 ha (91 acres) in 2010, Ch\u00e2teau Le Grand Vostock blend it with several other varieties, including KRASNOSTOP ZOLOTOVSKY and CABERNET SAUVIGNON in their Terres du Sud dry red. In Rostov, closer to the Ukraine border, Vedernikov Winery blend it with TSIMLYANSKY CHERNY. The variety is also found in the Czech Republic.',
      'Golubok was among several varieties trialled at Tabor Home in Iowa, on their own roots, but all succumbed to the cold winters or crown gall within five years.',
    ],
  },
  {
    name: 'GORGOLLASA',
    synonyms: ['Gargollasa', 'Gargollosa', 'Gorgollosa'],
    description: 'Recently resurrected, high-quality, red-fruited Mallorcan.',
    'origin and parentage': [
      'Gorgollasa is indigenous to Mallorca in the Islas Baleares, Spain, where it was already mentioned in 1839 in Pollen\u00e7a and was the main variety of the Raiguer area. Recent DNA parentage analysis has suggested that Gorgollasa is a natural cross between MONASTRELL from Valencia on Spain\u2019s east coast and Heb\u00e9n, an almost extinct Andalusian variety (Garc\u00eda-Mu\u00f1oz et al. 2012). This would make it a half-sibling of MAND\u00d3N from the Bierzo region in the province of Le\u00f3n in north-west Spain and MACABEO from Catalunya, but these parentages were based on too few DNA markers (twenty) and need to be confirmed.',
    ],
    'where its grown': [
      'There are now 4 ha (10 acres) of Gorgollasa planted on the island of Mallorca, Spain, in Consell, Algaida and Manacor, and the area is increasing. It was virtually extinct until Can Ribas rescued it by planting 200 vines in 1998, propagated from the four remaining vines found near Celler Can Amer, in Inca. They planted an additional 1,800 vines in 2000 but the variety was not officially authorized until 2011, even though it is a traditional Mallorcan variety and despite strong demand for its official accreditation.',
      'Gorgollasa is well suited to barrel ageing, producing wines that are dry and elegant but with a tendency to oxidation, which is why larger (eg 500-litre) barrels are preferred. Producers include Can Majoral, Can Ribas and Toni Gelabert. Wines typically have strawberry and violet flavours, fairly low acidity but an unexpected freshness nonetheless, moderate alcohol and soft tannins. According to Araceli Servera Ribas, the variety is closer to PINOT NOIR than to other Mallorcan varieties such as MANTO NEGRO or CALLET.',
    ],
  },
  {
    name: 'GORULI MTSVANE',
    synonyms: [
      'Goruli Mcvan\u00e9',
      'Kvishkhuri',
      'Mtsvane',
      'Mtsvan\u00e9',
      'Suramula',
      'Tetrpotola',
    ],
    description:
      'Fresh, fruity, light-skinned Georgian variety used to make both still and sparkling wines.',
    'origin and parentage': [
      'Goruli Mtsvane, meaning \u2018green from Gori\u2019, referring to the colour of the berries, is an old indigenous Georgian variety from the Kartli region, named after the town of Gori close to the Greater Caucasus mountains. It has also been suggested the name means \u2018green from the hills\u2019. Goruli Mtsvane should not be confused with the more heavily planted MTSVANE KAKHURI from the Kakheti region in south-eastern Georgia; the latter is commonly referred to simply as Mtsvane but they have distinct DNA fingerprints (Vouillamoz et al. 2006).',
    ],
    'where its grown': [
      'Planted mainly in Kartli and Imereti in Georgia, Goruli Mtsvane produces high-quality wines with fresh acidity and is also widely used as a base for sparkling wines. It has the potential to make very good fortified wines but, with such limited plantings, they are rarely found today. Chateau Mukhrani make a good varietal example that has attractive flavours of honey and wild flowers. There were 221 ha (546 acres) in Georgia in 2004.',
    ],
  },
  {
    name: 'GOUAIS BLANC',
    synonyms: [
      'Belina',
      'Belina Drobna',
      'Best\u2019s no 4',
      'Blanci\u00f3',
      'Bouilleaud',
      'Enfarin\u00e9 Blanc',
      'Foirard',
      'G\u00f4t',
      'Gouais Jaune',
      'Gouget Blanc',
      'Grobe',
      'Gueuche Blanc',
      'Gw\u00e4ss',
      'Hajnos',
      'Heunisch Weiss',
      'Heinsch',
      'Heinisch',
      'Krapinska Belina',
      'Liseiret',
      'Plant de S\u00e9chex',
      'Pr\u00e9sident',
      'Preveiral',
      'Provereau Blanc',
      'Weisser Heunisch',
      'Wippacher',
    ],
    description:
      'Extremely important progenitor (with Pinot) even if almost extinct.',
    'origin and parentage': [
      'Gouais Blanc is one of Western Europe\u2019s most ancient and prolific grape varieties. It was extremely widespread in the Middle Ages under many different names that were mainly variations on Gouais (Gau, Gauche, Geuche, Goe, Goet, Gohet, Goi, Goin, Goix, Got, Gouay, Gouche, Gouest, Gueuche, etc.) and Heunisch (Heinisch, Heinsch, Heinschen, Hennische, Hensch, Heunsch, Hinschen, Hintsch, Huensch, Huntsch, Hyntsch, etc.). The earliest plausible, albeit derogatory, mention of a white-berried Heunisch appeared in Hieronymus Bock\u2019s (1539) Kreutterbuch: \u2018die grossen feiste Hynische Drauben, welche umb ihrer schnelle w\u00fcrckung willen, von etlichen scheiss Drauben genandt werden\u2019 (\u2018the big large Hynsch grapes, which are known for their rapid growth, are called shit grapes by some\u2019). The earliest reliable mention of a white-berried Gouais appeared in 1540 in the Haut-Valais, Switzerland, under the Germanized name Gewess, today Gw\u00e4ss (Vouillamoz and Moriondo 2011): \u2018blantschier und gewess, die 9 Gross gelten\u2019 (\u2018blantschier [ie PLANTSCHER] and gewess, which are worth 9 gros\u2019). Although it was most likely present in France much earlier than in Switzerland, the first reliable mention of a white-berried Gouais appeared in Charles Estienne and Jean Li\u00e9bault (1564): \u2018Sur tous complants, nul n\u2019est moins tendre \u00e0 la gel\u00e9e que le Gou\u00ebst\u2019 (\u2018among all varieties, none is less sensitive to frost than Gou\u00ebst\u2019).',
      'FRANCO-GERMAN ORIGIN Gouais Blanc is often said to originate in Eastern Europe (see Other hypotheses) but for several reasons it seems more likely that Gouais Blanc comes from the area encompassing central north-eastern France and south-western Germany:',
      '\u2013\u00a0\u00a0\u00a0Vavilov\u2019s (1926) theory says that the place of origin of a variety usually corresponds to the place where the most important biodiversity is observed, and various forms were reported in that area: Gouais Long, Gouais Rond, Gouais Jaune, Gouais Saug\u00e9, a pink-berried mutation mentioned by Guicherd in the Aube (Galet 1990), and another one mentioned by Boursiquot et al. (2004) in south-west Germany under the name Dreifarbiger Heunisch.',
      '\u2013\u00a0\u00a0\u00a0The French ampelographer Adrien Berget (1903) had also suggested northern France as the origin of Gouais Blanc and similarly named varieties: \u2018Nul de nos c\u00e9pages n\u2019a \u00e9t\u00e9 signal\u00e9 de plus longue date et ne semble plus vraiment fran\u00e7ais d\u2019origine .\u00a0.\u00a0. Les Gouais paraissent, en effet, indig\u00e8nes des provinces de l\u2019\u00cele-de-France et de la Champagne o\u00f9 .\u00a0.\u00a0. ils composaient, au moyen \u00e2ge, la majeure partie des vignobles les plus communs au Nord-Est de la France\u2019 (\u2018None of our grape varieties has been mentioned over a longer period and seems more genuinely French .\u00a0.\u00a0. Indeed, the Gouais seem to be indigenous to the provinces of \u00cele-de-France and Champagne where .\u00a0.\u00a0. they formed the major part of the ordinary vineyards in north-eastern France in the Middle Ages\u2019).',
      '\u2013\u00a0\u00a0\u00a0In the Middle Ages, not only were PINOT, SAVAGNIN and Gouais Blanc the dominant varieties in north-eastern France but they also gave birth to many offspring there (see below).',
      '\u2013\u00a0\u00a0\u00a0The name Gouais is very likely to derive from the name of one or several French villages (see below).',
      'At that time, in Germany as well as in France, the noble PINOT and SAVAGNIN (the Fr\u00e4nkisch group, see below) were assigned the best vineyards, while the feeble Gouais Blanc and ELBLING (the Heunisch group) were given the worst expositions, so that orders to pull out Gouais Blanc were given as early as 1598 and the variety had almost disappeared from France by the twentieth century (Berget 1903).',
      'PROGENIES DNA parentage analyses have revealed that Gouais Blanc is the parent of at least eighty-one distinct grape varieties in Western Europe (see the pedigree diagram in PINOT), justifying its nickname \u2018Casanova of the grapes\u2019:',
      '\u2013\u00a0\u00a0\u00a0Bowers et al. (1999) discovered that Gouais Blanc and PINOT are the natural parents of sixteen grape varieties from north-eastern France, including CHARDONNAY and GAMAY NOIR (see Pinot for the pedigree), which came as a huge surprise. These crosses probably occurred in different places and at different times.',
      '\u2013\u00a0\u00a0\u00a0Bowers et al. (2000) found that AUBIN BLANC and PETIT MESLIER from northern France are natural crosses between Gouais Blanc and SAVAGNIN, while BALZAC BLANC, COLOMBARD and MESLIER SAINT-FRAN\u00c7OIS are natural progenies of Gouais Blanc and CHENIN BLANC.',
      '\u2013\u00a0\u00a0\u00a0Five additional offspring of the Gouais Blanc \u00d7 PINOT cross were found later, along with fifty-five possible offspring of Gouais Blanc with unknown parents, the most famous being FURMINT, BLAUFR\u00c4NKISCH, ELBLING and RIESLING (Regner et al. 1998; Boursiquot et al. 2004; Vouillamoz).',
      'According to Bisson (2009), Gouais Blanc is the reference variety for the Gouais ampelographic group (see p XXVII).',
      'Gouais Blanc was recently used to breed MENNAS.',
      'ETYMOLOGY Berget (1903) has suggested two distinct origins for the word Gouais, possibly deriving from:',
      '\u2013\u00a0\u00a0\u00a0the name of a village, such as Gouaix in the Seine-et-Marne, Gouais-les-Saint-Bris in the Yonne, Gouex in the Vienne or Goix in the Ni\u00e8vre',
      '\u2013\u00a0\u00a0\u00a0the adjective gou used as a derogatory term in central and eastern dialects in France, corresponding to the poor quality of Gouais wines.',
      'Berget favours the second hypothesis but the first seems more likely because the ancient names of these villages corresponded to old names of Gouais (for example, Gouais-les-Saint-Bris was called Goys in 1408, Goez in c.1550 and Goix in 1782), and also because old variety names were often based on the characteristics of the vine or the grape but rarely on those of the wine.',
      'Ampelography and DNA profiling have established that Gouais Noir (or Heunisch Rot or Heunisch Schwarz) is not a colour mutation of Gouais Blanc (Boursiquot et al. 2004; Frei et al. 2006).',
    ],
    'where its grown': [
      'Gouais Blanc\u2019s significance to today\u2019s wine drinker lies solely in its famous offspring and it is now very hard to find this variety in the vineyard. It has been banned several times in France and only one small vineyard remains, in Marin in the Haute-Savoie, but the wine is not commercially bottled.',
      'In Italy, Preveiral is still grown in some of the alpine valleys in northern Piemonte such as Val di Susa, Val Bormida (where it is known as Liseiret) and Pinerolo. Enzo Berger makes a small but, he hopes, increasing number of bottles of varietal wine from some old Liseiret vines in Val Germanasca and the Conte Reyneri di Lagnasco makes a varietal wine in Saluzzo, south-western Piemonte.',
      'Georg Breuer in R\u00fcdesheim in the Rheingau, Germany, planted a small parcel of Heunisch Weiss and the first wine was made in 2007.',
      'In the Haut-Valais, Switzerland, Gouais Blanc has been continuously cultivated since the late Middle Ages under the name Gw\u00e4ss and it is made today by a handful of producers. Josef-Marie Chanton\u2019s very good example is made from vines carefully tended to control yields and has aromas of green apple, pear blossom and a marked yet attractive citrus acidity. A handful of other Haut-Valais producers also make a varietal Gw\u00e4ss: Daniela Kramberger, Lengen Weine and Weinkeller zum Leysche. Chambers Rosewood in Rutherglen, Australia, occasionally produce a varietal wine from their 0.6 ha (1.5 acres) of centenarian vines.',
    ],
  },
  {
    name: 'GOUGET NOIR',
    synonyms: [
      'Gouge Noir',
      'Gouget',
      'Goujet',
      'Lyonnais',
      'N\u00e9rou',
      'Neyran',
      'Neyrou',
    ],
    description: 'Rare and disappearing central French vine.',
    'origin and parentage': [
      'Gouget Noir is a rare variety of the Allier and the Cher d\u00e9partements in central France, where it was first documented in 1843 (R\u00e9zeau 1997): \u2018le N\u00e9rou ou Gouget est celui de tous les c\u00e9pages dont le raisin est le plus sucr\u00e9, le plus agr\u00e9able \u00e0 manger\u2019 (\u2018N\u00e9rou or Gouget is the sweetest, the most pleasant to eat among grape varieties\u2019). It could simply have been named after its propagator, Gouget being a common local family name. Gouget Noir is a member of the Noirien ampelographic group (see p XXVII), along with AUXERROIS, CHARDONNAY, GAMAY NOIR, GAMAY TEINTURIER DE BOUZE, GENOUILLET, MELON, PINOT and Troyen, which explains why it is sometimes erroneously considered to be a clone of Gamay Noir or Pinot. According to the official French register of varieties, DNA analysis indicates that Gouget Noir is probably a descendant of GOUAIS BLANC (see Pinot pedigree diagram).',
    ],
    'where its grown': [
      'Gouget Noir used to be the main variety in Montlu\u00e7on, Dom\u00e9rat and Huriel in the Allier d\u00e9partement of central France, where it was largely replaced by GAMAY NOIR after the phylloxera crisis towards the end of the nineteenth century. In the mid nineteenth century, when the Allier had a much more significant vineyard area (nearly 17,000 ha/42,008 acres), Gouget Noir accounted for more than half the total plantings in the region (Kelley 2010). Official French statistics for 2008 record just 10 ha (25 acres), compared with 739 ha (1,826 acres) fifty years ago. Gilles Desgranges at the Ferme des Barchauds near Huriel seems to be the only producer persevering with this variety.',
    ],
  },
  {
    name: 'GOUSTOLIDI',
    synonyms: [
      'Augoustelidi',
      'Augoustelli',
      'Avgoustolidi',
      'Bostilidas',
      'Goustoulidi',
      'Goystolidi',
      'Rompola',
      'Vostilidi',
      'Vostilida',
      'Voustolidi',
    ],
    description: 'Traditional, rustic Greek variety.',
    'origin and parentage': [
      'Goustolidi is a Greek variety whose exact origin is unknown but whose name comes from the Greek avgoustolidi, meaning \u2018August\u2019s jewel\u2019 in reference to its early ripening (Boutaris 2000). It is genetically very close but not identical to ROBOLA (Biniari and Stavrakakis 2007), even though they share the synonym Rompola, and it has a possible parent\u2013offspring relationship with the dark-skinned THEIAKO MAVRO (Boutaris 2000). Similarly and even more confusingly, it also shares many synonyms in the Vitis International Variety Catalogue with the dark-skinned AVGOUSTIATIS, of which it is not a colour mutation. On Kefaloni\u00e1, Goustolidi is known as Vostilidi.',
    ],
    'where its grown': [
      'Wines from this variety, whether they be called Goustolidi or Vostilidi, are generally rustic, robust and fairly alcoholic \u2013 as Marianna Kosmetatos of Gentilini says, \u2018traditionally part of the daily diet .\u00a0.\u00a0. to fortify the farmers in their heavy manual labour\u2019.',
      'Goustolidi is grown mainly on the island of Z\u00e1kynthos and on Kefaloni\u00e1, Greece, where it is known as Vostilidi, although it is also found to a limited extent in the Pelop\u00f3nnisos (Peloponnese) and the south west of the mainland. On Z\u00e1kynthos it contributes to the island\u2019s Verdea wine \u2013 a traditional dry white blend, now less oxidized and lower in alcohol than it used to be. Producers such as Comoutos and Callinico make a Verdea, each with a slightly different combination of varieties but generally including Goustolidi, Skiadopoulo (see FOKIANO) and Pavlos (see MALVASIA BIANCA LUNGA), among others.',
      'On Kefaloni\u00e1, there are around 30 ha (74 acres) of Vostilidi, much of it in old mixed vineyards. The must is rich in sugar, moderate in acidity (depending on the altitude). It was traditionally made and drunk locally but more recently there have been attempts to make it in a more modern style for a wider market, although it oxidizes easily, which is probably why producers such as Divino, Domaine Foivos and Melissinos blend it with other local varieties, including ROBOLA andZAKYNTHINO. Sclavus is one of the few producers to make a varietal wine: orange in colour, quite tannic, with marked flavours of apricots and considerable length. Experiments with oak maturation have shown good results at Melissinos.',
    ],
  },
  {
    name: 'GR 7',
    synonyms: ['Geneva Red 7', 'NY 34791'],
    description:
      'Minor but sturdy, winter-hardy complex American hybrid used mostly in blends.',
    'origin and parentage': [
      'A Buffalo \u00d7 BACO NOIR hybrid made in 1947 at New York State\u2019s Cornell University, in which:',
      '\u2013\u00a0\u00a0\u00a0Buffalo is a black-berried Herbert \u00d7 Watkins hybrid obtained in 1921',
      '\u2013\u00a0\u00a0\u00a0Herbert is a Carter \u00d7 SCHIAVA GROSSA cross obtained in 1865',
      '\u2013\u00a0\u00a0\u00a0Carter (also called Mammoth Globe) is an offspring of ISABELLA (Vitis vinifera \u00d7 Vitis labrusca) and another unknown parent selected by Charles Carter in Virginia',
      '\u2013\u00a0\u00a0\u00a0Watkins is a Mills \u00d7 Ontario cross obtained in 1911 (see CAYUGA WHITE for the pedigree of Ontario)',
      '\u2013\u00a0\u00a0\u00a0Mills is a MUSCAT OF HAMBURG \u00d7 Creveling cross',
      '\u2013\u00a0\u00a0\u00a0Creveling is possibly a Vitis vinifera \u00d7 Vitis labrusca hybrid.',
      'GR 7 is therefore a complex Vitis labrusca and Vitis vinifera hybrid.',
      'Fruit was first observed in 1953 and vines were propagated that year and tested under the number NY 34791 (later renamed GR 7 or Geneva Red 7). After further testing it was eventually released in 2003 (Reisch et al. 2003).',
    ],
    'where its grown': [
      'GR 7 is valued mainly for its contribution to blends; Glenora in New York State\u2019s Finger Lakes region, for example, combine it with BACO NOIR in their Bobsled Red. In warmer years, wines show cherry and red-fruit aromas, as well as some foxy labrusca notes, but tend to be herbaceous in cooler years. They have better tannin structure but lower acidity than Baco Noir but higher acidity than, for example, DORNFELDER (Reisch et al. 2003). There were 64 acres (26 ha) in New York State in 2006 and minor plantings in states such as Iowa and Pennsylvania.',
    ],
  },
  {
    name: 'GRACIANO',
    synonyms: [
      'Bovale',
      'Bovale Sardo',
      'Bovaleddu',
      'Cagliunari',
      'Cagniulari',
      'Cagnulari',
      'Caldareddhu',
      'Calda Reio',
      'Courouillade',
      'Graciana',
      'Minustellu',
      'Monastrell Menudo',
      'Monastrell Verdadero',
      'Morastell',
      'Morrastel',
      'Moristell',
      'Muristellu',
      'Tinta Mi\u00fada',
      'Tintilla de Rota',
      'Xeres',
      'and many more',
    ],
    description:
      'Low-yielding, richly coloured, perfumed variety that retains acidity and is regaining favour in Rioja.',
    'origin and parentage': [
      'Graciano has a huge number of synonyms in Spain and all around the Mediterranean, suggesting it is an old and widespread variety. Spanish researchers were astonished when they found by DNA profiling that Graciano, PARRALETA and Tintilla de Rota are one and the same variety (Borrego et al. 2001) because such a hypothesis had never previously been advanced. However, the synonymy with Parraleta was recently disproved by more thorough studies showing that the two varieties are distinct yet closely related (Montaner et al. 2004; Buhner-Zaharieva et al. 2010). In addition, DNA profiling has provided evidence that Graciano is identical to Bovale Sardo and Cagnulari from Sardegna (Nieddu et al. 2007). Indeed, several scholars since the nineteenth century have considered Cagnulari to be a clone of Bovale Sardo, and its identity with Graciano had already been suggested by the French ampelographer Truel (Galet 2000). In addition, the synonymy between Bovale Sardo, Cagnulari and Graciano is consistent with their similar ampelographic descriptions (Galet 2000) and with their clustering together in genetic analyses (Nieddu et al. 2007).',
      'On the other hand, some authors suggested that Bovale Sardo was simply a clone of Bovale Grande, which was categorically refuted by ampelographic and molecular studies, not least because Bovale Grande is identical to Carignano (MAZUELO) on Sardegna (Reale et al. 2006; Nieddu et al. 2007). Moreover, a tentative parentage analysis based on very limited DNA data suggests that Bovale Sardo could be a parent or a progeny of Bovale Grande (Reale et al. 2006). In other words, Graciano and Mazuelo could have a parent\u2013offspring relationship, but this can be rejected by the analysis of their respective DNA profiles (Vouillamoz). Nevertheless, it is clear that the cross that gave birth to Graciano or to Mazuelo originally occurred in Spain.',
      'Recent DNA kinship analysis has suggested that Graciano and MONASTRELL could be siblings (Santana et al. 2010), which might explain the existence of the synonyms Muristeddu or Muristellu for Bovale Sardo on Sardegna and of the synonyms Monastrell Menudo and Monastrell Verdadero for Graciano in Spain. However, comparison of DNA profiles from independent sources disproves any close relationship between Graciano and Monastrell (Vouillamoz). This erroneous hypothesis most probably comes from the confusion between Monastrell and Moristell: Monastrell is identical to Mourv\u00e8dre, while Moristell is identical to Graciano, and consequently with Bovale Sardo and Cagnulari. DNA parentage analysis has also suggested that Graciano is a parent of MAND\u00d3N from the Bierzo region in the province of Le\u00f3n in north-west Spain (Garc\u00eda-Mu\u00f1oz et al. 2012).',
      'After it was enfeoffed to the Aragonese, the kingdom of Sardegna remained Spanish from 1323 to 1720. In all likelihood, this was the period during which Graciano was introduced from Spain to the island, where it took the name Bovale Sardo, possibly together with Carignan (MAZUELO), which took the name Bovale di Spagna (or Bovale Grande).',
      'Graciano was used to breed MORRASTEL BOUSCHET.',
    ],
    'where its grown': [
      'The fragrant, fresh, potentially powerful and occasionally spicy Graciano was far more widely planted in Spain before phylloxera struck at the end of the nineteenth century and has been in decline since then. However, increased appreciation of its fresh and aromatic contribution to Rioja blends, and the small but growing number of varietal wines, is highlighted by total plantings in 2008 of 1,478 ha (3,650 acres). Contino\u2019s single-vineyard Graciano, Valdemar\u2019s Inspiraci\u00f3n Valdemar Graciano and Dinastia Vivanco\u2019s Colecci\u00f3n Vivanco Graciano are notable examples of varietal Graciano produced in the Rioja region. As Tintilla de Rota, it is grown to a very limited extent in Jerez and is listed as an authorized variety for Vino de la Tierra de C\u00e1diz and de C\u00f3rdoba.',
      'Similarly, in the nineteenth century under the name Morrastel it was relatively widespread in Languedoc-Roussillon, France, where it has now become a rarity.',
      'It is more fashionable in Sardegna, Italy, under the names Bovale Sardo and Cagnulari. As Bovale Sardo, it is used in blends with other local varieties to provide colour, acidity and body (eg in the Mandrolisai DOC in Nuoro and Oristano and in the Campidano di Terralba DOC in Cagliari and Oristano). The excellent Korem made by Argiolas is a blend of mainly Bovale Sardo with Carignano and Cannonau \u2013 in other words, a blend of the Spanish varieties Graciano, MAZUELO and GARNACHA. As Cagnulari, a particular clone of Bovale Sardo that was rescued from extinction a few years ago by Giocanni Cherchi from Usini, it is mainly grown in the Sassari area. Cherchi was the first to produce a 100% Cagnulari, a full-bodied and well-structured wine with intense aromas of crushed red fruits, fresh mint leaves, pepper, ink, dry but rounded tannins and an attractive aftertaste of black cherries. Not every consumer of Sardinian wines is aware that a blend of Bovale Sardo and Cagnulari (eg in an Isola dei Nuraghi IGT wine) is a blend of two clones of the same variety, namely Graciano from Rioja!',
      'In Portugal, Tinta Mi\u00fada, meaning \u2018small red one\u2019, was traditionally grown around Lisboa but can now also be found further north and in the Alentejo to the east. Total plantings in 2010 amounted to 461 ha (1,139 acres). When the summer remains dry, maturation is long, producing juice high in sugar and phenolics. In sites with cooler soils and exposed to the sea, it is particularly susceptible to rot. The best examples have good ageing potential thanks to the level of acidity and tannins. Recommended producers include Herdade dos Grous in the Alentejo and Quinta do Sanguinhal in Lisboa.',
      'In California, there are some limited plantings and an increasing number of varietals bottled, mainly from Central Valley or Sierra Foothills fruit. There were 31 ha (90 acres) of Graciana in Argentina in 2007.',
      'There are also pockets of Graciano vines, sometimes bottled as varietals, around Australia. Word of the variety\u2019s qualities is clearly spreading.',
    ],
  },
  {
    name: 'GRAISSE',
    synonyms: [
      'Blanquette Grise',
      'Chalosse',
      'Gras',
      'Gras Blanc',
      'Gr\u00e8ce Blanche',
      'Plant de Graisse',
      'Plant de Gr\u00e8ce',
      'Pr\u00e9sident',
      'Ramassou Blanc',
      'Tizourine Bou-Afrara',
    ],
    description: 'Minor south-west French variety used mostly for armagnac.',
    'origin and parentage': [
      'Graisse was first mentioned in 1783\u20134 in the Gers under its misspelt synonym Gr\u00e8ce Blanche (R\u00e9zeau 1997): \u2018Gr\u00e8ce Blanche, ce raisin est blanc sale, le grain gros, la grappe longue et serr\u00e9e\u2019 (\u2018Gr\u00e8ce Blanche, this grape is dirty white, the berry is big, the bunch is long and tight\u2019). It was named after the French word graisse, meaning \u2018fat\u2019, alluding to the oily viscosity of the must. This becomes grechu in the Gascon dialect, later wrongly interpreted as Gr\u00e8ce (French for \u2018Greece\u2019) or Plant de Gr\u00e8ce.',
      'Graisse is a member of the Gras ampelographic group (see p XXVII; Bisson 2009). DNA parentage analysis has suggested that Graisse is one of the numerous natural progenies of GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram), which explains why it is sometimes called Pr\u00e9sident, just like Gouais Blanc.',
    ],
    'where its grown': [
      'Wines tend to be neutral and acidic and better suited to armagnac production than to wine. According to Lavignac (2001), it is rarely planted today, because of its susceptibility to botrytis bunch rot, and is now found only in the Condom region of the Gers in south-west France. There were 17 ha (42 acres) remaining in 2008. Ch\u00e2teau de Neguebouc in Haut-Armagnac blend 20% Graisse with 80% Ugni Blanc (TREBBIANO TOSCANO) in the base wine for their Les Tr\u00e8s Rares bottling.',
    ],
  },
  {
    name: 'GRAND NOIR',
    synonyms: [
      'Gran Negro',
      'Grand Bouschet',
      'Grand Noir de la Calmette',
      'Gros Noir',
      'Sumo Tinto',
    ],
    description:
      'Pale red-fleshed nineteenth-century French cross with potential. Still found around the world.',
    'origin and parentage': [
      'A PETIT BOUSCHET \u00d7 ARAMON NOIR cross obtained in 1855 by Henri Bouschet at the Domaine de la Calmette in Mauguio, near Montpellier in the H\u00e9rault d\u00e9partement, in southern France. It is often mistaken for its half-sibling MORRASTEL BOUSCHET (Viala and Vermorel 1901\u201310), as in the grape collection at the University of California at Davis, for example (Vouillamoz).',
    ],
    'where its grown': [
      'Grand Noir is gradually disappearing from the Languedoc and the Cognac region (there was less than 1 ha/2.5 acres in France in 2008) but Spain had 885 ha (2,187 acres) in 2008, virtually all in Galicia in the green north west. It is treasured for its colour in DOs such as Valdeorras and Ribeira Sacra, where it has been replacing Garnacha Tintorera (ALICANTE HENRI BOUSCHET) because it seems much better adapted to the humid climate.',
      'It is also still found in southern Portugal, mainly in older vineyards, where it may be interplanted with ALICANTE HENRI BOUSCHET. There were 347 ha (857 acres) in 2009 and the variety is authorized in the Portalegre subregion of the Alentejo appellation along with Aragonez (TEMPRANILLO), TRINCADEIRA and Alicante Henri Bouschet, although there are no new plantings and newer vineyards tend to favour Alicante Henri Bouschet.',
      'A small block, planted in the early 1900s, has been found in Windsor in the Russian River Valley in California, where Grand Noir was commonly planted at one point, but they were pulled out over the years in favour of other, more fashionable varieties. It is reported to exhibit more interesting \u2013 often peppery \u2013 flavours than ALICANTE HENRI BOUSCHET, with which it is often compared.',
    ],
  },
  {
    name: 'GRAPARIOL',
    synonyms: [
      'Grappariol',
      'Rabosa Bianca',
      'Rabosina Bianca',
      'Rabosino Grappariol',
    ],
    description: 'Veneto variety recently rescued from extinction.',
    'origin and parentage': [
      'Grapariol is a rare grape from the province of Treviso in the Veneto, northern Italy, that was believed to be a white mutation of RABOSO PIAVE, but recent DNA profiling has shown no direct relationship either with Raboso Piave or with RABOSO VERONESE (Crespan et al. 2009).',
    ],
    'where its grown': [
      'Grapariol was rescued by the research centre at Conegliano in the Veneto, Italy. Thanks to the efforts of researchers and adventurous producers, Grapariol entered the national register of grape varieties in 2007 and a few hectares are now being cultivated. It produces wines with aromas of white flowers, white peaches and celery. Producers of varietal wines include Barbaran Vigne e Vini and Le Rive.',
    ],
  },
  {
    name: 'GRAS\u0102 DE COTNARI',
    synonyms: [
      'Bajor',
      'Feh\u00e9r K\u00f6v\u00e9rsz\u0151l\u0151',
      'Fej\u00e9rsz\u0151l\u0151',
      'Gras',
      'Gras\u0103',
      'Gras\u0103 de Cotnar',
      'Gras\u0103 Mare',
      'Gras\u0103 Mic\u0103',
      'Grasi',
      'Grassa',
      'K\u00f6v\u00e9rsz\u0151l\u0151',
      'Poam\u0103 Gras\u0103',
      'Resertraube',
    ],
    description:
      'Rare Romanian variety once famous for its sweet Cotnari wines, occasionally found in those of Tokaj in Hungary.',
    'origin and parentage': [
      'Although ampelographers (Galet 2000; Dejeu 2004) seem to agree that the variety known in Romania as Gras\u0103, meaning \u2018fat\u2019, or Gras\u0103 de Cotnari, and the variety known in Hungary as K\u00f6v\u00e9rsz\u0151l\u0151, meaning \u2018fat grape\u2019, are one and the same, this has not yet been confirmed by DNA profiling. In Hungary, this variety has been known since the nineteenth century but it could be much older because, according to Balassa in Varga (2008), K\u00f6v\u00e9rsz\u0151l\u0151 is identical to Fej\u00e9rsz\u0151l\u0151, an old and unidentified variety that was frequently mentioned in old variety lists in Tokaj. In Romania, it has long been cultivated in the region of Cotnari, hence Gras\u0103 de Cotnari, and at least three morphological types have been described (Dejeu 2004): Gras\u0103 Galben\u0103 (yellow berries), Gras\u0103 Verde (green berries) and Gras\u0103 Crocant\u0103 (crunchy berries). This biodiversity points to a Romanian origin for Gras\u0103, supported by a recent study showing that it is genetically close to other varieties of the historical Moldavia region (including today\u2019s Republic of Moldova and the Romanian region of Moldova) such as FETEASC\u0102 ALB\u0102 and FETEASC\u0102 REGAL\u0102 (Bodea et al. 2009).',
      'Gras\u0103 de Cotnari is sometimes said to be identical to FURMINT (Roy-Chevrier 1903b; Galet 2000) but the two varieties might simply be morphologically similar (Dejeu 2004).',
    ],
    'where its grown': [
      'There were 415 ha (1,025 acres) of Gras\u0103 de Cotnari in Romania in 2008, predominantly in the hills of Romanian Moldova in the far north east of the country, where it is used to make the sweet botrytized Cotnari wines that once rivalled Tokaji in the courts of northern Europe. It may be made as a varietal wine but its \u2018fat\u2019, full-bodied character is better balanced and fresher when blended (minimum 30%) with its traditional partners T\u0103m\u00e2ioas\u0103 Rom\u00e2neasc\u0103 (see MUSCAT BLANC \u00c0 PETITS GRAINS), FR\u00c2NCU\u015e\u0102 and FETEASC\u0102 ALB\u0102. Semi-sweet and dry styles are also found and Cotnari wines are generally less exposed to oxygen and oak than Tokaji is, producing wines with a greenish tinge even after some years in bottle.',
      'In Hungary, K\u00f6v\u00e9rsz\u0151l\u0151 had almost disappeared after phylloxera until it experienced a limited revival in the late 1990s, although there were only 37 ha (91 acres) planted in 2008, almost exclusively in Tokaj. Its high sugar levels and susceptibility to noble rot make it a useful if minor ingredient in the sweet botrytized wines of the Tokaj appellation, in which it is the fifth authorized variety. Varietal wines are full-bodied, soft, slightly aromatic and not suited to ageing, which is why producers such as Tokaj Nobilis use only a small percentage in some of their sweet FURMINT-dominated wines. Rare varietal examples are made by Dorogi, H\u00e9tsz\u0151l\u0151 (mostly Asz\u00fa wines but also late-harvest styles in some vintages) and Tokaj Nobilis.',
    ],
  },
  {
    name: 'GRA\u0160EVINA',
    synonyms: [
      'Borba',
      'Gra\u0161evina Bijela',
      'Italian Riesling',
      'La\u0161ki Rizling',
      'Olasz Rizling',
      'Olaszrizling',
      'Riesling Italico',
      'Riesling Italian',
      'Rismi',
      'Rismi',
      'Risli',
      'Rizling Vla\u0161sk\u00fd',
      'Ryzlink Vla\u0161sk\u00fd',
      'Taljanska Gra\u0161evina',
      'W\u00e4lschriesling',
      'Welschriesling',
    ],
    description:
      'Unfairly maligned variety widely planted in Central Europe that has in its time suffered in comparison to Riesling.',
    'origin and parentage': [
      'The origin of this old and widely disseminated variety has long been the subject of speculation (see Other hypotheses below) and it goes under many different synonyms, mainly but not exclusively in Central and Eastern Europe. The name Gra\u0161evina (used in Croatia) was chosen as prime name in this book because:',
      '\u2013\u00a0\u00a0\u00a0It is the most widespread white grape variety in Croatia.',
      '\u2013\u00a0\u00a0\u00a0The names La\u0161ki Rizling, Olasz Rizling, Riesling Italico, Ryzling Vla\u0161sk\u00fd and Welschriesling confusingly suggest a link to the white RIESLING from Germany even though they are genetically and organoleptically quite distinct.',
      '\u2013\u00a0\u00a0\u00a0The name Welschriesling (welsch meaning \u2018foreigner\u2019) suggests that the variety was introduced from abroad to German-speaking countries such as Austria, where it is widely grown.',
      '\u2013\u00a0\u00a0\u00a0Gra\u0161evina was not introduced to northern Italy until the nineteenth century, where it became known as Riesling Italico (Cal\u00f2 et al. 2006).',
      'For all the above reasons, Croatia or the Danube basin appear to be the most likely places of origin of Gra\u0161evina.',
      'Most surprisingly, the DNA profile of the Spanish variety Borba cultivated in Ribera del Guadiana in south-west Spain that was published by Mart\u00edn et al. (2003) matches Gra\u0161evina (Vouillamoz).',
      'Gra\u0161evina was used (under different synonyms) to breed several cultivated varieties, including BUSSANELLO, FLAVIS, GOLDBURGER, ITALICA and\u015eARB\u0102.',
    ],
    'where its grown': [
      'The country having given its name to several of the variety\u2019s synonyms, it is not surprising that there is a considerable area of this variety planted in Italy: 2,030 ha (5,016 acres) in 2000, according to the agricultural census, predominantly in Lombardia in the north west. Recommended producers include C\u00e0 del G\u00e8, Cantina di Casteggio, Martilde, Pieropan of Soave and Mazzolino of Oltrep\u00f2 Pavese, although in general Italian examples are light, crisp and dry but not long on character.',
      'Spain had just 8 ha (20 acres) of Borba in 2008, mostly in the province of C\u00e1ceres in Extremadura in the south west but also to a limited extent to the east in Castilla-La Mancha.',
      'Austria recorded 3,597 ha (8,888 acres) of Welschriesling in 2009, representing 7.8% of the total vineyard area and almost double the area devoted to RIESLING. The finest wines, sometimes blended with CHARDONNAY or other, locally grown varieties, are from late-harvested or botrytized grapes on the shores of the Neusiedlersee in Burgenland, where nearly half of Austria\u2019s Welschriesling is planted \u2013 although they lack the longevity of the finest Riesling TBAs. Schr\u00f6ck & Kracher\u2019s Greiner Welschriesling is a very good late-harvest example. The other main expression of this variety is less dramatic: light, dry or off-dry wines for easy, early drinking. It is also used for Austrian Sekt. Recommended producers include Feiler-Artinger, Kracher and Velich.',
      'Under various different names, it is a widely planted workhorse white in much of Eastern Europe, with some impressive wines, potentially both powerful and refined, where the variety is taken seriously and the variety\u2019s productivity is curbed by the site and/or by work in the vineyard. When yields are high, the wines tend to be harsh or neutral, sometimes mildly floral, needing some residual sugar to make them more palatable. There were 2,482 ha (6,133 acres) of La\u0161ki Rizling in Slovenia in 2009, the great majority of vines in \u0160tajerska Slovenija (Slovenian Styria) in the north east. Recommended producers include Dveri-Pax, Jam\u0161ek 1887, Kupljen, Marof, P&F Jeruzalem Ormo\u017e, Pullus and Valdhuber for dry wines and RA-VinO and KZ Metlika for sweet wines.',
      'Recommended in every wine-growing region of Kontinentalna Hrvatska (Continental Croatia) but not in those of Primorska Hrvatska (Coastal Croatia), Gra\u0161evina is the most widely planted variety in Croatia, with nearly a quarter of the vineyard area, around 8,405 ha (20,769 acres) in 2009. There is a very wide range of styles in terms of both sweetness and winemaking, from dry or off-dry and simple to rich and sweet, sometimes botrytized, via lightly oaked and elegant. The best wines generally come from Slavonija in the east, notably from the Kutjevo, Slavonski Brod and Djakovo subregions. Gra\u0161evina is less aromatic than RIESLING but this is less of a disadvantage in intensely sweet wines (like Austria\u2019s Trockenbeerenauslesen). Recommended producers include Ad\u017ei\u0107 (particularly their lightly oaked version), Belje, Ivan Enjingi, Vlado Krauthaker, Kutjevo co-operative and Mihalj. The variety is also found in neighbouring Vojvodina, Serbia, and in Albania.',
      'Ryzling Vla\u0161sk\u00fd comprised more than 16% (3,132 ha/7,739 acres) of Slovakia\u2019s vineyard area in 2009, second only to GR\u00dcNER VELTLINER among white wine varieties. The Czech Republic reported 1,247 ha (3,081 acres) of Ryzlink Vla\u0161sk\u00fd in 2009 \u2013 almost exactly the same area as that dedicated to Ryzlink R\u00fdnsk\u00fd (RIESLING). Recommended producers for dry styles include Michlovsk\u00b4y (Rakvice), Mikrosv\u00edn (Mikulov) and Z\u00e1meck\u00e9 Vinarske Bzenec (Bzenec), as well as Galant, Silinek and Volarik (all in Mikulov), Reisten (Pavlov) and Sebesta (Brezi). For sweet wines, Marcincak (Mikulov), Oldrich Drapal Sr and Perina (Klentice).',
      'Known in Hungary as Olasz Rizling, it is the most widely planted white variety (4,909 ha/12,130 acres in 2008), grown throughout the country, in gardens and extensive vineyards, but most important in the regions around Lake Balaton (Roh\u00e1ly et al. 2003). There are some very good examples of dry wines but also sweet, late-harvest versions and the best tend to come from volcanic sites such as Balaton-Felvid\u00e9k and Soml\u00f3 north of the lake. Recommended producers include Bussay, Figula, J\u00e1sdi, Ott\u00f3 L\u00e9gli, Scheller, Szeremley around Lake Balaton; Lajor Tak\u00e1cs in Soml\u00f3; and Lajos G\u00e1l and Vilmos Thummerer in Eger. Wines sometimes have a bitter-almond aroma.',
      'Romania\u2019s Riesling Italian vineyards, concentrated in Transilvania, Moldova, Muntenia and Oltenia, totalled a substantial 7,061 ha (17,448 acres) in 2008. Bulgaria\u2019s Italian Riesling is grown mostly in the Varna and Shumen districts in the north east.',
      'There are limited plantings in both Canada and China.',
    ],
  },
  {
    name: 'GRECHETTO DI ORVIETO',
    synonyms: ['Grechetto', 'Grechetto Bianco', 'Grechetto Spoletino'],
    description: 'Characterful and versatile Umbrian.',
    'origin and parentage': [
      'The names Greco and Grechetto have been given to several distinct southern Italian varieties used to produce sweet wines, with more than a nod to the traditional sweet-wine styles of ancient Greece (Costacurta et al. 2004), but this is not necessarily an indication that these varieties are Greek in origin.',
      'Ampelographic and DNA studies have established that Grechetto di Orvieto and Grechetto di Todi, a synonym for PIGNOLETTO, are two distinct varieties (Filippetti et al. 1999). In addition, parentage studies suggested a parent\u2013offspring relationship between them (Costacurta et al. 2004), which might explain why they were previously considered to be clonal variations of the same variety.',
    ],
    'where its grown': [
      'Speciality of Umbria, central Italy. Orvieto DOC, Umbria\u2019s best-known white wine, is a blend of TREBBIANO TOSCANO and VERDELLO with 15\u201325% Grechetto (or other local varieties), and 15\u201340% is allowed in Torgiano DOC, another Umbrian Trebbiano-dominated blend. Grechetto is authorized or required in many Umbrian DOC wines, including several varietal versions such as Assisi, Colli Martani, Colli del Trasimeno and Colli Perugini in the province of Perugia and Colli Amerini in the province of Terni. However, since only the Colli Martani regulations distinguish between Grechetto di Orvieto and Grechetto di Todi, it is often not possible to know which variety is intended.',
      'Grechetto is also authorized in various DOCs in the Marche, Lazio and Toscana, though again it is not clear which variety is intended; in the last it may also be a useful component in Vin Santo thanks to its resistance to downy mildew. It is occasionally included in the blend for Umbrian sparkling wines, with a minimum of 50% required in Colli Altotiberini. Antinori\u2019s top white Cervaro uses Grechetto as a support to CHARDONNAY, an indication of the growing recognition of its quality potential. Lungarotti\u2019s varietal Grechetto (IGT Umbria) is a good, straightforward example of this interesting variety, with citrus and white fruit flavours as well as a slightly herbal, almond character and a delicately creamy texture.',
      'There were 1,204 ha (2,975 acres) planted in Italy in 2000, according to the agricultural census. However, the census, like the DOC regulations, does not distinguish between Grechetto di Orvieto and Grechetto di Todi.',
    ],
  },
  {
    name: 'GRECO',
    synonyms: [
      'Asprinio',
      'Greco del Vesuvio',
      'Greco della Torre',
      'Greco di Napoli',
      'Greco di Tufo',
      'Grieco di Castelvenere',
    ],
    description: 'Potentially fine, aromatic but notably firm Campanian.',
    'origin and parentage': [
      'The name Greco has been applied to many varieties used to make sweet wines like those of Ancient Greece (Costacurta et al. 2004) but this is not necessarily an indication that these varieties are Greek in origin. Greco is distinct from GRECO BIANCO in Calabria and also from several other varieties known as Greco Something (Costacurta et al. 2004). DNA profiling recently demonstrated that Greco, or Greco di Tufo, is unexpectedly identical to Asprinio, already mentioned in the seventeenth century, and is genetically close to ALEATICO (Costantini et al. 2005).',
    ],
    'where its grown': [
      'Mostly cultivated and recommended in Campania in southern Italy, especially in the provinces of Avellino, Benevento, Napoli and Salerno but also beyond Campania to the east in Puglia (provinces of Bari, Foggia and Taranto), and north of Campania in the province of Campobasso in Molise, in Lazio (provinces of Latina and Viterbo), in Toscana (Grosseto, Lucca and Massa-Carrara) and as far north as La Spezia in Liguria. In the Capri DOC, up to 50% Greco is permitted.',
      'The best-known and most widely admired varietal Greco is Greco di Tufo DOCG but varietal Greco is also permitted in various Campanian DOCs such as Irpinia, Sannio and Sant\u2019Agata de\u2019 Goti. In addition, it is authorized in several blended DOCs in Campania and beyond, eg in Gravina DOC, which straddles Puglia and Basilicata and where the higher altitude enhances the aromatic character of Greco. The best wines are full-bodied with some minerality and only delicately aromatic with a note of apricot kernels, or more herbal and racy, as in Mastroberadino\u2019s examples. Other notable producers of Greco di Tufo include Feudi di San Gregorio, Pietracupa, Terredora, Vadiaperti and Villa Raiano.',
      'According to the Italian census of 2000, total plantings of Greco were 941 ha (2,325 acres). It also records another 414 ha (1,023 acres) of Asprinio because the discovery that the varieties are identical is so recent.',
    ],
  },
  {
    name: 'GRECO BIANCO',
    synonyms: [
      'Greco Bianco di Cosenza',
      'Greco Bianco di Rogliano',
      'Pecorello Bianco',
    ],
    description: 'Rare Calabrian used to make dessert wines.',
    'origin and parentage': [
      'The names Greco and Grechetto have been given to several distinct southern Italian varieties used to produce sweet wines, probably only with reference to the traditional sweet-wine styles of ancient Greece (Costacurta et al. 2004) rather than because they are Greek in origin. Greco Bianco is distinct from GRECO in Campania and from Greco di Bianco, named after a Calabrian coastal village which was once part of the village of Gerace (hence the synonym), which has been shown to be identical to MALVASIA DI LIPARI (Crespan, Cabello et al. 2006). It is also distinct from Greco Bianco di Cir\u00f2, which is identical to GUARDAVALLE (Costacurta et al. 2004). Pecorello Bianco of Calabria was also recently found to be identical to Greco Bianco (Schneider, Raimondi, Grando et al. 2008).',
    ],
    'where its grown': [
      'Since several distinct varieties are named Greco Bianco in Calabria, southern Italy, sometimes with an additional epithet (eg di Cir\u00f2 or di Consenza), it is impossible to have a clear picture of the distribution of the Greco Bianco dicussed in this entry. However, the Greco Bianco in the area around Cosenza and Rogliano definitely corresponds to the variety described here. Under the name Pecorello Bianco, it is included in the Savuto and Donnici DOCs in Calabria. Total plantings of 686 ha (1,695 acres) were recorded in Italy in 2000.',
    ],
  },
  {
    name: 'GRECO NERO',
    synonyms: [
      'Collective name for different varieties',
      'so synonyms are misleading.',
    ],
    description:
      'A group of different Calabrians confusingly sharing one name.',
    'origin and parentage': [
      'The name Greco Nero has been used in Calabria, southern Italy, for several distinct varieties differing in their morphology and distribution. Recent ampelographic and DNA studies detected at least five different black-berried varieties named Greco Nero in Calabria (Schneider, Raimondi, Grando et al. 2008):',
      '\u2013\u00a0\u00a0\u00a0Greco Nero found in the Cir\u00f2 area, identical to a vine known as Nerello found near Lamezia Terme',
      '\u2013\u00a0\u00a0\u00a0Greco found in Scilla, identical to CASTIGLIONE',
      '\u2013\u00a0\u00a0\u00a0Greco Nero found in the Lametino, identical to MAGLIOCCO DOLCE',
      '\u2013\u00a0\u00a0\u00a0Greco Nero found in Verbicaro and Scalea',
      '\u2013\u00a0\u00a0\u00a0Greco Nero found in Sibari in north-eastern Calabria.',
      'The last two varieties have distinct morphologies and DNA profiles, and must be considered as two distinct varieties: GRECO NERO DI VERBICARO and GRECO NERO DI SIBARI.',
    ],
    'where its grown': [
      'Greco Nero is recommended in the whole of Calabria, southern Italy, but it is usually impossible to know which of the five or more varieties mentioned above goes into any one wine.',
      'The Italian agricultural census of 2000 records 3,041 ha (7,514 acres) of \u2018Greco Nero\u2019 but makes no further distinction.',
    ],
  },
  {
    name: 'GRIGNOLINO',
    synonyms: ['Barbesino', 'Barbisino', 'Berbesino'],
    description:
      'Waning, distinctively pale, light, local (ancient) Monferrato speciality.',
    'origin and parentage': [
      'A document dated 7 November 1249 records the fact that the church of Sant\u2019Evasio (in Casale Monferrato in Piemonte) lent a piece of land to a certain Guglielmo Crova on condition that it be planted \u2018bonis vitibus berbexinis\u2019 (\u2018with good vines of berbexinis\u2019; Nada Patrone 1988). Although some authors have identified Berbexinis with BARBERA, most ampelographers agree that Berbexinis was in fact Grignolino, because Berbesino is still used as a synonym for Grignolino in the Monferrato area. This document is therefore most likely to be the earliest mention of Grignolino.',
      'Even if there is any doubt that Grignolino and Berbexinis are one and the same, then Grignolino still has medieval credentials, mentioned in Almese in the province of Torino in 1337\u20138 in a document from the abbey of San Giusto di Susa, which refers to the production of \u2018sextarios XII vini albi tam muscatelli quam grignolerii\u2019 (Comba and Dal Verme 1990).',
      'The name Grignolino could derive from the Asti dialect word grign\u00f2le, meaning \u2018pips\u2019, because the berries of Grignolino usually have more pips (3\u20134 times more) than many other varieties.',
    ],
    'where its grown': [
      'Once cultivated throughout Piemonte in north-west Italy, Grignolino is today grown mainly in the Monferrato hills that straddle the provinces of Asti and Alessandria, and to a much more limited extent in the province of Cuneo. The most important DOCs are Grignolino del Monferrato Casalese, Grignolino d\u2019Asti and Piemonte Grignolino. Up to 10% FREISA is allowed in the first two and the best wines tend to be found in the first. It is also authorized in small or moderate amounts in other Piemonte DOCs such as Barbera del Monferrato and Colli Tortonesi.',
      'Varietal wines are generally pale red and light in alcohol (11\u201312%) with a herbal/floral alpine scent and tangy acidity. They are intended for early drinking but they can be quite tannic thanks to the high ratio of seeds. Recommended producers include: Bricco Mondalino, Marchesi Incisa della Rocchetta, Agostina Pavia, Carlo Querello, Rovero, San Sebastiano, Cantine Sant\u2019Agata and Luigi Spertino.',
      'There were 1,336 ha (3,301 acres) in Italy in 2000 according to the agricultural census.',
      'In California, Heitz of the Napa Valley persist with their Grignolino plantings, making a ros\u00e9 and sometimes a port-style wine from them.',
    ],
  },
  {
    name: 'GRILLO',
    synonyms: ['Ariddu', 'Riddu', 'Rossese Bianco'],
    description:
      'Increasingly popular high-quality, full-bodied western Sicilian white.',
    'origin and parentage': [
      'Grillo is a classic Sicilian variety, although its earliest mention is in 1873 (Bica 2007). This is probably because the variety is not that old: DNA studies have shown that Grillo is the offspring of a natural cross between CATARRATTO BIANCO and MUSCAT OF ALEXANDRIA, the latter known as Zibibbo on Sicilia (Di Vecchi Staraz, This et al. 2007). See the MUSCAT entry for the complete pedigree. Recent DNA profiling has surprisingly revealed that the Rossese Bianco grown in Riomaggiore in Liguria, along the north-western Italian coast (not to be confused with ROSSESE BIANCO from Piemonte), is identical to Grillo (Torello Marinoni, Raimondi, Ruffa et al. 2009).',
    ],
    'where its grown': [
      'After phylloxera reached Sicilia in Italy in the early 1880s, Grillo was widely planted to replace its parent CATARRATTO BIANCO but thereafter the planted area declined in favour of more productive varieties. Today Grillo is cultivated mainly in the province of Trapani in the north west of the island, where it is one of the main components of Sicilia\u2019s famous fortified wine Marsala DOC, though the higher-yielding Catarratto Bianco and INZOLIA and are now more often favoured. It is also cultivated in the provinces of Agrigento, Palermo, Messina, Caltanissetta and Siracusa.',
      'Varietal Grillo is catered for in DOCs such as Alcamo, Contea di Sclafani, Delia Nivolelli, Erice, Monreale and Salaparuta or it may well be classified as IGT Sicilia. The variety also features in blended DOCs such as Contessa Entellina, Mamertino di Milazzo and Menfi. The wines are full-bodied and slightly herbaceous or floral but not generally as aromatic as INZOLIA. Recommended producers include Calatrasi, Cusumano, Fondo Antico, Foraci, Rallo and Spadafora.',
      'There were 1,808 hectares (4,468 acres) in Italy in 2000 but in Sicilia alone there were 4,118 ha (10,176 acres) by 2008.',
    ],
  },
  {
    name: 'GRINGET',
    synonyms: ['Gringet Gras', 'Gros Gringet', 'Petit Gringet'],
    description:
      'Rare, lightly floral and high-quality variety exclusive to Savoie.',
    'origin and parentage': [
      'Gringet is an old variety of the Vall\u00e9e de l\u2019Arve in the Haute-Savoie in eastern France, where it was first mentioned in a manuscript written by Pierre-Joseph Decret in 1766 (Durand 1905). In his Topographie de tous les vignobles connus, Andr\u00e9 Jullien (1832) wrote about the apparently curious properties of Gringet from Ayze (now a Savoie cru): \u2018on fait, pr\u00e8s de cet endroit, un vin blanc nomm\u00e9 gringet, du nom du plant qui le produit. Ce vin a la singuli\u00e8re propri\u00e9t\u00e9 de ne pas enivrer tant qu\u2019on ne quitte pas la table; mais, aussit\u00f4t que l\u2019on prend l\u2019air, on perd l\u2019usage de ses jambes, et l\u2019on est forc\u00e9 de s\u2019asseoir\u2019 (\u2018we make near this place a white wine named gringet, after the name of the variety that produces it. This wine has the unique property of not causing inebriation so long as one does not leave the table; but as soon as one takes the fresh air, one loses the use of one\u2019s legs, and one is forced to sit down\u2019).',
      'The exact origin of the name Gringet is unknown, but the variety might have inherited the family name of the grower who propagated it since Gringet was a common local patronym (R\u00e9zeau 1997).',
      'Gringet has long been considered a clone of SAVAGNIN (Durand 1905; Galet 1990, 2000) but DNA profiling of a sample from Le Feu vineyard at Domaine Belluard in Ayze has established irrefutably that they are distinct varieties and that Gringet does not match any other known variety (Vouillamoz). In addition, DNA parentage analysis shows that Gringet is not genetically related to Savagnin and strongly suggests that MOLETTE, another rare Savoie variety, is a natural Gringet \u00d7 GOUAIS BLANC cross (Vouillamoz).',
    ],
    'where its grown': [
      'Gringet used to be cultivated in several places in the Vall\u00e9e de l\u2019Arve in Haute-Savoie (Ayze, Bonneville, Contamine, C\u00f4te d\u2019Hyot, Faucigny, Marignier, Thiez), France, and, since the wines tended to be light and lively, were often used for sparkling wine. Today it is almost exclusively found in the commune of Ayze, where Domaine Belluard is currently the only producer of commercial varietal wines. French official statistics record 26 ha (64 acres) in 2008 and, according to Belluard, there are 22 ha (54 acres) in Ayze. Belluard makes two still versions, Les Alpes and Le Feu, the latter a full-bodied single-vineyard wine with complex aromas of dried apple peel, wisteria and yellow plums and a rounded texture but with significant acidity, both elegant and persistent. In addition he makes two sparkling wines: an Ayse (sic) M\u00e9thode Traditionelle and the Ayse (sic) Mont-Blanc Brut Z\u00e9ro.',
    ],
  },
  {
    name: 'GRISA NERA',
    synonyms: ['Grisa', 'Grisa di Cumiana'],
    description: 'Old and rare Piemontese.',
    'origin and parentage': [
      'Grisa Nera is an old variety of the Pinerolo area south west of Torino in Piemonte, where it is still cultivated, often interplanted with other local varieties. DNA parentage analysis has clearly established a parent\u2013offspring relationship between Grisa Nera and AVARENGO, another old Piemontese variety, thus anchoring the origin of both varieties in this area (Anna Schneider and Jos\u00e9 Vouillamoz, unpublished data). It should not be confused with Grisa, found here and there in the Alto Canavese (Schneider and Mannini 2006).',
    ],
    'where its grown': [
      'Grisa Nera is found throughout the foothills and alpine vine-growing regions in the province of Torino in Piemonte, north-west Italy, especially west of Pinerolo and in the lower part of the Val di Susa, and to a lesser extent in the province of Cuneo. It is usually blended with other local varieties such as NERETTA CUNEESE, PLASSA or BARBERA.',
    ],
  },
  {
    name: 'GRK',
    synonyms: [
      'Gark',
      'Grk Bijeli',
      'Grk Kor\u010dulanski',
      'Grk Mali',
      'Grk Veli',
      'Lumbarajski Grk',
    ],
    description:
      'Rare, high-quality and highly localized island white from Croatia.',
    'origin and parentage': [
      'Grk is an old variety from the island of Kor\u010dula whose name most likely derives from gark, meaning \u2018bitter\u2019, referring to the taste of the must or the wine. DNA parentage analysis has shown that Grk, Crljenak Crni (no longer cultivated), PLAVINA and VRANAC all have a parent\u2013offspring relationship with TRIBIDRAG (Maleti\u0107 et al. 2004), also called Primitivo and Zinfandel, and are therefore grandparents or half-siblings of PLAVAC MALI (see Tribidrag for the full pedigree).',
    ],
    'where its grown': [
      'Grk is found in Croatia, mostly around Lumbarda on the island of Kor\u010dula within the Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine region, although it is also planted further south on the islands of Mljet and \u0160ipan and in the coastal area near Dubrovnik. Wines are full-bodied, generous and well structured but retain well-balanced acidity \u2013 unusual in this area \u2013 and have an attractive bitterness on the finish. Grk has the potential to make high-quality dry and sweet wines. Recommended producers include Bartul Batisti\u0107, Ivica-Fiduli\u0107 Batisti\u0107, Vinarija Bire, Stipan Cebalo, Branimir Cebalo and Milina.',
    ],
  },
  {
    name: 'GROLLEAU NOIR',
    synonyms: [
      'Bourdal\u00e8s',
      'Gamay de Ch\u00e2tillon',
      'Gamay Groslot',
      'Grolleau de Cinq-Mars',
      'Grolleau de Tourraine',
      'Grolleau des Mah\u00e9',
      'Grolleau de Tours',
      'Groslot',
      'Gros-Lot',
      'Groslot de Vall\u00e8res',
      'Groslot Noir',
      'Moinard',
      'Pineau de Saumur',
    ],
    description:
      'Widely planted though little recognized dark-skinned Loire variety that makes a lot of ros\u00e9 and some supple reds.',
    'origin and parentage': [
      'According to Bouchard (1901b), this variety was planted in 1810 by a certain M Lothion, a farmer at the Grande Gaudri\u00e8re in the commune of Mazi\u00e8res-de-Touraine, near Cinq-Mars-la-Pile, north of the Loire and west of Tours, in the Indre-et-Loire, France, hence its synonym Grolleau de Cinq-Mars. After a few years, this vine became large enough to cover a pear tree and cuttings were dispersed around the neighbourhood and then quickly reached the left bank of the Loire (eg the communes of Vall\u00e8res and Louerre). The name Grolleau supposedly derives from the old French grolle meaning \u2018black crow\u2019, referring to the colour of the berries.',
      'DNA parentage analysis has suggested that Grolleau Noir is one of the numerous natural progenies of GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram). Grolleau Gris (pink-berried) and Grolleau Blanc are colour mutations of Grolleau Noir.',
    ],
    'where its grown': [
      'Grolleau is the third most cultivated dark-skinned variety in the Loire after CABERNET FRANC and GAMAY NOIR and is mainly used to produce off-dry ros\u00e9 wines in Anjou and drier styles in Touraine. Yields must be controlled if the aim is to produce the relatively pale, light-bodied, supple, expressive, red-fruited red wines such as those made by St\u00e9phane Bernadeau, Domaine Chahut & Prodiges, Domaine Cousin-Leduc and Domaine de Ch\u00e2teau-Gaillard. Domaine Cousin-Leduc also produce a rare varietal Grolleau Gris \u2013 a ros\u00e9.',
      'There were 2,350 ha (5,807 acres) of Grolleau Noir in France\u2019s greater Loire region in 2009, mostly west of Tours but especially in the Maine-et-Loire. This marks a serious decline for Grolleau in the last fifty or so years, down from 11,409 ha (28,192 acres) in 1958, though, thanks largely to its high yields, it is still responsible for one sixth of the Val de Loire\u2019s total production and the decline seems to have slowed in the last few years. A maximum of 25% is allowed in Coteaux du Loir ros\u00e9 but Ros\u00e9 d\u2019Anjou is usually made predominantly from Grolleau Noir, often blended with GAMAY NOIR. It is also a permitted variety in Saumur Brut and Ros\u00e9 de Loire. The latter may also include Grolleau Gris.',
      'Although the area of Grolleau Gris has halved from 908 ha (2,244 acres) in 2000 down to 454 ha (1,122 acres) in 2009, it is still way up on the 19 ha (47 acres) recorded in 1958. Domaine James et Nicolas Paget in Azay-le-Rideau make a good traditional-method sparkling Touraine ros\u00e9.',
    ],
  },
  {
    name: 'GROPPELLO DI MOCASINA',
    synonyms: ['Groppello di San Stefano', 'Groppello Moliner'],
    description: 'Minor northern Italian whose wine is generally blended.',
    'origin and parentage': [
      'The name Groppello was given to several distinct varieties in northern Italy (see etymology in GROPPELLO GENTILE).',
      'Recent ampelographic studies at the Conegliano research centre have established that Groppello di Mocasina, from Lombardia, is distinct from Groppello dei Berici (not commercially cultivated), although the results of DNA profiling have not yet been published. Genetic studies have shown that Groppello di Mocasina, named after a village west of Lago di Garda, is distinct from GROPPELLO DI REV\u00d2, GROPPELLO GENTILE and PIGNOLA VALTELLINESE (called Groppello di Breganze in the Veneto), and that it is identical to Groppello di San Stefano (Costantini et al. 2001; Cancellier et al. 2009). The DNA profiles of Groppello di Mocasina and Groppello Gentile suggest that they are closely related.',
    ],
    'where its grown': [
      'It is grown mainly in the Lago di Garda area of Lombardia, northern Italy, and is often blended with GROPPELLO GENTILE, SANGIOVESE, MARZEMINO or BARBERA. In particular, it is blended with Groppello Gentile in the Riviera del Garda Bresciano DOC.',
    ],
  },
  {
    name: 'GROPPELLO DI REV\u00d2',
    synonyms: ['Gropel', 'Gropel Nones', 'Groppello Anaune'],
    description:
      'Very minor northern Italian that was far more popular in the eighteenth and nineteenth centuries.',
    'origin and parentage': [
      'The name Groppello was given to several distinct varieties in northern Italy (see etymology in GROPPELLO GENTILE).',
      'Recent ampelographic studies at the Conegliano research centre have established that Groppello di Rev\u00f2 is distinct from Groppello dei Berici (not commercially cultivated) found in the Veneto, although DNA profiling has not yet been published. Genetic studies have shown that Groppello di Rev\u00f2 is distinct from GROPPELLO DI MOCASINA, GROPPELLO GENTILE and PIGNOLA VALTELLINESE (called Groppello di Breganze in the Veneto; Costantini et al. 2001; Cancellier et al. 2009). More recently, DNA parentage tests unexpectedly showed that Groppello di Rev\u00f2 is a natural progeny of R\u00c8ZE, an old variety from the Valais in Switzerland (Vouillamoz, Schneider et al. 2007). Since R\u00e8ze is also a parent of the white Trentino variety NOSIOLA, Groppello di Rev\u00f2 and Nosiola are half-siblings, which might explain why Nosiola is sometimes called Groppello Bianco and why all three are said to be descendants of the Roman grape Raetica.',
    ],
    'where its grown': [
      'Groppello di Rev\u00f2 is cultivated exclusively in the Val di Non in Trentino in northern Italy, in the communes of Cagn\u00f2, Cloz, Rev\u00f2 and Romallo, where it was the most important variety in the eighteenth and nineteenth centuries. In the early twentieth century, its cultivation declined dramatically but, since the end of the twentieth century, interest in this autochthonous variety has been revived by local growers and academics. Groppello di Rev\u00f2 has recently been authorized for use in IGT Vigneti delle Dolomiti.',
      'Varietal wines are produced by Cantina Rotaliana and by Augusto Zadra (El Zeremia).',
    ],
  },
  {
    name: 'GROPPELLO GENTILE',
    synonyms: ['Groppella', 'Groppello Comune', 'Groppellone'],
    description: 'Ancient, usually blended, northern Italian red.',
    'origin and parentage': [
      'The name Groppello has been given to several distinct varieties in northern Italy. The etymology probably comes from grop or gropo, meaning \u2018knot\u2019 in the local Veneto dialect, perhaps because the grape cluster looks like a knot.',
      'Groppello Gentile is the oldest and most widespread of the varieties named Groppello. It was mentioned in the sixteenth century in the area of Brescia and Bergamo as a fine grape whose wine was exported to Germany (Bacci 1596). Recent ampelographic studies at the Conegliano research centre have shown that Groppello Gentile is distinct from Groppello dei Berici (not commercially cultivated), although DNA profiling has not been published yet. In addition, genetic studies have established that Groppello Gentile is distinct from GROPPELLO DI REV\u00d2, GROPPELLO DI MOCASINA and PIGNOLA VALTELLINESE (called Groppello di Breganze in the Veneto), and that it is identical to Groppellone (Costantini et al. 2001; Cancellier et al. 2009). The DNA profiles of Groppello Gentile and Groppello di Mocasina suggest that they are closely related.',
    ],
    'where its grown': [
      'Mainly cultivated in Lombardia (provinces of Brescia and Bergamo) in northern Italy. Often blended with GROPPELLO DI MOCASINA, SANGIOVESE, MARZEMINO or BARBERA. In particular, it is blended with Groppello di Mocasina in the Rivera del Garda Bresciano DOC.',
    ],
  },
  {
    name: 'GROS MANSENG',
    synonyms: [
      'Gros Mansenc',
      'Izkiriot Haundi',
      'Iskiriota Zuri Handia',
      'Manseng Gros Blanc',
    ],
    description:
      'Full-flavoured, thick-skinned white speciality of the far south west of France.',
    'origin and parentage': [
      'The earliest mention of a Manseng was recorded in 1562 in Juran\u00e7on in a text in Occitan: \u2018vinhe mansengue\u2019. However, it is not known whether this was referring to Gros Manseng or PETIT MANSENG, and the distinction between the two varieties was not recorded until 1783\u20134: \u2018Manseng gros, blanc .\u00a0.\u00a0. couleur dor\u00e9e, bon gout quoy que piquant. Manseng petit, blanc .\u00a0.\u00a0. le gout plus piquant que tout autres\u2019 (\u2018Gros Manseng, white .\u00a0.\u00a0. golden colour, good taste although spicy. Petit Manseng, white .\u00a0.\u00a0. the taste is spicier than any others\u2019). The name Manseng could derive from mans, meaning \u2018mansion\u2019.',
      'According to Galet (1990), Gros Manseng is morphologically close to PETIT MANSENG, and Bisson (2009) groups them in the Mansien ampelographic group (see p XXVII). DNA parentage analysis and reconstruction of the most likely pedigree (see PINOT pedigree diagram) strongly suggests that Gros Manseng is a progeny of Petit Manseng and a grandchild of SAVAGNIN (Vouillamoz; Myles et al. 2011).',
    ],
    'where its grown': [
      'Gros Manseng has tended to be the main grape variety for the dry whites in Juran\u00e7on in the south',
      'west of France and in 2009 there was almost three times as much Gros Manseng planted in France as the smaller-berried PETIT MANSENG (2,919 ha/7,213 acres v 1,019 ha/2,518 acres). However, Gros Manseng is starting to give way in the vineyard to Petit Manseng thanks to the increasing popularity of the sweet Juran\u00e7on wines in which Petit generally plays a more significant role (Strang 2009), although there are exceptions such as Ch\u00e2teau Joly\u2019s \u00c9piphanie, a vendanges tardives (sic) harvested as late as January, which is 100% Gros Manseng, and Alain Brumont\u2019s sweet Vin de Pays des C\u00f4tes de Gascogne. The majority of the vines are planted in the Gers.',
      'Gros Manseng is permitted as a principal variety in other appellations of the south west such as Pacherenc du Vic-Bilh, B\u00e9arn and the rare Iroul\u00e9guy whites but is also recommended further afield in d\u00e9partements such as the Landes and the Aude. It is sometimes used for the mistelle Floc de Gascogne. The yields are more generous though the wines may be less elegant and rich than those produced from PETIT MANSENG but they are not without power. Flavours of citrus fruit, apricot and light spice, occasionally floral notes, are intensified in late-harvest wines. Those thick skins have to be handled carefully in the winery to avoid astringent or bitter notes.',
      'Particularly good examples of varietal wines are produced by Domaine Cauhap\u00e9: the dry Chant des Vignes and S\u00e8ve d\u2019Automne and the sweet Ballet d\u2019Octobre. Other recommended producers, mostly for blends of Gros and PETIT MANSENG and other local varieties, include Domaine Bellegarde, Clos Lapeyre and Producteurs Plaimont. Blending with SAUVIGNON BLANC is not uncommon, especially in Vin de Pays des C\u00f4tes de Gascogne.',
      'Over the border in northern Spain, Gros Manseng is authorized for two of the three appellations in the Pa\u00eds Vasco (Basque Country): Arabako Txakolina/Chacol\u00ed de \u00c1lava and Bizkaiko Txakolina/Chacol\u00ed de Viscaia (a principal variety in the former and a complementary one in the latter). However, total plantings in Spain in 2008 amounted to no more than 11 ha (27 acres).',
      'Gros Manseng is also planted to a very limited extent in Uruguay (three vineyards totalling 0.7 ha/1.7 acres in 2009), where Familia Deicas produce a noble sweet wine from a blend of Gros and PETIT MANSENG. Plantings in Brazil\u2019s Rio Grande do Sul are even more limited at 0.29 ha (0.7 acres) in 2007.',
    ],
  },
  {
    name: 'GROS VERDOT',
    synonyms: ['H\u00e8re', 'Plant des Palus', 'Verdot Colon'],
    description: 'Very minor dark-skinned variety inferior to Petit Verdot.',
    'origin and parentage': [
      'Gros Verdot and PETIT VERDOT are usually said to come from the Gironde in south-west France (see Petit Verdot for details and etymology) but recent ampelographic and genetic research has placed them in an ampelographic group together with Ardonnet and Petit Verdot Faux that most likely comes from the Pyr\u00e9n\u00e9es-Atlantiques, further south, possibly the result of the domestication of local wild grapevines (Bordenave et al. 2007).',
    ],
    'where its grown': [
      'In France, Gros Verdot has been banned from plantings in the Gironde since 1946 (Lafforgue 1947) and is not included in the official French register of varieties, even though it was an important variety in the Queyries vineyards of nineteenth-century Bordeaux (Bordenave et al. 2007), what is now the Bastide area of the city. It lacks the interest and concentration of its namesake PETIT VERDOT and has almost disappeared.',
      'Gros Verdot is one of the lesser varieties permitted in California\u2019s Meritage. There is considerable confusion between Gros Verdot and CABERNET PFEFFER in California. DNA tests at Kenneth Volk Vineyards in San Benito have shown that what was thought to be the latter was in fact the former, even though their fresh, cherry-fruited and peppery wine is still labelled Cabernet Pfeffer.',
      'Although it is sometimes difficult to be confident as to which variety is referred to in official statistics in South America, as elsewhere, there are apparently 7 ha (17 acres) of \u2018Verdot\u2019 (said to be Gros Verdot) in Chile compared with the 257 ha (635 acres) given over to PETIT VERDOT. Argentina\u2019s 455 ha (1,124 acres) of \u2018Verdot\u2019, on the other hand, are said to be all Petit Verdot.',
    ],
  },
  {
    name: 'GROSSA',
    synonyms: ['Tinta Grossa'],
    description: 'Alentejo variety used to improve red blends.',
    'origin and parentage': [
      'Grossa, literally \u2018big\u2019, probably comes from the Alentejo in southern Portugal. The synonym Tinta Grossa is also given to TINTA BARROCA in the Douro, and to MARUFO, but their DNA profiles in Almadanim et al. (2004, 2007) are distinct.',
    ],
    'where its grown': [
      'Grossa is authorized in various DOC and regional wines in the Lisboa, Alentejo and Tejo regions of Portugal, although it is found almost exclusively in the Vidigueira subregion of the Alentejo. Wines are deeply coloured, fresh and rounded with exotic aromas of sour cherries, vanilla sweetness and blackberry fruits, and are generally used to add complexity, longevity and elegance to a blend. Paulo Loreano, for example, uses it in this way but also produces a rare varietal wine. There were 182 ha (450 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'GRUAJA',
    synonyms: [
      'Crovaja',
      'Cruaia',
      'Cruara',
      'Cruvaio',
      'Gruaia',
      'Gruaio',
      'Gruajo',
    ],
    description:
      'Rescued from extinction in northern Italy in the early 1990s but still very rare.',
    'origin and parentage': [
      'Gruaja is an old variety of the Vicenza and Breganze areas of the Veneto in northern Italy, mentioned as long ago as the eighteenth century. A recent DNA study suggested a close relationship between Gruaja and NEGRARA VERONESE, a rare variety from Breganze (Salmaso et al. 2008).',
    ],
    'where its grown': [
      'Gruaja was almost extinct until it was rescued in 1992 by Severina Cancellier and fellow researchers at the Conegliano research centre in the Veneto, northern Italy, together with three producers (Avogadro, Miotti and Vitacchio) who still had some old Gruaja vines. There are now even some new plantings. The wines have aromas of maraschino cherries and forest fruits, are well structured and finish slightly bitter. Firmino Miotti in Breganze produce a varietal version.',
    ],
  },
  {
    name: 'GR\u00dcNER VELTLINER',
    synonyms: [
      'Grauer Veltliner',
      'Gr\u00fcn Muskateller',
      'Veltliner',
      'Veltliner Grau',
      'Veltliner Gr\u00fcn',
      'Veltlinske Zelen\u00e9',
      'Veltlinsk\u00e9 Zelen\u00e9',
      'Weissgipfler',
      'Zeleni Veltinec',
      'Z\u00f6ld Veltlini',
    ],
    description:
      'Fashionable, versatile, top-quality Austrian white wine variety.',
    'origin and parentage': [
      'The earliest mention of Gr\u00fcner Veltliner dates back to the eighteenth century. Despite its name, Gr\u00fcner Veltliner has no link with Valtellina. Recent DNA profiling unexpectedly uncovered the parentage of Gr\u00fcner Veltliner: it is a natural cross between Traminer (SAVAGNIN) and, very likely, an obscure vine found in St Georgen am Leithagebirge near Eisenstadt (Regner 2007). This old vine has a unique DNA profile and is currently the only known survivor of this variety. It used to be called Gr\u00fcn Muskateller in St Georgen, but in order to avoid confusion with the heterogeneous group referred to as MUSCAT, it was simply named \u2018St Georgener\u2019. The vine, attacked by vandals in February 2011 and now a protected natural monument, is currently being propagated and the first wine is expected to be made in a few years.',
      'Going further back up the family tree, Gr\u00fcner Veltliner is either a grandchild or a half-sibling of PINOT, because SAVAGNIN and Pinot have a parent\u2013offspring relationship.',
      'Given its parentage, Gr\u00fcner Veltliner does not belong to the \u2018Veltliner\u2019 family, which includes ROTER VELTLINER, FR\u00dcHROTER VELTLINER, NEUBURGER and ROTGIPFLER (Sefc, Regner et al. 1998), even if it is a half-sibling of Rotgipfler (they have SAVAGNIN as common parent).',
      'DNA profiling has shown that, contrary to common belief, Grauer Veltliner (or Veltliner Grau) is not a distinct variety but just a colour mutation of Gr\u00fcner Veltiner, with greyish berries (Regner et al. 1996).',
    ],
    'where its grown': [
      'Plantings of Gr\u00fcner Veltliner in France are so far strictly experimental but Italy has one small area where the variety grows. Virtually any vine called Veltliner high up in the Valle Isarco of the Alto Adige in the far north of the country is Gr\u00fcner Veltliner, which has all but completely replaced the unrelated FR\u00dcHROTER VELTLINER there.',
      'In Germany, the historical Hansenwein of Plochingen (on the outskirts of Stuttgart) is made with Gr\u00fcner Veltliner, and not, as is sometimes suggested, with the true Hans or Hansen Rot, an old variety that is no longer in cultivation.',
      'It is Austria that is inextricably associated with Gr\u00fcner Veltliner (sometimes referred to simply as Gr\u00fcner or GV and more recently marketed as gru-vee, particularly in the US). It is the country\u2019s most widely planted variety, occupying 17,034 ha (42,092 acres), almost one third of the total vineyard area. It is most at home, and produces many of its best wines, along the Donau (Danube), where the vines lap up the heat funnelled west along the valley from the Pannonian Plain, but there are also substantial plantings throughout the northern half of Austria\u2019s wine-growing region, notably in the Weinviertel north of the river (8,529 ha/21,076 acres) but also in Burgenland, particularly in Neusiedlersee (1,272 ha/3,143 acres) and Neusiedlersee-H\u00fcgelland (882 ha/2,179 acres), the two main wine regions around the lake of the same name.',
      'When yields are high (up to 100 hl/ha or 5.7 tonnes/acre are possible), the wines are crisp, fresh and inoffensive, as in large swathes of the Weinviertel. But in the hands of the best growers and on the best sites in the Wachau, Kamptal, Kremstal, Weinviertel and Donauland, they can be stunningly concentrated and ageworthy: typically dry and full-bodied with a peppery perfume and firm, minerally citrus and sometimes spicy flavours, becoming more burgundian with age. Wines produced on the plain are often more peachy and fruit-dominated. It\u2019s a versatile variety, capable of producing anything from base wine for Sekt via light, medium-bodied peppery wines for everyday refreshment through to concentrated and powerful expressions of the steep, river-hugging sites. Some sweet Auslese and Trockenbeerenauslese bottlings can also be found.',
      'Gr\u00fcner Veltliner is currently recognized in four Austrian DACs, the classification used to indicate quality and typicality for a combination of grape and region: Kremstal, Kamptal, Traisental and Weinviertel.',
      'Best producers include Br\u00fcndlmayer, Schloss Gobelsburg, Hirsch, Jurschitsch and Loimer in Kamptal; Felsner, Malat, Sepp Moser, Nigl, Salomon, Dr Unger and Winzer Krems in Kremstal; Alzinger, Dom\u00e4ne Wachau, Donabaum, Hirtzberger, Jamek, Knoll, F X Pichler, Rudi Pichler, Prager and Schmelz in Wachau; Graf Hardegg and Pfaffl in Weinviertel; and Fritsch and Ott in Donauland. Mainhard Forstreiter\u2019s Tabor Gr\u00fcner Veltliner is made from 150-year-old vines in Krems-Hollenburg just east of Krems, probably the oldest vineyard in Austria.',
      'Gr\u00fcner Veltliner has a long history across the border in the Czech Republic, where there are now 1,713 ha (4,233 acres) of Veltlinsk\u00e9 Zelen\u00e9. The variety has even greater significance in Slovakia, where Veltlinske Zelen\u00e9 is the most widely planted white variety, with 3,805 ha (9,402 acres) and nearly 20% of the total vineyard area. In Hungary, there are 1,439 ha (3,556 acres) of Z\u00f6ld Veltlini, scattered across the country but with the greatest concentrations in Kuns\u00e1g and Tolna in the south, D\u00e9l-Balaton in the west, and M\u00e1tra further north and east. It is also found in Bulgaria.',
      'This internationally fashionable variety is beginning to be planted in the US with Oregon and then California leading the way, so far on a modest scale. Gr\u00fcner Veltliner is also found to a very limited extent in Washington State and in British Columbia, western Canada.',
      'In Australia the first varietal Gr\u00fcner Veltliner was Lark Hill of Canberra\u2019s 2009. Hahndorf Hill in the Adelaide Hills bottled a 2010. There are some new plantings in New Zealand, where Coopers Creek released the first commercial bottling, from Gisborne fruit, in 2008. It has also been planted at Mount Edward and Quartz Reef in Central Otago and by Yealands and a few growers in Marlborough.',
      'Thanks to its presence on restaurant wine lists around the world, it seems likely to continue to make an increasing impact in the world\u2019s vineyards.',
    ],
  },
  {
    name: 'GUARDAVALLE',
    synonyms: [
      'Greco Bianco di Cir\u00f2',
      'Greco del Cirotano',
      'Montonico di Rogliano',
      'Uva da Passito',
      'Uva Greca',
      'Vardavalli',
    ],
    description: 'Makes a minor, nutty white in Italy\u2019s deep south.',
    'origin and parentage': [
      'Guardavalle probably comes from and is named after the eponymous town on the Ionian coast, between Locri and Soverato in the province of Catanzaro, Calabria. DNA profiling has established that Greco Bianco di Cir\u00f2, which is distinct from GRECO and GRECO BIANCO, is in fact identical to Guardavalle (Costacurta et al. 2004). Recent ampelographic studies have shown that local Calabrians such as Montonico di Rogliano, Uva da Passito and Uva Greca are also identical to Guardavalle (Schneider, Raimondi, Grando et al. 2008).',
    ],
    'where its grown': [
      'Under the name Guardavalle, it is recommended in the province of Reggio Calabria on Italy\u2019s \u2018toe\u2019 but is planted only to a limited extent, mainly around the communes of Bianco, Locride and Bivongi, whereas under the name Greco Bianco di Cir\u00f2 it is one of the most widespread white wine varieties of Calabria. Guardavalle is authorized in the blend for Bivongi DOC. Wines have a nutty character and may be slightly astringent. Santa Venere\u2019s Vescovada is a varietal wine made from organically grown grapes and sold as IGT Calabria.',
      'There were 177 ha (437 acres) of Guardavalle in Italy in 2000, according to the agricultural census, but this is unlikely to include the area planted to Greco Bianco di Cir\u00f2, which the census fails to isolate.',
    ],
  },
  {
    name: 'GUEUCHE NOIR',
    synonyms: [
      'Foirard',
      'Foirard Noir',
      'Plant d\u2019Arlay',
      'Plant de Treffort',
    ],
    description:
      'Virtually extinct, late-ripening variety from eastern France, related to Gouais Blanc.',
    'origin and parentage': [
      'Gueuche Noir is an almost extinct variety from the Franche-Comt\u00e9 in eastern France, where it has been cultivated since the eighteenth century. It is a member of the Gouais ampelographic group (see p XXVII; Bisson 2009), which explains why GOUAIS BLANC is sometimes called Gueuche Blanc, and why Gueuche Noir is a likely offspring of Gouais Blanc (Boursiquot et al. 2004; see PINOT pedigree diagram). In addition, Guicherd considers Gueuche Noire to be similar to ENFARIN\u00c9 NOIR from the Aube (Galet 1990).',
    ],
    'where its grown': [
      '\u00c9tienne Thiebaud, who established Domaine des Cavarodes in the Jura, eastern France, at the age of twenty, has rescued an old vineyard in Liesle, south west of Besan\u00e7on in the Doubs region, and produces a red Vin de Pays de Franche-Comt\u00e9 which is a blend of PINOT NOIR, TROUSSEAU, PINOT MEUNIER, GAMAY NOIR, POULSARD, Gueuche Noir, Argant (G\u00c4NSF\u00dcSSER), BLAUER PORTUGIESER, ENFARIN\u00c9 NOIR and M\u00c9ZY, all from interplanted vines aged 50\u2013100 years. Domaine Ganevat in Rotalier in the Jura have replanted a small area of Gueuche Noir (and also what they refer to as Gueuche Blanc) and include it in an early-drinking red blend.',
      'According to Galet (2000), wines made solely from Gueuche Noir are generally rather hard and acidic because it is too cool in the Franche-Comt\u00e9 for the variety to ripen fully. Gueuche Noir is not yet included in the official French register of varieties.',
    ],
  },
  {
    name: 'GUTENBORNER',
    synonyms: ['Geisenheim 17-52'],
    description: 'Minor German cross with very limited plantings in the UK.',
    'origin and parentage': [
      'A M\u00dcLLER-THURGAU \u00d7 Bicane (under the name Chasselas Napoleon) cross bred by Heinrich Birk at the Geisenheim research centre in 1928, where Bicane is a French wine and table grape that is no longer cultivated.',
    ],
    'where its grown': [
      'Thanks to its ability to ripen in cool climates, this variety has had more success in sheltered sites in England than in Germany, where it is not authorized for commercial planting. However, plantings are still no more than a few hectares. Wines tend to be rather neutral, sometimes mildly grapey.',
    ],
  },
  {
    name: 'HAMASHARA',
    synonyms: ['Aronova Boroda', 'Gamashara'],
    description: 'Early-ripening Azeri red wine grape.',
    'origin and parentage': [
      'Hamashara, the historical name of the village of Has\u0131ll\u0131, is a variety local to the Jalilabad region in south-east Azerbaijan. It is said to be the result of natural pollination of wild vines whose offspring were cultivated from seeds by the local people. If this were the case, then Hamashara would not be a single grape variety but a group of several distinct and closely related varieties.',
    ],
    'where its grown': [
      'Jalilabad Winery in Azerbaijan make dry, dessert and fortified wines from this variety, whose produce is naturally low in alcohol and high in acidity.',
    ],
  },
  {
    name: 'H\u00c1RSLEVEL\u0170',
    synonyms: [
      'Budai F\u00e9her',
      'Feuille de Tilleul',
      'Harslevleue',
      'Harzevelu',
      'Lindenbl\u00e4ttrige',
      'Lipolist',
      'Lipovina',
    ],
    description:
      'Aromatic, descriptively named Hungarian variety used for both sweet and dry whites.',
    'origin and parentage': [
      'H\u00e1rslevel\u0171, meaning \u2018linden leaf\u2019, in reference to the wine\u2019s aroma, is a variety from Hungary, first mentioned there in 1744. Using thirteen DNA markers, Cal\u00f2, Costacurta et al. (2008) had posited a parent\u2013offspring relationship with FURMINT, which was confirmed with a total of sixty markers (Vouillamoz), making H\u00e1rslevel\u0171 an offspring of Furmint (see that entry) and a sibling of PLANTSCHER, an old Swiss variety of Hungarian origin, confirming the variety\u2019s Hungarian roots.',
      'H\u00e1rslevel\u0171 should not be confused with EZERJ\u00d3 even though they share the synonym Budai Feh\u00e9r (\u2018white of Buda\u2019).',
      'H\u00e1rslevel\u0171 was used to breed EZERF\u00dcRT\u0170, KABAR and ZEF\u00cdR.',
    ],
    'where its grown': [
      'Wines are aromatic and delicately spicy, adding perfume to the more fiery FURMINT in sweet Tokaji wines. The wines are softer than Furmint and mature more quickly. This century has seen an increasing number of very successful varietal late-harvest and Asz\u00fa varietal wines (Roh\u00e1ly et al. 2003). Dry and off-dry varietal wines are made at all quality levels but the best are viscous and full-bodied and have a distinctive flavour of linden blossom and linden honey.',
      'In Austria\u2019s Burgenland, Josef Umathum has planted a small parcel of Lindenbl\u00e4ttrige, planning to expand it, and makes a dry varietal wine but also sees the potential for botrytized sweet wines. The variety was officially authorized in Austria in 2011.',
      'H\u00e1rslevel\u0171 is grown throughout Hungary but particularly in Tokaj in the far north east (about 65% of the total), Eger (notably in the Debr\u0151 district) slightly further west and Soml\u00f3 in the far west, north of Lake Balaton, where wines tend to be more mineral and less aromatic. Wines from Vill\u00e1ny in the south are softer and more perfumed. Recommended producers include Gy\u0151rgyov\u00e1cs and Holl\u00f3v\u00e1r in Soml\u00f3, St Andrea in Eger, Ber\u00e9s and Zolt\u00e1n Demeter in Tokaj and Bock and Plg\u00e1r in Vill\u00e1ny. There were 1,612 ha (3,983 acres) in Hungary in 2008.',
      'H\u00e1rslevel\u0171 is also grown over the border in Slovakia; and in Romania (20 ha/49 acres in 2008), where it is used mainly for dry wines.',
      'In South Africa, there were 75 ha (185 acres) in 2008 scattered across the wine regions but with the biggest area in Robertson. Lammershoek in the Swartland make a dry varietal wine and have high hopes for this variety, picked early to retain acidity, from their 1 ha (2.5 acres) of 42-year-old bushvines. Elsewhere the fruit tends to be swallowed up in blends.',
    ],
  },
  {
    name: 'HASANDEDE',
    synonyms: ['Ahmet Bey', 'A\u015feri', 'Hasan Dede', 'Hasandede Beyazi'],
    description: 'Humdrum Turkish variety found east of the capital, Ankara.',
    'origin and parentage': [
      'Hasandede, literally \u2018grandfather Hasan\u2019, probably originates from the K\u0131r\u0131kkale province east of Ankara in central Turkey. DNA profiling has shown that A\u015feri from Nev\u015fehir in Kapadokya is identical to Hasandede, and DNA parentage analysis has suggested a possible parent\u2013offspring relationship between Hasandede and Sungurlu, another variety from the K\u0131r\u0131kkale region that is not commercially cultivated (Vouillamoz et al. 2006). Confusingly, Sungurlu is sometimes used as a synonym for Hasandede.',
    ],
    'where its grown': [
      'Grown in the K\u0131r\u0131kkale province east of Ankara in central Turkey, mostly for the table and to produce pekmez, concentrated grape juice, though it is also used to make very basic-quality white wine.',
    ],
  },
  {
    name: 'HELFENSTEINER',
    synonyms: ['Blauer Weinsberger', 'Weinsberg S 5332'],
    description: 'German cross eclipsed by its offspring Dornfelder.',
    'origin and parentage': [
      'A PINOT NOIR PR\u00c9COCE \u00d7 SCHIAVA GROSSA cross created in 1931 by August Herold. This variety was named after the ruined castle of Helfenstein close to Geislingen an der Steige, south east of Stuttgart. Helfensteiner is now more famous as a parent of the highly successful DORNFELDER than in its own right.',
    ],
    'where its grown': [
      'Whereas DORNFELDER has been widely planted within (and to a lesser extent without) Germany, there are just 19 ha (47 acres) of Helfensteiner, exclusively in southern Germany. In W\u00fcrttemberg, Dieterich, Jaillet and Wangler make varietal wines, the first producing a Weissherbst (pink) version. Kern (Baden) also bottles a varietal Helfensteiner.',
    ],
  },
  {
    name: 'HELIOS',
    synonyms: ['Freiburg 242-73'],
    description: 'Little-known, very complex, disease-resistant German hybrid.',
    'origin and parentage': [
      'A complex hybrid obtained in 1973 by Norbert Becker at the Freiburg research centre in Baden-W\u00fcrttemberg, southern Germany, by crossing MERZLING \u00d7 Fr 986-60, where:',
      '\u2013\u00a0\u00a0\u00a0MERZLING is itself a complex hybrid (see PRIOR for the full pedigree)',
      '\u2013\u00a0\u00a0\u00a0Fr 986-60 is a Seyve-Villard 12-481 \u00d7 M\u00dcLLER-THURGAU hybrid',
      '\u2013\u00a0\u00a0\u00a0Seyve-Villard 12-481 is a Seibel 6468 \u00d7 Seibel 6746 hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 6468 is a Seibel 4614 \u00d7 Seibel 3011 hybrid, and the parents of Seibel 6746 were not recorded',
      '\u2013\u00a0\u00a0\u00a0Seibel 4614 is a Seibel 752 \u00d7 (Vivarais \u00d7 Vitis berlandieri) hybrid (see PRIOR for the complete Vivarais pedigree)',
      '\u2013\u00a0\u00a0\u00a0Seibel 752 is a Panse Pr\u00e9coce (Sicilien) \u00d7 Clairette Dor\u00e9e Ganzin hybrid',
      '\u2013\u00a0\u00a0\u00a0Panse Pr\u00e9coce is a Bicane \u00d7 PASCAL BLANC cross, the former being a non-cultivated French wine and table grape variety',
      '\u2013\u00a0\u00a0\u00a0Clairette Dor\u00e9e Ganzin is a Ganzin 60 \u00d7 BOURBOULENC hybrid',
      '\u2013\u00a0\u00a0\u00a0Ganzin 60 is an ARAMON NOIR \u00d7 Vitis rupestris Ganzin hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 3011 is a Bayard \u00d7 Afus Ali (ie Dattier de Beyrouth) hybrid, where Bayard is an Emily \u00d7 Vitis rupestris hybrid and Emily is a Vitis vinifera subsp. vinifera \u00d7 Vitis labrusca hybrid',
      '\u2013\u00a0\u00a0\u00a0Afus Ali is a table grape variety from Lebanon.',
      'Helios was named after the Greek God of the sun. Helios is also the name of an unrelated table grape grown in Slovakia.',
    ],
    'where its grown': [
      'Helios was authorized for commercial production in Germany in 2005 and plantings are still insufficient for it to feature in official German vineyard statistics, although varietal wines are made by, for example, Dr Benz, Feuerstein and Isele in Baden in the south. They can be rather nondescript and are sometimes improved by a few grams of residual sugar. Acidity levels are similar to M\u00dcLLER-THURGAU although must weights can be higher.',
      'A few experimental vines (0.23 ha/0.57 acres) are also planted in Switzerland. Also found in Belgium at the Domaine Viticole du Chenoy.',
    ],
  },
  {
    name: 'HERBEMONT',
    synonyms: [
      'Black Herbemont',
      'Bottsi',
      'Brown French',
      'Dunn',
      'Herbemon',
      'Herbemont\u2019s Madeira',
      'Hunt',
      'Kay\u2019s Seedling',
      'Madeira',
      'Mcknee',
      'Neal Grape',
      'Neil Grape',
      'Warren',
      'Warrenton',
      'White Herbemont',
    ],
    description:
      'Almost extinct American hybrid with a complex and disputed parentage.',
    'origin and parentage': [
      'The exact origin of this variety is obscure and disputed. Thomas Volney Munson (1843\u20131903), a horticulturist and grape breeder from Texas, considered Herbemont and JACQUEZ (under the synonym Lenoir) to be members of Vitis bourquiniana, a doubtful botanical group of varieties that are closely related to the native Vitis aestivalis (Munson 1909). Bailey (1906) described these bourquiniana varieties as \u2018ameliorated forms of the native summer-grape Vitis aestivalis\u2019, and thought that some of them \u2018may be hybrids of Vitis aestivalis and the European wine-grape\u2019, the last hypothesis being supported by Galet (1988). It is likely that the European varieties came from Madeira.',
      'Nicholas Herbemont (1771\u20131839), a French-born grower from Columbia, South Carolina, collected one such bourquiniana variety in 1798 in the plantation of Judge John Huger in Columbia, South Carolina, and called it Madeira. Herbemont was so active in its promotion and propagation that it was later known as Herbemont\u2019s Madeira, and then simply Herbemont. According to Thomas McCall\u2019s record, this variety was brought to Georgia before the American Revolution by Henry Hunt in St Paul\u2019s parish north of Augusta, where it was known as the Hunt grape. In the early nineteenth century it spread to Warren County, Georgia, where it was then simply known as the Warren grape (Bonner 2009). These separate mentions clearly indicate that the variety had been cultivated and propagated long before Herbemont found it in Columbia.',
    ],
    'where its grown': [
      'In the humid climate of southern Brazil the variety found favour because of its disease resistance and there were still 846 ha (2,091 acres) in Rio Grande do Sul in 2007. Salton, for example, produce a ros\u00e9 that blends Herbemont with Isabel (ISABELLA) and SEYVAL BLANC. There are also still a few vines in the C\u00e9vennes, even though the variety is not authorized in France, and some of the fruit contributes to the Jacquez-dominated Cuv\u00e9e des Vignes d\u2019Antan made by the Association M\u00e9moire de la Vigne, which cannot legally be sold in the EU since almost all hybrids are banned there.',
    ],
  },
  {
    name: 'HEROLDREBE',
    synonyms: ['Weinsberg S 130'],
    description:
      'Unexceptional, productive but inconveniently late-ripening German cross.',
    'origin and parentage': [
      'A BLAUER PORTUGIESER \u00d7 BLAUFR\u00c4NKISCH cross bred by and named after August Herold at the Weinsberg research centre in Baden-W\u00fcrttemberg in southern Germany in 1929. Since Blaufr\u00e4nkisch is a progeny of GOUAIS BLANC, Heroldrebe is its grandchild. Heroldrebe was used to breed DORNFELDER. This parentage has been confirmed by DNA profiling (Myles et al. 2011).',
    ],
    'where its grown': [
      'There are 155 ha (383 acres) in Germany, mainly in the Pfalz, where it is used predominantly for ros\u00e9, but it\u2019s also planted in W\u00fcrttemberg and Rheinhessen. The wines are reasonably light but can have noticeable tannins and overall wine quality is lower than for its parent BLAUER PORTUGIESER. It is more famous as a parent of DORNFELDER than in its own right. Varietal wines are made by Anselmann in the Pfalz and Bernecker further south in Baden.',
    ],
  },
  {
    name: 'HETERA',
    synonyms: ['HTCVCB 4/13'],
    description:
      'Recently authorized Slovakian cross making promising sweet wines.',
    'origin and parentage': [
      'Hetera is a GEW\u00dcRZTRAMINER \u00d7 ROTER VELTLINER cross (with Slovakian names: Tram\u00edn \u010cerven\u00fd \u00d7 Veltl\u00ednske \u010cerven\u00e9) obtained in 1965 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia, and authorized in 2011.',
    ],
    'where its grown': [
      'Although total plantings in Slovakia are still small (5 ha/12 acres in 2011), Hetera seems best suited to the Ju\u017enoslovensk\u00e1 and Malokarpatsk\u00e1 wine regions in the south west and to botrytized sweet wines. \u017ditavsk\u00e9 Vinice make a varietal wine in this style.',
    ],
  },
  {
    name: 'HIBERNAL',
    synonyms: ['Geisenheim 322-58'],
    description:
      'German hybrid planted more widely in the Czech Republic than at home.',
    'origin and parentage': [
      'Complex self-crossed hybrid obtained in 1944 by Helmut Becker at the Geisenheim research centre in the Rheingau, Germany, by crossing CHANCELLOR \u00d7 RIESLING with itself (see PRIOR for the complete pedigree of Chancellor).',
    ],
    'where its grown': [
      'Very little Hibernal is planted in Germany (1 ha/2.5 acres) but there are 17 ha (42 acres) \u2013 and rising \u2013 in the Czech Republic, where it was registered in 2004, mainly in Morava in the south east. Knab in Baden, southern Germany, produce a rare varietal wine. It is also found to a very limited extent in Canada.',
      'Wines are fresh and fruity, in some years rather like SCHEUREBE, and the fruit purity and high levels of extract of the best, fully ripe examples reveal its RIESLING parent. When unripe, it can have a slightly foxy note but this is not usually pronounced. Owing to its complex family history, it is not 100% Vitis vinifera but it is registered as such in the German official list of varieties and is therefore authorized for making quality wine because the wine generally tastes like a vinifera product. This is the case for several other German hybrids such as RONDO.',
    ],
  },
  {
    name: 'HITZKIRCHER',
    synonyms: ['Grosse Blaue M\u00f6rsch', 'Hitzkirchener', 'Hitzkirchler'],
    description:
      'Swiss rarity found only in the village after which it was named.',
    'origin and parentage': [
      'Hitzkircher is an extremely rare variety from the village of Hitzkirch in the canton of Luzern in central Switzerland. It has often been confused with Briegler, which was recently shown to be identical to BONDOLA from Ticino (Frei et al. 2006; Vouillamoz, Frei and Arnold 2008). This makes more sense when we know from DNA parentage analysis that both Hitzkircher and BONDOLETTA from Ticino are distinct natural crosses between COMPLETER from Graub\u00fcnden and Bondola from Ticino (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'The only varietal Hitzkircher wine is made by Rebbaugesellschaft Hitzkirch in the village in Switzerland of the same name.',
    ],
  },
  {
    name: 'H\u00d6LDER',
    synonyms: ['Hoelder', 'Weinsberg S 397'],
    description: 'Very minor, distinctly ordinary German cross.',
    'origin and parentage': [
      'A RIESLING \u00d7 PINOT GRIS (Rul\u00e4nder) cross obtained in 1955 by August Herold. It was apparently named after the Lauffen am Neckar poet Friedrich H\u00f6lderlin.',
    ],
    'where its grown': [
      'Germany has just 6 ha (15 acres) of this variety, authorized in 1987, scattered across its more southerly wine regions. Wines are unexceptional \u2013 reasonably fresh and fruity and lightly aromatic.',
    ],
  },
  {
    name: 'HONDARRIBI BELTZA',
    synonyms: [
      'Chacol\u00ed',
      'Cruchen N\u00e8gre',
      'Hondarrabi Beltza',
      'Kurixketu Beltza',
      'Ondarrabi Beltza',
      'Txakoli',
      'Verde Matza',
      'Xerratu Beltza',
    ],
    description:
      'Rare speciality of northern Spain\u2019s Basque Country related to, and sometimes reminiscent of, Cabernet Franc.',
    'origin and parentage': [
      'Hondarribi Beltza was named after the town of Hondarribia in the province of Guip\u00fazcoa in northern Spain, close to the border with France, and the colour of its berries (beltza means \u2018black\u2019 in Basque). It is also called Txakoli or Chacol\u00ed, after the name of the vineyards where it is cultivated. It is often interplanted with CABERNET FRANC, with which it is frequently and reasonably confused: DNA parentage analysis has revealed a parent\u2013offspring relationship between Hondarribi Beltza and Cabernet Franc (Boursiquot et al. 2009). In addition, Hondarribi Beltza and FER spontaneously crossed to give birth to Gros Cabernet (Boursiquot et al. 2009; see CABERNET SAUVIGNON\u2019s pedigree), an almost extinct variety from the Gironde in south-west France. Hondarribi Beltza is therefore a member of the Carmenet ampelographic group (see p XXVII; Bisson 2009).',
      'Hondarribi Beltza is not a colour mutation of Hondarribi Zuri (zuri means \u2018white\u2019), a name which is, confusingly, used for three varieties planted in the Pa\u00eds Vasco: COURBU BLANC, CROUCHEN and the hybrid NOAH. Official Spanish statistics reported 412 ha (1,018 acres) of Hondarribi Zuri in 2008, almost exclusively in the Pa\u00eds Vasco, where it is the main white variety in the three principal Basque DOs (Bizkaiko Txakolina, Getariako Txakolina and Arabako Txakolina), but it is difficult to know which of the three varieties are referred to in the statistics and in the varietal wines produced (see below).',
    ],
    'where its grown': [
      'There were just 7 ha (17 acres) in 2008, almost exclusively in the Pa\u00eds Vasco (Basque Country) in northern Spain, where it is the principal variety in the red and ros\u00e9 wines of the Bizkaiko Txakolina (Chacol\u00ed de Bizcaia) DO and in the reds of the Getariako Txakolina (Chacol\u00ed de Guetaria) DO. Dondiene Gorrondona make a varietal wine from ancient pre-phylloxera vines. Other producers include Talai Berri and Txom\u00edn Etxaniz. Some ros\u00e9 wines are a blend of the two Hondarribis. Wines are typically high in both alcohol and acidity, potentially also in colour and tannins, and are generally aromatic with some herbaceous notes, sometimes reminiscent of CABERNET FRANC.',
    ],
  },
  {
    name: 'HOROZKARASI',
    synonyms: ['Horoz Karas\u0131'],
    description:
      'A stay-at-home variety used more for the table than for wine.',
    'origin and parentage': [
      'Horozkaras\u0131, literally \u2018black rooster\u2019, most likely originates from Kilis province near Gaziantep in south-eastern Turkey, near the border with Syria.',
    ],
    'where its grown': [
      'Horozkaras\u0131 has not travelled far. Today it is grown almost exclusively around Gaziantep, near the Syrian border in the far south of Turkey. Wines are typically deeply coloured, and high in tannin and alcohol, but it is more widely used for the table than for wine and is not generally regarded as a good-quality wine grape. B\u0131r\u0131c\u0131k make a varietal wine.',
    ],
  },
  {
    name: 'HRON',
    synonyms: ['CAAB 3/22'],
    description:
      'Recent but promising Slovakian cross producing full-bodied, ageworthy reds.',
    'origin and parentage': [
      'Hron, named after a tributary of the Dunaj (Danube), is a CASTETS \u00d7 ABOURIOU cross obtained in 1976 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia. It is a sibling of NITRANKA, RIMAVA and V\u00c1H and was authorized in 2011.',
    ],
    'where its grown': [
      'Hron is not yet widely planted (5 ha/12 acres in 2011) but shows promise, particularly in the Ju\u017enoslovensk\u00e1 and Malokarpatsk\u00e1 wine regions in south-west Slovakia, for high-quality, deeply coloured, full-bodied, ageworthy reds with some similarity to CABERNET SAUVIGNON. Producers of varietal wines include Chateau Modra and Karol Brani\u0161.',
    ],
  },
  {
    name: 'HRVATICA',
    synonyms: [
      'Hrvatica Crna',
      'Jarbola',
      'Karbonera',
      'Markolina',
      'Negrara',
      'Negrona',
    ],
    description: 'Recently rescued Croatian variety making pale reds.',
    'origin and parentage': [
      'Hrvatica, meaning \u2018Croatian girl\u2019, is an indigenous variety from the area of Ka\u0161telir in Istra (Istria) in north-west Croatia. Having exactly the same etymology, the Italian variety CROATINA has long been considered identical to Hrvatica, but DNA profiling not only rejected this identity (Maleti\u0107 et al. 1999) but also later showed that Jarbola, a black-berried variety cultivated exclusively in Matulji, north west of Rijeka and close to the Adriatic coast, is identical to Hrvatica.',
      'The white-berried Jarbola is not a colour mutation of the black-berried Jarbola and represents a distinct variety (Sladonja et al. 2007). New plantings were initiated in 2004 and it has been suggested that it be called Jarbola Bijela (bijela meaning \u2018white\u2019; Sladonja et al. 2007).',
    ],
    'where its grown': [
      'Recommended in all the Istrian wine regions in north-west Croatia but is currently planted only in Buj\u0161tine in the far north-western corner of Istra. A project to preserve and rehabilitate Jarbola in the Matulji area was begun in 2003. The grapes are not deeply coloured and are therefore well suited to the production of ros\u00e9.',
    ],
  },
  {
    name: 'HUMAGNE',
    synonyms: [
      'Humagne Blanc',
      'Humagne Blanche',
      'Miousat',
      'Miousap',
      'Mioussat',
    ],
    description: 'Ancient, tangy Swiss variety recently revived in the Valais.',
    'origin and parentage': [
      'Humagne is a very old variety from the Valais, southern Switzerland, where it was first mentioned in 1313 as \u2018humagny\u2019 (see also R\u00c8ZE) on a parchment known as the \u2018Registre d\u2019Anniviers\u2019 (Ammann-Doubliez 2007; Vouillamoz 2009a). Recent ampelographic observations and DNA profiling have surprisingly shown that Miousat, still scattered in the vineyards of Juran\u00e7on and Monein in the Pyr\u00e9n\u00e9es-Atlantiques in south-western France, is identical to Humagne (Bordenave et al. 2007). This makes more sense in the light of DNA parentage analysis revealing a parent\u2013offspring relationship between Humagne and COLOMBAUD (see COMPLETER for the pedigree diagram), an almost extinct variety from Provence in southern France (Vouillamoz and Moriondo 2011). It is therefore quite plausible that Humagne was introduced from southern France to the Valais via the Vall\u00e9e du Rh\u00f4ne before the thirteenth century, which would also explain its possible Greek etymology based on hylomaneus, meaning \u2018vigorous\u2019, the Phocaeans having probably introduced viticulture to southern France (Aebischer 1937; Vouillamoz and Moriondo 2011).',
      'In the Haut-Valais, a natural cross between Humagne and COMPLETER gave birth to LAFNETSCHA, while another natural cross with an unknown and probably extinct variety produced HIMBERTSCHA (Vouillamoz, Maigre and Meredith 2004).',
      'Humagne, often called Humagne Blanc, is not related to Humagne Rouge, the Valais name for CORNALIN from the Valle d\u2019Aosta.',
    ],
    'where its grown': [
      'Almost extinct by the mid twentieth century, Humagne was revived at the end of the 1980s and by 2009 its plantings in Switzerland totalled 30 ha (74 acres), exclusively in the Valais. Humagne wines are typically dry and fresh with subtle hints of lime-blossom, moderate alcohol and an elegant texture, developing a resin-like aroma as they age, although they can be very ordinary if yields are not kept low. Recommended producers include Defayes-Crettenand, Mabillard-Fuchs and Montzuettes.',
      'Local legend has it that Humagne wines contain more iron than any other, which is why it is often called vin des accouch\u00e9es (\u2018wine for new mothers\u2019) and has traditionally been offered to women who have just given birth. However, chemical analysis has shown this to be a myth and historical documents suggest that it was the medicinal herbs added to hot Humagne (or any other wine) that were responsible for its restorative properties (Pont 2005).',
    ],
  },
  {
    name: 'HUXELREBE',
    synonyms: ['Alzey S. 3962'],
    description:
      'German cross scores highly for yields, ripeness, aroma and acidity but not for subtlety.',
    'origin and parentage': [
      'A CHASSELAS (aka Weisser Gutedel) \u00d7 Muscat Pr\u00e9coce de Saumur (aka Courtiller Musqu\u00e9) cross bred in 1927 by Georg Scheu at Alzey, although it is named after the nurseryman Fritz Huxel, who was the first to propagate this variety widely in the 1950s. Muscat Pr\u00e9coce de Saumur is a selfling of PINOT NOIR PR\u00c9COCE obtained by Auguste Courtiller in 1842.',
    ],
    'where its grown': [
      'In Germany there are 635 ha (1,569 acres), predominantly in Rheinhessen (421 ha/1,040 acres) and in the Pfalz (189 ha/467 acres). Wines are often very sweet and tend to be pungently aromatic and full-flavoured, if not desperately subtle, with flavours and aromas of apricot, passion fruit, honey, spice and musk. Only the best examples from producers such as G\u00fcnter, Friedelsheim, Neckerauer and Wittmann have ageing potential thanks to the variety\u2019s marked acidity.',
      'Huxelrebe was introduced to England in 1972 but the current total of 25 ha (62 acres) is about half what it was twenty years ago. In such a cool climate, Huxelrebe\u2019s reliable ripeness helps to balance the high acidity. It is frequently added to blends such as Chapel Down\u2019s Flint Dry, for example, but also used to make dessert wines.',
    ],
  },
  {
    name: 'INCROCIO BIANCO FEDIT 51',
    synonyms: ['Fedit 51'],
    description: 'Rare Veneto 1950s white cross good for passito wines.',
    'origin and parentage': [
      'Note that incrocio is Italian for \u2018crossing\u2019, which is why there are several unrelated varieties called Incrocio Something (with Something usually being the breeder\u2019s name).',
      'This is a GARGANEGA \u00d7 MALVASIA BIANCA LUNGA (under the name Malvasia del Chianti) cross obtained by the Federazione Italiana dei Consorzi Agrari (Italian Federation of Agricultural Consortiums) in Padova in 1951.',
    ],
    'where its grown': [
      'Authorized in the provinces of Padova and Vicenza in the Veneto, northern Italy. Only 13 ha (32 acres) were recorded in Italy in 2000.',
    ],
  },
  {
    name: 'INCROCIO BRUNI 54',
    synonyms: ['Dorico', 'Sauvignon \u00d7 Verdicchio'],
    description:
      'Minor but high-yielding central Italian white cross that generally disappears into blends.',
    'origin and parentage': [
      'Obtained in 1936 by Bruno Bruni at the ministry of agriculture in the Marche region by crossing SAUVIGNON BLANC and VERDICCHIO BIANCO.',
    ],
    'where its grown': [
      'It is cultivated in the provinces of Ancona and Macerata in the Marche in central Italy and, to a lesser extent, in the province of Perugia in Umbria. However, total plantings in Italy in 2000, according to the agricultural census, were just 13 ha (32 acres). It is invariably blended with other varieties such as PECORINO, TREBBIANO TOSCANO, VERDICCHIO BIANCO, etc. but La Montata have produced a varietal wine classified as IGT Marche that combines the aromatic character of SAUVIGNON BLANC with the structure of Verdicchio Bianco. The wines tend to be aromatic, dry and slightly bitter.',
      'Incrocio Bruni 54 may comprise up to 30% of the blended Colli Maceratesi DOC just south of Ancona.',
    ],
  },
  {
    name: 'INCROCIO MANZONI 2.15',
    synonyms: ['Manzoni Nero', 'Prosecco \u00d7 Cabernet Sauvignon 2-15'],
    description:
      'Very minor black-skinned cross confined to the Veneto in northern Italy.',
    'origin and parentage': [
      'Incrocio Manzoni 2.15 is a cross obtained by Luigi Manzoni at the Conegliano research centre in the Veneto between 1924 and 1930. Manzoni\u2019s initial intention was to produce a white variety by crossing PROSECCO and SAUVIGNON BLANC but he used the pollen of CABERNET SAUVIGNON instead of Sauvignon Blanc and ended up with a black-berried variety.',
    ],
    'where its grown': [
      'Cultivation of Incrocio Manzoni 2.15 is restricted to the province of Treviso in the Veneto, Italy, mainly in the vineyards of Conegliano and Montello. Up to 10% may be included in the Colli di Conegliano Rosso DOC, blended with CABERNET FRANC, CABERNET SAUVIGNON, MERLOT or MARZEMINO. The Italian agricultural census of 2000 recorded total plantings of 169 ha (418 acres). Varietal wines are rare but Collalto produce one, classified as IGT Colli Trevigiani, and Perlage produce a traditional-method sparkling wine. The wines tend to be slightly herbaceous and low in tannins, with aromas of red and black fruits.',
    ],
  },
  {
    name: 'INCROCIO TERZI 1',
    synonyms: ['Barbera \u00d7 Cabernet Franc 1'],
    description: 'Minor Lombardia crossing that disappears into blends.',
    'origin and parentage': [
      'Obtained by Riccardo Terzi, a wine grower in the province of Bergamo in Lombardia, north-west Italy, by crossing BARBERA with CABERNET FRANC.',
    ],
    'where its grown': [
      'Incrocio Terzi 1 is cultivated only in Lombardia, north-west Italy, where it is particularly recommended in the provinces of Bergamo and Brescia. Authorized as part of the blend in the following DOCs, it does not appear to be produced as a varietal wine: Cellatica (10\u201315%) and Capriano del Colle (maximum 15%). It is also planted to a limited extent in Franciacorta and on the shores of Lago di Iseo and Lago di Garda. The wines are deeply coloured with highish alcohol.',
      'There were 69 ha (171 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'INVERNENGA',
    synonyms: ['Invernesca', 'Ua\u2019mbrunesca'],
    description: 'Old and rare Brescia white, ideal for drying off the vine.',
    'origin and parentage': [
      'Invernenga was first described in 1826 as one of the most widely cultivated varieties around Brescia in Lombardia (Giavedoni and Gily 2005). Its name probably comes from inverno (Italian for \u2018winter\u2019) because Invernenga grapes used to be dried during winter, and later rehydrated by putting the bunches in warm water, for consumption as table grapes.',
    ],
    'where its grown': [
      'Right in the middle of the city of Brescia in central Lombardia, Italy, at the foot of the hill that leads up to the castle, is the historic Vigneto della Pusterla vineyard, owned by the Pusterla Winery. This 4 ha (10 acres) vineyard is trained in a local pergola style and produces the Pusterla Bianco IGT Ronchi di Brescia, the only existing example of varietal Invernenga. This unique \u2018city wine\u2019, supported by Slow Food, is an elegant white with aromas of almonds. Outside Pusterla, there are just a few pockets of old, generally interplanted Invernenga vines.',
    ],
  },
  {
    name: 'INZOLIA',
    synonyms: [
      'Ansolica',
      'Ansonica',
      'Ansora',
      'Anzonica',
      'Insolia',
      'Insolia di Palermo',
      'Insora',
      '\u2019Nzolia',
      'Zolia Bianca',
    ],
    description: 'Nutty Sicilian white.',
    'origin and parentage': [
      'Under a number of names, this variety has been cultivated since the sixteenth century in Toscana and on Sicilia. Inzolia is usually said to have its roots on Sicilia, where it was first cited by Cupani (1696) and whence it spread to Sardegna and then to the islands of Elba and Giglio in Toscana (Giavedoni and Gily 2005). This island origin has been confirmed by a recent genetic study relating Inzolia to such other Sicilian varieties as GRILLO, FRAPPATO and NERELLO MASCALESE, as well as the closely related but distinct (no longer cultivated) Inzolia Imperiale and Inzolia Nera and the more widespread MOSCATO GIALLO (known as Muscatedda on Sicilia) (Carimi et al. 2009). This refutes the hypothesis of a Greek origin for Inzolia supported by Labra et al. (1999), who have suggested that it (under the name Ansonica) could be related to Greek varieties SIDERITIS and RODITIS (without specifying which one), as well as the supposed genetic relationship with AIR\u00c9N from Spain or with CLAIRETTE from France. This is probably a consequence of the low sampling number of Mediterranean (twenty-five) and Greek (forty) varieties analysed by Labra et al. (1999) and of the lack of other Tuscan or Sicilian varieties in their study.',
    ],
    'where its grown': [
      'Inzolia is one the most important and ancient white grapes of Sicilia, Italy, long cultivated in the province of Palermo, as well as in a few spots in the provinces of Trapani, Agrigento, Caltanisetta and Girgenti. It has been a valued ingredient in Marsala but is now more often blended with CATARRATTO BIANCO and GRILLO as part of numerous Sicilian DOCs (Alcamo, Contea di Sclafani, Contessa Entellina, Delia Nivolelli, Erice, Mamertino di Milazzo, Marsala, Menfi, Monreale, Riesi, Salaparuta, Sambuca di Sicilia, Santa Margherita di Belice, Sciacca, Vittoria) and IGTs.',
      'Inzolia has also been cultivated for a long time in Toscana, where it is called Ansonica, in particular on the Tuscan coast (Val di Cornia, Monte Argentario and Valle dell\u2019Albegna) and in the Tuscan archipelago (the islands of Elba and Giglio). A few Inzolia vines have apparently also been found on Sardegna and in Campania and Lazio. Total Italian plantings in 2000 were 9,500 ha (23,500 acres) although the area is declining. Sicilia recorded 7,795 ha (19,262 acres) in 2008.',
      'The best wines have a nutty character but the grapes must be picked early enough to retain sufficient acidity.',
    ],
  },
  {
    name: '\u0130RI KARA',
    synonyms: ['Arikaras', 'Focea', 'Fodja'],
    description: 'Very minor, mediocre, pink-skinned Turkish variety.',
    'origin and parentage': [
      '\u0130ri Kara, literally meaning \u2018big black\u2019, is a variety from the region of Had\u0131m in the province of Konya, Central Anatolia. According to Vouillamoz et al. (2006), \u0130ri Kara could be genetically closely related to Ek\u015fi Kara, a variety from the province of Karaman, south east of Konya, that is no longer cultivated.',
    ],
    'where its grown': [
      '\u0130ri Kara is grown on the Baklana plateau between Baklan-Bekilli in the province of Denizli and Karahall\u0131 in the province of U\u015fak, south-west Turkey. It is not ideally suited as a table grape and is therefore also made into low-quality table wine.',
    ],
  },
  {
    name: 'IRSAI OLIV\u00c9R',
    synonyms: [
      'Irsay Oliver',
      'Irsay Oliver Muskotaly',
      'Muscat Oliver',
      'Oliver Irsay',
    ],
    description:
      'Hungarian table grape promoted to making soft, aromatic white wines.',
    'origin and parentage': [
      'Irsai Oliv\u00e9r is a Pozsonyi Feh\u00e9r \u00d7 CSABA GY\u00d6NGYE cross obtained in 1930 by P\u00e1l Kocsis in Kecskem\u00e9t, central Hungary (Galb\u00e1cs et al. 2009), in which Pozsonyi Feh\u00e9r (Pozsony is the Hungarian name for Bratislava and Feh\u00e9r means \u2018white\u2019) is a white wine variety from Hungary that is no longer cultivated but is often confused with K\u00d6VIDINKA because they share the synonym Dinka Feh\u00e9r. It was officially registered in 1975.',
      'Irsai Oliv\u00e9r was used to breed AGNI and CSERSZEGI F\u0170SZERES.',
    ],
    'where its grown': [
      'Until the mid 1980s, Irsai Oliv\u00e9r was grown exclusively for the table but by 2008 there were also 997 ha (2,464 acres) planted to make soft, aromatic, grapey wines in many parts of Hungary, although the best results come from cooler areas in the north and west such as Sopron, Neszm\u00e9ly, Etyek-Buda, M\u00e1tra and around Lake Balaton (Roh\u00e1ly et al. 2003). The wines are best drunk young and are often improved by blending with a more structured variety. Recommended producers include Hilltop (Neszm\u00e9ly), Nyakas (Etyek-Buda), Font (Kuns\u00e1g), M\u00e1ty\u00e1s and Zolt\u00e1n Sz\u0151ke (M\u00e1tra).',
      'Irsai Oliv\u00e9r is quite widely planted in Slovakia (460 ha/1,137 acres in 2009) and also found in Morava in the south east of the Czech Republic (80 ha/198 acres in 2009), where it is often blended with Ryzlink Vla\u0161sk\u00fd (GRA\u0160EVINA) and used in the production of sparkling wines.',
      'Muscat Oliver is also found in Switzerland (fewer than 5 ha/12 acres). Producers include Schmidheiny and Lindenamann in the German-speaking canton of Argau and Rosenau in the canton of Luzern.',
      'Irsai Oliver is also found in Krasnodar Krai in Russia (24 ha/59 acres).',
    ],
  },
  {
    name: 'ISABELLA',
    synonyms: [
      'Americano',
      'Ananas',
      'Bangalore Blue',
      'Bellina',
      'Black Cape',
      'Bromostaphylo',
      'Captraube',
      'Constantia',
      'Dorchester',
      'Fragola',
      'Framboisier',
      'Fraula',
      'Fraulaghju',
      'Frutilla',
      'Gibb\u2019s Grape',
      'Gros Frambois\u00e9',
      'Isabel',
      'Isabella Nera',
      'Isabelle',
      'Isabellinha',
      'Izabella',
      'Kepshuna',
      'Kerkyraios',
      'Kokulu Uezuem',
      'Koreos',
      'Lidia',
      'Loipe Makedonia',
      'Moschostaphylo',
      'New Hanover',
      'Nostrano',
      'Odessa',
      'Paign\u2019s Isabella',
      'Raisin du Cap',
      'Raisin Fraise',
      'Raisin Framboise',
      'Sainte Helene',
      'Saluda',
      'Schuykill',
      'Strawberry Grape',
      'Tjortjidica',
      'Tzampela',
      'Tzortzidika Chakidike',
      'Tzortzines',
      'Utkopro',
      'Uva Cimice',
      'Uva Fragola',
      'Vernet',
      'Woodward',
      'Zampela',
    ],
    description:
      'Once widely planted, old American variety now found mostly in Brazil and India.',
    'origin and parentage': [
      'This is one of the oldest native American varieties, but its exact origin has been the subject of much speculation. It is most probably descended from the seedling of a wild native, Vitis labrusca, sometimes known as \u2018fox grapes\u2019 because of their so-called foxy flavour, found in a garden near Dorchester, South Carolina. According to Munson (1909), it is not a pure Vitis labrusca but a cross between Vitis labrusca and an unidentified Vitis vinifera subsp. vinifera variety. It was taken north under an unknown name as early as 1816 and was introduced to New York State by Colonel George Gibbs, an amateur grower from Brooklyn, Long Island. Gibbs then passed it on to William Robert Prince of the Linnaean Botanic Garden in Flushing, Long Island, who named it Isabella (Vitis isabellae) in 1822 in honour of the Colonel\u2019s wife, Mrs Isabella Gibbs (Prince 1830; Bailey 1906; Pinney 1989; Mabberley 1999).',
      'Isabella was used to breed LYDIA.',
    ],
    'where its grown': [
      'Isabella and CATAWBA were the two dominant varieties in North American viticulture for the first half of the nineteenth century but there have been few recent plantings and only a few hectares remain, eg in New York State (22 acres/9 ha in 2009), where it is used for juice and sparkling wine but has largely been replaced by CONCORD.',
      'It was once found in many different regions of the world, particularly in the southern hemisphere and also in Eastern Europe and the former Soviet Union, but the two main countries in which Isabella is still widely planted are Brazil, where, as Isabel, it is the most widely planted variety of all (10,691 ha/26,418 acres in 2007), and India. In Brazil, Isabel is used for juice and jelly but also for very large volumes of the sweetish, inexpensive red that is still enormously popular on the domestic market. In India, is it known as Bangalore Blue and the majority of the 4,500 ha (11,120 acres) recorded in 2000 were used for juice rather than wine. Wines are pale, more like a ros\u00e9, and have the tell-tale sweet, foxy notes of labrusca.',
      'Isabella was at one time also grown in France but new plantings of this and other American hybrids such as JACQUEZ were proscribed in the mid 1930s and existing vineyards compulsorily grubbed up (with compensation) in the 1950s. One objection was that they produced higher levels of methanol in the wines due to greater amounts of skin pectins. It is still quite widely planted in the Republic of Moldova (10,802 ha/26,692 acres in 2009) and in Ukraine (2,126 ha/5,253 acres in 2009).',
    ],
  },
  {
    name: 'ITALIA',
    synonyms: ['Moscatel Italia', 'Moscatel Italiano', 'Muscat d\u2019Italie'],
    description:
      'Important, extremely large-berried table grape only very rarely used to make wine.',
    'origin and parentage': [
      'A Bicane \u00d7 MUSCAT OF HAMBURG cross obtained in 1911 by Alberto Pirovano at the arboriculture research centre in Roma, where Bicane is a French wine and table grape that is no longer cultivated.',
    ],
    'where its grown': [
      'Widely grown around the world as a table grape. At one time it was used to make wine in Australia but this is now very rare and the area under this variety is no longer even recorded for wine-growing. It is authorized for wine in France but the area is limited (152 ha/376 acres in 2006) and declining.',
    ],
  },
  {
    name: 'ITALICA',
    synonyms: [
      '103',
      'Incrocio Cosmo 103',
      'Verdiso \u00d7 Riesling Italico 103',
    ],
    description:
      'A 1950s white Italian cross of interest only in the province of Treviso.',
    'origin and parentage': [
      'A VERDISO \u00d7 GRA\u0160EVINA (Riesling Italico) cross obtained in the 1950s by Italo Cosmo at the Conegliano research centre in the Veneto.',
    ],
    'where its grown': [
      'Cultivated on the hills near Conegliano and Pieve di Soligo in the province of Treviso in the Veneto, northern Italy, the only province in which the variety is authorized. There were 184 ha (455 acres) of Italica in Italy in 2000.',
    ],
  },
  {
    name: 'IVES',
    synonyms: [
      'Black Ives',
      'B\u00f4rdo',
      'Ives Madeira',
      'Ives\u2019 Madeira Seedling',
      'Ives Seedling',
      'Kittredge',
    ],
    description: 'American hybrid that needs fresh air.',
    'origin and parentage': [
      'The origin of Ives has long been debated. Most accounts suggest it is a chance seedling, the result of open pollination of Hartford (or Hartford Prolific), that was planted in 1840 by Henry Ives in his garden near Cincinnati, Ohio. However, Hartford is also an accidental seedling that was most probably a spontaneous ISABELLA \u00d7 Vitis labrusca hybrid that sprang up around 1846 in the garden of Paphro Steele & Son in West Hartford, Connecticut, thereby postdating Ives\u2019 birth by six years and contradicting this theory. Others have suggested that Ives is a Vitis labrusca \u00d7 Vitis aestivalis hybrid.',
    ],
    'where its grown': [
      'Wines are deeply coloured, making them useful in blends, and marked by the foxy sweetness of labrusca varieties, similar to those made from CONCORD but lighter in colour. The grapes are also used for juice. After Prohibition, the variety was a common ingredient in sweet port-style wines but plantings have declined considerably in recent years due to the vine\u2019s sensitivity to air pollution. Johnson Estate close to Lake Eyrie in New York State have nearly 7 acres (3 ha) and have seen improved quality since the power stations downwind in Michigan and Ohio cleaned up their emissions. Varietal wines, often made in a blush, sweet or semi-sweet style, are also produced by Americana and Bully Hill. Total plantings in New York amounted to 50 acres (20 ha) in 1996. In Arkansas, there were around 15 acres (6 ha) in 2011, almost all of those owned and vinified by Post Familie Vineyards, where some of the vines are over fifty years old. Ives can also be found to a very limited extent in Pennsylvania.',
    ],
  },
  {
    name: 'JACQU\u00c8RE',
    synonyms: [
      'Cugnette',
      'Jacquerre',
      'Jaqu\u00e8re',
      'Martin-Cot',
      'Molette de Montm\u00e9lian',
      'Plant des Ab\u00eemes',
      'Plant des Abymes de Myans',
      'Raisin des Ab\u00eemes',
      'Redin',
      'Robinet',
      'Roussette',
    ],
    description: 'Savoie speciality producing fresh, light, mountain whites.',
    'origin and parentage': [
      'Jacqu\u00e8re is the most important white wine variety of Savoie in eastern France. According to Rougier (1903), Jacqu\u00e8re was first mentioned by Fleury Lacoste under the name Redin in 1855. However, this mention is doubtful because a few years later the same Fleury Lacoste (1861) says that Redin is also called Sarvagnin, which undoubtedly corresponds to SAVAGNIN in Savoie (Galet 1990). Therefore, the first occurrence of the name Jacqu\u00e8re is more likely to be the one found in Pierre Tochon\u2019s 1868 description of the vineyard in the hilly area known as Ab\u00eemes de Myans (R\u00e9zeau 1997): \u2018le c\u00e9page qui recouvre ces blocs \u00e9normes de calcaire .\u00a0.\u00a0. s\u2019appelle Jacqu\u00e8re, martin-cot et raisin des Abymes\u2019 (\u2018the variety that covers these huge blocks of limestone .\u00a0.\u00a0. is called Jacqu\u00e8re, martin-cot and raisin des Abymes\u2019). According to Rougier (1903), this variety was supposedly named after the person who introduced it to Savoie in the thirteenth century, but although both Jacqu\u00e8re and Jacquerre do exist as family names in France, they are not common in this area. Jacqu\u00e8re is a member of the Pelorsien ampelographic group (see p XXVII).',
      'DNA parentage analysis suggested that Jacqu\u00e8re is one of the numerous natural progenies of GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'On its home territory in Savoie, eastern France, Jacqu\u00e8re produces light, fresh, lightly scented, mostly dry, alpine wines for drinking young, sometimes with a light spritz. Jacqu\u00e8re is authorized for the Vin de Savoie and Bugey appellations but is also officially recommended in the Ain and Is\u00e8re d\u00e9partements to the west. The most flavourful wines tend to come from the crus of Apremont and Abymes, where Jacqu\u00e8re must constitute at least 80% of the vineyard for whites, as it does in Jongieux. It has also been planted further south west, in Condrieu in the northern Rh\u00f4ne, although it is not entitled to that appellation. Total plantings in France in 2009, predominantly in Savoie, were a fairly stable 1,027 ha (2,538 acres).',
      'Recommended producers of varietal wines include Didier et Denis Berthollier (Chignin), Domaine Fr\u00e9d\u00e9ric Giachino (Apremont), Domaine Grisard (Fr\u00e9terive), Domaine de l\u2019Idylle (Cruet) and Andr\u00e9 et Michel Quenard (Chignin). Domaine Jean Masson et Fils in Apremont make a range of single-vineyard wines with good ageing potential.',
      'It is also planted to a very limited extent in Portugal (3 ha/7 acres in 2009).',
    ],
  },
  {
    name: 'JACQUEZ',
    synonyms: [
      'Black Spanish',
      'Cigar Box',
      'French Grape',
      'Jacquet',
      'Lenoir',
      'Longworth\u2019s Ohio',
      'Troya',
    ],
    description:
      'Once-popular American hybrid with good resistance to Pierce\u2019s disease and now surviving mainly in Brazil and Texas.',
    'origin and parentage': [
      'The origin of this variety is obscure and disputed. It is said to be a member of Vitis bourquiniana, a botanically dubious group of Vitis aestivalis \u00d7 Vitis vinifera hybrids (see HERBEMONT for details).',
      'Jacquez possibly originates from around the town of New Bordeaux in McCormick County, close to the Savannah River, which divides South Carolina from Georgia. Native Vitis aestivalis vines were growing here and French Huguenots, most of whom had settled in the region between 1730 and 1780, had introduced European Vitis vinifera varieties. The Huguenots were experienced vine growers and probably selected this chance seedling because it was more resistant to diseases than their European varieties. It was then apparently taken to Natchez in Mississippi, by a Spaniard called Jacques, hence the name Jacquez. It is often known as Lenoir in the United States, after the county of that name in South Carolina.',
    ],
    'where its grown': [
      'Jacquez was at one time popular in southern France, used mainly in blends from the end of the nineteenth to the middle of the twentieth century (it was even authorized for Ch\u00e2teauneuf-du-Pape until 1935), but new plantings of this and other hybrids such as ISABELLA were proscribed in the mid 1930s and existing vineyards compulsorily grubbed up (with compensation) in the 1950s. However, some centenarian vines still exist in the C\u00e9vennes, known here as Jacquet, and their fruit constitutes the main ingredient in the Cuv\u00e9e des Vignes d\u2019Antan produced by the Association M\u00e9moire de la Vigne, which cannot legally be sold within the EU because of its hybrid component. They also produce a varietal Cuv\u00e9e Le Jacquet.',
      'Once widely planted in Texas for the purposes of communion wine, the red-fleshed Jacquez \u2013 under the name Lenoir or Black Spanish \u2013 is still grown there to a limited extent: 130 acres (53 ha) in 2008. The bearing acreage has more than doubled since 2005 thanks to the variety\u2019s marked resistance to Pierce\u2019s disease, which is a serious problem in the heat and humidity of the state. It is used for the production of both Texas port-style wines and table wines with the former more typical today, showing the spicy black fruit characteristics typical of the variety. Producers of varietal table wines include Chisholm Trail, Dry Comal Creek and Inwood.',
      'The variety\u2019s surest stronghold is Brazil, where there were 1,397 ha (3,452 acres) in 2007 in Rio Grande do Sul. It is used mainly for juice, jelly and the inexpensive, slightly sweet red wines popular on the domestic market.',
      'The variety is sometimes used as a rootstock but its resistance to phylloxera is only moderate so that it is restricted to sandy soils or to those areas where phylloxera is so far unknown.',
    ],
  },
  {
    name: 'JAMPAL',
    synonyms: ['Cercial', 'Jampaulo', 'Jo\u00e3o Paolo', 'Pinheira Branca'],
    description:
      'Minor Portuguese vine recognized for its quality but not widely planted.',
    'origin and parentage': [
      'Jampal probably originates in Portugal\u2019s Colares region north west of Lisboa, whence it spread north to the rest of the Lisboa region and Beiras and east to the Tejo. Recent DNA profiling has suggested a possible sibling relationship with the widely planted black-berried CASTEL\u00c3O from the Douro (Myles et al. 2011).',
    ],
    'where its grown': [
      'Jampal, authorized in a whole host of regional and DOC appellations from Set\u00fabal and Lisboa in the west up to the Douro in the north east of Portugal, produces full-bodied, perfumed wines but has not been widely planted despite its potential quality. Biomanz produce a varietal wine within the Lisboa region. There were 106 ha (262 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'JOHANNITER',
    synonyms: ['Freiburg 177-68'],
    description: 'Recent German hybrid with very good disease resistance.',
    'origin and parentage': [
      'Complex self-crossed hybrid bred in 1968 by Johannes Zimmerman at the Freiburg research centre in Baden-W\u00fcrttemberg, southern Germany, by crossing RIESLING \u00d7 Freiburg 589-54 with itself, where:',
      '\u2013\u00a0\u00a0\u00a0Freiburg 589-54 is a Seyve-Villard 12-481 \u00d7 Freiburg 153-39 hybrid (see HELIOS for the complete Seyve-Villard 12-481 pedigree)',
      '\u2013\u00a0\u00a0\u00a0Freiburg 153-39 is a PINOT GRIS \u00d7 CHASSELAS cross.',
      'It was named in honour of Johannes Carpenter, former director of the Freiburg research centre.',
    ],
    'where its grown': [
      'Authorized in Germany in 2001, where there are now 65 ha (161 acres), mainly in Baden and Rheinhessen. Wines are fresh and lightly aromatic in the citrus/grapefruit spectrum, sometimes a little bitter on the finish, although this can be overcome with a few grams of residual sugar. Producers of varietal wines include Clemens Lang, Schlossgut Hohenbeilstein and Z\u00e4hringer in Baden, Anselmann in the Pfalz, Weingut Im Hagenb\u00fcchle in Baden-W\u00fcrttemberg and Zang in Franken.',
      'It is found to a very limited extent (about 10 ha/25 acres) in Switzerland, where Hasenheide, for example, produce a varietal wine south east of Z\u00fcrich. It is also grown in Belgium by Domaine Viticole du Chenoy and was authorized in Italy in 2011.',
    ],
  },
  {
    name: 'JUAN GARC\u00cdA',
    synonyms: [
      'Gorda',
      'Malvas\u00eda  Negra',
      'Mourat\u00f3n',
      'Negrera',
      'Negr\u00f3n de Ald\u00e1n',
      'Nepada',
      'Tinta Gorda',
      'Villarino',
    ],
    description: 'Intriguing, perfumed speciality of north-western Spain.',
    'origin and parentage': [
      'Juan Garc\u00eda is said to come from the Los Arribes del Duero national park in western Spain along the River Duero that forms the border with Portugal. The origin of its name is unknown. DNA parentage analysis has recently revealed that Juan Garc\u00eda (under the synonym Mourat\u00f3n) is a natural cross between CAYETANA BLANCA, widespread throughout the south and centre of the Iberian peninsula, and ALFROCHEIRO from the south or centre of Portugal (Zinelabidine et al. 2012). Juan Garc\u00eda is therefore a sibling of CORNIFESTO, MALVASIA PRETA, CASTEL\u00c3O and CAMARATE (see Cayetana Blanca for the pedigree diagram).',
      'Although both Juan Garc\u00eda and PETIT BOUSCHET are sometimes called Negr\u00f3n de Ald\u00e1n (Gonz\u00e1lez-Andr\u00e9s et al. 2007), they are not related. Juan Garc\u00eda and MALVASIA NERA DI BRINDISI share the synonym Malvas\u00eda Negra but should not be confused.',
    ],
    'where its grown': [
      'Juan Garc\u00eda is a speciality of the Fermoselle area in the province of Zamora, western Spain, west of Toro and just upstream from Portugal\u2019s Douro Valley, where it is sometimes blended with the Portuguese variety RUFETE. It is one of the principal dark-skinned varieties of the Arribes DO. Originally planted on the rocky slopes down to the Duero, it has since spread to flatter sites and now comprises more than half of the region\u2019s vineyard area with a total of 1,260 ha (3,114 acres) in 2008, mostly in Castilla y Le\u00f3n but also further north in Galicia. Producers of varietal wines include Almajora, Bodega Ribera de Pelazas, La Setera and Terrazgo. Old vines planted on the best sites generally produce wines that are deeply coloured and highly perfumed with herbal and red-fruit flavours and moderate alcohol.',
      'In northern Portugal, it is know as Gorda (Tinta Gorda until 2000) and is grown predominantly in the Tr\u00e1s-os-Montes and Beiras regions (Rolando Faustino, personal communication), where it produces early-drinking wines that are relatively low in alcohol and have simple red-fruit aromas. There were 77 ha (190 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'JUHFARK',
    synonyms: ['L\u00e4mmerschwanz'],
    description:
      'Minor Hungarian variety that needs ageing and aeration to swap asperity for elegance.',
    'origin and parentage': [
      'Juhfark, meaning \u2018sheep\u2019s tail\u2019 and referring to the long cylindrical shape of the bunches, is an old variety from Hungary whose ampelographic identity is still unclear because Juhfark reference samples in Varga et al. (2008), Galb\u00e1cs et al. (2009) and Jahnke et al. (2009) have yielded three distinct DNA profiles.',
      'It should not be confused with Csomorika, a very old but no longer cultivated mediocre Hungarian variety from the Baranya region in southern Hungary (Galb\u00e1cs 2009) which in the past was mistakenly planted instead of Juhfark by growers in Soml\u00f3 (Roh\u00e1ly et al. 2003).',
    ],
    'where its grown': [
      'Once grown quite widely in Hungary, this distinctive variety is now found almost exclusively in Soml\u00f3, north of Lake Balaton in the west of the country. It\u2019s perhaps the least modern or internationally appealing of Hungarian varieties, producing relatively neutral, high-acid, sometimes rustic but ageworthy wines that need time in barrel and plenty of aeration to become more elegant and admirable. Recommended producers include Imre Gy\u00f6rgykov\u00e1cs, Inhauser, Kreinbacher, Meinklang (based over the border in Austria), Soml\u00f3i Ap\u00e1ts\u00e1gi Pince and Tornai. There were 156 ha (385 acres) of Juhfark in Hungary in 2008.',
    ],
  },
  {
    name: 'JURAN\u00c7ON BLANC',
    synonyms: [
      'Braquet',
      'Dame Blanc',
      'Plant de Dame',
      'Plant Debout',
      'Quillat',
      'Quillard',
      'Secal',
    ],
    description:
      'Inferior and increasingly neglected variety traditionally used as a base for spirits.',
    'origin and parentage': [
      'Despite its name, Juran\u00e7on Blanc has no connection with the Juran\u00e7on appellation, although it was supposedly named after the commune of Juran\u00e7on in the Pyr\u00e9n\u00e9es-Atlantiques, south-west France, where it allegedly originated. However, the birthplace of Juran\u00e7on Blanc is more likely to be in the Tarn-et-Garonne, north east of the Pyr\u00e9n\u00e9es-Atlantiques, where it was first mentioned in 1839 under the name Juran\u00e7on (in Moissac) and under the name Plant de Dame or Quillat (in Auvillar; R\u00e9zeau 1997).',
      'Juran\u00e7on Blanc is not a colour mutation of JURAN\u00c7ON NOIR, even though both are members of the Folle ampelographic group (see p XXVII; Bisson 2009). DNA parentage analysis at INRA (Institut National de la Recherche Agronomique) in Montpellier has shown that Juran\u00e7on Blanc is most likely a progeny of Folle Blanche and the obscure white-berried French variety Pru\u00e9ras (no longer cultivated).',
    ],
    'where its grown': [
      'The wines tend to be low in alcohol, acidic and generally poor quality, which, along with its susceptibility to fungal diseases, probably accounts for the variety\u2019s decline in France in the last fifty years \u2013 from 5,755 ha (14,221 acres) in 1958 down to 12 ha (30 acres) in 2006 \u2013 and for its traditional destination: armagnac or cognac.',
    ],
  },
  {
    name: 'JURAN\u00c7ON NOIR',
    synonyms: [
      'Chalosse Noire',
      'Dame Noire',
      'Plant de Dame',
      'Enrageat Noir',
      'Fola Belcha',
      'Folle Noire',
      'Folle Rouge',
      'Gouni',
      'Juran\u00e7on Rouge',
      'Luxuriant',
      'Piquepout Rouge',
      'Quillat',
      'Quillard',
      'Vidiella',
    ],
    description:
      'South-west French variety producing light wines but rapidly losing ground.',
    'origin and parentage': [
      'Although this variety is not related to the Juran\u00e7on appellation, it was supposedly named after the commune of Juran\u00e7on in the Pyr\u00e9n\u00e9es-Atlantiques, in the far south west of France, where it allegedly originated (R\u00e9zeau 1997). However, Galet (1990) locates its birthplace in the area of Agen, between Toulouse and Bordeaux in the Lot-et-Garonne, which is consistent with its first mention, by Comte Odart, in 1837: \u2018le nom de Juran\u00e7on noir qui servait d\u2019\u00e9tiquette \u00e0 un paquet de sarments qui m\u2019a \u00e9t\u00e9 envoy\u00e9 du d\u00e9partement de Lot-et-Garonne\u2019 (\u2018the name Juran\u00e7on noir that was used as a label for a bunch of vinestocks that was sent to me from the Lot-et-Garonne d\u00e9partement\u2019; R\u00e9zeau 1997). Although they share the synonym Folle Noire, Juran\u00e7on Noir and FUELLA NERA are distinct varieties that belong to the Folle ampelographic group (see p XXVII; Bisson 2009). DNA parentage analysis at INRA (Institut National de la Recherche Agronomique) in Montpellier has recently shown that Juran\u00e7on Noir is a natural FOLLE BLANCHE \u00d7 COT cross (Viollet and Boursiquot 2009; see CABERNET SAUVIGNON and PINOT pedigree diagrams).',
      'Juran\u00e7on Noir was used to breed CHENANSON, GANSON and SEGALIN.',
    ],
    'where its grown': [
      'Wines are light with very moderate levels of alcohol and colour, best suited to ros\u00e9 or lighter styles of red. Plantings in France have diminished dramatically in the last fifty years, down from 12,325 ha (30,456 acres) in 1958 to 708 ha (1,750 acres) in 2009, mostly in the Tarn and the Gers. It is authorized in appellations such as Vins d\u2019Estaing and Vins d\u2019Entraygues et du Fel.',
      'Juran\u00e7on Noir was introduced to Uruguay in the 1870s under the name Folle Noire by Francisco Vidiella and it was known as Vidiella until the mid 1980s. However, most of the old vineyards have gone and in 2007 less than a hectare (2.5 acres) remained of what is now known as Juran\u00e7on Noir.',
    ],
  },
  {
    name: 'JUWEL',
    synonyms: ['Jewel', 'Weinsberg S 378'],
    description: 'Modern German cross with patchy disease resistance.',
    'origin and parentage': [
      'A KERNER \u00d7 SILVANER cross bred in 1951 by August Herold at Weinsberg. Expanding the pedigree, Juwel is a grandchild of SCHIAVA GROSSA and RIESLING (parents of Kerner) as well as of SAVAGNIN and \u00d6STERREICHISCH WEISS (parents of Silvaner). The name Juwel is a contraction of three parts: JU in honour of the vine breeder Josef Sch\u00e4ffer, nicknamed Jupp; WE is the official abbreviation of the Weinsberg research centre; and L for Lauffen am Neckar, the location of the research centre.',
    ],
    'where its grown': [
      'Germany has 25 ha (62 acres), largely in Rheinhessen, but the area is gradually declining. Producers include G\u00f6rgen in the Mosel and Umbrich in W\u00fcrttemberg.',
    ],
  },
  {
    name: 'KABAR',
    synonyms: ['Tarcal 10'],
    description: 'Recent Hungarian cross designed for Tokaj.',
    'origin and parentage': [
      'Kabar is a BOUVIER \u00d7 H\u00c1RSLEVEL\u0170 cross obtained in 1967 by L\u00e1szl\u00f3 Brezovcsik, G\u00e1born\u00e9 Szakolczay, Ferenc Marcink\u00f3, L\u00e1szl\u00f3 Baracskai and S\u00e1ndorn\u00e9 \u00c9les at the Tarcali research centre in the Tokaj region.',
    ],
    'where its grown': [
      'Kabar, authorized in the Tokaj region of Hungary since 2006, typically produces high-acid wines. Chateau Dereszla make a rare, dry varietal wine which they describe as relatively light with the taste of linden-blossom honey. Other producers such as Grof Degenfeld and Tokaj Renaissance include it as a minor component in the blend for their sweet Tokaji wines. It represents about 1% of plantings in the region and seems unlikely to increase.',
    ],
  },
  {
    name: 'KACHICHI',
    synonyms: ['Abkhazouri', 'Kagigi', 'Katchitchi', 'Katcitci'],
    description:
      'Minor variety producing deep red wines in north-western Georgia.',
    'origin and parentage': [
      'Kachichi, indigenous to Apkhazeti, north-western Georgia, was mentioned as early as the nineteenth century (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Kachichi is not widely planted (25 ha/62 acres in Georgia in 2004) but produces deeply coloured, dry red wines in Samegrelo in the north west of Georgia and in neighbouring Apkhazeti.',
    ],
  },
  {
    name: 'KADARKA',
    synonyms: [
      'Branicevka',
      'Cadarc\u0103',
      'Cadarc\u0103 Neagr\u0103',
      'Cadarc\u0103 de Mini\u015f',
      'Fekete Budai',
      'Gamza',
      'Gamza de Varna',
      'Gomza',
      'Gumza',
      'Gymza',
      'Kadarka K\u00e9k',
      'Kallmet',
      'Lugojan\u0103',
      'Skadarka',
      'T\u00f6r\u00f6ksz\u0151l\u0151',
      'Varnenska Gimza',
    ],
    description:
      'Variety of uncertain origin producing gentle, fresh reds in Eastern Europe.',
    'origin and parentage': [
      'Kadarka is said to have been introduced to Hungary from the Balkans, either by Serbians, which fits with Kadarka being the Slavic name for Scutari, a lake (also known as Skadar) between Montenegro and Albania, and with the fact that the variety was once cultivated under the synonym Skadarka in Croatia and Serbia (Levadoux 1956; Galet 2000; Roh\u00e1ly et al. 2003), or by Turks, hence its synonym T\u00f6r\u00f6ksz\u0151l\u0151 (\u2018Turkish grape\u2019). It is conceivable that it made its way to Hungary via Bulgaria, where it is still widely planted under the synonym Gamza, and where it is considered to be indigenous. It is also said to be indigenous to the region of Mini\u015f near Arad in western Romania, where the first sweet Asz\u00fa-style red wine was made from shrivelled Cadarc\u0103 grapes in 1744 (Dejeu 2004). The exact origin of Kadarka remains unknown but it lies somewhere within the Balkan-Pannonian area.',
      'Kadarka was used to breed B\u00cdBORKADARKA, PROBUS and RUBINTOS.',
    ],
    'where its grown': [
      'Statistically Kadarka has been in decline in Hungary in recent decades but it is still popular with consumers for its medium body, gentle tannins, fresh acidity, lightly spicy aromas and overall elegance, with some similarity to PINOT NOIR (Roh\u00e1ly et al. 2003). Nor is it without champions among producers, even though it is sometimes difficult to ripen fully. Wines are not typically deep in colour and Kadarka is also very good for ros\u00e9. Cultivated in most of Hungary\u2019s wine regions, its heartland is in the south, in Szeksz\u00e1rd and Vill\u00e1ny, but it is quite widely planted in Eger, where it is used in the red blend called Egri Bikav\u00e9r or Bull\u2019s Blood of Eger. However, BLAUFR\u00c4NKISCH, known as K\u00e9kfrankos in Hungary, is easier to grow and vinify and has therefore been gaining ground at the expense of Kadarka. Recommended produers include D\u00fazsi, Eszterbauer, Heimann, Takler, Ferenc Vesztergombi and P\u00e9ter Vida in Szeksz\u00e1rd and St Andrea in Eger. Total plantings of Kadarka in Hungary in 2008 were down to 666 ha (1,646 acres) but plantings are likely to increase, particularly in Szeksz\u00e1rd, as progress is made in clonal research to find healthier plant material. Some older vines such as those in Oszk\u00e1r Maurer\u2019s centenarian vineyard over the border in Serbia show better disease resistance and produce more richly flavoured wines.',
      'Under the name Gamza, the variety is widely planted in Bulgaria (3,169 ha/7,831 acres in 2009), particularly in Vidin, Pleven and Suhindol in the north, and it is also found in the Republic of Macedonia. As Kallmet, this variety is one of the more important red varieties in Albania, planted mainly around Lake Skadar, which straddles the border with Montenegro in the north, and to a lesser extent in the centre of the country. Arb\u00ebri, for example, produce a varietal wine. Romania\u2019s 47 ha (116 acres; 2008) are in the west of the country, both in the southerly region of Oltenia and further north \u2013 closer to Hungary \u2013 in Mini\u015f M\u0103derat (eg Wine Princess).',
    ],
  },
  {
    name: 'KAKHET',
    synonyms: ['Cakhete', 'Kachet', 'Kakheti'],
    description:
      'Possibly Georgian grape best known for the sweet Kagor reds in Armenia.',
    'origin and parentage': [
      'Kakhet is a traditional variety of the provinces of Ararat and Armavir in western central Armenia but, as its name suggests, may have been introduced to Armenia long ago from the Kakheti region of Georgia. Further, Kakhet appears to be genetically close to Georgian varieties, in particular KHIKHVI and KISI, and not to Armenian varieties (Vouillamoz et al. 2006).',
    ],
    'where its grown': [
      'Ijevan Wine Factory make a dry Kakhet ros\u00e9 and a semi-sweet red but it is used by many other producers in Armenia such as Brest and Vedi Alco to make the traditional red dessert wine known as Kagor as well as dry reds.',
    ],
  },
  {
    name: 'KAKOTRYGIS',
    synonyms: ['Kako Tryghi', 'Kakotriguis', 'Kakotriki', 'Kakotryghis'],
    description:
      'Very minor variety found on the Greek island of K\u00e9rkyra (Corfu).',
    'origin and parentage': [
      'Kakotrygis is an old variety from the Ionian Islands, possibly from K\u00e9rkyra, where a red-berried mutation (Kokkino Kakotrygis) is also grown (Nikolau and Michos 2004). Its name refers to the fact that it is difficult to harvest because the stems are kakotryghos, ie hard to cut (Manessis 2000).',
    ],
    'where its grown': [
      'Kakotrygis is grown mainly on the island of K\u00e9rkyra, Greece, where it is the dominant variety in the island\u2019s dwindling vineyards, producing wines that are typically delicate, citrus-flavoured, with relatively high alcohol, medium acidity and good length, often blended with other varieties. Livadiotis, one of the few producers determined to craft serious wines from K\u00e9rkyra\u2019s indigenous vines, make a varietal wine from ungrafted plants in the sandy southern tip of the island. Halikouna regional wine must be 100% Kakotrygis whereas K\u00e9rkyra regional wines must include at least 60% with other local varieties.',
    ],
  },
  {
    name: 'KANGUN',
    synonyms: ['2-17-22', 'Cangoune', 'Kangoon', 'Kangoun'],
    description:
      'Armenian complex hybrid used to produce brandy and sweet, fortified whites.',
    'origin and parentage': [
      'Kangun is a complex hybrid obtained in 1979 by P K Aivazyan at the Armenian viticultural research centre in Merdzavan, just west of Armenia\u2019s capital, Yerevan, by crossing SUKHOLIMANSKY BELY with RKATSITELI.',
    ],
    'where its grown': [
      'Kangun is used to make brandy and sweet fortified wines, mainly in Armenia\u2019s Ararat Valley.',
    ],
  },
  {
    name: 'KANZLER',
    synonyms: ['Alzey S 3983'],
    description: 'Low-yielding and declining German cross.',
    'origin and parentage': [
      'A M\u00dcLLER-THURGAU \u00d7 SILVANER cross bred in 1927 by Georg Scheu at the Oppenheim research centre and included in the official list of varieties in 1987. The name Kanzler (meaning \u2018chancellor\u2019) is said to honour the first three post-war German chancellors, and at the same time the grape\u2019s qualities: it is refined like Konrad Adenauer, full and round like Ludwig Erhard and elegant like Kurt Georg Kiesinger!',
    ],
    'where its grown': [
      'Wines are full-bodied and potentially long-lived, needing a year in bottle to develop their aromas. There are 34 ha (84 acres) planted in Germany, clustered in Rheinhessen and the Pfalz, but it does not yield well and plantings are declining. Producers of sweet versions include Fleischmann in the Pfalz and Schales in Rheinhessen.',
    ],
  },
  {
    name: 'KAPISTONI TETRI',
    synonyms: [
      'Capistoni Tetri',
      'Kabistoni Tetri',
      'Kapistona',
      'Kapistoni',
      'Zekroula Kapistoni',
    ],
    description:
      'Ancient Georgian variety responsible for still and sparkling wines.',
    'origin and parentage': [
      'Kapistoni Tetri (tetri means \u2018white\u2019) is indigenous to Imereti in western central Georgia and is reputedly one of the oldest Georgian varieties (Chkhartishvili and Betsiashvili 2004). According to Galet (2000), the other white Georgian varieties Kapistoni Imeretinsky from Imereti and Kapistoni Rgvali from Racha and Kutaisi are distinct from Kapistoni Tetri. This is consistent with DNA profiling that shows Kapistoni Imeretinsky to be identical to Dondglabi (Vouillamoz et al. 2006).',
      'The black-berried ALEKSANDROULI is also known as Kabistoni but it is not a dark-skinned version of Kapistoni Tetri, as shown by morphological and genetic studies (Chkhartishvili and Betsiashvili 2004; Maghradze et al. 2009).',
    ],
    'where its grown': [
      'Planted in the western part of Georgia, particularly in Imereti, this ancient but relatively neutral variety is used to make both still and sparkling wines.',
    ],
  },
  {
    name: 'KAPITAN JANI KARA',
    synonyms: [
      'Adzhi Ibram Kara',
      'Agii Ibram',
      'Capitan Kara',
      'Chaban Khalil Kara',
      'Kapitan Yani Kara',
      'Ridzhaga',
    ],
    description: 'Minor Ukrainian component in sweet red blends.',
    'origin and parentage': [
      'Kapitan Jani Kara, literally \u2018Captain Jani\u2019s black\u2019, comes from Solnechnaya Dolina in eastern Krym (Crimea), in the far south of Ukraine.',
    ],
    'where its grown': [
      'Wines are typically full-bodied with rounded tannins and are generally blended with other local Ukraine varieties such as EKIM KARA to make dessert wines. Kapitan Jani Kara plays a bit part in Massandra\u2019s Black Doctor, for example.',
    ],
  },
  {
    name: 'KAPSELSKY',
    synonyms: [
      'Kapsel Skii',
      'Kapselski',
      'Kapselskii',
      'Matvienkovsky',
      'SD-62',
    ],
    description: 'Minor Ukrainian white.',
    'origin and parentage': [
      'Kapselsky comes from Krym (Crimea) in southern Ukraine and is morphologically similar to SOLNECHNODOLINSKY, another local variety.',
    ],
    'where its grown': [
      'Sun Valley (Solnechnaya Dolina) include Kapselsky in the blend of a sweet wine from Krym in Ukraine that comprises up to thirty different varieties, both indigenous and international.',
    ],
  },
  {
    name: 'KARALAHNA',
    synonyms: ['Kara Lahna', 'Lahna Kara'],
    description:
      'Found mainly on the Turkish island of Bozcaada in the Aegean, makes tannic but fresh reds.',
    'origin and parentage': [
      'Karalahna, literally \u2018black pumpkin\u2019, probably originates from \u00c7anakkale on the southern coast of the Dardanelles or from the island of Bozcaada in north-western Turkey.',
    ],
    'where its grown': [
      'Used mainly to make brandy until the early twentieth century, Karalahna makes wines that are typically deeply coloured, medium to full-bodied and tannic with high acidity and flavours of black and red berries in Turkey. They need time in bottle and marry well with oak \u2013 and care is needed not to over-extract the tannins. The main producers of varietal wines \u2013 Corvus, Talay and Yunat\u00e7\u0131lar \u2013 are on the Aegean island of Bozcaada, in the far west of Turkey, south of \u00c7anakkale, but the variety is also used to add colour in blends. Further east and north in Trakya (Thrace), Seyit Karag\u00f6zo\u011flu is producing a Karalahna-dominant blend, with MERLOT, from vineyards near the Sea of Marmara. The Karalahna seems more fragrant here. There were just 43 ha (106 acres) of Karalahna in Turkey in 2010 but it shows promise when handled gently.',
    ],
  },
  {
    name: 'KARASAKIZ',
    synonyms: [
      'Kara Sak\u0131z',
      'Karak\u0131z',
      'Karassak\u00fdz',
      'Kuntra',
      'Makbule',
      'Mavrupalya',
      'Sak\u0131z Kara',
    ],
    description:
      'Turkish variety making soft, light reds and being overtaken by the likes of Cabernet and Merlot.',
    'origin and parentage': [
      'Karasak\u0131z, literally meaning \u2018black chewing gum\u2019, also called Kuntra in Greece as well as in parts of Turkey, is a variety from the Dardanelles, the strait dividing Europe and Asia near Istanbul. It probably originates from the island of Bozcaada in the Aegean, at the south-western end of the strait.',
    ],
    'where its grown': [
      'Found mostly on the island of Bozcaada (T\u00e9nedos in Greek) in the Aegean Sea, Karasak\u0131z is also planted in \u00c7anakkale, Lapseki and Bayrami\u00e7 on mainland Turkey to the east. It was once much more widely planted because it was the main ingredient in the Turkish brandy made in large volumes by the former state monopoly Tekel. Although their brandy plant in \u00c7anakkale was closed down in 2007, Mey (who bought Tekel) still use Karasak\u0131z in the production of the anise-flavoured spirit raki, in Tekirdag. It is losing ground to international varieties such as CABERNET SAUVIGNON, with which it is sometimes blended. Producers of varietal wines include \u00c7aml\u0131ba\u011f, Corvus and Talay; the last also make sweet and pink versions (the former confusingly called Kuntra). Wines tend to be light- to medium-bodied with red-fruit and candy aromas, low acidity and soft tannins; they don\u2019t really stand up to oak. There were just 43 ha (106 acres) in Turkey in 2010.',
    ],
  },
  {
    name: 'KARMRAHYUT',
    synonyms: ['Karmrahiut', 'Karmraiute'],
    description: 'Dark-skinned, red-fleshed Armenian hybrid.',
    'origin and parentage': [
      'Karmrahyut, meaning \u2018red juicy\u2019, is a complex hybrid obtained in 1950 by S A Pogosyan at the Armenian viticultural research centre at Merdzavan, west of the capital, Yerevan, by crossing Adisi with NO 15-7-1, in which Adisi is an Armenian variety that is no longer cultivated and NO 15-7-1 is a hybrid between a vine of Vitis amurensis Ruprecht and Sladky Cherny (meaning \u2018sweet black\u2019), the latter being a selfling of PINOT NOIR. Karmrahyut was used to breed CHARENTSI and NERKARAT.',
    ],
    'where its grown': [
      'Karmrahyut is a red-fleshed variety planted mainly in Armavir in western central Armenia. Kazumoff, for example, make a deeply coloured varietal wine and MAP blend it with RKATSITELI and MEGRABUIR to produce a semi-sweet dessert wine.',
    ],
  },
  {
    name: 'KARNACHALADES',
    synonyms: ['Karnachalas'],
    description: 'Very rare variety from north-eastern Greece.',
    'origin and parentage': [
      'Obscure variety from the Evros region in north-eastern Thr\u00e1ki (Thrace), north-east Greece.',
    ],
    'where its grown': [
      'Bellas in the city of Soufli in north-east Thr\u00e1ki, Greece, produce both a varietal wine and a blend of Karnachalades with CABERNET SAUVIGNON and MERLOT.',
    ],
  },
  {
    name: 'KATSAKOULIAS',
    synonyms: [
      'Gyftokoritho',
      'Kaltsakouli',
      'Kartsakouli',
      'Katsacoul',
      'Katsakouli',
      'Katsakoulia',
    ],
    description:
      'Rare Greek variety almost exclusive to the island of Z\u00e1kynthos.',
    'origin and parentage': [
      'Katsakoulias is a rare variety from the Greek island of Z\u00e1kynthos in the Ionian Sea, off the western coast of the Pelop\u00f3nnisos (Peloponnese). A white-berried Katsakoulias has been observed in Chalk\u00edda on the island of \u00c9vvoia (Euboea) and in Mesol\u00f3ngi in Aitol\u00eda-Akarnan\u00eda (Galet 2000).',
    ],
    'where its grown': [
      'Katsakoulias is found mainly on the island of Z\u00e1kynthos, Greece, where it is used in blends. Comoutos, for example, make both a red and a ros\u00e9 based on traditional local varieties that include AVGOUSTIATIS and SKYLOPNICHTIS. Solomos and Callinico also include it in their red blends. The variety is found to a very limited extent in the Pelop\u00f3nnisos, eg in Aitol\u00eda-Akarnan\u00eda and Il\u00eda.',
    ],
  },
  {
    name: 'KATSANO',
    synonyms: ['Katsamon'],
    description: 'Rare variety just surviving on Santor\u00edni.',
    'origin and parentage': [
      'Katsano is probably indigenous to the Greek island of Santor\u00edni. It is genetically close to PLATANI from the Kykl\u00e1des (Cyclades; Biniari and Stavrakakis 2007).',
    ],
    'where its grown': [
      'Katsano is found on the Kykl\u00e1des and particularly on Santor\u00edni, Greece. Gavalas is possibly the only producer making a commercially available example, which is a field blend of approximately 85% Katsano and 15% GAIDOURIA. He describes the wine as having \u2018sweet aromas of flowers, honey and lemon blossom\u2019. The grassy character and lightness of the wine may be more to do with the early harvest than the nature of the grape itself since acidity is relatively low if the grapes are left to ripen fully.',
    ],
  },
  {
    name: 'KAY GRAY',
    synonyms: ['Elmer Swenson 1-63', 'ES 1-63'],
    description: 'Minor and not terribly successful American hybrid.',
    'origin and parentage': [
      'An Elmer Swenson 217 seedling obtained by Elmer Swenson (1913\u20132004), a grape breeder from Osceola, Wisconsin, US, in which the pollen parent (ie the \u2018father\u2019) is probably Onaka, which is an old South Dakota hybrid (see BRIANNA for the complete pedigree of Kay Gray). Kay Gray is therefore a complex Vitis\nriparia, Vitis\nlabrusca and Vitis\nvinifera hybrid. It was released in 1981 and named after a family friend.',
      'Kay Gray was used to breed BRIANNA and LOUISE SWENSON.',
    ],
    'where its grown': [
      'There are minor plantings of Kay Gray in the US Midwest (3 acres/1.2 ha in Minnesota in 2007) and Northeast, and also in Qu\u00e9bec in Canada. Wines are relatively neutral though they can have some floral notes. Unpleasant aromas may develop during winemaking if the grapes are picked fully ripe (Smiley 2008). Tom Plocher of the Minnesota Grape Growers Association suggests it is best blended with a small percentage of a better-quality vinifera wine such as SAUVIGNON BLANC. Varietal wines are produced by Bob Cedergren, La Vitac\u00e9e and Vignobles Les Vents d\u2019Ange in Qu\u00e9bec; Wilbur Thomas and Bob Williams in Minnesota; and Amazing Grace in New York State.',
    ],
  },
  {
    name: 'KEFESSIYA',
    synonyms: [
      'Cefecia',
      'Cefesia',
      'Doktorsky Chernyi',
      'Kefe Izyum',
      'Kefesia',
      'Kefesiya',
      'Kethessia',
    ],
    description:
      'Late-ripening variety from southern Ukraine that needs help to reproduce.',
    'origin and parentage': [
      'Kefessiya was probably named after Feodosiya (or Theodosia), a city on the Black Sea coast in Krym (Crimea), southern Ukraine, where the variety most likely originates. It is sometimes confused with EKIM KARA, meaning \u2018doctor\u2019s black\u2019, because of its similar synonym Doktorsky Chernyi.',
    ],
    'where its grown': [
      'Grown in Ukraine mainly around the city of Feodosiya and in the Sudak region to the south west. Massandra and Sun Valley (Solnechnaya Dolina) both include Kefessiya in their Black Doctor dessert wines.',
    ],
  },
  {
    name: 'K\u00c9KNYEL\u0170',
    synonyms: ['Blaust\u00e4ngler'],
    description:
      'Rare, potentially high-quality western Hungarian variety producing fragrant, high-acid whites.',
    'origin and parentage': [
      'K\u00e9knyel\u0171, meaning \u2018blue stalk\u2019 and referring to the slightly blue tint of the petiole, is an old variety probably originating from the Badacsony region in western Hungary. The alleged synonymy between K\u00e9knyel\u0171 and the Italian PICOLIT mentioned in the Vitis International Variety Catalogue has been disproved by DNA profiling (Jahnke et al. 2007).',
    ],
    'where its grown': [
      'Total plantings of K\u00e9knyel\u0171 in Hungary in 2009 amounted to just 41 ha (101 acres), although this was twice as much as at the turn of the century. It is grown mainly in Badacsony on the north side of Lake Balaton in the west but to a much more limited extent than in the past, thanks mainly to its poor and unreliable yields. K\u00e9knyel\u0171 at its best produces full-bodied, high-acid but fresh and highly fragrant wines which benefit from some time in bottle. Planting alternate rows with a variety such as BUDAI Z\u00d6LD has encouraged more consistent pollination. Recommended producers include Laposa, Endre Sz\u00e1szi and Szeremley.',
    ],
  },
  {
    name: 'KERATSUDA',
    synonyms: [
      'Breza',
      'Breznik',
      'Govedina',
      'Keratsouda',
      'Keratuda',
      'Mirizlivka',
      'Tsarevitsa',
    ],
    description:
      'Minor Bulgarian variety making simple whites in the Struma Valley.',
    'origin and parentage': [
      'Keratsuda, meaning \u2018girl\u2019 in Greek, is an indigenous Bulgarian variety that was probably born on the banks of the Struma, or Strym\u00f3nas, which runs through Bulgaria and Greece, where it has traditionally been cultivated (Katerov 2004).',
    ],
    'where its grown': [
      'Keratsuda is used to make simple, low-acid wines for early domestic consumption, although it is also made into a semi-sweet Jerez-style fortified wine. There were just 8 ha (20 acres) in Bulgaria in 2008, almost all in the province of Blagoevgrad in the far south west.',
    ],
  },
  {
    name: 'KERNER',
    synonyms: ['Weinsberg S 2530'],
    description:
      'Qualitatively the most successful modern German cross, versatile, producing Riesling-like wines.',
    'origin and parentage': [
      'A SCHIAVA GROSSA \u00d7 RIESLING cross bred by August Herold at the Weinsberg research centre in Baden-W\u00fcrttemberg, southern Germany, in 1929 and named after the nineteenth-century W\u00fcrttemberg medical doctor and writer of drinking songs Justinius Kerner, who used to recommend that his patients drink a glass of wine as the best natural medicine. Parentage has been confirmed by DNA profiling (Grando and Frisinghelli 1998). Kerner and ROTBERGER are siblings and Kerner was used to breed JUWEL.',
    ],
    'where its grown': [
      'Kerner is currently Germany\u2019s fifth most planted white variety, only just less popular than Grauburgunder (PINOT GRIS). Its 3,712 ha (9,173 acres), planted in many of Germany\u2019s wine-growing regions but predominantly in Rheinhessen and the Pfalz, are particularly impressive given that the variety was not authorized until 1969, even though the total area is now a good deal less than the 5,000 ha (12,355 acres) of 2003. However, it is still the most widely planted of the new German crosses.',
      'The wines are in many ways quite similar to RIESLING, making them suitable for varietal wines and not just blends, although the acidity is lower and the texture slightly coarser, and they can have a lightly grapey, leafy or candied note. Its popularity with growers is mainly due to its reliable yields and ability to produce higher levels of sugar and acidity than M\u00dcLLER-THURGAU in a wide range of sites, although its vigour needs curbing to produce high-quality wines. Some of the best examples are grown on the clay-limestone soils of Franken. Recommended producers include Karl Haidle and Staatsweingut Weinsberg in W\u00fcrttemberg; Divino Nordheim Winzergenossenschaft, Reiss and Rainer Sauer in Franken; Didinger in the Mittelrhein; Kees-Kieren in the Mosel; and Zimmerling in Sachsen.',
      'In the Alto Adige in northern Italy, Manfred N\u00f6ssing regularly wins Gambero Rosso\u2019s top Tre Bicchieri award for his Kerner although total plantings in Italy amount to no more than 25 ha (62 acres). Switzerland had 18 ha (44 acres) in 2008. England has just 9 ha (22 acres), down from over 20 ha (49 acres) in 1990, and its less vigorous mutation, known as Kernling, is heading in the same direction. British Columbia in Canada has 33 acres (13 ha) and there are very limited plantings in both California and South Africa. In the extreme conditions of Japan\u2019s northernmost island, Hokkaido, Kerner is producing some surprisingly good wines (Sapporo make more than one example); there was a total of 357 ha (882 acres) in Japan in 2009.',
    ],
  },
  {
    name: 'KHIKHVI',
    synonyms: ['Chichvi', 'Djananura', 'Janaani', 'Jananura', 'Khichvi'],
    description: 'Rare Georgian variety used for both dry and sweet wines.',
    'origin and parentage': [
      'Khikhvi is indigenous to the Kakheti region in south-eastern Georgia. The synonym Janaani is a village in Kakheti today called Ojio.',
    ],
    'where its grown': [
      'Khikhvi, found mainly in the Kakheti region in south-eastern Georgia, particularly in the villages of Kardenakhi, Gurjaani, Tibaani and Ojio, makes high-quality dry and sweet wines that are typically full-bodied and soft in acidity and have aromas of box (Buxus) but plantings are few and far between, with a total of around 20 ha (49 acres). Khikhvi is also well suited to vinification in the traditional Georgian qvevri (clay vessels), showing more mineral and floral tones. Recommended producers include Kindzmarauli Marani, Vinoterra and Winiveria. Galet (2000) suggests it is also found in the Republic of Moldova and Ukraine.',
    ],
  },
  {
    name: 'KHINDOGNI',
    synonyms: [
      'Chindogni',
      'Khendorni',
      'Khindogny',
      'Khndogni',
      'Scireni',
      'Sveni',
    ],
    description:
      'Versatile grape from the southern Caucasus used to make just about every style of red wine.',
    'origin and parentage': [
      'Khindogni, meaning \u2018laughing\u2019 or \u2018joyful\u2019, is indigenous to the breakaway region of Nagorno-Karabakh in south-west Azerbaijan, where it has traditionally been cultivated.',
    ],
    'where its grown': [
      'Khindogni is found mainly in Nagorno-Karabakh, the disputed enclave within south-west Azerbaijan, but also, according to Galet (2000), in Armenia, Uzbekistan, Iran and Russia. It produces full-bodied dry reds with high alcohol and is often harvested late to produce sweet, fortified styles. Stepanakert Brandy Factory in Nagorno-Karabakh produces both dry and semi-sweet varietal examples, and sparkling and ros\u00e9 wines can also be found. Other producers of dry wines include Absheron Sharab, Qabala Sharab and Gyoy-Gyol.',
    ],
  },
  {
    name: 'KIR\u00c1LYLE\u00c1NYKA',
    synonyms: ['D\u00e1nosi Le\u00e1nyka'],
    description: 'Hungarian variety producing light, aromatic whites.',
    'origin and parentage': [
      'Kir\u00e1lyle\u00e1nyka, meaning \u2018princess\u2019, is said by some to be identical to FETEASC\u0102 REGAL\u0102 from Romania (Galet 2000) and by others to be a natural cross between K\u00f6v\u00e9rsz\u0151l\u0151 (GRAS\u0102 DE COTNARI) and LE\u00c1NYKA originating from Transilvania, now in central Romania, that was introduced to Hungary in the 1970s (Roh\u00e1ly et al. 2003). However, comparison of their DNA profiles shows that Kir\u00e1lyle\u00e1nyka and Feteasc\u0103 Regal\u0103 from Romania are distinct varieties (Vouillamoz), and the proposed parentage has been rejected by DNA profiling (Galb\u00e1cs et al. 2009), thus challenging the possible Romanian origin of Kir\u00e1lyle\u00e1nyka. Le\u00e1nyka is possibly one of the parents of Kir\u00e1lyle\u00e1nyka.',
    ],
    'where its grown': [
      'This widely grown variety (942 ha/2,328 acres in Hungary in 2008) produces wines that are fresh and lightly perfumed, often grapey, making it particularly popular in the last ten years as a typical summer or party wine. Its fresh acidity makes it a good blending partner for other, softer aromatic varieties and it seems to do best in M\u00e1tra and Eger. Recommended producers include Bolyki (Eger), Bujdos\u00f3 and Ott\u00f3 L\u00e9gli (Balatonbogl\u00e1r), Etkeki K\u00faria (Etyek), Tam\u00e1s Szecsk\u0151 and M\u00e1ty\u00e1s and Zolt\u00e1n Sz\u0151ke (M\u00e1tra).',
    ],
  },
  {
    name: 'KISI',
    synonyms: ['Kissi', 'Maghranuli'],
    description:
      'Floral-scented Georgian variety made in both modern and traditional styles.',
    'origin and parentage': [
      'Kisi is indigenous to Kakheti in south-eastern Georgia. According to the Vitis International Variety Catalogue, Kisi is a cross between Mtsvane (it\u2019s not clear whether this refers to MTSVANE KAKHURI or GORULI MTSVANE) and RKATSITELI but this has been disproved by DNA analysis (Vouillamoz et al. 2006).',
    ],
    'where its grown': [
      'Kisi wines have intense floral aromas as well as flavours of dried pear and apples that give the impression of sweetness even when the wine is dry. There are two main styles: modern European and traditional Georgian, the latter fermented in qvevri, traditional clay vessels, showing more apricot, mint and orange characteristics. There were 50 ha (124 acres) of Kisi in Kakheti, south-eastern Georgia, in 2008, notably in the villages of Maghraani and Argokhi, and it is often part of a local blend. Recommended producers of varietal wines include Kindzmarauli Marani, Vashadze and Winiveria in a European style and Alavardi Monastery Cellar, Vinoterra and Pheasant\u2019s Tears in the more traditional Georgian style. Many producers blend Kisi with other local varieties such as RKATSITELI. Telavi Wine Cellar blend Kisi with MTSVANE KAKHURI.',
    ],
  },
  {
    name: 'KLARNICA',
    synonyms: ['Klarna Mieja', 'Klarnca', 'Klarnitza', 'Mejina'],
    description:
      'Minor but characterful Slovenian variety making sweet-smelling whites.',
    'origin and parentage': [
      'Klarnica is an old variety most likely originating from Vipavska Dolina in the Primorska region in south-west Slovenia.',
    ],
    'where its grown': [
      'There were just 3 ha (7 acres) of Klarnica in Slovenia in 2009, all in the Vipavska Dolina wine region in Primorska in the south west. Producers include Mansus and Stegovec and the wines are typically full-bodied and dry or semi-sweet with surprisingly sweet, floral or fruity (canned strawberries?) aromas. It is also used to make sparkling wine.',
    ],
  },
  {
    name: 'KNIPPERL\u00c9',
    synonyms: [
      'Ettlinger',
      'Gelber Ortlieber',
      'Kleiner R\u00e4uschling',
      'Kniperl\u00e9',
      'Ortlieber',
      'Petit Mielleux',
      'Petit R\u00e4uschling',
      'Reichenweiherer',
      'Strassburger',
      'T\u00fcrckheimer',
      'Wesser Ortlieber',
    ],
    description: 'Light-skinned, rot-prone Alsace variety on the way out.',
    'origin and parentage': [
      'Knipperl\u00e9 most probably comes from T\u00fcrckheim in Alsace, north-east France. It was first mentioned in 1780 in nearby Riquewihr as \u2018Knipperling .\u00a0.\u00a0. improprie kleine R\u00fcschling\u2019 (\u2018Knipperling .\u00a0.\u00a0. incorrectly Kleine R\u00fcschling\u2019), although the modern spelling Knipperl\u00e9, meaning \u2018small size\u2019 in the Alsatian dialect, did not appear until 1848 (R\u00e9zeau 1997). According to Jean-Louis Stoltz (1852) from S\u00e9lestat, between Colmar and Strasbourg, this variety was initially cultivated under the name Petit-Mielleux in the area around T\u00fcrckheim, where the most widespread variety was ELBLING. Between 1760 and 1770, the early ripener Elbling produced extremely low yields due to the colder climate, while the late-ripening and more resistant Knipperl\u00e9 produced riper and more abundant fruit. In the space of a few years, Knipperl\u00e9 spread to the rest of Alsace, mainly near Colmar (Katzenthal, Kaysersberg, Ingersheim, Niedermorschweyer, Mittelweyer, Siegolsheim and T\u00fcrckheim) and Ribeauvill\u00e9 (Beblenheim, Hunawihr and Riquewihr).',
      'From 1780, the Riquewihr vine grower Jean-Michel Ortlieb (1730\u20131807) actively advertised this variety for its good yields, disease resistance and precocity, and exported large quantities of Knipperl\u00e9 cuttings to neighbouring Baden-W\u00fcrttemberg. In 1789, Ortlieb even claimed rather pompously: \u2018daignerait-on pardonner \u00e0 ma vanit\u00e9, si j\u2019osais former le d\u00e9sir, que ce petit R\u0153uschlinger f\u00fbt nomm\u00e9 de pr\u00e9f\u00e9rence, le cru d\u2019Ortlieb\u2019 (\u2018would my vanity be forgiven if I dared to formulate the desire that this petit R\u0153uschlinger be preferably named the cru of Ortlieb\u2019; R\u00e9zeau 1997). Since that time, the variety has been known in W\u00fcrttemberg under the name Ortlieber or Reichenweiherer (meaning \u2018from Riquewihr\u2019), and then under numerous additional synonyms (see above).',
      'The German ampelographer Lambert von Babo (1843\u20134) suggested a genetic relationship between Knipperl\u00e9 (under the name Ortlieber) and PINOT BLANC. More than a century later, his hypothesis was confirmed by DNA parentage analysis, which established that Knipperl\u00e9 is one of the twenty-one natural progenies of PINOT \u00d7 GOUAIS BLANC (Bowers et al. 1999; see Pinot pedigree diagram). This spontaneous cross most likely occurred in the neighbourhood of T\u00fcrckheim, some time before the eighteenth century. DNA profiling has also disproved the supposed identity of Knipperl\u00e9 with the German variety R\u00c4USCHLING, which is in fact a half-sibling, as well as with FOLLE BLANCHE and Pinot Blanc.',
      'Knipperl\u00e9 was used to breed TRIOMPHE.',
    ],
    'where its grown': [
      'After a peak in the late eighteenth century, the area planted to Knipperl\u00e9 in what is now France has decreased continuously \u2013 not helped by the fact that it is not permitted in any Alsace appellation wine \u2013 and the variety has virtually disappeared from Alsace and Baden-W\u00fcrttemberg in Germany. However, Alois Raubal has 1,000 Gelber Ortlieber vines on his estate in Gumpoldskirchen, south-west of Wien (Vienna) in Austria, and produces a varietal wine. Knipperl\u00e9 tends to produce soft, pleasant, easy-drinking wines with highish alcohol.',
    ],
  },
  {
    name: 'KOK PANDAS',
    synonyms: ['Coc Pandas', 'Kok Pandasse', 'Pandas Kok', 'Tken Izyum'],
    description: 'Vine making full-bodied whites in southern Ukraine.',
    'origin and parentage': [
      'Kok Pandas is an old variety from Solnechnaya Dolina in the Sudak district of Krym (Crimea), southern Ukraine.',
    ],
    'where its grown': [
      'Kok Pandas, planted mainly in the Sudak region in Krym, southern Ukraine, produces full-bodied dry wines and is used in sweet and/or fortified blends such as Sun Valley\u2019s eponymous dessert wine comprising up to thirty different varieties and in their fortified RKATSITELI and Kok Pandas blend.',
    ],
  },
  {
    name: 'KOKUR BELY',
    synonyms: ['Belji Dolgi', 'Dolgi', 'Kokour Blanc', 'Kokuri Belji'],
    description: 'Multi-purpose Ukrainian variety with possible Greek origins.',
    'origin and parentage': [
      'Kokur Bely is an old variety of unknown origin that was supposedly cultivated as early as the fourteenth and fifteenth centuries in Krym (Crimea), southern Ukraine, where it used to be the most important white grape variety (Chkhartishvili and Betsiashvili 2004). It is said to have been introduced from the Greek island of K\u00e9rkyra (Corfu; Galet 2000).',
    ],
    'where its grown': [
      'Kokur Bely is a versatile grape, used in Ukraine for the table, grape juice and a range of wine styles, including dry and sweet still wines, fizz and fortified wines. It is quite widely planted around Alushta and Feodosiya in the far south of Krym, along the Black Sea coast, with total plantings of around 918 ha (2,268 acres) in Ukraine in 2009. Massandra Winery, for example, make a sweet varietal wine, and Sun Valley\u2019s Kokur is dry with spice, floral and honey aromas.',
      'It is also found in Rostov, south-western Russia, and in Kyrgyzstan.',
    ],
  },
  {
    name: 'KOLINDRINO',
    synonyms: ['Kolindros'],
    description: 'Rare Greek variety named after its place of origin.',
    'origin and parentage': [
      'Obscure grape variety grown in Makedon\u00eda, most likely named after the village of Kolindros. There has so far been no DNA analysis to determine its identity or uniqueness.',
    ],
    'where its grown': [
      'Pieria Eratini\u2019s Gymnos Vasilias (\u2018Naked King\u2019) Syrah from Kolindros, south west of Thessalon\u00edki in Makedon\u00eda, Greece, includes a small percentage of this variety, which Pavlos Argyropoulos first planted in 1999 in the hilly Kolindros region, encouraged by the area\u2019s past reputation for good-quality wine. According to Argyropoulos, Kolindrino contributes firm tannins and an attractively bitter aftertaste to the blend.',
    ],
  },
  {
    name: 'KORINTHIAKI',
    synonyms: [
      'Alga Passera',
      'Black Corinth',
      'Corinthe Noir',
      'Corinto Negro',
      'Corinto Nero',
      'Crni Korint',
      'Currant Grape',
      'Korinthiaki Stafida',
      'Korinthiaki Stafis',
      'Korinthusi K\u00e9k',
      'Lianorogi',
      'Mavri Stafis',
      'Passerilla',
      'Passula di Corinto',
      'Raisin de Corinthe',
      'Stafida',
      'Stafidambelo',
      'Zante Currant',
    ],
    description: 'The currant grape.',
    'origin and parentage': [
      'Korinthiaki, literally \u2018Corinthian\u2019 in Greek, is a seedless grape that allegedly comes from K\u00f3rinthos (Corinth) in north-east Pelop\u00f3nnisos (Peloponnese). However, its true origin may not be in Greece at all because a morphological and genetic study has distinguished it from most other Mediterranean wine grape varieties (Aradhya et al. 2003). DNA profiling has shown that neither Corinto Bianco from Italy nor Corinthe Blanc from Greece are colour mutations of Black Corinth (Vargas et al. 2007) but more recent profiling (under the name Black Corinth) has suggested a possible parent\u2013offspring relationship with LIATIKO (Myles et al. 2011).',
    ],
    'where its grown': [
      'In the nineteenth century, the northern Pelop\u00f3nnisos, Greece, grew rich from Korinthiaki raisins, particularly thanks to exports to the UK. Today Korinthiaki is still used for drying but the fruit is also blended (up to a maximum of 49%) with MAVRODAFNI to make the sweet, fortified red wine traditional in this area and recognized under the Mavrodafni of P\u00e1tra appellation. The inclusion of sweet-fruited Korinthiaki often compensates for the low sugar levels of Mavrodafni grown in P\u00e1tra but does little for the quality of the final wine (Lazarakis 2005). It was still the second most planted variety on the Ionian Islands in 2008, with a total of 478 ha (1,181 acres), and there were 357 ha (882 acres) in western Greece.',
      'The vast majority, if not all, of California\u2019s 1,682 acres (681 ha) of Black Corinth are for the production of raisins. When a shortage of wine grapes has coincided with a surplus of Black Corinth, in the 1970s and 1990s, for example, the fruit has occasionally been used for blending or colour.',
    ],
  },
  {
    name: 'KORIOSTAFYLO',
    synonyms: ['Koriostaphylo'],
    description:
      'Very rare Greek variety that seems to be exclusive to the island of Ikar\u00eda.',
    'origin and parentage': [
      'Obscure local variety on the island of Ikar\u00eda in the Aegean Sea, just west of S\u00e1mos. Its identity has not yet been revealed by DNA analysis.',
    ],
    'where its grown': [
      'Koriostafylo appears to be grown exclusively on Ikar\u00eda, Greece, where Afianes blend it with other local varieties such as FOKIANO.',
    ],
  },
  {
    name: 'KOTSIFALI',
    synonyms: ['Kotrifali', 'Kotsiphali', 'Kotzifali'],
    description:
      'Oft-maligned variety widely planted on Kr\u00edti (Crete), producing soft, pale, alcoholic but potentially aromatic and distinctive reds.',
    'origin and parentage': [
      'This variety most likely originates from Kr\u00edti or the Kykl\u00e1des (Cyclades; Nikolau and Michos 2004). In the Greek Vitis Database, the DNA profile of Korfiatiko (or Korfiatis), a non-cultivated dark-skinned variety from the Ionian Islands, is, surprisingly, identical to Kotsifali, although they are ampelographically quite distinct (Vouillamoz).',
    ],
    'where its grown': [
      'Wines tend to be pale, fairly high in alcohol but soft and generous so that they generally benefit from the addition of the more deeply coloured, firmer structured and less alcoholic MANDILARIA, a blend that is stipulated in the Peza and Ach\u00e1rnes appellations on the variety\u2019s home territory on the island of Kr\u00edti, Greece. More recently producers have been experimenting with Kotsifali/SYRAH blends, but it is easy to see why the variety is not treated with great respect, especially when it is older vines that seem to show the variety\u2019s potential. On the other hand, a proportion of Kotsifali can add a distinctive perfume of strawberries, spices and herbs to a red blend. The colour is not only hard to extract from the berries\u2019 thin skins but it is also unstable and wines often look quite developed even when young.',
      'Kotsifali is rarely found outside Kr\u00edti, where it is the second most planted variety (1,264 ha/3,123 acres in 2008) after LIATIKO, with the greatest concentration of plantings on the eastern side of the island in Ir\u00e1kleio (Heraklion). Recommended producers of varietals and blends include Boutaris, Lyrarakis, Mediterra and Tamiolakis. Lyrarakis make a sour-cherry-flavoured varietal wine that manages to retain sufficient acidity to be long and fresh \u2013 but quite demanding.',
    ],
  },
  {
    name: 'KOUTSOUMPELI',
    synonyms: [
      'Koutsoubeli',
      'Koutsoumbeli',
      'Koutsoumbeli Kokkino',
      'Koutsoumpeli Kokkino',
    ],
    description: 'Rare Greek pink-skinned variety.',
    'origin and parentage': [
      'Koutsoumpeli is from the Greek island of Z\u00e1kynthos. According to the viticulturist Haroula Spinthiropoulou, there is also a white-berried clone which is more common than the pinky red one.',
    ],
    'where its grown': [
      'Koutsoumpeli is found in the regions of Il\u00eda, north-west Pelop\u00f3nnisos (Peloponnese), Greece, in Aitol\u00eda-Akarnan\u00eda (south-west mainland) and especially on the island of Z\u00e1kynthos, where Logothetis make a varietal ros\u00e9 from this pink-skinned variety.',
    ],
  },
  {
    name: 'K\u00d6VIDINKA',
    synonyms: [
      'Dinka Alba',
      'Dinka Feh\u00e9r',
      'Kevedinka',
      'Kevidinka',
      'K\u00f6vidinka Rose',
      'Rosentraube',
      'Ruzsitza',
      'Steinschiller',
    ],
    description:
      'Common pink-skinned Hungarian variety making commonplace whites.',
    'origin and parentage': [
      'K\u00f6vidinka is a Hungarian variety that was allegedly cultivated in the Middle Ages. It is said to originate in the Carpathian Basin, possibly from Slavonija in Croatia (Kocsis et al. 2005).',
      'According to Constantinescu in Galet (2000), K\u00f6vidinka may have come from Germany and been introduced to the historical Central European Banat region by German colonists. However, the DNA profile of K\u00f6vidinka in Galb\u00e1cs et al. (2009) does not correspond to any modern German variety (Vouillamoz). K\u00f6vidinka is sometimes called Maka Dinka, but this is a synonym for SAVAGNIN ROSE, which is quite distinct from K\u00f6vidinka.',
      'K\u00f6vidinka was used to breed KAR\u00c1T and SILA.',
    ],
    'where its grown': [
      'K\u00f6vidinka is widely planted in Central and Eastern Europe but particularly in central southern Hungary in the Kuns\u00e1g and Csongr\u00e1d regions of the Alf\u00f6ld (Great Plain), where it makes light, soft and typically fairly neutral whites for everyday drinking. Total plantings in Hungary in 2008 were 858 ha (2,350 acres) and recommended producers include Font in Kuns\u00e1g.',
    ],
  },
  {
    name: 'KRAKHUNA',
    synonyms: ['Chkovra', 'Krakhuna Shavi'],
    description:
      'Minor but well-established Georgian variety producing fresh, fruity whites.',
    'origin and parentage': [
      'Krakhuna is indigenous to the Imereti region in western central Georgia and was already known in the eighteenth century.',
    ],
    'where its grown': [
      'Krakhuna wines typically have flavours of ripe pear as well as more savoury and herbal notes and fresh acidity, with the best examples ageing well in bottle. There were just 35 ha (86 acres) planted in 2004, mostly in the villages of Dimi and Sviri in the Imereti region of western central Georgia, where Khareba make a good varietal example. The variety also shows good potential for high-quality fortified wine.',
    ],
  },
  {
    name: 'KRALJEVINA',
    synonyms: ['Imbrina', 'Kraljevina Crvena', 'Moravina'],
    description:
      'Old Croatian variety producing simple, refreshing everyday whites.',
    'origin and parentage': [
      'Despite the linguistic contraindication in the synonym Kraljevina Crvena (crvena means \u2018red\u2019 in Serbo-Croatian and kraljevina means \u2018kingdom\u2019), Kraljevina is a white-berried variety, possibly from Sveti Ivan Zelina near Zagreb, Croatia. With exposure to the sun, the berries tend to take on a slightly red tint at harvest, hence the synonym. Kraljevina shows a significant diversity of types with white, green, reddish and speckled berries (Edi Maleti\u0107, personal communication), suggesting that this is an old variety.',
      'Kraljevina is also known as Portugieser Rot, but it is not related to BLAUER PORTUGIESER, known in Croatia as Portugizac Crni, nor to any Portuguese variety (Vouillamoz).',
    ],
    'where its grown': [
      'Wines tend to be moderate to low in alcohol, non-aromatic, high in acidity, sometimes sour, but are popular for refreshing, simple, everyday drinking. Kraljevina is grown only in northern Croatia, around Zagreb, and is recommended in the Prigorje-Bilogora subregion. The wines may be labelled Kraljevina Zelina after the Sveti Ivan Zelina region. It is also found in Slovenia, where it is one of the many varieties permitted in the traditional, light-red blend known as Cvi\u010dek but rarely appears as a varietal wine.',
    ],
  },
  {
    name: 'KRASNOSTOP ZOLOTOVSKY',
    synonyms: ['Krasnostop', 'Krasnostop Anapsky'],
    description:
      'Russian variety making full-bodied dry reds as well as sweet fizz.',
    'origin and parentage': [
      'Krasnostop Zolotovsky is a variety from the Rostov wine region in south-western Russia, near the border with Ukraine, where it was first mentioned in 1814. It was traditionally grown in the Krasnodar and Anapa regions, hence its name and synonyms. Krasnostop Anapsky is not a distinct variety but a clone of Krasnostop Zolotovsky. The synonym Cherny Vinny is also given to PLECHISTIK, but the two varieties are distinct (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Wines are typically rich in tannins, alcohol and acidity, with flavours of dark plums, redcurrant and green pepper. The variety is found in both the Rostov (66 ha/163 acres in 2010) and Krasnodar wine regions in Russia. In the former, Tsimlyansky Winery include it in the blend for their sweet sparkling red wine and, in the latter, Chateau Le Grand Vostock blend it with varieties such as SAPERAVI, CABERNET SAUVIGNON and GOLUBOK for dry reds. Vedernikov Winery make a dry varietal wine and also a blend with Cabernet Sauvignon on the banks of the River Don.',
    ],
  },
  {
    name: 'KRASSATO',
    synonyms: ['Krasata', 'Krasato'],
    description: 'Greek variety integral to the Raps\u00e1ni appellation.',
    'origin and parentage': [
      'Krassato is considered to be indigenous to the Raps\u00e1ni region in the foothills of Mount \u00d3lympos (\u00d3ros \u00d3limbos in Greek) in the province of Lar\u00edsa in Thessal\u00eda in the east of central mainland Greece (Manessis 2000; Nikolau and Michos 2004).',
    ],
    'where its grown': [
      'Krassato is blended with equal parts of XINOMAVRO and STAVROTO within the Raps\u00e1ni appellation in Greece \u2013 usually a field blend \u2013 to make a rich, well-structured, spicy, sometimes earthy wine that is often aged in oak. Krassato wines are rich in alcohol but have medium acidity and tannins and do not generally age well, hence the requirement to blend with Xinomavro in particular. Krassato is also found further to the south in Magnis\u00eda and occasionally to the north in Makedon\u00eda. Recommended producers of Raps\u00e1ni include Dougos and Tsantali.',
    ],
  },
  {
    name: 'KREACA',
    synonyms: [
      'Banat Riesling',
      'B\u00e1n\u00e1ti Rizling',
      'Banatski Rizling',
      'Crea\u0163\u0103',
      'Crea\u0163\u0103 de Banat',
      'Franchie',
      'Kre\u00e1ca',
      'Kreatza',
      'Kriaca',
      'Riesling Banatsky',
      'Zakkelweiss',
    ],
    description: 'Rare Balkan pale-skinned vine now found mainly in Serbia.',
    'origin and parentage': [
      'Like SLANKAMENKA, Kreaca is a very old Balkan variety, possibly originating in the Banat, a historical region spanning parts of today\u2019s Romania, Serbia and Hungary and once part of Hungary. The variety shows significant morphological diversity within each country (Avramov and del Zan 2004), another indication of its age.',
    ],
    'where its grown': [
      'Serbia\u2019s Banat Rizling appellation allows a blend of GRA\u0160EVINA (Italian Riesling), Smederevka (DIMYAT), \u017dupljanka (CHASSELAS) and Kreaca. In Hungary, B\u00e1n\u00e1ti Rizling has almost disappeared \u2013 there were 3 ha (7 acres) in 2008 and the vineyards of the leading producer, Oszk\u00e1r Maurer, are over the border in Serbia. His \u00c9des Erd\u00e9ly is a blend of M\u00c9ZES FEH\u00c9R and B\u00e1n\u00e1ti Rizling. Wines are light on aroma but may be rich, sometimes heavy, in the mouth. The variety is also found in Romania.',
    ],
  },
  {
    name: 'KRKO\u0160IJA',
    synonyms: ['Krkochia', 'Krkoshia', 'Kyrkochia'],
    description:
      'Minor blending partner for \u017dilavka in Bosnia and Herzegovina.',
    'origin and parentage': [
      'Krko\u0161ija possibly originates in the Mostar region in the south of Bosnia and Herzegovina.',
    ],
    'where its grown': [
      'Krko\u0161ija usually plays a bit part alongside Bosnia and Herzegovina\u2019s principal indigenous white variety, \u017dILAVKA \u2013 up to 15% is permitted in the appellation \u017dilavka Mostar. Producers include \u010citluk Winery and Hepok.',
    ],
  },
  {
    name: 'KRSTA\u010c',
    synonyms: [
      'Bijeli Krsta\u010d',
      'Krata Bijela',
      'Krsta\u010d Bijela',
      'Loza Bijela',
      'Vinogradarska Bijela',
    ],
    description: 'Old Montenegrin variety producing light, summery whites.',
    'origin and parentage': [
      'Krsta\u010d is an ancient variety from Montenegro, possibly from the Podgorica region in the south. Krst means \u2018cross\u2019 in Montenegrin, a reference to the shape of the bunch. There is also a black-berried and non-cultivated variety, Krsta\u010d Crna, but so far DNA profiling has not been done to verify if one is a colour mutation of the other, or if Krsta\u010d Crna is identical to TRIBIDRAG (Zinfandel), called Krato\u0161ija in Montenegro with Krsta\u010d Crna a common and confusing synonym.',
    ],
    'where its grown': [
      'Wines are light-bodied and fresh, with flavours of green apples, pear and elderflower. Plantaze are currently the only producers of a varietal wine, from vines planted in Podgorica, Montenegro.',
    ],
  },
  {
    name: 'KUJUND\u017dU\u0160A',
    synonyms: [
      'Kojund\u017eu\u0161a',
      'Kujund\u017eu\u0161a Bijela',
      'Tvrdac',
      '\u017dutac',
      '\u017dutka',
    ],
    description: 'Rare Dalmatian variety.',
    'origin and parentage': [
      'Kujund\u017eu\u0161a probably comes from the Imotski region of Croatia, close to the Bosnia and Herzegovina border, south east of Split.',
    ],
    'where its grown': [
      'Kujund\u017eu\u0161a is authorized in the Dalmatinska Zagora (Dalmatian Hinterland) wine region and is the main white variety in the area around the town of Imotski, south east of Split in southern Croatia. It represented 2.2% of the total Croatian vineyard area in 2009, ie around 715 ha (1,767 acres). Varietal wines are produced by, for example, Imota (Imotski), Jerkovi\u0107-Promet (Runovi\u0107) and Grabovac (Donji Prolo\u017eac). Wines tend to be relatively light-bodied.',
    ],
  },
  {
    name: 'KUPUSAR',
    synonyms: ['Plavac Kupusar'],
    description: 'Very rare, tannic variety from coastal Croatia.',
    'origin and parentage': [
      'A rare variety from the Ka\u0161tela region, north of Split, Croatia, also found on the nearby islands of Hvar and Bra\u010d. Kupusar is often called Plavac Kupusar although it is unrelated to the more famous PLAVAC MALI. The name Kupusar points to the very large leaves of this variety: kupus means \u2018cabbage\u2019 in Croatian.',
    ],
    'where its grown': [
      'Kupusar is not currently made as a varietal wine. It is generally found in mixed vineyards in Croatia and is often blended with PLAVAC MALI. Toli\u0107 Tomis in Trogir, just west of Split, includes it in a blend with BABI\u0106 (under the name Rogozni\u010dka), BABICA, TRIBIDRAG (under the name Crljenak) and GLAVINU\u0160A.',
    ],
  },
  {
    name: 'KYDONITSA',
    synonyms: ['Kidonitsa'],
    description:
      'Promising but still very minor variety producing aromatic, finely textured wines near Monemvasia.',
    'origin and parentage': [
      'An almost forgotten variety from the southern Pelop\u00f3nnisos (Peloponnese). Its name is the diminutive of quince (kydoni).',
    ],
    'where its grown': [
      'Kydonitsa is grown in Lakon\u00eda, Greece, and particularly in the eastern finger of the southern Pelop\u00f3nnisos near Monemvasia, where it was found in old vineyards and replanted by pioneering producers such as Yiannis Vatistas and George Tsibidis (Monemvasia Winery). Both make varietal wines, which range from strongly quince-like, as the name might predict, to more mineral- and citrus-flavoured, with a chalky texture, both powerful and fresh. Even wines from young vines seem to have remarkable concentration and suggest a promising future. Although local growers have been encouraged by government subsidies to plant Kydonitsa, without any marketing support it is still little known and hard to promote. Antonopoulos have also planted this variety at the opposite, northern, extreme of the Pelop\u00f3nnisos.',
    ],
  },
  {
    name: 'L\u2019ACADIE BLANC',
    synonyms: ['Acadie', 'L\u2019Acadie', 'V 53261'],
    description:
      '1950s Canadian very complex hybrid performing well in the chilly climes of Nova Scotia and Qu\u00e9bec.',
    'origin and parentage': [
      'A CASCADE \u00d7 Seyve-Villard 14-287 hybrid made in 1953 by Ollie A Bradt at the Vineland research centre (now part of Guelph University) in Niagara, Ontario, Canada, in which:',
      '\u2013\u00a0\u00a0\u00a0Seyve-Villard 14-287 is a Seibel 6746 \u00d7 Muscat du Moulin hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 6746 is a hybrid made by Albert Seibel in Aubenas in the Ard\u00e8che, France, with unknown parents',
      '\u2013\u00a0\u00a0\u00a0Muscat du Moulin is a Couderc 603 \u00d7 PEDRO XIM\u00c9NEZ hybrid',
      '\u2013\u00a0\u00a0\u00a0Couderc 603 is a BOURRISQUOU \u00d7 Vitis rupestris hybrid.',
      'Under the code V 53261, this variety was sent for testing at the Kentville research centre in Nova Scotia, where it was named after the region of Canada and north-eastern North America that was once a French colony. The pedigree was revealed by Helen Fisher at the Department of Plant Agriculture at the University of Guelph.',
      'L\u2019Acadie is therefore an extremely complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\naestivalis, Vitis\nlincecumii, Vitis\nrupestris, Vitis\ncinerea and Vitis\nberlandieri hybrid.',
    ],
    'where its grown': [
      'L\u2019Acadie Blanc is grown mostly in Nova Scotia, eastern Canada, with just a few plantings in Qu\u00e9bec and some very recent ones in northern central Ontario. Its wine is generally blended with other varieties such as VANDAL-CLICHE, SEYVAL BLANC and VIDAL, eg by Domaine St-Jacques for a dry, still wine and Vignoble Carone for a frizzante style. Varietal wines are more full-bodied than Vandal-Clich\u00e9 and may have honey and floral aromas. The variety may suffer from low acidity in the warmer parts of Ontario, hence a slow increase in plantings in cooler areas of the province, but it shows promise in Qu\u00e9bec thanks to its good disease resistance and overall balance and quality potential.',
    ],
  },
  {
    name: 'LA CRESCENT',
    synonyms: ['MN 1166'],
    description:
      'Promising and increasingly popular cold-hardy American hybrid.',
    'origin and parentage': [
      'A complex hybrid of ST PEPIN and ES 6-8-25 made in 1988 by Peter Hemstad and James Luby at the University of Minnesota\u2019s Horticultural Research Center in Excelsior, in which ES 6-8-25 is a Vitis riparia \u00d7 MUSCAT OF HAMBURG hybrid selected by Elmer Swenson, a grape breeder from Osceola, Wisconsin. La Crescent was selected in 1992, tested under the name MN 1166 and released in 2002. This interspecific hybrid includes 45% Vitis vinifera, 28% Vitis riparia, and less than 10% each of Vitis rupestris, Vitis labrusca and Vitis aestivalis.',
    ],
    'where its grown': [
      'The variety\u2019s high acidity makes it suitable for sweet wines and the intense aromas contribute usefully to an otherwise neutral variety or blend. Wines are said to have aromas of apricot, peach and citrus and to lack any foxy flavours, provoking increasing interest in this variety among the more recent cold-hardy American hybrids in the Midwest and Northeast. There were around 160 acres/65 ha of La Crescent in Minnesota in 2007 and small areas in Iowa (19 acres/8 ha in 2006), Nebraska (2 acres/0.8 ha in 2007), Michigan and even in Ontario, Canada. Producers of varietal wines include Falconer, Fieldstone, Parley Lake and St Croix Vineyards in Minnesota; and Lincoln Peak in Vermont.',
    ],
  },
  {
    name: 'LA CROSSE',
    synonyms: ['ES 294', 'LaCrosse'],
    description:
      'Complex American hybrid starting to achieve some success in the US Midwest.',
    'origin and parentage': [
      'La Crosse is an Elmer Swenson 114 \u00d7 SEYVAL BLANC hybrid obtained around 1970 by Elmer Swenson (1913\u20132004), a grape breeder from Osceola, Wisconsin, US (see ST PEPIN for the pedigree of Elmer Swenson 114). A very complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\nlincecumii and Vitis\nrupestris hybrid, La Crosse was released in 1983, presumably named after the city of that name in western Wisconsin. It is a sibling of ST PEPIN.',
    ],
    'where its grown': [
      'Aromas of La Crosse wines range from pear and apricot to citrus and floral, making them good as varietals but also as a blending component that adds body to lighter varieties. The most successful styles are dry and oak aged or fruity and semi-sweet. The variety is planted mainly in the Midwest, eg in Iowa (33 acres/13 ha in 2006), Nebraska (around 28/11 ha acres in 2007), Minnesota (16 acres/6 ha in 2007), Illinois (10 acres/4 ha in 2007), but also to a very limited extent in Pennsylvania and even Nevada. Producers of varietal wines include Breezy Hills, Prairie Crossing and Two Saints in Iowa; Cuthills and Glacial Till in Nebraska; Long Creek in Illinois. Tahoe Ridge in Nevada even produce a La Crosse Ice Wine.',
    ],
  },
  {
    name: 'LACRIMA DI MORRO D\u2019ALBA',
    synonyms: ['Lacrima'],
    description:
      'Recovered, very local Marche speciality used for both dry and sweet early-maturing reds',
    'origin and parentage': [
      'The name Lacrima (English \u2018teardrop\u2019) was probably given to this variety because when the berries are fully ripe, they exude small drops of juice. However, several distinct varieties in various regions of central and southern Italy (Campania, Marche, Toscana, Umbria, Puglia, etc.) were given names based on Lacrima. In the Marche, Lacrima is a local variety of the Morro d\u2019Alba region mentioned as early as the eighteenth century. DNA studies suggest a close relationship between Lacrima di Morro d\u2019Alba and ALEATICO (Filippetti, Silvestroni, Thomas and Intrieri 2001).',
      'The table grape Lacrima di Maria is not related to Lacrima.',
    ],
    'where its grown': [
      'In the twentieth century, Lacrima di Morro d\u2019Alba was close to extinction but was rescued in 1985 when it was finally included in the national register of varieties in Italy for the Lacrima di Morro d\u2019Alba DOC, in which it must comprise at least 85% (potentially blended with SANGIOVESE, MONTEPULCIANO or even the white VERDICCHIO BIANCO). Its total area jumped from 1 ha (2.5 acres) in 1985 to 102 ha (252 acres) in 2000 and it is now cultivated in the following villages in the province of Ancona in the Marche: Morro d\u2019Alba, Monte San Vito, San Marcello, Belvedere Ostrense, Ostra and Senigallia. The wines are scented with wild strawberries, sometimes darker red fruit, may be dry or sweet (passito) and tend to mature quickly. Tannins are usually fine and the acidity refreshing. Recommended producers include: Colonnara, Conti di Buscareto, Piergiovanni Giusti, Luciano Landi, Stefano Mancinelli, Marotti Campi.',
      'As well as being the dominant variety in Lacrima di Morro d\u2019Alba, the variety is authorized as part of the blend in the dry red Colli Maceratesi DOC.',
      'The Italian agricultural census of 2000 recorded 722 ha (1,784 acres) of Lacrima but this is misleading because it includes varieties other than Lacrima di Morro d\u2019Alba.',
      'This variety is quite separate from the Lacryma Christi del Vesuvio DOC in Campania, which is made from PIEDIROSSO (minimum 80%) sometimes blended with SCIASCINOSO and/or AGLIANICO.',
    ],
  },
  {
    name: 'LAFNETSCHA',
    synonyms: ['Laffnetscha', 'Lafn\u00e4tscha', 'Lavenetsch'],
    description:
      'Old but quantitively unimportant Swiss variety producing well-structured, ageworthy whites.',
    'origin and parentage': [
      'Lafnetscha is indigenous to the Haut-Valais in southern Switzerland, where it was mentioned as early as 1627 under the name Lachneschen (Vouillamoz and Moriondo 2011). Its name is the dialect form of the German laff nicht schon, meaning \u2018don\u2019t drink too early\u2019, probably referring to the wine\u2019s high natural acidity, which benefits from ageing.',
      'DNA parentage analysis has shown that Lafnetscha is a natural cross between HUMAGNE from the Valais in the south and COMPLETER from Graub\u00fcnden in the south east (Vouillamoz, Maigre and Meredith 2004; see Completer for the pedigree). Old Completer vines were found near Visp in the Haut-Valais after this parentage had been discovered (Vouillamoz, Frei and Arnold 2008). Lafnetscha is therefore a half-sibling of BONDOLETTA, HIMBERTSCHA and HITZKIRCHER and a likely grandchild of COLOMBAUD from Provence.',
    ],
    'where its grown': [
      'The best example of Lafnetscha, made by Chanton, is dry with notes of green apple, pear and elderflower, a dense structure, high acidity and a lingering floral finish. The only two other producers are Gregor Kuonen and Papillon and there were fewer than 2 ha (5 acres) in the Haut-Valais, Switzerland, in 2009. Lafnetscha\u2019s structure gives it good ageing potential.',
    ],
  },
  {
    name: 'LAGARINO BIANCO',
    synonyms: ['Chegar\u00e8l', 'Sghittarella'],
    description:
      'Old but only recently registered crisp Trentino white wine grape.',
    'origin and parentage': [
      'Lagarino Bianco most probably comes from the Val Lagarino in the province of Trento in northern Italy. DNA profiling disproves any direct parent\u2013offspring relationship between Lagarino Bianco and LAGREIN (Vouillamoz).',
    ],
    'where its grown': [
      'Lagarino Bianco has historically been cultivated mainly in the Val di Cembra, north east of Trento, Italy, and to a lesser extent in Valsugana near Pergine. Thanks to studies carried out at the research centre in San Michele all\u2019Adige, Lagarino Bianco was added to the Italian national register of varieties, together with Verdealbara (a local synonym for ERBAMAT), in 2007. Varietal Lagarino Bianco wines, such as Alfio Nicolodi\u2019s crisp, lemony Cimbrus, are few and far between.',
    ],
  },
  {
    name: 'LAGREIN',
    synonyms: ['Lagrain'],
    description:
      'Very well-connected, distinctively fruity but sometimes rustic feature of the Alto Adige.',
    'origin and parentage': [
      'The earliest mention of Lagrein refers, surprisingly, to a white wine: in a document dated 1318 in Gries near Bolzano, people were told to give some white Lagrein (\u2018weiss Lagrein\u2019) to the poor or to the church. In 1370, Emperor Karl IV pronounced \u2018Lagreiner\u2019 to be the best wine of the area, but it is not known if it was white or red. Several references to white Lagrein appear later at different locations: Tramin (1379), Eppan (1497), Schenna (1507), Kaltern and Terlan (1532), etc. (Scartezzini 2005). The true identity of this white Lagrein has not been discovered: some have suggested it could be SAVAGNIN BLANC (called Traminer in this area), others have suggested it could be the LAGARINO BIANCO cultivated in Cembra, Faver and Grumes (Trentino), or even that the name \u2018weiss Lagrein\u2019 was simply used for a wine blend (Barbara Raifer, personal communication).',
      'Even though the designation \u2018weiss Lagrein\u2019 presumes the existence of a dark-berried version, the earliest mention of a red Lagrein, \u2018rott Lagrein\u2019, dates back to 1526 (Scartezzini 2005). It is most likely to come from the Val Lagarina in Trentino in northern Italy, from which its name probably derives.',
      'Recent DNA parentage analysis and reconstruction of a complex pedigree (Vouillamoz and Grando 2006) have established that Lagrein is:',
      '\u2013\u00a0\u00a0\u00a0a natural cross between TEROLDEGO and an unknown variety (although see below in Other hypotheses)',
      '\u2013\u00a0\u00a0\u00a0a sibling of MARZEMINO (see the pedigree diagram for REFOSCO DAL PEDUNCOLO ROSSO)',
      '\u2013\u00a0\u00a0\u00a0a nephew/niece of DUREZA, the parent of SYRAH',
      '\u2013\u00a0\u00a0\u00a0a grandchild of PINOT',
      '\u2013\u00a0\u00a0\u00a0a cousin of SYRAH.',
      'This disproves the supposed Greek or Albanian origin of Lagrein, which can be considered to be an ancient and autochthonous variety of the Alto Adige.',
    ],
    'where its grown': [
      'Lagrein is cultivated mainly in the Alto Adige and in Trentino in the far north of Italy. It is often blended with SCHIAVA GROSSA but varietal wines are permitted in the Alto Adige and Trentino DOCs. Ros\u00e9 wines are labelled Rosato or Kretzer, red wines Lagrein or Lagrein Dunkel or Lagrein Scuro. The reds are deeply coloured, have intense berry-fruit flavours and, although the tannin levels are not necessarily high, they can be slightly rustic. Some producers have shortened maceration times and used oak barrels to make the wines more rounded. They quite often seem to have a slight taste of iodine. They generally benefit from a year or two in bottle and are sweeter, more Germanic and less savoury than many Italian reds. Recommended producers include Barone de Cles Maso Scari, Kellerei Gries (who use some American oak), Hofst\u00e4tter, Alois Lageder, Manincor, Muri-Gries and Tiefenbrunner.',
      'Total plantings of Lagrein in the Italian agricultural census in 2000 were 477 ha (1,179 acres). The Alto Adige recorded 412 ha (1,018 acres) in 2008, making it the region\u2019s second most widely planted dark-skinned variety, some way behind SCHIAVA GROSSA.',
      'A number of California producers bottle the Lagrein grown in Paso Robles, notably Imagery and Tobin James. The state\u2019s total was 77 acres (31 ha) in 2008.',
      'In Australia there are currently fifteen or so producers who make a Lagrein, generally grown in cooler climates than that of its homeland, but this does not seem to result in herbaceous flavours. Producers include Cobaw Ridge at Keyneton in Victoria, Domaine Day at Mt Crawford, South Australia, and Bassham Wines in the Riverland. Tannin levels are generally lower than in the Alto Adige.',
    ],
  },
  {
    name: 'LAIR\u00c9N',
    synonyms: [
      'Laeren del Rey',
      'Layrenes',
      'Malvar',
      'Mantuo La\u00e9ren',
      'Mantuo Layrenes',
      'Temprana Agoste\u00f1a',
    ],
    description:
      'Very minor Andalusian variety often mistaken for the much more common Air\u00e9n.',
    'origin and parentage': [
      'Lair\u00e9n is a very old variety from Andaluc\u00eda in southern Spain that was mentioned by the Spanish agronomist Gabriel Alonso de Herrera (1470\u20131539) in 1513 under the name Layrenes in his description of the varieties of Castilla, Extremadura and Andaluc\u00eda (Alonso de Herrera 1790). The name Lair\u00e9n possibly derives from the Mozarabic name of a place in the region of Sevilla in Andaluc\u00eda (Fav\u00e0 i Agud 2001). Lair\u00e9n and AIR\u00c9N from Castilla-La Mancha have often been confused but DNA profiling has shown that they are unquestionably distinct (Iba\u00f1ez et al. 2003; Santana et al. 2010).',
      'According to Schneider et al. (2010), Lair\u00e9n could have a parent\u2013offspring relationship with Legiruela, a variety from Valladolid that is identical to PRI\u00c9, which has been cultivated in the Valle d\u2019Aosta for centuries (see the complete pedigree of Pri\u00e9).',
    ],
    'where its grown': [
      'It is hard to find information on Lair\u00e9n because it is so often confused with AIR\u00c9N, although the former is more likely to be found in Andaluc\u00eda, southern Spain, whereas the latter is much more likely to be in La Mancha in the centre of the country. Lair\u00e9n is permitted in the Montilla-Moriles, M\u00e1laga and Sierras de M\u00e1laga DOs and may be blended with other local varieties such as PEDRO XIM\u00c9NEZ and Balad\u00ed (aka Pardina or CAYETANA BLANCA) to make early-drinking dry whites, but it does not feature in the official Spanish statistics.',
      'However, under the Spanish synonym Malvar, information is easier to come by. It is planted to a limited extent across central Spain: a total of 266 ha (657 acres) in 2008, around three-quarters of that in the Madrid area, where it is one of the principal varieties in the Vinos de Madrid DO.',
      'Wines are greenish in colour, high in alcohol, with a light aroma of bitter almonds and medium to high acidity and may be bottled as varietal wines or blended with AIR\u00c9N, for example, contributing acidity and aroma. Malvar is also used in the production of late-harvest sweet wines and may be fermented or aged in oak. Vinos Jerom\u00edn produce two varietal examples: Puerta de Alcal\u00e1 Blanco and the barrel-fermented Puerta del Sol Blanco. Laguna make a good late-harvest example. Other producers of varietal wines include Jes\u00fas D\u00edaz, Bodegas Orusco and Bodegas Peral.',
    ],
  },
  {
    name: 'LALVARI',
    synonyms: ['Burra', 'Dana Bouroun', 'Dana Burnu', 'Dana Burun', 'Glglan'],
    description: 'Versatile Armenian white wine grape.',
    'origin and parentage': [
      'Lalvari possibly comes from the Tavush region in north-east Armenia.',
    ],
    'where its grown': [
      'Lalvari is planted mainly in the province of Tavush, north-eastern Armenia, where it is used to produce a range of styles, from light and sparkling to dry, medium dry and oaked. Unlike most other Armenian varieties, it is rarely used to make brandy. Wines tend to be fresh, relatively neutral, with moderate alcohol. Producers include 365 Wines, Brest and Ijevan Wine Factory.',
    ],
  },
  {
    name: 'LAMBRUSCA DI ALESSANDRIA',
    synonyms: [
      'Anr\u00e8',
      'Caru\u00e9t',
      'Croetto',
      'Crova',
      'Crovet',
      'Crov\u00ecn',
      'Moretto',
      'Lambrusco di Alessandria',
      'Neretto di Alessandria',
      'Stup\u00e8t',
    ],
    description:
      'Adaptable but minor dark-skinned variety grown in parts of Piemonte, north-west Italy, and generally blended.',
    'origin and parentage': [
      'Lambrusca di Alessandria most likely \u2013 and most logically \u2013 comes from the province of Alessandria in Piemonte. It was first mentioned by Nuvolone Pergamo, Conte di Scandaluzza (1787\u201398), under the name Crovet, cultivated in the Casale hills and in the province of Asti. Some years later, Acerbi (1825) describes five distinct \u2018Lambrusche\u2019, two of which are named \u2018Crouet\u2019 and are similar to Lambrusca di Alessandria (Torello Marinoni et al. 2006). The first occurrence of the name Lambrusca di Alessandria was in 1879 in Ampelografia italiana.',
      'DNA profiling has suggested likely parent\u2013offspring relationships between Lambrusca di Alessandria and two autochthonous varieties of the province of Alessandria in Piemonte: Neretto di Marengo (not commercially cultivated) and MALVASIA DI CASORZO (Torello Marinoni et al. 2006).',
    ],
    'where its grown': [
      'Lambrusca di Alessandria has historically been cultivated in the province of Alessandria in Piemonte, Italy, but also in the provinces of Asti and, to a lesser extent, Cuneo. It was later introduced in the province of Torino (in the regions of Pinerolo, Val Chisone, Val Germanasca, Ivrea and Val di Susa) after phylloxera (Schneider and Mannini 2006) and it is authorized throughout Piemonte, where it is usually blended with DOLCETTO, BARBERA, FREISA or BONARDA PIEMONTESE. It is specifically mentioned as a possible blending component in Colli Tortonesi DOC (red and ros\u00e9). There were 900 ha (2,224 acres) of Lambrusca di Alessandria in Italy in 2000, according to the agricultural census. It is not available as a varietal.',
    ],
  },
  {
    name: 'LAMBRUSCA VITTONA',
    synonyms: ['Vittona'],
    description: 'Very minor Piemontese variety.',
    'origin and parentage': [
      'Lambrusca Vittona is a typical variety of the Pinerolo area in the province of Torino, north-west Italy, possibly first mentioned as Lambrusca in Pinerolo in 1834, although there is some doubt as to whether it was Lambrusca Vittona (Torello Marinoni et al. 2006).',
      'DNA parentage analysis has suggested a parent\u2013offspring relationship between Lambrusca Vittona and Berla Grossa, a local and almost extinct variety of the Pinerolo region.',
    ],
    'where its grown': [
      'Historically well distributed in Cum\u00ecana, Bricherasio, San Secondo di Pinerolo and Prarostino in the province of Torino, north-west Italy, Lambrusca Vittona is today also cultivated in Barge, the Val Chisone and the Val Germanasca, where it is occasionally used in a blend with AVAN\u00c0, AVARENGO and Neretto (a name for several distinct varieties), plus up to 35% of other local red varieties, to produce a wine known as Ram\u00ece (Schneider and Mannini 2006). Lambrusca Vittona is not listed in the Italian agricultural census of 2000 because it is not officially authorized for the region. Its inclusion in Ram\u00ece is a classic example of the creative use of synonyms to get round the regulations. There are just five producers of Ram\u00ece. Lambrusca Vittona is thus generally declared as LAMBRUSCA DI ALESSANDRIA, another very rare local (but authorized) variety, and CHATUS is declared as Neretto!',
    ],
  },
  {
    name: 'LAMBRUSCHETTO',
    synonyms: ['Crovino', 'Lambruschetta', 'Malaga'],
    description:
      'Old and very localized variety arousing current interest in Piemonte.',
    'origin and parentage': [
      'Lambruschetto is a typical variety of south-east Piemonte, in particular around Castelnuovo Bormida (Schneider and Mannini 2006). Its first mention may be as early as 1831, when Italian ampelographer Gallesio described this variety under the synonym Crovino, while the first use of the name Lambruschetto is found in Demaria and Leardi (1875). These authors suggested that Lambruschetta was identical to Lambruschetta di Sorbara (ie LAMBRUSCO DI SORBARA) but this has been disproved by DNA analysis (Torello Marinoni et al. 2006). A possible parent\u2013offspring relationship has been established between Lambruschetto and TIMORASSO, an old white wine variety from south-east Piemonte (Torello Marinoni et al. 2006).',
    ],
    'where its grown': [
      'Formerly cultivated in the provinces of Asti and Alessandria, north-west Italy, Lambruschetto is today cultivated almost exclusively in Castelnuovo Bormida in the province of Alessandria, where there have recently been some new plantings.',
    ],
  },
  {
    name: 'LAMBRUSCO BARGHI',
    synonyms: ['Lambrusco Bardi', 'Lambrusco Corbelli', 'Lambrusco di Rivalta'],
    description:
      'Very minor, recently recognized, dark-skinned blending component in Reggio.',
    'origin and parentage': [
      'Lambrusco Barghi probably comes from the province of Reggio Emilia, where it is also called Lambrusco Corbelli or Lambrusco di Rivalta because until the 1960s it was widely cultivated in the Castelnuovo di Sotto and Rivalta properties of the Conte Corbelli.',
    ],
    'where its grown': [
      'Lambrusco Barghi entered Italy\u2019s national register of varieties as recently as 2009 as a component in the Reggiano Lambrusco DOC. The berries attain high sugar levels and the wines are deeply coloured, thanks to a high level of anthocyanins. Wine is especially aromatic.',
    ],
  },
  {
    name: 'LAMBRUSCO DI FIORANO',
    synonyms: ['Lambruscone'],
    description:
      'Obscure, highly localized Emilia-Romagna variety unrelated to other Lambruscos.',
    'origin and parentage': [
      'Lambrusco di Fiorano takes its name from the commune of Fiorano in the province of Modena. Recent studies have shown that Lambrusco di Fiorano is morphologically and genetically distinct from other Lambruscos of the Emilia-Romagna region (Boccacci et al. 2005).',
    ],
    'where its grown': [
      'Still extensively planted on the higher slopes in the commune of Fiorano, Emilia-Romagna, Italy.',
    ],
  },
  {
    name: 'LAMBRUSCO DI SORBARA',
    synonyms: ['Lambruschetta di Sorbara', 'Lambrusco Sorbarese'],
    description:
      'Perhaps the most respected of the varieties responsible for Lambrusco, Emilia-Romagna\u2019s potentially refreshing, lightly frothy, food-friendly red.',
    'origin and parentage': [
      'Lambrusco di Sorbara is a typical variety of the area between the Secchia and Panaro rivers in the province of Modena in Emilia-Romagna. It is named after the small village of Sorbara in the commune of Bomporto.',
    ],
    'where its grown': [
      'Lambrusco di Sorbara is cultivated mainly in the province of Modena, northern central Italy, in particular in Sorbara, Comporto, San Prospero and Nonantola. It is also cultivated in the province of Reggio Emilia, near San Martino, in Rio, Rubiera and Correggio. As well as being the dominant variety in the Lambrusco di Sorbara DOC (usually blended with 40% LAMBRUSCO SALAMINO), the variety is an authorized component in DOCs such as Reggiano Lambrusco, Reggiano Lambrusco Salamino and Lambrusco Mantovano (in Lombardia). There were 1,460 ha (3,608 acres) of Lambrusco di Sorbara in Italy in 2000.',
      'The wines, which are usually dry or off dry and should be drunk young, are deep cherry red, lightly sparkling and have bright, zesty red fruit flavours and high acidity that makes them ideal with the rich pork-based dishes of Emilia-Romagna. Smaller producers making high-quality Lambrusco di Sorbara that contradict the widespread image of Lambrusco as a sweet industrialized fizz include Cavicchioli, Chiarli 1860 and Villa di Corlo.',
    ],
  },
  {
    name: 'LAMBRUSCO GRASPAROSSA',
    synonyms: ['Lambrusco di Castelvetro', 'Scorzamara'],
    description:
      'Makes fuller, more tannic Lambrusco than most, both dry and medium sweet.',
    'origin and parentage': [
      'Traditionally cultivated in the commune of Castelvetro in Emilia-Romagna, where Lambrusco Grasparossa was first mentioned in 1867 (Giavedoni and Gily 2005). DNA profiling has established that Scorzamara from Coviolo, a rare black variety first recorded in the eighteenth century, is identical to Lambrusco Grasparossa (Boccacci et al. 2005).',
    ],
    'where its grown': [
      'Lambrusco Grasparossa is widespread throughout the province of Reggio Emilia in the region of Emilia-Romagna, Italy, and in particular in the communes of Coviolo Scandiano, Casalgrande and Quattro Castella, where it is made as varietal wine in the Colli di Scandiano e di Canossa DOC, either still or sparkling. Under its synonym Scorzamara, it is cultivated to a very limited extent in Coviolo. In the province of Modena, it is grown mainly in the Castelvetro region, where it is the dominant variety (minimum 85%) of the sparkling Lambrusco Grasparossa di Castelvetro DOC. There are also scattered plantings in the provinces of Parma, Mantova and Bologna. The variety is authorized as a minor blending component, usually with other varieties called Lambrusco Something, in Reggiano DOC (various styles) and also in Lombardia\u2019s Lambrusco Mantovano DOC (ros\u00e9 and red sparkling wines). There were 1,897 ha (4,688 acres) of Lambrusco Grasparossa in Italy in 2000.',
      'The wines, which are deeply coloured, fruity and more tannic than most Lambrusco varieties, with good acidity, may be dry or semi-sweet. Lambrusco Grasparossa tends to be fuller-bodied and have higher alcohol than, for example, Lambrusca di Sorbara. Good examples of varietal wines are produced by Corte Manzini, Gavioli Antica Cantina (semi-sweet, blended with 10% MALBO GENTILE), Rinaldini Rinaldo\u2019s Azienda Agricola Moro and Villa di Corlo.',
    ],
  },
  {
    name: 'LAMBRUSCO MAESTRI',
    synonyms: ['Grappello Maestri', 'Lambrusco di Spagna'],
    description:
      'One of the lesser Lambruscos, from Parma, and making rather rustic wines.',
    'origin and parentage': [
      'The earliest mention of Lambrusco Marani was in the mid nineteenth century in Reggio Emilia. DNA profiling has shown that Lambrusco Marani could be closely related to FOGARINA (Boccacci et al. 2005).',
    ],
    'where its grown': [
      'Lambrusco Marani is cultivated in the province of Reggio Emilia, Emilia-Romagna, Italy, and in particular in Rolo, Rio Saliceto, Fabbrico, Campagnola and Novellara. It is also cultivated to a lesser extent in the provinces of Mantova, Parma and Bologna. It produces lightly sparkling reds \u2013 dry and not so dry \u2013 that are deeply coloured, fruity, fresh and often tannic. The variety is the mainstay of Reggiano Lambrusco red and ros\u00e9 and may comprise up to 100% of Reggiano Bianco Spumante. It is authorized as part of the blend in both still and sparkling Reggiano Rosso DOC, generally with ANCELLOTTA, and in Lombardia\u2019s Lambrusco Mantovano DOC, as well as in the sparkling reds of Colli di Scandiano e di Canossa blended with LAMBRUSCO MONTERICCO, for example. There was a total of 2,318 ha (5,728 acres) of Lambrusco Marani in Italy in 2000.',
    ],
  },
  {
    name: 'LAMBRUSCO OLIVA',
    synonyms: ['Lambrusco Mazzone', 'Olivone'],
    description: 'Relatively minor, occasionally bitter Lambrusco variety.',
    'origin and parentage': [
      'Lambrusco Oliva, first mentioned in 1886 (Gozzi et al. 2002), takes its name from the typically olive-like shape of its berries.',
    ],
    'where its grown': [
      'Lambrusco Oliva was added to the national register of grape varieties in Italy in December 2000 and is now officially accepted for cultivation in the provinces of Modena and Reggio Emilia in Emilia-Romagna. Still thriving in old vineyards in these areas, it is generally blended with LAMBRUSCO MARANI and LAMBRUSCO SALAMINO to produce deeply coloured wines, often sweet or sparkling, that may be bitter if over-extracted. It is authorized as a minor part of the blend in Lambrusco and Rosso versions of Reggiano DOC.',
    ],
  },
  {
    name: 'LAMBRUSCO SALAMINO',
    synonyms: ['Lambrusco Galassi', 'Lambrusco di Santa Croce'],
    description:
      'The most widely planted of the Lambrusco varieties, making the most substantial wines.',
    'origin and parentage': [
      'Lambrusco Salamino takes its name from the cylindrical shape of its bunches, which resembles a small salami. It most likely originates from the commune of Santa Croce di Carpi in the province of Modena in Emilia-Romagna.',
    ],
    'where its grown': [
      'Lambrusco Salamino is cultivated in the provinces of Modena and Reggio Emilia (notably in Correggio, Fabbrico, San Martino in Rio and Rio Saliceto) in Emilia-Romagna, Italy, and also planted to a very limited extent in the provinces of Mantova, Bologna and Ferrara. It is the most widely planted Lambrusco variety, with 4,273 ha (10,559 acres) recorded in Italy in 2000. It is also planted to a limited extent on the island of Sardegna.',
      'In addition to being the main constituent in Lambrusco Salamino di Santa Croce DOC (minimum 90% blended with up to 10% ANCELLOTTA) and in the Lambrusco Salamino version of the Reggiano DOC (minimum 85%), Lambrusco Salamino plays an important part in Lambrusco di Sorbara DOC and several other DOCs such as Lambrusco Mantovano, Colli di Scandiano e di Canossa and Reggiano. The wines may be dry or sweet, intensely coloured with fruity aromas and, in the best examples, their naturally high level of tannin is offset by fresh acidity. The wine is quite high in alcohol if fermented dry. This is generally the most full-bodied and aromatic of all the Lambruscos.',
      'Cavicchioli & Figli\u2019s Tre Medaglie is a good off-dry example of Lambrusco Salamino di Santa Croce and Chiarli make dry, off-dry and sweet versions within the same DOC.',
    ],
  },
  {
    name: 'LAMBRUSCO VIADANESE',
    synonyms: ['Grappello Ruperti', 'Mantovano', 'Montecchio', 'Viadanese'],
    description: 'Minor but characterful Lambrusco variety.',
    'origin and parentage': [
      'Lambrusco Viadanese takes its name from the commune of Viadana in the province of Mantova in Emilia-Romagna, where it is also called Grappello Ruperti, after the agronomist Ugo Ruperti, who considered it to be one of the province\u2019s best varieties and encouraged its planting.',
    ],
    'where its grown': [
      'Lambrusco Viadanese is cultivated mainly in the provinces of Mantova and Cremona in Emilia-Romagna, Italy. The wines are ruby coloured, tannic and robust and have intense aromas of cherries and violets. It is authorized on its own or in a blend in the Lambrusco Mantovano DOC. It is also permitted in the blend of Rosso and Lambrusco versions of Reggiano DOC.',
      'There were just 294 ha (726 acres) of Lambrusco Viadanese recorded in the Italian agricultural census in 2000 and it is less significant and much less widely planted than, say, LAMBRUSCO SALAMINO or LAMBRUSCO DI SORBARA but its wines can be much more interesting than the average Lambrusco.',
    ],
  },
  {
    name: 'LANDAL',
    synonyms: ['Landot 244'],
    description: 'Disease-resistant, waning French hybrid.',
    'origin and parentage': [
      'A complex hybrid obtained between 1929 and 1942 by Pierre Landot in Conzieu in the Ain, eastern France, by crossing Seibel 5455 (also known as PLANTET) \u00d7 Seibel 8216, in which Seibel 8216 is a Seibel 5410 \u00d7 Seibel 5001 hybrid (see FLORENTAL for the pedigree of Seibel 5410), Seibel 5001 is a Seibel 2510 \u00d7 Seibel 867 hybrid, the latter being a Vivarais \u00d7 NOAH hybrid (see PRIOR for the pedigrees of Seibel 2510 and Vivarais). Landal was named after its breeder.',
    ],
    'where its grown': [
      'There were 49 ha (121 acres) in France in 2008, mainly in the Loire and the Vend\u00e9e, but it is definitely a dying breed. It is authorized in many French wine-growing regions but recommended in none, being known more for its cold hardiness and good level of disease resistance (Basler 2003) than for the quality of the wine it produces. It is also planted to a minuscule extent in the canton of Gen\u00e8ve in Switzerland (0.06 ha/0.15 acres in 2009). Naylor Wines in Pennsylvania make a light Landal/CHAMBOURCIN blend. It\u2019s also in Canada, both in Ontario, where it was first planted in the 1980s, and Qu\u00e9bec, where Carone use Landal in two of their red blends.',
    ],
  },
  {
    name: 'LANDOT NOIR',
    synonyms: ['Landot 4511'],
    description:
      'Minor French hybrid, son of Landal, also found in a few pockets in the US and Canada.',
    'origin and parentage': [
      'A complex hybrid obtained between 1929 and 1942 by Pierre Landot in Conzieu in the Ain, eastern France, by crossing LANDAL \u00d7 VILLARD BLANC. It was used to breed FRONTENAC.',
    ],
    'where its grown': [
      'Although this is a French hybrid, it is not included in the official French register of varieties, but it seems to have found a little home in North America. In Canada, where it was introduced in the mid 1950s, Carone in Qu\u00e9bec include Landot Noir (as well as LANDAL and CABERNET SEVERNY) in their FRONTENAC blend. In the north-eastern US, where it arrived a few years later, Jewell Towne in New Hampshire make a varietal wine, as do Newport Vineyards in Rhode Island and Farinacci in Ohio. Wines are generally soft and lightly spicy. It is also planted to a very limited extent in Illinois.',
    ],
  },
  {
    name: 'LANZESA',
    synonyms: ['Lanz\u00e9s', 'Lanzesca'],
    description:
      'Very rare white wine speciality of Ravenna in northern Italy.',
    'origin and parentage': [
      'Lanzesa\u2019s origin and etymology are unknown.',
    ],
    'where its grown': [
      'This variety was once used for both table and wine grapes. Today it is cultivated for wine on fewer than 5 ha (12 acres) in the communes of Brisighella, Faenza and Castel Bolognese in the province of Ravenna in Emilia-Romagna, Italy. The wines are dry, floral and fruity with a touch of bitterness. Tenuta Uccellina\u2019s Alma Luna is one example.',
    ],
  },
  {
    name: 'LASINA',
    synonyms: ['Krapljenica', 'Kutlarica', 'Lasin', 'Lasina Crna', 'Vlasina'],
    description:
      'Very rare vine found only in Dalmacija (Dalmatia) and generally used in blends.',
    'origin and parentage': [
      'Lasina possibly comes from the regions of Promina and Stankovaca in northern Dalmacija.',
    ],
    'where its grown': [
      'A rare variety still cultivated in some parts of the Sjeverna Dalmacija (Northern Dalmatia) wine region in Croatia, the only one in which it is officially authorized. It is found mainly around Promina, between Split and Zadar, but is little known because it tends to be used in blends, such as the one produced by Bibich (Lasina plus PLAVINA and BABI\u0106). Producers who grow this variety include Ivica D\u017eapo and Marko Duvan\u010di\u0107, both in Oklaj. The latter makes perhaps the only varietal wine available.',
    ],
  },
  {
    name: 'LAUROT',
    synonyms: ['MI 5-106'],
    description:
      'Recently bred and privately owned disease-resistant Czech dark-skinned hybrid.',
    'origin and parentage': [
      'Laurot is a Merlan \u00d7 Fratava hybrid recently bred for disease resistance by Milo\u0161 Michlovsk\u00fd, Vil\u00e9m Kraus, Lubom\u00edr Glos, Vlastimil Pe\u0159ina and Franti\u0161ek M\u00e1dl, in which:',
      '\u2013\u00a0\u00a0\u00a0Merlan is a MERLOT \u00d7 Seibel 13666 hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 13666 is a PLANTET \u00d7 Seibel 6468 hybrid (see HELIOS for the pedigree of Seibel 6468)',
      '\u2013\u00a0\u00a0\u00a0Fratava is a BLAUFR\u00c4NKISCH \u00d7 SANKT LAURENT cross.',
      'Pollen was obtained from the Chisinau research centre in the Republic of Moldova and the crossing and selection were done in Lednice, Breclav and Pern\u00e1 research centres in the Czech Republic. The variety rights are owned by Vinselekt Michlovsk\u00fd, a breeding company created in 1995 by Milo\u0161 Michlovsk\u00fd in Rakvice, southern Morava, in the south east of the Czech Republic. It was included in the official Czech register of varieties in 2004.',
    ],
    'where its grown': [
      'Wines are deeply coloured, with fruit flavours ranging from wild cherries to plums and blackcurrants; acidity is moderate and tannins are light. Vinselekt Michlovsk\u00fd make both juice and wine from their organic Laurot vineyards in the Czech Republic.',
    ],
  },
  {
    name: 'LAUZET',
    synonyms: ['Laouset', 'Lauzet Blanc'],
    description: 'Almost extinct variety in Juran\u00e7on, south-west France.',
    'origin and parentage': [
      'Lauzet is an endangered variety from the B\u00e9arn region in south-west France, often confused with PETIT COURBU in Juran\u00e7on. Its first mention appeared only recently in Viala and Vermorel (1901\u201310) under the local synonym Laouset. The origin of the name Lauzet is unknown and, curiously enough, Lauzet does not have a Basque name, although for Bisson (2009) it clearly belongs to the Courbu ampelographic group (see p XXVII). According to Lavignac (2001), it is morphologically close to BAROQUE from the Landes and to TANNAT from Madiran.',
    ],
    'where its grown': [
      'Lauzet is a minor, now virtually extinct, variety in the Juran\u00e7on area of France, a permitted \u2018accessory\u2019 variety in the Juran\u00e7on and B\u00e9arn appellations and cultivated mainly in Monein, Lasseube, Gan and Aubertin. Domaine Cauhap\u00e9 still have 1 ha (2.5 acres) planted and Domaine Nigri, for example, generally include 10% of Lauzet in their dry white Juran\u00e7on, along with 80% GROS MANSENG and 10% CAMARALET DE LASSEUBE. It has the potential to produce lightly spicy wines that have quite high alcohol yet still retain good acidity. However, given the potentially high sugar levels in both Mansengs, Camaralet de Lasseube is of greater value as a blending component, particularly in dry Juran\u00e7on. The total area recorded in France for 2006 was less than 2 ha (5 acres).',
    ],
  },
  {
    name: 'LEN DE L\u2019EL',
    synonyms: [
      'Cavaill\u00e8s',
      'Cavalier',
      'Len-de-Lel',
      'Len de l\u2019Elh',
      'Lenc d\u00e9 l\u2019El',
      'Loin de l\u2019Oeil',
    ],
    description:
      'Highly localized and well suited to the production of sweet wines in its native Gaillac.',
    'origin and parentage': [
      'A complex hybrid obtained in 1911 by Eug\u00e8ne Kuhlmann in Colmar, Alsace, by crossing Millardet et Grasset 101-14 OP with GOLDRIESLING, where Millardet et Grasset 101-14 OP is a selfling of a Vitis riparia \u00d7 Vitis rupestris cross generally used as a rootstock. The variety was named in honour of L\u00e9on Millot, president of the Soci\u00e9t\u00e9 Vosgienne de Viticulture, and commercialized in 1921.',
      'The same crossing produced L\u00e9on Millot\u2019s siblings, LUCIE KUHLMANN and MAR\u00c9CHAL FOCH, and L\u00e9on Millot was used to breed MILLOT-FOCH.',
    ],
    'where its grown': [
      'L\u00e9on Millot\u2019s popularity in parts of France such as Alsace was mainly due to its ability to add colour to pale reds, though it has received greater attention in the last thirty years from those looking for disease-resistant varieties that require less fungicide (Basler 2003). Since it cannot be used in any appellation wine, the vineyard area is in decline but there were still 85 ha (210 acres) in 2008. It is officially authorized in many French wine regions but recommended in none and has had greater success outside the hexagon, notably in cooler climates with shorter growing seasons where many reds are reluctant to ripen such as eastern Switzerland (9 ha/22 acres in 2009), where varietals are produced by Cultiva and Schmidheiny. It is now planted in Denmark (4 ha/10 acres in 2010), where it is a popular blending component, and in Sweden.',
      'Its early ripening is also appreciated in US Midwest states including Illinois (15 acres/6 ha projected for 2009), Iowa (14 acres/6 ha in 2006) and Nebraska (precisely 528 vines in 2006, apparently). It is also planted to a limited extent in New York State and as far north as Nova Scotia in Canada. Varietal wines tend to be deeply coloured and are produced by, among others, Keuka Lake in New York\u2019s Finger Lakes region, China Bend in Washington State and Jewell Towne in New Hampshire.',
    ],
  },
  {
    name: 'L\u00c9ON MILLOT',
    synonyms: ['Fr\u00fche Schwarze', 'Kuhlmann 194-2', 'Millot'],
    description:
      'French hybrid producing deeply coloured reds. Popular in regions with short growing seasons.',
    'origin and parentage': [
      'A complex hybrid obtained in 1911 by Eug\u00e8ne Kuhlmann in Colmar, Alsace, by crossing Millardet et Grasset 101-14 OP with GOLDRIESLING, where Millardet et Grasset 101-14 OP is a selfling of a Vitis riparia \u00d7 Vitis rupestris cross generally used as a rootstock. The variety was named in honour of L\u00e9on Millot, president of the Soci\u00e9t\u00e9 Vosgienne de Viticulture, and commercialized in 1921.',
      'The same crossing produced L\u00e9on Millot\u2019s siblings, LUCIE KUHLMANN and MAR\u00c9CHAL FOCH, and L\u00e9on Millot was used to breed MILLOT-FOCH.',
    ],
    'where its grown': [
      'L\u00e9on Millot\u2019s popularity in parts of France such as Alsace was mainly due to its ability to add colour to pale reds, though it has received greater attention in the last thirty years from those looking for disease-resistant varieties that require less fungicide (Basler 2003). Since it cannot be used in any appellation wine, the vineyard area is in decline but there were still 85 ha (210 acres) in 2008. It is officially authorized in many French wine regions but recommended in none and has had greater success outside the hexagon, notably in cooler climates with shorter growing seasons where many reds are reluctant to ripen such as eastern Switzerland (9 ha/22 acres in 2009), where varietals are produced by Cultiva and Schmidheiny. It is now planted in Denmark (4 ha/10 acres in 2010), where it is a popular blending component, and in Sweden.',
      'Its early ripening is also appreciated in US Midwest states including Illinois (15 acres/6 ha projected for 2009), Iowa (14 acres/6 ha in 2006) and Nebraska (precisely 528 vines in 2006, apparently). It is also planted to a limited extent in New York State and as far north as Nova Scotia in Canada. Varietal wines tend to be deeply coloured and are produced by, among others, Keuka Lake in New York\u2019s Finger Lakes region, China Bend in Washington State and Jewell Towne in New Hampshire.',
    ],
  },
  {
    name: 'LIATIKO',
    synonyms: ['Aleatiko', 'Liatico', 'Liatis', 'Stafili Tu Louliou'],
    description:
      'Idiosyncratic and underrated dark-skinned Cretan variety making aromatic dry and sweet wines.',
    'origin and parentage': [
      'Liatiko is mostly found on the island of Kr\u00edti (Crete), where it possibly originates. Its name is short for Iouliatiko, meaning \u2018of July\u2019 (Boutaris 2000), in reference to its early ripening. There is also a smaller-berried clone that local growers rate more highly.',
      'Recent DNA profiling has suggested a possible parent\u2013offspring relationship with KORINTHIAKI (under the name Black Corinth; Myles et al. 2011).',
    ],
    'where its grown': [
      'Liatiko is a thoroughly Cretan variety, used for both dry and sweet wines, and the most widely planted variety on this Greek island (2,104 ha/5,199 acres in 2008). Wines are not deeply coloured and tend quickly to lose what colour they have during maturation, which is why producers in Sit\u00eda in the east of the island opted to change their appellation regulations so that 20% of the more deeply coloured MANDILARIA must be added to the paler Liatiko. However, the wines have distinct \u2013 often floral and delicately spicy \u2013 aromas, fresh to moderate acidity and soft tannins and although they show their age, they can age gracefully to produce pale, complex wines not unlike mature burgundy with a hint of mature NEBBIOLO in their perfume.',
      'Yannis Economou, the champion of such styles, releases his initially more tannic wines (his old Liatiko vines, grown ungrafted on the 600-metre Ziros plateau, have smaller berries) around ten years after the harvest, once they are silky and refined. Less idiosyncratic but still relatively traditional dry Liatiko is made by producers such as Boutari, Douloufakis, Silva (Irini Daskalaki) and the Sit\u00eda co-operative. Producers more focused on the international market and working outside these appellations may include varieties such as SYRAH or CABERNET SAUVIGNON in the blend.',
      'The Ach\u00e1rnes appellation in the centre of the island permits dry wines only, whereas the Dafn\u00e9s appellation (also in the centre) and Sit\u00eda in the east allow both dry and sweet wines. The latter, made from raisined grapes, are soft but fresh and the best demonstrate Liatiko charm and fragrance. All the producers mentioned above also make good examples of sweet Liatiko.',
    ],
  },
  {
    name: 'LIMNIO',
    synonyms: [
      'Kalabaki',
      'Kalambaki',
      'Kalampaki',
      'Lembiotiko',
      'Lemnia',
      'Lemnio',
    ],
    description:
      'Ancient Greek variety that has successfully travelled from the island of L\u00edmnos to the mainland and is often a minor partner to Cabernets Sauvignon and Franc.',
    'origin and parentage': [
      'Limnio probably originates from the Greek island of L\u00edmnos in the northern Aegean, where it is also known by its Turkish name, Kalambaki (Galet 2000; Manessis 2000).',
      'LIMNIONA from Thessal\u00eda is not identical to Limnio, the two varieties showing distinct DNA profiles in the Greek Vitis Database.',
    ],
    'where its grown': [
      'As expected, Limnio is grown on the island of L\u00edmnos in the northern Aegean and was the fifth most planted variety in that region in 2008 (but still only 72 ha/178 acres in total). However, it is now also planted in much of northern Greece and further south in Thessal\u00eda; it seems to produce superior wines in the cooler climes of Makedon\u00eda and Thr\u00e1ki (Thrace). Wines typically have fairly high alcohol, moderate to fresh acidity, a distinctive aroma of fresh herbs and unexceptional tannins. The variety has not been granted appellation status on L\u00edmnos but on the mainland the C\u00f4tes de Meliton appellation in Halkidik\u00ed requires 70% Limnio and 30% CABERNET SAUVIGNON and/or CABERNET FRANC; elsewhere in various regional wines the proportion of Limnio may be small compared with those of its French blending partners. Gerovassiliou\u2019s Avaton, a regional wine of Epanom\u00ed, is a blend of Limnio, Mavroudi (see MAVROUDA) and MAVROTRAGANO, while Tsantali and Domaine Carras blend Limnio with Cabernet Sauvignon (Mount \u00c1thos and C\u00f4tes de Meliton respectively).',
    ],
  },
  {
    name: 'LIMNIONA',
    synonyms: ['Lemniona'],
    description:
      'Recently resurrected and promising Greek variety from Thessal\u00eda.',
    'origin and parentage': [
      'Limniona comes from Tyrnavos in the province of Lar\u00edsa in Thessal\u00eda, in the east of central mainland Greece. Although Limniona is often confused with the more widespread LIMNIO, they are completely separate varieties, as their DNA profiles in the Greek Vitis Database show.',
    ],
    'where its grown': [
      'Limniona was recently rescued from virtual extinction by Christos Zafirakis in Tyrnavos, who makes both a rare varietal wine, fermented in large oak casks, and a 50/50 blend with SYRAH. In the past Limniona tended to be found only in field blends with XINOMAVRO. Zafirakis\u2019 varietal Limniona, from vines densely planted in 2005 at 100 metres above sea level, is well coloured and spicy, medium-bodied, fresh, fragrant and silky, with good ageing potential. According to Zafirakis, the variety thrives when the days are hot and the nights are cool. In 2009 there were around 10 ha (25 acres) in Greece but plantings are increasing. Other producers who have planted Limniona include Domaine Karanika in Am\u00b4ynteo, western Makedon\u00eda, and George Tsibidis at Monemvasia Winery in the southern Pelop\u00f3nnisos (Peloponnese).',
    ],
  },
  {
    name: 'LIST\u00c1N DE HUELVA',
    synonyms: [
      'List\u00e1n',
      'List\u00e1n Blanca',
      'Malvasia Rasteiro',
      'Mante\u00fado',
      'Mante\u00fado Branco',
      'Mantheudo',
      'Mante\u00fado do Algarve',
      'Vale Grosso',
    ],
    description:
      'Minor variety used mostly for fino-like wines in western Andaluc\u00eda.',
    'origin and parentage': [
      'As its name suggests, List\u00e1n de Huelva comes from the province of Huelva in Andaluc\u00eda in the south-west corner of Spain. According to Hidalgo in Galet (2000), it is close to PALOMINO FINO, which is also called List\u00e1n, but their DNA profiles published in Mart\u00edn et al. (2003) are not consistent with a parent\u2013offspring relationship (Vouillamoz). In addition, a comparison of the DNA profiles of List\u00e1n de Huelva (Iba\u00f1ez et al. 2003) and Mante\u00fado Branco from the Alentejo and the Algarve in Portugal (Almadanim et al. 2004) unexpectedly shows that they are the same variety (Vouillamoz). Mante\u00fado Branco is genetically distinct from other Alentejo varieties (Almadanim et al. 2004), which suggests it may have been introduced fairly recently, possibly from Huelva in Spain (Vouillamoz).',
    ],
    'where its grown': [
      'List\u00e1n de Huelva is grown to a limited extent across southern Spain but predominantly in the province of Huelva in the west, where there were more than 200 ha (494 acres) in the early twenty-first century. It is authorized as one of many principal varieties in the Condado de Huelva DO, where it is used mainly in the blended, fortified wines classified as Palido (fino-like) and Viejo (oloroso-like), along with varieties such as PALOMINO FINO, GARRIDO FINO (plus ZALEMA in the Viejo). Bodegas Privilegio del Condado also make a varietal Palido version.',
      'In Portugal there were 692 ha (1,710 acres) of Mante\u00fado Branco in the Alentejo and the Algarve in 2010, used mainly in blends with other local varieties such as DIAGALVES. Wines are generally neutral, low in acidity and high in alcohol.',
    ],
  },
  {
    name: 'LIST\u00c1N NEGRO',
    synonyms: ['Almu\u00f1eco', 'List\u00e1n Morado', 'Negra Comm\u00fan'],
    description:
      'The red wine grape of Spain\u2019s Islas Canarias. Potentially aromatic wines.',
    'origin and parentage': [
      'List\u00e1n Negro is indigenous to the Spanish Islas Canarias, off the coast of north-west Africa, where it is often confused with LIST\u00c1N PRIETO (called Moscatel Negro on the islands) and with NEGRAMOLL, but a recent study has shown that List\u00e1n Negro has a distinct DNA profile that does not match any other known variety (Vouillamoz; Jorge Zerolo, personal communication).',
      'DNA profiling confirms that List\u00e1n Negro is not a dark-skinned mutation of PALOMINO FINO, which is called List\u00e1n Blanco in Andaluc\u00eda in southern Spain.',
    ],
    'where its grown': [
      'List\u00e1n Negro is the main red grape variety on the Islas Canarias, Spain, particularly in northern Tenerife, with 4,698 ha (11,609 acres) planted in 2008. On the grey volcanic soils, individual vines are planted in man-made, partially walled craters or hollows to capture moisture and break the prevailing winds from the Sahara.',
      'carbonic maceration is used to produce distinctively aromatic wines but others are vinified traditionally, some aged in oak. Producers of varietal wines include Los Bermejos, El Grifo, Bodegas Insulares Tenerife, El Lomo, Monje, El Penitente, Tajinaste, Valleoro and Vi\u00f1\u00e1tigo.',
    ],
  },
  {
    name: 'LIST\u00c1N PRIETO',
    synonyms: [
      'Criolla Chica',
      'El Paso',
      'Hariri',
      'Listr\u00e3o',
      'Misi\u00f3n',
      'Mission',
      'Mission\u2019s Grape',
      'Moscatel Negro',
      'Negra Antigua',
      'Negra Corriente',
      'Negra Peruana',
      'Pa\u00eds',
      'Palomina Negra',
      'Rosa del Per\u00fa',
      'Uva Chica Negra',
      'Uva Negra',
      'Uva Negra Vino',
      'Uva Tinta',
      'Vi\u00f1a Blanca',
      'Vi\u00f1a Negra',
    ],
    description:
      'Historically important, well-travelled, dark-skinned Spanish variety now more common in the Americas but generally in retreat and, with some recent exceptions, producing inferior wines.',
    'origin and parentage': [
      'List\u00e1n Prieto is an old variety from Castilla-La Mancha that was probably first described in 1513 by Alonso de Herrera (1790) under the name Palomina Negra. The etymology of the name List\u00e1n, also used as a synonym for PALOMINO FINO, is unkown, and the Spanish word prieto simply means \u2018dark\u2019 or \u2018black\u2019 (Galet 2000). Phylloxera almost eradicated List\u00e1n Prieto from Spain and the variety would have been lost in oblivion if DNA profiling hadn\u2019t recently discovered that List\u00e1n Prieto had been taken in the sixteenth and seventeenth centuries from Spain to at least four geographically distinct regions where it was given different names:',
      '\u2013\u00a0\u00a0\u00a0Around 1540, it was taken to Mexico by Spanish Franciscan priests who founded several missions, and the grape was simply named Misi\u00f3n. This almost certainly represented the first Vitis vinifera variety to be introduced to the Americas from the Old World. By 1620 it had moved northwards to Baja California.',
      '\u2013\u00a0\u00a0\u00a0In the mid sixteenth century, it was introduced to Chile and to Cuyo in western central Argentina by the Spanish conquistadores and was simply called Uva Negra (\u2018black grape\u2019). Later, in the mid nineteenth century, it was named Pa\u00eds (\u2018country\u2019) in Chile and Criolla Chica (\u2018Creole girl\u2019) in Argentina (Tapia et al. 2007; Lacoste et al. 2010), countries where it remained the most important variety until it was overtaken by the recently introduced French varieties such as COT (Malbec), CABERNET SAUVIGNON, CABERNET FRANC, MERLOT, etc.',
      '\u2013\u00a0\u00a0\u00a0In the mid sixteenth century, it made its way to the Islas Canarias, where it was misleadingly called Moscatel Negro, although it is not related to any form of Muscat.',
      '\u2013\u00a0\u00a0\u00a0In 1629, Misi\u00f3n was planted in the Rio Grande Valley in southern New Mexico by Fray Antonio de Arteaga, a Capuchin monk, and Fray Garc\u00eda de San Francisco y Z\u00fa\u00f1iga, a Franciscan priest, who had founded a mission at Senec\u00fa, near the modern-day town of San Antonio, and another one at Pilab\u00f3, near what is now Socorro, due south of Albuquerque. For the needs of their religious offices, the brothers planted some vines that had been smuggled out of Spain, in spite of the law forbidding the export of Spanish grapevines that was designed to protect the export of Spanish wines. This makes Mission the first Vitis vinifera grape to have been cultivated in the United States.',
      'In California, Father Jun\u00edpero Serra is credited with having founded the first mission in Alta California, in San Diego in 1769, and with having brought the Mission vine from Mexico, although there is documentary evidence to suggest the Mission vines were not planted until ten years later (Pinney 1989). It remained California\u2019s main variety until the introduction of other European varieties in the second half of the nineteenth century.',
      'Despite the old synonym Palomina Negra, List\u00e1n Prieto is not the black version of PALOMINO FINO, although their DNA profiles do not exclude the possibility of a close genetic relationship (Vouillamoz).',
      'DNA parentage analysis (see MUSCAT for the pedigree diagram) has established that CEREZA, Moscatel Amarillo, TORRONT\u00c9S RIOJANO and TORRONT\u00c9S SANJUANINO from Argentina are progenies of List\u00e1n Prieto \u00d7 MUSCAT OF ALEXANDRIA (Ag\u00fcero et al. 2003), that QUEBRANTA from Peru is a progeny of List\u00e1n Prieto \u00d7 NEGRAMOLL, that List\u00e1n Prieto (under the synonyms Moscatel Negro or Mission) is likely to have a parent\u2013offspring relationship with PERRUNO from Extremadura in western Spain (Iba\u00f1ez et al. 2003; This et al. 2006), and that a natural cross between List\u00e1n Prieto and CAYETANA BLANCA from the Iberian peninsula gave birth to Ja\u00e9n Tinto (Zinelabidine et al. 2012), an old and almost extinct variety from southern Spain that should not be confused with MENC\u00cdA (also called Ja\u00e9n Tinto in Bierzo in Castilla y Le\u00f3n; see Cayetana Blanca for the pedigree diagram).',
    ],
    'where its grown': [
      'List\u00e1n Prieto has disappeared from its homeland in Castilla-La Mancha, Spain, but it is still grown on the Islas Canarias: there were 29 ha (72 acres) planted there in 2008 and the variety is authorized in several DOs such as Albona, Gran Canaria and La Palma, although only in the Tacoronte-Acentejo denomination is it referred to as List\u00e1n Prieto; in all the others it is listed as Moscatel Negro.',
      'California still had 656 acres (265 ha) of Mission in 2009, although there have been almost no plantings in recent years. The vast majority of these vines are in the Central Valley, mainly in Fresno County, and are used mostly for sherry- or port-style dessert wines.',
      'In Chile in 2008, Pa\u00eds was still the second most planted variety after CABERNET SAUVIGNON, with 14,995 ha (37,053 acres) and nearly 13% of the vineyard area. Grown mainly in Maule and in B\u00edo B\u00edo in the south, it is mostly used for cheap domestic ros\u00e9. However, in the last few years, Frenchman Louis-Antoine Luyt of Clos Ouvert (with the support of the late Marcel Lapierre of Beaujolais) has been on a mission to re-evaluate the variety and coax the best from the very old vineyards in Maule, using carbonic maceration to tame the potentially astringent tannins and highlight the fruit. These wines are light in colour, thirst-quenchingly fresh, with tart red-fruit flavours and a texture that becomes silky with age, not unlike some old-vine South African CINSAUT.',
      'In Argentina, Criolla Chica is less common than either CRIOLLA GRANDE or CEREZA and its wine is a little paler though better quality. In 2008 there were 538 ha (1,329 acres), mostly in Mendoza and San Juan but also minor plantings in La Rioja and Salta, where it is generally used to make ros\u00e9.',
      'This ancient variety is thought to be planted in Peru, too.',
    ],
  },
  {
    name: 'LJUTUN',
    synonyms: ['Ljutac', 'Plavac', 'Plavac Bedalovac'],
    description:
      'Rare, tart Croatian variety exclusive to the area just north west of Split on the Dalmatian coast.',
    'origin and parentage': [
      'Ljutun is an indigenous variety from the Ka\u0161tela region north west of Split. The name comes from the Croatian word ljut meaning \u2018acid\u2019, which is appropriate given the high acidity in the berries.',
    ],
    'where its grown': [
      'Ljutun is found exclusively around the town of Ka\u0161tela (Zduni\u0107 2005) in Croatia and is often blended with other varieties such as PLAVAC MALI, BABI\u0106 and BABICA to make opolo, a local ros\u00e9-style wine. Producers include Svetin Bili\u0161kov, Dalibor Bu\u010dan, Marin Remetin, Branko Glumac and Neven and Vinko Vujina.',
    ],
  },
  {
    name: 'LONGYAN',
    synonyms: ['Czhi-Pu-Tao', 'Hun-Juan-Sin', 'Long Yan', 'Lungyen'],
    description:
      'Old and widely planted red-skinned Chinese variety used for the table and for white wine.',
    'origin and parentage': [
      'Longyan, named after a city and meaning \u2018dragon\u2019s eyes\u2019, is an old Chinese variety of unknown origin. Although Longyan is also called Ryugan in China, it should not be confused with the Japanese variety RYUGAN.',
    ],
    'where its grown': [
      'Although Longyan is grown mainly for the table, it also widely used to make wine (and some surprisingly good brandy) in China by producers such as China Great Wall Wine and is successful as a base for sparkling wines. Wines made from this red-skinned variety are typically bright, light green-yellow in colour, soft, with refreshing fruity aromas and full-bodied. It is widely planted in the Huaizhuo Basin (Hebei) as well as in Pingdu (Shandong), Qinhuangdao (Hebei), Qingxu (Shanxi) Province and Yulin (Shaanxi). Japanese oenologists produced Longyan wines in Shacheng back in the 1940s and sweet wines were made there in the 1960s, which is partly why the relationship between Longyan and RYUGAN is unclear.',
    ],
  },
  {
    name: 'LOUISE SWENSON',
    synonyms: ['ES 4-8-33'],
    description:
      'Complex American hybrid with a significant degree of cold hardiness.',
    'origin and parentage': [
      'An Elmer Swenson 2-3-17 \u00d7 KAY GRAY hybrid bred in 1980 by Elmer Swenson in Osceola, Wisconsin, in which:',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 2-3-17 is an Elmer Swenson 283 \u00d7 Elmer Swenson 193 hybrid',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 283 is an Elmer Swenson 114 \u00d7 SEYVAL BLANC hybrid (see ST PEPIN for the pedigree of Elmer Swenson 114)',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 193 is a Minnesota 78 \u00d7 Seneca hybrid (see BRIANNA for the complete pedigree of Minnesota 78)',
      '\u2013\u00a0\u00a0\u00a0Seneca is a LUGLIENGA \u00d7 Ontario hybrid (see CAYUGA WHITE for the pedigree of Ontario).',
      'This complex Vitis\nlabrusca, Vitis\nvinifera, Vitis\nlincecumii, Vitis\nriparia and Vitis\nrupestris hybrid was selected in 1984 and released in 2001, named after Elmer Swenson\u2019s wife (Smiley 2008).',
    ],
    'where its grown': [
      'Wines are typically light-bodied with some floral, pear and honey aromas and only moderate acidity (Smiley 2008). Plantings are limited but the variety is found in Vermont, Wisconsin and Minnesota in the US and in Qu\u00e9bec in Canada. Producers of varietal wines include East Shore in Vermont and Parallel 44 in Wisconsin. Coteau St-Paul in Qu\u00e9bec include it a blend with ADALMIINA, PRAIRIE STAR and SWENSON WHITE.',
    ],
  },
  {
    name: 'LOUREIRO',
    synonyms: [
      'Branco Redondos',
      'Loureira',
      'Loureiro Blanco',
      'Marqu\u00e9s',
      'Marquez',
    ],
    description: 'High-quality, aromatic Vinho Verde variety.',
    'origin and parentage': [
      'Loureiro comes from the Vale do Lima (known as Limia where the river rises in Spain) in the northern coastal part of the Minho region of north-west Portugal. Loureiro is Portuguese for \u2018laurel\u2019, referring to the aroma of its berries, which is similar to that of laurel flowers and leaves. Loureiro shows medium to high genetic variability, which suggests it is a fairly old variety (Rolando Faustino, personal communication) and C B de Lacerda Lobo refers to the existence of the variety at the end of the eighteenth century in Melga\u00e7o and Vila Nova de Cerveira (B\u00f6hm 2005). Comparison of DNA profiles indicates a possible close relationship with ALVARINHO (under the name Albari\u00f1o; Iba\u00f1ez et al. 2003) and a recent DNA parentage analysis has suggested a possible parent\u2013offspring relationship with AMARAL (Castro et al. 2011; D\u00edaz-Losada et al. 2011).',
      'The almost extinct Loureiro Tinto, still found in Spain, was thought by Galet (2000) to be a colour mutation of Loureiro, or vice versa. It has also in the past been used as a synonym for MENC\u00cdA but there is no genetic evidence for either of these connections.',
    ],
    'where its grown': [
      'In the 1960s and 1970s, Loureiro spread from the Ponte de Lima area to the rest of the Minho region of northern Portugal, contributing to the relatively recent revival of the Vinho Verde DOC. Varietal wines are fresh and have distinctive aromas not only of laurel leaves and flowers but also of linden, orange and acacia blossom, plus flavours of orange and peach and occasionally green apple. This intensity is often tamed by blending with ARINTO DE BUCELAS and/or TRAJADURA. It is also authorized in the Tejo, Beiras and Pen\u00ednsula de Set\u00fabal regions of Portugal and trials have been carried out in D\u00e3o, where the variety reaches higher alcohol levels of around 13%. Total Portuguese plantings in 2010 were 2,428 ha (6,000 acres). Recommended producers of varietal wines include Aphros, Quinta do Ameal, Quinta de Curvos, Quinta de Gomariz and Quinta dos Termos.',
      'Further north, in Spain\u2019s north-western extremity Galicia, there were 584 ha (1,443 acres) of Loureiro in 2008 and the variety is authorized for the R\u00edas Baixas, Ribeira Sacra and Ribeiro DOs, where it is usually part of a blend with varieties such as Albari\u00f1o (ie ALVARINHO), Treixadura (ie TRAJADURA) and GODELLO. Valmi\u00f1or\u2019s Davila L.100, made in the O Rosal subregion of southern Galicia, is a very good example \u2013 perhaps the only one \u2013 of a 100% Loureiro wine from Spain.',
      'There were also 13 ha (32 acres) of the very rare Loureiro Tinto in Galicia, 30 km north of most of the Loureiro vineyards, and Forja del Saln\u00e9s in R\u00edas Baixas make a varietal wine.',
      'A grape called Loureiro is grown in California\u2019s Central Coast.',
    ],
  },
  {
    name: 'LUCIE KUHLMANN',
    synonyms: ['Kuhlmann 149-3', 'Lucy-Kuhlman'],
    description: 'Minor French hybrid grown mainly in Canada.',
    'origin and parentage': [
      'A complex hybrid made in 1911 by Eug\u00e8ne Kuhlmann at the Institut Oberlin in Colmar, Alsace, eastern France, by crossing Millardet et Grasset 101-14 OP with GOLDRIESLING, where Millardet et Grasset 101-14 OP is a selfling of a Vitis riparia \u00d7 Vitis rupestris cross. This variety was named in honour of the vine breeder\u2019s wife and was commercialized in 1921.',
      'The same crossing produced the siblings of Lucie Kuhlmann (the vine): L\u00c9ON MILLOT and MAR\u00c9CHAL FOCH.',
    ],
    'where its grown': [
      'Like its more popular siblings L\u00c9ON MILLOT and MAR\u00c9CHAL FOCH, this French hybrid is more widely planted in cooler climates such as Canada\u2019s Qu\u00e9bec, Nova Scotia and Ontario than it is in France, though plantings are very limited even there. Like L\u00e9on Millot, the variety produces deeply coloured wines but they are a little more firmly structured and are likely to taste herbaceous if not fully ripe. Varietal wines are produced by, for example, Blomidon Estate and Gaspereau Vineyards in Nova Scotia, and Qu\u00e9bec\u2019s Domaine des C\u00f4tes d\u2019Ardoises is one of several to include Lucie Kuhlmann in their red blends.',
    ],
  },
  {
    name: 'LUGLIENGA',
    synonyms: [
      'Agostenga',
      'Bona in Ca',
      'Gu\u0161tana',
      'Jouanenc',
      'Lignan Blanc',
      'Lignenga',
      'Lugliatica',
      'Luglienga Blanca',
      'Luigese',
      'Seidentraube',
      'Uva di Sant\u2019Anna',
    ],
    description: 'Historic, widespread but mainly table grape.',
    'origin and parentage': [
      'Luglienga is a very old table and wine grape, mentioned in Piemonte, Italy, as long ago as 1329 under the old name Luglienchis (Fav\u00e0 i Agud 2001). Its numerous synonyms bear witness to the fact that it has been widely distributed all over Europe for several centuries. Luglienga is often grown on pergolas in front of houses, used mainly for table grapes and sometimes to produce homemade wine.',
      'The variety\u2019s name comes from Luglio (Italian for \u2018July\u2019), in reference to its early ripening, and this etymology finds an echo in an older French synonym, Jouanenc, from juin meaning \u2018June\u2019. Ampelographic studies had established that Luglienga was identical to France\u2019s Lignan Blanc and to Seidentraube from Germany and German-speaking Switzerland (Galet 2000) and this has been confirmed by DNA profiling (Vouillamoz, Frei and Arnold 2008). Parentage analysis has shown that Luglienga is a parent of PRI\u00c9, with which it was often confused, in the Valle d\u2019Aosta (Vouillamoz 2005). Luglienga was used as a parent in the breeding of REGNER.',
    ],
    'where its grown': [
      'As a table grape, Luglienga is grown all over Europe, from Spain to Hungary. It does not seem to be used to produce varietal wines but is still occasionally vinified.',
    ],
  },
  {
    name: 'LUMASSINA',
    synonyms: [
      'Acerbina',
      'Buzzetto',
      'Garella',
      'Mata\u00f2sso',
      'Mata\u00f2ssu',
      'Uga Matta',
    ],
    description:
      'Rare, light, crisp white exclusive to Liguria in north-west Italy.',
    'origin and parentage': [
      'Lumassina probably comes from the Genova region of Liguria in north-west Italy, where it is said to have been cultivated for centuries. Its name could derive from lumasse, local dialect for lumache, meaning \u2018snails\u2019, because it was traditionally paired with snail dishes. Its synonyms Acerbina (acerbo is Italian for \u2018acidic\u2019) and Buzzetto (buzzo means \u2018acidic\u2019 in the local dialect) both refer to the tradition of harvesting the grapes slightly before full maturity.',
    ],
    'where its grown': [
      'Lumassina has historically been cultivated on the steep slopes of Liguria, Italy, overlooking the sea and blended with BOSCO and VERMENTINO to produce the local Nostralino wine (a wine still celebrated by an annual festival). On the brink of extinction a few decades ago, the variety is today grown almost exclusively in the province of Savona, in particular in the communes of Finale Ligure, Quiliano, Noli, Spotorno and Varigotti. Today Lumassina is often blended with other local varieties to produce dry or sparkling wines but is also made as a varietal wine, eg by Cantine Ravera, Cascina delle Terre Rosse (L\u2019Acerbina) and Isetta Macella (Buzzetto IGT Colline Savonese). The wines tend to have aromas of flowers, herbs and green apples, fresh acidity and low alcohol of around 10\u201311%.',
    ],
  },
  {
    name: 'MACABEO',
    synonyms: [
      'Charas Blanc',
      'Lardot',
      'Macabeu',
      'Maccab\u00e9o',
      'Maccabeu',
      'Viura',
      'Vuera',
    ],
    description:
      'Often underestimated, widely grown variety that can make ageworthy whites as Viura in Rioja and Maccabeu in Roussillon.',
    'origin and parentage': [
      'Macabeo most likely originates from the Vilafranca del Pened\u00e8s region in Catalunya, where it was first mentioned in the early seventeenth century by Fray Miquel Agust\u00ed (1617): \u2018Lo vi Moscat, y lo vi Macabeu se han de cullir molt madurs los Rahims\u2019 (\u2018Grapes from the Muscat vine and from the Macabeu vine must be harvested very ripe\u2019). It was then introduced to Roussillon in southern France, where it was first mentioned in 1816 in Salses-le-Ch\u00e2teau, north of Perpignan (Gournay 1790): \u2018c\u2019est \u00e0 Salses, pr\u00e8s Perpignan, que se fait l\u2019excellent vin blanc, connu sous le nom de Macabeu, qui est d\u2019une d\u00e9licatesse & d\u2019un go\u00fbt exquis\u2019 (\u2018it is in Salses near Perpignan that the excellent white wine known under the name Macabeu, which has an exquisite delicacy and taste, is made\u2019). According to Fav\u00e0 i Agud (2001), the Catalan name Macabeu could derive from the French macab(r)\u00e9 used to designate a certain type of cleric.',
      'Contrary to popular belief, ALCA\u00d1\u00d3N from Somontano is not identical to Macabeo: the DNA profiles of Alca\u00f1\u00f3n (Mart\u00edn et al. 2003; Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007) and Macabeo (Iba\u00f1ez et al. 2003; Mart\u00edn et al. 2003) are quite distinct (Vouillamoz).',
      'According to DNA profiling, Macabeo could be related to XARELLO (Iba\u00f1ez et al. 2003), with which it is often blended and sometimes even confused. Recent DNA parentage analysis has also suggested that Macabeo is a natural cross between Heb\u00e9n, an almost extinct Andalusian variety, and a mysterious variety called Brustiano Faux (ie False Brustiano, distinct from BRUSTIANO BIANCO on Sardegna and Corse), which would make it a half-sibling of MAND\u00d3N from the Bierzo region in the province of Le\u00f3n in north-west Spain and GORGOLLASA from Mallorca (Garc\u00eda-Mu\u00f1oz et al. 2012). However, these parentages were based on too few DNA markers (twenty) and need to be confirmed.',
    ],
    'where its grown': [
      'In France, the variety is much less widely planted than in Spain but seems to have earned greater respect, particularly in Roussillon, where at least 85% of the 2,628 ha (6,494 acres) of Maccab\u00e9o/Maccabeu in France in 2009 was planted (significantly down on the 5,200 ha/12,849 acres reported in 2000). In and around the cauldron of wine experimentation that is the Roussillon\u2019s Vall\u00e9e de l\u2019Agly, the variety frequently turns up in generally barrel-aged blends with the likes of Grenache Blanc (see GARNACHA) and Carignan Blanc (see MAZUELO), while very successful varietal examples have emerged from Domaine Gayda (even if they have been labelled without much geographical precision).',
      'Macabeo/Viura is northern Spain\u2019s most planted white variety, although it is widely grown throughout the country, except in the far south and the north west. In 2008, there were 34,401 ha (85,007 acres) in Spain, predominantly in Catalunya (13,718 ha/33,898 acres), Castilla-La Mancha (5,311 ha/13,124 acres) and Arag\u00f3n (4,987 ha/12,323 acres), although it is also quite widely planted in Rioja, Valencia and Extremadura. Its prevalance in Catalunya is due mainly to the role it plays in Cava, where it is generally blended with PARELLADA and XARELLO. In Rioja it more or less displaced Malvas\u00eda Riojana (ALARIJE) and GARNACHA BLANCA, which were much more widely planted before phylloxera hit the region in 1901, but has generally not been planted recently, so average vine age is high. Although it has often suffered from lack of respect, some of the most distinguished traditional oak-aged white wines in Rioja, such as L\u00f3pez de Heredia\u2019s Vi\u00f1a Tondonia and Marqu\u00e9s de Murrieta\u2019s Capellan\u00eda, are based on or are exclusively Macabeu.',
      'Varietal wines are typically lightly floral and relatively aromatic when young and unoaked but acidity tends to be low and they can soon lose their aroma or develop a certain bitter-almond character. Despite this, low-cropped Macabeo responds well to barrel fermentation and oak ageing \u2013 both in the more traditional oxidative styles and in the rather more modern versions of producers such as Finca Allende, whose wines can drink well at about five years. In both Catalunya and Rioja it seems to be shown to best advantage in a blend, though frequently with Macabeo as the dominant partner. Other recommended producers in Rioja include Beronia, Palacios Remondo, Rem\u00edrez de Ganuza, Benjamin Romeo and Tob\u00eda, as well as Cercavins further east in the Costers del Segre DO.',
    ],
  },
  {
    name: 'MACERATINO',
    synonyms: [
      'Bianchetta Montecchiese',
      'Greco delle Marche',
      'Greco Maceratino',
      'Maceratese',
      'Matelicano',
      'Montecchiese',
      'Ribona',
      'Uva Stretta',
      'Verdicchio Marino',
      'Verdicchio Tirolese',
    ],
    description:
      'Rare white found in the Marche on Italy\u2019s Adriatic coast.',
    'origin and parentage': [
      'This variety originates from the province of Macerata in the Marche, from which its name derives. DNA profiling has shown that, contrary to local belief, Maceratino is distinct from GRECHETTO DI ORVIETO, GRECO, GRECO BIANCO and TREBBIANO TOSCANO and suggests a close relationship with VERDICCHIO BIANCO (Filippetti, Silvestroni, Thomas and Intrieri 2001).',
    ],
    'where its grown': [
      'Maceratino is planted almost exclusively in the province of Macerata and in the commune of Loreto in the province of Ancona, Italy. Colli Maceratesi DOC (still, sparkling or passito styles are all possible) stipulates a minimum of 70% Maceratino but also allows for a varietal version if the variety, under the synonym Ribona, is mentioned on the label. Varietal wines have good acidity and aromas of flowers, almonds and sometimes aniseed. Producers include Accattoli and Boccadigabbia, whose Mont\u2019Anello is an example of Colli Maceratesi Ribona, Villa Forano and Azzoni Avogadro Carradori.',
    ],
  },
  {
    name: 'MADELEINE ANGEVINE',
    synonyms: [
      '\u00c4ugstler Weiss',
      'Maddalena Angevina',
      'Madlen An\u017eevin',
    ],
    description:
      'Minor, fresh, citrusy French variety found mainly in Canada and very cool parts of Europe, sometimes grown for table grapes.',
    'origin and parentage': [
      'Madeleine Angevine is a seedling obtained in Angers in 1857 by Robert and Moreau, the successors to the famous vine breeder Jean-Pierre Vibert, and released in 1863 by their successor, who gave himself the nickname Moreau-Robert. This early-ripening variety was named after Sainte-Madeleine\u2019s Day (22 July), the period when the grapes were often ready for harvest, as well as after the city of Angers in the Val de Loire.',
      'Madeleine Angevine was originally said to be a Madeleine Royale \u00d7 PR\u00c9COCE DE MALINGRE cross but recent DNA parentage analysis has shown that it is in fact a Circ\u00e9 \u00d7 Madeleine Royale (see M\u00dcLLER-THURGAU) cross (Vargas et al. 2009), where Circ\u00e9 is a Schiras \u00d7 CHASSELAS cross obtained earlier by Vibert, and where Schiras is a French table grape obtained by Dr Houbdine.',
      'Madeleine Angevine was used to breed several wine or table grape varieties, including SIEGERREBE, CITRONNY MAGARACHA, CSABA GY\u00d6NGYE and MADELEINE \u00d7 ANGEVINE 7672.',
    ],
    'where its grown': [
      'Plantings in France in 2006 were a mere 7 ha (17 acres). It is also planted to a limited extent in Washington State\u2019s cool, damp Puget Sound AVA, where San Juan Vineyards produce a varietal wine which they describe as very aromatic and citrusy. Other Puget Sound producers of this fresh varietal include Perennial Vintners and Lopez Island Vineyards. Canada\u2019s British Columbia recorded 13 acres (5 ha) in 2007 and varietal wines are made in the Okanagan Valley by, among others, Larch Hills. It is apparently grown for table grapes in Kyrgyzstan.',
      'There are limited plantings in Denmark (less than 1 ha/2.5 acres in 2010), eg at Domaine Aalsgard just north of Helsing\u00f8r, the first accredited vineyard in the country, as well as in Jutland and on the islands of Zealand and Fyn, and in the very south of Sweden.',
    ],
  },
  {
    name: 'MADRASA',
    synonyms: [
      'Kara Shirei',
      'Qara Shira',
      'Matrasa',
      'Matrassa',
      'Medrese',
      'Sevi Shirai',
    ],
    description:
      'Widely planted Azeri variety producing full-bodied and well-structured wines.',
    'origin and parentage': [
      'Madrasa probably originates from the village of Madrasa in the Shamakhi region in north-eastern Azerbaijan, where it has been cultivated since the nineteenth century or earlier (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Wines are typically deeply coloured, high in alcohol and tannins and full-bodied and used \u2013 often blended \u2013 in the production of dry, sweet and fortified wines in Azerbaijan. Producers include Absheron Sharab, Ganja Sharab 2, Ismailli Wine and Shemakha Farm.',
    ],
  },
  {
    name: 'MAGLIOCCO CANINO',
    synonyms: ['Magliocco Ovale', 'Maglioccolone'],
    description: 'Makes tannic wines mainly for blends in Calabria.',
    'origin and parentage': [
      'A variety named Magliocca was mentioned in Calabria by Marafioti as early as the end of the 15th century. However, the name Magliocco has been used for several distinct varieties in southern Italy, often referred to as the \u2018Magliocchi family\u2019. Recent ampelographic and DNA studies have shown that only two distinct \u2018Magliocchi\u2019 exist: Magliocco Canino and MAGLIOCCO DOLCE (Schneider, Raimondi et al. 2009). The other grapes named \u2018Magliocco\u2019 correspond to other varieties, mainly GAGLIOPPO. As a consequence, there\u2019s no such thing as a \u2018Magliocchi family\u2019.',
      'In addition, DNA parentage analysis suggests that Magliocco Canino could have a parent\u2013offspring relationship with CALABRESE DI MONTENUOVO, which gave birth to SANGIOVESE through a natural cross with CILIEGIOLO (Vouillamoz, Monaco et al. 2007). As a consequence, Magliocco Canino is either a grandparent or a half-sibling of Sangiovese, thus further supporting the theory that part of Sangiovese\u2019s roots are in Calabria.',
    ],
    'where its grown': [
      'Magliocco Canino is cultivated on the Tirrenian coast of Calabria, Italy, mainly in the provinces of Catanzaro and Cosenza. Savuto is the only DOC to officially authorize Magliocco Canino, in a blend with other local varieties such as GAGLIOPPO and NERELLO CAPPUCCIO. The wine is generally tannic and contributes structure and ageing potential to a blend. Malaspina\u2019s Patros Pietro (IGT Calabria) is a blend of 80% Magliocco Canino and 20% CABERNET SAUVIGNON aged in acacia wood; Tenuta Terre Nobili\u2019s Cariglio (IGT Valle dei Crati) is a blend of 80% Magliocco Canino with 20% MAGLIOCCO DOLCE.',
      'Magliocco Canino is also said to be planted in the Marche and in Sicilia, but closer ampelographic study is probably needed.',
      'The Italian agricultural census (2000) records 616 ha (1,522 acres) of Magliocco Canino but since the census also describes MAGLIOCCO DOLCE as a synonym of Magliocco Canino, the figure cannot be taken at face value. Magliocco Dolce seems to be more widespread in Calabria than Magliocco Canino.',
    ],
  },
  {
    name: 'MAGLIOCCO DOLCE',
    synonyms: [
      'Arvino',
      'Catanzarese',
      'Gaddrica',
      'Greco Nero',
      'Guarnaccia Nera',
      'Lacrima Cristi Nera',
      'Magliocco Tondo',
      'Maglioccuni',
      'Mangiaguerra',
      'Marcigliana',
      'Marsigliana Nera',
      'Merigallo',
      'Nera di Scilla',
      'Petroniere',
    ],
    description:
      'Widely planted Calabrian and the more common of the two Maglioccos.',
    'origin and parentage': [
      'See MAGLIOCCO CANINO for more detail on the supposed \u2018Magliocchi family\u2019.',
      'The name Arvino, one of Magliocco Dolce\u2019s oldest synonyms, most likely derives from Lago Arvo in La Sila, a plateau that overlaps the Calabrian provinces of Crotone, Cosenza and Catanzaro and corresponding to the probable cradle of Magliocco Dolce. Ampelography and DNA profiling have shown that Marsigliana Nera, often considered to be a distinct variety, is in fact identical to Magliocco Dolce (Schneider, Raimondi, De Santis and Cavallo 2008).',
    ],
    'where its grown': [
      'Under numerous synonyms, Magliocco Dolce is one of the most widespread red wine grapes in Calabria, Italy, cultivated mainly in the provinces of Crotone, Catanzaro and Cosenza. Librandi\u2019s Magno Megonio is a good example of a varietal wine.',
      'The Italian agricultural census (2000) records 616 ha (1,522 acres) of MAGLIOCCO CANINO but includes Magliocco Dolce in this total so the actual figure for Magliocco Dolce is uncertain. However, Magliocco Dolce seems to be more widespread in Calabria than Magliocco Canino.',
    ],
  },
  {
    name: 'MAGNOLIA',
    synonyms: ['North Carolina 60-60'],
    description: 'Minor Muscadine variety that ripens unevenly.',
    'origin and parentage': [
      'A ((Hope \u00d7 Thomas) \u00d7 SCUPPERNONG) \u00d7 (Topsail \u00d7 Tarheel) Muscadine hybrid (mainly Vitis\nrotundifolia with a bit of Vitis\nlabrusca and Vitis\nvinifera) selected in 1954 at the North Carolina Agriculture Experiment Station, in which Hope is a selected vine of Vitis rotundifolia (see CARLOS for the pedigrees of Thomas, Topsail and Tarheel). It was released in 1961.',
    ],
    'where its grown': [
      'There were 20 acres (8 ha) of Magnolia in North Carolina in 2006 but overall plantings are very limited. Producers of varietal wines include Cypress Bend, Duplin and Uwharrie in North Carolina and Piney Woods in Texas. Others such as Colony Cellars in Texas and Wills Creek in Alabama use it in blends. Most descriptions of the wine refer to apples and pears.',
    ],
  },
  {
    name: 'MAGYARFRANKOS',
    synonyms: ['Magyar Frankos'],
    description:
      'Dark-skinned escapee from experimental plantings during Hungary\u2019s Communist era.',
    'origin and parentage': [
      'Magyarfrankos, meaning \u2018Hungarian-Franconian\u2019, is a Muscat Bouschet \u00d7 BLAUFR\u00c4NKISCH (or K\u00e9kfrankos) cross obtained in 1953 by P\u00e1l Kozma and J\u00f3zsef Tusn\u00e1di at the Szent Istv\u00e1n University in Hungary, in which Muscat Bouschet is a black-berried Muscat Rouge de Mad\u00e8re \u00d7 PETIT BOUSCHET cross that is no longer cultivated (see SANGIOVESE for the origin of Muscat Rouge de Mad\u00e8re). It was officially registered in 1974 together with B\u00cdBORKADARKA.',
    ],
    'where its grown': [
      'B\u00e1lint Losonci and a few other growers in the village of Gy\u00f6ngy\u00f6spata in M\u00e1tra, central northern Hungary, cultivate the last 2\u20133 ha (5\u20137 acres) of Magyarfrankos in the country, the remains of an experiment during the 1970s, rejected because it did not meet the two critical requirements of Communist-era viticulture: high yields and ease of cultivation. Losonci makes the only varietal example currently on the market, as well as a deeply coloured K\u00e9kfrankos/Magyarfrankos ros\u00e9 blend.',
    ],
  },
  {
    name: 'MAIOLICA',
    synonyms: ['Gaglioppa', 'Ortonese'],
    description:
      'Minor dark-skinned Abruzzo variety, usually blended with Montepulciano.',
    'origin and parentage': [
      'Maiolica is often confused with GAGLIOPPO because the bunches are similarly shaped.',
    ],
    'where its grown': [
      'Formerly widespread throughout Abruzzo, Italy,\nMaiolica is today restricted to the provinces of Pescara and Chieti, with the exception of a few plantings in the province of Macerata (Marche). There were 76 ha (188 acres) planted in Italy in 2000. It is usually blended with MONTEPULCIANO, providing colour, spicy aromas and soft tannins but it is also authorized as a varietal wine in IGT Colline Pescaresi.',
    ],
  },
  {
    name: 'MAIOLINA',
    synonyms: ['Majolina'],
    description:
      'Rare, productive, northern Italian red rescued from extinction in the 1980s.',
    'origin and parentage': [
      'Maiolina DNA is unique and fairly similar to that of other old Lombardian varieties (Geuna et al. 1997).',
    ],
    'where its grown': [
      'Right in the middle of the city of Brescia in central Lombardia, Italy,\nat the foot of the hill up to the castle, there\u2019s a unique and historic vineyard called Vigneto della Pusterla, owned by the Pusterla Winery. This 4 ha (10 acres) vineyard is trained in a local pergola style and produces not only a varietal INVERNENGA but also 2,000 bottles per year of Pusterla Rosso IGT Ronchi di Brescia, a blend of old, local and sometimes rare varieties, including Maiolina as well as MARZEMINO, GROPPELLO GENTILE, SCHIAVA GROSSA, BARBERA and CORVA.',
      'In addition to its inclusion in this unique \u2018city wine\u2019 supported by Slow Food, Maiolina has also long been cultivated in Ome in the Franciacorta area of Lombardia, where the appropriately named Majolini brothers rescued the variety from extinction in the early 1980s. Although the variety\u2019s name predates them, it could indeed derive from this common local family name. A few years ago, the brothers produced 360 magnums of red-fruited, well-structured but smooth varietal Maiolina to support their successful application to the ministry of agriculture to officially register Maiolina as an indigenous variety.',
    ],
  },
  {
    name: 'MALAGOUSIA',
    synonyms: ['Malagouzia', 'Malagoyzia', 'Malaouzia', 'Melaouzia'],
    description: 'Aromatic, high-quality Greek recently rescued from oblivion.',
    'origin and parentage': [
      'Malagousia probably comes from around N\u00e1fpaktos in Aitol\u00eda-Akarnan\u00eda on the southern coast of mainland Greece (Manessis 2000; Nikolau and Michos 2004).',
    ],
    'where its grown': [
      'Malagousia produces full-bodied, aromatic wines, their perfume ranging from basil, lime, orange and exotic fruits to rose petals. Although acidity is generally low, the best are zesty and elegant but poor examples can be unattractively heavy. Some wines have a finely grained texture thanks to the phenolics in the skins, increased by skin contact. There is some debate as to whether there is more than one clone since some of the vines seem to produce smaller berries and have higher acidity, others have bigger berries and are more aromatic, but this could also be due to pruning or site. Unlike certain aromatic varieties such as SAUVIGNON BLANC, the aromas do not seem to be lost \u2013 burnt off \u2013 in hotter sites. Although the acidity is higher in cooler regions, the wines can be thinner and less impressive.',
      'It is thanks to Evangelos Gerovassiliou\u2019s work when he was at Domaine Carras in the 1980s and 1990s that Malagousia was recognized for its great potential, the variety having been found in western Greece by Professor Logothetis of the Agricultural University of Thessalon\u00edki and planted in an experimental plot that he rented at Carras (Lazarakis 2005). However, Roxani Matsa close to the city of Athens has also been particularly influential in the continuing process of selection and distribution from her vineyards to many other growers around the country. At his own estate in Epanom\u00ed, Gerovassiliou continues the work of selection and makes both varietal wines (dry and barrel-fermented late harvest) and blends Malagousia with ASSYRTIKO to add perfume to the latter higher-acid and more mineral variety.',
      'Malagousia is now being planted in many different regions as far south as Attik\u00ed and the Pelop\u00f3nnisos (Peloponnese), generally on a small, experimental scale at first. Most of the more significant areas currently are in Kentrik\u00ed Makedon\u00eda (Central Macedonia). Recommended producers of varietal wines include Antonopoulos (P\u00e1tra), Argatia (N\u00e1oussa), Boutari/Matsa (Pallini), Gerovassiliou (Epanom\u00ed), Papagiannakos (Attik\u00ed), Claudia Papagianni (Halkidik\u00ed) and Vatistas (Monemvasia).',
    ],
  },
  {
    name: 'MALBO GENTILE',
    synonyms: ['Amabile di Genova', 'Tubino', 'Turbino'],
    description: 'Minor Italian red exclusive to Modena.',
    'origin and parentage': [
      'Despite its synonym Amabile di Genova, Malbo Gentile is not morphologically or genetically related to the varieties of the Ligurian Riviera. Recent DNA studies have shown that Malbo Gentile could be closely related to FORTANA from Emilia-Romagna (Boccacci et al. 2005).',
    ],
    'where its grown': [
      'Malbo Gentile is cultivated on hillsides in the provinces of Modena and Reggio Emilia, and to a much lesser extent in south-eastern Emilia-Romagna, Italy, where it is often blended with the various Lambruscos to increase the sugar content of the juice, eg in the Lambrusco Grasparossa di Castelvetro or Reggiano DOCs. The Colli di Scandiano e di Canossa DOC allows Malbo Gentile to be used in various blends or as a varietal wine and the latter is often produced in a precocious novella style. Sweet versions, such as Villa Bonluigi\u2019s passito-style La Pinona, and/or sparkling wines, such as Casali Viticoltori\u2019s Campo delle More, are also possible though less common.',
      'The Italian census of 2000 recorded 110 ha (270 acres) of Malbo Gentile.',
    ],
  },
  {
    name: 'MALIGIA',
    synonyms: [
      'Malese',
      'Malige',
      'Maligia Omalise',
      'Malis',
      'Malisa',
      'Malise',
      'Malisia',
      'Malixa',
      'Malixe',
      'Malixia',
    ],
    description: 'Rare vine makes alcoholic white in Emilia-Romagna.',
    'origin and parentage': [
      'The earliest mention of this variety supposedly dates back to the Middle Ages, when in c.1300 Pietro de Crescenzi wrote about a Malixia in the province of Bologna, but some morphological differences make this mention doubtful. Tanara\u2019s reference to a Maligia in Dozza (1644) is more reliable since the variety is still scattered in old vineyards in this area.',
    ],
    'where its grown': [
      'Maligia used to be widespread in the Imola region in the province of Bologna in Emilia-Romagna, Italy, extending westwards to Modena, in the province of the same name, and eastwards to Forl\u00ec in the province of Forl\u00ec-Cesena. Today, Maligia is cultivated on fewer than 3 ha (7 acres) in the communes of Imola, Castel San Pietro Terme, Dozza and Castel Bolognese in the province of Bologna and Faenza in the province of Ravenna. Wines are lightly aromatic with high alcohol and are generally used to enrich other local varieties.',
    ],
  },
  {
    name: 'MALVASIA',
    synonyms: [
      'These are basically translations of Malvasia',
      'rather than synonyms',
      'since the name Malvasia is used for so many different varieties',
      'as explained below: Malmsey',
      'Malvagia',
      'Malvasier',
      'Malvasijie',
      'Malvelzevec',
      'Malvoisie',
    ],
    description: 'MALVASIA',
    'origin and parentage': [
      'Malvasia is a generic name given to a wide range of distinct white-, pink-, grey- or black-skinned varieties which share an ability to produce sweet wines high in alcohol. It supposedly derives from the Greek island port of Monemvasia off the east coast of the Pelop\u00f3nnisos (Peloponnese), from which the various Malvasia grapes are supposed to have gone out into the world. Monemvasia was corrupted to Malfasia and then to Malvasia in Italian and Portuguese, Malvagia in Spanish, Malvasier in German, Malmsey in English, Malvasijie in Croatian and Malvoisie in French (Galet 2000).',
      'The port of Monemvasia used to be the trading hub for the wines produced in the region and on the neighbouring islands of the southern Aegean. The earliest mention of the sweet wines of Monemvasia dates back to 1214 in \u00c9fesos (Ephesus, Greece), and in 1278 the Venetians were already importing vinum de Malvasias. The identities of the grape varieties making up this sweet wine are unknown, although the various suggestions include some permutation of the following: A\u00cfDANI, ASSYRTIKO, ATHIRI, KYDONITSA, LIATIKO, MONEMVASSIA, THRAPSATHIRI and VILANA.',
      'However, the Greek origin of the Malvasias is not supported by recent DNA studies showing that the varieties named Malvasia Something are genetically very distinct and do not usually have a common origin (Fanizza et al. 2003; Lacombe et al. 2007). It therefore makes no sense to speak of a \u2018Malvasia family\u2019.',
    ],
  },
  {
    name: 'MALVASIA BIANCA DI CANDIA',
    synonyms: [
      'Malvasia Bianca',
      'Malvasia Candia',
      'Malvasia di Candia',
      'Malvasia Rossa',
    ],
    description:
      'Most planted Malvasia, making neutral white. In decline but still quite widely planted and blended in central Italy.',
    'origin and parentage': [
      'Candia is an old name for the island of Kr\u00edti (Crete), where this variety supposedly comes from. However, so far there\u2019s no genetic evidence to support this idea. Recent DNA profiling has shown that MALVASIA DI CANDIA AROMATICA is not a mutation of Malvasia Bianca di Candia but a distinct variety, genetically close to MALVASIA DI CASORZO (Lacombe et al. 2007). More recently, DNA parentage analysis has explained these relationships by showing that Malvasia Bianca di Candia is a likely progeny of GARGANEGA (see that entry for the pedigree diagram), the other parent being unknown (Crespan, Cal\u00f2 et al. 2008). As a consequence, Malvasia Bianca di Candia is either a half-sibling or a grandparent of Garganega\u2019s other progenies: ALBANA, CATARRATTO BIANCO, DORONA DI VENEZIA, MARZEMINA BIANCA, MONTONICO BIANCO, MOSTOSA (aka Empibotte Bianco), SUSUMANIELLO and TREBBIANO TOSCANO.',
    ],
    'where its grown': [
      'Malvasia Bianca di Candia is the most widely planted Malvasia in Italy. It is cultivated mainly in Lazio and to a lesser extent in Emilia-Romagna, Liguria, Toscana, Campania, Umbria and on Sardegna. It is usually blended with other varieties, particularly various types of TREBBIANO, to create what might be described as a typical central Italian blend, eg in DOCs such as Colli Lanuvini, Colli Martani, Castelli Romani, Colli Piacentini, Frascati and so on. It is prone to oxidation, thereby becoming deeper in colour and losing freshness, and the wines are often rather neutral-tasting and high in alcohol.',
      'Total plantings in Italy in 2000 amounted to 8,788 ha (21,716 acres) \u2013 down significantly from around 50,000 ha (123,553 acres) in the early 1990s.',
    ],
  },
  {
    name: 'MALVASIA BIANCA DI PIEMONTE',
    synonyms: [
      'Caccarella',
      'Greco',
      'Malvasia Bianca',
      'Malvasia Greca',
      'Moscatella',
      'Moscato Greco',
      'Mosella',
    ],
    description:
      'California\u2019s Malvasia. Aromatic, tough-skinned, originally Italian.',
    'origin and parentage': [
      'This member of the heterogeneous group of varieties known as Malvasia Something may have been mentioned near Torino in 1606 by agronomist and polymath Croce, under the name Malvasia. It was widely cultivated in Piemonte, north-west Italy, until it was replaced by MUSCAT BLANC \u00c0 PETITS GRAINS (known there as Moscato Bianco) after powdery mildew devastated the vineyards at the end of the nineteenth century (Schneider, Mannizi and Cravero 2004).',
    ],
    'where its grown': [
      'A few old vines of Malvasia Bianca di Piemonte can still be found in scattered vineyards in the provinces of Alessandria, Asti, Cuneo and Torino in Piemonte, Italy.',
      'Today it is much more important in California, where it was introduced by immigrants from Piemonte under the misleading name of Malvasia Bianca (see the MALVASIA entry). Wines here are typically dry to off dry with floral, herbal, citrus, sometimes more tropical, aromas and flavours and a certain viscosity or oiliness on the palate. Producers of varietal wines include Ballentine in Napa, Birichino in Monterey, Palmina in Santa Ynez Valley, Kenneth Volk and Wild Horse in San Bernabe. There were 1,384 acres (560 ha) in California in 2009, mainly in Merced, Madera and San Joaquin counties in the Central Valley but also in cooler Sonoma.',
    ],
  },
  {
    name: 'MALVASIA BIANCA LUNGA',
    synonyms: [
      'Krizol',
      'Malvasia Bianca',
      'Malvasia Bianca Siciliana',
      'Malvasia Bianca Toscana',
      'Malvasia del Chianti',
      'Malvasia di Arezzo',
      'Malvasia di Brolio',
      'Malvasia di San Nicandro',
      'Malvasia Lunga',
      'Malvasia Toscana',
      'Malvasia Verace',
      'Mara\u0161kin',
      'Mara\u0161tina',
      'Mare\u0161tina',
      'Menuetta',
      'Pavlos',
      'Prosecco Nostrano',
      'Racina du Monacu Bianca',
      'Rukatac',
      'Tundulillu Bianco',
      'Vi\u0161ana',
    ],
    description:
      'Widely distributed neutral Tuscan variety used in blends for both dry wines and Vin Santo.',
    'origin and parentage': [
      'This variety has for several centuries been found in the Chianti region of Toscana, under the entirely logical name of Malvasia del Chianti. Recent DNA profiling has shown that it is identical to Mara\u0161tina in Croatia and Pavlos in Greece (\u0160imon et al. 2007), and that it is genetically close to MALVAZIJA ISTARSKA and MALVASIA DI LIPARI (aka Malvasia di Sardegna; Lacombe et al. 2007). In addition, DNA parentage analysis has shown that MALVASIA NERA DI BRINDISI is a natural cross between Malvasia Bianca Lunga and NEGROAMARO (Crespan et al. 2008). Parentage analysis has also revealed that VITOVSKA, a white grape of the Karst region (north-east Italy and western Slovenia), is the progeny of Prosecco Tondo (see PROSECCO), also from the Karst region, and Malvasia Bianca Lunga (Crespan et al. 2007), suggesting that the latter was also cultivated in north-east Italy at the time Vitovska was born, possibly under its local synonym Prosecco Nostrano di Conegliano. It was used to breed INCROCIO BIANCO FEDIT 51.',
    ],
    'where its grown': [
      'Malvasia Bianca Lunga, under the name Malvasia del Chianti, is cultivated in almost every province of Toscana in central Italy and is still sometimes used in Chianti DOCG wines. It was traditionally blended with SANGIOVESE and CILIEGIOLO in the famous Chianti recipe established by Barone Bettino Ricasoli in the nineteenth century but significantly modified in the 20th. (Since 2006, white varieties have not been permitted in Chianti Classico DOCG.) It is authorized in several Tuscan DOCs, particularly for Vin Santo. More recently, Malvasia Bianca Lunga has also been cultivated in the Veneto, in Puglia in the provinces of Brindisi and Bari (and must constitute at least 50% of the blend in Leverano DOC), in Umbria, Lazio and the Marche. In some DOCs, it is restricted to a maximum of 10%, eg in Biferno (Molise) and Lizzano (Puglia). Wines tend to be rather neutral but full-bodied thanks to their relatively high alcohol. Total plantings in Italy in 2000 were 4,741 ha (11,715 acres).',
      'As Mara\u0161tina (and sometimes as Rukatac), this variety is widely but not intensively cultivated along the coast in Croatia and on several islands, including Kor\u010dula and Lastovo, often interplanted as a secondary variety. It is officially authorized for the regions of Hrvatsko Primorje (Croatian Littoral), Sjeverna Dalmacija (Northern Dalmatia), Dalmatinska Zagora (Dalmatian Hinterland) and Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia). There were just over 500 ha (1,236 acres) in 2009, ie just over 1.5% of the total vineyard area. Wines are golden yellow, full-bodied and sometimes lacking in acidity. Producers of varietal wines include Mato Antunovi\u0107, Marko Duvan\u010di\u0107 (Oklaj) and Toreta (Smokvica).',
    ],
  },
  {
    name: 'MALVASIA BRANCA DE S\u00c3O JORGE',
    synonyms: ['Malvasia Branca de S Jorge'],
    description:
      'Portuguese variety of unknown parentage but high quality used in the production of Madeira.',
    'origin and parentage': [
      'This variety was bred from unknown parents by Jos\u00e9 Le\u00e3o Ferreira de Almeida at the Esta\u00e7\u00e3o Agron\u00f3mica Nacional research centre near Lisboa in Portugal. It was introduced to the island of Madeira in the 1970s and was cultivated mainly in the parish of S\u00e3o Jorge in the district of Santana on the north coast of the island, hence the name.',
    ],
    'where its grown': [
      'The variety was found to be well suited to the production of Portugal\u2019s Madeira because the grapes have high levels of both sugar and acidity and it expanded rapidly on the island in the 1990s. The planted area is now stable at around 35 ha (86 acres) and this is now the main variety used to make Malmsey, or Malvasia, the sweetest of the island\u2019s fortified wines. Ricardo Diogo of Barbeito is very enthusiastic about the quality of this variety and is experimenting with drier styles, although such wines cannot be labelled Malvasia.',
    ],
  },
  {
    name: 'MALVAS\u00cdA DE LANZAROTE',
    synonyms: [
      'M\u00e1laga',
      'Malvas\u00eda',
      'Malvas\u00eda Portuguesa',
      'Malvas\u00eda Volc\u00e1nica',
      'Peregil',
      'Perejil',
      'Sebasti\u00e1n Garc\u00eda',
    ],
    description:
      'Recently identified variety found on the Islas Canarias, easily confused with other Malvas\u00eda Somethings.',
    'origin and parentage': [
      'This variety is found on the Islas Canarias under the ambiguous name of Malvas\u00eda but since it has a unique DNA fingerprint (Rodr\u00edguez-Torres et al. 2009), it was christened Malvas\u00eda de Lanzarote. DNA parentage analysis by Rodr\u00edguez-Torres et al. (2009) has suggested that Malvas\u00eda de Lanzarote could be a natural and local cross between MALVASIA DI LIPARI, cultivated on the Islas Canarias under the names Malvas\u00eda de Sitges and Malvas\u00eda de la Palma, and the local MARMAJUELO.',
    ],
    'where its grown': [
      'Malvas\u00eda de Lanzarote has not yet been included in the register of varieties in Spain and does not therefore appear in the official vineyard statistics but it has been found in several of the appellations on the Islas Canarias such as Lanzarote, La Palma and Valle de G\u00fc\u00edmar. Wines show typical Malvasia characteristics of light aromas, full body and full flavour. La Geria on Lanzarote make both a full-bodied sweet, tropical-fruited, rather wonderfully named Malvas\u00eda Volc\u00e1nica and a dry version.',
    ],
  },
  {
    name: 'MALVASIA DEL LAZIO',
    synonyms: [
      'Malvasia col Puntino',
      'Malvasia Gentile',
      'Malvasia Puntinata',
    ],
    description: 'Muscat of Hamburg sibling, grapey wines.',
    'origin and parentage': [
      'Recent DNA profiling has shown that Malvasia del Lazio is a natural cross between MUSCAT OF ALEXANDRIA and SCHIAVA GROSSA (Lacombe et al. 2007), which explains the light Muscat aroma of its berries. The crossing most likely took place in Lazio, where both parents must have been cultivated in the past. Since Malvasia del Lazio and MUSCAT OF HAMBURG share the same parents, they are full siblings (see MUSCAT and Schiava Grossa pedigree diagrams).',
      'The synonym of Malvasia Puntinata comes from the typical single dots (punti in Italian) on the berries.',
    ],
    'where its grown': [
      'Malvasia del Lazio is cultivated predominantly in Lazio, Italy,\nmainly in the Castelli Romani area south of the capital, and usually blended with other local grapes in various DOCs such as Cerveteri, Colli Albani, Colli Etruschi Viterbesi, Colli Lanuvini and Frascati. Marani DOC also allows for a varietal Malvasia del Lazio. It is also cultivated to a limited extent in Abruzzo.',
      'Varietal wines, mostly classified as IGT Lazio, generally have aromas of flowers and exotic or white fruits and are generally best drunk young. Examples include Pallavicini\u2019s Stillato, now made from grapes dried off the vine, Casale Pilozzo\u2019s Passione, Fontana Candida\u2019s Terre dei Grifi, Cantine San Marco\u2019s Solo Malvasia and Cantine Colli di Catone\u2019s Colle Gaio and Villa Ari Fiore di Malvasia. Its susceptibility to botrytis also makes it a useful \u2013 and sometimes the main \u2013 component in nobly rotten sweet wines such as those made by Castel de Paolis.',
      'There were 2,626 ha (6,489 acres) planted in Italy in 2000.',
    ],
  },
  {
    name: 'MALVASIA DI LIPARI',
    synonyms: [
      'Greco Bianco di Gerace',
      'Greco di Gerace',
      'Malmsey',
      'Malvagia',
      'Malvasia C\u00e2ndida',
      'Malvas\u00eda de la Palma',
      'Malvas\u00eda de Sitges',
      'Malvas\u00eda de Tenerife',
      'Malvasia delle Lipari',
      'Malvasia di Cagliari',
      'Malvasia di Sardegna',
      'Malvasija Dubrova\u010dka',
    ],
    description:
      'Very widespread variety used for a wide array of generally sweet, often dried-grape, wines.',
    'origin and parentage': [
      'Recent DNA profiling has shown that the Italian varieties Malvasia di Lipari, Malvasia di Sardegna and Greco Bianco di Gerace plus Malvas\u00eda de Sitges (Spain), Malvasia C\u00e2ndida (Madeira) and Malvasija Dubrova\u010dka (Croatia), previously believed to be distinct cultivars, are in fact one and the same variety (Crespan, Cabello et al. 2006; Lopes et al. 2006), and that Malvasia di Lipari (under the name Malvasia di Sardegna) is genetically close to MALVAZIJA ISTARSKA, MALVASIA BIANCA LUNGA (also known as Malvasia del Chianti) and MALVASIA NERA DI BRINDISI (Lacombe et al. 2007). This widespread distribution strongly suggests that Malvasia di Lipari is a very old variety, but its origin and itinerary remain obscure (Crespan, Cabello et al. 2006).',
      'Malvas\u00eda Rosada is a colour mutation found on the Islas Canarias, Spain; Malvasia C\u00e2ndida Roxa is similarly a mutation with thin reddish skin grown on the island of Madeira and also found in D\u00e3o, Portugal, though the total area is less than 2 ha (5 acres).',
    ],
    'where its grown': [
      'This variety is cultivated under various synonyms all round the western Mediterranean, and on the Islas Canarias and Madeira in the Atlantic:',
      '\u2013\u00a0\u00a0\u00a0Malvasia di Lipari, typical of Lipari\u2019s archipelago just north of Sicilia, Italy, where producers such as the Barone Villagrande and Tasca d\u2019Almerita make the famous orange-flavoured Malvasia delle Lipari passito wines. The Italian agricultural census recorded total plantings of 133 ha (329 acres) in 2000. In Sicilia in 2008, there were 122 ha (301 acres).',
      '\u2013\u00a0\u00a0\u00a0Greco Bianco di Gerace, cultivated mainly in the villages of Bianco and Casignara in Calabria, where it also produces the less famous but locally acclaimed Greco di Bianco DOC passito wine.',
      '\u2013\u00a0\u00a0\u00a0Malvasia di Sardegna, used to produce dry and sweet wines, sometimes fortified (and, if so, aged for at least two years), in the DOCs of Malvasia di Cagliari and Malvasia di Bosa. Producers include Battista Colombu, Fratelli Porcu and Silattari.',
      '\u2013\u00a0\u00a0\u00a0Malvasia C\u00e2ndida was introduced to Madeira, Portugal, in the 15th century and has been used to produce Malmsey or Malvasia, the sweetest of the island\u2019s wines, although today very little remains (4 ha/10 acres in 2010) and MALVASIA BRANCA DE S\u00c3O JORGE is the dominant variety for such wines. It is also found to a lesser extent in regions of the Portuguese mainland such as Beiras, Tr\u00e1s-os-Montes, the Tejo and Lisboa (just 1 ha/2.5 acres in 2010).',
      '\u2013\u00a0\u00a0\u00a0Malvas\u00eda de Sitges survives on 2.5 ha (6 acres) in Catalunya, north-east Spain. It is bottled as an aromatic, fresh, sweet passito wine within the Pened\u00e8s DO by the Hospital Sant Joan Baptista and also found very occasionally in the Catalunya DO. It may also be used to add perfume and freshness to more neutral dry whites.',
      '\u2013\u00a0\u00a0\u00a0Malvas\u00eda de Tenerife or Malvas\u00eda Rosada, used on the Islas Canarias in DOs such as Abona and Valle de G\u00fc\u00edmar.',
      '\u2013\u00a0\u00a0\u00a0Malvasija Dubrova\u010dka, recognized in Croatia since 1385 (Archive of the Republic of Dubrovnik) but today rarely found except in the Konavle wine region around Dubrovnik, is the subject of current clonal research at the Faculty of Agriculture in Zagreb and plantings are increasing from a very small base. Wines tend to be powerful with moderate acidity and only lightly aromatic. The variety\u2019s loose bunches make it suitable for drying to make sweet wines. Recommended producers include Karaman and Crvik.',
    ],
  },
  {
    name: 'MALVASIA DI SCHIERANO',
    synonyms: [
      'Malvasia a Grappolo Corto',
      'Malvasia di Castelnuovo Don Bosco',
    ],
    description:
      'Minor, dark-skinned Malvasia from Piemonte that produces lightly aromatic sweet reds.',
    'origin and parentage': [
      'The earliest mention of a red-berried Malvasia in Piemonte is to be found in De Maria and Leardi (1875), but it is not known whether it was a reference to MALVASIA NERA LUNGA, Malvasia di Schierano or MALVASIA DI CASORZO. Recent DNA profiling has shown that Malvasia di Schierano is genetically close to MALVASIA DEL LAZIO and MUSCAT OF ALEXANDRIA (Lacombe et al. 2007).',
    ],
    'where its grown': [
      'Malvasia di Schierano is cultivated in the province of Torino in Piemonte, north-west Italy (in Schierano, Castelnuovo Don Bosco and Pino Torinese), where it must comprise at least 85% of the still or sparkling red Malvasia di Castelnuovo Don Bosco DOC (with up to 15% FREISA) and at least 85% of a wine labelled Collina Torinese Malvasia. Producers of varietal wines include Cascina Gilli and Cantine Bava.',
      'There were 183 ha (452 acres) of this variety planted in Italy in 2000.',
    ],
  },
  {
    name: 'MALVASIA FINA',
    synonyms: [
      'Arinto do D\u00e3o',
      'Assario',
      'Assario Branco',
      'Boal',
      'Boal Branco',
      'Boal Cachudo',
      'Boal da Graciosa',
      'Boal da Madeira',
      'Cachudo',
      'Galego',
      'Gual',
      'Terrantez do Pico',
      'Torront\u00e9s',
    ],
    description:
      'High-quality Portuguese variety that goes by many names, notably Boal on Madeira, and produces many styles of wine.',
    'origin and parentage': [
      'Malvasia Fina is a very old variety from either the Douro or the D\u00e3o region in Portugal. The particularly high degree of genetic diversity found in the Douro suggests this may be the variety\u2019s birthplace but neither D\u00e3o nor Lisboa can be ruled out (Rolando Faustino, personal communication). Malvasia Fina is known under various names throughout the country. The synonym Boal used on the island of Madeira is especially confusing since it is also used for several other white wine varieties such as ARINTO DE BUCELAS, Boal Ratinho (no longer cultivated), DONA BRANCA, RABIGATO, S\u00c9MILLON and S\u00cdRIA. The official name adopted in Portugal in 2000 for this variety was Malvasia Fina. The synonym Boal Branco is equally confusing because it may also refer to S\u00e9millon in the Douro and to another obscure but distinct variety, now scarcely cultivated (Rolando Faustino, personal communication).',
      'DNA parentage analysis has shown that Boal Ratinho from the Carcavelos region just west of Lisboa is an offspring of Malvasia Fina (under the name Boal da Madeira) and S\u00cdRIA (under the name C\u00f3dega; Lopes et al. 1999).',
    ],
    'where its grown': [
      'Malvasia Fina is the basis of D\u00e3o whites in Portugal and produces varietal wines that are often relatively high in alcohol with average levels of acidity. The best are fruity and perfumed, elegant and ageworthy, developing fine aromatic complexity as they age in bottle. They respond well to barrel fermentation and ageing. Fruit from cooler regions produces wines that are particularly elegant and early-harvested grapes make a good base for sparkling wines, such as those from Varosa, Lamego and Encostas da Nave in the far north of Beiras. Total plantings in Portugal in 2010 were 2,217 ha (5,478 acres) and Malvasia Fina is authorized in DOCs such as Porto, Douro, T\u00e1vora-Varosa, D\u00e3o, Beira Interior and Lagos and in many regional wines, under various synonyms, as far from its birthplace as the Algarve and the A\u00e7ores. Quinta das Maias make an unfortified varietal wine in D\u00e3o, Murganheira a sparkling version in the Beiras subregion T\u00e1vora-Varosa.',
      'On the island of Madeira, Boal is grown predominantly on the southern coast, between the municipalities of Ribeira Brava and Calheta. The name Boal (known as Malvasia Fina only in official documents) was once used to identify a style of wine that was generally sweeter than Verdelho and less sweet than Malmsey. However, today a Madeira labelled Boal must comprise 85% of this variety and there were just 20 ha (49 acres) left on the island in 2010, though that area has remained stable over the last decade. The several producers of varietal Boal Madeiras include Barbeito, Blandy\u2019s and Henriques & Henriques.',
    ],
  },
  {
    name: 'MALVASIA NERA DI BASILICATA',
    synonyms: ['Malvasia Nera'],
    description:
      'The less common of the two dark-skinned Malvasias of southern Italy.',
    'origin and parentage': [
      'Malvasia Nera di Basilicata is not a colour mutation of MALVASIA BIANCA DI BASILICATA. It has morphological similarities with MALVASIA NERA DI BRINDISI, and some authors have suggested they might be one and the same variety but this has not yet been established by DNA testing. On the other hand, DNA profiling has established that Malvasia Nera di Brindisi and Malvasia Nera di Lecce are identical (Crespan, Colleta et al. 2008). Malvasia Nera di Basilicata is said to have been introduced to Basilicata from Puglia. In Toscana and in Basilicata, a few old vines of the Spanish variety TEMPRANILLO have been found under the misleading names Malvasia Nera and Malvasia Nera di Lecce (Storchi et al. 2009).',
    ],
    'where its grown': [
      'Malvasia Nera di Basilicata is widespread in the provinces of Potenza and Matera in Basilicata in southern Italy, where it is sometimes blended with other local grapes, including AGLIANICO, to increase aroma, alcohol and acidity in IGT Basilicata or Grottino di Roccanova DOC. It is also authorized in the ros\u00e9 version of Terre dell\u2019Alta Val d\u2019Agri DOC. The Italian agricultural census of 2000 distinguishes between Malvasia Nera di Basilicata, MALVASIA NERA DI BRINDISI and Malvasia Nera di Lecce and records total plantings of 848 ha (2,095 acres) for the first, with di Brindisi being much more widely planted (2,238 ha/5,530 acres).',
    ],
  },
  {
    name: 'MALVASIA NERA DI BRINDISI',
    synonyms: [
      'Malvasia Negra',
      'Malvasia Nera',
      'Malvasia Nera di Bari',
      'Malvasia Nera di Bitonto',
      'Malvasia Nera di Lecce',
      'Malvasia Nera di Trani',
      'Malvasia Niura',
    ],
    description:
      'The most common of the two dark-skinned Malvasias of southern Italy, commonly blended with Negroamaro, one of its parents.',
    'origin and parentage': [
      'Until recently, Malvasia Nera di Brindisi and Malvasia Nera di Lecce were thought to be two distinct varieties from the Salento region in Puglia, southern Italy, but DNA profiling has recently shown that they are identical and that this variety is a natural cross between MALVASIA BIANCA LUNGA and NEGROAMARO (Crespan, Colleta et al. 2008). Since both parents have been cultivated in Puglia for centuries, the cross most likely happened there. Whether Malvasia Nera di Brindisi and MALVASIA NERA DI BASILICATA are the same variety has not yet been tested by DNA profiling and therefore cannot be ruled out.',
      'In Toscana and in Basilicata, a few old vines of the Spanish variety TEMPRANILLO have been found under the misleading names Malvasia Nera (MALVASIA NERA DI BASILICATA) or Malvasia Nera di Lecce (a synonym for Malvasia Nera di Brindisi; Storchi et al. 2009).',
    ],
    'where its grown': [
      'Malvasia Nera di Brindisi is cultivated mainly in Puglia (notably in the provinces of Lecce, Taranto and Brindisi), southern Italy, but also in Toscana and Calabria. It is often used to produce ros\u00e9 wines in a blend with its parent NEGROAMARO and with SUSUMANIELLO. Conti Zecca produce both red and ros\u00e9 versions. In most of the DOCs in which it is authorized, such as Lizzano, Nard\u00f2, Salice Salentino and Squinzano, it is the junior partner (15\u201330% maximum) in blends with Negroamaro. Producers of varietal wines within IGT Puglia or IGT Salento include Duca Carlo Guarini, Feudi di San Marzano, Bortrugno (both dry and passito styles) and Lomazzi & Sarli. Varietal wines\u2019 fruit can taste sweet and rather dusty.',
      'The Italian agricultural census of 2000 distinguishes between Malvasia Nera di Brindisi and di Lecce because the DNA results (see above) were not available at that time. It records total plantings of 2,238 ha (5,530 acres) for the first and 669 ha (1,653 acres) for the second.',
    ],
  },
  {
    name: 'MALVASIA NERA LUNGA',
    synonyms: ['Moscatella'],
    description:
      'Rare, dark-skinned Malvasia exclusive to the province of Asti.',
    'origin and parentage': [
      'In Piemonte, Malvasia Nera Lunga is often confused with MALVASIA DI SCHIERANO but it has bigger and longer clusters, and it is also very distinct from MALVASIA DI CASORZO (Schneider and Mannini 2006). Although it is also called Malvasia Rosa, it should not be confused with the bud mutation of MALVASIA DI CANDIA AROMATICA discovered by Mario Fregoni in 1967 in the province of Piacenza.',
    ],
    'where its grown': [
      'Malvasia Nera Lunga is cultivated almost exclusively in the area of Castelnuovo Don Bosco and Albugnano in the province of Asti in Piemonte, north-west Italy, together with MALVASIA DI SCHIERANO, often interplanted. The area of Malvasia Nera Lunga was not recorded in the Italian agricultural census in 2000 because it had not been entered in the official register of varieties. However, it has been now been added to the register and, since 2008, both varieties have been authorized in the Collina Torinese and Malvasia di Castelnuovo Don Bosco DOCs.',
    ],
  },
  {
    name: 'MALVASIA PRETA',
    synonyms: ['Moreto', 'Mureto', 'Pinheira Roxa'],
    description: 'Douro variety used for port and table wines.',
    'origin and parentage': [
      'Recent DNA parentage analysis has shown that Malvasia Preta, literally \u2018black Malvasia\u2019, is a natural cross between the Iberian variety CAYETANA BLANCA and the Portuguese ALFROCHEIRO (Zinelabidine et al. 2012), which possibly occurred in the Douro in northern Portugal, its main area of cultivation. Malvasia Preta is therefore a sibling of CORNIFESTO, CAMARATE, JUAN GARC\u00cdA and CASTEL\u00c3O (see Cayetana Blanca for the pedigree diagram). The link between Malvasia Preta and the other Malvasia Something varieties is unknown. A large-berried mutation known as Pinheira Roxa has been observed but any link with JAMPAL, sometimes called Pinheira Branca (Lopes et al. 2006), is uncertain.',
      'According to Truel in Galet (2000), Malvasia Preta could be a synonym of Moreto do D\u00e3o (no longer cultivated), but this has been disproved by DNA profiling (Veloso et al. 2010).',
    ],
    'where its grown': [
      'Malvasia Preta is grown mostly in the Douro, often in old mixed vineyards, where it is authorized for both port and unfortified wines; it is also found to a lesser extent in Beiras and is authorized for regional wines and for the T\u00e1vora-Varosa DOC. Varietal wines show aromas of red fruits (often raspberry) and a light floral note of violets. They are well structured and have the potential to age in bottle. There were 1,144 ha (2,827 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'MALVAZIJA ISTARSKA',
    synonyms: [
      'Istrska Malvazija',
      'Malvasia del Carso',
      'Malvasia Friulana',
      'Malvasia Istriana',
      'Malvazija',
      'Malvazija Istarska Bijela',
      'Polij\u0161akica Drnovk',
    ],
    description:
      'The emphatic white wine grape of Croatia\u2019s Istra (Istria) peninsula.',
    'origin and parentage': [
      'As the Croatian name Malvazija Istarska and the Italian name Malvasia Istriana suggest, this variety is likely to originate in Istra, the peninsula in north-west Croatia that used to belong to Italy. Although it was not mentioned in Istra until 1891, Malvazija Istarska has certainly been cultivated there for centuries. It is ampelographically quite distinct from other Malvasias such as MALVASIA DEL LAZIO and MALVASIA BIANCA DI CANDIA (Giavedoni and Gily 2005) but recent DNA profiling has shown that Malvazija Istarska is genetically close to MALVASIA DI LIPARI (aka Malvasia di Sardegna), MALVASIA BIANCA LUNGA and MALVASIA NERA DI BRINDISI (Lacombe et al. 2007).',
      'Malvazija Istarska was used to breed VEGA.',
    ],
    'where its grown': [
      'Although Malvazija Istarska has spread down the Croatian coast to northern Dalmacija (Dalmatia) and is still found in some old vineyards in Kontinentalna Hrvatska (Continental Croatia) (eg in Kalnik and Kri\u017eevci), the variety\u2019s homeland is Istra, where it is the dominant variety; just about every grower bottles one or several examples. After GRA\u0160EVINA, by a very long way, it is Croatia\u2019s second most planted variety, with its 3,410 ha (8,426 acres) in 2009 comprising 10.5% of the total vineyard area. It is officially authorized in the Istra and Hrvatsko Primorje (Croatian Littoral) wine regions.',
      'Varietal wines (and they usually are) vary enormously in style, from full and powerful, sometimes oaked and a little spicy, to more taut, zesty and refreshing examples, most of them having a certain lightly honeyed tang about them. They often have a slightly green cast and seem generally fresher than many other varieties with Malvasia in their name, although the alcohol can reach intrusively high levels. Recommended producers include Bienvenuti, Giorgio Clai (Clai Bijele Zemlje), Moreno Coronica, Kabola (Marino Marke\u017ei\u0107), Gianfranco Koslovi\u0107, Ivica Mato\u0161evi\u0107, Roxanich and Bruno Trapan. Wines that have been made using long skin maceration such as Clai\u2019s and Roxanich\u2019s show that Malvazija Istarska is well suited to this winemaking technique, adding further depth and complexity yet without bitterness or excess of tannin.',
      'There were 466 ha (1,152 acres) in Italy in 2000, mostly in the north east, according to the agricultural census. This seems like a variety that could be of real interest elsewhere.',
    ],
  },
  {
    name: 'MALVERINA',
    synonyms: ['BV-19-143'],
    description:
      'Recent, late-ripening, disease-resistant Czech hybrid producing aromatic whites.',
    'origin and parentage': [
      'Malverina is a Rakisch \u00d7 Merlan hybrid recently bred for disease resistance by Milo\u0161 Michlovsk\u00fd, Vil\u00e9m Kraus, Lubom\u00edr Glos, Vlastimil Pe\u0159ina and Franti\u0161ek M\u00e1dl in which Rakisch is a VILLARD BLANC \u00d7 FR\u00dcHROTER VELTLINER hybrid (see LAUROT for Merlan). Pollen was obtained from the Chisinau research centre in the Republic of Moldova and the crossing and selection were carried out in the Lednice, B\u0159eclav and Pern\u00e1 research centres in the Czech Republic. The variety rights are owned by Vinselekt Michlovsk\u00fd, a breeding company created in 1995 by Milo\u0161 Michlovsk\u00fd in Rakvice, southern Morava, in the south east of the Czech Republic. It was included in the official Czech register of varieties in 2001.',
    ],
    'where its grown': [
      'Wines are aromatic, spicy and relatively full-bodied with fairly high acidity and typically develop an aroma of cinnamon, then bread, as they age in bottle. Sweet, later-harvest wines are possible when yields are reduced. Recommended producers include Michlovsk\u00fd and Rovenius. There were just 5 ha (12 acres) planted in Morava in the south east of the Czech Republic in 2009.',
    ],
  },
  {
    name: 'MAMMOLO',
    synonyms: [
      'Broumest',
      'Malvasia Montanaccio',
      'Mammolo Toscano',
      'Mammolone di Lucca',
      'Muntanaccia',
      'Schiorello',
      'Sciaccarello',
      'Sciaccarellu',
    ],
    description:
      'Minor but historically significant perfumed central Italian Sangiovese blending partner, more important on the French island of Corse, as Sciaccarello.',
    'origin and parentage': [
      'Mammolo is a very old Tuscan variety: Soderini (1600) referred to both the red and white versions: the white one either corresponded to another variety or has since disappeared, the red being considered a good wine grape. The long history of Mammolo is confirmed by the significant morphological variability of its numerous clonal variations, all found and described in Toscana: Mammolo Nero Primaticcio, Mammolo Piccolo, Mammolo Grosso, Mammolo Asciuto, Mammolo Sgrigliolante, Mammola Tonda, etc. The name supposedly derives from Viola mammola, the Italian name for Viola odorata or sweet violet, a reflection of the wine\u2019s perfume.',
      'DNA profiling has established that two varieties grown on the French island of Corse, Sciaccarellu and Malvasia Montanaccio, are identical to Mammolo (Di Vecchi Staraz, This et al. 2007). It is likely that Mammolo was brought from Toscana to Corse, rather than vice versa, probably during the rule of the Republic of Pisa (1077\u20131284) or the Republic of Genova (1284\u20131768), not least because parentage analysis has revealed a possible parent\u2013offspring relationship between Mammolo and several Tuscan varieties: BIANCONE DI PORTOFERRAIO, CALORIA, COLOMBANA NERA and POLLERA NERA (Di Vecchi Staraz et al. 2007). These relationships illustrate the significant genetic influence of Mammolo on the Tuscan grape heritage. In addition, DNA profiling suggests a possible parent\u2013offspring relationship with ROLLO from Liguria (Vouillamoz).',
    ],
    'where its grown': [
      'Once widespread in Toscana, Mammolo began to decrease in the 1960s, and today it is grown to a very limited extent mainly in the regions of Montepulciano, Siena, Arezzo and Lucca. There were just 147 ha (363 acres) in Italy in 2000 and it appears to be on the slippery slope to extinction.',
      'It is authorized in the Carmignano, Chianti, Colli dell\u2019Etruria Centrale, Monteregio di Massa Marittima, Morellino di Scansano, Parrina, Pomino, Rosso di Montepulciano and Vino Nobile di Montepulciano DOCs and is usually blended with SANGIOVESE. Wines are medium-bodied, with moderate alcohol, and become more perfumed with age. (Cennatoio\u2019s Mammolo di Toscana IGT is, confusingly, a 100% MERLOT.)',
      'On the island of Corse, France, Sciaccarello (often Sciaccarellu and sometimes Sciacarello), meaning \u2018crunchy\u2019, is one of the main varieties in all red and ros\u00e9 appellations except Patrimonio and is the dominant variety in the south of the island, particularly in the Ajaccio and Sart\u00e8ne regions. It is also frequently sold as an IGP L\u2019\u00cele de Beaut\u00e9. Plantings totalled 783 ha (1,935 acres) in 2008, climbing back to the level of the late 1970s after a dip in the late 1980s. Varietal wines tend to be rather pale in colour but combine a highish level of alcohol with fresh acidity and aromas of red fruit and spices, which explains why they are often made as ros\u00e9s. Notable producers include Clos Canarelli and Comte Peraldi. Some producers, such as Domaine Saparale, add a bit of VERMENTINO to their ros\u00e9, others, such as Clos Ornasca, add Nielluccio (SANGIOVESE) or Grenache (GARNACHA) in their reds.',
      'In Australia, in the Barossa Valley in the state of South Australia, Thorn-Clarke co-ferment about 5% of Mammolo with NEBBIOLO to add fragrance and colour to the blend, and in the far east of Gippsland, in Victoria, Norinbee Selection Vineyards blend Mammolo with Nebbiolo and MERLOT for similar reasons.',
    ],
  },
  {
    name: 'MANDILARIA',
    synonyms: [
      'Amorghiano',
      'Amorgiano',
      'Dombrena Mavri',
      'Doubraina Mavri',
      'Doumpraina Mavri',
      'Kontoura',
      'Koudouro',
      'Kountoura',
      'Koundoura',
      'Kountoura Mavri',
      'Mandelaria',
      'Mandilaria',
      'Mantilari',
      'Mantilaria',
      'Montoyra',
    ],
    description:
      'Greek island variety producing intensely coloured, tannic wines that tend to lack body.',
    'origin and parentage': [
      'Mandilaria originates from the islands of the eastern Aegean (Nikolau and Michos 2004) and its name may derive from the Greek mandili, meaning \u2018handkerchief\u2019 (Boutaris 2000), although no one seems to know why.',
      'There is some confusion among the reference vines in grape collections: for example, there are two distinct references for Vilana in the Greek Vitis Database: one has a unique DNA profile, most likely the true VILANA, the other is identical to a vine labelled Mandilaria (but not true to type) in the same database and to Mantilaria in Sefc et al. (2000), itself distinct from the true Mandilaria (Greek Vitis Database; Benjak et al. 2005).',
      'Although SAVATIANO is sometimes called Kountoura Aspri (aspro means \u2018white\u2019) and Mandilaria is also called Kountoura Mavri (mavro means \u2018black\u2019), Savatiano is not a colour mutation of Mandilaria, as evidenced by their distinct DNA profiles in the Greek Vitis Database.',
    ],
    'where its grown': [
      'Mandilaria produces wines that are very deeply coloured and tannic but generally lack body, which is why they are often blended with the softer but more alcoholic KOTSIFALI, eg in the Peza and Ach\u00e1rnes appellations on the island of Kr\u00edti (Crete), Greece. However, Lyrarakis have shown that, on the right site, a varietal Mandilaria can achieve balance while remaining imposingly tannic. On the island of R\u00f3dos, where the variety has a longer growing season and berries can achieve higher sugar levels, varietal Mandilaria qualifies for the R\u00f3dos appellation. There also seems to be a superior, smaller-berried clone known as Diminitiko growing on the higher slopes of Mount Att\u00e1viros, close to the village of \u00c1gios Is\u00eddorus. So deeply coloured are Mandilaria wines that on the island of P\u00e1ros the appellation of the same name allows the blending of white MONEMVASSIA with 20% Mandilaria for the production of red wine (Lazarakis 2005).',
      'The variety is also planted on Santor\u00edni and P\u00fdlos as well as in the southern Pelop\u00f3nnisos (Peloponnese) and, to a limited extent, further north on the mainland, although its produce tends to be lean and dry if not blended, eg with MAVROTRAGANO on Santor\u00edni. Overall it is the second most planted variety on the islands in the southern Aegean (682 ha/1,685 acres in 2008).',
      'Recommended producers include Boutari, Cair, Emery (using the synonym Amorgiano) and Mediterra. Sigalas\u2019 Apiliotis is a rare and outstanding example of an intensely sweet but tannic red made from sun-dried Mandilaria.',
    ],
  },
  {
    name: 'MAND\u00d3N',
    synonyms: ['Galmeta', 'Galmete', 'Mand\u00f3', 'Valenciana Tinta'],
    description: 'Virtually extinct variety from Bierzo, north-west Spain.',
    'origin and parentage': [
      'Mand\u00f3n is an endangered variety from the Bierzo region in the province of Le\u00f3n in north-west Spain that was recently suggested by DNA parentage analysis to be a natural cross between GRACIANO (from Rioja and Navarra) and Heb\u00e9n, an almost extinct Andalusian variety, which would make it a half-sibling of GORGOLLASA from Mallorca and MACABEO from Catalunya (Garc\u00eda-Mu\u00f1oz et al. 2012). While the parent\u2013offspring relationship with Graciano is backed up by Santana et al. (2010), the complete parentage was based on too few DNA markers (twenty) and needs to be confirmed.',
    ],
    'where its grown': [
      'Official 2008 statistics recorded just 1 ha (2.5 acres) of Mand\u00f3n in 2008, in Castilla-La Mancha, Spain, but there have recently been new plantings in Arribes del Duero in an attempt to halt its disappearance. Celler del Roure in Valencia use the variety in their Maduresa blend.',
    ],
  },
  {
    name: 'MANDR\u00c8GUE',
    synonyms: ['Manr\u00e8gue', 'N\u00e9ral'],
    description: 'Ancient and recently rescued Ari\u00e8ge variety.',
    'where its grown': [
      'Isolated vines of this almost forgotten variety were discovered in Marmande, between Bordeaux and Toulouse, France, under the synonym N\u00e9ral (Galet 1990). Recently, Les Vignerons Ari\u00e9geois in Mont\u00e9gut-Plantaurel (just west of Pamiers) came to the rescue by planting 30\u201340 vines of this and other endangered local varieties, including BERDOMENEL, CAMARALET DE LASSEUBE, CANARI NOIR and Tortozon (PLANTA NOVA; Carbonneau 2005). They aim to multiply these and to produce a commercial wine if their experiments yield encouraging results.',
    ],
  },
  {
    name: 'MANSENG NOIR',
    synonyms: [
      'Ca\u00ed\u00f1o do Freixo',
      'Ferr\u00f3n',
      'Ferrol',
      'Mansenc Noir',
      'Noir du Pays',
    ],
    description:
      'Rare but characterful red wine grape from south-west France, also found in north-west Spain.',
    'origin and parentage': [
      'Manseng Noir used to be widespread in the Basque Country, where it was first mentioned in 1783\u20134 (R\u00e9zeau 1997): \u2018Manseng noir .\u00a0.\u00a0. il est bon \u00e0 manger et fait le vin bon, il est mont\u00e9 en couleur\u2019 (\u2018Manseng noir .\u00a0.\u00a0. it is good to eat and makes good wine, it is highly coloured\u2019). In the Pyr\u00e9n\u00e9es-Atlantiques, Manseng Noir and COURBU NOIR were sometimes confused and both were called Noirs du Pays (Herriko Beltza in Basque dialect), in particular in Iroul\u00e9guy. Manseng Noir is a member of the Courbu ampelographic group (see p XXVII), so it is not surprising that a possible parent\u2013offspring relationship between Manseng Noir and TANNAT (of the same group) was recently suggested by the analysis of twenty-two DNA markers (Vouillamoz; to be confirmed with more markers).',
      'Unexpectedly, the DNA profile of Ferr\u00f3n published in Iba\u00f1ez et al. (2003) and in Mart\u00edn et al. (2003) turned out to match the profile of Manseng Noir (Vouillamoz). This is surprising because Ferr\u00f3n is considered to be indigenous in the region of Orense in Galicia, north-western Spain, where it is also known as Ca\u00ed\u00f1o do Freixo (Santiago, Boso, Mart\u00edn et al. 2005). This could be explained by the similarities between the climate of Galicia and that of the Basque region, but the historical presence of Manseng Noir in Galicia under different names does not challenge its Basque origin, not least because it is a member of the Courbu ampelographic group, typical of south-west France.',
      'Manseng Noir is morphologically and genetically distinct from GROS MANSENG and PETIT MANSENG.',
    ],
    'where its grown': [
      'Wines tend to be very deeply coloured, astringent and fairly acidic but with good ageing potential and thus make good blending components. According to Lavignac (2001), plantings were on the increase but in 2008 there were just 3 ha (7 acres) in the Pyr\u00e9n\u00e9es-Atlantiques of France, where it is a permitted minor ingredient in the B\u00e9arn appellation. It was originally interplanted with COURBU NOIR in the Iroul\u00e9guy region. The variety has been included in the Saint-Mont conservation vineyard and Antonin Nicollier at Domaine de Pouypardin in nearby Condom, between Bordeaux and Toulouse in the Gers, planted 10 ares, or one tenth of a hectare (1,200 square yards) in hot, clay-limestone soils in 2005.',
      'Ferr\u00f3n is listed as one of the principal varieties permitted in Galicia\u2019s Ribeiro appellation in Spain and producers such as O\u2019Ventosela, Vi\u00f1a Mein and Docampo Di\u00e9guez Colleteiro include it in their red blends, generally with MENC\u00cdA and other local varieties. Spain recorded a total of 4 ha (10 acres) in 2008.',
    ],
  },
  {
    name: 'MANTO NEGRO',
    synonyms: ['Cabelis', 'Mantonegro', 'Mantuo Negro'],
    description:
      'Principal dark-skinned variety on the Spanish island of Mallorca, producing pale, fruity, high-alcohol wines.',
    'origin and parentage': [
      'Manto Negro most likely originates from Mallorca in the Islas Baleares. Recent DNA parentage analysis has suggested that Manto Negro is a natural cross between two other, obscure and no longer cultivated, varieties from Mallorca, namely Sabat\u00e9 and Callet Cas Concos, the latter being proposed as a natural cross between BEBA from Andaluc\u00eda and GIR\u00d2 from Sardegna (Garc\u00eda-Mu\u00f1oz et al. 2012). This would make Manto Negro a grandchild of Beba and Gir\u00f2 and a half-sibling of CALLET, also from Mallorca. However, these parentages were based on too few DNA markers (twenty) and need to be confirmed.',
      'Although it is also known as Mant\u00fao Negro, it is not a black mutation of CHELVA, called Mant\u00fao in Extremadura.',
    ],
    'where its grown': [
      'All 320 ha (791 acres) in 2010 of Manto Negro are to be found on the island of Mallorca, Spain,\nwhere it is the most widely planted variety with just over 20% of the total vineyard area. It is the main variety in the Binissalem-Mallorca DO (at least 30% of any blend) and is authorized for the Pl\u00e0 i Llevant DO, also on Mallorca. Although it is widely planted on the island, it is much more successful and common in the more westerly Binissalem region than in the east and does particularly well on stony soils. Wines are generally soft, light in colour \u2013 some berries are closer to pink than black \u2013 and high in alcohol and therefore often blended with a small amount of the more structured and lower-alcohol CALLET. Young vines tend to produce red-fruit flavours in the wine but older plants with lower yields result in greater concentration, dark-fruit flavours and more marked aromas \u2013 wines that respond well to maturation in large oak barrels but do not age particularly well because the variety is prone to oxidation. Recommended producers include Binigrau, Jos\u00e9 Luis Ferrer, Maci\u00e0 Batle, Nadal and Ribas.',
    ],
  },
  {
    name: 'MANTONICO BIANCO',
    synonyms: [
      'Mantonico Pizzutella',
      'Mantonacu Viru della Locride',
      'Mantonico Vero',
    ],
    description: 'Minor light-skinned Calabrian.',
    'origin and parentage': [
      'Mantonico Bianco is typical of the Locride area, around Locri, in the province of Reggio Calabria in southern Italy, mentioned perhaps as early in 1601 by Marafioti (Schneider, Raimondi, De Santis and Cavallo 2008). It should not be confused with MONTONICO BIANCO, cultivated in central and southern Italy, or with GUARDAVALLE, which is called Mantonico in Cosenza. Recent morphological and DNA studies have established that Mantonico Pizzutella, also cultivated in Calabria, is identical to Mantonico Bianco (Zappia et al. 2007). Recent DNA parentage analysis has suggested that Mantonico Bianco is a possible parent of GAGLIOPPO from Calabria, of MARCHIONE from Puglia and of NERELLO MASCALESE from Sicilia (Cipriani et al. 2010).',
      'Mantonico Bianco is not a white mutation of any of the four distinct grapes named Mantonico Nero (Schneider, Raimondi, De Santis and Cavallo 2008).',
    ],
    'where its grown': [
      'Mantonico Bianco is cultivated along the Ionian coast of Calabria, Italy,\nmainly in the region of Bianco and Casignara. It is used to produce both dry and passito-style sweet wines and varietal examples include Librandi\u2019s elegant, minerally and oak-aged Efeso (IGT Val di Neto) and their lightly sweet, exotic-fruit-flavoured Le Passule. Statti\u2019s floral and herbal Nosside Passito (IGT Calabria) is predominantly Mantonico Bianco but they also make a dry varietal wine. Mantonico Bianco is mentioned in the regulations for DOCs such as Bivongi and Donnici but, unsurprisingly, it is not clear whether this refers to Mantonico Bianco or MONTONICO BIANCO.',
    ],
  },
  {
    name: 'MANZONI BIANCO',
    synonyms: ['I M 6.0.13', 'Incrocio Manzoni 6.0.13', 'Manzoni'],
    description:
      'Potentially high-quality northern Italian white cross which has inherited the positive qualities of its parents.',
    'origin and parentage': [
      'This is a RABOSO PIAVE \u00d7 MUSCAT OF HAMBURG cross obtained by Luigi Manzoni at the Conegliano research centre in Italy\u2019s Veneto between 1930 and 1935, in order to combine the robustness of the former with the softness, vigour and productivity of the latter.',
    ],
    'where its grown': [
      'Manzoni Moscato is cultivated to a very limited extent in the province of Treviso (particularly in Tezze, Fontanelle, Mareno di Piave and Cessalto) in the Veneto, Italy. Casa Roma and Villa Alm\u00e8, for example, both make a light, fresh, off-dry varietal sparkling ros\u00e9; Tenuta San Giorgi\u2019s Bizzarro version is sweeter and more deeply coloured. The variety is also blended with the pink-berried MANZONI ROSA to produce Manzoni Liquoroso.',
    ],
  },
  {
    name: 'MANZONI MOSCATO',
    synonyms: ['Incrocio Manzoni 13.0.25'],
    description:
      'One of the least planted of Manzoni\u2019s crosses, this dark-skinned version is used to make sweet, sparkling ros\u00e9 in Trentino.',
    'origin and parentage': [
      'This is a RABOSO PIAVE \u00d7 MUSCAT OF HAMBURG cross obtained by Luigi Manzoni at the Conegliano research centre in Italy\u2019s Veneto between 1930 and 1935, in order to combine the robustness of the former with the softness, vigour and productivity of the latter.',
    ],
    'where its grown': [
      'Manzoni Moscato is cultivated to a very limited extent in the province of Treviso (particularly in Tezze, Fontanelle, Mareno di Piave and Cessalto) in the Veneto, Italy. Casa Roma and Villa Alm\u00e8, for example, both make a light, fresh, off-dry varietal sparkling ros\u00e9; Tenuta San Giorgi\u2019s Bizzarro version is sweeter and more deeply coloured. The variety is also blended with the pink-berried MANZONI ROSA to produce Manzoni Liquoroso.',
    ],
  },
  {
    name: 'MANZONI ROSA',
    synonyms: ['Incrocio Manzoni 1-50'],
    description: 'Very rare Veneto pink-berried cross.',
    'origin and parentage': [
      'A TREBBIANO TOSCANO \u00d7 GEW\u00dcRZTRAMINER (Traminer Aromatico) cross created by Luigi Manzoni at the Conegliano research centre in Italy\u2019s Veneto between 1924 and 1930 with the aim of combining the robustness and productivity of Trebbiano Toscano with the finesse of Gew\u00fcrztraminer. From the four crossings obtained, Manzoni selected the one with pink and aromatic berries.',
    ],
    'where its grown': [
      'Manzoni Rosa was planted in the 1950s and 1960s around Conegliano and between Treviso and Venezia in the Veneto, northern Italy, but today there are only a few vineyards left, although Casa Roma does make a varietal wine. At the oenology school at Conegliano it is blended with MANZONI MOSCATO to produce an experimental wine known as Manzoni Liquoroso.',
    ],
  },
  {
    name: 'MARA',
    synonyms: ['C41', 'C41'],
    description: 'Recent, promising, early-ripening Swiss cross.',
    'origin and parentage': [
      'Mara is a GAMAY NOIR \u00d7 REICHENSTEINER cross obtained in 1970 by Andr\u00e9 Jaquinet at the Caudoz viticultural research centre in Pully, just outside Lausanne, Switzerland, today part of Agroscope Changins-W\u00e4denswil. It was initially labelled C41 before being selected and christened Mara on its release in 2009, nineteen years after its siblings GAMARET and GARANOIR.',
    ],
    'where its grown': [
      'Mara wines are generally well coloured and full-bodied with flavours of dark fruit and spice, the tannins slightly softer and more rounded than those of GAMARET. Recommended producers, all in the canton of Vaud, Switzerland, include Domaines Henri & Vincent Chollet, Christian Dugon and Pierre Monachon.',
    ],
  },
  {
    name: 'MARATHEFTIKO',
    synonyms: [
      'Aloupostaphylo',
      'Bambakada',
      'Bambakina',
      'Maratheftico',
      'Marathefticon',
      'Marathophiko',
      'Mavrospourtiko',
      'Pambakada',
      'Pambakina',
      'Pampakia',
      'Vambakadha',
      'Vambakina',
      'Vamvakada',
    ],
    description:
      'Rare, high-quality dark-skinned Cyprus variety that is starting to gain in popularity despite difficulties in the vineyard.',
    'origin and parentage': [
      'Maratheftiko is indigenous to the island of Cyprus, where it was first mentioned by the French ampelographer Pierre Mouillefert (1893) under the name Marathophiko as a grape with large berries, easy to crush and very susceptible to powdery mildew. The name Maratheftiko probably derives from Marathasa, the name of a valley in the Troodos mountains on Cyprus. According to DNA profiling, Maratheftiko is genetically close to Aspro X (Hvarleva, Hadjinicoli et al. 2005), an obscure variety from Cyprus that is distinct from the more common Aspro, which is a clone of XYNISTERI.',
    ],
    'where its grown': [
      'Maretheftiko vines have traditionally been planted on Cyprus in mixed vineyards to increase the colour and body of the lower-quality but far more productive MAVRO, which has always been more popular with growers paid by weight. The greatest concentration is found in the mountainous region of P\u00e1fos and also in Pitsilia, where it is commonly known as Vamvakada. KEO also has a large area of older vines in Malia and their Heritage is one of the oldest examples of a varietal Maretheftiko. Increasing recognition of Maretheftiko\u2019s quality potential has encouraged single-vineyard plantings but there were only 178 ha (440 acres) in 2010, just 1.8% of the vineyard area, and many of these vines are still quite young. Further work is needed to improve clonal selection and resolve the issues of poor pollination and inconsistent yields (by alternate-row plantings with SPOURTIKO, for example) but already the best wines, often made from the oldest, ungrafted, interplanted vines at higher altitudes, are deeply coloured, fresh, rich in dark berry and cherry fruit, sometimes slightly herbal or scented with violets. They have rounded tannins when fully ripe, an affinity with oak and the potential to age gracefully \u2013 although producers are still experimenting and a \u2018typical\u2019 Maretheftiko does not yet exist. Recommended producers include Aes Ampelis, Ezousa, KEO, Tsiakkas (labelled Vamvakada), Vardalis, Vasa, Vlassides and Zambartas. Aes Ampelis and Ezousa also make good ros\u00e9s.',
    ],
  },
  {
    name: 'MAR\u00c9CHAL FOCH',
    synonyms: ['Foch', 'Kuhlmann 188-2', 'Marechal Foch', 'Marshal Fosh'],
    description:
      'French hybrid that has been very successful in the colder climes of the US and Canada.',
    'origin and parentage': [
      'A complex hybrid obtained in 1911 by Eug\u00e8ne Kuhlmann in Colmar by crossing Millardet et Grasset 101-14 OP with GOLDRIESLING, where Millardet et Grasset 101-14 OP is a selfling of a Vitis riparia \u00d7 Vitis rupestris cross. First commercialized in 1921, this variety was named in honour of Mar\u00e9chal Ferdinand Foch, a general in the French army during the First World War who was made Mar\u00e9chal de France in 1918.',
      'The same crossing produced Mar\u00e9chal Foch\u2019s siblings, L\u00c9ON MILLOT and LUCIE KUHLMANN, and Mar\u00e9chal Foch was used to breed MILLOT-FOCH.',
    ],
    'where its grown': [
      'Mar\u00e9chal Foch is well suited to cold climates and is widely planted in Canada and the eastern and Midwestern US. Wines may be deeply coloured and quite tannic, sometimes on the herbaceous side or slightly smoky, but they avoid the foxy character of some hybrids that have American species in their pedigree. For lighter styles, carbonic maceration is often used but the variety can also be aged in oak for a more sturdy result.',
      'European plantings are relatively small: 13 ha (32 acres) in France (2008), though it was once quite widely planted in the Loire, and 12 ha (30 acres) in Switzerland (2009), mainly in the eastern, German-speaking part of the country.',
      'The variety was introduced to Canada in the late 1940s and there are now 171 acres (69 ha) in Ontario (2006), 122 acres (49 ha) in British Columbia (2008) and small areas in Nova Scotia and Qu\u00e9bec, where it is the most widely planted red variety. Iowa (93 acres/38 ha in 2006) and Illinois (80 acres/32 ha in 2007) have the most significant plantings in the US Midwest but its most popular and successful second home is New York State, where there were 144 acres (58 ha) in 2006. There are also significant plantings in Oregon. Producers of varietal wines include Kittling Ridge (Niagara Escarpment, Ontario), Prejean (Finger Lakes, New York), Wollersheim (Wisconsin) and 5 Trails (Nebraska).',
    ],
  },
  {
    name: 'MARMAJUELO',
    synonyms: ['Bermejuela', 'Marmajuela', 'Vermejuelo'],
    description:
      'Rare variety from Spain\u2019s Islas Canarias producing crisp, aromatic and potentially high-quality whites.',
    'origin and parentage': [
      'Marmajuelo most probably originates on the Islas Canarias in Spain, where DNA parentage analysis has suggested that the local MALVAS\u00cdA DE LANZAROTE is a natural cross between MALVASIA DI LIPARI, called Malvas\u00eda de Sitges and Malvas\u00eda de la Palma on the islands, and Marmajuelo (Rodr\u00edguez-Torres et al. 2009).',
    ],
    'where its grown': [
      'Marmajuelo is permitted in almost all the Islas Canarias DOs but is one of the principal varieties in El Hierro, La Palma and Ycoden-Daute-Isora under the name Bermejuela and in Tacoronte-Acentejo as Marmajuelo. Wines are aromatic and full-bodied, have a very good level of acidity and are frequently used in blends because there is so little planted, although there is an increasing interest in producing varietal wines, such as the one by Vi\u00f1\u00e1tigo. There were 24 ha (59 acres) on the Islas Canarias, Spain, in 2008.',
    ],
  },
  {
    name: 'MARQUETTE',
    synonyms: ['MN 1211'],
    description:
      'Promising, increasingly popular, cold-hardy complex American hybrid.',
    'origin and parentage': [
      'A complex hybrid of MN 1094 and Ravat 262 created in 1989 by Peter Hemstad and James Luby at the University of Minnesota\u2019s Horticultural Research Center in Excelsior, US, in which:',
      '\u2013\u00a0\u00a0\u00a0MN 1094 is a hybrid between MN 1019 and MN 1016',
      '\u2013\u00a0\u00a0\u00a0MN 1019 is a Riparia 64 \u00d7 CARMINE hybrid, in which Riparia 64 is a Vitis riparia vine selected at Geisenheim for use as a rootstock',
      '\u2013\u00a0\u00a0\u00a0MN 1016 is a Mandan \u00d7 LANDOT NOIR hybrid',
      '\u2013\u00a0\u00a0\u00a0Mandan is a Wilder \u00d7 Vitis riparia hybrid',
      '\u2013\u00a0\u00a0\u00a0Wilder is a Carter \u00d7 SCHIAVA GROSSA (under the name Black Hamburg) hybrid',
      '\u2013\u00a0\u00a0\u00a0Carter (also called Mammoth Globe) is an offspring of ISABELLA and an unknown parent selected by Charles Carter in Virginia',
      '\u2013\u00a0\u00a0\u00a0Ravat 262 (or Ravat Noir) is a Seibel 8365 \u00d7 PINOT NOIR hybrid (see FLORENTAL for the pedigree of Seibel 8365).',
      'This grandchild of PINOT NOIR (and cousin of FRONTENAC) is therefore an extremely complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\naestivalis, Vitis\nlincecumii, Vitis\nrupestris, Vitis\ncinerea and Vitis\nberlandieri hybrid. It was selected in 1994 and tested as MN 1211 before it was named in 2005 after P\u00e8re Marquette, a seventeenth-century Jesuit missionary and explorer, and released in 2006.',
    ],
    'where its grown': [
      'Marquette is one of the most promising recent cold-hardy American hybrids in the Midwest and the Northeast as well as in Qu\u00e9bec, Canada, thanks to its performance in the vineyards and high potential wine quality. Alcohol can be relatively high but varietal wines have cherry and blackcurrant flavours as well as more complex notes of black pepper and spice. They have relatively high acidity, which needs to be softened by malolactic fermentation, and noticeable tannins. Early trials with barrel ageing have shown positive results. Plantings are on the increase. For example, there were 214 acres (87 ha) in Minnesota in 2007, 10 acres (4 ha) in Indiana in 2010, 6 acres (2.4 ha) in Iowa in 2006 and 1 acre (0.4 ha) in Pennsylvania in 2008. Producers of varietal wines include Hinterland, Indian Island, Lincoln Peak, Parley Lake and St Croix in Minnesota.',
    ],
  },
  {
    name: 'MARSANNE',
    synonyms: [
      'Avilleran',
      'Ermitage',
      'Hermitage',
      'Grosse Roussette',
      'Marsanne Blanche',
      'Roussette de Saint-P\u00e9ray',
    ],
    description:
      'Flavourful and potentially very high-quality light-skinned Rh\u00f4ne variety with notable outposts in Australia and the US.',
    'origin and parentage': [
      'Marsanne is a traditional variety from the Vall\u00e9e du Rh\u00f4ne, where it was first mentioned in 1781 in a description of the white wine from Hermitage (R\u00e9zeau 1997): \u2018ce vin blanc se fait avec la Roussane, & plusieurs personnes y m\u00ealent de la Marsanne, autre raisin blanc qui rend le vin un peu liquoreux\u2019 (\u2018this white wine is made from Roussane, and several people add in some Marsanne, another white-berried variety that makes the wine a little sweet\u2019). The variety was most likely named after the commune of Marsanne near Mont\u00e9limar in the Dr\u00f4me, its most probable birthplace.',
      'Marsanne is a member of the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009). DNA parentage analysis strongly suggests a parent\u2013offspring relationship between Marsanne and ROUSSANNE (Vouillamoz), which might explain why they are often confused, as in Cipriani et al. (2010).',
    ],
    'where its grown': [
      'Marsanne is often blended with ROUSSANNE, and occasionally VIOGNIER, especially in the Rh\u00f4ne, France, although it has effectively taken over from its blending partner Roussanne in northern Rh\u00f4ne appellations such as Saint-P\u00e9ray, Saint-Joseph, Crozes-Hermitage and even Hermitage. It is one of the six principal varieties permitted in white C\u00f4tes du Rh\u00f4ne \u2013 although, unlike its north Rh\u00f4ne blending partner Roussanne, Marsanne was unknown in Ch\u00e2teauneuf-du-Pape in the 1930s, when its rules were drawn up, so is not permitted in Ch\u00e2teauneuf. Alone it produces wines that are generally deep-coloured, full-bodied, occasionally positively fat, and scented with only moderate acidity and flavours ranging from honeysuckle to rich almond paste via pear. Chapoutier\u2019s varietal Chante Alouette, Ermitage de l\u2019Or\u00e9e, Ermitage le M\u00e9al and the sweet Ermitage Vin de Paille demonstrate the potential quality of the variety and that the best examples can age well. Further south and west, too, it is gaining popularity in the Aude, where there were 239 ha (591 acres) in 2009 (compared with 270 ha/667 acres in the Dr\u00f4me and 226 ha/558 acres in the Ard\u00e8che). Varietal Marsannes from the Languedoc are by no means uncommon, as well as blends of it with various Rh\u00f4ne and local grapes.',
      'In Italy, where there were 50 ha (124 acres) planted in 2000, it is specifically mentioned in the regulations for only one wine: Vin Santo Vigoleno, a locally famous blended passito wine within the Colli Piacentini DOC in Emilia-Romagna.',
      'There are very few growers of Marsanne in Portugal but Espor\u00e3o in the Alentejo have 11 ha (27 acres) and use it in their Private Selection white blend.',
      'Switzerland\u2019s 47 ha (117 acres), almost exclusively in the Valais, are used for both dry and sweet wines though the latter are the most prized. Marie-Th\u00e9r\u00e8se Chappaz\u2019 Ermitage Grain Noble is one of the locally most sought-after sweet wines.',
      'California\u2019s Rh\u00f4ne Rangers are the main proponents of the 93 acres (38 ha) planted in California, mostly in Monterey, San Luis Obispo, Santa Barbara and Sonoma. Recommended producers include Qup\u00e9 and Beckmen in Santa Ynez Valley and Cline in Carneros. Marsanne is not restricted to the Sunshine State: Airfield Estates in Washington State\u2019s Yakima Valley, for example, have planted Marsanne to use as a blending partner. There are scatterings of the variety all over the US.',
      'In Australia, Marsanne is planted in pockets in most wine-growing states but is most closely associated with one producer in Nagambie Lakes, Victoria: Tahbilk, who have been growing Marsanne since the 1860s and still have vines from the end of the 1920s, have the largest Marsanne vineyard area in the world. Here too is consistent evidence of the variety\u2019s ability to age. Total Australian plantings in 2008 amounted to over 200 ha (494 acres).',
    ],
  },
  {
    name: 'MARSELAN',
    synonyms: ['INRA 1810-68'],
    description: 'One of the most successful relatively recent French crosses.',
    'origin and parentage': [
      'A CABERNET SAUVIGNON \u00d7 GARNACHA cross obtained by Paul Truel in 1961 at INRA (Institut National de la Recherche Agronomique) and ENSAM (\u00c9cole Nationale Sup\u00e9rieure d\u2019Arts et M\u00e9tiers) in Montpellier, southern France, in order to get a productive large-berried variety. However, Marselan turned out to have small berries and was initially neglected because it did not offer the high yields prized then. With the increasing demand for varieties that combined good disease resistance with high quality, Marselan was subsequently unearthed and included in the official register of varieties in 1990. Its name refers to Marseillan, the commune on the Mediterranean coast between Montpellier and B\u00e9ziers housing the grape collection of INRA Montpellier\u2019s Domaine de Vassal.',
    ],
    'where its grown': [
      'Marselan wines are generally aromatic, deeply coloured, well structured but with supple tannins and the potential to age well. France already had 1,356 ha (3,351 acres) planted by 2006 and 2,375 ha (5,869 acres) in 2009, mainly in the Languedoc and southern Rh\u00f4ne. Successful varietal examples have been made by Paul Mas, Mas de Rey, Domaine de la Camarette, Ch\u00e2teau Camplazens and Domaine de Couron, while Domaine de la Mordor\u00e9e have produced a Marselan/MERLOT blend.',
      'Another early adopter was Carlos Esteva at Can Rafols dels Caus, whose first Marselan vines were planted in the mid 1990s in Pened\u00e8s, north-east Spain. Also in Catalunya, there are experimental plantings in the Terra Alta appellation.',
      'In California, Marselan has been sold by Sunrise Nurseries. In Argentina there were 79 ha (195 acres) in 2008 \u2013 Zuccardi seem to be enthusiastic early adopters, having planted their vines in 1998. Brazil had 24 ha (59 acres) in 2007; producers include Cave Antiga and Serra Ga\u00facha.',
      'Even in China, Marselan has found friends: the Sino-French Demonstration Vineyard, close to the Great Wall in the Hualai county of Hebei province, has for several years been producing a varietal Marselan.',
    ],
  },
  {
    name: 'MARUFO',
    synonyms: [
      'Brujidera',
      'Brujidero',
      'Brujigero',
      'Crujidera',
      'Crujideiro',
      'Marufa',
      'Moravia Dulce',
      'Mourisco',
      'Mourisco du Douro',
      'Mourisco Preto',
      'Mourisco Tinto',
      'Rucial',
      'Trujidera',
      'Vigorosa',
    ],
    description:
      'Iberian variety that goes by many names and whose light wine is used mainly in blends.',
    'origin and parentage': [
      'Marufo is a very old and widespread variety from the Iberian peninsula, where it is known under a variety of names, as DNA profiling has revealed:',
      '\u2013\u00a0\u00a0\u00a0Marufo is identical to Brujidera in Arribes del Duero in Spain and Mourisco Tinto in the neighbouring Douro in Portugal (Castro et al. 2011).',
      '\u2013\u00a0\u00a0\u00a0Comparison of the DNA profiles of Marufo (Almadanim et al. 2004; Castro et al. 2011) and Moravia Dulce (Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007) shows that they are one and the same variety (Vouillamoz), which explains why Brujidera is a common synonym for both. However, the same authors have spotted a genetically distinct but so far unidentified variety in Albacete that is mistakenly called Moravia Dulce (Fern\u00e1ndez-Gonz\u00e1lez, Mart\u00ednez and Mena 2007).',
      '\u2013\u00a0\u00a0\u00a0Moravia Dulce is also called Crujidera and Rucial (Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007).',
      'It is not possible to say whether Spain or Portugal is the birthplace of this variety but recent DNA parentage analysis has shown that TOURIGA FRANCA from the Douro is an offspring of TOURIGA NACIONAL, also from the Douro, and Marufo, which means that Marufo has long been present in the Douro, but this is not sufficient to locate its origin. Since the name Moravia Dulce is provisional in the official register of varieties in Spain and is used for at least two distinct varieties, one in Cuenca and one in Albacete (Fern\u00e1ndez-Gonz\u00e1lez, Mart\u00ednez and Mena 2007), it seems less ambiguous to use Marufo as the prime name.',
      'According to DNA profiling (Fern\u00e1ndez-Gonz\u00e1lez, Mart\u00ednez and Mena 2007 and Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007), Colgadera is a synonym for Moravia Dulce in Campillo de Altobuey in Cuenca. Although the authors do not specify Colgadera\u2019s berry colour, this synonymy is surprising because Colgadera is a pale-berried variety, and no colour mutation of Moravia Dulce has ever been reported.',
    ],
    'where its grown': [
      'Official statistics for 2008 record 2,202 ha (5,441 acres) of Moravia Dulce in Spain, mostly in Castilla-La Mancha and particularly in the provinces of Albacete and Cuenca in central and south-east Spain, where it is one of the permitted varieties in appellations such as La Mancha (an enormous region covering 190,000 ha/469,500 acres) and Manchuela and is generally used in blends, although it is also used sometimes for the table.',
      'In Portugal, under the name Marufo, the variety makes wines that are light in colour and have a slight aroma of red fruit and a light structure that makes them unsuitable for ageing and better suited to ros\u00e9 than to red wines. There were 3,631 ha (8,972 acres) in 2010, mostly in the Douro and Pinhel regions, in the north east of the country, where it is also used as a table grape. Recommended producers include the Figueira de Castelo Rodrigo co-op and Quinta dos Termos.',
    ],
  },
  {
    name: 'MARZEMINA BIANCA',
    synonyms: ['Berzemina di Breganze', 'Sciampagna'],
    description:
      'Rare Veneto variety usually found as an ingredient in sweet blended wines.',
    'origin and parentage': [
      'Marzemina Bianca has long been cultivated in the Breganze region and was first mentioned by Giacomo Agostinetti (1679). Recent DNA studies have revealed a likely parent\u2013offspring relationship beween Marzemina Bianca and the black-berried MARZEMINO (Salmaso et al. 2008) as well as between Marzemina Bianca and GARGANEGA (Crespan, Cal\u00f2 et al. 2008; see Garganega for more details). Indeed, the DNA profiles available are consistent with Marzemina Bianca being a natural cross between Marzemino and Garganega (Vouillamoz), to be confirmed by further analyses. In addition, parentage analysis has shown that RABOSO VERONESE is a Marzemina Bianca \u00d7 RABOSO PIAVE cross (Salmaso et al. 2008; see the REFOSCO DAL PEDUNCOLO ROSSO pedigree diagram).',
    ],
    'where its grown': [
      'Marzemina Bianca is cultivated to a limited extent in the hills of the provinces of Treviso, Padova and Vicenza in northern Italy. It lost ground to more productive varieties when vineyards were replanted in the wake of phylloxera. A small amount may be blended with Glera (PROSECCO), VERDISO and Boschera (see VERDICCHIO BIANCO) in the Torchiato di Fregona (dry or sweet, passito-style) version of the Colli di Conegliano DOC, and with VESPAIOLA in the late-harvest Torcolato version of Breganze DOC. Dell\u2019Antonio and Francesco Tomasi make good examples of the former and Maculan is one of the best-known producers of the latter. There were 83 ha (205 acres) in Italy in 2000 according to the agricultural census.',
      'Casa Roma\u2019s Vinegia is a rare example of a light, dry, still white from 100% Marzemina Bianca, classified as a vino da tavola. They describe it as having aromas of green apple and hay and a faintly bitter aftertaste. Firmina Miotti\u2019s Sampagna is a dry, lightly sparkling version.',
    ],
  },
  {
    name: 'MARZEMINO',
    synonyms: [
      'Balsamina',
      'Barzemin',
      'Berzamino',
      'Marzemina Cenerenta',
      'Marzemina Nera',
      'Marzemino Comune',
      'Marzemino Gentile',
    ],
    description:
      'Makes fruity, perfumed reds often sweet or sparkling or both.',
    'origin and parentage': [
      'Marzemino is a very old northern Italian variety, mentioned in Lando (1553) as recommended for the Veneto. It is supposed to have spread from there to Trentino, Friuli, Lombardia and Emilia-Romagna (Giavedoni and Gily 2005). However, its wine was first mentioned in relation to a famous banquet held on 6 June 1409 in Cividale (Friuli) in honour of Pope Gregory XII, at which the following wines were served: Ribolla from Rosazzo, Verduzzo from Faedis, Ramandolo from Torlano, Refosco from Albana and Marzemino from Gradiscutta (Peterlunger et al. 2004), a small village near Varmo in the province of Udine.',
      'Pedigree reconstruction based on DNA profiling (see REFOSCO DAL PEDUNCOLO ROSSO pedigree diagram) has revealed that Marzemino and LAGREIN from the Alto Adige are both progenies of the same parents: TEROLDEGO from Trentino and another, unknown, variety, which means that Marzemino and Lagrein are siblings (Vouillamoz and Grando 2006). Additional genetic investigations have shown that Marzemino is a likely parent of MARZEMINA BIANCA (Salmaso et al. 2008) and Refosco dal Peduncolo Rosso from Friuli and a likely grandchild of PINOT (Grando et al. 2006), thus securely anchoring Marzemino\u2019s origins in northern Italy (see the PINOT pedigree diagram).',
      'There are two main clonal variations: Marzemino Gentile and Marzemino Comune.',
    ],
    'where its grown': [
      'Today Marzemino is cultivated mainly in Trentino, in particular in the Val Lagarina between Isera and Volano, northern Italy,\nwhere it is produced as a still wine within the Trentino DOC. Varietal wines are also authorized in DOCs such as Breganze, Garda and Merlara, the last in sparkling form only. Marzemino is also cultivated in Lombardia, in particular near the Lago di Garda in several DOCs, including: Botticino (minimum 20%), Capriano del Colle (minimum 35%), Cellatica (20\u201330%) and Riviera del Garda Bresciano (5\u201330%). In the Colli di Scandiano e di Canossa DOC in Reggio Emilia in Emilia-Romagna, it may be a varietal wine or part of the blend (minimum 50%), either still or sparkling. Total plantings in Italy in 2000 were 268 ha (662 acres).',
      'Mozart apparently tasted a wine made from Marzemino when he was in the Val Lagarina for his first concert in Italy in 1769 and in Act 2, scene 17, of Don Giovanni, the hero extols the virtues of the wine: \u2018Versa il vino! Eccellente Marzemino!\u2019',
      'Wines may be still or lightly sparkling, sometimes off dry or even sweet, passito-style, but they are generally fragrant and fresh with aromas of red fruits, occasionally violets, and a zesty sour-cherry aftertaste and not much tannin. Successful producers include the Cantine Sociale di Norni, Eugenio Rosi and Vivallis.',
      'In Australia, Michelini, in the cool Alpine Valleys area of north-east Victoria, have been producing a varietal Marzemino for more than ten years and Chrismont, in the neighbouring region of King Valley, produce both a still, dry version and an off-dry sparkling one.',
      'In New Zealand, Church Road have produced a spicy varietal Marzemino.',
    ],
  },
  {
    name: 'M\u00c1TRAI MUSKOT\u00c1LY',
    synonyms: ['M\u00e1trai Muskat\u00e1ly'],
    description: 'Recently registered, grapey Hungarian cross.',
    'origin and parentage': [
      'An ARANY S\u00c1RFEH\u00c9R \u00d7 MUSCAT OTTONEL cross obtained in 1952 by P\u00e1l Kozma at the Szent Istv\u00e1n University in Hungary. It was officially registered in 1982.',
    ],
    'where its grown': [
      'There were just 25 ha (62 acres) in Hungary in 2008, mostly in Kuns\u00e1g in the centre and south and Etyek-Buda in the north. B\u00e1rdos make a varietal wine. Wines have an aromatic Muscat-like character with flavours of ripe fruit and fresh acidity.',
    ],
  },
  {
    name: 'MATURANA BLANCA',
    synonyms: ['Maturano', 'Ribadavia'],
    description: 'Rare and recently rescued Rioja variety with real potential.',
    'origin and parentage': [
      'Maturana Blanca is found exclusively in Rioja but there is no reliable historical documentation on this variety (Mart\u00ednez de Toda and Sancha Gonz\u00e1lez 2000). The name Maturana most likely derives from the Spanish word madurando, meaning \u2018ripening\u2019. Morphological and DNA studies have shown that Maturana Blanca is identical to a few vines recovered under the name Ribadavia near the villages of Navarrete and Sot\u00e9s in La Rioja, west of Logro\u00f1o (Cervera et al. 1998; Mart\u00ednez de Toda and Sancha Gonz\u00e1lez 2000; Ulanovsky et al. 2002).',
      'Ampelographic and DNA evidence has clearly shown that Maturana Blanca is not a colour mutation of the black Maturana Tinta (Mart\u00ednez de Toda and Sancha Gonz\u00e1lez 2000), which is a synonym for TROUSSEAU.',
    ],
    'where its grown': [
      'According to official statistics, there were just 3 ha (7 acres) of Maturana Blanca in Spain in 2008, all in the province of La Rioja in the centre of northern Spain. Vi\u00f1a Ijalba rescued this variety in 1988, helped by viticulturists Juan Carlos Sancha Gonz\u00e1lez and Fernando Mart\u00ednez de Toda from the Universidad de La Rioja and the Rioja regulatory council. They planted the first vineyard in 1995 and are currently the sole producers of a varietal wine (first vintage 2000). Maturana Blanca, along with SAUVIGNON BLANC and several other white varieties, has recently been officially authorized by the Rioja regulatory council. Wines may have quite high alcohol but are well balanced by fresh acidity, citrus or tropical fruit flavours and light herbal notes. Plantings are expected to increase.',
    ],
  },
  {
    name: 'MAUZAC BLANC',
    synonyms: [
      'Blanc Laffite',
      'Gaillac',
      'Plant de Gaillac',
      'Gamet Blanc',
      'Mausat',
    ],
    description:
      'Characterful, apple-skin-flavoured Gaillac and Limoux variety used in dry, sweet and sparkling wines.',
    'origin and parentage': [
      'Mauzac Blanc is a variety specific to the Gaillac area of south-west France. The earliest mention is said to have appeared in the Livre de raison by Antiquamareta (Lavignac 2001) in 1525, and later in 1564 (in an archive document cited by Cayla in R\u00e9zeau 1997): \u2018vigne muscade et mausague\u2019. However, the true identity and the berry colour of this mausague is unknown, and the first reliable mention of Mauzac dates back to 1736 under the name Mausat (R\u00e9zeau 1997): \u2018En Languedoc. Mausat: il y en a de blanc et de noir, il produit un grain rond et croquant\u2019 (\u2018In Languedoc. Mausat: there is a white and a black one, it produces a round and crunchy berry\u2019). The name Mauzac (Mausac in Occitan) could derive from two distinct villages: Mauzac, south west of Toulouse (Haute-Garonne), or Meauzac to the north of Toulouse, near Moissac (Tarn-et-Garonne). According to the IFV (Institut Fran\u00e7ais de la Vigne et du Vin) website, Mauzac Blanc has a possible parent\u2013offspring relationship with N\u00e9gret Castrais, a local variety that is no longer cultivated.',
      'Mauzac Blanc is not a white mutation of MAUZAC NOIR but Mauzac Rose is a colour mutation of Mauzac Blanc.',
    ],
    'where its grown': [
      'Mauzac Blanc (often referred to simply as Mauzac) is used to produce dry, sweet and sparkling wines exclusively in Limoux and Gaillac in the western Languedoc and south-west France respectively. Its distinctive dried-apple-skin flavour is lost if the fruit is picked early to retain freshness, as is so often the case today. Mauzac Blanc is sometimes rather rustic but all the more tasty for that.',
      'In still Gaillac, Mauzac Blanc (including the colour mutation Mauzac Rose) is one of the three principal varieties, along with LEN DE L\u2019EL and MUSCADELLE, whereas the unusual, semicloudy, medium-sweet, lightly sparkling wines entitled to the term M\u00e9thode Ancestrale (bottled before the end of the first fermentation) must be 100% Mauzac (Blanc or Rose). The similar Limoux M\u00e9thode Ancestrale must be 100% Mauzac Blanc while the much more common Blanquette de Limoux has to be at least 90% Mauzac. In Limoux still wines and Cr\u00e9mant de Limoux, however, CHARDONNAY is gaining ground and Mauzac plays a less significant role.',
      'Despite the efforts in the late 1980s of champions of the variety such as Robert Plageoles and an increase in vineyard area in the late 1980s and early 1990s, total French plantings have slowly declined over the last fifty years, from 8,511 ha (21,031 acres) in 1958 to 1,991 ha (4,920 acres) in 2009 (although Mauzac is still more widely planted than either MARSANNE or ROUSSANNE).\nTop of the list of recommended producers come Domaine Robert et Bernard Plageoles but there are many others, including Domaine de Causse Marines and Ch\u00e2teau Rives-Blancs.',
      'As well as being a permitted variety in still Gaillac, Mauzac Rose is an authorized part of the blend for armagnac.',
      'Sparkling-wine producer Schramsberg of the Napa Valley have planted a little in California.',
    ],
  },
  {
    name: 'MAUZAC NOIR',
    synonyms: ['Mauzac Rouge'],
    description:
      'Rare variety from south-west France unrelated to its white-wine namesake.',
    'origin and parentage': [
      'Mauzac Noir, which is not the black version of MAUZAC BLANC (see that entry for history and etymology), is a variety from the Tarn in south-western France. Mauzac Noir is genetically close to FER and it is often confused with N\u00e9gret Castrais, which is no longer cultivated and, according to the IFV (Institut Fran\u00e7ais de la Vigne et du Vin) website, has a possible parent\u2013offspring relationship with Mauzac Blanc.',
    ],
    'where its grown': [
      'Wines tend to be light in colour and fruity. Plantings in Gaillac are extremely limited but Bernard and Robert Plageoles of Domaine des Tr\u00e8s Cantou/Domaine Plageoles, who have worked hard to revive this old variety, make a rare varietal example.',
    ],
  },
  {
    name: 'MAVRO',
    synonyms: [
      'Cipro Nero',
      'Cyperntraube Blaue',
      'Cypro Nero',
      'Korithi Mavro',
      'Kritiko Mavro',
      'Kypreico Mavro',
      'Kypreiko Mavro',
      'Kypriotiko',
      'Mavro Kyproy',
      'Skuro Mavro',
      'Staphili-Mavro',
    ],
    description: 'The dominant Cyprus variety, makes undistinguished wine.',
    'origin and parentage': [
      'Mavro, meaning \u2018black\u2019, was first mentioned under the name Cipro Nero (\u2018black of Cyprus\u2019) on the island of Cyprus by the Italian ampelographer Giuseppe di Rovasenda (1877) and then by French ampelographer Mouillefert (1893) under the name Mavro or Staphili-Mavro.',
      'It\u2019s difficult to identify Mavro\u2019s place of birth: Lefort and Roubelakis-Angelakis (2001) have shown by DNA profiling that Korithi Mavro (black Corinthian) from central Greece and the Pelop\u00f3nnisos (Peloponnese) and Kritiko Mavro (black Cretan) from Kr\u00edti and the Kykl\u00e1des (Cyclades) are clones of the same variety, and comparison of their DNA profile with Mavro from Cyprus (Hvarleva, Hadjinicoli et al. 2005) reveals that they are identical. It is therefore not known if this variety, allegedly referred to in ancient Greek texts (Lefort and Roubelakis-Angelakis 2001), originates from Cyprus, mainland Greece or Kr\u00edti.',
      'Mavro should not be confused with other varieties in Greece and Bulgaria that have Mavro as a synonym.',
    ],
    'where its grown': [
      'Mavro is by far the most widely planted grape variety on Cyprus, the 4,550 ha (11,243 acres) recorded in 2010 comprising 46% of the island\u2019s vineyard, although this is considerably down on the 80% it used to represent, with the vineyard area declining overall. Its large berries make it suitable for the table as well as for wine \u2013 perhaps more so since varietal wines are typically light in colour and flavour, being simple, low in acidity and best drunk young. The tannins rarely ripen fully. The (slightly) better wines come from vines planted at higher altitudes and in the less fertile soils of Pitsilia, Laona and Afames in the west of the island, where yields are naturally less generous.',
      'In addition to red wines, Mavro is used to make Commandaria, the traditional sweet and almost always fortified wine of the island made from sun-dried grapes, either on its own or blended with the light-skinned XYNISTERI, although the latter is generally thought to give better results. There were 289 ha (714 acres) in the Commandaria region in 2010.',
    ],
  },
  {
    name: 'MAVRO KALAVRITINO',
    synonyms: [
      'Kalavritino Mavro',
      'Mavro Kalavrytiko',
      'Psilomavro Kalavryton',
    ],
    description:
      'Very minor Greek variety from the northern Pelop\u00f3nnisos (Peloponnese).',
    'origin and parentage': [
      'A rare variety named after the village of Kalavryta in the Acha\u1e2fa region of the Pelop\u00f3nnisos, Greece. Comparison of DNA profiles in the Greek Vitis database shows that it is possibly identical to Psilomavro Kalavryton, also grown in Acha\u1e2fa.',
    ],
    'where its grown': [
      'Found only in Acha\u1e2fa in the northern Pelop\u00f3nnisos, Greece, Mavro Kalavritino is blended with CABERNET SAUVIGNON by Domaine Tetramythos, who also produce varietal wine tasting of cherries, spices, violets and leather. Domaine Mega Spileo blend it with MAVRODAFNI.',
    ],
  },
  {
    name: 'MAVRO MESSENIKOLA',
    synonyms: [
      'Mavro de Messenicolas',
      'Mavro Mesenikola',
      'Messenikola Mavro',
    ],
    description:
      'Variety exclusive to the Greek region of Thessal\u00eda that benefits greatly from its partnership with Syrah.',
    'origin and parentage': [
      'This variety comes in all probability from the province of Kard\u00edtsa in Thessal\u00eda, central eastern mainland Greece. DNA profiling has not yet been done to see if it matches any other known Greek variety.',
    ],
    'where its grown': [
      'The Messenikola appellation (created in 1994) in the lowlands around the town of Kard\u00edtsa, at 250\u2013600 m above sea level, in Greece, is, unsurprisingly, home to this variety. The regulations stipulate 70% Mavro Messenikola plus 30% Carignan (see MAZUELO) and SYRAH, though an equal blend of Messenikola with Syrah seems to produce far superior results (Lazarakis 2005). Varietal wines are typically light in every way, often more like a ros\u00e9 than a red, hence the obligation to blend. Karamitrou produce both a varietal wine as well as a blend with Syrah.',
    ],
  },
  {
    name: 'MAVRODAFNI',
    synonyms: [
      'Ahmar Mechtras',
      'Fraoula Kokkini',
      'Mavrodafnitsa',
      'Mavrodaphne',
      'Mavrodaphni',
      'Mavrodrami',
      'Thiniatiko',
    ],
    description:
      'Deeply coloured, tannic yet aromatic Greek variety famous for producing ageworthy, sweet, fortified wines.',
    'origin and parentage': [
      'Mavrodafni, meaning \u2018black laurel\u2019, is thought to come from the Ionian islands of Lefk\u00e1da and Kefaloni\u00e1 (Manessis 2000) or from the north-western part of the Pelop\u00f3nnisos (Peloponnese; Nikolau and Michos 2004).',
      'The DNA profile of the reference sample named Mavrodaphni at the United States Department of Agriculture\u2019s National Clonal Germplasm Repository in Davis, California, is distinct from the Mavrodafni in Sefc et al. (2000).',
      'The variety known as Thiniatiko, mainly found around Thin\u00e9a in the north-western part of Kefaloni\u00e1, is believed to be a synonym for, or a clone of, Mavrodafni but this has not yet been confirmed by DNA profiling (Haroula Spinthiropoulou, Nico Manessis, personal communications). DNA profiling with only eight markers has suggested a possible genetic relationship between Mavrodafni and GOUSTOLIDI (Boutaris 2000) but this needs to be confirmed with many more markers.',
    ],
    'where its grown': [
      'Mavrodafni is probably the most widely recognized Greek variety around the world, mainly because of the sweet, fortified wines that boast its name: Mavrodafni of P\u00e1tra and Mavrodafni of Kefaloni\u00e1. The former appellation represents about 7% of total quality-wine production in Greece.',
      'The variety\u2019s high levels of colour and tannin also make it very useful for adding colour and structure to unfortified, dry red blends, particularly in poor vintages, but on its own, it can be rather one-dimensional. Found mainly in P\u00e1tra and Il\u00eda in the northern Pelop\u00f3nnisos as well as on the islands of Kefaloni\u00e1 and Lefk\u00e1da, it is the third most planted variety in western Greece (497 ha/1,228 acres in 2008). There are said to be two main clones: the smaller-berried Tsigelo on Kefaloni\u00e1 and the Regnio clone, with tighter bunches, in the Acha\u1e2fa (Lazarakis 2005).',
      'While the Mavrodafni of P\u00e1tra appellation allows the addition of up to 49% KORINTHIAKI, Mavrodafni of Kefaloni\u00e1 must be a varietal wine. Aside from this potential difference, styles range widely depending on the winemaking, the timing of fortification, use of oak (generally large and old although there have been experiments with small and new), whether the barrels are topped up, etc., but the best are silky, sweet, complex and sometimes tawny in colour thanks to long ageing. Traditional styles are probably more akin to Grenache (GARNACHA)-based vins doux naturels from southern France than to port, although both styles exist. The very best have remarkable longevity.',
      'From P\u00e1tra, Parparoussis\u2019 Taos is a good example of a dry varietal wine, aromatic, full of lifted cherry fruit and softened by two years in oak. Antonopoulos\u2019 version spends less time in oak and is equally fragrant and supple. Both make good examples of sweet, fortified Mavrodafni, aged in barrel anywhere between four and six years, as do Gentilini and Sklavos on Kefaloni\u00e1. Acha\u00efa Clauss, named after Gustav Clauss, widely regarded as the pioneer of such fortified wines in the mid nineteenth century, have been through turbulent times since the 1980s and have not produced such consistent quality in recent years but they have an impressive library of wines back to the 1880s.',
    ],
  },
  {
    name: 'MAVROUDI ARACHOVIS',
    synonyms: [
      'Arachovis',
      'Arachovitiko Mavro',
      'Arahovitikos',
      'Mavro Arachovitiko',
      'Mavroudi of Arachova',
    ],
    description: 'Rare variety from central Greece.',
    'origin and parentage': [
      'The plethora of Mavrouda or Mavroudi Something grape varieties is confusing but Mavroudi Arachovis (\u2018Black from Ar\u00e1chova\u2019) most logically comes from the town of Ar\u00e1chova in the prefecture of Voiot\u00eda in central Greece and DNA profiling has shown that it is quite distinct from the MAVRUD of Bulgaria and the MAVRO of Cyprus (Hvarleva et al. 2004; Hvarleva, Hadjinicoli et al. 2005).',
    ],
    'where its grown': [
      'Mavroudi Arachovis is the dominant variety around Ar\u00e1chova in the north east of the Fok\u00edda region, central Greece, where Parnassos Vineyards produce a blend of 80% Arachovis and 20% MERLOT. Lazarakis (2005) describes the variety as \u2018modern and promising\u2019, producing wines that are full-bodied and deeply coloured.',
    ],
  },
  {
    name: 'MAVRUD',
    synonyms: [
      'Kachivela',
      'Mavro',
      'Mavroud',
      'Mavroudi',
      'Mavroudi Boulgarias',
      'Tsiganka',
    ],
    description:
      'Indigenous Bulgarian variety producing sturdy reds that can improve with age.',
    'origin and parentage': [
      'Mavrud, from the Greek mavro, meaning \u2018black\u2019, is one Bulgaria\u2019s two best quality indigenous red wine varieties and one of its oldest. It probably comes from Asenovgrad in the province of Plovdiv, central southern Bulgaria, where it has traditionally been cultivated (Katerov 2004). This place of origin is supported by the significant morphological diversity seen there, with at least five variants (Katerov 2004): Mavrud s Edri Zarna (large-berried Mavrud), Mavrud s Drebni Zarna (small-berried), Mavrud sas Spleskani Zarna (flat-berried), Mavrud Siv (grey-berried) and Mavrud Izresliv (with a tendency to coulure). Mavrud was used to breed BUKET and EVMOLPIA.',
    ],
    'where its grown': [
      'There were 1,709 ha (4,223 acres) of Mavrud in Bulgaria in 2009, less than of Melnik (SHIROKA MELNISHKA) and much less than of PAMID. Mavrud produces tannic and deeply coloured wines with fresh acidity that respond well to oak maturation although they are generally less ageworthy than wines made from Melnik. It is grown mainly in the centre and south of Bulgaria, concentrated in the province of Plovdiv. The majority of wines are dry, often blended with the country\u2019s widely planted international varieties such as MERLOT or CABERNET SAUVIGNON but also with the local RUBIN. However, Assenovgrad Winery produce a semi-sweet Mavrud called Manastirsko Shushukane, meaning \u2018monastery whisper\u2019, and Santa Sarah, a boutique producer near Pomorie on the southern part of the Black Sea coast, somehow manage to make a Mavrud icewine. Recommended producers of dry red varietal wines include Assenovgrad Winery, Malkata Zvezda, Rumelia, Terra Tangra and Todoroff but ambitious new wineries are being established all the time.',
    ],
  },
  {
    name: 'MAYOLET',
    synonyms: ['Ma\u00efolet', 'Majolet'],
    description:
      'Extremely rare Aosta variety gradually recovering from near-extinction.',
    'origin and parentage': [
      'Mayolet is an indigenous variety of the Valle d\u2019Aosta, where it has been known since 1787, mainly between Saint-Vincent and Avise. It was traditionally cultivated in the same vineyards as PETIT ROUGE and VIEN DE NUS and typically blended to produce Torrette, a full-bodied late-harvest wine famous since the nineteenth century (Gatta 1838). DNA parentage analysis has established that Mayolet and Petit Rouge are the parents of ROUGE DU PAYS, cultivated across the border in Switzerland\u2019s Valais, and that Mayolet has a parent\u2013offspring relationship with PRI\u00c9, another Aostan variety (Vouillamoz and Moriondo 2011; see Pri\u00e9 for the pedigree diagram).',
    ],
    'where its grown': [
      'Mayolet was rescued from extinction in the twentieth century and has seen new plantings in its native Valle d\u2019Aosta in north-west Italy since the 1990s (Moriondo 1999). Today, a handful of varietal wines are produced in the Valle d\u2019Aosta DOC, eg by the Cave des Onzes Communes and the Cantina di Barr\u00f2. Di Barr\u00f2\u2019s version has fresh, tangy acidity, sour-cherry flavours and quite soft tannins. Today Torrette is an official category within the Valle d\u2019Aosta DOC which must include at least 70% PETIT ROUGE, so the Mayolet component is optional and limited. Producers include Cantina di Barr\u00f2, Feudo di San Maurizio and Franco Noussan. There were barely 4 ha (10 acres) planted in Italy in 2000.',
    ],
  },
  {
    name: 'MAZUELO',
    synonyms: [
      'Bovale di Spagna',
      'Bovale Grande',
      'Bovale Mannu',
      'Carignan Noir',
      'Carignane',
      'Carignano',
      'Cari\u00f1ano',
      'Cari\u00f1ena',
      'Crujill\u00f3n',
      'Mazuela',
      'Mollard',
      'Sams\u00f3  and many more',
    ],
    description:
      'Black variety from north-east Spain high in tannins and acidity that can make fine wine from old vines but has in its time, as Carignan, blighted Languedoc-Roussillon.',
    'origin and parentage': [
      'Mazuelo, probably from Arag\u00f3n in north-east Spain, has dozens of synonyms in Spain and in various European countries, suggesting it is a very old variety that was dispersed a long time ago. The name Mazuelo derives from Mazuelo de Mu\u00f1\u00f3, a village in the province of Burgos in Castilla y Le\u00f3n, and the well-known synonym Cari\u00f1ena (which became Carignan in French, Carignano in Italian and Carignane in American English) most likely derives from the town of Cari\u00f1ena near Zaragoza in Arag\u00f3n. The name Cari\u00f1ena used to be prominent in Catalunya, but since it is also used for a Denominaci\u00f3n de Origen, in the province of Zaragoza, the Catalan authorities now call it Sams\u00f3 in order to avoid confusion. However, as Spanish journalist Victor de la Serna points out, Sams\u00f3 is also a synonym for CINSAUT, which creates further confusion. Mazuelo is therefore not only the most unambiguous name for the variety but also the one used in official Spanish statistics. Mazuelo is increasingly seen on labels.',
      'DNA profiling has confirmed that Cari\u00f1ena and Mazuelo \u2013 or Mazuela in Rioja \u2013 are identical (Mart\u00edn et al. 2003), and, more recently and surprisingly, that Carignan is also identical to Bovale di Spagna on the island of Sardegna (Nieddu et al. 2007). Comparison of DNA profiles from different sources has also established that Bovale di Spagna, often called Bovale Grande, is distinct from BOBAL, despite what the name might suggest; from NIEDDERA (Nieddu et al. 2007), contrary to opinions frequently expressed; and from CINSAUT, even though the synonym Sams\u00f3 in Catalunya might suggest otherwise (Vouillamoz). Some ampelographers also considered Bovale di Spagna to be identical to Bovale Sardo or Cagnulari but it is now clear that this is impossible, since these last two are both synonyms for GRACIANO (Nieddu et al. 2007). Most authors also speculated that TINTILIA DEL MOLISE from Molise and Lazio in central Italy, which is different from varieties known as Tintilia in other parts of Italy (Campania, Sardegna) and in Spain, could be the same variety as Bovale Grande but this was recently disproved by DNA profiling (Reale et al. 2006).',
      'As the name reveals, the Spanish origin of Bovale di Spagna had long been suspected. Given the recent discovery that it is identical to Mazuelo, it seems most likely that Bovale di Spagna was introduced to Sardegna during Spanish rule of the island (1323\u20131720), possibly together with Bovale Sardo (aka GRACIANO). Although the number of DNA markers was too low to be conclusive, Reale et al. (2006) also suggested that Bovale di Spagna and Bovale Sardo could have a parent\u2013offspring relationship, making Mazuelo either a parent or a progeny of GRACIANO, a theory that can be rejected by the analysis of their respective DNA profiles (Vouillamoz). In any case, the spontaneous cross that bred Mazuelo or the one that produced Graciano took place in Spain, not on Sardegna. Mazuelo was used to breed ARGAMAN and RUBY CABERNET.',
    ],
    'where its grown': [
      'Mazuelo/Carignan is an extreme variety in every way: high yielding and high in acid, colour and generally tough tannins, occasionally bitter. Even the best examples, the product of old bushvines or softened by carbonic maceration and blended with finer varieties, can provoke mixed responses in wine drinkers. It is not a subservient partner for oak.',
      'Total plantings in France in 2009 were nearly nine times higher than in Spain at 53,155 ha (131,349 acres), with nearly 80% of the total area in Languedoc-Roussillon, especially in the two major Languedoc d\u00e9partements of the Aude (16,113 ha/39,816 acres) and the H\u00e9rault (16,251 ha/40,157 acres). However, thanks to a decline in popularity, a reduction in the percentage allowed in certain appellations and financial incentives from the EU to replace it with \u2018improving varieties\u2019 such as SYRAH, Grenache (GARNACHA) and Mourv\u00e8dre (MONASTRELL), the area has been significantly reduced since the late 1990s, when these two d\u00e9partements had more than 60,000 ha (148,000 acres) between them. There are substantial plantings all over south-eastern France, particularly in the Gard, Pyr\u00e9n\u00e9es-Orientales, Var and Vaucluse d\u00e9partements. It rarely travels further north because it ripens so late. Its susceptibility to rot and mildew (both downy and especially powdery) makes it unsuitable for damper maritime climates such as that of south-west France, including Bordeaux. As elsewhere, the better wines depend on ancient, hand-harvested bushvine stock or the softening influence of carbonic maceration. Top producers include Sylvain Fadat at Domaine de l\u2019Aupilhac in Montpeyroux, Ch\u00e2teau Lastours and Maxine Magnon in Corbi\u00e8res, Jean-Marie Rimbert in Saint-Chinian and Domaine Bertrand Berg\u00e9 in Fitou. Roc des Anges and Clos du Gravillas have been major French champions of it.',
      'In Italy, more than 97% of the 1,748 ha (4,320 acres) of Carignano vines and the additional 583 ha (1,440 acres) of its synonym Bovale Grande are planted on the island of Sardegna, notably in the south west for Carignano del Sulcis DOC, though it is also authorized in Lazio\u2019s Cerveteri DOC and (as Bovale Grande) in Sardegna\u2019s Campidano di Terralba and Mandrolisai DOCs. The Santadi co-op\u2019s Terre Brune and Rocca Rubine are excellent examples, almost as good as and certainly more velvety than the best Mazuelo-dominated Priorats. Few consumers of Sardinian wines are aware that a blend of Bovale Grande (or Bovale di Spagna) and Carignano is a blend of two clones of the same variety, namely the Mazuelo from Arag\u00f3n.',
      'Mazuelo is now the official Spanish name for what many have until recently called Cari\u00f1ena. In spite of its Spanish origins, Mazuelo is less widely planted in Spain than it is, as Carignan, in France. It is authorized in the Rioja DOCa but, although it can add useful acidity, its contribution to the blend is much less frequent and significant than in the past. A very few producers such as Marqu\u00e9s de Murrieta produce a varietal Mazuelo. The Rioja region had 1,193 ha (2,950 acres) planted in 2008, compared with 2,634 ha (6,510 acres) in Catalunya (where it is sometimes known as Sams\u00f3). The other main regions for this variety are Arag\u00f3n (926 ha/2,290 acres), where it is not the dominant variety in the Cari\u00f1ena DOC, Castilla-La Mancha (697 ha/1,720 acres) and Navarra (515 ha/1,270 acres). Total plantings in Spain in 2008 were 6,130 ha (15,150 acres). Some of the finest varietal wines such as Cims de Porrera\u2019s are from centenarian bushvines on Priorat\u2019s schistous slopes, where this variety dominates the northern part of the region, around Porrera and Poboldea. El Tr\u00edo Infernal 2/3, made by Laurent Combier, Jean Michel-G\u00e9rin and Peter Fischer from seventy-year-old vines, is another excellent example.',
      'Under the name Carignan, Mazuelo is found in Croatia (210 ha/519 acres in 2009). Cyprus had 366 ha (904 acres) in 2010, Turkey 134 ha (331 acres) in 2010 and Malta 10 ha (25 acres) in 2010. It has also been grown in Tunisia and Morocco.',
      'Carignan was widely planted in Israel, from the late nineteenth century, but it has become much less common in the last thirty or so years, down to 800 ha (1,977 acres) in total in 2009, making it the third most planted red after CABERNET SAUVIGNON and MERLOT. However, in recent years, some of the best producers have kept a much tighter control on yields from their old-vine Carignan and are producing some very worthy examples.',
      'As Carignane (sic) in California (3,393 acres/1,373 ha in 2010), once again it is old bushvines, prized by producers such as Ridge, Cline and Lioco, that produce the best wines. High yields in the Central Valley do it no favours. Even though the total acreage is in gradual decline, there are some signs of revival, notably nearer the coast, in Mendocino, Sonoma and Contra Costa counties. Alvin Tollini in Mendocino\u2019s Redwood Valley AVA is one example of a grower with genuine respect for his old bushvine Carignane. There is also a little in Washington State (where its produce has been blended with Grenache/GARNACHA). Mexico\u2019s limited Carignane plantings are mostly in Aguascalientes, Sonora and Zacatecas.',
      'Probably the most promising Carignan vines in the New World are the ancient, unirrigated bushvines in Maule in southern Chile, whose Carignan total was 675 ha (1,668 acres) in 2008. They have their own promotional organization Vignadores de Carignan, and can produce some particularly profound wines. In 2009, Uruguay had 486 ha (1,201 acres) and Argentina just 30 ha (74 acres). A tiny amount is grown in Australia in the state of South Australia but so far it has been blended.',
      'South Africa boasts around 80 ha (200 acres), with Charles Back\u2019s Fairview Pegleg bottling from the shale slopes of the Paarderberg in the Swartland being the most successful of relatively few New World examples outside South America.',
    ],
  },
  {
    name: 'MAZZESE',
    synonyms: [
      'Massase',
      'Mazzese di Parlascio',
      'Orzese',
      'Rinaldesca',
      'Uva Mazzese',
      'Vajano',
    ],
    description: 'Very minor variety used as a minor component in blends.',
    'origin and parentage': [
      'Mazzese is an old Tuscan variety, mentioned in the manuscripts of the botanist Pier Antonio Michelin (1679\u20131737). It is thought to originate from either the province of Grosseto or the province of Pisa, where it is still cultivated.',
    ],
    'where its grown': [
      'Mazzese is cultivated exclusively in Toscana, mainly in the province of Grosseto and to a lesser extent in the province of Pisa. There were just 98 ha (242 acres) in Italy in 2000. Usually blended with other local varieties, it may be included as a minor component in Tuscan DOCs such as Montescudaio, as well as in IGT Toscana. For example, Poggio Gagliardo\u2019s Pulena is a blend of SANGIOVESE, MALVASIA NERA DI BRINDISI, COLORINO DEL VALDARNO, Mazzese, FOGLIA TONDA, CILIEGIOLO and VERMENTINO.',
    ],
  },
  {
    name: 'M\u00c8CLE DE BOURGOIN',
    synonyms: ['Mescle de Bourgoin'],
    description:
      'Neglected eastern French variety now receiving some attention.',
    'origin and parentage': [
      'M\u00e8cle de Bourgoin, named after the commune of Bourgoin-Jallieu, between Lyon and Grenoble in eastern France, has often been confused with M\u00e8cle or Mescle in Bugey, between Lyon and Gen\u00e8ve, which is identical to POULSARD (Galet 2000). According to R\u00e9zeau (1997), the name M\u00e8cle derives from the local dialect meiklle, meaning \u2018mixing\u2019, either because the berry colour is somewhere between light- and dark-skinned or because of the frequent occurrence of pink berries in the bunch.',
    ],
    'where its grown': [
      'Galet (1990) reported 1 ha (2.5 acres) of M\u00e8cle de Bourgoin in Saint-Savin in the Is\u00e8re, France, but the variety has today almost disappeared from the vineyards. However, with the support of the Centre d\u2019Amp\u00e9lographie Alpine in Montm\u00e9lian (Savoie), Nicolas Gonin in Saint-Chef (Is\u00e8re) has started a project to reintroduce long-forgotten local varieties, including this one. Having found pre-Second World War plantings of M\u00e8cle de Bourgoin in Is\u00e8re in 2009, he successfully petitioned for its official classification and plans to plant in 2012. According to Galet (2000), the wines are rather hard when young.',
    ],
  },
  {
    name: 'MEDNA',
    synonyms: [
      'Buboj',
      'Bumba',
      'Medna Bijala',
      'Medva',
      'Rizavac',
      'Zlo\u017eder',
    ],
    description: 'Rare Dalmatian variety, often with a honey aroma.',
    'origin and parentage': [
      'Medna is known under several synonyms in Dalmacija (Dalmatia), mainly in the Vrgorac, Pelje\u0161ac and Dubrovnik areas, its most likely region of origin (Pezo et al. 2006). Medna is Croatian for \u2018honeyed\u2019, referring to the characteristic honey aroma of the wines.',
    ],
    'where its grown': [
      'Medna is officially authorized in the Dalmatinska Zagora (Dalmatian Hinterland) wine region in Croatia and is found mostly around the town of Vrgorac, between Split and Dubrovnik. Wines are generally light, with marked acidity and a characteristic aroma of honey (Pezo et al. 2006). Producers of varietal wines include Dabera, Opa\u010dak and Kati\u0107 Veljko.',
    ],
  },
  {
    name: 'MEGRABUIR',
    synonyms: ['Meghrabuyr', 'Megrabouir', 'Megrabuyr'],
    description:
      'Productive and cold-hardy Armenian hybrid used mainly to produce pink dessert wines.',
    'origin and parentage': [
      'Megrabuir, meaning \u2018honey-flavoured\u2019, is a complex hybrid obtained in 1960 by S A Pogosyan at the Armenian viticultural research centre in Merdzavan, just west of Armenia\u2019s capital, Yerevan, by crossing C 484 with C 128, in which C 484 is a MADELEINE ANGEVINE \u00d7 Chasselas Musqu\u00e9 cross and C 128 is an Ichkimar \u00d7 Yanvarskii Chernyi cross. Ichkimar is a Ukrainian table grape and Yanvarskii Chernyi, literally \u2018January Black\u2019, is a Ukrainian variety that is no longer cultivated.',
    ],
    'where its grown': [
      'Recommended for dessert wines in Armenia and grown mainly in the westerly province of Armavir, Megrabuir can attain high sugar levels even at high yields. Wines are generally pink in colour. Brest and Kazumoff produce varietal wines and MAP blend it with RKATSITELI and KARMRAHYUT.',
    ],
  },
  {
    name: 'MELARA',
    synonyms: ['Merlara'],
    description: 'Rare, used as a component in Vin Santo di Vigoleno.',
    'origin and parentage': [
      'From the province of Piacenza in Emilia-Romagna, northern Italy.',
    ],
    'where its grown': [
      'Cultivated mainly in the province of Piacenza for the production of the locally famous Vin Santo di Vigoleno (within the Colli Piacentini DOC), a passito blend of Melara, SANTA MARIA, VERNACCIA DI SAN GIMIGNANO (Bervedino), MARSANNE, SAUVIGNON BLANC, ORTRUGO and TREBBIANO ROMAGNOLO, aged for at least five years in small casks. There are only about a dozen producers of this wine, with a combined total production of c.2,500 bottles per year. According to the agricultural census, there were just 13 ha (32 acres) of Melara in Italy in 2000.',
    ],
  },
  {
    name: 'MELNIK 82',
    synonyms: ['Melnik'],
    description:
      'Result of crossing the variety commonly called Melnik, Shiroka Melnishka, with a variety from south-west France.',
    'origin and parentage': [
      'Melnik 82 is a SHIROKA MELNISHKA \u00d7 VALDIGUI\u00c9 cross obtained in 1963 by Yane Atanasov and his colleagues Z Zankov and G Karamitev at the Sandanski research centre (now Sintica Winery) in the Struma Valley in south-west Bulgaria, near the tiny but historical town of Melnik, hence the name. The breeders made a series of crossings using a mixture of pollens from the three French varieties DURIF, Juran\u00e7on (most probably JURAN\u00c7ON NOIR) and Valdigui\u00e9 to create an early-ripening variety with the characteristics of Shiroka Melnishka. DNA parentage analysis established that Valdigui\u00e9 was the other parent of sibling varieties RANNA MELNISHKA LOZA and Melnik 82 (Hvarleva, Russanov et al. 2005).',
    ],
    'where its grown': [
      'There were just 28 ha (69 acres) in 2008, all in the far south west of Bulgaria. The variety makes wines with good body, acid and tannin levels which are suitable for ageing. This should not be confused with its much more common parent.',
    ],
  },
  {
    name: 'MELON',
    synonyms: [
      'Gamay Blanc',
      'Latran',
      'Melon de Bourgogne',
      'Muscadet',
      'Plant de Bourgogne',
      'Petit Bourgogne',
    ],
    description:
      'Old Burgundian variety well suited to the western, Atlantic-dominated mouth of the Loire.',
    'origin and parentage': [
      'Melon is an old variety from Burgundy, where it was cultivated as far back as the thirteenth century, according to Durand and Pacottet (1901a), although this is not historically documented. Melon was then introduced to the Franche-Comt\u00e9, which is where it was first mentioned, in 1567, in an edict issued by Philippe II, King of Spain and Comte de Bourgogne (R\u00e9zeau 1997): \u2018Auons interdit, prohib\u00e9 & defendu .\u00a0.\u00a0. de planter, & \u00e9difier de nouueau en quelque lieu que ce soit, gamez, melons, & autres plants de semblable nature\u2019 (\u2018We have forbidden, prohibited and not allowed .\u00a0.\u00a0. the planting and rebuilding again in any place of gamez, melons and other similar varieties\u2019). Like GAMAY NOIR, Melon was later banned several times in Burgundy and the Franche-Comt\u00e9.',
      'In the Middle Ages, Melon was taken to the Val de Loire under the name Plant de Bourgogne, where it won its spurs under the name Muscadet, possibly mentioned among obscure and mostly unidentified varieties around 1530 in the Cinqui\u00e8me Livre by the famous medieval writer Fran\u00e7ois Rabelais (see MENU PINEAU), and then again more certainly in 1635 in Gorges in the Loire-Atlantique (R\u00e9zeau 1997): \u2018planter en bonne plante de vigne blanche de muscadet de quatre pieds de distance\u2019 (\u2018to plant good plants of white muscadet vines four feet apart\u2019). During the seventeenth century, plantings of Melon significantly increased in the Loire-Atlantique to fulfil the demand of Dutch traders for productive grapes suited to distillation (Schirmer 2010).',
      'The name Melon could come from the Latin misculare, meaning \u2018to mix\u2019, like Meslier (see MESLIER SAINT-FRAN\u00c7OIS), or from the round shape of its leaves that are similar to those of the melon plant. The name Muscadet could come from the discreet musk aroma of the wine or from the noix de muscade (nutmeg) that the Dutch traders added to the wines, along with other spices, prior to distillation.',
      'DNA parentage analysis has established that Melon is a natural PINOT \u00d7 GOUAIS BLANC cross (Bowers et al. 1999), which makes it a sibling of CHARDONNAY, GAMAY NOIR, ALIGOT\u00c9, etc., as well as a half-sibling of the numerous other progenies of either Gouais Blanc or Pinot (see Pinot pedigree diagram). Melon is a member of the Noirien ampelographic group (see p XXVII).',
      'The Melon \u00e0 Queue Rouge that is still scarcely cultivated in the Jura is just a type of CHARDONNAY and should not be confused with the true Melon.',
    ],
    'where its grown': [
      'There were 12,384 ha (30,602 acres) in France in 2009, making it the fourth most planted pale-skinned variety in the country, just ahead of S\u00c9MILLON but a long way behind SAUVIGNON BLANC. Plantings are almost exclusively at the western end of the Val de Loire, especially in the Loire-Atlantique and Maine-et-Loire d\u00e9partements, in the Pays Nantais surrounding the town of Nantes. The greatest concentration is in the Muscadet S\u00e8vre-et-Maine appellation, south and west of Nantes, but Melon is the sole permitted variety in all the Muscadet appellations. The great success of Muscadet from the 1970s led to indiscriminate planting but now the sites are being more carefully selected and the total area is starting to decline. However, there are 3 ha (7 acres) and some recent plantings in Bourgogne-V\u00e9zelay.',
      'Wines are archetypically crisp, marine and relatively neutral in flavour, sometimes with a touch of citrus. Lees ageing (sur lie) can imbue a little more flavour and the wines can make an ideal partner to the fish and seafood of the region. Recommended producers in Muscadet S\u00e8vre-et-Maine include Guy Bossard\u2019s Domaine de l\u2019\u00c9cu, Christophe Gadais at Domaine de la Tourmaline and Pierre Lieubeau at Domaine de la Fruiti\u00e8re; and in Muscadet C\u00f4tes de Grandlieu, J\u00e9r\u00f4me Choblet at Domaine des Herbauges under labels such as Fief Gu\u00e9rin, Ch\u00e2teau de la Pierre, Clos de la Fine, and Eric Chevalier at Domaine de l\u2019Aujardi\u00e8re.',
      'There is limited but marked enthusiasm for the variety in the US \u2013 and even a dedicated website (http://melondebourgogne.com) set up by Perennial Vintners on Bainbridge Island near Seattle, Washington State. The variety was brought to the US by Napa Valley winemaker Georges de Latour but was initially labelled PINOT BLANC, and there seems to be some confusion even today. Before true-to-type selections were introduced in the 1980s, most Pinot Blanc vineyards in California were actually planted with Melon. Today, most US producers of wines labelled Melon de Bourgogne are in Oregon with De Ponte Cellars a notable producer.',
    ],
  },
  {
    name: 'MENC\u00cdA',
    synonyms: [
      'Jaen',
      'Jaen du D\u00e3o',
      'Loureiro Tinto',
      'Menc\u00eda Pajaral',
    ],
    description:
      'Increasingly appreciated aromatic variety found in both Spain and Portugal.',
    'origin and parentage': [
      'Menc\u00eda most probably originates from Salamanca, in Bierzo in the province of Le\u00f3n, north-west Spain, although it was not mentioned in this area until the late nineteenth century, after the arrival of phylloxera (Mart\u00ednez, Santiago et al. 2006).',
      'Recent DNA profiling has revealed that Jaen du D\u00e3o, also known simply as Jaen, cultivated in D\u00e3o in central Portugal and considered to be native to that region, is in fact identical to Menc\u00eda (Mart\u00edn et al. 2006), and that Ja\u00e9n Tinto, an old and almost extinct variety from southern Spain, should not be confused with Menc\u00eda (also misleadingly called Ja\u00e9n Tinto in Bierzo in Castillo y Le\u00f3n; Zinelabidine et al. 2012). It is plausible that Menc\u00eda was brought to D\u00e3o by pilgrims on their way back from Santiago de Compostela. The fact that Jaen vines in D\u00e3o are notably homogeneous and that Menc\u00eda in Spain shows a greater level of genetic variability supports the Spanish origin of this variety.',
    ],
    'where its grown': [
      'There were 9,055 ha (22,375 acres) of Menc\u00eda in Spain in 2008, mostly in the north of Castilla y Le\u00f3n and in Galicia but also to a limited extent in Asturias and Catalunya. It is particularly important and increasingly respected in the Bierzo, Ribeira Sacra, Monterrei and Valdeorras DOs and authorized in R\u00edas Baixas and Ribeiro. Although it has traditionally been known to produce aromatic and fruity varietal reds as well as attractive ros\u00e9 wines, the rediscovery of old vineyards with naturally low yields on deep schist soils, notably in Bierzo, has led to the more recent appearance of dense, concentrated examples. The alcohol level can be quite high and the acidity drops quickly at the end of ripening so it is not always easy to get the right balance and retain freshness. Recommended producers include Alma de Tinto, Descendientes de J Palacios, Docampo, Estefania, Mengoba, Peique, Ra\u00fal P\u00e9rez, Pittacum, J Rebolledo, Dominio de Tares and Castro Ventosa.',
      'Portugal had 2,578 ha (6,370 acres) of Jaen in 2010, predominantly in D\u00e3o. The best wines come from the south-eastern part of the region (Seia and Gouveia): intensely coloured, very soft due to the low level of acidity but with an intense and delicate aroma of raspberries and blackberries. They vary from slightly rustic to elegant and are generally for early drinking, especially if they have been made using carbonic maceration. In order to produce wines with a firmer structure and better ageing capacity, Jaen is sometimes blended with other varieties such as TOURIGA NACIONAL. Recommended producers include Quinta das Maias, Quinta da Pellada and Quinta dos Roques.',
    ],
  },
  {
    name: 'MENOIR',
    synonyms: ['K\u00e9kmedoc', 'Medoc Noir', 'Menoire'],
    description:
      'Minor, recently renamed Hungarian variety making reds with Muscat aromas.',
    'origin and parentage': [
      'Partly because of its old name Medoc Noir, Menoir has long been thought to be a French variety brought to Hungary by grape breeder Mathiasz J\u00e1nos (1838\u20131921) but recent DNA analysis strongly challenges this origin.',
      'According to Galet (1990), the French Rh\u00f4ne variety MORNEN NOIR is cultivated in Hungary under the name M\u00e9doc Noir, which is French for K\u00e9kmedoc, a synonym for Menoir. The name M\u00e9doc Noir has also been used elsewhere for COT and MERLOT but the DNA profile of K\u00e9kmedoc published by Jahnke et al. (2009) is distinct from all others, proving that Mornen Noir and Menoir are different varieties, if their reference sample was correct.',
    ],
    'where its grown': [
      'Menoir produces early-drinking wines in Hungary that are typically medium-bodied with fine tannins and the smell of Muscat. The change of name from K\u00e9kmedoc, occasionally also M\u00e9doc Noir, meaning \u2018black M\u00e9doc\u2019, to Menoir came as a result of Hungary\u2019s accession to the EU in 2004 and the latter\u2019s hypersensitive attitude towards misleading nomenclature. Menoir is found mainly in the Eger wine region, where Lajos G\u00e1l makes a good varietal example, but total Hungarian plantings in 2008 were just 46 ha (114 acres).',
    ],
  },
  {
    name: 'MENU PINEAU',
    synonyms: [
      'Arbois Blanc',
      'Herbois',
      'Menu',
      'Menu Pinot',
      'Orbois',
      'Petit Pineau',
      'Verdet',
    ],
    description:
      'Well-connected and once important Blois speciality with a handful of advocates.',
    'origin and parentage': [
      'Menu Pineau is an old variety from the Val de Loire named after its similarity to Gros Pineau (CHENIN BLANC; menu means \u2018small\u2019). Historically it was known as Arbois Blanc in the Loir-et-Cher, although it has nothing to do with the village called Arbois in the Franche-Comt\u00e9, where it has never been cultivated. Instead the name comes from the Loir-et-Cher name Herbois, in dialect Orbo\u00e9 or Orbou\u00e9, which became Orbois and then Arbois. To make things even more confusing, it is known as Orbois in the French official register of varieties.',
      'It was supposedly mentioned for the first time around 1530 in the Cinqui\u00e8me Livre by the famous medieval writer Fran\u00e7ois Rabelais, among other obscure and mostly unidentified varieties: \u2018un grand vignoble faict de toutes especes de vignes, comme Phalerne, Malvoisie, Muscadet, Taige, Beaune, Mireveaux, Orl\u00e9ans, Picardent, Arbois, Coussi, Anjou, Grave, Corsicque, Verron, Nerac et autres\u2019 (\u2018a large vineyard composed of many grape varieties such as .\u00a0.\u00a0. Arbois .\u00a0.\u00a0.\u2019). A more specific mention of this variety was found in Bauhin (1650): \u2018Spectand\u00e6 pulchritudinis sunt uv\u00e6, quas Montbelgardi Arbois vocant, \u00e0 loco in Burgundia, vbi vocantur \u00e0 quibusdam teste d\u2019oiselet\u2019 (\u2018Considered fine are the grapes named Arbois in Montb\u00e9liard, a place in Burgundy where some people call it baby bird\u2019s head\u2019).',
      'Menu Pineau is a member of the Messile ampelographic group (see p XXVII), named after Meslier (Bisson 2009). According to Galet (2000), Arbois Rose is a colour mutation.',
      'DNA parentage analysis has discovered a parent\u2013offspring relationship with GOUAIS BLANC (Boursiquot et al. 2004), which means that Menu Pineau is a half-sibling of at least eighty Western European varieties (see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'Menu Pineau used to be widely cultivated in Touraine, northern France, where it has today been almost entirely replaced by SAUVIGNON BLANC. In 2006 there were 270 ha (667 acres) planted in all of France, compared with 1,455 ha (3,595 acres) in 1968. It owes its more recent renaissance to the determination of Thierry Puzelat of Clos du Tue Boeuf, one of the only producers, along with Claude Courtois, to make wines in which Menu Pineau plays a leading role. Puzelat\u2019s P\u00e9tillant Naturel is a blend of Menu Pineau and CHENIN BLANC, while his La Tesni\u00e8re has an even higher proportion of the former. Puzelat describes it as \u2018a little more rustic but less acid than Chenin Blanc\u2019. It certainly has both honey and tang. Courtois\u2019 Alkimya is a rare varietal wine and Domaine des Capriades produce a 100% Orbois. Because the variety tends to have relatively low acidity it is generally blended, eg as a minor component in the Sauvignon Blanc-dominated Cheverny, Valen\u00e7ay and Touraine appellations. When taken seriously, it can show mineral, spicy and apple flavours.',
      'Note that the synonym Arbois Blanc has nothing to do with the white or red wines of the Arbois appellation in the Jura.',
    ],
  },
  {
    name: 'M\u00c9RILLE',
    synonyms: [
      'Bordelais',
      'Bordelais Noir',
      'Bourdal\u00e8s',
      'Bouchal\u00e8s',
      'Grand Vesparo',
      'Vesparo \u00e0 Queue Verte',
      'P\u00e9rigord',
    ],
    description:
      'Virtually extinct variety found occasionally in Fronton blends in south-west France.',
    'origin and parentage': [
      'M\u00e9rille was first mentioned in 1783\u20134 in the Lot-et-Garonne and in the Gers (R\u00e9zeau 1997): \u2018M\u00e9rille, raisin noir, petits grains ronds, grappe longue et large, les grains serr\u00e9s, de bon go\u00fbt\u2019 (\u2018M\u00e9rille, black grape, small round berries, long, wide cluster, closely packed berries, good taste\u2019). Its name is of unknown origin.',
      'The supposed parent\u2013offspring relationship with VALDIGUI\u00c9 (Galet 1990) has not yet been confirmed but DNA parentage analysis has uncovered a likely parent\u2013offspring relationship with PRUNELARD (Boursiquot et al. 2009), which is consistent with M\u00e9rille\u2019s being a member of the Cot ampelographic group (see p XXVII).',
    ],
    'where its grown': [
      'Wines are light and rather ordinary. M\u00e9rille is sometimes found in the blend of a wine from the Fronton appellation, where it is an \u2018accessory\u2019 variety. It is also permitted in the Vin de Pays des C\u00f4tes du Tarn. There were 55 ha (136 acres) in France in 2008, down from 2,422 ha (5,985 acres) around fifty years ago.',
    ],
  },
  {
    name: 'MERLOT',
    synonyms: [
      'Bigney',
      'Crabutet',
      'Langon',
      'M\u00e9doc Noir',
      'Merlau',
      'Merlot Noir',
      'Picard',
      'S\u00e9millon Rouge',
      'Vitraille',
    ],
    description:
      'Fleshy, widely planted, relatively early ripening member of the Bordeaux family.',
    'origin and parentage': [
      'The earliest mention of Merlot appeared in 1783\u20134 in Libourne in the Gironde (R\u00e9zeau 1997): \u2018Merlau .\u00a0.\u00a0. fait un vin noir et excellent, et il abonde dans un bon terrein\u2019 (\u2018Merlau .\u00a0.\u00a0. makes a black and excellent wine, and is productive in a good soil\u2019). Its modern spelling appeared in 1824 in a treatise about M\u00e9doc wines, which also provides the most convincing hypothesis for the origin of the name (R\u00e9zeau 1997): \u2018on a donn\u00e9 le nom de Merlot \u00e0 cette vari\u00e9t\u00e9 de vigne, parce que le merle (oiseau) aime beaucoup ce raisin\u2019 (\u2018the name Merlot was given to this variety because the blackbird likes this grape very much\u2019). Indeed, this bird is called merlau in Occitan. Merlot also used to be called lou seme do\u00f9 flube (\u2018the seedling from the river\u2019) because it was thought to have been born on the islands in the River Garonne.',
      'In the late 1990s, DNA parentage analysis revealed a parent\u2013offspring relationship between Merlot and CABERNET FRANC but the other parent remained unknown (Regner 1999). Ten years later, the other parent of Merlot was discovered by Boursiquot et al. (2009). It was an unknown cultivar, first sampled in 1996 in Saint-Suliac near Saint-Malo in northern Brittany, where vines were cultivated at the end of the Middle Ages (Droguet 1992). The cuttings were taken from an abandoned grapevine growing on the slope of a hill called Mont Garrot. A few years later, four more plants of this variety were discovered in the Charente, planted in front of houses in four distinct villages: Mainxe, Figers, Tanzac and Saint-Savinien. These vines were locally called Raisin de la Madeleine or Madeleina, referring to the precocity of the grapes (often ripe at Sainte-Madeleine, 22 July). The DNA profile of this variety did not match any other known variety from the vast collection at Vassal in Montpellier, so it had to be christened. In order to avoid confusion with other Madeleine Somethings, Boursiquot and colleagues named this cultivar \u2018Magdeleine Noire des Charentes\u2019. Through DNA testing, they have also established that Cabernet Franc is the father and Magdeleine Noire des Charentes the mother of Merlot.',
      'Merlot belongs to the Carmenet ampelographic group (see p XXVII) originating from the Gironde or south-western France or Spain\u2019s Pa\u00eds Vasco (Bordenave et al. 2007; Bisson 2009). In addition, ABOURIOU, CABERNET SAUVIGNON, CARMEN\u00c8RE and COT, having at least one parent in common, are all half-siblings (Abouriou could also be a grandparent) of Merlot (see the pedigree diagram in the Cabernet Sauvignon entry).',
      'While Merlot Gris is a colour mutation of Merlot, Merlot Blanc is a distinct variety found in 1891 and born from a natural cross between Merlot and FOLLE BLANCHE (Boursiquot et al. 2009). Merlot Blanc has today almost disappeared.',
      'Merlot has been used to breed numerous varieties around the world, including the following half-siblings, some of which are cultivated for commercial wines: CARMINE (US), EDERENA (France), EVMOLPIA (Bulgaria), FERTILIA (Italy), Mamaia (Romania), NIGRA (Italy), PRODEST (Italy) and REBO (Italy). There is also a small, isolated vineyard near Arezzo, central Italy, where Carnasciale grow a variety called Caberlot, thought to be a crossing of Merlot with an unidentified parent (Paolo Storchi, personal communication).',
    ],
    'where its grown': [
      'It would be quicker and easier to say where Merlot is not grown than to list the many regions and countries in which it has been planted. Because it is easier to ripen, it is grown even more widely than CABERNET SAUVIGNON, and the sweetness and freshness of its wine make it particularly easy to appreciate. Like its relatives Cabernet Sauvignon and CABERNET FRANC, it can exhibit a certain herbaceousness if not fully ripe, though this is much less common with Merlot than with either Cabernet. In general, a very considerable proportion of all the Merlot grown worldwide is blended with either or both Cabernets to provide fruit and flesh to counterbalance their more tannic structure.',
      'It is one of the world\u2019s most planted wine grapes and the single most planted in France,\nwhere the total was 115,746 ha (286,015 acres) in 2009. It is quite widely dispersed but by far the greatest concentrations are in Aquitaine, the greater Bordeaux region and Languedoc-Roussillon. Merlot was long regarded as secondary in Bordeaux until the mid nineteenth century, when its resistance to the scourge of powdery mildew, especially compared with CABERNET SAUVIGNON, made it very much more popular. The ease with which it could be ripened even in relatively cool years also contributed to its appeal so that today Merlot far exceeds Cabernet Sauvignon in the Bordeaux d\u00e9partement, Gironde (69,053 v 26,790 ha/170,634 v 66,200 acres) \u2013 and also in the Bergerac d\u00e9partement to the east, Dordogne (4,303 v 1,582 ha/10,633 v 3,909 acres).',
      'But the French total has started to decline slightly as Bordeaux\u2019s wine producers\u2019 respect for CABERNET SAUVIGNON and the majesty of its wines is revived. Wine made from Merlot generally has more obvious fruit and less aggressive tannins so that it can be drunk younger than a Cabernet. Typically it has a certain sweet plummy fruit which can vary between facile and the extraordinary velvety richness of a great Pomerol such as Ch\u00e2teau P\u00e9trus. See CABERNET FRANC for reasons why producers here generally like to spread their risk by planting all three varieties, although the plateau of Pomerol has shown a particular affinity with Merlot so that most Pomerols are dominated by the variety. Cabernet Sauvignon can be difficult to ripen on the (generally less temperate) right bank of the Gironde and red-wine vineyards in any of the cooler areas in the greater Bordeaux region are likely to be dominated by the Merlot vine. In the traditional Bordeaux blend, particularly on the left bank in the M\u00e9doc and Graves, Merlot provides the flesh on the bones of the Cabernets. It generally achieves sugar levels up to a full 1% of alcohol more than Cabernet.',
      'Merlot is also widely planted in the Languedoc, where total plantings are 29,914 ha (73,919 acres), a good 50% more than the CABERNET SAUVIGNON total, with more than 11,000 ha (27,000 acres) in both the Aude and the H\u00e9rault, although the Gard is not far behind with more than 7,000 ha (17,000 acres). Most Languedoc Merlot is reasonably anodyne stuff, sweetish red with a vaguely leafy perfume, for yields tend to be relatively high here.',
      'Italy\u2019s 2000 census found 25,614 ha (63,294 acres) of Merlot, most in the northern half of the country and two thirds of it grown for more basic IGT rather than DOC(G) wines. Italian Merlot is, typically, a mass-market, sweet-and-sour blend assembled from over-productive vines grown on the plains of the Veneto. Its other quantitative stronghold, Friuli, can boast rather more convincing examples such as Borgo del Tiglio\u2019s Rosso della Centa made in the image of a Pomerol and Graf de la Tour Merlot named after the founder of the Villa Russiz estate and children\u2019s institution, who is credited with importing top-quality French vine cuttings to Collio. Merlots from Trentino-Alto Adige tend to lie somewhere between the Friuli and Veneto styles and quality level, and are being helped by generally rising temperatures and concomitant ripeness levels.',
      'All over Italy, small quantities of Merlot are used like seasoning in ambitious blends \u2013 and, if gossip is to be believed, in supposedly all-Italian DOCs to give them \u2018international appeal\u2019. Because selection of suitable plant material is a much more developed art in France than in Italy, Merlot (and Cabernet) vines sourced from French nurseries tend to be very much more reliable and consistent than their Italian counterparts, which makes them popular with growers. But the variety\u2019s Italian stronghold is Toscana, especially but by no means exclusively the coastal Maremma. Here too it is only rarely a solo performer, as in such full-throttle, almost Californian, examples as Ornellaia\u2019s Masseto and wines such as Castello di Ama\u2019s L\u2019Apparita, Le Macchiole\u2019s Messorio and Tua Rita\u2019s Redigaffi made in Masseto\u2019s image. Merlot is also important in a host of Bordeaux blends made throughout central Italy, especially in the Maremma and Chianti Classico, and is also occasionally blended to good effect with the local SANGIOVESE as in Castello di Fonterutoli\u2019s Siepi. Merlot\u2019s achievements in southern Italy have been less glorious although, in Campania, Riccardo Cotarella\u2019s Montevetrano Bordeaux blend has won international adulation, as has Planeta\u2019s Sicilian varietal Merlot made in distinctly international style.',
      'Merlot is much less important to Spain than it is to generally cooler, damper France and Italy but the fashion for international varietals in Spain drove the country\u2019s total plantings from 8,700 ha (21,498 acres) in 2004 to 13,325 ha (32,927 acres) in 2008, with the biggest areas in Catalunya (3,360 ha/8,303 acres), Navarra (2,450 ha/6,054 acres) and Arag\u00f3n (2,218 ha/5,481 acres) in the north east and Castilla-La Mancha (2,894 ha/7,151 acres) in the centre of Spain. Spanish Merlot is used mainly in blends, often to add flesh to TEMPRANILLO as well as CABERNET SAUVIGNON, and is generally most successful in Catalunya \u2013 in Pened\u00e8s and cooler areas such as Conca de Barber\u00e0. Tom\u00e0s Cusin\u00e9 in Costers del Segre, for example, has fashioned a Bordeaux blend with Merlot the major ingredient, labelled Geol, with considerable success, but some of the best examples of varietal Merlot have been Caus Lubis from Can R\u00e0fols dels Caus and the old vintages of Maga\u00f1a Merlot from Navarra, where they now focus on blends. As ever, Portugal has been more resistant to international fashion and had only 556 ha (1,374 acres) of Merlot in 2010, mainly in the Tejo.',
      'Global warming has encouraged the planting of Merlot in Germany, mostly in the warmer Pfalz and Rheinhessen regions: 450 ha (1,112 acres) by 2008.',
      'In Austria,\nCABERNET FRANC is very much more important than Merlot and there were only 112 ha (277 acres) of the latter in 2008, half in Nieder\u00f6sterreich (Lower Austria) and the rest mostly in Burgenland.',
      'The warm southern, Italianate Ticino canton of Switzerland has long been a Merlot stronghold: 1,028 ha (2,540 acres) in 2009, the vast majority in Ticino in the south, towards the Italian border. Wines can vary from the most basic to fine subalpine answers to Pomerol from the likes of Guido Brivio (Platinum), Daniel Huber (Montagna Magica), Cantina Kopp von der Crone Visini (Balin), Werner Stucky (Tracce di Sassi) and Luigi Zanini (Castello Luigi).',
      'Hungary values CABERNET FRANC much more than Merlot but had 1,791 ha (4,426 acres) in 2008, mainly in Szeksz\u00e1rd and Vill\u00e1ny in the south, and Kuns\u00e1g in the south and centre, plus D\u00e9l-Balaton in the central west and Eger in the north east. Attila Gere\u2019s Solus is one of the better examples.',
      'In 2009, Bulgaria had 15,202 ha (37,565 acres) devoted to Merlot, only slightly less than total plantings of CABERNET SAUVIGNON, but no definitive varietal style. Much of the best Bulgarian Merlot is used in Bordeaux blends. Merlot is the dominant variety in the ambitious, plump and spicy, Bordeaux-influenced Enira blend, for example.',
      'Slovenia\u2019s 1,019 ha (2,518 acres) of Merlot in 2009 were predominantly in the west, in Vipavska Dolina, where it is the most planted variety, and Gori\u0161ka Brda, where it comes second. There\u2019s also quite a bit in Slovenska Istra (Slovenian Istria) in the far south west. The variety constitutes 15% of the vineyards of Primorska with the likes of Movia, Edi Sim\u010di\u010d, Marjan Sim\u010di\u010d and Sutor arguably producing rather more serious varietal examples than their counterparts over the border in Italian Friuli.',
      'The Czech Republic\u2019s 87 ha (215 acres) in 2009 were almost all in Morava in the south east. Merlot is one of Serbia\u2019s principal international varieties while Croatia grew 1,105 ha (2,731 acres) in 2009, and it is also a feature of Montenegro.',
      'Merlot is by far the leading imported red wine grape in Romania, where there were 10,782 ha (26,643 acres) in 2008, mostly in the south east \u2013 eg in Dealu Mare in Muntenia and closer to the Black Sea in Dobrogea but also further west in Dr\u0103g\u0103\u015fani. The variety is sometimes blended with FETEASC\u0102 REGAL\u0102, as in Davino\u2019s superior bottling, although, being so widely planted, it is also available in some pretty ordinary offerings. Over the border in very similar conditions, the Republic of Moldova grew 8,123 ha (20,072 acres) of Merlot in 2009.',
      'Ukraine recorded 2,820 ha (6,968 acres) of Merlot in 2009, while in Russia the Krasnodar Krai region had 1,588 ha (3,924 acres), ie Merlot constituted 5\u20136% of the region\u2019s vines.',
      'In Greece, Merlot was in the top six varieties in eastern Makedon\u00eda and Thr\u00e1ki (Thrace; 243 ha/600 acres) and there were 86 ha (213 acres) in western Makedon\u00eda and 74 ha (183 acres) in central Greece but it needs a relatively cool site to retain any freshness.',
      'Merlot is the second most planted variety in Israel after CABERNET SAUVIGNON \u2013 with about 1,000 ha (2,470 acres). Superior, concentrated, California taste-alike versions are made by the likes of Dalton, Margalit and Recanati.',
      'Turkey had just 429 ha (1,060 acres) of Merlot in 2010 and the variety is also found on Malta and Cyprus.',
      'Merlot has been hugely important in the United States, particularly in California, where 46,762 acres (18,924 ha) of vineyard was planted with the variety in 2010, declining slightly since the peak of over 51,000 acres (20,640 ha) in 2004. The 1990s saw a craze for mellow Merlot, so much fleshier than cussed CABERNET SAUVIGNON, although the pro-PINOT NOIR movie Sideways rubbed the shine off Merlot\u2019s reputation. Today it is most readily, and perhaps unfairly, associated with mass-market, sweet reds fed by considerable plantings in the Central Valley and on the Monterey valley floor. The more cosseted fruit from vineyards in Napa and Sonoma tends to play a supporting role in expensive wines labelled Cabernet Sauvignon and those deliberately sold as Meritage blends. Those who take wines labelled Merlot rather more seriously than most include Duckhorn, Pahlmeyer, Paloma, Pride Mountain, Shafer and Truchard, who have proved Carneros\u2019 kinship with the variety. Matanzas Creek made a similar point in what is now the Bennett Valley AVA in Sonoma in the late twentieth century. The combination of the opulence of Merlot and California sunshine can be overwhelming; the trick is to retain a certain freshness.',
      'The natural crispness of Washington wines complements Merlot\u2019s fleshy fruit and only CABERNET SAUVIGNON is more planted here. By 2011 the state\u2019s plantings of Merlot totalled 8,235 acres (3,334 ha) with much of it used in blends but also in particularly winning varietals which rarely suffer the excess ripeness that can mar those in some hotter climates. Producers of superior varietal Washington Merlot, often vineyard designated, include Bookwalter, Canoe Ridge, DeLille, L\u2019Ecole No 41, Leonetti, Pedestal, Quilceda Creek and Andrew Will.',
      'Merlot is relatively minor in Oregon, where vineyards totalled a steady 508 acres (206 ha) in 2008. This easily ripened variety is planted in most American states where vines are grown, with notable concentrations in Texas, where there were 290 acres (117 ha) in 2008, making it the second most planted red wine grape after CABERNET SAUVIGNON. In Virginia, Merlot was the most planted red wine variety in 2010 with a total of 338 acres (136 ha), although much of it goes into Meritage blends. The state with the third biggest Merlot acreage after California and Washington is New York, with 902 acres (365 ha) back in 2006, most of it thriving in Long Island\u2019s maritime conditions.',
      'In Canada, Merlot is British Columbia\u2019s most planted variety, with 1,585 acres (641 ha) planted by 2008, representing one red wine vine in three. It dominates in such successful blends as Osoyoos Larose and Mission Hill\u2019s Oculus, and Cedar Creek\u2019s Platinum Reserve shows that varietal Merlot can perform here too. In Ontario, where there were 1,230 acres (498 ha) of Merlot in 2008, it has so far only rarely achieved the intensity of BC Merlot or the precision of the best Ontario Pinot Noir.',
      'Merlot is also grown in Mexico, particularly in Baja California and Coahulia de Zaragoza, but can be a bit heavy.',
      'Merlot is extremely important in Chile, where it is the third most planted variety after CABERNET SAUVIGNON and Pa\u00eds (LIST\u00c1N PRIETO), with 13,280 ha (32,816 acres) in 2009, predominantly in the valleys that cross the Central Valley: Colchagua (3,359 ha/8,300 acres), Maule (3,019 ha/7,460 acres), Curic\u00f3 (2,911 ha/7,193 acres), Cachapoal (2,007 ha/4,959 acres) and Maipo (1,168 ha/2,886 acres). See CARMEN\u00c8RE for details of how for years these two Bordeaux varieties were confused in Chilean vineyards. Today, most true Merlot vines are relatively youthful but the quality of Merlot offerings from the likes of Casa Lapostolle, Err\u00e1zuriz, Vi\u00f1a Leyda and Montes is still extremely impressive. Successful Chilean Merlot can have real lusciousness.',
      'The variety is much less important to the wine industry in Argentina, where the obvious fruit of the dominant Malbec (COT) and to a lesser extent Bonarda (DOUCE NOIRE) need Merlot\u2019s flesh much less than, say, the CABERNET SAUVIGNON of Chile. There were 7,142 ha (17,648 acres) in 2008, mostly in Mendoza and to a lesser extent in San Juan and other regions. Here Merlot is often blended with Malbec, as in successful offerings from Finca La Anita, Chacra and NQN.',
      'Uruguay had 853 ha (2,108 acres) of Merlot in 2009, about 10% of the total vineyard area and just ahead of CABERNET SAUVIGNON, with this relatively soft variety an important, and steadily increasing, blending component with tough TANNAT. Bouza and Pizzorno make some of the better examples.',
      'In Brazil,\nthere were 1,089 ha (2,691 acres) in Rio Grande do Sul in 2007 with Miolo showing notable confidence in vinifying Merlot \u2013 perhaps not surprisingly since Pomerol-based Michel Rolland has consulted here. In 2009 there were 30 ha (74 acres) of Merlot in Bolivia and it was also found in Peru.',
      'Although Merlot is quantitatively important in Australia, its total of 10,537 ha (26,037 acres) in 2008 making it the third most planted red variety after Shiraz (SYRAH) and CABERNET SAUVIGNON, Australian varietal Merlot has no clear identity or reputation. Fuelled by global Merlot mania, the variety was very widely planted in the early years of this century, with the biggest areas in the hot inland regions of Riverland, Riverina and Murray Darling but also in all the country\u2019s best-known wine regions, although Merlot fever seems to have cooled somewhat recently. Basic varietals apart, the M word is encountered on labels most often in Cabernet/Merlot blends, of which Cullen\u2019s of Margaret River in Western Australia is probably the most respected.',
      'Merlot plantings have also tailed off in New Zealand, where there were 1,363 ha (3,368 acres) in 2008, constituting about 5% of the country\u2019s total vineyard area and making Merlot the second most planted red wine grape (a long way) after PINOT NOIR. The variety is very much easier to ripen this far from the equator than CABERNET SAUVIGNON and thrives in the well-drained soils of Hawke\u2019s Bay, as witness the best examples from Alluviale, Craggy Range and Trinity Hill. Ata Rangi have also had success with it in Martinborough.',
      'Where there is CABERNET SAUVIGNON there is usually Merlot and China is no exception. In 2009 total area planted in north-east and western China was estimated at 3,204 ha (7,917 acres; about a sixth of all Cabernet Sauvignon plantings) but this is probably an under-estimate. It is also grown in Japan (816 ha/2,016 acres in 2009) and India.',
      'Merlot is the third most planted red variety in South Africa with 6,614 ha (16,344 acres) in 2008 \u2013 almost 15% of all red vines. It is planted in most wine-growing regions, with the biggest areas in Stellenbosch (2,105 ha/5,202 acres) and Paarl (1,289 ha/3,185 acres) and wines tend to be made in a chocolatey, glossy California style. Over-achievers include Dombeya, Shannon, Thelema, Veenwouden, Vergelegen and Yonder Hill.',
    ],
  },
  {
    name: 'MERSEGUERA',
    synonyms: ['Exquitsagos', 'Esquitxagos', 'Verdosilla'],
    description: 'Uninspiring variety widely planted in eastern central Spain.',
    'origin and parentage': [
      'Merseguera most probably comes from Valenc\u00eda in eastern central Spain and is identical to Exquitsagos, cultivated further north, in San Mateo in the province of Castell\u00f3n and in the Bajo Ebro region in the province of Tarragona. Merseguera is genetically close to the Spanish TORRONT\u00c9S (Iba\u00f1ez et al. 2003).',
      'The name Marisancho is also used occasionally in Valencia as a synonym for Merseguera, which is confusing as it is also a synonym for PARDILLO.',
    ],
    'where its grown': [
      'Varietal wines are generally lacklustre with moderate alcohol levels, lightly herbaceous aromas and notes of bitter almonds. For more body and aroma, Merseguera is sometimes blended with MALVASIA and other varieties. The variety was traditionally used to make rancio-style and sweet wines. The vast majority of Spain\u2019s 3,921 ha (9,689 acres) in 2008 were in Valencia, although there are lesser plantings in Murcia and Castilla-La Mancha. It is one of the principal varieties in the Valencia and Utiel-Requena DOs and permitted in DO Alicante. Varietal or Merseguera-dominant wines are produced by, for example, Castillo de Chiva and Polo Monle\u00f3n.',
    ],
  },
  {
    name: 'MERZLING',
    synonyms: ['Freiburg 993-60'],
    description: 'Very minor, disease-resistant German hybrid.',
    'origin and parentage': [
      'Complex hybrid bred in 1960 by Johannes Zimmerman at the Freiburg research centre in Baden-W\u00fcrttemberg, southern Germany, by crossing Seyve-Villard 5276 (see SEYVAL BLANC) with Freiburg 379-52, where Freiburg 379-52 is a RIESLING \u00d7 PINOT GRIS cross (see PRIOR for the complete pedigree of Merzling). It was named after Merzhausen, the area of the city of Freiburg where the research institute had several trial vineyards. Merzling was used to breed BRONNER, HELIOS and SOLARIS, which are more widely planted than their parent.',
    ],
    'where its grown': [
      'There are just 8 ha (20 acres) planted in Germany. Thomas M\u00fcller in Franken produces a dry varietal wine, as do Siebenhaller and K\u00f6pfer in Baden. It is also found in Belgium at the Domaine Viticole du Chenoy.',
    ],
  },
  {
    name: 'MESLIER SAINT-FRAN\u00c7OIS',
    synonyms: [
      'Blanc Ram\u00e9',
      'Gaillac',
      'Gros Meslier',
      'Meslier',
      'Purgarie',
      'Pelegarie',
    ],
    description: 'Almost extinct, historic French variety.',
    'origin and parentage': [
      'The earliest mention of a grape named Meslier dates back to 1512 in the Comptes de l\u2019Abbaye de Saint-Germain-des-Pr\u00e9s (R\u00e9zeau 1997): \u2018le vie jour de mars, pour huit cens de marguottez de meslier\u2019 (\u2018the 6th day of March, for eight rents of meslier cuttings\u2019). However, three different types of Meslier were described in 1564 by Charles Estienne and Jean Li\u00e9bault (R\u00e9zeau 1997): \u2018l\u2019vn se nomme le meslier commun, qui est de grand rapport: l\u2019autre s\u2019appelle le gros meslier, qui a le bois & le fruit aussi plus gros: & l\u2019autre s\u2019appelle le franc meslier, qui rapporte le meilleur fruict de tous, & le grain plus s\u00e9par\u00e9\u2019 (\u2018one is called meslier commun, which has high yields: the other is called gros meslier, which has bigger wood and fruit: and the other is called franc meslier, which provides the best fruit of all, and has looser berries\u2019). Meslier Commun corresponds to the modern PETIT MESLIER (Galet 1990) and Gros Meslier is an old synonym for Meslier Saint-Fran\u00e7ois (Odart 1845) but the modern identity of Franc Meslier remains unknown.',
      'After several subsequent mentions under various spellings (Meli\u00e9, Meliez, M\u00e9lier, Mellier), the name Meslier de Saint-Fran\u00e7ois first appeared in Odart (1859): \u2018le Gros Meslier, ou Meslier de Saint-Fran\u00e7ois, produit en abondance du vin passable\u2019 (\u2018Gros Meslier, or Meslier de Saint-Fran\u00e7ois, produces abundant quantities of average wine\u2019). The name meslier most likely derives from the old French mesler, meaning \u2018to mix\u2019 (Latin misculare), owing to the ability of these varieties to be blended with others, an etymology shared with MELON.',
      'According to Galet (1990), Meslier Saint-Fran\u00e7ois originated in the area around Notre-Dame de Ch\u00e2teau-Landon and then spread out to the Val de Loire, the Charente and the south west. This is geographically consistent with DNA profiling showing that Meslier Saint-Fran\u00e7ois is a natural GOUAIS BLANC \u00d7 CHENIN BLANC cross (see the PINOT pedigree), just like its siblings BALZAC BLANC and COLOMBARD from the Charente (Bowers et al. 2000). Meslier Saint-Fran\u00e7ois is a member of the Messile ampelographic group (see p XXVII; Bisson 2009).',
      'Meslier Rose is a colour mutation of Meslier Saint-Fran\u00e7ois.',
    ],
    'where its grown': [
      'Meslier Saint-Fran\u00e7ois is a nearly extinct variety that once grew in Champagne, Basse-Bourgogne, the Franche-Comt\u00e9 and the Val de Loire in France but today it is found mainly in the Gers and used in the production of armagnac and Pineau des Charentes, a more fitting use given the light, flat, low-alcohol character of the wines. However, Charles Joumert in Villiers-sur-Loir, north of Tours in the Coteaux du Vend\u00f4mois appellation, took cuttings in 1990 from what were probably some of the last Meslier Saint-Fran\u00e7ois vines in the region, about to be grubbed up, and has for the last few years been producing a varietal wine, classified as a vin de table (Kelley 2009b). In eastern Touraine, Lionel Gosseaume\u2019s Climat no 2 is a blend of old-vine Meslier with SAUVIGNON BLANC.',
    ],
  },
  {
    name: 'M\u00c9ZES FEH\u00c9R',
    synonyms: [
      'Alf\u00f6ldi Feh\u00e9r',
      'Aranyka',
      'Aranyka Sarga',
      'Bieli Medenac',
      'Budai Feh\u00e9r',
      'Goldtraube',
      'Honigler',
      'Honigler Weisser',
      'Honigler Bianco',
      'Honigtraube',
      'Margit',
      'M\u00e9z\u00e9des',
      'M\u00e9zes',
      'Sarf\u00e9jer',
      'S\u00e1rga Margit',
      'S\u00e1rga Sz\u0151l\u0151',
      'Zsige',
    ],
    description:
      'Ancient but almost extinct Hungarian variety making rich, aromatic whites.',
    'origin and parentage': [
      'M\u00e9zes Feh\u00e9r, meaning \u2018white honey\u2019, is a very old Hungarian variety once widespread across Europe, mainly for table grapes. The name, and its German synonym Honigler Weisser, points to the variety\u2019s high sugar content. It is said to originate from the Balkans, but there is so far no evidence of this.',
    ],
    'where its grown': [
      'M\u00e9zes Feh\u00e9r is virtually extinct in Hungary but Oszk\u00e1r Maurer makes a rich, rather unusual yet fresh and fragrant varietal wine (from vineyards over the border in Serbia) and Fetzer\u2019s Sv\u00e1bbor is a field blend including M\u00e9zes Feh\u00e9r.',
    ],
  },
  {
    name: 'M\u00c9ZY',
    synonyms: ['M\u00e9si', 'Meslier Noir', 'Petit Mesi'],
    description:
      'Franche-Comt\u00e9 variety known only as a minor ingredient in a blend.',
    'origin and parentage': [
      'M\u00e9zy is a minor variety from the Franche-Comt\u00e9 in eastern France. Although it is also called Meslier Noir, it is not the black version of MESLIER SAINT-FRAN\u00c7OIS. DNA parentage analysis has shown that M\u00e9zy is one of the twenty-one crosses between PINOT and GOUAIS BLANC that have occurred in north-east France (Bowers et al. 1999; Boursiquot et al. 2004).',
    ],
    'where its grown': [
      'Even in its region of origin, M\u00e9zy has never had great significance, but \u00c9tienne Thiebaud, who founded Domaine des Cavarodes in the Jura, eastern France, at the age of twenty, has rescued an old vineyard in Liesle, south west of Besan\u00e7on in the Doubs region, and produces a red Vin de Pays de Franche-Comt\u00e9, a blend of PINOT NOIR, TROUSSEAU, PINOT MEUNIER, GAMAY NOIR, POULSARD, GUEUCHE NOIR, Argant (ie G\u00c4NSF\u00dcSSER),BLAUER PORTUGIESER, ENFARIN\u00c9 NOIR and M\u00e9zy.',
    ],
  },
  {
    name: 'MILGRANET',
    synonyms: ['P\u00e9rigord Noir', 'Petite M\u00e9rille'],
    description:
      'Recently snatched from virtual extinction by researchers in southern France.',
    'origin and parentage': [
      'Milgranet is a traditional variety of the Tarn, Haute-Garonne and Tarn-et-Garonne d\u00e9partements in central southern France. The name Milgranet may be derived from mille graines (\u2018thousand seeds\u2019) but Lavignac (2001) suggests it comes from milgrana, the Occitan word for pomegranate, because the grape bunches have small, tight berries. DNA parentage analysis has established that Milgranet is a likely progeny of GOUAIS BLANC (Boursiquot et al. 2004).',
    ],
    'where its grown': [
      'Milgranet is a complementary variety in the Vins de Lavilledieu appellation in France but there were just 2 ha (5 acres) recorded in France in 2008. However, local research continues: cuttings were taken from some thirty different clones in 2003 and the first official clone was agreed in 2007. If yields are not restricted, the wines can be green and astringent.',
    ],
  },
  {
    name: 'MILLOT-FOCH',
    synonyms: ['VB 85-1'],
    description:
      'Rare, disease-resistant Swiss hybrid producing deeply coloured, fruity wines.',
    'origin and parentage': [
      'As suggested by its name, Millot-Foch is a L\u00c9ON MILLOT \u00d7 MAR\u00c9CHAL FOCH hybrid obtained by Valentin Blattner in Soyhi\u00e8res in the Swiss Jura.',
    ],
    'where its grown': [
      'Only a few producers, including the breeder, cultivate this variety on just 2 ha (5 acres) in Switzerland. A deeply coloured varietal wine is made by Creux-de-la-Terre in the canton of Jura and Risch in the canton of Zug. They tend to have aromas of wild berries, cherries and hints of pepper plus silky tannins.',
    ],
  },
  {
    name: 'MINELLA BIANCA',
    synonyms: ['Eppula', 'Minedda Bianca', 'Minnella'],
    description: 'Minor Sicilian white rarely made as a varietal.',
    'origin and parentage': [
      'Minella Bianca was described in 1760 by Sestini (1991) in the Etna region of the Italian island of Sicilia, where it is still cultivated. Its name could derive from the local word minna, meaning \u2018breast\u2019, referring to the elongated shape of the berries. It is considered to be indigenous to Sicilia.',
    ],
    'where its grown': [
      'Minella Bianca is cultivated exclusively in the province of Catania, in the Etna foothills in north-east Sicilia, Italy,\nand to a lesser extent in the province of Enna in the centre of the island. It tends to be interplanted with NERELLO MASCALESE, CARRICANTE and CATARRATTO BIANCO and is authorized in the Etna Bianco and Etna Bianco Superiore DOCs. Benanti is one of the very few producers to make a varietal wine, which is aromatic and dry and may have aromas of aniseed. The Italian agricultural census recorded 86 ha (213 acres) in Italy in 2000 but it was not listed in more recent Sicilia statistics.',
    ],
  },
  {
    name: 'MINUTOLO',
    synonyms: [
      'Fiano Aromatico',
      'Fiano della Valle d\u2019Itria',
      'Fiano di Puglia',
      'Fiano di Salento',
      'Fiano Minutolo',
      'Fiore Mendillo',
      'Greco Aromatico',
      'Minutola',
      'Moscatellina',
    ],
    description: 'Opulently grapey Puglian once known as Fiano Aromatico.',
    'origin and parentage': [
      'Until recently, this grape variety from the Valle d\u2019Itria in Puglia, southern Italy, was known as Fiano Aromatico or Greco Aromatico, then Fiano Minutolo. It is now officially known as Minutolo, in order to distinguish it from Campania\u2019s FIANO (aka Fiano di Avellino), which has a distinct DNA profile (Cal\u00f2 et al. 2001). Despite its emphatically grapey flavour, Minutolo is genetically distinct from varieties with Muscat in their name (Fanizza et al. 2003).',
    ],
    'where its grown': [
      'Minutolo, a speciality of Puglia, southern Italy, was rescued from the brink of extinction in 2000 by oenologist Lino Carparelli and colleagues, who discovered old vines of what was then called Fiano Minutolo, a local synonym for the previous name Fiano Aromatico, in some old vineyards in the Valle d\u2019Itria in Puglia. Additional vines were later found and the variety was evaluated and subsequently included in the Italian national register of grape varieties under the name Minutolo. It is its Muscat-flavoured grapes that distinguish this so clearly from FIANO (ie Fiano di Avellino).',
      'Minutolo is recommended throughout Puglia and is used mainly in blends in Locorotondo and Martina/Martina Franca DOCs. Torrevento produce one such blend using grapes from Carparelli\u2019s vineyard. Varietal versions such as I Pastini\u2019s Ramone and Feudi di San Marzano\u2019s Magia are proliferating. The wines, if carefully vinified so as to retain freshness, tend to be intensely grapey, somewhat reminiscent of MUSCAT OF ALEXANDRIA, with floral, citrus, or white-fruit and herbal flavours.',
    ],
  },
  {
    name: 'MISKET CHERVEN',
    synonyms: [
      'Karlovski Misket',
      'Kimionka',
      'Misket Siv',
      'Misket Starozagorski',
      'Misket Sungurlarski',
      'Sinja Temenuga',
      'Songurlarski Misket',
      'Yuzhnobalgarski Cherven Misket',
    ],
    description:
      'Old, pink-skinned Bulgarian variety used to make dry, scented whites.',
    'origin and parentage': [
      'Misket Cherven, literally Red Muscat, is an old Bulgarian variety that possibly originates in the Sungurlare Valley in central eastern Bulgaria or around Karlovo or Brezovo further west, where it has traditionally been cultivated. The colour mutations Misket Rozov (pink berries) and Misket Byal (white berries) also exist. Despite its name, Misket Cherven is not related to any other Muscat Something or to the other Miskets in Bulgaria.',
    ],
    'where its grown': [
      'Misket Cherven produces simple, only lightly perfumed dry whites and is widely planted in Bulgaria, mostly in the provinces of Plovdiv, Burgas, Haskovo and Sliven in the south of the country, with a total area of 6,303 ha (15,456 acres) in 2009, making it the most widely planted indigenous white wine variety in Bulgaria. Varietal appellation wines are made in regions such as Sungurlare and Karlovo, although Misket Cherven is naturally low in acidity and is therefore often blended with crisper local varieties such as DIMYAT.',
    ],
  },
  {
    name: 'MISKET VARNENSKI',
    synonyms: ['Muscat de Varna', 'Muscat Varnenski'],
    description: 'Minor Bulgarian cross from Varna.',
    'origin and parentage': [
      'Misket Varnenski, literally \u2018Muscat from Varna\u2019, is a DIMYAT \u00d7 RIESLING cross obtained in 1971 by K Stoev at the Pleven viticulture and oenology institute in northern Bulgaria. It is not related to any other varieties named Muscat Something.',
    ],
    'where its grown': [
      'Not surprisingly, the majority of Bulgaria\u2019s 259 ha (640 acres) of Misket Varnenski is planted in the province of Varna in eastern Bulgaria, although it is also found to a much more limited extent in other provinces such as Ruse in the north. Wines are not particularly memorable and show little RIESLING character, which is perhaps why producers such as the Varna Winery blend Misket Varnenski with its more noble parent.',
    ],
  },
  {
    name: 'MISKET VRACHANSKI',
    synonyms: [
      'Mirizlivka',
      'Misket Vratchanski',
      'Tvarda Vrazhda Misket',
      'Wrat Chanskii Musket',
      'Wratchanski Misket',
    ],
    description:
      'Minor Bulgarian cross planted mostly in the far east and west. The most aromatic of the Bulgarian Miskets.',
    'origin and parentage': [
      'Misket Vrachanski is a Coarna Alba \u00d7 MUSCAT BLANC \u00c0 PETITS GRAINS cross made in Bulgaria, in which Coarna Alba is a Romanian variety with functionally female flowers that is no longer commercially cultivated.',
    ],
    'where its grown': [
      'There were just 152 ha (376 acres) of this lesser Misket planted in Bulgaria in 2008, mostly in the province of Montana in the north west and in Varna in the east. Wines are generally dry or off dry and have relatively powerful Misket aromas of flowers, herbs and honey. Chateau Euxinograde, housed in the former royal palace on the Black Sea coast north of Varna, make a varietal wine and the Ruse Wine House in the far north of the country blend Misket Vrachanski with Traminer (SAVAGNIN).',
    ],
  },
  {
    name: 'MLADINKA',
    synonyms: ['Mladenka'],
    description:
      'Very rare variety from the Croatian coast generally used in blends.',
    'origin and parentage': [
      'A rare variety that has survived in the Ka\u0161tela and Trogir regions north of Split on the Croatian coast. Contrary to local opinion, recent ampelographic research and DNA profiling have shown that Mladinka is not identical to BOGDANU\u0160A (Goran Zduni\u0107, personal communication).',
    ],
    'where its grown': [
      'Mladinka is found mainly in the Ka\u0161tela and Trogir regions very close to Split on the coast of Croatia. It is also grown in the Omi\u0161 region slightly further south. It is generally used in blends with other local varieties, sometimes interplanted in the vineyard with VLA\u0160KA, for example, to add acidity.',
    ],
  },
  {
    name: 'MOLETTE',
    synonyms: ['Molette Blanche', 'Molette de Seyssel'],
    description:
      'Minor and rather neutral vine used mainly for sparkling wines in Savoie, eastern France.',
    'origin and parentage': [
      'Molette is a local variety from Savoie, eastern France, where it was first mentioned by the ampelographers Alphonse Mas and Victor Pulliat in 1876\u20137 in Seyssel (R\u00e9zeau 1997): \u2018la Molette para\u00eet \u00eatre un c\u00e9page special et particulier au vignoble de Seyssel, o\u00f9 nous l\u2019avons vu pour la premi\u00e8re fois\u2019 (\u2018Molette seems to be a particular variety of the Seyssel vineyards, where we have seen it for the first time\u2019). DNA parentage analysis has shown that Molette is most likely a progeny of GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram), and it might be a GRINGET \u00d7 Gouais Blanc cross (Vouillamoz). The name Molette could derive from the Savoie dialect word mol\u00e8ta, meaning \u2018soft\u2019, because of its tender berries.',
    ],
    'where its grown': [
      'Molette is grown almost exclusively in the Seyssel area of Savoie, eastern France, and produces wines that are generally pale and rather ordinary with relatively high levels of alcohol and acidity, and greatly improved by being blended with ALTESSE. It is officially recommended in the Ain and Haute-Savoie d\u00e9partements, authorized in Savoie and may be included in the sparkling wines of the Seyssel and Bugey appellations. Domaine de Vens-le-Haut make one of the very rare examples of still varietal wines which are labelled Allobrogie. The area given over to the variety continues to decline, to 28 ha (69 acres) in 2008.',
    ],
  },
  {
    name: 'MOLINARA',
    synonyms: ['Brepon', 'Mulinara', 'Uva del Mulino', 'Uva Sal\u00e0'],
    description:
      'Now plays distinctly third fiddle to Corvina Veronese and Rondinella in wines such as Valpolicella.',
    'origin and parentage': [
      'Molinara has been mentioned in the Verona area of the Veneto, northern Italy, since the early nineteenth century. Its name derives from the Italian word mulino, meaning \u2018mill\u2019, because the bloom on the surface of the berries looks like flour.',
    ],
    'where its grown': [
      'Molinara is cultivated mainly in the province of Verona, Italy, in particular around the Lago di Garda. Its wines are generally pale in colour, high in acidity and prone to oxidation. They therefore tend to play only a minor role in blends such as Valpolicella and Bardolino, where the DOC rules permit a maximum percentage of 10% but in practice it is usually a good deal less than that. Molinara used to play a more important role in Amarone and Recioto but the regulations have changed in recognition of the fact that CORVINA VERONESE, CORVINONE and RONDINELLA give better results. However, old vines of some of the older clones, such as those in the care of Cecilia Trucchi of Villa Bellini, can produce low yields of very high-quality fruit. Masi also have superior pre-phylloxera clones at their Serego Alighieri estate. The fruit from the newer clonal selections is not as good. Carlo Boscaini produces a varietal Molinara, classified as IGT Veronese and looking very much like a ros\u00e9. There were 1,348 ha (3,331 acres) in 2000 but the variety is losing ground to Corvina Veronese, Corvinone, Rondinella and international varieties.',
    ],
  },
  {
    name: 'MOLLARD',
    synonyms: ['Molard', 'Petit Mollard', 'Tallardier'],
    description:
      'Very minor variety making fresh, light reds in south-east France.',
    'origin and parentage': [
      'Mollard is a traditional variety of the Hautes-Alpes in south-east France. Its earliest mention appeared in Comte Odart (1854), but the text implies much earlier cultivation in the Hautes-Alpes (R\u00e9zeau 1997): \u2018le c\u00e9page le plus anciennement cultiv\u00e9 et plus commun encore dans ces vignes, c\u2019est le gros mollar noir\u2019 (\u2018the variety that has been cultivated the longest and is still the most common in these vineyards is gros mollar noir\u2019). DNA parentage analysis has shown that Mollard is most likely a progeny of GOUAIS BLANC (Boursiquot et al. 2004). The name Mollard could derive from the French mol, meaning \u2018soft\u2019, because of the texture of the berries.',
    ],
    'where its grown': [
      'Varietal wines tend to be fresh and light-bodied but well coloured. The main proponent in the Hautes-Alpes, the only d\u00e9partement in France in which this variety is recommended, is Marc Allemand at Domaine Allemand. He has been making varietal Mollard since 1984 and currently has two bottlings, one from old vines. He also works closely with Yann de Agostini at Domaine du Petit Ao\u00fbt, created in 2009 in the Vall\u00e9e de la Durance and focusing on local varieties such as Mollard. There were 23 ha (57 acres) in France in 2008.',
      'Mollard has recently been identified in an old vineyard in Sonoma, California.',
    ],
  },
  {
    name: 'MONARCH',
    synonyms: ['Freiburg 487-88'],
    description:
      'Dark, powerful recent German hybrid giving fruity and full-bodied wines.',
    'origin and parentage': [
      'The name Monastrell was mentioned by Francesc Eiximenis in Empord\u00e0, Catalunya, as early as 1381\u20136 (Fav\u00e0 i Agud 2001) and is also said to have been mentioned together with BOBAL among the most important grape varieties of the region of Valencia in 1460 in Jaume Roig\u2019s Espill o llibre de les dones. It derives from the Latin monasteriellu, a diminutive of monasteriu, meaning \u2018monastery\u2019, suggesting that the variety was first cultivated and propagated by monks (Fav\u00e0 i Agud 2001). Monastrell most likely originates from Sagunto in the Camp de Morvedre region in Valencia, eastern Spain, where it was the main variety before the arrival of phylloxera in the early twentieth century. Morvedre (Spanish Murviedro) was until 1868 the Catalan name for the town of Sagunto, an important wine port north of Valencia from the fifteenth century onwards. Outside Spain, Monastrell is often known as Matar\u00f3 (or Mataro), a name also derived from a town on the Mediterranean coast, between Barcelona and Valencia. (See Bobal for the confusion caused by the synonym Beni Carlo.)',
      'In France, Monastrell was probably introduced in the sixteenth century from Morvedre (now Sagunto) to Provence, hence the name Mourv\u00e8dre, and from Matar\u00f3 to Roussillon, where it kept the name Mataro, which is now also used in Australia and California (Ganzin 1901a; Lavignac 2001). However, the earliest written mention of Mourv\u00e8dre did not appear until the end of the eighteenth century (R\u00e9zeau 1997).',
      'Recent DNA kinship analysis has suggested that Monastrell and GRACIANO could be siblings (Santana et al. 2010) but any parent\u2013offspring relationship between those two can be rejected (Vouillamoz).',
    ],
    'where its grown': [
      'In Europe, the variety is typically grown within 80 km (50 miles) of the Mediterranean, where winters are mild and summers are generally long and hot. Its wines are particularly prone to reduction so great care has to be taken to expose them to oxygen during \u00e9levage. Monastrell is typically high in alcohol and tannins and may well have an intense aroma of blackberries.',
      'France has the second biggest plantings total with 9,363 ha (23,136 acres) of Mourv\u00e8dre in 2009, predominantly along the Mediterranean coast in Provence and Languedoc-Roussillon, where it is authorized in many different appellations. This is remarkable given that fifty years ago there were just 517 ha (1,278 acres) but the variety enjoyed a certain vogue in the late twentieth century, was widely regarded as a useful and ameliorating ingredient in blends throughout southern France, and much better-yielding clones became available. The biggest plantings are in the Var (2,198 ha/5,431 acres), which is the home of the Bandol appellation, the Aude (1,712 ha/4,230 acres) and the Vaucluse (1,494 ha/3,692 acres) but there are also substantial plantings in the Pyr\u00e9n\u00e9es-Orientales (974 ha/2,407 acres) and the Gard (898 ha/2,219 acres). Provence\u2019s most famous red wine, Bandol, is arguably Mourv\u00e8dre\u2019s finest expression. The variety must contribute 50\u201395% of the blend (the rest comprising any or all of Grenache/GARNACHA, CINSAUT, SYRAH and Carignan/MAZUELO). Recommended producers include Domaines Bunan, La Suffr\u00e8ne, Tempier and Ch\u00e2teau Pibarnon.',
      'It also plays a very significant structural role in southern Rh\u00f4ne blends \u2013 Ch\u00e2teau de Beaucastel, for example, are renowned for the high proportion of Mourv\u00e8dre (usually around 30%) in their red Ch\u00e2teauneuf-du-Pape and for their Hommage \u00e0 Jacques Perrin made mainly from old Mourv\u00e8dre vines. Other varietal wines are occasionally found in the Languedoc, from producers such as Borie de Maurel and Condamine l\u2019\u00c9v\u00eaque, but such examples are rare.',
      'Spain has by far the biggest area devoted to this variety, most usually called Monastrell in Spain nowadays: 63,244 ha (156,279 acres) in 2008, making it the fourth most planted red wine variety in the country and the fifth most planted overall, behind AIR\u00c9N, TEMPRANILLO, BOBAL and GARNACHA. Most of the vines are in the centre and south-east, in the autonomous communities of Murcia (33,710 ha/83,299 acres), Castilla-La Mancha (19,641 ha/48,534 acres) and Valencia (9,495 ha/23,463 acres). It is the principal black grape variety in DOs such as Alicante, Almansa, Jumilla, Valencia, and Yecla on or near the Mediterranean coast. Varietal versions are common in Spain and recommended producers include Casa Castillo, Hijos de Juan Gil, Luz\u00f3n and Silvano Garc\u00eda in Jumilla, Casta\u00f1o in Yecla, Molino y Lagares de Bulla in Bullas, Enrique Mendoza and Laderas de Pinoso in Alicante and Rafael Cambra in Valencia.',
      'It is also found to quite an extent on Cyprus (149 ha/368 acres in 2010) and to a very limited extent in Turkey (7 ha/17 acres in 2010).',
      'In California, it was long known as Mataro, and indeed it was not until the 1990s that many growers realized that the Mataro associated with rustic old plantings was identical to the modish Mourv\u00e8dre of southern France. Total plantings in the state were 939 acres (380 ha) in 2010 and it thrives particularly in the deep, sandy, infertile, largely dry-farmed vineyards of Contra Costa County, as well as in Madera and San Luis Obispo counties. Recommended producers include Bonny Doon, Cass, Cline, Qup\u00e9, Rosenblum and Tablas Creek.',
      'Mourv\u00e8dre plantings along with all things Rh\u00f4neish have been increasing in Washington State and had reached 165 acres (67 ha) by 2011. McCrae, Chateau Ste Michelle and Syncline make particularly respectable varietal examples. Plantings in Oregon go unrecorded officially but Torii Moor and a few others include Mourv\u00e8dre in their Rh\u00f4ne-style blends. Varietal Mourv\u00e8dre is also produced in Arizona, Texas and even Virginia.',
      'In Australia, too, the variety\u2019s stock went up considerably when it turned out that Mataro could be labelled Mourv\u00e8dre and that it could be blended with the country\u2019s generally even older stocks of Shiraz (SYRAH) and Grenache (GARNACHA) to make the popular and often well-balanced blends known colloquially as GSMs. In 2008, Australia grew a total of 784 ha (1,937 acres), mainly in hotter regions such as the Riverland, Barossa and Riverina, although there are scattered plantings elsewhere. Its most common expression is in fashionable Rh\u00f4ne blends with Shiraz and/or Grenache such as those produced by D\u2019Arenberg, Henschke, Charlie Melton, Rockford, Spinifex, Teusner and Torbreck. Hewitson is one of the few to produce a varietal version. Although Mourv\u00e8dre is now a sought-after ingredient in such table wines, it was once, and still is to a more limited extent, an important component in fortified wines such as Grant Burge\u2019s 30 Year Old Tawny and Seppeltsfield\u2019s Para 100 Year Old Tawny.',
      'Under the official name Mataro \u2013 though often called Mourv\u00e8dre on labels \u2013 there were 359 ha (887 acres) in South Africa in 2008. Producers of varietal wines include Beaumont and Signal Hill but it is much more common for Mourv\u00e8dre to be part of a blend, most often with SYRAH (Shiraz), as in those from De Morgenzon (DMZ), Lammershoek (Roulette), Newton-Johnson, Sadie Family (Columella), Sequillo and Tulbagh Mountain Vineyard.',
    ],
  },
  {
    name: 'MONASTRELL',
    synonyms: [
      'Alcayata',
      'Balzac Noir',
      'Catalan',
      'Espagnen',
      'Espar',
      'Garrut',
      'Gayata',
      'Mataro',
      'Matar\u00f3',
      'Mourvede',
      'Mourvedon',
      'Mourv\u00e8dre',
      'Negria',
      'Ros',
    ],
    description:
      'High-quality, heat-loving dark-skinned variety most valued for its heady, structured contribution to blends.',
    'origin and parentage': [
      'The name Monastrell was mentioned by Francesc Eiximenis in Empord\u00e0, Catalunya, as early as 1381\u20136 (Fav\u00e0 i Agud 2001) and is also said to have been mentioned together with BOBAL among the most important grape varieties of the region of Valencia in 1460 in Jaume Roig\u2019s Espill o llibre de les dones. It derives from the Latin monasteriellu, a diminutive of monasteriu, meaning \u2018monastery\u2019, suggesting that the variety was first cultivated and propagated by monks (Fav\u00e0 i Agud 2001). Monastrell most likely originates from Sagunto in the Camp de Morvedre region in Valencia, eastern Spain, where it was the main variety before the arrival of phylloxera in the early twentieth century. Morvedre (Spanish Murviedro) was until 1868 the Catalan name for the town of Sagunto, an important wine port north of Valencia from the fifteenth century onwards. Outside Spain, Monastrell is often known as Matar\u00f3 (or Mataro), a name also derived from a town on the Mediterranean coast, between Barcelona and Valencia. (See Bobal for the confusion caused by the synonym Beni Carlo.)',
      'In France, Monastrell was probably introduced in the sixteenth century from Morvedre (now Sagunto) to Provence, hence the name Mourv\u00e8dre, and from Matar\u00f3 to Roussillon, where it kept the name Mataro, which is now also used in Australia and California (Ganzin 1901a; Lavignac 2001). However, the earliest written mention of Mourv\u00e8dre did not appear until the end of the eighteenth century (R\u00e9zeau 1997).',
      'Recent DNA kinship analysis has suggested that Monastrell and GRACIANO could be siblings (Santana et al. 2010) but any parent\u2013offspring relationship between those two can be rejected (Vouillamoz).',
    ],
    'where its grown': [
      'In Europe, the variety is typically grown within 80 km (50 miles) of the Mediterranean, where winters are mild and summers are generally long and hot. Its wines are particularly prone to reduction so great care has to be taken to expose them to oxygen during \u00e9levage. Monastrell is typically high in alcohol and tannins and may well have an intense aroma of blackberries.',
      'France has the second biggest plantings total with 9,363 ha (23,136 acres) of Mourv\u00e8dre in 2009, predominantly along the Mediterranean coast in Provence and Languedoc-Roussillon, where it is authorized in many different appellations. This is remarkable given that fifty years ago there were just 517 ha (1,278 acres) but the variety enjoyed a certain vogue in the late twentieth century, was widely regarded as a useful and ameliorating ingredient in blends throughout southern France, and much better-yielding clones became available. The biggest plantings are in the Var (2,198 ha/5,431 acres), which is the home of the Bandol appellation, the Aude (1,712 ha/4,230 acres) and the Vaucluse (1,494 ha/3,692 acres) but there are also substantial plantings in the Pyr\u00e9n\u00e9es-Orientales (974 ha/2,407 acres) and the Gard (898 ha/2,219 acres). Provence\u2019s most famous red wine, Bandol, is arguably Mourv\u00e8dre\u2019s finest expression. The variety must contribute 50\u201395% of the blend (the rest comprising any or all of Grenache/GARNACHA, CINSAUT, SYRAH and Carignan/MAZUELO). Recommended producers include Domaines Bunan, La Suffr\u00e8ne, Tempier and Ch\u00e2teau Pibarnon.',
      'It also plays a very significant structural role in southern Rh\u00f4ne blends \u2013 Ch\u00e2teau de Beaucastel, for example, are renowned for the high proportion of Mourv\u00e8dre (usually around 30%) in their red Ch\u00e2teauneuf-du-Pape and for their Hommage \u00e0 Jacques Perrin made mainly from old Mourv\u00e8dre vines. Other varietal wines are occasionally found in the Languedoc, from producers such as Borie de Maurel and Condamine l\u2019\u00c9v\u00eaque, but such examples are rare.',
      'Spain has by far the biggest area devoted to this variety, most usually called Monastrell in Spain nowadays: 63,244 ha (156,279 acres) in 2008, making it the fourth most planted red wine variety in the country and the fifth most planted overall, behind AIR\u00c9N, TEMPRANILLO, BOBAL and GARNACHA. Most of the vines are in the centre and south-east, in the autonomous communities of Murcia (33,710 ha/83,299 acres), Castilla-La Mancha (19,641 ha/48,534 acres) and Valencia (9,495 ha/23,463 acres). It is the principal black grape variety in DOs such as Alicante, Almansa, Jumilla, Valencia, and Yecla on or near the Mediterranean coast. Varietal versions are common in Spain and recommended producers include Casa Castillo, Hijos de Juan Gil, Luz\u00f3n and Silvano Garc\u00eda in Jumilla, Casta\u00f1o in Yecla, Molino y Lagares de Bulla in Bullas, Enrique Mendoza and Laderas de Pinoso in Alicante and Rafael Cambra in Valencia.',
      'It is also found to quite an extent on Cyprus (149 ha/368 acres in 2010) and to a very limited extent in Turkey (7 ha/17 acres in 2010).',
      'In California, it was long known as Mataro, and indeed it was not until the 1990s that many growers realized that the Mataro associated with rustic old plantings was identical to the modish Mourv\u00e8dre of southern France. Total plantings in the state were 939 acres (380 ha) in 2010 and it thrives particularly in the deep, sandy, infertile, largely dry-farmed vineyards of Contra Costa County, as well as in Madera and San Luis Obispo counties. Recommended producers include Bonny Doon, Cass, Cline, Qup\u00e9, Rosenblum and Tablas Creek.',
      'Mourv\u00e8dre plantings along with all things Rh\u00f4neish have been increasing in Washington State and had reached 165 acres (67 ha) by 2011. McCrae, Chateau Ste Michelle and Syncline make particularly respectable varietal examples. Plantings in Oregon go unrecorded officially but Torii Moor and a few others include Mourv\u00e8dre in their Rh\u00f4ne-style blends. Varietal Mourv\u00e8dre is also produced in Arizona, Texas and even Virginia.',
      'In Australia, too, the variety\u2019s stock went up considerably when it turned out that Mataro could be labelled Mourv\u00e8dre and that it could be blended with the country\u2019s generally even older stocks of Shiraz (SYRAH) and Grenache (GARNACHA) to make the popular and often well-balanced blends known colloquially as GSMs. In 2008, Australia grew a total of 784 ha (1,937 acres), mainly in hotter regions such as the Riverland, Barossa and Riverina, although there are scattered plantings elsewhere. Its most common expression is in fashionable Rh\u00f4ne blends with Shiraz and/or Grenache such as those produced by D\u2019Arenberg, Henschke, Charlie Melton, Rockford, Spinifex, Teusner and Torbreck. Hewitson is one of the few to produce a varietal version. Although Mourv\u00e8dre is now a sought-after ingredient in such table wines, it was once, and still is to a more limited extent, an important component in fortified wines such as Grant Burge\u2019s 30 Year Old Tawny and Seppeltsfield\u2019s Para 100 Year Old Tawny.',
      'Under the official name Mataro \u2013 though often called Mourv\u00e8dre on labels \u2013 there were 359 ha (887 acres) in South Africa in 2008. Producers of varietal wines include Beaumont and Signal Hill but it is much more common for Mourv\u00e8dre to be part of a blend, most often with SYRAH (Shiraz), as in those from De Morgenzon (DMZ), Lammershoek (Roulette), Newton-Johnson, Sadie Family (Columella), Sequillo and Tulbagh Mountain Vineyard.',
    ],
  },
  {
    name: 'MONBADON',
    synonyms: ['Burger', 'Frontignan des Charentes'],
    description:
      'Productive, dull western French variety that once thrived in California\u2019s Central Valley.',
    'origin and parentage': [
      'Monbadon, from the Charente in western France, could well be named after the village of Monbadon in the Gironde, or from a family name common in western France. DNA parentage analysis has shown that Monbadon is probably a FOLLE BLANCHE \u00d7 TREBBIANO TOSCANO cross (seeCABERNET SAUVIGNON and PINOT pedigree diagrams), the latter being known in France under the name Ugni Blanc (Bowers et al. 2000).',
    ],
    'where its grown': [
      'In France, Monbadon is not on the official register of varieties and has virtually disappeared.',
      'Under the name Burger, this variety is much more widely planted in California than in its home in the Charente but the area of California vineyard given over to this workhorse, high-acid variety is much smaller than when in the mid twentieth century it produced more wine than any other variety in the state. There were 1,247 acres (505 ha) in 2008, cultivated mostly in the Central Valley for the production of jug wine. There have been few new plantings in the twenty-first century.',
      'There\u2019s a rare outpost of Monbadon in Australia: Pfeiffer Wines in Rutherglen, Victoria, use a blend of PALOMINO FINO and Monbadon in their Apera (sherry-like) wines. The vines responsible were in an experimental planting at Charles Sturt University that has now been grubbed up.',
    ],
  },
  {
    name: 'MONDEUSE BLANCHE',
    synonyms: ['Dongine', 'Jongin', 'Savouette'],
    description:
      'Savoie variety more famous as Syrah\u2019s parent than for its wines.',
    'origin and parentage': [
      'Mondeuse Blanche is an old variety from Savoie and Is\u00e8re in eastern France. It was first mentioned in 1843 in the catalogue of the grape collection of Bouchereau Fr\u00e8res at Ch\u00e2teau Carbonnieux (R\u00e9zeau 1997), and then in 1868 by the ampelographer Pierre Tochon: \u2018la mondeuse blanche est moins f\u00e9conde que la rouge\u2019 (\u2018mondeuse blanche is less fertile than the red one\u2019).',
      'It was long considered a white mutation of MONDEUSE NOIRE (see that entry for the etymology) but DNA profiling has disproven this hypothesis and parentage analysis has shown that Mondeuse Blanche spontaneously crossed with DUREZA to give birth to SYRAH (Bowers et al. 2000). More recently, Vouillamoz (2008) has shown that Mondeuse Blanche has parent\u2013offspring relationships with two varieties: Mondeuse Noire, which was already considered by ampelographers (eg Comte de Villeneuve 1901) to be very close to Mondeuse Blanche, and, more unexpectedly, VIOGNIER, the Condrieu variety (see Syrah for the complete pedigree).',
      'These DNA findings disprove Tochon\u2019s (1869) assertion: \u2018la mondeuse blanche est un plant isol\u00e9 en Savoie qui, ici pas plus qu\u2019ailleurs, n\u2019a form\u00e9 de famille\u2019 (\u2018mondeuse blanche is an isolated variety from Savoie that has not, here not more than elsewhere, generated a family\u2019). Despite its strong genetic links with the S\u00e9rine ampelographic group (Syrah, MONDEUSE NOIRE, VIOGNIER, etc.), Mondeuse Blanche has been placed in the Pelorsien ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'There were just 5 ha (12 acres) remaining in Savoie in 2008, mostly in Bugey, where it is authorized as a secondary variety in the Bugey and Vin de Savoie appellations. The wines it produces are not very exciting: generally soft with relatively high alcohol. However, Domaine Grisard, Le Caveau Bugiste and Domaine Les Tart\u00e8res all make varietal wines.',
    ],
  },
  {
    name: 'MONDEUSE NOIRE',
    synonyms: [
      'Gros Rouge',
      'Grosse Syrah',
      'Maldoux',
      'Persagne',
      'Petite Persaigne',
      'Plant Maldoux',
      'Savoyan',
    ],
    description:
      'Aromatic Savoie variety regaining recognition for its well-structured reds.',
    'origin and parentage': [
      'Mondeuse Noire is an old variety from the Dauphin\u00e9, a former province of eastern France that roughly comprises the Is\u00e8re, Dr\u00f4me and Hautes-Alpes d\u00e9partements, where its first mention dates back no further than 1845, in the Haut de la Vall\u00e9e de l\u2019Is\u00e8re (R\u00e9zeau 1997). However, its earliest mention most likely appeared in the Jura under the synonym Maldoux in an order of the Parliament of Besan\u00e7on dated 3 February 1731 (see ENFARIN\u00c9 NOIR for the citation). While Maldoux undoubtedly comes from the French mal doux, meaning \u2018badly sweet\u2019, referring to the bitter taste of the berries, two equally valid hypotheses have been proposed for the etymology of the name Mondeuse (R\u00e9zeau 1997): it could derive from the Franco-Proven\u00e7al dialect moda or moduse that was used to describe a grape producing a lot of must or it could come from the verb monder or \u00e9monder, meaning \u2018to prune\u2019, because Mondeuse Noire\u2019s leaves often fall before harvest.',
      'In California, Mondeuse Noire has long been confused with REFOSCO DAL PEDUNCOLO ROSSO but DNA profiling has shown them to be distinct varieties (see the Refosco dal Peduncolo Rosso entry). DNA parentage analysis has shown that Mondeuse Noire is either a progeny or a parent of MONDEUSE BLANCHE (see SYRAH for the complete pedigree), which means Mondeuse Noire is either a half-sibling or a grandparent of Syrah (Vouillamoz 2008). This explains why Mondeuse Noire is often called Grosse Syrah, and also why Mondeuse Noire belongs to the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009). In addition, Mondeuse Noire is ampelographically close to DOUCE NOIRE (Bisson 2009).',
      'Mondeuse Grise is a mutation of Mondeuse Noire described by ampelographer Victor Pulliat at the end of the nineteenth century. It was thought to have disappeared but was reintroduced to the Institut National de la Recherche Agronomique\u2019s vine collection at Domaine de Vassal, Montpellier, southern France, by Pierre Galet in 1950.',
    ],
    'where its grown': [
      'Mondeuse Noire produces wines that are aromatic, tannic and deeply coloured, with good ageing potential and, in the best sites, an Italianate bitter-cherry bite. It is one of the principal varieties authorized in the Vin de Savoie and Bugey appellations of Savoie, eastern France. Recommended producers include Michel Grisard at the Domaine du Prieur\u00e9 Saint-Christophe and Domaine Louis Magnin. Following a sharp decline in the 1970s, France had a total of 300 ha (741 acres) in 2009 and the area is now very slowly increasing in recognition of the potential quality of these wines \u2013 and their distinctiveness in the company of most Savoie reds.',
      'In the nineteenth century, Mondeuse Noire, under the name Gros Rouge, was the most planted red variety on the shore of Lac L\u00e9man (Lake Geneva) in Switzerland and even though there were fewer than 4 ha (10 acres) in the Vaud and Gen\u00e8ve cantons in 2009, there are still a few very good producers there, such as Henri Chollet at Domaine du Graboz (especially his Le Vin de Bacouni) and Jean-Fran\u00e7ois Cossy at Domaine des Rueyres, both in Vaud, and Damien et Luc Mermoud in Gen\u00e8ve.',
      'It is hard to get accurate planting statistics for California because, although the Foundation Plant Services at the University of California at Davis corrected the name of their selection from Refosco to Mondeuse in 2005, the official California grape acreage report of 2008 still refers to the varieties as synonymous. Carole Meredith at Lagier Meredith in Napa Valley (who made many exciting discoveries about grapevine DNA when she was a professor at the University of California at Davis) is probably the only producer who is growing the real thing (apart from a few vines in old, mixed vineyards). She reports that her Mondeuse vines planted alongside SYRAH in 2007 are less vigorous and more productive, and produce wine that is darker and spicier than Syrah.',
      'Some old vines at Carlisle Winery in California\u2019s Russian River Valley that were thought to be Mondeuse turned out to be Calzin, a Zinfandel (aka TRIBIDRAG) \u00d7 Mondeuse Noire cross obtained by Harold Olmo in 1937 (although at the time it was said to be a Zinfandel \u00d7 Refosco cross). Calzin, bred to add tannin to Central Valley wines but in practice overly productive and brutally tannic, has now been almost entirely grubbed up, as has its white mutation Helena.',
      'In the state of Victoria in Australia, both Buller (Rutherglen) and Brown Brothers (Milawa) include Mondeuse Noire in blends but the overall planted area is too small to make it into the official statistics. According to Brown Brothers\u2019 viticulturist Roland Wahlquist, Mondeuse has been planted at Milawa since 1907. They have 1.2 ha (3 acres) of late-ripening vines and co-ferment it with Shiraz (SYRAH) and CABERNET SAUVIGNON.',
      'Mondeuse Grise, also known as Persagne Grise, has been propagated at the Grisard vine nursery in Savoie.',
    ],
  },
  {
    name: 'MONEMVASSIA',
    synonyms: [
      'Artemissi',
      'Klossaria',
      'Monemvasia',
      'Monemvassitiko',
      'Monovassia',
    ],
    description:
      'Powerful, historic variety widely planted on P\u00e1ros but receiving renewed attention in the southern Pelop\u00f3nnisos (Peloponnese).',
    'origin and parentage': [
      'This variety probably comes from the area close to Monemvasia, an island and fortified medieval port town on an imposing rock in the southern Pelop\u00f3nnisos. The name of the port means \u2018single entrance\u2019, from the Greek moni emvassis (Boutaris 2000) as there is just one gate into the island-town, linked to the easternmost leg of the Pelop\u00f3nnisos by a bridge, having become an island as the result of an earthquake in AD 375.',
      'Monemvassia is believed to be one of the varieties that went into the once-famous blended sweet wine known as Malvasia that was favoured by the Venetians and widely acclaimed in the Middle Ages (Manessis 2000). The first written mention of this wine dates back to 1214 in \u00c9fesos (Ephesus) as Monovasia or Monemvasias (Arranz et al. 2008). However, there is certainly no consensus and so far no firm evidence as to the make-up of the blend.',
      'Although the name Malvasia is said to be a Venetian transliteration of Monemvassia (Boutaris 2000), the DNA profile of the variety Monemvassia (Greek Vitis Database) is not identical, nor even related, to any of the MALVASIA varieties (Vouillamoz).',
    ],
    'where its grown': [
      'Monemvassia is found mainly on the Kykl\u00e1des (Cyclades) islands as well as elsewhere in the Aegean and on the large island of \u00c9vvoia that lies parallel to southern mainland Greece (Lazarakis 2005). Although the most significant plantings are on the island of P\u00e1ros, it is in the southern Pelop\u00f3nnisos near the port that gave the variety its name that it is particularly thriving currently. George Tsibidis of Monemvasia Winery (one S) in particular aims to re-create the historical sweet blend Malvasia. Tsibidis brought his Monemvassia vines from P\u00e1ros and both he and Yiannis Vatistas have new plantings of the variety in Lakon\u00eda.',
      'The confusion over nomenclature does not stop there, since the regional wine of Monemvasia in Lakon\u00eda allows eight different varieties, including Monemvassia. On P\u00e1ros, in the middle of the Kykl\u00e1des, this variety plays an exclusive role in the appellation of the same name, and even the red version allows two thirds Monemvassia to one third MANDILARIA, the single exception to the rule that red and white varieties should not be blended for ros\u00e9 or red wines (Lazarakis 2005).',
      'Varietal Monemvassia tends to be relatively alcoholic, full-bodied and low in acidity, needing cooler sites to produce fresher, better-balanced wines. They can be spicy and lightly aromatic and show plenty of fruit depth on the palate. Producers of dry varietal wines include Moraitis in north-eastern P\u00e1ros and Tsibidis in Lakon\u00eda. For his sweet blend, Tsibidis picks the grapes early to retain acidity but he also makes a powerful, spicy, slightly smoky dry version.',
    ],
  },
  {
    name: 'MONERAC',
    synonyms: ['Mon\u00e9rac'],
    description: 'Fairly recent Montpellier cross yet to win favour.',
    'origin and parentage': [
      'A GARNACHA \u00d7 ARAMON NOIR cross obtained by Paul Truel in 1960 at INRA (Institut National de la Recherche Agronomique) in Montpellier, southern France. Monerac is a partial anagram of its progenitors\u2019 names.',
    ],
    'where its grown': [
      'Although this variety is included in the official register of varieties, and recommended in much of southern France, there do not as yet appear to be any commercial bottlings and there were just 3 ha (7 acres) recorded in 2008.',
    ],
  },
  {
    name: 'MONICA NERA',
    synonyms: [
      'Manzesu',
      'Monaca',
      'Monica di Sardegna',
      'Niedda de Ispagna',
      'Niedda Mora',
      'Nieddera Manna',
      'Pascale Sardu',
    ],
    description:
      'Generally undistinguished dark-skinned variety widely planted on the Italian island of Sardegna and made in a variety of styles.',
    'origin and parentage': [
      'Like many other Sardinian varieties, Monica Nera is said to come from Spain. During Arag\u00f3n\u2019s rule over the island (1323\u20131720), the Spanish could have introduced this variety under the name Morillo. However, no evidence of a historical or genetic link with Spain has yet been found. Others suggest that its cultivation dates back to the eleventh century, when the Camaldolese monks (monaci) began to plant vines and produce wine.',
    ],
    'where its grown': [
      'Monica Nera is cultivated all over Sardegna, Italy, for varietal wines in Monica di Sardegna (dry and still or sparkling) and Monica di Cagliari (dry, sweet or fortified) DOCs, or used in blends with GRACIANO (Bovale Sardo) and GARNACHA (Cannonau) in the Mandrolisai DOC and to a lesser extent in Campidano di Terralba DOC. The Italian agricultural census recorded total plantings of 2,909 ha (7,199 acres) in 2000. Unless yields are kept low, the wines are generally undistinguished and for early drinking. Recommended producers include Argiolas, Ferruccio Deiana, Alberto Loi, Santadi and Sardus Pater.',
    ],
  },
  {
    name: 'MONTEPULCIANO',
    synonyms: [
      'Africano',
      'Angolano',
      'Montepulciano Cordisco',
      'Montepulciano Spargolo',
      'Morellone',
      'Sangiovese Cordisco',
      'Uva Abruzzese',
    ],
    description:
      'Productive, deeply coloured, firmly structured and widely planted.',
    'origin and parentage': [
      'Although this variety\u2019s name refers to the Montepulciano region in the province of Siena in Toscana, its origin is more likely to be in Abruzzo in central Italy, possibly in the area of Torre de\u2019 Passeri. Because SANGIOVESE is widely grown in the Montepulciano area of Toscana, Montepulciano has often been considered a synonym for Sangiovese but this is contradicted by ampelographic observations and DNA profiling (Vouillamoz).',
    ],
    'where its grown': [
      'Montepulciano is a workhorse grape planted across central Italy, often alongside SANGIOVESE (which is the variety responsible for Vino Nobile di Montepulciano DOCG, which has nothing to do with the Montepulciano grape). Montepulciano\u2019s most well-known and often best-value manifestation is as the main or sole variety in Montepulciano d\u2019Abruzzo DOC. The creation of the more specific DOCG Montepulciano d\u2019Abruzzo Colline Teramane in the hills around Teramo in the northern part of Abruzzo in 2002 indicates an aspiration to higher quality but not necessarily its achievement. It is also the principal ingredient in Marche DOCs such as Rosso Conero (minimum 85%), Rosso Piceno (35\u201370%) and Offida (minimum 50%), and the variety is specifed in varying percentages for various DOCs in Molise and further south in Puglia. It is also planted further north in Umbria and Toscana but it ripens better in the warmer and sunnier conditions further south. There were 29,828 ha (73,707 acres) in Italy in 2000, according to the agricultural census.',
      'The best wines are deeply coloured with ripe, robust tannins, making them an ideal blending component with softer wines. Recommended producers of Montepulciano d\u2019Abruzzo include Cataldi Madonna, Contucci Ponno, Dino Illuminati, Lepore, Masciarelli, Villa Medoro, Emidio Pepe, Bruno Nicodemi, Valentini, Valle Reale and Zaccagnini.',
      'A wide variety of California producers, from Paso Robles to Lodi, make a Montepulciano and there were 74 acres (30 ha) in 2008.',
      'It is also planted to a very limited extent in Australia (eg by Amadio in the Adelaide Hills, who supply grapes to Montepulciano devotees Matt Gant at First Drop and Ben Riggs), in New Zealand, notably from Hans Herzog, and on Malta (6 ha/15 acres).',
    ],
  },
  {
    name: 'MONTILS',
    synonyms: ['Chalosse'],
    description: 'Charentais variety used in the production of cognac.',
    'origin and parentage': [
      'Montils is a variety from the Charente in western France that was first mentioned in Viala and Vermorel (1901\u201310). Its name comes from the village of Montils in the Charente-Maritime. DNA parentage analysis has shown that Montils is most likely a progeny of GOUAIS BLANC (Boursiquot et al. 2004). It is member of the Folle ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Montils is used in blended base wines for cognac and for local mistelles such as Pineau des Charentes in western France. After a dip in the 1970s and 1980s, the area planted to this variety in France was back up to 165 ha (408 acres). It does not seem to be made as a varietal table wine, although Lavignac (2001) notes that it was still found among old vines in Bordeaux\u2019s Entre-Deux-Mers vineyards in 1960.',
    ],
  },
  {
    name: 'MONTONICO BIANCO',
    synonyms: [
      'Chiappar\u00f9',
      'Ciapparone',
      'Greco Bianco del Pollino',
      'Greco del Pollino',
      'Mantonico Bianco Italico',
      'Montonico',
      'Pagadebit',
      'Racciapaluta',
      'Uva della Scala',
      'Uva Regno',
    ],
    description: 'Minor southern Italian white used mainly in blends.',
    'origin and parentage': [
      'The names Mantonico or Montonico are often applied to various varieties in southern Italy. Recent ampelographic studies and DNA profiling have shown that Montonico Bianco is identical to two Puglian reference samples (Pagadebit and Uva della Scala) and two Calabrian ones (Greco Bianco del Pollino and Mantonico Bianco Italico) but it is \u2013 surprisingly \u2013 distinct from Calabria\u2019s MANTONICO BIANCO (Schneider, Raimondi, De Santis and Cavallo 2008; Schneider, Raimondi et al. 2009). In addition, Montonico Bianco is not a white mutation of Mantonico Nero (aka CASTIGLIONE) and is distinct from Montonico di Rogliano (aka GUARDAVALLE; Schneider, Raimondi et al. 2009).',
      'Recent DNA parentage analysis has shown that Montonico Bianco (under the name Greco del Pollino) has a parent\u2013offspring relationship with GARGANEGA, one of the most widespread and ancient white varieties of Italy (Crespan, Cal\u00f2 et al. 2008). Since Garganega also has parent\u2013offspring relationships with eight other varieties (ALBANA, CATARRATTO BIANCO, DORONA DI VENEZIA, MALVASIA BIANCA DI CANDIA, MARZEMINA BIANCA, MOSTOSA, SUSUMANIELLO and TREBBIANO TOSCANO), Montonico Bianco is either their half-sibling or their grandparent (see GARGANEGA for the pedigree diagram).',
    ],
    'where its grown': [
      'Until the 1960s, Montonico Bianco was planted mainly on the Adriatic coast in central Italy: in Abruzzo, notably around Chieti, Sulmona and Teramo, and in the Marche, in particular around Macerata and Fermano. Since then plantings have decreased in central Italy and today the variety is more or less restricted to Teramo. Montonico Bianco is also found, under various synonyms (see above), in Puglia (provinces of Foggia and Bari) and Calabria (around Locri). It is authorized as part of the blend in Calabrian DOCs such as Bivongi, Donnici and Pollino and it not usually made as a varietal wine, although La Quercia in the province of Teramo do make one, classified as IGT Colli Aprutini. Librandi\u2019s Efeso is a barrel-aged example. At one time it was also grown for table grapes. There were 809 ha (1,999 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'MONT\u00d9',
    synonyms: ['Bianchetto Faentino', 'Bianchino', 'Montuni', 'Montuno'],
    description: 'Very late ripening Bolognese variety.',
    'origin and parentage': [
      'Mont\u00f9 has long been cultivated in the province of Bologna in Emilia-Romagna under various names. The name could derive from the local dialect molz\u2019u\u2019 (Italian molta uva), meaning \u2018many grapes\u2019, a reference to its productivity. DNA comparison has suggested a genetic relationship between Mont\u00f9 and BLAUFR\u00c4NKISCH, called Franconia in Italy (Filippetti, Silvestroni, Thomas and Intrieri 2001).',
    ],
    'where its grown': [
      'Mont\u00f9 is cultivated mainly in the provinces of Bologna and Ravenna in Emilia-Romagna in northern Italy. It is the dominant variety (minimum 85%) in the dry, sweet or sparkling wines of the Montuni version of Reno DOC. Total plantings in Italy in 2000 amounted to 1,160 ha (2,866 acres). The wines tend to have good acidity.',
    ],
  },
  {
    name: 'MONVEDRO',
    synonyms: ['Bastardo', 'Monvedro D\u00e3o'],
    description:
      'Minor, late-budding Portuguese variety causing confusion by its synonyms.',
    'origin and parentage': [
      'In Portugal, the name Monvedro is a variation on Bonvedro, which is commonly used as a synonym for PARRALETA in the Algarve. This explains why Truel in Galet (2000) suggested that Monvedro could be identical to Carcajolo Noir (ie Parraleta) on Corse and Caricagiola Nera on Sardegna, both being local synonyms for Parraleta. In D\u00e3o, Monvedro is the name of a distinct variety with a unique DNA profile in Veloso et al. (2010). It is therefore distinct from TROUSSEAU, even though they share the synonym Bastardo.',
    ],
    'where its grown': [
      'Monvedro is cultivated in Beiras, where it is authorized for regional but not for appellation wines. There were 8 ha (20 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'MOORE\u2019S DIAMOND',
    synonyms: ['Diamond'],
    description:
      'Minor American hybrid similar to, but less successful than, Concord and Niagara.',
    'origin and parentage': [
      'A CONCORD \u00d7 Iona hybrid obtained around 1870 by Jacob Moore, private breeder in Brighton, New York State, in which Iona is a cross between Diana (or possibly CATAWBA, also known as Diana in the US) and an unknown partner obtained in 1855 by C W Grant on Iona Island in New York State. It is therefore a Vitis\nlabrusca and Vitis\nvinifera hybrid. It was used to breed GOLDEN MUSCAT.',
    ],
    'where its grown': [
      'New York State had 93 acres (38 ha) of Moore\u2019s Diamond in 2006, mostly in the west of the state and used for sparkling blends and dry varietal wines (unusually for an American hybrid) as well as for the table. Although the plant is similar to CONCORD, the fruit is more like that of NIAGARA, but it is less successful than either. This may be partly due to the fact that the berry skins tend to crack in wet weather. It is also found to a very limited extent in New Hampshire, Pennsylvania, Virginia, Kentucky and the Midwest. Producers include Plymouth Winery in Seneca Lake, Fulkerson in Finger Lakes, Starr Hill in Pennsylvania, Wight-Meyer in Kentucky and Candia in New Hampshire. Wines are quite similar to those made from Niagara but are fresher and less foxy-tasting.',
    ],
  },
  {
    name: 'MORADELLA',
    synonyms: ['Cro\u00e0', 'Moranzana', 'Vermiglio'],
    description: 'Prized by growers but not recognized by officialdom.',
    'origin and parentage': [
      'Originally from around Pavia in Lombardia. A distinction is often made between three clonal variations of Moradella, with slightly different morphologies: Moradella Comune (or di San Colombano), Moradella Vermiglio (or dal Peduncolo Rosso) and Moradella Cro\u00e0.',
    ],
    'where its grown': [
      'Moradella is widespread in the Oltrep\u00f2 Pavese and more generally in the province of Pavia in Lombardia in northern Italy. It was once the most important variety in that area but its popularity has declined dramatically in favour of BARBERA, partly because of its susceptibility to powdery mildew and partly because it has never been included in the Italian national register of varieties, and is not therefore mentioned in any of the DOC regulations, though the former may explain the latter. It is now found only in old vineyards but is prized by growers and used incognito with Barbera and CROATINA in such blends as Buttafuoco and Sangue di Giuda, both within the Oltrep\u00f2 Pavese DOC. Fortesi have made an experimental 100% Moradella, classified as a table wine. Cantina Storica di Mont\u00f9 Beccaria, Il Santo and Fattoria Mondo Antico are also making valiant efforts to preserve the variety. The wines tend to be full-bodied and spicy with fairly high alcohol.',
    ],
  },
  {
    name: 'MORAVIA AGRIA',
    synonyms: ['Maravia Agria', 'Moravia', 'Moravio'],
    description:
      'Hitherto neglected Spanish variety contributing to at least one distinctively fresh, tannic red in the heat of Manchuela.',
    'origin and parentage': [
      'Moravia Agria is an obscure variety from eastern Castilla-La Mancha, central Spain. The name refers to its high acidity, agria meaning \u2018sour\u2019, to distinguish it from Moravia Dulce (dulce meaning \u2018sweet\u2019), the name given to MARUFO in Castilla-La Mancha.',
    ],
    'where its grown': [
      'Moravia Agria, found only in Spain, has in the past tended to languish anonymously in blends thanks to its pale colour and poor performance in the vineyard. However, it can contribute freshness and structure as well as attractive aromas ranging from red fruits through floral to wild herbs and grasses. In Manchuela, Juan Antonio Ponce has begun to co-ferment GARNACHA with Moravia Agria to produce Buena Pinta, a structured, earthy, garrigue-scented red.',
    ],
  },
  {
    name: 'MORETO DO ALENTEJO',
    synonyms: ['Morito'],
    description:
      'Popular but generally light and lacklustre red in southern Portugal more commonly but ambiguously known as Moreto.',
    'origin and parentage': [
      'As suggested by the name, this variety most likely originates in the Alentejo in south-eastern Portugal (Almadanim et al. 2007), where it is usually called simply Moreto. However, this name is given to several distinct varieties, and Regner et al. (1999) have shown that Moreto from D\u00e3o and Moreto from the Alentejo have distinct DNA profiles, the latter corresponding to Moreto in Almadanim et al. (2007) and in Veloso et al. (2010), hence our use of the longer names Moreto do Alentejo and Moreto do D\u00e3o (the latter no longer cultivated) to distinguish the two varieties. In addition, BAGA, CAMARATE and MALVASIA PRETA are often called Moreto or Moreto Something but their DNA profiles in Veloso et al. (2010) are all distinct from those of Moreto do Alentejo and Moreto do D\u00e3o in Regner et al. (1999).',
      'The Austrian variety BLAUER PORTUGIESER was once thought to be identical to Moreto but even if it is impossible to rule out the possibility that they have a common ancestor (Regner et al. 1999), their DNA profiles are completely distinct.',
    ],
    'where its grown': [
      'Moreto wines are generally light in colour and body with light, red-fruit aromas and best drunk young. The variety is popular in the Alentejo, usually as part of a blend with local and/or international varieties, since the wines can be sold before others are ready. Carmim (the Reguengos de Monsaraz co-operative), Espor\u00e3o and Roquevale all produce Alentejo blends that include Moreto do Alentejo. There were 1,152 ha (2,847 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'MORIO-MUSKAT',
    synonyms: ['Geilweilerhof I-28-30'],
    description:
      'Intensely grapey but rather flabby German cross that has had its day.',
    'origin and parentage': [
      'Morio-Muskat was initially introduced as a SILVANER \u00d7 Weissburgunder (PINOT BLANC) cross bred by Peter Morio at Geilweilerhof in 1928 yet in the Vitis International Variety Catalogue it is shown as a Silvaner \u00d7 MUSCAT BLANC \u00c0 PETITS GRAINS cross, which would better explain its name and its light Muscat aroma. However, analysis of the DNA profile published in Lamboy and Alpha (1998) contradicts both suggested parentages (Vouillamoz), so that Morio-Muskat\u2019s true parentage remains to be elucidated.',
    ],
    'where its grown': [
      'Authorized in Germany in 1956, Morio-Muskat was particularly popular in the mid 1970s as providing a way of adding a dash of Muscat-like perfume to an otherwise neutral cheap German blend, frequently labelled Liebfraumilch on export markets. Today only 502 ha (1,240 acres) remain \u2013 much less than its peak of around 3,000 ha (7,413 acres) \u2013 and almost all of those are in Rheinhessen and the Pfalz.',
      'When the grapes are fully ripe, the wines have an overpowering, almost sickly grapiness, but the variety requires a good site (which would probably be better used by a more noble variety) and sugar levels are never very high, although acidity is generally fresh. Producers include Huff in Rheinhessen, Baldau in Franken, Franz Braun in the Pfalz and Wehweck in Baden.',
      'It is also planted to a very limited extent in Austria and there are around 26 ha (64 acres) in South Africa. Japan grows about 1.5 ha (4 acres) of Morio-Muskat.',
    ],
  },
  {
    name: 'MORISTEL',
    synonyms: ['Concej\u00f3n', 'Juan Ib\u00e1\u00f1ez', 'Moristell'],
    description:
      'Makes light but distinctive reds in Somontano, north-east Spain.',
    'origin and parentage': [
      'Moristel most likely originates from Arag\u00f3n in north-east Spain, where it is sometimes erroneously considered to be a variation of MONASTRELL, or a synonym for Morrastel, itself a synonym for GRACIANO; these synonymies can be rejected by DNA profiling (Vouillamoz).',
    ],
    'where its grown': [
      'Varietal wines are generally quite light but with a distinctive perfume and flavour of loganberries and thus often blended with the fuller-bodied TEMPRANILLO, PARRALETA or even CABERNET SAUVIGNON. The variety is prone to oxidation and wines are best unoaked and drunk young. The variety is also well suited to ros\u00e9 production. Total plantings in 2008 amounted to 154 ha (381 acres) in Spain, found mainly in the provinces of Huesca and Zaragoza as one of the principal varieties in the Somontano DO, which is otherwise dominated by international interlopers. Producers include Pirineos and Vi\u00f1as del Vero.',
    ],
  },
  {
    name: 'MORNEN NOIR',
    synonyms: ['Chasselas Noir', 'Montruchon', 'Mornant', 'Mornerain Noir'],
    description:
      'Chasselas relative that might just be rescued from extinction.',
    'origin and parentage': [
      'Mornen Noir has long been cultivated in the Loire and in the Rh\u00f4ne but its first mention was not until 1872 by Pulliat (Rougier 1902a). Mornen Noir probably originates from the area between the Rh\u00f4ne and the Loire rivers and its name most likely derives from the canton of Mornant in the Rh\u00f4ne-Alpes region, where it used to be widely cultivated together with CHASSELAS (Rougier 1902a). Indeed, DNA profiling has confirmed that an obscure variety named Montruchon in the grape collection at Domaine Grisard in Savoie is identical to Mornen Noir (Vouillamoz) and DNA parentage analysis strongly suggests that Mornen Noir has a parent\u2013offspring relationship with Chasselas (Vouillamoz and Arnold 2009). This explains why Chasselas is called Mornen Blanc near M\u00e2con and why Mornen Noir is often called Chasselas Noir and has sometimes been wrongly thought to be a black mutation of Chasselas.',
    ],
    'where its grown': [
      'Mornen Noir is not on the register of varieties in France, is not therefore included in the official vineyard statistics and it does seem to be disappearing. However, a small parcel recently discovered in the commune of Chavanay, very close to the Rh\u00f4ne between Lyon and Valence, has given local growers in the Coteaux du Gier the hope that it can be recovered and eventually enter the register. In 2008 they also planted an experimental vineyard with both Mornen Noir and CHOUCHILLON.',
    ],
  },
  {
    name: 'MORONE',
    synonyms: [
      'Morone Farinaccio',
      'Mostaiola del Lapi',
      'Uva Moro',
      'Uva Morone Nera',
    ],
    description: 'Very minor Tuscan occasionally used to add colour to blends.',
    'origin and parentage': [
      'Morone is a Tuscan variety that has been recorded since the early nineteenth century.',
    ],
    'where its grown': [
      'Morone is cultivated to a very limited extent in the province of Massa-Carrara in northern Toscana, Italy, in particular in the communes of Pontremoli and Aulla. Some plantings also exist in Val d\u2019Arno in the province of Firenze but the total area in 2000 amounted to just 39 ha (96 acres). It is usually added to blends to improve their colour rather than made as a varietal wine.',
    ],
  },
  {
    name: 'MORRASTEL BOUSCHET',
    synonyms: ['Garnacho', 'Morrastel Bouschet \u00e0 Gros Grains'],
    description:
      'Nineteenth-century southern French cross now making tiny amounts of deep red wine.',
    'origin and parentage': [
      'A GRACIANO \u00d7 PETIT BOUSCHET cross obtained in 1855 by Henri Bouschet at the Domaine de la Calmette in Mauguio in the H\u00e9rault, southern France. It was named after Morrastel, a synonym for Graciano in south-eastern France, and after its breeder. DNA profiling has shown that the variety called Garnacho in Valladolid is identical to Morrastel Bouschet (Iba\u00f1ez et al. 2003; Mart\u00edn et al. 2003). In addition, Morrastel Bouschet is often confused with GRAND NOIR (Viala and Vermorel 1901\u201310), eg in the grape collection at the University of California at Davis (Vouillamoz).',
    ],
    'where its grown': [
      'Although there were only 7 ha (17 acres) remaining in France in 2008, this variety was once widely planted in the Languedoc and effectively usurped the better quality GRACIANO (Morrastel). It is not included in the official French register of varieties and so cannot be used in an appellation wine, which probably contributed to its uprooting in the 1980s. It produces deeply coloured wines and was appreciated in the past mainly for its ability to add colour to blends.',
    ],
  },
  {
    name: 'MOSCATELLO SELVATICO',
    synonyms: ['Moscato di Barletta'],
    description:
      'Rare, aromatic white for sweet wines usually from southern Italy.',
    'origin and parentage': [
      'Moscatello Selvatico is a Muscat-flavoured variety from Puglia in southern Italy. Comparison of the DNA profiles of Moscatello Selvatico (Crespan and Milani 2001) and Moscato di Barletta (Zulini et al. 2002) shows that they are identical. DNA parentage analysis has revealed that Moscatello Selvatico is possibly a natural BOMBINO BIANCO \u00d7 MUSCAT OF ALEXANDRIA cross (Crespan and Milani 2001; Cipriani et al. 2010). As a consequence, Moscatello Selvatico is a grandchild of MUSCAT BLANC \u00c0 PETITS GRAINS (Moscato Bianco) and Axina de Tres Bias (see the MUSCAT pedigree diagram).',
    ],
    'where its grown': [
      'Moscatello Selvatico is cultivated mainly on the Adriatic coast of Puglia in southern Italy, between Barletta and Monopoli, in particular in the region of Andria and Trani, where it is blended with MUSCAT BLANC \u00c0 PETITS GRAINS (Moscato Bianco) in the Moscato di Trani DOC. Moscatello Selvatico is also a minor component in the Moscadello di Montalcino DOC near Siena (though it was the dominant variety before phylloxera), producing still and fizzy sweet wines, sometimes late harvested. There were 118 ha (292 acres) in Italy in 2000. The wines have the characteristic grapey aroma and flavour of many Muscat varieties. Azienda Vitivinicola Speziale in Crispiano make a rare, dry, varietal passito version called I Ualani and classified IGT Tarantino.',
    ],
  },
  {
    name: 'MOSCATO DI SCANZO',
    synonyms: ['Moscatino di Scanzo'],
    description:
      'Rare, aromatic red exclusive to Bergamo used to make sweet passito wines and basking in the creation of its new DOCG.',
    'origin and parentage': [
      'Moscato di Scanzo most probably comes from Scanzorosciate in the province of Bergamo in Lombardia in northern Italy, where it is thought to have been planted since the mid fourteenth century, although the first reliable written reference to it was not until 1789. DNA parentage analysis has revealed a parent\u2013offspring relationship between Moscato di Scanzo and MUSCAT BLANC \u00c0 PETITS GRAINS (Moscato Bianco; Crespan and Milani 2001), making it a half-sibling or a grandchild of at least five other varieties that have parent\u2013offspring relationships with Muscat Blanc \u00e0 Petits Grains: ALEATICO (Moscatello Nero), MOSCATO GIALLO, MOSCATO ROSA DEL TRENTINO, MUSCAT OF ALEXANDRIA and the non-cultivated Muscat Rouge de Mad\u00e8re (see MUSCAT pedigree diagram).',
    ],
    'where its grown': [
      'This rare grape is cultivated almost exclusively in a very limited area around Scanzorosciate, north east of Milano, in the province of Bergamo, Italy, although it is also found just to the south in the hills between Seriate and Val Seriana. There were just 76 ha (188 acres) in Italy in 2000 but the creation of the Moscato di Scanzo DOCG in 2009 signifies a renewed interest in and respect for the variety. Wines within this DOCG and the Moscato Passito version of Valcalepio DOC are full-bodied, aromatic, sweet red passito wines made from 100% late-harvested Moscato di Scanzo grapes, dried off the vine for at least twenty-one days. Recommended producers include the Cantina Sociale Bergamasca, Castello di Grumello, Monzio Compagnoni and Tenuta degli Angeli.',
    ],
  },
  {
    name: 'MOSCATO DI TERRACINA',
    synonyms: ['Moscato di Maccarese'],
    description:
      'Recently rescued aromatic white from central Italy making a wide range of styles.',
    'origin and parentage': [
      'Moscato di Terracina is local to the province of Latina, between Napoli and Roma. It is morphologically similar to MUSCAT OF ALEXANDRIA but DNA profiling shows that they are distinct (Stella Grando and Jos\u00e9 Vouillamoz, unpublished data).',
    ],
    'where its grown': [
      'Widely cultivated in and around Terracina in the province of Latina in Lazio, central Italy, in the early twentieth century, Moscato di Terracina was on the verge of extinction some fifty years later until it was rescued a few decades ago. Previously labelled IGT Lazio, it was promoted to Moscato di Terracina DOC, or just Terracina, in 2007. The regulations require a minimum of 85% of this variety for the dry, amabile (sweet) and passito wines and 100% for the sparkling version (sweet or dry). The Italian agricultural census recorded total plantings of 292 ha (722 acres) in 2000.',
      'Today, only very few producers make varietal Moscato di Terracina wines, notably Sant\u2019Andrea, Terra delle Ginestre and Villa Gianna. The wines tend to be aromatic, with aromas of dried roses, and to taste of tropical fruits and apricots, though Terre Ginestra\u2019s two wines, Stellaria and Promessa, are both oaked.',
    ],
  },
  {
    name: 'MOSCATO GIALLO',
    synonyms: [
      'Fior d\u2019Arancio',
      'Goldenmuskateller',
      'Goldmuskateller',
      'Moscatel',
      'Moscato dalla Siria',
      'Moscato Sirio',
      'Muscat du Pays',
      'Muscat Vert',
      'Muscatedda',
    ],
    description:
      'Golden-berried Muscat variety of northern Italy making mainly passito-style wines.',
    'origin and parentage': [
      'DNA parentage analysis has revealed that Moscato Giallo has a parent\u2013offspring relationship with MUSCAT BLANC \u00c0 PETITS GRAINS (Moscato Bianco; Crespan and Milani 2001) and is therefore a half-sibling or a grandchild of at least five other varieties that have parent\u2013offspring relationships with Muscat Blanc \u00e0 Petits Grains (see MUSCAT pedigree diagram): ALEATICO (Moscatello Nero), MOSCATO DI SCANZO, MOSCATO ROSA DEL TRENTINO, MUSCAT OF ALEXANDRIA and the no longer cultivated Muscat Rouge de Mad\u00e8re. The origins of Moscato Giallo are more likely to be in northern Italy than in the Middle East.',
    ],
    'where its grown': [
      'In Italy, Moscato Giallo is mainly cultivated in Vallagarina (Lagertal in German) in the province of Trento and in Bolzano (under the name Goldmuskateller) in northern Italy, where varietal wines are allowed in the Trentino and Alto Adige DOCs. It is also used in the sparkling and passito Fior d\u2019Arancio wines within the Colli Euganei DOC, in the sweet and sparkling Moscato wines of the Corti Benedettine del Padovano DOC, both in the Veneto, and further east in the Friuli-Isonzo DOC. There were 360 ha (890 acres) in Italy in 2000.',
      'Varietal wines are usually golden in colour, sweetly aromatic, and grapey or musky, with only moderate acidity. Manincor produce an intensely aromatic dry wine, Nalles Magr\u00e9 and Produttori Merano produce archetypal passito styles.',
      'In Switzerland, Muscat vineyards in the Valais have recently been proven to be a field mix of MUSCAT BLANC \u00c0 PETITS GRAINS and Moscato Giallo, locally named Muscat du Pays (Spring et al. 2008).',
    ],
  },
  {
    name: 'MOSCATO ROSA DEL TRENTINO',
    synonyms: ['Mu\u0161kat Ru\u017ea Pore\u010dki', 'Rosenmuskateller'],
    description:
      'A minor, red-skinned northern Italian that produces rose-scented sweet wines.',
    'origin and parentage': [
      'The variety is first mentioned at the end of the nineteenth century and its name probably derives from the wine\u2019s aroma of roses rather than from the colour of the berries, which are pinkish red rather than light pink.',
      'A thorough study of various Muscat accessions in vine collections around the world has shown that three distinct varieties were called Moscato Rosa, one in Trentino, one in Breganze (in the province of Vicenza), and another in a collection in Belgrade. The accessions from Trentino and Breganze were distinct and did not match any other Muscat, while the Moscato Rosa from Belgrade was a colour mutation of MUSCAT BLANC \u00c0 PETITS GRAINS (Crespan and Milani 2001). Since the accession from Trentino is the only one with significant plantings, we have used the name Moscato Rosa del Trentino here. The same authors carried out a DNA parentage analysis and revealed that Moscato Rosa del Trentino has a parent\u2013offspring relationship with Muscat Blanc \u00e0 Petits Grains (Moscato Bianco), and is therefore a half-sibling or a grandchild of at least five other varieties that have parent\u2013offspring relationships with Muscat Blanc \u00e0 Petits Grains (see the MUSCAT pedigree diagram): ALEATICO (Moscatello Nero), MOSCATO DI SCANZO, MOSCATO GIALLO, MUSCAT OF ALEXANDRIA and Muscat Rouge de Mad\u00e8re (the last no longer cultivated).',
      'Surprisingly, Moscato Rosa del Trentino turned out to be identical to Mu\u0161kat Ru\u017ea Pore\u010dki from Croatia (Maleti\u00b4c et al.1999). This could support the old hypothesis that in the nineteenth century Moscato Rosa del Trentino was introduced to Caldaro in the Alto Adige via Dalmacija (Dalmatia).',
    ],
    'where its grown': [
      'Moscato Rosa del Trentino is cultivated mainly in the Alto Adige in northern Italy as Rosenmuskateller, where it must comprise at least 85% of Alto Adige Moscato Rosa/Rosenmuskateller DOC, and to a lesser extent in Trentino along the Adige River and in the Valle dei Laghi, where it comprises at least 80% of Trentino Moscato Rosa DOC. In both these regions, it produces mainly late-harvest and/or passito wines, even fortified in the latter. Moscato Rosa del Trentino is also found in Friuli, in particular as a varietal wine within the Friuli-Isonzo DOC and in various IGTs. Rare plants are found in Piemonte in the provinces of Alessandria and Tortona. Total plantings in Italy in 2000 were 99 ha (245 acres) but this probably includes both the Trentino and the Breganze varieties.',
      'These generally sweet, smooth red wines are marked by aromas of roses, sometimes spice. Recommended Italian producers include Riccardo Battistotti, Cantina Produttori Termeno, Franz Haas, Kettmeir, Alois Lageder (labelled Rosenmuskateller), Letrari, Maso Martis and Waldgries.',
      'There are some small experimental vineyards in Germany, planted eg by Fuchs in Fl\u00f6rsheim-Dalsheim in Rheinhessen and Wissing in the Pfalz, and also in W\u00fcrttemberg. A few growers in Austria are experimenting with the variety, including Kloster am Spitz in Purbach in Burgenland and Gerhard Kracher in Neusiedlersee, who uses the variety to make a TBA-style wine.',
      'As Mu\u0161kat Ru\u017ea Pore\u010dki (meaning \u2018Rose Muscat of Pore\u010d\u2019) it is planted to a very limited extent in Istra (Istria) in Croatia, where it was first known as Mu\u0161kat Ru\u017ea Omi\u0161ki (\u2018Rose Muscat of Omi\u0161\u2019).',
    ],
  },
  {
    name: 'MOSCHOFILERO',
    synonyms: ['Fileri', 'Fileri Mantineias', 'Moschophilero'],
    description:
      'Aromatic, high-acid Greek variety that has seen a surge in popularity and plantings in the last twenty years.',
    'origin and parentage': [
      'Moschofilero probably comes from the central western Pelop\u00f3nnisos (Peloponnese) in Greece, possibly from Mant\u00edneia in the prefecture of Arkad\u00eda, where it was allegedly mentioned as early as 1601 (Nikolau and Michos 2004).',
      'Moschofilero, like Fileri or Fileri Mantineias, is generally used to refer to a group of clones, some quite distinctive, that are also referred to by more specific names but all have the same DNA profile (Lefort and Roubelakis-Angelakis 2001). These include Asprofilero (white), Xanthofilero (yellow-skinned), Mavrofilero (dark-skinned) and Fileri Kokkino (red-skinned). The first two are very minor \u2013 less than 1% of the Mant\u00edneia vineyards \u2013 and the vast majority of vines are the pink/grey-skinned version (with extreme variations in skin colour, leaf shape, aroma intensity, berry size, etc.) that most people refer to simply as Moschofilero. According to Stefanos Koundouras, assistant professor of viticulture at the Aristotle University of Thessalon\u00edki, DNA analysis and clonal selection could make a great difference to the overall quality and typicality of the wines.',
      'Some writers use the name Fileri to refer to a table grape grown in Attik\u00ed, Il\u00eda and Z\u00e1gkli (Messin\u00eda) in the Pelop\u00f3nnisos. However, this may simply be a clone that has adapted to more fertile soils to produce higher yields and bigger berries (Stefanos Koundouras, personal communication).',
    ],
    'where its grown': [
      'This delicate, aromatic variety enjoyed a renaissance in the early 1990s, in the Pelop\u00f3nnisos, Greece, generally but in particular on the high plateau of Mant\u00edneia (600\u2013700 m above sea level) in the centre of the area, where the eponymous appellation requires at least 85% Moschofilero. Many growers will have several clones interplanted in the same vineyard though there is a move towards planting them separately in new vineyards. Wines are generally light-bodied, fresh and relatively low in alcohol \u2013 often around 11% \u2013 and in some years it is a struggle to ripen this variety fully given the altitude of the best sites and the risk of poor weather at harvest.',
      'However, some producers aim for a richer, more concentrated alternative style as well. Yiannis Tselepos, for example, makes a light, fresh, grapey and floral version from a mixed-clone vineyard as well as a more serious blanc de gris made from the darker-skinned Mavrofilero that has undergone lees stirring and is part barrel fermented. Apostolos Spiropoulos has a similar pair of wines in contrasting styles, Astala showing the influence of skin contact and some new oak in its increased weight and creamier texture, though his are both made from the Mavrofilero clone.',
      'Moschofilero is also used in the production of sparkling wines, to make ros\u00e9 and also sweet wines. It is found on many of the Ionian Islands and is useful for adding aroma and freshness to varieties such as SAVATIANO or RODITIS. It was the fourth most planted variety in western Greece in 2008, with a total of 486 ha (1,201 acres).',
      'Recommended producers include Anton-opoulos, Skouras, Spiropoulos and Tselepos.',
    ],
  },
  {
    name: 'MOSCHOMAVRO',
    synonyms: ['Moschato Mavro', 'Moschogaltso', 'Xinogaltso'],
    description: 'Minor Greek variety with good potential for ros\u00e9s.',
    'origin and parentage': [
      'Moschomavro, meaning \u2018Black Muscat\u2019, possibly comes from Makedon\u00eda in northern Greece. Genetic analysis (under the name Moschato Mavro) has shown that it is distinct from MUSCAT OF HAMBURG and that it is not a colour mutation of Moschato Aspro, the Greek name for MUSCAT BLANC \u00c0 PETITS GRAINS (Stavrakakis and Biniari 1998).',
    ],
    'where its grown': [
      'Moschomavro is found here and there in western and central Makedon\u00eda, Greece. Wines are generally light in colour, with medium to high alcohol, good acidity and aromatic potential. Voyatzis in Velvendos, western Makedon\u00eda, for example, blends Moschomavro with XINOMAVRO to make a spicy/floral dry ros\u00e9 and Diamantis in Siatista, also in western Makedon\u00eda, uses some Moschomavro in his CABERNET SAUVIGNON/MERLOT blend. Tsantali, on the other hand, make a varietal wine in eastern Makedon\u00eda that they describe as full of red fruit and aromas of cherry jam. Viticulturist Haroula Spinthiropoulou, who grows this variety on her Argatia estate, believes it has a bright future as a ros\u00e9 and also includes a small amount in her unoaked Xinomavro-based red blend.',
    ],
  },
  {
    name: 'MOSTOSA',
    synonyms: ['Empibotte Bianco', 'Pagadebit', 'Pagadebito'],
    description:
      'Minor white variety found on the Adriatic coast, occasionally made as a varietal wine under its synonym Pagadebit.',
    'origin and parentage': [
      'Mostosa is from the Adriatic coast of central Italy. Its name comes from mosto (Italian for \u2018must\u2019), a reference to the large amount of juice produced, which is confirmed by the synonyms Empibotte (\u2018barrel filling\u2019) and Pagadebito (\u2018debt paying\u2019), both also used misleadingly for other productive varieties.',
      'Recent DNA parentage analysis has shown that Mostosa (under the name Empibotte) has a parent\u2013offspring relationship with GARGANEGA, one of Italy\u2019s most widespread and ancient white varieties (Crespan, Cal\u00f2 et al. 2008). Since Garganega also has parent\u2013offspring relationships with eight other varieties (ALBANA, CATARRATTO BIANCO, DORONA DI VENEZIA, MALVASIA BIANCA DI CANDIA, MARZEMINA BIANCA, MONTONICO BIANCO, SUSUMANIELLO and TREBBIANO TOSCANO), Mostosa is either their half-sibling or their grandparent (see Garganega for the pedigree diagram).',
      'Cipriani et al. (2010) have recently suggested through DNA parentage analysis that Mostosa could be a Ragusano \u00d7 Uva Femmina natural cross but this is contradicted by Crespan, Cal\u00f2 et al. (2008) and is questionable because Ragusano is the name of at least three other varieties (MOSCATELLO SELVATICO, GRECO and MALVASIA DI LIPARI), and Uva Femmina, meaning \u2018female grape\u2019, is a vague name.',
    ],
    'where its grown': [
      'Mostosa is cultivated in central Italy, mainly on the Adriatic coast (south-east Emilia-Romagna, the Marche, Abruzzo and Puglia), and to a lesser extent in Liguria and Lazio. Total plantings in Italy in 2000 were 104 ha (257 acres). It may comprise 30\u201350% of the blend in Colli di Rimini, along with varieties such as TREBBIANO ROMAGNOLO and BIANCAME, and contribute in a much more minor way to Colli di Rimini Rebola. Trere\u2019s sparkling Pagadebit di Romagna DOC is a blend of 85% Mostosa with 15% CHARDONNAY, whereas Guarni\u2019s version of this DOC is still and, most unusually, made from 100% Mostosa.',
    ],
  },
  {
    name: 'MOUYSSAGU\u00c8S',
    synonyms: ['N\u00e9gret', 'Plant du Pauvre'],
    description:
      'Virtually extinct variety once producing astringent reds in southern central France.',
    'origin and parentage': [
      'Mouyssagu\u00e8s was mentioned as early as 1783\u20134 in Rodez in the Aveyron in central southern France (R\u00e9zeau 1997): \u2018Le Manval lanut, ou Moissagu\u00e9s, c\u2019est un plan mou qui produit en quantit\u00e9 mais de mauvaise qualit\u00e9\u2019 (\u2018Manval lanut, or Moissagu\u00e9s, is a dull variety that produces large quantities of poor-quality grapes\u2019). In the local dialect, its name literally means \u2018from Moissac\u2019, a village in the Tarn-et-Garonne where the variety most likely originated.',
    ],
    'where its grown': [
      'The variety used to be cultivated in the harsh uplands of the Aveyron in southern central France but less than 1 ha (2.5 acres) remained in 2008. It is in the official French register of varieties, recommended in the Cantal and Aveyron d\u00e9partements and mentioned in the appellation regulations as a possible component in Vins d\u2019Entraygues et du Fel and Vins d\u2019Estaing but that doesn\u2019t seem to have preserved it in the vineyard, perhaps because it does not seem to graft well, according to Galet (2000), and produces astringent wines.',
    ],
  },
  {
    name: 'MSKHALI',
    synonyms: [
      'Ararati',
      'At Uzyum',
      'Mashali',
      'Messchaly',
      'Mishali',
      'Msali',
      'Mschali',
      'Musrali',
      'Spitak Khagog',
    ],
    description: 'Armenia\u2019s most planted variety, used mainly for brandy.',
    'origin and parentage': [
      'Mskhali probably comes from the province of Ararat in Armenia.',
    ],
    'where its grown': [
      'Mskhali was the dominant wine-grape variety in Armenia at the start of the twenty-first century, planted mainly in the Ararat region and used mostly for the production of brandy, though it also produces dry whites with moderate alcohol and fruity dessert wines. Both Vedi Alco and MAP produce dry varietal wines.',
    ],
  },
  {
    name: 'MTSVANE KAKHURI',
    synonyms: [
      'Dedali Mtsvane',
      'Mamali Mtsvane',
      'Mcknara',
      'Mtsvane',
      'Mtsvane Kachuri',
      'Mtsvani',
      'Mtzvan\u00e9',
    ],
    description:
      'The more widespread of the Mtsvanes makes high-quality Georgian whites in both traditional and European styles.',
    'origin and parentage': [
      'Mtsvane Kakhuri, literally \u2018green from Kakheti\u2019, is a very old indigenous variety from the Kakheti region in south-eastern Georgia. Although it is often referred to simply as Mtsvane, it should not be confused with the genetically distinct GORULI MTSVANE from the Kartli region in southern central Georgia (Vouillamoz et al. 2006).',
    ],
    'where its grown': [
      'Mtsvane Kakhuri is used, sometimes blended with up to 15% RKATSITELI, to produce dry white wines within the Manavi appellation in Georgia on mainly calcareous soils. These typically have fresh citrus aromas, some tropical fruit notes, relatively high alcohol and crisp acidity. The variety is found mainly in the villages of Manavi and Tsinandali within the Kakheti region of south-eastern Georgia but also in Ukraine, Russia, the Republic of Moldova and Armenia. There were 240 ha (593 acres) in Georgia in 2004. Recommended producers of European-style wines include Badagoni, Telavi Wine Cellar and Teliani Valley; those who make varietal wines using the traditional clay qvevri include Pheasant\u2019s Tears and Vinoterra.',
    ],
  },
  {
    name: 'MUJURETULI',
    synonyms: ['Keduretuli'],
    description: 'Georgian variety used to produce dry and semi-sweet wines.',
    'origin and parentage': [
      'Mujuretuli comes from the Racha-Lechkhumi region.',
    ],
    'where its grown': [
      'Although Mujuretuli is often blended with ALEKSANDROULI (with which it is frequently interplanted) in the semi-sweet red wine Khvanchkara, it also has potential for decent dry reds. Planted mainly in Racha-Lechkhumi in northern Georgia, particularly in the villages of Khvanchkara, Chorjo, Sadmeli and Tola, totalling just 64 ha (158 acres) in 2004. Recommended producers include Bugeuil and Rachuli Wine.',
    ],
  },
  {
    name: 'M\u00dcLLER-THURGAU',
    synonyms: [
      'Findling',
      'Riesling-Silvaner',
      'Riesling \u00d7 Silvaner',
      'Rivaner',
      'Rizlingszilv\u00e1ni',
      'Rizvanec',
    ],
    description:
      'Prolific German invader of the world\u2019s vineyards producing soft, semi-aromatic whites in over-abundance.',
    'origin and parentage': [
      'A RIESLING \u00d7 Madeleine Royale cross developed in 1882 by Swiss vine breeder Hermann M\u00fcller (born in the canton of Thurgau, hence the name), who was working at the Geisenheim research centre in the Rheingau, Germany, at the time. M\u00fcller-Thurgau was used to breed several new crosses that are now in cultivation: ARNSBURGER, BACCHUS, FABERREBE, GUTENBORNER, KANZLER, M\u00cdLIA, MORAVA, OPTIMA, ORTEGA, P\u00c1LAVA, PANONIA, PERLE, REICHENSTEINER and W\u00dcRZER.',
      'This variety was originally recorded as a RIESLING \u00d7 SILVANER cross by its breeder, Hermann M\u00fcller, which explains the erroneous synonyms Riesling \u00d7 Silvaner and Rivaner still in use today. It was later named M\u00fcller-Thurgau by a German grape expert in honour of the breeder. A first DNA study claimed that M\u00fcller-Thurgau was in fact a Riesling \u00d7 CHASSELAS cross (Regner 1996), and a second one corrected the parentage to Riesling \u00d7 Chasselas de Courtiller, a table grape of minor importance bred in the nineteenth century by Courtiller in Saumur in France\u2019s Val de Loire (Sefc et al. 1997). A third and final DNA study established that M\u00fcller-Thurgau is in reality Riesling \u00d7 Madeleine Royale, the latter a nineteenth-century cross with unrecorded parents that is no longer cultivated (Dettweiler et al. 2000). DNA parentage analysis recently extended the pedigree by showing that Madeleine Royale is a PINOT \u00d7 SCHIAVA GROSSA cross (Vouillamoz and Arnold 2010). As a consequence, Pinot and Schiava Grossa are the grandparents of M\u00fcller-Thurgau, as is GOUAIS BLANC, which is a parent of Riesling (see the Schiava Grossa pedigree diagram).',
      'Findling is said to be a progeny of M\u00fcller-Thurgau but this has not yet been confirmed by DNA profiling. Its name means \u2018foundling\u2019 but it can also be used to refer to a seedling.',
    ],
    'where its grown': [
      'M\u00fcller-Thurgau has been the most successful German cross, quantitatively though not qualitatively, and in the decades after it was officially authorized in Germany, in 1956, it spread far and wide around the world\u2019s wine regions. Growers in cooler climates loved its prolific yields and the fact that it ripened almost anywhere.',
      'It is authorized in France but plantings are negligible. It is in the Alto Adige in northern Italy that the variety produces its most impressive results, far more than in Germany, particularly from old vines on steep, high stony sites such as Tiefenbrunner\u2019s Feldmarschall. The minerality, refinement and complexity of these wines demonstrate what can be achieved on prime sites and at lower yields. Recommended producers include Cantina San Michele Appiano, Manfred N\u00f6ssing, Josef Briegl, the Caldaro and Santa Maddalena co-operatives and Abbazia di Novacella. The variety is also planted in Trentino, where Pojer e Sandri virtually built their reputation on their unusually firm varietal version. It has not been a success in Friuli but can be found as far south as Emilia-Romagna. Total Italian plantings in 2000 were no more than 279 ha (689 acres). Surprisingly for a variety designed for cool climates, there are even experimental plantings in the Costers del Segre region in Spain.',
      'As Rivaner, it is still the most widely planted variety in Luxembourg, with a current total of 367 ha (907 acres) although there have been virtually no new plantings in the last ten years. It produces some of the lightest wine in Europe.',
      'The variety has had a chequered history in England, from its enthusiastic introduction in the 1950s as an early-ripening high yielder with low acidity, via a peak of 184 ha (455 acres) in 1990, when it was the most widely planted vine, down to its 2009 total of 70 ha (173 acres). Its percentage of the vineyard area diminishes with the rush to plant CHARDONNAY and PINOT NOIR for sparkling wines and the certainty that its vigour and susceptibility to disease make it a poor choice for a cool, damp climate. The best examples are fruity and soft, usually off dry; the worst are flabby and/or overly herbaceous.',
      'In Germany it was so popular that by the early 1970s M\u00fcller-Thurgau had overtaken RIESLING as Germany\u2019s most widely planted variety, underpinning what were then Germany\u2019s prodigious exports of bland, light white wine dismissed by some at the time as little more than sugarwater. Fortunately, the focus of German vintners has now shifted definitively back towards quality and M\u00fcller-Thurgau plantings were down to (a still considerable) 13,721 ha (33,905 acres) by 2008, compared with Riesling\u2019s 22,434 ha (55,436 acres). The fortunes of Liebfraumilch have mirrored those of the grape variety that supplied its dominant ingredient.',
      'It is planted throughout the German wine regions but with most vines in Rheinhessen, the Pfalz, Baden and Franken. Even in the Mosel-Saar-Ruwer area, generally much better suited to RIESLING, there are still 1,263 ha (3,121 acres). M\u00fcller\u2019s aim was to breed a less pernickety version of Riesling but it falls sadly short of the latter\u2019s potential greatness, especially when volume is the grower\u2019s goal. The wines tend to be lightly aromatic, even floral, with peachy or grapey, Muscat-like aromas (often assisted by a dollop of a more aromatic variety such as MORIO-MUSKAT), but most lack zip and can be unappetizingly flabby, particularly when sweetened with a little S\u00fcssreserve.',
      'Austria\u2019s 3,010 ha (7,438 acres), two thirds of which are in Nieder\u00f6sterreich (Lower Austria), are declining overall but are still significant, particularly in the Weinviertel, north of the Donau (Danube), where it produces inexpensive, early-drinking whites of limited interest. It is also used for much sweeter wines such as Winzerhof Gmeiner\u2019s TBA and Eiswein from Wagram.',
      'M\u00fcller-Thurgau is Switzerland\u2019s second most planted white variety, at 487 ha (1,203 acres), after CHASSELAS, planted mainly in the German-speaking cantons in the north and east.',
      'Further east, Hungary has 2,296 ha (5,674 acres) of Rizlingszilv\u00e1ni, grown just about everywhere from M\u00e1tra and Eger in the central north to Kuns\u00e1g in the south, with significant plantings also in the wine regions around Lake Balaton. As in Germany, the wines are generally soft and rarely exciting. Plantings in the Czech Republic are substantial and total 1,778 ha (4,394 acres), predominantly in the southern regions of Morava, where wines tend to be dry and crisper than average for the variety. Slovenia\u2019s 160 ha (395 acres) of Rizvanec are almost exclusively in \u0160tajerska Slovenija (Slovenian Styria) region in the north east. The Republic of\nMoldova had 173 ha (427 acres) in 2009, Russia 106 ha (262 acres). The wines are rarely exciting though are less likely to be heavily burdened by sugar than in Germany.',
      'M\u00fcller-Thurgau\u2019s spread has not been limited to Europe. In North America, California seems to have resisted its dubious charms but Oregon has 91 acres (37 ha), mostly in the Willamette Valley. Sokol Blosser were the first to plant in the early 1970s but they now use it as part of a blend. Chateau Benoit, on the other hand, proudly continue with a varietal version. It can be found to a very limited extent in Washington State.',
      'On advice from Helmut Becker of Geisenheim in Germany, it was welcomed with open arms in cool-climate New Zealand as an improvement on the hybrids that dominated there in the 1950s and 1960s. In the mid 1980s, it was New Zealand\u2019s most widely planted variety although the total area has been in decline for the last fifteen years or so and currently stands at just 79 ha (195 acres). The best examples show the freshness that typifies New Zealand whites, though styles are frequently off dry, as in Germany. They seem to last better in bottle than their German counterparts.',
      'M\u00fcller-Thurgau is also found in Japan (171 ha/423 acres in 2009, mainly in Hokkaido).',
      'Germany has 29 ha (72 acres) of the Findling that is thought to be a mutation of M\u00fcller-Thurgau. There\u2019s less than 1 ha (2.5 acres) of Findling in Switzerland, with two producers in the Gen\u00e8ve region. Findling is also planted to a very limited extent in England.',
    ],
  },
  {
    name: 'MUSCADELLE',
    synonyms: [
      'Bouillenc',
      'Guinlhan Musqu\u00e9',
      'Muscat Fou',
      'Sauvignon Vert',
      'Tokay',
    ],
    description:
      'Significant in Monbazillac, less so in sweet white bordeaux and very important in north-east Victoria.',
    'origin and parentage': [
      'Muscadelle is an old variety from the Gironde and the Dordogne in south-western France, where it was first mentioned in 1736 in Bordeaux and in Cadillac (R\u00e9zeau 1997). Contrary to the opinion of Michel Cazeaux-Cazalet in Viala and Vermorel (1901\u201310) and although it was named for its supposed Muscat flavour, Muscadelle is not related to any Muscat variety. Indeed, DNA parentage analysis has established a parent\u2013offspring relationship with GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram), which explains why Muscadelle is a member of the Gouais ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Planted mainly in Bordeaux and Bergerac in south-west France (885 ha/2,187 acres in the Gironde in 2009), there are also 438 ha (1,082 acres) in the Dordogne and 234 ha (578 acres) further south and east in the Tarn. It plays a supporting role in many an appellation \u2013 Bordeaux, Bergerac, Sauternes, Entre-Deux-Mers, etc. for dry wines and Sauternes, Barsac, Cadillac, Loupiac, etc. for sweet ones \u2013 generally contributing scent and youthful fruitiness to blends of SAUVIGNON BLANC and S\u00c9MILLON. Muscadelle is more highly regarded in Bergerac than in Bordeaux, especially in the sweet wines of Monbazillac. Overall, French plantings have been falling consistently, down from 6,257 ha (15,461 acres) in 1958. There are very few varietal wines but Ch\u00e2teau des Chapelains in Sainte-Foy Bordeaux and Ch\u00e2teau de Salettes make rare examples.',
      'In California, producers of varietal Sauvignon Vert include Michelini (Napa). The variety may also be included in white Meritage blends, though usually in very small amounts.',
      'The most successful and distinctive outpost of Muscadelle is Australia, where it contributes to very ordinary dry whites in the Riverland, for example, and yet produces stunning sweet, slowly oak-matured, fortified wines in north-east Victoria, as it has since the 1850s. These wines were once known as Liqueur Tokay (because the Muscadelle variety was originally thought to have been brought to Australia from Hungary) but were renamed Topaque as part of an Australia\u2013EU agreement, and to appease the Hungarians. Some of the finest exponents are Morris, Seppeltsfield, McWilliams, Chambers, Pfeiffer, Stanton & Killeen, and Campbells. Total plantings in Australia were just 155 ha (383 acres) in 2008. In the Barossa Valley, Peter Lehmann co-ferment a small percentage of Muscadelle in Mudflat Shiraz, a practice they began in the 1960s.',
    ],
  },
  {
    name: 'MUSCAT',
    synonyms: [
      'These are basically translations of Muscat',
      'rather than synonyms',
      'since the name Muscat is used for so many different varieties',
      'as explained below: Meski',
      'Misket',
      'Moscatel',
      'Moscato',
      'Moscatello',
      'Moschato',
      'Moschoudia',
      'Muskat',
      'Muskateller',
      'Muskatoly',
      'Muskot\u00e1ly',
    ],
    description: 'MUSCAT',
    'origin and parentage': [
      'The name Muscat, or Muscat Something, in various languages, is given to over 200 distinct and often unrelated white, pinkish or black-berried varieties grown in many different wine regions. These varieties are used as table and/or wine grapes and all have in common a more or less distinctive grapey Muscat flavour. However, this flavour can also appear by mutation in other varieties (eg Chasselas Musqu\u00e9 (see CHASSELAS), GEW\u00dcRZTRAMINER, etc.) so it is not necessarily a distinguishing characteristic for varieties with Muscat in their name.',
      'The name Muscat most likely derives from the musky aroma produced by a gland of the male musk deer from southern Asia that was used in the fifth century AD to make a rare fragrance. The Persian word muchk became moskos in Greek, muscus in Latin, musc in French and musk in English.',
      'The earliest mention of the name Muscat dates back to 1230\u201340 in the Liber de proprietatibus rerum by Bartholomaeus Anglicus, translated into French in 1372 by Jean Corbichon as Le livre des propri\u00e9t\u00e9s des choses: \u2018vin extrait de raisins muscats\u2019 (\u2018wine extracted from muscat grapes\u2019).',
      'Recent genetic research (Crespan and Milani 2001; Cipriani et al. 2010; see pedigree diagram overleaf) has shown that:',
      '\u2013\u00a0\u00a0\u00a0MUSCAT OF ALEXANDRIA is probably and quite unexpectedly a natural cross between MUSCAT BLANC \u00c0 PETITS GRAINS, a white variety grown all around the Mediterranean, and Axina de Tres Bias, an old black-berried table grape grown on Sardegna, Malta and the Greek islands.',
      '\u2013\u00a0\u00a0\u00a0MUSCAT OF ALEXANDRIA and MUSCAT BLANC \u00c0 PETITS GRAINS are the progenitors of at least fourteen grape varieties that are currently cultivated to make wine, of which nine are Italian and five are South American.',
      'MUSCAT OF ALEXANDRIA, MUSCAT BLANC \u00c0 PETITS GRAINS and their fourteen respective progenies clearly constitute a close family within the Muscat group as a whole even though that group is otherwise largely heterogeneous, making it somehow misleading to speak about the \u2018Muscat family\u2019 for all Muscat-flavoured varieties.',
    ],
  },
  {
    name: 'MUSCAT BAILEY A',
    synonyms: ['Muscat Bailey'],
    description:
      'Japanese hybrid with many an American gene, good disease resistance but producing candy-flavoured wines.',
    'origin and parentage': [
      'Muscat Bailey A is a hybrid between Bailey and MUSCAT OF HAMBURG obtained in 1927 by Zenbei Kawakami (1868\u20131944) at his Iwanohara Winery in the Niigata prefecture in Japan, in which Bailey is an Extra \u00d7 Triumph hybrid (see CASCADE for the pedigree of Triumph), Extra is a Big Berry \u00d7 Triumph hybrid, and Big Berry is a Vitis lincecumii vine found by Thomas Volney Munson (1843\u20131903) in the US. Muscat Bailey A is therefore a Vitis vinifera, Vitis labrusca and Vitis\nlincecumii hybrid that was developed to obtain a variety adapted to the Japanese climate.',
    ],
    'where its grown': [
      'Wines tend to be sweet-fruited, candy-like reds, with moderate acidity and soft tannins. Oak-aged wines from fully ripe grapes seem to achieve better balance. About one third of Japan\u2019s 2008 total of 135 ha (334 acres) is grown in Yamanashi prefecture, central Honshu, and it is also found in Yamagata, Osaka, Shimane and other prefectures. Recommended producers include Alps Wine and Iwanohara Wine.',
    ],
  },
  {
    name: 'MUSCAT BLANC \u00c0 PETITS GRAINS',
    synonyms: [''],
    description:
      'The classic Muscat, the small-berried one that is widely dispersed around the world.',
    'origin and parentage': [
      'Muscat Blanc \u00e0 Petits Grains is a very old variety that has been known for centuries under a vast number of synonyms throughout the Mediterranean belt and may come from either Italy or Greece, for the following reasons.',
      'The earliest mention of this variety most likely appeared in Italy in 1304 in Pietro de Crescenzi\u2019s Ruralium commodorum libri XII under the Latin name Muscatellus, later translated into Italian as Moscadella. It referred to a table grape grown around Bologna. Countless subsequent mentions, under local synonyms, have been recorded since the Middle Ages, illustrating the expansive distribution of this variety, for example: in 1394 in France, \u2018vin muscat de Claira\u2019 (\u2018Muscat wine from Claira\u2019), Claira being the name of a place near Rivesaltes where Muscat Blanc \u00e0 Petits Grains is still widely cultivated (Dion 1959); in 1513 in Spain under the name Muscatel; in 1534 in W\u00fcrttemberg, Germany, under the name Muscateller; in 1536 in Wallis, Switzerland, under the name Muscatelli; and so on.',
      'The general consensus is that Greece is the homeland of Muscat Blanc \u00e0 Petits Grains. The Greeks are said to have brought the variety to Italy, and from Italy the Romans would have introduced it to the region of Narbonne on France\u2019s Mediterranean coast, where it became famous under the names Muscat de Frontignan and Muscat de Lunel. What\u2019s more, DNA profiling strongly suggests that the Greek varieties Moschato Aspro, Moschato Kerkyras, Moschato Mazas, Moschato Spinas and Moschoudi, long cultivated in Greece, are all synonyms of Muscat Blanc \u00e0 Petits Grains (Hvarleva et al. 2004; Lefort et al. 2000; Stavrakakis and Biniari 1998; Vouillamoz).',
      'In a wide-ranging genetic study of 222 grape varieties from all over the world, Muscat Blanc \u00e0 Petits Grains (under the name Moschato Samou) was grouped together with several Greek varieties such as MALAGOUSIA, MOSCHOMAVRO (under the name Moschato Mavro) and VOLITSA MAVRI, and with some Italian varieties such as Malvasia Bianca (whose true identity is unknown; see MALVASIA) and ALEATICO (Aradhya et al. 2003).',
      'Recent DNA parentage analysis has surprisingly revealed that MUSCAT OF ALEXANDRIA is probably a natural cross between Muscat Blanc \u00e0 Petits Grains and Axina de Tres Bias, an old black-berried table-grape variety on Sardegna (Cipriani et al. 2010), also found in Greece under the name Eftakoilo or Heftakilo (Vouillamoz; see Muscat of Alexandria), which means that the cross could have occurred either in Italy or in Greece. This parentage places Muscat Blanc \u00e0 Petits Grains at the top of the Muscat pedigree. Indeed, DNA parentage analysis has shown that Muscat Blanc \u00e0 Petits Grains has parent\u2013offspring relationships with at least six other cultivated varieties of the Muscat group (see the pedigree diagram in the MUSCAT entry): ALEATICO (Toscana in Italy), MOSCATO GIALLO (northern Italy), MOSCATO ROSA DEL TRENTINO (Trentino in Italy), MOSCATO DI SCANZO (Lombardia in Italy), Muscat of Alexandria (southern Italy or Greece?) and Muscat Rouge de Mad\u00e8re (Italy?), the latter being a natural progeny of Muscat Blanc \u00e0 Petits Grains and MAMMOLO (Crespan and Milani 2001; Di Vecchi Staraz, This et al. 2007) and no longer commercially cultivated. Most remarkably, with the possible exception of Muscat of Alexandria, all of them originate from Italy. This could speak in favour of an Italian rather than a Greek origin for Muscat Blanc \u00e0 Petits Grains. Indeed, it has been known in Italy for several centuries under the name Moscato Bianco. Without further evidence, it is not possible to determine whether Greece or Italy is the true homeland of Muscat Blanc \u00e0 Petits Grains.',
      'Pink, red and black colour mutations of Muscat Blanc \u00e0 Petits Grains also exist around the world (Crespan and Milani 2001), under various names, eg Muscat \u00e0 Petits Grains Roses, Muscat \u00e0 Petits Grains Rouges, Moscato Rosa, Muscat d\u2019Alsace Rouge, Muscat Violet de Mad\u00e8re, Moscato Rosso, Moscatel Rosada, Moscatel Galego Roxo or Moscatel Roxo, and Brown Muscat.',
      'Muscat Blanc \u00e0 Petits Grains was used to breed MISKET VRACHANSKI and ZAGREI.',
    ],
    'where its grown': [
      'Muscat Blanc \u00e0 Petits Grains is the oldest and most distinguished of the Muscat Somethings but it is unfortunately also quite difficult to grow. Well-made wines, dry, sweet or sweet and fortified, have a distinctly grapey aroma which may be also delicately (sometimes pungently) floral and spicy.',
      'Muscat Blanc \u00e0 Petits Grains is by far and away the most widely planted Muscat in France, with 7,620 ha (18,829 acres) in 2009 compared with 2,610 ha (6,449 acres) of Muscat d\u2019Alexandrie (MUSCAT OF ALEXANDRIA). Unlike other Muscats, this smaller-berried version has seen consistently increased plantings in France, especially in Roussillon, and total vineyard area is currently more than three times what it was fifty years ago. In 1998 in Roussillon, plantings of Muscat d\u2019Alexandrie were higher than for Muscat Blanc \u00e0 Petits Grains. The increase in plantings of the latter is partly the result of the growing popularity for light, dry Muscat and the ability of Muscat Blanc \u00e0 Petits Grains to withstand dry vintages, but also thanks to the decline in production of traditional sweet, fortified vins doux naturels and the now-rescinded rule that Muscat de Rivesaltes vineyards had to be equally divided between the two Muscats. While dry Muscat is difficult to make well, a little drop of this aromatic liquid can be a very successful addition to more neutral whites. The produce of old Muscat vines in Roussillon has been used to good effect as an ingredient in complex blended dry whites.',
      'This finer Muscat is the third most planted white in Languedoc-Roussillon (5,737 ha/14,176 acres), the biggest plantings being in Roussillon in the Pyr\u00e9n\u00e9es-Orientales (3,043 ha/7,519 acres) and in the H\u00e9rault (2,234 ha/5,520 acres) in the eastern Languedoc but there are also significant plantings in the Dr\u00f4me, where it is the main, sometimes the sole, component in the lightly sweet and sparkling Clairette de Die. It\u2019s also the most widely planted Muscat on the French island of Corse (230 ha/568 acres), where it is the sole variety permitted for the vin doux naturel Muscat du Cap Corse, although it can also be included in dry white wines based on VERMENTINO. Sweet fortified wines in a similar style are made in many southern French appellations in Roussillon (Muscat de Rivesaltes) and in the Languedoc and southern Rh\u00f4ne (Muscats of Beaumes-de-Venise, Frontignan, Lunel, Mireval and Saint-Jean-de-Minervois). Most must be made exclusively from Muscat Blanc \u00e0 Petits Grains, although Beaumes-de-Venise may also include the red mutation Muscat \u00e0 Petits Grains Rouges.',
      'Italy recorded an impressive total of 13,280 ha (32,816 acres) in 2000, plus another 99 ha (245 acres) of the colour mutation Moscato Rosa. Much of this goes into the sweet and lightly sparkling Asti and the superior Moscato d\u2019Asti as well as other north-west Italian fizz, so it\u2019s not surprising that it\u2019s the most widely planted white wine variety in Piemonte with 10,328 ha (25,521 acres) and nearly 22% of the vineyard area in 2000. Further south, Moscato wines tend to appear in the richer Mediterranean style, often made from dried grapes and labelled passito. Further north, in the Valle d\u2019Aosta, the variety is known as Moscato di Chambave, named after the town very close to the Swiss border, and is responsible mainly for sweet passito-style wines. In Montalcino, Moscadello has its own DOC for dry, sparkling and sweet, late-harvest wines.',
      'In Spain, most wines labelled Moscatel are made from the generally inferior and less subtle MUSCAT OF ALEXANDRIA but Moscatel de Grano Menudo was still planted on 713 ha (1,762 acres) in 2008, mostly in Navarra and Catalunya in the north east. Chivite have had great success with their botrytized version from southern Navarra.',
      'In Portugal, there were 693 ha (1,712 acres) of Moscatel Galego Branco in 2010. Plantings in the Douro, most concentrated on the plateau around Alij\u00f3 and Favaios, east of Vila Real, are important for Moscatel de Favaios, rather like white port, and as a component of white port itself. According to Mayson (2003), Moscatel is still grown on the island of Madeira, though the grapes are today usually destined for the table rather than the vat. Moscatel Galego Roxo (or just Moscatel Roxo), which has purple-pink berries and is most probably a colour mutation of Moscatel Galego Branco, is found in the Douro and on the Pen\u00ednsula de Set\u00fabal (total plantings 62 ha/153 acres in 2010). Recommended producers of sweet, fortified varietal wines in Set\u00fabal include Bacalh\u00f4a Vinhos de Portugal, Jos\u00e9 Maria da Fonseca and Hor\u00e1cio Sim\u00f5es.',
      'Gelber Muskateller, as it is known in Germany, has a long history there, purportedly as far back as the twelfth century, but today has virtually disappeared, displaced in large part in the 1970s by the less noble but also less troublesome and higher-yielding and intensely aromatic MORIO-MUSKAT (no relation and now also in decline). The 174 ha (430 acres) that remained in 2008 are found mostly in Baden and the Pfalz, where M\u00fcller-Catoir made some fine, pungent dry wines until the turn of the century. The variety seems to earn greater respect in Austria, where its existence is documented back to 1400. Total plantings have risen from 143 ha (353 acres) in 1999 to 422 ha (1,043 acres) in 2007. The generally dry, light, aromatic wines are particularly successful in the S\u00fcdsteiermark (South Styria), although Muskateller is also planted here and there in the regions along the Donau (Danube) known collectively as Nieder\u00f6sterreich (Lower Austria), and it contributes to some fine sweet wines in Burgenland.',
      'Although Switzerland has only a small area of Muscat Blanc \u00e0 Petits Grains (49 ha/121 acres in 2009), it can boast a varietal wine made from the red mutation: Cave Labuthe\u2019s fresh and aromatic L\u2019Extase, from the Valais.',
      'Slovenia\u2019s 403 ha (996 acres) of Rumeni Mu\u0161kat are planted predominantly in \u0160tajerska Slovenija (Slovenian Styria), near the border with Austria\u2019s S\u00fcdsteiermark, where they tend to produce aromatic, soft and deeply coloured wines that need drinking relatively early. They can be thin in more difficult years. The variety is popular in Slovenia for both the table and the fermentation vat.',
      'Although several synonyms are used in Hungary, the prime name here is S\u00e1rga Muskot\u00e1ly, meaning \u2018yellow Muscat\u2019, since it is distinguished by the deep yellow colour of its skins. Said to be the most ancient extant variety in the Carpathian Basin (Roh\u00e1ly et al. 2003), it was cultivated on 647 ha (1,599 acres) of Hungarian vineyard in 2008, mostly in Tokaj, where it tends to play a small part in the region\u2019s illustrious sweet wines, alongside FURMINT and H\u00c1RSLEVEL\u0170. It is occasionally given a soliloquy, dry or sweet, by the likes of J\u00e1nos \u00c1rvay, Zolt\u00e1n Demeter and Kir\u00e1lyudvar. Dry wines can be fresh and intensely aromatic but tend to age quickly.',
      'In other parts of Eastern Europe, MUSCAT OTTONEL holds sway but there are limited plantings in Romania, where it is known as T\u0103m\u00e2ioas\u0103 Alba, in Serbia as Tamjanka, and in Bulgaria and Russia under the name Tamyanka. The quality and longevity of wines labelled Massandra White Muscat and Pink Muscat produced early last century in Krym (Crimea), Ukraine, suggest that they must have been made from Muscat Blanc \u00e0 Petits Grains; in 2009, Ukraine had 674 ha (1,665 acres). There were 133 ha (329 acres) of Bornova Misketi in Turkey in 2010.',
      'Greece\u2019s strict appellation controls on its most highly classified sweet Moschato wines (sometimes also fortified) from the islands and from the northern Pelop\u00f3nnisos insist on 100% Muscat Blanc \u00e0 Petits Grains, under the various synonyms listed above, for the Muscats of Kefaloni\u00e1, L\u00edmnos, P\u00e1tra, Rio of P\u00e1tras, R\u00f3dos and, probably the most famous, Muscat of S\u00e1mos. Here the vines are grown up to 800 m (2,600 ft) above sea level, often on steep hillside terraces, and the co-operative has a monopoly. A delicate dry version as well as the sweet one is made on the island of L\u00edmnos. Parparoussis make a very good example of the sweet Muscat Rio of P\u00e1tras and Antonopoulos\u2019 Amydalies is a delicate dry Muscat (classified as a regional wine of Acha\u00ef\u00b4a) that is particularly impressive given the young age of the vines. Boutaris\u2019 Moschato Spinas from Kr\u00edti (Crete) is a complex, silky, full-bodied and highly aromatic sweet wine made from sun-dried grapes that spends a few months in old oak.',
      'It is also found in Israel and on Malta.',
      'MUSCAT OF ALEXANDRIA is very much more important than this finer Muscat in California, where most of Muscat Blanc\u2019s 1,908 acres (772 ha) are in the central San Joaquin Valley (2010). Once variously called Muscat Frontignan and Muscat Canelli, it is now officially called Muscat Blanc and distinguished from Orange Muscat (MUSCAT FLEUR D\u2019ORANGER). There were 177 acres (72 ha) of Muscat Canelli in Washington State in 2011.',
      'There are minor plantings in Argentina, Chile and Uruguay, although Chile has more of the pink-berried mutation Moscatel Rosada.',
      'Liqueur Muscat is a magnificent speciality of Australia, made especially around Rutherglen in north-west Victoria, from the mutation known as Brown Muscat, or Muscat \u00e0 Petits Grains Rouges. There were 237 ha (586 acres) of this darker-berried version in Australia in 2008 and 263 ha (650 acres) of the pale-skinned Muscat Blanc \u00e0 Petits Grains, which is generally used for lighter sweet wines. Liqueur Muscat is an extremely sweet, dark, fortified wine long aged in old wood that becomes increasingly complex with age. Some of the finest exponents are Chambers, Morris, Seppeltsfield, and Stanton & Killeen. Muscat is also found in New Zealand but the official statistics are less than helpful, reporting 135 ha (334 acres) in 2008 for \u2018Muscat varieties\u2019, which does not necessarily mean this one.',
      'South Africa\u2019s Muscat de Frontignan, also known as Frontignac, and as Muscadel or Muskadel on the domestic market to avoid confusion with MUSCADELLE, was planted on 668 ha (1,651 acres) in 2008. It was responsible for a good proportion of the famous sweet wines of Constantia in the eighteenth and nineteenth centuries and may well have been planted in the Cape as early as 1659. Its most famous guise today is Klein Constantia\u2019s sweet Vin de Constance, an attempt to re-create those historic wines, made from 100% Muscat Blanc \u00e0 Petits Grains shrivelled on the vine in the Constantia region. Both pale- and dark-skinned versions are found and used mainly for dessert and fortified wines, the latter especially when the vines are planted in hotter inland regions such as Robertson. Boplaas of Klein Karoo is one of the most successful producers of sweet, strong Muscat both pale and dark.',
    ],
  },
  {
    name: 'MUSCAT BLEU',
    synonyms: ['Garnier 83/2', 'Muscat Bleu Garnier'],
    description:
      'Minor Swiss hybrid valued particularly by organic growers for its Muscat-like aroma and disease resistance but more commonly grown for the table.',
    'origin and parentage': [
      'Muscat Bleu is a complex Garnier 15-6 \u00d7 Perle Noire hybrid obtained in the 1930s (Dupraz and Spring 2010) by producer and grape breeder Charles Garnier in Peissy in the canton of Gen\u00e8ve, Switzerland, in which:',
      '\u2013\u00a0\u00a0\u00a0Garnier 15-6 is a Villard Noir \u00d7 M\u00dcLLER-THURGAU hybrid',
      '\u2013\u00a0\u00a0\u00a0Villard Noir (or Seyve-Villard 18-315) is a CHANCELLOR \u00d7 Sub\u00e9reux hybrid (see PRIOR for the complete pedigrees)',
      '\u2013\u00a0\u00a0\u00a0Perle Noire (or Seyve-Villard 20-347) is a Teneron \u00d7 Seyve-Villard 12-358 hybrid, where Teneron is a table grape from Spain and Seyve-Villard 12-358 is a Seibel 6468 \u00d7 Sub\u00e9reux hybrid (see HELIOS for the pedigree of Seibel 6468).',
    ],
    'where its grown': [
      'Muscat Bleu is grown mainly by amateur growers in Switzerland for the table but there are also a few producers of commercial wines, including Biolenz, Diroso and Bruno Martin, with a total of less than 3 ha (7 acres) in 2009. Wines are generally soft and grapey. It\u2019s also found in Belgium.',
    ],
  },
  {
    name: 'MUSCAT FLEUR D\u2019ORANGER',
    synonyms: [
      'Chasselas Fleur d\u2019Orange',
      'Cranford Muscat',
      'Madarski Muskat',
      'Mirisavka',
      'Moscatel Gordo Peludo',
      'Moscato Fior d\u2019Arancio',
      'Muscat Croquant',
      'Muscat de J\u00e9sus',
      'Muscat d\u2019Espagne',
      'Muscat of Hungary',
      'Muscat Queen Victoria',
      'Muscat Regnier',
      'Muscat Vengerskii',
      'Orange Muscat',
      'Orange Muskat',
      'Primavis Muscat',
      'Raisin Vanille',
      'Vanilia Muskotaly',
      'Weisse Vanillentraube',
    ],
    description:
      'Highly aromatic, orange-flavoured variety of unknown geographical origin but definitely related to Muscat Blanc \u00e0 Petits Grains.',
    'origin and parentage': [
      'The parentage of this old variety was unknown until DNA parentage analysis revealed that Muscat Fleur d\u2019Oranger is derived from a cross between CHASSELAS and MUSCAT BLANC \u00c0 PETITS GRAINS (Schneider, Torello Marinoni and Crespan 2008). It is not known where the crossing took place. Muscat Fleur d\u2019Oranger was used to breed CSABA GY\u00d6NGYE.',
    ],
    'where its grown': [
      'In Italy, Moscato Fior d\u2019Arancio, authorized for dry, passito and sparkling wines in the Colli Euganei in the Veneto, has a more floral, orange-blossom character than the rich orange flavours that this variety yields in California.',
      'California had 232 acres (94 ha) in 2009, mostly in the Central Valley but also in Amador County. The fact that it ripens about two weeks later than MUSCAT BLANC \u00c0 PETITS GRAINS means that it is less well suited to cooler sites. Andrew Quady makes two Orange Muscat varietal wines: Essencia, a lightly fortified 100% Orange Muscat aged briefly in oak with strong orange and apricot flavours, and the much lighter Electra, with peach flavours and low alcohol. According to Quady, Orange Muscat has an unusually high level of phenolics, sometimes giving a tea-like aftertaste and a darker colour if the juice is left on the skins for any length of time. Other producers of varietal wines include Swanson in Napa and Barnard Griffin in Washington State. It is also planted to a limited extent in British Columbia, Canada. Cerelia is just one of several producers.',
      'In Australia in 2010 there were seven producers of this variety though the actual area under vine is not recorded. The most influential proponent is Brown Brothers, who have 27 ha (67 acres) of Orange Muscat and whose popular, delicately aromatic sweet wine is a blend of 80% Orange Muscat with 20% FLORA. Other Victorian producers of sweet wines predominantly or wholly made from Orange Flora include Goorambath and Monichino; Amulet and St Leonards produce dry versions.',
    ],
  },
  {
    name: 'MUSCAT OF ALEXANDRIA',
    synonyms: [
      'Acherfields Early Muscat',
      'Albillo di Toro',
      'Aleksandrijski Muskat',
      'Alexandriai Muskot\u00e1ly',
      'Angliko',
      'Apostoliatiko',
      'Argelino',
      'Daroczy Musko',
      'Gordo',
      'Gordo Zibibo',
      'Hanepoot',
      'Iskendiriye Misketi',
      'Lexia',
      'Malaga',
      'Moscatel',
      'Moscatel Bianco',
      'Moscatel Blanco',
      'Moscatel de Alejandr\u00eda',
      'Moscatel de Chipiona',
      'Moscatel de Grano Gardo',
      'Moscatel de M\u00e1laga',
      'Moscatel de Set\u00fabal',
      'Moscatel Gordo',
      'Moscatel Gra\u00fado',
      'Moscatel Romano',
      'Moscatellone',
      'Moscato d\u2019Alessandria',
      'Moscato di Pantelleria',
      'Moscato Francese',
      'Moschato Alexandrias',
      'Moschato Limnou',
      'Muscat \u00e0 Gros Grains',
      'Muscat Bowood',
      'Muscat d\u2019Alexandrie',
      'Muscat de Berkain',
      'Muscat de Fandouk',
      'Muscat de Raf-Raf',
      'Muscat de Rome',
      'Muscat El Adda',
      'Muscat Gordo Blanco',
      'Muscat Grec',
      'Muscat Romain',
      'Salamanca',
      'Seralamanna',
      'T\u0103ma\u00eeioas\u0103 de Alexandria',
      'White Hanepoot',
      'Zibbibo',
      'Zibibo',
      'Zibibbo',
    ],
    description:
      'Light-skinned Mediterranean variety generally considered inferior to Muscat Blanc \u00e0 Petits Grains, used mostly to produce very sweet wines.',
    'origin and parentage': [
      'Muscat of Alexandria is a very old variety (as witness all those synonyms) that has for centuries been cultivated in several Mediterranean countries, both for the table and for wine. Despite its name, it is unlikely that Muscat of Alexandria originated in the city of Alexandria in Egypt, as many have assumed (Galet 2000; Nikolau and Michos 2004). There is far more historical and genetic evidence to suggest that the variety comes either from what was known as Magna Graecia (southern Italy, Sardegna, Sicilia) or from Greece:',
      '\u2013\u00a0\u00a0\u00a0The name Muscat of Alexandria first appeared in Paris in 1713 (Molon 1906) but was already known in Sicilia in the sixteenth century under the name Zibibo, as documented by Pietro Andrea Mattioli (1563).',
      '\u2013\u00a0\u00a0\u00a0The name Zibibo or Zibibbo possibly derives from the Arabic zab\u012bb, meaning \u2018raisin\u2019, which might suggest that this name was given during the rule of Arabic-speaking Carthaginians over the north-western part of Sicilia (sixth to third centuries BC).',
      '\u2013\u00a0\u00a0\u00a0In Greece, Muscat of Alexandria is still cultivated under the name Moschato Limnou (ie \u2018of L\u00edmnos\u2019), a clone of Muscat of Alexandria according to Stavrakakis and Biniari (1998).',
      '\u2013\u00a0\u00a0\u00a0In a genetic study of 222 grape varieties from all over the world, Muscat of Alexandria was grouped together with several Greek varieties such as MALAGOUSIA, MOSCHOMAVRO (under the name Moschato Mavro) and VOLITSA MAVRI, and with some Italian varieties such as Malvasia Bianca (whose true identity is unknown) and ALEATICO (Aradhya et al. 2003).',
      '\u2013\u00a0\u00a0\u00a0In southern Italy, two old, natural crosses have involved Muscat of Alexandria (see the pedigree diagram in the MUSCAT entry): GRILLO from Sicilia is a progeny of CATARRATTO BIANCO and Muscat of Alexandria (Di Vecchi Staraz, This et al. 2007) and MOSCATELLO SELVATICO from Puglia is a progeny of BOMBINO BIANCO and Muscat of Alexandria (Cipriani et al. 2010). Two other varieties from further north in Italy are the result of natural crosses involving Muscat of Alexandria and SCHIAVA GROSSA: MALVASIA DEL LAZIO and MUSCAT OF HAMBURG (Crespan 2003; Lacombe et al. 2007).',
      '\u2013\u00a0\u00a0\u00a0Recent DNA parentage analysis has revealed that Muscat of Alexandria is probably a natural cross between MUSCAT BLANC \u00c0 PETITS GRAINS and Axina de Tres Bias (Cipriani et al. 2010). Like Muscat of Alexandria, Muscat Blanc \u00e0 Petits Grains has for centuries been cultivated all around the Mediterranean, including Italy, where it has long been known as Moscato Bianco. Axina de Tres Bias is an old black-berried table variety that is still grown on the Italian island of Sardegna under this dialect name meaning \u2018three times grape\u2019 (also Trifera or Tre Volte l\u2019Anno, in reference to its very high yields), as well as on the Maltese archipelago (Giannetto et al. 2010) and in the Greek islands (Logothetis in Cipriani et al. 2010). Comparison of the DNA profiles of Axina de Tres Bias (Giannetto et al. 2010) with an unnamed sample from Calabria (Stella Grando, personal communication) and with Eftakoilo (Sefc et al. 2000), which is considered to be an old, black-berried Greek table variety (Eftakoilo or Heftakilo means \u2018seven kilograms\u2019), shows that they are identical (Vouillamoz).',
      'Muscat of Alexandria has also been cultivated for centuries under the name Moscatel in South America (Lacoste et al. 2010), where DNA parentage analysis has shown that the Argentine varieties CEREZA, TORRONT\u00c9S RIOJANO, TORRONT\u00c9S SANJUANINO and Moscatel Amarillo are all natural crosses between LIST\u00c1N PRIETO (called Criolla Chica in South America) and Muscat of Alexandria, and that TORRONT\u00c9S MENDOCINO is an offspring of Muscat of Alexandria and another unknown variety (Ag\u00fcero et al. 2003; Myles et al. 2011).',
      'A pink-berried mutation called Flame Muscat in California and Red Hanepoot in South Africa and a black-berried mutation called Black Muscat of Alexandria in the UK have been known since the nineteenth century or earlier. The latter has sometimes been confused with MUSCAT OF HAMBURG, a black-berried offspring of Muscat of Alexandria.',
      'Muscat of Alexandria has been used to breed many table grapes and quite few wine grapes, including SYMPHONY.',
    ],
    'where its grown': [
      'Muscat of Alexandria tends to make less subtle, stickier sweet wines than the small-berried Muscat and to display a certain marmalade or orange-flower aroma. The main terpenic compounds responsible for typical Muscat aromas in several Muscat (and other aromatic) varieties are linalol (characterized as lily of the valley) and geraniol (rose). Although other terpenes such as nerol (also rose) are significant, they have lower perception thresholds and are therefore less influential. Rib\u00e9reau-Gayon et al. (1975) analysed these compounds in various Muscat varieties and found that the average level of linalol was similar for both MUSCAT BLANC \u00c0 PETITS GRAINS and Muscat of Alexandria but that the latter had a higher level of geraniol. The difference may contribute to the finer aromatic qualities of Muscat Blanc \u00e0 Petits Grains.',
      'France has a relatively modest total of Muscat d\u2019Alexandrie, also known as Muscat Romain, 2,610 ha (6,449 acres) in 2009, about one third of the area planted to the generally superior MUSCAT BLANC \u00c0 PETITS GRAINS, mostly in the Languedoc and Roussillon and especially in the Pyr\u00e9n\u00e9es-Orientales. Here it is used to a limited extent for dry table wines but predominantly for sweet, grapey and alcoholic vins doux naturels such as Muscat de Rivesaltes, which until 2008 had to comprise equal amounts of these two Muscats. This presumably explains the dominance of Muscat d\u2019Alexandrie in Roussillon as recently as 1998.',
      'In Italy, there\u2019s about twenty times as much Moscato d\u2019Alessandria planted for the table as there is for wine. Most of the national total of 1,592 ha (3,934 acres) in 2000 was planted in the south and islands. The wine grapes are more often called by their Sicilian name, Zibibbo. Zibibbo, grown on the volcanic island of Pantelleria, closer to Tunisia than to mainland Italy, can produce luscious, sweet wines in three styles, from late-harvested grapes, dried berries or fortified. The best are golden or orange in colour, very sweet and intense, and can taste of ripe apricots. Recommended producers for sweet Passito di Pantelleria include Marco de Bartoli, Donnafugata and Pellegrino. On Sicilia, Marco de Bartoli also make an oak-aged sweet wine, Terzavia a barrel-fermented dry white.',
      'In 2008, Spain had 9,894 ha (24,449 acres) of Moscatel de Alejandr\u00eda, also known simply as Moscatel, and official statistics listed 40 ha (99 acres) of Moscatel de M\u00e1laga separately, even though they are the same variety. Some of this may be destined for the table but the rest goes into sweet, simple and sometimes rather sickly dessert wines such as Moscatel de Chipiona as well as fortified versions. Producers such as Telmo Rodriguez and Jorge Ordo\u00f1ez, however, have shown that refined, delicate wines, both sweet and dry, can be made from the Moscatel grapes grown around the Andalusian city of M\u00e1laga, which once used to produce extremely dark, sticky wines given extended oak ageing \u2013 not that unlike the Liqueur Muscats of Australia.',
      'In Portugal, the variety is known as Moscatel Gra\u00fado or Moscatel de Set\u00fabal, and there were 949 ha (2,345 acres) planted in 2010. Moscatel de Set\u00fabal is also the name of the DOC south east of Lisboa famous for its fortified Moscatel, rendered extra grapey by macerating Moscatel skins in the finished wine for several months and becoming deep golden, spiced and raisiny after several years, occasionally up to twenty or more, in large old oak casks. Recommended producers in Set\u00fabal include Bacalh\u00f4a Vinhos de Portugal, Jos\u00e9 Maria da Fonseca, Hor\u00e1cio Sim\u00f5es and Ven\u00e2ncio da Costa Lima. Unfortified versions are also produced in the region and elsewhere in Portugal. On the Portuguese island of Madeira it is known as Moscatel Gra\u00fado or Moscatel de M\u00e1laga, and there was little more than 1 ha (2.5 acres) in 2009, although one producer planted this variety in 2010.',
      'In Greece,\nMuscat d\u2019Alexandrie or Moschato Alexandrias is found mainly in the northern Aegean Islands, where there were 220 ha (544 acres) in 2008, a tiny area compared with that planted to MUSCAT BLANC (\u00c0 PETITS GRAINS) but significant on the island of L\u00edmnos, whence it gets its synonym Moschato Limnou and where it is the grape of the Muscat of L\u00edmnos appellation for sweet wines. In Turkey, Israel and Tunisia, however, plantings of this Muscat far exceed those of MUSCAT BLANC \u00c0 PETITS GRAINS, although much of the fruit is for fresh consumption.',
      'California had 3,391 acres (1,372 ha) planted in 2010, almost all in the Central Valley and especially in Fresno. First planted in the mid 1800s, it was the main variety for the raisin business until the 1920s when seedless varieties became more popular. Today it is grown for the table, home winemaking and for dessert and sparkling wines, or is used to add floral aromas to a blend.',
      'In Australia,\nwhere it is known as Muscat Gordo Blanco and occasionally Lexia, a peculiarly Australian contraction of Alexandria, there were 2,363 ha (5,839 acres) in 2008. Its importance is restricted to the hot inland irrigated vineyards, where it may be made into wine or dried as raisins, depending on the prevailing demands of the market. In periods of shortage of white wine it has been used to bulk out commercial blends.',
      'In South America, the majority of Chile\u2019s 5,000 or so hectares (12,355 acres) of \u2018Moscato\u2019, most of which is probably Moscatel de Alejandria, is distilled into the national spirit, pisco, but the area is so substantial that it is the country\u2019s third most planted pale-skinned grape, after the various varieties described as Sauvignon Something and CHARDONNAY. It is also found in Argentina (4,064 ha/10,042 acres in 2009), to a lesser extent in Peru (361 ha/892 acres of \u2018Moscatel\u2019, which includes Muscat of Alexandria, in 2010), mostly for pisco, as well as in Uruguay, Colombia and Equador.',
      'In South Africa, there are currently more uprootings than plantings of Muscat of Alexandria, known there by its Afrikaans name, Hanepoot, and used chiefly for fortified wines and table grapes. It was planted on about 13% of the entire Cape vineyard area in the late 1970s, and there were still 2,346 ha (5,797 acres) of this variety in 2008, quite widely spread but with the greatest concentration in the hot inland Olifants River and Breedekloof regions. Varietal table wines are few and far between and generally lacklustre but the likes of Caltizdorp and Constantia Uitsig make superior fortified versions.',
    ],
  },
  {
    name: 'MUSCAT OF HAMBURG',
    synonyms: [
      'Black Muscat',
      'Black Muscat of Alexandria',
      'Hamburg Musqu\u00e9',
      'Hamburgii Muskot\u00e1ly',
      'Malvasia Nera',
      'Moscatel de Hamburgo',
      'Moscatel Prato',
      'Moscato d\u2019Amburgo',
      'Moscato Nero',
      'Moscato Nero d\u2019Acqui',
      'Moschato Amvourgou',
      'Moschato Tyrnavou',
      'Muscat Albertdient\u2019s',
      'Muscat de Hamburg',
      'Muscat Hamburg',
      'Muscat-Hamburgh',
      'Muscat Hamburg Crni',
      'Oeillade Musqu\u00e9e',
      'Snow\u2019s Muscat Hamburgh',
      'T\u0103m\u00eeioas\u0103 Hamburg',
      'T\u0103m\u00eeioas\u0103 Neagra',
      'Venn\u2019s Seedling',
      'Venn\u2019s Seedling Black Muscat',
      'Zibibbo Nero',
    ],
    description:
      'Genetic conundrum associated most readily with table grapes grown intermittently in England and widely elsewhere.',
    'origin and parentage': [
      'The origin of Muscat of Hamburg is unclear. According to a portrait published in the London\nFlorist (Smith 1858), \u2018this fine grape is a seedling, raised at Wrest Park, Bedfordshire, by Mr Seward Snow, who .\u00a0.\u00a0. informs us that this grape originated by fertilizing flowers of the Black Hamburgh vine with those of the White Muscat of Alexandria\u2019. In England, Black Hamburgh or Black Hamburg was the name given to SCHIAVA GROSSA, and White Muscat corresponded to MUSCAT OF ALEXANDRIA. The grape was initially named Snow\u2019s Muscat Hamburgh, combining the parents\u2019 and breeder\u2019s names, at the meeting of the British Pomological Society: \u2018Mr Snow having expressed a wish that the Society should name his new Grape, Mr Hogg proposed that it should be called Snow\u2019s Muscat Hamburgh, which was approved of\u2019 (Anonymous 1856).',
      'The parentage of Muscat of Hamburg was also surmised by twentieth-century ampelographers such as Pirovano and Levadoux and was recently confirmed by DNA analysis (Crespan 2003): it is a natural cross between MUSCAT OF ALEXANDRIA and SCHIAVA GROSSA. This makes it a sibling of MALVASIA DEL LAZIO in central Italy, which tends to suggest that both Malvasia del Lazio and Muscat of Hamburg are genetically Italian, even if the latter was bred in England. Muscat of Hamburg is therefore a grandchild of MUSCAT BLANC \u00c0 PETITS GRAINS and Axina de Tres Bias (see the pedigree diagram in the MUSCAT entry) and also a half-sibling of the other progenies of Muscat of Alexandria (MOSCATELLO SELVATICO and GRILLO in Italy; CEREZA, Moscatel Amarillo, TORRONT\u00c9S MENDOCINO, TORRONT\u00c9S RIOJANO and TORRONT\u00c9S SANJUANINO in Argentina) and of Schiava Grossa (BUKETTRAUBE, KERNER, Madeleine Royale, Othello, ROTBERGER and UVA TOSCA \u2013 see the pedigree diagram in the Schiava Grossa entry). Muscat of Hamburg was used to breed BEICHUN, CANADA MUSCAT, COMPLEXA, EARLY MUSCAT, FIOLETOVY RANNY, GOLDEN MUSCAT, ITALIA, MANZONI MOSCATO, MUSCAT BAILEY A and NEW YORK MUSCAT.',
    ],
    'where its grown': [
      'Muscat of Hamburg is far more commonly planted for the table than for wine thanks to its plump, shiny berries and its ability to withstand long journeys. It is particularly widely planted for this purpose in France, Eastern Europe, Greece and Australia and was very popular in greenhouses in Victorian England. However, it is also used to produce light, grapey wines in Eastern Europe and dessert wines elsewhere. In California, for example, where there were 251 acres (102 ha) of Muscat Hamburg (or Black Muscat) in 2009, it is grown almost exclusively for wine. Quady\u2019s Elysium is a dessert version with a light rose aroma that develops only when the grapes are very ripe. It is also used to make sweet wines in several European wine regions, including Thessal\u00eda in central mainland Greece, on Cyprus and in central Italy.',
    ],
  },
  {
    name: 'MUSCAT OTTONEL',
    synonyms: [
      'Muscadel Ottonel',
      'Muskat Otonel',
      'Muskat Ottonel',
      'Muskot\u00e1ly',
      'Ottonel Muskot\u00e1ly',
      'T\u0103m\u00eeioas\u0103 Ottonel',
    ],
    description:
      'Soft, grapey French variety used for both dry and sweet wines, particularly in Alsace and Eastern Europe.',
    'origin and parentage': [
      'Muscat Ottonel is a seedling obtained in Angers in the Val de Loire, France, by the vine breeder Jean-Pierre Vibert in 1839, and later released in 1852 by his chief gardener, Robert. It was named in honour of a certain H Ottonel. Vibert did not keep track of the parents, and the seedling was thought to be a CHASSELAS \u00d7 Muscat de Saumur cross (Galet 2000) until DNA profiling at INRA (Institut National de la Recherche Agronomique) in Montpellier, southern France, corrected this to a Chasselas \u00d7 Muscat d\u2019Eisenstadt cross. It was used to breed M\u00c1TRAI MUSKOT\u00c1LY and MU\u0160K\u00c1T MORAVSK\u00dd.',
    ],
    'where its grown': [
      'The berries are able to accumulate high sugar levels and produce aromatic wines, both dry and sweet, although acidity levels are generally low unless the grapes are picked very early, in which case they are much less aromatic. Ottonel is the palest of the Muscats, in terms of colour as well as character, but the fact that it ripens earlier than the more aromatic MUSCAT BLANC \u00c0 PETITS GRAINS and MUSCAT OF ALEXANDRIA gives it appeal in cooler growing regions. It is the most important of the Muscat Something varieties in France, mainly in Alsace for exuberantly grapey wines labelled Muscat d\u2019Alsace that finish dry or off dry. Total French plantings in 2008 were 165 ha (408 acres).',
      'In Switzerland there are just 4 ha (10 acres), mainly for light, dry wines, but Austria had 472 ha (1,166 acres) of Muskat Ottonel in 2007 (up from 418 ha/1,033 acres in 1999), mostly in Neusiedlersee and Neusiedlersee-H\u00fcgelland for sweet wines, though there are 110 ha (272 acres) distributed in rather small pockets in Nieder\u00f6sterreich (Lower Austria), the regions on either side of the Donau (Danube).',
      'In Hungary, the 1,232 ha (3,044 acres) of Ottonel Muskot\u00e1ly in 2008 were grown throughout the country except in Soml\u00f3 and Sopron in the north west, but mostly in M\u00e1tra and Eger, north east of Budapest, and Kuns\u00e1g further south. It is popular as a blending partner and for sweet wines but Szeremley in Badacsony and M\u00e1ty\u00e1s Sz\u0151ke in M\u00e1tra make fine dry or off-dry varietal wines.',
      'Romania\u2019s Muscat Ottonel (2,809 ha/6,914 acres in 2008) produces some very good sweet wines and the biggest vineyard areas are in the counties of Dobregea by the Black Sea, Vrancea in eastern Muntenia and in Transilvania in the centre of the country. Nearly twice as much Muskat Otonel (sic) was planted in Bulgaria \u2013 5,573 ha (13,771 acres) in 2009 \u2013 and much of its produce is labelled Misket.',
      'There are minor plantings in Krasnodar in Russia (34 ha/84 acres in 2009), the Czech Republic (59 ha/146 acres in 2008) and more significant ones in the former Soviet Republics. The Republic of Moldova had a significant 1,620 ha (4,003 acres) in 2009.',
      'Further afield, the variety is also found in South Africa, New York State and British Columbia in Canada.',
    ],
  },
  {
    name: 'MUSCAT SWENSON',
    synonyms: ['ES 8-2-43', 'Muscat de Swenson'],
    description: 'Muscat-like hardy American hybrid.',
    'origin and parentage': [
      'A Vitis riparia \u00d7 Varousset hybrid bred by Elmer Swenson in Osceola, Wisconsin, US, in which:',
      '\u2013\u00a0\u00a0\u00a0Varousset (or Seyve-Villard 23-657) is a Seibel 4668 \u00d7 Sub\u00e9reux hybrid (see PRIOR for the pedigree of Sub\u00e9reux)',
      '\u2013\u00a0\u00a0\u00a0Seibel 4668 is a hybrid of unknown parents, obtained by Albert Seibel in Aubenas in the Ard\u00e8che, south-eastern France.',
    ],
    'where its grown': [
      'Planting is beginning in Qu\u00e9bec, Canada, where Clos St-Ignace, for example, make a sweet, fortified varietal wine, and in New England. Wines have a grapey, Muscat-like character when harvested fully ripe but may be more neutral if harvested early.',
    ],
  },
  {
    name: 'MU\u0160K\u00c1T MORAVSK\u00dd',
    synonyms: ['Mopr', 'Moravian Muscat', 'Moravsky Muskat'],
    description: 'The Czech Republic\u2019s very own Muscat. Widely planted.',
    'origin and parentage': [
      'Mu\u0161k\u00e1t Moravsk\u00fd is a MUSCAT OTTONEL \u00d7 Prachttraube cross obtained by V K\u0159iv\u00e1nek at the research centre in Pole\u0161ovice in Morava in the south east of the Czech Republic, in which Prachttraube is a Madeleine Royale \u00d7 Boskokwi cross, and Boskokwi or Boskokvi a variety, no longer in cultivation, from the Greek island of Z\u00e1kynthos. (See M\u00dcLLER-THURGAU for details of Madaleine Royale.) It was bred in order to have a locally adapted Muscat-like variety and initially included in the offical Czech register of varieties in 1987 under the name Mopr, a combination of the initial letters of its parents, before being renamed Mu\u0161k\u00e1t Moravsk\u00fd in 1993.',
    ],
    'where its grown': [
      'Mu\u0161k\u00e1t Moravsk\u00fd is the most widely planted of the recently bred Czech varieties, with 358 ha (885 acres) in the Czech Republic, predominantly in Morava in the south east, in 2009. For dry, floral and Muscat-flavoured wines, grapes must be picked early enough for sufficient acidity. Kabinett and late-harvest styles can also be found. Recommended producers include Vladim\u00edr Tetur.',
    ],
  },
  {
    name: 'MUSTOAS\u0102 DE M\u0102DERAT',
    synonyms: ['Lampor', 'Mustafer', 'Mustosfeh\u00e9r'],
    description:
      'Productive, high-acid variety from western Romania making light, fresh, locally popular whites.',
    'origin and parentage': [
      'The exact origin of Mustoas\u0103 de M\u0103derat is unknown but its name suggests that it was selected a few centuries ago in the vineyards of M\u0103derat in the Mini\u015f area in western Romania (Dejeu 2004). Its relationship to FR\u00c2NCU\u015e\u0102, also called Mustoas\u0103 de Moldova, is unknown but their ampelographic descriptions are quite similar (Dejeu 2004).',
    ],
    'where its grown': [
      'The warm Mini\u015f M\u0103derat wine region in western Romania, close to the border with Hungary, is generally better suited to the red wine varieties that make up 80% of the vineyard but Mustoas\u0103 de M\u0103derat\u2019s high acidity keeps it fresh. Wines produced tend to be simple, but good-quality aromatic whites can be made if yields are controlled. Wine Princess make a typically light, citrus-flavoured, delicately aromatic, even a touch floral varietal. The grape is also suited to the production of sparkling wines and sometimes used for brandy. There were 486 ha (1,201 acres) in Romania in 2008, virtually all in the far west.',
    ],
  },
  {
    name: 'NARINCE',
    synonyms: [
      'G\u00fcz\u00fcl \u00dcz\u00fcm',
      'Kazova',
      'Narance',
      'Nerince',
    ],
    description:
      'Turkey\u2019s best-known white wine variety, also grown for the table.',
    'origin and parentage': [
      'Narince, literally meaning \u2018delicate\u2019 or \u2018fragile\u2019, comes from the Tokat region in northern central Turkey, south of the Black Sea coast.',
    ],
    'where its grown': [
      'Narince is one of the most important white wine varieties in Turkey although it is also widely planted for the table. Grown mainly on sandy and gravelly soils along the Ye\u015fil\u0131rmak River in the province of Tokat, at altitudes of 600\u2013700 m above sea level thanks to the Pontic mountains that separate northern Turkey from the Black Sea coast, it is also found in the neighbouring provinces of \u00c7orum and Amasya to the west and further south in Sivas. There were 814 ha (2,100 acres) planted in Turkey in 2010, making it the most widely planted white wine grape.',
      'Wines \u2013 both dry and off dry and often oaked \u2013 are typically fresh and fruity but can be quite full-bodied, with flavours ranging from orange and grapefruit through floral to apple and lime. They are probably the only Turkish whites with the capacity to age and they blend well with lighter and more piercing wines made from EMIR, as well as with international varieties such as CHARDONNAY or S\u00c9MILLON. Producers include Doluca, Kavaklidere, Kocaba\u011f and Turasan.',
    ],
  },
  {
    name: 'NASCETTA',
    synonyms: ['Anascetta', 'Nas-c\u00ebtta'],
    description:
      'Renascent semi-aromatic Piemontese made increasingly as a varietal wine.',
    'origin and parentage': [
      'Nascetta is a rare variety from the Langhe area of Piemonte in north-west Italy and was mentioned in the mid nineteenth century as a grape suited to making quality wine. It used to be widespread between Alba and Mondov\u00ec, usually blended with VERMENTINO (Favorita) and Moscato Bianco (MUSCAT BLANC \u00c0 PETITS GRAINS).',
    ],
    'where its grown': [
      'Nascetta was almost extinct when Elvio Cogno made the first micro-production of Nascetta in 1994. Today it is planted around the commune of Novello in the province of Cuneo, in Piemonte, Italy, and to a lesser extent in the neighbouring commune of Monforte d\u2019Alba and the more distant Trezzo Tinella (Dellavalle et al. 2005). In the past, this semi-aromatic variety was generally blended but varietal wines are now authorized within the Langhe DOC and made by producers such as Le Strette (labelled Anas-C\u00ebtta), La Tribuleira and Rivetto as well as by Elvio Cogno, whose example of Anas-C\u00ebtta has aromas of acacia flowers, exotic fruits, honey, sage, rosemary and citrus. Wines are well structured with fresh acidity and amenable to barrel ageing and often have the flavour of acacia honey.',
    ],
  },
  {
    name: 'NASCO',
    synonyms: ['Nascu', 'Nusco'],
    description:
      'Possibly ancient light-berried variety making soft white, mostly dessert, wines on Sardegna.',
    'origin and parentage': [
      'The name Nasco could derive from the characteristic aroma of musk (nuscu in Sardinian dialect) found in overripe berries or aged wines of this variety. As with many Sardinian varieties, it has been suggested that Nasco was introduced from Spain, but as yet there is no genetic evidence to back this up.',
    ],
    'where its grown': [
      'Nasco is cultivated mainly in the provinces of Cagliari in the south of Sardegna, Italy, and Oristano in the west, and to a lesser extent around Alghero in the province of Sassari and in Mandrolisai in the province of Nuoro in the centre of the island. The Italian agricultural census recorded 171 ha (423 acres) in 2000.',
      'Varietal wines that qualify as Nasco di Cagliari DOC can come from almost the whole of the southern part of the island and may be dry, sweet or fortified, and the fortified wines may be dry or sweet. The sweet wines have aromas of honey and dried fruits, apricot and spice and the sweetness is well balanced by the acidity. Argiolas\u2019 Angialis, classified as IGT Isola dei Nuraghi, made predominantly from Nasco, with a little added Malvasia di Cagliari (MALVASIA DI LIPARI), is among the best dessert wines in Italy. Other producers include Picciau.',
    ],
  },
  {
    name: 'NEBBIERA',
    synonyms: ['Incrocio Dalmasso XV/29'],
    description:
      'Very minor northern Italian dark-skinned cross whose true parentage has only recently been revealed.',
    'origin and parentage': [
      'Nebbiera is a cross created by Italian vine breeder Giovanni Dalmasso in 1938 at the Conegliano research centre in the Veneto. He himself claimed that it was a NEBBIOLO \u00d7 BARBERA cross and it entered the Italian register of varieties in 1977. The objective was to develop a new variety that combined the quality of Nebbiolo and the resistance and the naturally high yields of Barbera. However, DNA profiling has recently shown that the Nebbiolo used by Dalmasso was in fact Nebbiolo di Dronero, which is not Nebbiolo but a synonym for CHATUS (Torello Marinoni, Raimondi, Mannini and Rolle 2009). Nebbiera is therefore a Chatus \u00d7 Barbera cross, just like its siblings ALBAROSSA, CORNAREA, SAN MICHELE and SOPERGA.',
    ],
    'where its grown': [
      'There are a few plantings in Piemonte in north-west Italy but the total area recorded in the Italian agricultural census of 2000 was just 22 ha (54 acres).',
    ],
  },
  {
    name: 'NEBBIOLO',
    synonyms: [
      'Chiavennasca',
      'Picotendro',
      'Picoutener',
      'Picot\u00e8ner',
      'Prunent',
      'Pr\u00fcnent',
      'Spanna',
    ],
    description:
      'Piemonte\u2019s, arguably Italy\u2019s, most revered wine grape; very old variety capable of producing perfumed, expressive, ageworthy wines of great beauty.',
    'origin and parentage': [
      'While the vast majority of sources give 1268 as the earliest mention of Nebbiolo, the original reference to nibiol is in fact in a document in the Archivio di Stato di Torino, kindly provided by its director, Marco Carassi, who identified it as Camerale Piemonte, Articolo 65, paragrafo 1, Conti della castellania di Rivoli, mazzo 1, rotolo n. 1, dated 1266. Details of this document are given by the historian Cibrario (1833): in Rivoli, Conto d\u2019Umberto de Balma, castellan of Rivoli, obtained 626 sextarius (c.338 litres) of wine, including 306 from the grape named Nibiol (\u2018vini receptis de exitu vincarum de nibol hoc anno\u2019; Archivo di Stato di Torino; Marco Carassi, personal communication).',
      'Several subsequent mentions in the 13th and 14th centuries confirm Nebbiolo as one of the oldest and most widespread varieties of Piemonte: 1292 in Alba (filagnos de vitibus neblorii), 1295 in Camerano Casasco (nebiolo), 1303 in Canale (vino nebiolio) and in Moncalieri, 1304 in Asti (nebiolus), 1311 in Chieri, 1324-5 in Moretta (nubliolio), 1327\u20138 in Almese (vinum nibiolii), 1328\u20139 in Bricherasio (vites de nebiolio) and 1380 in Pinerolo (Comba and Dal Verme 1990).',
      'The most important synonyms of Nebbiolo also appear early in the literature: Pr\u00fcnent (Val d\u2019Ossola) in 1309, Chiavennasca (Valtellina) in 1595 (Bongiolatti 1996), Spanna (around Novara) in 1466 and Picotendro (Valle d\u2019Aosta) in the nineteenth century.',
      'The name Nebbiolo derives from nebbia, Italian for \u2018fog\u2019, probably referring to the thick, natural bloom covering the ripe berries, as if they were covered in a layer of fog. This etymology is more convincing than the alternative suggestion that the name refers to the fog that covers the Piemonte hills when these late-ripening grapes are harvested. Grape names are more likely to refer to the characteristics of the plant than to the local climate. The same etymology applies to the synonym Pr\u00fcnent, more probably derived from pruina (\u2018bloom\u2019) than from prugna (\u2018plum\u2019) although some authors suggest that it\u2019s a reference to the tradition of cultivating grapes together with plum trees. Chiavennasca derives from the city of Chiavenna in the province of Sondrio in Lombardia.',
      'Centuries of clonal propagation have resulted in several distinct clonal selections of Nebbiolo. Four main types are usually distinguished:',
      '\u2013\u00a0\u00a0\u00a0Nebbiolo Lampia, the most widespread',
      '\u2013\u00a0\u00a0\u00a0Nebbiolo Michet, a virused form of Lampia (morphology and production affected by grapevine fanleaf virus or GFLV)',
      '\u2013\u00a0\u00a0\u00a0NEBBIOLO ROS\u00c9, morphologically rather different',
      '\u2013\u00a0\u00a0\u00a0Nebbiolo Bolla, once widespread and now decreasing in importance.',
      'DNA profiling has shown that the types Lampia, Michet and Bolla have identical DNA profiles and belong to the same grape variety but, surprisingly, that NEBBIOLO ROS\u00c9 has a different profile (Schneider, Boccacci et al. 2004). Nebbiolo Ros\u00e9 must be considered as a separate variety and no longer as a clone of Nebbiolo.',
      'DNA researchers have tracked down Nebbiolo\u2019s origin, but since it is a very old variety, its parents are likely to be extinct. However, DNA parentage analysis has revealed at least eight parent\u2013offspring relationships (see pedigree diagram overleaf) between Nebbiolo and local varieties from Piemonte and Valtellina (Schneider, Boccacci et al. 2004). Northwards from southern Piemonte to Valtellina:',
      '\u2013\u00a0\u00a0\u00a0BUBBIERASCO (Saluzzo region, province of Cuneo) is a natural cross between Nebbiolo and Bianchetta di Saluzzo, another old and no longer cultivated white variety from the same area (Schneider et al. 2005\u20136), which confirms that Nebbiolo was formerly widely cultivated in Cuneo.',
      '\u2013\u00a0\u00a0\u00a0FREISA is widespread across Piemonte, where it is sometimes called Spannina, confirming its offspring relationship to Nebbiolo, which is sometimes called Spanna. DNA profiling has shown that Freisa could have a close genetic relationship with VIOGNIER from the northern Rh\u00f4ne in France, so that Nebbiolo and Viognier are likely to be cousins (Schneider, Boccacci et al. 2004).',
      '\u2013\u00a0\u00a0\u00a0NERETTO DI BAIRO, also called Neretto di San Giorgio, and VESPOLINA are minor varieties of northern Piemonte and the Oltrep\u00f2 Pavese, where Nebbiolo has been known since the Middle Ages.',
      '\u2013\u00a0\u00a0\u00a0NEBBIOLO ROS\u00c9 is traditionally called Chiavennaschino in Valtellina, where it may have originated. The parent\u2013offspring relationship with Nebbiolo explains why Nebbiolo Ros\u00e9 has generally been considered to be a clone of Nebbiolo.',
      '\u2013\u00a0\u00a0\u00a0Negrera, ROSSOLA NERA and BRUGNOLA (the last often confused with FORTANA) are old and minor varieties from Valtellina, confirming the historical presence of Nebbiolo in this area, where it is called Chiavennasca. (Negrera is also, confusingly, a synonym for JUAN GARC\u00cdA.)',
      'With the exception of BUBBIERASCO, the missing parents of these varieties are likely to have disappeared. It is therefore possible that Nebbiolo is the offspring of one of these varieties, although Nebbiolo\u2019s long history makes it more likely to be the parent.',
      'Based on these recent DNA findings, Nebbiolo could come from either Piemonte or Valtellina since it had four progenies in both regions.',
      'Nebbiolo was used to breed RUBIN.',
    ],
    'where its grown': [
      'Nebbiolo is intensively cultivated in southern central and north Piemonte (Langhe, Roero, Asti, Carema, Biella, Novara and around Vercelli) in north-west Italy although it constituted less than 8% of the Piemonte vineyard in 2001, according to official local statistics. Salaris (2005\u20136) notes 338 ha (835 acres) in northern Piemonte (provinces of Novara, Vercelli, Biella, Torino and Asti) in 2004 and 3,375 ha (8,340 acres) in the province of Cuneo. It is also grown in the lower part of the Valle d\u2019Aosta (just 26 ha/64 acres in 2004), around Donnas, and in Valtellina and Franciacorta, both in Lombardia (908 ha/2,244 acres). It is even grown in the region of Gallura on the island of Sardegna (52 ha/128 acres in 2004). The Italian agricultural census recorded 4,886 ha (12,074 acres) in 2000, about three quarters of that total in Piemonte.',
      'Because it ripens so late, and because of the quality (and market value) of its wines, Nebbiolo is given the best hillside sites, usually facing south or south west. It\u2019s fussy when it comes to soils, too, and produces the finest wines when planted on calcareous marls to the north and south of Alba on the right bank of the Tanaro in the DOCG zones of Barbaresco and Barolo respectively. Nebbiolo is rivalled only by PINOT NOIR in its ability to express the subtleties of different terroirs, which is why these two zones have been so assiduously divided into named crus, but in very general terms unadulterated Nebbiolo tends to be light in colour, turning orange with bottle age rather faster than most other red wine varieties, to be high in both acid and (especially) tannin, and to exhibit a haunting array of aromas which might include tar, cordite, leaf mould, dried cherries, liquorice, violets and roses.',
      'Top producers include Elio Altare, Enzo Boglietti, Cappellano, Ceretto, Michele Chiarlo, Cigliuti, Aldo Conterno, Giacomo Conterno, Gaja, Bruno Giacosa, Elio Grasso, Marcarini, Marchesi di Gr\u00e9sy, Bartolo Mascarello, Giuseppe Mascarello, Massolino, Armando Parusso, Pio Cesare, Produttori del Barbaresco, Fratelli Revello, Giuseppe Rinaldi, Bruno Rocca, Luciano Sandrone, Paolo Scavino, Vajra, Vietti and Roberto Voerzio.',
      'In these DOCGs, it comprises 100% of the wine, whereas in Gattinara, Ghemme and Roero (see Matteo Correggia\u2019s wines, for example), the minimum requirement varies between 75% and 95%. The DOCG regulations refer to the subvarieties Michet, Lampia and Ros\u00e9 but one wonders if these should be amended now that it has been shown that NEBBIOLO ROS\u00c9 is a different variety (see above). Varietal (or at least 85% Nebbiolo) wines are also permitted in the Piemonte DOCs of Albugnano, Canavese, Carema, Colline Novaresi, Coste della Sesia, Langhe and Nebbiolo d\u2019Alba and there\u2019s a host of other DOCs in Piemonte such as Bramaterra where Nebbiolo is the most important or at least a significant component. However, producers insist upon local synonyms such as Spanna and use them on labels. Blending partners include VESPOLINA, BONARDA PIEMONTESE and CROATINA, depending on local regulations.',
      'Spanna\u2019s home territory is in the north of Piemonte, in the hills above Novara and Vercelli, and wines produced there on glacial soils tend to lack the intensity of a Barolo but not its perfume. Historically more Nebbiolo was planted here than anywhere else but the area declined during Italy\u2019s industrial revolution in the 1950s. Nevertheless, good wines are once again being produced in varying soil types in the hills on the left and right banks of the Sesia River: in the province of Novara, the most important DOCs are Boca, Sizzano, Fara plus Ghemme DOCG; and in the Vercelli hills, Lessona, Bramaterra plus Gattinara DOCG. Superior producers include Antoniolo and both Valloni and Vallana.',
      'In the far north of Lombardia, Nebbiolo \u2013 known as Chiavennasca \u2013 produces a medium-bodied wine but struggles in all but the warmest vintages to ripen sufficiently to produce the fruit flavours that will balance the variety\u2019s marked tannins and acidity. Here it is the dominant variety in Valtellina Superiore DOCG and Valtellina DOC as well as in the distinctive passito-style Sforzato di Valtellina. Beyond this narrow northerly valley, in the rest of Lombardia Nebbiolo features only as part of the Franciacorta blend and then to a maximum of just 10%. Top producers in Valtellina include Ar Pe Pe and Nino Negri.',
      'Northern Italy\u2019s only other outpost of Nebbiolo, in the guise of Picotendro or Picoutener, is the Valle d\u2019Aosta, in Donnas (just over the regional border from Carema) and Arnad-Montjovet. Here the variety faces similar obstacles to full ripening as in Valtellina.',
      'Nebbiolo is pretty rare in the south of the country but there are 52 ha (128 acres) planted in Gallura on the island of Sardegna.',
      'The Siren-like beauty and power of the finest examples of Barolo or Barbaresco have lured producers around the world to try their hand and their land with this variety, just as they have done with PINOT NOIR but so far on a much more limited scale: about three quarters of worldwide Nebbiolo plantings are in Piemonte. It is not a very adaptable variety, its all-important fragrance is decidedly fragile, and it has shown a marked reluctance to travel.',
      'In Europe outside of Italy there are very few outposts of Nebbiolo. One notable example is the Mas de Daumas Gassac at Aniane in the Languedoc, southern France, where Aim\u00e9 Guibert planted about twenty-five rows, the product of which goes into their top red blend. In the Valais region of Switzerland, two producers, with about 1 ha (2.5 acres) of vines between them, market a varietal Nebbiolo and there are a couple of hectares in Austria.',
      'In California, there are around 150 acres (61 ha), most of those planted before 2000, notably in such AVAs as Paso Robles, Santa Cruz Mountains, Sierra Foothills, Amador, Monterey, Santa Ynez Valley and Santa Barbara. Passionate Nebbiolo-philes have included Palmina, Randall Grahm at Bonny Doon, Ahlgren, Jim Clendenen and Domenico. Beyond California there are limted plantings in the drier regions of Washington State, where Cavatappi\u2019s are probably the most successful examples, as well as in Oregon, Virginia, Tennessee, Pennsylvania, Idaho and New Mexico. In Canada there are limited plantings in British Columbia. Mexico\u2019s L A Cetto also produce a particularly full-bodied Nebbiolo in Baja California.',
      'In South America, there were 176 ha (435 acres) in Argentina in 2008, mostly in San Juan but also in Mendoza (eg Vi\u00f1a Alicia). In Chile, there are just 9 ha (22 acres) planted (the first vines went into the ground in 1998) but Botalcura is still the only producer to bottle and sell a Nebbiolo.',
      'In 2007, South Africa recorded 18 ha (44 acres) of Nebbiolo.',
      'There\u2019s a small but growing wave of interest in Australia, where it was first planted in the Hunter Valley in the early 1980s and then more successfully by Pizzini in the King Valley in Victoria in the late 1980s, although it was not until 1990 that the first wine was produced by Brown Brothers, also in the King Valley. These two have been followed by Garry Crittenden and by isolated producers in the Adelaide Hills, Langhorne Creek, Mudgee and even as far north as Queensland. It\u2019s seems that regions with long cool growing seasons and mature vineyards are most likely to succeed. In 2008 total plantings had reached 106 ha (262 acres) and, according to the Australia and New Zealand Wine Industry Directory 2009, Nebbiolo has so far seduced eighty-four producers.',
      'There are also very limited plantings in New Zealand.',
    ],
  },
  {
    name: 'NEBBIOLO ROS\u00c9',
    synonyms: ['Chiavennasca Piccola', 'Chiavennaschino', 'Nebiol Matin\u00e9'],
    description:
      'Piemontese similar to but genetically distinct from Nebbiolo.',
    'origin and parentage': [
      'Until recently, Nebbiolo Ros\u00e9 was considered a particular clonal variation of NEBBIOLO for the region of Alba, just like Nebbiolo Lampia, Nebbiolo Michet or Nebbiolo Bolla. However, DNA profiling has established that Nebbiolo Ros\u00e9 and Nebbiolo have distinct DNA profiles and must be considered distinct varieties (Schneider, Boccacci et al. 2004). Additional investigations uncovered a few vines of Nebbiolo Ros\u00e9 in old vineyards in Roero, in the Valle d\u2019Aosta and in Valtellina, mainly in the province of Sondrio, where Nebbiolo Ros\u00e9 is called Chiavennaschino and Nebbiolo is called Chiavennasca (Schneider 2005\u20136).',
      'In addition, DNA parentage analysis has revealed a parent\u2013offspring relationship between Nebbiolo Ros\u00e9 and NEBBIOLO (Schneider, Boccacci et al. 2004; see pedigree diagram above). Although the direction of the parentage cannot be ascertained, it is likely that Nebbiolo is a parent of Nebbiolo Ros\u00e9, the other parent being unknown and probably extinct.',
    ],
    'where its grown': [
      'Since Nebbiolo Ros\u00e9 has generally been considered to be a clone of NEBBIOLO, they have been propagated together under the name Nebbiolo so that Nebbiolo Ros\u00e9 is found almost everywhere in Italy where you find Nebbiolo: around Alba, in Roero, the Valle d\u2019Aosta and Valtellina. Once quite widespread in these areas, it is now grown mainly in Sassella near Sondrio in Valtellina.',
      'Nebbiolo Ros\u00e9 does not yet have its own entry in the national register of varieties and the area under vine is not recorded separately. Very few varietal wines are produced but Elvio Cogno\u2019s Barolo Vigna Elena is made exclusively from Nebbiolo Ros\u00e9, though they describe it as a \u2018subvariety of Nebbiolo\u2019. Wines tend to have higher alcohol, lower acidity and less colour and structure than NEBBIOLO but attractive aromas.',
    ],
  },
  {
    name: 'NEGOSKA',
    synonyms: [
      'Goumenissas Mavro',
      'Mavro Goumenissas',
      'Neghotska',
      'Negkoska',
      'Negoska Popolka',
      'Negotska',
      'Popolka Naoussis',
    ],
    description:
      'Northern Greek variety making soft, full-bodied wines that are often blended with Xinomavro.',
    'origin and parentage': [
      'Negoska probably originates from N\u00e1oussa in west Makedon\u00eda, Greece, as suggested by its name, which may derive from the Slavic word Negush meaning N\u00e1oussa. Negoska was believed to be a close relative of XINOMAVRO, with which it is often blended, but this is not supported by recent DNA analysis (Biniari and Stavrakakis 2007).',
    ],
    'where its grown': [
      'Negoska is grown in various parts of Makedon\u00eda, Greece, but is a required component \u2013 a minimum of 20% \u2013 only in the Goum\u00e9nissa appellation in central Makedon\u00eda, where it is blended with XINOMAVRO. Negoska\u2019s deep colour, fairly high alcohol, and relatively soft tannins and acidity make it a good partner for the higher-acid and more highly structured Xinomavro. Recommended producers in the region include Boutari and Tatsis, the latter blending Negoska with either Xinomavro or LIMNIO and also making a varietal ros\u00e9. Dalamara make a red Negoska but only in the best vintages.',
    ],
  },
  {
    name: 'NEGRAMOLL',
    synonyms: [
      'Molar',
      'Mollar',
      'Mollar Cano',
      'Mollar de Am\u00e9rica',
      'Mollar de C\u00e1diz',
      'Mollar de Granada',
      'Mollar de Huelva',
      'Mollar ICA',
      'Mulata',
      'Negra Criolla',
      'Negra Mole',
      'Negramolle',
      'Rabo de Ovelha Tinto',
      'Saborinho',
      'Tinta de Madeira',
      'Tinta Negra',
      'Tinta Negra Mole',
    ],
    description: 'The dark-skinned grape of Iberia\u2019s Atlantic islands.',
    'origin and parentage': [
      'Negramoll was thought to be a native variety on the Spanish Islas Canarias and on the Portuguese island of Madeira (under the name Tinta Negra Mole), where it was already widespread in the nineteenth century. However, recent DNA profiling has shown that Negramoll is identical to Mollar (Mart\u00edn et al. 2006), an old variety from Andaluc\u00eda in south-western Spain, possibly from the province of C\u00e1diz, which was first mentioned in 1787 (Fav\u00e0 i Agud 2001): \u2018me ha aportat una carbassa de vi mollar\u2019 (\u2018has brought me a calabash of mollar wine\u2019). It is therefore likely that this variety was introduced from Andaluc\u00eda to the Islas Canarias and to Madeira in or before the eighteenth century. Here it was given the name Negramoll or (Tinta) Negra Mole (officially Tinta Negra since 2000), based on the old Andalusian synonym Mollar Negro.',
      'The name Mollar derives from the Latin mollis, meaning \u2018soft\u2019 and referring to the berries, according to the Spanish ampelographer Sim\u00f3n de Rojas Clemente y Rubio (1807), who described three distinct forms of this variety:',
      '\u2013\u00a0\u00a0\u00a0Mollar Negro, the base type cultivated in various places in the province of C\u00e1diz, also known as Mollar Sevillano in the province of M\u00e1laga',
      '\u2013\u00a0\u00a0\u00a0Mollar Negro Brav\u00edo, with very thin shoots',
      '\u2013\u00a0\u00a0\u00a0Mollar Cano, a multicoloured mutation that can bear black, red, pink and white berries on the same vine.',
      'A pink-berried mutation, Negramoll Rosada, is reported on Tenerife. Such wide diversity indicates that this variety almost certainly originates in Andaluc\u00eda, where it is likely to have been cultivated long before its first mention in 1787. Indeed, Mollar was referred to in the early seventeenth century in Chile, where it had been introduced from Spain (Lacoste et al. 2010): the Cabildo (a Spanish colonial governor) of Santiago requested at a hearing dated 6 June 1614 that the merchants \u2018puedan vender vino mollar y moscatel y albillo .\u00a0.\u00a0. dando tres cuartillos por un real\u2019 (\u2018be allowed to sell wine of mollar and moscatel and albillo .\u00a0.\u00a0. giving three quarts for one real\u2019). There are many other early references to Mollar in South America, confirming its long history of cultivation in Argentina, Bolivia, Chile and Peru (Lacoste et al. 2010). The identity between the South American Mollar and the original variety from Andaluc\u00eda has recently been proved by DNA profiling (Mart\u00ednez, Cavagnaro et al. 2006; Tapia et al. 2007). Since the old name Mollar is hardly used today in Andaluc\u00eda, the more widespread Negramoll is used here as the prime name.',
      'Negramoll is a parent of QUEBRANTA (see MUSCAT pedigree diagram) and it was used (under the name Tinta de Madeira) to breed EGIODOLA.',
    ],
    'where its grown': [
      'In Spain, Negramoll is the main red variety in the Tacoronte-Acentejo DO on the island of Tenerife and is authorized or permitted in every other DO on the Islas Canarias, although quality-conscious growers seem to be turning their attention more towards varieties such as Vijariego Negro (SUMOLL) or Baboso Negro (ALFROCHEIRO) and the majority of varietal wines or Negramoll-dominated blends are found in La Palma DO on the island of the same name. There were 1,243 ha (3,072 acres) in Spain in 2008, all on the Islas Canarias. Wines tend to be light, soft and aromatic (though the berries can sometimes accumulate high sugar levels) and are best drunk young. Producers of varietal wines and Negramoll-dominated blends include Carballo, El Hoyo and Tenegu\u00eda on La Palma and El Mocanero in the Tacoronte-Acentejo DO. In most other instances, the wines are part of a local blend.',
      'Under the name Mollar (Mollar Cano in the official Spanish register of commercial varieties), there were 486 ha (1,201 acres) in Spain in 2008, the great majority in Extremadura in the south-west. Wines can be high in alcohol and are mostly low in acidity.',
      'In Portugal, Saborinho is authorized for IGP A\u00e7ores and the Pico co-operative include it in their Terras de Lava blend.',
      'Under the name Tinta Negra Mole, the variety dominates another Atlantic island\u2019s vineyards, those of Madeira. Although according to official statistics there were just 277 ha (684 acres) in 2009, cultivated in Funchal and C\u00e2mara de Lobos in the south of the island and in S\u00e3o Vicente in the north, and the variety is rarely acknowledged in wine names, it was still by far the most widely planted on the island and is responsible for 80\u201385% of all wine produced on Madeira. (The second most planted is VERDELHO, with 47 ha/116 acres.) This dominance was particularly marked after the island was hit by phylloxera in the late nineteenth century, when growers preferred to replant the more robust, dark-skinned Tinta Negra Mole rather than the traditional, light-skinned varieties, SERCIAL, VERDELHO, Boal (MALVASIA FINA), Malvasia C\u00e2ndida (MALVASIA DI LIPARI), TERRANTEZ and Moscatel (MUSCAT BLANC \u00c0 PETITS GRAINS). Despite official financial encouragement to replant these, winemakers such as Ricardo Diogo Freitas of Barbeito are learning to love Tinta Negra Mole and to turn it into ambitious and high-quality Madeira. Producers of varietal Negra Mole Madeira include Barbeito and Justino Henriques.',
      'Some in Portugal (Rolando Faustino, personal communication) suggest that Negra Mole in the Alentejo is a different variety altogether and the official statistics for 2010 list it separately (610 ha/1,507 acres).',
      'In South America, Mollar is still found in Peru but mainly distilled to produce pisco.',
    ],
  },
  {
    name: 'NEGRARA TRENTINA',
    synonyms: [
      'Doleara',
      'Doveana',
      'Dovenzana',
      'Edeleschwarze',
      'Keltertraube',
      'Zoveana',
    ],
    description:
      'Trentino variety that lost significant ground post-phylloxera in the early twentieth century.',
    'origin and parentage': [
      'The name Negrara, from niger (Latin for \u2018black\u2019), refers to the dark colour of the berries but it\u2019s a name that has been applied to numerous distinct varieties (known collectively as Negrare), mainly NEGRARA VERONESE in the Veneto and Negrara Trentina in Trentino-Alto Adige. Negrara Trentina was first mentioned by Pollini (1818). DNA parentage analysis has shown convincingly that ENANTIO is either a parent or a progeny of Negrara Trentina (Grando et al. 2006).',
    ],
    'where its grown': [
      'Negrara Trentina was widely cultivated in Trentino-Alto Adige, Italy, before the phylloxera invasion, after which it was replaced by other varieties when it was found that grafting onto American rootstocks, the remedy for phylloxera, severely diminished the vine\u2019s vigour. Today Negrara Trentina plantings are much smaller than those of NEGRARA VERONESE but it is still found, trained on traditional pergolas, throughout Trentino, mainly in the Valle del Sacra, in the Valdadige (Etschtaler) and in the Valle dei Laghi. Few varietal wines are made but Pravis\u2019 Negrara, classified as IGT Vigneti delle Dolomiti, is based on Negrara Trentina blended with other local varieties. Otherwise it may be used as a minor blending component (maximum 15% but usually less) in DOCs such as Valpolicella and Garda Colli Mantovani.',
    ],
  },
  {
    name: 'NEGRARA VERONESE',
    synonyms: ['Terodola'],
    description: 'Minor Veneto blending component, useful for colour.',
    'origin and parentage': [
      'Negrara Veronese (see NEGRARA TRENTINA for the etymology) was first mentioned by Pollini (1824). DNA analysis suggested a close relationship between Negrara Veronese and GRUAJA, a recently rescued variety from the Vicenza and Breganze area (Salmaso et al. 2008).',
    ],
    'where its grown': [
      'Negrara Veronese is cultivated in the province of Verona and to a lesser extent in the provinces of Vicenza and Padova in the Veneto in northern Italy. It is authorized as a minor blending component in the Bardolino, Breganze Rosso, Valpolicella (including Amarone and Recioto) and Valdadige DOCs and rarely made as a varietal wine. The variety was once much more widely planted and used to increase colour but is now typically restricted to small parcels of older vineyards because its quality and high yields (therefore poor ripening) have not encouraged new plantings. Tedeschi, for example, have less than 1% of this variety in their older vineyards.',
    ],
  },
  {
    name: 'N\u00c9GRETTE',
    synonyms: [
      'Cap de More',
      'D\u00e9go\u00fbtant',
      'Morelet',
      'Morillon',
      'Mourrelet',
      'N\u00e9gralet',
      'N\u00e9gret',
      'N\u00e9gret de Gaillac',
      'N\u00e9gret du Tarn',
      'N\u00e9grette de Fronton',
      'Noirien',
      'Pinot St George',
      'Vesparo Noir',
    ],
    description:
      'Supple, fruity and distinctive variety that has thrived for centuries just north of Toulouse.',
    'origin and parentage': [
      'The name N\u00e9gret or N\u00e9grette, from the local dialect negre meaning \u2018black\u2019, is confusing because it has been given to a large number of distinct varieties such as COT, GRACIANO (aka Morrastel in the Languedoc), Mourvaison, MOUYSSAGU\u00c8S, etc., making the task of interpreting historical documents very difficult. The N\u00e9grette variety described here, from the Toulouse area in southern France, is found in the Haute-Garonne and the Tarn-et-Garonne (Lavignac 2001) and is a member of the Cot ampelographic group (see p XXVII; Bisson 2009). According to Viala and Vermorel (1901\u201310), this N\u00e9grette is said to have been cultivated in Gaillac since time immemorial but its origin is unknown, and no genetic study has yet been carried out to shed light on its pedigree.',
    ],
    'where its grown': [
      'N\u00e9grette is the principal variety in the vineyards north of Toulouse in south-western France, especially around the towns of Fronton and Villaudric, where it is the principal variety in the Fronton appellation (it must comprise 50\u201370% of a grower\u2019s vineyard) and must constitute at least 30% of the blend in Lavilledieu to the west. In contrast to the other main dark-skinned variety of the region, TANNAT, N\u00e9grette is also deeply coloured but is supple and perfumed, with only moderate acidity, and best drunk young, with the fruit to the fore, occasionally showing an animal or floral (violets?) note. These characteristics have also led many growers to blend it with more structured varieties such as SYRAH, COT, CABERNET SAUVIGNON or CABERNET FRANC, although the percentages are strictly limited by the appellation rules. According to Strang (2009), Fronton wines are sometimes described locally as \u2018the Beaujolais of Toulouse\u2019. Total French plantings in 2009 were 1,227 ha (3,032 acres) and the area seems to be declining slightly. The Cave Coop\u00e9rative de Fronton make good-value examples of N\u00e9grette blends and one varietal. Other recommended producers include Ch\u00e2teaux Bellevue La For\u00eat (who have 59 ha/196 acres of N\u00e9grette), Baudaire, Cahuzac, Domaine de Callory, Ch\u00e2teaux Caze, Joliet, Plaisance and le Roc.',
      'N\u00e9grette is also, somewhat surprisingly, planted in California, where it was known as Pinot St George until 1997. DeRose make a varietal from old Cienega Valley vines. Kenneth Volk\u2019s from San Benito vines has plenty of sweet dark fruit but also a hint of sage.',
    ],
  },
  {
    name: 'NEGRETTO',
    synonyms: [
      'Negretta',
      'Negrettino',
      'Negrettino Bolognese',
      'Negrettino Erioli',
    ],
    description:
      'Ancient but now minor Emilia-Romagna variety for the table or early-drinking wines.',
    'origin and parentage': [
      'The name Negretto has historically been applied to several distinct black-berried varieties. The one found in south-east Emilia-Romagna was supposedly mentioned by Pietro de Crescenzi in 1303. Negretto was among the most highly praised local varieties in the nineteenth century, when it was used both as a table grape and for wine.',
    ],
    'where its grown': [
      'After the phylloxera invasion, Negretto was widely planted because of its robustness and good resistance to powdery mildew. Today the vineyard area has decreased significantly and Negretto is cultivated mainly in the province of Bologna and in the hills near Ravenna, and to a lesser extent around the cities of Forl\u00ec and Imola in the Emilia-Romagna region of Italy. The wines are deeply coloured, have lightish tannins and are not generally suited to ageing. They are usually blended with ALBANA, ALIONZA, BARBERA or SANGIOVESE. There were 303 ha (749 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'NEGROAMARO',
    synonyms: [
      'Abruzzese',
      'Albese',
      'Jonico',
      'Lacrima',
      'Negro Amaro',
      'Nigroamaro',
      'Purcinara',
      'Uva Olivella',
    ],
    description:
      'Makes sweet-tasting, early-drinking reds and some good ros\u00e9s on the heel of Italy.',
    'origin and parentage': [
      'Negroamaro is one of the most important varieties in Puglia in southern Italy, where it was mentioned as early as the nineteenth century. Its numerous synonyms suggest that Negroamaro is an old and historically widespread variety. Its etymology and origin are disputed: some authors suggest a simple etymology from negro (\u2018black\u2019) and amaro (\u2018bitter\u2019), while others suppose a Greek etymology and origin from mavro (\u2018black\u2019), despite the redundancy of \u2018black black\u2019, referring to the historical links between Puglia and Greece. Since Negroamaro\u2019s DNA shows no relation to modern Greek varieties, the \u2018black-bitter hypothesis\u2019 seems more logical. In addition, a variety named Negro Dolce (\u2018black sweet\u2019) was documented in Salento in the nineteenth century, probably to distinguish it from the bitter amaro one.',
      'Recent DNA profiling has suggested a possible parent\u2013offspring relationship with SANGIOVESE (Myles et al. 2011) but this can be rejected by comparison with other published DNA profiles (Vouillamoz).',
    ],
    'where its grown': [
      'Negroamaro is cultivated mainly in the provinces of Lecce and Brindisi in the eastern part of the Salento peninsula in Puglia in southern Italy, and to a lesser extent in the Puglian provinces of Bari and Taranto. It is the dominant variety in a number of DOCs, including Alezio, Brindisi, Copertino, Leverano, Salice Salentino and Squinzano, and a minor component, blended with MALVASIA NERA DI BRINDISI, for example, in several others. Cantele\u2019s Amativo (IGT Salento) is a particularly successful example of a Primitivo (TRIBIDRAG) and Negroamaro blend, Primitivo supplying the structure. There were 16,760 ha (41,415 acres) in Italy in 2000, a considerable reduction from the 31,000 ha (76,603 acres) in 1990 \u2013 thanks to the EU vine-pull scheme.',
      'The deeply coloured wines tend to taste sweet and velvety but can age quite rapidly. The best wines come from bushvines planted near enough to the sea to be cooled at night. Notable producers include Cantele, Due Palme, Castello Monaci, Taurino, Tormaresca and Vallone. The variety is also responsible for some good, emphatically fruity ros\u00e9s and some dramatic dried-grape sweet wines such as Vallone\u2019s Gratticaia.',
      'In Australia, Italian specialist Parish Hill in the Adelaide Hills produced their first vintage of Negro Amaro in 2005 and are continuing to extend their plantings of this variety, which seems to retain its acidity well even in the heat \u2013 which is probably why it is so happy in southern Italy.',
    ],
  },
  {
    name: 'NER D\u2019ALA',
    synonyms: [
      'Barau',
      'Dur\u00e1s',
      'Fiori',
      'Gros Vien',
      'Neir\u00e9t dal Picul Rus',
      'Provin\u00e8',
      'Pruin\u00e8',
      'Uva di Biella',
      'Verd\u00e9s',
      'Vernassa',
    ],
    description: 'Virtually extinct vine now known to come from Piemonte.',
    'origin and parentage': [
      'Ner d\u2019Ala has long been considered to be indigenous to the Valle d\u2019Aosta, where it was first mentioned by Gatta (1838) but recent ampelographic and DNA studies have shown that it is identical to Vernassa from the Canavese region in the province of Torino in Piemonte and to Uva di Biella around Biella (Moriondo 1999; Labra et al. 2002; Schneider and Mannini 2006). Its name could derive from Val d\u2019Ala (Piemonte) but today this valley has no vineyards, or it could derive from ala (meaning \u2018wing\u2019) in reference to the winged shape of the bunch. DNA parentage analysis suggests a close genetic link between Ner d\u2019Ala and AVARENGO, confirming the Piemontese origin of Ner d\u2019Ala (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Although it is virtually extinct in the Valle d\u2019Aosta, Ner d\u2019Ala does thrive in a few old vineyards in the lower part of the valley between the communes of Arnad and Montjovet, as well as in Ch\u00e2tillon, Saint-Denis, Quart and Saint-Christophe. Its main cultivation area is now in Piemonte, Italy, in the Alto Canavese (Carema, Parella, Serra and Ivrea), and to a lesser extent in the province of Biella and in Castagneto Po in the province of Torino. There were just 9 ha (22 acres) in Italy in 2000, according to the agricultural census, and the grapes are used in blends. As Vernassa, it may be found in blends with NEBBIOLO, eg in the Canavese Rosso DOC.',
    ],
  },
  {
    name: 'NERELLO CAPPUCCIO',
    synonyms: [
      'Mantiddatu Niuru',
      'Nerello Mantellato',
      'Nirello Cappucio',
      'Niureddu',
      'Niureddu Ammatiddatu',
      'Niureddu Capucciu',
    ],
    description:
      'The lesser, softer of Sicily\u2019s Nerellos, frequently planted with other, as yet unknown, varieties.',
    'origin and parentage': [
      'Nerello Cappuccio is typical of the Etna region on the Italian island of Sicilia and was already mentioned in the province of Catania in the eighteenth century. While Nerello obviously comes from nero (\u2018black\u2019), the name cappuccio (\u2018hood\u2019) probably derives from the bloom that covers the bunches like a coat (mantello), hence the synonym Nerello Mantellato. A recent DNA survey of thirty-four vines of Nerello Cappuccio sampled in commercial vineyards in Sicilia showed that they consist of a mix of true Nerello Cappuccio with five other distinct and unidentified varieties (Branzanti et al. 2008). Nerello Cappuccio has therefore been described as a \u2018polyphyletic variety\u2019. However, such terminology is not appropriate in modern ampelography (see pp XIV\u2013XVI) and it must be concluded that Nerello Cappuccio vineyards might contain several distinct varieties.',
    ],
    'where its grown': [
      'Nerello Cappuccio is grown in both Calabria, particularly in the provinces of Reggio Calabria and Catanzaro, and on Sicilia, Italy, notably in the north-easterly provinces of Catania and Messina, where it is often mixed with the more widely planted and better-quality NERELLO MASCALESE (and others) \u2013 in the vineyard and/or in the vat. Wines are typically moderate in colour, black-fruited, softer and less precise than Nerello Mascalese (\u2018the Merlot to Mascalese\u2019s Cabernet\u2019, as one Etna producer puts it). Not generally suited to a solo career. Benanti and Fessina are unusual in making a varietal wine on Sicilia. Nerello Cappuccio is authorized in several DOs, including Etna, Faro, Lamezia, Savuto and Scavigna, this last a blend with GAGLIOPPO. There were 1,559 ha (3,852 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'NERELLO MASCALESE',
    synonyms: [
      'Mascalese Nera',
      'Mascalisi',
      'Nerello Calabrese',
      'Nerello Carbunaru',
      'Nerello di Mascali',
      'Nerello Nostrale',
      'Nerello Paesano',
      'Niureddu',
      'Niureddu Mascalese',
    ],
    description:
      'Important, noble Sicilian of particular significance around Etna. The firmer, longer-lived Nerello.',
    'origin and parentage': [
      'Nerello Mascalese, first mentioned in the eighteenth century, is indigenous to the Etna region of north-east Sicilia. While the name Nerello obviously comes from nero, meaning \u2018black\u2019, the name Mascalese derives from Mascali, a commune east of Etna and close to the coast (Zappal\u00e0 2005), its likely centre of origin.',
      'The parentage of Nerello Mascalese (under the name Nerello Calabrese) was recently revealed by DNA analysis (Cipriani et al. 2010): it is a possible natural cross between SANGIOVESE and MANTONICO BIANCO. This makes Nerello Mascalese a sibling of GAGLIOPPO from Calabria and it explains why Sangiovese is sometimes called Nerello in Sicilia or Negrello in Calabria. A recent DNA survey of 111 samples of Nerello Mascalese from commercial vineyards on Sicilia showed that they consist of a mix of true Nerello Mascalese and five other distinct but undetermined varieties (Brancadoro 2006). More recently, genetic comparison has suggested that Nerello Mascalese could be related to CARRICANTE (Maitti et al. 2009).',
    ],
    'where its grown': [
      'Nerello Mascalese is cultivated mainly on Sicilia, Italy, in particular in the Etna area in the province of Catania. It is the dominant variety in Etna Rosso DOC, although it may be blended with NERELLO CAPPUCCIO and other local varieties. It plays a significant but lesser role in DOCs such as Faro, Marsala and Sambuca Contea di Sclafani, with the last allowing varietal versions. The variety is also cultivated in Calabria, mainly in Lamezia and Sant\u2019Anna di Isola di Capo Rizzuto, together with Nerello Cappuccio. There were 4,387 ha (10,840 acres) in Italy in 2000, according to the official census, and Sicilia recorded 3,985 ha (9,847 acres) in 2008.',
      'The generally light-coloured and alcoholic wines have fresh acidity, red-fruit flavours, gentle tannins and an obvious nobility, making it possible to drink them young yet also capable of ageing gracefully. Its ability to communicate terroir is especially prized by the swelling ranks of Etna producers. Benanti and Tenuta Terre Nerre make notable varietal wines; other top producers include Calabretta, Cornelissen, Graci, Passopisciaro, Girolamo Russo and Terre di Trente. Some producers prefer to blend with the richer and fruitier NERO D\u2019AVOLA but Etna purists can be scathing about the simplicity of the latter.',
    ],
  },
  {
    name: 'NERET DI SAINT-VINCENT',
    synonyms: ['Neiret', 'Neret', 'Neretto', 'Neyret'],
    description: 'Obscure, recently identified, burly Aostan.',
    'origin and parentage': [
      'The name Neret (or Neretto, Neiret, Neyret) has been given to several distinct varieties in Piemonte and in the Valle d\u2019Aosta. A DNA survey of ten vines called Neret in the Valle d\u2019Aosta showed that three of them corresponded to the three distinct varieties CHATUS (called Neret Pinerolese in Piemonte), PETIT ROUGE and BONARDA PIEMONTESE, while seven turned out to have the same and unique DNA profile (Moriondo et al. 2008). This variety matched the description of Neret given by Gatta (1838), and it was renamed Neret di Saint-Vincent to avoid any confusion. DNA parentage analysis has revealed that Neret di Saint-Vincent has a parent\u2013offspring relationship with ROUGE DU PAYS, confirming that it is an old variety of the Valle d\u2019Aosta (Vouillamoz and Moriondo 2011; see PRI\u00c9 for the pedigree diagram).',
    ],
    'where its grown': [
      'Neret di Saint-Vincent is scattered in old vineyards between the communes of Arnad and Montjovet in the Valle d\u2019Aosta in northern Italy. It is usually blended with other local grapes to deepen colour and increase alcohol.',
    ],
  },
  {
    name: 'NERETTA CUNEESE',
    synonyms: [
      'Freisa di Nizza',
      'Freisa Grossa',
      'Freisa Mora',
      'Neretta',
      'Neretto',
      'Neretto di Cavagli\u00e0',
    ],
    description: 'Minor Piemontese that disappears into blends.',
    'origin and parentage': [
      'Neretta Cuneese is local to the provinces of Cuneo and Torino, where it was referred to at the end of the eighteenth century by Nuvolone Pergamo (1787\u201398), Conte di Scandaluzza. Although some of its synonyms suggest a link with FREISA, no close genetic relationship has been proved (Vouillamoz).',
    ],
    'where its grown': [
      'Neretta Cuneese is found throughout Piemonte, Italy, mainly in the Cuneo, Saluzzo, Pinerolo and Val di Susa areas but it is specified in only one DOC, Valsusa, and is usually blended with other local grapes such GRISA NERA, PLASSA, BARBERA or NEBBIOLO. Total plantings of 407 ha (1,006 acres) were recorded in Italy in 2000.',
    ],
  },
  {
    name: 'NERETTO DI BAIRO',
    synonyms: [
      'Ner\u00e9t',
      'Ner\u00e9t Gros',
      'Neretto',
      'Neretto di San Giorgio',
    ],
    description: 'Minor Piemontese used in blends.',
    'origin and parentage': [
      'Neretto di Bairo takes its name from the commune of Bairo in the province of Torino, where it was mentioned in the early nineteenth century. DNA parentage analysis has revealed a parent\u2013offspring relationship between Neretto di Bairo and NEBBIOLO (Schneider, Boccacci et al. 2004), the former being the likely progeny of the latter through a natural cross with a now extinct variety.',
    ],
    'where its grown': [
      'Neretto di Bairo is grown mainly in the Canavese region of Piemonte in northern Italy, particularly in Bairo, San Giorgio and Valperga. It is authorized as part of the blend \u2013 with other local varieties such as BARBERA, BONARDA PIEMONTESE and NEBBIOLO \u2013 in the red and ros\u00e9 versions of the Pinerolese and Canavese DOCs (and with AVARENGO in Pinerolese Rami\u00e8) but is rarely made as a varietal. However, as the various Neretti are not always distinguished in the DOC regulations, it is hard to be certain which one is planted or authorized. The Italian agricultural census of 2000 recorded just 59 ha (146 acres).',
    ],
  },
  {
    name: 'NERETTO DURO',
    synonyms: [
      'Bal\u00f2',
      'Balau',
      'Barbera Rotonda',
      'Bonarda',
      'Bonarda \u2018d Macoun',
      'Dolcetto di Boca',
      'Durasa',
      'Freisone',
      'Peilavert',
      'Uva \u2032d Galvan',
    ],
    description: 'Rare Piemontese with a disproportionate number of synonyms.',
    'origin and parentage': [
      'Neretto Duro, literally \u2018hard little black\u2019, is an autochthonous red variety scattered throughout Piemonte in north-west Italy. Several synonyms of Neretto Duro misleadingly suggest a link to other varieties such as BARBERA, BONARDA PIEMONTESE and DOLCETTO. It is likely that Neretto Duro is identical to Durasa, authorized under this name in the province of Novara, but this has not yet been confirmed (Schneider and Mannini 2006).',
    ],
    'where its grown': [
      'It is cultivated mainly in the Canavese region in the province of Torino, and to a lesser extent in the whole alpine region of Piemonte, Italy, around Asti, Cuneo, Chieri, Pinerolo and Alessandria. The wines are both fruity and spicy, with low alcohol and low acidity, and are usually blended with other local varieties. Confusingly, Ettore Germano produce a wine named Balau, a synonym widely used for Neretto Duro, although it is a blend of DOLCETTO, BARBERA and MERLOT.',
    ],
  },
  {
    name: 'NERETTO GENTILE',
    synonyms: [
      'Ner\u00e9t Cit',
      'Neret\u00ecn',
      'Neretto',
      'Neretto di Cavagli\u00e0',
      'Vermiglia',
    ],
    description: 'Fragile, minor Piemontese.',
    'where its grown': [
      'Neretto Gentile is cultivated mainly in the Canavese area in the province of Torino in Piemonte, north-west Italy, and to a lesser extent in Gattinara, and is generally blended with other local varieties. The wines are usually pale and low in alcohol.',
    ],
  },
  {
    name: 'NERETTO NOSTRANO',
    synonyms: [
      'Freisa Blu',
      'Nebbiul\u00ecn',
      'Ner\u00e9t \u2018d Rean',
      'Ner\u00e9t dal Busc Bianc',
      'Ner\u00e9t di Romano',
      'Ner\u00e9t Gentil',
      'Neretto della Valchiusella',
    ],
    description:
      'Minor Piemontese also known in the south of the Valle d\u2019Aosta.',
    'where its grown': [
      'Neretto Nostrano is cultivated mainly in the Canavese area in the province of Torino in Piemonte, north-west Italy and in La Serra Morenica d\u2019Ivrea, a glacial hill between the town of Ivrea and the Valle d\u2019Aosta. There are also scattered plantings in Carema and in the lower part of the Valle d\u2019Aosta.',
    ],
  },
  {
    name: 'NERKARAT',
    synonyms: ['Nerkarata'],
    description: 'Cold-hardy Armenian hybrid used mainly for sweet reds.',
    'origin and parentage': [
      'Nerkarat, meaning \u2018rich in colour\u2019, is a complex hybrid obtained in 1960 by S A Pogosyan at the Armenian viticultural research centre at Merdzavan, just west of Armenia\u2019s capital Yerevan, by crossing Seyanets C 1262 with KARMRAHYUT, in which Seyanets C 1262 is a hybrid between a vine of Vitis amurensis Ruprecht and CSABA GY\u00d6NGYE. It is therefore a sibling of CHARENTSI.',
    ],
    'where its grown': [
      'Thanks to its relatively high sugar levels, Nerkarat is well suited to the production of dessert wines in Armenia. Ijevan Wine Factory, for example, in the north-eastern province of Tavush, produce both a dry and a semi-sweet varietal wine.',
    ],
  },
  {
    name: 'NERO BUONO DI CORI',
    synonyms: ['Nero Buono', 'Nero di Cori'],
    description:
      'Minor central Italian generally blended with Montepulciano or Cesanese.',
    'where its grown': [
      'Nero Buono di Cori, literally \u2018the good black of Cori\u2019, is cultivated almost exclusively in the commune of Cori in the province of Latina in Lazio, central Italy, and in the neighbouring region of Castelli Romani. 20\u201340% may be included in the blend of Cori DOC and it is authorized as the dominant variety or part of the blend in the various styles of red and ros\u00e9 Castelli Romani DOC. Varietal wines are few and far between but include Marco Carpineti\u2019s ros\u00e9 Os Rosae and Poggio Le Volpi\u2019s Baccarossa (both IGT Lazio). The agricultural census recorded 123 ha (304 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'NERO D\u2019AVOLA',
    synonyms: [
      'Calabrese',
      'Calabrese d\u2019Avola',
      'Calabrese di Vittoria',
      'Calabrese Dolce',
      'Niureddu Calavrisi',
    ],
    description:
      'Sicilia\u2019s most widely planted red wine variety valued for its colour, full body and ageing potential.',
    'origin and parentage': [
      'Nero d\u2019Avola, named after and most likely originating from the city of Avola in the province of Siracusa on the Italian island of Sicilia, was first described by Sicilian botanist Francesco Cupani (1696) under the name Calavrisi, the dialect word for Calabrese (\u2018from Calabria\u2019), which was the name given to this variety in the eighteenth and nineteenth centuries and could point to a possible Calabrian origin. However, some have suggested that Calavrisi could derive from Calaulisi, which became Caia-Avola, meaning \u2018grape from Avola\u2019 (see Carimi et al. 2011). Although this variety is listed in the official Italian register of varieties under the name Calabrese, we have opted for Nero d\u2019Avola because Calabrese is used for other varieties such as SANGIOVESE or CANAIOLO NERO and because Nero d\u2019Avola is the name most often encountered on wine labels. A recent DNA survey of Nero d\u2019Avola showed a high level of genetic diversity in Sicilia, with several distinct clones intermixed among other distinct and undetermined varieties (possibly seedlings?) in the vineyards (Carimi et al. 2011).',
    ],
    'where its grown': [
      'Nero d\u2019Avola is Sicilia\u2019s most planted red variety and widely grown in the provinces of Siracusa (Avola, Noto, Pachino, etc.), Ragusa, Caltanisetta, Agrigento and Catania \u2013 all over the island, in fact. To succeed it needs a good site and warmth, which is why it is often planted close to the ground. Total plantings in Italy in 2000 amounted to 11,410 ha (28,195 acres) but have risen considerably since then, with 19,304 ha (47,701 acres) in Sicilia in 2008.',
      'Until just a few decades ago, Nero d\u2019Avola was used mainly to provide colour to other wines and was often exported to Toscana, Piemonte or even to France, where it strengthened the much lighter wines of the Languedoc plains. By the 1990s it was enjoying the spotlight of fashion, however. Although it is still often blended with FRAPPATO, and sometimes with other local varieties such as NERELLO MASCALESE and PERRICONE, Nero d\u2019Avola is now respected in its own right and is being made successfully as a varietal wine, generally deeply coloured, full-bodied and with good ageing potential. At its best, Nero d\u2019Avola produces wines that have a wild plum and sweet chocolate character, high levels of tannins, and decent acidity. At its worst, it can be a rather facile red with aromas of candy. An increasing number of producers, eg Abbazia Santa Anastasia, Baglio di Pianetto and Cusumano, are blending Nero d\u2019Avola with international varieties such as CABERNET SAUVIGNON and MERLOT.',
      'Not surprisingly given its significance on the island, it is the dominant variety in many Sicilian DOCs, including Contea di Sclafani, Erice, Riesi, and Cerasuolo DOCG. It may also be made as a varietal wine in DOCs such as Eloro, Erice, Menfi and Vittoria. Recommended producers include Calatrasi, Cos, Cusumano, Donnafugata, Feudo Montoni, Gulfi, Planeta, Rudini, Scurati, Tasca d\u2019Almerita and Vero.',
      'Nero d\u2019Avola is also grown to a lesser extent in the province of Reggio Calabria, where it is authorized in the blend for Bivongi DOC.',
      'Small outposts of this heat-loving variety can be found in Australia, where it has been planted by the Simians at Politini Wines in the King Valley, Victoria; by Jeffrey Grosset in the Clare Valley; and by at least four producers in McLaren Vale (Coriole, Kay Brothers, Pertaringa and Sabella). It has also found favour in one or two hotspots in California, eg in Tracy Hills AVA in the Central Valley, as sold by Jacuzzi. It is also found in Turkey and on Malta.',
    ],
  },
  {
    name: 'NERO DI TROIA',
    synonyms: [
      'Sommarrello',
      'Sumarello',
      'Summariello',
      'Tranese',
      'Troiano',
      'Uva della Marina',
      'Uva di Barletta',
      'Uva di Canosa',
      'Uva di Troia',
    ],
    description:
      'High-quality, flavourful, firm northern Puglian that has declined considerably in the last 40 years.',
    'origin and parentage': [
      'Nero di Troia has nothing to do with the legendary city of Troy in ancient Greece, but most likely originates from the village of Troia in the province of Foggia in Puglia, southern Italy, which is coincidentally and neatly said to have been founded by Diomedes, who destroyed Troy!',
      'Until the second decade of this century it was most commonly known as Uva di Troia but those growing it, perhaps inspired by the recent fashion for Sicilian NERO D\u2019AVOLA, decided that renaming it Nero di Troia, already a local name for it, might make it more attractive to consumers.',
    ],
    'where its grown': [
      'Nero di Troia is cultivated almost exclusively in northern Puglia in southern Italy, mainly in the provinces of Bari and Barletta-Andria-Trani, as well as in the area around Lucera and Cerignola in the province of Foggia (including Troia). It plays a significant and often dominant role in DOCs such as Cacc\u2019\u00e8 Mmitte di Lucera, Castel del Monte (particularly), Rosso di Barletta, Rosso Canosa, Rosso di Cerignola and Orta Nova. It has also been found in Benevento in Campania under the name Summariello, the identity of the varieties having been revealed by DNA profiling (Antonella Monaco and Jos\u00e9 Vouillamoz, unpublished data). The Italian agricultural census of 2000 recorded 1,782 ha (4,403 acres) but the area has declined sharply since 1970, when there were over 9,000 ha (22,240 acres).',
      'Because the wines tend to be relatively high in tannin and to keep their freshness only in youth, most Nero di Troia is blended with other varieties but some varietal Nero di Troia wines can be impressive. Alberto Lungo\u2019s Le Cruste is among the best, with aromas of camomile, verveine, liquorice, juniper, a dense and velvety texture thanks to very fine tannins, and a dry persistent finish. Rivera\u2019s Puer Apuliae is another benchmark varietal. Zagaria\u2019s Vigna Grande is full of complex flavours of sour cherries, dried herbs and olives and is remarkably fresh this far south, with slightly rustic tannins. Other superior examples include Santa Lucia\u2019s Vigna del Melograno (Castel del Monte) and Tarantino\u2019s Petrigama (IGT Puglia).',
    ],
  },
  {
    name: 'NEUBURGER',
    synonyms: ['Brubler', 'Neubursk\u00e9'],
    description: 'Nutty, full-bodied but soft Austrian white.',
    'origin and parentage': [
      'Neuburger was long been thought to be a cross between PINOT BLANC and SILVANER, but DNA profiling proved it to be a natural cross between ROTER VELTLINER and Silvaner (Sefc et al. 1997) that probably took place in Austria. Neuburger is therefore a sibling of FR\u00dcHROTER VELTLINER, because they have the same parents, a half-sibling of ROTGIPFLER and ZIERFANDLER, because they have Roter Veltliner as a common parent, and a grandchild of SAVAGNIN (see Silvaner, and see the PINOT pedigree diagram).',
      'Tradition has it that in the 1860s, a bundle of vines was found on the bank of the Donau (Danube) at Oberarnsdorf in the Wachau by the winemakers Christoph Ferstl and Franz Marchendl, who produced the first wine in 1872. It was later planted on the other side of the river in Spitz, in a place named Burg, from where it supposedly got its name. Neuburger was used to breed the Czech variety AURELIUS.',
    ],
    'where its grown': [
      'Austria has 990 ha (2,446 acres), a gradually declining total, with two-thirds spread across most regions of Nieder\u00f6sterreich (Lower Austria) but particularly in the Thermenregion, and the rest in Neusiedlersee and Neusiedlersee-H\u00fcgelland. Wines are generally golden, full-bodied but gentle, nutty, sometimes with a touch of spice. The best examples develop well over a few years in bottle, becoming more velvety and even more nutty. There are 360 ha (890 acres) in Morava in the south east of the Czech Republic and it is also grown in Slovakia, where it is known as Neubursk\u00e9. It is also found in Romania (46 ha/114 acres), mainly in Transilvania.',
    ],
  },
  {
    name: 'NEW YORK MUSCAT',
    synonyms: ['New York 12997', 'NY 12997', 'NY Muscat'],
    description:
      'American hybrid with pronounced Muscat flavours grown mainly in Canada.',
    'origin and parentage': [
      'A MUSCAT OF HAMBURG \u00d7 Ontario hybrid made in 1926 by Richard Wellington at Cornell University, Geneva, US (see CAYUGA WHITE for the pedigree of Ontario) and released in 1961. New York Muscat is therefore a Vitis labrusca and Vitis vinifera hybrid.',
    ],
    'where its grown': [
      'According to grape breeder Lon Rombough, New York Muscat has the best Muscat flavour of any hybrid. It is planted mainly in Nova Scotia and Qu\u00e9bec in Canada, where it makes notable sweet wines, including icewines, and is also valued as a table grape thanks to its grapey flavours and only moderate acidity. Producers of varietal wines include Domaine de Grand Pr\u00e9 and Jost in Nova Scotia.',
    ],
  },
  {
    name: 'NIAGARA',
    synonyms: ['Niagara White', 'White Concord'],
    description: 'The foxiest American hybrid of them all.',
    'origin and parentage': [
      'A CONCORD \u00d7 Cassady hybrid bred in 1866 by Claudius L Hoag and B Wheaton Clark, private growers in Lockport, Niagara County, New York, in which Cassady is a selected white seedling of Vitis labrusca. Named after its county of origin, Niagara was first sold commercially in 1882 by the Niagara Grape Company founded in 1879 by Hoag, Clark and colleagues.',
      'The company had vineyards in New York and in Florida and had a singular operating policy: the vines were not sold but were placed with growers who would hand over to the company the proceeds from half the net sales of the grapes. This novel and exclusive method of grape distribution proved very successful but by 1915 the market for the Niagara variety was saturated and the company folded.',
    ],
    'where its grown': [
      'This labrusca variety has noticeably foxy and floral flavours, perhaps the most foxy of all the American hybrids, especially straight after picking if not fully ripe, and is best blended with a more neutral variety. Wines have lower acidity than most American hybrids. Niagara is used for juice and the table as well as for wine, which accounts for the high acreage in New York (3,468 acres/1,403 ha in 2006) and Michigan (3,520 acres/1,424 ha in 2006 but only 60 acres for wine). It is found to a lesser extent in states such as Pennsylvania, Ohio, Indiana, Illinois and Iowa. Producers of varietal wines in the US include Honeywood in Oregon, Lakewood and Schulze in New York State, and Kirkwood in West Virginia.',
      'Niagara is also planted in eastern Canada, often for sweet wines. Ontario had about 320 acres (129 ha) in 2006, only about half of what it was in 2004. It is very significant in that other home of hybrids, Brazil, where 2,839 ha (7,015 acres) were grown in 2007 in Rio Grande do Sul. It is also found in Japan but only a small proportion is planted for wine (128 ha/316 acres in 2009), mainly in Nagano and the most northerly Hokkaido prefectures.',
    ],
  },
  {
    name: 'NIEDDERA',
    synonyms: ['Nieddaera', 'Nireddie'],
    description: 'Recently revived and promising Sardinian.',
    'origin and parentage': [
      'Nieddera is an old and typical variety of the western coast of Sardegna. Nieddu et al. (2007) suggested it was closely related to Nieddu Mannu. However, a comparison of DNA profiles shows that Nieddu Mannu is identical to PASCALE (Vouillamoz), leading to the conclusion that Nieddera is probably closely related to Pascale. Other writers have suggested a Spanish origin but Nieddera\u2019s DNA does not match any known Spanish variety (Vouillamoz).',
    ],
    'where its grown': [
      'Nieddera is authorized in the provinces of Cagliari, Nuoro and Oristano on the island of Sardegna, Italy, but it is cultivated almost exclusively in the Valle del Tirso in the province of Oristano, in particular in the communes of Cabras, Solanas and Nuraxinieddu near the west coast. On the brink of extinction just a few decades ago, it was rescued by Contini, which today is one of the very few producers of Nieddera Rosso (IGT Valle del Tirso), a blend of 90% Nieddera with other local red varieties. Contini\u2019s wine, matured in a mix of French oak and chestnut casks, is typically full-bodied but well balanced with aromas of liquorice and cherries and a long finish. The Cantina Sociale della Vernaccia Oristano also make Nieddera Rosso. There were 60 ha (148 acres) planted in Italy in 2000.',
    ],
  },
  {
    name: 'NIGRA',
    synonyms: ['Incrocio Cosmo 96'],
    description: 'Very minor, little-planted, dark-skinned Veneto cross.',
    'origin and parentage': [
      'Nigra is a MERLOT \u00d7 BARBERA cross obtained in the 1960s by Italo Cosmo at the Conegliano research centre in the Veneto, northern Italy, with the aim of increasing the acidity of Merlot. It is a sibling of PRODEST.',
    ],
    'where its grown': [
      'There are just a few hectares planted in the province of Treviso and fewer than 10 ha (25 acres) were recorded in Italy in 2000.',
    ],
  },
  {
    name: 'NIN\u010cU\u0160A',
    synonyms: ['Lincu\u0161a', 'Mlin\u010devac', 'Vincu\u0161a'],
    description:
      'Now virtually extinct, rather ordinary variety from the region of Split, on Croatia\u2019s Adriatic coast.',
    'origin and parentage': [
      'The origin of Nin\u010du\u0161a is unknown, and it is assumed that it was found as a wild grapevine by someone called Nin\u010devi\u0107, and later spread throughout the Split area.',
    ],
    'where its grown': [
      'Used both for wine and for the table, Nin\u010du\u0161a was one of the most widespread varieties in the Split area on Croatia\u2019s Adriatic coast a hundred years ago, mainly around the towns of Omi\u0161, Sinj and Ka\u0161tela. Today it has virtually disappeared even though it is authorized in the Dalmatinska Zagora (Dalmatian Hinterland) and Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine regions.',
    ],
  },
  {
    name: 'NITRANKA',
    synonyms: ['CAAB 3/22'],
    description:
      'Recently authorized and increasingly popular Slovakian cross.',
    'origin and parentage': [
      'Nitranka, previously known as Nitra, is a CASTETS \u00d7 ABOURIOU cross obtained in 1976 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia. It is a sibling of HRON, RIMAVA and V\u00c1H and likely to be registered in 2012. The name was changed to avoid confusion on wine labels between the variety and its designation of origin since Nitra is a town and wine region in western Slovakia (Dorota Posp\u00ed\u0161ilov\u00e1, personal communication).',
    ],
    'where its grown': [
      'Nitranka is the most widely planted of the new Slovakian red wine crosses (around 5 ha/12 acres in 2011) and produces wines that are deeply coloured and relatively full-bodied but best drunk young. Producers include Igor Blaho, Mih\u00e1lek Juraj, Chateau Modra and Otto Rube\u0161.',
    ],
  },
  {
    name: 'NOAH',
    synonyms: [
      'Belo Otelo',
      'Charvat',
      'Flaga Alba',
      'Fraga',
      'Noa',
      'No\u00e9',
      'Noka',
      'Nova',
      'Tatar Rizling',
    ],
    description:
      'American hybrid once popular in Europe, notably Spanish Basque Country, but now almost extinct.',
    'origin and parentage': [
      'A seedling of Taylor planted in 1869 by Otto Wasserzieher in Nauvoo, Illinois, where Taylor is a spontaneous Vitis riparia \u00d7 Vitis labrusca hybrid found in the middle of the nineteenth century by Judge Taylor of Jericho in Henry County, Kentucky. Named after the first vintner in the Bible, Noah was disseminated by Bush & Son & Meissner of Bushberg, Missouri, after it first fruited in 1873.',
      'DNA profiling has revealed that the samples of Hondarribi Zuri from the Spanish Pa\u00eds Vasco (Basque Country) analysed by Iba\u00f1ez et al. (2003) and Mart\u00edn et al. (2003) are identical to Noah (Vouillamoz). This explains why Mart\u00edn et al. described Hondarribi Zuri as a possible hybrid variety with the foxy flavour that is specific to Vitis labrusca and with the cuneiform leaves that are typical of Vitis riparia, and why Hondarribi Zuri was completely distinct from all Spanish grape varieties in the genetic tree reconstructed by Iba\u00f1ez et al. (2003).',
      'Noah was used to breed BACO BLANC.',
    ],
    'where its grown': [
      'Like so many other hybrids, Noah was planted in France in the wake of phylloxera, mainly but not exclusively in the Loire. It was also popular in south-west France at the end of the nineteenth century because of its resistance to black rot, which was rampant at that time (Galet 2000). However, also like other American hybrids such as ISABELLA and JACQUEZ, it was banned in the 1930s because of the higher than average level of methanol in the wine (due to greater amounts of skin pectins in some all-American hybrids). Italy still had 17 ha (42 acres) in 2000, according to the agricultural census. The juice has the typical foxy flavour of labrusca varieties, as evident from the Hondarribi Zuri still grown to a limited extent in the Basque Country, northern Spain. There are still limited plantings in Europe, on the island of Madeira, Portugal, for example, but it is not authorized. It is also planted in the Republic of Moldova (71 ha/175 acres in 2009).',
      'Although the variety does not appear to have survived in its state of birth, Renault Winery and Tomasello, both in New Jersey, produce varietal examples.',
    ],
  },
  {
    name: 'NOBLE',
    synonyms: ['North Carolina 20-119'],
    description:
      'Productive Muscadine variety widely grown in North Carolina for juice and wine.',
    'origin and parentage': [
      'A Thomas \u00d7 Tarheel hybrid (mainly Vitis\nrotundifolia with some Vitis labrusca and Vitis vinifera) selected at the North Carolina Agriculture Experiment Station and released in 1971 (see CARLOS for the pedigrees of Thomas and Tarheel).',
    ],
    'where its grown': [
      'Noble is the primary red Muscadine variety used in juice and wine production in North\nCarolina, with 75 acres (30 ha) planted in the state in 2006 and lesser plantings in some more southerly states. Producers of varietal wines include Bannerman, Benjamin, Uwharrie in North Carolina; Rosa Fiorelli in Florida; and Wills Creek in Alabama. Wines are generally full-bodied, soft and often sweet.',
    ],
  },
  {
    name: 'NOBLING',
    synonyms: ['Freiburg 128-40'],
    description: 'Demanding German cross making neutral wines.',
    'origin and parentage': [
      'SILVANER \u00d7 CHASSELAS cross bred in 1940 by Johannes Zimmerman (authorized 1971). Its name was clearly meant to evoke nobility.',
    ],
    'where its grown': [
      'Germany\u2019s 63 ha (156 acres) are in Baden, particularly in the Markgr\u00e4flerland. Wines are light- to medium-bodied and rather neutral, similar to those made from its parent Gutedel (CHASSELAS), although on a good site the berries can achieve respectable levels of sugar and acidity and produce wines with a bit more aroma and refinement. Producers include Bretz, Britzingen, Frick and Kaufmann, all in Baden.',
      'In Switzerland, where a varietal wine is made by R\u00e4blus near the Bielersee in the west of the country, there was less than 1 ha (2.5 acres) in 2008.',
    ],
  },
  {
    name: 'NOCERA',
    synonyms: [
      'Nocera di Catania',
      'Nocera Mantonico',
      'Nocera Nera di Milazzo',
      'Nucera',
      'Nucera Niura',
    ],
    description: 'Minor blending ingredient in Calabria and Sicilia.',
    'origin and parentage': [
      'Nocera is an old variety, supposedly from the province of Messina on Sicilia and introduced to Calabria as early as 1726, although it is not known if it was the true Nocera or MAGLIOCCO DOLCE, given the frequent confusion between the two (Schneider, Raimondi, De Santis and Cavallo 2008).',
    ],
    'where its grown': [
      'On Sicilia, Italy, it is recommended in the provinces of Siracusa and Messina, and in Calabria in all provinces except Cosenza. It used to be widely cultivated in the province of Messina in the nineteenth century, especially in the area of Milazzo, where old vines still thrive today. Its cultivation began to decrease after the Second World War, and today plantings are very limited \u2013 30 ha (74 acres) on Sicilia and in southern Italy in 2000, according to the agricultural census.',
      'In Sicilia, Nocera plays a minor part in the blends for Faro and Mamertino di Milazzo DOCs, blended with NERELLO MASCALESE and NERELLO CAPPUCCIO in the former and with NERO D\u2019AVOLA in the latter. In Calabria, Nocera is cultivated mainly around the communes of Longobardi and Amantea, and may contribute to the blend in the DOCs of Bivongi (up to 30%, with GRECO NERO and/or Nero d\u2019Avola, etc.) and Sant\u2019Anna di Isola Capo Rizzuto (40\u201360% with GAGLIOPPO, Nerello Mascalese, etc.).',
    ],
  },
  {
    name: 'NOIR FLEURIEN',
    synonyms: [
      'Damas Rouge',
      'Fleurien Noir',
      'Mire-Fleurien',
      'Mirefleurien',
      'Noir-Fleurien',
    ],
    description: 'Ancient and very rare Auvergne vine.',
    'origin and parentage': [
      'Noir Fleurien, an ancient variety from the Auvergne in central France, was first mentioned and christened in 1895 by Professor Girard-Col of Clermont-Ferrand, who had found it in the village of Mirefleurs in the Puy-de-D\u00f4me, hence the synonym Mirefleurien (R\u00e9zeau 1997). Girard-Col propagated this variety for its resistance to downy mildew and chlorosis. It also used to be known as Bordelais and Gros Rouge, names that were given to various other varieties in the area as well. DNA profiling shows that the reference vine labelled Damas Rouge in the United States Department of Agriculture\u2019s National Clonal Germplasm Repository (NCGR) in Davis, California, is in fact identical to Noir Fleurien (Vouillamoz). In addition, DNA parentage analysis has shown that Noir Fleurien is one of the numerous progenies of GOUAIS BLANC (see the PINOT pedigree diagram; Boursiquot et al. 2004).',
    ],
    'where its grown': [
      'Noir Fleurien is now virtually extinct (fewer than 3 ha/7 acres remaining in France) but still found in its birthplace of Mirefleurs in the Puy-de-D\u00f4me, where it is grown mostly for domestic consumption. However, a few kilometres to the north in Saint-Georges-sur-Allier, Jean Maupertuis blends Mirefleurien with Gamay d\u2019Auvergne (GAMAY NOIR) in his Pierres Noires red blend, as does Patrick Bouju at Domaine de la Boh\u00e8me in nearby Glaine-Montaigut. Traditionally wines were light in colour, alcohol and acidity but results can be much more interesting when yields are curbed (Kelley 2009a).',
    ],
  },
  {
    name: 'NOIRET',
    synonyms: ['NY 73.0136.17'],
    description:
      'Promising new American hybrid producing peppery, well-structured dry reds.',
    'origin and parentage': [
      'A complex hybrid obtained in 1973 by Bruce Reisch, R S Luce, B Bordelon and Thomas Henick-Kling at Cornell University, Geneva, New York State, by crossing New York 65.0467.08 with STEUBEN, where:',
      '\u2013\u00a0\u00a0\u00a0New York 65.0467.08 is a CHANCELLOR \u00d7 New York 33277 hybrid',
      '\u2013\u00a0\u00a0\u00a0New York 33277 is a New York 10589 \u00d7 Seibel 6339 hybrid',
      '\u2013\u00a0\u00a0\u00a0New York 10589 is a Ripley \u00d7 Golden Chasselas hybrid, the latter being PALOMINO FINO (but see also CHASSELAS)',
      '\u2013\u00a0\u00a0\u00a0Ripley is a Winchell \u00d7 MOORE\u2019S DIAMOND hybrid (see CAYUGA WHITE for the pedigree of Winchell)',
      '\u2013\u00a0\u00a0\u00a0Seibel 6339 is a Seibel 867 \u00d7 Seibel 2524 hybrid',
      '\u2013\u00a0\u00a0\u00a0Seibel 867 is a Vivarais \u00d7 NOAH hybrid (see PRIOR for the complete Vivarais pedigree)',
      '\u2013\u00a0\u00a0\u00a0Seibel 2524 is an Alicante Ganzin \u00d7 DUTCHESS hybrid (see ROYALTY for the pedigree of Alicante Ganzin).',
      'Noiret is therefore an extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris and Vitis cinerea hybrid.',
      'The original seedling vine was germinated in 1974 and tested for wine characteristics in 1980 under the number NY 73.0136.17. Noiret was named and released in 2006 together with COROT NOIR and VALVIN MUSCAT (Reisch et al. 2006a, 2006c).',
    ],
    'where its grown': [
      'According to Reisch et al. (2006b), the wines are deeply coloured with aromas of green and black pepper, as well as raspberry, blackberry and mint. They tend to have fine tannins and to be free of the aromas typical of many hybrids. There were 39 acres (16 ha) of Noiret in New York State in 2006, 12 acres (5 ha) in Pennsylvania in 2008, as well as scattered plantings in the Northeast and Midwest. Producers of varietal wines include Two Saints in Iowa; Arbor Hill, Deer Run and Fulkerson in Finger Lakes, New York; Smoky Hill in Kansas; Peaceful Bend in Missouri; and Candia in New Hampshire.',
    ],
  },
  {
    name: 'NORTON',
    synonyms: [
      'Arkansas',
      'Cynthiana',
      'Norton Virginia',
      'Norton\u2019s Seedling',
      'Norton\u2019s Virginia Seedling',
      'Red River',
      'Virginia',
      'Vitis Nortoni',
    ],
    description:
      'Old, very successful American hybrid making rich, vinifera-like reds in a wide range of US states.',
    'origin and parentage': [
      'This variety was named after Daniel Norborne Norton (1794\u20131842), a physician and horticulturist near Richmond, Virginia, who discovered it around 1820 and promoted it as a wine grape. According to Norton, it was a hybrid of Bland, a Vitis labrusca \u00d7 Vitis vinifera hybrid, and PINOT MEUNIER, under the translated name of Miller\u2019s Burgundy, which were growing next to each other in his garden (Prince 1830). However, a recent DNA study has shown that Norton is a hybrid variety whose ancestry includes Vitis aestivalis and Vitis vinifera, rejecting Pinot Meunier as the vinifera parent but suggesting it might be a seedling of ENFARIN\u00c9 NOIR (Stover et al. 2009).',
    ],
    'where its grown': [
      'Norton, widely grown in the American Northeast and Midwest, is said by many to be the only American vine variety capable of making premium-quality wine, lacking as it does any hint of foxiness, although it does occasionally have a grapey flavour that may come close to it. In 1873, a Norton wine from Missouri was described in a competition in Wien (Vienna) as the \u2018best red wine of all nations\u2019. Europeans had high hopes for the vine after phylloxera but it was soon found to dislike the calcareous soils of many of France\u2019s best vineyards. The fact that Norton is thought to have twice as much of the antioxidant resveratrol as CABERNET SAUVIGNON has helped to maintain its popularity in the US in the context of the red wine and coronary health debate, as has its good all-round resistance to fungal diseases.',
      'It is also the oldest native American vine still widely cultivated for wine, even if it is no longer as significant in its home state as in Missouri, for example, which had 307 acres (124 ha) in 2009; Illinois, 60 acres (24 ha) in 2007; and Iowa, 10 acres (4 ha) in 2006. Limited plantings are also found in Kansas, Nebraska, New Jersey and further south in Kentucky, Tennessee, Texas, Arkansas, Louisiana and Florida. Virginia is regaining pride in Norton and now produces about thirty varietal versions, notably those of Chrysalis and Horton. Wines tend to be ruby rather than crimson and to have rich, ageworthy flavours of spicy red fruits, soft tannins and good freshness. Other recommended producers include Augusta Winery, Crown Valley and Stone Hill in Missouri and Pontchartain in Louisiana.',
    ],
  },
  {
    name: 'NOSIOLA',
    synonyms: [
      'Groppello Bianco',
      'Nosellara',
      'Nosiola Gentile',
      'Nusiola',
      'Spargelen',
    ],
    description:
      'Trentino-Alto Adige variety long appreciated for Vino Santo but now making waves for its characterful dry wine.',
    'origin and parentage': [
      'Together with GROPPELLO DI REV\u00d2, Nosiola is traditionally said to be a descendant of Raetica, the most widespread white wine grape in northern Italy in Roman times. DNA parentage analysis has established that both Groppello di Rev\u00f2 and Nosiola have a parent\u2013offspring relationship with R\u00c8ZE, an old variety of the Valais in the Swiss Alps, whose name most likely derives from Raetica (Vouillamoz, Schneider and Grando 2007). R\u00e8ze was first mentioned in 1313, whereas both Nosiola and Groppello di Rev\u00f2 appear in the nineteenth century. It is therefore logical to consider R\u00e8ze as a parent of Nosiola and Groppello di Rev\u00f2, which explains why Nosiola is sometimes called Groppello Bianco. Although it is now found only in Trentino in northern Italy, it was once also grown in the Alto Adige under the name Spargelen, so either region could be its place of origin.',
      'The name Nosiola most likely derives from the Italian nocciola, meaning \u2018hazelnut\u2019, probably referring to the toasted-hazelnut flavour of the wine, or, more improbably, since the berries do not go brown, to the colour of the mature berries. An alternative and much less convincing etymology is based on the local dialect ociolet, meaning \u2018little eye\u2019, possibly referring to an unidentified eighteenth-century variety called Uva dall\u2019Occhio Bianca (meaning \u2018white eye\u2019s grape\u2019), which supposedly gave ciaret and then nosiolet by \u2018phonetic contamination\u2019 (Giavedoni and Gily 2005).',
    ],
    'where its grown': [
      'In Trentino, Italy, Nosiola is cultivated in the Val di Cembra, Vallagarina, Merano and most significantly in the Valle dei Laghi, between Lago di Garda and Trento, where Nosiola is used \u2013 usually as the sole variety \u2013 to produce the rare Vino Santo version of Trentino DOC, a slow-fermented and barrel-aged dessert wine known since the seventeenth century and made from grapes that are concentrated, losing 60\u201380% of their original weight, by drying on racks and by the effects of noble rot. Such wines are not usually put on the market until they are 7\u201310 years old.',
      'Today, Nosiola grapes for this Vino Santo are cultivated on about 10 ha (25 acres), mainly in Calavino, Cavedine, Lasino, Padergnone and Vezzano by only a handful of determined producers: Gino Pedrotti, Pisoni, Francesco Poli, Giovanni Poli, Pravis, Cantina Toblino and Cantina Viticoltori Cavit. The total annual production is just 30,000 half-bottles. With a century of uninterrupted production, Pisoni make an outstanding Vino Santo Trentino with complex aromas of apricot, quince, lime, pineapple and orange peel \u2013 a dense mouthful superbly balanced by excellent acidity.',
      'In recent years there has been increasing interest in growing Nosiola to produce fragrant, zesty dry wines, often with a slight aroma of hazelnuts, within the Trentino DOC, mainly in the hills around the village of Pressano. Cesconi are producing particularly good examples of this style, as are Pojer & Sandri. Nosiola is also a potential ingredient in the rare Sorni Bianco style within the same DOC and in Valdadige/Etschtaler DOC. Giuseppe Fanti make a varietal wine, classified as IGT Vigneti delle Dolomiti, that is perfumed and citrusy but also has richer, sweeter fruit flavours of peach and apricot.',
      'Other producers of respectable dry varietal wines include Battistotti, Spagnoli, Toblino, Pravis, Concilio, Endrizzi and Zeni.',
      'Total plantings in Italy in 2000 were 193 ha (477 acres).',
    ],
  },
  {
    name: 'NOTARDOMENICO',
    synonyms: ['Notar Domenico'],
    description: 'Minor variety of unknown origin found in Puglia today.',
    'origin and parentage': [
      'Notardomenico\u2019s earliest mention appears in Di Rovasenda (1877). The orgin of the variety is unknown but it may have been named after the fourteenth-century Puglian writer Notar Domenico.',
    ],
    'where its grown': [
      'Notardomenico is a rare variety found in Puglia in southern Italy, traditionally cultivated in the Brindisi area, mainly for wine but also occasionally as a table grape. Today only a few vines survive in the more common Ottavianello (CINSAUT) vineyards. Notardomenico is cultivated mainly between Cisternino, Ostuni and San Vito dei Normanni north west of Brindisi, and is authorized as a minor component (maximum 15%) in the Ottavianello version of Ostuni DOC. The Italian agricultural census recorded just 15 ha (37 acres) in 2000.',
    ],
  },
  {
    name: 'NURAGUS',
    synonyms: [
      'Abbondosa',
      'Axina \u2019e P\u00f2berus',
      'Axina Scacciad\u00e8ppidus',
      'Preni Tineddus',
    ],
    description: 'Ancient, fertile and generally unremarkable Sardinian.',
    'origin and parentage': [
      'Nuragus is one of the oldest varieties of Sardegna. Its name most likely derives from the eponymous village near Cagliari, although it is often said to derive from nuraghe, the name of the typical truncated conical towers dry-built from volcanic stones in prehistoric times. Some of its local synonyms reflect its high yields: Axina \u2019e P\u00f2berus means \u2018grapes of the poor\u2019 and Axina Scacciad\u00e8ppidus means \u2018debt-paying grapes\u2019.',
      'Recent DNA profiling (De Mattia et al. 2007) has shown that two other distinct and non-cultivated varieties share the name Nuragus: Nuragus Arrubiu and Nuragus Moscadeddu (also called Nuragus Rosso Rompizzolla or Nuragus Moscatello).',
    ],
    'where its grown': [
      'Nuragus is the second most widely cultivated variety on Sardegna, Italy, with total plantings of 3,272 ha (8,085 acres) in 2000, according to the agricultural census. It is found mainly in the provinces of Cagliari and Oristano in the south and west of the island. If yields of this fertile and generally unremarkable variety are not controlled, wines tend to be bland and unbalanced. Its main expression is as the varietal wine Nuragus di Cagliari DOC \u2013 dry or sweet, still or sparkling \u2013 but it may also be blended with other local varieties such as SEMIDANO, which has the potential to produce higher-quality wines but is less generous in its crop. The best examples, such as Argiolas\u2019s S\u2019elegas and Cantine di Dolianova\u2019s Perlas, display fresh fruit flavours, often have a lightly bitter finish and are best drunk young.',
      'It is also used to produce vermouth and dry sparkling wines.',
    ],
  },
  {
    name: 'ODESSKY CHERNY',
    synonyms: [
      'Alibernet',
      'Od\u011bskij \u010cornyj',
      'Odessa Black',
      'Odesskii Chernyi',
      'Semenac 1-17-4',
    ],
    description:
      'Red-fleshed, versatile Ukrainian cross that reveals its Cabernet parentage.',
    'origin and parentage': [
      'Odessky Cherny, meaning \u2018black of Odessa\u2019, is an ALICANTE HENRI BOUSCHET \u00d7 CABERNET SAUVIGNON cross obtained in 1948 by M P Tsebriy, P K Ayvazyan, A N Kostyuk, E N Dokuchaeva, M I Tulaeva and A P Ablyazova at the Tairov research centre in Odessa, southern Ukraine, and officially registered in 1972. Four clones are currently cultivated. While the name Odessky Cherny is widespread in its homeland, the variety is known in Slovakia under the name Alibernet (officially registered there in 1975), a contraction of the parents\u2019 names. It was used to breed NERONET.',
    ],
    'where its grown': [
      'Odessky Cherny, widely planted in Ukraine (2,426 ha/5,995 acres in 2009) and recommended in the Odessa, Nikolaev and Kherson regions and in Krym (Crimea), is used to make fresh, deeply coloured, good-quality dry, semi-sweet, sweet and fortified reds with dark-fruit flavours, sometimes a note of tomato, and moderate tannins. Sun Valley (Solnechnaya Dolina) use it in both sweet and fortified blends while Kolonist\u2019s dry version combines black-fruit flavours with a herbaceous, minty note.',
      'Although plantings are much more limited than in Ukraine, Alibernet is popular in Slovakia for deepening the colour of red blends, for its Cabernet-like structure and dark-berry fruit flavours that marry well with oak as well as for late-harvested varietal wines such as Hlohovec Food Farm\u2019s example under the Golguz label.',
    ],
  },
  {
    name: 'OEILLADE NOIRE',
    synonyms: [
      'Aragnan Noir',
      'Ouillade',
      'Ouillard',
      'Ouliade',
      'Passerille Noire',
    ],
    description:
      'Disappearing, dark-skinned, southern French variety more popular as a table grape.',
    'origin and parentage': [
      'Oeillade Noire is an old variety from Provence, southern France, where it used to be cultivated in the Var, Vaucluse, Gard, H\u00e9rault and the Vall\u00e9e du Rh\u00f4ne. According to R\u00e9zeau (1997), its first mention supposedly dates back to 1544 in the \u2018Chant de vendanges\u2019 (\u2018harvest song\u2019) by Bonaventure des P\u00e9riers as \u2018\u0153illades, c\u00e9page de la vall\u00e9e du Rh\u00f4ne\u2019 (\u2018\u0153illades, variety of the vall\u00e9e du Rh\u00f4ne\u2019), but it is not known whether this \u2018\u0153illades\u2019 corresponded to Oeillade Blanche (a synonym for PICARDAN) or Oeillade Noire, which are distinct cultivars. The first reliable mention appeared in Pierre Magnol (1676): \u2018Plures ali\u00e6 [vites] sunt nigr\u00e6 quibus vinum rubrum conficitur, ut tarret, piquepoule, efoirou, ouliade, ali\u00e1que plurima\u2019 (\u2018Many other black grape varieties are used to make red wines, such as tarret, piquepoule, efoirou, ouliade, and many others\u2019).',
      'The name Oeillade most likely derives from the French verb ouiller, meaning \u2018to top up\u2019, the very productive Oeillade Noire being used to refill the vats or barrels (Lavignac 2001). It is much less likely to be derived from the French \u0153il, meaning \u2018eye\u2019 or \u2018bud\u2019, for which there\u2019s no logical explanation.',
      'Oeillade Noire is not the black version of Oeillade Blanche, a synonym of PICARDAN, and is not identical to CINSAUT, often sold under the name Oeillade Noire, especially for table grapes in the south of France.',
    ],
    'where its grown': [
      'Traditionally grown around Saint-Chinian in the Languedoc, France, and valued for its ability to ripen, albeit at modest alcohol levels, on higher sites that did not suit CINSAUT, Oeillade virtually disappeared in the twentieth century. However, Thierry Navarre of Domaine Navarre at Roquebrun, north west of B\u00e9ziers in the Languedoc, has recently started bottling a varietal wine \u2013 fruity and for early drinking, lightly chilled. It is not included in the official French register of varieties and so cannot be used in any appellation wine.',
    ],
  },
  {
    name: 'OFTHALMO',
    synonyms: [
      'Oftalmo',
      'Ophtalmo',
      'Ophtalmon',
      'Optalmo',
      'Pephtalmo',
      'Pophtalmo',
    ],
    description:
      'Rare Cypriot variety whose soft, light reds are generally hidden in blends.',
    'origin and parentage': [
      'Ofthalmo is indigenous to the island of Cyprus, where it was first mentioned by the French ampelographer Pierre Mouillefert (1893) under the name Pophtalmo, meaning \u2018bull\u2019s eye\u2019.',
      'According to DNA profiling, Ofthalmo is genetically relatively close to SPOURTIKO, also from Cyprus (Hvarleva, Hadjinicoli et al. 2005).',
    ],
    'where its grown': [
      'Ofthalmo is scattered across the wine areas in the western half of Cyprus but the better wines come from Pitsilia and the villages in the Lemes\u00f3s (Limassol) region (Constantinou 2006). Wines are aromatic but light in colour and body, with low acidity, which is probably why they are generally blended with other local varieties such as MAVRO and MARATHEFTIKO (eg by Chrysorroyiatissa, Kolios and Zenon) or with international varieties such as CABERNET SAUVIGNON and Grenache (GARNACHA) (eg by Kamanterena, Nelion and Vasilikon). Total plantings in 2010 were just 126 ha (311 acres) and the quality of the wines suggests this variety may well slowly disappear.',
    ],
  },
  {
    name: 'OHRIDSKO CRNO',
    synonyms: ['Prespanka'],
    description: 'Macedonian variety that was a casualty of phylloxera.',
    'origin and parentage': [
      'Ohridsko Crno, literally \u2018black from Ohrid\u2019, is probably indigenous to the area around Lake Ohrid in the far south west of the Republic of Macedonia on the border with Albania.',
    ],
    'where its grown': [
      'Before the advent of phylloxera, Ohridsko Crno was widely planted in the Ohrid region of the Republic of Macedonia but now survives only in pockets in Ohrid, Struga and Resen. Wines are generally light in colour, fresh and fruity with moderate alcohol and often blended with PROKUPAC and VRANAC.',
    ],
  },
  {
    name: 'OJALESHI',
    synonyms: ['Chonouri', 'Odjaleshi', 'Odzhaleshi', 'Sconuri', 'Svanuri'],
    description: 'Georgian variety suspected of Turkish origins.',
    'origin and parentage': [
      'Ojaleshi, meaning \u2018growing on trees\u2019 in the Megrelian dialect, is thought to be indigenous to Samegrelo, near Georgia\u2019s Black Sea coast, but a recent DNA study has shown it to be genetically close to several Turkish varieties (Vouillamoz et al. 2006), suggesting it may have been introduced from a region now part of neighbouring Turkey but once part of Georgia.',
    ],
    'where its grown': [
      'Ojaleshi is well suited to the subtropical climate of Samegrelo, north-western Georgia, particularly in the villages of Salkhino, Tamakoni and Abedati, where it is used to make sweet ruby-red wines that have plenty of red-fruit flavours and sometimes a peppery or spicy note. It seems to have good potential for dry wines, too. It is also planted further east in Orbeli in the Racha-Lechkhumi region, where it develops rose aromas. As its name suggests (see above), it was traditionally grown up trees, and its survival was mainly thanks to a Frenchman, Achille Murat, who started trellising it in the mid nineteenth century (Smithsonian Institution 2009). Recommended producers include Khareba, Tbilvino and Telavi Wine Cellar. There were around 130 ha (321 acres) in Georgia in 2004.',
    ],
  },
  {
    name: '\u00d6K\u00dcZG\u00d6Z\u00dc',
    synonyms: ['Kara Erik'],
    description:
      'Turkish variety with an increasing reputation for its juicy, fresh, aromatic reds.',
    'origin and parentage': [
      '\u00d6k\u00fczg\u00f6z\u00fc, literally \u2018bull\u2019s eye\u2019 because of its large, black berries, most likely originates from the province of El\u00e2z\u0131\u011f in eastern Anatolia. Together with BO\u011eAZKERE, from the same area, it counts among the most important Turkish reds. DNA profiling has shown that the variety called Kara Erik in the neighbouring provinces of Sivas, Erzincan and Malatya is identical to \u00d6k\u00fczg\u00f6z\u00fc (Vouillamoz et al. 2006) but recent ampelographic observations have cast doubts on the true-to-typeness of the Kara Erik sample (Y\u0131lmaz Boz, personal communication).',
    ],
    'where its grown': [
      'Grown both for the table and for wine, \u00d6k\u00fczg\u00f6z\u00fc is a widely planted wine grape in Turkey, with 1,656 ha (4,092 acres) in 2010, mostly in the provinces of El\u00e2z\u0131\u011f and Malat\u00e2ya in eastern Anatolia and, to a lesser extent, in Tunceli (north of El\u00e2z\u0131\u011f), Trakya (Thrace, north of the Sea of Marmara), Kapadokya (Cappadocia), Manisa (western Turkey) and along the Aegean coast. The vineyards in eastern Anatolia, planted mostly on sandy-clay soils over limestone although there is also red clay and decomposed granite, are at 850\u20131,100 m above sea level but here the harsh continental climate is softened by the Euphrates and its dams.',
      'Varietal wines are generally light in colour, medium-bodied and juicy, with bright, aromatic fruit flavours, fresh acidity, moderate alcohol and relatively soft tannins so that they are often blended with the tougher and less acidic BO\u011eAZKERE. \u00d6k\u00fczg\u00f6z\u00fc has been growing in stature since the 1980s and there are now some very good varietal wines, following the lead of pioneer Kavaklidere. Others include Diren, Doluca, Kayra and Melen. On its own, the variety is not particularly ageworthy and is best with little or very subtle oak influence.',
    ],
  },
  {
    name: 'ONCHETTE',
    synonyms: ['Ouchette'],
    description: 'Recently rescued Is\u00e8re variety.',
    'origin and parentage': [
      'Onchette is a rare variety from Is\u00e8re that is close to extinction. DNA parentage analysis has shown that Onchette is one of the numerous natural progenies of GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'Onchette used to be cultivated mainly in Lavars and in Roissard in the Vall\u00e9e du Drac in the Is\u00e8re, central eastern France, although it has now almost disappeared. The association Vignes et Vignerons du Tri\u00e8ves, founded in 2008 with the aim of saving the old vineyards of the Tri\u00e8ves region from oblivion, planted 1.2 ha (3 acres) of Onchette in 2009 near the village of Pr\u00e9bois in the Is\u00e8re.',
    ],
  },
  {
    name: 'ONDENC',
    synonyms: [
      'Blanquette',
      'Irvine\u2019s White',
      'Oundenc',
      'Oundenq',
      'Piquepout de Moissac',
      'Plant de Gaillac',
    ],
    description:
      'Neglected variety persists in south-west France and in isolated spots in Australia.',
    'origin and parentage': [
      'Ondenc is an ancient variety from the Tarn region, where it was first mentioned in 1783 (R\u00e9zeau 1997): \u2018Ondene blanc .\u00a0.\u00a0. c\u2019est un des meilleurs raisins que je connoisse pour manger et faire de bon vin\u2019 (\u2018Ondene blanc .\u00a0.\u00a0. it is one of the best grapes I know to eat and make good wine\u2019). The name Ondenc, meaning \u2018undulating\u2019 in the local dialect, could derive from the abundant and clear juice, or from the undulation of the leaves in the strong, local Autan wind, but this is doubtful \u2013 growers\u2019 descriptions are generally descriptive rather than poetic.',
      'Ondenc is a member of the Folle ampelographic group (see p XXVII; Bisson 2009).',
      'Surprisingly, DNA parentage analysis suggests a possible parent\u2013offspring relationship between Ondenc and ROUSSETTE D\u2019AYZE from Savoie (Vouillamoz).',
    ],
    'where its grown': [
      'Ondenc was preferred over and above MAUZAC BLANC after the phylloxera crisis because it was thought to be less susceptible to the louse responsible but its popularity waned due to poor yields and susceptibility to botrytis bunch rot. It is still officially recommended in much of the south-western quarter of France and permitted as a minor variety in several appellations, including Bordeaux, Bergerac, Gaillac, Montravel and Vins de Lavilledieu. However, just 10 ha (25 acres) remained in France in 2008, down from 1,589 ha (3,927 acres) in 1958.',
      'The variety produces wines that are not very aromatic but in the right conditions may be late harvested for sweet wines. It has been saved from oblivion by a small group of growers, notably Robert and Bernard Plageoles, who, at their Domaine de Tr\u00e8s-Cantous, produce both dry and sweet varietal wines within the Gaillac appellation.',
      'Ondenc was taken to Australia at the end of the nineteenth century and known as Irvine\u2019s White at Great Western and confusingly as Sercial in South Australia. At Seppelt in Victoria it contributed to their Hans Irvine sparkling wine until it was pulled up a few years ago. In the Barossa Valley, Langmeil have produced a traditional-method sparkling wine from 100% Ondenc which they describe as having aromas of honey, beeswax and apples. They plan to replace it.',
    ],
  },
  {
    name: 'OPSIMO EDESSIS',
    synonyms: [
      'Agriostaphylo',
      'Chimoniatiko',
      'Dopio',
      'Foustani',
      'Karatsova Naousis',
      'Karatzovitiko',
      'Ntopia',
      'Opsimo',
      'Opsimos Edessis',
      'Opsimos Lefko',
      'Pandiri',
      'Paschalino',
      'Raisin de Foustani',
      'Raisin de Karatzova',
      'Staphyli Edessis',
      'Staphyli Karatzovas',
      'Valandovski Drenak',
      'Zimsko Belo',
      'Belo Zimsko',
    ],
    description: 'Inferior northern Greek variety also grown for the table.',
    'origin and parentage': [
      'Opsimo Edessis, literally \u2018late ripening from \u00c9dessa\u2019, probably comes from Kentrik\u00ed Makedon\u00eda (Central Macedonia) in northern Greece, where it is also called Dopio or Ntopio, meaning \u2018from the country\u2019 (Galet 2000). Comparison of the DNA profiles of Opsimo Edessis, Opsimos Lefko and Karatsova Naousis in the Greek Vitis Database suggests that they are identical (Vouillamoz), this last being also known as Zimsko Belo in the Republic of Macedonia (\u0160tajner, Angelova et al. 2009). Both the synonyms Zismko and Chimoniatiko mean \u2018winter\u2019, possibly referring to the fact that the grapes will keep for up to five or six months for the table.',
    ],
    'where its grown': [
      'Opsimo Edessis, originally a table grape but used to make wine when the market for table grapes dried up, seems to be confined to Kentrik\u00ed Makedon\u00eda in northern Greece, especially around \u00c9dessa, north west of Thessalon\u00edki. However, the wines are generally poor quality \u2013 low in alcohol and acidity \u2013 and are typically used in the production of retsina. There were 228 ha (563 acres) in 2008.',
    ],
  },
  {
    name: 'OPTIMA',
    synonyms: ['Optima 113'],
    description:
      'German cross designed to reach stupendous sugar levels even on poor sites.',
    'origin and parentage': [
      'A (SILVANER \u00d7 RIESLING) \u00d7 M\u00dcLLER-THURGAU cross bred by Peter Morio and Bernhard Husfeld in 1933 at the Geilweilerhof research centre, authorized in Germany in 1971. Parentage has been confirmed by DNA profiling (Grando and Frisinghelli 1998). Optima and BACCHUS are siblings and, having RIESLING twice in their pedigree, are genetically very similar to it. Optima was used to breed ORION.',
    ],
    'where its grown': [
      'Germany\u2019s 64 ha (158 acres) are planted mainly in Rheinhessen, Mosel-Saar-Ruwer and the Pfalz, a marked decline from 184 ha (455 acres) in 2001. There were more than 400 ha (988 acres) in 1990 but it has mainly been used to boost sugar levels in a blend, hence its current decline. Even though, or perhaps because, it can reach a high level of ripeness, the wines tend to be flabby and undistinguished or just plain sweet. Producers of varietal wines include Butzelt in Franken and Karlheinz Schneider in the Nahe.',
      'It is also planted to a limited extent in England (2 ha/5 acres), where it is sometimes made into a late-harvest-style sweet wine, and also in British Columbia, Canada, where there are 14 acres (6 ha).',
    ],
  },
  {
    name: 'ORANGETRAUBE',
    synonyms: ['Gelbe Orangetraube', 'Narancssz\u0151l\u0151', 'Orangentraube'],
    description:
      'Variety with a much-discussed history but now strictly limited geography.',
    'origin and parentage': [
      'Johann Philipp Bronner (1792\u20131864) was a German pharmacist and ampelographer from Neckargem\u00fcnd in Baden-W\u00fcrttemberg in southern Germany. Since the 1840s, he had carefully studied the wild grapevines growing along the Rhein between Mannheim and Rastatt (Bronner 1857). Bronner found thousands of red-berried vines, and only three white-berried ones, the most interesting being the one found near Speyer (south of Mannheim in Rheinland-Pfalz) that had yellow, round and sweet berries, with a strong aroma of orange-blossom oil. Bronner had established his own classification system of varieties and gave it the Latin name Zaehringia nobilis, in honour of the old and influential German ruling family Z\u00e4hringen, and he gave it the common name Orangetraube for its particular aroma. The first official use of this name was made by Friedrich Jakob Dochnahl (1848), who described Orangetraube as a new and valuable table grape.',
      'Orangetraube has always been considered to be a wild grapevine, thus belonging to the Vitis vinifera subsp. silvestris. However, this is highly improbable because wild grapevines are usually dioecious, having separate male and female plants, and they never have light-skinned berries. In addition, several cultivated varieties were found to be morphologically similar to Orangetraube:',
      '\u2013\u00a0\u00a0\u00a0KNIPPERL\u00c9 (called Ortlieber in Germany), according to the famous ampelographer Hermann Goethe (1878), who promulgated Orangetraube in Austria',
      '\u2013\u00a0\u00a0\u00a0PINOT (called Blauburgunder), according to August Wilhelm Freiherr von Babo and Edmund Mach (1923)',
      '\u2013\u00a0\u00a0\u00a0R\u00c4USCHLING, according to Ambrosi et al. (1997).',
      'Since the DNA profile of Orangetraube published by Sefc et al. (2000) does not match any other known cultivated variety or wild grapevine (Vouillamoz), and since it resembles several cultivated varieties, we suggest that Orangetraube originated from a grape seed that was randomly eaten by a bird in a neighbouring vineyard and ejected in the forest along the banks of the Rhein. A basic DNA parentage analysis based on a mere ten DNA markers suggests that the parents of Orangetraube could be PINOT, possibly PINOT GRIS because of the colour and aroma of the berries, and CHASSELAS (Vouillamoz), although at least thirty or forty markers would be necessary to confirm this hypothesis. This parentage would be absolutely consistent with historical data. Indeed, Chasselas (aka Gutedel) has been cultivated in Baden-W\u00fcrttemberg since the sixteenth century (Kr\u00e4mer 2006), and Pinot Gris is said to have been first introduced to Germany by a grape collector in Speyer (Babo 1843\u20134), exactly where Orangetraube was found, before being spread by a merchant called Ruland, hence its synonym Rul\u00e4nder in Baden-W\u00fcrttemberg. La boucle est boucl\u00e9e!',
      'Orangetraube was crossed with Welschriesling (GRA\u0160EVINA) to breed GOLDBURGER.',
    ],
    'where its grown': [
      'In Austria, on the south side of the capital, Wien (Vienna), Richard Zahel is one of the few producers of a varietal wine, though the variety is also planted to a very limited extent in Burgenland, further south, and around Klosterneuburg north west of Wien. Zahel describes his wine as having intense stone-fruit flavours and racy acidity.',
    ],
  },
  {
    name: 'ORANIENSTEINER',
    synonyms: ['Geisenheim 11-34', 'Hochkroner'],
    description: 'German cross that seems to have a found a niche in Canada.',
    'origin and parentage': [
      'A RIESLING \u00d7 SILVANER cross bred in 1985 at the Geisenheim research centre, probably named after Schloss Oranienstein in Rheinland-Pfalz. Oraniensteiner, OSTEINER and RIESLANER are siblings.',
    ],
    'where its grown': [
      'Bred in Germany though not yet authorized there for quality wine production, Oraniensteiner is planted to a limited extent in the Okanagan Valley in British Columbia, Canada, where Sonoran Estate, for example, produce a varietal icewine and Joie include it in their off-dry Edelzwicker-style Noble Blend. It\u2019s also authorized in Ontario. In Hungary there are a few vines near Lake Balaton. Wines have good acidity and strong citrus notes.',
    ],
  },
  {
    name: 'ORION',
    synonyms: ['Geilweilerhof GA-58-30'],
    description:
      'Little-planted cool-climate German hybrid similar to M\u00fcller-Thurgau.',
    'origin and parentage': [
      'Orion is a complex OPTIMA \u00d7 VILLARD BLANC hybrid bred by Gerhardt Alleweldt in 1964 at the Geilweilerhof research centre. However, Orion is categorized as vinifera in the UK because of its vinifera-like wines. It was named after the giant hunter in Greek mythology, killed by Artemis and now the name of a constellation.',
    ],
    'where its grown': [
      'Orion was authorized in 1994 but there are still only a few hectares in Germany, mostly in Rheinhessen, where Dr Lawall produce a varietal wine, and there are a few lonely vines in Switzerland (one tenth of a hectare/a quarter of an acre). It has been slightly more popular in England, where there were 9 ha (22 acres) in 2007. Denmark had just 2 ha (5 acres) in 2010. There are also limited plantings in Sweden. Wines are fresh, sometimes slightly floral but relatively soft. According to Basler (2003), it is in decline because its resistance to disease has been disappointing.',
    ],
  },
  {
    name: 'ORLEANS GELB',
    synonyms: [
      'Gelber Orleans',
      'Hart-Heunscht',
      'Hartheinisch',
      'Harthengst',
      'Hart-H\u00e4ngst',
      'Harthinsch',
      'Orleaner',
      'Orl\u00e9ans Jaune',
      'Orleanser',
      'Orleanstraube',
      'Weisser Orleans',
    ],
    description:
      'Ancient but now very minor, high-acid German vine replaced by Riesling. The oldest vines in Germany may be Orleans Gelb.',
    'origin and parentage': [
      'Despite being named after the city of Orl\u00e9ans in central northern France, Orleans Gelb (gelb means \u2018yellow\u2019 in German) comes from the Rheingau region, where it was widely cultivated until the nineteenth century, especially in the R\u00fcdesheimer Berg vineyard, near R\u00fcdesheim, its historical base. Its first mention most likely appeared under its old synonym Harthinsch in Hieronymus Bock\u2019s (1539) Kreutterbuch: \u2018Harthinsch umb T\u00fcrckheim und Wachenheim\u2019 (T\u00fcrckheim here is not the village in Alsace but an old spelling of D\u00fcrkheim, a village close to Wachenheim in the Pfalz.)',
      'The name Harthinsch, also spelt Hartheinisch in Neuw Kreutterbuch (1588) by Jacobus Theodorus (also known as Tabernaemontanus), is undoubtedly a contraction of hart Heunisch meaning \u2018hard Gouais Blanc\u2019. This name refers to the thickness of the berry skins and to a similarity with GOUAIS BLANC, still called Heunisch in Germany. According to Aeberhard (2005), the name was changed to Hart-H\u00e4ngst in 1697, Harthengst in 1751, Hartheinst in 1781 and then back to Hart-Heunscht in Bassermann-Jordan (1923). The name Orleans can be confusing because it has also been given to different grape varieties in the past (mainly PINOT), and the first use of this name for the variety under discussion here was in Ludwig von Babo (1843\u20134), who described three types within the variety (Gelber Orleans, Gr\u00fcner Orleans and Sp\u00e4ter Weisser Orleans), which are most likely mutations of berry colour and ripening period (gr\u00fcn meaning \u2018green\u2019, weiss meaning \u2018white\u2019 and sp\u00e4t meaning \u2018late\u2019).',
    ],
    'where its grown': [
      'Orleans Gelb was once widely cultivated in some of the best vineyards of the Rheingau, Germany (Assmannshausen, Nierstein, R\u00fcdesheimer Berg, Scharlachberg bei Bingen and Schloss Johannisberg), where it was planted together with RIESLING, GOUAIS BLANC (Heunisch), ELBLING, SILVANER, PINOT GRIS (Rul\u00e4nder), SCHIAVA GROSSA (Trollinger) and SAVAGNIN BLANC (Traminer). By the end of the nineteenth century, Orleans Gelb had been largely replaced by Riesling, which produced better-quality, aromatic wine that commanded higher prices, and there is no record of the variety being vinified after 1921 in R\u00fcdesheim.',
      'The variety was then assumed to be extinct until Helmut Becker of the Geisenheim research centre rediscovered old feral vines of Orleans Gelb in the 1980s on the terraces of R\u00fcdesheimer Berg. The first plantings were made in the early 1990s in collaboration with Weingut Knipser in Laumersheim (c.700 vines), and in 1995 in collaboration with Weingut Georg Breuer in R\u00fcdesheim (c.600 vines), who released the first commercial \u2013 oak-aged \u2013 wine in 2002. Knipser describes the wine as rather neutral with marked acidity but this is buffered by the relatively full body. Wines can be quite forceful.',
      'Five very old vines of Orleans Gelb have been discovered growing almost wild by grape researcher Andreas Jung in Disibodenberg near Odernheim am Glan in the Nahe. Based on local historical documents, the planting date of these vines has been estimated at between 1108 and 1559, when the Disibodenberg monastery was still active, and it has been claimed that these grapevines may be the oldest in Germany.',
    ],
  },
  {
    name: 'ORTEGA',
    synonyms: ['W\u00fcrzburg 48-21-4'],
    description:
      'Early-ripening Gerrman cross that can achieve impressive sugar levels if not acidity.',
    'origin and parentage': [
      'A M\u00dcLLER-THURGAU \u00d7 SIEGERREBE cross bred by Hans Breider in 1948 at W\u00fcrzburg, Franken, named after the Spanish philosopher Jos\u00e9 Ortega y Gasset. This parentage has been confirmed by DNA analysis (Myles et al. 2011).',
    ],
    'where its grown': [
      'Ortega can achieve high grape-sugar levels in most years, making it popular in Germany (634 ha/1,567 acres), though less so than in the late 1980s. Half of the German plantings are in Rheinhessen and the rest mostly in the Pfalz. Its tendency towards coulure makes it less appropriate than OPTIMA for the Mosel-Saar-Ruwer region, where there are still a few plantings. It produces soft, full-flavoured, occasionally spicy wines, including sweet, peachy examples from Auslese level to very sweet. Recommended producers in Germany include Scherner-Kleinhanss in Rheinhessen and Frey in the Pfalz.',
      'Its reliable ripeness has led to its adoption in England (27 ha/67 acres), where Biddenden Vineyards have been particularly successful with their offerings and Chapel Down produce a barrel-aged version. It is also grown in Switzerland, Sweden and Denmark (1.5 ha/4 acres in 2010).',
      'It is also found in British Columbia in Canada (51 acres/21 ha), where it is valued for its precociousness and its sugar content.',
    ],
  },
  {
    name: 'ORTRUGO',
    synonyms: ['Altruga', 'Altrugo', 'Artrugo'],
    description:
      'Emilia-Romagnan often blended with the most aromatic Malvasia.',
    'origin and parentage': [
      'The earliest mention of this variety in the province of Piacenza in Emilia-Romagna is to be found under its synonyms Artrugo or Altrugo in an ampelographic bulletin dated 1881, whereas the name Ortrugo does not appear until the early part of the twentieth century, in Toni (1927). The name could derive from altrughe or artrugo, meaning \u2018the other grape\u2019 (altra uva) in local dialect.',
    ],
    'where its grown': [
      'Ortrugo almost disappeared in the mid twentieth century, largely replaced by MALVASIA DI CANDIA AROMATICA, before being rescued in the 1960s and 1970s, thanks mainly to the perseverance of Luigi Mossi in the Val Tidone south west of the city of Piacenza, Italy. Today, Ortrugo is one of the most widely cultivated white varieties in the province of Piacenza, almost exclusively in the Colli Piacentini DOC (Val Tidone, Val Luretta, Val Trebbia, Val Nure, Val Chero, Val d\u2019Arda and Val Stirone), as a sparkling, lightly sparkling or still varietal or as part of a blend (often with Malvasia di Candia Aromatica or with TREBBIANO ROMAGNOLO) and also in Vin Santo and Vin Santo di Vigoleno within the same DOC. It is also planted in the Oltrep\u00f2 Pavese, close to the border with Emilia-Romagna. The Italian census recorded 526 ha (1,300 acres) in 2000.',
      'Good examples are produced by Mossi (still and sparkling), Barattieri, Pusteria (includes 15% TREBBIANO ROMAGNOLO). Sparkling wines tend to be light and fresh whereas the still wines are generally more full-bodied and alcoholic.',
    ],
  },
  {
    name: 'OSELETA',
    synonyms: ['Oselina'],
    description: 'Revived and rather fashionable in Valpolicella country.',
    'origin and parentage': [
      'The name Oseleta probably derives from its synonym Oselina, based on Italian uccelli, meaning \u2018birds\u2019, a name given to several distinct varieties whose berries are often eaten by birds.',
    ],
    'where its grown': [
      'Oseleta was almost extinct before it was recovered in the 1970s in the small village of Pigozzo, north east of Verona in the Veneto, north-east Italy. It is used by some producers in small amounts to add colour and tannins to the blend for Valpolicella, Recioto and Amarone, and Tedeschi, for example, have included the variety in their most recent plantings. Since 2002 Oseleta has been officially authorized and recommended in the Valpolicella area. Zym\u00e8 and Masi produce two of the very rare examples of varietal wines, both classified as IGT Rosso del Veronese. Masi\u2019s Osar, made from vines planted in 1985 and 1990, spends twenty-four months in small new oak barrels but still has a complex aromatic profile from herbs and cinnamon through hedgerow fruit to floral and then leather notes, and pretty firm tannins even after several years in bottle. Zym\u00e8, who planted Oseleta in 1998, estimate a total of 10\u201320 ha (25\u201350 acres) in the Valpolicella region. La Cappuccina include 10% Oseleta in the CARMEN\u00c8RE-based Campo Buri and 50% in their sweet passito wine called Carmenos (blended with Carmen\u00e8re, hence Carmenos).',
    ],
  },
  {
    name: 'OSTEINER',
    synonyms: ['Geisenheim 9-97'],
    description:
      'Very minor German cross with a successful outpost in New Zealand.',
    'origin and parentage': [
      'A RIESLING \u00d7 SILVANER cross created by Heinrich Birk in 1929 at the Geisenheim research centre in the Rheingau, Germany, and authorized in 1984. This parentage has been confirmed by DNA profiling (Myles et al. 2011). It was named after Graf Friedrich Karl von Ostein, who constructed several buildings and gardens in Geisenheim. ORANIENSTEINER, Osteiner and RIESLANER are siblings.',
    ],
    'where its grown': [
      'There is about as much Osteiner in New Zealand as there is in Germany \u2013 about 1 ha (2.5 acres) in each. Rolfe Mills of Rippon Vineyards in Central Otago imported the variety in the mid 1990s. Rippon\u2019s Hotere white, a 100% Osteiner, has aromas of currants and blossom plus a hint of lemon and is made in a fresh but off-dry style. More generally, the wines have good acidity and body.',
    ],
  },
  {
    name: '\u00d6STERREICHISCH WEISS',
    synonyms: ['Kahlenberger Weisse'],
    description:
      'Rare light-skinned Viennese variety with a well-known offspring.',
    'origin and parentage': [
      '\u00d6sterreichisch Weiss (literally \u2018Austrian White\u2019) is an ancient variety cultivated mainly in the region of Kahlenberg near Wien (Vienna), hence its synonym Kahlenberger Weisse. DNA profiling has shown that \u00d6sterreichisch Weiss spontaneously crossed with SAVAGNIN to give birth to SILVANER (Sefc, Steinkellner et al. 1998), and that \u00d6sterreichisch Weiss is one of the numerous progenies of GOUAIS BLANC, the other parent being unknown (Regner et al. 1998).',
    ],
    'where its grown': [
      '\u00d6sterreichisch Weiss was quite widely planted in the vineyards of Kahlenberg on the north side of Wien, capital of Austria, where a popular blend with 10% Traminer (SAVAGNIN) was known as Grinzinger.',
      'Until the 1950s, it was used for the production of sparkling wine, but its importance has greatly diminished today (Sefc, Steinkellner et al. 1998). Alois Raubal (Bioweinbau-Raubal) has recently planted 300 vines of \u00d6sterreichisch Weiss in Gumpoldskirchen south of Wien and produces a varietal wine.',
    ],
  },
  {
    name: 'OTSKHANURI SAPERE',
    synonyms: ['Argvetuli Sapere'],
    description:
      'Minor Georgian variety responsible for deeply coloured, ageworthy reds.',
    'origin and parentage': [
      'Otskhanuri Sapere is indigenous to Imereti, western central Georgia, possibly named after the Otskha River. The Georgian word sapere means \u2018dye\u2019, in reference to the deep black colour of the berries.',
    ],
    'where its grown': [
      'Grown in the western, subtropical part of Georgia, mostly in the villages of Rodinauli, Sviri, Dimi and Obcha in Imereti, Otskhanuri Sapere produces deeply coloured, well-structured and ageworthy red wines with distinctive varietal flavours of forest fruits, cherries and wild grasses. Khareba and Imeretian Wines produce good varietal examples.',
    ],
  },
  {
    name: 'PADEIRO',
    synonyms: ['Padeiro de Basto', 'Tinto Matias'],
    description:
      'Productive but good-quality Portuguese variety makes ros\u00e9 and light-red wines.',
    'origin and parentage': [
      'Padeiro, or Padeiro de Basto, literally \u2018baker from Basto\u2019, comes from the Minho region in north-west Portugal. It should not be confused with ESPADEIRO, also called Padeiro de Basto or Padeiro Tinto in the same region, nor with TINTO C\u00c3O, another synonym for Padeiro de Basto, all three having distinct DNA profiles in Veloso et al. (2010).',
    ],
    'where its grown': [
      'Padeiro juice tends to be high in sugar and low in acidity but the variety is popular for ros\u00e9 and light red-fruited reds in the Minho region of Portugal, where Vinho Verde is produced and whose climate ensures fresher-style wines. Recommended producers include Quinta de Gomariz, who also make a varietal ESPADEIRO ros\u00e9.',
    ],
  },
  {
    name: 'P\u00c1LAVA',
    synonyms: ['Nema', 'Veverka'],
    description:
      'Pink-skinned Czech cross producing full-bodied yet fresh, spicy whites.',
    'origin and parentage': [
      'P\u00e1lava is a SAVAGNIN ROSE \u00d7 M\u00dcLLER-THURGAU cross initially bred in 1953 by Josef Veverka at the \u0160SV research centre in Velk\u00e9 Pavlovice in southern Morava, in the Czech Republic, and the seedlings then moved to Pern\u00e1, as for AURELIUS. It was named after the UNESCO-registered P\u00e1lava Protected Landscape Area (abbreviated CHKO P\u00e1lava in Czech) in southern Morava, and included in the official Czech register of varieties in 1977. P\u00e1lava is a sibling of the Slovak variety M\u00cdLIA.',
    ],
    'where its grown': [
      'P\u00e1lava wines show many of the characteristics of its SAVAGNIN ROSE parent \u2013 full body, spiciness, some rose petal notes \u2013 but are generally slightly higher in acidity and smoother. There were 195 ha (482 acres) in the Czech Republic in 2009, almost all in Morava in the south east. Recommended producers include Karpatsk\u00e1 Perla, Vajbar Bronislav and Znov\u00edn Znojmo. The variety is also found in Slovakia, where Ch\u00e2teau Topo\u013e\u010dianky and Vino Levice make varietal wines.',
    ],
  },
  {
    name: 'PALLAGRELLO BIANCO',
    synonyms: [
      'Pallagrella Bianca',
      'Pallagrello di Avellino',
      'Pallarella',
      'Piedimonte Bianco',
    ],
    description: 'Old, rare Campanian, not unlike Viognier.',
    'origin and parentage': [
      'The name Pallagrello probably derives from pagliarello, the lattice of straw on which the grapes were traditionally dried. The earliest mention of this variety is most likely at the end of the eighteenth century under the name Pallarelli, including both white and black versions. However, Pallagrello Bianco is not a colour mutation of PALLAGRELLO NERO. Both varieties were included in the Vigna del Ventaglio, a vineyard established in 1775 by the architect Luigi Vanvitelli for King Ferdinand I of the Two Sicilies on the slopes of Monte San Leucio, near the royal palace of Caserta in Campania, southern Italy. The vineyard has now disappeared, but an 1826 document described it as a semicircle divided into ten segments so that it looked like a hand-held fan (a ventaglio). Each segment was planted with a different variety, among which were Piedimonte Bianco and Piedimonte Rosso, two old synonyms for Pallagrello Bianco and Pallagrello Nero. Indeed, both varieties are thought to originate in the area between Piedimonte Matese and Alife in the province of Caserta.',
      'Pallagrello Bianco has often been confused with CODA DI VOLPE BIANCA since the bunches of both are shaped like a foxtail (coda di volpe) but DNA profiling has shown that they are distinct varieties, and that Pallagrello di Avellino and Pallagrello di Caserta are also distinct (Costantini et al. 2005). It was later established that Pallagrello di Caserta is in fact identical to PIGNOLETTO, also called Grechetto di Todi (Vouillamoz).',
    ],
    'where its grown': [
      'As with many other indigenous Campanian varieties, cultivation of Pallagrello was drastically reduced during the twentieth century. Both Pallagrello Bianco and PALLAGRELLO NERO were considered to be extinct when they were rediscovered in the 1990s by Peppe Mancini, a local lawyer and passionate winegrower.',
      'Pallagrello Bianco is now cultivated to a very limted extent in the province of Caserta, in Campania, Italy, mainly in the communes of Caiazzo, Castel Campagnano and Castel di Sasso. Rare examples of varietal wines are made by Terre del Principe, who produce both an unoaked and a barrel-fermented version, and Castello Ducale. The wines tend to be high in alcohol and have only moderate acidity, with aromas of peaches and apricots, rather like VIOGNIER. No statistics are available because Pallagrello Bianco was treated as a synonym of CODA DI VOLPE BIANCA in the 2000 census.',
    ],
  },
  {
    name: 'PALLAGRELLO NERO',
    synonyms: [
      'Coda di Volpe Nera',
      'Pallagrella Nera',
      'Piedilungo',
      'Piedimonte Rosso',
    ],
    description:
      'Rare dark-skinned variety from Campania, unrelated to Pallagrello Bianco.',
    'origin and parentage': [
      'The name Pallagrello probably derives from pagliarello, the lattice of straw on which the grapes were traditionally dried. The earliest mention of this variety is most likely at the end of the eighteenth century under the name Pallarelli, including both white and black versions. Although it is also named Coda di Volpe Nera, Pallagrello Nero is a colour mutation of neither CODA DI VOLPE BIANCA nor PALLAGRELLO BIANCO. Together with Pallagrello Bianco, Pallagrello Nero was included in the eighteenth-century Vigna del Ventaglio \u2013 see Pallagrello Bianco for more details.',
      'DNA studies suggest a close genetic relationship between Pallagrello Nero and CASAVECCHIA, another rare and old variety of the province of Caserta in Campania (Masi et al. 2001).',
    ],
    'where its grown': [
      'As with many other indigenous Campanian varieties in Italy, cultivation of Pallagrello Nero was drastically reduced during the twentieth century. Both PALLAGRELLO BIANCO and Pallagrello Nero were considered to be extinct when they were rediscovered in the 1990s by Peppe Mancini, a local lawyer and passionate winegrower.',
      'Pallagrello Nero is now cultivated in the province of Caserta, mainly in the communes of Alife, Alvignano, Caiazzo and Castel Campagnano. It is also cultivated to a lesser extent in the provinces of Avellino and Campobasso. Rare examples of varietal wines, generally classified as IGT Campania, are made by Alois, Castello Ducale and Terre del Principe. Such wines are generally cherry-fruited and sometimes peppery, with smooth but noticeable tannins that allow the wine to age some years in bottle.',
    ],
  },
  {
    name: 'PALOMINO FINO',
    synonyms: [
      'Alb\u00e1n',
      'Albar',
      'Albillo de Lucena',
      'Bianco',
      'Fransdruif',
      'Golden Chasselas',
      'Jerez',
      'Listan',
      'Listan de Jerez',
      'List\u00e1n Blanco',
      'List\u00e1n Comun',
      'List\u00e3o',
      'Listr\u00e3o',
      'Madera',
      'Malvasia Rei',
      'Manzanilla de Sanlucar',
      'Ojo de Liebre',
      'Palomina Blanca',
      'Palomino',
      'Palomino Macho',
      'Palomino Pelus\u00f3n',
      'Temprana',
      'Tempranilla',
      'Xeres',
    ],
    description: 'The sherry grape.',
    'origin and parentage': [
      'Palomino Fino is an old Andalucian variety from southern Spain known under various synonyms, List\u00e1n being the most widespread. According to Permart\u00edn in Garc\u00eda de Luj\u00e1n (1996), Palomino was named after Fern\u00e1n Y\u00e1\u00f1ez Palomino, a knight of King Alfonso X of Castilla (1221\u201384), and was introduced to the Islas Canarias in the late fifteenth century. Two morphological variants of Palomino Fino have been described: Palomino Pelus\u00f3n with hairy leaves and Palomino Macho with poor fruit set.',
      'Palomino Fino is not the white-berried version of Palomina Negra, an old synonym for LIST\u00c1N PRIETO, already described in 1513 by Alonso de Herrera (1790) under the name Palomina Negra, but their DNA profiles do not exclude a close genetic relationship (Vouillamoz).',
      'In California, Golden Chasselas usually refers to Palomino Fino although some vines under that name have turned out to be the true CHASSELAS.',
      'Palomino Fino was used to breed many varieties but of these only CHASAN, COLOMINO and CORREDERA seem to be cultivated today.',
    ],
    'where its grown': [
      'In France in 2009 there were 172 ha (425 acres) of what is known there as Listan or, logically, Listan de Jerez, mainly in Gascogne and the western Languedoc \u2013 a significant reduction from the 1,252 ha (3,094 acres) fifty years ago. It was once much more widely planted for distillation into armagnac but has now been largely replaced by other varieties such as Ugni Blanc (TREBBIANO TOSCANO), COLOMBARD and BACO BLANC.',
      'Palomino Fino was once grown exclusively around Sanl\u00facar de Barrameda in the Jerez region north of C\u00e1diz in south-western Spain and gradually became the variety of choice for sherry production. Although it is only one of three varieties authorized for such wines, it is by far the most widely planted, with 13,926 ha (34,412 acres) in 2008, almost three quarters of that total in Andaluc\u00eda and the rest mostly, and surprisingly given the cooler, wetter climate, in Galicia, in north-west Spain. After phylloxera, Palomino Fino\u2019s productivity and ready availability in nurseries were regarded as sufficient attributes for widespread planting. Although it is giving way to more aromatic and more characterful local varieties in the north west, it is still officially a principal white variety in such DOs as Rueda and Bierzo in Castilla y Le\u00f3n. In Andaluc\u00eda, it is most significant in Jerez, where it thrives on the white chalky-looking albariza soils, but also contributes to the fortified wines of Condada de Huelva further north and west towards Portugal.',
      'To this total vineyard area recorded for Palomino Fino, one should also add the 9,483 ha (23,433 acres) of what is listed separately in the official statistics as List\u00e1n Blanco, the most important and successful white variety on the Islas Canarias. Here it is one of the principal white varieties in all the islands\u2019 DOs. Producers of varietal wines include Bilma, Monje, Soagranorte and Valleoro.',
      'In addition, the official Spanish register of commercial varieties and the official vineyard statistics both distinguish between Palomino and Palomino Fino. Although there are some ampelographic differences that have led to this division (mainly the shape or openness of the flower), the varieties are genetically identical \u2013 based on a comparison of the DNA profiles published by Iba\u00f1ez et al. (2003), Mart\u00edn et al. (2003) and Gonz\u00e1lez-Andr\u00e9s et al. (2007) \u2013 and the synonyms are not used consistently to distinguish them. In many regions, Palomino is used for both, although Palomino Fino is more often used in Andaluc\u00eda, its place of origin. The relative quality of the wines produced seems to depend more on the growing environment than on the particular clone planted. 3,358 ha (8,297 acres) of Palomino tout court were recorded in 2008.',
      'Varietal wines are typically neutral and low in alcohol (both because the berries have low levels of fermentable sugar and because they tend to be picked early) and low in acidity and ideally suited to the production of fortified wines in a range of styles such as those found in the Jerez region, from fino through to amontillado. Although the variety\u2019s tendency to oxidize is not a drawback in these wines, it does mean that unfortified wines are generally dull and flabby and in need of added acidity (although the Navazos-Niepoort dry table wine aged under flor is an interesting exception).',
      'In Portugal there were 3,468 ha (8,570 acres) of Malvasia Rei in 2010, mostly in northern Portugal and particularly in the Douro, where it produces large amounts of typically bland wine, although the aromas are increased by cool fermentation. It is used in both table and fortified wines and is steadily losing ground to better-quality varieties.',
      'The variety was a popular import to Cyprus because of its high yields and suitability for the production of what was once known as \u2018Cyprus sherry\u2019 but the decline in the market for such wines led to widespread uprooting of the variety, leaving just 56 ha (138 acres) in 2009, used for both fortified and table wines.',
      'In California, there were 340 acres (138 ha) in 2008, mostly blended away in Madera and Fresno counties in the the San Joaquin Valley, although a few producers such as Andrew Quady also make sherry-style wines. Argentina recorded 168 ha (415 acres) in 2008.',
      'Used in Australia mainly for fortified, sherry-style wines, Palomino had declined to just 50 ha (124 acres) in 2008. There are also minor plantings in New Zealand (14 ha/35 acres in 2008), where it used to be far more popular.',
      'At one time Palomino \u2013 as Fransdruif in Afrikaans or White French in English \u2013 was widely planted in South Africa, mainly for distillation, but just 381 ha (941 acres) remained in 2008.',
    ],
  },
  {
    name: 'PAMID',
    synonyms: [
      'Grechesky Rosovy',
      'Manaluki',
      'Pamidi',
      'Pamitis',
      'Piros Szlanka',
      'Plovdina',
      'Ro\u015fioar',
      'Saratchoubouk',
      'Slankamenka Crvena',
    ],
    description:
      'Old, widely planted but uninspiring Bulgarian red wine grape.',
    'origin and parentage': [
      'Some ampelographers say that Pamid originates in Bulgaria, others in Thr\u00e1ki (Thrace), Greece, but all agree that it is an old variety from the Balkan peninsula, where it has long been cultivated. Pamid shows significant biodiversity, with at least seven cultivated forms (Katerov 2004): Pamid Rozov (pink), Pamid Siv (grey), Pamid Cheren (black), Pamid Byal (white), Pamid Edar (big), Pamid Izresliv (prone to coulure) and Pamid s Makhrovati Tsvetove (with flowers like sugar beet).',
      'The white-berried DIMYAT is also known as Pamid but the varieties are quite distinct.',
    ],
    'where its grown': [
      'Pamid is Bulgaria\u2019s most widely planted variety (15,842/39,146 acres in 2009, ie 15% of the total vineyard area) but also its least interesting, as hinted by the fact that it is also a table grape. Wines are typically light in colour, thin, lacking acidity and best drunk very young, though they do have a slight fruit sweetness to explain their popularity on the domestic market. The best wines come from hillside vineyards and some ros\u00e9 is also made.',
      'It is popular in Romania, where it is known as Ro\u015fioar and found predominantly in Oltenia in the south west. Total Romanian plantings in 2008 were 3,231 ha (7,984 acres). Pamid is found under various names (see synonyms above) in Serbia, the Republic of Macedonia, Albania, Turkey, Greece and Hungary. In Macedonia, the area declined considerably after the uprootings required by phylloxera but it is still found in most vineyard areas.',
    ],
  },
  {
    name: 'PAMPANUTO',
    synonyms: ['Pampanino', 'Pampanuta', 'Rizzulo'],
    description: 'Minor and largely unexciting Puglian.',
    'origin and parentage': [
      'The origins of this variety and of its strange name are unknown.',
    ],
    'where its grown': [
      'Long cultivated in Puglia in southern Italy, mainly in the province of Bari (Corato, Ruvo di Puglia and Minervino), Pampanuto was rescued from extinction in the 1970s and by 2000 there were 291 ha (719 acres) planted in Italy. Today, Pampanuto is often blended with BOMBINO BIANCO or TREBBIANO GIALLO, although the Bianco version of the Castel del Monte DOC may comprise 100% Pampanuto. Examples of such varietal wines include Crifo\u2019s Bianco Dry (a vino da tavola), Agresti\u2019s Reges Apuliae (IGT Murgia) and Marmo Maria\u2019s Castel del Monte DOC. They tend to be dry and light and can lack acidity.',
    ],
  },
  {
    name: 'PAPAZKARASI',
    synonyms: ['Papaskara', 'Papaz Karas\u0131'],
    description:
      'Turkish variety found mainly in Trakya (Thrace) with a very local purpose.',
    'origin and parentage': [
      'Papazkarasi, literally \u2018black of the priest\u2019, was possibly named after the numerous Byzantine monasteries on the Turkish island of Av\u015fa in the Marmara Sea, where it was supposedly born.',
    ],
    'where its grown': [
      'Papazkarasi is grown in those parts of Trakya, north of the Sea of Marmara, that have a continental climate. The grapes are used to make dark, powerful wines but also to produce the traditional local drink known as Hardaliye, in which the must goes through malolactic fermentation and is mixed with mustard seeds, sour cherry leaves and benzoic acid (G\u00fclc\u00fc 2010). Melen produce an oak-aged example. There were 211 ha (521 acres) in Turkey in 2010.',
    ],
  },
  {
    name: 'PARDILLO',
    synonyms: [
      'Blanca Peque\u00f1a',
      'Marisancho',
      'Parda',
      'Pardilla',
      'Pardillo de Madrid',
      'Parill Blanco',
      'Temprana Media',
    ],
    description:
      'Local La Mancha variety producing well-structured, non-aromatic wines.',
    'origin and parentage': [
      'Pardillo (until 2009 officially known as Pardilla) is probably indigenous to La Mancha in central Spain, where it has long been cultivated under its old synonyms Parill Blanco and Marisancho (Clemente y Rubio 1807). The latter name is also used occasionally for MERSEGUERA in Valencia.',
      'Confusingly, Pardilla is a synonym for both Pardina (see CAYETANA BLANCA) and Pardillo.',
    ],
    'where its grown': [
      'Varietal wines are typically high in alcohol and well structured but not particularly aromatic. There were 3,547 ha (8,765 acres) in 2008, almost all in Castilla-La Mancha, Spain.',
    ],
  },
  {
    name: 'PARELLADA',
    synonyms: [
      'Martorella',
      'Moltonach',
      'Monta\u00f1esa',
      'Montona',
      'Mont\u00f2nec',
      'Mont\u00f3nega',
      'Montonench',
      'Verda Grossa',
    ],
    description:
      'Aromatic variety with high quality potential used mainly in the production of Cava.',
    'origin and parentage': [
      'Parellada originates in Arag\u00f3n in north-east Spain, where it has been widespread since the fourteenth century under a variety of old synonyms (Fav\u00e0 i Agud 2001). In the fifteenth century, Valencian winemaker, physician and writer Jaume Roig is credited with having made a white Mont\u00f2nec that was much appreciated by King Juan II. It may be genetically close to ALBILLO REAL (Iba\u00f1ez et al. 2003).',
    ],
    'where its grown': [
      'Spain grew 8,786 ha (21,711 acres) of Parellada in 2008 with virtually all of it, 8,600 ha (21,251 acres), in Catalunya, where it is mainly used in the production of Cava, particularly in the provinces of Tarragona, Barcelona and L\u00e9rida. Because it is one of the three principal white varieties of the Cava DO (along with MACABEO and XARELLO), which may be made in 160 municipalities in seven different regions (even though 85% comes from the area around Sant Sadurn\u00ed d\u2019Anoia in\nPened\u00e8s), it is also scattered across most of central Spain. It is one of the main varieties in the Catalan DOs Conca de Barber\u00e0, Costers del Segre, Pened\u00e8s and Tarragona, and is authorized on the island of Mallorca for both still and sparkling wines but is only scantily planted there.',
      'Parellada produces aromatic wines that have moderate alcohol levels and delicate, fruity acidity, generally for drinking young. Examples of varietal wines, which are few and far between, are produced by Celler Carles Andreu in Conca de Barber\u00e0 and Torres, who makes a dry (Vi\u00f1a Sol) and a sweet version (San Valentin). Gramona\u2019s Moustillant Brut sparkling wine is also 100% Parellada.',
      'Parellada may be advancing north. It was added to the official register of varieties in France in 2011.',
    ],
  },
  {
    name: 'PARRALETA',
    synonyms: [
      'Bonifaccencu',
      'Bonifacienco',
      'Bonvedro',
      'Bomvedro',
      'Carcaghjolu Neru',
      'Carcajolo Nero',
      'Carcajolo Noir',
      'Carenisca',
      'Caricagiola',
      'Cua Tendra',
      'Espagnin Noir',
      'False Carignan',
      'Lambrusco de Alentejo',
      'Monvedro',
      'Monvedro do Algarve',
      'Monvedro de Sines',
      'Olho Branco',
      'Parrel',
      'Pau Ferro',
      'Perrel',
      'Preto Foz',
      'Preto Jo\u00e3o Mendes',
      'Salce\u00f1o Negro',
      'Tinta Caiada',
      'Tinta Grossa',
      'Tinta Lameira',
      'Tintorro',
      'Torres de Algarve',
    ],
    description:
      'Distinctive Somontano variety producing dark, aromatic wines in several countries and under many different names.',
    'origin and parentage': [
      'This widely dispersed variety is known under various names in several countries around the Mediterranean. The Spanish name Parraleta has been chosen here as the prime name because it\u2019s the name used in its likely place of origin, the Somontano region in the province of Huesca, north-east Spain (Casanova Gasc\u00f3n 2008), as suggested by Vacarel\u2019s 1765 reference to it under the old synonym Parrel (Montaner et al. 2004). Vacarel also referred to Ribote in the province of Huelva, an old pink-berried variety that has been shown by DNA profiling to be a colour mutation of Parraleta (Montaner et al. 2004).',
      'Comparison of the DNA profiles of Tinta Caiada (Almadanim et al. 2004), cultivated in the Alentejo in Portugal, and Carenisca (Zecca et al. 2010), grown on the Italian island of Sardegna, and Salce\u00f1o Negro (Iba\u00f1ez et al. 2003) from Somontano with that of Parraleta (Mart\u00edn et al. 2003) suggests that they are one and the same. Tinta Caiada is also identical to Carcajolo Nero on the French island of Corse, this local name being based on the dialect word carcaghjolu, meaning \u2018yielding much\u2019. It was then introduced as Caricagiola to the Italian island of Sardegna, probably from Bonifacio, as suggested by the synonym Bonifaccencu or Bonifacienco. These synonyms illustrate the vast distribution of this old variety across the Mediterranean basin, even though some of the synonymies are based on ampelographic similarity and have not yet been confirmed by DNA analysis.',
      'Although Parraleta/Tinta Caiada and MONASTRELL are morphologically and genetically quite distinct (Mart\u00edn et al. 2003), there has been some linguistic confusion between them because they both share the synonym Monvedro (or Bonvedro) in Portugal, which is also the prime name for another distinct variety in D\u00e3o (see MONVEDRO). Similarly, Parraleta/Tinta Caiada is known as Bastardo in Reguengos de Monsaraz in the Alentejo and possibly as Bastard\u00e2o in Bairrada but is quite distinct from any other variety of that name (see TROUSSEAU).',
    ],
    'where its grown': [
      'On the island of Corse, France, there are very limited plantings of Carcaghjolu Neru (or Carcajolo Nero), mainly in Figari, Porto-Vecchio and Sart\u00e8ne in the south and, to an even lesser extent, on the east coast.',
      'In Spain Parraleta vines, found only in Arag\u00f3n in the north east, produce wines that tend to be deeply coloured, light, fresh and fragrant, often slightly floral. The variety is provisionally included in the official Spanish register of commercial varieties and is authorized in the Somontano DO but its plantings in that region have diminished considerably in the last thirty-five years: in 1975 it represented 22% of the vineyard area in the province of Huesca but by 2006 it was down to a meagre 0.2% (Casanova Gasc\u00f3n 2008) and total plantings in Arag\u00f3n in 2008 were just 75 ha (185 acres). However, new plantings are underway as companies such as Vi\u00f1as del Vero make an effort to conserve this distinctive cultivar. Varietal wines are produced by, for example, Ballabriga and Pirineos, both in Somontano.',
      'In Portugal, under various names but officially known as Tinta Caiada, there were 299 ha (739 acres) in 2010. The variety has had a chequered history: it was long thought to produce rather mediocre and anonymous wines until in recent years producers in the Alentejo, perhaps the only region where it reaches perfect maturity, started to take it more seriously and produce much better wines. This wave of enthusiasm and the increased plantings have started to slow but producers such as Ervideira, Maria de Lourdes de Noronha Lopes and Monte Seis Reis make deeply coloured, fresh yet rounded reds, mostly for early drinking. Current work on clonal selection should help to maintain this improvement in quality.',
      'Bonvedro/Bomvedro does not appear in Australia\u2019s official statistics but in the past it was occasionally mistakenly known as Carignane (see MAZUELO).',
    ],
  },
  {
    name: 'PASCAL BLANC',
    synonyms: ['Pascal', 'Pascaou', 'Pascau'],
    description: 'Historic variety barely surviving in Provence.',
    'origin and parentage': [
      'Pascal Blanc is a local Proven\u00e7al variety, typically in the Var and in the Bouches-du-Rh\u00f4ne in southern France, where it was first mentioned by Gadriel in 1715 under its dialect name in the region of Aix-en-Provence (R\u00e9zeau 1997): \u2018Pascau blanc, cette esp\u00e8ce est tr\u00e8s-commune\u2019 (\u2018Pascau blanc, this species is very common\u2019). The first use of the modern spelling appeared in 1783\u20134 in Toulon: \u2018Pascal Blanc, la grappe est longue et grosse, les grains gros, ronds, le gout en est fade\u2019 (\u2018Pascal Blanc, the bunch is long and large, the berries are big, the taste is dull\u2019).',
      'Its name comes from the Proven\u00e7al word pascau that most likely derives from the Latin paschalis, meaning \u2018of Easter\u2019, but the link with the religious feast is not clear.',
      'Pascal Blanc belongs to the Claret ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Recommended in the Vaucluse, France, and authorized in several other d\u00e9partements of the southern Rh\u00f4ne and the Languedoc, Pascal Blanc is a permitted minor variety in the tiny Cassis appellation on the Mediterranean coast south east of Marseille. However, it is virtually extinct, with less than a hectare (2.5 acres) planted in 2008. Domaine Saint-Louis Jayne continue to use it in their white Cassis blend, Laurent Jayne having defiantly replanted it in 1996.',
    ],
  },
  {
    name: 'PASCALE',
    synonyms: [
      'Barberone',
      'Falso Gregu',
      'Giacomino',
      'Muresconu',
      'Nera Tomentosa',
      'Nieddu Mannu',
      'Nieddu Pedra Serra',
      'Pascale di Cagliari',
      'Pascale Nero',
      'Pasquale',
      'Picciolo Rosso',
      'Primidivu Nieddu',
    ],
    description: 'Dark-skinned Sardinian rarely bottled on its own.',
    'origin and parentage': [
      'Pascale was first mentioned in 1780 in the province of Sassari in the north of the Italian island of Sardegna. Like many other Sardinian varieties, Pascale is said to have been introduced during Spanish rule over the island (1323\u20131720).',
      'Recent DNA profiling has revealed several synonyms of Pascale: Falso Gregu, Nieddu Mannu, Nieddu Pedra Serra, Nera Tomentosa, Picciolo Rosso, Primidivu Nieddu (De Mattia et al. 2007).',
    ],
    'where its grown': [
      'Pascale is cultivated in the provinces of Sassari and Nuoro in the north of the island of Sardegna, Italy, and often blended with GARNACHA TINTA (known locally as Cannonau) and GRACIANO (Bovale Sardo). It is authorized as part of the blend in the DOCs Campidano di Terralba and Carignano del Sulcis. Dettori\u2019s Ottomarzo is a very rare example of a varietal wine, classified as IGT Romangia Rosso: full-bodied with aromas of blackberries and black cherry. Official Italian statistics for 2000 record a total of 1,273 ha (3,146 acres).',
    ],
  },
  {
    name: 'PASSAU',
    synonyms: ['Incrocio Dalmasso 17/25'],
    description:
      'Very minor Veneto cross whose correct parentage has only recently been identified.',
    'origin and parentage': [
      'The Italian vine breeder Giovanni Dalmasso introduced Passau in 1936 at the Conegliano research centre as a cross between NEBBIOLO and DOLCETTO and it was included in the official register of Italian varieties in 1977. However, DNA profiling has recently shown that the Nebbiolo used by Dalmasso was in fact Nebbiolo di Dronero, which is not Nebbiolo at all but a synonym for CHATUS (Torello Marinoni, Raimondi, Mannini and Rolle 2009). Therefore, Passau must be considered to be a Chatus \u00d7 Dolcetto cross, just like its siblings SAN MARTINO and VALENTINO NERO.',
    ],
    'where its grown': [
      'Passau is cultivated in Piemonte in north-west Italy. The Italian census of 2000 recorded just 13 ha (32 acres).',
    ],
  },
  {
    name: 'PASSERINA',
    synonyms: [
      'Cacciadebiti',
      'Caccione',
      'Camplese',
      'Pagadebito',
      'Scacciadebito',
      'Trebbiano di Teramo',
      'Uva d\u2019Oro',
      'Uva Fermana',
    ],
    description:
      'Old variety from Italy\u2019s Adriatic coast made in a variety of styles.',
    'origin and parentage': [
      'Passerina is an old variety thought to come from the Adriatic coast of Italy. It has numerous, often erroneous, synonyms, many referring to its high yields: Cacciadebiti and Scacciadebito both mean \u2018chasing the debts\u2019, Pagadebito means \u2018paying the debts\u2019 and Uva d\u2019Oro, \u2018golden grape\u2019. A relationship with TREBBIANO TOSCANO has long been suspected but not yet proven by DNA testing.',
    ],
    'where its grown': [
      'Passerina is grown throughout central Italy and is widespread in the province of Ascoli Piceno in the Marche, where it is blended with PECORINO and TREBBIANO TOSCANO in Falerio dei Colli Ascolani DOC, and it is made as a varietal wine in Offida. In the Controguerra DOC, in the province of Teramo in Abruzzo, it is authorized on its own or as part of a blend. 767 ha (1,895 acres) were recorded in Italy in 2000. Successful producers of Offida Passerina include La Caniette (Vino Santo), Cocci Grifoni (passito and sparkling), San Giovanni and Villa Pigna. Still, dry varietal wines such as those produced by Casale della Loria and Giovanni Terenzi tend to be fresh, taste of apples and pears and have a spicy or slightly bitter aftertaste.',
    ],
  },
  {
    name: 'PAVANA',
    synonyms: [
      'Nera Gentile di Fonzaso',
      'Nostrana Nera',
      'Pelosetta',
      'Ussulara',
      'Vicentina',
      'Visentina',
    ],
    description: 'Much diminished, now highly localized, northern Italian.',
    'origin and parentage': [
      'Pavana was first mentioned in Valsugana and near Trento in Trentino-Alto Adige by Acerbi (1825). Its name probably derives from padovana, suggesting that the variety came from the region of Padova in the Veneto.',
      'Pavana is not the black version of the no longer cultivated Pavana Bianca, which is often confused with BIANCHETTA TREVIGIANA even though they have distinct DNA profiles (Crespan, Giannetto et al. 2008). Pavana is said to be genetically related to SCHIAVA LOMBARDA and Turca (Scienza and Failla 1996), two other northern Italian varieties. However, Turca is identical to DOUCE NOIRE so these relationships seem unlikely.',
    ],
    'where its grown': [
      'Pavana used to be cultivated in several provinces of the Veneto and Trentino, Italy, in the nineteenth century, but its acreage drastically decreased during the twentieth century. In Trentino, Pavana is today cultivated only in the province of Trento, in particular between Levico and Borgo Valsugana. In the Veneto, Pavana is today cultivated only in the province of Belluno, in particular between Fonzaso and Arsi\u00e8. Pavana della Valsugana by Francesco Poli is a rare example of a varietal.',
    ],
  },
  {
    name: 'PECORINO',
    synonyms: [
      'Arquitano',
      'Norcino',
      'Pecorina Arquatanella',
      'Pecorino di Osimo',
      'Promotico',
      'Uva delle Pecore',
      'Uva Pecorina',
      'Vissanello',
    ],
    description:
      'Marche speciality enjoying a certain modishness. Mineral or herbal dry whites.',
    'origin and parentage': [
      'Pecorino (from pecora, meaning \u2018sheep\u2019) is an old variety probably originating from the Marche, in particular in the area of Monti Sibillini south east of Perugia, where it was widespread in the nineteenth century. In Calabria, the variety named Pecorino is identical to GRECO BIANCO, also known as Pecorello Bianco.',
    ],
    'where its grown': [
      'Formerly widely cultivated in the Marche, eastern Italy, its distribution was reduced during the twentieth century to the area of Arquata del Tronto midway between Spoleto and the coast. In the 1980s, producer Guido Cocci Grifoni introduced this variety to Offida and Ripatransone in the province of Ascoli Piceno, slightly further north east, where most plantings are today.',
      'A varietal wine is authorized as part of the Offida DOCG. It tends to be firm, dry and minerally and producers include Aurora, Le Caniette, Cantine di Castignano, Ci\u00f9 Ci\u00f9, Cocci Grifoni, Costadoro, Capecci-San Savino and San Giovanni. Umani Ronchi\u2019s Pecorino, an IGT Terre di Chieti, is fresh, delicate and zesty on the nose and crisp and much more mineral on the palate. Pasetti\u2019s Pecorino is fresh, herbal and lemony. It is also cultivated in Abruzzo in the provinces of Teramoa and Pescara and, to a lesser extent, in Umbria, Toscana, Lazio and Liguria. It may be included as part of a blend \u2013 often but not exclusively with TREBBIANO TOSCANO \u2013 in the Colli Maceratesi and Falerio dei Colli Ascolani DOCs and in the sparkling version of Controguerra DOC.',
      'There were 87 ha (215 acres) in Italy in 2000 but it is doubtless much more important today.',
    ],
  },
  {
    name: 'PEDRAL',
    synonyms: [
      'Alvarinho Tinto',
      'Cainho dos Milagres',
      'Cainho Espanhol',
      'Dozal',
      'Padral',
      'Pardal',
      'Pedrol',
      'P\u00e9gudo',
      'Perna de Perdiz',
      'Verdejo Colorado',
    ],
    description:
      'Rare Minho variety, now more common (just) over the border in Spain and found in blends.',
    'origin and parentage': [
      'Pedral comes from the Mon\u00e7\u00e3o area in the Minho, Vinho Verde country, north-west Portugal. The DNA profiles of Verdejo Colorado (Mart\u00edn et al. 2006), which is not a colour mutation of VERDEJO, and of Pedral (Gago et al. 2009) turned out to be identical (Vouillamoz). This Portuguese origin probably explains why Mart\u00edn et al. (2006) found Verdejo Colorado to be genetically distant from the other Spanish varieties they analysed.',
    ],
    'where its grown': [
      'In spite of Pedral\u2019s Portuguese origins, current plantings in Galicia in north-west Spain (79 ha/195 acres in 2008), although small-scale, far exceed what remains in Portugal (5 ha/12 acres in 2009). The variety is provisionally included in the official Spanish list of commercial varieties and authorized in R\u00edas Baixas.',
    ],
  },
  {
    name: 'PEDRO GIM\u00c9NEZ',
    synonyms: ['Pedro Jim\u00e9nez'],
    description:
      'Quantitatively if not qualitatively important Argentine white unrelated to Spain\u2019s Pedro Xim\u00e9nez.',
    'origin and parentage': [
      'Pedro Gim\u00e9nez is indigenous to Argentina and is one of the Criollas (see CRIOLLA entry for details). Mart\u00ednez et al. (2003) suggested that Pedro Gim\u00e9nez is ampelographically distinct from the Spanish variety PEDRO XIM\u00c9NEZ, as confirmed by DNA profiling (Vouillamoz).',
    ],
    'where its grown': [
      'Pedro Gim\u00e9nez is in decline in Argentina but is still one of the mainstays, along with CEREZA and CRIOLLA GRANDE, of the country\u2019s production of cheap everyday wine sold in litre bottles and cartons. Fapes in the Valle de Uco make a varietal example. There were 13,476 ha (33,230 acres) in 2009, only slightly less than the much more reputable Bonarda (DOUCE NOIRE), predominantly in Mendoza province and to a lesser extent in San Juan. It is also planted to a much more limited extent in Chile.',
    ],
  },
  {
    name: 'PEDRO XIM\u00c9NEZ',
    synonyms: [
      'Corinto Bianco',
      'Don Bueno',
      'Pedro Jimenez',
      'Pedro Ximenes',
      'Perrum',
      'PX',
      'Verdello',
      'Ximenes',
    ],
    description:
      'Andaluc\u00eda\u2019s variety used mainly for some of the darkest, stickiest wines made anywhere.',
    'origin and parentage': [
      'Pedro Xim\u00e9nez most likely originates from Andaluc\u00eda, southern Spain, where it has been cultivated since the seventeenth century or even earlier: it was already famous in M\u00e1laga when Vicente Espinel (1618) spoke about \u2018una bota de Pedro Ximenez de Malaga\u2019 (\u2018a barrel of Pedro Ximenez from M\u00e1laga\u2019). Janini and Roy-Chevrier (1905) suggest the variety\u2019s name comes from a place called Jim\u00e9nez in Sanl\u00facar de Barrameda, but it seems more likely that it was named after a famous local vintner since Ximen\u00e8s/Ximenez is a common surname in Andaluc\u00eda.',
      'DNA parentage analysis has suggested a possible parent\u2013offspring relationship between Pedro Xim\u00e9nez and GIBI (Vargas et al. 2007), an old table grape of probable Arabic origin that was formerly cultivated in the south of France and in Spain, where it also gave birth to ALARIJE in Extremadura (Lacombe et al. 2007), which is therefore a half-sibling of Pedro Xim\u00e9nez. In addition, DNA profiling has shown that the widely cultivated table grape Corinto Bianco is a seedless mutation of Pedro Xim\u00e9nez (Vargas et al. 2007).',
    ],
    'where its grown': [
      'The great majority of Spain\u2019s 9,583 ha (23,680 acres) in 2008 was in the Montilla-Moriles region in Andaluc\u00eda, southern Spain. Here the variety is used to produce fortified wines similar to sherry and also unfortified wines in various styles but generally fairly high in alcohol (though not as high as sherry) and low in acidity. The sugar-rich grapes are traditionally dried in the sun to concentrate these sugars in order to produce intensely sweet fortified wines akin to essence of alcoholic raisins, often known as PX, and to sweeten certain styles of fortified Montilla. Recommended producers include Alvear, La Aurora, Delgado, Equipo Navazos (both Jerez and Montilla), Gracia Hermanos, Moreno and P\u00e9rez Barcero.',
      'Neighbouring Jerez uses Pedro Xim\u00e9nez for similar purposes but grows far less because the producers here and in M\u00e1laga may legally buy PX wines from Montilla for blending, saving them the trouble of cultivating this rather disease-prone variety themselves. Most of the top Jerez producers make a varietal PX. Recommended producers from M\u00e1laga include Gomara and M\u00e1laga Virgen. It is one of the principal varieties in the Jerez, M\u00e1laga, Montilla-Moriles and Valencia DOs and is also planted in Extremadura (639 ha/1,579 acres), Castilla-La Mancha (117 ha/289 acres), Catalunya (12 ha/30 acres) and the Islas Canarias (less than 1 ha/2.5 acres).',
      'As Perrum in Portugal, it is grown mainly in the Alentejo, especially in \u00c9vora, and to a lesser extent in the Algarve, where it is considered a late ripener and generally contributes to a blend, such as those produced by Carmim, Fundac\u00e4o Eug\u00e9nio de Almeida (Cartuxa\u2019s EA) and the Borba co-operative. There were 350 ha (865 acres) in Portugal in 2010.',
      'The wine made from the more than 3,000 ha (7,413 acres) of Pedro Jimenez (sic) grown in Chile is mostly distilled to make pisco but the statistics for wine grapes specifically recorded 33 ha (82 acres) of this variety in 2008. Falernia in the Elqui Valley make a refreshing and fruity dry, unfortified version, although it is not known whether the vines are this variety or Argentina\u2019s distinct PEDRO GIM\u00c9NEZ.',
      'Pedro Xim\u00e9nez, often called simply Pedro, was once fairly widely planted in Australia, for unfortified blends and for very fine botrytized wines such as the rich, deep golden McWilliam\u2019s Pedro Sauterne (sic) made in irrigated vineyards near Griffith in New South Wales. There are still a few producers from a range of wine-growing regions who bottle this wine in a number of styles: rich and fortified at Turkey Flat in the Barossa Valley and at Gralyn in Western Australia, for example; dry, unfortified and bottle aged at Campbell\u2019s in Rutherglen, Victoria.',
    ],
  },
  {
    name: 'PELAVERGA',
    synonyms: [
      'Cari',
      'Pelaverga di Pagno',
      'Pelaverga Grosso',
      'Uva Coussa',
      'Uva delle Zucche',
    ],
    description:
      'The more commonly planted, big-berried Pelaverga, often blended with Nebbiolo and/or Barbera in Piemonte.',
    'origin and parentage': [
      'It is believed that the first mention of Pelaverga appeared in 1511 when Pope Julius II praised a wine from Pagno and Chastellar (now spelt Castellar), in the province of Cuneo, when he was offered a few barrels by Margherita di Foix, Marchesa di Saluzzo (Comba and Dal Verme 1990), although the name Pelaverga is not specifically mentioned in the source document. Pelaverga is distinct from PELAVERGA PICCOLO.',
    ],
    'where its grown': [
      'Formerly widespread all over Piemonte in north-west Italy, grown for the table as well as for wine, Pelaverga is today cultivated for wine production mainly in the Val Bronda, south west of Torino in Piemonte, northern Italy, as well as in Chieri and Baldissero Torinese in the province of Torino, where it is called Cari. 121 ha (299 acres) were recorded in Italy in 2000.',
      'A varietal wine (labelled Pelaverga or Cari) is authorized as part of the Collina Torinese DOC and Pelaverga may be a varietal or blended with NEBBIOLO and/or BARBERA in the Colline Saluzzesi DOC. The wines are light red, sometimes slightly fizzy and peppery, perfumed, and often taste of strawberries. Producers include Casetta, Maero Emidio and Produttori Pelaverga.',
    ],
  },
  {
    name: 'PELAVERGA PICCOLO',
    synonyms: ['Pelaverga di Verduno'],
    description:
      'Fashionable if relatively minor Piemontese making light, fruity, spicy varietal reds.',
    'origin and parentage': [
      'Pelaverga Piccolo is distinct from PELAVERGA (or Pelaverga Grosso), although this was not officially established until the early 1990s (Schneider and Mannini 2006). It is said to have been brought eastwards across the Piemontese province of Cuneo from Saluzzo to Verduno by the priest Sebastiano Valfr\u00e8 in the eighteenth century.',
    ],
    'where its grown': [
      'Pelaverga Piccolo was almost forgotten until a few producers began to craft interesting varietal wines in the 1970s. Today it is grown to a limited extent around the commune of Verduno, near Alba in Piemonte, north-west Italy, where it is the main variety (minimum 85%) of Verduno Pelaverga, or plain Verduno, DOC, producing light, fruity, spicy reds. It is also blended with NEBBIOLO and BARBERA. There were 26 ha (64 acres) in Italy in 2000.',
      'Varietal wines tend to be light in colour, smooth and fruity, occasionally spicy. GB Burlotti\u2019s tastes of sweet red fruit but is also a little peppery and nicely fresh. Successful producers include Fratelli Alessandria, Ascheri, Bel Colle, Michele Reverdito and Cantina Terre del Barolo.',
    ],
  },
  {
    name: 'PELOURSIN',
    synonyms: [
      'Corsin',
      'Fumette',
      'Feunette',
      'Gros B\u00e9clan',
      'Pelossard',
      'P\u00e9loursin',
      'Pourret',
      'Pourrot',
    ],
    description:
      'From Is\u00e8re, now mostly of historical and genetic significance.',
    'origin and parentage': [
      'Peloursin is an old variety from the Vall\u00e9e du Gr\u00e9sivaudan near Grenoble in the Is\u00e8re in eastern France that was later introduced to the Savoie and the Franche-Comt\u00e9, where it took on many different names. Its prime name supposedly derives from Pelossier, the local name for the blackthorn (prunellier). Peloursin was used to breed DURIF through a crossing with SYRAH, and Joubertin (no longer cultivated) through a crossing with PERSAN, and it gave its name to the Pelorsien ampelographic group (see p XXVII; Bisson 2009). In addition, Peloursin is morphologically close to Bia Blanc (Bisson 1999), no longer cultivated.',
    ],
    'where its grown': [
      'The variety seems to have more or less disappeared, apart from a few vines interplanted in old vineyards, and has been overtaken by its offspring DURIF. It has, however, been planted at the experimental domaine of the Tarn d\u00e9partement at Peyrole in south-west France for research purposes. Domaine Ganevat in Rotalier in the Jura have replanted a small area of Gros B\u00e9clan and include it in an early-drinking red blend.',
      'Peloursin has recently been identified in an old vineyard in Sonoma, California.',
    ],
  },
  {
    name: 'PERERA',
    synonyms: ['Perera Gialla', 'Pevarise'],
    description:
      'Old variety of the Veneto used to perfume passito and sparkling wines in Prosecco country.',
    'origin and parentage': [
      'Perera has traditionally been cultivated in Valdobbiadene in the province of Treviso in the Veneto. Its name derives from pera, meaning \u2018pear\u2019, because the shape of the bunch looks like an inverted pear. Some producers suggest the name might also be inspired by the pear-like flavour of the grape pulp.',
    ],
    'where its grown': [
      'Once widespread in the area between Conegliano and Valdobbiadene in the province of Treviso in northern Italy, the heartland of Prosecco, Perera almost disappeared in the 1970s after the arrival of the vine disease flavescence dor\u00e9e, to which it is very sensitive. Perera is today cultivated mainly in Valdobbiadene, in particular in the region of Cartizze. It may be included as a minor component \u2013 with Glera (see PROSECCO), VERDISO and Boschera (see VERDICCHIO BIANCO) \u2013 in the dry or sweet, passito-style Torchiato di Fregona version of the Colli di Conegliano DOC and a small amount (generally 5\u201310%, often together with Verdiso and BIANCHETTA TREVIGIANA) may be added to the main variety Glera in Prosecco Conegliano-Valdobbiadene DOCG sparkling wines. It is said to add perfume and contribute a pear-like flavour. There were 25 ha (62 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'PERLE',
    synonyms: ['Perle von Alzey'],
    description:
      'Pink-berried German cross with a hint of its rose-like parental perfume but short on freshness.',
    'origin and parentage': [
      'A Roter Traminer (see SAVAGNIN ROSE) \u00d7 M\u00dcLLER-THURGAU cross bred by Georg Scheu in 1927 at the Alzey research centre in Rheinhessen, Germany, though it was developed at W\u00fcrzburg in the 1950s and authorized in 1961. This parentage has been confirmed by DNA profiling (Myles et al. 2011).',
    ],
    'where its grown': [
      'In Germany, more than half of its 37 ha (91 acres) can be found in Franken, where its late budding is good insurance against spring frosts. Wines are light, lightly floral but can lack acidity, and the variety is generally in decline. Producers who persevere with it include Kistner and Herbert Schuler in Franken. Perle is authorized in England but plantings are too insignificant to appear in the official vineyard statistics.',
    ],
  },
  {
    name: 'PERRICONE',
    synonyms: [
      'Catarratto Rouge',
      'Niuru',
      'Perricone Nero',
      'Pignateddu',
      'Pignatello',
      'Pirricuni',
      'Tuccarinu',
    ],
    description:
      'Dark-skinned, relatively tannic Sicilian, often blended with Nero d\u2019Avola.',
    'origin and parentage': [
      'Perricone, from the Italian island of Sicilia, has been confused with many other varieties, as listed above. Recent DNA profiling has provided evidence that they are not identical but that there is a possible parent\u2013offspring relationship with SANGIOVESE (Di Vecchi Staraz, This et al. 2007).',
    ],
    'where its grown': [
      'Perricone is cultivated mainly in the north west of the Italian island of Sicilia, in the provinces of Palermo and Trapani, as well as in those of Agrigento on the south-west coast and Messina in the far north east. There were 340 ha (840 acres) planted on the island in 2008, according to official statistics. Figures for the whole of Italy in the 2000 census were 624 ha (1,542 acres).',
      'Perricone may be made as a varietal wine or included as part of the blend \u2013 usually with NERO D\u2019AVOLA \u2013 in DOCs such as Contea di Sclafani, Delia Nivolelli, Eloro (here called Pignatello), Erice and Monreale, and as a minor part of the blend in the Alcamo DOC.',
      'Varietal wines vary in style but Caruso & Minini\u2019s Sach\u00eca Perricone, classified as IGT Sicilia, has firm tannins and slightly bitter flavours of cherries and dark chocolate. Tamburello\u2019s Pietragavina Perricone, Monreale DOC, is also notably tannic and persistent. Other examples of varietal wines include Castellucci Miano di Valledolmo\u2019s Maravita (using grapes that have been dried for a month prior to vinification), Feotto dello Jato\u2019s Vigna Curria, softened by two years in oak, and Romano\u2019s Perricone IGT Sicilia.',
    ],
  },
  {
    name: 'PERRUNO',
    synonyms: [
      'Casta de Mont\u00fao',
      'Firmissima',
      'Getibi',
      'Granadina',
      'Jetibi',
      'Morata',
      'Perruna',
      'Perruno Com\u00fan',
      'Perruno de Arcos',
      'Perruno de la Sierra',
      'Perruno Duro',
      'Perruno Fino',
      'Perruno Tierno',
      'Vidue\u00f1o',
    ],
    description:
      'Old Extremaduran variety with a lone supporter in Andaluc\u00eda.',
    'origin and parentage': [
      'Perruno, from Extremadura, western Spain, was mentioned in 1792 by Garc\u00eda de Lena, who said: \u2018ni el sol los dulcifica\u2019 (\u2018even the sun cannot mellow it\u2019) and that \u2018el nombre est\u00e1 diciendo su ninguna bondad\u2019 (\u2018the name speaks about its lack of mildness\u2019), because perruno means \u2018dog\u2019 or \u2018dog-like\u2019 in Spanish. Clemente y Rubio (1807) later described three distinct types of this variety in Andaluc\u00eda, the last two probably colour mutations: Perruno Duro with hard white berries, Perruno Com\u00fan or Perruno Tierno with reddish berries, Perruno Negro with black berries. At that time, two centuries ago, it covered half of the vineyard area of Jerez de la Frontera.',
      'DNA parentage analysis has suggested a parent\u2013offspring relationship between Perruno and LIST\u00c1N PRIETO (under the synonyms Moscatel Negro or Mission; Iba\u00f1ez et al. 2003; This et al. 2006), which makes Perruno a likely half-sibling or a grandparent of CEREZA, Moscatel Amarillo, TORRONT\u00c9S RIOJANO and TORRONT\u00c9S SANJUANINO cultivated in Argentina (see MUSCAT pedigree diagram).',
    ],
    'where its grown': [
      'Perruno has been provisionally included in the Spanish register of commercial varieties and is authorized in Andaluc\u00eda, south-west Spain, as well as in the Ribera del Guadiana DO in Extremadura. There were 741 ha (1,831 acres) in Extremadura in 2008, mostly in the Ribera Alta subregion around Castuera, where it tends to disappear in bulk wines, and just 3 ha (7 acres) in Andaluc\u00eda, where Lagar Ambrosio in the Sierra de C\u00e1diz region produce a varietal wine and are working hard to resurrect Perruno there. Victor Palacios from the University of C\u00e1diz suggests that despite the very negative picture painted by Garc\u00eda de Lena, it could have interesting results as a wine grape even if it is unsuitable for the table. It is said to be low in both sugar and acidity.',
    ],
  },
  {
    name: 'PERSAN',
    synonyms: [
      'Beccu',
      'B\u00e9cuet',
      'Becu\u00e9t',
      'B\u00e9cuette',
      'Berla\u2018d Crava Cita',
      'Berlo Citto',
      'Etraire',
      'Etris',
      'Princens',
      'Prinsens',
      'Serine',
      'Siranne',
    ],
    description:
      'Rare but high-quality Savoie variety on a voyage of rediscovery.',
    'origin and parentage': [
      'Persan most likely originates from Princens, a hamlet above Saint-Jean-de-Maurienne whose vineyards were reputed to give the best wines of Savoie. Indeed, the name Princens probably derives from the dialect prin, meaning \u2018prime\u2019, and cens, used to refer to a fee collected by landowners from their vassals, suggesting that Princens was the best land. Princens later evolved in popular usage to Persan, a name that was first mentioned by Albin Gras in 1846 in the Is\u00e8re (R\u00e9zeau 1997): \u2018Etraire (grosse) R. ou Etri\u00e8re (rive droite de l\u2019Is\u00e8re, etc.) synon. Persan (rive gauche de l\u2019Is\u00e8re, etc.)\u2019 (\u2018Etraire (capital) R. or Etri\u00e8re (right bank of the Is\u00e8re, etc.) synon. Persan (left bank of the Is\u00e8re, etc.\u2019).',
      'DNA profiling has established that Persan has long been cultivated in the Valle di Susa and around Pinerolo in Piemonte in north-western Italy under the name B\u00e9cuet (Schneider et al. 2001). Persan has often been confused with other varieties such as ETRAIRE DE L\u2019ADU\u00cf, MONDEUSE NOIRE, SYRAH or even PINOT NOIR; Guyot (1876) thought that Persan was a local mutation of this last. Rougier (1902b) suggested that Etraire de l\u2019Adu\u00ef could be a natural seedling of Persan, which is now confirmed by DNA parentage analysis that strongly suggests a parent\u2013offspring relationship between Etraire de l\u2019Adu\u00ef and Persan (Vouillamoz). Indeed, both varieties belong to the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009).',
      'Persan was crossed with PELOURSIN to breed Joubertin (no longer cultivated).',
    ],
    'where its grown': [
      'In France, Persan used to be the dominant variety in Princens, a highly regarded vineyard near Saint-Jean-de-Maurienne, east of Grenoble, that was mentioned way back in the seventeenth century (Gros 1930). Before phylloxera hit the region at the end of the nineteenth century, Persan was widespread throughout Savoie and in the Is\u00e8re. Today, even though it is recommended in the Savoie and Is\u00e8re d\u00e9partements, is a permitted variety in the Vin de Savoie appellation and also appears under the local Vin de Pays d\u2019Allobrogie, there are just 9 ha (22 acres). Nevertheless, the variety is regaining popularity and, with the help of Michel Grisard in Fr\u00e9terive, one of the rare producers of varietal Persan, a local association is planning to replant Persan in the Princens vineyard. Wines tend to be tannic with good acidity and well suited to ageing. Domaine de M\u00e9jane and Domaine Saint-Germain also produce varietal wines.',
      'In Italy, under the name B\u00e9cuet, Persan is usually blended with other local varieties such as AVAN\u00c0 to produce Rami\u00e8, a historical alpine wine, but Azienda Agricola Martina in Giaglione and Azienda Sibille in Gravere both make unblended wines.',
      'In Switzerland, Gen\u00e8ve producer Jean-Pierre Pellegrin has recently planted 1,500 vines of Persan at his Domaine Grand\u2019Cour and the first varietal wine is expected to be released in 2015 or 2016.',
    ],
  },
  {
    name: 'PERVENETS MAGARACHA',
    synonyms: ['Pervenec Magaraca', 'Pervenyec Magaracsa'],
    description:
      'Recent Ukrainian cross notably resistant to downy mildew and used for dry and sweet wines.',
    'origin and parentage': [
      'Pervenets Magaracha, meaning \u2018firstborn of Magarach\u2019, is a hybrid obtained in 1966 by P Y Golodriga, V T Usatov, L P Troshin, Y A Malchikov, I A Suyatinov, V A Dranovskiy and P N Nedov at the Magarach research centre in Yalta, southern Ukraine, by crossing RKATSITELI with Magarach 2-57-72, in which the latter is a MTSVANE KAKHURI \u00d7 Sochinsky Cherny cross, and Sochinsky Cherny is a non-cultivated Ukrainian variety. Pervenets Magaracha is a sibling of PODAROK MAGARACHA. It was included in Ukraine\u2019s official register of varieties in 1992.',
    ],
    'where its grown': [
      'Pervenets Magaracha is used in Ukraine (643 ha/1,589 acres in 2009) to make amber-coloured dry and sweet wines with fresh acidity. It is also quite widely planted in Russia \u2013 in Temriuk and Anapa in Krasnodar in the south west (1,915 ha/4,732 acres in 2010) as well as in Stavropol and Rostov-on-Don (323 ha/798 acres in 2010) \u2013 and has also been reported in Azerbaijan and the Republic of Moldova.',
    ],
  },
  {
    name: 'PETIT BOUSCHET',
    synonyms: [
      'Aramon-Teinturier',
      'Bouschet Petit',
      'Negr\u00f3n de Ald\u00e1n',
      'Tintinha',
    ],
    description:
      'Disappearing red-juiced nineteenth-century southern French cross.',
    'origin and parentage': [
      'An ARAMON NOIR \u00d7 TEINTURIER cross obtained in 1824 by Louis Bouschet at the Domaine de la Calmette in Mauguio in the H\u00e9rault in the south of France. Although both Petit Bouschet and JUAN GARC\u00cdA are sometimes called Negr\u00f3n de Ald\u00e1n (Gonz\u00e1lez-Andr\u00e9s et al. 2007), they are not related. Recent DNA profiling has suggested a possible parent\u2013offspring relationship with T\u00c9OULIER NOIR (Myles et al. 2011) but this can be rejected by comparison with the DNA profile of the true T\u00e9oulier Noir (Vouillamoz).',
      'Petit Bouschet is a sibling of Gros Bouschet (no longer cultivated) and was used to breed ALICANTE HENRI BOUSCHET, GRAND NOIR and MORRASTEL BOUSCHET.',
    ],
    'where its grown': [
      'Petit Bouschet, like all vines with TEINTURIER ancestry, produces deeply coloured wines but they are otherwise unremarkable. Popular in France in the second half of the nineteenth century, notably to add colour to blends, it was overtaken by the greater popularity of its offspring ALICANTE HENRI BOUSCHET, and has now largely disappeared from French vineyards, though there are minor plantings in Portugal (11 ha/27 acres in 2009) and North Africa.',
    ],
  },
  {
    name: 'PETIT COURBU',
    synonyms: [
      'Courbis',
      'Courbu Petit',
      'Petit Courbu Blanc',
      'Vieux Pacherenc',
      'Xuri Zerratu',
    ],
    description: 'Minor but high-quality, aromatic Juran\u00e7on speciality.',
    'origin and parentage': [
      'Petit Courbu is local to Juran\u00e7on in France\u2019s far south west, considered by Galet (1990) to be a clonal variation of COURBU BLANC, another Juran\u00e7on variety with which Petit Courbu shares the synonyms Courbis and Vieux Pacherenc, while others confuse it with LAUZET. However, ampelographic observations and DNA profiling have clearly disproved these hypotheses (Bowers et al. 1999; Bordenave et al. 2007).',
    ],
    'where its grown': [
      'Petit Courbu is grown only in the far south west of France and is generally used in blends, although dry varietal wines have the potential for great finesse and have been described as tasting like a cross between VIOGNIER and RIESLING. Rare examples of this aromatic wine (lemon, floral, sometimes honey) are produced by Alain Brumont and Domaine Laougu\u00e9, both within the Pacherenc du Vic-Bilh appellation, where it is considered one of the principal varieties. It may be included in blends in the B\u00e9arn, Iroul\u00e9guy, Juran\u00e7on and Pacherenc appellations, though it rarely contributes more than 20\u201330%. The regulations for C\u00f4tes de Saint-Mont stipulate a minimum of 30%. Total plantings in France in 2008 were 68 ha (168 acres), a considerable increase on the 10 ha (25 acres) in 1958.',
    ],
  },
  {
    name: 'PETIT MANSENG',
    synonyms: [
      'Izkiriot Ttipi',
      'Iskiriota Zuri Tipia',
      'Ichiriota Zuria Tipia',
      'Manseng Petit Blanc',
      'Mansengou',
    ],
    description:
      'High-quality variety making superb sweet wines in south-western France.',
    'origin and parentage': [
      'The earliest mention of a Manseng was recorded in 1562 in Juran\u00e7on in a text in Occitan: \u2018vinhe mansengue\u2019. However, it is not known whether this name referred to GROS MANSENG or to Petit Manseng, and the two varieties were not distinguished until 1783\u20134: \u2018Manseng gros, blanc .\u00a0.\u00a0., couleur dor\u00e9e, bon gout quoy que piquant. Manseng petit, blanc.\u00a0.\u00a0., le gout plus piquant que tout autres\u2019 (\u2018Manseng gros, white .\u00a0.\u00a0. golden colour, good taste although spicy. Manseng petit, white .\u00a0.\u00a0. the taste is spicier than any others\u2019). The name Manseng could derive from mans, meaning \u2018mansion\u2019.',
      'According to Galet (1990), Petit Manseng is morphologically close to GROS MANSENG, and Bisson (2009) groups them in the Mansien ampelographic group (see p XXVII). DNA parentage analysis and reconstruction of the most likely pedigree (see the PINOT pedigree) strongly suggests that Petit Manseng is a progeny of SAVAGNIN and a parent of Gros Manseng (Vouillamoz; Myles et al. 2011).',
    ],
    'where its grown': [
      'Thanks to its remarkable ability to concentrate sugars in the berry and still retain very high acidity, Petit Manseng is ideally suited to high-quality, aromatic sweet wines made from grapes shrivelled on the vine and picked as late as November and even early December.',
      'There were 1,019 ha (2,518 acres) in France in 2009, predominantly in south-west France, where it is a principal variety in the Juran\u00e7on and Pacherenc du Vic-Bilh appellations. There have been some recent plantings in the Languedoc and the total planted area is continuing to rise. Recommended producers include Domaine Cauhap\u00e9 (both dry and sweet versions), Domaine Bru-Bach\u00e9 and Clos Uroulat. A little is included in the blend of white Mas de Daumas Gassac in the H\u00e9rault, eastern Languedoc.',
      'Over the border in northern Spain, Petit Manseng is authorized for two of the three appellations in the Pa\u00eds Vasco: Arabako Txakolina/Chacol\u00ed de \u00c1lava and Bizkaiko Txakolina/Chacol\u00ed de Viscaia (a principal variety in the former and a complementary one in the latter). However, total plantings in Spain in 2008 amounted to no more than 4 ha (10 acres). There could be potential here.',
      'The first varietally labelled Petit Manseng wine in the US was produced by Chrysalis Vineyards in Virginia (63 acres/25 ha in 2010) but others such as Horton Vineyards, Delfosse, Prince Michel, Sugarleaf Vineyards and White Hall Vineyards have followed suit, some making dry wines. The variety\u2019s naturally high acidity suits Virginia\u2019s hot summers. It is also planted to a very limited extent even further south in South Carolina, and Georgia, where Tiger Mountain Vineyards in Tiger, Georgia, make both a dry and a late-harvest version from grapes very much at home at 2,000 ft on the flinty, decayed granite of the southern Appalachians.',
      'Basque immigrants took the variety to Uruguay in 1870, along with the now much more widespread TANNAT, but the total was fewer than 2 ha (5 acres) in 2010, produced by Familia Deicas, who blend it with GROS MANSENG and other aromatic varieties in their Botrytis Noble.',
      'There are also very minor plantings in Argentina and Japan.',
      'Although Petit Manseng has not yet made it into Australia\u2019s official vineyard statistics, there are a few producers making successful varietal wines, including Gapsted in the Victorian Alps and 919 Wines in Riverland. Churton in Marlborough, New Zealand, planted 0.5 ha (1.24 acres) of Petit Manseng on a hillside vineyard in 2007, the country\u2019s first commercial planting of this variety.',
    ],
  },
  {
    name: 'PETIT MESLIER',
    synonyms: [
      'Meli\u00e9 Blanc',
      'Meslier Dor\u00e9',
      'Meslier Jaune',
      'Meslier Petit',
    ],
    description:
      'Extremely rare, light-berried potential champagne ingredient with significant relatives and a lone outpost in Australia.',
    'origin and parentage': [
      'The term Meslier (variously spelt) has been used indiscriminately for Petit Meslier or for MESLIER SAINT-FRAN\u00c7OIS (see the latter for history and etymology) and its first reliable mention appeared in Sancerre in 1783\u20134 (R\u00e9zeau 1997): \u2018Petit meslier blanc. Feuille delicate, blanche par dessous, petit raisin excellent, grain rare\u2019 (\u2018Petit meslier blanc. Delicate leaf, white underside, small and excellent grape, few berries\u2019). According to Galet (1990), Petit Meslier originates from north-eastern France, between Champagne and the Franche-Comt\u00e9, which is consistent with DNA profiling showing that Petit Meslier is a natural GOUAIS BLANC \u00d7 SAVAGNIN cross (see PINOT pedigree diagram), just like its siblings AUBIN BLANC from Lorraine (Bowers et al. 2000) and R\u00c4USCHLING from the Rheintal (Rhine Valley) in Germany (Vouillamoz). In addition, DNA parentage analysis has strongly suggested that AMIGNE from the Valais, Switzerland, is a likely grandchild of Petit Meslier (Vouillamoz and Moriondo 2011). Petit Meslier is a member of the Messile ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Once valued in the Champagne region from France for its ability to produce wines able to retain acidity in warm vintages, particularly in the warmer areas south of the River Aube, it fell out of favour because of its erratic and often low yields and because the timing of the harvest is particularly time-critical \u2013 the grapes are not easy to ripen and when they are ripe, they must be harvested promptly. The very high acidity and low pH mean that it is also more difficult to vinify and needs longer on the lees to attain balance.',
      'There were just 4 ha (10 acres) remaining in France in 2008, although it is still permitted in the Champagne and still Coteaux Champenois appellations. There is clearly a growing interest in this variety. Duval-Leroy, for example, produced their first 100% Petit Meslier vintage champagne in 1998, from a vineyard in Venteuil in the Vall\u00e9e de la Marne, and will continue to do so in years when the quality is high enough. Wines are generally quite floral and sometimes show aromas of rhubarb and white nettle. Tarlant have recently replanted some Petit Meslier, as well as PINOT BLANC and ARBANE, and have produced a new cuv\u00e9e with 75% Petit Meslier. Ren\u00e9 Geoffroy too has included some Petit Meslier in a field blend, and a few champagne houses such as Moutard and L Aubry Fils include a small amount in particular cuv\u00e9es.',
      'More unexpected is the rare varietal sparkling wine produced by Irvine Wines in the Eden Valley, South Australia. They planted their 0.6 ha (1.5 acres) of vines in the mid 1980s and ten years later decided to start producing a varietal wine. Along with another local grower, who supplies them, they are pioneers of the variety in Australia. The wine has fine acidity and intense flavours of green apple.',
    ],
  },
  {
    name: 'PETIT ROUGE',
    synonyms: ['Oriou', 'Picciourouzo'],
    description:
      'Old, dark-skinned Aostan producing spicy, redcurrant-flavoured wines.',
    'origin and parentage': [
      'Petit Rouge, French for \u2018little red\u2019, is one of the oldest and most widespread varieties of the Valle d\u2019Aosta in the Italian Alps. It was described by Gatta (1838) as the main type of the Orious, a group of closely related varieties which includes, as the most recent DNA studies have shown, Petit Rouge, CORNALIN and VIEN DE NUS in the Valle d\u2019Aosta, as well as ROUGE DE FULLY and ROUGE DU PAYS in the Valais (Vouillamoz and Moriondo 2011). DNA parentage analysis has provided evidence that Petit Rouge is a parent of Rouge du Pays through a natural cross with MAYOLET (Vouillamoz et al. 2003), a sibling of FUMIN and a parent of Vien de Nus (Vouillamoz and Moriondo 2011), illustrating the key role of Petit Rouge for Valle d\u2019Aosta viticulture.',
      'Several clonal variations of Petit Rouge are distinguished under the group name Oriou: Oriou Lombard (the most widespread), Oriou Voirard, Oriou Picciou, etc.',
    ],
    'where its grown': [
      'Petit Rouge is the most important grape variety of the Valle d\u2019Aosta in Italy\u2019s far north west, cultivated mainly between Saint-Vincent and Arvier, in particular in Aymavilles, Saint-Pierre and Villeneuve, where it contributes at least 70% of the blend, with MAYOLET and VIEN DE NUS, in the Torrette DOC, a historical wine already famous at the beginning of the nineteenth century, at least 85% in Enfer d\u2019Arvier DOC and contributes to a lesser extent to the Nus Rouge and Chambave Rouge DOCs. There were 119 ha (294 acres) in Italy in 2000.',
      'Varietal wines tend to have aromas of redcurrants and a spicy, fruity taste. Examples include Petit Rouge DOC from Anselmet, Di Barr\u00f2, Didier Gerbelle, Vinirari; Enfer d\u2019Arvier from Albino Thomain; and Torrette DOC by Ch\u00e2teau Feuillet.',
    ],
  },
  {
    name: 'PETIT VERDOT',
    synonyms: ['Lambrusquet', 'Verdau', 'Verdot', 'Verdot Petit'],
    description:
      'High-quality, late-ripening Bordeaux variety adding colour and spice to blends there but making an increasing number of solo appearances elsewhere.',
    'origin and parentage': [
      'Petit Verdot and GROS VERDOT are usually considered to come from the Gironde in south-west France, where they were first mentioned together in Bordeaux in 1736 (R\u00e9zeau 1997): \u2018grand verdot, petit verdot, bons\u2019. Although they partially share a name, derived from vert, meaning \u2018green\u2019 (in the sense of unripe and/or acidic), and although they used to be planted together, Petit Verdot and Gros Verdot are two completely different varieties. The ampelographer Bisson (2009) classifies Petit Verdot in the Carmenet ampelographic group (see p XXVII), together with Ardonnet, ARROUYA, CABERNET FRANC, CABERNET SAUVIGNON, FER, MERLOT, Merlot Blanc and Pardotte, most of them still cultivated in Bordeaux along with Petit Verdot, but recent ampelographic and genetic research has placed Petit Verdot in a distinct ampelographic group along with Ardonnet, Gros Verdot and Petit Verdot Faux (Bordenave et al. 2007). This as yet unnamed group most likely originates from the Pyr\u00e9n\u00e9es-Atlantiques, where these varieties could have been domesticated from wild grapevines, because their morphology is very similar to the lambrusques (Levadoux 1956), the French name for Vitis vinifera subsp. silvestris that gave Petit Verdot its synonym Lambrusquet.',
      'DNA parentage analysis has shown that Petit Verdot and DURAS spontaneously crossed to give birth to TRESSOT (Bowers et al. 2000; see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'When fully ripe \u2013 not always easy to achieve in Bordeaux \u2013 Petit Verdot produces wines that are powerful, rich, deeply coloured, tannic and ageworthy, often spicy and with good levels of alcohol and acidity in the best sites. Today it generally plays a small part in the Bordeaux blend but in the nineteenth century, according to Bordenave et al. (2007), Petit Verdot was the dominant variety in the Queyries vineyards on the left bank of the Gironde, what is now the Bastide area of the city. Even though the planted area has declined since then, the variety\u2019s qualities and contribution are highly prized in the best vintages \u2013 except that ironically the best vintages are often those when that contribution is least needed to enrich the Cabernet and MERLOT. Wines which benefit from a relatively high proportion of Petit Verdot in some years include Ch\u00e2teaux La Lagune, L\u00e9oville Poyferr\u00e9, Margaux, Palmer and Pichon Lalande.',
      'Petit Verdot is recommended in much of southern and south-western France. Of the 862 ha (2,130 acres) recorded in 2009, the majority (526 ha/1,300 acres) was in the Bordeaux region. Plantings declined in the second half of the twentieth century, down to just 338 ha (835 acres) in 1988, mostly because of its inconveniently late ripening, but they are increasing once again, perhaps encouraged by global warming. Varietal wines are very rare in Bordeaux (though Moutte Blanc is an exception) but are increasingly produced elsewhere, including the Languedoc. Domaines Preignes and de Ravanes are examples.',
      'Italy recorded 63 ha (156 acres) in the 2000 agricultural census, predominantly in the Maremma or in Toscana more generally for seasoning Bordeaux blends chez the likes of Ornellaia, Castello del Terriccio and Cavali Tenuta degli Dei. Casale del Giglio in Lazio make a rare varietal example.',
      'The variety was introduced to Spain in the 1990s by Marqu\u00e9s de Gri\u00f1on at Dominio de Valdepusa near Toledo and the country\u2019s plantings had reached 1,042 ha (2,575 acres) by 2008. It ripens well here and has sufficient flesh to provide a satisfactory varietal in the hands of Abad\u00eda Retuerta (Castilla y Le\u00f3n); Casa de la Ermita and Luz\u00f3n (Jumilla); Friedrich Schatz, La Sangre de Ronda and Vetas (Sierras de M\u00e1laga); Array\u00e1n (M\u00e9ntrida); Finca Antigua (La Mancha); Laudum Nature, Enrique Mendoza and Vinalop\u00f3 (Alicante); and Pago del Vicario (Castilla).',
      'Portugal reported 139 ha (343 acres) in 2010, used mainly in ambitious blends such as Campolarga\u2019s Calda Bordalesa (with ALICANTE HENRI BOUSCHET) or Monte da Ravasqueira\u2019s Premium (with SYRAH and MERLOT). However, varietal versions are made by Azamor, J\u00falio Bastos, Lima Mayer and Monte da Ravasqueira, all in the Alentejo. This late-ripening variety does well in this hot southern region and produces fresh, lively wines with intense colour and notable fragrance.',
      'Turkey (20 ha/49 acres in 2010) and Israel (50 ha/124 acres in 2009) produce a few varietal examples.',
      'In California, plantings have been increasing to reach 4,396 acres (1,779 ha) by 2008, mostly in Napa, San Luis Obispo and San Joaquin counties, generally for including in Meritage (Bordeaux) blends, but the number of varietal versions has been increasing significantly, with L\u2019Aventure\u2019s from Paso Robles being particularly well received. Washington State had 131 acres (53 ha) at the last count in 2006, also for Bordeaux blends in the main, but varietals are also emerging at quite a rate here, where there is rarely a problem achieving full ripeness. There are limited plantings in other American states, particularly those making Bordeaux blends such as Virginia (167 acres/68 ha by 2010) and a few plantings on Long Island in New York State. Petit Verdot is also finding friends in the Okanagan Valley in Canada\u2019s British Columbia.',
      'Argentina recorded 455 ha (1,124 acres) of Petit Verdot in 2008 (though it is generally referred to as just Verdot). Finca Anita and Landelia Mendoza produce good varietal examples. Chile recorded 257 ha (635 acres) in 2008, though the distinction between Petit Verdot and what the statistics refer to simply as \u2018Verdot\u2019 may not always be accurate. Von Siebenthal\u2019s bottling is a worthy representative of the real McCoy. Uruguay had 20 ha (49 acres) in 2009, on a steady upward trajectory over the last ten years or so. The variety plays a significant role at Vi\u00f1a Tacama in the Ica Valley in Peru, where it is generally blended with TANNAT and/or Malbec (COT).',
      'Petit Verdot has become a popular varietal in Australia, particularly as an alternative to Shiraz (SYRAH) in warmer regions such as Riverland, Riverina and Murray Darling. Because it ripens two weeks later, it is likely to finish ripening in cooler conditions. In 2008 there were 1,354 ha (3,346 acres), mostly in these inland regions but also in McLaren Vale, Padthaway, Langhorne Creek, Margaret River, Barossa, Mudgee, Swan Hill, Clare Valley and Coonawarra. Here it is far more likely than in Bordeaux to be produced as a varietal wine and these are generally very deeply coloured, dark-fruited (more plum than cassis), rich, full-bodied and smooth, sometimes with a floral note akin to violets. Recommended producers include Capel Vale, De Bortoli, Gemtree and Trentham Estate. It is occasionally an ingredient in New Zealand\u2019s Bordeaux blends.',
      'The variety looks set for a very positive future in South Africa, although here too it is more often encountered in Bordeaux blends than as a varietal. Total vineyard area has grown from little more than 20 ha (49 acres) in 1998 to 634 ha (1,567 acres) ten years later, mostly in Paarl and Stellenbosch, but it shows promise in other wine growing regions except perhaps in the Swaartland, where it may be too dry. Recommended from among the handful of varietal wines are Zorgvliet\u2019s powerful version, Asara\u2019s and a charming example from the KWV Mentors range.',
    ],
  },
  {
    name: 'PETITE AMIE',
    synonyms: ['DM 8313.1'],
    description:
      'Recent and promisingly aromatic American hybrid planted in Nebraska.',
    'origin and parentage': [
      'An Elmer Swenson 2-11-4 \u00d7 DM P2-54 hybrid obtained in 1983 by David MacGregor in South Haven, Minnesota, in which:',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 2-11-4 is an Elmer Swenson 5-14 \u00d7 SWENSON RED hybrid (see BRIANNA for the pedigree of Elmer Swenson 5-14)',
      '\u2013\u00a0\u00a0\u00a0DM P2-54 is a Suelter \u00d7 MORIO-MUSKAT hybrid',
      '\u2013\u00a0\u00a0\u00a0Suelter is a Vitis riparia \u00d7 CONCORD hybrid.',
      'Petite Amie it therefore an extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris, Vitis cinerea and Vitis berlandieri hybrid.',
      'The plant was selected in 1987 and tested as DM 8313.1 before being named in 2004 by grower Ed Swanson of Cuthills in Pierce, Nebraska, with the permission of David MacGregor.',
    ],
    'where its grown': [
      'Wines typically have a fine grapey flavour similar to MUSCAT BLANC \u00c0 PETITS GRAINS as well as an aroma of roses and tropical flavours. There was less than an acre (0.4 ha) planted in Nebraska in 2007 and Cuthills is currently the only producer there. Indian Island in Minnesota also produce a varietal wine.',
    ],
  },
  {
    name: 'PETITE PEARL',
    synonyms: ['TP 2-1-24'],
    description: 'Promising new cold-hardy American hybrid.',
    'origin and parentage': [
      'An MN 1094 \u00d7 Elmer Swenson 4-7-26 hybrid crossed in 1996 by Tom Plocher, private grape breeder in Hugo, Minnesota, US, in which:',
      '\u2013\u00a0\u00a0\u00a0MN 1094 (MN stands for Minnesota) is a hybrid between MN 1019 and MN 1016 (see MARQUETTE for the pedigrees)',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 4-7-26 is an Elmer Swenson 2-12-27 \u00d7 ST CROIX hybrid',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 2-12-27 is an Elmer Swenson 5-14 \u00d7 SWENSON RED hybrid (see BRIANNA for the pedigree of Elmer Swenson 5-14).',
      'Petite Pearl is therefore an extremely complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\naestivalis, Vitis\nlincecumii, Vitis\nrupestris, Vitis\ncinerea and Vitis\nberlandieri hybrid. The plant was tested as TP 2-1-24 and was initially named Black Pearl by Plocher, who had to change the name to Petite Pearl to avoid a trademark dispute. Petite Pearl was finally released by Plocher in 2009.',
    ],
    'where its grown': [
      'This new variety shows great promise among new winter-hardy hybrids and produces big, dense red wine with some nice soft tannins, not too high acidity and complex aromas mainly of herbs and spices. Plocher suggests it has some similarity with Malbec (COT) or Bonarda (DOUCE NOIRE). Commercial wines are expected from 2013 in the US Midwest but it is likely to be popular with growers in cold-climate regions such as New York State, and Ontario and Qu\u00e9bec in Canada.',
    ],
  },
  {
    name: 'PETRA',
    synonyms: ['Petka'],
    description: 'Serbian hybrid producing full-bodied, aromatic whites.',
    'origin and parentage': [
      'A Kunbarat \u00d7 PINOT NOIR hybrid obtained in 1977 by P Cindri\u0107 and V Kova\u010d at the viticulture research centre in Sremski Karlovci, part of Novi Sad University in Vojvodina, Serbia, in which Kunbarat is a (Vitis amurensis \u00d7 Vitis vinifera) \u00d7 ITALIA hybrid. Petra was used to breed BA\u010cKA and RUBINKA.',
    ],
    'where its grown': [
      'Grown mainly in the Fru\u0161ka Gora region, Serbia, Petra produces wines that are high in alcohol with Muscat-like aromas and are well suited to the production of dessert wines. Producers of varietal wines include Daniel Celov\u0161ki, Stanislav \u0110ier\u010dan, Milijan Jeli\u0107 and Du\u0161an Stefanovi\u0107.',
    ],
  },
  {
    name: 'PETROKORITHO',
    synonyms: ['Petrocoritho', 'Pietro Corinto'],
    description:
      'Greek island variety of which the white mutation is the stronger survivor.',
    'origin and parentage': [
      'The dark-skinned Petrokoritho Mavro comes from the Greek island of K\u00e9rkyra (Corfu) but it is the light-skinned mutation (sometimes referred to as Petrokoritho Lefko) that survives.',
    ],
    'where its grown': [
      'Once found mainly on the island of K\u00e9rkyra but also in the Pelop\u00f3nnisos (Peloponnese), Greece, and on \u00c9vvoia (Euboea; Krimbas in Galet 2000), the original black-berried Petrokoritho Mavro is close to extinction. However, Livadiotis make a rare varietal version from the white-berried mutation in the south west of K\u00e9rkyra that has lower alcohol than KAKOTRYGIS but higher acidity.',
    ],
  },
  {
    name: 'PETROULIANOS',
    synonyms: ['Petrolianos'],
    description:
      'Rare but promising vine from the southern tip of Greece\u2019s Pelop\u00f3nnisos (Peloponnese).',
    'origin and parentage': [
      'Rare variety local to the southern Pelop\u00f3nnisos in Greece.',
    ],
    'where its grown': [
      'Petroulianos is found mainly in Lakon\u00eda in the southern Pelop\u00f3nnisos, Greece, but also on the island of K\u00fdthira (Cythera) a little further south. Yiannis Vatistas was one of the first producers to select Petroulianos vines from a field blend and plant the variety in a single vineyard close to the sea. He makes a lightly oaked varietal wine that is relatively full-bodied but still quite taut and has an intense, pure lemon character, a peachy richness and a clean minerality with relatively soft acidity. He also includes it in a sweet blend with ASSYRTIKO and MALVASIA.',
    ],
  },
  {
    name: 'PHOENIX',
    synonyms: ['Geilweilerhof GA-49-22'],
    description:
      'Disease-resistant German hybrid producing fresh and lightly aromatic wines in the UK.',
    'origin and parentage': [
      'A complex hybrid obtained by crossing BACCHUS \u00d7 VILLARD BLANC authorized in Germany in 1992. It was named after the mythological bird that rose from the ashes.',
    ],
    'where its grown': [
      'Although Germany has a greater area of Phoenix vineyards than the UK (48 ha/119 acres v 16 ha/40 acres), it is declining there owing to its susceptibility to botrytis bunch rot. German plantings are mainly in Rheinhessen and the Nahe.',
      'It has been relatively more successful and significant in England, where it can produce attractively herbaceous and elderflower-scented wines, similar to BACCHUS, tasting very much like wines made from vinifera vines, which is why the variety has been registered as vinifera and authorized for the production of quality wines. Three Choirs produce a good example. It is also planted to a very limited extent in Switzerland, Denmark and Sweden.',
    ],
  },
  {
    name: 'PICAPOLL BLANCO',
    synonyms: [
      'Blanca Extra',
      'Clairette Blanche',
      'Picapoll',
      'Picapolla Blanca',
    ],
    description:
      'Minor Catalan that may be the same as France\u2019s Clairette Blanche.',
    'origin and parentage': [
      'Picapoll is an indigenous variety from the area that is now designated as the Pla de Bages DO, north west of Barcelona in Catalunya, north-eastern Spain, where it was already mentioned in the mid sixteenth century and where it was the only white variety at the end of the nineteenth (Puig et al. 2006). DNA profiling has shown that Picapoll Blanco is not a white mutation of Picapoll Negro, which is a synonym for PIQUEPOUL NOIR, and is not identical to PIQUEPOUL BLANC from the Vaucluse in southern France (Puig et al. 2006). Furthermore, the DNA profiles of Picapoll Blanco and CLAIRETTE from southern France are almost identical (Puig et al. 2006) but it is not yet clear whether Picapoll Blanco is simply a clonal variation of Clairette or a distinct yet very closely related variety (Vouillamoz).',
    ],
    'where its grown': [
      'There were 29 ha (72 acres) of Picapoll Blanco in Spain in 2008, mostly in Catalunya, where it is authorized in the Pla de Bages, Empord\u00e0, Priorat and Alella DOs. Wines tend to have moderate acidity with grapefruit and herbal flavours, floral in cooler years. Producers of varietal wines include Abadal and Mas de Sant Iscle, both in Bag\u00e9s, west of Tarragona.',
    ],
  },
  {
    name: 'PICARDAN',
    synonyms: [
      'Aragnan',
      'Aragnan Blanc',
      'Araignan Blanc',
      'Gallet',
      'Gallet Blanc',
      'Grosse Clairette',
      'Milhaud Blanc',
      'Papadoux',
      'Oeillade Blanche',
      'Picardan Blanc',
    ],
    description: 'Occasionally adds acidity to Ch\u00e2teauneuf.',
    'origin and parentage': [
      'Picardan is an old variety from Provence in southern France, where it used to be cultivated in the Var, Vaucluse, Gard, H\u00e9rault and in the Vall\u00e9e du Rh\u00f4ne under various names, especially Oeillade Blanche and Araignan Blanc. The names Picardan and Oeillade Blanche were first mentioned in 1544 in the \u2018Chant de vendanges\u2019 by Bonaventure des P\u00e9riers (R\u00e9zeau 1997): \u2018Les vins des blancs sperollans/Des rouvergnas, des picquardans/Des belles grappes muscades/Pellef\u00e8des et oeillades\u2019 (\u2018The wines from the white sperollans/The rouvergnas, the picquardans/The beautiful bunches of muscades/Pellef\u00e8des and oeillades\u2019). However, the name Picardan was also used for BOURBOULENC, and \u2018oeillades\u2019 here could also refer to CLAIRETTE. Therefore, the first indisputable mention of this variety appeared much later, in 1715, under its synonym Araignan Blanc (R\u00e9zeau 1997): \u2018Vitis acini oblong, subviridi, dulci & molli. Aragnan. Cette esp\u00e8ce est tr\u00e8s-commune\u2019 (\u2018Vine with oblong, greenish, sweet and soft berries. Aragnan. This species is very common\u2019). The name Picardan supposedly derives from the French terms piquer (\u2018to sting\u2019) and ardent (\u2018burning\u2019), in reference to its acidity, and the name Araignan probably comes from the French araign\u00e9e (\u2018spider\u2019), in reference to the spiderweb-like hairs on the underside of the leaves.',
    ],
    'where its grown': [
      'Wines tend to be moderate in both alcohol and acidity and the variety\u2019s most well-known home is in Ch\u00e2teuneuf-du-Pape, southern France, where it is one of the eighteen varieties (plus colour mutations) authorized for red and white appellation wines, albeit a very minor one. Ch\u00e2teau de Beaucastel, for example, still include very small amounts of Picardan in their red Ch\u00e2teauneuf (from a 15-are parcel and from old interplanted vineyards). Although there was less than a hectare (1.2 acres) planted in France in 2008, Clos de Centeilles in the Minervois have 35% Picardan (which they refer to as Araignan Blanc) in their C de Centeilles Blanc, a Vin de Pays des C\u00f4tes du Brian from the H\u00e9rault.',
    ],
  },
  {
    name: 'PICCOLA NERA',
    synonyms: ['Mala Cerna', 'Nera Tenera'],
    description: 'Makes very pale reds in the hinterland of Trieste.',
    'origin and parentage': [
      'This trivially named variety (literally \u2018little black\u2019) originates from the border region of Italy and Slovenia, in the province of Trieste.',
    ],
    'where its grown': [
      'Piccola Nera is cultivated in the province of Trieste, Italy, close to the Slovenian border. It is said also to be found in the province of Trento in Trentino to the west but it is sometimes confused with SCHIAVA GROSSA. It produces a light ros\u00e9-red wine for early drinking. A small amount may be blended with TERRANO in the Carso DOC. It is also authorized as a varietal wine under the IGT Venezia classification. The Italian agricultural census of 2000 recorded just 20 ha (49 acres).',
      'It is also found occasionally in Slovenia.',
    ],
  },
  {
    name: 'PICOLIT',
    synonyms: ['Pikolit'],
    description:
      'Ancient Friulian producing small quantities of highly regarded sweet wines.',
    'origin and parentage': [
      'The earliest mention of Picolit appears in 1682 in a list of wines for the wedding celebration of Alvise Contarini, the Doge of Venice. Picolit wines became famous throughout Europe in the eighteenth century when Conte Fabio Asquini began exporting to the courts of France, Holland, Austria, England and Russia the few thousand bottles produced from his vineyards at Fagagna near Udine. It was a marketing triumph, with the wines bottled in hand-made Murano glass and sold at a far higher price than any other wine from Friuli at that time. The Pope liked it, too, apparently. Asquini also left detailed notes on how the wine was made although no one picked up the baton until the twentieth century (see below). This fame prompted some to identify the Hungarian varieties Balaf\u00e1nt, today almost extinct, and K\u00c9KNYEL\u0170 with Picolit, but this can be rejected by comparison of their DNA profiles (Jahnke et al. 2007; Varga et al. 2008; Galb\u00e1cs et al. 2009; Crespan et al. 2011).',
      'The name Picolit could derive from the reduced size of the clusters (piccolo is Italian for \u2018small\u2019), or from the local dialect pecol, meaning \u2018top of a hill\u2019, the best place for late-harvest Picolit.',
      'Picolit is not a colour mutation of PICULIT NERI.',
    ],
    'where its grown': [
      'Formerly cultivated in the provinces of Udine, Treviso (Conegliano), Bassano and Vicenza in Friuli, Italy, Picolit vineyards were severely reduced by phylloxera and it was mainly thanks to Giuseppe Perusini and his family of the Rocca Bernarda in Rosazzo that Picolit survived the first half of the twentieth century, helped by their work on identifying hardier clones, rising to become something of a cult wine in the late 1960s and early 1970s, though plantings are still very limited. Today the variety is grown mainly in the provinces of Udine and Gorizia. There were just 97 ha (240 acres) in Italy in 2000.',
      'It is used almost exclusively to make sweet late-harvest wines within the recently promoted Colli Orientali del Friuli Picolit DOCG. Wines from the Rosazzo and Cialla subzones are recognized as the finest but there is little consistency with regard to style. The grapes are either dried on mats or in racks after the harvest or simply left longer on the vine, harvested with or without the influence of botrytis. More recently, some producers have experimented with ageing in small oak barrels.',
      'Many wines are less impressive than one might expect considering the prices they achieve but recommended producers include Ronchi di Cialla, Dorigo, Livio Felluga and Moschioni. The best are delicately sweet with floral aromas and the flavour of apricots and peaches, sometimes a little honeyed. Wines aged for more than two years may be labelled Riserva. Picolit may also play a major or minor role in the dry Colli Orientali del Friuli Bianco or the sweet Colli Orientali del Friuli Dolce. Le Due Terre produce a rare dry varietal Picolit called Implicito.',
      'Pikolit is planted in Slovenia under two morphologically distinct types called Pikolit Italia and Pikolit Vienna, although DNA profiling has shown that both are identical to Picolit from Friuli (\u0160tajner et al. 2008) and growers are unlikely to distinguish between them. Total plantings in 2009 were just 5 ha (12 acres), in Gori\u0161ka Brda and Vipavska Dolina. Producers include \u0160\u010durek, who make a dry, alcoholic version, and Vinska Klet Gori\u0161ka Brda and \u010carga, who make sweet or medium-sweet versions.',
      'Pizzini produce some in the King Valley, Victoria, Australia.',
    ],
  },
  {
    name: 'PICULIT NERI',
    synonyms: ['Picolit Neri', 'Picolit Nero'],
    description:
      'Old, rare, small-berried red wine variety unrelated to Picolit.',
    'origin and parentage': [
      'Historically cultivated in Friuli. Its name simply means \u2018small black\u2019, in reference to its tiny dark berries. Despite the similarity in their names, Piculit Neri is not a black mutation of the white-berried PICOLIT used to make expensive sweet wines in Friuli.',
    ],
    'where its grown': [
      'Piculit Neri is cultivated in particular in the province of Pordenone in Friuli-Venezia Giulia in north-east Italy, mainly in Pinzano al Tagliamento and Castelnovo del Friuli. Together with other old local varieties, CIANORIE, CIVIDIN, FORGIARIN, SCIAGL\u00ccN and UCEL\u00d9T, it was saved from extinction by Emilio Bulfon, with the help of ampelographers Antonio Cal\u00f2 and Ruggero Forti. Emilio Bulfon is one of the few producers to craft varietal wines from these grapes. Others include Florutis and Ronco Cliona. Piculit Neri has firm tannins and aromas of herbs, red fruits and vanilla. Together with Forgiarin, Sciagl\u00ecn and Ucel\u00f9t, Piculit Neri was included in the Italian national register of varieties in 1991.',
    ],
  },
  {
    name: 'PIEDIROSSO',
    synonyms: [
      'Palombina',
      'Palumbina',
      'Palumbo',
      'Palummina',
      'Per\u2019e Palummo',
      'Streppa Verde',
    ],
    description:
      'Fresh, aromatic Campanian that makes an ideal blending partner for Aglianico but can also shine as a varietal.',
    'origin and parentage': [
      'Piedirosso is a very old Campanian variety, probably originating from the area of Vesuvio, which is still the variety\u2019s heartland. It was supposedly first mentioned in the sixteenth century under its old synonyms Palomina Nera and Uva Palombina but the first reference to the name Piedirosso appears much later, in Carlucci (1905) in the provinces of Avellino and Benevento. However, Carlucci was not convinced that Palomina Nera and Uva Palombina were botanically identical to Piedirosso because the older references lacked good descriptions. Nevertheless, their names have a common origin: Piedirosso, meaning \u2018red feet\u2019, comes from the red colour of the stalk and the stem at harvest time, which look like the claws of a pigeon (Latin Columba palumbus). Interestingly, the clonal variation known as Streppa Verde (\u2018green stalk\u2019) on the island of Ischia does not have this red character. DNA profiling has shown that Piedirosso is distinct from Piedirosso Avellinese (province of Avellino) and from Piedirosso Beneventano (province of Benevento), also known as Piedirosso Napoletano in the province of Napoli, neither commercially cultivated, and genetically close to CAPRETTONE (Costantini et al. 2005).',
    ],
    'where its grown': [
      'Piedirosso, intensively cultivated in the area around Napoli in southern Italy, is the second most widely grown red in Campania after AGLIANICO, with which it is usually blended to add perfume and freshness in DOCs such as Cilento, Falerno del Massico and Sant\u2019Agata de\u2019 Goti. It plays a more dominant role or may be a varietal in DOCs such as Capri, Ischia, Taburno, Sannio and Vesuvio. In some instances, eg Costa d\u2019Amalfi, Penisola Sorrentina and Vesuvio, it may also be blended with the local SCIASCINOSO. Piedirosso is also authorized in the province of Bari in Puglia. Plantings halved in the 1980s but have been fairly constant since then, with 1,013 ha (2,503 acres) recorded in the Italian census in 2000.',
      'Varietal wines tend to be relatively soft despite their fresh acidity, and aromatic, with flavours of red fruits such as plums and cherries \u2013 almost Gamay-like in some examples. They may also have herbal and spicy notes, as in Mastroberardino\u2019s Lacryma Christi del Vesuvio Rosso. Other producers of varietal wines include Annunziata, Mustilli and Cantina del Taburno. Many of the varietal wines are classified as IGT, eg IGT Campania or IGT Pompeiano.',
    ],
  },
  {
    name: 'PIGNOLA VALTELLINESE',
    synonyms: [
      'Groppello di Breganze',
      'Pignola',
      'Pignola Spanna',
      'Pignolo Spano',
    ],
    description:
      'Minor red wine grape grown in Lombardia and Piemonte, and in the Veneto as the recently rescued Groppello di Breganze.',
    'origin and parentage': [
      'The name Pignola, derived from pigna, meaning \u2018pine cone\u2019 and referring to the shape of the bunch, has been given to several varieties in central Piemonte, Valtellina, the Oltrep\u00f2 Pavese and Friuli. DNA profiling has shown that Pignola from Valtellina is identical to Pignolo Spano cultivated in Piemonte (Schneider et al. 2001). PIGNOLO from Friuli, on the other hand, is a completely distinct variety (Fossati et al. 2001; Salmaso et al. 2008), whereas the true identity of Pignolo from the Oltrep\u00f2 Pavese is still uncertain. Since DNA parentage analysis established that the Pignola grown in Valtellina has a parent\u2013offspring relationship with ROSSOLINO NERO (Vouillamoz), a variety local to Valtellina, this Pignola is likely to originate in Valtellina and would be better named Pignola Valtellinese to avoid any confusion. In addition, DNA studies have suggested that Pignola Valtellinese could also be a cousin of NEBBIOLO (Schneider, Boccacci et al. 2004), called Chiavennasca in Valtellina.',
      'A recent ampelographic and DNA profiling study has unexpectedly shown that Pignola Valtellinese is identical to Groppello di Breganze in the province of Vicenza in the Veneto, northern Italy, where it was already mentioned in the Breganze area in the eighteenth century (Cancellier et al. 2009). According to this study, this variety is distinct from GROPPELLO GENTILE, GROPPELLO DI MOCASINA and GROPPELLO DI REV\u00d2, in spite of some morphological similarities with the last.',
    ],
    'where its grown': [
      'Under the name Pignola Valtellinese, this variety is cultivated in the province of Sondrio in Lombardia; under the name Pignolo Spano, it is grown in Piemonte in the provinces of Biella, Novara and Vercelli, often blended with NEBBIOLO (misleadingly called Spanna in the same areas). There were 77 ha (190 acres) in Italy in 2000. Triacca\u2019s Pinea, classified IGT Terrazze Retiche di Sondrio, is a rare varietal wine. In general, acidity is quite high but tannins are moderate, and certainly softer than those of Nebbiolo. Under the name Groppello di Breganze in the province of Vicenza in the Veneto, it was rescued from extinction by Severina Cancellier, an ampelographer based at the Conegliano research centre, and has recently been vinified by a few producers in Breganze, resulting in fruity, tannic wines. Cantina Beato Bartolomeo\u2019s passito wine Ezzelino is a blend of Groppello di Breganze and CABERNET SAUVIGNON, while Firmino Miotti crafts a 100% Groppello di Breganze.',
    ],
  },
  {
    name: 'PIGNOLETTO',
    synonyms: [
      'Grechetto di Todi',
      'Grechetto Gentile',
      'Occhietto',
      'Pallagrello di Caserta',
      'Pignoletto Bolognese',
      'Pignolino',
      'Pulcinculo',
      'R\u00e9bola',
      'Ribolla Riminese',
      'Strozzavolpe',
    ],
    description:
      'Light, refreshing variety quite widely planted in the Bologna hills and in Umbria.',
    'origin and parentage': [
      'Pignoletto is an ancient variety from the hills in the province of Bologna in Emilia-Romagna, where it was supposedly first mentioned in 1654 under the name uve pignole. Like PIGNOLA VALTELLINESE, the name Pignoletto most likely derives from the shape of the bunch, which resembles a pine cone (pigna).',
      'Ampelographic and DNA studies have established that Pignoletto is identical to Grechetto di Todi in Umbria and Ribolla Riminese or R\u00e9bola in Emilia-Romagna but distinct from GRECHETTO DI ORVIETO in Umbria (Filippetti et al. 1999). Parentage studies have suggested a parent\u2013offspring relationship between Pignoletto (under the name Grechetto di Todi) and Grechetto di Orvieto (Costacurta et al. 2004), which might explain why they were previously considered as clonal variations of the same variety, and DNA analysis has suggested a close genetic relationship between SPERGOLA and Pignoletto (Filippetti, Silvestroni, Thomas and Intrieri 2001). More surprisingly, a comparison of the DNA profiles of Pignoletto (Filippetti and Intrieri 1999) and Pallagrello di Caserta (Costantini et al. 2005) has revealed that they are identical. In addition, Pignoletto has often been confused with Pinot Bianco (PINOT BLANC) and Riesling Italico (GRA\u0160EVINA) by local growers.',
    ],
    'where its grown': [
      'Grechetto is authorized in several varietal DOC wines in the province of Perugia in Umbria, central Italy (Assisi, Colli Martani, Colli Perugini and Colli del Trasimeno), as well as in the Marche and in Lazio (Colli Etruschi Viterbesi). However, since some DOC regulations simply say Grechetto, it is often impossible to be sure whether they refer to GRECHETTO DI ORVIETO or Grechetto di Todi although, logically, Grechetto found in the Orvieto area is likely to be Grechetto di Orvieto.',
      'As Pignoletto, it is cultivated mainly in the hills south west of Bologna and produced as a varietal wine in various styles in many DOCs, particularly in the subzones of Colli Bolognesi but also in Colli di Imola and Reno. Pignoletto is the most important white variety in the Colli Bolognesi and the Italian vineyard census of 2000 recorded 6,790 ha (16,778 acres).',
      'Varietal wines are lively and crisp and well suited to the production of sparkling wine. Recommended producers include Chiarli 1860, Il Monticino, Verigilio Sandone, Tizzano and Vallona in Emilia-Romagna and Todini and Tudernum in Umbria.',
    ],
  },
  {
    name: 'PINEAU D\u2019AUNIS',
    synonyms: [
      'Aunis',
      'Chenin Noir',
      'Gros Pineau',
      'Gros-V\u00e9ronais',
      'Plant d\u2019Aunis',
      'Plant de Mayet',
    ],
    description:
      'Ancient, light and underrated Loire variety, often used for ros\u00e9 but capable of interesting reds.',
    'origin and parentage': [
      'The name Pineau appeared as early as 1183 in the Val de Loire, but it has been given to at least four distinct varieties: CHENIN BLANC, MENU PINEAU, PINOT NOIR and Pineau d\u2019Aunis. The first reliable mention of Pineau d\u2019Aunis, a variety from the Val de Loire (mainly Sarthe, Loiret, Vienne, Maine-et-Loire, Indre and Touraine), was in Loches (Touraine) by Andr\u00e9 Jullien (1816): \u2018Dans l\u2019arrondissement de Loches, on cultive le tendrier, l\u2019auberon, le fromenteau, le bordelais, l\u2019aunis, le viret, le salais, le fi\u00e9, le c\u00f4te-r\u00f4tie, le confort et la franche noire\u2019 (\u2018In the Loches arrondissement, we cultivate .\u00a0.\u00a0.\u2019 etc.).',
      'Pineau d\u2019Aunis was named after the French pin, because the bunches resemble pine cones, as do those of PINOT, and after Aunis, a former province of the Charente-Maritime d\u00e9partement.',
      'Although Pineau d\u2019Aunis was called Chenin Noir by Odart (1845), and although CHENIN BLANC is still often called Pineau de la Loire or Gros Pineau, Chenin Blanc is not a white mutation of Pineau d\u2019Aunis, as DNA profiling has verified. Pineau d\u2019Aunis is not related to PINOT and belongs to the Messile ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Wines are generally pale but lively and peppery, often produced as a ros\u00e9 or used as base wines for fizz. France had 435 ha (1,075 acres) in 2009, barely changed in the previous decade but considerably down on the 1,741 ha (4,302 acres) of 1958. Plantings are predominantly around the city of Tours and in the Loir-et-Cher in the Val de Loire. Pineau d\u2019Aunis features in various appellations: as a minor component in ros\u00e9 and sparkling Touraine and in the reds, fizz and sweetish ros\u00e9s of Anjou; red and ros\u00e9 Coteaux du Loir require at least 65%, and in the Coteaux du Vend\u00f4mois, vin gris must be 100% and reds at least 50% Pineau d\u2019Aunis. Domaine de Montrieux\u2019s light red in this last appellation is lifted and peppery but has quite a firm tannic grip. Other recommended producers of varietal wines include Thierry Puzelat (red) and Clos Roche Blanche (ros\u00e9), both from Touraine, and Domaine Les Maisons Rouges in the Coteaux du Loir.',
    ],
  },
  {
    name: 'PINELLA',
    synonyms: ['Mattozza', 'Pinela', 'Pinjela', 'Pinola'],
    description:
      'Minor variety from the hills south of Padova, also grown in neighbouring Slovenia.',
    'origin and parentage': [
      'Pinella probably originates from the province of Gorizia in Friuli, north-east Italy, where it was apparently first mentioned on 8 August 1324 among the grapes used in Udine, Friuli, listed in Pietro di Maniago\u2019s Catalogo delle variet\u00e0 delle viti del Regno Veneto (Fabbro et al. 2002).',
      'Under the Slovenian spelling Pinela, it is considered to be an old Slovenian variety, most likely originating from Vipavska Dolina. DNA profiling has not yet been done to confirm whether it is the same variety as Friulian Pinella.',
    ],
    'where its grown': [
      'Pinella has now virtually disappeared from its supposed cradle in Gorizia in Friuli, Italy, and it is found particularly in the Colli Euganei, the hills just south of the town of Padova. Here it is used to produce still or sparkling wines, as a varietal or blended (maximum 20%) with varieties such as GARGANEGA or PROSECCO, in the DOC of the same name, or as a minor part of the blend in Bagnoli di Sopra (maximum 10%) and Merlara DOCs. Some varietal wines, eg the Cantine dei Colli Tramonte\u2019s sparkling Pinello di Pinella, are classified as IGT Veneto. There were 72 ha (178 acres) planted in Italy in 2000.',
      'There are also limited plantings over the border in Slovenia (50 ha/124 acres in 2009) under the name Pinela, occasionally Pinjela, especially in the Vipavska Dolina region in Primorska in the south west. Recommended producers include Guerila, and Vipava 1894.',
    ],
  },
  {
    name: 'PINOT NOIR',
    synonyms: [
      'Auvernat',
      'Auvernas',
      'Black Burgundy',
      'Blauburgunder',
      'Blauer Arbst',
      'Blauer Sp\u00e4tburgunder',
      'Bourguignon',
      'Burgunder',
      'Cerna',
      'Clevner',
      'Kl\u00e4vner',
      'Cortaillod',
      'K\u00e9k Burgundi',
      'Kisburgundi',
      'Klebroth',
      'Mor\u00e9ote',
      'Morillon',
      'Morillon Noir',
      'Mourillon',
      'Noirien',
      'Noirin',
      'Orl\u00e9anais',
      'Pineau Noir',
      'Pino Fran and Pino Ceren',
      'Pinot Cernii',
      'Pinot Li\u00e9bault',
      'Pinot Nero',
      'Plant Dor\u00e9',
      'Rulandsk\u00e9 Modr\u00e9',
      'Savagnin Noir',
      'Salvagnin Noir',
      'Servagnin',
      'Sp\u00e4tburgunder',
      'Vert Dor\u00e9',
    ],
    description:
      'Finicky Burgundian vine produces wildly variable, relatively delicate, potentially haunting essences of place.',
    'where its grown': [
      'Pinot Noir occupies a very special place in the firmament of well-travelled grape varieties. Such is the allure of top-quality examples, produced mainly in Burgundy, where the variety\u2019s ability to express minute differences in terroir is most brilliantly evident, and the frustration involved in trying to reproduce them in other climates, that making fine Pinot Noir is seen by wine producers the world over as the ultimate test. Its early ripening means that only cooler regions can provide a long enough growing season to produce interesting wines but many climates are too cool or too wet for healthy crop levels.',
      'Outside Burgundy, regions that have earned a reputation for producing successful still Pinot Noir tend to be either continental (Jura, parts of Germany, Switzerland and Canada), low latitude (New Zealand, Tasmania, Patagonia and B\u00edo B\u00edo), high altitude (Alto Adige and some emerging new territories for the vine), or cooled by maritime influence (Oregon, Sonoma, Carneros, Monterey, California\u2019s Central Coast, emerging Chilean coastal regions, and parts of Victoria and Tasmania in Australia).',
      'There may be geographical limitations on its spread but the early twenty-first century saw extraordinary commercial demand for Pinot Noir, especially in the US, as a direct result of Sideways, a successful Hollywood film about its unique qualities. The result was a rash of wine-based movies and particularly enthusiastic planting of the variety in most suitable spots and some unsuitable in Sonoma, Carneros and all points south from Monterey, and a scramble for varietal Pinot Noir, not all of it authentic, on the international bulk market. In parallel with what became known as \u2018the Sideways effect\u2019, warmer summers and more fastidiousness in vineyard and cellar saw the variety, as Sp\u00e4tburgunder, blossom in Germany, where it is hugely popular and where total plantings are surpassed only by those in France and California. Oregon and New Zealand have also staked their red-wine future on this cool-climate variety.',
      'In contrast to Cabernet\u2019s trademark tannins, most Pinot Noir is relatively soft, fruity and easy to like. Great grand cru red burgundy can be tough in its youth and need a decade or two for its ineffable complexities to unfurl but, in general, charm is one of Pinot Noir\u2019s great assets. This can translate into a cheap commercial example that is simply sweet. At much higher prices, some practitioners have evolved a particularly distinctive rich, deep-coloured, powerful (up to 17% alcohol) California style of Pinot. Most other Pinot producers aim to capture some of its elusive delicacy, however, and see a relatively light cherry hue as a badge of honour. Oregonian Pinot Noir is typically more Burgundian than California\u2019s, albeit often betraying the easy fruit of the Pommard clone that underpinned early plantings here, while New Zealand\u2019s exuberantly fruity Pinot Noir can be as easy to appreciate as its SAUVIGNON BLANC, with overtones of dried herbs from Central Otago, occasionally beetroot from Marlborough.',
      'Pinot Noir the wine can also be precocious, with only the very best demanding long ageing. In youth it can taste of cherries, raspberries and a wide range of other fruits even if in Burgundy it can be more obviously autumnal and savoury. As it ages, it can take on even more obviously mulch-like notes, hints of truffles and other fungi, but always with an underlying sweetness and, sometimes, a uniquely filigree appeal. There is more to Pinot than power.',
      'But there is also more to Pinot than still wine. In Champagne, Pinot Noir (along with Pinot Meunier and CHARDONNAY) is a key ingredient, its just-ripe berries pressed lightly to yield pale-pink juice without aggressive tannins whose light charge of pigments is precipitated naturally. The worldwide increase in sparkling wines made in champagne\u2019s image has seen the variety invade territories such as Lombardia in northern Italy, Carneros in northern California, Catalunya in northern Spain, Limoux in southern France and even England.',
      'France grew 29,576 ha (72,595 acres) of Pinot Noir in 2009 so that, despite its acclaim, Pinot Noir was only the seventh most planted red wine vine in France, rather less than the total for GAMAY NOIR. However, the area has expanded remarkably in the last fifty years, from just 8,535 ha (21,090 acres) in 1958. Much of the growth has been in Champagne, where its 12,900 ha (31,877 acres) represented 39% of all plantings in 2010 (as opposed to 34% five years earlier). It is virtually the only variety grown in the southern Aube d\u00e9partement of the Champagne region and makes generally rather earthy, rustic Blanc de Noirs champagnes there.',
      'There is more Pinot Noir grown in Champagne than in its presumed birthplace in greater Burgundy, where 10,691 ha (26,418 acres) grow today, only 6,579 ha (16,257 acres) of that in the C\u00f4te d\u2019Or heartland. Here it reaches the peak of its performance as transmitter of terroir, the medium of expression for perceptibly different though sometimes neighbouring small vineyards. The C\u00f4te d\u2019Or deserves a book to itself and has inspired many excellent works more detailed than we can be here. There are 3,200 ha (7,907 acres) of Pinot Noir in the Sa\u00f4ne-et-Loire, where some first-class vignerons fashion lively and sometimes delicate southern answers to the best of the C\u00f4te d\u2019Or but where most wines are just slightly rustic. Some Pinot Noir makes very light, sometimes tart, red in the greater Chablis area while Pinot Noir is the grape of red and pink Sancerre, Menetou Salon and other Loire appellations. Pinot Noir in marginal areas such as Sancerre and Alsace has been becoming darker in colour and deeper in flavour as general temperatures and winemaker skills have increased. The variety\u2019s 1,521 ha (3,758 acres) in Alsace in 2009 represented almost 10% of the total vineyard area.',
      'With POULSARD, Pinot Noir is the principal red wine grape of the Jura and is allowed in many far-flung French appellations, often in conjunction with other varieties. It is found as far north as the C\u00f4tes de Toul and Moselle and as far west as Saumur for its fizzy pink version but only as far south as Ch\u00e2tillon en Diois (maximum 25%, mainly with GAMAY NOIR), where altitude helps to mitigate the latitude.',
      'Italy had 3,314 ha (8,189 acres) of Pinot Nero, according to the agricultural census of 2000. It tends to be most successful in the Alto Adige, where recommended producers include Hofstatter, Haas, Gottardi and Lageder. Respectable examples are also made by Le Due Terre and Bressan in Friuli and Le Fracce in the Oltrep\u00f2 Pavese, where there has been marked progress with making still wines from this variety in recent years even though a very high proportion of it is used for sparkling wine, notably Franciacorta.',
      'Much of Spain is too hot for this delicate, early-ripening variety. The 968 ha (2,392 acres) planted in 2008 were used predominantly for Cava in Catalunya, with the most successful still varietal examples coming from high-altitude vineyards such as Escoda-Sanahuja\u2019s in Conca de Barber\u00e0. Portugal is no great enthusiast for international varieties of any sort and had just 244 ha (603 acres) of the red burgundy grape in 2010, mainly in the north. Perhaps unexpectedly, the tiny wine industry in England can draw on a greater total area of Pinot Noir than Portugal: 250 ha (618 acres) in 2009, making it the UK\u2019s most planted vine variety, just ahead of CHARDONNAY, thanks to the rush to make English sparkling wine in the image of champagne.',
      'Germany is currently bursting with pride in its hugely improved Sp\u00e4tburgunder or Blauer Sp\u00e4tburgunder, of which there was a total of 11,800 ha (29,158 acres) in 2008. Thanks largely to climate change, but also to lower yields and real will and skill, most German Pinot Noir is now made from fully ripe, healthy grapes with good colour \u2013 and often a tad more sweetness than is customary in France. Baden is the centre of Pinot activity, with 5,855 ha (14,468 acres) of Sp\u00e4tburgunder planted. Well over 1,000 ha (2,471 acres) of the variety are grown in each of the Pfalz, Rheinhessen and W\u00fcrttemberg, however. The overall area increased significantly in the 1980s and 1990s but has now levelled off. Recommended producers include Rudolf F\u00fcrst in Franken; Hessische Staatsweing\u00fcter Kloster Eberbach and August Kesseler in the Rheingau; Friedrich Becker and Klaus Peter Keller in Rheinhessen; J J Adenauer, Deutzerhof, Meyer-N\u00e4kl and Jean Stodden in the Ahr; Bercher, Dr Heger, Bernhard Huber and Martin Wassmer in Baden; and Bernhart, Knipser and Philipp Kuhn in the Pfalz.',
      'Pinot Noir, sometimes known as Blauburgunder, is a comparatively minor red wine grape in Austria, where there were just 649 ha (1,604 acres) in 2009, up from 409 ha (1,011 acres) in 1999 but much less popular than its descendants BLAUFR\u00c4NKISCH and ZWEIGELT, and even less than the local SANKT LAURENT. The most substantial plantings are in Nieder\u00f6sterreich (Lower Austria) and Burgenland and recommended producers include Paul Achs, Gernot and Heike Heinrich, P\u00f6ckl, Claus Preisinger and Schloss Halbturn in Neusiedlersee; Prieler in Neusiedlersee-H\u00fcgelland; Meinklang and Pittnauer in Burgenland; Schloss Gobelsburg in Kamptal; and Gerhard Markowitsch in Carnuntum.',
      'Pinot Noir, often known as Blauburgunder in eastern Switzerland, notched up a 2009 national total of 4,401 ha (10,878 acres), making it the most widely planted red wine variety by far, representing more than half the non-hybrid reds in the country. It is grown throughout the country but with the greatest area in the Valais in the French-speaking south west. The tradition was to blend it with GAMAY NOIR but varietal wines are on the increase. The next biggest areas are in Z\u00fcrich and Graub\u00fcnden in the German-speaking north and east. Recommended varietal wines are made by the likes of Martha & Daniel Gantenbein, Thomas Marugg and Markus St\u00e4ger in Graub\u00fcnden and Domaine Henri Cruchon in Vaud.',
      'Pinot Noir, and vines mistaken for Pinot Noir, are widely spread throughout Eastern Europe, where its name is usually some variant on the local word for Burgundian. The Republic of Moldova has most, 6,521 ha (16,114 acres) in 2009, of the variety known variously as Pino Fran, Pino Ceren and Cerna. Neighbouring Romania had 779 ha (1,925 acres) in 2008, more than half of it on the Black Sea. In Hungary, sometimes known as K\u00e9k Burgundi or Kisburgundi, it was planted on 963 ha (2,380 acres) of vineyard in 2008, with the biggest area on the hills of Balatonbogl\u00e1r, although Eger looks more promising to judge from the handiwork of producers such as Vilmos Thummerer and the late Tibor G\u00e1l. It has a longer tradition in Vill\u00e1ny in the warmer south, but the vital acidity is sometimes lacking. Recommended producers here include Malatinszky, Tiffan and Vylyan. In Croatia, there were 290 ha (717 acres) of Pinot Crni in 2009. In Krasnodar Krai, the main wine-growing region in southern Russia, where it is also known as Pinot Franc, there were 533 ha (1,317 acres) in 2009. Turkey had 10 ha (25 acres) in 2010 and Israel a tiny amount. Ukraine had 767 ha (1,895 acres) in 2009. Pinot Noir is grown to a very limited extent in Georgia (171 ha/423 acres in 2004).',
      'The Sideways effect boosted California plantings of Pinot Noir enormously in the early years of the twenty-first century so that by 2010 the state had a total of 37,290 acres (15,091 ha), especially in Sonoma (11,013 acres/4,457 ha) and Monterey (8,569 acres/3,468 ha). In the mid 2000s, there were about 24,000 acres (9,600 ha), notably in Sonoma \u2013 especially the dynamic but excessively extensive Sonoma Coast AVA and Russian River Valley, where demand for Pinot Noir has put pressure even on ancient Zinfandel (TRIBIDRAG) plantings \u2013 but the most fog-cooled pockets of the Central Coast have seen considerable Pinot plantings too.',
      'A certain amount of California Pinot Noir is used as a sparkling wine base but there has been such huge demand for it as a still red varietal that this is now its principal incarnation. Styles vary from lighter, nervy, cherry-fruited wines from the likes of Saintsbury of Carneros, Rhys of Santa Cruz Mountains, Littorai and Kutch of various Sonoma locations, and Sandhi of Sta. Rita Hills through the firm, long-lived burgundy-inspired wines of Au Bon Climat in Santa Maria, the rather richer Pinots of Santa Lucia Highlands of Monterey to some really full-throttle, late-picked renditions from Sonoma such as those of Marcassin and Kosta Browne.',
      'The entire Oregon wine industry is predicated on Pinot Noir, of which 11,201 acres (4,533 ha) were planted in 2008, up from 3,689 acres (1,493 ha) in 1998, mainly in the Willamette Valley immediately south of Portland. The state staked its wine future on Pinot Noir when David Lett planted the W\u00e4denswil clone back in 1965, having moved north from California in search of cooler climes. The grey skies (and, often, early autumns) of the Willamette Valley suited him, and Pinot, perfectly. Today Dijon clones are all the rage although even some of the wines made from clones sourced in California can have impressive structure and longevity. Domaine Drouhin, owned by the eponymous Beaune n\u00e9gociant, has been a pioneer of sensitively made Pinot Noir but there are dozens of other fine practitioners \u2013 so many that, as in the M\u00e9doc, for example, it is almost invidious to name names. The south of the state can be a little hot for this variety.',
      'Summers are too hot for Pinot Noir in eastern Washington, where most of the state\u2019s vines grow, but it is the most popular variety in the damp Puget Sound and has performed encouragingly in the new Lake Chelan AVA. The state total was 307 acres (127 ha) in 2011.',
      'New York State\u2019s tally of Pinot Noir vines was 335 acres (136 ha) in 2009. There has been some tentative experimentation with Pinot Noir in the Finger Lakes but sparkling versions so far seem the best bet.',
      'There has been some success with the variety in Idaho and in Michigan (135 acres/55 ha in 2006, up from 39 acres/16 ha in 1997), and Gruet grow it on the hot, dry mesas of New Mexico for their sparkling wine. Virginia\u2019s total had reached 37 acres (15 ha) by 2011, including some interesting new plantings at altitude at Ankida Ridge, and there were 70 acres (28 ha) in 2008 in Texas.',
      'Both Ontario (1,025 acres/415 ha in 2006) and British Columbia (793 acres/321 ha in 2008) in Canada can produce some very creditable examples of Pinot Noir indeed, with Le Clos Jordanne and Blue Mountain being skilled respective practitioners, but there have been no shortage of competitors.',
      'The dazzling sunshine and low latitudes of most wine regions in Argentina are inimical to a variety as fragile as Pinot Noir but some intriguingly fine, if relatively rich and earthy, Pinot has emerged from R\u00edo Negro, Patagonia \u2013 notably from Chacra, whose vines are many decades old. Others making good progress with the variety include Familie Schroeder and NQN in Neuqu\u00e9n and Gouguenheim in Tupungato. The country\u2019s total plantings were just 1,509 ha (3,729 acres) in 2008.',
      'Chile has more obvious terrain for Pinot Noir along its fog-cooled Pacific coast, specifically the cooler regions of Casablanca and San Antonio in the north, as well as in the cooler inland vineyards of B\u00edo B\u00edo in the south. Recommended producers include Casa Marin and Matetic in San Antonio; Vi\u00f1a Leyda in the Leyda Valley; Cono Sur (Chile\u2019s Pinot pioneer) and Ventisquero in the Casablanca Valley; and the various operations of the Vi\u00f1a Dos Andes group in B\u00edo B\u00edo, which has initiated joint ventures with such luminaries of Burgundy as Pascal Marchand and Nicolas Potel. Chile grew a total 1,382 ha (3,415 acres) of Pinot Noir in 2008 and is able to produce some of the world\u2019s best-value examples.',
      'There were just 42 ha (104 acres) of Pinot in Uruguay in 2009 and 190 ha (470 acres) in Brazil in 2007. A little is also found in Peru.',
      'As with SAUVIGNON BLANC, Australia is very conscious of New Zealand\u2019s aptitude for Pinot Noir and grows only about the same amount as its much cooler, trans-Tasman rival: 4,490 ha (12,118 acres) in 2008. Australia\u2019s Pinot Noir is if anything more varied than New Zealand\u2019s, even if limited to the coolest spots, many of them in the state of Victoria with the Yarra Valley (702 ha/1,735 acres) offering the greatest concentration of the variety, although only its cooler reaches are really suitable. Victorian Pinot superstars include Coldstream Hills, De Bortoli, Gembrook Hill, TarraWarra and Yering Station in the Yarra Valley; Bannockburn in Geelong; Curly Flat and Domaine Epis in the Macedon Ranges; Bass Phillip in Gippsland; and a host of producers in the ocean-cooled Mornington Peninsula who are making very creditable Antipodean answers to red burgundy. In South Australia, the variety is most commonly planted in the cool Adelaide Hills (404 ha/998 acres) although it is starting to show form in the cool Fleurieu Peninsula. Australia\u2019s second biggest concentration of Pinot Noir is in Tasmania (658 ha/1,626 acres), where much of the Pinot Noir is grown for wine made sparkling on the mainland, notably Hardys\u2019 various fizzes. Stoney Rise, Sinapius, Freycinet (known as Wineglass Bay in Europe) and Stefano Lubiana have all produced some very pretty still Pinot Noir.',
      'Along with SAUVIGNON BLANC, Pinot Noir is one of New Zealand\u2019s proudest accomplishments. Plantings were estimated to be 5,000 ha (12,355 acres) in 2011, up from 2,624 ha (6,484 acres) in 2003 and still increasing, albeit at a slower rate than before. Only about 10% is destined for sparkling wine \u2013 the rest is for exuberantly fruity still red wine that is slowly gaining complexity as the vines age. The greatest area is in Marlborough (2,000 ha/4,942 acres), where the likes of Clos Henri, Dog Point, Fromm, Isabel Estate, Saint Clair and Seresin offer welcome individuality. Central Otago is almost entirely devoted to its cheerfully fruity still Pinot Noir, much of its 1,202 ha (2,970 acres) also having been planted during the recent boom. Felton Road, Mount Difficulty, Peregrine, Quartz Reef and Valli are just some of many reliable names here. Waitaki in North Otago has the limestone that Central lacks, but its young vines have also suffered severe frost damage. Some isolated outcrops of limestone in Canterbury such as those planted by Bell Hill and Pyramid Valley also show promise, as does Mountford. Some of New Zealand\u2019s oldest Pinot Noir vines are in Martinborough in the south east of the North Island, where producers such as Ata Rangi, Dry River and Martinborough Vineyards have already established admirable track records.',
      'Less than 1% of South Africa\u2019s total vineyard area in 2008, 727 ha (1,795 acres), was given over to Pinot Noir, most successfully in the Antarctic-cooled region of Walker Bay, near Hermanus. Recommended producers there include pioneer Hamilton Russell, Bouchard Finlayson and Newton Johnson, although there has long been frustration in Cape winelands that their Pinot Noir has yet to make much of a splash abroad.',
      'Pinot Noir is also found in China (36 ha/89 acres in 2009), Japan (63 ha/156 acres in 2009, mainly in Nagano but results in Hokkaido are promising) and India. But while winemakers all over the world are fascinated by the challenge of producing this delicate variety, in most locations it tends to be too finicky to shine.',
    ],
  },
  {
    name: 'PINOT MEUNIER',
    synonyms: [
      'Auvernat Blanc',
      'Gris Meunier',
      'Meunier',
      'Morillon Taconn\u00e9',
      'M\u00fcllerrebe',
      'Samtrot',
      'Schwarzriesling',
    ],
    description: 'Fruity blending ingredient for top-quality sparkling wine.',
    'origin and parentage': [
      'The black-berried Pinot Meunier is characterized by a layer of white hairs on the underside of the leaves that look like a dusting of flour, hence the name meunier, meaning \u2018miller\u2019 in French. This mutation was first mentioned by Merlet in 1690 (R\u00e9zeau 1997): \u2018le Morillon taconn\u00e9, ou Meunier, \u00e0 cause qu\u2019il a ses fe\u00fcilles blanches et farineuses\u2019 (\u2018Morillon taconn\u00e9, or Meunier, because it has white and floury leaves\u2019).',
      'Samtrot is a mutation of Pinot Meunier observed by Schneider in Heilbronn, north of Stuttgart in southern Germany.',
    ],
    'where its grown': [
      'Pinot Meunier makes more obviously fruity, earlier maturing wine than does Pinot Noir \u2013 although varietal versions are relatively rare, for its wine is most commonly blended with Pinot and CHARDONNAY base wines for sparkling wine.',
      'France grew 11,088 ha (27,387 acres) of Meunier in 2009, making it the tenth most planted red wine variety (and the second most widely planted in the Champagne region after Pinot Noir), even though the word Meunier is hardly ever seen on a French label.',
      'It was once widely planted in the far north of France but is now chiefly found, and valued, in Champagne, particularly in the cooler north-facing vineyards of the damp and frost-prone vineyards of the Vall\u00e9e de la Marne. Not only does it fare better than Pinot Noir on such sites but it also tends to have higher acidity yet can achieve comparable sugar levels, making it a good blending component with Pinot Noir and CHARDONNAY. It is said to contribute youthful fruitiness but not longevity and few growers prize it enough to make a varietal Meunier-dominated wine. Notable exceptions include Fran\u00e7oise Bedel, Egly-Ouriet, Georges Laval, Michel Loriot, J\u00e9r\u00f4me Pr\u00e9vost and, notably, Krug, which long favoured the inclusion of Meunier in their highly regarded blends.',
      'Outside Champagne, plantings are few and far between in France although it is recommended in much of the Loire and eastern France and permitted in such appellations as C\u00f4te de Toul, Moselle and Touraine, and is the principal variety in the ros\u00e9 wines of Touraine Noble-Jou\u00e9 and the reds and ros\u00e9s of Orl\u00e9ans.',
      'England had 65 ha (161 acres) in 2009, an indication of the increasing interest in sparkling wines based on Pinot Noir, Meunier and CHARDONNAY there.',
      'Pinot Meunier is known as M\u00fcllerrebe, and also rather more misleadingly as Schwarzriesling, in Germany, where total plantings were 2,361 ha (5,834 acres) in 2008, mostly in W\u00fcrttemberg (1,738 ha/4,295 acres) but also Baden (266 ha/657 acres), the Pfalz (161 ha/398 acres), Franken (90 ha/222 acres) and Rheinhessen (81 ha/200 acres). It is hard to know how much Samtrot is planted because the official German statistics include this mutation within the area for Pinot Noir (Blauer Sp\u00e4tburgunder). However, good examples are produced by Aldinger, Wachstetter and Weing\u00e4rtner Bad Canstatt, all in W\u00fcrttemberg. Thomas Seeger in Baden is one of the few to produce a worthwhile wine labelled Schwarzriesling. Most others are rather light and insubstantial.',
      'California grew just 163 acres (66 ha) in 2010 (mostly in Sonoma and to a lesser extent in Napa) with still varietal versions typically produced by sparkling-wine operations such as Domaine Chandon. There are also several producers in Oregon\u2019s Willamette Valley, including Eyrie, and 8th Generation make a version in British Columbia, Canada.',
      'Pinot Meunier turns up in a surprising number of countries around the world, most commonly where producers in cooler sites are following the champagne model to make sparkling wine. Australia had 106 ha (262 acres) in 2008. More surprising is the variety\u2019s track record for varietal red wine with some ageing ability in Australia, where it was known at one time as Miller\u2019s Burgundy, produced most successfully from old vines by Best\u2019s Great Western in the Grampians in Victoria. Other producers of varietal Pinot Meunier in Australia include a number of others in Victoria and Barringwood Park in Tasmania.',
      'There were just 19 ha (47 acres) in New Zealand in 2009, used mainly for sparkling wine.',
    ],
  },
  {
    name: 'PINOT GRIS',
    synonyms: [
      'Auvernat Gris',
      'Beurot',
      'Burgunder Roter',
      'Friset',
      'Fromenteau',
      'Fromenteau Gris',
      'Grauburgunder',
      'Grauer Burgunder',
      'Grauer Clevner',
      'Griset',
      'Kleiner Traminer',
      'Malvoisie',
      'Pinot Beurot',
      'Pinot Grigio',
      'Pirosburgundi',
      'R\u00e2jik',
      'Rul\u00e4nder',
      'Rulandsk\u00e9 \u0160ed\u00e9',
      'Rulandsk\u00e9 Siv\u00e9',
      'Sivi Pinot',
      'Speyeren',
      'Sz\u00fcrkebar\u00e1t',
      'Tokay',
    ],
    description:
      'Full-bodied and aromatic at its best but much more usually encountered enjoying international fame if not glory as anodyne Pinot Grigio.',
    'origin and parentage': [
      'Pinot Gris is a colour mutation of Pinot Noir that occurred at different times and in different places in Burgundy (notably in Chassagne-Montrachet in the C\u00f4te d\u2019Or), but also in Rheinland-Pfalz and in Baden-W\u00fcrttemberg in south-west Germany (Durand 1901a). According to Dion (1959), the mention in 1283 of a \u2018vin fourmentel\u2019 in the region centred on Beauvais, north of Paris (see above), corresponded to Pinot Gris, which was called Fourmenteau or Fromenteau in the Middle Ages, but this interpretation is highly questionable because this old synonym was also given to SAVAGNIN. The earliest reliable mention of Pinot Gris was found in Baden-W\u00fcrttemberg in 1711, when it was discovered growing wild in a garden in Speyer by Johann Seger Ruland, after whom the variety was then named Rul\u00e4nder in this area. In France, this colour mutation was mentioned one year later in the region of Orl\u00e9ans by Boullay (1712) under the old name Auvernat Gris (R\u00e9zeau 1997). The synonym Beurot was first mentioned in 1770 under the variant Bureau (R\u00e9zeau 1997) and the modern name Pinot Gris did not appear until 1783\u20134, in Flavigny in the C\u00f4te d\u2019Or in Burgundy: \u2018Pinot Gris, autrement l\u2019auvergnat gris d\u2019Orl\u00e9ans et vulgairement beurot\u2019 (\u2018Pinot Gris, otherwise grey auvergnat from Orl\u00e9ans, and commonly beurot\u2019).',
      'From 1984, after the Hungarian government lodged a complaint with what was then the European Economic Community, Alsace producers agreed to stop using just the name Tokay on their labels and to use instead the full name Tokay Pinot Gris. In exchange, the Hungarians agreed to drop the name M\u00e9doc from their labels. Since 2007, the name Tokay has been banned from Alsace wine labels and only Pinot Gris may be mentioned.',
      'The name Malvoisie, still used for Pinot Gris in some parts of France, Italy and Switzerland, appeared as early as the Middle Ages. However, this name is confusing because it has been given to many distinct varieties such as VERMENTINO, SAVAGNIN, MACABEO, BOURBOULENC, etc. (R\u00e9zeau 1997), and Malvoisie was not identified as Pinot Gris until 1800 in France (R\u00e9zeau 1997) and 1869 in Switzerland (Vouillamoz 2009d). Pinot Gris or Pinot Grigio is said to have been introduced to Italy at the beginning of the nineteenth century by a producer who cultivated it in the provinces of Alessandria and Cuneo in Piemonte (Giavedoni and Gily 2005) but its presence in the Valle d\u2019Aosta under the name Malvoisie was already attested by Gatta (1838) and its cultivation there might well predate Pinot Gris\u2019 introduction to Piemonte.',
    ],
    'where its grown': [
      'When Pinot Gris\u2019 yields are not too high and it is allowed to reach full ripeness it can produce deep-coloured, fairly rich wines with relatively low acidity \u2013 occasionally running to flab \u2013 and a heady perfume, but in the mass-market arena, the name Pinot Grigio seems enough to guarantee sales of even a tart, neutral, almost colourless and flavourless white wine. In very general terms, producers tend to choose the name Pinot Grigio for less expensive, less characterful versions destined for export markets, but there are many exceptions to this rule and Friuli in particular in north-east Italy uses the term for all of its varietal wine, no matter how concentrated and exciting.',
      'France grew 2,617 ha (6,467 acres) of Pinot Gris in 2009, mostly in Alsace (2,356 ha/5,822 acres), where it is the third most planted variety, after RIESLING (3,382 ha/8,357 acres) and GEW\u00dcRZTRAMINER (2,928 ha/7,235 acres), and has been steadily increasing over the last ten years so that it now represents 15% of the vineyard area. Alsace Pinot Gris can be as luscious as a ripe peach or apricot, with a hint of smoke, developing biscuity, buttery flavours with age. Vendange Tardive versions can stand up to savoury foods. Particularly good examples have been made by JB Adam, Kuentz-Bas, Trimbach, Weinbach and Zind-Humbrecht. It is recommended in much of wine growing France except the south and south west.',
      'In Burgundy, where it is also known as Pinot Beurot, it is still authorized as an \u2018accessory\u2019 variety (along with Pinot Blanc and CHARDONNAY) in many red-wine appellations, from basic Bourgogne Rouge (theoretically up to a maximum 10%) to Gevrey-Chambertin and Bonnes Mares, although it is very rarely found in practice.',
      'Very limited plantings remain in the Loire, where it is known as Malvoisie or Pinot Beurot and can produce perfumed, substantial wines in a wide range of different sweetness levels. The name Malvoisie occasionally appears on the label of a wine from the Coteaux d\u2019Ancenis appellation. Pierre Guindon\u2019s version, like many, is slightly sweet. Malvoisie must contribute at least 30% to a pale-pink wine labelled Touraine Noble-Jou\u00e9, along with Pinot Meunier and Pinot Noir. Domaine Rousseau Fr\u00e8res in Esvres make a good one. The Fr\u00e8res Couillaud in the Nantais region are unusual in producing a varietal Vin de Pays du Val de Loire labelled Pinot Gris. The synonym Malvoisie also crops up in Savoie.',
      'Italy\u2019s Pinot Grigio has been the somewhat unfathomable success story of the early twenty-first century. The area given over to the variety, mostly in the north east, almost doubled between 1990 and 2000 (6,668 ha/16,477 acres at the last agricultural census) and has continued to soar as export markets such as the UK and US have flourished thanks to the generally fresh but anodyne style of these inexpensive wines. More serious, characterful and weightier wines may still be found, eg in Friuli made by Vie di Romans, in the Veneto by Alois Lageder and in the Alto Adige by Franz Haas. In Lombardia, Pinot Grigio is often an important component in local fizz.',
      'Most Veneto versions are rather neutral and, at their best, inoffensive, the high yields diluting any true Pinot Grigio character \u2013 but at least they are Italian and are Pinot Grigio. Many observers are bemused by the sheer volume of wine labelled Pinot Grigio exported from Italy.',
      'Luxembourg is relatively keen on Pinot Gris with a total of 181 ha (447 acres) planted in 2008, more than Pinot Blanc and just less than AUXERROIS. Pinot Gris\u2019 weight and relatively low acidity are useful this far north.',
      'In Germany, the variety known as Grauburgunder, Grauer Burgunder or (occasionally) Rul\u00e4nder is the country\u2019s fourth most planted white wine grape, with 4,481 ha (11,073 acres) in 2008, concentrated in Baden, Rheinhessen and the Pfalz. Recommended producers include Bercher, Dr Heger, Franz Keller and especially Salwey in Baden. Rul\u00e4nder is generally used for sweeter styles although traditionalists Reinhold & Cornelia Schneider in Baden make a very good dry version. It needs a good site with deep, heavy soils to maximize the impressive level of extract of which it is capable.',
      'In Switzerland, where the majority of the vines \u2013 known as either Malvoisie or Pinot Gris \u2013 are in the Valais, total plantings were 216 ha (534 acres) in 2009. Marie-Th\u00e9r\u00e8se Chappaz\u2019s Malvoisie from Fully in the Valais is one of the country\u2019s most sought-after sweet wines. Yvon Cheseaux from Saillon in the Valais is another successful producer of sweet Malvoisie.',
      'In Austria, the variety may be called Grauer Burgunder or Pinot Gris and in 2009 there were just 222 ha (549 acres), down from 293 ha (724 acres) in 1999. The majority of those vines are in Burgenland, where it is used both for dry varietal wines and as a component in sweet blends.',
      'In Hungary it enjoys the memorable name Sz\u00fcrkebar\u00e1t and in 2008 there were 1,522 ha (3,761 acres), mostly in M\u00e1tra in the north, although it is more often associated with Badacsony north of Lake Balaton in the west. The best results are produced on warmer, volcanic soils. Wines tend to be rich and sometimes oily with high alcohol, heady perfume and some residual sugar, often aged in large oak. Some of the finest examples come from Dr Bussay in Zala, south west of Lake Balaton and near the Croatian border. Other recommended producers include Mih\u00e1ly Figura, Szak\u00e1lvin and Szent Orb\u00e1n.',
      'In Slovenia, it is known as Sivi Pinot and has a real Slovenian identity. Plantings in 2009 totalled 495 ha (1,223 acres), about half in \u0160tajerska Slovenija (Slovenian Styria) in the north east and half in Primorska in the west, mainly in Gori\u0161ka Brda, just over the border from Italy\u2019s Friuli. Good examples are produced by Mansus, Prin\u010di\u010d, Edi Sim\u010di\u010d and Marjan Sim\u010di\u010d in the west and Dveri Pax in the north east.',
      'Called Rulandsk\u00e9 \u0160ed\u00e9 in the Czech Republic, it was grown on a sizeable 741 ha (1,831 acres) in 2009, mostly in Morava in the south east of the country. In Romania, it is more usually known as Pinot Gris and was grown on 969 ha (2,394 acres) in 2008, mostly in the south west towards the Black Sea. The Republic of Moldova grew a considerable 2,042 ha (5,046 acres) of Pinot Gris in 2009, Ukraine 638 ha (1,577 acres) in 2009. Plantings in Krasnodar Krai in Russia were a much more modest 78 ha (193 acres) in 2009.',
      'The fashion for Pinot Grigio has certainly not passed California by. There were already 12,907 acres (5,223 ha) of Pinot Gris planted by 2010, a total that had been steadily increasing even though it lost the race against SAUVIGNON BLANC to be the third most planted variety after CHARDONNAY and COLOMBARD. It is widely produced throughout the state, generally in a rich, Alsace style. Bottlings labelled Pinot Grigio tend to be lighter and, usually, more commercial. Particularly toothsome examples are made from Anderson Valley fruit by Breggo, Copain and Navarro, while Babcock\u2019s from Sta. Rita Hills and Robert Sinskey\u2019s Carneros version have also garnered acclaim.',
      'But Pinot Gris has been regarded as the signature white wine of Oregon almost since David Lett of The Eyrie Vineyard first trialled cuttings from Alsace \u2013 not least because it has been only recently that suitable clones of CHARDONNAY have been planted there. The state total of 2,736 acres (1,107 ha) in 2008 was double the area planted at the end of the last century, making it by far the most widely planted light-skinned variety in the state (with more than double the area given over to Chardonnay). Virtually all of the many good Pinot Noir producers also make a competent, lightly scented Pinot Gris that can taste rather like an aromatic Chardonnay.',
      'Pinot Gris is also popular in Washington State, where its 2011 total of 1,576 acres (638 ha) make it the third most planted white wine grape, expanding steadily, but a long way behind CHARDONNAY and RIESLING.',
      'It is produced in scores and grown in dozens of other American states, particularly New York, Michigan and Virginia (70 acres/28 ha in 2010).',
      'Pinot Gris is an important variety in Canada\u2019s British Columbia, where it was the most widely planted white wine variety in 2008 with 928 acres (376 ha), just ahead of CHARDONNAY and planted on more than 10% of the total vineyard area even though its produce seems less distinctive than that of Pinot Blanc.',
      'Pinot Gris was introduced to one of Mendoza\u2019s higher vineyards in Argentina when the Lurton brothers of Bordeaux planted Alsace clones 52 and 53 in 2002 and it is now firmly established there. All that sunshine results in a fairly rich wine, not unlike a California CHARDONNAY. Grape skins are often burnished by the sun but the wines can deliver great value. The country grew a total of 349 ha (862 acres) in 2008. A very small amount is grown in the far south of Chile.',
      'Australia grew a total of 2,836 ha (7,004 acres) of Pinot Gris, sometimes labelled as Pinot Grigio, in 2008. The fact that this includes 758 ha (1,873 acres) that are not yet bearing fruit, and that the total in 2004 was just 300 ha (741 acres), indicates the popularity of this variety with growers. T\u2019Gallant in Mornington Peninsula pioneered varietal versions, under both names, and they have been much followed. Care is needed to prevent the wines from flab in most warmer areas although Holly\u2019s Garden and Seppelt have risen to the challenge in Victoria, and Tim Adams makes a useful version in the Clare Valley.',
      'In New Zealand, Pinot Gris has been the most successful of all the aromatic or semi-aromatic light-skinned varieties after runaway leader SAUVIGNON BLANC. The area planted to this variety has doubled in the last four years, to reach 1,501 ha (3,707 acres) in 2009, 5% of the country\u2019s total vineyard area and projected to increase further over the next few years. Although there has been a good deal of planting in Marlborough on the South Island, the warmer and more northerly Hawke\u2019s Bay is also proving to be a successful region for this variety. The producer\u2019s choice of Pinot Gris v Pinot Grigio is a strong indication of the style of wine \u2013 rich and weighty v crisp and bland, though many have been held back by a little too much residual sugar. The best achieve a balanced and aromatic richness without the need for excess sweetness. Recommended producers include Dry River in Martinborough; Brennan, Mount Difficulty and Richardson in Central Otago; Neudorf in Nelson; and Saint Clair and Seresin in Marlborough.',
      'A small amount is grown in South Africa; a tiny amount in Japan.',
    ],
  },
  {
    name: 'PINOT BLANC',
    synonyms: [
      'Auvernat Blanc',
      'Beli Pinot',
      'Burgunder Veisser',
      'Chardonnet Pinot Blanc',
      'Pinot Blanc Chardonnet',
      'Clevner',
      'Kl\u00e4vner',
      'Feh\u00e9r Burgundi',
      'Pino Bel\u00eei',
      'Pinot Bianco',
      'Pinot Bijeli',
      'Pinot Blanc Vrai',
      'Pinot Branco',
      'Rulandsk\u00e9 Biele',
      'Rulandsk\u00e9 B\u00edl\u00e9',
      'Weissburgunder',
      'Weisser Burgunder',
      'Burgunder Weiss',
    ],
    description:
      'Possibly underrated producer of friendly, medium-bodied, confident whites. Easy to ripen.',
    'origin and parentage': [
      'Until the end of the nineteenth century, Pinot Blanc was frequently confused with CHARDONNAY, which was mistakenly called Chardonnet Pinot Blanc or Pinot Blanc Chardonnet. French ampelographer Victor Pulliat was the first to distinguish the two varieties, in 1868 (R\u00e9zeau 1997): \u2018le Pineau blanc vrai est un accident fix\u00e9 d\u2019un Pineau noir devenu blanc: il n\u2019en diff\u00e8re que par la couleur de sa grappe qui est d\u2019un blanc jaun\u00e2tre\u2019 (\u2018the true Pineau blanc is an irreversible mutation of a Pineau noir that became white; it differs only by the colour of its cluster, which is yellowy white\u2019). The distinction between Pinot Blanc and Chardonnay was officially accepted at the Lyon viticultural exhibition in 1872 (Chandon de Briailles in Durand 1901a). Therefore, every mention of Pinot Blanc earlier than 1868 is ambiguous and unreliable because of the confusion that existed with Chardonnay. Needless to say, the confusion went on several decades later in both France and Switzerland.',
      'According to Eug\u00e8ne Durand (1901a), Pinot Blanc is a colour mutation of Pinot Gris that was observed by Pulliat in Chassagne-Montrachet in 1895 and by Durand himself in Nuits-Saint-Georges in 1896, which means the mutation had already occurred at least twice. The existence of additional and possibly earlier mutations is quite plausible because black, grey and white berries can sometimes be observed on the same Pinot Noir vine, and some vines even have stripey berries.',
    ],
    'where its grown': [
      'Many wine lovers\u2019 introduction to this variety is via Alsace, where it is regarded as a workhorse variety and is much less respected than Pinot Gris. Admirers of freshness in wine can find some Pinot Gris overbearing whereas, in the right place and hands, Pinot Blanc can offer a lip-smacking halfway house between refreshment and opulence. The wines are moderately structured and have moderate acidity and may be used for sparkling wine.',
      'The area of France planted with Pinot Blanc increased steadily until it reached 1,565 ha (3,867 acres) at the end of the 1980s but is now declining slowly, reaching 1,292 ha (3,193 acres) in 2009, surprisingly far down the league table of white varieties, perhaps because so little is planted outside its home territory of Alsace. Even there, it is less significant in terms of area planted than RIESLING, SILVANER and AUXERROIS, with which it is traditionally blended, although only Pinot Blanc is mentioned on the label and the official Alsace statistics report the two varieties together to give a total of 3,331 ha (8,231 acres) in 2009, more or less steady over the last ten years. It may also be included in Cr\u00e9mant d\u2019Alsace. Very little is now grown in Burgundy, although it is still technically eligible for inclusion in Bourgogne Blanc.',
      'Pinot Blanc makes some of the most successful wine in the French Moselle region, just as it does over the border in Luxembourg, where there were 144 ha (356 acres) in 2008 with very few new plantings this century. AUXERROIS (184 ha/455 acres) is more highly regarded this far north because of its usefully lower acidity.',
      'A total of 5,126 ha (12,667 acres) of Pinot Bianco were recorded in the last, 2000, agricultural census in Italy, the vast majority of vines in the north east, where it is used to make a popular dry white, along the lines of a lighter-style unoaked CHARDONNAY. However, good examples can have delicate floral and green-apple aromas and are equally delicate on the palate. Recommended producers include Franco Toros and Ermacora in Friuli and Cantina Terlano and Franz Haas in the Alto Adige.',
      'The variety is probably most respected in Germany, where there were 3,731 ha (9,220 acres) in 2008. Known as Weissburgunder or Weisser Burgunder, it is the country\u2019s fifth most planted white wine variety, albeit some way behind the Grauburgunder (Pinot Gris) of which it is so often a stablemate. By far the biggest area is in Baden (1,165 ha/2,879 acres), followed by the Pfalz (862 ha/2,130 acres) and Rheinhessen (831 ha/2,053 acres). Producers such as Bercher, Dr Heger, Franz Keller and Martin Wassmer in Baden, Philipp Kuhn, Rebholz and Dr Wehrheim in the Pfalz and Dreissigacker in Rheinhessen make some of the most winning examples of the varietal anywhere \u2013 although there has been a tendency to overripen and overoak it \u2013 perhaps in an attempt to provide something utterly unlike RIESLING.',
      'As Weissburgunder, the variety makes most of its richest wines in Austria in Burgenland, where it succumbs easily to noble rot and is traditionally blended with the rather crisper Welschriesling (GRA\u0160EVINA) to produce luscious, if fairly early-maturing Trockenbeerenauslesen. Prieler and Willi Opitz also make dry versions with admirable precision. As a dry white varietal, Weissburgunder is associated with an almond-like scent, medium to high alcohol, and an ability to age. It is grown throughout the country with a total of 1,995 ha (4,930 acres) planted in 2010, especially in Nieder\u00f6sterreich (Lower Austria) and the Steiermark (Styria).',
      'Switzerland grows just 105 ha (259 acres) of Pinot Blanc, mostly in the Valais, Vaud and Graub\u00fcnden but Weingut Bad Osterfingen in Schaffhausen make a notable exception.',
      'In Hungary there were 200 ha (494 acres) as Pinot Blanc (also Feh\u00e9r Burgundi) in 2008. Although it is not that widely planted, it was already officially registered in the 1930s. The most significant area is in Tolna in the central south. In Slovenia, it is known as Beli Pinot and 2009 total plantings were 540 ha (1,334 acres), the majority (315 ha/778 acres) being in Podravje in the north east. Vipava 1894 make an excellent rendition. Croatia grew 357 ha (882 acres) of Pinot Bijeli in 2008. In the Czech Republic, there were 812 ha (2,006 acres) of Rulandsk\u00e9 B\u00edl\u00e9 in 2009, mostly in Morava in the south east, while in Slovakia, where it is also known as Rulandsk\u00e9 B\u00edl\u00e9, there were 1,050 ha (2,595 acres) in 2005 (5.4% of vineyard area). The Republic of Moldova grew 350 ha (865 acres) of Pinot Blanc in 2009. The 2009 total in Krasnodar Krai in Russia was 605 ha (1,495) and Ukraine had 338 ha (835 acres) in 2009.',
      'The variety is not nearly as popular as Pinot Gris in California but is widely planted with various bottlings from the likes of the Bien Nacido vineyard in the Santa Maria Valley and some Anderson Valley examples proving there may be a future for Pinot Blanc as a still wine. Historically much of it was used as base wine for California fizz, a practice pioneered by old hand Schramsberg. In 2010 there were just 536 acres (217 ha) with the area increasing slowly, the biggest areas in Santa Barbara, Sonoma and Monterey, in that order. According to Pinder and Meredith (2003), many older Pinot Blanc vines in California are actually MELON, including those pioneered by Chalone.',
      'There are also some very competent Oregon Pinot Blancs but it is the Okanagan Valley in Canada\u2019s British Columbia that seems to be the variety\u2019s perfect spot. There were only 369 acres (149 ha) planted in 2009, making it BC\u2019s fifth most planted white variety, but when varieties were assessed in the late 1970s and early 1980s, Pinot Blanc was the most popular because it was as winter-hardy as the hybrids that had been planted before and it produced consistently good wines from year to year in both the north and south of the Okanagan Valley (Philip 2007). Presumably it is only Pinot Blanc\u2019s lack of international glamour that is acting as a brake, because vivacious varietals from the likes of Lake Breeze, Nk\u2019Mip and Wild Goose suggest a particularly bright future here.',
      'There are tiny amounts planted in South America and Japan.',
    ],
  },
  {
    name: 'PINOT TEINTURIER',
    synonyms: ['Farbclevner', 'Pinot Fin Teinturier'],
    description: 'Red-fleshed Pinot mutation.',
    'origin and parentage': [
      'Pinot Teinturier is a mutation of Pinot Noir with red-juiced berries, somewhere between GAMAY TEINTURIER DE BOUZE and TEINTURIER in terms of colour intensity (Galet 1990). It was first selected at the end of the nineteenth century by Jean Guicherd in a vineyard in Nuits-Saint-Georges in the C\u00f4te d\u2019Or in Burgundy, and the same mutation was then also observed in Morey and in Vosne-Roman\u00e9e (Durand 1901a).',
    ],
  },
  {
    name: 'PINOT NOIR PR\u00c9COCE',
    synonyms: [
      'Augustclevner',
      'Augusttraube',
      'Blauer Fr\u00fchburgunder',
      'Fr\u00fchburgunder',
      'Ischia',
      'Vigne d\u2019Ischia',
      'Jacobstraube',
      'Jakobstraube',
      'Juliustraube',
      'Madeleine Noire',
      'M\u00f6hrchen',
      'Morillon Noir H\u00e2tif',
      'Pinot H\u00e2tif de Rilly',
      'Pinot Madeleine',
      'Pinot Pr\u00e9coce',
      'Pinot Pr\u00e9coce Noir',
      'Raisin de la Madeleine',
    ],
    description:
      'Early-ripening mutation of Pinot Noir that is currently popular in Germany as Fr\u00fchburgunder.',
    'origin and parentage': [
      'According to Eug\u00e8ne Durand (1901a), nothing is known about the origins of this mutation that ripens approximately two weeks before Pinot Noir (pr\u00e9coce means \u2018early\u2019). However, it has numerous old synonyms that were mentioned in Merlet (1690): \u2018le Morillon h\u00e2tif, ou de la Madeleine\u2019 and in Liger (1762): \u2018le raisin pr\u00e9coce, raisin de la madeleine, ou morillon h\u00e2tif, parce qu\u2019il est h\u00e2tif & m\u00fbr souvent d\u00e8s la Madeleine, est un raisin noir, plus curieux que bon, \u00e0 cause qu\u2019il a la peau dure\u2019 (\u2018the early grape, the madeleine grape, or early morillon, because it is early and often ripe at the Madeleine, is a black grape, more curious than good, because it has hard skin\u2019). The earliest mention of the modern name Pinot Noir Pr\u00e9coce appeared in Di Rovasenda (1877), and the modern German name Fr\u00fchburgunder was first mentioned in Braun (1824): \u2018Unter den wei\u00dfen auch schimmerte dicht von Beeren der dunkle Fr\u00fchburgunder\u2019 (\u2018among the white [grapes], the dark Fr\u00fchburgunder shimmered with berries\u2019).',
    ],
    'where its grown': [
      'Wines are well coloured, soft, ample and full-flavoured with flavours of cherries and blackberries and sometimes a smoky note. It is well suited to restrained barrel ageing but generally not as long-lived as Pinot Noir of comparable quality.',
      'Plantings in England are so far limited but there are some promising examples such as that from Welcombe Hills near Stratford-upon-Avon.',
      'Fr\u00fchburgunder was very popular in the early nineteenth century in Germany, partly thanks to its ability to ripen even in poor vintages and also for its very early ripening \u2013 sometimes as precocious as late July or early August. This explains several of its synonyms: Juliustraube, meaning \u2018July grape\u2019, Augustclevner, Jakobstraube (the grape that ripened by St Jacob\u2019s day, ie 25 July) and Pinot Madeleine (ripening around St Mary Magdelene\u2019s day, ie 22 July).',
      'Although it had declined to around 15 ha (37 acres) by the 1960s, it is now seeing something of a revival, thanks largely to a clonal selection programme carried out at the Geisenheim research centre in the 1970s, although it was Julius Wasem & S\u00f6hne in Rheinhessen that initially came to the variety\u2019s rescue when it was deleted from the official German register of varieties in the 1960s. Slow Food\u2019s championing of Fr\u00fchburgunder can only have encouraged its survival.',
      'From just 44 ha (109 acres) in 1994, the total area in Germany increased slowly but steadily to 251 ha (620 acres) in 2007. Although there are more hectares of Fr\u00fchburgunder in Rheinhessen (83 ha/205 acres) and the Pfalz (62 ha/153 acres), the hotbed is clearly in the Ahr, where there are 35 ha (86 acres), getting on for 10% of the valley\u2019s vineyard area, and more than half of the region\u2019s producers are making wine from this variety. It is also the only region where members of the VDP, Germany\u2019s top growers\u2019 association, are allowed to produce Fr\u00fchburgunder labelled Grosses Gew\u00e4chs, the highest classification for dry wines. Franken had only 14 ha (35 acres) in 2008 but the variety seems to do particularly well on the red sandstone soils around Miltenberg and, historically, B\u00fcrgstadt. Among the best producers are Burggarten, Deutzerhof, Kreutzberg, Pieter Kriechel and Meyer-N\u00e4kel in the Ahr; Rudolf F\u00fcrst in Franken; Philipp Kuhn and Ludi Neiss in the Pfalz; and Villa B\u00e4der, Wendelsheim and Julius Wasem & S\u00f6hne in Rheinhessen.',
    ],
  },
  {
    name: 'PINOTAGE',
    synonyms: ['Perold\u2019s Hermitage \u00d7 Pinot'],
    description: 'South Africa\u2019s very own cross, both loved and despised.',
    'origin and parentage': [
      'Pinotage was bred in 1925 by Abraham Izak Perold (1880\u20131941), the first professor of viticulture at the University of Stellenbosch, South Africa. In the garden of his official residence at the Welgevallen experimental farm belonging to the University of Stellenbosch, Perold pollinated a PINOT NOIR plant with CINSAUT, known at the time in South Africa as Hermitage. He then planted the four resulting seeds in the same garden and forgot them when he left Stellenbosch University to work for the KWV winery in Paarl in 1927.',
      'The seedlings were rescued by Charlie Niehaus, who transported them to CJ Theron\u2019s nursery at Elsenburg Agricultural College, where they were simply labelled Perold\u2019s Hermitage \u00d7 Pinot. He then grafted material from the Pinotage seedlings onto rootstocks at Welgevallen and later showed them to Perold, who urged him to propagate them. They are said to have come up with the name Pinotage \u2013 a contraction of those of its parents \u2013 while in the Welgevallen vineyard. The best of the four plants was then selected to become the mother material of all Pinotage vines. The first commercial planting of Pinotage was made in 1943 at Myrtle Grove farm near Sir Lowry\u2019s Pass (Pinotage Association 2010) although the name was not seen on a bottle until 1961, on Lanzerac\u2019s 1959 vintage.',
      'Like several other South African crosses, Pinotage was obtained by means of open-air hybridization in vineyards and not under controlled greenhouse conditions. Nevertheless, the proposed parentage was confirmed by DNA analysis at ENTAV (\u00c9tablissement National Technique pour l\u2019Am\u00e9lioration de la Viticulture) in France in 2007.',
    ],
    'where its grown': [
      'Depending on your point of view, Pinotage is either South Africa\u2019s signature red variety or its worst vinous ambassador. Its reputation has not been helped by the effects of virus diseases in the vineyard and fermentations that were sometimes heavy handed or too hot. However, with healthier planting material, continued refinements in winemaking and the efforts of the Pinotage Association, founded in the mid 1990s, the best wines have improved enormously, ranging in style from light and red-fruited (pointing to the vine\u2019s parentage) to the rich, dark, firm and spicy but fresh produce of old vines in the hands of expert proponents such as Beyers Truter at Beyerskloof in Stellenbosch, who also uses it to make port-style wines, ros\u00e9, fizz and red and even \u2018white\u2019 blends (with CHENIN BLANC). Other recommended producers of varietal wines include L\u2019Avenir, Chamonix, Kaapzicht and Kanonkop. Grangehurst and Kaapzicht, among others, have produced successful Pinotage-dominated blends.',
      'There were 6,088 ha (15,044 acres) in 2009, that\u2019s 6% of the South African vineyard area, and grape prices have fluctuated considerably according to fashion. The variety is planted in many different regions but the biggest areas are in Malmesbury, Stellenbosch and Paarl.',
      'According to the Pinotage Association (2010), unpleasant spray-paint aromas (due to isoamyl acetate) may develop if the vines are subject to water stress or high temperatures at harvest time, aromas that are increased by high fermentation temperatures. Others have reported an aroma of burnt rubber in many Pinotage wines. This may be linked to the continued effects of viral diseases in the vineyard but the causes are unclear and research is underway at the University of Stellenbosch to try to identify the compounds that are responsible.',
      'Pinotage is also grown in California (53 acres/21 ha in 2009 mostly in the San Joaquin Valley but also in Napa Valley and Sonoma Coast), Brazil (112 ha/277 acres in 2007 in Rio Grande do Sul), New Zealand (74 ha/183 acres in 2008 in Gisborne, Hawke\u2019s Bay and Marlborough) and to a more limted extent in Australia, Oregon, Washington State, British Columbia in Canada, and in Zimbabwe and Israel.',
    ],
  },
  {
    name: 'PINOTIN',
    synonyms: ['VB 91-26-19'],
    description: 'Recent, obscure Swiss hybrid.',
    'origin and parentage': [
      'The vast majority of sources define Pinotin as a cross between PINOT NOIR and an undisclosed disease-resistant variety (known in German as a Resistenzpartner) bred by Valentin Blattner in Switzerland in 1991, although the name was not registered until 2002. However, Blattner recently revealed that Pinotin is in fact a complex hybrid: CABERNET SAUVIGNON \u00d7 (SILVANER \u00d7 (RIESLING \u00d7 Vitis vinifera) \u00d7 (JS 12417 \u00d7 CHANCELLOR)), in which JS 12417 is an unknown hybrid obtained by Joann\u00e8s Seyve (see PRIOR for the pedigree of Chancellor). Nonetheless, the PINOT-like flavour of the wine might be due to the proximity of a row of Pinot Noir vines next to the crossed vines, and without DNA analysis it is not possible to rule out Pinot Noir as a parent.',
    ],
    'where its grown': [
      'There are very minor plantings in Switzerland and in Germany, where Th\u00fcringer Weingut Bad Sulza in Saale-Unstrut (central Germany), for example, produce a varietal wine that\u2019s ruby-coloured and soft with aromas of black cherries.',
    ],
  },
  {
    name: 'PIONNIER',
    synonyms: ['ES 4-7-25'],
    description:
      'Minor but complex, cold-hardy American hybrid used in blends in Qu\u00e9bec.',
    'origin and parentage': [
      'An Elmer Swenson 2-12-27 \u00d7 ST CROIX hybrid obtained by Elmer Swenson in Osceola, Wisconsin, US, in which Elmer Swenson 2-12-27 is an Elmer Swenson 5-14 \u00d7 SWENSON RED hybrid (see BRIANNA for the pedigree of Elmer Swenson 5-14). Pionnier is therefore an extremely complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\naestivalis, Vitis\nlincecumii, Vitis\nrupestris, Vitis\ncinerea and Vitis\nberlandieri hybrid.',
    ],
    'where its grown': [
      'Pionnier is planted in Qu\u00e9bec, Canada, where producers such as Bouche-Art, Clos du Roc Noir and Domaine Mont V\u00e9zeau blend it with other cold-hardy varieties such as FRONTENAC and SABREVOIS.',
    ],
  },
  {
    name: 'PIQUEPOUL NOIR',
    synonyms: ['Picapoll Negro', 'Picapoll Tinta'],
    description:
      'DNA profiling has recently shown that PICAPOLL BLANCO, a white variety from Pla de Bages in Catalunya, north-east Spain, already mentioned in the sixteenth century and long considered identical to the French Piquepoul Blanc, is in fact a distinct variety that is much more closely related to CLAIRETTE, possibly a clone (Puig et al. 2006). However, Picapoll Negro has been shown to be identical to Piqupoul Noir (Galet 2000). Piquepoul belongs to the Piquepoul ampelographic group (see p XXVII; Bisson 2009).',
    'where its grown': [
      'Piquepoul Noir produces pale, alcoholic but aromatic and nervy wines well suited to ros\u00e9 production and is much less widely planted than the white version \u2013 there were just 73 ha (180 acres) in southern France in 2008. Although it is recommended in most French wine-growing d\u00e9partements, and is a permitted variety in Ch\u00e2teauneuf-du-Pape and C\u00f4tes du Rh\u00f4ne, it is generally a very minor component in blends, unlike its more successful white mutation. A rare example of a Piquepoul Noir-dominant red wine is Minervois producer Clos Centeille\u2019s C de Centeilles, which is 90% Piquepoul Noir and 10% RIVAIRENC; Domaine La Grangette just south of P\u00e9zenas bottle a varietal ros\u00e9.',
      'In Spain, there were just 3 ha (7 acres) of Picapoll Negro officially recorded in 2008 in Catalunya, where it is generally included in blends, as in those of \u00c1lvaro Palacios and Celler Francisco Castillo\u2019s from Priorat. It is authorized in DOs such as Montsant and Priorat and has experimental status in Pla de Bages.',
    ],
  },
  {
    name: 'PIQUEPOUL BLANC',
    synonyms: [
      'Avillo',
      'Languedocien',
      'Picpoul de Pinet',
      'Piquepoul de Pinet',
    ],
    description:
      'Piquepoul Noir produces pale, alcoholic but aromatic and nervy wines well suited to ros\u00e9 production and is much less widely planted than the white version \u2013 there were just 73 ha (180 acres) in southern France in 2008. Although it is recommended in most French wine-growing d\u00e9partements, and is a permitted variety in Ch\u00e2teauneuf-du-Pape and C\u00f4tes du Rh\u00f4ne, it is generally a very minor component in blends, unlike its more successful white mutation. A rare example of a Piquepoul Noir-dominant red wine is Minervois producer Clos Centeille\u2019s C de Centeilles, which is 90% Piquepoul Noir and 10% RIVAIRENC; Domaine La Grangette just south of P\u00e9zenas bottle a varietal ros\u00e9.',
    'where its grown': [
      'PIQUEPOUL\u2019s high acidity makes it an ideal blending partner to freshen up many a Languedoc or Proven\u00e7al white since it is recommended across much of southern France, and may be included in Ch\u00e2teauneuf-du-Pape\u2019s complex cocktail, but its most famous expression is as a varietal wine in the eponymous appellation Picpoul de Pinet, grown between P\u00e9zenas and S\u00e8te on the coast. Wines are usually lemon-scented, nervy and dry, occasionally on the floral side. There were 1,455 ha (3,595 acres) in France in 2009, a considerable increase on 1998\u2019s 650 ha (1,606 acres), the overwhelming majority of the vines being in the Languedoc. Production is dominated by the Cave de l\u2019Ormarine in Pinet and the three other co-ops in the area, but smaller producers such as Domaine de Creyssels, Domaine F\u00e9lines Jourdan and Domaine Gaujal de Saint Bon make slightly more intensely flavoured, in some instances lees-aged, examples.',
      'Rh\u00f4ne specialists Tablas Creek in western Paso Robles were pioneers of the variety in California, though their Picpoul Blanc has more tropical fruit flavours than its counterpart in southern France. A range of varietal examples is now made.',
    ],
  },
  {
    name: 'PLANT DROIT',
    synonyms: [
      'Cinsaut Droit',
      'Espanenc',
      'Garnacha Francesa',
      'Plant Dress\u00e9',
    ],
    description:
      'Minor and diminishing southern Rh\u00f4ne variety producing rather pale reds.',
    'origin and parentage': [
      'Plant Droit comes from the Vaucluse in the southern Vall\u00e9e du Rh\u00f4ne, where it was first described by Henri Mar\u00e8s in 1890 (R\u00e9zeau 1997). Its name was deliberately changed in the Vaucluse to Cinsaut Droit in an attempt to include it discreetly in the C\u00f4tes du Rh\u00f4ne appellation (Galet 1990). A comparison of the DNA profile of Garnacha Francesa in Mart\u00edn et al. (2003) with Plant Droit shows that they are identical (Vouillamoz). According to Bisson (2009), Plant Droit is ampelographically close to CLAIRETTE.',
    ],
    'where its grown': [
      'Wines are generally pale and low in alcohol. There were 21 ha (52 acres) in France in 2008, down from 337 ha (833 acres) in 1968, the downward trend being most marked in the 1990s. It is pretty much confined to the Ch\u00e2teauneuf-du-Pape region in the southern Rh\u00f4ne, although it is not one of the many permitted varieties in that appellation.',
    ],
  },
  {
    name: 'PLANTA NOVA',
    synonyms: ['Coma', 'Malvasia', 'Tardana', 'Tortozon', 'Tortoz\u00f3n'],
    description:
      'Actually very old but generally undistinguished variety grown mainly in Valencia today.',
    'origin and parentage': [
      'Although the name means \u2018new plant\u2019, it is an old Andalucian variety from southern Spain that was first mentioned in 1513 by Gabriel Alonso de Herrera (1790) under the synonym Tortoz\u00f3n. DNA parentage analysis has shown that Planta Nova (under the synonym Tortoz\u00f3n) and GIBI are the parents of ALARIJE (under the synonym Subirat Parent; Lacombe et al. 2007), an old variety from Extremadura that was also cited in 1513 by Gabriel Alonso de Herrera. The synonym Tardana reflects its late-ripening habit (tardar means \u2018to take a long time\u2019 in Spanish).',
    ],
    'where its grown': [
      'In France, Les Vignerons Ari\u00e9geois in Mont\u00e9gut-Plantaurel (just west of Pamiers) have planted 30\u201340 vines of Tortozon (no accent in French) and other endangered varieties (Carbonneau 2005). They aim to multiply these and to produce a commercial wine if their experiments yield encouraging results, although it seems unlikely in this case.',
      'Authorized in neighbouring DOs Utiel-Requena and Valencia in Spain, Planta Nova was grown on a total of 1,385 ha (3,422 acres) in 2008, the vast majority in the autonomous community of Valencia plus very limited plantings in Castilla-La Mancha and Extremadura. Torroja\u2019s Sybarus Tardana \u00danico, one of the very few better-quality varietal wines, is fresh and well structured with flavours of peach and lemon.',
    ],
  },
  {
    name: 'PLANTET',
    synonyms: ['Seibel 5455'],
    description:
      'Hybrid once popular in the Loire, now producing slightly odd, raspberry-flavoured wines.',
    'origin and parentage': [
      'A complex hybrid obtained at the beginning of the twentieth century by Albert Seibel in Aubenas in the Ard\u00e8che, south-eastern France. Its genetic origin is still not clear because Seibel initially described it as a Seibel 867 \u00d7 Seibel 2524 cross but then corrected his own notes to \u2018[Seibel] 4461 \u00d7 Berlandieri\u2019 (Galet 1988). It was used to breed COLOBEL and LANDAL.',
      'The origin of the name is unknown but it may be related to plantier, designating a young vine (R\u00e9zeau 1997).',
    ],
    'where its grown': [
      'Plantet was one of the most popular hybrids in the Loire, France, but has gone from total plantings in France of 27,900 ha (68,942 acres) in 1958 to just 1,105 ha (2,731 acres) in 2009, even though it is authorized in almost every wine-growing region. At one time it was significant in western Switzerland but it has all but disappeared today. The grapes tend to have a marked raspberry flavour and the wines may taste odd to those not used to varieties with a part-American pedigree. Domaine Mondon-Demeur\u00e9, specialists in obscure and forgotten varieties, produce both a ros\u00e9 and a medium-dry version (classified as a vin de France) north west of Saint-\u00c9tienne in the Rh\u00f4ne.',
    ],
  },
  {
    name: 'PLANTSCHER',
    synonyms: ['Bordeaux Blanc', 'Bourgogne Blanc', 'Gros Bourgogne'],
    description:
      'Very rare Swiss or possibly Hungarian variety just surviving in the Haut-Valais.',
    'origin and parentage': [
      'The name Plantscher supposedly derives from Blanchier, from blanc (\u2018white\u2019), a name that has been used to designate several distinct varieties in Vaud and the Valais in southern Switzerland. DNA parentage analysis has unexpectedly revealed that Plantscher is a natural progeny of FURMINT (Vouillamoz, Maigre et al. 2004) and a sibling of H\u00c1RSLEVEL\u0170 (Vouillamoz and Moriondo 2011), making it a grandchild of GOUAIS BLANC (see PINOT pedigree diagram) and suggesting that Hungary might be the origin of Plantscher.',
    ],
    'where its grown': [
      'Despite the synonyms linking this variety to two French regions, Plantscher is found only in Switzerland, where it used to be scattered in the vineyards of Vaud and the Valais. In addition to the few remaining isolated vines, only one Plantscher vineyard existed in 2011: 0.75 ha (2 acres) cultivated by Josef-Marie Chanton in the Haut-Valais. Chanton\u2019s softly textured wine has aromas of wild flowers such as chamomile, a hint of honeysuckle and moderate acidity, although the variety has also been described as rather neutral (Dupraz and Spring 2010).',
    ],
  },
  {
    name: 'PLASSA',
    synonyms: ['Scarlattino'],
    description: 'Thick-skinned red making tannic wines south west of Torino.',
    'origin and parentage': [
      'Plassa probably originates from the Pinerolo area in the province of Torino, Italy, more precisely in the region around Bibiana, Bricherasio, Fenile and San Secondo. Plassa is the local dialect for the Italian pellaccia, meaning \u2018hard skin\u2019, in reference to the thickness of the berries\u2019 skin. Its synonym Scarlattino refers to the red colour of the stalks.',
    ],
    'where its grown': [
      'Thanks to its thick berry skin, Plassa used to be stored after the harvest for consumption as table grapes during the winter, but today it is used exclusively for wine, in blends with other local varieties such as BARBERA, NERETTA CUNEESE and CHATUS. It is cultivated mainly in the area of Pinerolo, in particular in the communes of Pinerolo, Bibiana, Bricherasio, Campiglione, Cumiana and Frossasco. There are also scattered plantings of Plassa in the Val di Susa. There were just 44 ha (109 acres) in Italy in 2000. The wines are tannic, thanks to those thick skins, and generally benefit from the softening effects of bottle ageing.',
    ],
  },
  {
    name: 'PLATANI',
    synonyms: ['Platania', 'Platanos'],
    description:
      'Minor blending component on the Greek island of Santor\u00edni.',
    'origin and parentage': [
      'Platani, from the Greek Kykl\u00e1des (Cyclades) islands and from Kr\u00edti (Crete), is genetically closely related to KATSANO, also cultivated in the Kykl\u00e1des (Biniari and Stavrakakis 2007). Recent DNA profiling has suggested a possible parent\u2013offspring relationship with ASSYRTIKO (Myles et al. 2011).',
    ],
    'where its grown': [
      'Platani is hidden almost entirely in blends on the island of Santor\u00edni, Greece, and found in old interplanted vineyards, probably to about the same extent as ATHIRI. Both are among the earliest picked varieties on the island and are therefore frequent companions in the fermenting vat, although Platani is less often mentioned and not as highly regarded. Wine producer Paris Sigalas once tried vinifying it separately and produced a wine he describes as \u2018fresh and light with aromas of lemon and orange\u2019.',
    ],
  },
  {
    name: 'PLAVAC MALI',
    synonyms: [
      'Crljenak',
      'Kasteljanak',
      'Pagadebit Crni',
      'Pagadebit Mali',
      'Plavac Mali Crni',
      'Plavac Veliki',
      'Plavec Mal',
      'Zelenak',
    ],
    description:
      'Ancient and leading Croatian variety making powerful reds, genetically close but not identical to Tribidrag (Zinfandel).',
    'origin and parentage': [
      'Plavac Mali from the Dalmatian coast in south-western Croatia is Croatia\u2019s economically most important dark-skinned grape variety, first mentioned by Tr\u00fcmmer (1841) as a typical Dalmatian grape. However, the variety must be much older because recent ampelographic and genetic investigations have shown relatively significant clonal diversity within Plavac Mali (Zduni\u0107 et al. 2009). The name is descriptive of the berries: in Croatian plavo means \u2018blue\u2019 and mali means \u2018small\u2019.',
      'Plavac Mali has long been confused with TRIBIDRAG (aka Zinfandel) but recent DNA profiling has shown that they are distinct varieties (Peji\u0107 et al. 2000) and DNA parentage analysis has established that Plavac Mali is in fact a cross between Tribidrag and DOBRI\u010cI\u0106 (Maletic et al. 2004), two very old Dalmatian varieties. Plavac Mali is therefore a half-sibling of PLAVINA, which is a Tribidrag \u00d7 VERDECA cross, and either a grandparent or a half-sibling of GRK, Crljenak Crni and VRANAC, which all have a parent\u2013offspring relationship with Tribidrag (Maleti\u0107 et al. 2004; see Tribidrag for the pedigree diagram). Plavac Mali has also been confused with BABI\u0106 but DNA parentage analysis has shown that BABI\u0106 is a grandparent or a half-sibling of Plavac Mali since it has a parent\u2013offspring relationship with Dobri\u010di\u0107 (Zduni\u0107, Peji\u0107 et al. 2008). In addition, BABICA, planted only in the Ka\u0161tela Valley, has been found to be a progeny of Plavac Mali (Zduni\u0107, Peji\u0107 et al. 2008).',
    ],
    'where its grown': [
      'Plavac Mali is the most widely planted red wine variety in Croatia even though it constitutes only 9% of the total vineyard area, roughly 2,925 ha (7,228 acres) in 2009. It is authorized in the Dalmatinska Zagora (Croatian Hinterland) and Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine regions and was the first variety to have its own appellation: Dinga\u010d, created in 1961. Wines in the Postup and Dinga\u010d appellations on the Pelje\u0161ac peninsula just north of Dubrovnik must be made exclusively from Plavac Mali and may be dry or off dry \u2013 the residual sugar helping to disguise aggressive tannins in some wines. The vines that make the best, most intense, wines are grown on steep, sandy terraces sloping down to the Adriatic, usually as bushvines to shield the fruit from the hot sun and protect the vine from the strong winds. Vines grown on more fertile sites that produce higher yields are likely to produce lower-quality fruit.',
      'The combination of location and variety produces deeply coloured, powerful, alcoholic wines that are notably \u2013 sometimes aggressively \u2013 tannic with flavours of dark cherry, plum and blackberry, well suited to bottle ageing despite the generally low acidity. Regulations for Dinga\u010d stipulate a minimum of 13% alcohol but some go as high as 17%, unfortified. Vines higher up the slopes are fresher and more elegant. Other important appellations for Plavac Mali are Ivan Dolac and Sveta Nedilja on the island of Hvar. The islands of Bra\u010d, Lastovo and Vis are also capable of producing high-quality Plavac Mali. Overripe, dried grapes are sometimes used to make the dessert wine Pro\u0161ek.',
      'Recommended producers include Hrvoje Bakovi\u0107, Bura-Mokalo, Ivo Dubokovi\u0107, Kirid\u017eija, Korta Katarina, Mili\u010di\u0107, Saints Hills, Skaramu\u010da and Andro Tomi\u0107.',
      'The variety is also found \u2013 spelt Plavec Mal \u2013 in the Veles and Tikve\u0161 areas of the Republic of Macedonia.',
    ],
  },
  {
    name: 'PLAVEC \u017dUTI',
    synonyms: [
      'Debeli Kle\u0161ec',
      'Plavac \u017duti',
      'Plavec Rumeni',
      'Rumeni Plavec',
    ],
    description:
      'Minor variety producing light, tart whites in Croatia and Slovenia.',
    'origin and parentage': [
      'Plavec \u017duti is an old variety from northern Croatia and southern Slovenia, once used for table grapes but now also to make wine. \u017duti and rumeni mean \u2018yellow\u2019 in Croatian and Slovenian respectively.',
    ],
    'where its grown': [
      'In Croatia, Plavec \u017duti is authorized in three regions around Zagreb in the north of Kontinentalna Hrvatska (Continental Croatia): Ple\u0161ivica, Prigorje-Bilogora and Pokuplje. The variety has been showing more promise as a wine grape since producers started to restrict yields. Those who make varietal wines, which are generally rather light at around 11\u201312% alcohol, include \u0160ember and Vu\u010dini\u0107.',
      'In Slovenia the variety goes by the name of Rumeni Plavec and is planted in the Bizeljsko-Sremi\u010d and Dolenjska subregions of Posavje and to a lesser extent further north in \u0160tajerska Slovenija (Slovenian Styria), with a total of 105 ha (259 acres) in 2009. Vino Graben produce a varietal wine but it is more often part of a blend because of its very high acidity. For the same reason, producers such as Isteni\u010d may use it in a blend for sparkling wines.',
    ],
  },
  {
    name: 'PLAVINA',
    synonyms: ['Brajda', 'Brajdica', 'Plavina Crna', 'Plavka', 'Plajka'],
    description:
      'Dark-skinned offspring of Tribidrag (Zinfandel) producing light reds the length of the Dalmatian coast.',
    'origin and parentage': [
      'Plavina is often confused with PLAVAC MALI although DNA parentage analysis has established that Plavina is a cross between VERDECA from Puglia and TRIBIDRAG (ie Primitivo or Zinfandel) from Dalmacija (Dalmatia; Lacombe et al. 2007). This trans-Adriatic link is further illustrated by the similarity between some Dalmatian and Puglian dialects, as well as by the numerous villages in southern Italy where there is a significant Dalmatian population. However, it is not yet known whether Plavina was born in Puglia or in Dalmacija, because the crossing that gave it birth could have taken place in either.',
      'Southern Italy, most likely Puglia, is a possibility because VERDECA and TRIBIDRAG have traditionally been cultivated there, but Plavina seems to have disappeared from Puglia, unless it is still cultivated under another name. Dalmacija, where Plavina is today widely cultivated, is also a possible birthplace for the variety but Verdeca has never been mentioned in Dalmacija and is not grown there now, unless it has been cultivated under another name.',
      'However, since the Dalmatian varieties GRK, Crljenak Crni (no longer cultivated), PLAVAC MALI and VRANAC also have a parent\u2013offspring relationship with TRIBIDRAG (Maletic et al. 2004) and are either grandparents or half-siblings of Plavina (see Tribidrag for the pedigree diagram), it is more likely that Plavina is of Dalmatian origin. Since VERDECA has recently been identified in Greece under the name Lagorthi (Vouillamoz), it might be interesting to search for Verdeca in Dalmacija in order to confirm this hypothesis.',
    ],
    'where its grown': [
      'Plavina is grown throughout most of Primorska Hrvatska (Coastal Croatia), from the islands in the Kvarner Gulf down to Crna Gora (Montenegro), often planted with PLAVAC MALI. It is the most important red variety in the Sjeverna Dalmacija (Northern Dalmatia) wine region and represented 3.35% of Croatia\u2019s total vineyard area in 2009, around 1,090 ha (2,693 acres). Officially authorized in all the coastal wine regions apart from Istra (Istria), Plavina makes wines that are generally light to medium-bodied, lightly coloured, with soft tannins, making them good for early drinking or to complement more powerful varieties in a blend. Recommended producers include Duvan\u010di\u0107.',
    ],
  },
  {
    name: 'PLECHISTIK',
    synonyms: [
      'Bogata Kist',
      'Goryun',
      'Khreshchatinskii',
      'Letun',
      'Osipnijak',
      'Petoun',
      'Plechistik Tsimlyansky',
      'Plecistik',
      'Rogataia Kisty',
    ],
    description:
      'Viticulturally difficult Russian variety used in sweet red sparkling wine.',
    'origin and parentage': [
      'Plechistik is an old variety from the Rostov wine region, along the River Don in south-western Russia, near the border with Ukraine. Under the shared synonym Cherny Vinny, it has been confused with KRASNOSTOP ZOLOTOVSKY. It is also sometimes called Plechistik Tsimlyansky, but Plechistik and TSIMLYANSKY CHERNY are distinct varieties (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'The Russian\nvariety Plechistik needs to be grown next to another variety in order to achieve pollination \u2013 eg TSIMLYANSKY CHERNY, with which it used to be confused in the vineyard and with which it is often blended in the sweet but tannic sparkling reds from Tsimlyansky in the Rostov wine region. Tsimlyansky Winery make a benchmark version of this traditional wine. Plechistik is never produced as a varietal wine but it contributes flavours of prunes and sweet and sour cherries. It is also planted to a limited extent further south \u2013 also with Tsimlyansky Cherny \u2013 in Russia\u2019s Krasnodar region.',
    ],
  },
  {
    name: 'PLYTO',
    synonyms: [': Plito', 'Ploto', 'Pluto', 'Plyt\u00f3'],
    description:
      'Rare, ancient, herbal-scented Cretan variety with great promise.',
    'origin and parentage': [
      'Plyto probably comes from the eastern half of the Greek island of Kr\u00edti (Crete).',
    ],
    'where its grown': [
      'Very old Plyto vines can still be found in mixed vineyards in eastern Kr\u00edti, Greece, but the variety was close to extinction before Lyararkis saw its potential and planted 1.5 ha (4 acres) at around 400 m above sea level. Their varietal Plyto, a regional wine of Ir\u00e1kleio (Heraklion), combines a herbal, slightly grassy character with a delicate, chalky, pear-grained texture and juicy citrus flavours, at the modest alcohol level of 12.5\u201313%. Other producers of varietal wines include Michalakis, also in Ir\u00e1kleio; Tamiolakis make a VIDIANO/Plyto blend. Total plantings on the island were around 5\u20137 ha (12\u201317 acres) in 2011.',
    ],
  },
  {
    name: 'POLLERA NERA',
    synonyms: ['Corlaga', 'Pollora Nera'],
    description:
      'Old dark-skinned variety grown in Liguria and north-west Toscana.',
    'origin and parentage': [
      'Pollera Nera was first described by Acerbi (1825) as a grape cultivated in the Cinque Terre (Liguria) and in Massa-Carrara (Toscana). DNA profiling has shown that Pollera Nera is a progeny of MAMMOLO, like several other Tuscan varieties (BIANCONE DI PORTOFERRAIO, CALORIA and COLOMBANA NERA) that turn out to be half-siblings of Pollera Nera (Di Vecchi Staraz, This et al. 2007). Two clonal variations of Pollera Nera are usually distinguished: Pollera Corlaga and Pollera Commune.',
    ],
    'where its grown': [
      'Pollera Nera is cultivated here and there in the Cinque Terre in Liguria, between Monterosso and Riomaggiore, and in the area of Carrara in north-west Toscana, Italy. Wines are simple and fruity and are usually blended with other local varieties, as in the Colli di Luni DOC, which tends to be predominantly SANGIOVESE. There were 73 ha (180 acres) planted in Italy in 2000.',
    ],
  },
  {
    name: 'PO\u0160IP BIJELI',
    synonyms: [
      'Po\u0161ip',
      'Po\u0161ip Veliki',
      'Po\u0161ipak',
      'Vgorski Bijeli',
    ],
    description:
      'High-quality vine from the Croatian island of Kor\u010dula making full-bodied whites.',
    'origin and parentage': [
      'Po\u0161ip Bijeli probably originates from the Croatian island of Kor\u010dula off the Dalmatian coast because DNA parentage analysis has established that it is a cross between Zlatarica Blatska Bijeli and BRATKOVINA BIJELA (Piljac et al. 2002), all three varieties having been grown on Kor\u010dula for a long time. (Zlatarica Blatska Bijeli is no longer cultivated despite its reputation for quality, probably because of irregular yields.) The black-berried Po\u0161ip Crni from the same island is a distinct variety (Maleti\u0107 et al. 1999) but is not commercially cultivated.',
    ],
    'where its grown': [
      'Po\u0161ip Bijeli, more commonly known simply as Po\u0161ip, is qualitatively one of the most important white varieties of central Dalmacija (Dalmatia) in Primorska Hrvatska (Coastal Croatia), comprising 1.4% of the Croatian vineyard area in 2009, around 450 ha (1,112 acres). The main plantings are on the island of Kor\u010dula, particularly in \u010cara and Smokvica, and to a lesser extent on neighbouring islands and, now, on the coast itself. The wines, officially recognized for their high quality by the creation of the Kor\u010dula appellation in 1967, are full-bodied, rich and yet fresh, with gentle but ripe fruit aromas, depending on location. The variety is commonly used for dry white wines, generally best in southern Dalmacija. It is occasionally blended with its parent BRATKOVINA BIJELA and sometimes oaked, but the grapes are also dried for the production of the traditional sweet wine Pro\u0161ek. Recommended producers include Grgi\u0107, Korta Katarina, Krajan\u010di\u0107 and Kunjas.',
    ],
  },
  {
    name: 'POTAMISSI',
    synonyms: [
      'Aspropotamisio',
      'Aspropotamissi',
      'Potamisi',
      'Potamisi\u00e8s',
      'Potamisio',
      'Potamisio Lefko',
      'Potamissio Lefko',
    ],
    description: 'Minor Greek island white hidden mainly in blends.',
    'origin and parentage': [
      'Potamissi, literally \u2018from the river\u2019, is a variety from the Greek Kykl\u00e1des (Cyclades) islands. According to Myles et al. (2011), the reference sample of Potamissi in the United States Department of Agriculture\u2019s National Clonal Germplasm Repository could be identical to EARLY MUSCAT but this can be rejected by comparison with the DNA profile of the true Potamissi (Vouillamoz).',
      'The dark-skinned Potamissi Mavro is also cultivated in the same area but it is not yet known whether it\u2019s a colour mutation or a distinct variety.',
    ],
    'where its grown': [
      'Potamissi is found mainly on the island of Santor\u00edni, Greece,\nwhere it is used in blends, and also to a limited extent on Kykl\u00e1des islands such as N\u00e1xos, T\u00ednos, Amorg\u00f3s, M\u00fdkonos, Fol\u00e9gandros, \u00cdos, M\u00edlos and, more surprisingly, on the island of K\u00fdthira (Cythera), off the southern tip of the Pelop\u00f3nnisos (Peloponnese). It is rarely mentioned since it comprises only a small percentage of old interplanted vineyards. Potamissi, though never made as a varietal wine, produces wines that are rather ordinary, with relatively high alcohol and moderate acidity, sometimes slightly tannic.',
    ],
  },
  {
    name: 'POULSARD',
    synonyms: [
      'M\u00e8cle',
      'Pelossard',
      'Peloussard',
      'Plant d\u2019Arbois',
      'Pleusart',
      'Ploussard',
      'Plussart',
      'Poulsard Noir',
      'Pulceau',
      'Pulsard',
    ],
    description: 'Ancient, perfumed Jura speciality.',
    'origin and parentage': [
      'Poulsard is an old variety from the Franche-Comt\u00e9 in eastern France, where it was mentioned as early as 1386 under the name Polozard in a charter written in Lons-le-Saunier and referring to a plot of land in Chilley, today called Chilly-le-Vignoble (R\u00e9zeau 1997): \u2018il devray planter savaignins noirs, polozard et mergeliains pour le pris et some de quatre florins de Florence d\u2019or\u2019 (\u2018he should plant savaignins noirs, polozard and mergeliains for the price and amount of four golden Florentine florins\u2019). Spelt Pulsard, it later appeared in a list of good varieties established by the parliament of Besan\u00e7on in 1732 (R\u00e9zeau 1997).',
      'Like PELOURSIN, the name Poulsard could derive from the colour of the berries, which resembles that of pelosses, \u2018wild plums\u2019 in the local dialect (Galet 1990). The black-berried Poulsard, or Poulsard Noir, has undergone two colour mutations \u2013 Poulsard Blanc, which has white berries (13 ha/32 acres in France in 2000), and Poulsard Rouge, which has red berries \u2013 as well as an aromatic mutation, the Poulsard Noir Musqu\u00e9, with black berries and a slight Muscat aroma but no longer in cultivation (Galet 2000).',
    ],
    'where its grown': [
      'A speciality of the Jura in eastern France, Poulsard flourishes under the local name Ploussard at Pupillin near Arbois in the north of the region and has the potential to produce perfumed, high-quality wines that are not particularly powerful, usually pale in colour and often blended, eg with TROUSSEAU or PINOT NOIR, or sold as ros\u00e9. It is also valued for adding colour to vin de paille. It is planted to a lesser extent in neighbouring Bugey, mainly for semi-sweet sparkling wines labelled Cerdon. There were 311 ha (768 acres) in France in 2009, an area almost unchanged over the last fifty years, and it is the second most widely planted red variety in the Jura. Poulsard is authorized as one of the main varieties in the Arbois, Macvin du Jura vin de liqueur, red C\u00f4tes du Jura and Cr\u00e9mant du Jura appellations and as a minor variety in those of L\u2019\u00c9toile and white C\u00f4tes du Jura.',
      'Recommended producers include Philippe Bornard (Arbois-Pupillin), Domaine Emmanuel Houillon/Pierre Overnoy (Arbois-Pupillin), Domaine Puffeney (Arbois), Domaine Andr\u00e9 et Mireille Tissot (St\u00e9phane Tissot in Arbois), Domaine de la Tournelle (Arbois).',
    ],
  },
  {
    name: 'PRAIRIE STAR',
    synonyms: ['ES 3-24-7'],
    description:
      'Cold-hardy American hybrid producing neutral, full whites for blending.',
    'origin and parentage': [
      'An Elmer Swenson 2-7-13 \u00d7 Elmer Swenson 2-8-1 hybrid bred in 1980 by Elmer Swenson in Osceola, Wisconsin, US, in which:',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 2-7-13 is an Elmer Swenson 5-14 \u00d7 SWENSON RED hybrid (see BRIANNA for the pedigree of Elmer Swenson 5-14)',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 2-8-1 (or Alpenglow) is also an Elmer Swenson 5-14 \u00d7 SWENSON RED hybrid.',
      'Prairie Star is therefore the progeny of two sibling varieties and it is an extremely complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\naestivalis, Vitis\nlincecumii, Vitis\nrupestris and Vitis\ncinerea hybrid. It was selected in 1984 and tested as ES 3-24-7 before being named and released by Tom Plocher and Bob Parke in 2000 (Smiley 2008).',
    ],
    'where its grown': [
      'Wines tend to be pretty neutral, sometimes with floral notes, but without any foxy characteristics and are unusually useful for adding body to lighter blends. Producers of varietal wines in Minnesota, where there were 40 acres (16 ha) in 2007, include Glacial Ridge, Indian Island and Millner Heritage, though it is also planted to a very limited extent elsewhere in the Midwest, eg in Iowa and Nebraska. In Qu\u00e9bec, Canada, Les Petits Cailloux and Clos du Roc Noir make dry varietal wines (oaked and unoaked versions) but many others blend it with other cold-hardy varieties such as KAY GRAY, Frontenac Gris (see FRONTENAC), ST PEPIN or VANDAL-CLICHE.',
    ],
  },
  {
    name: 'PR\u010c',
    synonyms: ['\u010cimavica', 'Par\u010d', 'Pr\u010d Bijeli'],
    description:
      'Unusually scented Croatian variety being recuperated on the island of Hvar.',
    'origin and parentage': [
      'Pr\u010d is undoubtedly from the Croatian island of Hvar. Pr\u010d in the local slang means \u2018goat\u2019, supposedly referring to the smell of the berries (Galet 2000).',
    ],
    'where its grown': [
      'Although Pr\u010d is authorized for the whole of the Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine region in Croatia and was much more widely planted at the end of the nineteenth century, it is now found almost exclusively on the island of Hvar, most often interplanted with other varieties. Wines have a distinctive aroma or odour which some describe as Muscat-like and others consider somewhat malodorous, which might explain the goat reference. The wines typically have low acidity but can be a useful addition to more neutral blends. Pr\u010d, along with several other autochthonous varieties, is part of a revitalization project involving clonal selection and replanting to save it from extinction. Such clonal selection is particularly important because almost all the vines recently studied were found to be virused (Zduni\u0107, Peji\u0107 et al. 2008). Producers of varietal wines include Plan\u010di\u0107 (dry and sweet examples as well as a blend with the equally rare BOGDANU\u0160A) and Vujnovi\u0107.',
    ],
  },
  {
    name: 'PR\u00c9COCE DE MALINGRE',
    synonyms: [
      'Blanc Pr\u00e9coce de Malingre',
      'Early Malingre',
      'Madeleine Blanche de Malingre',
      'Malinger',
      'Malingre Pr\u00e9coce',
    ],
    description:
      'Obscure Parisian cross still found in one old vineyard in the Ahr in Germany.',
    'origin and parentage': [
      'An cross obtained around 1840 by Malingre, a gardener in the Paris region of France, who did not record the parents. Recent DNA parentage analysis has shown that it is in fact a PINOT \u00d7 Bicane cross (Vargas et al. 2009), the latter being no longer cultivated.',
    ],
    'where its grown': [
      'In 2008 there was less than a hectare (2.5 acres) in France, where it is recommended for the Vend\u00e9e only and there\u2019s no sign that anyone is still producing a varietal wine. However, Johannes Hostert at Weingut Jakob Hostert in the Ahr in Germany has a small vineyard (1,330 vines on 0.16 ha/0.4 acres) of ninety-year-old ungrafted Malinger vines and produces dry, medium and sweet versions. A dry example was non-aromatic and tasted like a muted RIESLING without such fresh acidity. It is planted in many other countries as a table grape.',
    ],
  },
  {
    name: 'PRENSAL',
    synonyms: [
      'Moll',
      'Pensal Blanca',
      'Premsal',
      'Premsal Blanca',
      'Prensal Blanc',
    ],
    description: 'Robust Mallorcan speciality.',
    'origin and parentage': [
      'Prensal is an indigenous variety on the Spanish Islas Baleares.',
    ],
    'where its grown': [
      'Premsal \u2013 as it is generally spelt on Mallorca \u2013 lacks acidity and is often blended with other varieties such as Moscatel (MUSCAT), CHARDONNAY or VIOGNIER to make it fresher, and acidification is common. Although it is the principal white variety in the Binissalem-Mallorca DO, Spain, the minimum required is just 50%. There were about 65 ha (161 acres) planted on the island in 2008, used mainly for easy-drinking whites. It can produce a range of styles, from tropical-fruited and soft to more structured with an almond or woody note, all for early consumption, although some improve after a few months in bottle. It is also one of the principal varieties in the other DO on the Islas Baleares, Pl\u00e0 y Llevant, and is frequently bottled as a Vino de la Tierra Mallorca. \u00c0n Negra\u2019s Qu\u00edbia, a blend of 50% Premsal with 50% dark-skinned CALLET (made as a white) is a dry, finely textured and minerally wine with pear and greengage flavours and a sour, refreshing aftertaste. Toni Gelabert blends Premsal with Moscatel (MUSCAT BLANC \u00c0 PETITS GRAINS) to produce a perfumed, delicate and surprisingly refreshing example. It is not an easy variety to handle in the winery because it is prone to reduction when aged on the lees but also to oxidation.',
    ],
  },
  {
    name: 'PRETO MARTINHO',
    synonyms: ['Amostrinha', 'Preto Martinho do Oeste'],
    description: 'Portuguese variety often confused with Trincadeira.',
    'origin and parentage': [
      'Preto Martinho is from north-east Portugal. It has long been confused with TRINCADEIRA, and even with NEGRAMOLL (Braz\u00e3o et al. 2005), but their DNA profiles are distinct in Veloso et al. (2010).',
    ],
    'where its grown': [
      'Wines are typically moderate in alcohol and blended with other varieties such as CASTEL\u00c3O but Casa Santos Lima produce a soft, oaked varietal example. There was a total of 269 ha (665 acres) in Portugal in 2010, in the Lisboa region as well as in the north east.',
    ],
  },
  {
    name: 'PRI\u00c9',
    synonyms: [
      'Bernarde',
      'Blanc de Morgex',
      'Blanc du Valdigne',
      'Legiruela',
      'Plant de la Salle',
      'Pri\u00e9 Blanc',
    ],
    description:
      'Hugely significant Aostan variety with links to Spain and Switzerland.',
    'origin and parentage': [
      'Pri\u00e9 is one of the oldest varieties of the Valle d\u2019Aosta, where it was mentioned as early as 1691 in Saint-Pierre near Aosta. It is also called Blanc du Valdigne, after the old Latin name of the upper Valle d\u2019Aosta, at the foot of Mont Blanc, where this variety probably comes from. At one time there were two clonal variations of Pri\u00e9 (Gatta 1838): one in Valdigne (mainly in Morgex and La Salle) and one in Valpelline at the foot of the Grand Combin but the one from Valpelline has disappeared. Pri\u00e9 has also been cultivated to a very limited extent since the early nineteenth century in the Valais in Switzerland under the name Bernarde, referring to the likely route taken by those who imported the variety from the Valle d\u2019Aosta via the Grand-Saint-Bernard pass.',
      'Quite unexpectedly, Schneider et al. (2010) discovered by DNA profiling that Pri\u00e9 has also long been cultivated in the province of \u00c1vila in Spain under the local synonym Legiruela and they have found parent\u2013offspring relationships with ALBILLO REAL from the neighbouring Valladolid region and with LUGLIENGA from Piemonte in north-west Italy, while Santana et al. (2010) have also suggested a parent\u2013offspring relationship with LAIR\u00c9N from Andaluc\u00eda in southern Spain. The discovery of additional parent\u2013offspring relationships with MAYOLET and PRIMETTA from the Valle d\u2019Aosta (Vouillamoz 2005; Vouillamoz and Moriondo 2011) results in a complex pedigree that connects northern Italy and northern Spain. Because Luglienga was mentioned in the literature much before Pri\u00e9, it has been placed at the head of the genealogy shown in the diagram but since the other parents are unknown, Lair\u00e9n and Albillo Real could be switched with Luglienga, and even Pri\u00e9 could be placed as parent of all the other five varieties (Luglienga, Albillo Real, Lair\u00e9n, Primetta and Mayolet).',
      'Morphological similarities between Pri\u00e9 and LUGLIENGA had already been noticed, as well as with PRIMETTA, which is sometimes (quite reasonably, given its pedigree) called Pri\u00e9 Rouge. However, the parent\u2013offspring relationship with MAYOLET was totally unexpected and makes Pri\u00e9 a grandparent of ROUGE DU PAYS, born in Italy\u2019s Valle d\u2019Aosta and now grown exclusively in the Valais in Switzerland, and a great-grandparent of CORNALIN, ROUSSIN and NERET DE SAINT-VINCENT (see Rouge du Pays). This illustrates the significant role played by Pri\u00e9 in the biodiversity of Valle d\u2019Aosta varieties. In addition, the links with the Spanish varieties ALBILLO REAL and LAIR\u00c9N call into question the Italian origin of Pri\u00e9, and scholars have not been able to determine whether Pri\u00e9 was brought from Italy to Spain or vice versa (Schneider et al. 2010).',
    ],
    'where its grown': [
      'Pri\u00e9 is cultivated almost exclusively in the Valle d\u2019Aosta, north-west Italy, in the communes of Morgex and La Salle, between 900 and 1200 m above sea level, which puts these vineyards among the highest in continental Europe. Interestingly, phylloxera does not seem to survive in such cold climates, and all Pri\u00e9 vines are still ungrafted. Pri\u00e9 is traditionally trained on a low pergola in order to benefit from the heat accumulated in the soil. The agricultural census of 2000 recorded 39 ha (96 acres) in Italy.',
      'The Blanc de Morgex et de La Salle version of the Valle d\u2019Aosta DOC is a varietal Pri\u00e9 wine, usually dry, with aromas of mountain hay and white flowers, a fresh acidity and a persistent finish. Sparkling and dessert wines are also made. Examples include Ermes Pavese\u2019s Cuv\u00e9e Nathan (dry, oaked) and Cuv\u00e9e Ninive (sweet, grapes harvested in December), Maison Albert Vevey\u2019s dry wine and the Cave du Vin Blanc de Morgex et de La Salle\u2019s traditional-method sparkler.',
      'A few vines (0.02 ha/0.05 acres) still exist in the Valais in Switzerland under the name Bernarde.',
      'In Spain, it is scarcely cultivated, under the local name Legiruela, in San Esteban del Valle in the Provincia de \u00c1vila (Schneider et al. 2010).',
    ],
  },
  {
    name: 'PRIETO PICUDO',
    synonyms: ['Prieto Picudo Oval', 'Prieto Picudo Tinto'],
    description: 'Crisp, perfumed speciality of Le\u00f3n, north-west Spain.',
    'origin and parentage': [
      'Prieto Picudo is thought to be indigenous to the area south of Le\u00f3n in north-west Spain that qualifies for the Valdevimbre-Los Oteros Vi\u00f1o de la Tierra designation. A mutation with oval berries known as Prieto Picudo Oval is found in the same region. DNA parentage analysis has recently suggested that Prieto Picudo is a sibling of the Portuguese variety ALFROCHEIRO, cultivated under the synonym Albar\u00edn Negro in Asturias north towards the coast (Santana et al. 2010).',
      'Prieto Picudo Blanco grown south of Le\u00f3n has been shown by DNA profiling to be identical to GODELLO (Santana et al. 2007; Gago et al. 2009) and is therefore not a white mutation of Prieto Picudo.',
    ],
    'where its grown': [
      'Prieto Picudo is grown almost exclusively in Castilla y Le\u00f3n in north-west Spain, where there were a total of 5,187 ha (12,817 acres) in 2008. It is one of the principal red varieties authorized in DOs such as Tierra de Le\u00f3n and Valles de Benavente and Valtiendas although many examples are classified as Vino de la Tierra Castilla y Le\u00f3n. Wines are typically cherry-coloured, high in alcohol, very aromatic (fruits of the forest and black pepper) and high in acidity and seem to blend well with MENC\u00cdA. Recommended producers include Dehesa de Rubiales, Dominio Dostares, Gordonzello, Pardevalles, Tampesta and Vega Carriegos.',
    ],
  },
  {
    name: 'PRIKNADI',
    synonyms: [
      'Prekiadi',
      'Prekna',
      'Preknadi',
      'Preknari',
      'Preknari Lefko',
      'Prekniariko',
      'Prekno',
    ],
    description: 'Rare, and puzzling, variety from eastern Makedon\u00eda.',
    'origin and parentage': [
      'Priknadi is a rare variety local to Thessal\u00eda and Makedon\u00eda.',
    ],
    'where its grown': [
      'Priknadi wines are typically lightly aromatic, high in alcohol but low in acidity and need careful handling in the winery to avoid oxidation. The only producers currently making a varietal wine, both in N\u00e1oussa in western Makedon\u00eda, Greece, are Chrisohoou, who have 1.5 ha (4 acres) planted, and Diamantakos, who planted 1.6 ha in 2006. Dalamara nearby make a blend of MALAGOUSIA, Prekniariko and RODITIS.',
    ],
  },
  {
    name: 'PRIMETTA',
    synonyms: [
      'Neblou',
      'Premetta',
      'Pr\u00ebmetta',
      'Pri\u00e9 Rouge',
      'Prometta',
    ],
    description: 'Recuperated Aostan pink wine speciality.',
    'origin and parentage': [
      'Primetta is an old variety of the Valle d\u2019Aosta, sometimes called Pri\u00e9 Rouge because it was considered to be a seedling of PRI\u00c9. DNA evidence has recently shown this to be true: Primetta is a progeny of Pri\u00e9 and is therefore a grandchild, grandparent or half-sibling of MAYOLET, LUGLIENGA, ALBILLO REAL and LAIR\u00c9N (see Pri\u00e9 pedigree diagram; Vouillamoz and Moriondo 2011). Its name comes from Italian prima, meaning \u2018first\u2019, in reference to its early ripening.',
    ],
    'where its grown': [
      'Primetta is grown only in the Valle d\u2019Aosta, north-west Italy, mainly in Saint-Pierre, Saint-Denis, Aymavilles and Quart, and produces a ros\u00e9-coloured wine. In the past it was only ever blended in small amounts with other local varieties such as PETIT ROUGE in Enfer d\u2019Arvier and Chambave or with Petit Rouge and VIEN DE NUS in Nus (all within the Valle d\u2019Aosta DOC). In the late twentieth century it was on the verge of extinction but was rescued by Costantino Charr\u00e8re, who was the first in modern times to plant a small vineyard with this old variety and the first to produce a varietal wine. A few rare varietal ros\u00e9s have appeared quite recently, also within the Valle d\u2019Aosta DOC, such as Grosjean Fr\u00e8res\u2019 Pr\u00ebmetta, which includes 10% CORNALIN, smells of roses and strawberries and is fresh and quite firmly structured, and Les Cr\u00eates Premetta, a light-pink traditional-method sparkling version. Under the name Pri\u00e9 Rouge, the Italian agricultural census recorded just 18 ha (44 acres) in 2000.',
    ],
  },
  {
    name: 'PRINZIPAL',
    synonyms: ['Geisenheim 7116-26'],
    description:
      'Recent disease-resistant German hybrid with marked Riesling influence.',
    'origin and parentage': [
      'A complex hybrid obtained by Helmut Becker in 1971 at the Geisenheim research centre by crossing Geisenheim 323-58 \u00d7 EHRENFELSER, where Geisenheim 323-58 is a CHANCELLOR \u00d7 RIESLING hybrid (see PRIOR for the complete pedigree of Chancellor).',
    ],
    'where its grown': [
      'Authorized in Germany in 1999 though there are still only a few hectares planted. Varietal wines are produced by, for example, Wolfgang Hermes in the Nahe and Hans J Becker in Rheinhessen. They show an encouraging likeness to RIESLING and are often made in off-dry or sweet styles.',
    ],
  },
  {
    name: 'PRIOR',
    synonyms: ['Freiburg 484-87 R'],
    description: 'German hybrid bred for disease resistance.',
    'origin and parentage': [
      'Complex hybrid obtained in 1987 by Norbert Becker at the Freiburg research centre in Baden-W\u00fcrttemberg, southern Germany, by crossing Freiburg 4-61 \u00d7 Freiburg 236-75. Its name derives from the Latin for \u2018former\u2019 or \u2018elder\u2019, presumably intending to suggest superior quality, though it might also be a reference to its relatively early ripening. Its complete pedigree is shown in the diagram because it reveals that of many other varieties as well.',
    ],
    'where its grown': [
      'In Germany, the Winzergenossneschaft Achkarren in Baden produce a varietal wine, as do Arnoldweine in Arlesheim, just south of Basel, northern Switzerland. After several years of trials in the experimental vineyard of Agroscop Changins-W\u00e4denswil at Pully in Switzerland, Prior is now cultivated to a very limited extent in Switzerland (0.33 ha/0.8 acres). It shows more promise than CABERNET CORTIS, CABERNET CARBON and CABERNET CAROL. Authorized in Italy in 2011.',
    ],
  },
  {
    name: 'PRODEST',
    synonyms: ['Incrocio Cosmo 109'],
    description: 'Conegliano cross; attempt at fresher Merlot.',
    'origin and parentage': [
      'Prodest is a MERLOT \u00d7 BARBERA cross obtained in the 1960s by Italo Cosmo at the Conegliano research centre in the Veneto, northern Italy, with the aim of increasing the acidity of Merlot. It is a sibling of NIGRA.',
    ],
    'where its grown': [
      'A few hectares are cultivated around Conegliano and Pieve di Soligo in the province of Treviso in the Veneto, Italy, where it has been proposed as a replacement for MERLOT. The Italian agricultural census of 2000 records just 14 ha (35 acres). Wines tend to be herbaceous and lightly fruity, full-bodied, tannic and have good ageing capacity but its name is unlikely to catch on in English-speaking markets.',
    ],
  },
  {
    name: 'PROKUPAC',
    synonyms: [
      'Kameni\u010darka',
      'Kamenilarka',
      'Majski \u010cornii',
      'Nikodimka',
      'Ni\u0161evka',
      'Prokupec',
      'Prokupka',
      'Rskavac',
      'Skopsko Crno',
      'Zar\u010din',
    ],
    description:
      'Balkan variety making early-drinking reds and deeply coloured ros\u00e9.',
    'origin and parentage': [
      'Prokupac\nis a very old Balkan variety, possibly originating in Serbia, where it has long been the dominant red variety, supposedly since the Middle Ages, and where its significant morphological diversity (Avramov and del Zan 2004) confirms its ancientness. It was used to breed \u017dUPLJANKA.',
    ],
    'where its grown': [
      'Widely planted in Serbia, particularly in the Zapadna Morava and Juzna Morava wine regions in the centre and south of the country, and in Kosovo, Prokupac produces early-drinking wines that are typically a deep ruby colour, high in alcohol, with aromas of red fruit. It is often blended with international varieties and frequently produced as a dark ros\u00e9. Producers include Ivanovi\u0107 and Topli\u010dki Winery. It is also found in the Republic of Macedonia, where it is grown to a limited extent in cooler sites, as well as in Bulgaria and Russia.',
    ],
  },
  {
    name: 'PROSECCO',
    synonyms: [
      'Bri\u0161ka Glera',
      'Glera',
      'Prosecco Tondo',
      'Serpina',
      'Serprina',
      'Serprino',
      '\u0160teverjana',
      'Teran Bijeli',
    ],
    description:
      'The dominant, rather neutral grape for Prosecco sparkling wine, probably Istrian. Misleadingly renamed Glera for commercially protective reasons.',
    'origin and parentage': [
      'Since the late eighteenth century, several morphologically distinct varieties have been called Prosecco in the area of Conegliano in the province of Treviso in northern Italy, possibly taking the name of the village of Prosecco in the province of Trieste. In the mid nineteenth century, Balbi Valier selected and cultivated a particular clonal variation named Prosecco Tondo after the shape of the berries (tondo is Italian for \u2018round\u2019). In the 1980s, two other clonal variations were selected for further study by the research centre at Conegliano: PROSECCO LUNGO, with oval berries (lungo means \u2018long\u2019) and Prosecco Nostrano (nostro means \u2018ours\u2019).',
      'Morphological and DNA analyses proved that all three supposed clonal variations are in fact distinct varieties: Prosecco Tondo is now simply called Prosecco, PROSECCO LUNGO keeps its name and Prosecco Nostrano was shown to be identical to MALVASIA BIANCA LUNGA (Costacurta et al. 2003; Crespan et al. 2003). A strong genetic relationship was observed between Prosecco and Prosecco Lungo, although a parent\u2013offspring relationship can be ruled out (Crespan et al. 2009). Interestingly, DNA parentage analysis showed that VITOVSKA (under the name Vitouska), a white grape of the Karst region on the Italian\u2013Slovenian border, is a natural progeny of Prosecco and Malvasia Bianca Lunga (Crespan et al. 2007). In terms of the clonal variation names explained above, that makes Vitovska a Prosecco Tondo \u00d7 Prosecco Nostrano cross!',
      'In addition, comparison of DNA profiles shows that Prosecco is identical to Teran Bijeli (bijeli is Croatian for \u2018white\u2019), previously considered to be a rare variety native to the Istra (Istria) region of Croatia (Maleti\u0107 et al. 1999), and also identical to Bri\u0161ka Glera and \u0160teverjana, previously thought to be two distinct varieties cultivated in Slovenia (\u0160tajner et al. 2008).',
      'Prosecco was crossed with CABERNET SAUVIGNON to obtain INCROCIO MANZONI 2.15.',
      'As part of the promotion of Prosecco di Conegliano-Valdobbiadene to DOCG status and the enlargement of the Prosecco DOC zone in 2009, the Prosecco Consorzio set in motion an official name change so that this principal grape variety is known as Glera, its supposed Friulian synonym, and Prosecco is reserved for the designation of origin, effectively preventing producers from other regions or countries taking advantage of the name Prosecco to designate any old sparkling wine. The enlargement of the Prosecco DOC area includes the village of the same name near Trieste, the variety\u2019s alleged birthplace. This amendment is both confusing and misleading: Glera is a generic name applied to several distinct varieties in the province of Trieste, and recent studies have shown that Glera in fact usually refers to PROSECCO LUNGO and much less frequently to Prosecco (Tondo) and other local varieties from the Karst region such as VITOVSKA, or the non-cultivated Aghedone and Mocula.',
      'By reconstructing the genetic relationships among Croatian grapes, Maleti\u0107 et al. (1999) showed that Prosecco (under the name Teran Bijeli) occupies a key role in relation to all other Croatian grapes, and appears to be related to \u017dILAVKA from Bosnia-Herzegovina. This genetic study supports an Istrian origin for Prosecco. As a consequence, it is likely that the village of Prosecco in the province of Trieste was only a stopover of this variety on its way from Istra to Friuli, and that its original name was Teran Bijeli.',
    ],
    'where its grown': [
      'Prosecco is one of the most widespread varieties of the Veneto in northern Italy, cultivated mainly in the province of Treviso between Valdobbiadene and Conegliano to produce popular sparkling wines in the generally superior zone of the hills around Conegliano that qualifies as Prosecco di Conegliano-Valdobbiadene DOCG, in the more obscure Colli Asolani Prosecco DOCG and in the recently expanded flatter area that is now Prosecco DOC. The latter encompasses no fewer than nine provinces (Belluno, Gorizia, Padova, Pordenone, Treviso, Trieste, Udine, Venezia and Vicenza), absorbing practically all of the IGTs under which Prosecco production outside the classic hills has been marketed up to now. While the province of Treviso boasts more than 10,000 ha (24,711 acres) devoted to the variety, Padova, Vicenza and Belluno together have no more than 600 ha (1,483 acres) planted with Prosecco, and Friuli a mere 125 ha (309 acres).',
      'Total annual production of this generally inexpensive fizz is around 1,300,000 hl and total plantings of the variety in Italy in 2000 were already 8,144 ha (20,124 acres), according to the agricultural census, which does not, however, distinguish between Prosecco and PROSECCO LUNGO. More recently, the total vineyard area has been estimated at 11,000 ha (27,182 acres). The subzone of Cartizze, even cooler than the rest of the zone, comprises 106 ha (262 acres) of vineyard, and one million bottles of Prosecco which is held to be superior to the normal DOC or DOCG production.',
      'In the right hands, Prosecco (Tondo) can be lightly floral, compared with the slightly more spicy PROSECCO LUNGO. The variety is rather neutral, especially if yields are not controlled. The finished wines are light and frothing, sometimes lemony, sometimes slightly floral, but their neutrality and defects are too often masked by over-generous additions of sugar.',
      'Prosecco wines exist in still, but mainly fizzy and sparkling, versions. Recommended producers of superior wines include Adami, Bisol, Bortolin, Bortolomiol, Case Bianche, Col Vetoraz, Conte Collalto, Nino Franco, Andreola Orsola and Sorelle Bronca. Still wines may be produced under the Prosecco designations described above but also in the Colli di Conegliano DOC, where it may be included with VERDISO and Boschera (aka VERDICCHIO BIANCO) in the dry or sweet, passito-style Torchiato di Fregona, and both still and sparkling wines are made in the Colli Euganei DOC (Prosecco here known as Serprino), both on its own and blended with varieties such as GARGANEGA.',
      'A few hectares are planted over the border in Gori\u0161ka Brda in Slovenia, and there are similarly limited plantings in Argentina. Brown Brothers in Australia have already produced several vintages of \u2018Prosecco\u2019 but are no longer allowed to export it without changing the name.',
    ],
  },
  {
    name: 'PROSECCO LUNGO',
    synonyms: ['Glera', 'Ribolla Spizade', 'Tocai Nostrano'],
    description: 'The spicier, less common Prosecco grape.',
    'origin and parentage': [
      'In the province of Treviso in north-eastern Italy, Prosecco Lungo, Prosecco Tondo and Prosecco Nostrano used to be considered simple clonal variations of the same variety but with differently shaped berries (see PROSECCO). However, recent DNA profiling has shown that Prosecco Lungo is distinct from Prosecco Tondo, now simply called Prosecco, and distinct from Prosecco Nostrano, which turned out to be identical to MALVASIA BIANCA LUNGA (Costacurta et al. 2003; Crespan et al. 2003).',
      'Prosecco Lungo had almost disappeared when it was selected and cultivated by the research centre at Conegliano in the Veneto, northern Italy, at the end of the 1980s. It was long thought to be an old and virtually extinct variety from the province of Treviso but recent DNA investigation showed unexpectedly that the majority of grapes named Glera in the Karst area on the Italy\u2013Slovenia border are in fact identical to Prosecco Lungo, whereas only a few of them corresponded to PROSECCO and other local Italian varieties (Crespan et al. 2009). In addition, Prosecco Lungo was found scattered in vineyards from the Veneto to Friuli-Venezia Giulia under various synonyms: Glera, Tocai Nostrano, Ribolla Spizade (Crespan et al. 2003; Crespan et al. 2009). The same authors observed a strong genetic relationship between Prosecco and Prosecco Lungo, although a parent\u2013offspring relationship can be ruled out. The non-cultivated Bela Glera (bela means \u2018white\u2019 in Slovenian), mistakenly identified as CHASSELAS by Galet (2000), was shown by DNA analysis to be distinct from Glera (\u0160tajner et al. 2008), and therefore from Prosecco Lungo and Prosecco, and identical to Pienel (Crespan et al. 2011), once cultivated near Udine.',
    ],
    'where its grown': [
      'Prosecco Lungo is often confused with PROSECCO (Tondo), though wines made from the former tend to be slightly spicier than the latter\u2019s wines, which can be more floral, and a small amount of Lungo may be blended with Prosecco in the fermentation vat in the production of Prosecco sparkling wines since the varieties ripen around the same time. Costadila, for example, include some Prosecco Lungo in their single-vineyard San Lorenzo wine.',
      'Its distribution in Italy is very similar to that of PROSECCO, although Prosecco Lungo is more sparsely cultivated. Exact figures are hard to come by because the Italian register of varieties, and therefore also the agricultural census, do not distinguish between Prosecco and Prosecco Lungo. Under the synonym Tocai Nostrano, this variety is grown around Breganze in the province of Vicenza in north-east Italy and may be included with VESPAIOLA and other minor local varieties in the sweet, passito-style Torcolato wine within the Breganze DOC. In the Colli di Conegliano DOC, Prosecco Lungo is one of the varieties that may be used to supplement the minimum 85% of Prosecco. Most unusually, Varaschin produce a varietal, still Prosecco Lungo using grapes from San Pietro di Barbozza.',
    ],
  },
  {
    name: 'PRUNELARD',
    synonyms: ['Prunelart'],
    description:
      'Old, rare, recently rescued, south-west French variety, parent of Cot (Malbec).',
    'origin and parentage': [
      'Prunelard is an old variety from the Haute-Garonne and the Tarn that was mentioned for the first time in Hardy (1842), and a dozen years later by Victor Rendu (1854) in his Amp\u00e9lographie fran\u00e7aise: \u2018les principaux c\u00e9pages du vignoble de Gaillac sont le Duras, le Taloche, le Muscat et le Prunelard, associ\u00e9s au Mauzac\u2019 (\u2018the main grape varieties of the Gaillac vineyards are Duras, Taloche, Muscat and Prunelard, together with Mauzac\u2019). The name Prunelard comes from prun\u00e8l, the Languedoc dialect word for \u2018plum\u2019, because of the shape and colour of the berries (R\u00e9zeau 1997).',
      'Prunelard should not be confused with Prunelat, a synonym of CINSAUT and/or COT in the Gironde, that was mentioned as far back as the sixteenth century (R\u00e9zeau 1997), even though they all belong to the Cot ampelographic group (see p XXVII; Bisson 2009). Recent DNA parentage analysis has shown that Cot is a Magdeleine Noire des Charentes \u00d7 Prunelard cross (see CABERNET SAUVIGNON for the complete pedigree), and that Prunelard has a parent\u2013offspring relationship with M\u00c9RILLE (Boursiquot et al. 2009).',
    ],
    'where its grown': [
      'Prunelard was once widely planted from Lavilledieu to Gaillac in south-west France and has been recently rediscovered and nursed back to life thanks to Robert and Bernard Plageoles and a few other local backers of the region\u2019s historic indigenous varieties. Although Prunelard is recommended only in the Tarn and is not permitted in any appellation wine, the total vineyard area has increased from just over 2 ha (5 acres) in 1998 to 13 ha (32 acres) in 2008. Producers of varietal wines include Domaine Plageoles and Domaine Carcenac; Domaine de la Ramaie produce a blend of 75% Prunelard with 25% Braucol (FER). Wines are deeply coloured, full-bodied yet balanced and well suited to ageing, often with robust alcohol and flavours of ripe plum and spice.',
    ],
  },
  {
    name: 'PRUNESTA',
    synonyms: ['Prunesta Nera'],
    description: 'Minor Calabrian used mainly in blends.',
    'origin and parentage': [
      'The name Prunesta might derive from the Italian bruma, meaning \u2018mist\u2019, referring to the bloom covering the berries, similar to the etymology suggested for NEBBIOLO. Galet (2000) proposed that Bermestia Bianca could be the white mutation of Prunesta Nera but comparison of their DNA profiles proves them to be distinct varieties (Vouillamoz).',
      'Confusingly, a table grape grown in Puglia is also called Prunesta (or Prugesta Pugliese) even though it is a different variety.',
    ],
    'where its grown': [
      'Prunesta is cultivated on the Tyrrenian coast of Calabria, Italy, in particular in the province of Reggio Calabria. Although it is authorized as a varietal wine in IGT Val di Neto, it is usually blended with other local varieties, as in Enopolis Costa Viola\u2019s Armac\u00eca Rosso (IGT Costa Viola) and Tramontano\u2019s Scilla. There were 96 ha (237 acres) planted to this variety in Italy in 2000.',
    ],
  },
  {
    name: 'PUKHLIAKOVSKY',
    synonyms: [
      'Coarna Alba',
      'Kecskecsecsu',
      'Korna Belaja',
      'Majorka Belaja',
      'Puchljakovski',
    ],
    description: 'Russian variety with erratic yields producing light wines.',
    'origin and parentage': [
      'Pukhliakovsky probably originates from the Rostov wine region in south-western Russia, near the border with Ukraine, and is probably named after the village of Pukhliakovskaya. The black-berried table grape known as Pukhliakovsky Cherny is not a colour mutation but a distinct variety.',
    ],
    'where its grown': [
      'Pukhliakovsky is grown mainly in the Rostov wine region along the River Don in Russia, especially in the village of Pukhliakovskaya. Wines are relatively light and low in alcohol. The grapes are often grown for the table.',
    ],
  },
  {
    name: 'PULES',
    synonyms: ['Pul\u00ebs', 'Puls'],
    description: 'Minor Albanian white.',
    'origin and parentage': [
      'Pules most likely originates in the Berat region in south-central Albania but its ampelographic identity is still obscure because two reference vines called Pules i Bylyshit, sampled in Tepelene in southern Albania, turned out to have distinct and unique DNA profiles in Ladoukakis et al. (2005) and it is not known which of the two is the true-to-type Pules.',
    ],
    'where its grown': [
      'Found only in its region of origin in south-central Albania and traditionally used to make raki and trained up trees, Pules has recently been replanted and used to make wine. \u00c7obo, for example, make a varietal version.',
    ],
  },
  {
    name: 'RABIGATO',
    synonyms: [
      'Baldsena',
      'Carrega Besta',
      'Estreito',
      'Muscatel Bravo',
      'N\u00e3o H\u00e1',
      'Puesta en Cruz',
      'Rabigato Respigueiro',
      'Rabo de Asno',
      'Rabo de Carneiro',
      'Rabo de Gato',
      'Rodrigo Affonso',
    ],
    description: 'Potentially high-quality, high-acid Douro variety.',
    'origin and parentage': [
      'Rabigato comes from the Douro in northern Portugal, where it is grown almost exclusively (Rolando Faustino, personal communication). It was first mentioned as Rabigato Respigueiro in Tr\u00e1s-os-Montes in 1711 by Vic\u00eancio Alarte in his \u2018Agricultura das vinhas\u2019 (Galet 2000). The name Rabigato is a contraction of one of its synonyms, Rabo de Gato, meaning \u2018cat\u2019s tail\u2019 (Galet 2000), referring to its long clusters. Other synonyms make similar references: Rabo de Asno means \u2018donkey\u2019s tail\u2019 and Rabo de Carneiro means \u2018sheep\u2019s tail\u2019. Recent DNA studies have suggested a possible genetic link between Rabigato and TINTA FRANCISCA (Castro et al. 2011).',
      'Rabigato from the Douro should not be confused with RABO DE OVELHA, which is also known as Rabigato in the Minho in north-western Portugal. Although the names are confusing, they are quite easy to distinguish in the vineyard because their leaves are so different.',
    ],
    'where its grown': [
      'Rabigato is widely grown in the Douro, particularly in the Douro Superior in the east of the region, but is rarely made as a varietal wine. Such wines tend to be fresh, vibrant, fairly high in alcohol and very high in acidity with aromas of lemon and orange blossom as well as having vegetal or mineral notes. When carefully made, the wines have impressive length and are worth ageing in bottle. There were 2,542 ha (6,281 acres) in Portugal in 2010. Given the variety\u2019s high acidity, it is often blended with lower-acid varieties such as FERN\u00c3O PIRES, S\u00cdRIA, Gouveio (GODELLO) and VIOSINHO. Varietal wines are, unusually, made by Dona Berta, Jo\u00e3o Brito e Cunha, CARM and Quinta do Carrenho. It is also used in the production of port.',
    ],
  },
  {
    name: 'RABO DE OVELHA',
    synonyms: ['Fernan Piriz', 'Rabo de Ovella'],
    description:
      'Undemonstrative Portuguese variety making whites for early drinking.',
    'origin and parentage': [
      'Rabo de Ovelha, meaning \u2018ewe\u2019s tail\u2019 and referring to the shape of the bunch, probably originates in the Alentejo region in southern Portugal, where the greatest genetic diversity has been observed (Lopes et al. 2006). It is often confused with RABIGATO from the Douro but their DNA profiles in Almadanim et al. (2007) are distinct, and it is not a colour mutation of Rabo de Ovelha Tinto, which is a synonym for NEGRAMOLL (Mart\u00edn et al. 2006; Veloso et al. 2010).',
      'DNA parentage analysis has shown that Rabo de Ovelha has a likely parent\u2013offspring relationship with CAYETANA BLANCA from the Iberian peninsula (Zinelabidine et al. 2012; see Cayetana Blanca for the pedigree diagram).',
    ],
    'where its grown': [
      'Widely planted in the Alentejo in southern Portugal and also found further north in Beiras, Rabo de Ovelha produces lightly aromatic wines without great intensity, usually blended with other varieties and best drunk young. J Portugal Ramos\u2019 Loios is a blend of Rabo de Ovelha with Roupeiro (S\u00cdRIA), and Carmim (the Reguengos de Monsaraz co-operative) include it in several white blends. There were 957 ha (2,365 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'RABOSO PIAVE',
    synonyms: [
      'Friulara di Bagnoli',
      'Friularo',
      'Raboso del Piave',
      'Raboso Nostrano',
    ],
    description:
      'Often tough Veneto variety needing careful handling and long ageing to produce high-quality wine. Parent of Raboso Veronese.',
    'origin and parentage': [
      'Raboso Piave and RABOSO VERONESE are very similar old varieties from the Veneto in northern Italy, where they have often been confused. However, morphological and DNA profiling has now clearly established that they are distinct varieties (Crespan, Cancellier et al. 2006). Recent DNA profiling has suggested a possible parent\u2013offspring relationship with FOGARINA (Myles et al. 2011).',
      'Raboso Piave most likely originates from the plains of the River Piave in the Veneto north of Venezia. The name Raboso could derive from the local dialect rabioso, used to describe unripe fruit, possibly in reference to the variety\u2019s astringency. Alternatively, Raboso could have been named after a tributary of the River Piave, although no Raboso vines have ever been found there. In the province of Padova, Raboso Piave is called Friulara or Friularo, probably because this grape was introduced from Friuli in the seventeenth or eighteenth century by the De Vidiman family, who owned a villa in Bagnoli. However, this does not necessarily signify a Friulian origin for Raboso Piave because at that time the River Piave marked the Friuli boundary.',
      'DNA profiling showed unexpectedly that Raboso Friularo is not a synonym of Raboso Piave but of RABOSO VERONESE (Salmaso et al. 2008). Raboso Piave, already mentioned in 1679, is much older than Raboso Veronese, which was not mentioned until the nineteenth century (Cal\u00f2, Francini et al. 2008). This is consistent with the results of DNA parentage analysis, which show that Raboso Veronese is a Raboso Piave \u00d7 MARZEMINA BIANCA cross (Crespan, Cancellier et al. 2006; see REFOSCO DAL PEDUNCOLO ROSSO and PINOT pedigree diagrams).',
      'GRAPARIOL, also called Rabosina Bianca, is a rare variety from the province of Treviso that was often believed to be a white mutation of Raboso Piave but recent DNA profiling has showed there is no direct relationship between Grapariol and either Raboso Piave or RABOSO VERONESE (Crespan et al. 2009). Finally, it should also be noted that MANZONI MOSCATO is a Raboso Piave \u00d7 MUSCAT OF HAMBURG cross.',
    ],
    'where its grown': [
      'Raboso Piave has a reputation for astringent tannins and high acidity, though proponents claim that this reputation is partly because the grapes were in the past not fully ripe at harvest and that the grape can be tamed in the winery and by extended bottle ageing to produce high-quality wines. It is planted right across the Veneto, northern Italy, often interplanted with the less common RABOSO VERONESE. Of all the Raboso plantings today, about 90% are Raboso Piave and 10% Raboso Veronese. About 80% of the Raboso Piave vineyards are in the province of Treviso, the rest in the provinces of Padova, in particular in the neighbourhood of Bagnoli di Sopra, where it is known under the name Friularo, and Venezia, between the Piave and Livenza rivers.',
      'In the 1950s and 1960s, Raboso Piave plantings decreased in favour of softer and easier-to-sell international varieties such as MERLOT and CABERNET FRANC but the Confraternita del Raboso Piave (Brotherhood of Raboso Piave), established in 1996 at the instigation of Cecchetto (which has the biggest vineyard holdings of this variety) and other local producers, is making strenous efforts to safeguard and promote the variety. There were 1,330 ha (3,287 acres) planted in Italy in 2000, according to the agricultural census, but the total had shrunk to around 1,100 ha (2,718 acres) by 2008.',
      'Today Raboso Piave is used to make not only deeply coloured, firmly tannic reds but also ros\u00e9 and sparkling wines, both dry and sweet. Varietal wines are authorized in DOCs such as Bagnoli di Sopra, Corti Benedettine del Padovano and Riviera del Brenta (labelled Friularo in the first). Although the tannins can be aggressive, emphasized by the naturally high acidity (useful as a minor component in blends), improved winemaking and maturation are showing this variety\u2019s real potential. For example, Cecchetto\u2019s full-bodied and ageworthy Amarone-style Gelsaia has complex aromas and flavours of ripe black fruits, liqueur de cassis, almonds and fresh mint, and although the tannins are firm, they are ripe and in balance with the fruit and the freshness on the finish. Villa Sandi make an interesting, lightly sweet, late-harvest passito-style wine. Other recommended producers include Bonotto delle Tezze, Casa Roma and Italo Cescon.',
    ],
  },
  {
    name: 'RABOSO VERONESE',
    synonyms: ['Rabosa', 'Raboso di Verona', 'Raboso Friularo'],
    description:
      'Makes tannic Veneto red, often used for sparkling wines, and often confused with its parent, the more common Raboso Piave.',
    'origin and parentage': [
      'Raboso Veronese and RABOSO PIAVE are very similar old varieties from the Veneto, where they have often been confused. However, morphological and DNA profiling has now clearly established that they are distinct varieties (Crespan, Cancellier et al. 2006). Raboso Veronese was first mentioned in the province of Treviso in the nineteenth century whereas Raboso Piave was already mentioned in 1679 (Cal\u00f2, Francini et al. 2008).',
      'As Raboso Veronese is \u2013 surprisingly \u2013 not cultivated in the province of Verona today, its name was long thought to derive from a man named Veronesi who was responsible for the spread of the variety. However, DNA parentage analysis has revealed that Raboso Veronese is a RABOSO PIAVE \u00d7 MARZEMINA BIANCA cross that probably occurred at the Azienda dei Conti Papadopoli in Cologna Veneta (Crespan, Cancellier et al. 2006), a village in the province of Verona (see REFOSCO DAL PEDUNCOLO ROSSO pedigree diagram). This recent discovery points again to Verona as the birthplace of Raboso Veronese, suggesting its name is fully deserved geographically.',
      'Since MARZEMINA BIANCA is likely to be a natural cross between MARZEMINO and GARGANEGA (Salmaso et al. 2008; Vouillamoz), two important and historical northern Italian varieties, Raboso Veronese is their probable grandchild. Remarkably, DNA profiling showed that Raboso Friularo is a synonym for Raboso Veronese, and Friularo a synonym for RABOSO PIAVE (Salmaso et al. 2008). It should also be noted that FERTILIA is a MERLOT \u00d7 Raboso Veronese cross.',
    ],
    'where its grown': [
      'Raboso Veronese is cultivated mainly in the south east of the Veneto, Italy, in the provinces of Treviso, Venezia, Vicenza and Rovigo, and to a lesser extent in the provinces of Ferrara and Ravenna in Emilia-Romagna and in Friuli. Raboso Veronese is often interplanted with RABOSO PIAVE. The Italian agricultural census of 2000 recorded total plantings of 340 ha (840 acres).',
      'Raboso Veronese is authorized in the sparkling, ros\u00e9 and passito versions of the Bagnoli di Sopra DOC and in Colli Euganei DOC; a varietal wine, labelled simply Raboso, may be made within the Vicenza DOC. Sparkling ros\u00e9 wines are not uncommon, presumably because of the high acidity and sweet fruit flavours. The wines tend to be less tannic than those made from RABOSO PIAVE although the two varieties are often blended since they may well be interplanted and are still widely confused, despite the DNA evidence of their separate identities.',
      'In 2008 there were also limited plantings of Raboso Veronese of around 50 ha (124 acres) in Argentina, divided between the Mendoza and San Juan wine regions.',
    ],
  },
  {
    name: 'RADISSON',
    synonyms: ['ES 5-17'],
    description:
      'Another dark-skinned American hybrid that has found its way to Qu\u00e9bec, although its exact identity is unclear.',
    'origin and parentage': [
      'Radisson is said to be a seedling of an Elmer Swenson 593 vine obtained by Elmer Swenson in Osceola, Wisconsin, US, in which Elmer Swenson 593 is an Elmer Swenson 80 \u00d7 VILLARD BLANC hybrid (see BRIANNA for their pedigrees). However, what is cultivated in Canada under the name ES 5-17 or Radisson might not be the original ES 5-17 with female-only flowers but a distinct and unknown variety with complete male and female flowers (Hart nd).',
    ],
    'where its grown': [
      'Like several other Swenson hybrids, this seems to be more widely planted in Qu\u00e9bec, Canada, than in the American Midwest. Several producers, including Domaine de la Source \u00e0 Marguerite, use it in blends with varieties such as MAR\u00c9CHAL FOCH, FRONTENAC and SABREVOIS.',
    ],
  },
  {
    name: 'RAFFIAT DE MONCADE',
    synonyms: ['Arr\u00e9fiat', 'Arrufiat', 'Raffiat', 'Refiat', 'Ruffiac'],
    description: 'Rare south-west French vine often confused with Arrufiac.',
    'origin and parentage': [
      'Raffiat de Moncade is an old variety from B\u00e9arn in the Pyr\u00e9n\u00e9es-Atlantiques and from the Landes (mainly Orthez, Salies and Lagor) in south-west France, where it shares several synonyms with ARRUFIAC, with which it has often been confused. The first reliable reference to Raffiat de Moncade was probably the one made by Jean-Alexandre Cavoleau in 1827 (R\u00e9zeau 1997): \u2018dans les Basses-Pyr\u00e9n\u00e9es, les vins blancs de Juran\u00e7on sont produits par le refiat, le menseing gros et petit, le claverie, l\u2019aulhan, le courtoisie\u2019 (\u2018in the Basse-Pyr\u00e9n\u00e9es, white wines from Juran\u00e7on are produced by refiat .\u00a0.\u00a0.\u2019 etc.). Raffiat de Moncade is an offspring of GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram).',
      'Like ARROUYA and ARRUFIAC, Raffiat could derive from arro(u)y, meaning \u2018red\u2019 in the B\u00e9arnese dialect, while rufe means \u2018rough\u2019. The name Moncade is a reference to an old and noble family from B\u00e9arn whose name was also given to the \u2018Tour Moncade\u2019, built in 1242 by Gaston VII de Moncade in Orthez, the historical location of the Raffiat de Moncade vineyard.',
      'Raffiat de Moncade was used to breed ARRILOBA.',
    ],
    'where its grown': [
      'Even though Raffiat de Moncade is recommended in the Gers, Landes and Pyr\u00e9n\u00e9es-Atlantiques d\u00e9partements and is one of the principal varieties officially authorized for the B\u00e9arn appellation, total plantings in France in 2008 were just 9 ha (22 acres), down from 86 ha (213 acres) in 1979. Wines tend to be rather neutral and alcoholic. Pascal Lapeyre of Domaine Guilhemas produces one of the very rare examples of varietal Raffiat, which he describes as rounded with aromas of pear, apple and flowers.',
    ],
  },
  {
    name: 'RAMISCO',
    synonyms: ['Rasmisco nos A\u00e7ores'],
    description:
      'Demanding and endangered Portuguese variety that produces wines aggressive in youth but elegant after considerable time in bottle.',
    'origin and parentage': [
      'Ramisco originates in Portugal\u2019s Colares region, north east of Lisboa. According to recent DNA studies, TRINCADEIRA from the Alentejo and SERCIAL from Lisboa could be genetically close to Ramisco (Almadanim et al. 2007).',
    ],
    'where its grown': [
      'Ramisco is the variety of the Colares DO, the threatened, Atlantic-whipped sandy vineyards on the coast north west of Lisboa, Portugal. They are challenged not only by the city\u2019s encroachment but also because the variety is difficult to grow since it has to be planted in trenches several metres deep to anchor the plant in the clay soils below the sand, yields are low and the wines are generally approachable only after several years in bottle thanks to high acidity and powerful tannins. The sand does, however, mean that the vines can be planted on their own roots since phylloxera cannot survive here. It is hardly surprising that there were just 23 ha (57 acres) of Ramisco remaining in 2010 but it is a shame since these rarefied wines, once aged, can become elegant and aromatic \u2013 a complex mix of flavours that include fresh meat, mushrooms, wet earth and cedar. Alcohol is generally low at around 11 or 11.5% (Rolando Faustino, personal communication). Recommended producers include Monte Cascas, Quinta das Vinhas de Areia and Paulo da Silva.',
    ],
  },
  {
    name: 'RANAC BIJELI',
    synonyms: ['Ranac Silbijanski Bijeli', 'Silbijanac Bijeli'],
    description: 'Almost extinct, intensely flavoured Croatian island variety.',
    'origin and parentage': [
      'Ranac Bijeli, literally \u2018early white\u2019, is an almost extinct variety from the islands of Pag and Silba (hence its synonyms), north of Zadar, in Croatia.',
    ],
    'where its grown': [
      'A few vines of Ranac Bijeli can still be found in old vineyards on the islands of Pag and Silba in the northern Adriatic. It is currently part of a revitalization project involving clonal selection and replanting of autochthonous Croatian varieties. Although it has quality potential, its main advantage of full flavour and early ripening also make it unpopular with growers as they lose so much of the crop to sweet-toothed wasps.',
    ],
  },
  {
    name: 'RANFOL',
    synonyms: [
      'Belina Pleterje',
      'Plavis',
      'Praskava Belina',
      'Ranfol Beli',
      'Ranfol Bijeli',
      'Sremska Lipovina',
      '\u0160tajerska Belina',
      'Urbanka',
      'Vrbanka',
    ],
    description:
      'Variety with Slovenian origins making humdrum whites there and in Croatia.',
    'origin and parentage': [
      'This variety probably originates in \u0160tajerska Slovenija (Slovenian Styria) or possibly from the north of neighbouring Croatia. It should not be confused with GOUAIS BLANC, of which it is a possible offspring (\u0160tajner et al. 2008). The common synonym \u0160tajerska Belina simply means \u2018Styria white\u2019.',
    ],
    'where its grown': [
      'Ranfol produces light and relatively neutral whites. There were 210 ha (519 acres) in Croatia in 2009, mainly in the Slavonija, Moslavina, Prigorje-Biogora and Pokupjle wine regions in Kontinentalna Hrvatska (Continental Croatia). Plantings in Slovenia are smaller still (36 ha/89 acres in 2009), divided fairly evenly between the wine regions of Podravje and Posavje in the east of the country. Recommended producers there include Ptujska Klet.',
    ],
  },
  {
    name: 'RANNA MELNISHKA LOZA',
    synonyms: [
      'Early Melnik',
      'Melnik 55',
      'Melnishka Ranna',
      'Ranna Melnishka',
    ],
    description: 'Bulgarian cross with good potential but lacking recognition.',
    'origin and parentage': [
      'Ranna Melnishka Loza, literally \u2018early Melnik grape\u2019, is a SHIROKA MELNISHKA \u00d7 VALDIGUI\u00c9 cross obtained in 1963 by Yane Atanasov and his colleagues Z Zankov and G Karamitev at the Sandanski research centre (now Sintica Winery) in south-west Bulgaria. The breeders made a series of crossings using a mixture of pollens from the three French varieties DURIF, JURAN\u00c7ON NOIR and Valdigui\u00e9 to create an early-ripening variety with the characteristics of Shiroka Melnishka. The seedling that was initially named Melnik 55 was later officially christened Ranna Melnishka Loza. DNA parentage analysis has finally established that Valdigui\u00e9 was the other parent of sibling varieties Ranna Melnishka Loza and MELNIK 82 (Hvarleva, Russanov et al. 2005). It was not commercially vinified until the late 1990s.',
    ],
    'where its grown': [
      'Although there were 546 ha (1,349 acres) of Ranna Melnishka Loza in Bulgaria in 2009, almost all in the Struma Valley in the far south west of the country, the variety struggles for recognition because it is often thought to be the same asSHIROKA MELNISHKA and both are often referred to simply as Melnik. Logodaj produce both red and ros\u00e9 varietal wines, Sintica an oak-aged red. The wines are said to have sweet and sour cherry flavours and a note of spice, with rounded tannins, respectable levels of alcohol and fresh acidity.',
    ],
  },
  {
    name: 'RASTAJOLA',
    synonyms: ['Durera', 'Restajola'],
    description: 'Minor variety from northern Piemonte encountered in blends.',
    'origin and parentage': [
      'Rastajola probably comes from northern Piemonte, where it was once widespread. It is often confused with NERETTO DURO, or with Erbaluce Nero in the Canavese in the province of Torino (Schneider and Mannini 2006), a non-cultivated variety that is not a colour mutation of ERBALUCE.',
    ],
    'where its grown': [
      'Rastajola is cultivated here and there in the communes of Sizzano, Ghemme, Fara and Romagnano Sesia in the province of Novara in north-east Piemonte, north-west Italy. It is nearly always blended with other local varieties such as VESPOLINA and NEBBIOLO.',
    ],
  },
  {
    name: 'R\u00c1THAY',
    synonyms: ['Klosterneuburg 1355-3-33', 'R\u00e1thay Noir'],
    description:
      'Rare, disease-resistant Austrian hybrid making wines for early drinking.',
    'origin and parentage': [
      'A Klosterneuburg 1189-9-77 \u00d7 BLAUBURGER hybrid obtained in 1970 by Gertrude Mayer at the Klosterneuburg research centre in Austria (see ROESLER for the pedigree of Klosterneuburg 1189-9-77), making it a half-sibling of Roesler. R\u00e1thay was named after Emerich R\u00e1thay (1845\u20131900), second director of Klosterneuburg.',
    ],
    'where its grown': [
      'R\u00e1thay is planted mainly in Burgenland (total area c.10 ha/25 acres) and produces soft, early-drinking reds. Varietal wines are made by Trabauer (Nieder\u00f6sterreich/Lower Austria), Taurot (Burgenland) and Edenhof (Weinviertel), for example.',
    ],
  },
  {
    name: 'R\u00c4USCHLING',
    synonyms: [
      'Dretsch',
      'Drutsch',
      'Drutscht',
      'Gro\u00dfer R\u00e4uschling',
      'Kl\u00f6pfer',
      'Offenburger',
      'R\u00e4uschling Weiss',
      'Reuschling',
      'Ruchelin',
      'Weisser R\u00e4uschling',
      'Z\u00fcrirebe',
      'Z\u00fcriwiss',
    ],
    description:
      'Historic, rare German variety experiencing a small revival in Switzerland.',
    'origin and parentage': [
      'R\u00e4uschling is a very old variety from the Rheintal (Rhine Valley) in Germany that was grown in the Middle Ages in what is now Rheinland-Pfalz and W\u00fcrttemberg in southern Germany, Alsace in north-eastern France, as well as northern and western Switzerland. According to Aeberhard (2005), the earliest mention of this variety appeared under its old synonym Drutsch(t) in Hieronymus Bock\u2019s (1546) Kreutterbuch in the region of Landau in Rheinland-Pfalz. A clearer reference to the name Ra\u00fcschling is found in Franken in a request from Graf Philip Ernest von Hohenlohe-Langenburg, dated 12 December 1614, to replace GOUAIS BLANC (H\u00fcnnisch) by a selection of better varieties including Reuschling and ELBLING (Dornfeld 1868). The modern spelling was first mentioned by Michael Sorg in 1759 in Schaffhausen, along with its synonym Z\u00fcrirebe, meaning \u2018grape from Z\u00fcrich\u2019 (Aeberhard 2005). The name R\u00e4uschling could derive from rauschen, referring to the sound of the wind going through the dense foliage of this variety, or alternatively from Russling, Rus meaning \u2018dark wood\u2019.',
      'A preliminary DNA parentage analysis tentatively suggesting that R\u00e4uschling could be a natural GOUAIS BLANC \u00d7 SAVAGNIN cross (Maul 2006), just like its siblings PETIT MESLIER and AUBIN BLANC in northern France (see PINOT pedigree diagram), has been confirmed by the analysis of fifty DNA markers (Vouillamoz). This parentage explains why R\u00e4uschling was sometimes erroneously called Grosser Traminer (Traminer is the name used for Savagnin in German-speaking regions), and why it was called Grosser R\u00e4uschling in north-eastern France to distinguish it from Kleiner R\u00e4uschling, a synonym for KNIPPERL\u00c9 in Alsace, which is also a progeny of Gouais Blanc.',
      'A red-berried R\u00e4uschling Rot, also called Blauer R\u00e4uschling or Gelbh\u00f6lzer, was often mentioned in eighteenth- and nineteenth-century literature but was thought to be extinct until it was found in a Swiss grape collection, showing the same DNA profile as R\u00e4uschling, making it a colour mutation (Vouillamoz, Frei and Arnold 2008).',
      'Although R\u00e4uschling shares the synonym Z\u00fcrirebe with COMPLETER, the two varieties are quite distinct.',
    ],
    'where its grown': [
      'R\u00e4uschling has now virtually disappeared from Alsace and Germany and is found only in German-speaking Switzerland (23 ha/57 acres in 2009), especially in the canton of Z\u00fcrich in the north east. It produces light wines with citrus notes and typically high acidity. One of the best and most ageworthy R\u00e4uschlings is made by Hermann Schwarzenbach by the Z\u00fcrichsee (Lake Z\u00fcrich). Other notable producers in Switzerland include Erich Meier in Z\u00fcrich and Weinstamm in Schaffhausen.',
    ],
  },
  {
    name: 'RAVAT BLANC',
    synonyms: ['Rava 6', 'Ravat 6', 'Ravat Chardonnay 6', 'Ravat Chardonnay B'],
    description: 'Mediocre light-skinned M\u00e2connais hybrid on its way out.',
    'origin and parentage': [
      'A complex hybrid obtained in the 1930s by J F Ravat in Marcigny, eastern central France, by crossing Seibel 5474 (or Seibel 8724 according to some authors) \u00d7 CHARDONNAY, where Seibel 5474 is a Seibel 405 \u00d7 Seibel 867 cross, and Seibel 867 is a Vivarais \u00d7 NOAH cross (see PRIOR for the complete Seibel 405 and Vivarais pedigrees).',
    ],
    'where its grown': [
      'Wines are generally rather mediocre and susceptible to oxidation. Even though this variety is included in the official register of varieties in France, total plantings are shrinking, from 600 ha (1,483 acres) in 1958 to just 7 ha (17 acres) in 2008. Domaine Mondon-Demeur\u00e9, specialists in obscure and forgotten varieties, produce a varietal version (classified as a vin de France) north west of Saint-\u00c9tienne in the Rh\u00f4ne.',
      'There are still scattered plantings in the Finger Lakes of New York State (Bully Hill make a very sweet version), and elsewhere in the north-eastern US, but not enough to make it into the official statistics.',
    ],
  },
  {
    name: 'RAYON D\u2019OR',
    synonyms: [
      'Feherek Kiralya',
      'Roi des Blancs',
      'Seibel 4986',
      'Zlatni Luc',
      'Zokoy Loloutch',
    ],
    description:
      'Minor but relatively successful French hybrid once popular in the Loire. Begat Seyval Blanc and Vidal.',
    'origin and parentage': [
      'A complex hybrid obtained at the beginning of the twentieth century by Albert Seibel in Aubenas, west of Mont\u00e9limar in south-eastern France, by crossing Seibel 405 \u00d7 Aramon du Gard (see PRIOR for the complete pedigrees of Seibel 405 and Aramon du Gard).',
      'Rayon d\u2019Or was used to breed SEYVAL BLANC, SEYVAL NOIR and VIDAL, the first and the last having been more successful than their parent, especially in colder climates.',
    ],
    'where its grown': [
      'There were just 6 ha (15 acres) of Rayon d\u2019Or in France in 2008 although it was once authorized and widely grown in the Loire (6,965 ha/17,211 acres in 1958) when vineyards were replanted in the wake of phylloxera. It has also found its way across the Atlantic and is found to a very limited extent in the American states as far apart as Missouri and New Jersey, for example, but has not achieved the success of its offspring VIDAL.',
    ],
  },
  {
    name: 'REBERGER',
    synonyms: ['Geilweilerhof 86-2-60'],
    description: 'Recent, disease-resistant German hybrid.',
    'origin and parentage': [
      'A REGENT \u00d7 BLAUFR\u00c4NKISCH hybrid obtained in 1986 by Rudolf Eibach and Reinhard T\u00f6pfer at the Geilweilerhof research centre, released in 2004 but not yet authorized for quality wine production in Germany.',
    ],
    'where its grown': [
      'Wines can be powerful and have well-integrated tannins, similar to BLAUFR\u00c4NKISCH, but more deeply coloured. Among the few producers of varietal Reberger wines are H\u00f8jbakke Vineyard in Denmark and, in Germany, Eberle in the Pfalz and Dr Lawall in Rheinhessen. The Geilweilerhof research centre also sell their bottling of this variety.',
    ],
  },
  {
    name: 'REBO',
    synonyms: ['Incrocio Rigotti 107-3'],
    description: 'Rather promising red cross made in Trentino.',
    'origin and parentage': [
      'Rebo is number 107-3 in a series of crossings obtained in 1948 by Rebo Rigotti at the San Michele all\u2019Adige research centre between Verona and Bolzano in Trentino, northern Italy, and selected for its disease resistance and good production. Rigotti described this cross as MERLOT \u00d7 MARZEMINO but recent DNA profiling has revealed that Rebo is a Merlot \u00d7 TEROLDEGO cross (Malossini et al. 2000).',
    ],
    'where its grown': [
      'Rebo is cultivated in Trentino, northern Italy, mainly in San Michele all\u2019Adige, Volano and Calavino near Trento, as well as in Cavedine and Padergnone (Rigotti\u2019s birthplace) in the Valle dei Laghi west of Trento. Although the variety is also planted in other areas, varietal wines are made only within the Trentino DOC, by producers such as Dorigati, the Istituto Agrario di San Michele all\u2019Adige and Pisoni. They can be rich, full-bodied and well suited to barrel maturation. There were 39 ha (96 acres) in Italy in 2000, according to the agricultural census.',
      'It is also found in Brazil, where Aurora are experimenting with Rebo in Serra Ga\u00facha.',
    ],
  },
  {
    name: 'REFOSCO',
    synonyms: ['Refoschin', 'Refo\u0161k', 'Rif\u00f2sc', 'Rifosco'],
    description: 'REFOSCO',
    'origin and parentage': [
      'The earliest mention of Refosco appeared in a document referring to a famous banquet held in Cividale on 6 June 1409 in honour of Pope Gregory XII at which the following wines were served: Ribolla from Rosazzo, Verduzzo from Faedis, Ramandolo from Torlano, Refosco from Albana and Marzemino from Gradiscutta (Peterlunger et al. 2004).',
      'Refosco is later mentioned several times in Friuli (Cal\u00f2 2005) but its identity is uncertain because Refosco or Refo\u0161k is a name used in northern Italy and Slovenia for several distinct varieties often referred to as the Refoschi family. Using ampelography and DNA profiling, Costacurta et al. (2005) distinguished six varieties among the Refoschi:',
      '\u2013\u00a0\u00a0\u00a0REFOSCO DAL PEDUNCOLO ROSSO, the most widespread variety',
      '\u2013\u00a0\u00a0\u00a0REFOSCO DI FAEDIS, also called Refoscone, Refosco Nostrano, Refosco di Ronchis (Cipriani et al. 1994)',
      '\u2013\u00a0\u00a0\u00a0Refosco d\u2019Istria, identical to Refo\u0161k in Slovenia (Kozjak et al. 2003) and with TERRANO or Teran in Istra (Istria), Croatia (Maleti\u0107 et al. 1999)',
      '\u2013\u00a0\u00a0\u00a0Refosco di Guarnieri, identical toTREVISANA NERA (Crespan, Giannetto et al. 2008)',
      '\u2013\u00a0\u00a0\u00a0Refosco del Botton, identical to TAZZELENGHE (Costacurta et al. 2005)',
      '\u2013\u00a0\u00a0\u00a0Refosco di Rauscedo, or Refosco Gentile, not officially registered in Italy and almost extinct.',
      'In addition, both Refosco del Botton and Refosco di Rauscedo showed a likely parent\u2013offspring relationship with REFOSCO DI FAEDIS (Costacurta et al. 2005).',
    ],
  },
  {
    name: 'REFOSCO DAL PEDUNCOLO ROSSO',
    synonyms: ['Rif\u00f2sc dal Pec\u00f2l R\u00f2ss'],
    description:
      'Most widely planted Refosco with complex family history making tart but potentially interesting reds in Friuli and Slovenia.',
    'origin and parentage': [
      'Refosco dal Peduncolo Rosso (meaning \u2018red pedicel\u2019, or \u2018red stem\u2019) is the best known of the Refoschi, a group of closely related varieties from the Karst plateau on the Italian\u2013Slovenian border. It is not known whether the Refosco mentioned in this region from 1409 onwards was identical to Refosco dal Peduncolo Rosso or to another member of the Refoschi. The name Refosco dal Peduncolo Rosso appeared for the first time around 1870, when ampelographers started to distinguish this variety from the other Refoschi.',
      'The widespread confusion between Refosco dal Peduncolo Rosso and MONDEUSE NOIRE probably started life some years ago at the Foundation Plant Services at the University of California at Davis (Nelson-Kluk 2005). A vine named Refosco was collected some time before 1966 by Austin Goheen from the Jackson experimental vineyard established in Amador County in the 1880s by the University of California at Berkeley\u2019s Eugene Hilgard. In the 1990s, European ampelographers identified this variety as Mondeuse Noire, as did DNA profiling some time later, so that Refosco and Mondeuse Noire were said to be synonyms. However, at the beginning of the twenty-first century, DNA profiling revealed that this American Refosco matched neither the original Italian Refosco dal Peduncolo Rosso nor any other variety of the Refosco group: this was simply a misnamed Mondeuse Noire.',
      'Refosco dal Peduncolo Rosso is also often confused with TERRANO or Teran from Istra (Istria) but a comparison of their DNA profiles disproves this hypothesis (Vouillamoz).',
      'DNA parentage studies have provided evidence that Refosco dal Peduncolo Rosso is a progeny of MARZEMINO (Grando et al. 2006). Since Marzemino and LAGREIN are both progenies of TEROLDEGO, Refosco dal Peduncolo Rosso turns out to be a grandchild of Teroldego and a nephew/niece of Lagrein, as shown in the diagram. In addition, Refosco dal Peduncolo Rosso is a parent of CORVINA VERONESE and a grandparent of RONDINELLA. The historical geographic distribution of the varieties involved in the pedigree of Refosco dal Peduncolo Rosso strongly anchors its roots in north-eastern Italy.',
    ],
    'where its grown': [
      'Refosco dal Peduncolo Rosso is widely cultivated in Friuli-Venezia Giulia and in the Veneto in northern Italy, both on hillsides and on flatter land. Varietal wines are authorized in many DOCs in both the Veneto (eg Lison-Pramaggiore, Riviera del Brenta) and in Friuli (eg Colli Orientali del Friuli, Friuli Aquileia, Friuli Isonzo). The wines are deeply coloured with flavours of plums and sometimes a hint of almonds; they are usually medium-bodied but the acidity can be quite high because the grapes ripen notoriously late. Interest in Refosco increased in the 1980s, leading to greater care in the vineyard and winery and a general improvement in quality, although experiments with small-barrel ageing have not been universally successful. The most promising zone for Refosco is the Colli Orientali and the Koper district in Slovenia. Others include Grave del Friuli, Lison-Pramaggiore (outside Friuli), Latisana and Aquileia. There were 720 ha (1,779 acres) in Italy in 2000, according to the agricultural census.',
      'Recommended producers in Italy include Alberice, Anna Berra, Bosco del Merlo, Livio Felluga, Iole Grillo, Moschioni, Paladin, Tenuta Pinni, La Roncaia, Russolo, Tenuta Santa Anna, Santa Margherita, Vignai da Duline.',
      'Given the political rather than viticultural origins of the border between Italy and Slovenia, and the fact that Refosco dal Peduncolo Rosso may be confused with TERRANO in the vineyard, it is quite likely that there are some Refosco dal Peduncolo Rosso vines in Slovenian vineyards bordering Gorizia. However, the majority of vines known as Refo\u0161k in Slovenia are Terrano.',
      'American wines labelled Refosco may well be MONDEUSE NOIRE. The odd Refosco is produced in Greece.',
    ],
  },
  {
    name: 'REFOSCO DI FAEDIS',
    synonyms: ['Refosco di Ronchis', 'Refosco Nostrano', 'Refoscone'],
    description:
      'A relatively minor but perfumed Friuli Refosco enthusiastically supported by a local growers\u2019 association.',
    'origin and parentage': [
      'Refosco di Faedis is thought to originate in the area between Faedis, Cividale and Torreano to the west of the city of Udine in Friuli. DNA profiling has shown that Refosco di Faedis is distinct from REFOSCO DAL PEDUNCOLO ROSSO and identical to Refoscone, Refosco Nostrano and Refosco di Ronchis (Cipriani et al. 1994; Costacurta et al. 2005). DNA analysis has also provided evidence of likely parent\u2013offspring relationships with both TAZZELENGHE (or Refosco del Botton) and Refosco di Rauscedo (Costacurta et al. 2005), the latter identical to Refosco Gentile in Crespan et al. (2011) but no longer cultivated.',
    ],
    'where its grown': [
      'Refosco di Faedis used to be widespread across Friuli, north-east Italy, especially in its homeland in the province of Udine, between Faedis and Torreano, but today it has virtually disappeared. In an effort to keep the variety alive, a group of enthusiastic growers in Faedis have formed the Associazione Volontaria fra viticoltori del Refosco di Faedis to promote this local variety. In general, it tends to be slightly more acidic and tannic than REFOSCO DAL PEDUNCOLO ROSSO. Varietal wines may be produced within the Colli Orientali del Friuli DOC.',
      'Listed as Refosco Nostrano, the variety notched up a total of 263 ha (650 acres) in the 2000 Italian agricultural census. Forchir and Vigna delle Beccacce produce a varietal wine, the former labelling it Refoscone.',
    ],
  },
  {
    name: 'REGENT',
    synonyms: ['Geilweilerhof 67-198-3'],
    description: 'Highly successful and increasingly popular German hybrid.',
    'origin and parentage': [
      'A complex hybrid obtained in 1967 by Gerhardt Alleweldt at the Geilweilerhof research centre in the Pfalz, Germany, by crossing Diana \u00d7 CHAMBOURCIN, in which Diana is a SILVANER \u00d7 M\u00dcLLER-THURGAU cross that should not be confused with the CATAWBA seedling that is also known as Diana in the US.',
      'It was named after Le R\u00e9gent, the famous diamond (140.5 carats) found by a slave in the Golkonda mine in India. Philippe II, Duke of Orl\u00e9ans, used the gem to decorate the crown of Louis XV for his coronation in 1722, Napoleon later embellished his sword with it and since 1887 it has sparkled in the French Royal Treasury at the Louvre.',
      'Regent was used to breed CALANDRO and REBERGER and was authorized for quality wine production in Germany in 1996 despite its non-vinifera genes.',
    ],
    'where its grown': [
      'Of all the recently bred, disease-resistant German varieties, Regent is the most widely planted and rapidly expanding in the German-speaking wine world. There are 2,161 ha (5,340 acres) in Germany, with most vines in Rheinhessen, the Pfalz and Baden. Fippinger-Wick in the Pfalz and J\u00fcrgen Ellwanger in W\u00fcrttemberg make good examples.',
      'Regent has already been widely adopted outside Germany: 40 ha (99 acres) in north-east Switzerland, particularly in the cantons of Z\u00fcrich and Schaffhausen; 15 ha (37 acres) in England, where it shows real promise though is currently less widely planted than RONDO; and modest plantings in Sweden and Belgium.',
      'Its success is not due solely to its disease resistance but also to the quality of the wines it produces. The grapes ripen to higher sugar levels than Sp\u00e4tburgunder (PINOT NOIR) and the wines are full-bodied and approachable, suitable both for early drinking and for barrel ageing thanks to present but velvety tannins. Fruit flavours are in the cherry to redcurrant spectrum.',
    ],
  },
  {
    name: 'REGNER',
    synonyms: ['Alzey 10378'],
    description:
      'Declining, light-skinned German cross with little to recommend it.',
    'origin and parentage': [
      'A crossing of the white table grape LUGLIENGA (aka Seidentraube) with GAMAY NOIR (in fact the Gamay Pr\u00e9coce mutation), created in 1929 by Georg Scheu at Alzey. It was named in honour of Anne-Marie Regner (1911\u201399), who collaborated with Scheu.',
    ],
    'where its grown': [
      'Wines are low in acidity with a slight Muscat note, reminiscent of M\u00dcLLER-THURGAU. The variety can attain high sugar levels but at the expense of acidity and it is not surprising that it is in decline, with a current total in Germany of only 46 ha (114 acres), down from 124 ha (306 acres) in 2001. It has produced slightly better results in England, where there are just over 5 ha (12 acres), though it is probably better as a blending component than as a varietal wine.',
    ],
  },
  {
    name: 'REICHENSTEINER',
    synonyms: ['Geisenheim 18-92'],
    description:
      'Cool-climate, productive, relatively soft German cross of pan-European descent.',
    'origin and parentage': [
      'A M\u00dcLLER-THURGAU \u00d7 (MADELEINE ANGEVINE \u00d7 Weisser Calabreser) cross bred by Heinrich Birk at the Geisenheim research centre in the Rheingau, Germany, in 1939 and authorized in 1978. (Weisser Calabreser is not a colour mutation of NERO D\u2019AVOLA, which is often called Calabrese.) It was named after Reichenstein castle at Trechtingshausen, perched dramatically above the Rhein in Rheinland-Pfalz. Reichensteiner was used to breed the Swiss varieties GAMARET, GARANOIR and MARA.',
    ],
    'where its grown': [
      'Germany had 106 ha (262 acres) in 2008 with more than half in Rheinhessen but also 26 ha (64 acres) in the Mosel region. Producers include Paternushof in Rheinhessen and Rainer Heil in the Mosel.',
      'In England it was the third most planted white wine variety after CHARDONNAY and BACCHUS with total plantings of 95 ha (235 acres) by 2009. Wines are pretty neutral, sometimes lightly floral, and best as part of a blend. In the UK, it is often blended with SEYVAL BLANC, MADELEINE \u00d7 ANGEVINE 7672, HUXELREBE, etc.; Chapel Down and Camel Valley include it in their blended sparkling wines. There are also limited plantings in New Zealand (72 ha/178 acres) and tiny amounts in British Columbia, Japan and Switzerland.',
    ],
  },
  {
    name: 'R\u00c9SELLE',
    synonyms: ['VB 86-3'],
    description: 'Very minor disease-resistant Swiss hybrid.',
    'origin and parentage': [
      'R\u00e9selle is a BACCHUS \u00d7 SEYVAL BLANC hybrid obtained by Valentin Blattner in Soyhi\u00e8res in the Swiss Jura. R\u00e9selle and BIRSTALER MUSKAT are therefore siblings. The variety was named after the hamlet of La R\u00e9selle close to Soyhi\u00e8res.',
    ],
    'where its grown': [
      'The variety\u2019s breeder Valentin Blattner is almost the only producer of this variety (less than a hectare/2.5 acres planted in Switzerland in 2009), giving light wines with aromas of grapefruit, lemon and blackcurrant.',
    ],
  },
  {
    name: 'RETAGLIADO BIANCO',
    synonyms: [
      'Arba Luxi',
      'Arretallau',
      'Arrotelas',
      'Bianca Lucida',
      'Co \u2018e Erbei',
      'Coa de Breb\u00e8i',
      'Erbaluxi',
      'Mara Bianca',
      'Rechiliau',
      'Redagl\u00e0du',
      'Retagladu',
      'Retellau',
      'Ritelau',
    ],
    description: 'Rare Sardinian with almost more synonyms than vines.',
    'origin and parentage': [
      'Retagliado Bianco was first cited in 1877 on Sardegna, where it has numerous synonyms depending on where it\u2019s grown.',
    ],
    'where its grown': [
      'Today Retagliado Bianco is cultivated on a very small scale in the provinces of Sassari and Gallura, especially in old vineyards on the island of Sardegna. There were 28 ha (69 acres) in Italy in 2000. It is authorized as a varietal wine within the IGT Colli del Limbara but is usually blended with VERMENTINO and other local varieties.',
    ],
  },
  {
    name: 'R\u00c8ZE',
    synonyms: [
      'Blanc de Maurienne',
      'Resi',
      'R\u00e8ze Jaune',
      'R\u00e8ze Verte',
    ],
    description:
      'Rare, very old, well-connected Swiss variety worthy of greater attention.',
    'origin and parentage': [
      'R\u00e8ze is one of the oldest varieties in the Alps. Its earliest mention appeared in the Valais, southern Switzerland, on a Latin parchment dated 1313 known as the \u2018Registre d\u2019Anniviers\u2019 (Ammann-Doubliez 2007; Vouillamoz 2009a): \u2018tribus generibus racemorum bene et sufficienter maturorum, scilicet de neyrun, de humagny et de regy\u2019 (\u2018three kinds of grapes, good and ripe enough, that is to say of neyrun, of humagny and of regy\u2019). The name Regy undoubtedly corresponds to R\u00e8ze, because several subsequent documents mentioned it under the name Regis, suggesting that it possibly derived from the family name Regis that was widespread in the region in the Middle Ages (Vouillamoz and Moriondo 2011).',
      'Until recently, R\u00e8ze was known only in the Valais, Switzerland, but Jos\u00e9 Vouillamoz and the brothers Jean-Pierre, Philippe and Michel Grisard from Fr\u00e9terive in France\u2019s Savoie discovered old R\u00e8ze vines in the Vall\u00e9e de la Maurienne that were identified by DNA profiling in 2009. Local people simply called it Blanc de Maurienne, suggesting that R\u00e8ze had long been grown in the Maurienne. A similar finding was made by Ga\u00ebl Delorme in the French Jura (Thierry Lacombe, personal communication), which indicates that the distribution of R\u00e8ze was once much wider than it is now. This has been confirmed by DNA parentage analyses showing that R\u00e8ze has parent\u2013offspring relationships with at least five geographically distant varieties (Vouillamoz, Schneider and Grando 2007; Vouillamoz 2009b): in Switzerland, the now virtually extinct Diolle and Grosse Arvine in the Valais; in Italy, CASCAROLO BIANCO in Piemonte as well as GROPPELLO DI REV\u00d2 and NOSIOLA in Trentino. In addition, R\u00e8ze might also be closely related to FREISA from Italy\u2019s Piemonte (Vouillamoz, Schneider and Grando 2007).',
      'A rare red-berried colour mutation was recently found in the Valais (Vouillamoz, Frei and Arnold 2008).',
    ],
    'where its grown': [
      'Before the widespread replacement of R\u00e8ze by CHASSELAS and SILVANER in the replantings that followed the phylloxera crisis at the end of the nineteenth century, it was one of the most widespread varieties in the Valais, Switzerland, where it was traditionally used to make Vin du Glacier, a dry unfortified wine made in a solera system in which the casks are refilled only once a year, producing a maderized style.',
      'Today there are little more than 2 ha (5 acres) of R\u00e8ze grown in the Valais, where Josef-Marie Chanton crafts varietal wines with typical aromas of gooseberries, green apple, crisp acidity and a light structure. Other notable producers include Cave Les Sentes and Vin du Mur.',
    ],
  },
  {
    name: 'RIBOLLA GIALLA',
    synonyms: [
      'Rabola',
      'Rabiola',
      'Rebolla',
      'Rebula',
      'Ribolla di Rosazzo',
      'Ribu\u00e8le',
    ],
    description: 'Ancient white from the Italy\u2013Slovenia border.',
    'origin and parentage': [
      'Ribolla Gialla is a very old variety from Friuli-Venezia Giulia. The earliest mention of its wine dates from 1296 under the name Rabola, an old and attested synonym for Ribolla: on 20 March, Pope Boniface VIII settled a dispute between the Bishop of Trieste and the monastery of San Giorgio Maggiore in Venezia about the selling of a wine called Rabola (Di Manzano 1860). Three years later (3 February 1299), the same wine appears in the documents of the notary Ermanno di Gemona among the gifts made by the city of Udine to Girardo da Camino: one pig, one cow, two conzi of Rabiola (a conz was an old measure equal to 85 litres/22.5 US gallons) and some bread (Di Manzano 1860). Numerous subsequent references demonstrate that Ribolla Gialla was already widespread in Friuli and Istra (Istria) in the fourteenth century.',
      'Ribolla Gialla is distinct from the almost extinct Ribolla Verde and is not a colour mutation of Ribolla Nera (ie SCHIOPPETTINO). The variety named R\u00e9bola or Ribolla Riminese in the province of Rimini is identical to PIGNOLETTO (Filippetti et al. 1999), and Ribolla Spizade is a synonym for PROSECCO LUNGO. A possible parent\u2013offspring relationship with GOUAIS BLANC, based on DNA evidence, is currently under investigation (Serena Imazio and Jos\u00e9 Vouillamoz, unpublished data).',
      'A recent DNA survey of Rebula and similar varieties in Slovenia confirmed that it is the same variety as Ribolla Gialla but also that it shows a significant level of genetic diversity in Gori\u0161ka Brda (Slovenia), Collio (Italy) and Istra (Croatia), with several distinct clones interplanted with other distinct and undetermined varieties (possibly seedlings?) in the vineyard (Rusjan et al. 2010).',
    ],
    'where its grown': [
      'Ribolla Gialla is cultivated in Friuli, north-east Italy, hard by the border with Slovenia \u2013 a border that has seen so many changes that it is hardly surprising that the variety is also grown, as Rebula, in Slovenia. In Italy in 2000 the agricultural census recorded 284 ha (701 acres), planted mainly on the hills between Tarcento and Istra and around Gorizia. Varietal wines are produced in the Colli Orientali del Friuli and Collio DOCs, with the villages of Cialla, Rosazzo, Capriva, Cividale, Cormons, Gradisca d\u2019Isonzo, Manzano and Oslavia producing particularly fine examples \u2013 the first two officially recognized as subzones within the Colli Orientali del Friuli DOC. Oslavia is regarded as the home of the variety, which was traditionally fermented on the skins. Today a few days\u2019 skin contact is by no means unusual.',
      'Traditionally, varietal wines have been light-bodied and high in acidity, sometimes slightly floral. More recently, ambitious and innovative producers have been making more concentrated and characterful versions with a deep yellow colour and rich yellow-fruit, sometimes nutty and mineral, flavours, although experiments with barrel fermentation and/or ageing have not been universally successful. Recommended producers include Josko Gravner, whose distinctive Anfora is made in clay amphorae, Miani, Primosic, Matjaz Tercic and Le Vigne di Zam\u00f2.',
      'In Slovenia the majority of Rebula vines are planted in Gori\u0161ka Brda north of the Italian city of Gorizia (446 ha/1,102 acres), where it is the most widely planted variety of either colour, and in Vipavska Dolina east of Gorizia (295 ha/729 acres), where it is the most widely planted white. There are also very limited plantings in Kras (Carso in Italian) and Slovenska Istra (Slovenian Istria). Recommended producers include Bati\u010d, Kabaj, Edi Sim\u010di\u010d, Marjan Sim\u010di\u010d and \u0160\u010durek.',
      'A little is also planted in the Napa Valley, California.',
    ],
  },
  {
    name: 'RIESEL',
    synonyms: ['VB 11-11-89-12'],
    description:
      'Minor Swiss hybrid bred mainly for its disease resistance that has found a home in the Netherlands.',
    'origin and parentage': [
      'Riesel is a hybrid obtained by Valentin Blattner in Soyhi\u00e8res in the Swiss Jura by crossing CABERNET SAUVIGNON with an undisclosed disease-resistant partner (Resistenzpartner in German).',
    ],
    'where its grown': [
      'In the Netherlands, Colonjes in Groesbeek produce a medium-dry, lemony varietal wine and both Colonjes and Wijnboerderij De Gravin in Roswinkel make a blend of Riesel and SOLARIS. It is not unlike RIESLING, with floral and white-fruit aromas, but with less acidity and complexity.',
    ],
  },
  {
    name: 'RIESLANER',
    synonyms: ['Mainriesling', 'W\u00fcrzburg N 1-11-17'],
    description:
      'Rare, undervalued but demanding Riesling-like German cross making stunning botrytis-influenced sweet wines.',
    'origin and parentage': [
      'A SILVANER \u00d7 RIESLING cross bred by August Ziegler in 1921 in W\u00fcrzburg. This parentage has been confirmed by DNA profiling (Grando and Frisinghelli 1998). ORANIENSTEINER, OSTEINER and Rieslaner are siblings. Rieslaner was used to breed ALBALONGA.',
    ],
    'where its grown': [
      'Germany has 87 ha (215 acres), mostly in Franken and the Pfalz, but Dreissigacker and Keller both make outstanding Rieslaner TBA in Rheinhessen, where just a few hectares remain. In these wines, as in F\u00fcrst L\u00f6wenstein\u2019s and Rudolf F\u00fcrst\u2019s Auslesen from Franken, the botrytis influence concentrates the ripe grapefruit, apricot and bitter orange flavours of the variety, balanced by fine acidity. M\u00fcller Catoir in the Pfalz have shown the potential too. It is a shame that Rieslaner is becoming increasingly rare because if it reaches full ripeness, it can produce remarkable, full-bodied yet fresh, ageworthy wines. Dry versions can taste alcoholic and unbalanced if the grapes are picked fully ripe and overly acidic if not.',
    ],
  },
  {
    name: 'RIESLING',
    synonyms: [
      'Beyaz Riesling',
      'Johannisberg',
      'Kleinriesling',
      'Klingelberger',
      'Lipka',
      'Petit Rhin',
      'Raisin du Rhin',
      'Rajinski Riesling',
      'Rajnai Rizling',
      'Renski Riesling',
      'Renski Rizling',
      'Rheinriesling',
      'Rhine Riesling',
      'Riesling Edler',
      'Riesling Gelb',
      'Riesling Renano',
      'Riesling Rh\u00e9nan',
      'Riesling Weisser',
      'Rislinoc',
      'Risling',
      'Rizling Rajnski',
      'Ryzlink R\u00fdnsk\u00fd',
      'Starovetski',
      'Weisser Riesling',
      'White Riesling',
    ],
    description:
      'One of the world\u2019s greatest white wine grapes, capable of making particularly geographically expressive and long-lived wines at all sweetness levels.',
    'origin and parentage': [
      'Riesling is one of the most ancient German grape varieties, as suggested by its impressive number of synonyms. According to Bertsch in Levadoux (1956), the name Riesling could derive from reissen (old German r\u00eezan), originally meaning \u2018to split\u2019, then \u2018to make an incision\u2019, \u2018to carve\u2019 or \u2018to engrave\u2019 and later \u2018to tear\u2019 or \u2018to write\u2019. No explanation is provided for this rather strange etymology, but perhaps the earliest meaning, \u2018to split\u2019, could be a reference to the way the berries split between the fingers, similar to the use of the synonym Fendant for CHASSELAS. Alternatively, the name might possibly be related to the grape\u2019s tendency to millerandage (in German verrieseln, though it is stretching it a bit to say millerandage is like \u2018making an incision\u2019 in the flowers so that they do not form berries) or to the German word Rissling (also derived from reissen), meaning \u2018cutting\u2019.',
      'Riesling probably originated in the Rheingau, on the northern bank of the Rhein in Germany, where its earliest mention is said to be in a document dated 3 March (February according to some accounts) 1435. Klaus Kleinfisch, cellarmaster at Schloss Katzenelnbogen in R\u00fcsselsheim near Frankfurt-am-Main in the state of Hessen, reports on the expenditures and revenues of Graf Johann IV von Katzenelnbogen (Staab 1991): \u2018item XXII \u00df umb setzreben riesslingen in die wingarten\u2019 (\u201822 Solidi [gold coins] for riesslingen vine cuttings for the vineyard\u2019). Some sources have seczreben but the writing is hard to decipher and setzreben (\u2018vine cuttings\u2019) makes more sense.',
      'Numerous references to Riesling are to be found in other documents in Germany but the similarity with ancient mentions of R\u00c4USCHLING (eg ruesseling in Kintzheim, Alsace, in 1348 and in Trier in 1464) can be a source of confusion. The first mention of the modern Riesling spelling appeared in the Latin edition of Hieronymus Bock\u2019s (1552) Kreutterbuch: \u2018Ad Mosellam, Rhenum et in agro Wormatiensi vites procreantur Riesling appellate\u2019 (\u2018Riesling grows in the Mosel/Rhein and in Worms\u2019), though some sources say it appeared in the 1546 edition of Bock\u2019s book.',
      'Regner et al. (1998) established by DNA profiling that Riesling has a parent\u2013offspring relationship with GOUAIS BLANC, one of the most ancient and prolific wine grapes of Western Europe. Since Gouais Blanc also has parent\u2013offspring relationships with at least eighty other grape varieties (see the pedigree diagram in the PINOT entry), including CHARDONNAY, GAMAY NOIR, ELBLING and FURMINT, they all turn out to be half-siblings, grandparents or grandchildren of Riesling.',
      'Riesling has no genetic relationship with Riesling Italico or Welschriesling (both synonyms of GRA\u0160EVINA) and many other grape varieties have been erroneously named after Riesling: in Australia, Clare Riesling (= CROUCHEN); in South Africa, Cape Riesling (= CROUCHEN); in California, Gray Riesling (= TROUSSEAU) and so on.',
      'Not least because Germans were prolific vine breeders, Riesling has been used in numerous crosses, many of which are cultivated today: AURELIUS, DALKAUER (possibly),EHRENFELSER, GEISENHEIM 318-57, GOLDRIESLING, H\u00d6LDER, MANZONI BIANCO, KERNER, MISKET VARNENSKI, M\u00dcLLER-THURGAU, NORIA, ORANIENSTEINER, OSTEINER, RIESLANER, RIESUS, ROTBERGER, SCHEUREBE and VERITAS.',
      'The red-berried mutation Roter Riesling is discussed at the end of this entry.',
    ],
    'where its grown': [
      'At long last there seems to be some real international enthusiasm for Riesling, not least in the US, where the two biggest wine companies have introduced branded varietals, imported from Germany. This is not a variety that shows much of its mettle in inexpensive versions but a hand-crafted Riesling is generally relatively low in alcohol but high in both acidity and, often, extract. There is rarely any shortage of flavour and aroma in a Riesling \u2013 in fact the bouquet in mature examples can be so strong as to deter some wine drinkers. Because Riesling is so good at expressing the terroir where it is grown, it is difficult to generalize about its aromas and general character. They can be floral, fruity, mineral and/or spicy. One generalization can be made: Riesling makes some of the longest-lived wines in the world, wines that last about as long as a red bordeaux of equivalent quality.',
      'The quintessentially Germanic grape Riesling is not officially allowed in France, except in Alsace, which was of course German once. This region between the Vosges Mountains and the Rhine had 3,382 ha (8,357 acres) of Riesling planted in 2009, a considerable increase on the 787 ha (1,945 acres) planted in 1958, and a total that makes it the most planted variety in the region now that it has so widely displaced Sylvaner (SILVANER). But there are still only three authorized Riesling clones in Alsace \u2013 49, 1089 and 1091 \u2013 compared with more than sixty in Germany. Alsace Riesling has traditionally been chaptalized and vinified dry so has typically been fuller-bodied than German Riesling. Only Vendange Tardive and S\u00e9lection de Grains Nobles Rieslings are sweet. Riesling is a fine medium here for communicating the variation between various limestone, granite and marl sites, and their altitudes and expositions. Recommended producers include Marcel Deiss, Hugel, Josmeyer, Kreydenweiss, Meyer-Fonn\u00e9, Ren\u00e9 Mur\u00e9, Andr\u00e9 Ostertag, Rolly-Gassmann, Domaines Schlumberger, Trimbach, Weinbach and Zind-Humbrecht.',
      'Riesling Italico is much more important in Italy than Riesling and the national total was only 624 ha (1,542 acres) in 2000, mostly in the cooler reaches of the Alto Adige and in Friuli. Kuenhof, Cantina San Michele Appiano, Schwanburg and Tiefenbrunner produce some of the best examples, often a little grassy, in the Alto Adige; Jermann, Puiatti and Villa Russiz in Friuli. Aldo Vajra makes a distinctive 100% Riesling in Piemonte: Pietracine sold as Langhe Bianco.',
      'Spain grew only 97 ha (240 acres) of Riesling in 2009, principally in Catalunya in the north east for wines such as Torres\u2019 Waltraud from Pened\u00e8s, as well as in Castilla-La Mancha.',
      'Riesling is much more important in little Luxembourg, just over the border from the upper Moseltal (Mosel Valley) in Germany after all. The country\u2019s 159 ha (393 acres) represent more than 12% of the total vineyard area. Most sophisticated exponents are Mathis Bastian, Charles Decker, Gales, Alice Hartmann and Ch Pauqu\u00e9. Klaus Peter Keller of Rheinhessen in Germany is involved with a small experimental planting of Riesling in southern Norway.',
      'Germany is epicentre of Riesling lore, and the country with by far the most Riesling in the ground: 22,434 ha (55,436 acres), making it the country\u2019s most planted grape variety. (There was an ignoble period in the history of German wine in the late twentieth century when plantings of the much-easier-to-ripen M\u00dcLLER-THURGAU overtook those of the noble Riesling.) Riesling is grown in every German wine region with almost equal totals in the Pfalz (5,458 ha/13,487 acres) and Mosel-Saar-Ruwer (5,390 ha/13,319 acres) as well as 3,769 ha (9,313 acres) in Rheinhessen, 2,464 ha (6,089 acres) in the Rheingau and 2,083 ha (5,147 acres) in W\u00fcrttemberg. It arguably reaches its apogee, or certainly makes its most distinctive wines, in Germany\u2019s coolest wine region, the Mosel-Saar-Ruwer, where the relatively late-ripening Riesling can be ripened only on the most propitiously sited and exposed slopes, angled so as to catch maximum sunshine, some of it reradiated by slaty soils, some reflected from the surface of the river. Wines produced here are typically only about 8% alcohol with extremely high acidity (which needs time in bottle to soften) yet chock full of extract and character and, usually, with perceptible if delicate fruity sweetness.',
      'There was a time when virtually all German wine had some sweetness, sometimes to disguise shortcomings in the wine itself, but climate change and revived concentration on quality rather than quantity have meant that Germany\u2019s Riesling, especially in southern wine regions, can now be made into perfectly respectable dry, or trocken, wines, with alcohol levels closer to 13% and no help from sugar added to the fermentation vat. Dry wines, particularly popular in Germany itself, have a quite different structure from fruitier ones, but in general Rheingau Rieslings are relatively firm with a lightly honeyed character. Those from the Nahe are nervier, with a certain grapefruit quality in some instances. Rieslings from the extensive Rheinhessen region vary enormously from big, luscious ferments ripened overlooking the Rhein around Nierstein to the tense, vibrant, sometimes nutty vineyard expressions now being produced by a nucleus of younger, ambitious producers in the south of the region. Pfalz Rieslings tend to be almost fat, certainly honeyed and quite full-bodied, while those of W\u00fcrttemberg are light and refreshing, mainly for local quaffing. Franken is SILVANER country and Baden specializes in the various PINOTs but both can produce their own very respectable renditions of this, the quintessential German grape.',
      'Although it is so close to Germany, Switzerland hardly grows Riesling at all \u2013 just 12 ha (30 acres), nearly half of which is in the Valais. Amphitryon by Domaine du Mont d\u2019Or in Sion was the first Valais Riesling and is still today among the best examples.',
      'Austria, on the other hand, grows 1,874 ha (4,631 acres) \u2013 although Riesling routinely plays second fiddle to Austria\u2019s own GR\u00dcNER VELTLINER. The Weinviertel has the most hectares planted to Riesling, 552 ha (1,364 acres), but this represents less than 4% of the region\u2019s vineyards (compared with almost 50% given over to Gr\u00fcner Veltliner), whereas the Wachau\u2019s 198 ha (489 acres) represent 14% of the region\u2019s vines, Kamptal\u2019s 298 ha (736 acres) is 7.5% and Kremstal\u2019s 214 ha (529 acres) is nearly 9.5%. Burgenland\u2019s Riesling is found mainly in Neusiedlersee and is made into impressively concentrated sweet wines. Austrian Riesling is rather more full-bodied than most German Riesling, and often somewhat spicier and less austere than the Rieslings of Alsace. Most of the top Riesling exponents are based on south-facing slopes on the north bank of the Donau (Danube), the likes of Br\u00fcndlmayer, Hirtzberger, Knoll, Loimer, Nigl, Nikolaihof, F X Pichler, Prager, Schloss Gobelsburg, Stadt Krems, Undhof Salomon and Dom\u00e4ne Wachau, but in general the quality level is consistently high.',
      'Hungary grows 1,283 ha (3,170 acres) of Riesling, mainly in the southern central regions of Kuns\u00e1g, Csongr\u00e1d and Tolna, but there are some fine specimens from near Lake Balaton. The top producers are Bussay (Zala), Ott\u00f3 L\u00e9gli (Balatonbogl\u00e1r), J\u00f3zsef Szentesi (P\u00e1zm\u00e1nd) and Villa Tolnay (Badacsony).',
      'There are 1,270 ha (3,138 acres) of Ryzlink R\u00fdnsk\u00fd in the Czech Republic with plantings concentrated in the southern wine regions of Morava in the south east of the country, although Velk\u00e9 \u017dernoseky is also known for its Riesling. In Slovakia, Pezinok and especially Modra within the Malokarpatsk\u00e1 region on the border with Austria have a good reputation for Riesling. There was a total of 998 ha (2,466 acres) of Riesling in 2009. Egon M\u00fcller of the Saar makes the particularly precise Kastiel Bel\u00e1 Riesling near St\u00farovo a little further south and east on the border with Hungary.',
      'Renski Riesling ranks as the tenth most planted variety in Slovenia. It is grown mainly in \u0160tajerska Slovenija (Slovenian Styria; 591 ha/1,460 acres) and Prekmurje (52 ha/128 acres) in the north east. Dveri-Pax make a creditable example.',
      'Riesling was the sixth most planted variety in Croatia in 2009, under the name Rizling Rajnski, with just over 3% of the vineyard area, ie 1,072 ha (2,649 acres).',
      'The Republic of Moldova had 1,343 ha (3,319 acres) of the variety, also known as Rislinoc, in 2009 while Bulgaria\u2019s 2009 total of Risling was 1,170 ha (2,891 acres). Official statistics showed 882 ha (2,179 acres) of Riesling planted in 2009 in Krasnodar Krai, Russia\u2019s biggest winemaking region, but it is not clear which Riesling this is. Fanagoria do have 60 ha (148 acres) of Rhine Riesling and Ukraine had 2,702 ha (6,677 acres) of \u2018Rhine Riesling\u2019 in 2009. Real Riesling is definitely grown in Israel to a very limited extent.',
      'The same problem does not apply to California, where the variety once known as Johannisberg or White Riesling is clearly identified with the Riesling under discussion here. There were 3,831 acres (1,550 ha) of it in California vineyards in 2010, although 300 acres (121 ha) of that were not yet bearing \u2013 a sign of American commercial interest in the variety. Much California Riesling can be rather soft and dilute but Chateau Montelena, Smith Madrone and Stony Hill in Napa Valley; Dashe Cellars, Esterlina and Navarro in Mendocino County; and Ventana Vineyards in Monterey County have all produced delicious and relatively long-lived exceptions to this rule.',
      'Cooler, cloudier Oregon has long had a solid tradition of producing respectable Riesling, lacking only an enthusiastic market for it. The state total of 777 acres (314 ha) provides fruit for the most interested and competent producers such as A to Z, Amity, Argyle, Brooks, Chehalem and Elk Cove.',
      'But on the west coast it is Washington State that is busy styling itself as a Riesling specialist. Thanks to a long-standing co-operation (evinced by the hugely successful Eroica Riesling) between the dominant wine company there, Ch\u00e2teau Ste Michelle, and Erni Loosen of the Mosel, interest in the variety has grown enormously and has been reflected in a similar increase in plantings of Riesling in the state, from 1,900 acres (769 ha) in 1999 to 6,320 acres (2,558 ha) by 2011. CHARDONNAY is still more widely grown in Washington but the state joins the Rheingau and Australia as organizer of a triennial international Riesling event and there are dozens of producers of fine, bright-fruited, extremely crisp Riesling at all sweetness levels.',
      'Ch\u00e2teau Grand Traverse of Michigan is well known for its Riesling.',
      'On the east coast, the Finger Lakes region of New York State, where 683 acres (276 ha) of Riesling were grown in 2008, has a long tradition of producing seriously fine Riesling, at all sweetness levels, even if it has been somewhat ignored during the era of CHARDONNAY mania. Anthony Road, Glenora, Heron Hill and Hermann J Wiemer have all produced truly world-class Rieslings here. Not surprisingly, Riesling can also shine across the border in Ontario, Canada, where it is a highly significant variety and the likes of Norman Hardie and Cave Spring (related by marriage to St Urbans-Hof of the Mosel) have made extremely promising bone-dry Rieslings. Riesling icewines can also shine. The finest (dry) Rieslings of British Columbia (367 acres/149 ha) have so far come from Tantalus.',
      'Riesling is much more at home in the cool north east of North America than in South America. Argentina had only 112 ha (277 acres) planted in 2008, mainly around Mendoza. A handful of determined young winemakers are experimenting with Riesling in Chile so that there were 33 ha (82 acres) in B\u00edo B\u00edo (eg Cono Sur) in the cool far south in 2008 and Cousi\u00f1o Macul have some planted near Santiago. In Uruguay there were just 17 ha (42 acres) in 2009.',
      'Historically Riesling was hugely important to Australia. Silesian immigrants brought it to the Barossa Valley in South Australia in the early nineteenth century and it quickly showed an aptitude for the rather cooler Clare and Eden Valleys nearby. Before the advent of CHARDONNAY, it was the country\u2019s favourite white wine grape and it is still the fifth most planted with a national total of 4,401 ha (10,875 acres) in 2008. This may be dwarfed by Chardonnay\u2019s 31,564 ha (77,996 acres) but Australia can claim to have one of the world\u2019s most distinctive styles of Riesling \u2013 produced in a very much drier, warmer climate than that of any German wine region. Pioneered by the likes of Jeffrey Grosset of Clare Valley (with 862 ha/2,130 acres, home to the greatest concentration of Australian Rieslings), current offerings are some of the driest, steeliest and ambitiously tightly furled in the world, although there are signs of very slight mellowing in this respect. Australia has long made some top-quality sweet Rieslings, too. The most significant and successful regions for Riesling today are the Clare, Eden and Barossa Valleys and Great Southern, though there are also significant plantings in the Adelaide Hills and Padthaway \u2013 and Tasmania shows some potential. Crawford River, Frankland Estate, Grosset, Henschke, Leeuwin, Petaluma, Piper\u2019s Brook, Plantagenet, Tamar Ridge and Yalumba are just some of the most accomplished Riesling producers.',
      'New Zealand is just getting to grips with varieties it calls \u2018aromatics\u2019, of which Riesling is undoubtedly one. The country grows a total of 917 ha (2,666 acres) of Riesling, up from 493 ha (1,218 acres) in 2001 and projected to rise further. Top producers include Dry River, Forrest Estate, Framingham (including some very fine sweet versions), Palliser, Pegasus Bay, Richardson and Seresin.',
      'The cause of Riesling has not been helped in South Africa by the persistence of the term Cape Riesling, and occasionally just Riesling, for the lowly CROUCHEN. Only recently was the term Riesling reserved for the real thing and the requirement that it be called Weisser Riesling abandoned. Total plantings of \u2018real\u2019 Riesling were just 215 ha (531 acres) in 2008 and they are widely scattered, with the biggest areas in Stellenbosch and Robertson. Best wines tend to come from the coolest climes such as Constantia (Buitenverwachting and Klein Constantia), Elgin (Paul Cluver) and Swartberg at 1,000 m altitude in the far east of the Cape winelands (Howard Booysen).',
      'Riesling is also grown in China (378 ha/934 acres in 2009), in the provinces of Shanxi, Gansu and Xinjiang, and there were 7.6 ha (19 acres) in 2009 in Japan.',
    ],
  },
  {
    name: 'RIESUS',
    synonyms: ['Risus', 'Risys', 'Rysus'],
    description:
      'Recent and relatively robust Ukrainian hybrid with Riesling-like aromas.',
    'origin and parentage': [
      'Riesus is a hybrid obtained by P Golodriga, M Kostik and V Yurchenko at the Ampelos private breeding company in Yalta, southern Ukraine, by crossing RIESLING with Roucaneuf, the latter being a Seibel 6468 \u00d7 Sub\u00e9reux hybrid (for the complete pedigrees, see HELIOS for Seibel 6468 and PRIOR for Sub\u00e9reux). Riesus is a contraction of Riesling and ustoichivy, meaning \u2018resistant\u2019 with respect to diseases.',
    ],
    'where its grown': [
      'Riesus presumably grows in its homeland of Ukraine but is also planted in the Rostov and Krasnodar wine regions of Russia, where it is used mainly in blends. Its produce is also distilled. Wines typically have RIESLING-like aromas.',
    ],
  },
  {
    name: 'RIMAVA',
    synonyms: ['CAAB 3/12'],
    description: 'Recently authorized, promising Slovakian cross.',
    'origin and parentage': [
      'Rimava, named after a river in south-eastern Slovakia, is a CASTETS \u00d7 ABOURIOU cross obtained in 1976 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia. It is a sibling of HRON, NITRANKA and V\u00c1H and was officially authorized in 2011.',
    ],
    'where its grown': [
      'Rimava is one of the best Cabernet-like varieties in Slovakia, producing deeply coloured, full-bodied wines with a distinctive Cabernet flavour and good ageing potential (Dorota Posp\u00ed\u0161ilov\u00e1, personal communication). Although there were only around 3 ha (7 acres) in 2011, it shows greatest promise on sloping sites in the best Slovakian wine regions of Ju\u017enoslovensk\u00e1 and Malokarpatsk\u00e1, in the south west of the country. Igor Blaho currently includes it in a red blend.',
    ],
  },
  {
    name: 'RIPOLO',
    synonyms: ['Ripala', 'Ripoli', 'Uva Ripola'],
    description:
      'Makes ageworthy whites on the Amalfi coast in southern Italy.',
    'origin and parentage': [
      'Ripolo used to be cultivated on Monti Lattari, especially in Gragnano, Lettere and Castellamare di Stabia in the province of Napoli in southern Italy. Ripolo is not mentioned in historical documents until the mid nineteenth century. It is genetically close to AGLIANICONE, FALANGHINA FLEGREA and PALLAGRELLO NERO (Costantini et al. 2005).',
    ],
    'where its grown': [
      'Ripolo is cultivated almost exclusively on the Amalfi coast in the province of Salerno, south of Napoli, Italy, in particular in the communes of Amalfi, Furore and Positano, where it is generally trained (often ungrafted) on pergolas. Ripolo may be included in the Costa d\u2019Amalfi DOC, unofficially at the moment. The grapes have high sugar levels and moderate acidity. Varietal wines have scents of exotic fruits, apricot and honey, and with age they tend to develop kerosene aromas similar to those of some mature RIESLING wines. Marisa Cuomo\u2019s Furore Bianco Fiorduva, Costa d\u2019Amalfi DOC, is a dry, barrel-fermented blend of roughly equal proportions of late-harvested Ripolo, FENILE and GINESTRA.',
    ],
  },
  {
    name: 'RITINO',
    synonyms: ['Aretino', 'Reteno', 'Retina', 'Retino', 'Ripno'],
    description: 'Very minor Greek variety used in local blends.',
    'origin and parentage': [
      'Ritino probably comes from the Greek Aegean Islands.',
    ],
    'where its grown': [
      'Ritino is found to a very limited extent on the Greek islands of \u00c9vvoia (Euboea), Ikar\u00eda, S\u00e1mos and the Spor\u00e1des in the Aegean Sea and in Magnes\u00eda. The S\u00e1mos Co-operative include it in a ros\u00e9 blend with FOKIANO and the Ikarian Winery include it in a red blend with other local varieties.',
    ],
  },
  {
    name: 'RIVAIRENC',
    synonyms: [
      'Aspiran',
      'Aspiran Noir',
      '\u00c9piran',
      'Esperan',
      'Espiran',
      'Peyral',
      'Peyrar',
      'Piran',
      'Ribeyrenc',
      'Riverain',
      'Riveirenc Noir',
      'Riveyrenc',
      'Riveyrene',
      'Spiran',
      'Verdai',
      'Verdal',
    ],
    description:
      'Ancient, once widespread, nearly extinct southern French vine. Better known as Aspiran Noir.',
    'origin and parentage': [
      'Under its old synonym Esperan, this variety may well have been mentioned for the first time some years before 1544 in the \u2018Chant de vendanges\u2019 (\u2018harvest song\u2019) by Bonaventure des P\u00e9riers (R\u00e9zeau 1997): \u2018La Gabbie/J\u00e0 rougie/Du sang des bruns esperans\u2019 (\u2018The wine press/Already reddened/By the blood of the brown esperans\u2019). It was later mentioned under the name Espiran in 1676 by Magnol in his Botanicum Monspeliense: \u2018Inter nigras [vites] vulg\u00f2 comendatur espiran, quae tum ad esum, tum ad vinum optima est\u2019 (\u2018Among the widespread black [varieties] is recommended espiran, which is excellent as food as well as wine\u2019). Several additional citations were found in the eighteenth and nineteenth centuries under the variety\u2019s historical name Aspiran Noir in the region between N\u00eemes and Montpellier, southern France, which can be considered the birthplace of this variety, not least because several colour mutations were found in this area: Aspiran Blanc (white), Aspiran Gris (grey), Aspiran Rouge Fonc\u00e9 (red) and Aspiran Verdal (pink), although these are no longer found.',
      'The etymology of Aspiran is unknown but it is unlikely that this variety was named after the village of Aspiran in the H\u00e9rault in southern France, because it has never been cultivated there. Recently, it was included in the official French register of varieties as Rivairenc (along with Rivairenc Gris and Rivairenc Blanc), surprisingly using the name local to the Aude d\u00e9partement rather than the name Aspiran Noir, formerly widespread in the Gard and the H\u00e9rault. The name Rivairenc probably derives from the Occitan ribairenc meaning \u2018riparian\u2019, riverain in French, which is one of its synonyms, suggesting that this variety was found on river banks.',
      'DNA parentage analysis based on twenty-three DNA markers suggests a possible parent\u2013offspring relationship between Rivairenc and CINSAUT (Vouillamoz, to be confirmed with more DNA markers), which is consistent with Rivairenc belonging to the Piquepoul ampelographic group (see p XXVII; Bisson 2009). According to Myles et al. (2011), the reference sample of Rivairenc (under the name Aspiran) in the United States Department of Agriculture\u2019s National Clonal Germplasm Repository could be identical to the American table grape Queen but this can be rejected by comparison with other DNA profiles (Vouillamoz).',
      'Aspiran Noir was used to breed ASPIRAN BOUSCHET.',
    ],
    'where its grown': [
      'Widely planted in the H\u00e9rault and the Gard in southern France under the name Aspiran Noir before phylloxera struck the region in the late nineteenth century, it is now very hard to find even though it and the grey and white colour mutations are included in the official French register of varieties. Some vines found their way to Algeria. Wines used to be pretty light in every way but they generally went into blends. Thierry Navarre in Saint-Chinian may be the sole producer of a varietal wine but Clos des Centeilles include 10% Riveirenc (sic) Noir with 90% PIQUEPOUL NOIR in their Minervois C de Centeilles red, and their white C de Centeilles (C\u00f4tes du Brian) includes both Riveirenc Gris and Blanc with other local varieties. Rivairenc may constitute up to 10% of the blend in the red and ros\u00e9 versions of Minervois though it is now extremely rare for it so to do.',
    ],
  },
  {
    name: 'RKATSITELI',
    synonyms: [
      'Baiyu',
      'Corolioc',
      'Dedali Rcatiteli',
      'Mamali Rcatiteli',
      'Rkatiteli',
      'Rkatziteli',
      'Topolioc',
    ],
    description:
      'Extremely useful, versatile, widely planted, originally Georgian variety.',
    'origin and parentage': [
      'Rkatsiteli is one of the most ancient grape varieties in Georgia. Its name reflects the appearance of the vine, combining rka, meaning \u2018vine shoot\u2019, and tsiteli, meaning \u2018red\u2019. Recent DNA studies have shown that it is genetically very close to local wild grapevines (Ekhvaia et al. 2010), suggesting a possible direct domestication that allegedly took place centuries ago in the region of the Lekhura gorge. However, very few DNA markers were used and a much more detailed study is needed to confirm this hypothesis.',
      'Rkatsiteli was used to breed KANGUN, PERVENETS MAGARACHA and PODAROK MAGARACHA.',
    ],
    'where its grown': [
      'Rkatsiteli is by far the most widely planted variety of either colour in Georgia (19,503 ha/48,193 acres in 2004, considerably less than before Gorbachev\u2019s vine-pull scheme), particularly throughout Kakheti in the south east of the country, with some of the best wines coming from the villages of Kardenakhi and Tibaani. It is used not only for decent table wines in appellations such as Tsinandali, Gurjaani and Vazisubani (each one allowing up to 15% MTSVANE KAKHURI in the blend) but also for fortified wines in hotter parts of Kakheti such as Kardenakhi. Recommended producers of more traditional style wines, vinified on the skins in clay qvevri and often amber in colour with apple-skin flavours, include Alaverdi Monastery, Pheasant\u2019s Tears and Vinoterra. European styles, which tend to be lightly floral or with stone-fruit, apple or quince flavours, crisp acidity and lots of fruit, are made by the likes of Badagoni, Chateau Mukhrani, Schuchman, Tbilvino, Telavi Wine Cellar and Teliani Valley.',
      'Rkatsiteli is very popular in most of the ex-Soviet states, being valued for its productivity, cold hardiness and ability to retain acidity \u2013 and the fact that the grapes can withstand transport well. It is the most planted variety in Ukraine with the sizeable total area of 11,552 ha (28,546 acres) in 2009 and the Republic of Moldova recorded a total of 11,508 ha (28,437 acres) in 2009. It is also planted in Russia but to a more limited extent (569 ha/1,406 acres in Rosotov in 2010 and 133 ha/329 acres in Krasnodar Krai in 2010) and elsewhere in Eastern Europe. During the Soviet era, Rkatsiteli was the most widely planted grape in Armenia but plantings had fallen to around 7% of the vineyard area by the end of the twentieth century. Yet it is still the most widely planted white variety in Bulgaria (12,631 ha/31,212 acres in 2009) and is also found in Romania (354 ha/875 acres in 2008).',
      'Konstantin Frank introduced the variety to New York State\u2019s Finger Lakes while Horton did the same in Virginia and there are tiny plantings in other American states.',
      'Under the name Baiyu it was introduced to China in 1956 and has adapted well there. Today it is widely grown in northern China, as well as in Shandong, Jiangsu and the former Yellow River region of Anhui province. Chinese winemakers use it for sweet, dry and sparkling wines as well as for distillation.',
    ],
  },
  {
    name: 'ROBOLA',
    synonyms: [
      'Asporombola',
      'Asprorobola',
      'Asprorompola',
      'Robbola',
      'Robola Aspri',
      'Robola Aspro',
      'Robola Kerini',
      'Robolla',
      'Rombola',
      'Rombola Aspri',
      'Rompola',
      'Rompola Kerine',
    ],
    description:
      'High-quality Greek island white producing elegant, sometimes powerful, dry lemony wines.',
    'origin and parentage': [
      'The name Robola has been used to refer to a wine made of black, red or white grapes and also to several distinct varieties, especially the light-skinned GOUSTOLIDI and the dark-skinned THEIAKO MAVRO (Boutaris 2000). This account describes the white-berried Robola that comes from either the Ionian Islands off Greece\u2019s western coast or from the Pelop\u00f3nnisos (Peloponnese).',
      'Robola was for many years presumed to be related to Friuli\u2019s RIBOLLA GIALLA. It was argued that the vine had been transported from Italy to Kefaloni\u00e1 by the Venetians in the thirteenth century (Manessis 2000; Nikolau and Michos 2004). However, recent DNA profiling has shown that Robola (under the names Robola Aspri or Robolla) is not related to Ribolla Gialla (Sladonja et al. 2007; \u0160tajner et al. 2008; Vouillamoz) but it is genetically very close to Greece\u2019s GOUSTOLIDI and THEIAKO MAVRO (Biniari and Stavrakakis 2007).',
      'The pink-berried Rombola Kokkino and the black-berried Mavro Rombola are considered to be colour mutations of Robola (Nikolau and Michos 2004).',
    ],
    'where its grown': [
      'Robola, grown mainly on the Ionian Islands, Greece, particularly on Kefaloni\u00e1, Z\u00e1kynthos, K\u00e9rkyra (Corfu) and Lefk\u00e1da, is the most highly regarded white in the region and the third most planted variety (276 ha/682 acres in 2008). It produces wines that are fresh, lightly aromatic and citrus-flavoured \u2013 lime, lemon or grapefruit \u2013 though the variety is prone to oxidation and needs careful handling during vinification. The timing of the harvest is critical to retain acidity and also to maintaining Robola\u2019s depth of flavour. The best wines, typically from vines at altitudes above 300 m (984 ft), can be quite powerful and have a fine chalky texture and a marked persistence. Such wines are most often encountered within the Robola of Kefaloni\u00e1 appellation, which is for certain communes in the south of the island and vineyards above 50 m, but the variety is now being planted further afield, eg in Arkad\u00eda in the central Pelop\u00f3nnisos. Recommended producers include Domaine Foivos, Divino, Gentilini, who have vines planted in the late 1950s after the 1956 earthquake, the Robola Co-operative and Vassilakis.',
    ],
  },
  {
    name: 'RODITIS',
    synonyms: [
      'Alepou Roditis',
      'Arilogos Roditis',
      'Arsenikos Roditis',
      'Kanellato',
      'Kritsanisti',
      'Lisitsines',
      'Litsitsines',
      'Rhodites',
      'Rhoditi',
      'Rhoditis',
      'Rodea Stafyli',
      'Rodites',
      'Roditi',
      'Roditis Alepou',
      'Roditis Kanellatos',
      'Roditis Ros\u00e9',
      'Rodomoussi',
      'Rogdites',
      'Rogditis',
      'Roidites',
      'Roiditis',
      'Ro\u00efdo',
      'Roigditis',
      'Sourviotes',
      'Sourviotis',
      'Thilikos Roditis',
    ],
    description:
      'Very widely planted Greek variety with many clonal variations and generally low ambitions.',
    'origin and parentage': [
      'The name Roditis, probably derived from the island of R\u00f3dos (Rhodes; Lefort and Roubelakis-Angelakis 2001) rather than from the colour of the berries (rodon means \u2018rose\u2019; Manessis 2000), is given to at least three distinct varieties cultivated throughout Greece, each with a distinct berry colour and DNA profile: Roditis (pink), discussed here, Roditis Lefkos (white and no longer cultivated), also called Kolokythas Lefkos, and Roditis Kokkinos (red), which is a synonym for TOURKOPOULA (Lefort and Roubelakis-Angelakis 2001; Greek Vitis Database).',
    ],
    'where its grown': [
      'There is considerable confusion over this old variety, deepened by the fact that many Roditis vineyards in Greece are interplanted with what are said to be several different clones. For example, most growers believe that Roditis Alepou (alepou meaning \u2018fox\u2019 and referring to the colour of the berries), once far more common in the northern Pelop\u00f3nnisos (Peloponnese) than it is now, is a superior clone of Roditis, with lower yields of more deeply coloured, more aromatic, smaller berries. It also ripens up to two weeks earlier than other clones. However, without DNA analysis, there is as yet no evidence to prove that it is not a distinct variety. According to viticulturist Haroula Spinthiropoulou, Roditis is very susceptible to viruses, especially closteroviruses, which alter the colour of the berries, and many of the clones are the result of adaptation to different climatic conditions.',
      'Most wines labelled Roditis are therefore made from a field blend. The best examples are full-bodied with melon and apple aromas, a sour-citrus freshness and moderate acidity. They are not designed for ageing. The most common, pink-berried clone is both vigorous and productive, especially in more fertile soils and on lower sites, resulting in wines that are neutral and lack concentration or are used in the production of retsina.',
      'Producers in P\u00e1tra in the northern Pelop\u00f3nnisos, notably Antonopoulos, Oenoforos and Parparoussis, seem to be paying most attention to the Roditis Alepou clone, although Skouras further south is also investing in this clone, preferring its aromatic spiciness and its greater longevity. Two other clones considered worthy of greater investment are the Kanellatos (\u2018cinammon\u2019) clone and Roditis from M\u00e9gara in Attik\u00ed (Lazarakis 2005). Oenoforos have been particularly active in trying to identify and select the various clones.',
      'Roditis is planted in many areas of mainland Greece and the Pelop\u00f3nnisos, from Thr\u00e1ki (Thrace) in the north east to Lakon\u00eda in the south (Lazarakis 2005). Varietal wines qualify for the P\u00e1tra appellation, where the variety is particularly significant; the Anhialos appellation in Thessal\u00eda requires a 50/50 blend of Roditis and SAVATIANO; and the C\u00f4tes de Meliton appellation in Halkidik\u00ed permits a blend of 35% Roditis with 50% ATHIRI and 15% ASSYRTIKO.',
      'Also important in the make-up of many regional wines, Roditis was the second most planted white variety in Greece in 2008 (after SAVATIANO), with a total of 9,743 ha (24,075 acres), including 5,383 ha (13,302 acres) in western Greece and 1,407 ha (3,477 acres) in the Pelop\u00f3nnisos. When yields are limited and sites are cooler, wines show much better concentration and weight at around 13.5% alcohol. Recommended producers include Babatzim (Kentrik\u00ed Makedon\u00eda/Central Macedonia), Katogi Averoff (Pelop\u00f3nnisos), Kir-Yianni (N\u00e1oussa) and Mercouri (western Pelop\u00f3nnisos).',
    ],
  },
  {
    name: 'ROLLO',
    synonyms: [
      'Bruciapagliaio',
      'Capello',
      'Livornese Bianca',
      'Occhiana',
      'Pagadebiti',
      'Rollo Genovese',
    ],
    description:
      'Rare, old Ligurian (possibly Tuscan?) with a confusing array of synonyms.',
    'origin and parentage': [
      'Rollo is an old variety of Liguria in north-west Italy that was often confused with VERMENTINO, also called Rolle or Roll\u00e9 in the Alpes-Maritimes and the Var in southern France. DNA profiling has recently shown that Rollo is distinct from Vermentino and that it is identical to:',
      '\u2013\u00a0\u00a0\u00a0Bruciapagliaio (Torello Marinoni, Raimondi, Mannini and Rolle 2009), an old and rare Ligurian',
      '\u2013\u00a0\u00a0\u00a0Livornese Bianca (Di Vecchi Staraz, This et al. 2007; Torello Marinoni, Raimondi, Mannini and Rolle 2009), a very minor blend component used occasionally in Toscana, central Italy',
      '\u2013\u00a0\u00a0\u00a0Occhiana (Di Vecchi Staraz, This et al. 2007), found in the region of Gavorrano in the province of Grossetto in Toscana',
      '\u2013\u00a0\u00a0\u00a0Pagadebiti from Corse (Di Vecchi Staraz, This et al. 2007; Torello Marinoni, Raimondi, Mannini and Rolle 2009) \u2013 this identity was already known to ampelographers.',
      'It should be noted that Di Vecchi Staraz, This et al. (2007) consider Biancone from Corse to be a synonym for Rollo but its DNA profile is distinct from that shown for Pagadebiti in the same study and that given for Rollo in Torello Marinoni, Raimondi, Mannini and Rolle (2009). A more detailed DNA profiling study of Biancone and BIANCONE DI PORTOFERRAIO from the Tuscan island of Elba is therefore necessary to determine whether they are identical.',
      'In addition, comparison of the DNA profiles of Rollo and MAMMOLO from Toscana (also found on the French island of Corse under the name Sciaccarello) suggests a possible parent\u2013offspring relationship (Vouillamoz). This hypothesis should be further investigated to verify a possible Tuscan origin for Rollo.',
    ],
    'where its grown': [
      'Rollo is a tentacular variety cultivated under several names in various places, although the exact identity of the varieties is often uncertain.',
      'ROLLO Within Liguria, along the coast of north-west Italy, Rollo is cultivated mainly in the province of Genova and in the Riviera Ligure di Ponente and virtually absent from the Riviera di Levante and the province of Savona. It is usually blended with varieties such as ALBAROLA, BOSCO and TREBBIANO TOSCANO. It may be part of the blend with VERMENTINO and Albarola (Bianchetta Genovese), among others, in the Val Polcevera DOC, as in Bruzzone\u2019s Coronata della Val Polcevera (Coronata is a subzone of the DOC). The Italian agricultural census recorded a total of 114 ha (277 acres) in 2000.',
      'BRUCIAPAGLIAIO Bruciapagliaio is cultivated in the Cinque Terre, mainly in Val Polcevera in the province of Genova, Italy, and in Val Graveglia in the Riviera di Levante, though it does not appear by name in the census. Bruciapagliaio may be a minor part of the blend in the famous and expensive sweet passito-style Cinque Terre Sciacchetr\u00e0 DOC, which is predominantly BOSCO, ALBAROLA and VERMENTINO.',
      'LIVORNESE BIANCA Even though the name Livornese Bianca suggests a link to the port of Livorno in the far west of Toscana, Italy, the variety is cultivated mainly in the province of Massa-Carrara, further north towards La Spezia. The agricultural census of 2000 recorded just 11 ha (27 acres) planted, in particular in Versilia in the province of Lucca and in the historical region of Lunigiana, which straddles the provinces of La Spezia and Massa-Carrara. Livornese Bianca may make a minor contribution to the blend in the Colli di Luni DOC and in the Toscana and Val di Magra IGTs. Wines made from this variety tend to be low in alcohol.',
      'PAGADEBITI Pagadebiti is a confusing name applied to several distinct varieties. Its literal meaning is \u2018paying the debts\u2019. On the island of Corse, France, Pagadebiti usually corresponds to Rollo, but also sometimes to BIANCONE DI PORTOFERRAIO. It was once much more widespread but today it is cultivated on just a few hectares near Porto Vecchio, Figari and Sart\u00e8ne.',
    ],
  },
  {
    name: 'ROM\u00c9',
    synonyms: ['Rom\u00e9 de Motril', 'Rom\u00e9 Negro'],
    description:
      'Rare local M\u00e1laga speciality, possibly better at ros\u00e9s than reds.',
    'origin and parentage': [
      'Rom\u00e9, considered to be indigenous to M\u00e1laga in south-west Spain, was mentioned by Clemente y Rubio (1807) in Motril in the province of Granada in Andaluc\u00eda.',
    ],
    'where its grown': [
      'Rom\u00e9 is grown almost exclusively in the Axarqu\u00eda region east of M\u00e1laga. Bodegas Bentomiz, who include the variety in their Ariyanas red blend, report that it contributes red fruit, and also some floral aromas and vanilla, and a creaminess in the mouth. They are also planning to experiment with a varietal ros\u00e9 since the wine lacks colour and body and is relatively light on its own. Many locals use it for home wine production.',
    ],
  },
  {
    name: 'ROMEIKO',
    synonyms: ['Lo\u00efssima', 'Romeico', 'Romeiko Mavro', 'Tsardana'],
    description:
      'Greek island variety that has been important on Kr\u00edti (Crete).',
    'origin and parentage': [
      'It is not known whether Romeiko comes from the Aegean Islands or from Kr\u00edti, where it has traditionally been cultivated under the name Tsardana (Lefort and Roubelakis-Angelakis 2001). It should not be confused with Romeiko Machaira (no longer cultivated), which has larger berries and a distinct DNA profile (Lefort and Roubelakis-Angelakis 2001). The name Romeiko possibly derives from the Turkish word romios or romioi used to designate the Greeks (Boutaris 2000; Lefort and Roubelakis-Angelakis 2001).',
    ],
    'where its grown': [
      'Romeiko is grownly mainly in western Kr\u00edti, Greece, in the Chani\u00e1 region, where it once comprised more than 80% of the vineyard area, and to a lesser extent in R\u00e9thymno. There were still almost 1,000 ha (2,470 acres) on the island in 2008. In the local Kissamos regional wine, for example, it is authorized for white wines (up to 70% of the blend and an unusual phenolic style, according to Lazarakis 2005), ros\u00e9 (up to 60%) and reds (up to 60%), often blended with international rather than local varieties. Wines are generally high in alcohol, lack freshness and the colour quickly browns but this may be partly due to the overly high yields and lack of clonal selection. According to Lazarakis (2005), in the past Romeiko was used to make wines known as marouvas, mostly for home consumption: very ripe grapes finished fermenting in large barrels, where they were aged for four years without topping up, resulting in wines that were fierce but complex and drunk at the end of a meal. Today the variety seems out of kilter with modern wine styles.',
      'Although Romeiko and Tsardana have been shown to be synonyms, the official Greek list of varieties for winemaking includes them as separate varieties.',
    ],
  },
  {
    name: 'ROMERO DE H\u00cdJAR',
    synonyms: ['Romero'],
    description: 'Potentially interesting but very rare Aragonese speciality.',
    'origin and parentage': [
      'Romero de H\u00edjar is an obscure autochthonous variety named after the area of H\u00edjar in the province of Teruel in Arag\u00f3n, northern Spain, for which little information is available.',
    ],
    'where its grown': [
      'Apparently only one producer, Fandos y Barriuso in Bajo Arag\u00f3n, Spain, makes this commercially, producing about 1,250 bottles a year. They age it in barrel and describe it thus: \u2018It has an intense bouquet of black fruits, spices, brambles and damp soil. Fresh and flavourful in the mouth, somewhat fleshy with hints of liquorice and good acidity\u2019. Sounds promising.',
    ],
  },
  {
    name: 'ROMORANTIN',
    synonyms: [
      'Bury',
      'Dameret Blanc',
      'Damery',
      'Dannery',
      'Dannezy',
      'Maclon',
      'Petit Dannezy',
    ],
    description:
      'Minor and difficult to ripen Burgundian variety now exclusive to Cour-Cheverny in the Loire.',
    'origin and parentage': [
      'Romorantin is today cultivated exclusively in the Val de Loire but, like at least twenty other varieties, it is a PINOT \u00d7 GOUAIS BLANC cross born in north-eastern France (Bowers et al. 1999; Boursiquot et al. 2004; see PINOT pedigree diagram). In fact, more accurate DNA parentage analyses have suggested that the father is not exactly PINOT NOIR but its colour mutation PINOT TEINTURIER (Bowers et al. 2000; Franks et al. 2002).',
      'The earliest mention of Romorantin appeared in 1868 (R\u00e9zeau 1997): \u2018on cultive, dans les arrondissements de Blois et de Romorantin .\u00a0.\u00a0. en c\u00e9pages blancs, le romorantin ou gros pineau blanc de la Loire, le chenin, l\u2019orbois, arbois ou meslier du G\u00e2tinais\u2019 (\u2018we cultivate in the arrondissements of Blois and Romorantin .\u00a0.\u00a0. as white grape varieties romorantin.\u00a0.\u00a0.\u2019 etc.). It was named after the village of Romorantin (part of today\u2019s Romorantin-Lanthenay) in the Loir-et-Cher, where Fran\u00e7ois I of France owned a residence and had supposedly ordered 80,000 plants of this vine from Burgundy in 1519, but there is no historical evidence for this introduction of Romorantin to the region. According to Mouillefert (1903), local tradition in the Loir-et-Cher has it that Romorantin was introduced around 1830 by an unknown vine grower in Villefranchesur-Cher, whence it spread to Romorantin and then to the whole of the Loir-et-Cher. In any case, the Burgundian Romorantin, like MELON in Muscadet, won its spurs in the Loire. In spite of its parentage, Romorantin is considered by Bisson (2009) to be a member of the Cot ampelographic group (see p XXVII). Since it is also ampelographically close to Melon (Bisson 2009), classified among the Noirien ampelographic group based on PINOT, Romorantin\u2019s ampelographic position might change in the light of further DNA discoveries.',
    ],
    'where its grown': [
      'Romorantin has its own appellation, Cour-Cheverny, just west of Blois in the eastern Val de Loire, France, created in 1993. Once more widely cultivated, it was planted on a grand total of 73 ha (180 acres) in 2008, pretty much exclusive to this area. The wines range from dry and rather sharp to rich, quite broad-flavoured and yet still intensely fresh. It is hard to get the grapes fully ripe in some years and this is its major disadvantage since the acidity is then way too high. Examples from Thierry Puzelat and from Henri Marionnet at Domaine de la Charmoise suggest that wines from old, ungrafted vines seem to have much more character and depth. Other producers of varietal wines include Herv\u00e9 Villemade at Domaine du Moulin, Michel Gendrier at Domaine des Huards, Domaine Fran\u00e7ois Cazin and Franz Saumon. Cazin\u2019s Cuv\u00e9e Renaissance is a late-harvested, botrytized, off-dry version.',
    ],
  },
  {
    name: 'RONDO',
    synonyms: ['Geisenheim 6494-5'],
    description:
      'German red-fleshed hybrid with a complicated genealogy well suited to cool climes makes light, fruity reds.',
    'origin and parentage': [
      'A hybrid obtained by Helmut Becker in 1964 at the Geisenheim research centre in the Rheingau, Germany, by crossing Zarya Severa \u00d7 SANKT LAURENT, where Zarya Severa is a Seyanets Malengra \u00d7 Vitis amurensis hybrid, and Seyanets Malengra a seedling of PR\u00c9COCE DE MALINGRE. Rondo was used to breed ALLEGRO.',
    ],
    'where its grown': [
      'Rondo was authorized in Germany in 1999 but plantings are now more numerous in England, where there are 39 ha (96 acres). Although Rondo represents less than 4% of the vineyard area, it is still the third most planted red wine variety in England. Yearlstone and Wroxeter, for example, both make varietal wines, as do Sch\u00f6nhals in Baden, southern Germany.',
      'It is also found in other cool extremes of the wine world such as Belgium, Poland and the Netherlands. Rondo is the most widely planted variety in Denmark (20 ha/49 acres in 2010) and one of the most successful varieties in Sweden. Although it was bred to include certain Vitis amurensis genes to give it better resistance to cold winters, the light red wines it produces are sufficiently similar to the produce of vinifera vines for it to be officially registered as a vinifera variety so that it can be used in the production of quality wines. Experience in England shows that it responds well to a certain amount of oak, preferably American, although its wine may not be as good as REGENT\u2019s.',
    ],
  },
  {
    name: 'ROS\u00c9 DU VAR',
    synonyms: ['Barbaroux', 'Grec Rose', 'Roussanne du Var'],
    description:
      'Dull and understandably declining pink-skinned Proven\u00e7al variety.',
    'origin and parentage': [
      'As its name suggests, Ros\u00e9 du Var comes from the Var d\u00e9partement of Provence in southern France but, despite its synonym Roussanne du Var, has nothing to do with the ROUSSANNE from the northern Rh\u00f4ne. DNA parentage analysis has shown that Ros\u00e9 du Var is one of the numerous progenies of GOUAIS BLANC (Boursiquot et al. 2004; see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'Wines are vinified white or very pale pink, generally dull and low in alcohol, and sometimes included in ros\u00e9 blends. This lack of potential quality and its exclusion from the C\u00f4tes de Provence appellation in southern France in 1986 probably account for the variety\u2019s continuing decline, down from 1,557 ha (3,847 acres) in 1968 to 65 ha (161 acres) in 2008. Domaine de Sauvecanne in the Var is one of the few producers to make a varietal Ros\u00e9 du Var, a ros\u00e9 classified as Vin de Pays des Maures.',
    ],
  },
  {
    name: 'ROSE HONEY',
    synonyms: ['Honey', 'Red'],
    description:
      'As yet unidentified minor variety found in China\u2019s Yunnan province.',
    'origin and parentage': [
      'According to an unverified online source (www.cnwinenews.com), Rose Honey was imported into China in 1965 by Dongfeng Farm in Mile county, Yunnan province, southern central China, along with ten other grape varieties, including Yunnan\u2019s two other curiosities, FRENCH WILD and CRYSTAL. Since DNA profiling has not yet been done, it is not known whether Rose Honey is related to European varieties. According to Sun (2004), it is a Vitis vinifera \u00d7 Vitis labrusca hybrid more suitable for eating and processing than for wine.',
    ],
    'where its grown': [
      'Along with FRENCH WILD and CRYSTAL, Rose Honey is widely planted in Yunnan, southern China, and is one of the main red varieties grown by the Yunnanhong (Yunnan Red) Wine Company. Wines tend to be ruby-coloured and full-bodied and have the sweet foxy flavours typical of wines made from labrusca vines or their hybrids. According to Chinese statistics, Honey or Rose Honey is also grown in Sichuan (60 ha/148 acres), immediately north of Yunnan, and in Jilin province (112 ha/277 acres) in the north east.',
    ],
  },
  {
    name: 'ROSETTA',
    synonyms: ['Freiburg 991-60'],
    description: 'Red-skinned German hybrid used mainly for the table.',
    'origin and parentage': [
      'A VILLARD BLANC \u00d7 SIEGERREBE hybrid created in 1960 at the Freiburg research centre in Baden-W\u00fcrttemberg, southern Germany, by Johannes Zimmermann.',
    ],
    'where its grown': [
      'Although Rosetta is used mainly as a table grape, Dale Valley in Iowa make a varietal wine.',
    ],
  },
  {
    name: 'ROSETTE',
    synonyms: ['Francuz Seibel', 'Seibel 1000'],
    description: 'Ard\u00e8che hybrid still planted in New York State.',
    'origin and parentage': [
      'A complex hybrid obtained at the beginning of the twentieth century by Albert Seibel in Aubenas, southern France, by crossing Vitis rupestris \u00d7 Munson (see PRIOR for the complete pedigree of Munson).',
    ],
    'where its grown': [
      'Widely planted in France prior to 1960 but eliminated from French vineyards by 1983 (Galet 2000), it was one of the first French hybrids to be grown in New York State, now surviving in just a few pockets (total 10 acres/4 ha) in the Finger Lakes region, where it has been largely ousted by vinifera varieties but is still used by producers such as Glenora and Fulkerson in light reds and ros\u00e9 blends. Aracadian Estate make a varietal blush wine.',
      'It is unrelated to the sweet-wine Rosette appellation just north of Bergerac in south-west France.',
    ],
  },
  {
    name: 'ROSSARA TRENTINA',
    synonyms: [
      'Geschlafene',
      'Ross Ci\u00e0r',
      'Rossar',
      'Rossera',
      'Varenzasca',
    ],
    description: 'Trentino interrelated rarity.',
    'origin and parentage': [
      'As its name suggests, Rossara Trentina most probably originates from Trentino in Italy\u2019s far north. The name Rossara refers to the clear red colour of its berries and its must. DNA profiling has established that Rossara Trentina is distinct from the variety named Rossara (or Ross\u00e9ra, Rossana), today almost extinct in Reggio Emilia (Boccacci et al. 2005), but identical to Varenzasca in Piemonte (Anna Schneider and Jos\u00e9 Vouillamoz, unpublished data). It has a possible genetic relationship with SGAVETTA from Reggio Emilia (Boccacci et al. 2005) and DNA parentage studies have also revealed that Rossara Trentina is a likely progeny of GOUAIS BLANC (Vouillamoz; see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'Rossara Trentina is grown mainly on the Rotaliano plain north of the city of Trento in the far north of Italy, where it has traditionally been planted alongside TEROLDEGO and NEGRARA TRENTINA, with which it is often blended. The Italian agricultural census recorded 34 ha (84 acres) in 2000.',
      'One of the very few varietal wines is produced by Zeni and classfied as an IGT Vigneti delle Dolomiti. They describe it as fresh and perfumed with apple-like acidity.',
    ],
  },
  {
    name: 'ROSSETTO',
    synonyms: ['Greco di Velletri', 'Greco Giallo', 'Greco Verde', 'Roscetto'],
    description:
      'Rare Lazio vine only recently showing its potential for quality.',
    'origin and parentage': [
      'Although this variety\u2019s berries are essentially white, it is named Rossetto because they turn pinky red (Latin russum) when fully ripe. A similarity between Rossetto and TREBBIANO GIALLO has been suggested (Giavedoni and Gily 2005). Recent ampelographic and DNA studies have shown that although Rossetto is also known locally as Greco, Greco Giallo or Greco di Velletri, its DNA profile is quite distinct from those of the Campanian GRECO and the Calabrian GRECO BIANCO (Muganu et al. 2009).',
    ],
    'where its grown': [
      'Historically cultivated between Lazio and Umbria in Italy, in particular in the regions of Castelli Romani and between Orvieto and the Lago di Bolsena. It was close to extinction in the 1960s but had never been very popular with farmers because of its low yields. It continued to be used in very small amounts (up to 15%) blended with TREBBIANO TOSCANO and MALVASIA BIANCA LUNGA in Est! Est!! Est!!! di Montefiascone. To avoid the pinkish colour, growers have traditionally picked the grapes prematurely, thus sacrificing the depth of complex citrus fruit flavours that it can produce. A varietal Rossetto is authorized within the Colli Etruschi Viterbesi DOC in Lazio.',
      'Oenologists Ricardo and Renzo Cottarella have been influential in promoting this variety and showing its real potential from their vineyards in Montefiascone in Lazio but they found they had to flash freeze the grapes to release the yellow pigment from the skins into the must. Their oaked Falesco Ferentano is a rich but very well-balanced varietal wine, reminiscent of a top-notch Saint-Aubin.',
    ],
  },
  {
    name: 'ROSSIGNOLA',
    synonyms: ['Rossetta', 'Rossetta di Montagna', 'Rossola'],
    description:
      'Minor and rather tart variety used to a limited extent in Veneto blends.',
    'origin and parentage': [
      'The earliest mention of Rossignola as an autochthonous variety of the province of Verona was in 1818 (Pollini 1824). Although Rossignola has synonyms such as Rossetta, Rossetta di Montagna or Rossola, it should not be confused with Rosetta di Montagna, an old variety once grown near the Lago di Garda but no longer cultivated commercially, nor with ROSSOLA NERA from Valtellina.',
    ],
    'where its grown': [
      'Rossignola\u2019s grapes and therefore wine tend to be high in acidity. It is grown in Valpolicella as well as in the Lago di Garda area, where a small amount may be blended with other local varieties in the Bardolino, Breganze Rosso, Garda Orientale, Valdadige and Valpolicella DOCs. It is also cultivated to a much more limited extent in the province of Vicenza. Total plantings in Italy in 2000 were 341 ha (843 acres).',
    ],
  },
  {
    name: 'ROSSOLA NERA',
    synonyms: ['Rossera', 'Rossola', 'Rossolo'],
    description: 'Minor, high-acid Valtellina vine.',
    'origin and parentage': [
      'Rossola Nera was already mentioned in Valtellina in the early seventeenth century in a Latin text under the name Rossoladure (Bongiolatti 1996). The variety\u2019s name derives from the pink-red (Italian rosso) colour of the berry at maturity. ROSSOLINO NERO is usually taken to be the most widespread clonal variation of Rossola Nera (Bongiolatti 1996; Fossati et al. 2001) but DNA profiling has established that they are distinct varieties, linked by a parent\u2013offspring relationship (Vouillamoz). In addition, Rossola Nera has been shown to have a parent\u2013offspring relationship with NEBBIOLO (Schneider, Boccacci et al. 2004). Chronologically, it makes sense to consider Rossola Nera to be an offspring of Nebbiolo and a parent of Rossolino Nero.',
    ],
    'where its grown': [
      'Rossola Nera is cultivated all over Valtellina in the far north of Italy close to the Swiss border, where it is usually blended (5\u201325%) with other local varieties, mainly NEBBIOLO, known here as Chiavennasca. Total plantings in Italy in 2000 were 115 ha (284 acres). Wines tend to have marked acidity.',
    ],
  },
  {
    name: 'ROSSOLINO NERO',
    synonyms: ['Rossolino Rosa'],
    description:
      'Minor variety in Valtellina in Italy\u2019s cool far north, probably progeny of Rossola Nera.',
    'origin and parentage': [
      'Rossolino Nero is usually considered to be the most widespread clonal variation of ROSSOLA NERA (Bongiolatti 1996; Fossati et al. 2001) but DNA profiling has established that they are distinct varieties, linked by a parent\u2013offspring relationship, with Rossolino Nero most probably the offspring (Vouillamoz). A pink-berried form called Rossolino Rosa can be found throughout Valtellina, in particular in Teglio.',
    ],
    'where its grown': [
      'Rossolino Nero and ROSSOLA NERA are interplanted and generally confused with one another in vineyards throughout Valtellina in the far north of Italy, close to the Swiss border.',
    ],
  },
  {
    name: 'ROTBERGER',
    synonyms: ['Geisenheim 3-37', 'Redberger'],
    description:
      'German cross that takes after its parent Schiava Grossa (aka Trollinger).',
    'origin and parentage': [
      'A SCHIAVA GROSSA \u00d7 RIESLING cross bred by Heinrich Birk in 1928 at the Geisenheim research institute in the Rheingau, Germany. Parentage has been confirmed by DNA profiling (Grando and Frisinghelli 1998). Having the same parents, Rotberger and KERNER are full siblings.',
    ],
    'where its grown': [
      'There are just 16 ha (40 acres) in Germany, nearly half of them in the Rheingau. Notable German producers include Schlossgut Schmidt in Rheinhessen and Birkert in W\u00fcrttemberg.',
      'Pojer e Sandri in the Alto Adige, Italy, produce a fresh and zesty varietal Rotberger called Vin dei Molini, although total Italian plantings were too few for the variety to be included in the 2000 agricultural census.',
      'There are 3 ha (7 acres) in British Columbia, Canada, where Gray Monk make a varietal ros\u00e9 from it in the Okanagan Valley. Wines can be reasonably full-bodied yet fresh and ros\u00e9 versions are common.',
    ],
  },
  {
    name: 'ROTER VELTLINER',
    synonyms: [
      'Piros Veltelini',
      'Rote Fleischtraube',
      'Roter Veltliner Baldig',
      'Rotmuskateller',
      'Rotreifler',
      'Ryvola Cervena',
      'Veltl\u00ednske \u010cerven\u00e9',
    ],
    description:
      'Ancient Austrian variety that makes powerful white wine, whatever its name suggests.',
    'origin and parentage': [
      'The parentage of Roter Veltliner has not yet been uncovered but DNA profiling provided evidence that Roter Veltliner spontaneously crossed with SILVANER to produce FR\u00dcHROTER VELTLINER and NEUBURGER, with SAVAGNIN to produce ROTGIPFLER (Sefc, Steinkellner et al. 1998), and possibly with a close relative of Savagnin to produce ZIERFANDLER. Roter Veltliner and its progeny Fr\u00fchroter Veltliner are, surprisingly, not related to GR\u00dcNER VELTLINER (see PINOT pedigree diagram). Roter Veltliner was used to breed DEV\u00cdN and HETERA.',
    ],
    'where its grown': [
      'Austria has 259 ha (640 acres) of Roter Veltliner scattered across Nieder\u00f6sterreich (Lower Austria) but with most plantings in the Weinviertel and in Wagram. It used to be much more widely planted and can produce powerful, full-bodied wines even at relatively high yields; peppery and spicy when young, some tasting quite similar to (the unrelated) GR\u00dcNER VELTLINER. The best wines develop considerable complexity and some almond flavours after two or three years in bottle. Top producers include Stefan Bauer, Josef Fritz, Grill, Franz Leth and S\u00f6llner.',
      'It is also planted in Slovakia (363 ha/897 acres), to a very limited extent in Hungary (just over 3 ha/7 acres officially recorded in 2008) and in the Czech Republic, where it doesn\u2019t even make it into the official statistics.',
    ],
  },
  {
    name: 'ROTGIPFLER',
    synonyms: ['Reifler', 'Slatzki Zelenac', 'Zelenac Slatki'],
    description:
      'Thermenregion speciality makes powerful whites usually blended with Zierfandler.',
    'origin and parentage': [
      'DNA parentage tests revealed that Rotgipfler, most probably from Austria\u2019s Thermenregion, is a natural cross between SAVAGNIN and ROTER VELTLINER (Sefc, Steinkellner et al. 1998), and is therefore a half-sibling of FR\u00dcHROTER VELTLINER, NEUBURGER and ZIERFANDLER via Roter Veltliner, and a half-sibling of GR\u00dcNER VELTLINER and SILVANER via Savagnin. Taking the pedigree further, Rotgipfler is either a grandchild or a half-sibling of PINOT, because Savagnin and Pinot have a parent\u2013offspring relationship (see PINOT pedigree diagram).',
    ],
    'where its grown': [
      'Although Rotgipfler is not widely planted, with a total of 117 ha (289 acres), almost entirely in its homeland of the Thermenregion, south of Wien (Vienna) in Austria, local producers in Gumpoldskirchen are fiercely proud of this local speciality, which finds its most powerful, spicy and ageworthy expression in partnership with ZIERFANDLER, the other local speciality. It retains its acidity well, even when very ripe, and produces generous, mouth-filling wines high in extract and alcohol, often with flavours of peach and apricot, occasionally heavy, becoming more expressive with bottle age. Wines produced in an off-dry style can lack freshness. The variety is well suited to restrained use of oak. Recommended producers include K Alphart, Leo Aumann, Biegler, Johanneshof Rheinisch, Stadlmann and Zierer.',
      'It is also found to a limited extent, under local synonyms, in Slovenia and Croatia (zeleni means \u2018green\u2019, slatki, \u2018sweet\u2019). Krauthaker produces a good example of Zelenac in the latter.',
    ],
  },
  {
    name: 'ROUGE DE FULLY',
    synonyms: ['Durize'],
    description: 'Swiss, or possibly Italian, variety making rustic reds.',
    'origin and parentage': [
      'Local to and traditional in the region around Fully in the Valais, Switzerland, where it was the most planted variety until the end of the nineteenth century (Vouillamoz 2009b). Today it is often referred to under its dialect name, Durize, in reference to its hard (French dur) skin.',
      'DNA parentage analysis has not revealed its parents but has suggested a close genetic relationship with ROUSSIN from the neighbouring Valle d\u2019Aosta in Italy, where Rouge de Fully most likely originates (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Rouge de Fully is cultivated exclusively in the villages of Fully and Saillon in the Valais, Switzerland, on less than 1 ha (2.5 acres) by fewer than a dozen producers, including Henri Valloton and Samuel Roduit. The wines are lightly smoky, sometimes spicy, and have aromas of strawberries and blackberries, with rustic tannins and fresh acidity.',
    ],
  },
  {
    name: 'ROUGE DU PAYS',
    synonyms: [
      'Cornalin',
      'Cornalin du Valais',
      'Landroter',
      'Rouge du Valais',
    ],
    description:
      'Variety exclusive to southern Switzerland also known as Cornalin but distinct from the Italian Cornalin in the Valle d\u2019Aosta.',
    'origin and parentage': [
      'Rouge du Pays (meaning \u2018homeland red\u2019) is possibly one of the most ancient red wine grape varieties of the Valais, southern Switzerland, where it has been cultivated for centuries. From the mid nineteenth century, it was supplanted by interlopers such as PINOT NOIR and GAMAY NOIR, and by the mid twentieth century it was almost extinct. Rouge du Pays was rescued in the 1970s by taking cuttings from the few surviving vines and was unfortunately renamed Cornalin (Nicollier 1972) \u2013 unfortunately because this name is already taken by another variety in the Valle d\u2019Aosta, which is incidentally called Humagne Rouge in the Valais (see CORNALIN).',
      'As it happens, it has long been suspected that Rouge du Pays originated from the Valle d\u2019Aosta (Berget 1904), which was confirmed by DNA parentage analysis showing that Rouge du Pays is a natural cross between PETIT ROUGE and MAYOLET (Vouillamoz et al. 2003), both traditional varieties from the Valle d\u2019Aosta, making it a grandchild of PRI\u00c9 (see Pri\u00e9 for the pedigree diagram). Rouge du Pays is also the parent of at least three varieties from the Valle d\u2019Aosta: ROUSSIN, NERET DI SAINT-VINCENT and CORNALIN. This last parentage fully justifies the choice of Rouge du Pays instead of Cornalin as prime name for this variety in the Valais. In addition, a close relationship with VIEN DE NUS has been suggested (Vouillamoz and Moriondo 2011).',
      'Rouge du Pays was probably introduced from its homeland in the Valle d\u2019Aosta, where it has today disappeared, to the neighbouring Valais several centuries ago, possibly via the Grand-Saint-Bernard pass that followed the Roman road Via Francigena.',
    ],
    'where its grown': [
      'Rouge du Pays is cultivated almost exclusively in the Valais in southern Switzerland, where its vineyards totalled 116 ha (287 acres) in 2009. It\u2019s a difficult variety to grow, but in good hands it gives wines that rank among the most praised in the Valais, with aromas of black cherries, violets and raspberries, dense but silky tannins and a sapid, slightly bitter-acidic finish. Recommended producers include Arte Vinum, Domaine Cornulus, Anne-Catherine & Denis Mercier and Maurice Zufferey.',
    ],
  },
  {
    name: 'ROUSSANNE',
    synonyms: [
      'Barbin',
      'Bergeron',
      'Fromental',
      'Fromenteau',
      'Martin Cot',
      'Petite Roussette',
      'Roussanne Blanc',
    ],
    description:
      'Scented, high-quality Rh\u00f4ne white often blended with Marsanne. Not easy to grow.',
    'origin and parentage': [
      'Roussanne is a traditional variety of the northern Rh\u00f4ne, where it was first mentioned in 1781 in a text about the white wines from the Hermitage region (see MARSANNE). The name Roussanne probably refers to the russet (roux) colour of the mature berries. Roussanne is a member of the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009). DNA parentage analysis strongly suggests a parent\u2013offspring relationship between Roussanne andMARSANNE (Vouillamoz). This might explain why Marsanne and Roussanne are often confused.',
    ],
    'where its grown': [
      'In France, Roussanne\u2019s most famous and revered renditions are in partnership with MARSANNE in the northern Rh\u00f4ne still white wines of Hermitage, Crozes-Hermitage and Saint-Joseph and in still and sparkling Saint-P\u00e9ray. The regulations do not specify the exact proportions but there is much more Marsanne planted than Roussanne now because, although the latter produces more elegant wine, the former is easier to grow, more productive and less susceptible to fungal diseases. However, Roussanne is not restricted to the northern Rh\u00f4ne since, unlike Marsanne, it is also a permitted ingredient in white Ch\u00e2teauneuf-du-Pape and C\u00f4tes du Rh\u00f4ne and is recommended in many a southern and south-eastern d\u00e9partement as well as in the Loire, and in Savoie (96 ha/237 acres in 2009), where it is the sole component of Chignin-Bergeron.',
      'Roussanne is more aromatic than MARSANNE, often with a refreshing perfume akin to herbal tea (verbena?) reminiscent of spring blossom, and tends to have higher acidity, hence its superior ageing capacity.',
      'The variety\u2019s fashionability is revealed by the rapid increase in total French plantings, which doubled in the last ten years to reach 1,352 ha (3,341 acres) in 2009, compared with just 71 ha (175 acres) in the 1960s. The variety\u2019s tendency to ripen late is less of a problem in Languedoc and Roussillon than in the northern Rh\u00f4ne although it can suffer in very dry conditions. In Languedoc-Roussillon it is often blended with BOURBOULENC, Grenache Blanc (GARNACHA BLANCA), VERMENTINO, MARSANNE and so on but it has also proved to be a good partner for CHARDONNAY. A particularly fine example of varietal Roussanne is produced by Ch\u00e2teau de Beaucastel in Ch\u00e2teauneuf-du-Pape, who have demonstrated that the fruit of their old vines has great affinity with oak and the capacity to age. Grand Veneur is another example of this style.',
      'Other recommended French producers of varietal wines or Roussanne-dominated blends include Ch\u00e2teau Pesquier (Ventoux), Domaine Sainte-Rose (C\u00f4tes de Thongue), Domaine de la Solitude (Ch\u00e2teauneuf) and Paul Jaboulet A\u00een\u00e9 (Rh\u00f4ne).',
      'In Savoie, Michel Quenard is one of the best exponents of the variety, here called Bergeron, in Chignin, a cru south east of Chamb\u00e9ry. Relative to Savoie\u2019s generally delicate whites, these wines are powerful, rich, heady and usually just dry. Other recommended Savoie producers include all the other Qu\u00e9nards in Chignin: Andr\u00e9 and Michel, Jean-Pierre and Jean-Fran\u00e7ois, Raymond, and Pascal and Annick. Roussanne in Savoie should not be confused with Roussette, once the name used for the ALTESSE variety that constitutes Roussette de Savoie.',
      'In Italy it is grown to a very limited extent in Liguria and Toscana, where it is a permitted ingredient in Italy\u2019s Montecarlo Bianco (no more than 10% allowed) but the area was too small to be included in the 2000 agricultural census. Lamoresca on the island of Sicilia sometimes include Roussanne in their VERMENTINO-dominated white.',
      'There are very few growers of Roussanne in Portugal but Espor\u00e3o in the Alentejo have 6 ha (15 acres) and use it in their Private Selection white blend. Ted Manousakis produces it on the island of Kr\u00edti (Crete), Greece.',
      'The variety has recently been gaining ground rapidly in California, where there were 348 acres (141 ha) in 2009. It is particularly favoured by the state\u2019s Rh\u00f4ne Rangers in the Central Coast, notably Qup\u00e9 in Santa Ynez Valley and Alban in Edna Valley. It is often blended with MARSANNE, while at Bonny Doon in the Santa Cruz Mountains, Randall Grahm blends Roussanne and Grenache Blanc (GARNACHA BLANCA) in his Cigare Volant white. Further north and inland, Renaissance have been particularly successful with varietal wines in North Yuba. There are also limited plantings in Washington State, where Syncline and DeLille, among others, make varietal wines and Airfield Estate use it in blends. In Oregon, Andrew Rich, Agate and Troon all make varietal examples quite successfully. In the Okanagan Valley in western Canada, it is usually blended with VIOGNIER.',
      'As in the Rh\u00f4ne, Roussanne is more often blended with MARSANNE in Australia, sometimes also with VIOGNIER thrown in. In 2010 there were over forty producers of this variety, though the total area under vine was too small to be recorded. Yeringberg in Victoria\u2019s Yarra Valley have been particularly successful with their ageworthy varietal wine. Other producers in Victoria include Giaconda in Beechworth, Tahbilk (more famous for their Marsanne) in the Nagambie Lakes and Brown Brothers in the King Valley; in South Australia, d\u2019Arenberg in McLaren Vale and Torbreck in the Barossa; in Western Australia, McHenry Hohnen and Jerusalem Hollow, among others.',
      'South Africa had 28 ha (69 acres) of Roussanne in 2009, the majority in Paarl and to a lesser extent in Stellenbosch, where Rustenberg make a particularly powerful varietal wine. Producers such as Adi Badenhorst and Eben Sadie in the Swartland are using a small amount of Roussanne in their complex white blends.',
    ],
  },
  {
    name: 'ROUSSETTE D\u2019AYZE',
    synonyms: [
      'Bonne Roussette d\u2019Ayze',
      'Grosse Roussette',
      'Riusse',
      'Riussette',
    ],
    description:
      'Virtually extinct and rather inferior Savoie variety used occasionally in sparkling wines.',
    'origin and parentage': [
      'Roussette d\u2019Ayze is a variety particular to the Ayze region near Bonneville in the Haute-Savoie and should not be confused with other varieties that include Roussette among their synonyms. It is a member of the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009). Surprisingly, parentage analysis of twenty-three DNA markers suggests a possible parent\u2013offspring relationship between Roussette d\u2019Ayze and ONDENC from the Vall\u00e9e du Tarn in southern France (Vouillamoz, to be confirmed with more markers).',
      'According to Galet (2000), Roussette d\u2019Ayze is simply a clone of MONDEUSE BLANCHE but this can be disproved by DNA profiling (Vouillamoz).',
    ],
    'where its grown': [
      'Wines are not especially aromatic and are generally used to make sparkling wines. There was less than 1 ha (2.5 acres) in France in 2009. It is authorized as a complementary variety for still and sparkling wines in the Ayze cru within the Vin de Savoie appellation, along with its blending partners GRINGET and ALTESSE, but the quality of the selection has been a major factor in its decline \u2013 though even in 1988 there were only 20 ha (49 acres).',
    ],
  },
  {
    name: 'ROUSSIN',
    synonyms: ['Gros Roussin', 'Picciou Roussin', 'Ross\u00e9'],
    description: 'Almost extinct Aostan.',
    'origin and parentage': [
      'Roussin was first mentioned in the Valle d\u2019Aosta, north-west Italy, by Gatta (1838) as a rare vine from the area between Arnad and Verrayes, in the lower part of the valley, due north of Torino. It most likely takes its name from the red colour (Italian rosso) of the berries at maturity. DNA profiling has shown that Roussin is not related to Roussin de Morgex, a variety from the upper valley that is no longer cultivated, and parentage analysis has established that Roussin is an offspring of ROUGE DU PAYS (Vouillamoz 2005), an old Valle d\u2019Aosta variety now grown only in the Valais in Switzerland (see PRI\u00c9 for the pedigree diagram). Roussin is therefore a grandchild of the parents of Rouge du Pays, namely PETIT ROUGE and MAYOLET, and a half-sibling of the other progenies of Rouge du Pays, namely CORNALIN and NERET DI SAINT-VINCENT, as well as a half-sibling of VUILLERMIN through another genetic line (Vouillamoz and Moriondo 2011).',
      'Labra et al. (2002) suggested a genetic link with AMIGNE but this has been disproved by a more detailed DNA investigation (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Saved from extinction in the 1990s by Giulio Moriondo, ampelographer at the Institut Agricole R\u00e9gional in Aosta, Italy, Roussin is today an endangered variety. There is just one Roussin vineyard, near Arnad, plus a few old vines in the neighbouring vineyards.',
    ],
  },
  {
    name: 'ROYAL DE ALLOZA',
    synonyms: ['Alloza', 'Derechero de Muniesa'],
    description: 'Recuperated and very limited Aragonese speciality.',
    'origin and parentage': [
      'As the name suggests, Royal de Alloza is an autochthonous variety from the village of Alloza in the province of Teruel, north-east Spain.',
    ],
    'where its grown': [
      'Royal de Alloza is grown exclusively in the province of Teruel in Arag\u00f3n, northern Spain. Plantings are so limited that it is not mentioned in the official vineyard statistics and Fandos y Barriuso in Bajo Arag\u00f3n, who rescued the variety (and ROMERO DE H\u00cdJAR) from local vineyards, may be the only ones to make a varietal wine from this variety. They describe it as \u2018deep cherry red with copper edges, a potent bouquet, toasted, of black fruit confit and Mediterranean herbs. Potent in the mouth yet fresh.\u2019',
    ],
  },
  {
    name: 'ROYALTY',
    synonyms: ['Calif 526', 'California S 26', 'Royalti', 'Royalty 1390'],
    description: 'Red-fleshed American hybrid on the wane in California.',
    'origin and parentage': [
      'A complex Vitis\nvinifera and Vitis\nrupestris hybrid obtained by Harold P Olmo in 1938 at the University of California at Davis by crossing Alicante Ganzin with TROUSSEAU (Walker 2000), where Alicante Ganzin is a Ganzin 4 \u00d7 ALICANTE HENRI BOUSCHET hybrid and Ganzin 4 an ARAMON NOIR \u00d7 Vitis rupestris hybrid (see PRIOR pedigree diagram). RUBIRED and Royalty were released together in 1958, having been bred mainly for their deep and stable colour (Olmo and Koyama 1962).',
    ],
    'where its grown': [
      'Because it is more difficult to grow, Royalty has been far less successful than RUBIRED. There were just 240 acres (97 ha) in California in 2009, mostly in the San Joaquin Valley. It is used in blends to add colour but also to produce food colouring. Preston Wines in Washington State use it to make a port-style wine.',
    ],
  },
  {
    name: 'RUBIN',
    synonyms: ['Rubin Bolgarskii'],
    description:
      'Intriguing, successful but not widely planted Bulgarian cross used for both dry and sweet reds.',
    'origin and parentage': [
      'Rubin is a NEBBIOLO \u00d7 SYRAH cross obtained in 1944 at the Pleven viticulture and oenology institute in northern Bulgaria. It was officially released in 1961.',
    ],
    'where its grown': [
      'In its homeland, Bulgaria, Rubin produces full-bodied, soft, sweet, spicy reds with only moderate alcohol and fruit flavours ranging from blackberries and plums to figs, very ripe mulberries and raisins, especially when harvested late for sweet wines. Their deep colour is due to the level of anthocyanins, which is higher than in CABERNET SAUVIGNON. It marries well with oak but there is little evidence as to its ageworthiness. Logodaj in the Struma Valley in the south-western corner of Bulgaria produce a good varietal example, as do Brestovitsa. There were 165 ha (408 acres) in Bulgaria in 2008. Rubin is also found in Romania and Slovenia.',
    ],
  },
  {
    name: 'RUBIN GOLODRIGI',
    synonyms: ['Magarach 15-74-29', 'Ruby of Golodryga'],
    description:
      'Recent and usefully disease-resistant Ukrainian hybrid producing deeply coloured reds.',
    'origin and parentage': [
      'Rubin Golodrigi is a hybrid obtained in 1974 by P Golodriga, M Kostik and V Yurchenko at the Ampelos vine-breeding company in Yalta, southern Ukraine, by crossing RUBINOVY MAGARACHA with Magarach 6-68-27, the latter being an unspecified cross from the Magarach research centre in Krym (Crimea), southern Ukraine. The parentage was confirmed by recent DNA analysis (Goryslavets et al. 2010).',
    ],
    'where its grown': [
      'Wines produced in Ukraine are deeply coloured and appear in dry, sweet and fortified guises. The variety is also found in Krasnodar, south-west Russia (82 ha/203 acres in 2010).',
    ],
  },
  {
    name: 'RUBINET',
    synonyms: ['R \u2013 27', 'Tintet'],
    description:
      'Very minor red-fleshed Czech cross producing usefully dark, rich wines.',
    'origin and parentage': [
      'Rubinet is a (Revolta \u00d7 Alibernet) \u00d7 ANDR\u00c9 cross recently obtained by Vil\u00e9m Kraus at the faculty of horticulture in Lednice in southern Morava, in the south east of the Czech Republic, in which Revolta is a (PR\u00c9COCE DE MALINGRE \u00d7 CHASSELAS) \u00d7 (CSABA GY\u00d6NGYE \u00d7 Corinthe Rouge) cross bred in the Czech Republic, Corinthe Rouge, or Korinthusi Piros, being a Greek table grape. (For Alibernet, see ODESSKY CHERNY.) Rubinet was initially named Tintet before being officially renamed and included in the Czech register of varieties in 2004.',
    ],
    'where its grown': [
      'Thanks to its deep colour, richness, black-fruit flavour and soft tannins, Rubinet is a useful blending partner, although there were only 2 ha (5 acres) recorded in 2009, mostly in Morava, in the south east of the Czech Republic. Hradil and C\u00edsa\u0159sk\u00e9 Sklepy Hor\u00e1k both make varietal examples.',
    ],
  },
  {
    name: 'RUBINOVY MAGARACHA',
    synonyms: ['Crossing 56', 'Magarach 56', 'Magarach Ruby', 'Ruby Magaracha'],
    description: 'Robust but awkwardly late-ripening Ukrainian cross.',
    'origin and parentage': [
      'Rubinovy Magaracha is a CABERNET SAUVIGNON \u00d7 SAPERAVI cross obtained by N Paponov, V Zotov, P Tsarev and P Y Golodriga in 1928 at the Magarach research centre in Krym (Crimea), southern Ukraine, and included in the official register of varieties in 1969. The parentage was confirmed by recent DNA analysis (Goryslavets et al. 2010). Rubinovy Magaracha was used to breed ANTEY MAGARACHSKY and RUBIN GOLODRIGI.',
    ],
    'where its grown': [
      'Grown in Ukraine, Rubinovy Magaracha berries can attain high sugar levels and produce dark-fruited wines at various sweetness levels as well as being used for juice and the table. Magarach, for example, include it in various semi-sweet and dessert blends. It is also found in Kazakhstan and Uzbekistan.',
    ],
  },
  {
    name: 'RUBIRED',
    synonyms: ['California S 8', 'Tintoria'],
    description:
      'Winemakers\u2019 secret weapon. Productive, red-fleshed American hybrid valued for juice and for adding colour.',
    'origin and parentage': [
      'A complex hybrid obtained by Harold P Olmo in 1938 at the University of California at Davis by crossing Alicante Ganzin (see ROYALTY for the pedigree) with TINTO C\u00c3O. Rubired and ROYALTY were released together in 1958, having been bred mainly for their intense and stable colour (Olmo and Koyama 1962).',
    ],
    'where its grown': [
      'Rubired took over from SALVADOR as the primary variety grown in California for red juice concentrate, to add colour to red-wine blends and for other food processing because the juice colour was more intense. The wine\u2019s main asset is its deep red colour but it has little character or body (Christensen et al. 2003). Mega Purple is the major brand of colouring grape juice concentrate dependent on Rubired. There were 11,776 acres (4,766 ha) in California in 2009, about a third as much as total plantings of PINOT NOIR, almost all in the San Joaquin Valley, particularly in Fresno, Kern and Madera counties.',
    ],
  },
  {
    name: 'RUBY CABERNET',
    synonyms: ['California 234 F 2'],
    description:
      'Productive California cross with sweet-fruit flavours, generally used to bolster blends.',
    'origin and parentage': [
      'A MAZUELO (under the name Carignane) \u00d7 CABERNET SAUVIGNON cross bred by Harold P Olmo in 1936 at the University of California at Davis, US, and introduced to the market in 1948 (Walker 2000). It is Olmo\u2019s oldest crossing of wine varieties (Christensen et al. 2003). His aim was to combine the characteristics of Cabernet with the productivity and heat tolerance of Mazuelo. Ruby Cabernet was used to breed CABERINTA.',
    ],
    'where its grown': [
      'Wines tend to be deeply coloured, slightly rustic, with sweet red-fruit flavours if yields are controlled, occasionally showing the darker fruit more typical of its parents. Although varietal wines exist, it is more often used in blends or to stretch CABERNET SAUVIGNON. California had 5,993 acres (2,425 ha) in 2009, down from the height of its popularity in the 1960s, planted mainly in the San Joaquin Valley. Ruby Cabernet is also planted in North Carolina, Wisconsin and Virginia, and it apparently shows potential in Oklahoma. In Texas, particularly at higher altitudes in the Texas High Plains AVA, new plantings of Ruby Cabernet are showing greater finesse thanks to the cooler temperatures. Val Verde produce a good example. Although some of the older, less well-sited vineyards are being pulled up, there were still about 100 acres (40 ha) in Texas in 2010.',
      'It is also planted to a very limited extent in Brazil (40 ha/100 acres in 2009), Uruguay (2 ha/5 acres in 2009) and Israel.',
      'Australia had 1,141 ha (2,819 acres) in 2008, mainly in the hot inland regions of Riverland, Riverina and the Murray Darling but also scattered to a much lesser extent in cooler areas. Most is used for blending.',
      'Elsewhere, South Africa had almost as much as California \u2013 2,381 ha (5,884 acres) in 2008 \u2013 but it is restricted to hot, irrigated areas and used for bulk wine. Ladismith\u2019s varietal has won some acclaim.',
    ],
  },
  {
    name: 'RUCH\u00c8',
    synonyms: ['Moscatellina', 'Roch\u00e9', 'Romitagi', 'Rouchet'],
    description: 'Rare, perfumed red wine grape with its own Piemonte DOC.',
    'origin and parentage': [
      'Ruch\u00e8 most probably originates from the area of Castagnole Monferrato in the province of Asti in Piemonte, north-west Italy. Its name is said to derive from the local dialect word roncet, describing a virus infection to which Ruch\u00e8 had proved to be resistant (unlike BARBERA and GRIGNOLINO). However, it seems very odd to name a variety after a disease that did not affect it.',
    ],
    'where its grown': [
      'Ruch\u00e8 is cultivated mainly in its birthplace in the province of Asti in Piemonte, north-west Italy, in the communes of Castagnole Monferrato, Grana, Montemagno, Portacomaro, Refrancore, Scurzolengo and Viarigi. Varietal wines are produced under the Ruch\u00e8 di Castagnole Monferrato DOC (with up to 10% BARBERA and/or BRACHETTO DEL PIEMONTE) or possibly as IGT Monferrato Rosso. There are limited plantings of Ruch\u00e8 in the province of Alessandria to the east, under the names Moscatellina or Romitagi. There were 46 ha (114 acres) planted in Italy in 2000.',
      'Varietal wines tend to be headily scented, often with aromas of roses. They can be spicy and the tannins so marked that the wines can sometimes leave a bitter aftertaste. Acidity is generally low. Recommended producers include Antica Casa Vinicola Scarpa, Bersano, Dacapo, Montalbera and Cantine Sant\u2019Agata. It can also produce good dessert wines.',
    ],
  },
  {
    name: 'RUDAVA',
    synonyms: ['CATAP 6/28'],
    description:
      'Recently authorized Slovakian cross with good potential for everyday reds.',
    'origin and parentage': [
      'Rudava, named after a river in western Slovakia, is a CASTETS \u00d7 I-35-9 cross obtained in 1967 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia, and authorized in 2011. I-35-9 is an obscure (TEINTURIER \u00d7 ALEATICO) \u00d7 PUKHLIAKOVSKY cross obtained in Russia (Dorota Posp\u00ed\u0161ilov\u00e1, personal communication). Rudava is a sibling of TORYSA.',
    ],
    'where its grown': [
      'Wines are dark and full-bodied, with a CABERNET SAUVIGNON-like flavour and ageworthy in the best years but also suited to early drinking. In 2011 there were just 5 ha (12 acres) of this unpretentious variety that can be grown in most of Slovakia\u2019s wine regions. Karol Brani\u0161 in the west of the country is one of the few producers to make a varietal example.',
    ],
  },
  {
    name: 'RUFETE',
    synonyms: [
      'Castellana',
      'Penamacor',
      'Preto Rifete',
      'Rifete',
      'Rosette',
      'Rufeta',
      'Tinta Pinheira',
    ],
    description:
      'Late-ripening Portuguese variety that can occasionally yield fine, ageworthy, light reds.',
    'origin and parentage': [
      'Rufete, also commonly called Tinta Pinheira, most likely originates from the district of Castelo Branco in central eastern Portugal, where it was already in cultivation by the end of the nineteenth century (Cunha et al. 2009). According to DNA analysis, Rufete could be genetically very close to TOURIGA NACIONAL from the neighbouring D\u00e3o region (Almadanim et al. 2007). Rufete is also very close to Puesto Mayor from Rueda in Spain, itself very close to PRIETO PICUDO from Tierra de Le\u00f3n in Spain (Santana et al. 2010). These geographically distant relatives suggest that Rufete is a very old variety that spread out from central Portugal to Spain a long time ago.',
      'Rufete Blanca is not a colour mutation of Rufete but a misleading synonym for VERDEJO SERRANO (Vouillamoz).',
    ],
    'where its grown': [
      'Rufete is planted mainly in the regions of Pinhel, Figueira de Castelo Rodrigo and Cova da Beira in eastern Beiras as well as further north in D\u00e3o and the Douro, with a total in Portugal of 2,741 ha (6,773 acres) in 2010. Like BAGA, it performs well only in very specific sites where it can reach full maturity and the majority of wines are light in colour, low in alcohol and have high acidity that highlights the astringency of the tannins (Rolando Faustino, personal communication). However, in some years in the Pinhel region, given the best, ie less productive, clones, the grapes ripen fully (around the end of October) and are capable of producing very good wines that are rich in colour and body, have flavours of red fruits and the potential to age well in bottle, not unlike Saumur CABERNET FRANC. Plantings have expanded in D\u00e3o, where producers have started to make successful ros\u00e9 and sparkling wines (Rolando Faustino, personal communication). Recommended producers include the Adega Cooperativa da Covilh\u00e3o, Quinta dos Barreiros and Quinta dos Termos, both in Beira Interior, as well as Quinta das Carvalhas in the Douro.',
    ],
  },
  {
    name: 'RUGGINE',
    synonyms: ['Rugginosa', 'Ruzninteina'],
    description: 'Recuperated Emilia-Romagnan.',
    'origin and parentage': [
      'Ruggine is a historical variety from the province of Modena in Emilia-Romagna, where it was once as widespread as TREBBIANO MODENESE or ALBANA. The name Ruggine derives from the rusty (Italian arrugginito) colour of the berries at maturity.',
    ],
    'where its grown': [
      'Ruggine was almost extinct when it was rescued in the early 1990s from old, local vineyards by Italo Pedroni, who planted one hectare (2.5 acres) in Rubbiara, just west of the city of Modena, northern central Italy, and is today still the only producer of this variety. The resulting wine is fizzy and fruity.',
    ],
  },
  {
    name: 'RUZZESE',
    synonyms: ['Rossese Bianco'],
    description:
      'Rare Ligurian rescued from extinction but so far authorized only for table wines.',
    'origin and parentage': [
      'Ruzzese, also known as Rossese Bianco (although this name is a source of confusion \u2013 see ROSSESE), was mentioned in Liguria by Acerbi (1825) as one of the most important varieties of the Cinque Terre region. Its name could come from the intense pink colour of the berries at maturity. Ruzzese is currently under consideration for entry in the Italian national register of varieties. Recent DNA profiling has shown that it should not be confused with another, morphologically distinct variety called ROSSESE BIANCO, of which a few old vines still thrive in Roddino and Sinio in Piemonte (Torello Marinoni, Raimondi, Mannini and Rolle 2009). Since this latter is already included in the national register, it is clearer to stick to the name Ruzzese for the Ligurian variety found in La Spezia and keep Rossese Bianco for the Piemontese variety.',
    ],
    'where its grown': [
      'Since Ruzzese is not yet in Italy\u2019s national register of grape varieties, it can be used only for table wines. Once widespread along the Ligurian coast, it was virtually extinct by the late twentieth century but was recently rescued by Nino Picedi Benettini, who planted cuttings from old vines he had rediscovered in Baccano di Arcola, west of La Spezia. Today Ruzzese is cultivated mainly in the province of Imperia, in the far west of Liguria, usually blended with VERMENTINO and ALBAROLA. Rare examples of varietal wines are made by Conte Picedi Benettini and Maccario Dringenberg.',
    ],
  },
  {
    name: 'SABATO',
    synonyms: ['Gigante'],
    description: 'Minor Campanian vine associated with the Sorrento coast.',
    'origin and parentage': [
      'Sabato, Italian for \u2018Saturday\u2019, is an old grape variety from the area around the city of Sorrento in the bay of Napoli, southern Italy, or possibly from further north in the Vesuvio area. It may be identical to Gigante, a variety from the Val Fortore in the province of Benevento in northern inland Campania, but there is as yet no ampelographic or DNA evidence to confirm this.',
    ],
    'where its grown': [
      'Sabato is still cultivated to a very limited extent on the Sorrento coast, south of Napoli in Campania, southern Italy, in particular in Vico Equense on the north of the peninsula where old ungrafted vines are still propagated, and in nearby Castellamare, Lettere and Gragnano. Sabato is being studied as part of a preservation programme. It is a secondary variety in the Penisola Sorrentina DOC.',
    ],
  },
  {
    name: 'SABREVOIS',
    synonyms: ['ES 2-1-9'],
    description: 'Insubstantial US hybrid prospering in Qu\u00e9bec.',
    'origin and parentage': [
      'An Elmer Swenson 283 \u00d7 Elmer Swenson 193 hybrid bred by Elmer Swenson in Osceola, Wisconsin, US, in which:',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 283 is an Elmer Swenson 114 \u00d7 SEYVAL BLANC hybrid (see ST PEPIN for the pedigree of Elmer Swenson 114 and PRIOR for that of Seyval Blanc)',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 193 is a Minnesota 78 \u00d7 Seneca hybrid (see BRIANNA for the pedigree of Minnesota 78)',
      '\u2013\u00a0\u00a0\u00a0Seneca is a LUGLIENGA \u00d7 Ontario hybrid (see CAYUGA WHITE for the pedigree of Ontario).',
      'This complex Vitis\nriparia, Vitis\nlabrusca, Vitis\nvinifera, Vitis\nlincecumii and Vitis\nrupestris hybrid has been grown in Minnesota for a long time as ES 2-1-9. It was named in August 2001 by Gilles Beno\u00eet after the village of Sabrevois on the Richelieu River, south east of Montr\u00e9al, the location of Beno\u00eet\u2019s winery, and is grown in Qu\u00e9bec under that name (Smiley 2008).',
      'Sabrevois and ST CROIX have the same parents and are therefore siblings.',
    ],
    'where its grown': [
      'Wines may be deeply coloured and pleasantly fruity but tend to lack body and tannin and are thus better as a blending component, though they can make an aromatic dry ros\u00e9. Sabrevois seems to have found greater favour in Qu\u00e9bec, Canada (16 acres/6 ha and the seventh most planted variety in the province), than in its homeland in the American Midwest, though there were 26 acres (11 ha) in Minnesota in 2007. Varietal wines are made by Gilles Beno\u00eet (see above) at Vignoble des Pins in Qu\u00e9bec and at Oak Ridge in Wisconsin among others.',
    ],
  },
  {
    name: 'SACY',
    synonyms: [
      'Aligot\u00e9 Vert',
      'Plant d\u2019Essert',
      'Plant de Sacy',
      'Tressallier',
    ],
    description: 'Light, nether Bugundian, losing ground.',
    'origin and parentage': [
      'Sacy is a variety from north-eastern France that is cultivated mainly in the area around Auxerre, between Dijon and Paris, and named after a village south of Reims in champagne country. Its first mention appeared under the synonym Plant de Sacy in 1782, when the mayor of Vermenton requested \u2018l\u2019interdiction de cultiver le Plant d\u2019Essert ou de Sacy, lequel ne donne qu\u2019un vin plat et de mauvaise calit\u00e9\u2019 (\u2018the ban on cultivating the Plant d\u2019Essert or de Sacy, which gives no more than a dull, poor-quality wine\u2019; Galet 1990; R\u00e9zeau 1997). Its main synonym, Tressallier, possibly derived from trans Alligerim (\u2018beyond Allier\u2019) because it used to be planted on the left bank of the Allier River, was first mentioned in Jullien (1816). These relatively recent dates could give a clue to when the variety was born. DNA parentage analysis has established that Sacy is one of the numerous progenies of PINOT and GOUAIS BLANC, this cross most likely occurring in Burgundy or at least in north-eastern France (Bowers et al. 1999; Boursiquot et al. 2004).',
      'Solely on the basis of morphology, Sacy was included in the Folle ampelographic group (see p XXVII; Bisson 2009), and not, surprisingly, in the group of either of its parents. However, this classification might change in the light of DNA parentage analysis.',
    ],
    'where its grown': [
      'Once widely grown in the Yonne d\u00e9partement of France, it is now considered a speciality of Saint-Pour\u00e7ain in central France, where it is known as Tressallier. It is authorized as a complementary variety in the Saint-Pour\u00e7ain appellation (20\u201340% of the blend) alongside the principal variety CHARDONNAY and possibly also SAUVIGNON BLANC (maximum 10%). It produces light, high-acid wines, sometimes tasting of pears, that are low in alcohol and well suited to the production of sparkling wine (it is a permitted variety in Cr\u00e9mant de Bourgogne). The vineyard area is in steady decline: just 12 ha (30 acres) in 2008 compared with 172 ha (425 acres) just ten years before and 655 ha (1,619 acres) in 1958. Domaine Nebout is one of the rare producers in Saint-Pour\u00e7ain to make a varietal wine.',
    ],
  },
  {
    name: 'SAINT-MACAIRE',
    synonyms: ['Bouton Blanc', 'Moust\u00e8re', 'Moustouz\u00e8re'],
    description:
      'Obscure Bordeaux variety found to a very limited extent in California and Australia.',
    'origin and parentage': [
      'Little is known about the history of this obscure Bordeaux variety named after a village near Langon in the Gironde, south-west France, where it used to be cultivated and where it probably originated. First mentioned as Saint-Macaire in the \u2018Livre de raison d\u2019Antoine Feuilhade\u2019, an account book written between 1763 and 1777 by the mayor of Libourne (Garde 1946), and commonly grown in the Gironde in the nineteenth century.',
    ],
    'where its grown': [
      'Saint-Macaire has more or less disappeared from its Bordeaux homeland: official statistics for 2008 in France report less than 1 ha (2.5 acres). There are also tiny plantings in California, where it is one of the minor varieties permitted in a bordeaux-style Meritage blend. O\u2019Shaughnessy in Howell Mountain, for example, include 2% Saint-Macaire in theirs. In Australia, Bill Calabria at Westend Estate in Griffith, Victoria, claims to be the only producer of this variety in the country, growing just 2 ha (5 acres). Varietal wines tend to be deeply coloured and relatively acidic.',
    ],
  },
  {
    name: 'SALVADOR',
    synonyms: ['Pate Noir', 'Salvadore', 'Seibel 128'],
    description:
      'Red-fleshed French hybrid now largely superseded by Rubired in California\u2019s San Joaquin Valley.',
    'origin and parentage': [
      'A complex hybrid obtained at the beginning of the twentieth century by Albert Seibel in Aubenas in south-eastern France by crossing Munson with Vitis vinifera subsp. vinifera (ie an unknown cultivated variety). See PRIOR for the pedigree of Munson.',
    ],
    'where its grown': [
      'There were 176 acres (71 ha) of this red-fleshed variety in California in 2008, mostly in Kern County at the southern end of the San Joaquin Valley. It was once more widely planted and was the main variety used in Fresno in the 1960s and 1970s for the production of red grape-juice concentrate though it was also occasionally used to add colour to wine blends. It has been largely replaced by RUBIRED, which serves the same function but does not have the same herbaceous flavours and harsh tannins and has redder \u2013 less purple \u2013 juice. Salvador is also planted in Aguascalientes and Chihuahua in Mexico.',
    ],
  },
  {
    name: 'SAN LUNARDO',
    synonyms: ['Bianca', 'Don Lunardo', 'Lunardo'],
    description: 'Rare Ischian white wine variety.',
    'origin and parentage': [
      'Although the earliest reference to San Lunardo on the island of Ischia in the bay of Napoli, southern Italy, was in 1962 (Migliaccio et al. 2008), the existence of old vines indicates that it has been there much longer. Its name is said to derive either from Don Lunardo, the name of the priest who owned the farmhouse where it was found, or from San Leonardo, as a tribute to the patron saint of Panza, a village near Forio on Ischia.',
      'Cipriani et al. (2010) have recently suggested through DNA parentage analysis that San Lunardo is a possible Albana Bordini \u00d7 BIANCOLELLA natural cross, the former being an obscure black-berried variety of unknown origin, the latter a white-berried variety indigenous to Ischia.',
    ],
    'where its grown': [
      'San Lunardo is authorized throughout the province of Napoli in Campania, southern Italy, but it is found only on the island of Ischia, in the bay of Napoli, where a few old vines thrive in old vineyards, and on the island of Ventotene, where it is simply called Bianca. A small amount \u2013 up to 15% \u2013 may be blended with FORASTERA and BIANCOLELLA in Ischia DOC. La Vigna dell\u2019Encadde are one of the few producers of a varietal wine.',
    ],
  },
  {
    name: 'SAN MARTINO',
    synonyms: ['Incrocio Dalmasso 7/21'],
    description:
      'Very minor cross made at Conegliano in the Veneto but apparently grown only in Piemonte.',
    'origin and parentage': [
      'The Italian vine breeder Giovanni Dalmasso announced that San Martino was a NEBBIOLO \u00d7 DOLCETTO cross obtained in 1936 at the Conegliano research centre and it was officially entered in the national register of varieties in 1977. However, DNA profiling has recently shown that the Nebbiolo used by Dalmasso was in fact Nebbiolo di Dronero, which is not Nebbiolo but a synonym for CHATUS (Torello Marinoni, Raimondi, Mannini and Rolle 2009). Therefore, San Martino must be considered to be a Chatus \u00d7 Dolcetto cross, just like its siblings PASSAU and VALENTINO NERO.',
    ],
    'where its grown': [
      'San Martino is cultivated in Piemonte, north-west Italy. The Italian agricultural census of 2000 recorded total plantings of 45 ha (111 acres).',
    ],
  },
  {
    name: 'SAN MICHELE',
    synonyms: ['Incrocio Dalmasso 15/34'],
    description:
      'Obscure Conegliano cross whose true parentage has only recently been revealed.',
    'origin and parentage': [
      'The Italian vine breeder Giovanni Dalmasso claimed that San Michele was a NEBBIOLO \u00d7 BARBERA cross obtained in 1938 at the Conegliano research centre and it was included in the national register of varieties in 1977. The objective was to get a new variety that combined the quality of Nebbiolo with the resistance and high yields of Barbera. However, DNA profiling has recently shown that the Nebbiolo used by Dalmasso was in fact Nebbiolo di Dronero, which is not Nebbiolo but a synonym for CHATUS (Torello Marinoni, Raimondi, Mannini and Rolle 2009). San Michele is therefore a Chatus \u00d7 Barbera cross, just like its siblings ALBAROSSAA, CORNAREA, NEBBIERA and SOPERGA.',
    ],
    'where its grown': [
      'There were 128 ha (316 acres) in Italy in 2000, mainly in Piemonte.',
    ],
  },
  {
    name: 'SANGIOVESE',
    synonyms: [
      'Brunelletto',
      'Brunello',
      'Cacchiano',
      'Calabrese',
      'Chiantino',
      'Corinto Nero',
      'Guarnacciola',
      'Liliano',
      'Morellino',
      'Morellino di Scansano',
      'Negrello',
      'Nerello',
      'Nerello Campotu',
      'Niella',
      'Nielluccio',
      'Primaticcio',
      'Prugnolo Dolce',
      'Prugnolo Gentile',
      'Puttanella',
      'San Gioveto',
      'San Zoveto',
      'Sangiogheto',
      'Sangiovese Grosso',
      'Sangiovese Piccolo',
      'Sangioveto',
      'Tabernello',
      'Toustain',
      'Tuccanese',
      'Vigna del Conte',
      'Vigna Maggio',
    ],
    description:
      'Hugely important and wildly variable central Italian variety with many a name and surprising origins.',
    'origin and parentage': [
      'EARLIEST MENTION Sangiovese was first mentioned in Giovan Vettorio Soderini\u2019s (1600) treatise on viticulture in Toscana under the name Sangiogheto, its oldest attested synonym: \u2018il Sangiogheto, aspro a mangiare, ma sugoso e pienissimo di vino\u2019 (\u2018the Sangiogheto, bitter to eat, but juicy and very vinous\u2019). Legend has it that Sangiovese derives from sanguis Jovis (\u2018Jupiter\u2019s blood\u2019), the name arbitrarily given to it by monks in Santarcangelo di Romagna, at the foot of Monte Giove near Rimini in Emilia-Romagna, after they had been asked by a visitor for the name of the wine they used to simply call vino.',
      'SANGIOVESE IS HALF-TUSCAN, HALF-CALABRIAN The Tuscan origin of Sangiovese had long been taken for granted until Vouillamoz et al. (2004) revealed that DNA parentage analysis had shown the true origin of Sangiovese: it is a natural CILIEGIOLO \u00d7 CALABRESE DI MONTENUOVO cross. Ciliegiolo\u2019s role did not come as a surprise because this old Tuscan variety, also known since Soderini (1600), had traditionally been blended with Sangiovese in Chianti and a possible family relationship between the two had already been suspected (Crespan et al. 2002).',
      'The big surprise was CALABRESE DI MONTENUOVO. Just a few vines of this obscure variety were found near a winery on the hill of Montenuovo next to the Lago d\u2019Averno in the Campi Flegrei west of Napoli during a survey of old local varieties (Costantini et al. 2005). Montenuovo is a relatively young hill, formed in one night after a volcanic eruption in 1538. The winery was established in the mid nineteenth century by the Calabrian family Strigari, who introduced several varieties from their homeland, as evidenced by the presence of MAGLIOCCO DOLCE and NERELLO MASCALESE in the vineyards around the Lago d\u2019Averno (Vouillamoz, Monaco et al. 2008). Calabrese di Montenuovo, which is unrelated to the Sicilian NERO D\u2019AVOLA \u2013 often called Calabrese \u2013 was later found in an additional vineyard in Campi Flegrei called Soccavo (Vouillamoz, Monaco et al. 2008). However, its true identity remained unknown. Vouillamoz, Monaco et al. (2007) then analysed the DNA profiles of 194 additional old varieties from Calabria, Campania, Toscana, Basilicata and Puglia. None matched Calabrese di Montenuovo but a possible parent\u2013offspring relationship was detected between Calabrese di Montenuovo and MAGLIOCCO CANINO, one of the most widespread red varieties in Calabria. This strongly suggests a Calabrian origin for Calabrese di Montenuovo.',
      'In addition, DNA profiling has revealed the historical cultivation of Sangiovese in southern Italy under various local names: Corinto Nero (a seedless mutation grown in Scalea in Calabria and on the Isola di Lipari just north of Sicilia, where it is used in small amounts in Malvasia delle Lipari wines), Nerello (Savelli in Calabria), Nerello Campotu (Motta San Giovanni in Calabria), Puttanella (Mandatoriccio in Calabria), Tuccanese (Puglia), Vigna del Conte (Calabria; Zulini et al. 2002; Grando et al. 2008).',
      'Interestingly, DNA parentage analysis has also established that Sangiovese has some more or less obscure possible progenies in Toscana \u2013 FOGLIA TONDA, Morellino del Casentino, Morellino del Valdarno (Crespan, Cal\u00f2 et al. 2008) \u2013 and several ancient and well-known progenies in southern Italy: FRAPPATO (under the name Frappato di Vittoria; Sicilia), GAGLIOPPO (Calabria), Mantonicone (Calabria), NERELLO MASCALESE (Sicilia), PERRICONE (Sicilia; Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008; Cipriani et al. 2010). The parent\u2013offspring relationship between Sangiovese and Gaglioppo explains why Morellino Pizzuto, supposedly a clone of Sangiovese in Toscana, was found to be identical to Gaglioppo (Scalabrelli et al. 2008), and the parent\u2013offspring relationship with Nerello Mascalese explains why Sangiovese is sometimes called Nerello on Sicilia or Negrello in Calabria (Grando et al. 2008; Schneider, Raimondi et al. 2009). Another seedless mutation has also been found in Campania (Schneider, Raimondi et al. 2009). In addition, a possible genetic relationship with ALEATICO has been suggested (Filippetti, Silvestroni, Thomas and Intrieri 2001).',
      'As a consequence, both CALABRESE DI MONTENUOVO and possibly Sangiovese are most likely of southern Italian origin, whence Sangiovese later spread to Toscana, the French island of Corse and elsewhere. This might explain why Vannuccini (1902) mentioned a San Gioveto called Calabrese near Arezzo in the south of Toscana.',
      'CLONAL DIVERSITY Like many old and widespread varieties, Sangiovese is said to be of polyclonal origin (a concept that does not fit with the definition of a grape variety used in this book \u2013 see pp XIX\u2013XVI), which means that several distinct varieties are confusingly cultivated under the name Sangiovese (Vignani et al. 2002; Filippetti et al. 2005; Di Vecchi Staraz, This et al. 2007). The significant biodiversity within Sangiovese is illustrated by its various synonyms (Filippetti et al. 2005; Di Vecchi Staraz, This et al. 2007), listed above.',
      'Conventional descriptions of Sangiovese, based on the pioneering work of ampelographer G Molon in 1906, divide the variety into two groups (sometimes erroneously called families): the Sangiovese Grosso (including Brunello, Prugnolo Gentile and the Sangiovese di Lamole of Greve in Chianti) and the Sangiovese Piccolo of other zones of Toscana. This led to an implicit supposition that superior quality was found in the Grosso family. However, from the more recent evidence outlined above, such a simple distinction is no longer possible and qualitative judgements based on the size of either the berries or the bunches are not justified. Significant efforts have been made in central Italy to identify and propagate superior clones rather than the former practice of selecting those with the highest yields irrespective of fruit or wine quality.',
    ],
    'where its grown': [
      'On the island of Corse in France, as Nielluccio, Sangiovese is the most highly regarded and widely planted red wine vine, with 1,319 ha (3,259 acres) in 2008. Nielluccio is often thought of as an indigenous variety, even though it is genetically identical to Sangiovese, and was probably brought to the island by the Genoese, who ruled the island until the eighteenth century. Often blended with Sciaccarello (MAMMOLO), it is particularly important in the Patrimonio region in the north, where it thrives on clay-limestone soils to produce tannic but ageworthy wines, though it plays a significant role in all Corsican red and ros\u00e9 appellations.',
      'The most important nursery in Italy, Rauscedo, has recently begun selling Sangiovese cuttings to producers on the French mainland, specifically the Languedoc, where they are intrigued by its drought resistance and reliable yields.',
      'But the kingdom of Sangiovese is Italy,\nwhere it is the most widely planted variety by some margin \u2013 69,790 ha (172,455 acres) in 2000, according to the agricultural census, down from more than 100,000 ha (247,000 acres) in 1990 \u2013 and responsible for some of Italy\u2019s most internationally famous wines such as the Chiantis and Brunello di Montalcino. It is recommended or authorized for more than half of Italy\u2019s wine regions \u2013 all but the most northerly and the most southerly \u2013 and is particularly important in Toscana, Emilia-Romagna, the Marche and Umbria. However, the quality of Sangiovese wines is extremely variable, influenced particularly by the clones planted and vineyard location and yield. The variety adapts well to a wide variety of soils, although the presence of limestone seems to exalt the elegant and forceful aromas that are perhaps the most attractive quality of the grape, and vines planted too high may fail to ripen fully. Many of the clones planted in the 1960s to 1980s, and some over-ambitiously located vineyard sites, resulted in wines lacking in body and colour but with considerable acid and astringency. With Chianti Classico vineyards regularly over 450 m and even up to 600 m altitude, exposition and a willingness to harvest late can be essential. Considerable and considered research in the late 1980s and 1990s into different clones of Sangiovese, particularly by the Chianti Classico Consorzio, showed, much to Tuscan surprise, that some of those producing the finest wine such as R24 and T19 originated in Emilia-Romagna, however thin, tart and uninspiring the quality of basic Sangiovese di Romagna DOC. Fully ripe Sangiovese can have alluring aromas of plums and dried underbrush. Less ripe, or less carefully made, it can exhibit farmyard aromas.',
      'In its many guises (see above for synonyms and clones used in specific regions), Sangiovese is the principal vine variety for red wine in Toscana, sometimes unadorned, as in Brunello di Montalcino, often the dominant variety in a blend, eg in Chianti Classico and other Chiantis, Vino Nobile di Montepulciano and many of the Supertuscans, those ambitious bottlings usually classified as IGT and often using international varieties such as CABERNET SAUVIGNON, MERLOT or SYRAH to give added lustre. Since the late twentieth century, Sangiovese has depended on other varieties, traditionally CANAIOLO NERO and MAMMOLO but increasingly the well-known interlopers, to make up for its deficiencies \u2013 quite legally within Chianti\u2019s oft-changed regulations, more covertly for other wines such as Brunello di Montalcino.',
      'But an increasing number of producers who really care about local expression, or who have learned that Toscana\u2019s distinction lies in carefully ripened Sangiovese, eschew non-Tuscan grapes and there has been a re-evaluation of Chianti Classico and particularly Riserva versions, and of top-quality all-Sangiovese wines in general, at the expense of those Supertuscans that depend on international varieties. Fine varietal Sangioveses from the Chianti zones include Fontodi\u2019s Flaccianello, Montevertine\u2019s pioneering Le Pergole Torte, Poggio Scalette\u2019s Il Carbonaione, Isole e Olena\u2019s Cepparello, La Porta de Vertine\u2019s Chianti Classico Riserva, San Giusto a Rentennano\u2019s Percarlo and Selvapiana\u2019s Riserva Bucerchiale. In theory, all Brunello di Montalcino is made from 100% Sangiovese, called here Brunello. Arguably the single most convincingly 100% Brunello example is Soldera\u2019s at Case Basse. Of Vino Nobile di Montepulciano, of which Sangiovese can constitute between 70 and 100%, Avignonesi is the leader.',
      'All but the most brazenly \u2018international\u2019 reds of Emilia-Romagna are based on Sangiovese, even if the most basic wines tend to be thin and tart. Castelluccio\u2019s Ronco delle Ginestre, Drei Don\u00e0\u2019s Pruno and Zerbina\u2019s Pietramora represent the other end of the quality spectrum. In Umbria, Lungarotti\u2019s Torgiano Riserva Vigna Monticchio has upheld the glory of Sangiovese, albeit seasoned with other central Italian varieties from this vineyard, for decades. In the Marche further north, Sangiovese is the foundation for Rosso Piceno DOC (30\u201350%) and an important component in Rosso Conero DOC and Conero DOCG.',
      'Sangiovese is also now found in Switzerland, Malta, Turkey and Israel. In Greece, Nico Lazaridi have planted Sangiovese in the north-east region of Dr\u00e1ma and blend 55% Sangiovese with CABERNET SAUVIGNON to produce their Perpetuus oak-aged red.',
      'In California, Sangiovese benefited from a perceived fashion for \u2018Cal-Ital\u2019 varietals in the early years of this century but by 2010 there were only 1,950 acres (789 ha), a significant decline since a peak of around 3,000 acres (1,214 ha) in 2003. There are some significant plantings in the Napa Valley, Sonoma, San Luis Obispo, Santa Barbara and the Sierra Foothills. Pioneers of the variety included Robert Pepi, Seghesio and Atlas Peak, in which the Tuscan firm of Antinori originally had an important stake, plus Villa Ragazzi and Noceto on a smaller scale. Other successful producers of Sangiovese in California include Benessere, Dalla Valle, Shafer and Stolpman.',
      'In Washington State, the generally bright fruit quality seems to suit Sangiovese rather well, filling in some of its often spare frame, but plantings had fallen to 185 acres (75 ha) by 2011. Successful producers include Leonetti and Novelty Hill.',
      'In Canada, there are experimental plantings of 5\u201310 acres (2\u20134 ha) in Ontario. Pillitteri Estates even make a small amount of icewine from their one-acre (0.4 ha) vineyard in Niagara-on-the-Lake. There\u2019s about the same area of Sangiovese in British Columbia with Sal D\u2019Angelo\u2019s a notable example.',
      'There were 2,319 ha (5,730 acres) of Sangiovese in Argentina in\n2008, mostly in Mendoza but with limited plantings in San Juan and La Rioja. Benegas of Mendoza have made a speciality of Sangiovese. Chile had\nonly 124 ha (306 acres) in 2008. Err\u00e1zuriz produce a single-vineyard version in the Aconcagua Valley. There were only 25 ha (62 acres) in 2007 in Brazil,\nwhere Casa de Amaro make a varietal.',
      'Sangiovese was a late arrival in Australia with only one clone available until recently, the H6V9 imported from the University of California at Davis in the 1960s, though there are now ten to choose from. The first commercial plantings were in the 1980s, the first source block in Penfold\u2019s Kalimna vineyard. Early results were often pale, hollow and dry, partly because the vines were allowed to follow their natural inclination to overcrop. In 2008 there were 517 ha (1,278 acres). The pioneers were Coriole and Dromana; others succeeding with the variety today include Brown Brothers, De Bortoli, The Little Wine Company, Mount Langi Ghiran, Pizzini and Scaffidi. Today\u2019s wines are a vast improvement on the early bottlings but consistency and popular appeal both seem elusive.',
      'New Zealand was home to just 6 ha (15 acres) of Sangiovese in 2008 with no signs of more enthusiasm. Heron\u2019s Flight in Matakana north of Auckland is one of the few who produce a varietal wine; their first release was 1998.',
      'From South Africa\u2019s 63 ha (156 acres), as counted in 2008 \u2013 mostly in Darling and Stellenbosch \u2013 Chris Mullineux and Terra Da Capo make varietals; blends include Nederburg\u2019s Ingenuity Red and Bouchard Finlayson\u2019s Hannibal.',
    ],
  },
  {
    name: 'SANKT LAURENT',
    synonyms: [
      'Laurenzitraube',
      'Lorenztraube',
      'Saint Laurent',
      'Saint-Laurent',
      'Saint-Lorentz',
      'Schwarzer',
      'Svatovav\u0159ineck\u00e9',
      'Sv\u00e4tovavrineck\u00e9',
      'Vav\u0159ineck\u00e9',
    ],
    description:
      'Middle European variety of uncertain origin producing velvety reds.',
    'origin and parentage': [
      'Sankt Laurent most likely originates from Austria, where it has been cultivated since the nineteenth century at the very least. Its name has nothing to do with any of the numerous villages named Saint-Laurent in France but probably derives from its old Austrian synonym Laurenzitraube, which in turn refers to St Laurentius, patron saint of chefs, whose saint\u2019s day falls on 10 August, when the variety begins to ripen. According to Regner (2000b; 2007), Sankt Laurent could be a progeny of an as yet unknown variety that is genetically close to PINOT. However, this hypothesis is doubtful because the DNA profile of Sankt Laurent is genetically distant from Pinot, and Regner possibly confuses Sankt Laurent with Pinot Saint-Laurent, two quite distinct varieties. Contrary to common belief, Pinot Saint-Laurent (which is not in commercial production) is not a synonym of Sankt Laurent, nor does it have anything to do with Pinot (Vouillamoz).',
      'Sankt Laurent was used to breed several varieties, some of which are cultivated for commercial wine production: ANDR\u00c9, DUNAJ, RONDO and ZWEIGELT.',
    ],
    'where its grown': [
      'Sankt Laurent produces wines in several Central European countries that are generally aromatic, deeply coloured and velvety but well structured with fine tannins and flavours of sour cherry, sometimes likened to a powerful version of PINOT NOIR. The best wines are well suited to subtle oaking.',
      'Germany\u2019s 669 ha (1,653 acres) of unhyphenated Saint Laurent were, in 2008, overwhelmingly in the Pfalz and Rheinhessen. The variety had almost disappeared in the 1960s but is now enjoying something of a comeback thanks especially to the efforts of viticultural adviser Fritz Klein, who propagated the variety from the last few remaining vines, and to the perseverance of the Pfalz wine grower Karlheinz Kleinmann, who campaigned for its inclusion in the official register of varieties. Recommended producers include Knipser, Philip Kuhn and Bernhart in the Pfalz; Gutzler and Wagner-Stempel in Rheinhessen; and Fischer in Baden.',
      'Plantings in the variety\u2019s presumed homeland of Austria have almost doubled in the last nine years in keeping with the general expansion of red-wine production, reaching 795 ha (1,964 acres) in 2008. The majority of the plantings are south and east of Vienna in Burgenland\u2019s Neusiedlersee and in the Thermenregion and west of the capital in the Weinviertel. Despite the viticultural disadvantages described above, its potential quality and the fact that it can be grown on a wider range of sites than PINOT NOIR, and ripens earlier, explains its increasing popularity. Recommended producers include Gernot and Heike Heinrich, Leo Hillinger, Pimpel, Schloss Halbturn, Steinklammer and Zantho.',
      'But the variety is even more widely planted north and east of Austria. Under the name Svatovav\u0159ineck\u00e9 (it lost its sanctity during the Communist era and was known simply as Vav\u0159ineck\u00e9), it is the most widely cultivated dark-skinned variety in the Czech Republic, planted mainly in Morava in the south east, with a total of 1,482 (3,662 acres) in 2008. Recommended producers include Patria in Kobyl\u00ed, Michlovsk\u00fd in Rakvice, Stapleton-Springer and Jedli\u010dka & Novak in Bo\u0159etice and ZD in N\u011bm\u010di\u010dky.',
      'Further east, in Slovakia, where only the accents change on Sv\u00e4tovavrineck\u00e9, there were 1,408 ha (3,479 acres) in 2009, 7% of the vineyard area, making it the second most planted red variety after BLAUFR\u00c4NKISCH. Most wines are varietal but it is occasionally blended with BLAUER PORTUGIESER (under the name Modr\u00fd Portugal).',
    ],
  },
  {
    name: 'SAPERAVI',
    synonyms: ['Atenuri Saperavi', 'Meskhuri Saperavi', 'Obchuri Saperavi'],
    description:
      'Quintessential Georgian variety producing deeply coloured, firm, initially tart but ageworthy reds.',
    'origin and parentage': [
      'Saperavi, meaning \u2018dye\u2019 in Georgian, referring to its deep black skin and pinkish juice, is a very old variety from south-western Georgia (Zemo Kartli), historically known as Meskheti and now straddling the Georgian-Turkish border. From there it spread to the Kartli region and then more widely to Kakheti, where it found its second home. It was widespread in the seventeenth century in western Georgia as well as in Kakheti (Chkhartishvili and Betsiashvili 2004).',
      'Several distinct clones have been identified (Saperavi Budeshurisebri, Saperavi Grdzelmarcvala, Saperavi Mskhvilmarcvala, Saperavi Pachkha, etc.), illustrating the ancient cultivation of this variety.',
      'Saperavi was used to breed AKHTANAK, BASTARDO MAGARACHSKY, NERKENI, NOVAC, RUBINOVY MAGARACHA, SAPERAVI SEVERNY and TIGRANI.',
    ],
    'where its grown': [
      'The most widely planted dark-skinned variety in Georgia (3,692 ha/9,123 acres in 2004), Saperavi is planted throughout the Kakheti wine region in the south east of the country but produces its finest wines in the villages of Mukuzani, Khashmi, Anaga, Kardenakhi, Shilda, Napareuli, Kondoli and Tsinandali and is solely responsible for highly regarded appellation wines such as Mukuzani, Napareuli, Akhasheni and Kindzmarauli. Varietal wines are notably high in colour, acidity and tannin, full-bodied, rich in dark fruit as well as more savoury flavours and generally repay time in bottle. Saperavi also adds colour and acidity to blends and makes impressive semi-sweet and port-style fortified wines, particularly in the Kindzmarauli and Akhasheni appellations. In cooler, higher sites, the grapes fail to ripen fully and wines may be overly acidic and sappy. Recommended producers include Badagoni, Chelti, Eniseli Wines, Jakeli Wines, Kakhuri, Nika, Orovela, Maisuradze Wines, Chateau Mukhrani, Tbilvino, Telavi Wine Cellar, Wine Man and Teliani Valley. Pheasant\u2019s Tears and Vinoterra make good examples of traditional styles, vinified in qvevri (traditional fermentation vessels made of clay).',
      'Saperavi is widely planted throughout the wine regions of the former Soviet Union, including Ukraine (1,514 ha/3,741 acres in 2009), the Republic of Moldova (716 ha/1,769 acres in 2009), Russia (614 ha/1,515 acres in Krasnodar and 102 ha/252 acres in Rostov in 2010), Azerbaijan, Armenia and also in Eastern European countries such as Bulgaria (30 ha/74 acres in 2009, mostly in Plovdiv).',
      'The exceptional qualities of Saperavi are so self-evident that it has even found a foothold in Australia, where Symphonia and Gapsted in Victoria, Domain Day and Patritti in Barossa and Hugh Hamilton in McLaren Vale all produce varietal examples.',
    ],
  },
  {
    name: 'SAPHIRA',
    synonyms: ['Geisenheim 7815-1'],
    description:
      'New German hybrid with moderate disease resistance making rather neutral whites.',
    'origin and parentage': [
      'A complex hybrid obtained in 1978 by Helmut Becker at the Geisenheim research centre in Germany by crossing ARNSBURGER \u00d7 Seyve-Villard 1-72, where Seyve-Villard 1-72 is a RAYON D\u2019OR \u00d7 Bertille Seyve 450 hybrid and Bertille Seyve 450 is a NOAH \u00d7 Vivarais hybrid (see PRIOR for the complete Vivarais pedigree). Authorized in 2004.',
    ],
    'where its grown': [
      'There are experimental plantings in the canton of Z\u00fcrich in Switzerland and Martin Buser produces a varietal wine in the Swiss Jura, close to the French border. There are some vines in Liechtenstein and very limited commercial production in Germany (eg Weingut Sch\u00f6nhals in Rheinhessen). The wines are similar to PINOT BLANC but acidity can be very high if the grapes are not fully ripe.',
    ],
  },
  {
    name: 'SARY PANDAS',
    synonyms: ['Sarah Pandas'],
    description:
      'Old Ukrainian variety planted mainly in the south of the country for dessert wines.',
    'origin and parentage': [
      'Sary Pandas is an old variety from Solnechnaya Dolina in the Sudak district of Krym (Crimea), southern Ukraine. It was first described in 1803 by Palas (Chkhartishvili and Betsiashvili 2004).',
    ],
    'where its grown': [
      'Found mainly in the Sudak district of Krym, southern Ukraine, Sary Pandas has the advantage of attaining high sugar levels in the berries while retaining fresh acidity as long as the vines are in warm, sunny sites. It is generally made into amber-coloured, fragrant, spicy, full-bodied dessert wines, often in a blend, as in that produced by Sun Valley (Solnechnaya Dolina), and is rarely encountered as a dry wine. It is also found in Russia.',
    ],
  },
  {
    name: 'SAUVIGNON BLANC',
    synonyms: [
      'Blanc Fum\u00e9',
      'Blanc Fumet',
      'Fi\u00e9',
      'Fiers',
      'Fum\u00e9',
      'Fum\u00e9 Blanc',
      'Muskat-Silvaner',
      'Mu\u0161katni Silvanec',
      'Sauternes',
      'Sauvignon Fum\u00e9',
      'Sauvignon Musqu\u00e9',
      'Sauvignon Blanc Musqu\u00e9',
      'Savagnou',
      'Sotern M\u0103runt',
      'Surin',
      'Verdo Bel\u00eei',
    ],
    description:
      'Parent of Cabernet Sauvignon producing increasingly appreciated aromatic, zesty, crisp whites.',
    'origin and parentage': [
      'Contrary to common belief, Sauvignon Blanc is unlikely to come from the Bordeaux area, where it was first mentioned between 1710 and 1720 in Margaux: \u2018le blanc dont le raisin est pour la plupart du Sauvignon\u2019 (\u2018the white [wine] in which the grape is for the most part Sauvignon\u2019). Instead it is more likely to have originated from the Val de Loire in France, where its earliest mention appeared under the old synonym Fiers in 1534 in chapter 25 of Fran\u00e7ois Rabelais\u2019 Gargantua: \u2018Car notez que cest viande celeste manger a desieuner raisin auec fouace fraische; mesmement des pineaulx, des fiers, des muscadeaulx, de la bicane et des foyrars pour ceulx qui sont constipez du ventre\u2019 (\u2018note that it is heavenly meat to eat grapes with fresh fouace [a local pastry] for lunch; also pineaulx, fiers, muscadeaulx, bicane and foyrars for those who have a constipated stomach\u2019).',
      'Under the name Sauvignon Fum\u00e9 or Blanc Fum\u00e9, it was first mentioned in 1783\u20134 in Sancerre and Pouilly in the Cher d\u00e9partement in central France, where it has won its spurs over the last two centuries. DNA parentage analysis also supports a Val de Loire origin for Sauvignon Blanc (see CABERNET SAUVIGNON for the complete pedigree) for the reasons outlined below.',
      'Sauvignon Blanc has a parent\u2013offspring relationship with SAVAGNIN (Regner 1999), which explains why the two varieties have sometimes been confused. Since Savagnin is mentioned much earlier in the literature, it would logically be the parent and Sauvignon Blanc the progeny, the other parent being unknown. This makes Sauvignon Blanc a half-sibling of Savagnin\u2019s other progenies (AUBIN BLANC, B\u00c9QUINOL NOIR, GR\u00dcNER VELTLINER, PETIT MANSENG, PETIT MESLIER, R\u00c4USCHLING, ROTGIPFLER, SILVANER, TEINTURIER, VERDELHO) and a grandchild of PINOT (if we consider Savagnin to be an offspring of Pinot; see that entry). Since Savagnin most likely originates from north-eastern France and has never been mentioned in western France, Sauvignon Blanc was probably born somewhere in between.',
      'Statistical analyses of the DNA profiles suggest that Sauvignon Blanc is a sibling of CHENIN BLANC (Vouillamoz), the most important variety in the Val de Loire, as well as a sibling of TROUSSEAU (analysed under the name Merenzao in Santana et al. 2010), an old variety from the Jura in eastern France. As a consequence, the genetic and historical links strongly support a Loire origin for Sauvignon Blanc as well.',
      'In addition, Sauvignon Blanc belongs to the Messile ampelographic group (see p XXVII), together with B\u00c9QUIGNOL NOIR, CHENIN BLANC, MENU PINEAU, MESLIER SAINT-FRAN\u00c7OIS, PETIT MESLIER and PINEAU D\u2019AUNIS, all varieties that are typical of northern France, which strengthens the case for a Loire origin for Sauvignon Blanc. From there, Sauvignon Blanc spread to the Gironde, where it spontaneously crossed with CABERNET FRANC to produce CABERNET SAUVIGNON (Bowers and Meredith 1997).',
      'This Loire origin is also supported by the etymology of the name Sauvignon, which probably comes from the French sauvage, meaning \u2018wild\u2019, because the shape of Sauvignon Blanc\u2019s leaves is similar to those of the wild grapevine. This origin and etymology are supported by the old synonyms Fi\u00e9 or Fiers (in the Loire and the Vienne valleys), which come from the Latin ferus, meaning \u2018wild\u2019 (R\u00e9zeau 1997). It should be noted that the wild vine Vitis vinifera subsp. silvestris is still present in the Loire, whereas it has never been observed in the Gironde (Arnold et al. 1998).',
      'Recent DNA studies have suggested that Sauvignon Blanc and S\u00c9MILLON are genetically very close (Jahnke et al. 2009) but a parent\u2013offspring relationship can be rejected (Vouillamoz).',
      'At least two colour mutations of Sauvignon Blanc have been observed: Sauvignon Gris, also called Fi\u00e9, Fiers, Surin Gris or Sauvignon Rose, and Sauvignon Rouge, both grown to a very limited extent in the Val de Loire.',
      'Sauvignon Blanc was used to breed ARRILOBA, INCROCIO BRUNI 54 and SIRMIUM.',
    ],
    'where its grown': [
      'Sauvignon Blanc has spread rampantly around the world, just as its foliage is inclined to do if not held in check. It has been the most obvious beneficiary of a certain boredom with both CHARDONNAY and obvious oak. Sauvignon the wine is typically high in acidity with a range of aromas generally associated with things green \u2013 grass, leaves, nettles and gooseberries \u2013 provided the grapes are picked early enough. \u2018Cat\u2019s pee on a gooseberry bush\u2019 became a popular description of the aroma of some early-picked Sauvignon in the late twentieth century. The wine produced by overripe grapes, on the other hand, can be relatively dull.',
      'The origin of Sauvignon Blanc\u2019s particularly distinctive aromas, and the way they may be influenced in the vineyard and the winery, have provoked a considerable amount of research over many years, particularly since the mid 1990s. Studies in the last twenty or so years focused first on the influence of flavour compounds called methoxypyrazines on the aroma and flavour of Sauvignon Blanc, and the impact of climate, sunlight and harvesting time on the intensity of the herbaceous flavours for which they are responsible.',
      'More recently, there has been further research into the key role played by volatile thiols, compounds formed after alcoholic fermentation by the interaction between yeast and the sulphur in the lees due to certain aroma precursors barely detectable in the fruit prior to fermentation and influenced by yeast strain, enzymes and fermentation conditions (Dubourdieu 2009). Thiols typically contribute wide-ranging aromas from boxwood and cat\u2019s pee via grapefruit and passion fruit to flinty, smoky notes.',
      'Sauvignon Blanc is the third most planted white wine variety in France, after Ugni Blanc (TREBBIANO TOSCANO) and CHARDONNAY, with total plantings in 2009 of 26,839 ha (66,321 acres). The French region with the greatest area planted is, rather surprisingly, Languedoc-Roussillon with 7,357 ha (18,180 acres), making Sauvignon Blanc the second most important white wine variety there after Chardonnay\u2019s 12,156 ha (30,038 acres). Southern France can be a little warm for Sauvignon Blanc and many of the wines produced, typically labelled Pays d\u2019Oc, are simply vaguely tart white wines. Slightly zestier Sauvignon is grown in the Gers (armagnac country), where there are 1,801 ha (4,450 acres), and in the nearby Tarn (460 ha/1,137 acres). Simple Vins de Pays are also made in cognac country, where there are about 520 ha (1,285 acres).',
      'Sauvignon Blanc makes much more serious wines in the Aquitaine region comprising Bordeaux and Bergerac, where Sauvignon Blanc\u2019s plantings total 7,117 ha (17,586 acres), making it the second most planted white wine variety after S\u00c9MILLON\u2019s 11,348 ha (28,042 acres). The great majority, 5,142 ha (12,706 acres), are in the Gironde, the Bordeaux d\u00e9partement, where it is often blended with the fatter, richer S\u00e9millon. Sauvignon typically adds zest to sweet white bordeaux in which it is a minority partner and has become increasingly important in the serious dry white bordeaux, notably in Pessac-L\u00e9ognan, where it is generally blended with S\u00e9millon and aged in oak. All-Sauvignon dry white bordeaux has grown in popularity, however, from inexpensive wines designed to be drunk young to such rarities as Pavillon Blanc de Ch\u00e2teau Margaux. The 1,171 ha (2,894 acres) of Sauvignon Blanc grown in the Dordogne are typically blended with S\u00e9millon, occasionally MUSCADELLE, to make Bergerac Blanc.',
      'But it is in the Loire that Sauvignon Blanc finds its purest expression in France, notably in Sancerre in the Cher d\u00e9partement (2,961 ha/7,317 acres in 2009) and Pouilly-Fum\u00e9 in the Ni\u00e8vre d\u00e9partement (1,317 ha/3,254 acres), where the variety is presented unblended and unoaked but, in the best examples, as expressions of very local terroir. Basic wines may simply be tart, bone dry and with vaguely refreshing aromas of green fruits but the finest can demand a year or more in bottle, and then time in the glass, to unfurl sufficiently to display the sometimes austere but usually intriguing and refreshing character imbued by the particular permutations and dispositions of flint, limestone and clay that underlie each vineyard. Menetou-Salon, Reuilly, Quincy and Coteaux du Giennois are more rural versions of Sancerre but the other great concentration of Loire Sauvignon is in Touraine, where easy, gooseberry-flavoured, well-priced expressions of Sauvignon Blanc fruit can be found. More than 3,000 ha (7,413 acres) of Sauvignon Blanc are grown upstream of Tours, used mainly for wines of this sort.',
      'Even Burgundy has a Sauvignon Blanc outpost in the light, racy, bone-dry wines of St Bris (146 ha/361 acres).',
      'Sauvignon is not an especially important variety in Italy. The 2000 agricultural census identified only 3,393 ha (8,384 acres), overwhelmingly in the north east. In Piemonte, Gaja manages to display some genuine Sauvignon Blanc flavour in his Alteni di Brassica and there are isolated other examples in north-western Italy but most Italian Sauvignon Blanc is grown in the Alto Adige and Friuli. Flavours can be a little muted but there are thrilling exceptions such as the Cantino Terlano\u2019s Quarz and Colterenzio\u2019s Lafoa in the Alto Adige and Vie de Romans\u2019 bottling from Isonzo. Much of southern Italy is too hot for the preservation of Sauvignon aroma but producers as grand as Ornellaia in Bolgheri and Biondi-Santi in Montalcino have persisted with it.',
      'Much of Spain is also too hot for Sauvignon. In 2008 the national total was just 2,515 ha (6,215 acres), with the biggest areas in Castilla-La Mancha (1,700 ha/4,201 acres) for often humdrum inexpensive varietals, and Castilla-Le\u00f3n (473 ha/1,169 acres), where Sauvignon Blanc has become important, like VERDEJO, in fashionable Rueda. Good examples of Sauvignon-based Rueda are produced by Marqu\u00e9s de Riscal, Vinos Sanz (notably Finca La Colina) and Sitios de Bodega (Palacio de Menade). Modernizers in Catalunya have planted nearly 500 ha (1,236 acres) there, too, with Torres\u2019 Fransola vineyard in Pened\u00e8s producing a distinctive, barrel-fermented example.',
      'In 2010 only 26 ha (64 acres) of Sauvignon were grown in Portugal. When not used in varietal wines, it is often blended with ARINTO DE BUCELAS, which gives the wine more acidity but most of the varietal character is lost. Quinta do Cidr\u00f4 (belonging to Real Companhia Velha) produce a fine, high-altitude Sauvignon on the southern edge of the Douro and it\u2019s also grown in the Tejo.',
      'RIESLING and the like provide the great majority of Germany\u2019s aromatic, unoaked white wine but\na little Sauvignon Blanc, often called Muskat-Silvaner, is grown in such southern regions as W\u00fcrttemberg, Franken and, especially, the Pfalz. The variety is much more important, and notably successful, in Austria, where the total area planted more than doubled in the first decade of this century. There were 753 ha (1,861 acres) in 2007, more than half in the Steiermark (Styria) in the south and the rest scattered throughout Nieder\u00f6sterreich (Lower Austria) but especially in the Weinviertel. Recommended producers of wines that typically combine the refreshment of Sancerre with the creaminess of a rich white burgundy in the S\u00fcdsteiermark (South Styria) include Erich and Walter Polz, Erwin Sabathi, Sattlerhof, Manfred Tement and Ewald Zweytick. R & A Pfaffl make some of the finest Sauvignon in the Weinviertel.',
      'Switzerland\u2019s\n133 ha (329 acres) of Sauvignon Blanc in 2009 were scattered across the country. Recommended producers include Peter and Rosi Hermann in Graub\u00fcnden, Domaines des Balisiers and de la Planta in Gen\u00e8ve and Stefan Gysel Saxer in Schaffhausen.',
      'Sauvignon Blanc is relatively important in the Czech Republic, where 869 ha (2,147 acres) are grown, all in Morava in the south east. Recommended producers include Ji\u0159\u00ed Barab\u00e1\u0161 (Hodonice), Franti\u0161ek M\u00e1dl (Velk\u00e9 B\u00edlovice), Vinselekt Michlovsky (Rakvice) and Trp\u011blka & Oulehla (Nov\u00e9 Br\u00e1nice).',
      'Sauvignon Blanc, sometimes known as Mu\u0161katni Silvanec (literally, Muscat Silvaner) in Slovenia, grows both around Trieste in Primorska in the south west and especially in \u0160tajerska Slovenija (Slovenian Styria) in the north-eastern Podravje region, across the border from Austria\u2019s S\u00fcdsteiermark, where the variety thrives in dry, piercingly mineral unoaked styles and fuller, richer oaked ones. There are just 390 ha (964 acres) of Sauvignon Blanc in Croatia, mainly in Kontinentalna Hrvatska (Continental Croatia) since this is where the international varieties congregate.',
      'Romania\u2019s 3,243 ha (8,014 acres) in 2008 were quite widely dispersed but with particular concentrations in Constan\u0163a near the Black Sea in the south east, Valcea in the centre and Vrancea further east.',
      'The variety is widely planted in the Republic of Moldova (8,151 ha/20,142 acres in 2009), in Krasnodar Krai in southern Russia (900 ha/2,223 acres in 2009) and to a lesser extent in Ukraine (432 ha/1,067 acres in 2009) \u2013 although all of these locations are a little warm for really stimulating varietal expression.',
      'Although the national total area devoted to Sauvignon in Greece is not that high, the variety is regarded as a usefully crisp, aromatic blending ingredient in dry whites based on indigenous varieties. It is also grown on Malta and Cyprus (35 ha/86 acres). There were 158 ha (390 acres) in Turkey in 2010 with Sevilen making a particularly successful Fum\u00e9 Blanc grown at 900 m altitude on the G\u00fcney plateau inland from \u0130zmir. Sauvignon Blanc was Israel\u2019s most widely planted white wine grape in 2009, with around 250 ha (618 acres).',
      'California grew 15,407 acres (6,235 ha) of Sauvignon Blanc in 2010, a marked fashion for the variety seeming to have peaked. The biggest areas are in Sonoma (2,512 acres/1,017 ha) and Napa (2,496 acres/1,010 ha) counties, closely followed by San Joaquin in the Central Valley (1,996 acres/808 ha). Much of the state\u2019s wine country is too hot to produce Sauvignon Blanc with grassy aromas and a California Sauvignon Blanc is notably richer and softer than most of the world\u2019s Sauvignon, often lightly oaked and with melon-like aromas in warmer parts of the North Coast, but there are certainly fog-cooled parts of the Central Coast, particularly Santa Ynez Valley and Happy Canyon, well able to make distinctive wines with real tension.',
      'It was David Stare of Dry Creek Vineyard in Sonoma\u2019s Dry Creek Valley who first coined the term Fum\u00e9 Blanc for Sauvignon Blanc. It was adopted in the mid 1970s by Robert Mondavi and has been used far and wide ever since, without much rigour but usually for marketing reasons. Particularly successful California Sauvignons have been made by Araujo, Benzinger Family, Brander Vineyard, Dry Creek Vineyard, Merry Edwards, Robert Mondavi, Sbragia Family, Star Lane and Westerly Vineyards.',
      'The golden-berried clone known as Sauvignon Musqu\u00e9, Sauvignon Blanc (Musqu\u00e9) or clone 27 in Calfornia has been shown by DNA analysis to be identical to Sauvignon Blanc (as the French grape expert Pierre Galet had previously adjudged on ampelographic grounds) but the flavours tend to be richer, rounder, sometimes more floral or freshly tropical and less feline, and it is easier to get the grapes to ripen fully compared with those of the more common Wente clone. However, the wines do not generally have a Muscat-like aroma, as the name might suggest. Many top California Sauvignon Blancs include a percentage of this clone and its success in California has encouraged viticulturist Richard Smart to take it to Tasmania.',
      'In California (and sometimes elsewhere) there has been a growing trend to encourage botrytis infections of Sauvignon Blanc in order to produce sweet, Sauternes-like wines which can be charmingly luscious in youth.',
      'There were just 79 acres (32 ha) of Sauvignon Blanc in Oregon in 2008. Washington State\u2019s\n2011 total was 1,173 acres (475 ha), making it the state\u2019s fourth most popular white variety, having been overtaken by PINOT GRIS. It is also waning in Texas, where only 40 acres (16 ha) remained in 2008. New York State produces some Sauvignon Blanc and Virginia had 45 acres (18 ha) in the ground in 2010. It is also found in Mexico, although varietal aroma must be difficult to retain here. It is not an especially significant variety in Canada although a certain amount goes into some convincing Bordeaux blends with S\u00c9MILLON in British Columbia.',
      'Sauvignon Blanc has had an interesting history in Chile (see SAUVIGNONASSE). Plantings of true Sauvignon Blanc continue to increase so that, according to official records, they had reached 7,922 ha (19,576 acres) by 2008, predominantly in Curic\u00f3 (3,819 ha/9,437 acres) and Maule (1,744 ha/4,310 acres) but with increasing plantings in the cooler northern regions such as Casablanca (1,085 ha/2,681 acres). However, although all these newer plantings in the north are likely to be true Sauvignon Blanc, it is highly likely that more than half the so-called Sauvignon Blanc vines in Curic\u00f3 and Maule, especially the older vines, are in fact SAUVIGNONASSE, because Sauvignon Blanc is a legal synonym for Sauvignonasse. Sauvignon does well in Chile and quality is improving all the time. Superior examples from regions north of Santiago, where the variety seems to perform best, include Amayna, Montes, O Fournier and Vi\u00f1a Leyda made from fruit grown in the Leyda Valley; and Casa Marin and Matetic in the San Antonio Valley.',
      'Sauvignon Blanc is still a relatively minor variety in Argentina,\nwhere a total of 2,090 ha (5,165 acres) was planted by 2008. Most Argentine Sauvignon is rather flabby or insipid but Chakana, O Fournier, Pulenta Estate, Finca Sophenia and especially Tapiz provide exceptions to the rule. Uruguay had just 113 ha (279 acres) of Sauvignon and Bolivia 35 ha (86 acres) in 2009.',
      'Australia\u2019s total plantings of Sauvignon Blanc, on the other hand, were 6,405 ha (15,827 acres) in 2008, making it the third most planted white variety after CHARDONNAY and S\u00c9MILLON. It is only in very recent years that the variety has become popular there, perhaps partly driven by the success of New Zealand Sauvignon (see below); there were only about 20 ha (49 acres) in the mid 1970s. The crush doubled between 2003 and 2004 and it seems the Australians can\u2019t get enough \u2018Savvy\u2019, even with the tide of Kiwi Sauvignon Blanc that continues to sweep over the Tasman. Early examples in the 1980s and 1990s were sometimes a bit oily, until the variety took to cooler regions such as the Adelaide Hills, Coonawarra, Western Australia and Tasmania. Blends with Semillon are particularly successful in Western Australia, eg from producers such as Cape Mentelle, Larry Cherubino, Cullen and Stella Bella. Excellent examples are also made in the Yarra Valley, from PHI, for example; in the Adelaide Hills, in the hands of Shaw and Smith among others; and at Domaine A on Tasmania.',
      'But it is New Zealand that claims the Sauvignon Blanc crown. In fact patriotic producers like to suggest that New Zealand is the home of the variety, despite its very short history there. First planted in the 1970s, Sauvignon Blanc alone was responsible for the extraordinary vineyard expansion of the late twentieth and early twenty-first centuries in New Zealand, especially Marlborough in the north of the South Island, where thousands of hectares of farmland have now been planted to this profitable variety. High yields, no expensive oak barrels, little ageing to speak of and a market primed by the extraordinary global success of Cloudy Bay, now a sister company of Mo\u00ebt & Chandon champagne, combined to make Sauvignon Blanc look a most attractive cash crop. Needless to say, a glut has followed and there have been virtually no new plantings since 2009, but the variety\u2019s 2011 total was officially estimated at 18,000 ha (44,479 acres), of which 15,700 ha (38,796 acres) are in Marlborough. This puts it way ahead of any other variety in the country, its nearest competitor being PINOT NOIR with around 5,000 ha (12,355 acres). The pungent, almost aggressively fruity but always razor-sharp style of Marlborough Sauvignon has been most obviously successful and the characters of specific subregions are now being identified. Although the variety is by no means restricted to the north of the South Island, the dominant style is unoaked, fruit-driven, and into the glass as quickly as possible. Recent years have seen increasing subtlety and the occasionally successful oaked version, however. Recommended producers include Babich, Dog Point, Isabel Estate, Jackson Estate, Brancott, St Clair, Seresin and Spy Valley in Marlborough; Palliser in Martinborough; and Neudorf in Nelson.',
      'South African Sauvignon Blanc is a little more muted than that of New Zealand but can be attractively balanced, with reasonably demure fruit and Antarctic influences keeping the wines refreshing. In 2008 a total of 9,155 ha (22,622 acres) made it the country\u2019s third most planted white variety after CHENIN BLANC and COLOMBARD \u2013 more important than CHARDONNAY \u2013 with plantings widely spread around the regions but with the greatest hectarage in Stellenbosch. Recommended producers include Kaapzicht, Mulderbosch, Quoin Rock, Vergelegen and Waterkloof in Stellenbosch; Paul Cluver and Iona in Elgin; Chamonix in Franschhoek; The Berrio and Strandveld in Elim; Springfield in Robertson; Klein Constantia and Steenberg in Constantia; Cape Point Vineyards in Cape Point; and Fryer\u2019s Cove in Bamboes Bay.',
      'Sauvignon Blanc is also found in India and Japan.',
      'SAUVIGNON GRIS/ROSE and SAUVIGNON ROUGE',
      'Of the colour mutations Sauvignon Gris/Rose and Sauvignon Rouge, the former is the more widely planted and seems to be increasingly fashionable. The total in France was 463 ha (1,144 acres) in 2009, and seems to be on the increase. In the Loire, it is known as Fi\u00e9 and it is not officially permitted in some areas, such as Sancerre. However, the fruit for Henri Bourgeois\u2019 Sancerre Jadis is harvested from old vineyards that include a good proportion of Fi\u00e9. Other recommended producers include Domaine de l\u2019Aujardi\u00e8re in Muscadet country, Paul Buisse at Montrichard, Clos des Ronceveaux in Touraine and Fred Brochet at Ampelidae in the southern Loire. But it is also increasingly found in dry white bordeaux, generally blended as an ingredient that adds rather heady perfume and a bit of weight to Sauvignon Blanc.',
      'There are also more and more examples further afield, especially in Chile \u2013 for instance chez Cousi\u00f1o-Macul, Vi\u00f1a Leyda and Casa Marin. It is also planted in Argentina, Uruguay and New Zealand.',
    ],
  },
  {
    name: 'SAUVIGNONASSE',
    synonyms: [
      'Friulano',
      'Occhio di Gatto',
      'Sauvignon de la Corr\u00e8ze',
      'Sauvignon Gros Grain',
      'Sauvignon Vert',
      'Tai Bianco',
      'Tocai',
      'Tocai Friulano',
      'Tocai Italico',
      'Tocai Italico Friulano',
      'Zeleni Sauvignon',
    ],
    description:
      'Lightly aromatic variety now called Friulano in Italy and for long confused with Sauvignon Blanc in Chile.',
    'origin and parentage': [
      'Sauvignonasse is an old variety from the Gironde region of south-west France, where it has never been widely cultivated. Sauvignonasse is not, as its name might suggest, a progeny of SAUVIGNON BLANC, as DNA parentage analysis has shown (Vouillamoz).',
      'This variety was introduced to Friuli, north-east Italy, in the early nineteenth century and given the name Tokai (Acerbi 1825; Di Rovasenda 1877), the modern name Tocai Friulano appearing in the 1930s (Giavedoni and Gily 2005). As with PINOT GRIS in Alsace, it was deliberately called Tokai/Tocai in order to take advantage of the name of the internationally famed Tokaji wines made mainly from FURMINT in north-eastern Hungary. And it worked: the identity of this variety has been long debated, and until the 1990s most Italian growers and ampelographers considered Tocai Friulano to be unique, possibly originating from Hungary. However, ampelographic studies (Cal\u00f2 and Costacurta 1992) as well as DNA profiling (Crespan et al. 2003) have clearly established that Tocai Friulano and Sauvignonasse are one and the same.',
      'Ampelography and DNA profiling show that the variety known as Sauvignon Vert in California is in fact MUSCADELLE (Galet 2000; Vouillamoz).',
    ],
    'where its grown': [
      'Sauvignonasse was often cultivated with SAUVIGNON BLANC and S\u00c9MILLON in the Gironde in south-west France but the area is now drastically reduced, to the extent that Sauvignonasse can no longer be found in the official register of varieties.',
      'Under the name Tocai Friulano and its various synonyms, this variety has thrived in north-east Italy and continues to do so, even under its new name, Friulano. It is used in varietal and blended wines in many different appellations, mostly in Friuli (eg Collio, Colli Orientali del Friuli, Friuli Grave, Friuli Izonzo) but also in Lombardia (eg Garda Colli Mantovani) and the Veneto (eg Colli Berici, Colli Euganei, Lison-Pramaggiore). In 2000, when the last Italian agricultural census was taken, growers in Friuli were still nonchalantly calling their vines Tocai Friulano and a total of 4,698 ha (11,609 acres) was recorded, the vast majority in the north east. Under pressure from the Tokaj wine region in Hungary since 1993, the Council of the European Union agreed to ban the use of the name Tocai Friulano from Italian wine labels, with effect from 2008, and, after much debate and not a little dissent, the Italians adopted the name Friulano for this variety.',
      'Recommended producers include Canus, La Castellada, Colle Duga, Livio Felluga, Adriano Gigante, Edi Keber, Doro Princic, Dario Raccaro, Ronco del Gelso, Schiopetto, Franco Toros, Venica & Venica and Volpe Pasini. Wines often have both herbal and almond flavours, sometimes more on the grassy side, and are neither as crisp nor as aromatic as SAUVIGNON BLANC but may well be fuller-bodied.',
      'Similar naming issues exist over the border in Slovenia, where there were 29 ha (195 acres) of Zeleni Sauvignon in 2009, mainly in the Primorska region close to Friuli, although there are also significant plantings in \u0160tajerska Slovenija (Slovenian Styria) in the north east. In Slovenia there has been official agreement to use either Zeleni Sauvignon or Sauvignonasse but some producers are unhappy with both and prefer to give their wines a fantasy name such as Jakot, Pikotno or Gredi\u010d instead, without referring to the grape variety. Recommended producers include Bla\u017ei\u010d, Kabaj, Marjan Sim\u010di\u010d and Kocijan\u010di\u010d Zanut. It is also found in Ukraine (2,691 ha/6,650 acres in 2009) and to a limited extent in Krasnodar Krai in Russia (51 ha/126 acres in 2010).',
      'It is highly likely that some of the older plantings of so-called SAUVIGNON BLANC in Chile\u2019s Curic\u00f3 and Maule valleys are in fact Sauvignonasse but exact figures are hard to come by as the latter may legally still be labelled as the former. However, many of the older Sauvignonasse vines are now being grafted over to Sauvignon Blanc and all the new plantings certainly are. Official statistics for Sauvignon Vert, as Sauvignonasse is officially known in Chile, reported 234 ha (578 acres) in 2008. In Argentina, there were 659 ha (1,628 acres) in 2008, almost all in the Mendoza region. Finca La Anita make a particularly good and surprisingly ageworthy example using fruit from the Alto Agrelo subregion.',
    ],
  },
  {
    name: 'SAVAGNIN BLANC',
    synonyms: [
      'Bryn\u0161t',
      'Edler Weiss',
      'Weissedler',
      'Formentin',
      'Fromenteau',
      'Fourmenteau',
      'Fourmentans',
      'Formentin',
      'Fr\u00e4nkisch',
      'Frennschen',
      'Weiss Frennschen',
      'Gentil Blanc',
      'Heida',
      'Malvoisie',
      'Natur\u00e9',
      'Naturel',
      'Pa\u00efen',
      'Pryn\u010d',
      'Sauvagnin',
      'Savagnin Jaune',
      'Savagnin Vert',
      'Tram\u00edn B\u00edl\u00fd',
      'Traminec',
      'Traminer',
      'Weisser Traminer',
    ],
    description:
      'Full-bodied, firm, ageworthy wines, sometimes called Traminer.',
    'where its grown': [
      'Savagnin Blanc (or Traminer) has the potential to produce high-quality varietal wines that are powerful, heady, well structured and ageworthy. In France, where there were 481 ha (1,189 acres) of Savagnin Blanc in 2009, it is particularly valued in the Jura for the production of vin jaune, a wine aged under flor and with some similarity to dry sherry although it is not fortified. The Ch\u00e2teau Chalon appellation is exclusively for vin jaune. Full-bodied dry white wines are also produced here from Savagnin Blanc, now in two main styles: the traditional, golden, nutty, oxidative version and the more modern, non-oxidative one, occasionally blended with CHARDONNAY. Recommended Jura producers of Savagnin-based wines include Daniel Dugois, Domaine Lornet, Jacques Puffeney, Domaine de la Renardi\u00e8re, Andr\u00e9 and Mireille Tissot and La Tournelle in Arbois, and Ch\u00e2teau d\u2019Arlay, Domaine Baud and Les Chais du Vieux Bourg in the C\u00f4tes du Jura. Berthet-Bondet, Jean Bourdy and Jean Macle are particularly renowned for Ch\u00e2teau Chalon.',
      'Austria recorded 321 ha (793 acres) of Traminer in 2009 but these vines are likely to be Roter Traminer (see Savagnin Rose below) and Gew\u00fcrztraminer. In Krems and the S\u00fcdoststeiermark (South-East Styria), the rare yellow-berried mutation Gelber Traminer (or Traminer Gelb) has been preserved and producers such as Knoll and Salomon Undhof make extremely floral, high-acid, dry and sweet wines from it.',
      'In Switzerland, Heida or Pa\u00efen, as Savagnin Blanc is known, is a traditional speciality of the high-altitude vineyards (1,100 m/3,600 ft) of Visperterminen in the Valais, which produce full-bodied, lightly aromatic, flavourful and gently spicy whites. St Jodern\u2019s Heida Veritas (Visperterminen) from ungrafted vines and Chanton\u2019s Heida (Visp) are good examples. Other recommended producers include G\u00e9rald et Patricia Besse (Martigny-Combe), Didier Joris (Chamoson) and Provins Valais (Sion).',
      'Traminer is also grown in the Czech Republic, where it is known as Pryn\u010d or Bryn\u0161t, although its official name is Tram\u00edn B\u00edl\u00fd.',
      'In Slovenia, it is known as Traminec, of which there were 215 ha (531 acres) in 2009, far more than for Gew\u00fcrztraminer (see below). The great majority of it is in \u0160tajerska Slovenija (Slovenian Styria) in Podravje, in the north east of the country.',
      'Bulgaria had 1,072 ha (2,649 acres) of Traminer in 2009 and although the official statistics do not distinguish between Gew\u00fcrztraminer, also known as Mala Dinka in Bulgaria, and Traminer, the more recent plantings in the north west are likely to be Gew\u00fcrztraminer.',
      'It is also found in Krasnodar Krai in Russia.',
      'There are minor plantings of Savagnin Blanc in Canada, where Ch\u00e2teau des Charmes, in the Niagara-on-the-Lake appellation in Ontario, produce an icewine and occasionally a dry white from this variety.',
      'Australia also has limited but successful plantings of Savagnin Blanc, although this success, which has been encouraging an increasing number of growers to plant the variety, was partly based on the mistaken belief that what they had was the variety known in Spanish as Albari\u00f1o (ALVARINHO). The mix-up was not finally confirmed until 2009, when it was shown that the source material propagated at CSIRO, Australia\u2019s plant research institute, was derived from mis-labelled plant material imported from Spain via France. Now those who were selling wines labelled Albari\u00f1o have to choose whether to change their labels to Savagnin (Blanc) or Traminer or start again and plant Albari\u00f1o. However, the use of the term Traminer could also be confusing since many Australians use Traminer to refer to Gew\u00fcrztraminer.',
    ],
  },
  {
    name: 'SAVAGNIN ROSE',
    synonyms: [
      'Clevener',
      'Clevner',
      'Fromenteau Rouge',
      'Froment\u00e9 Rose',
      'Heidarot',
      'Klevener de Heiligenstein',
      'Piros Tramini',
      'Prin\u00e7',
      'Roter Traminer',
      'Rotfrenschen',
      'Savagnin Rose Non Musqu\u00e9',
      'Savagnin Rouge',
      'Traminac Crveni',
      'Traminer Rot',
      'Traminer Rother',
      'simply Traminer',
    ],
    description:
      'The non-aromatic version of the much more famous Gew\u00fcrztraminer.',
    'where its grown': [
      'Although Savagnin Rose has pink berries, it produces wines that are closer in style to Savagnin Blanc than to the more aromatic pink-berried Gew\u00fcrztraminer. There were 44 ha (109 acres) of Savagnin Rose in France in 2008, almost all in the Heiligenstein area of Alsace (Bourgheim, Gertwiller, Heiligenstein and Obernai) under the name Klevener de Heiligenstein, where it qualifies as a varietally named wine within the Alsace appellation.',
      'In Germany, Roter Traminer is used in the official statistics to refer to both the pink-berried Savagnin Rose and the pink-berried and aromatic Gew\u00fcrztraminer (see below); total plantings in 2008 were 835 ha (2,063 acres), the majority in the Pfalz, Rheinhessen and Baden. The Traminer plantings in Austria\u2019s Burgenland (192 ha/474 acres in 2008) and Nieder\u00f6sterreich (Lower Austria; 118 ha/292 acres) are likely to be Roter Traminer. In the Neusiedlersee region of Burgenland, it is often used in sweet-wine blends.',
      'In Croatia, there were around 470 ha (1,161 acres) of Traminac Crveni in 2009 but this figure could include both Savagnin Rose and Gew\u00fcrztraminer. Ukraine had around 1,000 ha (2,470 acres) of Savagnin Rose in 2009, but again they could be either or both.',
      'Old vines are also likely to be found in other Central European countries interplanted with Traminer or Gew\u00fcrztraminer.',
    ],
  },
  {
    name: 'GEW\u00dcRZTRAMINER',
    synonyms: [
      'Di\u0161e\u010di Traminec',
      'Gentil Aromatique',
      'Gentil Rose Aromatique',
      'Mala Dinka',
      'Rother Muskattraminer',
      'Rusa',
      'Savagnin Rose Aromatique',
      'Tramin \u010cerven\u00fd',
      'Traminac Crveni',
      'Traminer Aromatico',
      'Traminer Musqu\u00e9',
      'Traminer Rose',
      'Traminer Rot',
      'Traminer Rother',
      'Traminer Roz',
      'Tramini',
      'F\u0171syeres Tramini',
      'Piros Tramini',
    ],
    description:
      'One of the most headily and distinctively aromatic varietals of all with strong lychee flavours and high alcohol levels.',
    'origin and parentage': [
      'Gew\u00fcrztraminer is thought to be an aromatic mutation of Savagnin Rose (Galet 2000).',
    ],
    'where its grown': [
      'Gew\u00fcrztraminer, often written Gew\u00fcrztraminer, has become by far the most planted variant of Traminer (Savagnin Blanc). The grapes are certainly notable at harvest for their variegated but incontrovertibly pink colour, which is translated into very deep golden wines, sometimes with a slight coppery tinge. Winemakers unfamiliar with the variety have been known to be panicked into extracting colour and flavour. Gew\u00fcrztraminers also attain higher alcohol levels than most white wines, with over 14% being by no means uncommon, and acidities can correspondingly be precariously low. Because of this the variety is not suited to very warm climates. Malolactic fermentation is almost invariably suppressed for Gew\u00fcrztraminer and steps must be taken to avoid oxidation.',
      'If all goes well, the result is deep-golden, full-bodied wines with a substantial spine and concentrated heady aromas of lychees and rose petals whose acidity level will preserve them while those aromas unfurl. In a lesser year or too hot a climate, the result is respectively either an early-picked, neutral wine or an oppressively oily, flabby one that can easily taste bitter to boot. Really intense, ageworthy examples made from low-yielding or late-picked vines can develop flavours reminiscent of bacon fat, while some of the least intense examples may be given ersatz aroma via a small proportion of MUSCAT in the blend. The distinctive aroma of Gew\u00fcrztraminer is often referred to as \u2018spicy\u2019 by wine tasters simply because Gew\u00fcrz means \u2018spice\u2019, not because it is reminiscent of any particular spice.',
      'France had 3,083 ha (7,618 acres) of Gew\u00fcrztraminer (spelt without the accent) in 2009. The vast majority of these vines (2,928 ha/7,235 acres) are in Alsace, where the variety comprises 19% of the vineyard area. The variety slowly gained ground in the first decade of this century and the total area is double what it was fifty years ago. The variety is also authorized in the Moselle appellation. Wines are very distinctively perfumed, often with aromas of rose petal and lychees as well as exotic fruits, whether they are made in a dry or almost-dry style or whether the grapes are picked late for the sweet Vendange Tardive classification or even later and botrytized for the ultra-sweet S\u00e9lection de Grains Nobles. Recommended producers include Paul Blanck, L\u00e9on Beyer, Marcel Deiss, Willy Gisselbricht, Hugel, Josmeyer, Gustave Lorentz, Andr\u00e9 Ostertag, Schlumberger, Trimbach, Domaine Weinbach and Zind-Humbrecht.',
      'In Italy, it is known as Traminer Aromatico, of which there were 560 ha (1,384 acres) in 2000, almost exclusively in the Alto Adige with the best wines generally coming from the west of the Val d\u2019Adige both north and south of the village of Tramin. Recommended producers include Kuenhof, Alois Lageder, Manincor, Cantina Terlano, Tiefenbrunner, Elena Walch and, appropriately, Cantina Termeno/Tramin, which produces specific crus of this heady variety.',
      'Spain had 247 ha (610 acres) in 2009, mostly in Somontano (Arag\u00f3n) and in Catalunya. Enate in Somontano and Torres and Gramona in Pened\u00e8s make recognizable varietal versions.',
      'Luxembourg had 19 ha (47 acres) of Gew\u00fcrztraminer in 2008.',
      'In Germany, Roter Traminer is used in the official statistics to refer to both Savagnin Rose and Gew\u00fcrtztraminer, although it is likely that most of the 835 ha (2,063 acres) recorded in 2008, notably in the Pfalz, Baden and Rheinhessen, south of where it is most likely to suffer frost damage, refer to the latter. Recommended producers include Bercher and Martin Wassmer in Baden and B\u00fcrgerspital in Franken. Some others tend to make rather oily, heavy examples. At Rhodt in the Pfalz is a Traminer vineyard said to be nearly 400 years old.',
      'The majority of the 81 ha (200 acres) officially described as \u2018Traminer\u2019 in Austria\u2019s Steiermark (Styria) is likely to be Gew\u00fcrztraminer. Switzerland had 50 ha (124 acres) of Gew\u00fcrztraminer in 2010, mainly in the Trois Lacs region and Vaud in the west of the country, in the Valais to the south, and in German-speaking Switzerland to the north and east. In Vully in the canton of Fribourg, Cru de l\u2019H\u00f4pital make a good varietal wine confusingly labelled Traminer.',
      'In Hungary, it is known as Tramini, of which there were 720 ha (1,779 acres) in 2008, grown in most wine-growing regions except Tokaj, with the greatest concentrations in M\u00e1tra, Kuns\u00e1g and M\u00f3r. It does well on clay or loess soils but has better acidity when grown on volcanic rock, eg in Soml\u00f3 in the north west and Balatonfelvid\u00e9k on the north-western side of Lake Balaton. Recommended producers include L\u00e1sl\u00f3 Bussay (Balatonmell\u00e9ke), Eurobor (Tolna) and Sz\u0151l\u0151skert (M\u00e1tra).',
      'In the Czech Republic, it is known as Tramin \u010cerven\u00fd and there were 600 ha (1,483 acres) in 2009, almost all in Morava in the south east.',
      'Slovakia had 363 ha (897 ha) of Tram\u00edn \u010cerven\u00fd in 2009, 1.9% of the vineyard area.',
      'In Slovenia, it is known as Di\u0161e\u010di Traminec and there were just 12 ha (30 acres) in 2008, mostly in \u0160tajerska Slovenija (Slovenian Styria) in Podravje, in the north east of the country.',
      'Romania had 218 ha (539 acres) in 2008, the majority in Transilvania in the centre. It is also found across the border in the Republic of Moldova (2,731 ha/6,748 acres in 2009), and there were 961 ha (2,375 acres) in Ukraine in 2009.',
      'In many of these Eastern European cases, yields are too high to conserve much of the variety\u2019s trademark aroma.',
      'Plantings are not insignificant in California, where there were 1,735 acres (702 ha) in 2010. Once grown in many parts of the state, it is now predominantly in cool locations in coastal counties such as Monterey, Mendocino, Santa Barbara and Sonoma. Recommended producers include Navarro in Anderson Valley; Fetzer, Handly, Husch and Lazy Creek in Mendocino; Alderbrook, DeLoach, Mill Creek and Ravenswood in Sonoma; and Babcock, Bedford, Claiborne and Churchill, Firestone, Fogerty, Thompson and Ventana in the Central Coast.',
      'Gew\u00fcrztraminer played a significant part in the early years of the Oregon wine industry and the likes of Brooks and Erath still make fine examples. There were 217 acres (88 ha) in 2008.',
      'In Washington State,\nthe variety\u2019s acreage has been growing steadily, along with that of RIESLING. There were 775 acres (314 ha) of the variety by 2011 and reliable producers include Chateau Ste Michelle, Snowqualmie and Tsillan Cellars of Lake Chelan.',
      'The variety is quite well suited to the cool climes of the Finger Lakes in New York State, which had 143 acres (58 ha) in 2006. Some of the best-known varietals are from Dr Frank, Keuka Spring, Red Newt, Standing Stone and Herman Wiemer. Michigan had 45 acres (18 ha) in 2006 and there were 15 acres (6 ha) in Virginia in 2008, but most American states appear to produce this hugely recognizable varietal to some extent.',
      'In Canada, there are only very limited plantings in Ontario but there were 644 acres (261 ha) in British Columbia in 2008. Superior producers include Blasted Church, Cedar Creek, Gray Monk, Inniskillin, Kettle Valley, Poplar Grove, Quails\u2019 Gate, Red Rooster, See Ya Later Ranch, Sumac Ridge, Thornhaven and Wild Goose. Mission Hill is one of several successful producers of Gew\u00fcrztraminer icewine.',
      'The variety is a minor one in Chile, where there were only 182 ha (450 acres) in 2008, but producers such as Aresti in Curic\u00f3, Carmen in Colchagua Valley, Casa Marin in San Antonio Valley, Cono Sur as far south as the B\u00edo B\u00edo Valley and Vi\u00f1a Casablanca in Casablanca Valley are all trying hard to produce particularly fresh, zesty examples of it.',
      'Argentina had only 20 ha (49 acres) in 2008 while there were 48 ha (119 acres) in Brazil\u2019s Rio Grand do Sul in 2007.',
      'Australia has a long history with the variety. As a varietal or blended with RIESLING, \u2018Traminer\u2019, as Gew\u00fcrztraminer has often been called in Australia, was a popular, scented, off-dry wine throughout the 1960s and 1970s. In 2008 there were still 840 ha (2,076 acres) of the variety, with the biggest areas in the inland Riverina and Riverland, often used to add aromatic spice to more neutral blends, plus limited plantings in the Hunter Valley, Mudgee, Padthaway, Clare Valley and elsewhere. The best examples are from the Clare Valley, Great Southern and Tasmania. Traditionally, official statistics referred to the variety as Traminer, but now that cuttings thought to be Albari\u00f1o (ALVARINHO) have been shown to be Savagnin Blanc, perhaps more care will be taken with the terminology for Gewurz. Reliable producers of Australian Gew\u00fcrztraminer include Pirie in Tasmania and Knappstein in the Clare Valley.',
      'There were 316 ha (781 acres) of Gew\u00fcrztraminer in New Zealand in 2008 and the total is expected to remain stable, although this \u2018aromatic\u2019 is a notable speciality here, partly thanks to reliably crisp acidity. Most of it grows in Gisborne and Hawke\u2019s Bay on the North Island and Marlborough on the South Island. Vinoptima in Gisborne is a producer devoted to the variety and makes an excellent late-harvest version modelled on a fine Alsace Vendange Tardive. Other recommended producers include Dry River, Framingham, Hunter\u2019s, Lawson\u2019s Dry Hills, Saint Clair, Seresin, Spy Valley and Te Whare Ra.',
      'It is something of a minority interest in South Africa, where it can take on a rather simplistic, talc-like character, but Paul Cluver and Woolworths have earned respect for theirs. There were 122 ha (301 acres) in 2008, mainly in Robertson, then Stellenbosch.',
      'Gew\u00fcrztraminer is also found in Japan and Israel.',
    ],
  },
  {
    name: 'SAVATIANO',
    synonyms: [
      'Dobraina Aspri',
      'Doubraina Aspri',
      'Doumpraina Lefki',
      'Kountoura Aspri',
      'Perahoritiko',
      'Sakeiko',
      'Savathiano',
      'Savvatiano',
      'Stamatiano',
      'Tsoumprena',
    ],
    description:
      'Greece\u2019s most widely planted variety, used to make retsina but capable of a good deal more.',
    'origin and parentage': [
      'The exact origin of Savatiano is unknown, but it may come from Attik\u00ed, where it has traditionally been cultivated to produce retsina and has many synonyms. The name may derive from the Greek savvato, meaning \u2018Saturday\u2019 (Boutaris 2000).',
      'Although MANDILARIA is sometimes called Kountoura Mavri (mavro means \u2018black\u2019), it is not the black version of Kountoura Aspri, which is a synonym for Savatiano (Vouillamoz).',
    ],
    'where its grown': [
      'Savatiano is the most widely planted grape variety in Greece, with an estimated total production of more than 6 million hectolitres (158.5 million US gallons) in 2010. Its heartlands are in Attik\u00ed, central Greece, and the large island of \u00c9vvoia but it is also found in Makedon\u00eda, the Kykl\u00e1des (Cyclades) islands, the Pelop\u00f3nnisos (Peloponnese) and, to a lesser extent, on Kefaloni\u00e1. Its popularity is due in large part to its role in the production of retsina, sometimes blended with a higher-acid variety such as ASSYRTIKO, and large-volume, inexpensive table wines. The latter tend to be bland, high in alcohol and pretty dull. However, older vines, controlled yields and cooler sites can produce broad but well-balanced wines that go well with food. Recommended producers such as Papagiannakos in Attik\u00ed and Roxani Matsa just outside Athens show what can be achieved with this underrated variety.',
    ],
  },
  {
    name: 'SCHEUREBE',
    synonyms: ['Alzey S 88', 'Dr Wagnerrebe', 'S\u00e4mling 88', 'Scheu 88'],
    description:
      'Underrated and declining early-twentieth-century German cross capable of producing intense, refreshing, dry and sweet wines.',
    'origin and parentage': [
      'Scheurebe was bred by and named after Georg Scheu in 1916 at the Alzey research centre in Rheinhessen. DNA profiling has shown that Scheurebe is not a SILVANER \u00d7 RIESLING cross, as commonly accepted, but a cross between Riesling and an unknown variety (Grando and Frisinghelli 1998).',
    ],
    'where its grown': [
      'Scheurebe was developed specifically for the sandy soils of Rheinhessen, which is where the variety is most widely planted in Germany, followed by the Pfalz and, at quite a distance, by Franken and the Nahe. Germany\u2019s total plantings stand at 1,672 ha (4,132 acres) but they have been in persistent decline over the last few decades: as recently as 2003 there were 2,200 ha (5,436 acres).',
      'This decline is disappointing given the high potential quality of dry and sweet Scheurebe wines. When fully ripe, they retain a refreshing, racy acidity, are filled with rich, intense flavours of blackcurrant and/or grapefruit and demonstrate an impressive ability to age. Like RIESLING, it is also able to translate the characteristics of the vineyard site into nuanced flavours in the glass. Producers such as M\u00fcller-Catoir, Pfeffingen and Lingenfelder in the Pfalz; Wirsching in Franken; and Wittman in Rheinhessen produce remarkable dry versions. Keller in Rheinhessen make an intense, grapefruit-laden botrytized Scheurebe; its sweet brilliance is matched by the best Austrian interpretations of the variety (also known as S\u00e4mling 88) in Neusiedlersee, by Kracher, for example.',
      'A similar decline, from a smaller base, has also been seen in Austria, where there were 511 ha (1,263 acres) in 2007, the majority in Neusiedlersee close to the Hungarian border, to a more limited extent in the Steiermark (Styria) in the south of the country and in the Weinviertel north of Wien (Vienna). Just 5 ha (12 acres) of Scheurebe were grown in Switzerland in 2008.',
      'Scheurebe is also found on 14 acres (6 ha) of vineyard in British Columbia, Canada, and New Zealand recorded just 1 ha (2.5 acres) in 2009.',
    ],
  },
  {
    name: 'SCHIAVA GENTILE',
    synonyms: ['Edelvernatsch', 'Rothervernatsch'],
    description:
      'Small-berried vine making soft, fruity reds in the Alto Adige.',
    'origin and parentage': [
      'Schiava Gentile probably originates from the Alto Adige in the far north of Italy and is a member of the heterogeneous Schiava group. DNA profiling has shown that SCHIAVA GRIGIA, SCHIAVA GROSSA and SCHIAVA LOMBARDA are all distinct varieties (Grando 2000).',
    ],
    'where its grown': [
      'Schiava Gentile is included in numerous DOCs in Trentino-Alto Adige in Italy\u2019s far north, including a varietal wine in the Alto Adige/S\u00fcdtirol DOC, though the regulations for some DOCs do not specify which of the Schiavas they have in mind, or allow any and all. In Lombardia, it may be part of the blend in Botticino and Cellatica DOCs. Varietal wines, labelled Schiava Gentile or Edelvernatsch, are perfumed and fruity, with hints of almonds and violets, and generally soft tannins, very similar to SCHIAVA GRIGIA and SCHIAVA GROSSA. There were 1,181 ha (2,918 acres) in Italy in 2000, according to the agricultural census. Producers of varietal wines, where Gentile or one if its synonyms is specified on the label, include Cantina Aldeno, Franz Haas, Neidermayer and Pravis. Some producers emphasize the lightness of the variety and make a rosato version.',
    ],
  },
  {
    name: 'SCHIAVA GRIGIA',
    synonyms: ['Cenerina', 'Grauervernatsch', 'Grauvernatsch'],
    description:
      'Particularly blueish-berried member of the Alto Adige\u2019s Schiava group of varieties.',
    'origin and parentage': [
      'Schiava Grigia probably originates from the Alto Adige in northern Italy and is a member of the heterogeneous Schiava group. As the berries are violet-blue in colour, it is likely that the name Schiava Grigia (grigia meaning \u2018grey\u2019) derives from the bloom covering them. DNA profiling has proved that Schiava Grigia is not identical to SCHIAVA GENTILE, SCHIAVA GROSSA or SCHIAVA LOMBARDA but that it is the same as the obscure Cenerina in Trentino (Grando 2000).',
    ],
    'where its grown': [
      'Schiava Grigia appears in various DOCs in Trentino-Alto Adige and in the blends for Botticino and Cellatica DOCs in Lombardia in northern Italy, sometimes specified precisely and sometimes more generally as Schiava tout court. Varietal wines are authorized in the Alto Adige/S\u00fcdtirol DOC, labelled Schiava Grigia or Grauvernatsch. They are generally perfumed and fruity, with hints of almonds and violets and light tannins, just like SCHIAVA GENTILE and SCHIAVA GROSSA. Producers of varietal Schiava Grigia include Griesbauer, Cantina San Michele Appiano, Cantina Terlan and Wilhelm Walch.',
    ],
  },
  {
    name: 'SCHIAVA GROSSA',
    synonyms: [
      'Black Hamburg',
      'Black Hamburgh',
      'Blauer Trollinger',
      'Bresciana',
      'Bressana',
      'Frankenthaler',
      'Grossvernatsch',
      'Kleinvernatsch',
      'Meraner Kurtraube',
      'Schiavone',
      'Trollinger',
      'Tschaggele',
      'Uva Meranese',
    ],
    description:
      'The most common Schiava, from the Tirol. Well connected and more widely planted, as Trollinger, in Germany than in Italy. Also a table grape.',
    'origin and parentage': [
      'Schiava Grossa probably originates from the Alto Adige in the far north of Italy and is a member of the heterogeneous Schiava group. As its name suggests, it has larger bunches and berries than the other Schiava varieties. DNA profiling has shown that SCHIAVA GENTILE, SCHIAVA GRIGIA and SCHIAVA LOMBARDA are distinct varieties (Grando 2000). Schiava Grossa was deliberately crossed with RIESLING to produce KERNER and ROTBERGER, with SILVANER to produce BUKETTRAUBE and with PINOT NOIR PR\u00c9COCE to produce HELFENSTEINER. In addition, recent DNA parentage analysis has revealed that both MUSCAT OF HAMBURG and MALVASIA DEL LAZIO are natural crossings of Schiava Grossa \u00d7 MUSCAT OF ALEXANDRIA (Crespan 2003; Lacombe et al. 2007), which makes them siblings (see pedigree diagram below). Recent pedigree reconstruction has also shown that PINOT and Schiava Grossa are the parents of Madeleine Royale (no longer cultivated), and consequently the grandparents of M\u00dcLLER-THURGAU (Vouillamoz and Arnold 2010). Recent DNA parentage analysis has suggested that Schiava Grossa is a possible parent of UVA TOSCA from Emilia-Romagna and, more questionably, of LAGREIN from the Alto Adige (Cipriani et al. 2010).',
    ],
    'where its grown': [
      'Schiava Grossa is the most widespread variety among the Schiava Somethings. In Italy, it is included in various DOCs in Trentino-Alto Adige such as Alto Adige, Caldaro, Casteller, Trentino and Valdadige and in Valtellina close to the Swiss border, sometimes blended with other local varieties including SCHIAVA GENTILE and/or SCHIAVA GRIGIA, LAGREIN or TEROLDEGO. It is also grown in Lombardia under the name Bressana, in particular in the province of Brescia (but this is not the same as the Bressana found in Piemonte). It is made as a varietal wine only in the Alto Adige/S\u00fcdtirol and Valdadige/Etschtaler DOCs, labelled Schiava Grossa or Grossvernatsch, but often simply Schiava or Vernatsch, giving light, perfumed, fruity wines, with hints of almonds and violets and light tannins, just like SCHIAVA GENTILE and SCHIAVA GRIGIA. There were 1,180 ha (2,916 acres) in Italy in 2000.',
      'In Germany, the country\u2019s enthusiasm for red wines pushed total plantings, under the name Trollinger, to 2,491 ha (6,155 acres) by 2008, predominantly in W\u00fcrttemberg, producing pale red wines for local consumption. This name most likely derives from Tirolinger, indicating its origin. Under the same name, it is also found to a very limited extent in Japan.',
      'Because Schiava Grossa is easy to cultivate and does well under glass, it is also widely grown around the world for the table, generally under the names Black Hamburg or Black Tripoli. The vine planted in the mid eighteenth century at Hampton Court Palace in England is a particularly ancient, surviving example.',
    ],
  },
  {
    name: 'SCHIAVA LOMBARDA',
    synonyms: ['Schiava Nera'],
    description: 'Lombardia\u2019s Schiava produces soft, fruity red.',
    'origin and parentage': [
      'As its name suggests, Schiava Lombarda probably originates from Lombardia and is a member of the heterogeneous Schiava group. DNA profiling has disproved any identity with SCHIAVA GENTILE, SCHIAVA GRIGIA or SCHIAVA GROSSA (Grando 2000), which are genetically quite distinct.',
    ],
    'where its grown': [
      'Schiava Lombarda is cultivated principally in Lombardia in northern Italy, in the provinces of Como, Brescia and Bergamo, but also in Trentino-Alto Adige, usually blended with other varieties. The Cantina Bergamasca makes a varietal ros\u00e9, classified as IGT Bergamasca.',
    ],
  },
  {
    name: 'SCHIOPPETTINO',
    synonyms: ['Pocalza', 'Po\u00e7alza', 'Pokalca', 'Pokalza', 'Ribolla Nera'],
    description:
      'Perfumed and fruity Friulian rescued in the 1970s and now thriving.',
    'origin and parentage': [
      'The earliest mention of Schioppettino was probably under its synonym Ribolla Nera in Di Rovasenda (1877) as a grape variety from Udine in Friuli. Morphological observations and DNA profiling have confirmed that Schioppettino is not a black variant of RIBOLLA GIALLA (Crespan et al. 2011). Its name is said to derive from scoppiettio, meaning \u2018crackle\u2019, either because Schioppettino wines used to be slightly fizzy or because its thick-skinned berries \u2018crackle\u2019 in the mouth. Originally Schioppettino was the name of the wine made from Ribolla Nera but it is now used as the name of the variety itself.',
    ],
    'where its grown': [
      'Like PIGNOLO and TAZZELENGHE, Schioppettino had almost disappeared in the early twentieth century after Friuli in north-east Italy was hit by phylloxera. The variety was rehabilitated in the 1970s thanks to the efforts of the mayor of Prepotto and Paolo and Dina Rapuzzi from Cialla, within the commune of Prepotto very close to the Slovenian border. In 1976 the Rapuzzis won the first Nonino Risit d\u2019Aur award, set up by the Nonino family to encourage those in Friuli who persevere with endangered indigenous varieties.',
      'Paolo Rapuzzi had just established his winery when he heard several local senior citizens talking about a forgotten variety called Pocalza or Schioppettino. He began his search for this variety and found some vines in the mayor\u2019s garden. He then scoured many old vineyards in Friuli and Slovenia to collect more vine material. In 1972, he had a hundred vines clandestinely grafted by the Rauscedo nursery \u2013 clandestinely because the variety was not at that time authorized and fines were imposed on those who planted unauthorized varieties \u2013 and planted the first dedicated Schioppettino vineyard in his crusade to save it from oblivion. He was then followed by other growers in the commune of Prepotto, in Cialla and Albana, which is now Schioppettino\u2019s most important area of cultivation. The mayor of Prepotto continued to campaign against the outlawing of Schioppettino and it was eventually included in the list of authorized varieties for the province of Udine in 1981. By 2000 there were 96 ha (237 acres) planted in Italy. It is also grown to a very limited extent in the rest of Friuli but has pretty much disappeared over the border in Slovenia, where it is known as Pokalca. It was awarded DOC status in 1987.',
      'Schioppettino has become a major source of fine red wine in Friuli, producing excellent and sometimes expensive varietal wines within the Colli Orientali del Friuli and the Friuli Isonzo DOCs (although an equal amount of wine is produced as IGT Venezia Giulia). In the former, it may be blended with REFOSCO DAL PEDUNCOLO ROSSO. Wines are generally deeply coloured, perfumed and peppery, with flavours of wild fruits, firm but fine tannins and fresh acidity, both distinctive and elegant. A Schioppettino festival is held annually in Prepotto in the first week of May.',
      'Recommended producers include Bressan, Girolamo Dorigo, Petrussa and Ronchi di Cialla (the Rapuzzi family winery).',
    ],
  },
  {
    name: 'SCH\u00d6NBURGER',
    synonyms: [
      'Geisenheim 15-114',
      'Sch\u00f6nberger',
      'Sch\u00f6nburger Rose',
    ],
    description:
      'A pink-skinned German cross making white wines with light Muscat aromas, mainly in England.',
    'origin and parentage': [
      'A PINOT NOIR \u00d7 Pirovano 1 cross made by Heinrich Birk at the Geisenheim research centre in the Rheingau, Germany, in 1939 and authorized in 1980. Pirovano 1 is a Chasselas Rose \u00d7 MUSCAT OF HAMBURG cross, where Chasselas Rose is a colour mutation of CHASSELAS. This parentage has been confirmed by DNA profiling (Myles et al. 2011).',
    ],
    'where its grown': [
      'Named after the town of Sch\u00f6nburg in the Mittelrhein, this variety is planted today on 20 ha (49 acres) of vineyard in Germany, predominantly in Rheinhessen. Schillinghof in the Nahe make one of the rare German varietal examples.',
      'It is more widely planted in England (45 ha/111 acres in 2009) although the total English area has declined from 75 ha (185 acres) in 2003. In the cool English climate, where lack of acidity is not generally a problem, the wines are fresh and have a delicate Muscat aroma, occasionally reminiscent of GEW\u00dcRZTRAMINER in their perfume. They generally benefit from a touch of residual sweetness but can lack concentration. Danebury (Hampshire) and Denbies (Surrey) make varietal wines; others such as Carr Taylor (Sussex) use it in blends.',
      'Sch\u00f6nburger is also planted to a limited extent in British Columbia, Canada \u2013 28 acres (11 ha) \u2013 and there are minuscule plantings (less than a hectare/2.5 acres) in South Africa.',
    ],
  },
  {
    name: 'SCIAGL\u00ccN',
    synonyms: ['Scjagl\u00ecn'],
    description: 'Ancient and rare Friulian.',
    'origin and parentage': [
      'Sciagl\u00ecn is said to have been mentioned near Spilimbergo in Friuli-Venezia Giulia, north-east Italy, as early as the fifteenth century. Its name could derive from Italian s\u2019ciale (meaning \u2018terrace\u2019) or from schiavolino (\u2018wine from Slavia\u2019).',
    ],
    'where its grown': [
      'Sciagl\u00ecn was formerly cultivated in Vito d\u2019Asio and Fagagna del Friuli, north-east Italy. Today it is grown mainly between Maniago and Pinzano al Tagliamento in the province of Pordenone. Together with other old local varieties CIANORIE, CIVIDIN, FORGIARIN, PICULIT NERI and UCEL\u00d9T, it was saved from extinction by Emilio Bulfon, with the help of ampelographers Antonio Cal\u00f2 and Ruggero Forti. Emilio Bulfon is one of the few producers to craft varietal wines from these grapes. Florutis is another. Sciagl\u00ecn wines are quite juicy with aromas of apple and citrus. Together with Forgiarin, Piculit Neri and Ucel\u00f9t, Sciagl\u00ecn was included in the Italian national register of varieties in 1991.',
    ],
  },
  {
    name: 'SCIASCINOSO',
    synonyms: [
      'Crovina',
      'Livella di Battipaglia',
      'Livella Nera',
      'Olivella Nera',
      'Olivella Nostrana',
      'Sanginoso',
      'Uva Olivella',
      'Uva Olivella Verace',
      'Vulivella',
    ],
    description: 'Unusual Campanian making light reds.',
    'origin and parentage': [
      'It has long been debated whether Sciascinoso and Olivella were identical or not. The question is not straightforward because the name Olivella, or Livella in the dialect of Napoli, has been given to several distinct Campanian varieties with olive-shaped berries. Recent DNA profiling has shown that at least three distinct varieties share this common name: Livella d\u2019Ischia, Livella di Mirabella and Livella di Battipaglia. While the first two are not commercially cultivated, Livella di Battipaglia, also called Olivella Nera, turned out to be identical to Sciascinoso (Costantini et al. 2005).',
    ],
    'where its grown': [
      'Sciascinoso is cultivated all over Campania, Italy, mainly in the provinces of Avellino, Benevento and Salerno, but it is also found in the province of Caserta as well as further north in the province of Frosinone in Lazio. Varietal wines are authorized in the Irpinia and Sannio DOCs and it may be part of the blend, often with PIEDIROSSO or AGLIANICO, in several others such as Costa d\u2019Amalfi, Penisola Sorrentina and Vesuvio. Total Italian plantings in 2000 were 169 ha (418 acres).',
      'Examples of varietal wines are made by Dedicato a Marianna and Tenuta Vitagliano (both still and sparkling). They tend to be light- to medium-bodied and not suited to ageing, which is another reason Sciascinoso is blended with more robust varieties such as Aglianico.',
    ],
  },
  {
    name: 'SCIMISCI\u00c0',
    synonyms: [
      '\u00c7imix\u00e2',
      'Frate Pelato',
      'Genovese',
      'Genov\u00e8se',
      'Raisin G\u00e9nois',
      'Rossala Bianca',
      'Simix\u00e0',
    ],
    description:
      'Old and very minor Genoese variety also found on the French island of Corse.',
    'origin and parentage': [
      'Scimisci\u00e0 is an old variety from the province of Genova in Liguria, near the coast in north-west Italy. Its name is a dialect form of the Italian word cimice, meaning \u2018bug\u2019, referring to the tiny stains on the berry skin that resemble small bugs.',
      'DNA profiling has recently established that the variety cultivated on the French island of Corse since the early nineteenth century under the name Genovese, often confused with ALBAROLA (aka Bianchetta Genovese) and BOSCO from Liguria, is in fact identical to Scimisci\u00e0 (Torello Marinoni, Raimondi, Ruffa et al. 2009), which is consistent with its synonym Genovese, meaning \u2018from Genova\u2019, and is a reminder of its introduction from Liguria. In addition, DNA profiling has shown that the variety Frate Pelato grown in the Cinque Terre in Liguria is also identical to Scimisci\u00e0 (Torello Marinoni, Raimondi, Ruffa et al. 2009).',
    ],
    'where its grown': [
      'Scimisci\u00e0 vines are found only in the province of Genova in north-west Italy, more specifically in the Fontanabuona and Cicchero valleys. In the 1990s, Scimisci\u00e0 was close to extinction when Marco Bacigalupo, an ex-pastry chef from Cassottana di Cicogna in the Val Fontanabuona, wanted to save the 500 old vines that some elderly locals had told him were the ancient and famous Scimisci\u00e0. With the support of the Comunit\u00e0 Montana della Fontanabuona, the identity of these vines was subsequently confirmed and Scimisci\u00e0 was included in the national register of varieties in 2003.',
      'Daniele Parma of La Ricolla and the Cantina \u00c7imix\u00e2 both make 100% Scimisci\u00e0 wines. It was not included in the Italian national register of varieties until 2003 and was not therefore included in the most recent agricultural census. Dry wines have aromas that range from herbs to apples and pears and marked acidity on the palate. Passito-style wines generally show aromas of dried fruits.',
      'Under the name Frate Pelato (meaning \u2018bald friar\u2019) it is still sporadically found in old vineyards in Liguria\u2019s Cinque Terre and it has been recently replanted by Samuele Bonanini at Azienda Agricola Possa, whose dry white 5 Terre blend includes a small amount of the variety.',
      'Under the names Genovese and Rossala Bianca, it is found on the island of Corse in France, eg in Domaine Abbatucci\u2019s Cuv\u00e9e Collection Il Cavaliere Diplomate d\u2019Empire, blended with BIANCU GENTILE, BRUSTIANO BIANCO and VERMENTINO.',
    ],
  },
  {
    name: 'SCUPPERNONG',
    synonyms: [
      'American Muscadine',
      'Big White Grape',
      'Bull',
      'Bullace',
      'Bullet Grape',
      'Green Muscadine',
      'Green Scuppernong',
      'Hickman\u2019s Grape',
      'Pedee',
      'Roanoke',
      'White Muscadine',
      'White Scuppernong',
      'Yellow Muscadine',
    ],
    description:
      'Bronze-berried native American variety producing distinctive sweet wines said to have health benefits.',
    'origin and parentage': [
      'Scuppernong is probably the source of one of the first American wines as well as one of the rare cultivated representatives of the American Vitis rotundifolia, a dark-purple-berried native species of the southern United States that is often referred to as the Muscadine grape. It is thought to be a greeny bronze-berried mutation of a wild Vitis rotundifolia vine (Gohdes 1982) that grew on the Outer Banks of North Carolina, a chain of barrier islands in the Atlantic off the coast of North Carolina and Virginia. However, when and where the first vines were cultivated remains unknown.',
      'Until the nineteenth century, this variety was known simply as the Big White Grape or the Roanoke Grape, after Roanoke Island, often considered to be its birthplace. In 1811, Calvin Jones and Tom Henderson, then editors of a journal called the Southern Planter, christened the grape Scuppernong, meaning \u2018place where magnolias grow\u2019 in the Algonquian language, in honour of Colonel James Blount, who found numerous vines growing along the banks of the Scuppernong River that runs from Washington County to the Albemarle Sound, east of Roanoke Island (Gohdes 1982).',
      'Scuppernong\u2019s popularity rapidly increased and its cultivation spread beyond North Carolina, helped particularly by Thomas Jefferson, who in 1817 praised the wine as one that would be \u2018distinguished on the best tables of Europe, for its fine aroma, and chrystalline transparence\u2019. However, Jefferson\u2019s patriotic comment must be read with caution, because at that time Scuppernong was closer to fortified grape juice than to wine (Pinney 1989).',
      'By the mid nineteenth century, the vine had been propagated across the country for table consumption and winemaking and North Carolina had become the foremost grape producer of the United States, mainly thanks to Scuppernong. In the early 1900s, Paul Garrett began selling his Scuppernong wine in North Carolina as Virginia Dare, named after the first child born to English settlers on Roanoke Island, and the wine became so popular that Garrett was the most successful eastern winegrower before Prohibition (Pinney 1989).',
      'In 2001 the Scuppernong was named North Carolina\u2019s official state fruit.',
    ],
    'where its grown': [
      'Scuppernong is still planted in the south-eastern US but it has been largely superseded by Muscadines developed in more recent breeding programmes that produce better-quality wine. However, it still has some marketing appeal thanks to its history and its name and the purported health benefits of Muscadines. According to Georgia\u2019s College of Agricultural and Environmental Sciences (2010), \u2018A few vineyards are using Scuppernong as a bronze juice cultivar, but mostly just for the novelty of the name, rather than the superiority of the juice. We do not recommend planting of Scuppernong in new vineyards.\u2019 However, the better Scuppernong wines are certainly distinctive and very different from wine made from vinifera varieties: dark gold, well structured and sweet. Several recent studies have suggested that Scuppernong and other Muscadines have a high level of antioxidants that are said to reduce the risk factors associated with degenerative diseases.',
      'Producers of varietal wines include Benjamin Vineyards, Cypress Bend, Duplin, Hinnant Family and WoodMill in North Carolina; Perdido in Alabama; and Butterducks in Georgia.',
    ],
  },
  {
    name: 'SEARA NOVA',
    synonyms: ['H-8-51-29'],
    description:
      'Cross quite widely planted in western Portugal and on the A\u00e7ores.',
    'origin and parentage': [
      'A DIAGALVES \u00d7 FERN\u00c3O PIRES cross obtained in 1951 by Jos\u00e9 Le\u00e3o Ferreira de Almeida at the national agronomy centre in Oeiras, east of Lisboa, Portugal. It is a sibling of RIO GRANDE.',
    ],
    'where its grown': [
      'Found mainly in the Lisboa and the Tejo regions of Portugal but also authorized on the island of Pico in the A\u00e7ores, where the local co-op include it in their Terras de Lava blend. Seara Nova adapts well to Atlantic-influenced climates to produce wines that may have quite high alcohol and average acidity but are otherwise fairly light in aroma and flavour and are therefore destined for blends. There were apparently around 700 ha (1,730 acres) in Portugal in 2010 but this may be an overestimate.',
    ],
  },
  {
    name: 'SEFKA',
    synonyms: ['Mavrouti', 'Nichevka', 'Sefka Nichevka', 'Sefko'],
    description:
      'Undistinguished variety of Balkan origin found only in Greece.',
    'origin and parentage': [
      'Sefka is said to originate in Bulgaria or from the Balkans (Greek Vitis Database; Nikolau and Michos 2004). However, today it is known only in Greece and its DNA profile (Greek Vitis Database) does not match that of any other variety (Vouillamoz).',
      'In Thr\u00e1ki (Thrace), Sefka is also known as Chondromavro but it is ampelographically distinct from the true CHONDROMAVRO from Koz\u00e1ni in western Makedon\u00eda.',
    ],
    'where its grown': [
      'Sefka is grown in Makedon\u00eda in northern mainland Greece and further south in Thessal\u00eda, where it is used mainly in blends with local varieties such as KARNACHALADES and MOSCHOMAVRO, sometimes even with white varieties. Wines are typically pale with moderate alcohol and low acidity.',
    ],
  },
  {
    name: 'SEGALIN',
    synonyms: ['S\u00e9galin'],
    description:
      'Minor Montpellier cross useful for stiffening blends in Cahors country.',
    'origin and parentage': [
      'A JURAN\u00c7ON NOIR \u00d7 BLAUER PORTUGIESER cross obtained by Paul Truel in 1957 at INRA (Institut National de la Recherche Agronomique) in Montpellier, southern France, included in the national register of varieties in 1976. It was named after the S\u00e9galis, inhabitants of S\u00e9gala, a region between the Aveyron and the Tarn (R\u00e9zeau 1997).',
    ],
    'where its grown': [
      'Wines are deeply coloured, well structured and tannic. There were 67 ha (166 acres) in France in 2008, marginally down on 2006. It is recommended, as so many of these recent INRA crossings, in much of southern France, from the Dordogne in the west to the Bouches-du-Rh\u00f4ne in the south east. Domaine de Mat\u00e8le produce a Segalin/Malbec (COT) blend just north of Cahors. In the Lot, where Segalin comprises around 10% of the vineyard, along with GAMAY NOIR and MERLOT, the variety is used to increase body.',
    ],
  },
  {
    name: 'SEIBEL 10868',
    synonyms: ['Soleil Blanc'],
    description: 'Ard\u00e8che hybrid found only (rarely) in Michigan today.',
    'origin and parentage': [
      'A complex hybrid obtained at the beginning of the twentieth century by Albert Seibel in Aubenas in the Ard\u00e8che in southern France, by crossing Seibel 5163 \u00d7 Seibel 5593 (see PRIOR for the complete pedigree of Seibel 5163 and see CHELOIS for the complete pedigree of Seibel 5593).',
    ],
    'where its grown': [
      'Although Seibel 10868 was bred in France, it is not authorized there and does not appear to be planted. Boskydel in Michigan have just a few hundred vines left which they include in their Soleil Blanc blend.',
    ],
  },
  {
    name: 'SEMIDANO',
    synonyms: ['Arvusiniagu', 'Laconarzu', 'Migiu', 'Semidamu', 'Semidanu'],
    description:
      'Minor white variety exclusive to the Italian island of Sardegna.',
    'origin and parentage': [
      'Semidano was first mentioned by Manca dell\u2019Arca (1780) under the name Semidamu or Laconarzu. Semidano is said to have spread across the Italian island of Sardegna, together with NURAGUS, having been introduced possibly much earlier through the ports of Karalis (today\u2019s Cagliari) and Nora.',
    ],
    'where its grown': [
      'After the ravages of phylloxera at the end of the nineteenth century, Semidano was supplanted by the more productive and disease-resistant NURAGUS and slowly disappeared from the island of Sardegna, Italy. It is cultivated today mainly in the Campidano area in the province of Cagliari, usually blended with Nuragus, and occasionally made as a varietal wine. The Sardegna Semidano DOC, sometimes with the subzone Mogoro on the label, allows still, sparkling and passito versions. The main producer is the local co-operative, the Cantina Sociale Il Nuraghe. There were 50 ha (124 acres) planted in Italy in 2000.',
    ],
  },
  {
    name: 'S\u00c9MILLON',
    synonyms: [
      'Barnawartha Pinot',
      'Blanc Doux',
      'Chevrier',
      'Greengrape',
      'Hunter River Riesling',
      'Merwah',
      'Saint-\u00c9milion',
      'Semilion',
      'Semillon',
      'S\u00e9millon Blanc',
      'S\u00e9millon Muscat',
    ],
    description:
      'The grape variety of sweet white bordeaux and a natural blending partner of Sauvignon Blanc in dry whites too.',
    'origin and parentage': [
      'Until the eighteenth century, S\u00e9millon was cultivated only in the Sauternes region of Bordeaux, south-west France, where it was first cited in 1736 as \u2018semilion ou St \u00c9milion\u2019. Although Sauternes is commonly considered to be its birthplace, the name S\u00e9millon probably derives from semeljun, the local pronunciation of Saint-\u00c9milion (R\u00e9zeau 1997), where the variety does exist but has never been cultivated to any significant extent.',
      'Recent DNA studies have suggested that S\u00e9millon and SAUVIGNON BLANC are genetically very close (Jahnke et al. 2009) but a parent\u2013offspring relationship can be rejected (Vouillamoz).',
      'MERLOT and TROUSSEAU have sometimes been called S\u00e9millon Rouge (Levadoux 1956; Galet 1990) but they have strictly no relationship with S\u00e9millon. A colour mutation called Red S\u00e9millon has been observed in South Africa (see below).',
      'S\u00e9millon was used to breed FLORA and NORIA.',
    ],
    'where its grown': [
      'S\u00e9millon is not a fashionable variety, which is a little strange because at less than maximum ripeness it can taste quite herbaceous and remarkably like its probable relative SAUVIGNON BLANC, which is currently extremely popular. Fully ripe S\u00e9millon is fuller-bodied and fatter than Sauvignon Blanc, with waxy, lanolin, sometimes lemony aromas and, often, a broad, more viscous impact on the palate rather than Sauvignon\u2019s sharper attack and high acidity.',
      'France grows more S\u00e9millon than any other country. Its 11,693 ha (28,894 acres) in 2009 made it the country\u2019s fifth most planted white wine variety, focused almost entirely on south-west France. There is considerably more S\u00e9millon than SAUVIGNON BLANC planted in Bordeaux, where there were 7,384 ha (18,246 acres) in 2009. Here it is planted particularly on the left bank of the Garonne, in Sauternes and surrounding areas producing sweet white wines, for which its thin skins make it usefully prone to noble rot, and also the oaked \u2013 sometimes smoky, sometimes almost oily \u2013 dry whites whose apogee are the best of Pessac-L\u00e9ognan such as Ch\u00e2teaux La Mission Haut-Brion and Haut-Brion. In both cases, the variety is generally blended with Sauvignon Blanc, with four to one being the traditional S\u00e9millon:Sauvignon ratio in sweet white wines but with the proportion of Sauvignon Blanc having increased in recent years in the dry wines. A little S\u00e9millon is also grown in the Entre-Deux-Mers for simpler, fresher dry whites.',
      'Thanks to its (declining) importance throughout Bergerac, S\u00e9millon is no longer the most planted variety in the Dordogne d\u00e9partement, its 3,726 ha (9,207 acres) in 2009 outnumbered by MERLOT\u2019s 4,303 ha (10,633 acres), but it is still a very important ingredient in wines such as Monbazillac (with SAUVIGNON BLANC and MUSCADELLE) and provides heft to much Bergerac Blanc. There were also 236 ha (583 acres) to the south east in neighbouring Lot-et-Garonne, where it is less important than Sauvignon Blanc and COLOMBARD. S\u00e9millon is technically allowed in most appellations of Provence \u2013 there were 187 ha (462 acres) in the Var d\u00e9partement in 2009 \u2013 but it has made little impact on the vineyards of the Midi, where acidity is at a premium.',
      'The variety is hardly known in Italy, although Castel de Paolis in Lazio somehow make their own riposte to Sauternes, a botrytized S\u00e9millon with the classic 20% of SAUVIGNON BLANC to help the acid levels. In Spain, it is virtually unknown and in Portugal is grown by one Douro producer for a late-harvest white. Hungary grew 55 ha (136 acres) of S\u00e9millon in 2008, while there is also a little in Krasnodar Krai in Russia. It is grown by a handful of producers in Greece and Cyprus (36 ha/89 acres in 2010) \u2013 and Turkey had 547 ha (1,352 acres) of S\u00e9millon in 2010, mostly in the Tekirda\u011f region north of the Marmara Sea. In Israel, it still has a limited, declining presence, having been taken from Bordeaux to help establish the wine industry in the late nineteenth century. Nowhere outside Sauternes, unfortunately, does there seem to be a groundswell of enthusiasm for this noble variety.',
      'If you are unfashionable, California is hardly the place to be, but there was still a total of 890 acres (360 ha) of Semillon (no accent in the US) in 2010, dotted around the state and mainly in Napa, Sonoma, Yuba and San Joaquin counties. It is generally used in the same way as in Bordeaux: blended with SAUVIGNON BLANC, mostly for dry but also sweet and occasionally botrytized whites. It is also used to bulk out such blends as Mondavi\u2019s Woodbridge Chardonnay. Kalin make arguably the most distinctive California varietal Semillon, deliberately bottle aged, from vines planted in the Wente vineyard in the 1880s near Livermore, based on cuttings imported from Ch\u00e2teau d\u2019Yquem by Charles Wetmore. But this is not California\u2019s only source of very old-vine Semillon. The Monte Rosso vineyard in Sonoma, also famous for its old Zinfandel (TRIBIDRAG) vines, has Semillon planted in 1886 whose concentrated fruit is bought by the likes of Carlisle and Luc Morlet, who blend it, and Morgan, who produce a 100% Semillon sweet wine from Monte Rosso fruit.',
      'Washington State has a long history of Semillon production and has in its time produced some fine and distinctive dry wine, especially that made by L\u2019Ecole 41, but the total area devoted to the variety has declined sharply in recent years, from 700 acres (283 ha) in 1993 to 222 acres (90 ha) in 2011. DeLille\u2019s Chaleur Estate white Bordeaux blend has long been exceptional, and a few sweet Semillons are also made. Over the border in Canada, British Columbia had 72 acres (29 ha) in 2008 and the likes of Sumac Ridge and Black Hills make very creditable Semillon/SAUVIGNON BLANC blends.',
      'S\u00e9millon was once quite widely planted in Chile. As recently as 2008 there were still about 1,727 ha (4,268 acres) planted and one must assume that most of its produce bulks out wines labelled Sauvignon \u2013 so rare are varietal Semillons (usually without the accent in South America) in Chile, although Viu Manent and Valdivieso have made rather delicious, and extremely well-priced, late-harvest sweet ones.',
      'Argentina grew 973 ha (2,404 acres) of Semillon in 2008, mostly in Mendoza but also to a limited extent in San Juan and R\u00edo Negro. Finca La Anita make a rare, serious dry varietal version in Mendoza. Uruguay grows just 129 ha (319 acres) of Semillon.',
      'S\u00e9millon\u2019s real sphere of influence outside France, however, is Australia, where it has a noble history of being responsible for some of the most distinctive dry white wines in the world. In 2008 Australia had a total of 6,715 ha (16,593 acres) of Semillon (without the accent), making it Australia\u2019s second most planted white, albeit a long way behind CHARDONNAY\u2019s 31,564 ha (77,996 acres). Semillon is grown throughout the country but is most famous as a varietal wine in the Hunter Valley (618 ha/1,527 acres), where the unoaked, low-alcohol (typically picked at around 10% potential alcohol) and high-acid wines are wonderfully transformed into toasty complexity by ageing over many years in bottle. Blended with SAUVIGNON BLANC in Western Australia, especially Margaret River, and in the Adelaide Hills, it can produce elegant and sophisticated wines, both oaked and unoaked, similar to but generally more refreshing than white bordeaux \u2013 although the Semillon tends to be picked early, when it has a marked flavour of grapefruit and sometimes tastes very similar to its blending partner, Sauvignon Blanc, to avoid any oily fatness. Varietal wines from the Barossa are sometimes powerful and heavily oaked but a more restrained style is now emerging. Recommended producers of varietal wines include Brokenwood, McWilliams and Tyrrells in the Hunter Valley; Mount Horrocks in the Clare Valley; Moss Wood and Vasse Felix in Margaret River; and Peter Lehmann in the Barossa Valley. Good examples of Semillon/Sauvignon blends are made by, for example, Brookland Valley, Cape Mentelle, Larry Cherubino, Cullen, Goundrey and Lenton Brae in Western Australia.',
      'The variety is not nearly as important in New Zealand, where the total area planted to Semillon had declined to 201 ha (497 acres) by 2009, shared mainly among Gisborne, Marlborough and Hawke\u2019s Bay, and virtually none is exported.',
      'Semillon (again without the accent) was once extremely important in South Africa, comprising over 90% of Cape vines in the early nineteenth century. It became so popular and dominant that it was called simply \u2018greengrape\u2019, groendruif in Afrikaans, because of its bright green foliage, and then later wijndruif or \u2018winegrape\u2019. It was not identified as Bordeaux\u2019s S\u00e9millon until the end of the nineteenth century \u2013 and when a red mutation appeared (see below), it was known logically as \u2018red greengrape\u2019.',
      'Semillon\u2019s importance in South Africa declined throughout the twentieth century and by 2008 only 1,153 ha (2,849 acres) remained, the equivalent of just over 2% of the total white vineyard area. The largest plantings are in Stellenbosch, Swartland and Franschhoek, which has some particularly ancient bushvines at, for example, Landau du Val. But even if plantings are waning, the variety\u2019s reputation may be waxing. Many of the best examples are oaked and some of South Africa\u2019s top producers such as Eben Sadie value its contribution to blends. Recommended producers of varietal wines include Boekenhoutskloof, Cederberg, Fairview, Steenberg and Stellenzicht. As in Semillon\u2019s Bordeaux homeland, many of the finest wines are Semillon/SAUVIGNON BLANC blends such as Tokara\u2019s and Vergelegen\u2019s top white, which usually has more Semillon than Sauvignon. A few oaked dessert wines are also made from botrytized grapes.',
    ],
  },
  {
    name: 'SERCIAL',
    synonyms: [
      'Arinto dos A\u00e7ores',
      'Esgana',
      'Esgana C\u00e3o',
      'Esganoso',
    ],
    description:
      'Exceptionally acid Portuguese variety most famous for its role in the driest of Madeiras.',
    'origin and parentage': [
      'Sercial probably originates from Bucelas near Lisboa, Portugal, where it was traditionally grown under the name Esgana C\u00e3o, although it has also been suggested that it might possibly come from the Douro. It was introduced from the mainland to the island of Madeira, where it was given the name Sercial. However, this variety should not be confused with CERCEAL BRANCO (also known as Cercial) from D\u00e3o, nor with SERCIALINHO. The name Esgana C\u00e3o, meaning \u2018dog strangler\u2019, hints at the fierce acidity of the wines.',
      'According to recent DNA studies, Sercial could be genetically close to RAMISCO from the Colares region, to TRINCADEIRA from the Alentejo (Almadanim et al. 2007) and to ALVARELH\u00c3O from the Douro or D\u00e3o (Lopes et al. 1999).',
    ],
    'where its grown': [
      'Although Esgana C\u00e3o is an official synonym for Sercial, the latter name is probably more familiar because of the variety\u2019s importance for the driest style of Madeira. However, there were fewer than 20 ha (49 acres) on the island in 2010, mostly high up on the southern coast and on the northern coast near the sea. There are around 70 ha (173 acres) on mainland Portugal, mostly in Bucelas just north of Lisboa and further north in the Minho region and as far north as the Douro, where it sometimes plays a minor \u2013 acidifying \u2013 role in white port and dry white table wines. As the mainland synonym suggests, wines are austere and high in acidity, on Madeira rarely achieving more than 11% alcohol before fortification, but they produce intensely aromatic, vibrant, bone-dry, nervy, extremely long-lived wonders. In Bucelas, Esgana C\u00e3o plays a minor role in blends with ARINTO DE BUCELAS and is rarely found as a varietal wine, although \u00c1lvaro de Castro makes a Beira Interior varietal Sercial at Quinta de Saes.',
      'About 0.5 ha (1.24 acres) is grown in the vineyards of Mas de Daumas Gassac in the eastern Languedoc, France, and adds aroma and acidity to either the dry white or, in some vintages, to Muscat to produce the late-harvest sweet Vin de Laurence.',
    ],
  },
  {
    name: 'SERCIALINHO',
    synonyms: ['Cercealinho', 'Sercealinho'],
    description: 'Rare, high-acid Portuguese cross adding freshness and aroma.',
    'origin and parentage': [
      'A Portuguese cross obtained in the 1950s by Le\u00e3o Ferreira de Almeida at the Alcoba\u00e7a research centre in Portugal. According to the Vitis International Variety Catalogue, this is a VITAL \u00d7 ALVARINHO cross but this is inconsistent with their DNA profiles in Veloso et al. (2010). Luis Pato of the Bairrada region, continuing in his father\u2019s footsteps as the lone promoter of this variety, believes it to be a SERCIAL \u00d7 Alvarinho cross (Luis Pato, personal communication).',
    ],
    'where its grown': [
      'Sercialinho \u2013 all 9 ha (22 acres) of it in Portugal in 2010 \u2013 is found mainly close to its birthplace, in Bairrada. Its most successful proponent is Luis Pato, who likens it to RIESLING and says it adds acidity and a pine-resin/petrol character to the BICAL and CERCEAL BRANCO in his Vinhas Velhas blend. He experimented with a varietal Sercialinho in 2009 but concluded that its wine is not complete enough on its own. The vines were originally planted by his father to add freshness to sparkling wine but Luis Pato has now planted more and also makes a sweet wine from the same fruit using cryoextraction. On its own, it is aromatic with green-apple, pear and light honey flavours and a creamy texture. It can retain very high acidity even at 13.5% alcohol, making it particularly interesting in a blend as the climate gets warmer.',
    ],
  },
  {
    name: 'SERVANT',
    synonyms: ['Nonay', 'Raisin Blanc', 'Servan'],
    description:
      'Minor variety in both France and South Africa, used mainly for the table.',
    'origin and parentage': [
      'In the H\u00e9rault in France\u2019s Languedoc, the name Servant was given to the old table grape Gros Vert until the nineteenth century, and was then used for the modern Servant, which is supposed to have been introduced from Greece around 1864 by Amiral Nonay in the region of Toulon, where it is cultivated under the name Nonay (Galet 1990; R\u00e9zeau 1997). The name Servant derives from the Languedoc dialect serva, meaning \u2018may be kept/stored\u2019, because Gros Vert used to be stored in lofts to remain fresh until Easter (R\u00e9zeau 1997). According to Galet (2000), its original name could be Moldavsky Bely in Bulgarian, but this hypothesis is not certain because this name is also given to at least five other varieties.',
    ],
    'where its grown': [
      'Both in France (143 ha/353 acres in 2008) and in South Africa (95 ha/235 acres in 2008), this variety is grown mainly as a table grape, but Domaine Jordy\u2019s Cert-Vent, a Vin de Pays de l\u2019H\u00e9rault, is a Servant-dominated blend. Fr\u00e9d\u00e9ric Jordy describes the wine as rustic, dry, dense, with a note of flint thanks to the site, and a good companion to shellfish.',
    ],
  },
  {
    name: 'SEVERNY',
    synonyms: ['Severnyi Muskat'],
    description: 'Russian variety valued mainly for its cold hardiness.',
    'origin and parentage': [
      'Severny, literally \u2018northern\u2019, is a hybrid obtained in 1936 by Ya I Potapenko and E Zakharova at the all-Russia research and development centre for viticulture and winemaking in Novocherkassk, in the province of Rostov, Russia, by breeding Seyanets Malengra with a Vitis amurensis Ruprecht vine. Seyanets Malengra, literally \u2018seedling of Malingre\u2019, was indeed obtained by open pollination of PR\u00c9COCE DE MALINGRE (ie the pollen donor is unknown). Severny was used to breed FIOLETOVY RANNY, GOLUBOK, OVIDIOPOLSKY and SAPERAVI SEVERNY.',
    ],
    'where its grown': [
      'Thanks to the north-east Asian amurensis genes, the vine is very winter-hardy, which is why it does not need earthing up in winter in the Rostov wine region, unlike most other varieties, but it does not seem to have won favour beyond the borders of Russia.',
    ],
  },
  {
    name: 'SEYVAL BLANC',
    synonyms: ['Seyval', 'Seyve-Villard', 'Seyve-Villard 5276'],
    description:
      'One of the more successful pale-skinned French hybrids, popular in marginal climates, especially England.',
    'origin and parentage': [
      'A complex hybrid obtained by Bertille Seyve and Victor Villard in Saint-Vallier in the Is\u00e8re in eastern France by crossing Seibel 5656 \u00d7 RAYON D\u2019OR (see the pedigree diagram in PRIOR). The same parents were used to breed the sibling variety SEYVAL NOIR. The name Seyval is a contraction of Seyve and Vallier (R\u00e9zeau 1997). Seyval Blanc was used to breed BIRSTALER MUSKAT, CAYUGA WHITE, CHARDONEL, LA CROSSE, MELODY, R\u00c9SELLE and ST PEPIN.',
    ],
    'where its grown': [
      'Seyval is authorized in many French wine regions but, perhaps tellingly, recommended in none and not allowed in the production of quality wine (in the regulatory sense of the word) due to its hybrid ancestry. There were 111 ha (274 acres) in France in 2008, down from 159 ha (393 acres) in 1998 and 1,309 ha (3,235 acres) in 1958.',
      'However, it is both more successful and more useful in cooler climates due to its productivity and relatively early ripening. Seyval and M\u00dcLLER-THURGAU were the standard varieties for most of the early UK vineyards in the 1950s, for example, and it was at one time, before the rush to plant the traditional champagne varieties for fizz, the most widely planted variety in England, although the area had dropped slightly, down to 90 ha (222 acres) in 2009, around 6.5% of the total vineyard area. The sugar level in the berries is not generally high, perhaps because it is usually found in cooler climates, and the wine can be relatively neutral and high in acidity, but it is a good base for sparkling wine and the better examples take well to barrel fermentation and lees ageing, though ageing in bottle is not generally recommended. Most importantly, it does not have the foxy flavour of some hybrids. For strange bureaucratic reasons, in the UK its produce does qualify as a Quality Sparkling Wine. Recommended producers who use Seyval Blanc in their sparkling wines include Camel Valley and Breaky Bottom.',
      'Other cooler regions that have welcomed Seyval Blanc include Canada, New York State and the eastern United States. Ontario had approximately 210 acres (85 ha) in 2006 and there are limited plantings in Nova Scotia and Qu\u00e9bec. New York State had a sizeable 373 acres (151 ha) in 2006, and there were 75 acres (30 ha) in Michigan (2006), 48 acres (19 ha) in Virginia (2008), 30 acres (12 ha) in Indiana (2009) and 16 acres (6 ha) further west in Iowa (2006). St James Winery in Missouri make a good example.',
    ],
  },
  {
    name: 'SEYVAL NOIR',
    synonyms: ['Seyve-Villard 5247'],
    description:
      'Is\u00e8re hybrid that is less successful than its white sibling but still found in Qu\u00e9bec.',
    'origin and parentage': [
      'A complex hybrid obtained by Bertille Seyve and Victor Villard in Saint-Vallier in the Is\u00e8re in eastern France by crossing Seibel 5656 \u00d7 RAYON D\u2019OR (see the pedigree diagram in PRIOR). The same parents were used to breed the sibling variety SEYVAL BLANC. The name Seyval is a contraction of Seyve and Vallier (R\u00e9zeau 1997).',
    ],
    'where its grown': [
      'Domaine Ganevat in Rotalier in the Jura, France, have replanted a small area of Seyval Noir and include it in an early-drinking red blend. Unlike SEYVAL BLANC, Seyval Noir is not in the official French register of varieties. It is still found to a limited extent in Qu\u00e9bec, Canada, but even there it is in decline. La Mission and Domaine Les Brome, for example, include it in their ros\u00e9 blends and Les Pervenches have it in their red blend. L\u2019Orpailleur make vinegar with it!',
    ],
  },
  {
    name: 'SGAVETTA',
    synonyms: ['Sganetta', 'Sgavetta a Graspo Rosso'],
    description:
      'Emilia-Romagna rarity, generally playing only a small, colouring part in blends.',
    'origin and parentage': [
      'Sgavetta was first mentioned by Di Rovasenda (1877), who considered the name to be derived from Schiava or Schiavetta in Emilia-Romagna. Sgavetta used to be highly praised for its potential to make good wine in the provinces of Modena and Reggio Emilia. DNA analysis has suggested a genetic relationship with ROSSARA TRENTINA (Boccacci et al. 2005).',
    ],
    'where its grown': [
      'Sgavetta is cultivated in the provinces of Modena and Reggio Emilia in Emilia-Romagna in northern Italy, where it is usually blended with other local grape varieties to add colour. It may be included as a minor component in both the MALBO GENTILE and the MARZEMINO versions of the Colli di Scandiano e di Canossa DOC. Varietal wines are rare but there have been some successful sweet and sparkling versions. There were 68 ha (168 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'SHAVKAPITO',
    synonyms: ['Chavkapito'],
    description: 'Rediscovered and promising dark-skinned Georgian variety.',
    'origin and parentage': [
      'Shavkapito is indigenous to the Kartli region of southern central Georgia.',
    ],
    'where its grown': [
      'The recently rediscovered Shavkapito, planted to a very limited extent in the villages of Metekhi, Khidistavi, Mukhrani and Okami in the Kartli region of Georgia, north west of Tbilisi, produces red-fruited wines with a smoky flavour, even without the influence of oak. Apart from a few home-grown vines, there are about 10 ha (25 acres) now in production: Pheasant\u2019s Tears make a qvevri-fermented version, Chateau Mukhrani a more European, oaked style.',
    ],
  },
  {
    name: 'SHESH I BARDH\u00cb',
    synonyms: ['Pucalla'],
    description:
      'Albania\u2019s most planted, late-ripening, light-skinned indigenous variety.',
    'origin and parentage': [
      'Shesh i Bardh\u00eb, literally \u2018white Shesh\u2019, is an Albanian variety named after the village of Shesh just west of Tirana, from which it probably originates. DNA profiling has shown that Pucalla grown in the Berat region in south-central Albania is identical to Shesh i Bardh\u00eb (Ladoukakis et al. 2005) but it is not yet known whether Shesh i Bardh\u00eb is a colour mutation of SHESH I ZI.',
    ],
    'where its grown': [
      'Shesh i Bardh\u00eb and SHESH I ZI are the two main indigenous varieties planted in Albania, mostly around Tirana, together constituting around 30% of the vineyard area at the beginning of the twenty-first century. They tend not to be planted in the cold eastern and north-eastern parts of the country (Maul et al. 2008). Arb\u00ebri and \u00c7obo, among others, produce a varietal Shesh i Bardh\u00eb.',
    ],
  },
  {
    name: 'SHESH I ZI',
    synonyms: ['Seshi Zi', 'Shesh'],
    description: 'Albania\u2019s most planted indigenous dark-skinned variety.',
    'origin and parentage': [
      'Shesh i Zi, literally \u2018black Shesh\u2019, is an Albanian variety named after the village of Shesh just west of Tirana, from which it probably originates. DNA profiling has not yet been done to verify whether SHESH I BARDH\u00cb is a colour mutation of Shesh i Zi.',
      'A close genetic relationship with SYRAH has been suggested (see that entry) but this can be rejected by DNA profiling.',
    ],
    'where its grown': [
      'See SHESH I BARDH\u00cb for details of where in Albania these two varieties are planted.',
    ],
  },
  {
    name: 'SHEVKA',
    synonyms: ['Chevka'],
    description: 'Bulgarian making bland, low-alcohol, low-acid reds.',
    'origin and parentage': [
      'Shevka is considered to be indigenous to the Sliven region in central eastern Bulgaria, where it has been widely cultivated. Lack of morphological diversity (Katerov 2004) suggests it is a relatively young variety.',
    ],
    'where its grown': [
      'Vini Sliven, founded in 1920 and currently one of the largest wineries in Bulgaria, was originally called Shevka, perhaps an indication of the importance of the variety at that time, although in 2008 there were just 156 ha (385 acres) of it left, split between the provinces of Sliven in central eastern Bulgaria and Burgas further east. Vini Sliven relaunched their Shevka a few years ago but the fruit is not generally rich enough in sugar, acidity or tannins to make a serious red wine. Silvenska Perla make a semi-sweet version and it is also grown for the table.',
    ],
  },
  {
    name: 'SHIROKA MELNISHKA',
    synonyms: [
      'Chiroka Melnichka',
      'Melnik',
      'Shiroka Melnishka Loza',
      'Siroka Melniska',
    ],
    description:
      'Very old, late-ripening, top-quality variety associated with Melnik in the far south west of Bulgaria producing powerful, ageworthy reds.',
    'origin and parentage': [
      'Shiroka Melnishka, often called Shiroka Melnishka Loza, literally \u2018broad-leaved vine of Melnik\u2019, is a very old Bulgarian variety named after the town of Melnik in south-western Bulgaria, at the foot of the Pirin Mountains and close to the Greek border.',
      'Shiroka Melnishka was used to breed MELNIK 82, RANNA MELNISHKA LOZA and RUEN.',
    ],
    'where its grown': [
      'More generally referred to simply as Melnik, this variety produces impressively dense dry red wines with high levels of extract, tannin and acidity, well suited to oak maturation and more ageworthy than reds made from the other local red wine varieties of Bulgaria. It is extensively and almost exclusively planted in the Struma Valley in the far south west of the country, where it is ideally suited to this the warmest wine region: 2,602 ha (6,430 acres) in 2009. Recommended producers include Damianitza and Logodaj. Semi-sweet and sweet, fortified wines are also made.',
    ],
  },
  {
    name: 'SHIRVANSHAHY',
    synonyms: ['Shirvan Schahi', 'Shirvanshakhi', 'Shirvan Shakhi'],
    description:
      'Powerful, Azeri variety used to make fortified, semi-sweet as well as dry reds.',
    'origin and parentage': [
      'Shirvanshahy is local to the Kurdamir region in central Azerbaijan.',
    ],
    'where its grown': [
      'Shirvanshahy is grown in Azerbaijan to make a fortified semi-sweet wine called Kurdamir as well as deeply coloured, full-bodied, ageworthy dry reds. Producers include Kurdamir Wines.',
    ],
  },
  {
    name: 'SIBIRKOVY',
    synonyms: ['Efremovsky', 'Sibirek', 'Sibir\u2019kovy'],
    description:
      'Very minor Russian variety making aromatic whites on the banks of the Don.',
    'origin and parentage': [
      'Sibirkovy originates from the Rostov wine region along the River Don in south-western Russia, close to the border with Ukraine, not in Siberia, as its name might suggest.',
    ],
    'where its grown': [
      'Sibirkovy is found to a limited extent (less than 15 ha/37 acres in 2011) in the Vedernikov wine region on the banks of the Don near Rostov-on-Don in Russia, where the Vedernikov Winery produce a soft, aromatic wine with flavours of wild grasses, thyme, green apples, citrus and white peaches.',
    ],
  },
  {
    name: 'SIDALAN',
    synonyms: ['Sidalak'],
    description:
      'Rare Turkish variety grown mainly on the Aegean island of Bozcaada.',
    'origin and parentage': [
      'Sidalan probably originates from the island of Bozcaada off Turkey\u2019s western coast.',
    ],
    'where its grown': [
      'Grown mainly on the Turkish Island of Bozcaada in the Aegean, western Turkey, Sidalan is also found in the Ezine and Bayrami\u00e7 regions on the mainland to the east. It is rarely made as a varietal wine, with the exception of G\u00fclerada, but Talay, for example, blend Sidalan from Ezine with VASILAKI from Bozcaada in their Assos white blend and Sidalan from Bayrami\u00e7 with S\u00c9MILLON from Lapseki in their T\u00e9nedos white.',
    ],
  },
  {
    name: 'SIDERITIS',
    synonyms: [
      'Akaki',
      'Chimoniatiko',
      'Siderites',
      'Sideritis Scopelan',
      'Sideritis Scopelou',
      'Sidiritis',
    ],
    description:
      'Pink-skinned Greek variety grown mainly for the table but now showing potential for crisp, peppery whites.',
    'origin and parentage': [
      'Sideritis is said to be indigenous to central Greece, where it was mentioned in the nineteenth century (Nikolau and Michos 2004). In Cyprus there is also a variety called Sideritis but it has a completely distinct DNA profile (Hvarleva, Hadjinicoli et al. 2005) and is no longer cultivated. The name comes from the Greek word sidero, meaning \u2018iron\u2019, referring to the toughness of the skins.',
    ],
    'where its grown': [
      'Once grown mainly for the table in the north-western Pelop\u00f3nnisos (Peloponnese), Greece, it has more recently been made into fresh, steely, peppery wines in the northern Pelop\u00f3nnisos, particularly in P\u00e1tra, where Parparoussis is leading the way with about 25% of the region\u2019s production and some of the best varietal examples, as well as an excellent eau de vie. It is also found in the north of mainland Greece, Thessal\u00eda and Attik\u00ed.',
    ],
  },
  {
    name: 'SIEGERREBE',
    synonyms: ['Alzey 7957', 'Scheu 7957', 'Sieger'],
    description: 'Overpoweringly Muscat-scented, low-acid German cross.',
    'origin and parentage': [
      'A MADELEINE ANGEVINE \u00d7 SAVAGNIN ROSE cross bred by Georg Scheu at Alzey in 1929, confirmed by DNA profiling (Vouillamoz). Siegerrebe was used to breed ORTEGA and ROSETTA.',
    ],
    'where its grown': [
      'Germany\u2019s total of 103 ha (255 acres), mainly in Rheinhessen and the Pfalz, continues to decline, thankfully. Its potential for very high natural sugar levels (the name translates as \u2018champion vine\u2019) makes it suitable for sweet wines, but its low acidity does not. Its heady, sometimes pungent Muscat aroma can be overwhelming and even a small percentage in a blend tends to overpower all other components. Knobloch in Rheinhessen, Schmitt-Peitz in the Nahe and Rabenhof in Baden nevertheless persist with varietal wines.',
      'England has 10 ha (25 acres) and one notably successful producer of trophy-winning Siegerrebe is Three Choirs in Gloucestershire. It is also planted in Denmark, at Domain Aalsgaard, for example, and in Switzerland.',
      'It is also grown in another particularly cool, wet wine region, the San Juan Islands of Washington State, where Lopez Island Vineyards produce a varietal version. British Columbia in Canada has 38 acres (15 ha) and producers include Domaine de Chaberton, Gray Monk, Larch Hills and Recline Ridge.',
      'Palmara in Tasmania, Australia, make a varietal Siegerrebe. It has to be harvested early to retain enough acidity but, in small quantities, can add body and flavour to other whites.',
    ],
  },
  {
    name: 'SILVANER',
    synonyms: [
      'B\u00e1lint',
      'Gros Rhin',
      'Gr\u00fcner Silvaner',
      'Gr\u00fcner Zierfandler',
      'Zierfandl',
      'Johannisberg',
      '\u00d6sterreicher',
      '\u00d6sterreichisch',
      'Roter Silvaner',
      'Salfin',
      'Silvain Vert',
      'Silvanac Zeleni',
      'Silv\u00e1nske Zelen\u00e9',
      'Sylvaner',
      'Sylvaner Verde',
      'Sylvanske Zelen\u00e9',
      'Zeleni Silvanec',
      'Z\u00f6ld Szilv\u00e1ni',
    ],
    description:
      'Austrian variety making relatively neutral wines with impressively racy firmness and staying power in Germany and Alsace.',
    'origin and parentage': [
      'DNA profiling has unexpectedly shown that Silvaner is a natural SAVAGNIN \u00d7 \u00d6STERREICHISCH WEISS cross (Sefc, Steinkellner et al. 1998). Silvaner is first mentioned in 1665 under the name \u00d6stareiche Rebe (Sylvaner), when it was introduced by abbot Alberich Degen at the Cistercian abbey of Ebrach in southern Germany. This means that the cross took place spontaneously more than 500 years ago and the name suggests this happened in Austria. Indeed, Savagnin was mentioned in Austria in 1349 under its well-known synonym Traminer, and \u00d6sterreichisch Weiss is an ancient variety cultivated mainly in Austria in the region around Wien (Vienna). Since \u00d6sterreichisch Weiss was restricted mainly to the eastern part of Austria, it is most likely that Silvaner was born in this area. This is consistent with the historical synonym \u00d6sterreichisch still in use in Franken today, and this also explains why the DNA profile of B\u00e1lint (Galb\u00e1cs et al. 2009), considered indigenous to the nearby region of Syrmia (today straddling Serbia and Croatia), turns out to be identical to Silvaner (Vouillamoz).',
      'The Silvaner family tree can be expanded further (see PINOT pedigree diagram). Pinot and SAVAGNIN have a parent\u2013offspring relationship (Regner et al. 2000b), and \u00d6STERREICHISCH WEISS is a progeny of GOUAIS BLANC (Regner et al. 1998). Therefore, Silvaner is either a grandchild or a half-sibling of Pinot, and a grandchild of Gouais Blanc. Silvaner also had offspring by two crosses with ROTER VELTLINER: FR\u00dcHROTER VELTLINER and NEUBURGER. Silvaner was also extensively used by grape breeders and is a parent of several varieties, some of them used for commercial wines: ALBALONGA, BUKETTRAUBE, FREISAMER, JUWEL, KANZLER, ?MORIO-MUSKAT, NOBLING, ORANIENSTEINER, OSTEINER and RIESLANER.',
    ],
    'where its grown': [
      'Although Silvaner\u2019s origins are in Austria, the 44 ha (109 acres) planted there, mostly in Nieder\u00f6sterreich (Lower Austria), are far outstripped by those in Germany and France.',
      'In France plantings of the vine called here Sylvaner rather than Silvaner are declining and are exclusively in Alsace, where there\u2019s a total of 1,446 ha (3,573 acres), about 10% of the total vignoble. Agathe Bursin, Rolly Gassmann, Josmeyer, Ren\u00e9 Mur\u00e9, Andr\u00e9 Ostertag and Domaine Weinbach are among the finest exponents here. The variety can retain acidity rather more proficiently than the PINOT BLANC and AUXERROIS that have ousted Sylvaner because they are easier to grow. Sylvaner achieves particular distinction on the slopes of the Bas-Rhin, especially around Mittelbergheim \u2013 so much so that in 2005 the Zotzenberg vineyard was recognized as a Grand Cru, exceptionally, for its Sylvaner.',
      'Italy has just 113 ha (279 acres) of what it calls Sylvaner Verde and it\u2019s a speciality of the Valle Isarco (Eisacktaler in German) in the Alto Adige in the far north of the country. Kuenhof is a particularly fine exponent of this nervy combination of the variety and relatively high altitude.',
      'Germany has by far the greatest total area planted, with 5,236 (12,938 acres), including 1,276 ha (3,153 acres) in Franken, 2,467 ha (6,096 acres) in Rheinhessen and 844 ha (2,086 acres) in the Pfalz, but the area planted to Silvaner has declined considerably in the last 100 years: at the beginning of the twentieth century it was Germany\u2019s most important white wine variety; now it is only the third most planted after RIESLING and M\u00dcLLER-THURGAU.',
      'There may be more Silvaner vines in Rheinhessen than in Franken but the latter is where the variety finds its greatest expression and where its 350th anniversary was celebrated in 2009. The finest wines, grown on clay-limestone soils, are not particularly aromatic and tend to have only moderate acidity but they are dry, mineral, sometimes earthy, firm and full-bodied. The W\u00fcrzburger Stein vineyard is one of the best sites, with a long-established reputation. Silvaner is still revered at the Castell estate, where it was introduced from Austria, and can make lovely wines on the Steigerwald slopes near Iphofen. Best producers include Horst Sauer, Hans Wirsching, F\u00fcrst L\u00f6wenstein, Juliusspital and Schloss Sommerhausen and virtually all the wines are sold in the distinctive flat flask known as the Bocksbeutel.',
      'In Rheinhessen, the variety has a noble history and producers such as Wittmann and Keller demonstrate the \u2018greener\u2019, leafier side of this variety, producing wines that are slightly more aromatic than classic Franken wines, still with the dry weight and power, and ideally suited to accompany asparagus. Although Silvaner is typically made in a dry style, witness the Silvaner RS classification introduced for this style of wine in the mid 1980s, there are some excellent and ageworthy examples of sweeter wines from Auslese to TBA and everything in between.',
      'Although Switzerland has just 246 ha (608 acres), mostly in the Valais region, its name here has been the cause of a certain amount of confusion and anguish: from the nineteenth century until 1928, Johannisberg was a synonym for RIESLING in the Valais. From 1928 up to now, Johannisberg has been a synonym for Silvaner in the Valais (some local agronomists were angry with this switch at that time). This synonym is now much more widespread than Gros Rhin, which was also an old Swiss (mainly Valais and Vaud) synonym for Silvaner. The variety ripens after the popular CHASSELAS but can produce fine wine from favoured sites.',
      'It is also planted in the southern regions of Morava in the south east of the Czech Republic (104 ha/257 acres), where it is known as Sylvanske Zelen\u00e9, in Slovakia as Silv\u00e1nske Zelen\u00e9, in Slovenia as Zeleni Silvanec (105 ha/259 acres in 2009), in Croatia as Silvanac Zeleni (195 ha/482 acres in 2009), in Ukraine (c.70 ha/173 acres in 2009) and in the Republic of Moldova (98 ha/242 acres) in 2009.',
      'Beyond Europe, producers seem to have paid little attention to this variety, perhaps because it is easily underestimated given its rather neutral aromatic profile. However, Flood Family Vineyards and Rancho Sisquoc grow some in Santa Barbara, California, there are limited plantings in British Columbia in Canada \u2013 and New Zealand recorded 4 ha (10 acres) in 2008.',
      'ROTER SILVANER and BLAUER SILVANER',
      'Roter Silvaner and Blauer Silvaner cannot be genetically distinguished from Silvaner and are simple colour mutations. Roter Silvaner is also called Cirfandler, \u00d6sterreicher Roth, Piros Cirfandli, Piros Szilvani, Rifai Piros, Roter Zierfahndler, Sch\u00f6nfeiler Roth, Zierfahndler Rot. Blauer Silvaner is restricted to the W\u00fcrttemberg area of Germany. It is also called Blauer \u00d6sterreicher, Blauer Reifler, Blauer Sch\u00f6nfeilner, Blauer Zierfahndler, Bodenseeburgunder,',
      'Bodenseetraube, Schwarzer \u00d6sterreicher, Schwarzer Silvaner and Sylvaner Rouge.',
    ],
  },
  {
    name: 'SIRAM\u00c9',
    synonyms: ['Salom\u00e9'],
    description:
      'Very minor Swiss hybrid that adapts well to marginal climates.',
    'origin and parentage': [
      'Siram\u00e9 is a hybrid obtained in the 1970s by Anton Meier at Rebschule Meier in W\u00fcrenlingen in the canton of Aargau, northern central Switzerland, from undisclosed parents, one of them probably a Seyve-Villard hybrid.',
    ],
    'where its grown': [
      'Developed as a table and wine grape, Siram\u00e9 covered just 2 ares (0.05 acres) in Switzerland in 2009. Davinum in Z\u00fcrich are perhaps the only producer to use this variety, blended with the French hybrid L\u00c9ON MILLOT. Also found in Denmark and southern Sweden.',
    ],
  },
  {
    name: 'S\u00cdRIA',
    synonyms: [
      'Alva',
      'Alvadur\u00e3o',
      'Alvaro de Soire',
      'Alvaro de Sousa',
      'Blanca Extra',
      'Cig\u00fcente',
      'Coda',
      'C\u00f3dega',
      'Colh\u00e3o de Gallo',
      'Crato Branco',
      'Do\u00f1a Blanca',
      'Graciosa',
      'Gracioso',
      'Malvas\u00eda',
      'Malvas\u00eda Blanca',
      'Malvasia Branca',
      'Malvas\u00eda Castellana',
      'Malvas\u00eda Grosso',
      'Moza Fresca',
      'Posto Branco',
      'Roupeiro',
      'Sabro',
      'Valenciana',
      'Verdegudillo',
    ],
    description:
      'Aromatic, light-skinned variety widely planted in Portugal under various names, with as many names but fewer vines in Spain.',
    'origin and parentage': [
      'S\u00edria is a very old variety that is known in the north west of the Iberian peninsula under various synonyms, mainly C\u00f3dega, Do\u00f1a Blanca, Malvasia Castellana and Roupeiro (Arranz et al. 2010). The earliest mention of this variety was found in Spain under the name Cig\u00fcente in Alonso de Herrera\u2019s 1513 description of the varieties of Castilla, Extremadura and Andaluc\u00eda (Alonso de Herrera 1790). It was described as a variety that is similar to the Alvillas, the generic name of the Albillos (see ALBILLO REAL). In Portugal, it was first mentioned near Lamego in the district of Viseu in the Douro region by Rui Fernandes (1531\u20132) under the synonym Alvaro de Sousa. Its wide distribution makes it difficult to locate the variety\u2019s birthplace, but recent genetic investigations have suggested that it could come from around Pinhel in the district of Guarda in north-eastern Portugal, where it has shown the highest level of diversity (Almadanim et al. 2007) and where it is known as S\u00edria, which is why this is its official name (along with the synonym Roupeiro).',
      'With DNA parentage analysis, Zinelabidine et al. (2012) have recently found a parent\u2013offspring relationship between S\u00edria and CAYETANA BLANCA, a variety that is widespread throughout the south and centre of the Iberian peninsula (see Cayetana Blanca for the pedigree diagram); Lopes et al. (1999) found that the white and no longer cultivated Boal Ratinho from the Carcavelos region near Lisboa originates from a natural cross between MALVASIA FINA (under the name Boal da Madeira) and S\u00edria.',
      'Dona Branca, sometimes known as Dona Branca do D\u00e3o, is also used as a synonym for S\u00edria, possibly because it is so similar to Do\u00f1a Blanca, one of the Spanish synonyms for S\u00edria. However, there is a distinct variety in Portugal called DONA BRANCA, grown mainly in the Beiras, Douro and Tr\u00e1s-os-Montes regions.',
    ],
    'where its grown': [
      'The variety is controversial among producers: proponents point to the intensity of its aromas \u2013 floral notes such as linden, acacia, orange and laurel blossom as well as orange, peach and melon fruit \u2013 but its opponents highlight its tendency to oxidize, which causes the premature loss of such aromas, and its lack of ageing capacity (Rolando Faustino, personal communication). Alcohol and acidity tend to be about average. However, work on clonal selection aims to increase the variety\u2019s productivity and allow harvesting before the acidity drops but without a reduction in sugar levels. In the meantime, producers vinifying more carefully and sometimes blending with other local varieties are able to achieve these ends.',
      'Under its various names, but officially S\u00edria or Roupeiro, this is the fifth most planted variety in Portugal (and the second most planted white after FERN\u00c3O PIRES), with 13,778 ha (34,046 acres) in 2010, from the Douro in the north, where it is known as C\u00f3dega, to the Algarve in the south, where it is known as Crato Branco. Roupeiro has long been a recommended variety in the Alentejo: records from 1889 show that it was grown in thirty-two of the thirty-seven subregions, with even more synonyms then than now. In the area around Pinhel and Figueira de Castelo Rodrigo in Beira Interior in the north east, S\u00edria is the most important white variety and the basis of many of the appellation\u2019s wines \u2013 Quinta do Cardo (part of Companhia das Quintas) make a good example there.',
      'There were 656 ha (1,621 acres) of Do\u00f1a Blanca in Spain in 2008, mainly in Galicia (especially Monterrei and Bierzo but also Valdeorras, where it is known as Valenciana) and Castilla y Le\u00f3n, and 148 ha (366 acres) of Cig\u00fcente in Extremadura. Quinta da Muradella\u2019s Gorvia Blanco from Monterrei is a good example of varietal Do\u00f1a Blanca: full, dense and peachy with a fabulously satin-smooth texture. Vi\u00f1as del Bierzo\u2019s varietal wines tend to be more neutral.',
    ],
  },
  {
    name: 'SKLAVA',
    synonyms: ['Sklaba', 'Sklabes', 'Sklabos', 'Sklaves', 'Sklavos'],
    description: 'Greek variety producing lightweight wines used in blends.',
    'origin and parentage': [
      'Sklava originates from Argol\u00edda in the eastern Pelop\u00f3nnisos (Peloponnese), where it has often been mistakenly considered to be related to the Italian SCHIAVA GENTILE (Nikolau and Michos 2004); both Sklava and Schiava mean \u2018slave\u2019 and Schiava is sometimes used as a synonym for Sklava.',
    ],
    'where its grown': [
      'Sklava, traditionally grown in Argol\u00edda, Greece, for blending, produces wines that are low in alcohol but can have relatively fresh acidity. Kontovraki are unusual in making a varietal example.',
    ],
  },
  {
    name: 'SKOPELITIKO',
    synonyms: ['Scopelitico', 'Skopelitis'],
    description: 'Speciality of the island of K\u00e9rkyra (Corfu).',
    'origin and parentage': [
      'As the name might suggest, Skopelitiko is said to come from the Aegean island of Sk\u00f3pelos in the Spor\u00e1des, off the east coast of mainland Greece and brought by monks to the island of K\u00e9rkyra.',
    ],
    'where its grown': [
      'Today Skopelitiko is considered a speciality of K\u00e9rkyra, Greece, grown mainly around Ano Garouna in the centre of the island and to a lesser extent in the south. Wines can be rather rustic. Livadiotis are unusual in making a deeply coloured varietal wine, which has moderate acidity, 12\u201313% alcohol and fine tannins.',
    ],
  },
  {
    name: '\u0160KRLET',
    synonyms: [
      'Ovnek Slatki',
      'Ovnek \u017duti',
      '\u0160krlet Tusti',
      '\u0160krtec',
    ],
    description:
      'Delicate, fresh Croatian variety enjoying a new lease of life.',
    'origin and parentage': [
      '\u0160krlet originates from the Moslavina and Pokuplje wine regions, south east of Zagreb, in the centre of Kontinentalna Hrvatska (Continental Croatia). The name \u0160krlet supposedly derives from the German word Scharlach, meaning \u2018scarlet fever\u2019, referring to the red speckles on the surface of the berry skin.',
    ],
    'where its grown': [
      '\u0160krlet is officially authorized in the Moslavina, Prigorje-Bilogora and Pokuplje wine regions east of Zagreb in northern Croatia but had become virtually extinct until efforts to recuperate it began in the late 1990s, resulting in a current increase in popularity and demand. Varietal wines, which are typically light and fresh with delicate aromas of spring flowers or hay, are made by producers such as Ivan Belajec, Marijan Brli\u0107, Juren, Kezele, Vladimir Kos, Ko\u0161uti\u0107, Marko Miklau\u017ei\u0107 and Vladimir Mik\u0161a in Moslavina as well as Anto Marin\u010di\u0107 and Trdeni\u0107 in Pokuplje.',
    ],
  },
  {
    name: 'SKYLOPNICHTIS',
    synonyms: [
      'Kasteliotiko',
      'Mavros Arkadias',
      'Skylopnichtis Kokkino',
      'Skylopnichtra',
      'Skylopnihti',
    ],
    description: 'Rare Greek variety used in traditional local blends.',
    'origin and parentage': [
      'Skylopnichtis, meaning \u2018smothers the dog\u2019, is a Greek variety that may come from Z\u00e1kynthos or possibly from the Argol\u00edda and Arkad\u00eda regions in the Pelop\u00f3nnisos (Peloponnese). The white-berried Skylopnichtis Lefkos (no longer commercially cultivated but found occasionally on Z\u00e1kynthos, Lefk\u00e1da and in Aitol\u00eda-Akarnan\u00eda on the south-western mainland) is said not to be a colour mutation (Galet 2000) but this is uncertain.',
    ],
    'where its grown': [
      'Skylopnichtis is found to a very limited extent on the island of Z\u00e1kynthos, Greece, where it is used in blends. Comoutos, for example, blend it with local varieties such as AVGOUSTIATIS and KATSAKOULIAS.',
    ],
  },
  {
    name: 'SLANKAMENKA',
    synonyms: [
      'Ma\u0111aru\u0161a',
      'Maghiarca',
      'Magyarica',
      'Magyark\u0103',
      'Majarc\u0103 Alb\u0103',
      'Slankamenka B\u00e9la',
      'Szlanka Feh\u00e9r',
    ],
    description:
      'Ancient Balkan variety producing generally uninspiring whites.',
    'origin and parentage': [
      'Like KREACA, Slankamenka is a very old Balkan variety, possibly originating from the Banat, the historical region that is today part of Romania, Serbia and Hungary. Found under various different names, it shows significant morphological diversity \u2013 and therefore its age \u2013 in all three countries (Avramov and del Zan 2004). The synonym Maghiarca means \u2018Hungarian\u2019 but Slankamen is the name of a village in the Srem district of Vojvodina.',
      'The black-berried Slankamenka Rosie, also called Majarc\u0103 Rosie in Romania, is probably a colour mutation, while the pink-berried Slankamenka Crvena is a synonym for PAMID.',
    ],
    'where its grown': [
      'In Serbia Slankamenka is planted exclusively in Vojvodina in the north. Wines are typically low in alcohol, acid and flavour and are often part of a blend. Distillation is common. Producers of varietal wines include Antonijevi\u0107 Winery and Dejan Dejanov. There were 55 ha (136 acres) in Romania in 2008 under the name Majarc\u0103 Alb\u0103 and the variety is also found in Hungary and eastern Croatia.',
      'There were 27 ha (67 acres) of Majarc\u0103 Rosie in Romania in 2008.',
    ],
  },
  {
    name: 'SOLARIS',
    synonyms: ['Freiburg 240-75'],
    description:
      'Modern, disease-resistant and early-ripening German hybrid that produces tooth-rottingly high sugar levels.',
    'origin and parentage': [
      'A MERZLING \u00d7 Geisenheim 6493 hybrid bred by Norbert Becker in Freiburg in Baden-W\u00fcrttemberg, southern Germany, in 1975, where Geisenheim 6493 is a Zarya Severa \u00d7 MUSCAT OTTONEL hybrid, Zarya Severa is a Seyanets Malengra \u00d7 Vitis amurensis hybrid, and Seyanets Malengra is a seedling of PR\u00c9COCE DE MALINGRE. Solaris has been authorized in Germany since 2004. The name Solaris refers to the sun, as a symbol of power and early ripening. Solaris was used to breed CABERNET CAROL, CABERNET CORTIS and MONARCH.',
    ],
    'where its grown': [
      'Solaris has become one of the more planted varieties in the new wine regions in the far north of vinous Europe. There were 4 ha (10 acres) in Denmark in 2010, at Domaine Aalsgaard, for example, and it is one of the most successful varieties in Sweden. It is also planted to a limited extent in Norway and Belgium.',
      'There are 59 ha (146 acres) in Germany, mainly in Baden in the south but there are also a few hectares in Rheinhessen. Early trials have produced wines with aromas of pineapple and hazelnut and pretty good acidity but which are more neutral though powerful (sometimes alcoholic) on the palate. Schindler and Engelhof in Baden and Jan Ulrich in Sachsen are already producing varietal wines.',
      'It is grown on 11 ha (27 acres) in Switzerland and producers include Cultiva (Solaris Amabile from the canton of Thurgau), Chalmberger (Aargau), R\u00e4blus (Solaris Dessertwein, from the canton of Bern) and Forster (Aargau). Solaris was authorized in Italy in 2011.',
    ],
  },
  {
    name: 'SOLDAIA',
    synonyms: ['SD-56', 'Soldaiya'],
    description:
      'Old, late-ripening Ukrainian variety found occasionally in blends.',
    'origin and parentage': [
      'Soldaia is an old and obscure variety from Krym (Crimea), southern Ukraine. Soldaia was the Venetian name for the fortress at Sudak.',
    ],
    'where its grown': [
      'Grown for the table and the vat in Ukraine, Soldaia is typically a minor component in blended dessert or fortified wines such as Arkhadersse by Sun Valley (Solnechnaya Dolina).',
    ],
  },
  {
    name: 'SOLNECHNODOLINSKY',
    synonyms: ['DM-63', 'SD-2'],
    description: 'Productive Ukrainian pale-skinned variety.',
    'origin and parentage': [
      'Selected by P M Gramotenko, V V Pestretsov, N S Skripkin, A M Romanenko, M N Matvienko, L P Troshin, V F Karzov and V V Karzova in 1969 at the Magarach research centre in Krym (Crimea), southern Ukraine, because it was morphologically similar to SARY PANDAS. It was named after the Solnechnaya Dolina region and included in the official register of varieties in 1995 but its pedigree is still unknown.',
    ],
    'where its grown': [
      'Solnechnodolinsky\u2019s generous yields and high sugar levels make it a useful ingredient in the production of semi-sweet and dessert wines in Ukraine such as Sun Valley\u2019s eponymous bottling and in the sweet, fortified Arkhaderesse (Sun Valley is the direct translation of Solnechnaya Dolina).',
    ],
  },
  {
    name: 'SOPERGA',
    synonyms: ['Incrocio Dalmasso IV/31', 'Superga'],
    description:
      'Rare Conegliano cross whose true parentage has only recently been revealed.',
    'origin and parentage': [
      'Italian vine breeder Giovanni Dalmasso announced that Soperga was a NEBBIOLO \u00d7 BARBERA cross created in 1936 at the Conegliano research centre in the Veneto, north-east Italy, with the aim of combining the quality of Nebbiolo and the resistance and the naturally high yields of Barbera. It was included in the official register of varieties in 1977. However, DNA profiling has recently shown that the Nebbiolo used by Dalmasso was in fact Nebbiolo di Dronero, which is not Nebbiolo but a synonym for CHATUS (Torello Marinoni, Raimondi, Mannini and Rolle 2009). Soperga is therefore a Chatus \u00d7 Barbera cross, just like its siblings ALBAROSSA, CORNAREA, NEBBIERA and SAN MICHELE.',
    ],
    'where its grown': [
      'Used for wine as well as for the table in Piemonte, north-west Italy. There were 32 ha (79 acres) in Italy in 2000, although it is now difficult to find evidence of any commercial production.',
    ],
  },
  {
    name: 'SORBIGNO',
    synonyms: ['Sorbino', 'Sorvegna', 'Sorvigno'],
    description: 'A minor Ischian speciality.',
    'origin and parentage': [
      'Sorbigno is an obscure and ancient variety from the Italian island of Ischia, where it was mentioned as long ago as 1588 (Monaco 2006). However, Sorbigno does not appear in the local atlas of varieties and its identity is still unknown (Migliaccio et al. 2008).',
    ],
    'where its grown': [
      'Sorbigno is exclusive to the island of Ischia, southern Italy, where La Vigna dell\u2019Encadde is probably the only producer of a varietal wine.',
    ],
  },
  {
    name: 'SPERGOLA',
    synonyms: [
      'Spargoletta Bianca',
      'Spergol\u00e0',
      'Spergolina',
      'Spergolin\u00e0is',
    ],
    description:
      'Tart Emilia-Romagnan thought until very recently to be Sauvignon Blanc.',
    'origin and parentage': [
      'Spergola was first mentioned by Dalla Fossa in 1811 as one of the best grape varieties of Reggio Emilia in northern Italy (Giavedoni and Gily 2005). It was often confused with SAUVIGNON BLANC \u2013 on ampelographic rather than organoleptic grounds \u2013 until further analysis and DNA profiling proved that they were distinct varieties (Filippetti, Silvestroni, Ramazzotti and Intrieri 2001). In addition, DNA analysis suggested a close genetic relationship between Spergola and PIGNOLETTO (Filippetti, Silvestroni, Thomas and Intrieri 2001).',
    ],
    'where its grown': [
      'Spergola was a major grape variety in Reggio Emilia in the nineteenth century, where it is now cultivated on approximately 200 ha (494 acres). Spergola is no longer listed as a synonym for SAUVIGNON BLANC in the official register of varieties in Italy and is included separately, authorized in Emilia-Romagna, in particular as the main variety (minimum 85%) in white Colli di Scandiano e di Canossa DOC. Spergola can produce dry, sparkling or sweet varietal wines. Tenuta di Aljano\u2019s dry version is appropriately named: La Vigna Ritrovata means \u2018the rediscovered vine\u2019; the alcohol is quite high but it has very good acidity and roundness on the palate. The high level of acidity also makes it suitable for the production of sparkling wine. Ca\u2019 de Noci\u2019s Querciole is a good example of the latter but they also make a still varietal wine called Reserva dei Fratelli and use Spergola in blends with MOSCATO GIALLO and what the producer calls \u2018Malvasia Aromatica\u2019 (see MALVASIA).',
    ],
  },
  {
    name: 'SPOURTIKO',
    synonyms: ['Spourtico'],
    description: 'Rare Cypriot variety producing light, zesty whites.',
    'origin and parentage': [
      'Spourtiko, meaning \u2018bursting\u2019, referring to the explosive habit of the berries, is indigenous to the district of P\u00e1fos on the island of Cyprus. According to DNA profiling, it is relatively close to the dark-skinned OFTHALMO, also from Cyprus (Hvarleva, Hadjinicoli et al. 2005).',
    ],
    'where its grown': [
      'Wines typically have fresh flavours of grapefruit and lemon, sometimes lemongrass, low alcohol (rarely above 11%) and can be slightly grassy. There were just 10 ha (25 acres) on the island of Cyprus in 2010, a significant increase from less than 2 ha (5 acres) in 2008, but many of the Spourtiko vines, found mostly in P\u00e1fos and to a lesser extent around Lemes\u00f3s (Limassol), are planted in alternate rows with MARATHEFTIKO to help improve the pollination \u2013 and thus the yields \u2013 of the latter rather than for the quality of the wine. Fikardos is one of the few producers to make a varietal wine.',
    ],
  },
  {
    name: 'ST CROIX',
    synonyms: ['ES 2-3-21', 'Sainte-Croix', 'Ste-Croix'],
    description:
      'Very cold-hardy, complex American hybrid gaining ground in Qu\u00e9bec and the Midwest.',
    'origin and parentage': [
      'An Elmer Swenson 283 \u00d7 Elmer Swenson 193 hybrid bred in 1974 by Elmer Swenson in Osceola, Wisconsin, US (see SABREVOIS for the pedigrees). The vine first fruited in 1977 and the variety was introduced in 1981 and patented to Elmer Swenson in 1982. This complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis lincecumii and Vitis rupestris hybrid has the same parents as its sibling Sabrevois. St Croix was used to breed CHISAGO and PIONNIER.',
    ],
    'where its grown': [
      'Wines are typically light- to medium-bodied with moderate acidity, fairly low alcohol, light tannins and are generally best in a blend. They are free from foxy labrusca flavours and can show heavy flavours of tobacco, tar or smoke if overripe or overly macerated (Hart nd). St Croix (often Ste Croix in Canada) is the most widely planted variety in the province of Qu\u00e9bec (36 acres/15 ha in 2009) and is also found in the American Midwest (eg 29 acres/12 ha in Iowa in 2006, 20 acres/8 ha in Illinois in 2007, 18 acres/7 ha in Minnesota in 2007) and to a lesser extent further east in Pennsylvania (6 acres/2.4 ha in 2008). Varietal wines are produced by Tassel Ridge in Indiana; Fieldstone in Minnesota; La Roche des Brises, Le Royer St-Pierre and Vignoble Ste-P\u00e9tronille in Qu\u00e9bec.',
    ],
  },
  {
    name: 'ST PEPIN',
    synonyms: ['Elmer Swenson 282', 'ES 282', 'Saint-P\u00e9pin'],
    description:
      'Fruity, low-acid American hybrid that often has a hint of foxiness.',
    'origin and parentage': [
      'A complex hybrid of Elmer Swenson 114 and SEYVAL BLANC made around 1950 by Elmer Swenson in Osceola, Wisconsin, US, in which:',
      '\u2013\u00a0\u00a0\u00a0Elmer Swenson 114 is a Minnesota 78 \u00d7 ROSETTE hybrid',
      '\u2013\u00a0\u00a0\u00a0Minnesota 78 is a Beta \u00d7 Witt hybrid (see the BRIANNA pedigree diagram for Minnesota 78)',
      '\u2013\u00a0\u00a0\u00a0Beta is a Carver \u00d7 CONCORD hybrid, where Carver is a selected Vitis riparia vine',
      '\u2013\u00a0\u00a0\u00a0Witt is a seedling of CONCORD, itself descended from Vitis labrusca.',
      'This complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis lincecumii and Vitis rupestris hybrid was introduced in 1983 and plant patent was assigned to Swenson Smith Vines in 1986. It is a sibling of LA CROSSE and was used to breed LA CRESCENT.',
    ],
    'where its grown': [
      'Varietal wines, which are fruity but often have a hint of foxy labrusca flavour and generally low acidity, are made by Cannon River Winery in Minnesota and Parallel 44 in Wisconsin. In Canada, where there were just a few acres in 2009, it is often blended with other cold-hardy hybrids such as PRAIRIE STAR and VANDAL-CLICHE, although Domaine les Brome in Qu\u00e9bec produce a varietal St Pepin. Plantings in the US Midwest are limited. For example, there were 17 acres (7 ha) in Minnesota in 2007, 15 acres (6 ha) in Iowa in 2006, 10 acres (4 ha) in Illinois in 2007 and 2 acres (less than 1 ha) in Nebraska in 2007. It is occasionally used to make icewine.',
    ],
  },
  {
    name: 'STANU\u0160INA CRNA',
    synonyms: ['Gradesh', 'Stanu\u0161hina Crna', 'Strnu\u0161ina', 'Tikvesko'],
    description:
      'Macedonian variety in dire need of vineyard selection to improve quality.',
    'origin and parentage': [
      'Stanu\u0161ina Crna, originally called Strnu\u0161ina, possibly originates in Tikve\u0161, the Republic of Macedonia\u2019s main vine-growing region in the centre of the country, hence its synonym Tikvesko. A green-berried colour mutation (Stanu\u0161ina Zelena, ie \u2018green Stanu\u0161ina\u2019), which lacks sugar and acidity, as well as a type of Stanu\u0161ina more prone to millerandage and therefore poor yields (Stanu\u0161ina Rehuljava, ie \u2018straggly Stanu\u0161ina\u2019) have been observed in the vineyards (Bozinovic et al. 2003; \u0160tajner, Angelova et al. 2009).',
    ],
    'where its grown': [
      'Like OHRIDSKO CRNO, Stanu\u0161ina Crna was the dominant variety in its region of origin, now in the Republic of Macedonia, before the advent of phylloxera but since then plays a more minor role in Tikve\u0161, with small pockets in other parts of the country. Alcohol is usually moderate but the wines often lack acidity, body and texture and are generally blended with PROKUPAC and/or Krato\u0161ija (TRIBIDRAG). Popova Kula, swimming against the incoming tide of international varieties, make varietal examples, both red and ros\u00e9. Selection in the vineyard is needed to weed out the inferior-quality mutations described above.',
    ],
  },
  {
    name: 'STAVROTO',
    synonyms: ['Ampelakiotiko', 'Ampelakiotiko Mavro', 'Stavromavro'],
    description:
      'Greek variety required in and virtually exclusive to the Raps\u00e1ni appellation.',
    'origin and parentage': [
      'Local variety of the Raps\u00e1ni region in the north east of Thessal\u00eda, eastern central Greece.',
    ],
    'where its grown': [
      'Stavroto is found mainly in Thessal\u00eda, Greece, thanks to its role as one of the three varieties required in the Raps\u00e1ni appellation, along with KRASSATO and XINOMAVRO (usually a field blend). Colour, alcohol and acidity levels are lower than for Xinomavro (Manessis 2000). It\u2019s also found to the north in Koz\u00e1ni and to the south in Magnis\u00eda. Recommended producers of Raps\u00e1ni, a rich, well-structured, spicy wine, include Dougos and Tsantali.',
    ],
  },
  {
    name: 'STEUBEN',
    synonyms: ['New York 12696'],
    description:
      'Minor but successful American hybrid used for the table and for wine.',
    'origin and parentage': [
      'A Wayne \u00d7 Sheridan hybrid obtained in 1925 by Richard Wellington and George D Oberle in the Department of Pomology and Viticulture at Cornell University\u2019s New York State Agricultural Experiment Station, Geneva, US, in which:',
      '\u2013\u00a0\u00a0\u00a0Wayne is a Mills \u00d7 Ontario hybrid (see GR 7 for the pedigrees of Mills and CAYUGA WHITE for that of Ontario)',
      '\u2013\u00a0\u00a0\u00a0Sheridan is a Herbert \u00d7 Worden hybrid (see GR 7 for the pedigree of Herbert)',
      '\u2013\u00a0\u00a0\u00a0Worden is a selected seedling of CONCORD.',
      'This Vitis labrusca and Vitis vinifera hybrid was selected in 1931, then named and introduced in 1947. It was used to breed COROT NOIR and NOIRET.',
    ],
    'where its grown': [
      'Steuben is thinly but widely spread in the US Midwest and the Northeast regions: 45 acres (18 ha) in Pennsylvania in 2008, 37 acres (15 ha) in New York State in 2006, 25 acres (10 ha) and growing in popularity in Indiana and 3 acres (1.2 ha) in Illinois in 2007, for example. Steuben is used to make aromatic white, blush or ros\u00e9 wines at various sweetness levels and is highly regarded as a table grape thanks to the shape of the bunch and the flavour of the grapes. It is also grown for juice. Varietal wines are made by, for example, Marjim Manor, Mayers Lake Ontario and Niagara Landing in New York State; Starr Hill in Pennsylvania; Buck Creek in Indiana; Jewell Town in New Hampshire; Tassel Ridge in Iowa.',
    ],
  },
  {
    name: 'STORGOZIA',
    synonyms: ['Storgoziya'],
    description: 'Recent, usefully disease-resistant Bulgarian hybrid.',
    'origin and parentage': [
      'Storgozia is a BUKET \u00d7 VILLARD BLANC hybrid created in 1976 by I Ivanov, V Valtchev and G Petkov at the Pleven viticulture and oenology institute in northern Bulgaria and given the old name of the ancient city of Pleven.',
    ],
    'where its grown': [
      'There were 738 ha (1,824 acres) in Bulgaria in 2008, almost all in the province of Pleven in the north. Yantra near the town of Ruse, close to the Romanian border, and Chateau de Val, near the city of Vidin in the far north east, include Storgozia in their red blends. Wines have good levels of acid and alcohol and can be aged.',
    ],
  },
  {
    name: 'SULTANIYE',
    synonyms: [
      'Banati',
      'Kishmish',
      'Kis Mis Alb',
      'Ki\u015fmi\u015f',
      'Soultanina',
      'Sultana',
      'Sultani \u00c7ekirdeksiz',
      'Sultanina',
      'Sultanina Blanche',
      'Sultanine',
      'Thompson Seedless',
    ],
    description:
      'The world\u2019s most widely planted light-skinned variety. The sultana grape is only rarely used for wine, fortunately.',
    'origin and parentage': [
      'Sultaniye, named after the Ottoman sultans to whom the precious grape was offered, has been cultivated in so many countries and under so many synonyms that the location of its birthplace has long been the subject of speculation: proposed origins have included Turkey, Greece, South Africa, Afghanistan and Iran.',
      'Colour mutations were observed by Galet (2000) in Afghanistan: Kishmish Sorh (red) and Kishmish Siah (black). Sultaniye has been used to breed many table-grape varieties.',
      'Sultaniye was used to breed numerous wine and table grapes, of which only TARRANGO is commercially cultivated for wine.',
    ],
    'where its grown': [
      'Sultaniye, a seedless variety, as its common synonym Thompson Seedless suggests, is the most widely planted variety in the world but the vast majority of the fruit is eaten dried or fresh. (The synonym Kishmish means \u2018grape for drying\u2019 in Pashtun.) In Turkey, Sultaniye is planted for the same purposes but is also used to make wine on the Manisa plain and around \u0130zmir and Denizli in the Aegean region in the west of the country, where it is less prone to disease. At best the wines are light and rather neutral, sometimes made in an off-dry style. Some of the bigger Turkish producers such as Kavaklidere, Kayra and Doluca make varietal wines but also blend Sultaniye with other local varieties such as EMIR and NARINCE. Total plantings in 2010 were 2,545 ha (6,289 acres) but it is likely that much of this is for the table. It is also grown in neighbouring Armenia.',
      'It is widely planted in Cyprus, where there were 516 ha (1,275 acres) of Soultanina in 2010, for the table and for wine. In Greece there were 1,095 ha (2,706 acres) in the Pelop\u00f3nnisos (Peloponnese) in 2008.',
      'California recorded a massive 198,094 acres (80,166 ha) of Thompson Seedless in 2008 but these were almost all for raisins. (In its time Thompson Seedless has been pressed into winemaking service only in times of severe shortage of light-skinned grapes in California and Australia.) It goes under the same name in Australia, where the variety is grown predominantly for table grapes and raisins.',
    ],
  },
  {
    name: 'SUMOLL',
    synonyms: [
      'Sumoi',
      'Sumoll Negro',
      'Sumoll Tinto',
      'Sunier',
      'Verijariego Negra',
      'Vigiriega',
      'Vigiriega Negra',
      'Vigiriego Negro',
      'Vijariego Negra',
      'Vijariego Negro',
      'Vijiriego Negro',
    ],
    description:
      'Minor Catalan found mainly in red blends, and also now on the Islas Canarias.',
    'origin and parentage': [
      'Sumoll was first mentioned in 1797 in Catalunya, its name derived from sumollar, a local dialect word based on the Latin submolliare, meaning \u2018to ripen\u2019 or \u2018to mature\u2019 (Fav\u00e0 i Agud 2001). DNA profiling has unexpectedly shown that Sumoll is identical to Vijariego Negra from Andaluc\u00eda (SIVVEM), first described by Clemente y Rubio (1807) as a variety that was cultivated mainly in Jerez de la Frontera and was rare in Sanl\u00facar de Barrameda. The variety was also introduced to the Islas Canarias under the name Vijariego Negro, possibly together with Vijariega Blanca (see VIJARIEGO), which is not a colour mutation.',
      'It was used to breed varieties such as CIENNA and TYRIAN in Australia.',
      'SUMOLL BLANC has a distinct morphology and DNA profile and is therefore not a colour mutation of Sumoll (Giralt et al. 2009).',
    ],
    'where its grown': [
      'Spain\u2019s total vineyard area of Sumoll, under that synonym, was 258 ha (638 acres) in 2008, virtually all in Catalunya. It mostly contributes a small percentage to blends, with both local and international varieties, but the following producers make varietal wines, either red or ros\u00e9: Bohigas, Can Ramon Viticultors del Montgr\u00f3s, Cellar Pardas, Heretat Mon-Rub\u00ed. Sumoll has been gaining in popularity and is now authorized for Alella, Catalunya, Pened\u00e8s, Pla de Bages and Tarragona DOs. Wines are typically red-fruited with fresh acidity.',
      'Under the synonym Vijariego Negro, official statistics for 2008 recorded 59 ha (146 acres) on the Islas Canarias, where it is authorized in the El Hierro, La Gomera and Pla de Bages DOs. Tanajara in the El Hierro DO make a particularly good varietal example.',
    ],
  },
  {
    name: 'SUMOLL BLANC',
    synonyms: ['Sumoi Blanc', 'Sumoll Blanco'],
    description:
      'Very rare but promising, refreshing Catalan variety that is unrelated to the dark-berried Sumoll.',
    'origin and parentage': [
      'Sumoll Blanc is local to Tarragona, north-east Spain, but it is not a white mutation of SUMOLL, as DNA profiling has shown (Giralt et al. 2009).',
    ],
    'where its grown': [
      'Sumoll Blanc is planted to a very limited extent in Tarragona and Pened\u00e8s in Catalunya, north-eastern Spain, on just 5 ha (12 acres) of vineyard in 2008. 10 Sentits produce rare varietal oaked and unoaked versions, both of which show fine acidity, minerality and an array of fruit flavours \u2013 not unlike Chablis in character.',
    ],
  },
  {
    name: 'SU\u0160\u0106AN',
    synonyms: [
      'Brajda Velika Crna',
      'Sansigot',
      'Susac',
      'Su\u0161\u0107an Crni',
    ],
    description:
      'Minor Croatian variety found on just a few islands in the Adriatic but gaining in popularity.',
    'origin and parentage': [
      'Su\u0161\u0107an comes from the island of Susak, in the Kvarner Gulf in north-west Croatia, south of the island of Cres. This is confirmed by its synonym Sansigot, derived from Sansego, the Italian name for the island.',
    ],
    'where its grown': [
      'Officially authorized in the Hrvatsko Primorje (Croatian Littoral) wine region, Su\u0161\u0107an is the main variety on the tiny Croatian island of Susak and is also found on the nearby and larger islands of Lo\u0161inj, Cres, Unije and Krk. Wines are typically fairly high in alcohol and sold to the increasing number of tourists who visit these islands. At one time Susak was known as the \u2018floating vineyard\u2019 because 95% of the island was planted with vines. On Krk, Anton Katunar blends Sansigot with SYRAH and GEGI\u0106. Its viticultural advantages and the deeply coloured, full-bodied wines it produces have led to a recent increase in popularity.',
    ],
  },
  {
    name: 'SUSUMANIELLO',
    synonyms: [
      'Cozzomaniello',
      'Grismaniello',
      'Somarello Nero',
      'Susomaniello',
      'Susumaniello Nero',
      'Zingariello',
      'Zuzomaniello',
    ],
    description: 'Minor, deeply coloured Puglian.',
    'origin and parentage': [
      'Susumaniello has long been known in the province of Brindisi in Puglia, southern Italy. Its name could derive from its synonym somarello, meaning \u2018donkey\u2019, a reference to the load this animal is able to bear, just like this vine variety.',
      'Recent DNA parentage analysis has established that Susumaniello is a cross between GARGANEGA and Uva Sogra (Di Vecchi Staraz, This et al. 2007), the latter identical to Uva Sacra (Zulini et al. 2002), a table grape of Puglia (Vouillamoz). As a consequence, Susumaniello is either a grandparent or a half-sibling of eight other varieties having parent\u2013offspring relationships with Garganega: ALBANA, CATARRATTO BIANCO, DORONA DI VENEZIA, MALVASIA BIANCA DI CANDIA, MARZEMINA BIANCA, MONTONICO BIANCO, MOSTOSA and TREBBIANO TOSCANO (see the pedigree diagram in the Garganega entry).',
    ],
    'where its grown': [
      'Susumaniello is authorized in every Puglian province except Foggia in the north. It is cultivated mainly north west and south east of the port of Brindisi, where it is usually blended with other local grape varieties such as MALVASIA NERA DI BRINDISI and NEGROAMARO in the Ostuni and Brindisi DOCs. Tenute Rubino\u2019s Torre Testa (IGT Salento), Racemi\u2019s Sum from the Torre Guaceto nature reserve (a vino da tavola), Lomazzi & Sarli\u2019s Nomas and Cantine Due Palme\u2019s Serre (IGT Salento) are rare examples of 100% Susumaniello wines. They tend to be deeply coloured and have red-fruit flavours but can be quite tart and tough. There were 72 ha (178 acres) in Italy in 2000.',
    ],
  },
  {
    name: 'SWENSON RED',
    synonyms: ['Elmer Swenson 439'],
    description:
      'Minor red-berried American hybrid bred for the chill of the Northwest.',
    'origin and parentage': [
      'A Minnesota 78 \u00d7 Rubilande hybrid bred in 1962 by Elmer Swenson in Osceola, Wisconsin (see BRIANNA for the complete pedigree of Swenson Red), selected in 1967 and released jointly by Elmer Swenson and the University of Minnesota in 1978. Swenson Red is therefore an extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris and Vitis cinerea hybrid. It was used to breed CHISAGO.',
    ],
    'where its grown': [
      'Swenson Red is used mainly for the table but also for wine. Swenson did not recommend fermenting the juice on the skins (Swenson et al. 1980) and this variety is better suited for white or blush wines. It is found mainly in Minnesota (24 acres/10 ha in 2007) and Iowa (5 acres/2 ha in 2006) but there are few varietal wines \u2013 although Summerset Winery in Iowa produce an exception. The variety also seems to be grown in Poland.',
    ],
  },
  {
    name: 'SWENSON WHITE',
    synonyms: ['ES 6-1-43'],
    description:
      'Minor but very complex, cold-hardy American hybrid better suited to the table than to wine.',
    'origin and parentage': [
      'An EDELWEISS \u00d7 Elmer Swenson 442 hybrid obtained by Elmer Swenson in Osceola, Wisconsin, US, in which Elmer Swenson 442 is a Minnesota 78 \u00d7 Rubilande hybrid (see BRIANNA for the pedigrees of Minnesota 78 and Rubilande). Swenson White, selected in 1988 and released in 1994, is therefore an extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris, Vitis cinerea and Vitis berlandieri hybrid.',
    ],
    'where its grown': [
      'According to Mark Hart of Mt Ashwabay Vineyard in Wisconsin (Hart nd), this is really a table grape and any wines made are neutral, sometimes with floral aromas, but have a slight foxy flavour especially when the grapes are picked ripe \u2013 though not as strong as its EDELWEISS parent. Its late ripening means that it is really only suited to Midwest states such as Iowa and Nebraska. It is planted to a very limited extent in the American Midwest and in Canada, where Domaine et Vins Gelinas in Qu\u00e9bec make a rare varietal icewine.',
    ],
  },
  {
    name: 'SYKIOTIS',
    synonyms: ['Chiotis', 'Kiotes', 'Skiotes', 'Skiotis', 'Sykioti'],
    description: 'Greek variety used mainly for distillation.',
    'origin and parentage': [
      'Sykiotis has long been known in Thessal\u00eda in central Greece but its origin is not clear (Nikolau and Michos 2004). The name derives from s\u00fdko, meaning \u2018fig\u2019.',
    ],
    'where its grown': [
      'Although this variety is mostly used for distillation, in Greece, into the spirit tsipouro, the Dimitra Co-operative in N\u00e9a Anh\u00edalos, south-eastern Thessal\u00eda, also make a dry red Sykiotis varietal wine. Also found in Makedon\u00eda in the north and on the large island of \u00c9vvoia (Euboea).',
    ],
  },
  {
    name: 'SYMPHONY',
    synonyms: ['Davis S15-58'],
    description: 'Rare and highly aromatic Muscat-flavoured California cross.',
    'origin and parentage': [
      'A GARNACHA ROJA \u00d7 MUSCAT OF ALEXANDRIA cross bred by Harold P Olmo in 1940 at the University of California at Davis and released in 1983 (Walker 2000).',
    ],
    'where its grown': [
      'Symphony produces fruity, Muscat-flavoured wines from dry to sweet, both still and sparkling. After a certain rise in popularity as an off-dry table wine in the 1990s, the acreage in California reached over 700 acres (283 ha) in 2004 and in 2009 stood at 803 acres (325 ha), with the biggest plantings in Fresno, Merced, Yolo and San Joaquin counties in the Central Valley. Producers of varietal Symphony in California include Maple Creek in the Yorkville Highlands, who make an off-dry wine and a sweet late-harvest version, Sebastiani in Sonoma and Michael David Winery in Lodi. Ironstone have the most significant plantings of this variety in California, in Lodi and the Sierra Foothills, and they regularly sell out of this popular medium-dry wine. New plantings are likely since it is also in demand as a blending component. The Symphonys bottled by Winter Park in Colorado and Pahrump Valley in Nevada are based on grapes grown in California whereas Volcano Winery on Hawaii grow their own Symphony grapes.',
    ],
  },
  {
    name: 'SYRAH',
    synonyms: [
      'Candive',
      'Hermitage',
      'Marsanne Noire',
      'Petite Sirrah',
      'S\u00e9r\u00e8ne',
      'Serine',
      'S\u00e9rine',
      'Serinne',
      'Shiraz',
      'Sira',
      'Sirac',
      'Sirah',
      'Syra',
      'Syrac',
    ],
    description:
      'Generally fashionable alternative to Cabernet Sauvignon with a complex and surprising family background.',
    'origin and parentage': [
      'Syrah\u2019s most famous and historic home is in the vineyards of the northern Rh\u00f4ne, such as Hermitage and C\u00f4te R\u00f4tie. It was first mentioned by Faujas de Saint-Fond (1781) under various spellings: \u2018la Sira de l\u2019Hermitage .\u00a0.\u00a0. produit un vin agr\u00e9able, g\u00e9n\u00e9reux, stomachique et qui gagne en vieillissant: l\u2019on peut .\u00a0.\u00a0. y m\u00ealer une petite quantit\u00e9 de raisins blancs, ainsi qu\u2019on le pratique \u00e0 Tain. La Serine et le Vionnier de C\u00f4te-R\u00f4tie seroient tr\u00e8s-bien aussi\u2019 (\u2018Sira from Hermitage .\u00a0.\u00a0. gives a nice, generous, appetizing and ageworthy wine: we can .\u00a0.\u00a0. blend a small quantity of white grapes, as is done in Tain. Serine and Vionnier from C\u00f4te R\u00f4tie would be suitable as well\u2019). Sira de l\u2019Hermitage and Serine from C\u00f4te R\u00f4tie are simply local variations of Syrah (the variety and the name) although Saint-Fond clearly considered them to be different varieties. The name Syrah could derive, via its synonym S\u00e9rine, from the Indo-European root *ser- indicating a long period, which gave the Latin serus, meaning \u2018late ripening\u2019 (Andr\u00e9 and Levadoux 1964).',
      'Syrah begat DURIF through a natural crossing with PELOURSIN.',
      'Zizak is occasionally used as a synonym for Syrah although it is also the name of a distinct Montenegrin variety (see ZIZAK).',
      'SYRAH\u2019S PARENTS UNCOVERED In 1998, DNA parentage analysis carried out at the University of California at Davis and INRA (Institut National de la Recherche Agronomique) in Montpellier, southern France, unexpectedly showed that Syrah is a natural cross between MONDEUSE BLANCHE (mother) and DUREZA (father; Bowers et al. 2000). Mondeuse Blanche is a Savoie variety that used also to be cultivated in the Ain, Is\u00e8re and Haute-Savoie d\u00e9partements, and Dureza is an Ard\u00e8che variety that was also formerly cultivated, together with Syrah, in the Dr\u00f4me and the Is\u00e8re. The crossing that gave birth to Syrah had to take place in a vineyard where both parents were cultivated together, making it very likely that this happened in the French Rh\u00f4ne-Alpes region, probably in the Is\u00e8re (Meredith and Boursiquot 2008).',
      'PINOT, PROBABLE GREAT-GRANDPARENT OF SYRAH Using a type of probabilistic analysis of DNA data that had never before been applied to grape genetics, Vouillamoz and Grando (2006) at the Istituto Agrario di San Michele all\u2019Adige in northern Italy have shown that DUREZA is a sibling of TEROLDEGO, an old variety from Trentino in northern Italy. This was the first evidence of a close genetic link between two varieties on different sides of the Alps. Since Syrah is a progeny of Dureza, it is therefore a nephew/niece of Teroldego. Vouillamoz and Grando have also detected a second-degree genetic relationship between PINOT and both Dureza and Teroldego, which means that Pinot could be their grandparent, grandchild, uncle/aunt, nephew/niece or half-sibling. Since Pinot was already known in France and in the Tyrol in the fourteenth century, its cultivation predates that of both Dureza and Teroldego and it is logical to consider Pinot as their ancestor, either their grandparent (illustrated in diagram 1 overleaf) or their uncle/aunt. Therefore, Pinot is a very likely great-grandparent of Syrah, which challenges the supposition that they have completely different origins.',
      'MONDEUSE NOIRE AND VIOGNIER IN THE SYRAH PEDIGREE DNA parentage analysis has recently shown that MONDEUSE BLANCHE from Savoie has a parent\u2013offspring relationship with both MONDEUSE NOIRE from Savoie/Haute-Savoie and VIOGNIER from the northern Vall\u00e9e du Rh\u00f4ne (Vouillamoz 2008), which means that Mondeuse Noire and Viognier are either parents or offspring of Mondeuse Blanche. However, in the absence of the other parent, it is impossible to know which is the parent and which the offspring. As a consequence, three pedigrees are equally possible, as shown in diagram 2 below.',
      'If MONDEUSE NOIRE is a parent of MONDEUSE BLANCHE through a natural cross-pollination with an unknown and probably extinct variety, then Mondeuse Noire must be a grandparent of Syrah and VIOGNIER (option A opposite), because the relationship Mondeuse Blanche = Mondeuse Noire \u00d7 Viognier is rejected by DNA analysis. In this case, Viognier is a progeny of Mondeuse Blanche and a half-sibling of Syrah.',
      'Conversely, if MONDEUSE NOIRE is a progeny of MONDEUSE BLANCHE through a natural cross-pollination with an unknown and probably extinct variety, then Mondeuse Noire is a half-sibling of Syrah (option B on p 1025). In this case, VIOGNIER may either be the parent of Mondeuse Blanche, and a grandparent of Syrah and Mondeuse Noire, or Viognier could be a progeny of Mondeuse Blanche as well, and thus a half-sibling of both Mondeuse Noire and Syrah (option C), the other parents of Viognier and Mondeuse Noire being distinct, unknown and probably extinct.',
      'As a consequence, Syrah is either a grandchild or a half-sibling of both MONDEUSE NOIRE and VIOGNIER, which explains why Mondeuse Noire was called Grosse Syrah in the Dr\u00f4me region, and why all four varieties were clustered in the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009).',
      'SYRAH\u2019S MOST COMPLETE FAMILY TREE Keeping in mind that other pedigree reconstructions are theoretically possible for the reasons explained above, we propose the most plausible and comprehensive family tree for Syrah (see diagram 3 below): it is a natural progeny of MONDEUSE BLANCHE and DUREZA, a half-sibling of VIOGNIER, a grandchild of MONDEUSE NOIRE, a niece/nephew of TEROLDEGO and a great-grandchild of PINOT.',
      'The genetic links discovered by paternity testing strongly anchor the origins of Syrah in the French Rh\u00f4ne-Alpes region.',
    ],
    'where its grown': [
      'Until the late twentieth century, Syrah was grown chiefly in the Vall\u00e9e du Rh\u00f4ne and, as Shiraz, in Australia. But a combination of a surge in Australian wine exports, a fashion for all things Rh\u00f4neish and a certain ennui with Bordeaux red wine grapes combined to encourage widespread planting of this variety. Its wines have a very different profile from those of the Cabernet family. Their tannins are in general much gentler and there is more obvious weight on the mid palate, especially if, as is often the case, Syrah is blended with other Rh\u00f4ne and southern French varieties such as Grenache (GARNACHA) and Mourv\u00e8dre (MONASTRELL). Syrah\u2019s flavours tend to be in the leather, liquorice and tar spectrum with marked black pepper or even burnt-rubber aromas in slightly underripe examples but much sweeter black-fruit flavours in Syrah picked fully ripe in warm climates. Wines made from very ripe to overripe (and therefore thoroughly shrivelled) berries can have flavours of dark chocolate and prunes, sometimes with porty overtones.',
      'Syrah plantings have increased quite staggeringly in France in the last fifty years: from 1,602 ha (3,959 acres) in 1958 to 68,587 ha (169,482 acres) in 2009, making it the third most planted red wine grape overall after MERLOT then Grenache (GARNACHA). It is planted throughout south and south-eastern France (it would have difficulty ripening reliably north of Lyon) with the greatest total area in Languedoc-Roussillon (43,163 ha/106,658 acres), not least because in recent years, as the EU has offered uprooting and replanting incentives to tackle its wine surplus in southern France, Syrah has been considered the first-choice c\u00e9page am\u00e9liorateur (improving variety) to replace the poor-quality, high-yielding varieties such as ARAMON NOIR, ALICANTE HENRI BOUSCHET and Carignan (MAZUELO) planted in the first half of the twentieth century and to add structure, complexity and longevity to blends often based on Grenache. Throughout Languedoc-Roussillon, Syrah is most often encountered in blends. In the southern Rh\u00f4ne, Syrah is the second most planted variety, on about a third as much vineyard as Grenache, and is generally used to add backbone and longevity to blends such as Ch\u00e2teauneuf-du-Pape and similar wines. Proven\u00e7al vineyards to the east have a long tradition of growing Syrah and, often, blending it with CABERNET SAUVIGNON (a blend also long sanctioned in Australia).',
      'It is in the northern Rh\u00f4ne that French Syrah shines as a mono-varietal wine. The magnificently concentrated, long-lived reds grown on the granite rock of Hermitage represent the most \u2018manly\u2019 aspect of Syrah, varying considerably according to precise soil type and aspect but usually with a notably glossy texture and luscious but bone-dry fruit. Crozes-Hermitage is a lesser, earlier-maturing version made on lower ground around the hill of Hermitage. The steep, south-east-facing terraces of C\u00f4te R\u00f4tie represent almost the northern limit of Syrah cultivation and, not just because a small proportion of VIOGNIER is often co-fermented with the Syrah, this is stereotypically Syrah at its purest, most refreshing, lighter and more \u2018feminine\u2019 (although Guigal\u2019s single-vineyard trophy wines are exceptionally concentrated versions). Saint-Joseph\u2019s terrain between C\u00f4te R\u00f4tie and Hermitage is too varied to allow generalizations.',
      'Syrah has been planted in Italy since 1899, when it was introduced from Montpellier to Piemonte, but total plantings were still only 1,039 ha (2,567 acres) by 2000. Italian wine growers are generally much more besotted by Bordeaux red wine grapes but the most successful area for Syrah so far has been Cortona in southern Toscana, although several appellations on the island of Sicilia \u2013 eg Alcamo, Erice, Menfi \u2013 allow varietal Syrah wines, so it is perhaps not surprising that 5,357 ha (13,237 acres) were recorded there in 2008. Recommended producers of varietal Syrah in Italy include Gillardi in Piemonte; Isole e Olena and Fontodi in the heart of the Chianti region; La Braccesca, Ruffino and Tenimenti d\u2019Alessandro in Cortona; and Cottanera and Planeta on Sicilia.',
      'Wine producers in Spain, on the other hand, have recently been planting Syrah so enthusiastically that the national total rose from under 3,000 ha (7,413 acres) in 2004 to 16,568 ha (40,940 acres) by 2008. Most Syrah vines are in Castilla-La Mancha in the centre of the country but there are also significant areas in Arag\u00f3n and Catalunya in the north east, Murcia in the south east and Extremadura in the west. Carlos Falc\u00f3, Marqu\u00e9s de Gri\u00f1on, was one of the earliest Spanish exponents at Dominio de Valdepusa near Toledo and others who have produced Syrah of seriously notable quality, only rarely as a varietal, include Abad\u00eda Retuerta, Castell d\u2019Encus (Thalarn), Finca Sandoval and Pago del Ama. The wines tend to be notably sweeter and plumper than French Syrahs.',
      'Over the border in Portugal, Syrah is planted to no enormous extent and mainly in the Alentejo and also in the Tejo and Lisboa regions, with experimental plantings in the Douro. Recommended producers include Cortes de Cima, who pioneered it in the Alentejo, long before it was officially permitted, plus Quinta Lagoalva de Cima and Quinta da Lapa in the Tejo.',
      'Switzerland, and especially the sunny slopes in the upper reaches of the Vall\u00e9e du Rh\u00f4ne in the Valais, produces unexpectedly concentrated wine from mature vines. Recommended producers include Jean-Ren\u00e9 Germanier, Simon Maye & Fils, and L\u2019Orpailleur. There was a total of just 181 ha (447 acres) in 2009.',
      'Syrah, often labelled Shiraz in markets where Australian wine is familiar, is also found in many other European and Mediterranean wine-producing countries such as Croatia (110 ha/272 acres in 2009), Romania (16.4 ha/41 acres in 2008), Hungary (100 ha/247 acres in 2008), Malta (100 ha/247 acres in 2010), Cyprus (257 ha/635 acres in 2010) and Turkey, where there was quite a sizeable area \u2013 1,489 ha (3,679 acres) \u2013 in 2010. Greece grows a limited amount of Syrah but the likes of Alpha Estate, Gerovassiliou and Spiropoulos have demonstrated that it can produce both fine varietals and the odd accomplished blend in different climates here. Syrah is also reckoned to be the second most planted red wine grape in Lebanon, and Israel had 350 ha (865 acres) in 2009. The variety likes warmth.',
      'One would therefore expect California to be an enthusiastic grower of Syrah but in fact the 2010 state total of 19,283 acres (7,803 ha) was dwarfed by the more than 37,290 acres (15,091 ha) devoted to the much more fragile, supposedly cool-climate variety PINOT NOIR. Fashion rules the Golden State, but the worldwide fashion for Syrah has eluded Californians \u2013 or perhaps they just got there first. Under the noisy influence of a California movement known as the Rh\u00f4ne Rangers, total plantings in the decade to 2003 rose from 400 to 17,000 acres (162 to 6,880 ha), but since then there have been remarkably few new plantings. It is said that the American consumer is bemused by Syrah, doesn\u2019t really know what to expect. The advent of the Sideways/Pinot Noir phenomenon and a sudden rise and fall in the reputation of Australian Shiraz have combined to diminish the lustre of Syrah in California. Syrah was a major beneficiary of the new Central Coast plantings and the county with the most is San Luis Obispo with 2,770 acres (1,121 ha). Many ambitious, relatively new wine producers here are devoted to the variety and to blending it with other Rh\u00f4ne grapes, but often in tiny quantities. Some of the more obvious fine Syrah producers are Alban (Edna Valley), Cline (Carneros), DuMol and Pax Walker (Russian River Valley), Swanson (Napa), Lagier Meredith (Mount Veeder), Terre Rouge (Sierra Foothills), Baileyana (Edna Valley), Favia (Sierra Foothills) and Sean Thackrey\u2019s ingenious blends.',
      'Washington State winemakers on the other hand are firmly in the grip of Rh\u00f4ne fever, which resulted in a dramatic increase in plantings of Syrah in recent years to a total of 3,103 acres (1,256 ha) by 2011, making it the third most planted red variety after CABERNET SAUVIGNON and MERLOT. The variety responds well to the famously bright-fruited style of the state and some of those who have already made a name for their Syrah (there are many more in the wings) are Betz Family, Cayuse, L\u2019Ecole No 41, Hedges Family, McCrea Cellars and Charles Smith of K Vintners.',
      'Oregon\u2019s climate is less obviously suitable for Syrah but the state had 572 acres (231 ha) planted by 2008 and the likes of Domaine Serene and newcomer Gramercy Cellars certainly show potential for the variety here. A little is also grown in Idaho and in Arizona, Texas and Colorado. Tedeschi Vineyard on the Hawaiian island of Maui have planted several acres of Syrah, mostly to replace the inferior CARNELIAN. Mexico has plantings of Syrah in Baja California and Coahulia de Zaragoza.',
      'In Chile, Syrah is currently regarded with considerable hope and ambition. It was first planted there at the end of the nineteenth century but was later eradicated in the agrarian reform of the 1970s. Syrah began its second life in Chile in the mid 1990s, a trend led by Err\u00e1zuriz in Aconcagua, although there is some debate about who actually planted first (Richards 2010). Thanks to the recent rush to develop this variety, plantings had already reached 3,370 ha (8,327 acres) by 2008, the vast majority in Colchagua although there have more recently been encouraging results in the more northerly and cooler San Antonio, Limar\u00ed and Elqui regions from the likes of Casa Marin, Falernia, Matetic, Maycas del Limar\u00ed and Vi\u00f1a Leyda. In Cachapoal, Alta\u00efr incorporate Syrah in their impressive red blends and top-notch varietal wines are produced by the likes of Lapostolle and Santa Rita; in Colchagua, Montes, Polkura and Viu Manent have had good results.',
      'Argentina has more Syrah planted than Chile: 12,960 ha (32,025 acres) in 2008, the majority in Mendoza, up from fewer than 1,000 ha (2,500 acres) in 1990. As in Chile, most producers call their wine Syrah rather than Shiraz and the variety is an important component in many top red blends such as Cuvelier Los Andes and Dominio del Plata as well as delivering a range of styles on its own in the hands of, for example, O Fournier. But since the Argentines already have their very own hugely successful alternative to Bordeaux grapes in Malbec (COT), there is less impetus to focus on Syrah than across the Andes. That said, San Juan has shown a particular aptitude for the north Rh\u00f4ne grape, which is also found in Peru, Bolivia and Uruguay.',
      'Of all Syrah\u2019s many homes outside France, Australia is by far the most important, and has the second largest plantings after France of the variety, here called Shiraz, although when it was first taken to the Antipodes, probably from Montpellier by James Busby in 1832, it was known as Scyras. It flourished and was eagerly adopted in New South Wales and then spread quickly to other wine-growing states. It fell briefly out of favour in the late twentieth century when \u2018French\u2019 CABERNET SAUVIGNON seemed more exotic and glamorous but today Shiraz is the most widely planted variety in the country, with 43,977 ha (108,670 acres) in 2008 \u2013 almost 45% of the entire red wine crush and 24% of the total grape crush. Much of it is planted in the inland regions such as the Riverland, Murray Darling and Riverina, where it is heavily irrigated and makes oceans of inexpensive, sweet, lightweight red wine that have done little for the image of Australian wine in recent years.',
      'Much more serious and certainly more concentrated wines are made in the Barossa Valley, where 5,281 ha (13,050 acres) of the variety include many of Australia\u2019s oldest vines, some of them centenarians producing extremely rich, long-lived, spicy nectars such as Penfolds Grange, Australia\u2019s most famous wine \u2013 although Henschke\u2019s Hill of Grace Shiraz, also made from some of the oldest vines in the world, grown in the Eden Valley, is a serious rival. Tar and chocolate are the predominant flavours, while the Shiraz of McLaren Vale (2,882 ha/7,122 acres) is rather more open and breezy. Langhorne Creek grows 2,097 ha (5,182 acres) of easy, spicy Shiraz but its name is relatively rarely seen on labels; Australian wine producers are inveterate blenders. It makes finer textured wines in the Clare Valley (1,614 ha/3,988 acres), cooler and tarter examples in Padthaway (1,470 ha/3,632 acres) and Coonawarra (1,241 ha/3,067 acres) in South Australia, and has its own very particular earthy style in the Hunter Valley (1,001 ha/2,474 acres) in New South Wales. There are also significant plantings in Western Australia, Queensland and Canberra District, where Clonakilla pioneered a particularly Rh\u00f4ne-like style of wine, introducing co-fermentation with VIOGNIER, which became a rather exaggerated trend at one stage. Virtually every Australian producer worth their salt, except those in the coolest reaches of the country, makes a worthwhile Shiraz or possibly several. Cabernet/Shiraz blends have also long been popular in Australia.',
      'New Zealand is generally too cool for the heat-loving Syrah (there were just 278 ha/687 acres in 2008) but Hawke\u2019s Bay in the North Island has already provided some delicious exceptions to this rule, notably made by Bilancia, Craggy Range, Sacred Hill, Te Mata and Trinity Hill. The wines are based on a single clone imported from Australia and rescued from the Te Kauwhata viticultural research station by Alan Limmer of Stonecroft. Martinborough also seems to suit this variety, as Dry River, Ata Rangi and Kusuda have demonstrated. Almost all examples in New Zealand are labelled Syrah rather than Shiraz, to emphasize its cooler climate presumably.',
      'In South Africa, the name Shiraz is more widely used, perhaps inspired by envy of Australia\u2019s formidable track record as a wine exporter, although two styles of the variety exist. Shiraz generally denotes the dominant full-throttle, heavily oaked style that is more Australian than French, although there are notable exceptions such as Boekenhoutskloof, The Foundry, Haskell and Lammershoek, all of them highly successful Cape renditions of a lighter, more savoury style (labelled Syrah). There were 9,907 ha (24,481 acres) of the variety in 2008, quite widely dispersed and representing nearly 10% of the total vineyard area, a huge increase since 1995, when it represented just 1%. Virus-free clones have made a big difference to the quality of the vines but there is still a major problem with what has been labelled Shiraz disease, a fatal, graft-transmissible viral disease specific to South Africa that also affects MERLOT, GAMAY NOIR and Malbec (COT) and some white varieties. Research is still underway but it is thought to be caused by several different grapevine viruses and spread by mealybugs. It is different from what is known as Syrah decline in France and Syrah/Shiraz disorder in California. These appear to be connected to specific clones and do not spread from vine to vine; the symptoms start at the graft point and the cause is as yet unknown.',
      'As well as producing varietal wines, the variety plays a major part in many excellent blends with, for example, Grenache (GARNACHA) and Mourv\u00e8dre (MONASTRELL), particularly in the Swartland, where top producers include Eben Sadie, Mullineux and Lammershoek. In the bigger, oakier style of varietal wines some of the more successful performers include Simonsig, Haskell, Hartenberg, De Trafford and Saxenburg. Good examples from Stellenbosch include Quoin Rock and Waterkloof; cooler-climate styles are produced by, for example, Eagles\u2019 Nest (Constantia), Julien Schaal (Elgin) and Luddite (Walker Bay). Solms-Delta also make sweetish, potent wines from desiccated Syrah grapes.',
      'China grew 195 ha (482 acres) of Syrah in 2009 and it was also known in Thailand.',
    ],
  },
  {
    name: 'SYRIKI',
    synonyms: [
      'Cserichi',
      'Kserikhi',
      'Kseriki',
      'Kserukhti',
      'Seriki',
      'Syrike',
      'Xerichi',
      'Xerichi Mavro',
      'Xeruchti',
      'Xirichi',
    ],
    description:
      'Vine variety grown spottily around Greece but rarely seen on labels.',
    'origin and parentage': [
      'Although Syriki is found in many parts of Greece, its exact origin is unknown.',
    ],
    'where its grown': [
      'Syriki is grown on many of the islands of Greece \u2013 the Ionian Islands, the southern Kykl\u00e1des (Cyclades), K\u00e9rkyra (Corfu), Kr\u00edti (Crete), \u00c9vvoia (Euboea) \u2013 as well as in the Pelop\u00f3nnisos (Peloponnese) and in southern and western mainland Greece (Ladoukakis et al. 2005). On the island of Ikar\u00eda, between S\u00e1mos and M\u00b4ykonos, Afianes, for example, blend Syriki with other local varieties FOKIANO and KORIOSTAFYLO.',
    ],
  },
  {
    name: 'TAMAREZ',
    synonyms: ['Arinto Gordo', 'Boal Prior', 'Tamares', 'Tamarez Branco'],
    description:
      'Old southern Portuguese variety authorized in many wine regions but usually distilled or blended.',
    'origin and parentage': [
      'Tamarez was first mentioned in 1712 by Vicencio Alarte in southern Portugal (Galet 2000). However, Tamarez is a rather confusing name that has been used for several distinct varieties in Portugal, so that care should be taken about the true identity of Tamarez, depending on where it\u2019s grown, especially in the Alentejo, where it is often confused with TRINCADEIRA DAS PRATAS even though they are ampelographically quite distinct.',
    ],
    'where its grown': [
      'Tamarez is found mainly in the Alentejo, where the Borba co-op, for example, use it in various white blends with other local varieties such as Roupeiro (S\u00cdRIA) and ANT\u00c3O VAZ. Although it is widely authorized for regional and DOC wines from the Algarve in the south to the Douro in the north, much of it ends up distilled. There were 602 ha (1,488 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'TAMURRO',
    synonyms: ['Coll d\u2019Tammurr'],
    description: 'Virtually extinct, thick-skinned Basilicata variety.',
    'origin and parentage': [
      'Tamurro was formerly cultivated in the region of Pietragalla in the province of Potenza in Basilicata, southern Italy, and was thought to be extinct until it was recently rediscovered around the town of Barile in the Vulture area between Bari and Napoli, where local growers called it Coll d\u2019Tammurr, a name already in use in the Middle Ages when the Duca Filiberto di Savoia conquered Vulture. It is said that the duke was tired of the powerful, rich AGLIANICO wines and ordered the introduction of a more gentle variety from France. One of the first to plant this variety was a robust local grower with a neck like a bull, nicknamed Coll d\u2019Tammurr, the local dialect for collo di tamburo, meaning \u2018drum neck\u2019, which gave the name to the variety. DNA profiling is needed to determine whether it is the same as an existing French grape variety.',
    ],
    'where its grown': [
      'Tamurro used to be harvested overripe and blended with other local varieties to add flesh and colour. It had been effectively forgotten until it was rediscovered by Tenuta Le Querce in Barile, Vulture, Italy. They were the first to produce a varietal wine, under the modernized Italian name Tamurro and with an ambitious price tag.',
    ],
  },
  {
    name: 'TANNAT',
    synonyms: [
      'Bordelez Beltza',
      'Harriague',
      'Madiran',
      'Moustrou',
      'Moustroun',
      'Tanat',
    ],
    description:
      'Powerful, characterful, often tannic variety at home in south-west France and Uruguay but becoming global.',
    'origin and parentage': [
      'Tannat was first mentioned in 1783\u20134 in Madiran in the Hautes-Pyr\u00e9n\u00e9es in south-west France, its most likely origin and where it is still today the dominant variety (R\u00e9zeau 1997): \u2018le tanat est un raisin noir .\u00a0.\u00a0.\u2019 (\u2018tanat is a black grape .\u00a0.\u00a0.\u2019). The modern spelling with double \u2018n\u2019 appeared in 1827 (R\u00e9zeau 1997): \u2018le vin de Madiran est produit par le tannat\u2019 (\u2018the wine of Madiran is produced from tannat\u2019). The name Tannat probably derives from the B\u00e9arn dialect tanat, meaning \u2018coloured like tan\u2019, in reference to the dark colour of the berries and also possibly to their high tannin content.',
      'According to Lavignac (2001), Tannat could be the progeny of an undetermined variety from the Pyr\u00e9n\u00e9es, and it is also morphologically close to BAROQUE from the Landes and to LAUZET from the B\u00e9arn in the Pyr\u00e9n\u00e9es-Atlantiques, two varieties that belong, like Tannat, to the Courbu ampelographic group (see p XXVII), together with COURBU BLANC, CROUCHEN, MANSENG NOIR and PETIT COURBU (Bisson 2009). This grouping is consistent with recent parentage analysis based on twenty-two DNA markers suggesting a possible parent\u2013offspring relationship between Tannat and Manseng Noir (Vouillamoz; to be confirmed with more markers).',
      'Tannat was used to breed ARINARNOA and EKIGA\u00cfNA.',
    ],
    'where its grown': [
      'Tannat wines are deeply coloured, tannic with marked acidity, often powerful and ageworthy, especially from vines grown on clay soils in its Madiran heartland in south-west France. In the past, they were often unbroachable in youth but many of the wines made today are much softer and more supple, less rustic, although most improve with age. Wines grown on sandy soils are generally more gentle in youth but still have a surprising Peter Pan quality. It comes as no surprise that the technique of micro-oxygenation, deliberately bubbling tiny amounts of oxygen into the nascent wine to soften the tannins, was developed in the Madiran region.',
      'There were 2,914 ha (7,201 acres) of Tannat in France in 2009, the vast majority in the Gers but also in the neighbouring regions of south-west France. It is the principal ingredient in Madiran (40\u201380%) and Saint-Mont (minimum 60%), its principal blending partners being CABERNET FRANC and CABERNET SAUVIGNON. It also contributes to the Iroul\u00e9guy, B\u00e9arn, Tursan, C\u00f4tes du Brulhois and Cahors appellations. Recommended producers include Alain Brumont and Ch\u00e2teau d\u2019Aydie in Madiran and Producteurs Plaimont in Saint-Mont. Tannat\u2019s popularity has increased in the twenty-first century thanks to its high level of procyanidins, reported to be the source of red wine\u2019s health benefits (Corder 2006). The Gers region of France apparently has double the national average of nonagenarian men.',
      'Italy has a little (45 ha/111 acres in 2000), mainly on Sicilia.',
      'It is also found to a more limited extent in California (238 acres/96 ha in 2008), where Tablas Creek in Paso Robles have been one of the pioneers of this variety. Some is also grown in Lodi. Other US states such as Virginia (just over 20 acres/8 ha planted very recently), Illinois and Georgia are also trying their hand at Tannat. British Columbia in Canada grows a little.',
      'Tannat\u2019s second home, however, is in Uruguay, where it used to be called Harriague after the Frenchman from the Basque region who introduced the variety to Uruguay during a period of Basque immigration. Pascal Harriague planted a Tannat vineyard 400 km north of Montevideo, on the outskirts of the city of Salto, in 1870 (Gonz\u00e1lez-Techera et al. 2004). In 2009 it was the most widely planted, and proudly trumpeted, variety in the country, with 1,784 ha (4,408 acres) in total, almost 22% of the national vineyard area. Wines produced here tend to have slightly more rounded tannins thanks to the climate. Some are blended with softer varieties such as MERLOT or PINOT NOIR but the best retain the variety\u2019s hallmark freshness and ability to age. Recommended producers include Arerungu\u00e1, Bouza, Castillo Viejo, De Lucca, Juanic\u00f3, Pisano, Pizzorno and Stagnari. Tannat has spread across the border into several regions of Argentina (553 ha/1,366 acres in 2008) and into Brazil (421 ha/1,040 acres in 2007 in Rio Grande do Sul) and Tacama grow it in Peru.',
      'A tiny amount is grown in Australia such as by Glenguin in the Hunter Valley and there is a tiny amount in Switzerland, South Africa and Japan.',
    ],
  },
  {
    name: 'TAUBERSCHWARZ',
    synonyms: [
      'Blaue Hartwegstraube',
      'Blauer H\u00e4ngling',
      'Els\u00e4sser',
      'Frankentraube',
      'Grobrot',
      'Grobschwarze',
      'H\u00e4usler',
      'S\u00fcssrot',
    ],
    description:
      'Old, unidentified cross used to produce light reds in southern Germany.',
    'origin and parentage': [
      'Tauberschwarz (\u2018black of Tauber\u2019) is one of the few old, indigenous varieties of Baden-W\u00fcrttemberg still in cultivation today, mainly in the Tauberfranken wine-growing region. Its earliest written mention dates back to 1726 in a decree of the bishopric of W\u00fcrzburg during the reign of Graf Carl-Ludwig von Hohenlohe of Weikersheim: \u2018Tauber schwarzen Weinbergsfexern\u2019 (\u2018cuttings of Tauber schwarzen\u2019). The first reference to the name Tauberschwarz appeared in 1757 in Fr\u00e4nkischen Sammlungen von Anmerkungen aus der Naturlehre, a N\u00fcrnberg newspaper.',
    ],
    'where its grown': [
      'This old, dark-skinned variety was once widely planted in Baden and W\u00fcrttemberg (mainly in the Kocher, Jagst and Tauber valleys) in Germany and had almost disappeared in the early twentieth century, remaining only in the regions of Laudenbach (Vorbachtal) and Weikersheim (Taubertal).',
      'In the 1960s, the Weinsberg research centre in Baden-W\u00fcrttemberg in southern Germany selected thirty cuttings from a vineyard in Ebertsbronn (Vorbachtal), which was probably the last and only old vineyard where Tauberschwarz had survived. They carefully studied and selected the best vines and Tauberschwarz was then authorized in Germany in 1994. Today there are only 15 ha (37 acres), cultivated by a small group of producers crafting light and lightly coloured wines, with the support of the Slow Food movement. Varietal wines are made by Hubert and Renate Benz, Engelhart, and Alois and J\u00fcrgen Hofmann, all in Franken, and by Schurk and Winzergenossenschaft Beckstein in Baden.',
    ],
  },
  {
    name: 'TAVKVERI',
    synonyms: [
      'Takweri',
      'Tarkveri',
      'Tavaveri',
      'Tavkeri Kartalinsky',
      'Tavkeri Kartlis',
      'Tavkveri Didmartsvala',
    ],
    description:
      'Georgian variety producing very small amounts of light, tangy reds.',
    'origin and parentage': [
      'Tavkveri is indigenous to the Kakheti and Kartli regions in eastern and central Georgia.',
    ],
    'where its grown': [
      'Tavkveri produces light and very fresh, cherry-flavoured dry reds. It is planted in Georgia\u2019s Kartli region, especially in moderately warm locations such as Gori and Tskhinvali, and Pheasant\u2019s Tears produce a version fermented in traditional clay qvevri, Chateau Mukhrani a more European style ros\u00e9. It is also found in Azerbaijan.',
    ],
  },
  {
    name: 'TAZZELENGHE',
    synonyms: [
      'Refosco del Botton',
      'Tacelenghe',
      'Tassalinghe',
      'Taze Lunghe',
      'Tazzalenghe Nera',
      'Tazzalenghe Nera Friulana',
      'Tazzalingua',
    ],
    description:
      'High-acid and potentially astringent red Friulian, rescued from extinction in the 1980s.',
    'origin and parentage': [
      'Tazzelenghe, literally \u2018tongue cutter\u2019 in reference to its elevated acidity, was first mentioned in Friuli under its dialect name Tacelenghe in 1823 in the Catalogo delle variet\u00e0 delle viti del Regno Veneto, together with 126 other varieties (Fabbro et al. 2002).',
      'DNA profiling has shown that Refosco del Botton is identical to Tazzelenghe and suggested a parent\u2013offspring relationship with REFOSCO DI FAEDIS (Costacurta et al. 2005).',
    ],
    'where its grown': [
      'Like PIGNOLO and SCHIOPPETTINO, Tazzelenghe had almost disappeared by the early twentieth century, after Friuli in north-east Italy was hit by phylloxera. Rescued at the end of the 1980s, the variety is today cultivated again in the province of Udine, notably in the communes of Buttrio, Manzano and Cividale east of the city of Udine. A varietal is authorized in the Colli Orientali del Friuli DOC. Wines are deeply coloured and need time in oak and/or bottle to soften the marked tannins and acidity. Producers include Gianpaolo Colutta, Conte d\u2019Attimis-Maniago, Girolamo Dorigo, Le Due Torri, Jac\u00f9ss and La Viarte. There were 75 ha (185 acres) in Italy in 2000, according to the agricultural census.',
    ],
  },
  {
    name: 'TEINTURIER',
    synonyms: [
      'Auvernat Teint',
      'Bourguignon Noir',
      'Gros Noir',
      'Moreau',
      'Neraut',
      'Noir d\u2019Orl\u00e9ans',
      'Plant d\u2019Espagne',
      'Pontac',
      'Tachard',
      'Teint-Vin',
      'Teinturier du Cher',
      'Teinturier M\u00e2le',
      'Tintewein',
    ],
    description:
      'Red-fleshed French variety still found (just) in Portugal and South Africa.',
    'origin and parentage': [
      'Teinturier, literally \u2018dyer\u2019, is a very old red-fleshed variety possibly originating from the region of Orl\u00e9ans in northern France, where it was widely cultivated from the seventeenth century on. The earliest mention of this variety probably appeared under the old synonym Neraut in Charles Estienne and Jean Li\u00e9baut (1564): \u2018Les complans de la vigne noire sont le morillon, le samoireau, le negrier & le neraut..\u00a0.\u00a0. Le neraut, nomm\u00e9 Bourguignon noir, a la nature du Bourguignon blanc, .\u00a0.\u00a0. fait force couleur de sorte que ceux qui ont pleine plante en font vin pour les teintures de draps, & le vendent fort cher\u2019 (\u2018The black grape varieties are morillon, samoireau, negrier and neraut.\u00a0.\u00a0. Neraut, called Bourguignon noir, has the nature of Bourguignon blanc, .\u00a0.\u00a0. makes so much colour that those who have a lot of vines make wine for the dying of sheets, and sell it at a very high price\u2019). The name Teinturier appeared somewhat later in 1667 (R\u00e9zeau 1997): \u2018Le Noirault, dit Plant d\u2019Espagne ou teinturier, est vn Raisin dont le grain est fort serr\u00e9, teint fort noir\u2019 (\u2018Noirault, known as Plant d\u2019Espagne or teinturier, is a grape whose berries are very tightly packed, deep-black colour\u2019).',
      'Recent parentage analysis based on thirty-two DNA markers strongly suggests a possible parent\u2013offspring relationship between Teinturier and SAVAGNIN (Vouillamoz). Consequently, Teinturier is a grandchild, a grandparent or a half-sibling of PINOT (see Pinot pedigree diagram), which explains why they share the old synonym Auvernat. Interestingly, Teinturier, Pinot and Savagnin are all said to derive from wild grapevines (Galet 1990).',
      'Teinturier was used to breed DECKROT, PERVOMAISKY and PETIT BOUSCHET.',
    ],
    'where its grown': [
      'Teinturier has disappeared from French statistics but it is officially authorized in Portugal. The wines are rather harsh and its best use has always been to add colour to blends.',
      'Teinturier is known as Pontac in South Africa (Galet 2000), possibly named after the de Pontac family of Bordeaux, although there are competing theories concerning Pontac\u2019s name and origin. It was widely planted in the Cape for blended and fortified wines from the seventeenth century until the arrival of phylloxera in the late nineteenth. When vineyards were replanted, it was generally replaced by higher-yielding and more fashionable varieties. Hartenberg in Stellenbosch were the last producers to make a varietal wine but their final vintage was 2000 and they have now pulled up the vines, partly because of disease but also because there was virtually no market for the wine. Allesverloren in the Swartland have 1.5 ha (4 acres) of Pontac and include the fruit in their port-style blend, as do several others in the Western Cape.',
    ],
  },
  {
    name: 'TEMPRANILLO',
    synonyms: [
      'Arag\u00f3n',
      'Aragones',
      'Aragonez',
      'Arauxa',
      'Bot\u00f3n de Gallo',
      'Cencibel',
      'Chinchillana',
      'Escobera',
      'Garnacho Fo\u00f1o',
      'Grenache de Logrono',
      'Jacibiera',
      'Jacivera',
      'Negra',
      'Negra de Mesa',
      'Pi\u00f1uela',
      'Santo Stefano',
      'Tempranilla',
      'Tempranillo de Rioja',
      'Tinta de Nava',
      'Tinta del Pa\u00eds',
      'Tinta de Toro',
      'Tinta Madrid',
      'Tinta Roriz',
      'Tinto Arag\u00f3nez',
      'Tinto de Madrid',
      'Tinto del Pa\u00eds',
      'Tinto Fino',
      'Ull de Llebre',
      'Valdepe\u00f1as',
      'Verdiell',
      'Vid de Aranda',
    ],
    description:
      'Spain\u2019s most famous grape, responsible for the majority of its most famous reds.',
    'origin and parentage': [
      'The earliest reference to this variety was supposedly found under the plural las tempraniellas in the Libro de Alexandre published in the thirteenth century and referring to the region of Ribera del Duero (Casas Rigall 2007). However, las tempraniellas and the name Tempranillo both derive from the Spanish temprano, meaning \u2018early\u2019, and the true identity of the early-ripening varieties mentioned in the Libro de Alexandre is not known. The next possible earliest mention of Tempranillo is said to have appeared in 1513 under the old synonym Aragon\u00e9s in Spanish agronomist Gabriel Alonso de Herrera\u2019s description of the varieties of Castilla, Extremadura and Andaluc\u00eda (1790). However, the name Aragon\u00e9s, referring to the medieval kingdom of Arag\u00f3n (today\u2019s autonomous community of Arag\u00f3n) in north-eastern Spain, has been historically used for both GARNACHA and Tempranillo, and Alonso de Herrera\u2019s description is too vague to know which he is describing.',
      'Therefore, the first reliable mention of Tempranillo was when the Spanish ampelographer Clemente y Rubio (1807) described it as a variety that was praised in Logro\u00f1o in La Rioja and in Peralta in Navarra, two adjacent regions north west of Arag\u00f3n that can be considered the likely birthplace of this variety. This historical origin is consistent with genetic analyses of dozens of ancient clones of Tempranillo from all over Spain that have shown that they are all very uniform, suggesting that Tempranillo vineyards are the result of rapid multiplication and distribution from a small number of original clones (Cervera et al. 2002; Carcamo et al. 2010). These clones most likely come from the provinces of La Rioja and Navarra, where V\u00edctor Cruz Manso de Z\u00fa\u00f1iga (1905) delineated the geographic distribution of Tempranillo in the early twentieth century.',
      'According to Santana et al. (2010), Tempranillo most likely has a parent\u2013offspring relationship with ALBILLO MAYOR from Ribera del Duero, where Tempranillo has traditionally been cultivated under the name Tinta del Pa\u00eds, but it is not known which is the parent and which is the offspring.',
      'In Italy, DNA profiling has surprisingly revealed that Tempranillo has traditionally been cultivated in Toscana and Basilicata under the misleading names Malvasia Nera (a synonym for MALVASIA NERA DI BASILICATA) and Malvasia Nera di Lecce (a synonym for MALVASIA NERA DI BRINDISI; Storchi et al. 2009). In Portugal, Tempranillo has been cultivated for centuries under the names Tinta Roriz in the Douro and Aragonez in the Alentejo, but no close relationship with Portuguese varieties could be established (Lopes et al. 1999; Pinto-Carnide et al. 2003). In South America, Tempranillo was introduced around the seventeenth century, and has been shown to be genetically close to CRIOLLA GRANDE in Argentina (Mart\u00ednez et al. 2003).',
      'Tempranillo was used to breed TEMPARIA.',
      'See below for Tempranillo Blanco.',
    ],
    'where its grown': [
      'Although Tempranillo is essentially an Iberian variety, it can still be found in southern France, where there were 766 ha (1,893 acres) in 2009, about half as much as at the turn of the century, most of it in the western Languedoc and used for blending. The 2000 agricultural census in Italy found just 7 ha (17 acres) of Tempranillo and it was not authorized for any DOC(G) wines under that name. However, the census also shows 2,693 ha (6,655 acres) of Malvasia Nera, some or much of which may well be Tempranillo.',
      'Spain is the kingdom of Tempranillo, a kingdom that extended to 206,988 ha (511,478 acres) in 2008, making it the country\u2019s most widely planted red wine variety and the second most planted vine after AIR\u00c9N. It is widely distributed across the country, albeit under a host of synonyms, with the exception of Asturias in the far north, and is not as significant in Galicia in the far north west and in Andaluc\u00eda in the deep south as elsewhere. In the higher, cooler subregions of Rioja, Rioja Alta and Rioja Alavesa, it can ripen a full two weeks before the GARNACHA with which it has traditionally been blended there.',
      'Tempranillo produces wines with considerably less alcohol than GARNACHA and such other Spanish stalwarts as MONASTRELL and BOBAL, marked tannins, sometimes slightly low acidity and flavours that range over spice, leather, tobacco leaves, sometimes strawberry when it has been given extended ageing in American oak as it traditionally was in Rioja. In the distinctly less traditional wine region of Ribera del Duero, average vine age is generally much lower and producers have tended to rely on oak, often French barriques, and the concentrated bright fruit naturally produced by the cool nights at Ribera\u2019s altitude to distract from a lack of nuance in the variety known here as Tinto Fino. Modernist producers in both these leading Spanish red-wine regions have increasingly also looked to seasonings of CABERNET SAUVIGNON and occasionally MERLOT, too. Tempranillo\u2019s innate character is less obtrusive than that of these incomers from Bordeaux and it tends to submit meekly to such a blend, which has become common in Navarra and throughout Castilla-La Mancha. Perhaps the most concentrated, alcoholic examples of Tempranillo are produced in Toro.',
      'Recommended producers of varietal wines include Artadi, Abel Mendoza, Finca Allende, Fernando Rem\u00edrez de Ganuza, Roda, Se\u00f1or\u00edo de San Vicente and Sierra Cantabria in Rioja; Aalto, Ali\u00f3n, Alonso del Yerro, Alejandro Fern\u00e1ndez, O Fournier, Pago de los Capellanes, Pagos de Matanera, P\u00e9rez Pascuas and Pingus in Ribera del Duero; Dominio del Bendito, Maurodos, Numanthia, Pintia, Telmo Rodr\u00edguez, Teso La Monja in Toro; and, within the Vino de la Tierra de Castilla y Le\u00f3n classification (just outside Ribera del Duero), Abad\u00eda Retuerta.',
      'Tempranillo is one of relatively few Spanish varieties to have a significant presence in Portugal, which had almost 17,000 ha (42,000 acres) of Tinta Roriz in 2010, up from around 13,000 ha (32,000 acres) in 2004. It is the second most planted red variety in the Douro (both for port and for table wines), is equally significant in the D\u00e3o region, and of historical importance in the Alentejo further south, where it is known as Aragonez. Thanks to its quality potential, it has spread to other regions as well, particularly around the Tejo (Tagus) and in Lisboa.',
      'Aragonez has long been planted in the Alentejo and is considered one of the region\u2019s most noble varieties. In this hot climate, however, great care must be taken to harvest at the right moment since any slight delay leads to a significant loss of fruit quality. Experiments carried out in vineyards at around 600 metres have demonstrated that Aragonez is at its best at those cooler altitudes and in the type of soil found there, frequently yielding better results than TOURIGA NACIONAL. Recommended producers include Ervideira and J Portugal Ramos.',
      'In the Douro, Tinta Roriz has long been an extremely important component in the production of port, in good years adding colour and body, with elegant aromas of crushed black pepper and wild flowers and flavours of wild cherries with a hint of vegetables. The variety can bring freshness to the blends that predominate here in both port and Douro table wines. Quinta do Portal are unusual in making a varietal Tinta Roriz.',
      'Varietal examples are much more common in D\u00e3o, where the wines can be densely coloured, full-bodied, with fine tannins and in some ways not unlike Ribera del Duero.',
      'Tempranillo is grown to a very limited extent in both Switzerland and Malta, and in Turkey (7 ha/17 acres in 2010) Kavaklidere have planted Tempranillo in both Central Anatolia and Kapadokya (Cappadocia). Other producers include Melen and \u0130dol.',
      'California grew 957 acres (387 ha) of Tempranillo in 2010, quite widely distributed but mainly in the warmer Central Valley. Tempranillo seems to thrive where Zinfandel does and the varieties are interplanted in some older vineyards. Available clones are registered under the names Tempranillo, Valdepe\u00f1as and Tinta Roriz, the last imported from Portugal by Harold Olmo in 1984. However, it is now officially known as Tempranillo. Recommended producers include Truchard in Carneros, Curran in Santa Ynez Valley, and Jarvis and Viader in Napa Valley.',
      'The minor but perceptible American craze for Tempranillo is demonstrated by the fact that total plantings in Washington State grew from zero in 2006 to 94 acres (38 ha) by 2011, with the likes of Gramercy Cellars and Cayuse leading the pack. They may have been inspired by a single grower and producer in southern Oregon, Abacela in Oregon\u2019s Umqua Valley, who have more than 20 of the state total of 150 acres (8 out of 61 ha) planted and pioneered the variety in the Pacific Northwest, instigating an international Tempranillo Day. Texas has an increasing number of Tempranillo fans as well, more than thirty wineries drawing fruit from 75 acres (30 ha) of vineyard, encouraged by such pioneers as Inwood and Lone Oak. The variety\u2019s ability to withstand hot summers and cold winters is appreciated here. Wines tend to be pretty New World and ripe in character.',
      'It is also planted to a very limited extent (10 acres/4 ha) in British Columbia in western Canada. In Mexico, Tempranillo is found mainly in Baja California, with Santo Tomas a notable producer.',
      'Argentina had a very considerable total of 6,568 ha (16,230 acres) of Tempranillo in the ground in 2009, mostly in Mendoza, where it is the fifth most planted grape variety and the area continues to increase. The majority of the varietal wines are inexpensive and rather more savoury than the dominant Malbec (COT) but O Fournier\u2019s Tempranillo-dominated blends Alpha and Beta Crux, and Zuccardi\u2019s Q bottling, show the potential for this variety in Argentina. Chile, on the other hand, grew only 10 ha (25 acres) of Tempranillo in 2008.',
      'Tempranillo has been a beneficiary of Australia\u2019s growing interest in \u2018alternative varieties\u2019. By 2008 there were 385 ha (951 acres), very widely dispersed but with particularly sizeable plantings in the irrigated inland Riverina and Riverland, as well as the Barossa Valley, Heathcote and McLaren Vale. Recommended producers include Ross Estate in the Barossa Valley and Pokolbin Estate in the Hunter Valley. D\u2019Arenberg blend Tempranillo with Grenache (GARNACHA) and Sous\u00e3o (VINH\u00c3O) in McLaren Vale and Yalumba have successfully blended it with Grenache and VIOGNIER in the Barossa Valley.',
      'New Zealand had a mere 7 ha (17 acres) in 2008 but the remarkable performance of Trinity Hill\u2019s example grown on the Gimblett Gravels in Hawke\u2019s Bay may have encouraged more plantings. This is a variety currently exciting considerable interest around the world.',
    ],
  },
  {
    name: 'T\u00c9OULIER NOIR',
    synonyms: ['Manosquin', 'Plant de Manosque', 'Plant Dufour', 'Thuillier'],
    description: 'Almost extinct Proven\u00e7al variety.',
    'origin and parentage': [
      'T\u00e9oulier Noir is a rare variety from the Manosque area between Aix-en-Provence and Gap in Provence, southern France, where it was cited in 1715 by Garidel (R\u00e9zeau 1997): \u2018Le vulgaire a donn\u00e9 le nom de Taulier, ou de Plan de Manosquo, \u00e0 cette espece qui est assez commune dans les vignes du Tholonet\u2019 (\u2018The common language has given the name Taulier, or Plan de Manosquo, to this species that is rather common in the vineyards of Tholonet\u2019). The modern name T\u00e9oulier Noir appeared in 1755 (R\u00e9zeau 1997). While the synonym Manosquen or Manosquin refers to its place of origin, the etymology of T\u00e9oulier is unknown. T\u00e9oulier Noir is a member of the Claret ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'There was less than a hectare (2.5 acres) remaining in France in 2009 but Ch\u00e2teau Simone, in the tiny Palette appellation, still have a few vines in a 120-year-old vineyard and these are included in their red blend. Wines are generally low in acidity and moderate in alcohol.',
    ],
  },
  {
    name: 'TERBASH',
    synonyms: ['Irtik Iaprak', 'Irtuk Yaprak'],
    description: 'Widely planted, multi-purpose Turkmen variety.',
    'origin and parentage': [
      'Terbash, meaning \u2018crunchy head\u2019 in reference to the berry flesh, comes from Turkmenistan. The synonym Irtik Iaprak means \u2018torn leaf\u2019.',
    ],
    'where its grown': [
      'Terbash is one of the most important and widely planted varieties in Turkmenistan, where it is grown both for the table and for wine \u2013 dry, dessert and fortified styles \u2013 as well as for juice and raisining. Geokdepe make varietal examples. Also found in Russia and Azerbaijan.',
    ],
  },
  {
    name: 'TERMARINA ROSSA',
    synonyms: ['Oltremarina', 'Tramarina'],
    description:
      'Unusual Emilia-Romagna seedless pink-skinned variety used in cooking as well as for wine.',
    'origin and parentage': [
      'Termarina Rossa was first cited in Viti della Provincia di Reggio Emilia, a manuscript written by Bertozzi in 1840. It was described as a grape variety from the province of Reggio Emilia, also called Uva Passerina, of which there was also a white-berried version. However, according to DNA and enzymatic studies, the white Termarina Bianca is not a mutation but a distinct variety. Termarina Rossa has sometimes been confused with Corinto Nero, probably because both varieties are seedless, but DNA profiling has proved they are not the same (Boccacci et al. 2005).',
    ],
    'where its grown': [
      'The black and white versions of Termarina were traditionally used in the province of Parma and Reggio Emilia in northern Italy to make jam and saba, a local syrup made from boiled must. Today Termarina Rossa is also used for wine, blended with other varieties. It was included in the national register of varieties only in 2007 so does not show in the most recent, 2000, agricultural census.',
      'Varietal wines are extremely rare but Angelo Casalini, the guardian of Termarina Rossa since the 1970s, makes a distinctive, full-bodied wine with aromas of tar and ripe cherries, silky tannins, good acidity and a bitter finish.',
    ],
  },
  {
    name: 'TEROLDEGO',
    synonyms: [
      'Merlina',
      'Teroldega',
      'Teroldeghe',
      'Teroldico',
      'Teroldigo',
      'Tiraldega',
      'Tirodola',
      'Tiroldegho',
      'Tiroldigo',
    ],
    description:
      'Well-connected, revived Trentino speciality whose acidity needs careful handling.',
    'origin and parentage': [
      'Teroldego is a very old variety from Trentino in north-east Italy, where its wine was first mentioned on 18 January 1480 in Cognola, just east of the city of Trento, in a sales contract written in Latin: \u2018duarum brentarum vini teroldigi buliti, boni et sufficientis\u2019, meaning \u2018two brente [an old measure] of fermented, good and abundant teroldigo wines\u2019 (Prato 1989). There were also several other references later in historical documents from the same region. The cradle of Teroldego is therefore very likely to be the Rotaliano plain between Trento and Mezzolombardo, where it has been cultivated for centuries. Indeed, Teroldego almost certainly took its name from a place near Mezzolombardo called Alle Teroldege, where vineyards were mentioned as far back as the fifteenth century (Roncador 2006).',
      'Pedigree reconstruction based on DNA profiling revealed that Teroldego spontaneously crossed at least twice with the same unknown and most likely extinct variety to give birth to MARZEMINO in Trentino or Lombardia and to LAGREIN in Trentino or the Alto Adige (Vouillamoz and Grando 2006). More surprisingly, DNA pedigree reconstruction revealed that DUREZA from the Ard\u00e8che, which is a parent of SYRAH, is a full-sibling of Teroldego, and that both are grandchildren of PINOT. Teroldego is therefore an uncle/aunt of Syrah, as well as a grandparent of REFOSCO DAL PEDUNCOLO ROSSO (Vouillamoz and Grando 2006; see the pedigree diagrams in the Syrah and Refosco dal Peduncolo Rosso entries).',
      'Teroldego was used to breed REBO.',
    ],
    'where its grown': [
      'Teroldego has been cultivated for centuries on the Rotaliano Plain (the Campo Rotaliano) north of Trento in northern Italy, in the communes of Mezzolombardo, Mezzocorona, San Michele all\u2019Adige and Rover\u00e8 della Luna. In Trentino-Alto Adige it has had its own DOC since 1971, Teroldego Rotaliano, for both red and ros\u00e9 (labelled Rosato or Kretzer) made from 100% Teroldego. It may also be included in small amounts in ros\u00e9 versions of DOCs such as Valdadige/Etschtaler, Trentino and Casteller. It is also planted further south, in the Veneto, and west, in Valtellina (under the name Merlina). The Italian agricultural census of 2000 recorded total plantings of 690 ha (1,705 acres) but the area is decreasing. Recommended producers include Elisabetta Foradori, who instigated a quality revolution in the mid 1980s exemplified by her straight Teroldego Rotaliano and the more concentrated and ageworthy Granato, and Marco Donati. Chianti Classico producer Poggio al Cassone has bottled a Teroldego under the name La Cattura.',
      'Wines are deeply coloured, lively and fruity and sometimes prone to reduction. When yields are restricted and the grapes reach full maturity \u2013 which is sadly not always the case \u2013 the rich black-cherry fruit is well supported by ripe tannins, the acidity is mouth-watering rather than eye-watering, the wine marries well with oak and ages well.',
      'In California a Teroldego is made by about a dozen producers all over the state, notably Central Coast producer Wolff Vineyards. Plantings totalled around 100 acres (40 ha) in 2008. Michelini in the King Valley, Victoria, are the lone proponents of this variety in Australia. Their first commercial vintage, 2008, was a trophy winner at the Australian Alternative Varieties Wine Show 2010. There is also some in Rio Grande do Sul in Brazil.',
    ],
  },
  {
    name: 'TERRANO',
    synonyms: [
      'Cagnina',
      'Crodarina',
      'Rabiosa Nera',
      'Refosco del Carso',
      'Refosco d\u2019Istria',
      'Refosco Terrano',
      'Refo\u0161k',
      'Teran',
      'Terran',
      'Terrano del Carso',
      'Terrano d\u2019Istria',
    ],
    description:
      'Significant variety making fresh, aromatic red from the Italy\u2013Slovenia border and Croatia; often confused with the best-known Refosco.',
    'origin and parentage': [
      'Terrano is a very old variety from the Karst plateau (Kras in Slovenia, Carso in Italy) that includes parts of Friuli in north-eastern Italy, south-western Slovenia and Istra. The earliest mention of Terrano appeared in a document dated 13 November 1340 referring to the cultivation in Barbana (now in Slovenia) of \u2018Rabiole Malvasie Terrano bianco e vermiglio Moscatello e Pignolo\u2019 (Fabbro et al. 2002). Surprisingly, both white- (bianco) and red- (vermiglio) berried Terrano are cited, whereas only the red version has survived. Terrano wines are again referred to on 14 August 1390 when 20 ingestariis (an old measure of volume) of vini terrani were offered to Conte di Lozo, ambassador of the Emperor (Di Manzano 1860). Terrano is then mentioned in several historical documents in Friuli.',
      'Terrano is part of the so-called Refosco group (see REFOSCO) and is often confused with REFOSCO DAL PEDUNCOLO ROSSO. However, comparison of DNA profiles disproves this hypothesis (Vouillamoz) and has shown that Terrano \u2013 Teran in Istra (Croatia) \u2013 is identical to Refosco d\u2019Istria and to Refo\u0161k in Slovenia (Maletic et al. 1999; Kozjak et al. 2003). Comparison of DNA profiles from distinct sources has also shown that Terrano is identical to Cagnina in Friuli and in Emilia-Romagna, and with Rabiosa Nera in Breganze (Vouillamoz).',
    ],
    'where its grown': [
      'Terrano is widely planted in Friuli-Venezia Giulia and Emilia-Romagna in Italy, often known as Cagnina in the latter, hence the varietal DOC Cagnina di Romagna. Varietal wines are also authorized within the Carso DOC, in which Terrano may also play a minor part, as it does in Colli di Rimini. Wines are full- to medium-bodied, food-friendly, quite tannic with fresh acidity and marked aromas of forest fruits, sometimes cherries. There were 203 ha (502 acres) in Italy in 2000. Producers of varietal wines in Italy include Skerk, Castello di Rubia, Castelvecchio and Zidarich.',
      'In Slovenia, plantings are mainly in the Kras region, north of Trieste, where there were 481 ha (1,189 acres) in 2008, and in Slovenska Istra (Slovenian Istria), south of Trieste, where it is the most widely planted variety, 818 ha (2,021 acres) in 2008. There are also limited plantings in Vipavska Dolina. Producers of varietal wines include Josko Rencel (Kras) and Parovel (Trieste).',
      'As Teran, the variety is also planted in Croatia, on around 480 ha (1,186 acres) in 2008. It is recommended only in the Istra wine region but is very successful there, producing elegant, fresh wines with lively fruit and relatively firm tannins that respond well to barrel ageing. Recommended producers include Franc Arman, Dimitri Bre\u010devi\u0107, Kozlovi\u0107 and Roxanich.',
      'Also found in the Republic of Macedonia.',
    ],
  },
  {
    name: 'TERRANTEZ',
    synonyms: ['Terrantez da Madeira'],
    description:
      'Historic Madeira variety that has virtually disappeared from the island.',
    'origin and parentage': [
      'Terrantez is an old variety that used to be widespread on the Portuguese island of Madeira, as evidenced by nineteenth- and early-twentieth-century bottles still sold at auction today, but has now almost disappeared. Terrantez has long been considered to be a synonym for FOLGAS\u00c3O from the D\u00e3o region but comparison of their DNA profiles (Mart\u00edn et al. 2006; Almadanim et al. 2007; Veloso et al. 2010) indicates that they are distinct varieties. Terrantez is also genetically distinct from TERRANTEZ DA TERCEIRA and TERRANTEZ DO PICO from the A\u00e7ores, both with unique DNA profiles in Veloso et al. (2010), and from the variety officially known in D\u00e3o as Terrantez, which is not currently in commercial cultivation.',
    ],
    'where its grown': [
      'Although less than 2 ha (5 acres) of Terrantez remains or has recently been replanted on Madeira, Portugal, it was once highly regarded and the many fine older bottles still resting in cellars show how well these sweet but initially astringent fortified wines have aged. Its demise on the island was due mainly to the lack of replanting after the destruction caused first by powdery mildew and then by phylloxera in the late nineteenth century. Any Terrantez Madeira is rare enough to be worth trying.',
    ],
  },
  {
    name: 'TERRET',
    synonyms: ['Bourret', 'Tarret'],
    description:
      'Old Languedoc variety found in three colours, all in quantitative decline.',
    'origin and parentage': [
      'The variety Terret consists of Terret Noir, Terret Gris and Terret Blanc that are simply colour mutations. Terret, one of the oldest varieties in the Languedoc, most likely originates from the H\u00e9rault d\u00e9partement in southern France, where it has long been cultivated. According to R\u00e9zeau (1997), Terret was possibly cited as early as 1619 in Carcassonne, followed by other mentions in 1639 (terren) and 1676 (tarret), but the identity of these old mentions is uncertain and the first reliable one appeared in 1736 in the Languedoc: \u2018Terret noir: produit beaucoup\u2019 (\u2018Terret noir: produces a lot\u2019). Terret belongs to the Picquepoul ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Terret Gris used to be the most widely planted variety in the Languedoc, France (8,130 ha/20,090 acres in 1958, for example), and was used not only for wine but also for vermouth and eaux-de-vie but the vineyard area had declined to 104 ha (257 acres) by 2008. Now it is Terret Blanc that is the most widely planted of the three, with 1,451 ha (3,585 acres) in 2009, almost all in the H\u00e9rault. Terret Noir occupies slightly more vineyard than Gris: 189 ha (467 acres) in 2008, down from 400 ha (988 acres) in 2000.',
      'Although there are some varietal wines \u2013 or blends with a range of other more internationally recognized varieties including CHARDONNAY, SAUVIGNON BLANC or MUSCAT BLANC \u00c0 PETITS GRAINS \u2013 all three colours tend to play a minor role in appellation wines in the southern Rh\u00f4ne and west across the Languedoc, where they are authorized or recommended. Terret Noir may be included as a minor component in Languedoc and Provence appellations such as Fitou, Minervois, Corbi\u00e8res, Palette and Cassis, and also in Ch\u00e2teauneuf-du-Pape, C\u00f4tes du Rh\u00f4ne-Villages, Gigondas and Rasteau in the Rh\u00f4ne; Terret Blanc and Gris are limited to an equally minor role in the Cassis, Palette, Corbi\u00e8res, Minervois and Languedoc appellations. Clos du Gravillas produce a barrel-fermented varietal Terret Gris and Les Clos Perdus make a varietal Terret (mainly Terret Gris) with aromas of brioche and truffle from a centenarian vineyard where all three colours are interplanted. L\u00e9on Barral of Faug\u00e8res make a complex, aromatic white from Terrets Blanc and Gris.',
      'Wines made from Terret of all three hues are generally moderate in alcohol and have the useful ability to retain fresh acidity in the heat of southern France. Terret Blanc and Terret Gris are mostly rather neutral, Terret Noir tends to be light and pale.',
    ],
  },
  {
    name: 'THRAPSATHIRI',
    synonyms: ['Begleri', 'Beghleri', 'Dafnato'],
    description:
      'Distinctive Greek island variety, travelling under two names and producing richly fruited wines.',
    'origin and parentage': [
      'Thrapsathiri probably comes from the island of Kr\u00edti. It has long been thought to be close or identical to ATHIRI (thrapsa means \u2018lots of\u2019) but this has been contradicted by DNA analysis, which has suggested instead a close relationship with VIDIANO, also from Kr\u00edti (Biniari and Stavrakakis 2007). Comparison of their respective DNA profiles in the Greek Vitis Database shows that Begleri, found on the Kykl\u00e1des (Cyclades) islands and on Kr\u00edti is identical to Thrapsathiri (Vouillamoz). Therefore, the red-berried Begleri Kokkino (Galet 2000) may be considered to be a colour mutation of Thrapsathiri.',
    ],
    'where its grown': [
      'Grown mainly in the southern Aegean on Greek islands including the Kykl\u00e1des, Kr\u00edti and R\u00f3dos (Rhodes), Thrapsathiri is very often part of a field blend. Domaine Economou, for example, have ungrafted sixty- to seventy-year-old vineyards in western Kr\u00edti and, according to wine writer Nico Manessis (personal communication), there is an increasing trend among producers in the centre of the island to blend it with the more perfumed VILANA. Lyrarakis, however, make a full-bodied and rich varietal wine, aged in acacia and French oak, that has intense tropical-fruit aromas and more acidity than is typical for the variety thanks to the altitude (500 m) and exposition of the vineyard. The S\u00edtia co-op also make both a varietal and a blend.',
      'There were 47 ha (116 acres) under the name Begleri scattered across the islands of the northern Aegean in 2008. Afianes are experimenting with vinifying and ageing this variety in clay amphorae.',
    ],
  },
  {
    name: 'TIBOUREN',
    synonyms: [
      'Antiboulen',
      'Rossese',
      'Rossese di Dolceacqua',
      'Rossese Nericcio',
      'Rossese di Ventimiglia',
      'Tiboulen',
    ],
    description:
      'Ancient variety making fine ros\u00e9 wines in Provence and light reds over the border in Italy\u2019s Liguria.',
    'origin and parentage': [
      'Tibouren is said to have been introduced to the region of Saint-Tropez in the Var, southern France, in the late eighteenth century by a marine captain named Antiboul, which would explain its synonym Antiboulen, later corrupted to Tibouren (Ganzin 1901b). DNA profiling recently and unexpectedly showed that Rossese di Dolceacqua, cultivated in the area of Ventimiglia in Liguria (close to the French border) and included in the Italian national register of varieties, is identical to Tibouren (Torello Marinoni, Raimondi, Mannini and Rolle 2009). This is consistent with the local tradition which says that Rossese di Dolceacqua was introduced from France to the province of Imperia in western Liguria (very close to the French border) by the soldiers of the Doria family in Dolceacqua, whence it subsequently spread along the Ligurian Riviera. It is not known whether the variety was first called Tibouren or Rossese, but given the possible confusion with other varieties named Rossese Something, it seems clearer to use Tibouren here.',
    ],
    'where its grown': [
      'In southern France, Tibouren\u2019s traditional growing area is the gulf of Saint-Tropez, where ungrafted centenarian vines can still be found in Saint-Tropez, Ramatuelle, Gassin, Grimaud, Cogolin, etc. (Galet 2000). Tibouren is recommended in the Var and the Alpes-Maritimes although almost all of France\u2019s 445 ha (1,100 acres) in 2009 were in the Var, slightly up on 2006\u2019s 417 ha (1,030 acres). It is generally used within the C\u00f4tes de Provence appellation to produce expressive, earthy ros\u00e9 wines with the scent of garrigue, sometimes as a varietal wine, more often blended with Cinsault (CINSAUT), Grenache (GARNACHA), Mourv\u00e8dre (MONASTRELL) or SYRAH. Clos Cibonne, where the late Andr\u00e9 Roux dedicated most of his vineyards to this variety, also make full-bodied and ageworthy C\u00f4tes de Provence reds that blend Syrah, Grenache and Tibouren and in some years a rare varietal or Tibouren-dominant red cuv\u00e9e.',
      'In Liguria, Italy, Rossese or Rossese di Dolceacqua has long been widespread in the province of Imperia, in particular in the Valle Nervia and Vallerosia (Ventimiglia, Dolceacqua, Camporosso, Soldano, Isolabona, San Biagio and Perinaldo) very close to the Golfo di Genova and the French border. Esteemed varietal wines are produced within the Rossese di Dolceacqua and the Rossese Riviera Ligure di Ponente appellations, although the latter is more likely to be made from ROSSESE DI CAMPOCHIESA in the area of Campochiesa and Albenga (the regulations do not specify which Rossese). The Italian agricultural census of 2000 reported 268 ha (662 acres) under the name \u2018Rossese\u2019 but does not distinguish between them. Recommended producers include Alessandro Anfossso, Alta Via, Giobatta Mandino Cane, Tenuta Giuncheo, Antonio Perrino, Poggio dell\u2019Elmo, Ravera, Ramoino, Sancio, Terre Bianche. Varietal Rossese di Dolceaqua wines are typically lively, aromatic and relatively light with a distinctive and refreshing sour-cherry flavour.',
    ],
  },
  {
    name: 'TIGRANI',
    synonyms: ['1452/75'],
    description: 'Armenian all-vinifera cross used for reds dry and sweet.',
    'origin and parentage': [
      'Tigrani is a SAPERAVI \u00d7 ARENI cross obtained by S A Pogosyan and S S Khachatryan at the Armenian viticultural research centre at Merdzavan, just west of Armenia\u2019s capital Yerevan.',
    ],
    'where its grown': [
      'MAP produce a dry varietal red in the Ararat region of Armenia but it is also used to produce the sweeter versions of which Armenians are so fond.',
    ],
  },
  {
    name: 'TIMORASSO',
    synonyms: ['Morasso', 'Timoraccio', 'Timuassa'],
    description: 'Rare, high-quality Piemontese earning renewed recognition.',
    'origin and parentage': [
      'Timorasso is an old variety from the Colli Tortonesi region around Tortona in the province of Alessandria in Piemonte, north-west Italy. DNA parentage analysis has revealed a possible parent\u2013offspring relationship between Timorasso and LAMBRUSCHETTO (Torello Marinoni et al. 2006), a variety typical of Castelnuovo Bormida, also in the province of Alessandria, thus anchoring Timorasso\u2019s roots in Piemonte.',
    ],
    'where its grown': [
      'Timorasso used to be one of the most praised and widespread varieties in the province of Alessandria in Piemonte, north-west Italy, both for wine and for the table. When vineyards were replanted in the early twentieth century after the ravages of phylloxera, the more productive CORTESE found greater favour and plantings of Timorasso declined. However, over the last few decades, a handful of producers, notably Walter Massa, have been replanting and nurturing the variety in the Tortona region, where some of the best vineyards are on the steep slopes just east of Tortona and where the vineyard area has expanded from around 6 ha (15 acres) in 2000 to double that today. The agricultural census of 2000 shows total plantings in Italy of 20 ha (49 acres). It is authorized in the provinces of Asti and Alessandria and varietal wines are permitted within the Colli Tortonesi and Monferrato DOCs, where it may also be included in blends with other local varieties such as Cortese.',
      'Timorasso is definitely too interesting a variety to be hidden in a blend. Even in youth, varietal wines have complex aromas of light honey and spice as well as floral, citrus and nutty characteristics and a creamy texture \u2013 they sometimes taste as if they are lightly oaked even when they are not. The acidity is fresh and well-made wines have excellent length, delicate minerality and surprising longevity. Producers of such wines include Luigi Boveri, Franco Martinetti, Walter Massa and Morgassi.',
    ],
  },
  {
    name: 'TINTA BARROCA',
    synonyms: ['Boca de Mina', 'Tinta Barocca', 'Tinta das Baroccas'],
    description: 'Rustic, dark-skinned, early-ripening port variety.',
    'origin and parentage': [
      'Tinta Barroca, meaning \u2018black baroque\u2019, comes from the Douro region of northern Portugal, where it has long been cultivated for port wines. Until the end of the nineteenth century, it was generally known as Tinta Grossa (\u2018big black\u2019) but it should not be confused with GROSSA from the Alentejo, which is also known as Tinta Grossa. According to genetic studies, Tinta Barroca could be closely related to TOURIGA FRANCA, also from the Douro region (Almadanim et al. 2007).',
    ],
    'where its grown': [
      'Tinta Barroca is one of the five most important varieties used in the production of port in the Douro in northern Portugal, and the third most planted dark-skinned port variety after TOURIGA FRANCA and Tinta Roriz (TEMRANILLO), accounting for 11.2% of vines in the Douro in 2004. Its popularity is due in large part to its abundant yields and to the fact that it ripens a good two weeks before other red varieties in the region. This precocity explains why it is planted mainly in the Baixo Corgo and Cima Corgo subregions as it does not like the heat of the Douro Superior. It is also planted in the T\u00e1vora-Varosa region immediately south of the Douro for blended unfortified wines. Total plantings in 2010 were 4,455 ha (11,009 acres). Varietal wines are typically soft, full-bodied, rustic and high in alcohol with flavours of cherry and blackberry but lacking in complexity and acidity, which means Tinta Barroca is best in a blend, in both port and table wines. Its colour fades more quickly than that of Touriga Franca and TOURIGA NACIONAL. However, it can produce fresher, more elegant and aromatic wines when planted on higher, cooler sites (over 500 m above sea level). Quinta das Tecedeiras are unusual in making a varietal wine.',
      'Tinta Barroca is one of the few port varieties that have been exported. South Africa recorded 261 ha (892 acres) in 2009, though more vines have been uprooted than planted in the last few years. It is used mostly for fortified wines but a few producers of port-style wines such as Allesverloren, Boplaas and DeKrans also make varietal table wines from the variety they spell Tinta Barocca, as do Lammershoek.',
    ],
  },
  {
    name: 'TINTA CARVALHA',
    synonyms: ['Lob\u00e3o', 'Preto Gordo', 'Tinta Carvalha du Douro'],
    description: 'Portuguese variety that is light in every way.',
    'origin and parentage': [
      'This variety probably comes from northern Portugal, from within the Douro or Tr\u00e1s-os-Montes (Rolando Faustino, personal communication).',
    ],
    'where its grown': [
      'Varietal wines are typically light in colour and body with low acidity, aromas of red fruits and, not surprisingly, little ageing potential. There were 1,171 ha (2,894 acres) in Portugal in 2010, mostly in the Douro and Tr\u00e1s-os-Montes regions and to a lesser extent in Beiras, the Tejo and the Alentejo, although it is authorized in most of Portugal\u2019s wine-growing regions.',
    ],
  },
  {
    name: 'TINTA CASTA\u00d1AL',
    synonyms: ['Casta\u00f1al', 'Rabo de Cordeiro'],
    description: 'Recently resurrected Galician of unknown origin.',
    'origin and parentage': [
      'Tinta Casta\u00f1al is an almost extinct variety from the Spanish side of the River Mi\u00f1o, on the border with Portugal, where it was both widely cultivated and highly praised until the early twentieth century (Santiago et al. 2008). Its DNA profile does not correspond to any other Spanish or Portuguese variety (Santiago et al. 2008; Gago et al. 2009) but a likely parent\u2013offspring relationship with AMARAL (under the name Cai\u00f1o Bravo) has been suggested (D\u00edaz-Losada et al. 2011).',
    ],
    'where its grown': [
      'Although this variety is not included in the official Spanish register of commercial varieties, not even provisionally, cuttings from vines more than 200 years old discovered in the areas of O Rosal, Goian and Tuy (along the Mi\u00f1o in the south of Pontevedra province, on the border with Portugal) were added to the collection at Misi\u00f3n Biol\u00f3gica de Galicia in 1993 and commercial vineyards were recently planted at the Vi\u00f1as do Torroxal winery in the O Rosal Valley in R\u00edas Baixas, in the north west of Spain (Santiago et al. 2008). Their Tinto Torroxal is a blend of Cai\u00f1o Tinto (BORRA\u00c7AL), Sous\u00f3n (VINH\u00c3O), Brancellao (ALVARELH\u00c3O), Tinta Casta\u00f1al and PEDRAL.',
    ],
  },
  {
    name: 'TINTA FRANCISCA',
    synonyms: ['Tinta de Fran\u00e7a', 'Tinta Francesca', 'Tinta Franceza'],
    description: 'Lesser but not insignificant port variety.',
    'origin and parentage': [
      'Tinta Francisca is from northern Portugal, possibly originating from the Douro. According to recent DNA studies, Tinta Francisca could be genetically related to TINTO C\u00c3O and VIOSINHO (Pinto-Carnide et al. 2003) as well as to RABIGATO (Castro et al. 2011), all from the Douro or neighbouring regions. Tinta Francisca is also cultivated under the name Teinturier M\u00e2le (Galet 2000) but it is morphologically and genetically distinct from the real red-fleshed French variety TEINTURIER.',
      'Tinta Francisca means \u2018French black\u2019 and it has been suggested that the variety was introduced from France, perhaps from Burgundy, by Robert Archibald, who planted it at his Quinta de Roriz, where it was thought to be PINOT NOIR (Galet 2000). This theory can be rejected by DNA profiling and by the genetic relationships of Tinta Francisca with TINTO C\u00c3O, VIOSINHO and RABIGATO.',
    ],
    'where its grown': [
      'Found mostly in the Douro in north-eastern Portugal, Tinta Francisca produces aromatic wines generally high in alcohol and low in acidity but not especially concentrated. It is used mainly as a minor ingredient in the production of port, by producers such as Niepoort and Quinta das Carvalhas, often from a field blend. Quinta da Revolta make a rare varietal unfortified wine. There were 32 ha (79 acres) in Portugal in 2010.',
      'There are a very few hectares in South Africa, where it is also included in port-style blends such as that made by Allesverloren.',
    ],
  },
  {
    name: 'TINTILIA DEL MOLISE',
    synonyms: ['Tintilia'],
    description:
      'Minor Molise variety until recently confused with Bovale Grande (ie Mazuelo/Carignan).',
    'origin and parentage': [
      'Tintilia is a particular variety of the Molise region in south-central Italy, where it is said to have been introduced from Spain during Bourbon rule in the middle of the eighteenth century. The variety presumably took its name from the Spanish word tinto, meaning \u2018black\u2019, although an etymology based on the Italian tintore, meaning \u2018dyer\u2019, owing to its ability to stain clothes, is also plausible.',
      'Tintilia has long been confused with Bovale Grande (aka MAZUELO or Carignan) on Sardegna and is not listed separately in the Italian agricultural census of 2000. However, recent DNA investigations have confirmed that they are not the same and that Tintilia del Molise is also distinct from the obscure varieties known as Tintilia in Campania and Sardegna and even Tintilia de Rota from Spain (Reale et al. 2006). To prevent further confusion, the full name Tintilia del Molise, referring to its likely place of origin, is used here. Nonetheless, a possible Iberian origin cannot be ruled out, because Reale et al. (2006) found Tintilia del Molise to be relatively close to the Spanish PARRALETA.',
    ],
    'where its grown': [
      'Tintilia del Molise is cultivated mainly in Molise in south-central Italy and to a lesser extent in the province of Chieti in Abruzzo to the north. It is often blended with other local varieties such as MONTEPULCIANO, but a few interesting varietal wines have recently been made by, for example, Di Majo Norante, who were among the first wineries to rediscover Tintilia del Molise and make a varietal wine (as well as blending it with Montepulciano for their top cuv\u00e9e, Don Luigi), Angelo d\u2019Uva, Catabbo and Cipressi. Such wines, permitted within the Molise DOC, tend to be dense and full-bodied with aromas of ripe dark fruit \u2013 blackberries, cherries or plums \u2013 and fine tannins.',
    ],
  },
  {
    name: 'TINTO C\u00c3O',
    synonyms: ['Farmento', 'Tinta C\u00e3o'],
    description:
      'High-quality port variety that occasionally shines in D\u00e3o.',
    'origin and parentage': [
      'This is a very old variety from the Douro and D\u00e3o in northern Portugal. It has been known there since the seventeenth century (Rolando Faustino, personal communication) and some authors consider it to be one of the oldest varieties in the country. Tinto C\u00e3o means \u2018red dog\u2019 but the reason for the name is uncertain.',
      'According to recent studies, Tinto C\u00e3o could be genetically related to VIOSINHO and TINTA FRANCISCA (Pinto-Carnide et al. 2003). Tinto C\u00e3o was used to breed the hugely successful colouring grape RUBIRED.',
    ],
    'where its grown': [
      'Tinto C\u00e3o is one of the five most important port grapes planted in the Douro, Portugal, often described as a little more feminine than, say, TOURIGA NACIONAL, and although it is particularly valued for vintage port because of its capacity to age, it has never been widely planted \u2013 probably because of its low yields. The Visconde de Vila Maior, a viticulture expert in the Douro in the nineteenth century, noted that the variety ripens well, does not dry up or rot and, despite low production, gives rise to a very full, strong and generous wine (Vila Maior 1875). Now that yield is no longer quite such a critical attribute, plantings of this variety are on the increase and it is used in blended table wines as well as for port.',
      'In D\u00e3o the variety is more controversial: rather like BAGA, it can produce both great and awful wines, mainly because it is not easy to ripen in all parts of the region. When the fruit reaches full maturity, however, the wines are deeply coloured, aromatic (wild flowers and red fruits), almost feminine, yet full-bodied at around 13.5% alcohol and impressively powerful, rounded and fresh on the palate and with great potential longevity, elegance and length (Rolando Faustino, personal communication). Recent plantings further south in the Beira Interior DO have shown encouraging results: wines that are austere in youth, with rather more acidity than most port grapes, but age elegantly. Recommended producers include Herdade S\u00e3o Miguel in the Alentejo, Lu\u00eds Pato in Beiras and Quinta das Maias in D\u00e3o. There were 374 ha (924 acres) in Portugal in 2010.',
      'Although Tinto C\u00e3o is not included in California\u2019s official vineyard statistics for 2009, the variety was planted by Austin Goheen in the University of California at Davis experimental vineyards at Jackson in the 1960s, providing several selections for commercial propagation. Some producers of port-style wines use the variety in their blends and a few such as Pierce (San Antonio Valley) include it in blended unfortified reds and occasionally make a varietal. Cinquain (Paso Robles) make a sweet red from a blend of Tinto C\u00e3o plus TOURIGA FRANCA and TOURIGA NACIONAL.',
      'Plantings in Australia are also too insignificant to be included in official statistics but there are a few scattered outposts. For example, Peel Estate in Western Australia use Tinto C\u00e3o in their port-style wines, as do Campbells of Rutherglen in Victoria. The latter have also had success with a dry red wine made from a blend of Portuguese varieties including Tinto C\u00e3o, all from small areas planted in a vineyard block dating back to the 1960s \u2013 so much so that they have plans to expand the area dedicated to these varieties. Mansfield Wines in Mudgee even make a varietal dry red.',
    ],
  },
  {
    name: 'TINTO VELASCO',
    synonyms: [
      'Benitillo',
      'Blasco',
      'Frasco',
      'Granadera',
      'Tinto de la P\u00e1mpana Blanca',
      'Tinto Velasco Peludo',
    ],
    description: 'Unexciting La Mancha variety.',
    'origin and parentage': [
      'Tinto Velasco most likely originates from Castilla-La Mancha in central Spain. DNA profiling has confirmed Blasco, Frasco and Granadera as synonyms (Fern\u00e1ndez-Gonz\u00e1lez, Mena et al. 2007) and has unexpectedly shown that Tinto Velasco is also identical to Benitillo and to Tinto de la P\u00e1mpana Blanca, another black-berried variety (despite its name) from La Mancha (Borrego, Cabello et al. 2002).',
      'Confusingly, the name Tinto Velasco is used for both this distinct variety and also for ALICANTE HENRI BOUSCHET.',
    ],
    'where its grown': [
      'Tinto Velasco has been provisionally included in the register of commercial varieties in Spain, although it also lists Tinto de la P\u00e1mpana Blanca as a separate variety, which explains why there are national 2008 vineyard totals of 2,287 ha (5,651 acres) of Tinto Velasco as well as 4,908 ha (12,128 acres) of the latter. However, since the name Tinto Velasco is also used for ALICANTE HENRI BOUSCHET, it is impossible to know which variety these statistics refer to and whether a wine labelled Tinto Velasco is made from this variety or from Alicante Henri Bouschet. Tinto de la P\u00e1mpana Blanca is authorized in DOs such as Extremadura and La Mancha.',
    ],
  },
  {
    name: 'TINTORE DI TRAMONTI',
    synonyms: ['Cannamelu', 'Guarnaccia', 'Tintora', 'Tintore'],
    description:
      'Rare, deeply coloured Campanian with excellent potential for fine reds.',
    'origin and parentage': [
      'Tintore means \u2018dyer\u2019 and this comes from the province of Avellino in Campania, southern Italy. DNA profiling has suggested genetic relationships between Tintore di Tramonti and three other minor Campanian varieties that are not significantly cultivated (Costantini et al. 2005): Tintiglia (distinct from TINTILIA DEL MOLISE), Livella Ischia and Mangiaguerra (distinct from MAGLIOCCO DOLCE, sometimes called Mangiaguerra in Calabria).',
    ],
    'where its grown': [
      'As its name suggests, Tintore di Tramonti is cultivated mainly in the area around the little town of Tramonti in the Monte Lattari just north of Salerno in Campania, southern Italy, near the Amalfi coast, where old and ungrafted vines can still be found trained on pergolas. It is blended with PIEDIROSSO and other local varieties in the Costa d\u2019Amalfi DOC, as well as in Ischia DOC, on the island of the same name, where it is known as Guarnaccia or Cannamelu. Varietal wines, which have to be classified as IGTs such as Campania or Colli di Salerno, are very deeply coloured, as the name suggests, rich in dark fruit but with firm acidity to give structure, sometimes peppery flavours mixed with the fruit, sometimes more savoury and earthy. They can age well thanks to the high acidity and firm tannins that become silky with time in bottle. Examples of Tintore or Tintore-dominated wines: Reale\u2019s oaked Borgo di Gete, Colli di Salerno DOC, and Alfonso Arpino\u2019s Monte di Grazie IGT Campania, a blend of 90% Tintore and 10% Piedirosso. There are less than 10 ha (25 acres) of this variety on the Amalfi coast and the total production is around 8,000 bottles a year.',
    ],
  },
  {
    name: 'TORBATO',
    synonyms: [
      'Malvoisie du Roussillon',
      'Malvoisie des Pyr\u00e9n\u00e9es Orientales',
      'Torbat',
      'Tourbat',
      'Turbat',
      'Uva Catalana',
    ],
    description:
      'Pale-berried variety found on the Italian island of Sardegna and in southern France, recovering from virtual extinction.',
    'origin and parentage': [
      'DNA profiling recently confirmed that Torbato is identical to Malvoisie du Roussillon in southern France, also called Tourbat or Malvoisie des Pyr\u00e9n\u00e9es Orientales (Lacombe et al. 2007), and mentioned in Limoux, southern France, as early as 1804 (Galet 2000). Like many Sardinian varieties, Torbato is said to have been introduced from Spain during Catalan rule of Sardegna in the fourteenth and fifteenth centuries but there is as yet no evidence to confirm this hypothesis.',
    ],
    'where its grown': [
      'In Italy, Torbato is cultivated mainly in the area around Alghero west of Sassari on the north-west coast of Sardegna and also further inland in Mejlogu. It is made as varietal wine in the Alghero DOC. One of the rare examples of varietal wines is Sella & Mosca\u2019s apple and pear-flavoured Terre Bianche, which has a slightly bitter finish. There were 143 ha (353 acres) in Italy in 2000 but this is a considerable increase on the lonely 10 ha (25 acres) that remained in 1970.',
      'In France, this variety had virtually disappeared at the beginning of the twentieth century because of its susceptibility to powdery mildew and fanleaf degeneration (court-nou\u00e9 in French). New and healthier plant material was reintroduced from Sardegna thanks to work done there in the 1970s and there are now around 33 ha (82 acres) of Tourbat, which is recommended in the Aude, H\u00e9rault and Pyr\u00e9n\u00e9es-Orientales d\u00e9partements. It is generally blended with other local varieties to produce vins doux naturels in Banyuls, Grand Roussillon, C\u00f4tes du Roussillon, Maury and Rivesaltes.',
    ],
  },
  {
    name: 'TORRONT\u00c9S MENDOCINO',
    synonyms: ['Chichera', 'Loca Blanca', 'Palet'],
    description: 'A lesser Argentine Torront\u00e9s.',
    'origin and parentage': [
      'Torront\u00e9s Mendocino is indigenous to Argentina, where it was long confused with the equally obscure TORRONT\u00c9S SANJUANINO and the more widespread TORRONT\u00c9S RIOJANO (see the latter entry for geographic origin and etymology) until they were recently distinguished by morphological descriptions and DNA profiling (Alcalde 1989; Ag\u00fcero et al. 2003; Lacoste et al. 2010). Through DNA parentage analyses, Ag\u00fcero et al. (2003) have shown that Torront\u00e9s Mendocino is a natural cross between MUSCAT OF ALEXANDRIA (Moscatel in Argentina) and an unidentified variety, making it a member of the group of varieties known as the Criollas (see CRIOLLA entry and MUSCAT pedigree diagram).',
    ],
    'where its grown': [
      'Torront\u00e9s Mendocino, presumably taking its name from the province of Mendoza, even though it is now a little more widely planted in the province of Ri\u00f3 Negro in the south, tends to lack the fragrant Muscat-like aroma of TORRONT\u00c9S RIOJANO. There were 666 ha (1,646 acres) planted in Argentina in 2009.',
    ],
  },
  {
    name: 'TORRONT\u00c9S RIOJANO',
    synonyms: ['Malvasia'],
    description:
      'The most important of the three Argentine Torront\u00e9s Somethings, responsible for the country\u2019s highly aromatic signature white wine.',
    'origin and parentage': [
      'Torront\u00e9s Riojano was first mentioned in the provinces of La Rioja (whence its name), San Juan and Mendoza by Dami\u00e1n Hudson in 1867 (Lacoste et al. 2010). It was often speculated that Torront\u00e9s Riojano was one of several varieties that had been introduced from Spain and that the name Torront\u00e9s was borrowed from the Spanish Torront\u00e9s (sometimes Turront\u00e9s or Turrunt\u00e9s), a vague name used in Spain for ALARIJE, ALBILLO MAYOR and MALVASIA FINA. However, the DNA profiles of all these varieties are quite distinct (Vouillamoz).',
      'Through DNA parentage analyses, Ag\u00fcero et al. (2003) have shown that Torront\u00e9s Riojano is a natural cross between MUSCAT OF ALEXANDRIA (Moscatel in Argentina) and LIST\u00c1N PRIETO (Criolla Chica in Argentina), making it a member of the group of varieties known as the Criollas (see CRIOLLA entry and MUSCAT pedigree diagram for details).',
    ],
    'where its grown': [
      'Torront\u00e9s Riojano is the most valued and widespread of the three indigenous varieties called Torront\u00e9s Something (more usually and confusingly just Torront\u00e9s) in Argentina, often described as the country\u2019s signature white wine variety and its mostly widely planted light-skinned variety after the uninspiring and declining PEDRO GIM\u00c9NEZ. At its best, it produces fresh, highly aromatic wines, typically floral and Muscat-like, although poor examples can be alcoholic with a bitter aftertaste. It seems to do particularly well in the higher, cooler vineyards of Cafayate in the province of Salta in the north of the country, although there are also some very successful wines from the Valle de Uco in the Mendoza region and from Chilecito in La Rioja. Given its very distinctive flavours, it also makes a useful blending component with more neutral varieties such as PINOT GRIS. Improving the general quality of Torront\u00e9s has been a speciality of Susana Balbo of Dominio del Plata. Other recommended producers of varietal wines include Catena Zapata, Colom\u00e9, O Fournier and Mauricio Lorca. There were 8,443 ha (20,863 acres) in Argentina in 2009, mostly in Mendoza, La Rioja, San Juan and Salta but also in Catamarca and R\u00edo Negro.',
      'Pisano have planted Torront\u00e9s Riojano in their vineyards in Progreso, Uruguay, and make a wine that is more powerful than most Argentine versions, less floral, with intense citrus and pear flavours.',
    ],
  },
  {
    name: 'TORRONT\u00c9S SANJUANINO',
    synonyms: ['Moscatel de Austria', 'Moscatel Romano'],
    description:
      'One of the two lesser-known Torront\u00e9s Somethings in Argentina.',
    'origin and parentage': [
      'Torront\u00e9s Sanjuanino is indigenous to Argentina, where it was long confused with the equally obscure TORRONT\u00c9S MENDOCINO and the more widespread TORRONT\u00c9S RIOJANO (see the latter entry for geographic origin and etymology) until they were recently distinguished by morphological descriptions and DNA profiling (Alcalde 1989; Ag\u00fcero et al. 2003; Lacoste et al. 2010). Through DNA parentage analyses, Ag\u00fcero et al. (2003) have shown that Torront\u00e9s Sanjuanino is a natural cross between MUSCAT OF ALEXANDRIA (Moscatel in Argentina) and LIST\u00c1N PRIETO (Criolla Chica in Argentina), making it a member of the group of varieties known as the Criollas (see CRIOLLA entry and MUSCAT pedigree diagram).',
    ],
    'where its grown': [
      'Not surprisingly, Torront\u00e9s Sanjuanino is found mainly in the province of San Juan in Argentina, although it is also planted in Mendoza and La Rioja. Wines are typically less aromatic than those made from TORRONT\u00c9S RIOJANO. Total plantings were 2,539 ha (6,274 acres) in 2009, the majority, unsurprisingly, in San Juan.',
    ],
  },
  {
    name: 'TORYSA',
    synonyms: ['CATAP 9/17'],
    description: 'Recently authorized red-fleshed Slovakian cross.',
    'origin and parentage': [
      'Torysa, named after a river in eastern Slovakia, is a CASTETS \u00d7 I-35-9 cross obtained in 1967 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia, and authorized in 2011. I-35-9 is an obscure (TEINTURIER \u00d7 ALEATICO) \u00d7 PUKHLIAKOVSKY cross obtained in Russia (Dorota Posp\u00ed\u0161ilov\u00e1, personal communication). Torysa is a sibling of RUDAVA.',
    ],
    'where its grown': [
      'Torysa is one of the few red-fleshed varieties to produce deeply coloured, ageworthy Cabernet-like wines (Dorota Posp\u00ed\u0161ilov\u00e1, personal communication). Producers include Chateau Marco in south-west Slovakia and Igor Blaho near Trnava in the west. Less than 1 ha (2.5 acres) was grown in Slovakia in 2011.',
    ],
  },
  {
    name: 'TOURIGA F\u00caMEA',
    synonyms: ['Tinta Coimbra', 'Touriga Brasileira'],
    description: 'Minor Touriga from northern Portugal.',
    'origin and parentage': [
      'Touriga F\u00eamea most probably originates from the Douro in northern Portugal. DNA parentage analysis has suggested a possible parent\u2013offspring relationship with TOURIGA NACIONAL (Lopes et al. 2006), which would make it a half-sibling (or, in theory, a grandparent) of TOURIGA FRANCA, all from the same region.',
    ],
    'where its grown': [
      'Touriga F\u00eamea wines are deeply coloured, full-bodied yet soft with fine tannins and flavours of black fruits and scrubland. The variety is authorized for DOC Douro wines and also for various regional wines, mostly in the northern half of Portugal but also in the Pen\u00ednsula de Set\u00fabal. In the Douro, Rui Jos\u00e9 Xavier Soares includes this variety, under the name Touriga Brasileira, in his Esmero red Douro blend and Quinta da Revolta make a rare varietal wine. There were 43 ha (106 acres) in Portugal in 2010, about half of that in the Douro.',
    ],
  },
  {
    name: 'TOURIGA FRANCA',
    synonyms: [
      'Albino de Souza',
      'Touriga Frances',
      'Touriga Francesa',
      'Tourigo Franc\u00eas',
    ],
    description:
      'High-quality, widely planted Douro variety qualitatively in the shadow of Touriga Nacional.',
    'origin and parentage': [
      'In spite of its name, Touriga Franca does not originate from France but most likely from the Douro region in northern Portugal. Indeed, recent DNA parentage analysis has shown that Touriga Franca is the offspring of TOURIGA NACIONAL and MARUFO (Castro et al. 2011), both also traditionally cultivated in the Douro. Since Touriga Nacional was not traditionally cultivated in Spain, where Marufo is grown under various names, the cross is likely to have occurred in Portugal (Castro et al. 2011). Touriga Franca shows little genetic diversity and it was not mentioned in writing until 1880, which suggests that the cross may be relatively recent (Almadanim et al. 2007; Rolando Faustino, personal communication). In addition, genetic studies have suggested that Touriga Franca could be closely related to TINTA BARROCA, also from the Douro region (Pinto-Carnide et al. 2003; Mart\u00edn et al. 2006; Almadanim et al. 2007).',
    ],
    'where its grown': [
      'Known until 2000 as Touriga Francesa in Portugal, Touriga Franca is the most widely planted dark-skinned variety in the Douro region \u2013 8,919 ha (22,039 acres) or just over 21% of the vineyard area in 2004 \u2013 and can reach almost the same quality levels as TOURIGA NACIONAL when planted on warm, south-facing slopes, but in less favourable years or sites, ripening may be less successful due to greater susceptibility to botrytis bunch rot. Varietal wines are less concentrated than Touriga Nacional but show finesse and have aromatic intensity, good depth of colour and moderate body, although for port production they are best in blends. Some 100% Touriga Franca table wines, such as the one from Quinta do Mour\u00e3o, have started to appear and these show the potential of the variety for high quality and longevity: intense aromas of red fruits, forest fruits, wild flowers, violets and herbs, an excellent ruby/violet colour and firm but fine tannins.',
      'It is also widely planted in Tr\u00e1s-os-Montes in the far north and is slowly being disseminated further south to other Portuguese regions such as Lisboa, the Tejo, the Alentejo and the Pen\u00ednsula de Set\u00fabal. There are also small areas in D\u00e3o. Recommended producers of varietal wines include Ermelinda Freitas in Set\u00fabal and Quinta da Plansel in the Alentejo. There was a total of 12,055 ha (29,789 acres) in Portugal in 2010, down from 14,000 ha (34,595 acres) in 2004, but a far greater area than TOURIGA NACIONAL\u2019s 7,268 ha (17,960 acres).',
      'Although Tinta Barca has been used as a synonym for Touriga Franca, Tinta da Barca (occasionally just Barca) is a distinct but scarcely planted \u2013 often interplanted \u2013 variety from the Douro Superior used mainly in port blends and valued by Ramos Pinto, who have 8 ha (20 acres), and Vargellas, for example.',
      'In California, although only TOURIGA NACIONAL shows up in the official statistics and Touriga tout court is the official synonym for that variety, growers such as Cinquain (Paso Robles), Pierce and Quinta Cruz (San Antonio Valley) and Revolution Wines (Sacramento) often include both Nacional and Franca in their port-style blends and a few, eg Morgan, make a red table-wine blend from Lodi that includes both.',
      'Australia had 46 ha (114 acres) of \u2018Touriga\u2019 planted in 2008. Although this is far more likely to be TOURIGA NACIONAL, only a few producers use the full name.',
    ],
  },
  {
    name: 'TOURIGA NACIONAL',
    synonyms: [
      'Azal Espanhol',
      'Carabu\u00f1eira',
      'Mortagua',
      'Mortagua Preto',
      'Touriga',
      'Touriga Fina',
      'Tourigo',
      'Tourigo Antiguo',
      'Tourigo do D\u00e3o',
    ],
    description:
      'High-quality, concentrated, tannic and perfumed dark-skinned Portuguese variety increasingly cast in a starring role.',
    'origin and parentage': [
      'Touriga Nacional most likely originates from the D\u00e3o region, where it was first mentioned in 1822 (Cunha et al. 2009) and where it shows higher morphological diversity than elsewhere (Almadanim et al. 2007; Rolando Faustino, personal communication). This is consistent with the suggestion that the name Touriga derives from the village of Tourigo (Galet 2000), west of Tondela in the district of Viseu in D\u00e3o.',
      'According to recent DNA parentage analyses, Touriga Nacional and MARUFO are the parents of TOURIGA FRANCA from the Douro (Castro et al. 2011), and Touriga Nacional could have parent\u2013offspring relationships with TOURIGA F\u00caMEA, also traditionally cultivated in the Douro and D\u00e3o regions (Pinto-Carnide et al. 2003; Lopes et al. 2006; Almadanim et al. 2007). In addition, Touriga Nacional could be genetically very close to RUFETE from Castelo Branco to the south east (Almadanim et al. 2007).',
      'Touriga Nacional was used to breed TARRANGO.',
      'Confusingly, the name Bical, used principally for a white-berried variety (see the BICAL entry), is also sometimes used as a synonym for Touriga Nacional in Anadia, between Porto and Coimbra. The alternative Bical Tinto is marginally less confusing.',
    ],
    'where its grown': [
      'Touriga Nacional, the most revered variety for port, has become Portugal\u2019s poster child for fine dry reds too. The wines are deeply coloured, concentrated, tannic, rich in dark fruit and in some cases distinguished by their fragrance \u2013 aromas such as bergamot, rosemary, rock roses or violets. This very intensity can make such wines almost too much on their own and many believe Touriga Nacional is better in a blend. Lighter wines with lower alcohol often have a tea-like aroma. The variety also has a good level of acidity, excellent ageing potential (although the wines often close down for a few years after five years in bottle) and marries well with oak.',
      'Until the end of the nineteenth century, Touriga Nacional accounted for almost 100% of red grape varieties in D\u00e3o and there were significant plantings in the Douro, but by the mid twentieth century the area had dropped dramatically, and was down to around 5% in D\u00e3o by 1986. This was due largely to the variety\u2019s poor productivity, exacerbated by the use of unsuitably vigorous rootstocks in the wake of phylloxera, which made it very unpopular with growers despite the very high potential quality of the fruit. In order to encourage growers to continue with Touriga Nacional, to achieve the variety\u2019s fullest potential and to be able to depend on consistent yields from year to year, there has been much investment in clonal selection since the late 1970s and attempts to find the best combinations of rootstocks and clones for higher productivity without loss of quality, in conjunction with less dense planting systems. Recent plantings in the Douro have been mainly in the Douro Superior, towards the Spanish border, and the total area in the Douro is now 1,440 ha (3,558 acres).',
      'By 2010 there were 7,268 ha (17,960 acres) in Portugal, more than double the total area in 2004, and the improvements in planting material as well as increased global recognition for the wines have encouraged the southward migration of Touriga Nacional, which is currently planted in Bairrada, the Tejo, Lisboa, the Pen\u00ednsula de Set\u00fabal, the Alentejo and even in the Algarve. However, wines from the hotter south tend to be less elegant and perfumed. Recommended producers of varietal wines include Quinta do Couquinho and Quinta do Noval in the Douro, Quinta dos Roques in D\u00e3o and Quinta da Sapeira in the Encostas d\u2019Aire DOC (central western Portugal).',
      'The recent rise in popularity of Touriga Nacional is not limited to Portugal. The variety has already spread to Priorat in Spain, to Australia and California.',
      'The 220 acres (89 ha) planted in California in 2009, more than half in the San Joaquin Valley but also encroaching on Napa, are used in much the same way as in Australia. The TTB (Alcohol and Tobacco Tax and Trade Bureau) does not permit producers in the US to distinguish between the two Tourigas on the label even though this is legal for imported wines. Franca Morgan\u2019s Rio Tinto unfortified red blend (Santa Lucia Highlands) includes Touriga Nacional, TOURIGA FRANCA and other Portuguese varieties. Kenneth Volk\u2019s Pomar Junction is an unfortified varietal Touriga Nacional from Paso Robles (labelled Touriga tout court) and York Creek Vineyards (Spring Mountain, Napa) make an impressive ros\u00e9 exclusively from Touriga Nacional. Ficklin Vineyards (Madera) make a fine range of port-style wines that have Touriga in the mix and Quady\u2019s Starboard (Madera) is a sweet, fortified blend of TINTO C\u00c3O and Touriga Nacional.',
      'There are also minor plantings in Washington State and Virginia as well as in Argentina and Brazil.',
      'Although Australians tend to use the name Touriga to refer to both Touriga Nacional and TOURIGA FRANCA, it is likely that almost all of the scattered 46 ha (114 acres) in Australia are Touriga Nacional, used for both port-style and table wines. Coates and Old Mill in Langhorne Creek and Kaesler and Tscharke in the Barossa are among those who make varietal wines, the last one a fortified version. Trinity Hill in Hawke\u2019s Bay, New Zealand, make a fortified 100% Touriga Nacional.',
      'Touriga Nacional has long been grown to a limited extent in the hotter, inland wine regions of South Africa, where it can make some quite convincing ripostes to port, generally in blends. Recommended producers include Allesverloren, Boplaas and De Krans. There was a total of 87 ha (215 acres) of Touriga Nacional in 2008, the greatest proportion in Little Karoo.',
    ],
  },
  {
    name: 'TOURKOPOULA',
    synonyms: ['Rhoditis Kokkinos', 'Roditis Kokkinos'],
    description:
      'Rare, pinky-red-skinned Greek variety that should not be confused with Roditis.',
    'origin and parentage': [
      'Tourkopoula, meaning \u2018young Turkish woman\u2019, comes from north-western Greece and the Pelop\u00f3nnisos (Peloponnese) and has been shown by DNA profiling to be identical to Roditis Kokkinos (\u2018red from R\u00f3dos\u2019) but distinct from the more widely planted pink-skinned RODITIS and the white-berried Roditis Lefkos (Lefort and Roubelakis-Angelakis 2001; Greek Vitis Database). The berry skins are reddish pink.',
    ],
    'where its grown': [
      'Tourkopoula is planted to a limited extent on southern mainland Greece, the western Pelop\u00f3nnisos and on several islands, including Z\u00e1kynthos and K\u00e9rkyra (Corfu). Mercouri in the western Pelop\u00f3nnisos have planted Tourkopoula and blend it with RIBOLLA GIALLA to make a wine that is light and citrus-flavoured.',
    ],
  },
  {
    name: 'TRAJADURA',
    synonyms: ['Treixadura'],
    description:
      'High-quality variety best in blends in the north-western corner of both Spain and Portugal.',
    'origin and parentage': [
      'This variety is called Trajadura in the Douro and Minho regions of northern Portugal and Treixadura in Galicia in north-west Spain. It is not genetically related to Galician varieties and is thought to have been introduced to Galicia from other vine-growing areas (Vidal et al. 1999), possibly from the Minho since it is genetically fairly close to ARINTO DE BUCELAS, AZAL and LOUREIRO (Lopes et al. 1999; Almadanim et al. 2007), three varieties with which Trajadura is often blended in the Vinho Verde DOC.',
    ],
    'where its grown': [
      'Trajadura is recommended throughout the Minho region in north-west Portugal with the exception of the Basto and Amarante subregions, although it was originally concentrated in the north west of the region, in Mon\u00e7\u00e3o, Melga\u00e7o, Valen\u00e7a, Vila Nova de Cerveira and Caminha. There was a total of 1,415 ha (3,497 acres) in 2010, down from 2,200 ha (5,436 acres) in 2004, but plantings now seem to be relatively stable.',
      'Wines are typically golden-straw in colour, have delicate aromas of apples, pears and peaches, sometimes lemon, and are naturally low in acidity, and therefore better blended, eg with LOUREIRO and/or Padern\u00e3 (ie ALVARINHO). Recommended producers include Casa de Vila Boa, Quinta da Pousada and Quinta do Regueiro.',
      'In Spain there were 840 ha (2,076 acres) of Treixadura in 2008, up from 660 ha (1,631 acres) in 2004, virtually all in Galicia in the far north west. Here it is the main variety in the Ribeiro DO but is also found in R\u00edas Baixas, where it is often blended with Albari\u00f1o (ALVARINHO) and possibly Loureira (LOUREIRO; ie as over the border in Portugal). Treixadura is also authorized in the Monterrei, Valdeorras and Ribeira Sacra DOs, where it tends to be blended with other local varieties such as GODELLO. Varietal wines are relatively rare but are made by producers such as Aurea Lux, Campante (a sweet, dried-grape version, a style traditionally known as tostado), Cooperativa San Roque Beade, Docampo and Vi\u00f1o Ribeiro. Coto de Gomariz produce a fine blend of 80% Treixadura together with Godello, Loureira and Albari\u00f1o.',
    ],
  },
  {
    name: 'TRBLJAN',
    synonyms: [
      'Dobrogo\u0161tina',
      'Grban',
      'Ku\u010d',
      'Pljuskavac',
      'Rukavina',
      '\u0160ampanjol',
      'Trbljan Bijeli',
    ],
    description:
      'Variety widely planted in coastal Croatia for long thought to be a Trebbiano.',
    'origin and parentage': [
      'The exact origin of Trbljan is unknown but it is considered to be indigenous to Dalmacija (Dalmatia), southern Croatia. Contrary to common belief, recent ampelographic research and DNA profiling have shown that Trbljan is not identical to TREBBIANO TOSCANO (Goran Zduni\u0107, personal communication) but it might be related to TREBBIANO ROMAGNOLO.',
    ],
    'where its grown': [
      'Trbljan is widely planted in southern and inland Dalmacija, particularly on the island of Vis, and further north along the coast of Croatia. In 2009 it represented about 3% of Croatia\u2019s vineyard, with around 975 ha (2,409 acres) planted. In the region of Ka\u0161tela close to Split, the variety tends to be blended with other local varieties. Wines are typically light, crisp and rather simple, especially if yields are not controlled.',
    ],
  },
  {
    name: 'TREBBIANO D\u2019ABRUZZO',
    synonyms: [
      'Sbagagnina',
      'Sbagarina',
      'Svagadia',
      'Trebbiano Abruzzese',
      'Trebbiano Campolese',
      'Trebbiano di Teramo',
    ],
    description:
      'Light-skinned variety from east-central Italy unrelated to other Trebbianos but whose identity is still unclear.',
    'origin and parentage': [
      'As its name suggests, Trebbiano d\u2019Abruzzo has long been known in Abruzzo, central Italy. It is generally thought to be identical to BOMBINO BIANCO in Puglia, further south. However, in 1994 they were listed as separate varieties in the Italian national register, so they should probably be thought of as distinct varieties until DNA profiling has shed some light on this ampelographic issue. A DNA study has suggested a possible genetic relationship between Trebbiano d\u2019Abruzzo and TREBBIANO SPOLETINO (Labra et al. 2001). Some authors have suggested Trebbiano d\u2019Abruzzo is a seedling of BIANCAME or TREBBIANO TOSCANO (Cal\u00f2 et al. 2006).',
    ],
    'where its grown': [
      'Trebbiano d\u2019Abruzzo is cultivated mainly in Abruzzo in east-central Italy, in particular in the provinces of Chieti, Teramo, Pescara and L\u2019Aquila, and also to a more limited extent in Molise. The regulations for the DOC Trebbiano d\u2019Abruzzo further obfuscate the distinction between Trebbiano d\u2019Abruzzo and BOMBINO BIANCO varieties since they specify that such DOC wines must be made from \u2018Trebbiano d\u2019Abruzzo (Bombino Bianco) and/or Trebbiano Toscano\u2019 along with other varieties authorized in the region. Valentini\u2019s strongly mineral Trebbiano d\u2019Abruzzo DOC, made from mass selection of old vines in their own vineyards, ages beautifully and is considered one of the best Italian whites but it is not typical of the DOC \u2013 and is made from Bombino Bianco. Masciarelli\u2019s Marina Cvetic Trebbiano d\u2019Abruzzo DOC is apparently made from the variety of that name but the Trebbiano d\u2019Abruzzo v Bombino Bianco question cannot be resolved without DNA analysis. The Italian agricultural census of 2000, which also distinguishes between these two varieties, records 418 ha (1,033 acres) of \u2018Trebbiano Abruzzese\u2019.',
    ],
  },
  {
    name: 'TREBBIANO GIALLO',
    synonyms: [
      'Greco di Velletri',
      'Trebbiano dei Castelli',
      'Trebbiano di Spagna',
      'Trebbiano Giallo di Velletri',
    ],
    description: 'Another Trebbiano, grown and blended mainly around Roma.',
    'origin and parentage': [
      'Trebbiano Giallo was first described by Acerbi (1825) and was later identified with Trebbiano di Spagna, a variety cultivated in the province of Modena, where it is used to produce a base wine for aceto balsamico or balsamic vinegar. It has often \u2013 erroneously \u2013 been suggested that there is a similarity between ROSSETTO and Trebbiano Giallo.',
    ],
    'where its grown': [
      'Trebbiano Giallo is cultivated mainly in the Castelli Romani and Colli Albani regions, south of Roma in Lazio, central Italy. It is generally blended with other varieties and authorized in many DOCs in Lazio, including Castelli Romani, Cerveteri, Cori and Frascati, as well as in Garda Colli Mantovani DOC in Lombardia. Under its synonym Trebbiano di Spagna, it is found in the province of Modena in Emilia-Romagna, exclusively for the production of aceto balsamico. There were 4,262 ha (10,532 acres) in Italy in 2000 for the production of wine.',
    ],
  },
  {
    name: 'TREBBIANO MODENESE',
    synonyms: [
      'Terbi\u00e0n Moscatl\u00e8',
      'Terbianella',
      'Trebbiano Comune',
      'Trebbiano di Collina',
      'Trebbiano di Modena',
      'Trebbiano Montanaro',
    ],
    description:
      'Emilia-Romagnan used more for balsamic vinegar than for wine.',
    'where its grown': [
      'Trebbiano Modenese has long been known on the Po plain in the provinces of Modena and Reggio Emilia in Emilia-Romagna, north-central Italy, mainly to produce the base wine for aceto balsamico di Modena. It is not listed in the regulations for any DOC but there were 667 ha (1,648 acres) for the production of wine in Italy in 2000.',
    ],
  },
  {
    name: 'TREBBIANO ROMAGNOLO',
    synonyms: ['Trebbiano della Fiamma', 'Trebbiano di Romagna'],
    description:
      'The widely planted but not especially distinguished Emilia-Romagnan Trebbiano.',
    'origin and parentage': [
      'Trebbiano Romagnolo probably originates from the province of Ravenna in Emilia-Romagna in north-central Italy, where the earliest mention of any (though not necessarily this) TREBBIANO appears in 1303 in Pietro de Crescenzi\u2019s treatise on agriculture.',
    ],
    'where its grown': [
      'Trebbiano Romagnolo is the most widely cultivated variety in Emilia-Romagna in north-central Italy, mainly in the province of Ravenna, in particular between Faenza, Lugo and Russi. It is also found in the province of Forl\u00ec and here and there in the provinces of Parma and Bologna, as well as, to a lesser extent, in Lazio and on Sardegna. The Italian agricultural census of 2000 recorded total plantings of 20,033 ha (49,503 acres).',
      'Trebbiano Romagnolo is used in the blend of many and various dry white DOC wines, both still and sometimes sparkling, in Emilia-Romagna, including Colli Bolognesi, Colli di Rimini, Colli di Scandiano e di Canossa and Colli Piacentini, as well as an optional blending component in several Lazio DOCs such as Castelli Romani and Colli Albani. Varietal wines are relatively common, often rather bland but with fresh acidity \u2013 which is probably why they are used in the base wines for some fizz \u2013 and are authorized in Colli Romagna Centrale and the ubiquitous Trebbiano di Romagna DOCs. On the island of Sardegna, it is an alternative to TREBBIANO TOSCANO in the Arborea DOC. It is also used in the production of brandy.',
      'Examples from Battistini, La Berta, Celli, Dalfiume, Cantina di Faenza and Zerbina are probably better than most.',
    ],
  },
  {
    name: 'TREBBIANO SPOLETINO',
    synonyms: ['Spoletino', 'Trebbiano di Avezzano', 'Trebbiano di Spoleto'],
    description:
      'Recently rescued Umbrian capable of better quality, and more body, than most Trebbiano Somethings.',
    'origin and parentage': [
      'Trebbiano Spoletino is a local Umbrian variety first cited in 1878, which suggests it was a local natural cross that occurred some time in the early nineteenth century. A DNA study has suggested a possible genetic relationship between Trebbiano Spoletino and TREBBIANO D\u2019ABRUZZO (Labra et al. 2001).',
    ],
    'where its grown': [
      'Trebbiano Spoletino was almost extinct when it was rescued by Cantina Novelli in the early 2000s. It was replanted in Spoleto using cuttings from 600 old vines \u2013 some of them over 100 years old, ungrafted and trained alberate (up or between trees) \u2013 selected by Attilio Scienza of the University of Milano. It is today cultivated almost exclusively in Umbria in central Italy, mainly in the province of Perugia, in particular in Bevagna, Castel Ritaldi, Giano, Gualdo Cattaneo, Montefalco, Spoleto and Trevi. The Trebbiano authorized in the Assisi DOC and Colli del Trasimeno DOC blends is probably this particular one. Other Umbrian DOCs such as Colli Martani and Montefalco may include a small amount of Trebbiano Spoletino but it is often TREBBIANO TOSCANO that plays the dominant role.',
      'Varietal wines tend to be gold in colour, full-bodied, rich and aromatic \u2013 white blossom, tropical fruit, spices \u2013 but with good acidity, too. The first modern example was Cantina Novelli\u2019s Trebbiano Spoletino, IGT Umbria, which has aromas of citrus and nutty flavours on the palate. Antonelli San Marco, Perticaia and Tabarrini also make varietal wines.',
    ],
  },
  {
    name: 'TREBBIANO TOSCANO',
    synonyms: [
      'Alfrocheiro Branco',
      'Armenian',
      'Bianca di Poviglio',
      'Douradinha',
      'Morterille Blanche',
      'Procanico',
      'Regrat',
      'Rogozni\u010dka',
      'Rossola Brandisca',
      'Roussan',
      'Saint-\u00c9milion',
      'St Emilion',
      '\u0160ijaka',
      'T\u00e1lia',
      'Thalia',
      'Ugni Blanc',
    ],
    description:
      'Tuscan variety also known as Ugni Blanc that probably produces more (typically tart and neutral) wine than any other vine in the world.',
    'origin and parentage': [
      'As its name suggests, Trebbiano Toscano has been known in Toscana, central Italy, for a long time. In his treatise on viticulture, Soderini (1600) considers Trebbiano (Toscano, in all probability) and Malvasia (MALVASIA BIANCA LUNGA, in all probability) as the most widespread varieties in the region.',
      'Trebbiano Toscano is said to have been exported to France in the fourteenth century during the period when the papacy resided in Avignon. In France, it was given the name Ugni Blanc, first mentioned as \u2018uniers\u2019 in Pernes in the Vaucluse in 1514 (R\u00e9zeau 1997). Indeed, Ugni Blanc was first cultivated in Provence, then in the Languedoc and later in the Charente and the Charente-Maritime, where it found its favoured spot. The name Ugni actually comes from the Proven\u00e7al dialect uni, meaning \u2018early grape\u2019, which itself possibly derives from the Greek eugenia, meaning \u2018of noble origin\u2019 (R\u00e9zeau 1997).',
      'DNA profiling found another unsuspected synonym of Trebbiano Toscano: Bianca di Poviglio, a minor white variety of Poviglio in the province of Reggio Emilia in Emilia-Romagna (Boccacci et al. 2005). DNA analysis has also shown that ALIONZA could be closely related to Trebbiano Toscano (Filippetti, Silvestroni, Thomas and Intrieri 2001). More recent investigations have revealed a likely parent\u2013offspring relationship between Trebbiano Toscano and the Soave grape GARGANEGA, another very old Italian variety (Di Vecchi Staraz, This et al. 2007; Crespan, Cal\u00f2 et al. 2008), which indicates Trebbiano Toscano\u2019s Italian origin. Since Garganega also has parent\u2013offspring relationships with eight other varieties (ALBANA, CATARRATTO BIANCO, MALVASIA BIANCA DI CANDIA, DORONA DI VENEZIA, MARZEMINA BIANCA, MONTONICO BIANCO, MOSTOSA and SUSUMANIELLO), Trebbiano Toscano is either their half-sibling or their grandparent (see Garganega for the pedigree diagram).',
      'Another parent\u2013offspring relationship, between Trebbiano Toscano and PASSERINA, cultivated on the Adriatic coast and with which Trebbiano is sometimes confused, has long been suspected but has not yet been tested by DNA profiling.',
      'Although Ugni Blanc is sometimes known as Clairette Ronde in the Rh\u00f4ne, it is not related to CLAIRETTE.',
      'Trebbiano Toscano was used to breed CHENEL, FOLIGNAN, FUBIANO, MANZONI ROSA, NOUVELLE and VIDAL.',
    ],
    'where its grown': [
      'Trebbiano Toscano is widely planted throughout the world and probably produces more wine than any other variety planted (Spain\u2019s AIR\u00c9N covers far more ground but has much lower yields and is used substantially for brandy). The wines tend to be light, fresh and unremarkable, thanks partly to the vine\u2019s abundant yields. It is by far the most widespread of the heterogeneous group of varieties with Trebbiano in their name and the second most planted white in Italy after CATARRATTO BIANCO but is much less common on labels than more interesting varieties. It generally disappears into blends or in some countries into a still. Its neutral nature and high acidity make it an ideal candidate for distillation.',
      'As Ugni Blanc, and sometimes under the name Saint-\u00c9milion, it is the most widely planted white wine variety in France because of its popularity with distillers. Although the vineyard area given over to this high-acid workhorse variety has declined since its peak at the end of the 1970s, total French plantings in 2009 were still 83,892 ha (207,302 acres), with the bulk of the plantings in the Charente and the Charente-Maritime, predominantly for the production of cognac, although it also goes into many a Vin de Pays du Gers and C\u00f4tes de Gascogne.',
      'In 2000 there were 42,456 ha (104,911 acres) of this particular, commonly planted Trebbiano in Italy, mainly in Toscana and Umbria, as well as in Emilia-Romagna and the Veneto. Plantings in Toscana have declined since this white wine grape began to lose favour with red-wine producers in Chianti, where it is no longer an obligatory part of the blend, and now that it is no longer sanctioned in Chianti Classico. It will surely continue to decline as wine drinkers are increasingly concerned with flavour rather than volume and price alone (there were more than 61,000 ha/150,734 acres in Italy in 1990). Attempts to promote dry whites made from the surplus have generally met with little success and much Trebbiano in Toscana has been grubbed up or hijacked for Vin Santo.',
      'AIR\u00c9N performs Trebbiano\u2019s functions in Spain but Portugal had 390 ha (964 acres) of T\u00e1lia in 2010. Most of it is planted in the Tejo and the resulting wine is hardly exciting.',
      'There were 337 ha (833 acres) in Croatia in 2009 while Bulgaria had 1,388 ha (3,430 acres).',
      'In Greece, the majority of plantings are in Thessal\u00eda (72 ha/178 acres in 2007) but in Dr\u00e1ma, north-east Greece, Nico Lazaridi make a dry, late-harvest, oak-aged Trebbiano, blend a little in with their SAUVIGNON BLANC and are experimenting with a lightly sparkling varietal wine.',
      'Although there were some experimental plantings in Russia in the late 1990s (by agronomists who had visited France and were inspired by its high yields), mainly in the Taman peninsula between the Black and Azov seas, the variety proved to be insufficiently cold-hardy and about two thirds of the c.150 ha (370 acres) were uprooted. The 60 or so hectares (150 acres) that remain are used, appropriately, to make base wines for brandy.',
      'In California, it is called variously St Emilion, Ugni Blanc and Trebbiano and there were 197 acres (80 ha) of it in 2010, some in Paso Robles as well as the Central Valley. It is generally used as a cheap, crisp blending component, although one or two adventurous souls have managed to craft quite an interesting full-bodied varietal tasting of white currants from it.',
      'Hester Creek bottle some in British Columbia, Canada.',
      'Argentina had 2,478 ha (6,123 acres) of Ugni Blanc in 2008, mainly in Mendoza and San Juan and to a lesser extent in R\u00edo Negro in the south. Under the same name in Uruguay there were 719 ha (1,777 acres) in 2008, about 8% of the total vineyard area. In Brazil, where it is known as Trebbiano, there were 173 ha (427 acres) in Rio Grande do Sul in 2007.',
      'Australia had 220 ha (544 acres) of Trebbiano in 2008, mainly but not exclusively in the warm, inland Riverina region where most often it is used in blends and occasionally by distillers.',
      'Known as Ugni Blanc in South Africa, in 2008 it was planted on just 68 ha (168 acres) in the warmer inland regions of Robertson and, to a much lesser extent, Klein Karoo. As in France, it is used in the production of brandy as well as for wine, but this role is more often played by COLOMBARD.',
      'It is also found in India.',
    ],
  },
  {
    name: 'TREPAT',
    synonyms: [
      'Bonicaire',
      'Carlina',
      'Embolicaire',
      'Negra Blana',
      'Parrel',
      'Parrel-Verdal',
      'Trepat Negre',
      'Traput',
    ],
    description:
      'Red-skinned Catalan traditionally used for still and sparkling ros\u00e9s.',
    'origin and parentage': [
      'Trepat comes from the Conca de Barber\u00e0 region in north-east Spain. DNA profiling has recently shown that Bonicaire in Murcia, south-east Spain, is identical to Trepat (SIVVEM) and that TREPAT BLANC from Priorat is not a colour mutation of Trepat (Giralt et al. 2009).',
      'Parrel and Parrel-Verdal are occasional synonyms for Trepat but should not be confused with PARRALETA.',
    ],
    'where its grown': [
      'Trepat, provisionally included in the Spanish register of commercial varieties, is found mainly in the Conca de Barber\u00e0 and also in the Costers del Segre DOs in Catalunya, north-east Spain, although it is permitted in the Catalunya and Cava DOs as well. The first Festa del Trapat was held in Barber\u00e0 de la Conca in 2010, suggesting there is increasing local pride in this pale-red grape. It is generally used for ros\u00e9 wines (and may be included in pink Cava) but shows good potential for light reds. In 2008, total plantings had reached 953 ha (2,355 acres), virtually all in Catalunya. Varietal wines are typically fruity, lightly structured, dominated by fresh red berries (strawberries, raspberries), hay, and a certain spiciness (cinnamon).',
      'Varietal Cava ros\u00e9 wines are produced by Bohigas, Carles Andreu, Cavas del Castillo de Perelada, Portell and Agust\u00ed Torell\u00f3 Mata, for example; Vilarnu\u2019s version includes 10% PINOT NOIR. Still ros\u00e9s are made by producers such as the Cabanal Cooperativa Agr\u00edcola de Barber\u00e0 de la Conca, the Cooperativa Agr\u00edcola de L\u2019Espluga de Francol\u00ed, Carlania and Vinya Sanfeliu. Red wines made from 100% Trepat are harder to find, but include, for example, those made by Carles Andreu and Mas Foraster.',
      'Under the name Bonicaire, it is authorized in such appellations as Vino de la Tierra Castell\u00f3 in Castell\u00f3n in Valencia, eastern Spain (just south of Catalunya), in the Valencia DO and further south in the Vinos de la Tierra Abanilla and Campo de Cartagena in Murcia, although very few vines remain. Wines tend to be light in colour and soft and have a citrus flavour, with a slightly bitter-orange aftertaste.',
    ],
  },
  {
    name: 'TRESSOT',
    synonyms: [
      'Bourguignon',
      'Bourguignon Noir',
      'Morillon Noir',
      'Noirien',
      'Nairien',
      'Resseau',
      'Treceaux',
      'Tresseau',
      'Tressiot',
      'Tressot Noir',
      'V\u00e9rot',
      'Petit Verrot',
    ],
    description:
      'Ancient but virtually extinct, struggling variety from Chablis country of intriguing parentage.',
    'origin and parentage': [
      'Tressot is a very old variety from the Yonne d\u00e9partement in north-eastern France, where it was mentioned under the name Treceaux in 1394 (see PINOT for the full citation), and then in 1562 in Auxerre, the main city of the Yonne (Simp\u00e9e 1902): \u2018Et pour ce faire, a dit Lenayn promis fornir le pland chevel\u00e9e de Pynots, Terceaulx et Servigneans, bons et convenables \u00e0 planter et \u00e9difier vigne\u2019 (\u2018And to do so, it was promised to the aforesaid Lenayn to provide with rooted cuttings of pynots, terceaulx and servigneans, good and suited for planting and setting up a vineyard\u2019). The spelling Tresseau was generally used before the twentieth century. The etymology of Tressot is uncertain, but it is usually said to mean trois ceps (\u2018three vines\u2019) because it produces three times more than Pinot, with which it used to be cultivated, together with C\u00c9SAR (Simp\u00e9e 1902).',
      'DNA parentage analysis has shown that Tressot and GOUAIS BLANC are the parents of GENOUILLET, a local variety of the Indre and Cher d\u00e9partements, and that Tressot is a cross between DURAS and PETIT VERDOT (Bowers et al. 2000; see PINOT pedigree diagram. This parentage of Tressot is hard to believe from a geographical point of view: how could Duras from the Tarn or from Ari\u00e8ge in the Midi-Pyr\u00e9n\u00e9es and Petit Verdot from the Gironde or from the Pyr\u00e9n\u00e9es-Atlantiques in south-west France have had a progeny in the Yonne d\u00e9partement in Burgundy?',
      'The Tressot ampelographic group (see p XXVII) also includes ARBANE, BACHET NOIR and Peurion (the last no longer cultivated; Bisson 2009).',
      'Confusion is caused by various synonyms shared by Tressot and other varieties: GASCON and N\u00c9GRETTE share with Tressot the synonym Noirien, PINOT NOIR shares with Tressot the synonyms Noirien and Morillon Noir.',
      'Two colour mutations of Tressot have been described: Tressot Blanc, which is no longer cultivated, and Tressot Panach\u00e9 (or Tressot Bigarr\u00e9 or Ballon de Suisse), a spectacular chimera (a mutation affecting only some layers of tissue, in this case the berry skin) observed in Girolles in the Yonne as well as in Switzerland that results in some purple-and-green-striped berries.',
    ],
    'where its grown': [
      'Wines are generally powerful, deeply coloured and reasonably alcoholic. There was less than a hectare (2.5 acres) in France in 2008 and only in the Yonne is Tressot allowed in such appellations as Bourgogne and Bourgogne-Chitry \u2013 and then only from vines already in the ground in 2009. Domaine de Cerisiers in the latter appellation is one of the few producers which still has Tressot in the vineyard and they include it in their PINOT NOIR blend.',
    ],
  },
  {
    name: 'TREVISANA NERA',
    synonyms: ['Borgogna', 'Gattera', 'Refosco di Guarnieri'],
    description: 'Minor Veneto vine of mysterious origin.',
    'origin and parentage': [
      'Trevisana Nera is a traditional variety of the area of Feltre in the province of Belluno in the Veneto, northern Italy, where it was first mentioned as recently as the mid twentieth century. Its name suggests it may have been introduced from the neighbouring province of Treviso, but no similarity between Trevisana Nera and the traditional varieties of that area has been found so this origin is unproven.',
      'In 2003, ampelographers of the Conegliano research centre, in the Veneto, found in Castelcucco in the province of Treviso a few vines of an obscure local variety called Gattera or Borgogna which looked very similar to Trevisana Nera (Cancellier et al. 2008). DNA profiling has confirmed that Gattera or Borgogna from Castelcucco as well as Refosco di Guarnieri, cultivated as a table grape in Friuli, are identical to Trevisana Nera (Crespan, Giannetto et al. 2008). This suggests that the cultivation area of Trevisana Nera was once much more extensive than it is now.',
    ],
    'where its grown': [
      'Trevisana Nera is grown almost exclusively in the province of Belluno, between Trento and Udine in the Veneto in northern Italy, especially in the area of Feltre. It is usually blended with other local varieties though a varietal wine could feasibly be made as an IGT Vigneti delle Dolomiti. There were 37 ha (91 acres) planted in Italy in 2000.',
    ],
  },
  {
    name: 'TRIBIDRAG',
    synonyms: [
      'Crljenak Ka\u0161telanski',
      'Krato\u0161ija',
      'Morellone',
      'Pribidrag',
      'Primaticcio',
      'Primitivo',
      'Primitivo di Goia',
      'Primativo',
      'Trebidrag',
      'Uva di Corato',
      'Zagarese',
      'Zinfandel',
    ],
    description:
      'Croatian variety making full-bodied reds, much better known as Primitivo or Zinfandel.',
    'origin and parentage': [
      'Tribidrag is the original and oldest Croatian name for this grape variety from central Dalmacija (Dalmatia) that is much better known today as Primitivo in Puglia in southern Italy and as Zinfandel in California. The respective history and peregrinations of Primitivo and Zinfandel are discussed here before an explanation of how they were found to be identical to each other and then to Tribidrag.',
      'HISTORICAL REFERENCES TO PRIMITIVO The earliest mention of this variety in Puglia dates back to 1799 when Francesco Filippo Indellicati (1767\u20131831), priest of Gioia del Colle and amateur botanist and agronomist, made a note in the Gioia town records that he had found in his vineyard a particularly early ripening variety that he called Primativo, from the Latin primativus, meaning \u2018first to ripen\u2019. Before that, this variety was apparently known as Zagarese (Molon 1906), allegedly named after the city of Zagreb in Croatia, but a similar name (Zagarolese) was also given to CASTIGLIONE in Calabria. Indellicati planted his Primativo in Liponti, a hamlet near Gioia del Colle, and with the help of local growers the variety was later spread to other areas in Puglia (Turi, Cassano delle Murge, San Michele, Noci and Castellana Grotte) around 1820. The spelling Primitivo appeared later, around 1860.',
      'HISTORICAL REFERENCES TO ZINFANDEL The origin of California\u2019s flagship variety has been the subject of much speculation (Piljac 2005). Many an American has argued that Zinfandel is indigenous, notwithstanding the fact that Vitis vinifera L. is not a native species of the Americas. Many others have suggested that Zinfandel was introduced to California in the 1850s by Agoston Haraszthy, a Hungarian immigrant who planted over 300 varieties in different US states after his arrival in 1837. However, the historian Charles L Sullivan (2003) provided evidence that Zinfandel had arrived in California as a nameless vine from the Sch\u00f6nbrunn imperial collection in Wien (Vienna) that contained all the grape varieties grown at that time in the Austrian Empire, and that was well before Haraszthy had set foot on American soil. Indeed, Long Island grape grower George Gibbs introduced this variety to his nursery in the 1820s, and in 1829 the name Zinfardel first appeared in a catalogue of another nursery on Long Island. In the 1830s, the variety appeared in other catalogues under Zinfendal, Zinfindal or Zinfendel. The modern spelling Zinfandel first appeared in 1852 in a letter to the editor of The Horticulturist written by viticulturist John Fisk Allen, and in the 1860s it was agreed that this spelling should be used. Despite some doubtful hypotheses, the exact etymology of Zinfandel remains a mystery.',
      'ZINFANDEL IDENTIFIED WITH PRIMITIVO In 1967, Austin Goheen, a plant pathologist from the United States Department of Agriculture\u2019s Agricultural Research Service (ARS), visited Bari in Puglia, southern Italy, where he happened to taste Primitivo wines that reminded him of those made from Zinfandel. He asked to see the vineyards and was so struck by the resemblance between Primitivo and Zinfandel that he had cuttings sent to the University of California at Davis to plant it next to Zinfandel. In 1972, Goheen confirmed that their morphology was very similar, and in 1975 Wade Wolfe, a PhD candidate at Davis, established that the vines showed identical isozyme patterns (isozymes are a particular type of enzymes formerly used to establish a molecular profile; modern DNA profiling based on microsatellites did not exist at that time). The news that Zinfandel and Primitivo were the same quickly spread and the \u2018battle over Zinfandel\u2019 immediately began (Piljac 2005). In the late 1970s, Puglian producers started selling their Primitivo in the US with the name Zinfandel on the labels, which prompted vexed California Zinfandel producers to protect the Zinfandel heritage in which they had invested effort and money. In 1985, the Bureau of Alcohol, Tobacco and Firearms (BATF) ruled that Zinfandel could not be used as a synonym for Primitivo because of lack of evidence of synonymy. However, the identity between Zinfandel and Primitivo was confirmed by DNA profiling in 1994 by Carole Meredith and her doctoral candidate John Bowers at Davis. As a consequence, in 1999 the European Union granted Italian Primitivo producers the right to use the name Zinfandel. BATF were unsuccessful in the complaint they filed in 2000 in response to this ruling. No agreement has yet been reached.',
      'ZINFANDEL AND PRIMITIVO MISTAKEN FOR PLAVAC MALI A Dalmatian origin for Primitivo has long been suspected, as suggested by the synonym Zagarese that was already in use in the eighteenth century in Puglia. The search for the origins of Zinfandel/Primitivo in Croatia began in the mid 1970s, when Austin Goheen contacted Franco Lamberti from the University of Bari, who had recently visited Croatian vineyards together with Ana \u0160ari\u0107, professor at the University of Zagreb. Lamberti suggested to Goheen that Zinfandel/Primitivo might correspond to a variety grown in Dalmacija and known as Plavac Mali or Plavina (Miro\u0161evi\u0107 and Meredith 2000). In 1979, Goheen had cuttings of PLAVAC MALI sent to him by \u0160ari\u0107, and in 1982 he was able to reject the identity with Zinfandel, helped again with isozyme analysis. Nevertheless, the word had already spread that Zinfandel and Plavac Mali were one and the same, and the rumour was fuelled by Croatians willing to sell Croatian wines in the US under the name Zinfandel, which elicited a second episode of the \u2018battle over Zinfandel\u2019, in which Croatian-born Californian producer Mike Grgich of Grgich Hills actively participated by supporting the identity between Plavac Mali and Zinfandel.',
      'THE \u2018ZINQUEST\u2019 IN CROATIA Encouraged by Mike Grgich, Carole Meredith at the University of California at Davis in 1998 initiated a collaboration with Edi Maleti\u0107 and Ivan Peji\u0107 from the University of Zagreb in order to find out the truth about Zinfandel\u2019s origins in Croatia. Their long search was later nicknamed the \u2018Zinquest\u2019. Together with Jasenka Piljac, Meredith and her Croatian colleagues collected 148 samples of old PLAVAC MALI vines scattered along the Dalmatian coast. Back at the University of California at Davis, none of the DNA profiles of these samples matched that of Zinfandel (Peji\u0107 et al. 2000). In the following years, Maleti\u0107 and Peji\u0107 continued their Zinquest in old Dalmatian vineyards and kept sending \u2018Zinfandel suspects\u2019 to Meredith\u2019s lab, still without any match. Finally, in December 2001, the Zinquest proved successful: a vine sampled in a vineyard owned by Ivica Raduni\u0107 in Ka\u0161tel Novi, north of Split, and known locally as Crljenak Ka\u0161telanski (literally \u2018the red from Ka\u0161tela\u2019) perfectly matched the DNA profile of Zinfandel!',
      'Another nine Crljenak Ka\u0161telanski vines were later found in Raduni\u0107\u2019s vineyard, and this variety was then found in 2002 under the name Pribidrag in the village of Svini\u0161\u0107e near Omi\u0161 south of Split, growing in the garden of a very old lady.',
      'TRIBIDRAG IS THE OLDEST NAME FOR THIS VARIETY Using a particular method developed for the profiling of ancient DNA, Malenica et al. (2011) established that the DNA profile of a ninety-year-old herbarium specimen of Tribidrag, a Croatian autochthonous variety that was mentioned as early as the fifteenth century and later in 1518, 1529 and 1546 as a reputed grape variety in the region of Split (Ambroz Tudor, Nenad Malenica, Edi Maleti\u0107 and Ivan Peji\u0107, personal communications) and has now disappeared from germplasm collections, matched Zinfandel/Primitivo/Crljenak Ka\u0161telanski/Pribidrag. According to the rule of anteriority, whereby the oldest name takes precedence, Tribidrag is adopted here as the prime name for this variety. Most interestingly, the etymology of Tribidrag echoes that of Primitivo: according to Croatian linguist Valentin Putanec (2003), the Croatian word tribidrag(a) comes from the Greek \u03c0\u03c1\u03c9\u03b9 \u03ba\u03b1\u03c1\u03c0o\u03b6, meaning \u2018early ripening\u2019. This perfectly corresponds to the meaning of the Latin primativus that gave Primativo and then Primitivo. La boucle est boucl\u00e9e!',
      'THE PARENTAGE OF PLAVAC MALI ANCHORS TRIBIDRAG IN CROATIA During their \u2018Zinquest\u2019, researchers had suspected a possible parent\u2013offspring relationship between Tribidrag (that they obviously called Zinfandel) and PLAVAC MALI (Peji\u0107 et al. 2000). This relationship was confirmed four years later when DNA parentage analysis established that Plavac Mali is a natural progeny of Tribidrag and DOBRI\u010cI\u0106, another old Dalmatian variety (Maleti\u0107 et al. 2004). In addition, Maleti\u0107 et al. (2004) discovered additional parent\u2013offspring relationships between Tribidrag and at least four other indigenous Dalmatian varieties (PLAVINA, GRK, VRANAC and Crljenak Crni), and Lacombe et al. (2007) have shown that Plavina is in fact a cross between Tribidrag and VERDECA from Puglia (see the pedigree diagram below). These close relationships with several indigenous varieties confirm the Croatian origin of Tribidrag (see PLAVINA entry for the Puglia\u2013Croatia controversy about its birthplace).',
    ],
    'where its grown': [
      'Chiefly because of Zinfandel\u2019s importance in California, this multi-named variety has spread around the world, although, in France, Domaine de l\u2019Arjolle is currently the only producer to have planted this variety and to make a varietal wine, known as Cuv\u00e9e Z.',
      'In Italy, Primitivo was once much more widely planted in Puglia than it is now, due mainly to the financial incentives of the EU vine-pull scheme at the end of the twentieth century: in 1990 there were around 17,000 ha (42,000 acres) compared with 7,951 ha (19,647 acres) ten years later, according to the agricultural census. However, now that it has gained popularity in its own right, rather than as an alcohol-boosting blending partner, this decline in vineyards has largely come to an end. The ovewhelming majority of Italy\u2019s Primitivo vines are in Puglia, specifically the west of the Salento peninsula on the heel of Italy, where the combination of variety and climate produces wines that are generous and rich in fruit and body. Very old bushvines on the red soils over white limestone in the Primitivo di Manduria zone produce some of the most structured and complex examples, but Gioia del Colle with its hills and higher altitude can produce rather more elegant wines. All are high in alcohol, however. Recommended producers include Cannito, Morella, Paololeo and Petrera Fatalone.',
      'According to the ampelographer and viticulturist Edi Maleti\u0107, Crljenak Ka\u0161telanski is the fastest growing variety in Dalmacija since the revelations about Zinfandel and Primitivo described above. From an original find of twenty-two vines, there are now more than 200,000 in Croatia and growing interest among producers. One of the first of the modern varietal wines was Zlatan Plenkovi\u0107\u2019s Crljenak, which comes from a vineyard Plenkovi\u0107 planted near Makarska on the coast between Split and Dubrovnik with the support of Paul Draper of Ridge Vineyards in California (see below) and researchers at the University of Zagreb. Others include Bura-Mokalo.',
      'Under the name Krato\u0161ija, Tribidrag is popular in Montenegro, where Zenta and Buk make varietal wines and Plantaze use it in blends. It has been neglected in the Republic of Macedonia, where it is found in old interplanted vineyards but shows variable and unreliable fertility. Growers are considering importing Primitivo vines from Italy to avoid the laborious process of clonal selection in these old vineyards.',
      'As Zinfandel it is also found in Israel, where American influence is strong.',
      'California is undoubtedly Tribidrag\u2019s \u2013 ie Zinfandel\u2019s \u2013 most successful outpost, previously in sheer volume but now also in terms of quality, even though a large percentage of the fruit will be harvested earlier and without much attention to detail since it is destined for oceans of off-dry \u2018white\u2019 (very pale pink) Zinfandel or blush wine. In 2008 there were 50,354 acres (20,377 ha) of Zinfandel, making it the state\u2019s second most planted red after CABERNET SAUVIGNON. For many years Zinfandel was California\u2019s most planted variety but was often mistreated, planted in overly hot regions and allowed to overproduce. Today there are many insect and disease problems to overcome due to the variety\u2019s tightly packed bunches, including the Willamette mite in the north of the state and the Pacific mite in the San Joaquin Valley, where there were nearly 20,000 acres (8,000 ha) in 2008, together with leafroll virus that may delay ripening and render the fruit suitable only for white Zin (Christensen et al. 2003).',
      'Full-blooded red Zinfandel has been enjoying a revival of interest, not least because of the activities of ZAP, Zinfandel Advocates & Producers, the promotional organization for \u2018America\u2019s Heritage Wine\u2019. The wines tend to have more obvious fruit than those made from CABERNET SAUVIGNON with a strongly spiced berry element \u2013 sometimes too simplistically so in inexpensive, overcropped, oversweet versions \u2013 reminiscent of boysenberries and often with more than a hint of dried fruit (perhaps a result of its characteristic uneven ripening). Ridge Vineyards have championed serious, ageworthy styles of Zin, typically based on some of the state\u2019s oldest vineyards, often field blends and always aged in top-quality American oak, since the 1960s. Their best bottlings can last decades. Many of the oldest Zinfandel vines, all dry farmed and typically gnarled and thick-trunked, were planted and tended by Italian immigrants, typically in Sonoma but also in Napa Valley, the Sierra Foothills and Lodi in the north of the Central Valley, which styles itself the Zinfandel Capital of the World since it grows 40% of California\u2019s total. Louis Martini\u2019s clone from the nineteenth-century Monte Rosso vineyard in Sonoma Valley enjoys special status. Other producers of currently acclaimed serious Zinfandels include Bedrock, Biale, Carlisle, Easton, Martinelli, A Rafanelli, Ravenswood (single-vineyard bottling specifically), Seghesio and Turley.',
      'Because of Zinfandel\u2019s importance in California, and also because so many wine producers in other US states buy grapes from California, Zinfandel is bottled (even if not grown) in just about every state of the Union (even distant, chilly Maine), although only Washington State (89 acres/36 ha) and to an even lesser extent Oregon have much planted. It is also grown in British Columbia, western Canada, and, not surprisingly, in Mexico.',
      'The variety has become quite popular in Australia. Once a speciality of Cape Mentelle in Western Australia, it is now a feature of South Australia with the likes of Rusden and Groom making the most of its suitability for hot, dry climates.',
      'Glen Carlou and Zevenwacht produce slightly lighter varietal Zinfandels in South Africa.',
    ],
  },
  {
    name: 'TRINCADEIRA',
    synonyms: [
      'Black Alicante',
      'Black Portugal',
      'Crato Preto',
      'Mortagua',
      'Mortagua Preto',
      'Rosete Espalhado',
      'Tinta Amarela',
      'Tinta Amarelha',
      'Tinta Manuola',
      'Torneiro',
      'Trincadeira Preta',
    ],
    description:
      'The dark-skinned, widely planted Trincadeira goes under many aliases throughout Portugal. Potentially high-quality reds but not easy to grow.',
    'origin and parentage': [
      'This variety, known under many names throughout Portugal, most likely originates from the Oeste region, north of Lisboa, where it shows the highest degree of clonal diversity (Rolando Faustino, personal communication), although it might also originate in the Alentejo region, east of Oeste. The most widespread and official Portuguese name for this variety is Trincadeira, although Tinta Amarela (meaning yellow-black in reference to the yellow colour of the mature shoots and to the black berries) is an officially authorized and still widely used synonym.',
      'The name Trincadeira, or Trincadeira Preta (preta meaning black), is nevertheless a source of confusion because it is also given to other black- or white-berried varieties (CASTEL\u00c3O, TAMAREZ, TRAJADURA). The synonym Rifete used for this variety in Pinhel is equally confusing because it is also used for RUFETE in the same region. According to recent studies, Trincadeira could be genetically close to RAMISCO from the Colares region and to SERCIAL from Madeira (Almadanim et\nal. 2007). Trincadeira is not related to the light-skinned TRINCADEIRA DAS PRATAS.',
    ],
    'where its grown': [
      'Trincadeira, though not easy to grow, can produce high-quality wines when the yields are moderate and berries are fully mature: wines that are well structured, full-bodied and full of rich, dark fruit spiced up by notes of cinnamon and clove, supported by rounded tannins, so that they can be broached in youth. However, the wine\u2019s structure is also complemented by oak ageing, resulting in wines with greater longevity. Trincadeira makes a good blending partner, eg with Aragones (TEMPRANILLO) in the Tejo or with TOURIGA NACIONAL in the Douro and in D\u00e3o. Although the variety is planted in many parts of Portugal and permitted in many DOCs, with a total vineyard area of 14,220 ha (35,138 acres) in 2010, it is most important in the Alentejo, Douro and Tejo (especially Bairro and Chamusca). Recommended producers in the Alentejo include Cortes de Cima, Altas Quintas and Jo\u00e3o Portugal Ramos.',
    ],
  },
  {
    name: 'TRINCADEIRA DAS PRATAS',
    synonyms: ['Arinto Gordo', 'Boal Prior'],
    description: 'Underrated aromatic variety from central Portugal.',
    'origin and parentage': [
      'Trincadeira das Pratas, first described by Gyr\u00e3o in 1822 in his Tratado theorico e pratico da agricultura das vinhas, originates in the Tejo region in central Portugal (Cunha et al. 2009). It is still often confused with TAMAREZ in the Alentejo, although they are morphologically quite distinct. According to Galet (2000), Trincadeira das Pratas could simply be a synonym for TRINCADEIRA from the Douro but the latter is a black-berried variety, and it cannot be a colour mutation of Trincadeira because they have distinct DNA profiles in Almadanim et al. (2007).',
    ],
    'where its grown': [
      'Planted mainly in the Tejo region, but also north and south of Lisboa in Portugal, Trincadeira das Pratas produces wines that are light in colour but well structured with aromas of fresh herbs, lemon and pear, compatible with barrel fermentation and ageing. Although many producers see little future for this variety and few are planting it, Australian Peter Bright, a consultant in southern Portugal, sees it as a variety with great promise. Recommended producers of blends that include Trincadeira das Pratas: Quinta da Aloma, Fi\u00faza & Bright and the Cartaxo co-op. There were 250 ha (618 acres) in Portugal in 2010.',
    ],
  },
  {
    name: 'TRIOMPHE',
    synonyms: [
      'Kuhlmann 319-1',
      'Triomphe d\u2019Alsace',
      'Triumpf vom Elsass',
      'Triumph d\u2019Alsace',
    ],
    description: 'Slightly foxy-tasting red hybrid planted mainly in the UK.',
    'origin and parentage': [
      'Complex French-American hybrid bred by Eug\u00e8ne Kuhlmann in Alsace in the early twentieth century by crossing Millardet et Grasset 101-14 OP \u00d7 KNIPPERL\u00c9, where Millardet et Grasset 101-14 OP is a seedling of Millardet et Grasset 101-14 (OP stands for open pollination), this latter being a Vitis riparia \u00d7 Vitis rupestris hybrid.',
    ],
    'where its grown': [
      'Little known outside of the UK, where there are 15 ha (37 acres). The deeply coloured wines can taste slightly foxy, revealing their part-American parentage, so it is surprising that the variety has continued to be planted when both DORNFELDER and REGENT produce better results. Also found in the nascent vineyards of Denmark and the Netherlands.',
    ],
  },
  {
    name: 'TRNJAK',
    synonyms: ['Rude\u017eu\u0161a', 'Trnjak Crni'],
    description: 'Minor variety found in both Croatia and Bosnia-Herzegovina.',
    'origin and parentage': [
      'Trnjak is a variety from the Croatian region of Imotski in Dalmacija (Dalmatia) south east of Split and close to the Bosnia-Herzegovina border.',
    ],
    'where its grown': [
      'Trnjak is authorized only in the Dalmatinska Zagora (Dalmatian Hinterland) wine region in Croatia and is found not only in its region of origin, Imotski, south east of Split in Primorksa Hrvatska (Coastal Croatia) but also in Makarska by the coast and in Mostar over the border in Bosnia-Herzegovina. Producers of varietal wines include Imota (Imotski) and Opa\u010dak (Makarska).',
    ],
  },
  {
    name: 'TRONTO',
    synonyms: ['Aglianico di Napoli', 'Tronta'],
    description:
      'Possibly an Aglianico relative from around Amalfi in Campania.',
    'origin and parentage': [
      'Ampelographers had noticed many similarities between Tronto and AGLIANICO and this was confirmed by DNA profiling when Costantini et al. (2005) found that Tronto had a close genetic relationship with Aglianico, which would explain why it is sometimes named Aglianico di Napoli.',
    ],
    'where its grown': [
      'Tronto can still be found in some old vineyards on the Amalfi coast west of Salerno in Campania, southern Italy, in particular in Furore, Positano and Amalfi, where it may be included in the Costa d\u2019Amalfi DOC. It is also occasionally found near Napoli under the name Aglianico di Napoli.',
    ],
  },
  {
    name: 'TROUSSEAU',
    synonyms: [
      'Bastardo',
      'Bastardo',
      'Bastardhino',
      'Carnaz',
      'Godello Tinto',
      'Mar\u00eda Ordo\u00f1a',
      'Mar\u00eda Ordo\u00f1ez',
      'Maturana Tinta',
      'Merenzao',
      'Roibal',
      'Tinta',
      'Tintilla',
      'Tinta Lisboa',
      'Trouss\u00e9',
      'Trousseau Noir',
      'Troussot',
      'Verdejo',
      'Verdejo Negro',
      'Verdejo Tinto',
    ],
    description:
      'Demanding variety making powerful, ageworthy wines and having a complex geographical distribution and many aliases, including Bastardo.',
    'origin and parentage': [
      'Trousseau is a variety from the Jura in eastern France, once widely cultivated in the regions of Poligny and Besan\u00e7on, where it was first mentioned in 1732 under the spelling Troussot. Trousseau has often been confused with TRESSOT from the Yonne d\u00e9partement, undoubtedly because Trousseau was often spelt Tressau or Tresseau in the past, as in the commune of Poligny in 1774 (R\u00e9zeau 1997): \u2018Plants de tressaux, bon pour le vin, mediocre \u00e0 manger\u2019 (\u2018Tressaux vines, good for wine, mediocre for eating\u2019). The name Tresseau is, rather unconvincingly, said to derive from the French word trouss\u00e9 (\u2018trussed\u2019), an old synonym used in Salins-les-Bains, because of the pyramid shape of the bunch (R\u00e9zeau 1997).',
      'DNA profiling has established that Trousseau has been cultivated in Spain and Portugal for at least two centuries under different names such as Bastardo, Merenzao and Verdejo Negro (Gago et al. 2009), and under the name Maturana Tinta (F\u00e9lix Cabello and Victor de la Serna, personal communications), but it is not known how it reached the Iberian peninsula. The most ancient and widespread name is Bastardo (\u2018bastard\u2019), first mentioned in 1531 by Rui Fernandes in his \u2018Descrip\u00e7\u00e3o do terreno em roda da cidade de Lamego duas leguas\u2019 and described in 1790 by Rebello da Fonseca, who tentatively suggested it was the same variety as PINOT NOIR (d\u2019Oliveira 1903).',
      'Trousseau belongs to the Salvanien ampelographic group (see p XXVII; Bisson 2009), making it unlikely that this variety originated in Iberia. This is confirmed by DNA parentage analysis, which strongly suggests that Trousseau (under the name Merenzao), CHENIN BLANC and SAUVIGNON BLANC are siblings (Santana et al. 2010; Myles et al. 2011), and that Trousseau has a likely parent\u2013offspring relationship with SAVAGNIN (Myles et al. 2011), as shown in the PINOT pedigree diagram.',
      'The colour mutation Trousseau Gris was once significant in the Charente and is cultivated in California under the name Gray Riesling. Trousseau has sometimes and confusingly been called S\u00e9millon Rouge (see S\u00c9MILLON).',
      'Trousseau was used to breed BASTARDO MAGARACHSKY and ROYALTY.',
    ],
    'where its grown': [
      'In France, Trousseau is one of Jura\u2019s two main dark-skinned indigenous varieties but represents only about 5% of the vineyard area because it requires more sun than other varieties and prefers warm gravel or marl soils. It is more deeply coloured and robust than the pale POULSARD, producing wines that are powerful, warm and structured, but Trousseau wines can lack colour if yields are not kept in check. Although it is one of the principal varieties authorized for red and ros\u00e9 wines in the Arbois and C\u00f4tes du Jura appellations as well as for the fortified Macvin du Jura and sparkling Cr\u00e9mant du Jura, there were no more than 172 ha (425 acres) in France in 2009, little changed over the last few years. Recommended producers include Lucien Aviet, Daniel Dugois, Jacques Puffeney and Domaine Andr\u00e9 and Mireille Tissot in the Arbois appellation; Domaines Ganevat and Pignier in the C\u00f4tes du Jura.',
      'In Galicia, north-west Spain, the variety goes under the names Merenzao, Mar\u00eda Ordo\u00f1a or Bastardo. Although it is technically one of the principal varieties authorized for reds from the Ribeira Sacra and Valdeorras DOs, along with the more well-known MENC\u00cdA, only 12 ha (30 acres) remained in 2008. Algueira and A Tapada produce varietal wines, as do Quinta da Muradella, using the synonym Bastardo. Listed separately for Spain are the 70 ha (173 acres) of Verdejo Negro in Asturias, north east of Galicia. Here it is one of the principal varieties in the little-known Vino de la Tierra de Cangas. In the Tierra de Le\u00f3n appellation, Gordonzillo have planted Merenzao in their trial vineyard, producing peppery-flavoured grapes.',
      'Under the name Maturana Tinta, the variety has been provisionally included in the official Spanish list of commercial varieties as a separate entry and is authorized in the Rioja DO. Plantings are very limited indeed but Ijalba and Valdemar have both produced intense, oak-aged varietal versions that show the variety\u2019s fresh acidity and deep fruit flavours.',
      'The variety is much more common in Portugal, which has 1,218 ha (3,101 acres) of Trousseau, known here as Bastardo. Most are in the Douro, where it is one of the lesser port varieties and generally found in old mixed vineyards, in D\u00e3o and in Beiras. There are tiny plantings on the islands of Madeira and Porto Santo, mostly in experimental vineyards, although it was at one time much more widely used in the production of Madeira, sometimes even as a varietal wine. Unfortified varietal wines are typically pale in colour, high in alcohol but low in acidity, although some early-picked fruit has shown the potential for ros\u00e9.',
      'Bastardo Tinto is a distinct and far less widely planted Portuguese variety and should not be confused with Bastardo/Trousseau.',
      'Trousseau has recently been identified in an old vineyard in Russian River Valley, California, and in the same appellation it\u2019s included in Acorn Vineyards\u2019 old-vine Zinfandel bottling that includes fruit from a field blend. The variety is mentioned in the California grape acreage report, under both Bastardo and Trousseau, but no acreage is given, suggesting the area is negligible. Several North American producers include Bastardo in the blend of their port-style fortified wines, including Heitz in Napa Valley and Renwood in Sierra Nevada.',
      'Trousseau Gris used to be planted throughout California but is now generally found only in old interplanted vineyards. However, Fanucchi Vineyards in the Russian River Valley in Sonoma, California, planted an entire Trousseau Gris block in 1981 and make a very successful varietal wine, which Peter Fanucchi describes as having a crisp, clean, flowery bouquet with flavours of fresh peaches, honeysuckle, pear, melon, delicate spice, tropical fruit .\u00a0.\u00a0.',
      'In Oregon, Abacela in the Umpqua Valley have some Bastardo planted. And a variety called Bastardo is also planted in Krym (Crimea), Ukraine, and in the  Republic of Moldova.',
    ],
  },
  {
    name: 'TSIMLYANSKY CHERNY',
    synonyms: ['Tsimlyansky'],
    description:
      'Convincing, if rather tannic, Russian variety used to make various styles of red.',
    'origin and parentage': [
      'Tsimlyansky Cherny probably comes from the Rostov wine region in south-western Russia, near the border with Ukraine.',
    ],
    'where its grown': [
      'Tsimlyansky Cherny is grown in the Vedernikov and Tsimlyansky wine regions along the River Don north east of Rostov-on-Don in Russia (170 ha/420 acres in 2010 although this figure includes PLECHISTIK) and also in Krasnodar further south and west. Tsimlyansky Winery use it for sweet sparkling wines and Vedernikov Winery make a tannic dry red with flavours of dried plum, black pepper and leather. At Fanagoria winery in Krasnodar it makes slightly dusty, confident wines with good structure and flavours that locals describe as blackthorn, smoked cherry and black fruits but reminds foreign visitors of BARBERA. It is also used in dessert and fortified blends \u2013 often with Plechistik and KRASNOSTOP ZOLOTOVSKY \u2013 as well as for ros\u00e9.',
    ],
  },
  {
    name: 'TSITSKA',
    synonyms: ['Shanti', 'Tsitsiko'],
    description:
      'High-acid Georgian variety used to make still and sparkling wines.',
    'origin and parentage': [
      'Tsitska comes from Kolkheti, western Georgia, like TSOLIKOURI and USAKHELOURI.',
    ],
    'where its grown': [
      'Tsitska is found mainly in the villages of Sviri, Dimi, Persati and Obcha in the Imereti region of Georgia. It produces both high-quality dry table wine \u2013 on its own or blended with other varieties such as TSOLIKOURI \u2013 and, thanks to its high acidity (often 9\u201312 g/l at harvest), is well suited to the production of sparkling wine. Fruit flavours tend to be in the melon-apple-pear spectrum. Recommended producers of varietal wines include Imeretian Wines and Khareba. There were 2,783 ha (6,877 acres) of Tsitska in Imereti in 2004.',
    ],
  },
  {
    name: 'TSOLIKOURI',
    synonyms: ['Melqos Tsolikouri', 'Obchuri Tsolikouri', 'Tsolikoouri'],
    description: 'Versatile, widely planted Georgian variety.',
    'origin and parentage': [
      'Tsolikouri comes from Kolkheti, western Georgia, like TSITSKA and USAKHELOURI.',
    ],
    'where its grown': [
      'Tsolikouri is grown predominantly in the villages of Dimi, Baghdati, Sviri and Tvishi in Georgia. It is the sole variety responsible for the semi-sweet whites of the Tvishi appellation and, blended with TSITSKA (and sometimes KRAKHUNA), adds fruit and body to the dry wines of the Sviri appellation. Dry Tsolikouri wines tend to be full-bodied and to have soft acidity and flavours of quince or pear. The variety also shows good potential for sparkling wine blends despite the moderate acidity. Teliani Valley make a good varietal example. There was a total of 5,787 ha (14,300 acres) in Georgia in 2004, the vast majority in Imereti but also in Samegrelo and Racha-Lechkhumi.',
    ],
  },
  {
    name: 'TSULUKIDZIS TETRA',
    synonyms: ['Rachuli Tetra', 'Racthuli Tetri', 'Tsulukidze Tetra'],
    description:
      'Minor but productive Georgian variety making both dry and sweet whites.',
    'origin and parentage': [
      'Tsulukidzis Tetra comes from the Racha-Lechkhumi region of north-west Georgia.',
    ],
    'where its grown': [
      'Tsulukidzis Tetra, used to make both semi-sweet and dry white wines, is found to a limited extent in Racha-Lechkhumi in north-west Georgia, particularly around the villages of Sadmeli and Chorjo. The wines are crisp, with moderate alcohol and flavours of ripe peach and pale-skinned cherry. Producers include Rachuli Wine and Bugeuli, although the former use the synonym Rachuli Tetra.',
    ],
  },
  {
    name: 'TUR\u00c1N',
    synonyms: ['Agria', 'Bikav\u00e9r 13', 'Eger 208'],
    description:
      'Dark-skinned, red-fleshed Hungarian variety valued mainly for its colour.',
    'origin and parentage': [
      'A Bikav\u00e9r 8 \u00d7 G\u00e1rdonyi G\u00e9za cross obtained in 1964 by J\u00f3zsef Csizmazia and L\u00e1szl\u00f3 Bereznai in Hungary, in which Bikav\u00e9r 8 is a TEINTURIER \u00d7 KADARKA cross and G\u00e1rdonyi G\u00e9za is a MENOIR \u00d7 CSABA GY\u00d6NGYE cross.',
    ],
    'where its grown': [
      'Tur\u00e1n produces deeply coloured wines with fine tannins, good acidity and flavours of rose, rosehip and dark forest fruits. It is sometimes blended with varieties such as KADARKA. The majority of Hungary\u2019s 178 ha (420 acres) are planted in Eger, although it is also found in the neighbouring M\u00e1tra region. Recommended producers of varietal wines include Tam\u00e1s Szecsk\u0151 in M\u00e1tra and Csaba Demeter in Eger. Imre Kal\u00f3, also in Eger, makes the finest examples, sometimes even an Asz\u00fa-style sweet wine, but they are sold only at the cellar door.',
      'Under the synonym Agria, this variety somehow found its way across the Atlantic to Canada\u2019s British Columbia (7.5 acres/3 ha in 2008) and thence south to Washington State in the US, but not in sufficient quantities to make it into the official statistics. Marley Farm in BC and Glacier Peak in WA both make varietal wines.',
    ],
  },
  {
    name: 'USAKHELOURI',
    synonyms: ['Okhureshuli', 'Okourechouli'],
    description: 'Georgian variety producing semi-sweet, aromatic reds.',
    'origin and parentage': [
      'Usakhelouri comes from Kolkheti, western Georgia, like TSITSKA and TSOLIKOURI. The synonym Okhureshuli is taken from the name of a village in the Racha-Lechkhumi region. According to Chkhartishvili and Betsiashvili (2004), it is botanically and genetically close to the wild grapevines of the area.',
    ],
    'where its grown': [
      'Usakhelouri, used to produce smooth, richly flavoured, rose-scented, semi-sweet (and very occasionally dry) red wines, is grown around the villages of Zubi and Okhureshi in the Racha-Lechkhumi region of north-west Georgia. Recommended producers include Khareba, Paata Sharashenidze and Telavi Wine Cellar.',
    ],
  },
  {
    name: 'UVA DELLA CASCINA',
    synonyms: ['Uva Cascina'],
    description:
      'Rare, perfumed, recently rescued Oltrep\u00f2 Pavese variety.',
    'origin and parentage': [
      'Uva della Cascina, meaning \u2018grape of the farm\u2019, is an ampelographic rarity of unknown origin, cultivated in the Oltrep\u00f2 Pavese.',
    ],
    'where its grown': [
      'Uva della Cascina was considered to be extinct but was recently rediscovered by adventurous local growers such as Fortesi in Rovescala due south of Milano in the province of Pavia, Lombardia, in Italy. Initially their experimental varietal wine was available for tasting only at the cellar.',
      'Uva della Cascina could not be included in the Italian national register of varieties because of the possible confusion with the Tuscan village of C\u00e0scina. It is cultivated mainly in the Valle Staffora in Pavia and is usually blended with other local grape varieties. Cabanon\u2019s Vino del Bosco (IGT Provincia di Pavia) combines at least three ancient varieties, Uva della Cascina, UVA RARA and Ughetta (ie VESPOLINA), and Fattoria Mondo Antico\u2019s Sinodo (Oltrep\u00f2 Pavese DOC) is a blend of BARBERA, CROATINA, Uva della Cascina and MORADELLA. Owner Dario Tiraboschi is planning to produce a varietal wine and describes it as perfumed and full-bodied.',
    ],
  },
  {
    name: 'UVA LONGANESI',
    synonyms: ['Burs\u00f4n', 'Longanesi', 'Negretto Longanesi'],
    description:
      'Hardy Emilia-Romagnan named after the family that rescued it.',
    'origin and parentage': [
      'This variety is said to have been cultivated incognito in the neighbourhood of Bagnacavallo, west of the city of Ravenna in Emilia-Romagna, for at least two centuries. It was almost extinct at the end of the nineteenth century and has only recently been officially named after the Longanesi family who rescued it from extinction.',
      'In 1913, Antonio Longanesi bought a property in Bagnacavallo and found an old vine thriving in the neighbourhood, climbing on an oak tree. Given the remarkable hardiness of the plant and the resistance to fungal diseases of its thick-skinned small berries, Longanesi successfully made a house wine that was much appreciated by the locals. The vine was propagated for Antonio Longanesi and his friends, and in 1956 his son Aldo Longanesi planted the first vineyard of this variety. In 1972, one year after Aldo\u2019s death, his son Pietro tentatively named this vine Uva Longanesi in honour of his forefathers. Pietro\u2019s son Daniele, who took over the winery in 1995, obtained official recognition for Uva Longanesi in 1999 by its inclusion in the national register of varieties. Its synonym Burs\u00f4n, meaning \u2018big bag\u2019 in the local dialect, was Antonio Longanesi\u2019s nickname.',
      'Recent DNA profiling at the San Michele all\u2019Adige research centre has established that Uva Longanesi is not identical to any other known grape variety (Stella Grando and Jos\u00e9 Vouillamoz, unpublished data).',
    ],
    'where its grown': [
      'Remarkably, from a single vine, Uva Longanesi today covers 200 ha (494 acres) in the area of Bagnacavallo and Faenza, west and south west of Ravenna in Emilia-Romagna in north-central Italy, giving sometimes rich wines with slightly rustic or bitter tannins. According to local regulations, varietal wines, which qualify as IGT Ravenna, are divided into two categories: the Etichetta Nera (\u2018black label\u2019), signifying a more ambitious version (minimum twenty months in oak and a proportion of dried grapes), and the less oaked and less ambitious Etichetta Blu, which may be made using the technique of carbonic maceration to soften the tannins. Producers include Cantine Ercolani and the eponymous Daniele Longanesi.',
    ],
  },
  {
    name: 'UVA TOSCA',
    synonyms: ['Montanara'],
    description: 'Minor Emilia-Romagnan variety that has moved downhill.',
    'origin and parentage': [
      'Uva Tosca was first described by Tanara (1644) in Emilia-Romagna, which may be its place of origin, as a grape variety giving a \u2018reddish wine, not very sweet, spicy, elegant and very healthy\u2019. Despite its name, the variety does not come from Toscana because, as Cipriani et al. (2010) have recently suggested through DNA parentage analysis, Uva Tosca may be a Crepallocchi \u00d7 SCHIAVA GROSSA natural cross (see Schiava Grossa for the pedigree diagram), the former being an obscure black-berried variety of unknown origin, the latter a variety from the Alto Adige that has spread to Lombardia. Another study has also suggested a possible parent\u2013offspring relationship between Uva Tosca and LAMBRUSCO MONTERICCO (Boccacci et al. 2005), which would then have to be a progeny of Uva Tosca.',
    ],
    'where its grown': [
      'Uva Tosca used to be cultivated between 700 and 900 m above sea level in the Emilia-Romagnan stretch of the Appenines in Italy, where no other red variety could ripen. Today the vineyard area at this cool altitude has declined dramatically, whereas it has increased in the lower-altitude provinces of Ravenna, Modena (Zocca, Polinago) and Reggio Emilia (Ligonchio, Carpineti). However, overall plantings are down since 1990 and total plantings in Italy in 2000 were 115 ha (284 acres). Uva Tosca is the only variety grown in the Val di Secchia. It is generally used as a blending component.',
    ],
  },
  {
    name: 'UVALINO',
    synonyms: ['Cunaiola', 'Freisone', 'Lambrusca', 'Lambruschino'],
    description: 'Recently resurrected Piemontese.',
    'origin and parentage': [
      'This variety has various names even within the Piemonte region (see list of synonyms above) and it is known as Uvalino, apparently meaning \u2018small grape\u2019 but most likely derived from uvario (dialect for a blend of minor-quality wines), only in the Asti region. It was first mentioned by Gallesio in 1831 as a variety cultivated in Nizza Monferrato and Castelletto Molina south east of Asti (Torello Marinoni et al. 2006). DNA profiling has shown that it is most likely that Uvalino has a parent\u2013offspring relationship with Neretto di Marengo (Torello Marinoni et al. 2006), an almost extinct Piemontese variety.',
    ],
    'where its grown': [
      'There were at one time scattered plantings of Uvalino throughout Piemonte in north-west Italy, mainly in the provinces of Asti, Alessandria and Tortona, but the variety was in decline until Mariuccia Borio, owner of Cascina Castl\u00e8t in Costigliole d\u2019Asti, between Alba and Asti, met Renato Ratti, who had planted a small vineyard of Uvalino at his Villa Pattono winery in La Morra near Barolo. Uvalino reminded Borio, like Proust\u2019s memory-stirring madeleine, of a precious family wine, and she started to make a determined effort in the early 1990s to save Uvalino from oblivion, in collaboration with Lorenzo Corino, head of the Institute of Experimental Viticulture in Asti. After years of historical research and vinification experiments, Uvalino was included in the official register of varieties in 2002. Today Cascina Castl\u00e8t is one of the very few producers of varietal Uvalino, which is described as having intense aromas of ripe red fruits and sweet spices plus notable tannins and good acidity.',
    ],
  },
  {
    name: 'V\u00c1H',
    synonyms: ['CAAB 3/13'],
    description:
      'Recently authorized Slovakian cross that needs a good site to prosper.',
    'origin and parentage': [
      'V\u00e1h, named after the longest river wholly within Slovakia, a tributary of the Dunaj (Danube), is a CASTETS \u00d7 ABOURIOU cross obtained in 1976 by Dorota Posp\u00ed\u0161ilov\u00e1 at the VSSVVM research centre for oenology and viticulture in Modra, Slovakia, and authorized in 2011. It is a sibling of HRON, NITRANKA and RIMAVA.',
    ],
    'where its grown': [
      'Wines are deeply coloured, full-bodied, with a marked capsicum flavour reminiscent of some CABERNET SAUVIGNONs that can improve with time in bottle. Although the variety is not yet widely planted in Slovakia (5 ha/12 acres in 2011), a few producers such as Karol Brani\u0161 have begun making varietal wines. Ideally it should be grown in the best sites in the Ju\u017enoslovensk\u00e1 and Malokarpatsk\u00e1 wine regions in the south west of the country.',
    ],
  },
  {
    name: 'VALDIGUI\u00c9',
    synonyms: ['Cot de Cheragas', 'Gros Auxerrois', 'Valdiguier'],
    description:
      'High-yielding rather ordinary variety from south-west France once popular in southern France and California but now in continuing decline.',
    'origin and parentage': [
      'Valdigui\u00e9, from south-west France, seems to be a fairly recent variety, not mentioned until 1884 (R\u00e9zeau 1997): \u2018le Valdiguier, c\u00e9page encore peu connu, qui para\u00eet m\u00e9riter une attention sp\u00e9ciale\u2019 (\u2018Valdiguier, still little-known grape variety that seems to deserve special attention\u2019). Three distinct and equally uncertain hypotheses have been proposed for the origin of this strange grape name (Galet 1990):',
      '\u2013\u00a0\u00a0\u00a0In Puylaroque near Montauban in the Tarn-et-Garonne, a wine grower named Vald\u00e9guier (1745\u20131817) could have propagated this variety that grew in his courtyard from a random seed, possibly a M\u00c9RILLE seedling.',
      '\u2013\u00a0\u00a0\u00a0Still in Puylaroque around 1845, Jean-Baptiste Valdigui\u00e9 (?\u20131864) may have noticed this variety among others in a neighbour\u2019s vineyard and then planted it in a vineyard in Tressens.',
      '\u2013\u00a0\u00a0\u00a0In Aujols in the Lot, a vine of this variety was noticed in the ruins of the Templiers monastery by a worker named Guillaume Valdiguier.',
      'Valdigui\u00e9 is a member of the Cot ampelographic group (see p XXVII; Bisson 2009). Its supposed parent\u2013offspring relationship with M\u00c9RILLE (Galet 1990) has not yet been confirmed by DNA analysis. It was used to breed MELNIK 82 and RANNA MELNISHKA LOZA.',
    ],
    'where its grown': [
      'Although Valdigui\u00e9 was once widely planted in southern France (4,908 ha/12,128 acres in 1958), thanks in particular to its high yields and tolerance of powdery mildew, there remained just 145 ha (358 acres) in 2008, mostly in the Tarn, Languedoc and Provence. It became popular in California during Prohibition for the same reasons and plantings reached a high of 6,118 acres (2,476 ha) in 1977, though they have since declined dramatically to 316 acres (128 ha) in 2008. Originally misnamed Napa Gamay, it was identified as Valdigiu\u00e9 by Pierre Galet in 1980. Official statistics still refer to it as Gamay (Napa), as distinct from Gamay Beaujolais, incidentally, even though its approved name is now Valdigui\u00e9. Wines are generally bright and well coloured, with only moderate alcohol. They have slightly astringent tannins, countered by carbonic maceration by some California producers. It is also used to produce everyday ros\u00e9s. Recommended producers include Edmunds St John and J Lohr in California and Brick House in Oregon. Oliver of Indiana also produce a varietal Valdigui\u00e9.',
    ],
  },
  {
    name: 'VALENTINO NERO',
    synonyms: ['Incrocio Dalmasso 16/8'],
    description:
      'Very minor Veneto cross whose true parentage has only recently been revealed.',
    'origin and parentage': [
      'The Italian vine breeder Giovanni Dalmasso announced that Valentino Nero was a NEBBIOLO \u00d7 DOLCETTO cross obtained in 1936 at the Conegliano research centre, in the Veneto, northern Italy. It was included in the official register of Italian varieties in 1977. DNA profiling has recently shown that the Nebbiolo used by Dalmasso was in fact Nebbiolo di Dronero, which is not Nebbiolo but a synonym for CHATUS (Torello Marinoni, Raimondi, Mannini and Rolle 2009). This makes Valentino Nero a Chatus \u00d7 Dolcetto cross, just like its siblings PASSAU and SAN MARTINO.',
    ],
    'where its grown': [
      'Valentino Nero is grown to a very limited extent in Piemonte, north west Italy.',
    ],
  },
  {
    name: 'VALIANT',
    synonyms: [
      'South Dakota 72S15',
      'South Dakota NF7-121',
      'South Dakota SD7-121',
    ],
    description:
      'Very winter-hardy, foxy American hybrid best suited to juice, jelly and the table.',
    'origin and parentage': [
      'A FREDONIA \u00d7 South Dakota 9-39 hybrid obtained in 1967 by R M Peterson at the South Dakota Agricultural Experiment Station in Brookings, in which South Dakota 9-39 is a Vitis riparia seedling collected near Culbertson, Montana. This Vitis labrusca, Vitis riparia and Vitis vinifera hybrid was selected in 1972 and released in 1997.',
    ],
    'where its grown': [
      'Although the South Dakota Agricultural Experiment Station class this as a variety for table, juice and jelly, it is being used to a limited extent for winemaking in South Dakota, Minnesota (28 acres/11 ha in 2007), Iowa and other parts of the Midwest, eg by Carlos Creek in Minnesota, Table Mountain in Wyoming, Point of View in North Dakota, Threefold Vine in Michigan and Phoenix in Missouri. The wines are often part of a blend or made as a light ros\u00e9. They inherit the CONCORD flavour of the FREDONIA parent but also the high sugar and deep colour typical of Vitis riparia.',
    ],
  },
  {
    name: 'VAN BUREN',
    synonyms: ['Gladwin 3000'],
    description: 'Old, low-acid American hybrid used mostly for the table.',
    'origin and parentage': [
      'A FREDONIA \u00d7 Worden hybrid obtained in the early twentieth century by Fred E Gladwin in the Department of Pomology and Viticulture at the New York State Agricultural Experiment Station, Geneva, in which Worden is a selected seedling of CONCORD, which makes Van Buren a complex Vitis labrusca and Vitis vinifera hybrid. It\u2019s not known when the cross was made but it bore its fifth crop in 1936. It was introduced to the market in 1935.',
    ],
    'where its grown': [
      'Low acidity makes Van Buren more suited to table grapes than to wine production but Forks of Cheat in West Virginia make a sweet, fruity varietal red. The variety can also be found in Ontario, Canada.',
    ],
  },
  {
    name: 'VANDAL-CLICHE',
    synonyms: [
      'Cliche',
      'Cliche 8414',
      'Cliche Blanc',
      'Vandal 84-14',
      'Vandal Blanc',
      'Vandal Cliche',
    ],
    description:
      'Hardy Canadian complex hybrid used for a wide range of wine styles.',
    'origin and parentage': [
      'A Vandal 63 \u00d7 Vandal 163 hybrid bred after 1946 by Joseph O Vandal (1907\u201394) at Laval University in Qu\u00e9bec, Canada, specifically for cold-climate regions, in which:',
      '\u2013\u00a0\u00a0\u00a0Vandal 63 is a Prince of Wales \u00d7 Vitis riparia hybrid, where Prince of Wales is a Vitis vinifera table grape from the United Kingdom',
      '\u2013\u00a0\u00a0\u00a0Vandal 163 is an AURORE \u00d7 CHANCELLOR hybrid.',
      'Vandal-Cliche was developed by Mario Cliche, professor of horticulture at the Qu\u00e9bec Institut de Technologie Agroalimentaire, hence the name. Vandal-Cliche is an extremely complex Vitis riparia, Vitis labrusca, Vitis vinifera, Vitis aestivalis, Vitis lincecumii, Vitis rupestris and Vitis cinerea hybrid.',
    ],
    'where its grown': [
      'Vandal-Cliche is used in eastern Canada to make varietal wines in a range of styles, dry to sweet as well as sparkling or semi-sparkling, but it needs to be harvested quite early to avoid an excess of foxy labrusca character and its thin skins and weak stems make it unsuitable for late-harvest wines or icewines. Varietal wines are generally fresh, crisp and light, often marked by flavours of lemon peel. Qu\u00e9bec producers include Carone, Les C\u00f4tes du Gavet, Au Jardin d\u2019Emmanuel, Les Murmures, Moulin du Petit Pr\u00e9, La Rivi\u00e8re du Ch\u00eane and Domaine Royarnois (the commercial pioneer of this variety), among others. Plantings in Qu\u00e9bec are unlikely to increase, because of the variety\u2019s susceptibility to disease.',
    ],
  },
  {
    name: 'VASILAKI',
    synonyms: ['Alt\u0131nta\u015f'],
    description:
      'Low-acid but potentially high-quality white wine grape from the Turkish island of Bozcaada.',
    'origin and parentage': [
      'Vasilaki, often called Alt\u0131nta\u015f (literally \u2018golden stone\u2019), is a variety from the island of Bozcaada in the Aegean, south west of the port of \u00c7anakkale in Turkey. When the island belonged to Greece (and was called T\u00e9nedos), the variety was used widely to make both dry and sweet wines.',
    ],
    'where its grown': [
      'Grown mainly on the island of Bozcaada, just off Turkey\u2019s western coast, Vasilaki is a low-acid variety and wines need to include some early-picked fruit to remain fresh. They have a tendency to oxidize and age quickly unless the lees are retained during vinification but they can also have interesting aromas of citrus, orange peel and sometimes clove, with a slight bitterness on the finish. Corvus produce both dry and passito varietal wines and also make a blend with \u00c7AVU\u015e, occasionally with SAUVIGNON BLANC. Other producers on Bozcaada include Talay, Yunat\u00e7\u0131lar and G\u00fclerada. Total plantings in Turkey in 2010 were just 4 ha (10 acres).',
    ],
  },
  {
    name: 'VB 91-26-4',
    synonyms: ['VB 91-26-04'],
    description:
      'Minor and usefully disease-resistant Swiss hybrid found to a very limited extent in northern Europe.',
    'origin and parentage': [
      'VB 91-26-4 is a hybrid obtained in 1991 by Valentin Blattner (hence VB) in Soyhi\u00e8res in the Swiss Jura by crossing CABERNET SAUVIGNON with an undisclosed disease-resistant variety (Resistenzpartner in German).',
    ],
    'where its grown': [
      'In Switzerland, only Geiger in Sankt Gallen make wine from VB 91-26-4 on less than half a hectare (1 acre) but the variety is also found in Belgium, where the Domaine du Ry d\u2019Argent use VB 91-26-4 in a blend, and in the Netherlands, where Wijnboerderij De Gravin make a varietal VB 91-26-4 wine, described as similar to CABERNET FRANC.',
    ],
  },
  {
    name: 'VERDEA',
    synonyms: ['Colombana Bianca', 'Colombana di Peccioli'],
    description:
      'Ancient Tuscan white, now a Lombardia speciality, used to make a range of wine styles.',
    'origin and parentage': [
      'Verdea is a very old variety from Toscana, where it was mentioned by Pietro de Crescenzi in 1303, and then later by Soderini (1600), who described it as a \u2018vitigno apprezzatissimo\u2019 (\u2018much appreciated grape variety\u2019).',
      'Surprisingly, DNA profiling has revealed that a black-berried variety called Sangiovese Forte is in fact identical to Verdea (Di Vecchi Staraz, This et al. 2007), which would mean that Sangiovese Forte is a colour mutation of Verdea. However, this is questionable because the DNA profile of Sangiovese Forte in Filippetti et al. (2005) is identical to that of Sangiovese.',
    ],
    'where its grown': [
      'Verdea is cultivated mainly in Lombardia in northern Italy, in particular in the area of San Colombano al Lambro, between the cities of Milano and Piacenza, and also further south in the province of Piacenza in Emilia-Romagna and in the province of Pisa in Toscana. Until the 1940s, Verdea was used together with Malvasia Bianca (generally MALVASIA BIANCA LUNGA), BESGANO BIANCO and Pizzutello (no longer cultivated) to produce Vino Santo in the Val Trebbia between the cities of Genova and Piacenza and in the Val Nure near Piacenza. There were 152 ha (376 acres) in Italy in 2000 (though some of these may be grown for the table).',
      'Panizzari make one of the very few dry varietal wines, fresh and delicate and classified as IGT Colline del Milanese. Nettare dei Santi produce not only a still dry wine (including 10% RIESLING) but also a sweet passito-style dessert wine and a dry fizz. Cantina Pietrasanta\u2019s Verdea also includes 15% Riesling.',
    ],
  },
  {
    name: 'VERDECA',
    synonyms: [
      'Alvino Verde',
      'Lagorthi',
      'Verdera',
      'Verdicchio Femmina',
      'Verdisco Bianco',
      'Verdone',
      'Vino Verde',
    ],
    description:
      'Regarded as light, neutral and declining in Puglia but, as Lagorthi, in tantalizingly short supply in Greece.',
    'origin and parentage': [
      'Verdeca is a traditional variety in Puglia in southern Italy. DNA profiling has established that PLAVINA, widely planted in Croatia, is a cross between Verdeca and TRIBIDRAG (Lacombe et al. 2007), this latter being the original Croatian name for Primitivo and Zinfandel. This parentage would suggest that Verdeca could originate in Croatia, where it has never been mentioned. However, DNA profiling has shown, surprisingly, that Verdeca is identical to Lagorthi (Vouillamoz), a Greek variety from Kal\u00e1vrita in the Aigi\u00e1leia mountains in the Pelop\u00f3nnisos, where it is still grown to a very limited extent, as well as on the Ionian Islands. Therefore, it is also possible that Verdeca could have been brought from Greece to Puglia.',
    ],
    'where its grown': [
      'The main cultivation area of Verdeca still corresponds to its historical distribution in the provinces of Taranto and Bari in Puglia, southern Italy. It is the dominant variety in Locorotondo and Martinafranca DOC blends and may play a supporting role in Gravina, Ostuni and San Severo DOCs. It is also found occasionally in Basilicata, as well as in Campania\u2019s Lacryma Christi del Vesuvio DOC, and is used to produce vermouth, thanks to its relatively neutral flavour profile. There were 2,265 ha (5,597 acres) in Italy in 2000 but the vineyard area declined considerably in the last thirty years of the twentieth century (down from nearly 8,000 ha/19,768 acres in 1970) and is expected to continue to do so. Recommended varietal wines from Puglia include Leone de Castris\u2019 Messapia, I P\u00e0stini\u2019s Faraone and Cantine Rivera\u2019s Vivace, a light, fizzy version.',
      'Under the name Lagorthi, this variety is highly regarded but little cultivated in Greece. It was Konstantinos Antonopoulos, based in P\u00e1tra in the northern Pelop\u00f3nnisos, who saw the potential of this variety in the late 1980s, planted high up at 600\u2013900 m above sea level, but even now Antonopoulos don\u2019t have enough vines to make a varietal Lagorthi. Instead it is the dominant variety in their Adoli Ghis blend. Oenoforos make a varietal wine, also in P\u00e1tra; there are limited plantings on the Ionian Islands and more recent plantings in Makedon\u00eda in northern mainland Greece. Wines from Lagorthi tend to have low to medium alcohol (particularly planted at this height), moderate to fresh acidity and a soft and slightly creamy quality. They often start off with peach aromas, becoming more mineral and citrus after six months in bottle.',
    ],
  },
  {
    name: 'VERDEJO',
    synonyms: [
      'Albillo de Nava',
      'Bot\u00f3n de Gallo Blanco',
      'Verdeja',
      'Verdejo Blanco',
    ],
    description:
      'High-quality, aromatic, rich, nutty variety most at home in Spain\u2019s Rueda region.',
    'origin and parentage': [
      'Verdejo, named after the green (verde) colour of the berries, most likely comes from the region of Rueda, where it was supposedly introduced by the Mozarabs before Moorish rule. DNA profiling has shown that Albillo de Nava from Rueda is identical to Verdejo (Santana et al. 2007), although it has no relationship to ALBILLO MAYOR or ALBILLO REAL (Vouillamoz). DNA parentage analysis has recently suggested that GODELLO from Galicia in north-west Spain and Verdejo are siblings (Santana et al. 2010), which explains why Verdejo is sometimes \u2013 and confusingly \u2013 called Gouveio or Verdelho, both synonyms for Godello in Portugal.',
    ],
    'where its grown': [
      'Verdejo, the pride and joy of the Rueda DO in Castilla y Le\u00f3n in northern central Spain, is the country\u2019s fifth most planted white wine variety, with 11,352 ha (28,051 acres) in 2008. It comprises nearly 10% of the Castilla y Le\u00f3n vineyard area but is also widely planted in Castilla-La Mancha and there\u2019s a fair amount in Extremadura. Since the 1970s, when Marqu\u00e9s de Riscal saw the potential for dry white wines in Rueda, once more famous for its fortified styles, Verdejo has been a rising star. Wines are very aromatic, with notes of laurel and bitter almonds, medium to high acidity, full-bodied but rich and smooth showing a hint of almonds on the finish. They become more nutty with age and respond well to barrel fermentation and oak ageing.',
      'In Rueda, Verdejo is often blended with SAUVIGNON BLANC but seems to be easily holding its ground against the incomer (473 ha/1,169 acres in 2008) even though the DO regulations also allow a Rueda that is solely or predominantly made from Sauvignon. These regulations permit a range of styles for Verdejo-based wines: still dry table wines, sparkling wines made by the traditional method, and two different types of fortified, sherry-like wines, P\u00e1lido and Dorado.',
      'Recommended producers of unfortified, still varietal Verdejo (or wines with at least 85% of the variety) include Belondrade et Lurton, Cerrosol, Bodegas de Crianza Castilla La Vieja, Ermita Veracruz, Jos\u00e9 Pariente, Marqu\u00e9s de Riscal, Naia Vi\u00f1a Sila, Ossian and Javier Sanz.',
      'In Rueda, Vi\u00f1edos Nieva make a Pie Franco cuv\u00e9e and Bodegas Naia (Vi\u00f1a Sila) make Naiades, both from very old and ungrafted Verdejo vines.',
      'Keswick produce a Verdejo in the US state of Virginia.',
    ],
  },
  {
    name: 'VERDEJO SERRANO',
    synonyms: ['Rufete Blanca'],
    description:
      'Almost extinct variety from the mountains of Salamanca province.',
    'origin and parentage': [
      'Verdejo Serrano is an almost extinct variety from the mountainous Sierra de Francia region in the province of Salamanca in western central Spain near the border with Portugal. It was rediscovered in the early twenty-first century but was not described until 2006, and was officially registered in 2009 under the name Verdejo Serrano, with Rufete Blanca as synonym (Arranz et al. 2008). This synonym is misleading because DNA profiling shows that Verdejo Serrano is not a colour mutation of RUFETE (Vouillamoz), a Portuguese variety that is the most widespread black variety in Sierra de Francia. In addition, comparison of the DNA profiles of Verdejo Serrano, VERDEJO (Santana et al. 2010) and Verdejo de Salamanca (Mart\u00edn et al. 2003), the last being an old and no longer cultivated variety from the same area, shows that they are all distinct.',
    ],
    'where its grown': [
      'The Cooperativa de San Esteban in San Esteban de la Sierra in Sierra de Francia, Spain, make one of the very few varietal wines, Alma de Tiri\u00f1uelo.',
    ],
  },
  {
    name: 'VERDELHO',
    synonyms: [
      'Verdelho Branco',
      'Verdelho da Madeira',
      'Verdelho dos A\u00e7ores',
      'Verdelho Pico',
      'Verdello no Peluda Finca Natero',
    ],
    description:
      'Portuguese variety making fine, medium-dry wines on Madeira and full-bodied table wines in Australia and the Loire.',
    'origin and parentage': [
      'Verdelho is said to have been widely cultivated on the island of Madeira in the seventeenth century and may have been taken there from the mainland when the island was first populated. However, the variety is rarely found on the mainland, so it may actually originate from Madeira.',
      'DNA profiling has shown that Verdelho Roxo is a red-berried colour mutation of Verdelho, while Verdelho Tinto from the Minho region is a distinct variety that is no longer cultivated (Lopes et al. 1999; Lopes et al. 2006). Recent DNA parentage analysis has suggested that Verdelho could have a parent\u2013offspring relationship with SAVAGNIN (see the pedigree of PINOT for details), a very old French variety (Myles et al. 2011).',
      'Verdelho should not be confused with GODELLO, a variety from Galicia in Spain that is also cultivated in the Portuguese D\u00e3o region under the name Verdelho or Verdelho do D\u00e3o (Lopes et al. 2006).',
    ],
    'where its grown': [
      'Before the arrival of phylloxera on Madeira, Portugal, in 1872, Verdelho constituted around two thirds of Madeira\u2019s vineyards (Mayson 2003). Although the four so-called noble varieties associated with Madeira lost out to Tinta Negra Mole (NEGRAMOLL) in the subsequent replanting, Verdelho has seen the greatest expansion and is now the most widely cultivated of the four within the DOC region \u2013 47 ha (116 acres) on Madeira in 2010, mainly in the cooler north. The best sites are said to be around Ponta Delgada and S\u00e3o Vicente (Mayson 2003). The must has moderate sugar levels, marked acidity and is used for medium-dry fortified wines that fall between Sercial and Bual on the sweetness scale. It is also used for dry table wines labelled Terras Madeirenses. In the A\u00e7ores, Verdelho is the main variety in the Biscoitos, Pico and Graciosa DOCs (60 ha/148 acres in 2010) for both fortified and alcoholic but unfortified medium-dry styles. There are several producers of Madeira made from 100% Verdelho \u2013 eg Barbeito \u2013 and Paix\u00e3o do Vinho make an unfortified version, as does Jos\u00e9 Maria da Fonseca in Set\u00fabal. Verdelho is also surprisingly successful in the hot Alentejo region in southern Portugal, retaining its refreshing acidity in the hands of Peter Bright at Terras d\u2019Alter, for example. The total area planted on mainland Portugal in 2010 was 93 ha (230 acres).',
      'In France Verdelho was recently reinstated in the official register of vine varieties, from which it had been dropped in 1995. Verdelho was introduced to Savenni\u00e8res in the Loire in the early nineteenth century, by mistake according to some sources, and those who have persisted with the variety, making deep-flavoured wines of varying degrees of sweetness, include Baumard, St\u00e9phane Bernaudeau and Closel.',
      'In California are tiny pockets of the variety such as in the DeWitt vineyard in Amador County and in Clarksburg in the Sacramento Delta. Kenneth Volk of Paso Robles makes an impressively fresh, slightly grippy version with flavours of quince, pear and almonds. A little is planted in Mendoza, Argentina.',
      'Taken from Madeira to Australia in the 1820s, Verdelho was enthusiastically planted and praised in the mid nineteenth century. Even though it is probably better suited to the drier heat of parts of Australia compared with the subtropical conditions of Madeira, where it is at greater risk from fungal diseases, it was once quite widespread in the Hunter Valley in New South Wales. In South Australia it has made a minor but significant come-back in the last twenty years even though there are few winemakers to sing its praises (Thistlewood 2007). In 2008 there were 1,761 ha (4,352 acres) in Australia, the biggest areas in the Hunter Valley and Riverina. Producers here, in Victoria and in the warmer parts of Western Australia such as the Swan District are making vibrant, tangy, full-bodied white wines, generally for early drinking, although some are experimenting with a fresher style from earlier-harvested fruit. Recommended producers of varietal wines include Angove (South Australia), Bimbadgen (Hunter Valley), Bremerton (Langhorne Creek), Elysium (Hunter Valley), Houghton (Swan District), Karri Grove (Western Australia) and especially David Traeger (Victoria), who deliberately makes Verdelho to be aged. Some fortified Verdelhos are also made in Australia, by Bimbadgen, for example.',
      'Varietal Verdelho is also produced in New Zealand by the sister companies Esk Valley and Villa Maria.',
    ],
  },
  {
    name: 'VERDELLO',
    synonyms: ['Verdetto'],
    description: 'Waning high-acid Umbrian blending ingredient.',
    'origin and parentage': [
      'Verdello comes from Umbria in central Italy. Like many other varieties, Verdello was named after the greenish colour of its berries just before maturity. It was first mentioned in 1894 in Orvieto and has no relationship with VERDELHO from Portugal. The high-acid Verdello planted on the Italian island of Sicilia is likely to be a distinct variety but this has not yet been proven.',
    ],
    'where its grown': [
      'Verdello is cultivated in various parts of Umbria, central Italy, where it is prized for its natural acidity but is nevertheless losing popularity. There are also minor plantings in Toscana and Sicilia, giving a total vineyard area of 678 ha (1,675 acres) in Italy in 2000 \u2013 about half the area planted to Verdello in 1970. It may play a minor role to TREBBIANO TOSCANO\u2019s lead in Colli Amerini DOC in Umbria and in Bianco di Pitigliano DOC in Toscana, and is also authorized as a minor component (up to 20\u201330%) in Orvieto DOC. Palazzone\u2019s Umbria Bianco IGT includes 20% Verdello in a Procanico- (ie Trebbiano Toscano-) dominated blend, Decugnano dei Barbi\u2019s traditional-method sparkling wine also contains 20% Verdello and Cerquetto\u2019s Orvieto has 30% Verdello, but the lack of varietal examples suggest it is less successful on its own.',
    ],
  },
  {
    name: 'VERDESSE',
    synonyms: [
      'Bian Ver',
      'Verdasse',
      'Verd\u00e8che',
      'Verdesse Musqu\u00e9e',
    ],
    description:
      'Potentially high-quality, aromatic variety being replanted in Savoie.',
    'origin and parentage': [
      'Verdesse most likely comes from the Vall\u00e9e du Gr\u00e9sivaudan in Is\u00e8re, where it was first mentioned in Sassenage in 1845 (R\u00e9zeau 1997). The name refers to the dark-green colour of the leaves and berries (vert is French for \u2018green\u2019). Verdesse belongs to the Pelorsien ampelographic group (see p XXVII; Bisson 2009).',
    ],
    'where its grown': [
      'Verdesse used to be widely cultivated in the Vall\u00e9e du Gr\u00e9sivaudan in the Is\u00e8re, south-eastern France, where it was the most widely planted variety at the end of the 1920s, and is still authorized for the still and sparkling whites of the Vin de Savoie appellation. The vineyard area had declined to little more than 2 ha (5 acres) by 2008 but Verdesse has recently been successfully replanted. Producers of varietal wines include the Cave Coop\u00e9rative de Bernin, Michel Magne and Mas du Bruchet. According to Schneider, Mannini and Cravero (2004), it was also formerly cultivated in the Valle Chisone and the Valle di Susa in Piemonte in north-west Italy but it has disappeared to the extent that it is not listed in the official census statistics of 2000. Wines tend to be powerful yet lively and aromatic.',
    ],
  },
  {
    name: 'VERDICCHIO BIANCO',
    synonyms: [
      'Angelica',
      'Boschera',
      'Boschera Bianca',
      'Lugana',
      'Pevarise',
      'Peverella',
      'Peverenda',
      'Pfefferer',
      'Pfeffertraube',
      'Pievana',
      'Terbiana',
      'Trebbiano di Lonigo',
      'Trebbiano di Lugana',
      'Trebbiano di Soave',
      'Trebbiano di Verona',
      'Trebbiano Nostrano',
      'Trebbiano Valtenesi',
      'Trebbiano Verde',
      'Turbiana',
      'Turbiano',
      'Turviana',
      'Verdetto',
      'Verdicchio Giallo',
      'Verdicchio Marchigiano',
      'Verdicchio Peloso',
      'Verdicchio Verde',
      'Verdone',
    ],
    description:
      'Famously associated with the Marche\u2019s best-known dry white, this is probably originally from the Veneto, where it is also found, as Trebbiano di Soave. Wines can age.',
    'origin and parentage': [
      'Verdicchio Bianco is an extremely widespread and probably very old variety from the Veneto in northern Italy or from central Italy, known under a great variety of synonyms. Among the numerous mentions of \u2018Trebbiano\u2019 grapes in the Marche and the Veneto since the thirteenth century, it is likely that some of them corresponded to Verdicchio. However, the earliest mention of a grape variety named Verdicchio appeared in 1569 in a document signed by Costanzo Felici (1525\u201385), a physician and naturalist from Piobbico (in the Marche, between Rimini and Perugia), author of a series of manuscripts (1569\u201372) entitled De l\u2019insalata e piante che in qualunque modo vengono per cibo de l\u2019homo (\u2018On the salads and the plants that in whatever way are used as food for man\u2019). It was later mentioned in 1579 in Matelica (between Perugia and Ancona) by the notary Niccol\u00f2 Attucci, and then in the province of Ascoli Piceno by Bacci (1596).',
      'Although Verdicchio has been grown in the Marche for centuries, according to Pollini (2007), it originally came from the Veneto and was most likely introduced to the Marche in the fifteenth century, when farmers moved south to repopulate the plague-devastated region. Indeed, early ampelographic and enzymatic studies have established that Trebbiano di Soave, widely planted in the provinces of Brescia (Lombardia) and Verona (Veneto), is identical to Verdicchio Bianco (Cal\u00f2, Costacurta et al. 1991), which was subsequently confirmed by DNA profiling (Labra et\nal. 2001).',
      'More recently, DNA studies at Milano University have proved that Verdicchio Bianco is identical to two other varieties of the heterogeneous TREBBIANO group: Trebbiano Valtenesi, a local and endangered variety from the Lago di Garda region in the province of Brescia (Lombardia), and Trebbiano di\nLugana, cultivated in the provinces of Brescia and Verona (Ghidoni et al. 2008).',
      'Similarly and more surprisingly, DNA profiling has shown that Peverella,\nan old variety traditionally cultivated in Trentino (Trento, Lavis, Salorno, Faedo and Val di Cembra) as well as in the Alto Adige under the name Pfefferer, is identical to Verdicchio Bianco (Stella Grando and Jos\u00e9 Vouillamoz, unpublished data). It had almost disappeared after the phylloxera plague at the end of the nineteenth century and was recently rescued from extinction by the joint effort of the San Michele all\u2019Adige research centre and amateur ampelographer Gianpaolo Girardi.',
      'In addition, DNA comparison has suggested a close relationship between Verdicchio Bianco and MACERATINO (Filippetti, Silvestroni, Thomas and Intrieri 2001). Finally, it should be noted that the pink-berried Verdicchio, for which Di Vecchi Staraz, This et al. (2007) have found a parent\u2013offspring relationship with MAMMOLO, has been shown by DNA profiling to be distinct from the true Verdicchio Bianco (Vouillamoz).',
      'Verdicchio Bianco was used to breed INCROCIO BRUNI 54.',
    ],
    'where its grown': [
      'Under the name Verdicchio Bianco, its main area of cultivation is the Marche in eastern central Italy, where it is best known as a varietal wine in the DOCs of Verdicchio dei Castelli di Jesi, west of Ancona 30 km (20 miles) from the Adriatic, and the much smaller Verdicchio di Matelica, on hillier terrain further inland and close to the border with Umbria. A minimum of 50% Verdicchio is required in the less well-known Esino DOC. Further south, in the province of Ascoli Piceno, it may play a minor role in the Falerio dei Colli Ascolani DOC. The variety is recommended in Abruzzo, Umbria and Lazio and authorized in Toscana and Sardegna. There were 3,561 ha (8,799 acres) in Italy in 2000. Recommended producers of varietal wines include Fazi Battaglia and Umani Ronchi. The best wines have a marked lemony acidity and combine substance and freshness, often leaving a bitter-almond flavour on the finish. The finest examples such as Umani Ronchi\u2019s Casal di Serra and La Monacesca\u2019s Mirum can improve in bottle for at least as long as the average white burgundy. In the past, wines were often given more weight by the use of skin contact but this also tended to make them coarser and the practice has been largely abandoned. The variety\u2019s high acidity also makes it well suited to the production of sparkling wines, with a long tradition that goes back to the nineteenth century.',
      'Further north in the Veneto, under the name Trebbiano di Soave, it adds perfume to complement GARGANEGA\u2019s steely profile in all forms of Soave, including sweet Recioto di Soave. Pieropan is one of the top producers to include the variety in his Soaves. The census records 1,802 ha (4,453 acres) of Trebbiano di Soave in 2000. Further to the west, the warmer climate produces full-bodied, perfumed wines of real interest in the guise of Trebbiano di Lugana (not listed separately in the census), mainly in the eponymous Lugana DOC south of the Lago di Garda. Zenato and C\u00e0 dei Frati make good examples.',
      'As Peverella, the variety is still planted to a very limited extent in Trentino, eg by Francesco Poli, whose Massenza Bianco is a blend of NOSIOLA and Peverella.',
      'Under the name Trebbiano Valtenesi (again, not mentioned in the census), the variety is rarely found but Comincioli in Puegnago sul Garda blend 60% Trebbiano Valtenesi with 40% ERBAMAT to produce their dry white Perl\u00ec IGT Garda.',
      'Rather curiously, Peverella turns up in Brazil\u2019s Rio Grande do Sul, where there were 20 ha (49 acres) in 2007. This is probably due to the arrival of numerous Trentino families in Brazil at the end of the nineteenth century. DNA profiling has recently confirmed that this Brazilian Peverella is identical to Verdicchio Bianco (Le\u00e3o et al. 2009). Salvati & Sirena produce a varietal wine in Bento Gon\u00e7alves. It also pops up in California\u2019s Sierra Foothills, where Avanguardia blend it with Friulano (SAUVIGNONASSE) and other Italian varieties in their Selvatico blend.',
    ],
  },
  {
    name: 'VERDISO',
    synonyms: ['Pedevenda', 'Peverenda', 'Pexerenda', 'Verdino', 'Verdise'],
    description:
      'Recuperated minor variety from the Colli Euganei in the Veneto makes wines from fizz to passito.',
    'origin and parentage': [
      'Verdiso, or Verdise, is local to the Colli Euganei, south of Padova in the Veneto, north-east Italy, where it was widely planted after the terrible 1709 frost. Recent DNA profiling has confirmed that Verdiso and Pedevenda, another local variety that has long been cultivated further north around Breganze in the province of Vicenza, where it was mentioned as early as 1754 by Aureliano Acanti in a book of poetry entitled Roccolo, are one and the same (Crespan et al. 2003). Since Pedevenda is the old name for the Colli Euganei, this links the variety to its likely place of origin.',
      'Verdiso was used to breed FLAVIS and ITALICA.',
    ],
    'where its grown': [
      'Verdiso had almost disappeared from the Colli Euganei just south of Padova in the Veneto, Italy, when it was recovered in the 1960s by vine breeder and collector Giuseppe Tocchetti, who propagated and preserved it. Today Verdiso is cultivated mainly in the Refrontolo hills and between Conegliano and Valdobbiadene in the province of Treviso, where it is blended with PROSECCO and Boschera (aka VERDICCHIO BIANCO) to produce Colli di Conegliano DOC\u2019s Torchiato di Fregona sweet wines. A small percentage (maximum 10%) is also permitted in the generally superior fizz with the longest name in the Prosecco region: Conegliano Valdobbiadene Superiore di Cartizze DOCG. As Pedevenda, it is cultivated in the Breganze area, generally for inclusion in the famous passito-style wines of Torcolato di Breganze DOC. There were 81 ha (200 acres) in Italy in 2000, considerably less than in 1970 (1,150 ha/2,842 acres). Il Colle, Conte Collato and Gregoletto are among the few producers of varietal wines. The variety is characterized by aromas of citrus and white flowers but these generally lively wines are best drunk young.',
    ],
  },
  {
    name: 'VERDUZZO FRIULANO',
    synonyms: [
      'Ramandolo',
      'Ramandolo Dorato',
      'Verd\u00f9\u00e7',
      'Verd\u00f9z',
      'Verd\u00f9zz',
      'Verduzzo di Ramandolo',
      'Verduzzo Giallo',
    ],
    description:
      'Ancient, characterful, sometimes astringent, Friulian; good sweet wines.',
    'origin and parentage': [
      'The earliest mention of a variety named Verduzzo was in relation to a banquet held on 6 June 1409 in Cividale (Friuli) in honour of Pope Gregory XII, at which the following wines were served: Ribolla from Rosazzo, Verduzzo from Faedis, Ramandolo from Torlano, Refosco from Albana and Marzemino from Gradiscutta (Peterlunger et al. 2004). Since Faedis is in the province of Udine in Friuli, north-east Italy, this document most likely refers to Verduzzo Friulano. Interestingly, Ramandolo is an ancient synonym for Verduzzo Friulano, which therefore seems to have been served twice at this banquet! Comparison of DNA profiles shows that Verduzzo di Ramandolo (Cipriani et al. 2010) is indeed identical to Verduzzo Friulano (Vouillamoz) but distinct from VERDUZZO TREVIGIANO (Crespan et al. 2011).',
    ],
    'where its grown': [
      'Verduzzo Friulano is cultivated mainly in Friuli in north-east Italy, notably but by no means exclusively in the more easterly province of Udine (the communes of Nimis, Faedis, Tarcento and Torlano have long been associated with this variety), but also in Piave over the border in the Veneto. Total plantings in Italy, which are gradually declining, were 1,658 ha (4,097 acres) in 2000.',
      'Varietal wines both dry and sweet, and occasionally sparkling, are produced within the DOCs of Lison-Pramaggiore, Colli Orientali del Friuli, Friuli Annia, Friuli Aquiliea, Friuli Grave, Friuli Isonzo, Friuli Latisana and Piave. Ramandolo (named after the Ramandolo hills) has been used rather loosely in the past for a variety of sweet wines but since its establishment as a DOCG, it may refer only to sweet wines made from Verduzzo Friulano (known locally as Ramandolo) in the steeply hilly region above Nimis, at around 380 m (1,250 ft) above sea level. The most significant quantities are produced in Grave and in the Colli Orientali. The Verduzzo of the Colli Orientali is qualitatively far superior thanks to the preponderance of hillside vineyards. Some Verduzzo Friulano is planted in the Collio production zone but has to be labelled as vino da tavola.',
      'Varietal wines are generally quite powerful, fresh, ranging from dry to fairly sweet but the sweeter wines \u2013 made from late-harvested or partially dried grapes \u2013 tend to be the more successful. They often have slightly astringent tannins and a lightly herbal or cedary aroma which works in harmony with the honeyed flavours that increase with age. In fact, the best examples are greatly improved by a few years in bottle. In the dry wines, however, that astringency may be slightly too noticeable.',
      'Recommended producers include Bresan, Conte d\u2019Attimis-Maniago, Adriano Gigante, Davino Meroi, Roberto Scubla, La Tunella and Valchiar\u00f2.',
      'In Slovenia there were 4.15 ha (10 acres) in Gorki\u0161ka Brda in 2009. Klinec make a good example.',
      'Pizzini make dry and sweet versions in the King Valley, Victoria, Australia.',
    ],
  },
  {
    name: 'VERDUZZO TREVIGIANO',
    synonyms: ['Verduc', 'Verduz', 'Verduzzo di Motta'],
    description:
      'The other Verduzzo, generally inferior to and often blended with Verduzzo Friulano. Waning.',
    'origin and parentage': [
      'Despite its name (Trevigiano means \u2018from the province of Treviso\u2019), the origins of Verduzzo Trevigiano are unknown. It is said to have been introduced from Sardegna in the early twentieth century, although it cannot be identified with any modern Sardinian varieties. It is distinct from VERDUZZO FRIULANO, with which it is often cultivated and confused.',
    ],
    'where its grown': [
      'Verduzzo Trevigiano is cultivated mainly in the provinces of Treviso and Venezia in the Veneto, north-east Italy. It is often blended with the superior VERDUZZO FRIULANO in the Piave and Lison-Pramaggiore DOCs, where wines labelled Verduzzo may be made from Verduzzo Trevignano and/or Verduzzo Friulano. Wines appear in a range of styles, from dry to medium sweet as well as sparkling, and Casa Piave are one of the few producers to make a wine from 100% Trevigiano, in both dry and late-harvest styles. Terre di Ger\u2019s Limine is a barrel-fermented, oak-aged blend of Verduzzo Trivigiano and CHARDONNAY. The agricultural census of 2000 recorded 1,734 ha (4,285 acres) in Italy, not much more than half what they were in 1970.',
    ],
  },
  {
    name: 'VERMENTINO',
    synonyms: [
      'Favorita',
      'Furmentin',
      'Garbesso',
      'Malvasia',
      'Malvoisie de Corse',
      'Malvoisie \u00e0 Gros Grains',
      'Malvoisie du Douro',
      'Pigato',
      'Rolle',
      'Roll\u00e9',
      'Sapaiola',
      'Verlantin',
      'Varlantin',
      'Verlentin',
      'Varlentin',
      'Vermentino di Gallura',
      'Vermentinu',
    ],
    description:
      'Aromatic, crisp high-quality variety flourishing near the sea in northern Italy, southern France and on the islands of Corse and Sardegna.',
    'origin and parentage': [
      'Vermentino is said to have been introduced to the French island of Corse in the thirteenth or fourteenth century but the earliest mention appears to have been in 1658 in Montaldeo in the province of Alessandria in Piemonte, north-west Italy, where vineyards were planted with cortese (CORTESE), fermentino (Vermentino) and nebioli dolci (NEBBIOLO; Nada Patrone 1991). Some scholars believe that the name Vermentino derives from vermene, a local word used to describe a young, thin and flexible shoot, but an etymology based on fermento, meaning \u2018ferment\u2019, probably referring to the fizzy character of the young wine, sounds more convincing, not least because it echoes the spelling of its first mention, fermentino.',
      'Morphological and DNA comparisons have clearly established that Favorita (Piemonte), Pigato (Liguria) and Vermentino (Liguria, Sardegna, Toscana and Corse) are one and the same variety (Schneider and Mannini 1990; Botta, Scott et al. 1995). Favorita is supposed to have been brought to Piemonte as a gift from Ligurian oil merchants. It was first mentioned in 1676 in the cellar book of Conti Roero in Piemonte: three loads of \u2018biancho di favorie\u2019 were registered in the cellar in Vezza d\u2019Alba. In Roero, it quickly became the \u2018favourite\u2019 variety for table-grape consumption, hence its name. Pigato derives from the Ligurian dialect word pigau, meaning \u2018spotted\u2019, because of the appearance of the berries at maturity. Pigato was supposedly mentioned in the Savona area in the fifteenth century, and is said to have been introduced from Toscana by merchants, this time from Firenze.',
    ],
    'where its grown': [
      'This aromatic variety is widely grown, under its various synonyms, in Liguria in north-west Italy, on the Italian island of Sardegna and in Piemonte, as well as in southern France and on the French island of Corse.',
      'In fact Vermentino/Rolle is even more widely planted in southern France than in its native Italy. In 2008 total plantings amounted to 3,453 ha (8,533 acres), most in the Var in Provence (1,165 ha/2,879 acres) but also on the island of Corse (814 ha/2,011 acres), where it is the most important variety in every one of the island\u2019s white wine appellations (minimum 75\u201380%) apart from its Muscats, and in Languedoc-Roussillon (813 ha/2,009 acres), especially in the H\u00e9rault d\u00e9partement. Here Domaine du Poujol blend it with ROUSSANNE and Carignan Blanc (see MAZUELO) to produce a complex and highly drinkable wine. It is recommended in the Alpes-Maritimes, Aude, Bouches-du-Rh\u00f4ne, Gard, H\u00e9rault, Pyr\u00e9n\u00e9es-Orientales and Var, and authorized in the Alpes-de-Haute-Provence, Hautes-Alpes, Ard\u00e8che, Dr\u00f4me and Vaucluse. Styles range from intensely aromatic to steely citrus.',
      'Total plantings of Vermentino in Italy in 2000 were 3,000 ha (7,413 acres) and fairly stable. The census lists Favorita and Pigato separately, recording 276 ha (682 acres) and 255 ha (630 acres) respectively. Varietal wines appear in many guises but the best are fragrant, sometimes more mineral than floral, fruity or gently spicy; mostly moderate alcohol \u2013 especially when picked early enough to retain acidity \u2013 and for early drinking.',
      'As Pigato or Vermentino it used to be the most widespread variety in Liguria in north-west Italy, where it is still traditionally cultivated in the provinces of Savona and Imperia. It is also grown in the communes of Arenzano and Cogoleto in the province of Genova, where it is known exclusively as Vermentino. It appears mainly in the following DOCs, on its own or blended with other local varieties such as ALBAROLA: Colli di Luni, Colline di Levanto, Golfo del Tigullio, Riviera Ligure di Ponente and Val Polcevera. This last DOC, incidentally, still provides for a varietal Pigato as well as a varietal Vermentino. U Munte produce a particularly good example that is relatively light but combines floral, mineral and slightly salty aromas and Laura Aschero\u2019s Pigato is seriously vibrant with intriguing scents of fresh herbs and lush vegetation and then real tension on the palate.',
      'On Sardegna, Vermentino is the most important white variety, especially in Gallura in the north of the island, where it was awarded its own DOCG, the island\u2019s first and only, in 1996. The grapes tend to be harvested early to retain acidity but the wines are still lively and characterful. Producers such as Capichera have successfully produced a richer, fuller style. More generally, Vermentino di Sardegna DOC can be produced anywhere on the island, as the name suggests. Alghero DOC in the north west includes a sparkling Vermentino. Other recommended producers include Argiolas, Carpante, Ferruccio Deiana, Cantina Sociale Gallura, Cantina del Giogantinu, Piero Mancini, Fratelli Pala and Pedres.',
      'It is planted to a lesser extent in Massa-Carrara in Toscana and in Puglia, Umbria and Lazio. In Toscana, Vermentino is thriving thanks to the growing interest in indigenous varieties. It appears in the recipes for several Vin Santos and may be made as a varietal in a host of DOCs including Bolgheri, Colline Lucchese and Montecucco.',
      'Under the name Favorita, it has traditionally been planted \u2013 formerly as both table and wine grape but today almost exclusively as a wine grape \u2013 in the regions of Alba and especially Roero in Piemonte, north-west Italy. Since the 1990s, Favorita has been slowly replaced by the more fashionable ARNEIS in Roero and by CHARDONNAY in Langhe although some fine aromatic wines are still being made by producers such as Gianni Gagliardo.',
      'Vermentino is found on Malta and has recently been planted by Massaya at an altitude of 1,200 m in the Bekaa Valley in Lebanon.',
      'In California, Tablas Creek have been producing bright, citrus-flavoured Vermentino from their acre of vines in Paso Robles. Other produces include Mahoney in Carneros, Cougar in Temecula, Indian Rock Vineyards in the Sierra Nevada, Uvaggio and Woodbridge in Lodi and, beyond the Golden State, in Texas, chez Raffaldini in North Carolina and particularly successfully at Barboursville Vineyards in Virginia.',
      'Vermentino is also found in Brazil.',
      'In Australia, there were around fifteen producers of Vermentino in 2009, most notably Yalumba, the most sustained pioneer of alternative varieties in their vine nursery in Nuriootpa, South Australia, but also Brown Brothers in Victoria, De Bortoli in the Hunter Valley, Politini in the King Valley, Spinifex in the Barossa Valley and Trentham Estate in Murray Darling. In Australia the wines tend to have riper fruit flavours \u2013 stone fruit and even more tropical aromas \u2013 but still have that refreshing acidity as long as the grapes are picked fairly early.',
    ],
  },
  {
    name: 'VERNACCIA DI ORISTANO',
    synonyms: [
      'Aregu Biancu',
      'Aregu Seulu',
      'Cranaccia',
      'Garnaccia',
      'Granaccia',
      'Granazza',
      'Varnaccia',
      'Vernaccia',
      'Vernaccia Austera',
      'Vernaccia Orosei',
      'Vernaccia S. Rosalia',
    ],
    description: 'Makes complex sherry-like wines on Sardegna.',
    'origin and parentage': [
      'Vernaccia di Oristano, from the Italian island of Sardegna, was first mentioned in 1327 in Breve\ndi Villa di Chiesa, a law book conserved in Iglesias, a town in south-western Sardegna, formerly known as Villa di Chiesa, with the following statement about the vintners: \u2018non debbiano n\u00e8 possano tenere a mano pi\u00f2 di una botte de uno vino, ma s\u00ec divisi vini, cio\u00e8 una di varnaccia, una di greco, una di vermiglio, et una di brusco bianco che fusse facto fuora di Sardigna, et una di vino Sardisco\u2019 (\u2018neither should they nor could they possess more than one barrel of a type of wine, but wines are separated, that is to say one of varnaccia, one of greco, one of vermiglio, and one of brusco bianco that was made outside of Sardegna, and one of a Sardinian wine\u2019). DNA profiling has confirmed several synonyms for Vernaccia di Oristano in Sardegna, as listed above, including many similar to \u2018Garnacha\u2019, but has also proved that the Spanish GARNACHA and Vernaccia di Oristano are distinct varieties (De Mattia et al. 2007; Zecca et al. 2010). Recent DNA parentage analysis has suggested that Vernaccia di Oristano is a possible parent of SANTA MARIA from Emilia-Romagna (Cipriani et al. 2010).',
    ],
    'where its grown': [
      'This variety is cultivated almost exclusively in the Vernaccia di Oristano DOC in the lower part of the Valle del Tirso in the province of Oristano, on the central west coast of the Italian island of Sardegna, producing various styles, from dry and unfortified to the more complex sherry-like fortified wines both sweet and dry. Each style has strict minimum ageing requirements \u2013 ranging from twenty-nine months for the Secco (ie dry and unfortified) to fifty-three months for the Superiore Riserva (also dry and unfortified) \u2013 in a solera system using partially filled chestnut or oak barrels. There were 582 ha (1,438 acres) planted in Italy in 2000.',
      'Attilio Contini make fine examples of these oxidative-style wines. Their Antico Gregori incorporates wine approximately 100 years old.',
    ],
  },
  {
    name: 'VERNACCIA DI SAN GIMIGNANO',
    synonyms: ['Bervedino', 'Piccab\u00f2n', 'Vernaccia'],
    description:
      'Refreshing and generally light variety from the Tuscan town with the towers. Potential for high-quality wine.',
    'origin and parentage': [
      'The earliest mention of this variety dates back to 1276 in San Gimignano in the province of Siena in Toscana, central Italy: Vernaccia and Greco wines were praised in the Ordinamenti di Gabella, a tax document held in the archives of San Gimignano. It was later acclaimed in the sixteenth century by Sante Lancerio, the cellar keeper of Pope Paolo III, and then mentioned as a sweet Tuscan wine under the names Vernaccia and Vernacce (plural) by Soderini (1600) in his treatise on the grape varieties of Toscana. Morphological and DNA analyses have shown that Vernaccia di San Gimignano is not identical to VERNACCIA DI ORISTANO or any other similarly named variety (Vouillamoz; see VERNACCIA, above), but also that it is identical to Bervedino in Emilia-Romagna (Storchi et al. 2011).',
    ],
    'where its grown': [
      'Vernaccia di San Gimignano is cultivated mainly on sandstone-based soils around the tower-festooned hill town of San Gimignano in the province of Siena in Toscana, central Italy, where it is found as a varietal wine in the Vernaccia di San Gimignano DOCG. It may be blended with TREBBIANO TOSCANO or other local varieties in the Colli dell\u2019Etruria Centrale DOC and in the Vin Santo version of San Gimignano DOC. Total plantings in Italy in 2000 were 784 ha (1,937 acres).',
      'San Gimignano\u2019s claim to fame, in addition to its fortifications, is that it was awarded the first ever DOC in Italy, in 1966 (DOCG in 1993). This accolade, and Vernaccia di San Gimignano\u2019s superiority to most of the local TREBBIANO TOSCANO-based blends, helped to halt its steady decline in the first half of the twentieth century. Varietal wines are crisp and citrusy, sometimes gently floral, with a refreshing bitter-almond aftertaste and have not generally been improved by experiments with small-barrel maturation. Some producers have experimented with additions of varieties such as CHARDONNAY and SAUVIGNON BLANC but it is easy to overwhelm the inherently rather light Vernaccia di San Gimignano.',
      'Recommended producers include Giovanni Panizzi, whose Evo\u00e8 is marked by a fine astringency thanks to extended skin contact and fermentation in large wooden vats, and Fontaleoni. Others include Baroncini, La Calcinaie, Vincenzo Cesani, Il Colombaio di Santa Chiara, La Lastra, Montenidoli, La Marmoraia, Niccolai-Palagetto and Fratelli Vagnoni.',
      'As Bervedino, it is cultivated in the Val d\u2019Adra in the province of Piacenza in Emilia-Romagna, where it is used in the very special Vin Santo di Vigoleno (see MELARA).',
    ],
  },
  {
    name: 'VERSOALN',
    synonyms: ['Versailler', 'Weiss Versoalen', 'Weisser Versailler'],
    description:
      'Recent burgeoning based on a single, ancient Alto Adige giant vine.',
    'origin and parentage': [
      'The strange name of this variety from the province of Bolzano in Trentino-Alto Adige (S\u00fcdtirol), northern Italy, could simply derive from the local dialect word verdolen, meaning \u2018green\u2019, like the berries, or less straightforwardly from the dialect versoaln, meaning \u2018to secure with a rope\u2019, possibly in reference to the need to tie the numerous branches of the vine to the supporting pergolas. Another and less convincing hypothesis suggests this name refers to Versailles, whence the plant was supposedly brought to Italy in the fifteenth century by Grafen Schlandesberg, a previous owner of Castel Katzenzungen (see below). The DNA profile of Versoaln does not match any other known variety (Vouillamoz).',
    ],
    'where its grown': [
      'This oddly named, once far more widespread, variety was until very recently represented by a single vine at Castel Katzenzungen in Tesimo in the province of Bolzano in the S\u00fcdtirol in the far north of Italy. It is one of the world\u2019s oldest and largest vines \u2013 approximately 350 years old (according to Martin Worbes at the University of G\u00f6ttingen) and covering 350 m2 \u2013 and is tended by the Laimburg viticultural research centre, where 100 new vines have recently been planted. Castel Katzenzungen and researchers from Laimburg collaborate to produce about 500 numbered bottles every year, using fruit from old and young vines. The dry wine has fresh green-apple aromas, marked acidity and a touch of apricot on the finish. So far quantity has been more reliable than quality.',
    ],
  },
  {
    name: 'VERTZAMI',
    synonyms: [
      'Deykaditiko',
      'Lefkada',
      'Lefkaditiko',
      'Lefkas',
      'Marzavi',
      'Vartzami',
    ],
    description:
      'Promising Greek variety that produces deeply coloured, powerful, very tannic wines.',
    'origin and parentage': [
      'Vertzami is local to the island of Lefk\u00e1da in the Ionian Sea off the west coast of Greece, its most likely place of origin given that DNA profiling has shown it to be identical to the variety known as Lefkas on Cyprus (Hvarleva, Hadjinicoli et al. 2005).',
      'There is also a white-berried Vertzami Lefko (Nikolau and Michos 2004) but it is not known if it is a colour mutation or a distinct variety.',
    ],
    'where its grown': [
      'Vertzami produces deeply coloured, almost black, wines with high levels of tannin and moderate to high acidity as well as plenty of dark and forest fruits. The variety is so deeply coloured that it is also used to deepen the colour of blended reds. According to research by Kallithraka et al. (2006), Vertzami had by far the highest total anthocyanin content of the twenty wines tested.',
      'Vertzami is found mainly and most densely on the island of Lefk\u00e1da, Greece,\nwhere it constitutes about 90% of the wine vineyards, as well as on K\u00e9rkyra (Corfu). There was a total of 269 ha (665 acres) in the Ionian region in 2008; it is also found in the western Pelop\u00f3nnisos (Peloponnese) and western central Greece and although the total area is small, the variety is becoming increasingly attractive for its quality potential. Vineyards need to be carefully sited (neither too cool/high nor too dry) to ensure the tannins ripen fully, otherwise they can be just too assertive and drying. Antonopoulos have been experimenting with this variety for the last twenty years using fruit from Lefk\u00e1da but have not been able to produce a commercial wine in every vintage. They also soften the variety by blending it with CABERNET FRANC. The main producers of Vertzami on Lefk\u00e1da are the Lefk\u00e1da co-op, Lefkaditiki Gi and Vertzamo.',
      'Vertzami is also popular on the island of Cyprus, where there were 96 ha (237 acres) of Lefkada in 2010, mainly in Stroumbi and Polemi in the P\u00e1fos district in the far west and in Malia and Omodos in the Lemes\u00f3s (Limassol) district further east. It is often blended with international varieties such as CABERNET SAUVIGNON, CABERNET FRANC or SYRAH but it shows the same promise as in Greece. Recommended producers include Zambartas.',
    ],
  },
  {
    name: 'VESPAIOLA',
    synonyms: ['Bresparola'],
    description: 'Speciality of Vicenza, associated with sweet whites.',
    'origin and parentage': [
      'Vespaiola is considered to be an ancient variety from the province of Vicenza, but little is known about its origin and history. It was first mentioned by Acerbi (1825) in the towns of Bassano del Grappa and Marostica. Like VESPOLINA, its name most likely comes from the attractiveness to wasps of the berries\u2019 high sugar content (vespa is Italian for \u2018wasp\u2019).',
    ],
    'where its grown': [
      'Vespaiola is cultivated exclusively in the province of Vicenza in the Veneto, north-east Italy. Within the Breganze DOC, it appears as a dry varietal wine or as the sweet passito-style Torcolato, as well as being authorized in the Friulano- (SAUVIGNONASSE-)dominated Bianco. It may be blended with GARGANEGA in the Gambellara and Vicenza DOCs. There were 112 ha (277 acres) in Italy\nin 2000, according to the agricultural census. Maculan\u2019s rare varietal Vespaiolo has good acidity and aromas of flowers, fruits and honey, though this may be more from the passito process since the variety itself is rather acidic and neutral, which may be why it is used by the Cantina Beato Bartolomeo da Breganze to make a sparkling version.',
    ],
  },
  {
    name: 'VESPOLINA',
    synonyms: ['Ughetta', 'Uvetta di Canneto'],
    description:
      'Low-yielding, minor Nebbiolo relative of Gattinara that is currently losing ground.',
    'origin and parentage': [
      'Vespolina has been known in the Oltrep\u00f2 Pavese in Lombardia and in Piemonte since the eighteenth century, especially in the area of Gattinara (Nuvolone Pergamo 1787\u201398). It used to be widespread in the Piemontese provinces of Novara, Vercelli and Biella, in Piacenza in Emilia-Romagna, and in Como and Pavia in Lombardia. Like VESPAIOLA, its name most likely comes from the high sugar content of the berries attracting wasps (vespa in Italian). DNA parentage analysis has established that Vespolina is a progeny of NEBBIOLO (Schneider, Boccacci et al. 2004), and that it is therefore a half-sibling of Nebbiolo\u2019s other progenies: BRUGNOLA, BUBBIERASCO, FREISA, NEBBIOLO ROS\u00c9, Negrera, NERETTO DI BAIRO and ROSSOLA NERA (see Nebbiolo pedigree diagram).',
    ],
    'where its grown': [
      'Vespolina\u2019s popularity and vineyard area decreased in the aftermath of phylloxera in favour of the more productive and resistant BARBERA, not least because grafting onto American rootstocks affected Vespolina\u2019s ripening. It is today cultivated in north Piemonte, Italy, mainly in the region of Gattinara, and in Oltrep\u00f2 Pavese under the name Ughetta or Uvetta di Canneto. Rarely made as varietal wine, in Piemonte DOCs such as Boca, Bramaterra, Colline Novaresi, Coste della Sesia, Fara, Ghemme and Sizzano it is usually blended with NEBBIOLO and in the Oltrep\u00f2 Pavese DOC mostly with Barbera. However, varietal wines are permitted within the Colline Novaresi and Coste della Sesia DOCs and Vercesi del Castellazzo make a spicy, fruity, tannic varietal wine. Varietal wines are also produced by Sergio Barbaglia, Francesco Brigatti and Ioppa, among others. There were 108 ha (267 acres) in Italy in 2000, less than half of plantings in 1970.',
    ],
  },
  {
    name: 'VIDAL',
    synonyms: ['Vidal Blanc', 'Vidal 256'],
    description:
      'French hybrid ideally suited to the production of sweet Canadian icewine.',
    'origin and parentage': [
      'A complex hybrid obtained by Jean-Louis Vidal (1880\u20131976) in the 1930s by crossing TREBBIANO TOSCANO \u00d7 RAYON D\u2019OR in order to produce a variety that was suitable for the production of cognac in Charente-Maritime.',
    ],
    'where its grown': [
      'Although Vidal was bred in France, it is not authorized and is rarely found there \u2013 yet it is, amazingly, now grown successfully in Sweden. It has been much more successful in Canada, particularly for icewine, and, to a lesser extent and generally for dry whites, in a wide array of American states including Virginia (150 acres/61 ha in 2010), the Finger Lakes region of New York State, Michigan (145 acres/59 ha in 2006), Missouri (118 acres/48 ha in 2009, more than 7% of the vineyard area), Indiana (35 acres/14 ha in 2009) and Illinois (32 acres/13 ha in 2009).',
      'Canada had 1,920 acres (777 ha) planted in 2008, the vast majority in Ontario, plus minor areas in British Columbia, Nova Scotia and Qu\u00e9bec. The variety was probably introduced to the country by Frenchman Adh\u00e9mar de Chaunac, chief oenologist at T G Bright & Co, who imported both hybrid and vinifera varieties in the late 1940s. Originally used to make voluptuously fruity white table wines, according to Schreiner (2001) de Chaunac also left some grapes on the vine for late-harvest wine and icewine but it was not until the 1980s that further experimentation led to wider commercial production of curranty sweet wines made from grapes frozen on the vine. Vidal has the great advantage of being winter-hardy but having no foxy taste and the wines can taste very bright and pure, with the usual extreme crispness associated with icewine, for two or three years, though the wines are not generally designed to age in bottle. Recommended producers include Inniskillin (the world\u2019s largest producer of icewine), sister company Jackson-Triggs, and Peller in the Niagara Peninsula, Mission Hill in the Okanagan Valley and Vignoble de l\u2019Orpailleur in Qu\u00e9bec. Wines of all sweetness levels are made from Vidal but icewine has seen the greatest commercial success and achieves the highest prices.',
    ],
  },
  {
    name: 'VIDIANO',
    synonyms: ['Abidano', 'Abidiano', 'Abudiano', 'Avidiano', 'Bidiano'],
    description:
      'Old Greek variety showing signs of a revival on Kr\u00edti (Crete).',
    'origin and parentage': [
      'Rare variety from the Greek island of Kr\u00edti that is genetically close to THRAPSATHIRI and VILANA (Biniari and Stavrakakis 2007), both also from Kr\u00edti.',
    ],
    'where its grown': [
      'Vidiano had virtually disappeared from Kr\u00edti, Greece, found only in old mixed vineyards, but it seems to be slowly regaining ground thanks to the efforts of growers such as Douloufakis, who has three recently planted vineyards, the original one based on mass selection from old vines in R\u00e9thymno, the later ones working with a local nursery.',
      'It produces wines rich in fruit flavours that range from lime to apricot, sometimes quite tropical, and they retain fresh acidity even at alcohol levels of around 13%. Tamiolakis, who produce a Vidiano/PLYTO blend, may have been the first to replant this variety but they have been followed by others such as Lyrarakis, whose barrel-fermented varietal Ippodromos has hints of spice and honey as well as aromas of apricot and quince. Douloufakis always blend in some SAUVIGNON BLANC for increased aroma.',
    ],
  },
  {
    name: 'VIEN DE NUS',
    synonyms: ['Gros Rouge', 'Gros Vien', 'Oriou Gros'],
    description:
      'Minor Aostan with relatives elsewhere. Wine is always blended.',
    'origin and parentage': [
      'Vien de Nus literally means \u2018vine of Nus\u2019, named after the village in the Valle d\u2019Aosta between the towns of Aosta and Saint-Vincent where this variety was most likely born. Its first mention and description appeared in the writings of Gatta (1838), an amateur ampelographer and professional physician from neighbouring Ivrea (Piemonte). DNA parentage analysis has established that Vien de Nus is a progeny of PETIT ROUGE from the Valle d\u2019Aosta and another unknown and probably extinct variety, and that Vien de Nus probably has close relationships with EYHOLZER ROTE and ROUGE DU PAYS from the neighbouring Valais (Vouillamoz and Moriondo 2011).',
    ],
    'where its grown': [
      'Vien de Nus is cultivated exclusively in the Valle d\u2019Aosta, between Donnas and Avise, in north-west Italy, close to the borders with Switzerland and France, especially in the centre of the valley. Never made as varietal wine, Vien de Nus is blended (minimum 40%) with PETIT ROUGE (maximum 30%) and other local red varieties in the Nus Rouge category of the Valle d\u2019Aosta DOC. The Italian agricultural census of 2000 recorded just 25 ha (62 acres), down from 170 ha (420 acres) in 1970.',
    ],
  },
  {
    name: 'VIGNOLES',
    synonyms: ['Ravat 51'],
    description:
      'French hybrid better known in the United States, mostly for sweeter wines.',
    'origin and parentage': [
      'According to Galet (1979), a complex hybrid obtained in the 1930s by J F Ravat in Marcigny, eastern central France, by crossing Sub\u00e9reux with PINOT NOIR (see PRIOR for the complete Sub\u00e9reux pedigree). However, recent DNA analysis has raised doubts about this parentage (Bautista et al. 2008) and further analyses are needed.',
    ],
    'where its grown': [
      'Vignoles is not included in the official register of varieties in France but, like VIDAL, has found its home much further afield, this time in the United States, particularly for sweet wines in the Finger Lakes region of New York State and for fresh wines at a range of sweetness levels \u2013 often to add floral aromas and fruity flavours to blends \u2013 in the Northeast and Midwest. Introduced in the 1950s, Vignoles\u2019 biggest plantings are in Missouri (208 acres/84 ha in 2009 \u2013 13% of the vineyard), New York (148 acres/60 ha in 2006) and Michigan (85 acres/34 ha in 2006), with smaller areas in Illinois (72 acres/29 ha in 2007), Indiana (only 30 acres/12 ha in 2009 but one of the state\u2019s best varieties), Iowa (18 acres/7 ha in 2006) and Nebraska (7 acres/3 ha in 2007), among others. Recommended producers include Stone Hill in Missouri (all sweetness levels), Hunt Country Vineyards, Keuka Springs, Swedish Hill and Wagner Vineyards in New York.',
    ],
  },
  {
    name: 'VIJARIEGO',
    synonyms: [
      'Bujariego',
      'Diego',
      'Verijadiego Blanco',
      'Vijariego Blanco',
      'Vijiriega Blanca',
      'Vijiriega Com\u00fan',
      'Vijiriego',
      'Vujariego',
    ],
    description:
      'Minor variety found mainly on the Islas Canarias, used for both still and sparkling wines.',
    'origin and parentage': [
      'Vijariego was first described under the name Vigiriega Com\u00fan by Clemente y Rubio (1807) as a variety widespread in Andaluc\u00eda. It is said to have been introduced to the Islas Canarias in the late fifteenth or early sixteenth century together with Moscatel (possibly MUSCAT OF ALEXANDRIA), TORRONT\u00c9S, PALOMINO FINO and LIST\u00c1N PRIETO (Tapia et al. 2007), in all probability from Andaluc\u00eda. It is not a colour mutation of Vijariego Negro (Jorge Zerolo, personal communication; Vouillamoz).',
      'There are numerous different spellings, depending on location and producer, for what the official Spanish regulations for authorized varieties refer to as Vijariega or Vijiriega; and although CHELVA is sometimes called Mant\u00fao Vigiriego or Vigiriego Blanco in Granada, it is not related to the Vijariego described here.',
    ],
    'where its grown': [
      'There were said to be 473 ha (1,169 acres) of Vijariego on the Islas Canarias, Spain, in 2008, and it is authorized in all the island\u2019s DOs, where it is used for both still and sparkling wines thanks to the generally high levels of acidity. On the island of Tenerife, producers of varietal wines include Tanajara and Vi\u00f1\u00e1tigo.',
      'In Granada, the variety had virtually disappeared, partly due to its low alcohol levels, and was found only in some old interplanted vineyards in Sierra de la Contraviesa. In 1984, Manuel Valenzuela of Barranco Oscuro in the Alpujarra planted a new Vijariego vineyard (using the historical name Vigiriega) and they now produce an impressive varietal sparkling wine with no dosage and also use the variety in other white blends. A handful of growers have followed suit, both here and in La Mancha.',
    ],
  },
  {
    name: 'VILANA',
    synonyms: ['Velana'],
    description:
      'The grape of Kr\u00edti (Crete) produces fresh, lightly floral whites.',
    'origin and parentage': [
      'Vilana is a Cretan variety that is genetically close to Piperionos, which is from Acha\u1e2fa but no longer cultivated (Ladoukakis et al. 2005), and to VIDIANO from Kr\u00edti (Biniari and Stavrakakis 2007).',
    ],
    'where its grown': [
      'Vilana is the most important white wine variety on the island of Kr\u00edti, Greece, planted mostly in the eastern half, with a total of 589 ha (1,455 acres) in 2008. The Peza appellation requires 100% Vilana, Sit\u00eda requires 70% (with THRAPSATHIRI), while the variety is authorized in all of the island\u2019s regional whites and must constitute at least 80% of Ir\u00e1kleio (Heraklion) regional wines. The variety\u2019s tendency to overcrop means that it often produces bland, dilute whites and the variety also has a tendency to oxidize in the winery. However, when vines are planted on dry-farmed sandy hillsides and yields are kept in check, they can produce fresh, aromatic whites with aromas of white blossom and flavours of citrus and green apple, sometimes a little spicy. Producers of creditable varietal wines include Lyrarakis, Mediterra, Michalakis and the Peza co-op. A few producers such as Douloufakis are making successful if less typical blends with a small amount of SAUVIGNON BLANC rather than with other local varieties.',
    ],
  },
  {
    name: 'VILLARD BLANC',
    synonyms: ['Seyve-Villard 12-375', 'SV 12-375'],
    description:
      'Light-skinned French hybrid in decline but still quite widely scattered round the globe.',
    'origin and parentage': [
      'A complex hybrid obtained by Bertille Seyve and Victor Villard in Saint-Vallier in the Is\u00e8re, France, by crossing Seibel 6468 with Sub\u00e9reux (for the complete pedigrees, see HELIOS for Seibel 6468 and PRIOR for Sub\u00e9reux). It was used to breed ESPIRIT, ORION, PHOENIX, ROSETTA and STORGOZIA.',
    ],
    'where its grown': [
      'There were 349 ha (862 acres) of Villard Blanc in France in 2008, down from 1,129 ha (2,790 ha) in 1998 and only a tiny fraction of the 21,397 ha (52,873 ha) in 1968, when it was the third most planted white wine variety in France. It is still authorized in many d\u00e9partements across southern and eastern France but recommended in none, probably because the wines are rather ordinary and can be slightly bitter, though the best examples have some citrus freshness. It is still found in some vineyards in the south of France and is also grown on pergolas for table grapes.',
      'It is also grown in the US, generally in the cooler parts of the northern states, although in some regions, eg New York State, it may be hard to ripen fully. Plantings are too few to be included in official state statistics but producers include Goose Watch in New York State, Blue Sky in Illinois, Rushland Ridge in Pennsylvania and Tomasello in New Jersey. Further south, White Oak in Alabama also produce a varietal wine.',
      'Limited plantings can still be found in Australia. Douglas Vale in Port Macquarie and Raleigh Vineyard, both in New South Wales, produce varietal wines. Villard Blanc has also been planted to a limited extent to resist the humid summers of KwaZulu-Natal in South Africa.',
    ],
  },
  {
    name: 'VINCENT',
    synonyms: ['Vineland 49431', 'V 49431'],
    description:
      'Hardy North American hybrid bred in Canada for cold climates.',
    'origin and parentage': [
      'A Vineland 370628 \u00d7 CHELOIS hybrid obtained in 1947 by Ollie A Bradt at the Vineland research centre (now part of Guelph University) in Niagara, Ontario, Canada, in which:',
      '\u2013\u00a0\u00a0\u00a0Vineland 370628 is a Lomanto \u00d7 Seneca hybrid',
      '\u2013\u00a0\u00a0\u00a0Lomanto is a Salado \u00d7 Molinera hybrid, where Molinera is a Vitis vinifera table grape from Spain',
      '\u2013\u00a0\u00a0\u00a0Salado is a De Grasset \u00d7 Brilliant hybrid',
      '\u2013\u00a0\u00a0\u00a0De Grasset is Vitis champinii vine selected by T V Munson',
      '\u2013\u00a0\u00a0\u00a0Brilliant is a Lindley \u00d7 DELAWARE hybrid',
      '\u2013\u00a0\u00a0\u00a0Lindley is a Carter \u00d7 CHASSELAS hybrid',
      '\u2013\u00a0\u00a0\u00a0Carter (also called Mammoth Globe) is an offspring of ISABELLA and another unknown parent selected by Charles Carter in Virginia',
      '\u2013\u00a0\u00a0\u00a0Seneca is a LUGLIENGA \u00d7 Ontario hybrid (see CAYUGA WHITE for the pedigree of Ontario).',
      'Vincent, selected in 1958 and released in 1967, is therefore an extremely complex Vitis labrusca, Vitis vinifera, Vitis champinii, Vitis aestivalis, Vitis lincecumii, Vitis rupestris and Vitis cinerea hybrid.',
    ],
    'where its grown': [
      'Fulkerson in Finger Lakes, New York State, is one of the very few producers to make a varietal wine, which they describe as smooth, low in tannin with plum and black-cherry aromas and flavours. The juice is very dark (much darker than CABERNET SAUVIGNON), making it useful as a blending component. There were 44 acres (18 ha) in New York State in 2006. It is also used by blenders in Canada for its colour.',
    ],
  },
  {
    name: 'VINH\u00c3O',
    synonyms: [
      'Espadeiro Basto',
      'Espadeiro da Tinta',
      'Espadeiro Preto',
      'Negr\u00e3o',
      'Pinh\u00e3o',
      'Sousao',
      'Sous\u00e3o',
      'Sous\u00e3o de Correr',
      'Sous\u00e3o Forte',
      'Sous\u00f3n',
      'Souzao',
      'Souz\u00e3o',
      'Tinta Nacional',
      'Tinta Pa\u00eds',
      'Tinto de Parada',
      'Tinto Nacional',
    ],
    description:
      'High-acid, highly distinctive Portuguese variety making rustic, deeply coloured wines in north-west Iberia.',
    'origin and parentage': [
      'According to the Visconde de Vila Maior (1875), Vinh\u00e3o originates in Minho in north-west Portugal, where it is still the main variety today (Rolando Faustino, personal communication). Around 1790, it was introduced to the Douro, where it was named Sous\u00e3o and was used instead of the prohibited elderflower berries to darken port wines (Rolando Faustino, personal communication). DNA profiling finally ended a long debate and confirmed that Vinh\u00e3o and Sous\u00e3o are one and the same (Ferreira Monteiro et al. 2000). A likely parent\u2013offspring relationship with AMARAL (under the name Cai\u00f1o Bravo) has recently been suggested (D\u00edaz-Losada et al. 2011).',
      'Vinh\u00e3o was used to breed ARGAMAN.',
    ],
    'where its grown': [
      'Vinh\u00e3o produces what are probably the darkest wines in all of Portugal, even darker than those produced by truly red-fleshed varieties. There is so much colour in the skins that the colour leeches into the flesh when the berry is split, giving the impression that it is a red-fleshed variety.',
      'It is the basis for all good reds in the Vinho Verde DOC in the north west of the country, giving well-structured, characterful wines with very high acidity and flavours of cherry and fruits of the forest that have until recently had a mainly local appeal. It is often the dominant or sole variety in some of the most expensive wines and does particularly well in the Basto and Amarante subregions in the south west of the Vinho Verde DOC. After a swing towards white varieties such as LOUREIRO that began in this region in the 1950s, there now seems to be a return to reds and to Vinh\u00e3o in particular and it is now one of the most grafted varieties in local nurseries. Recommended producers of varietal wines include Aphros, Quinta da Palmirinha, Quinta da Raza, Quinta de Agui\u00e3, Quinta de Carape\u00e7os and Quinta de Gomariz; others use it in a blend of local varieties. Quinta de Linhares, among others, turn it into a ros\u00e9.',
      'In the Douro, Sous\u00e3o (or Souz\u00e3o) is found mainly in the old mixed vineyards and its primary role is still to add colour. There are Sous\u00e3o vines in Quinta do Noval\u2019s ungrafted Nacional vineyard but it is the site and the age of the vines that make them special and other plantings of this variety on the Noval estate are less interesting. There are nevertheless some producers who value Sous\u00e3o as a component in their port blends; Paul Symington, for example, of the eponymous group of port houses says they appreciate it for the acidity it brings in a region where freshness may be lacking. New plantings are on the increase thanks to the fashion for deeply coloured wines. Quinta do Vallado make a varietal Douro table wine. Total plantings in Portugal were 2,099 ha (5,187 acres) in 2010.',
      'Spain recorded 573 ha (1,426 acres) of Sous\u00f3n in 2008, virtually all in Galicia in the north west, where it is authorized in the R\u00edas Baixas, Ribeiro and Valdeorras DOs. It is generally used as part of a blend but a few producers make varietal wines: Quinta da Muradella and Santa Marta in Valdeorras (the former in the south of the appellation on the Portuguese border) and Coto Redondo in R\u00edas Baixas, just over the border from the Vinho Verde subregion of Mon\u00e7\u00e3o.',
      'There are also plantings of Souzao in California (63 acres/25 ha in 2009), mostly for inclusion in fortified wines. Belo in St Helena, California, are unusual in making a varietal port-style wine. Campbells of Rutherglen in Victoria, Australia, have also had success with a dry red wine made from a blend of Portuguese varieties including Sous\u00e3o, all from small areas planted in a vineyard block dating back to the 1960s \u2013 so much so that they have plans to expand the area dedicated to these varieties. Others who use Sous\u00e3o as part of a blend include Mazza in Geographe, Western Australia, and D\u2019Arenberg in McLaren Vale.',
      'South Africa had 38 ha (94 acres) of Sous\u00e3o in the ground for port-style wines in 2009.',
    ],
  },
  {
    name: 'VIOGNIER',
    synonyms: ['Viogn\u00e9', 'Vionnier', 'Viognier Jaune', 'Viognier Vert'],
    description:
      'Headily aromatic variety making particularly full-bodied whites \u2013 all over the world now.',
    'origin and parentage': [
      'Viognier is a variety from the northern Rh\u00f4ne in France, probably from the Condrieu and Ampuis wine regions, where it was first mentioned by Faujas in 1781 (R\u00e9zeau 1997): \u2018Deux seules especes de raisins composent ces excellent vins [c\u00f4te-r\u00f4tie], la Serine & le Vionnier\u2019 (\u2018Only two species of grapes compose the excellent wines [c\u00f4te-r\u00f4tie], Serine and Vionnier\u2019). The name Viognier supposedly derives from the French viorne, meaning \u2018viburnum\u2019, but this etymology is doubtful. Through DNA parentage analysis, a parent\u2013offspring relationship has been discovered between Viognier and MONDEUSE BLANCHE (Vouillamoz 2008), which makes Viognier either a half-sibling or a grandparent of SYRAH (see Syrah for the complete pedigree). Viognier is therefore logically a member of the S\u00e9rine ampelographic group (see p XXVII; Bisson 2009). In addition, Viognier has a close genetic relationship with FREISA, so that Viognier is in some way related to NEBBIOLO (Schneider, Boccacci et al. 2004).',
      'It has recently been suggested that Viognier is a sibling of SYRAH (Myles et al. 2011) rather than a half-sibling or a grandparent, as in Vouillamoz (2008), but this can be refuted because Viognier does not have a parent\u2013offspring relationship with DUREZA, Syrah\u2019s other parent (Vouillamoz).',
    ],
    'where its grown': [
      'It is extraordinary to think that this world-famous variety, now planted in virtually every wine region around the globe, including on 4,395 ha (10,869 acres) of vineyard in France in 2009, could be found on only 14 ha (35 acres) of vineyard as recently as fifty years ago. All of those vines were on the steep right bank of the Rh\u00f4ne just south of Lyon, where Viognier had long been producing headily perfumed, full-bodied, rather soft, sometimes sweet, white wines on the granite terraces of Condrieu and in Ch\u00e2teau Grillet\u2019s unusual amphitheatre just to the south. It had also long been added in small quantities (generally 5% or so, although 20% is allowed in the vineyard) to be fermented with the local SYRAH grapes in C\u00f4te R\u00f4tie just north of Condrieu so as to help stabilize the colour and occasionally add perfume.',
      'The variety was almost on the point of extinction in the late 1960s because the plant material then grown was highly susceptible to coulure and yields were discouragingly low. But as the particularly distinctive character of the variety \u2013 descriptors include apricots, honeysuckle, May blossom, gingerbread \u2013 became known and appreciated outside its region of origin, Viognier\u2019s fame spread so that by the 1980s curious growers from the Languedoc to California were experimenting with it and the quality of cuttings improved considerably. Condrieu today is a highly fashionable, usually dry wine that, when successful, combines a firm, rich undertow of extract with Viognier\u2019s hedonistic cocktail of aromas \u2013 even if it rarely improves with age. Ch\u00e2teau Grillet, recently acquired by the owner of Bordeaux first growth Ch\u00e2teau Latour, is in the process of being restored to its former glory when it did seem to represent a particularly lemony, mineral-scented answer to Condrieu that could improve in bottle. And many C\u00f4te R\u00f4tie growers today still add a small proportion of Viognier to their red wine fermentation vats, from generally healthier vines than half a century ago. But the variety is also planted very much more widely in the northern and southern Rh\u00f4ne today. It is a permitted ingredient in the C\u00f4tes du Rh\u00f4ne, C\u00f4te du Rh\u00f4ne-Villages and Lirac appellations \u2013 although it is not allowed in Hermitage, Crozes-Hermitage, Saint-Joseph, nor Ch\u00e2teauneuf-du-Pape, presumably because the rules for these well-established appellations merely embraced the status quo when they were drawn up. Recommended producers in the northern Rh\u00f4ne include Cuilleron, Gangloff, Guigal, Ni\u00e9ro, Christophe Pichon, Ren\u00e9 Rostaing, Georges Vernay, Fran\u00e7ois Villard and Les Vins de Vienne.',
      'Today, however, Viognier is recommended or authorized in much of southern France. Quantitatively its greatest presence is in the Languedoc, where it is used to make aromatic vins de pays wines, too often lacking acidity and disappointing after an initial whiff of a vaguely floral and unconvincing perfume, although there are exceptions and Viognier is often an ingredient with such varieties as Grenache Blanc (GARNACHA BLANCA), sometimes CHARDONNAY, MARSANNE and/or ROUSSANNE, in some intriguing blended full-bodied dry whites, particularly in Roussillon. The variety needs to be fully ripe, and not overcropped, in order to deliver its characteristic perfume \u2013 which is usually signalled by a deep, golden colour.',
      'Viognier has not really caught on in Italy,\nalthough Ascheri have an established tradition of producing it in Piemonte, as Castel de Paolis do in Lazio. It is also planted in the Maremma and on Sicilia.',
      'In Spain, Viognier is even less important: just 18 ha (44 acres) in 2008, mostly in Castilla-La Mancha, often blended with GARNACHA BLANCA or other Rh\u00f4ne varieties. In Priorat, producers such as Celler Mas d\u2019En Just are trying their hand at SYRAH plus a little Viognier. Quinta do Monte d\u2019Oiro in the Lisboa region and Quinta do Lagar Novo in Alenquer are two of the few producers of varietal Viognier in Portugal, where there were 82 ha (203 acres) in 2010.',
      'A little is grown in the Pfalz in Germany, while Graf Hardegg in the Weinviertel is Austria\u2019s specialist Viognier producer. Hungary had 5 ha (12 acres) in 2008, with Tibor Gal producing a varietal version in Eger. Gerovassiliou is the chief proponent of the variety in Greece. There were also 15 ha (37 acres) in Turkey, 7 ha (17 acres) on Malta and at least 50 ha (124 acres) in Israel in 2010.',
      'As in France, Viognier plantings in California have increased remarkably over the last few decades, up from the first 25 acres (10 ha) in 1982 to 2,993 acres (1,211 ha) in 2010, particularly in the Central Coast. In California it is moderately vigorous and generally grown on deep, alluvial soils but has to reach full maturity, generally with plenty of sun exposure, to reveal its characteristic scent. Recommended producers include Calera of Mount Harlan, who have particularly long experience of the variety, and Darioush of Napa Valley. Thomas George of Russian River Valley is a Viognier specialist.',
      'There were only 183 acres (74 ha) of Viognier in Oregon in 2008, almost all planted in the last ten years, with Cristom being a notable pioneer. The fragrant, relatively full-bodied PINOT GRIS that is the state\u2019s signature white wine leaves less scope for Viognier than elsewhere. Thanks to its enthusiasm for all things Rh\u00f4neish, Washington has a little more of the variety (its famous RIESLING may be aromatic but is not full-bodied): 390 acres (158 ha) in the ground by 2011, a very small proportion of the state\u2019s total vineyard.',
      'In Virginia, on the other hand, Viognier is regarded as the state\u2019s signature grape. The area devoted to it doubled between 2003 and 2010: there were 229 acres (93 ha) in 2010, only about half the area devoted to CHARDONNAY but much more than any other white wine grape. Horton pioneered the variety and was delighted to find how much better its thick-skinned berries withstood Virginia\u2019s humid summers than CHARDONNAY. Keswick and Veritas are other reliable producers.',
      'The variety is far from uncommon in Texas, and in western Canada there were 165 acres (67 ha) of Viognier in British Columbia in 2008.',
      'In South America, Viognier has been embraced slightly more enthusiastically in Argentina, where there were 714 ha (1,764 acres) in 2008 and successful varietal wines are produced by the likes of Chakana, Mauricio Lorca and Trapiche, than in Chile, where there were 263 ha (650 acres) in 2008. Notable Chilean varietal Viogniers are made by Vi\u00f1a Emiliana and Anakena but, as in Argentina, vines tend to be too young for very powerful varietal expression. There were 32 ha (79 acres) in Uruguay in 2009 and 7 ha (17 acres) in Brazil in 2007.',
      'The practice of co-fermentation traditional in the northern Rh\u00f4ne has become fashionable in other parts of the world where SYRAH flourishes, including California and Australia. For example, Ridge\u2019s Lytton West Syrah from Dry Creek Valley contains around 6% Viognier, and in Australia Clonakilla (Canberra), Torbreck (Barossa Valley) and Yering Station (Yarra Valley) all include a percentage of Viognier in their top Shiraz (Syrah) wines. One South Australian winemaker has done so much for the cause of Viognier that her name has become almost synonymous with this variety that was first trialled at Heathcote Winery in central Victoria in 1978 (Rose 2006): Yalumba\u2019s Louisa Rose, who has spearheaded work on clonal selection at the company\u2019s own nursery and set up the first ever Viognier Symposium in 2002. It was Yalumba that initiated the first commercial planting of Viognier in the Eden Valley in 1980. Today more than 500 wineries produce a Viognier in Australia, whose generally warm climate develops flavours of apricot, peaches, white flowers, occasionally ginger, and luscious texture in these wines. Recommended producers in addition to Yalumba include D\u2019Arenberg, Haselgrove, Petaluma and Temple Bruer in South Australia and Trentham Estate in New South Wales. Total plantings in Australia in 2008 amounted to 4,401 ha (10,875 acres).',
      'While New Zealand has a much smaller total area planted to Viognier, it too has seen a steep increase in plantings: from 155 ha (383 acres) in 2005 to 854 ha (2,110 acres) in 2010. The naturally high acidity levels in Kiwi wine counteract this variety\u2019s richness. Recommended producers include Dry River in Martinborough, Herzog in Marlborough, Millton in Gisborne and Craggy Range in Hawke\u2019s Bay. Several Hawke\u2019s Bay producers such as Trinity Hill are also following the C\u00f4te R\u00f4tie model of co-fermenting Viognier with SYRAH.',
      'Viognier is also increasingly fashionable in South Africa, where plantings had reached 859 ha (2,123 acres) by 2008, planted throughout the country\u2019s wine-growing regions. Many top producers such as Adi Badenhorst, Lammershoek, Mullineux and Eben Sadie in the Swartland, Tulbagh Mountain Vineyard in the Coastal Region and Quoin Rock in Stellenbosch use Viognier to add perfume and texture to complex white blends, while others such as Eagle\u2019s Nest, Elgin Valley, Fairview, The Foundry and The Winery of Good Hope are producing creditable varietal wines, often oaked.',
    ],
  },
  {
    name: 'VIOLENTO',
    synonyms: ['Violenti'],
    description:
      'Pink-skinned variety from the Greek island of Z\u00e1kynthos used mostly in blends.',
    'origin and parentage': [
      'Violento is local to the Greek island of Z\u00e1kynthos in the Ionian Sea off the north-western coast of the Pelop\u00f3nnisos (Peloponnese). It has traditionally been considered to be a clone of RODITIS but this has been contradicted by DNA profiling (Boutaris 2000).',
    ],
    'where its grown': [
      'Violento, grown on Z\u00e1kynthos, Greece, produces wines that have rather high alcohol but are typically low in acidity and are usually part of a local ros\u00e9 or white blend. Producers include Comoutos and Callinico.',
    ],
  },
  {
    name: 'VITAL',
    synonyms: ['Boal Bonifacio', 'Malvasia Corada', 'Malvasia Fina do Douro'],
    description: 'Low-acid variety grown mainly in central Portugal.',
    'origin and parentage': [
      'Very little is known about the origins of this Portuguese variety although it is likely to come from the north or centre of the country, probably in the area north of Lisboa. DNA profiling shows that it is not identical to MALVASIA FINA (Vouillamoz), despite the confusing synonyms mentioned above.',
    ],
    'where its grown': [
      'Wines made from Vital are generally low in acidity and pretty bland, quickly losing any youthful aroma they may have had, hence the need for blending. It is grown mainly in central Portugal \u2013 in the Lisboa region as well as in the Alentejo and Tejo. Sanguinhal\u2019s Quinta de S\u00e3o Francisco (\u00d3bidos DOC) is a blend of Vital, FERN\u00c3O PIRES and ARINTO DE BUCELAS. It is also planted further north in the Douro.',
      'However, thanks to the late Ant\u00f3nio Carvalho, a Portuguese winemaker passionate about indigenous varieties in his home region just north of Lisboa, Vital has now been shown to have much greater quality potential. He made his first varietal Vital from vines in the mountainous Montejunto area near the town of Cadaval, where the variety thrives thanks to the dual influences of altitude and windy proximity to the Atlantic on a northern exposure. Since Carvalho\u2019s death, his partner Marta Soares has continued to make this elegant, citrus- and mineral-scented white under the name Casal Figueira.',
      'There were 1,080 ha (2,669 acres) in Portugal in 2010, some of its produce being distilled into brandy.',
    ],
  },
  {
    name: 'VITOVSKA',
    synonyms: ['Vitouska'],
    description:
      'Makes rare but high-quality white in the Italy\u2013Slovenia border region around Trieste.',
    'origin and parentage': [
      'Vitovska is a specific variety of the Carso/Kras/Karst region which straddles north-eastern Italy and western Slovenia. Its name could derive from the local Slovenian dialect, or from the village of Vitovlje in the Vipavska Dolina region. DNA parentage analysis has recently found that Vitovska is a natural cross between MALVASIA BIANCA LUNGA and PROSECCO (Crespan et al. 2007). Prosecco is widespread in Karst, and although Malvasia Bianca Lunga has never been mentioned there, Karst is still the most likely region of origin for Vitovska. Contrary to the consensus in the literature, recent DNA profiling has shown that VITOVSKA GRGANJA (or Vitovska Garganija) is not identical to Vitovska, but it may be an offspring of Vitovska (\u0160tajner et al. 2008).',
    ],
    'where its grown': [
      'Vitovska has long been cultivated exclusively in the Carso/Kras/Karst region, in particular in the villages of Sg\u00f3nico and Duino-Aurisina. It had almost disappeared during the twentieth century and was rescued in the 1980s by winemakers such as Edi Kante and Benjamin Zidarich. Today, after MALVAZIJA ISTARSKA, Vitovska is the most widely cultivated white in the Italian province of Trieste and the neighbouring area in Slovenia (all one province before the Second World War). However, there is still much confusion between Vitovska and VITOVSKA GRGANJA.',
      'A varietal Vitovska is included in the Carso DOC and made by a few determined Slovenian producers based in Italy. The wines tend to be fresh, dry or off dry with aromas of hay, sage, pear, quince and lemon and an attractive sour edge. They are also relatively expensive. Zidarich\u2019s version is tangy and firmly structured thanks to extended skin contact and generally hands-off winemaking. Other pioneering producers include Edi Kante, Paolo & Walter Vodopivec, who have been producing a special amphora-made version since 2005, Silvano Ferluga and \u0160kerk.',
    ],
  },
  {
    name: 'VITOVSKA GRGANJA',
    synonyms: ['Grganka', 'Racuk', 'Vitovska Garganija'],
    description: 'Rare Slovenian white attracting increasing recognition.',
    'origin and parentage': [
      'Recent DNA profiling has shown that, contrary to popular belief, Vitovska Grganja is not identical to VITOVSKA, of which it is a possible offspring (\u0160tajner et al. 2008; Rusjan et al. 2010), which means that it probably also originates in the Carso/Kras/Karst region on the Italian-Slovenian border. It was first mentioned in 1844 by Matija Vertovec, a priest and teacher in Vipava (Grmek 2007). The proposed parent\u2013offspring relationship would make Vitovska Grganja a grandchild of MALVASIA BIANCA LUNGA and PROSECCO (see VITOVSKA).',
    ],
    'where its grown': [
      'Vitovska Grganja was once far more important than it is today (Grmek 2007) but is still found in the Carso/Kras/Karst region, and in Vipavska Dolina, a little to the north in western Slovenia, and is gaining in importance once more. Given the common confusion between VITOVSKA and Vitovska Grganja, exact areas are hard to confirm although there were apparently 19 ha (47 acres) of the latter in Slovenia in 2009.\nWines have moderate alcohol levels, quite marked acidity and peachy, even cinnamon aromas that are like a less pronounced MALVAZIJA ISTARSKA. Recommended Slovenian producers include \u010cotar, Furlan, Ren\u010del and Vinakras.',
    ],
  },
  {
    name: 'VLACHIKO',
    synonyms: [
      'Blachiko',
      'Blachos',
      'Vlachico de Jannina',
      'Vlachos',
      'Vlachs',
      'Vlacos',
      'Vlahico',
      'Vlahos',
    ],
    description:
      'Greek variety that makes svelte, dark-fruited wines and deserves more attention.',
    'origin and parentage': [
      'Vlachiko is local to the region of Io\u00e1nnina in \u00cdpeiros (Epirus), western mainland Greece. Its name comes from vlacos, meaning \u2018countryman\u2019 (Galet 2000).',
    ],
    'where its grown': [
      'Vlachiko was traditionally grown in \u00cdpeiros, Greece, and was often blended with DEBINA and BEKARI to make a sparkling ros\u00e9 (still made by Z\u00edtsa producers such as the Io\u00e1nnina co-operative and Glinavos). However, Katogi & Strofilia planted a new Vlachiko vineyard in 2001 in the mountainous Zag\u00f3ri region in \u00cdpeiros and now make a varietal wine known as Rossiu di Munte (\u2018red of the mountains\u2019) under the Katogi Averoff label \u2013 a wine with fresh acidity, very fine tannins and restrained, peppery, cassis fruit. Glinavos also use it in red blends with varieties such as AGIORGITIKO and Bekari.',
    ],
  },
  {
    name: 'VLA\u0160KA',
    synonyms: [
      'Mara\u0161kina Velog Zrna',
      'Prejica',
      'Vezuljka',
      '\u017dutuja',
    ],
    description:
      'Rare, low-acid Croatian variety found only in the area around Split.',
    'origin and parentage': [
      'Vla\u0161ka is Croatian for \u2018Wallachia\u2019 (what is now southern Romania) and this variety is said to have been introduced to the regions of Ka\u0161tela and Trogir north of Split from Romania in the twentieth century (Zduni\u0107 2005).',
    ],
    'where its grown': [
      'Vla\u0161ka, along with several other autochthonous varieties, is part of a revitalization project involving clonal selection and replanting in Croatia and although it is authorized throughout the Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine region, it is currently found only in Ka\u0161tela and Trogir near the coastal town of Split.',
    ],
  },
  {
    name: 'VLOSH',
    synonyms: ['Vloshi', 'Vlosk'],
    description: 'Dark-skinned Albanian variety in decline.',
    'origin and parentage': [
      'Vlosh originates in the region around the coastal town and lagoon of Narta, south-west Albania, and may well be named after the nearby town of Vlosh, between Narta and Berat. However, it has also been suggested that the name derives from a Greek word meaning \u2018sweet grape\u2019.',
      'Lambert-Gocs (2007) suggested that Vlosh is a possible ancestor of CABERNET SAUVIGNON but this is contradicted by DNA profiling (see Cabernet Sauvignon).',
    ],
    'where its grown': [
      'Vlosh is found mainly in south-west Albania, particularly around its birthplace, but is in decline despite the quality of the wine produced due to its lack of colour and moderate yields (Maul et al. 2008). Wines are typically full-bodied and astringent, often made in an oxidized, rancio style. It is also planted for the table.',
    ],
  },
  {
    name: 'VOSKEAT',
    synonyms: [
      'Hardzhi',
      'Kanacicheni',
      'Katviacik',
      'Khardji',
      'Kharji',
      'Pishik Gezi',
      'Pscigi',
      'Voskeate',
      'Voskehat',
      'Xardji',
    ],
    description:
      'Productive old Armenian variety used mostly for fortified whites.',
    'origin and parentage': [
      'Voskeat is a very old variety that probably originates in the Ashtarak region in western Armenia, where it shows significant morphological diversity (Chkhartishvili and Betsiashvili 2004). Traditionally called Khardji or Kharji, it was later named after the village of Voskehat, just west of the capital Yerevan.',
    ],
    'where its grown': [
      'Voskeat is very well suited to the production of sweet fortified wines in Armenia, the most famous of which is Ochakan, made in the image of Madeira (Carbonneau 1995). It is also used to make sparkling wine, usually as part of a blend, and for the table. Found mainly in the Ararat region. Ginekar, for example, produce a dry version, Ijevan Wine Factory a semi-sweet one. It is also popular in the Nakhchivan Autonomous Republic in Azerbaijan, where it is known as Kharji and used to make sherry- and Madeira-style wines.',
    ],
  },
  {
    name: 'VRANAC',
    synonyms: ['Vranac Crmnichki', 'Vranac Crni', 'Vranac Prhljavac', 'Vranec'],
    description:
      'Balkan variety capable of producing powerful, high-quality, ageworthy reds.',
    'origin and parentage': [
      'Vranac, meaning \u2018strong black\u2019 or \u2018black stallion\u2019, is the most important indigenous variety of the region that includes today\u2019s Montenegro, part of Serbia and southern Croatia, where it has allegedly been cultivated since the Middle Ages and where it shows a high degree of heterogeneity (Avramov and del Zan 2004), suggesting it is an ancient variety. DNA parentage analysis has revealed a parent\u2013offspring relationship with TRIBIDRAG (Maleti\u0107 et al. 2004), otherwise known as Zinfandel, which makes it a half-sibling (or grandparent) of PLAVAC MALI, GRK, Crljenak Crni (no longer cultivated) and PLAVINA (see Tribidrag for the pedigree diagram). This could also explain why it has often been confused with Krato\u0161ija (Maras et al. 2004), the Montenegrin synonym for Tribidrag, with which it is often interplanted. Although Vranac is today the major black-berried variety in the Republic of Macedonia, genetic comparison has revealed that it is quite distinct from the other local varieties and that it was most probably introduced there in the mid twentieth century (Galet 2000; \u0160tajner, Angelova et al. 2009).',
    ],
    'where its grown': [
      'Vranac is widely planted in Montenegro, the Republic of Macedonia (particularly in Povardarje in the south east), Serbia and Kosovo. It is also important in southern Croatia (390 ha/964 acres in 2008) and in Herzegovina. Wines are deeply coloured, full-bodied, tannic, high in alcohol and have soft acidity plus rich flavours of both black and red fruit. The best take well to oak and become more complex with age. Its wine is also used to add colour to blends. Given the heterogeneity of this variety, research is underway in Macedonia to select the best-quality clones. Producers include Milovi\u0107, Planta\u017ee and Sjeklo\u0107a in Montenegro, Popova Kula, Skovin, Tikve\u0161 and Veritas in Macedonia, Stonecastle in Kosovo, Jovi\u0107 in Serbia and Vukoje in Herzegovina.',
    ],
  },
  {
    name: 'VUGAVA',
    synonyms: ['Bugava', 'Ugava', 'Vi\u0161kulja', 'Vugava Bijela'],
    description:
      'High-quality, aromatic variety found on the Croatian island of Vis.',
    'origin and parentage': [
      'Vugava is an old variety from the island of Vis in Dalmacija (Dalmatia), south-west Croatia.',
    ],
    'where its grown': [
      'Vugava is authorized throughout the Srednja i Ju\u017ena Dalmacija (Central and Southern Dalmatia) wine region in Croatia but is found mainly on the island of Vis, with limited plantings on neighbouring islands and on the coast. Wines are typically intense and fairly alcoholic with the flavour of ripe apricots so it is easy to see why the variety has been confused with VIOGNIER. Producers on Vis include Lipanovi\u0107, the Pod\u0161pilje co-operative, Rokis and Svili\u010di\u0107.',
    ],
  },
  {
    name: 'W\u00dcRZER',
    synonyms: ['Alzey 10487'],
    description: 'Declining, perfumed German cross best in small doses.',
    'origin and parentage': [
      'A SAVAGNIN ROSE \u00d7 M\u00dcLLER-THURGAU cross bred by Georg Scheu at Alzey in 1932, authorized in Germany in 1978. It was named after the spicy (w\u00fcrzig) aromas of the wine.',
    ],
    'where its grown': [
      'There are 66 ha (163 acres) in Germany, mostly in Rheinhessen and the Nahe, down from a peak of 121 ha (299 acres) in 1995. Wines are generally soft and too headily perfumed not to be blended with other varieties. Producers of varietal wines include Sitzius and Schillingshof in the Nahe and Schmitt\u2019s Kinder in Franken. There are also very limited plantings in Switzerland (0.33 ha/0.8 acres).',
      'Just 6 ha (15 acres) of W\u00fcrzer grow in England but in such a cool climate it ripens too late to be successful in most sites and it is declining in favour of earlier-ripening varieties such as BACCHUS.',
      'Seifried in Nelson, New Zealand, have a few rows and make a varietal wine that is as spicy as its name suggests.',
    ],
  },
  {
    name: 'XARELLO',
    synonyms: [
      'Cartoix\u00e0',
      'Pansa Blanca',
      'Pansal',
      'Premsal Blanca',
      'Xarel-lo',
      'Xarel\u00b7lo',
      'Xerello',
    ],
    description:
      'High-quality Catalan with a strong personality making firm wines both still and sparkling.',
    'origin and parentage': [
      'Xarello was first mentioned under the name Xerello in 1785 in Sitges in Pened\u00e8s (Fav\u00e0 i Agud 2001), its most likely place of origin. According to this author, the name Xarello comes from the Italian chiarello, meaning \u2018claret\u2019, a term originally used to refer to wine that was light red in colour. DNA profiling suggests that Xarello might be related to MACABEO (Iba\u00f1ez et al. 2003), with which it is often blended and sometimes even confused.',
      'A pink-berried mutation called Xarello Rosado, also known as Pansa Rosada, has been observed near Barcelona (Iba\u00f1ez et al. 2003) and is authorized in the Alella DO.',
    ],
    'where its grown': [
      'The official variety list in Spain has adopted the Xarello spelling, perhaps because not many keyboards have the raised Catalan dot to hand for Xarel\u00b7lo, though the hyphenated form Xarel-lo is probably more common.',
      'Xarello is Catalunya\u2019s highest-quality light-skinned variety for ageworthy still whites and sparkling Cavas thanks to its combination of power, density, high acidity and relatively high sugar levels. Its main role is in Cava, partnered with PARELLADA and MACABEO, throughout Catalunya \u2013 particularly in Pened\u00e8s \u2013 in north-east Spain. In 2008, almost 99% of the Spain\u2019s 8,043 ha (19,875 acres) were planted in Catalunya, where it is authorized in most of the region\u2019s DOs. It is valued by Cava producers because it contributes body, structure and freshness and increases a wine\u2019s longevity. Studies carried out at Barcelona and Washington universities found that Xarello has a very high level of the anti-oxidant resveratrol, which helps to protect it against disease in the vineyard. One of Spain\u2019s finest Cavas, Recaredo\u2019s single-vineyard Tur\u00f3 d\u2019en Mota, is made from 100% Xarello, and Gramona\u2019s fine Celler Batlle is dominated by Xarello.',
      'However, it can also produce powerful still wines, sometimes as part of a blend with varieties such as GARNACHA BLANCA, MACABEO or occasionally CHARDONNAY. As a varietal wine, it can be a little austere rather than overwhelmingly fruity but it often has aromas of citrus and pear as well as a herbal note. Producers of still, varietal wines include Albet i Noya, Ar\u00e7 Blanc, Bolet, Can R\u00e0fols dels Caus, Donzella, El Cep (L\u2019Alzinar), Gramona, Mas Rabassa, Nadal, Parat\u00f3, Pardal, Par\u00e9s Balt\u00e0, Sabat\u00e9 i Roca and Torell\u00f3.',
    ],
  },
  {
    name: 'XINOMAVRO',
    synonyms: [
      'Csinomavro',
      'Mavro',
      'Mavro Naoussis',
      'Mavro Naoustino',
      'Mavro Xyno',
      'Naouses Mavro',
      'Naoustiano',
      'Naoystiro Mavro',
      'Negroska Popolka',
      'Niaousa',
      'Niasoustino',
      'Pipoliko',
      'Pipolka',
      'Popoliko',
      'Popolka',
      'Xinogaltso',
      'Xynomavro Bolgar',
      'Xynomavro Naoussis',
      'Xynomavro of N\u00e1oussa',
      'Xynomavron',
      'Zinomavro',
      'Zynomavro',
    ],
    description:
      'Top-quality, widely planted but pernickety, high-acid Greek variety.',
    'origin and parentage': [
      'Xinomavro, meaning \u2018acid black\u2019 in reference to the grape\u2019s acidity, is the dominant red variety in northern Greece. At least three main clones have been distinguished in the N\u00e1oussa region, where it most likely originates from (Galet 2000; Manessis 2000; Nikolau and Michos 2004).',
      'Some have suggested a similarity between Xinomavro and PINOT NOIR, or between Ximomavro and NEBBIOLO, but DNA analysis has shown it to be genetically distinct from both.',
    ],
    'where its grown': [
      'Xinomavro was the second most planted dark-skinned variety in Greece in 2008 (2,389 ha/5,903 acres) after AGIORGITIKO and the most widely planted variety in central Makedon\u00eda (749 ha/1,851 acres) and in western Makedon\u00eda (1,376 ha/3,400 acres). It is best known as the sole variety allowed in the N\u00e1oussa appellation but is showing increasingly impressive results in the higher, cooler and windier vineyards further north in Am\u00fdnteo, an appellation that also requires 100% Xinomavro. In Goum\u00e9nissa further east, at least 20% NEGOSKA must be blended in; and in the Raps\u00e1ni appellation to the south, near Mount \u00d3lympos in Thessal\u00eda, Xinomavro is an equal partner with KRASSATO and STAVROTO.',
      'Wines vary enormously in style but generally share high acidity and excellent potential for ageing. Colour stability can be a problem so that wines may be relatively pale and with time become brick-like in colour, rather like NEBBIOLO. To generalize further is difficult since there is considerable clonal variation and a wide range of winemaking influences such as use of oak during maturation. Tannins may be dry and even angular in youth but time in bottle can soften them and create complexity and elegance. In young wines the aromas are dominated by red fruits such as strawberry and plum but with age these change to more savoury aromas of tomato, olive and dried fruit. There is also a strong trend to blend Ximomavro with SYRAH or MERLOT, to fill out the palate. Recommended producers include Alpha Estate, Argatia, Boutari, Foundis, Katogi & Strofilia, Kir-Yiannis and Tsantali (particularly for Raps\u00e1ni).',
      'Several different clones have been identified (ampelographically rather than genetically). Of these, the Velvent\u00f3s clone is better suited to cooler climates, ripens earlier and has softer tannins, whereas the Yiannakochori clone tends to be more powerful and tannic (Lazarakis 2005).',
      'Xinomavro is also responsible for some increasingly good ros\u00e9 wines, still or sparkling, and at various sweetness levels.',
    ],
  },
  {
    name: 'XYNISTERI',
    synonyms: [
      'Aspro Kyprou',
      'Cipro Bianco',
      'Hebron Blanc',
      'Hebron White',
      'Hibron Blanc',
      'Koumantaria',
      'Lefko Kyprou',
      'Lefko Kyproy',
      'Topiko Aspro',
      'Xinisteri',
      'Xynistera',
      'Xynisteri Aspro Kyprou',
    ],
    description:
      'Widely planted Cypriot variety capable of making fresh, citrusy whites.',
    'origin and parentage': [
      'Xynisteri is indigenous to the island of Cyprus, where it was first mentioned, spelt Xinisteri, among the better quality wines of the island in 1881 in volume 17 of the Italian Bolletino consolare pubblicato per cura del Ministero per gli Affari Esteri, and then several years later by French ampelographer Pierre Mouillefert (1893) as the best and most widespread white variety of Cyprus.',
    ],
    'where its grown': [
      'Xynisteri, the most common white wine grape on Cyprus (2,227 ha/5,503 acres in 2010, ie nearly 23% of the total vineyard area) is used to produce both dry white wines and Commandaria, the island\u2019s sweet, usually fortified, dried-grape wine. Table wines are generally light and lemony with moderate alcohol levels. Planted at higher altitudes, the variety produces wines that are more vibrant and mineral but the variety is often refreshed and/or filled out by blending with international varieties such as S\u00c9MILLON, CHARDONNAY or SAUVIGNON BLANC, occasionally with a light touch of oak and, in a few instances, with a dollop of MUSCAT OF ALEXANDRIA. Some examples have a slightly grippy texture, attractive in moderation. Recommended producers include Aes Ambelis, Constantinou, Ezousa, Kolios, Kyperounda, Tsiakkas, Vasilikon, Vlassides and Zambartas.',
    ],
  },
  {
    name: 'YAMABUDO',
    synonyms: ['Crimson Glory Vine', 'Meoru'],
    description:
      'Member of an Asian vine species producing fresh, leafy wines in Japan.',
    'origin and parentage': [
      'Yamabudo, literally \u2018wild grape\u2019 in Japanese, is the common name of Vitis coignetiae, an indigenous Asian vine species that French ampelographer Jules-Emile Planchon named in honour of M and Mme Coignet from Lyon, southern France, who brought seeds back to Victor Pulliat, another French ampelographer, after their trip to Japan in 1875.',
    ],
    'where its grown': [
      'Yamabudo produces deeply coloured reds with leafy notes and high acidity. There were 158 ha (390 acres) planted in Japan in 2008, mainly in Okayama, Nagano, Yamagata, Iwate and Aomori prefectures, but less than half of this area was for wine. Recommended producers include Gassan Wine, Hirizen Wine and Kuzumaki-Kogen. Also found in Korea.',
    ],
  },
  {
    name: 'YAPINCAK',
    synonyms: [
      'Erkek Yap\u0131ncak',
      'K\u0131nal\u0131 Yap\u0131ncak',
      'Yapakak',
      'Yap\u0131ndjac',
    ],
    description:
      'Minor Turkish variety grown on the north coast of the Sea of Marmara.',
    'origin and parentage': [
      'Yap\u0131ncak probably originates in the province of Tekirda\u011f on the north side of the Sea of Marmara, north-western Turkey. The synonym K\u0131nal\u0131 Yap\u0131ncak, meaning \u2018henna-ed Yap\u0131ncak\u2019, makes reference to the red-flecked berries, which have given rise to the nickname Yap\u0131ncak for girls with freckles, suggesting it was once much more widely planted (Umay \u00c7eviker, personal communication).',
    ],
    'where its grown': [
      'It is grown exclusively in the Marmara region, around the coastal villages of \u015eark\u00f6y and M\u00fcrefte in Turkey. Wines are typically medium-bodied, low in acidity (which is why it is generally blended), with aromas of golden apple, pear and quince. Melen produce a sweet wine from this variety, whose leaves are also the preferred choice for the production of yaprak salamura (brined or pickled vine leaves; G\u00fclc\u00fc 2010). Pa\u015faeli have just produced their first vintage of dry, varietal Yap\u0131ncak, which is creamy but has respectable acidity and some minerality. It is also used for the table.',
    ],
  },
  {
    name: 'ZA\u010cINAK',
    synonyms: ['Krajinsko Crno', 'Negotinsko Crno', 'Zachinak', 'Zazinak'],
    description: 'Serbian variety contributing colour to red blends.',
    'origin and parentage': [
      'Za\u010dinak is an old Balkan variety, probably originating in Serbia, where it shows significant morphological diversity (Avramov and del Zan 2004).',
    ],
    'where its grown': [
      'Za\u010dinak is grown in Serbia, and also to a limited extent in the Republic of Macedonia, as a useful variety for adding colour to blends. Bra\u0107a Rajkovi\u0107, in \u017dupa in south-eastern Serbia, for example, blend it with the paler PROKUPAC (known locally as Rskavac) and VRANAC.',
    ],
  },
  {
    name: 'ZAKYNTHINO',
    synonyms: ['Zachara', 'Zacharo', 'Zakinthino'],
    description: 'Ancient Greek island variety being modestly revived.',
    'origin and parentage': [
      'As suggested by its name, Zakynthino may well come from the island of Z\u00e1kynthos in the Ionian Sea, off the western coast of the Greek Pelop\u00f3nnisos (Peloponnese).',
    ],
    'where its grown': [
      'Producers in Kefaloni\u00e1, Greece, who seem to have a virtual monopoly on this variety, confirm the view that it came to their island from Z\u00e1kynthos but suggest its arrival was a good 500 years ago. It has been neglected until recently, when Metaxa and Gentilini started bottling it as a varietal wine; the former also blend it with ROBOLA and make both dry and off-dry versions. Wines are lightly aromatic, showing mostly citrus and apricot characteristics but also slight floral notes. Acidity is generally moderate to fresh and there seems to be more intensity on the nose than on the palate. There are around 10 ha (25 acres) in the south west of the island and it is also found in the northern Pelop\u00f3nnisos, where Antonopolous have recently planted a small experimental vineyard.',
    ],
  },
  {
    name: 'ZALAGY\u00d6NGYE',
    synonyms: [
      'Ecs-24',
      'Egri Csillagok 24',
      'Pearl of Zala',
      'Zala Dende',
      'Zhemchug Zala',
    ],
    description:
      'High-yielding, widely planted Hungarian hybrid producing generally inferior wines.',
    'origin and parentage': [
      'Zalagy\u00f6ngye is an Eger 2 \u00d7 CSABA GY\u00d6NGYE hybrid obtained in 1957 by J\u00f3zsef Csizmazia and L\u00e1szl\u00f3 Bereznai at the research station for viticulture and oenology in K\u00f6lyuktet\u00f6, Eger region, Hungary (see BIANCA for the pedigree of Eger 2).',
    ],
    'where its grown': [
      'Zalagy\u00f6ngye is widely planted on the Alf\u00f6ld (Great Plain) in southern central Hungary. Its high-acid wines are used mainly as a basis for fizz, although it is also grown for the table. There were a substantial 2,243 ha (5,543 acres) in 2008 and producers include Zolt\u00e1n Farkas, J\u00f3zsef Locskai, J\u00f3zsef Nagy, \u00d6reghegy Sz\u0151l\u0151birtok and J\u00f3zsef V\u00e1mosi.',
    ],
  },
  {
    name: 'ZALEMA',
    synonyms: ['Del Pipajo', 'Rebazo', 'Salemo', 'Zalemo'],
    description:
      'Southern Spanish variety that is light in every way and used to make fortified wines.',
    'origin and parentage': [
      'Zalema is a rare variety from the province of Huelva in Andaluc\u00eda, south-west Spain. According to DNA profiling, Zalema could be genetically close to Blanco Castellano (ALCA\u00d1\u00d3N) from the province of Castell\u00f3n in the autonomous community of Valencia, on the opposite side of the country, where Zalema is also grown to a very limited extent (Iba\u00f1ez et al. 2003).',
      'Zalema is sometimes confusingly known under the synonyms Perruna (also a synonym for PERRUNO) or Torront\u00e9s de Montilla (see TORRONT\u00c9S).',
    ],
    'where its grown': [
      'Wines are light and pretty neutral with a slight note of almonds and the unfortunate tendency to oxidize. They are used mainly in the production of fortified wines in the Condado de Huelva DO in Andaluc\u00eda in southern Spain and to a lesser extent in Montilla-Moriles. There were 5,055 ha (12,491 acres) in 2008 but it is gradually being replaced by the higher-quality PALOMINO FINO. Bodegas Iglesias make several varietal examples in Condado de Huelva.',
    ],
  },
  {
    name: '\u017dAMETOVKA',
    synonyms: [
      'Bettlertraube',
      'Blauer Hainer',
      'Blauer K\u00f6lner',
      'Kapcina',
      'Kav\u010dina Crna',
      'K\u00f6lner Blau',
      'Modra Kav\u010dina',
      'Plava Velica',
      '\u017dametasta \u010crnina',
      '\u017dametna \u010crnina',
    ],
    description:
      'Ancient dark-skinned Slovenian variety traditionally goes into the tart local Cvi\u010dek but also has a claim as oldest vine in the world.',
    'origin and parentage': [
      '\u017dametovka is an old and obscure variety that possibly originates in the Dolenjska region in south-east Slovenia, where it used to be widely cultivated. The synonym \u017dametna \u010crnina, literally \u2018black velvet\u2019, could be a translation of Samtrot, the German name for PINOT MEUNIER, which is a quite distinct variety. In Austria, \u017dametovka is supposedly called Blauer Hainer, Blauer K\u00f6lner or K\u00f6lner Blau, or even Frankenthaler, a synonym for SCHIAVA GROSSA, but this last suggestion can be rejected by DNA profiling because the DNA profile of the old vine from Maribor (see below) in Vr\u0161i\u010d et al. (2011) does not match any other known local or foreign varieties (Nata\u0161a \u0160tajner and Jos\u00e9 Vouillamoz, unpublished data).',
    ],
    'where its grown': [
      '\u017dametovka is planted mainly in the Dolenjska wine region in Posavje, south-eastern Slovenia, close to the Croatian border, where it is often blended with the white variety KRALJEVINA (and/or BLAUFR\u00c4NKISCH) to make Cvi\u010dek, a traditional, low-alcohol, pale-red, high-acid wine that is locally very popular and has its own appellation within Dolenjska. In Bela Krajina, it is part of another, more deeply coloured and powerful, blended appellation wine known as Metli\u0161ka \u010crnina. Total plantings in Slovenia in 2009 amounted to 945 ha (2,335 acres), with only 73 ha (180 acres) further north in Podravje.',
      'Here, however, the variety has a more striking claim to fame, in the town of Maribor, where a huge 400-year-old \u017dametovka vine growing in front of what is now a museum on the bank of the River Drava is said by many to be the oldest living vine in the world, notwithstanding contenders to this title in Italy and Georgia, for example. Nicknamed Stara Trta, Slovenian for \u2018old grapevine\u2019, its status was recognized in the 2004 Guinness book of records. The building that houses the museum, at 8 Voja\u0161ni\u0161ka Street, appears with the vine in visual records dating back to 1657 and 1681 (Vr\u0161i\u010d et al. 2011). The vine yields 35\u201355 kg (77\u2013121 lbs) of grapes per year, giving around a hundred 25 cl bottles of wine, a true collector\u2019s item.',
    ],
  },
  {
    name: 'ZEF\u00cdR',
    synonyms: ['Badacsony 2', 'Zephyr'],
    description:
      'Recent Hungarian cross of uncertain parentage yet to establish a track record.',
    'origin and parentage': [
      'Zef\u00edr was initially described as a H\u00c1RSLEVEL\u0170 \u00d7 LE\u00c1NYKA cross created in 1951 by Ferenc Kir\u00e1ly in P\u00e9cs, Hungary, but recent DNA parentage analysis has disproved this pedigree (Gizella Jahnke, personal communication). Its true parentage is still unknown.',
      'The Vitis International Variety Catalogue defines it as a H\u00c1RSLEVEL\u0170 \u00d7 FETEASC\u0102 ALB\u0102 cross but this is because Feteasc\u0103 Alb\u0103 has often been confused with LE\u00c1NYKA.',
    ],
    'where its grown': [
      'Wines are light and mildly aromatic (floral or blackcurrant), although a \u2018typical\u2019 style is yet to be established. Vaszary in Pannonhalma in north-west Hungary make a varietal wine. There were 71 ha (175 acres) in Hungary in 2008, mostly in the Etyek-Buda and Eger wine regions in the north of the country. It is an optional component in Egri Csillag, a white blended wine devised by a group of winemakers in Eger as a pale partner to Egri Bikav\u00e9r, otherwise known as Bull\u2019s Blood.',
    ],
  },
  {
    name: 'ZENG\u0150',
    synonyms: ['Badachon 8', 'Badacsony 8'],
    description:
      'Hungarian cross making full-bodied, high-quality whites if handled carefully.',
    'origin and parentage': [
      'An EZERJ\u00d3 \u00d7 BOUVIER cross produced by Ferenc Kir\u00e1ly in 1951 in P\u00e9cs, south-west Hungary, making it a sibling of ZENIT and ZEUSZ. It is probably named after the highest peak in the nearby Mecsek mountains.',
    ],
    'where its grown': [
      'Although Zeng\u0151 is rarely made as a varietal wine, it does produce full-bodied wines that mellow with age, particularly when matured in cask (Roh\u00e1ly et al. 2003). According to Tam\u00e1s P\u00f3k, a leading wine grower in Eger, Hungary, if Zeng\u0151 is taken seriously, it can make high-quality wines with quince and pepper flavours, becoming more tropical when harvested later. However, if left on the vine, the skins have a high phenolic content, which must be carefully handled during maceration. There were 317 ha (783 acres) in 2008, mainly in Eger and M\u00e1tra but also in B\u00fckk, Kuns\u00e1g and Tokaj. J\u00f3zsef Szentesi just south west of Budapest makes a rare but good varietal example.',
    ],
  },
  {
    name: 'Z\u00c9TA',
    synonyms: ['Oremus'],
    description:
      'Renamed daughter of Furmint used mainly in sweet Tokaji blends.',
    'origin and parentage': [
      'A FURMINT \u00d7 BOUVIER cross produced by Ferenc Kir\u00e1ly in 1951 in P\u00e9cs, Hungary, making it a half-sibling of ZENG\u0150, ZENIT and ZEUSZ. The variety was renamed in 1999 to avoid confusion with the Oremus vineyard in Tokaj. Z\u00e9ta is a character from a novel by the well-known Hungarian novelist G\u00e9za G\u00e1rdonyi\u2019.',
    ],
    'where its grown': [
      'Z\u00e9ta is becoming increasingly significant in Hungary\u2019s Tokaj wine region, where it is one of the four recommended ingredients in Tokaji wines \u2013 along with FURMINT, H\u00c1RSLEVEL\u0170 and S\u00e1rga Muskot\u00e1ly (MUSCAT BLANC \u00c0 PETITS GRAINS) \u2013 and where all of Hungary\u2019s 114 ha (282 acres) are planted. Known as Oremus until 1999, it ripens earlier than both Furmint and H\u00e1rslevel\u0171, has good levels of acidity and sugar and is well suited to the production of sweet botrytized wines, hence its use in the blend of the region\u2019s Azs\u00fa specialities. Varietal wines are rare but both Balassa and Kikelet make good examples.',
    ],
  },
  {
    name: 'ZEUSZ',
    synonyms: ['Badacsony 10'],
    description:
      'Hungarian cross making full-bodied but well-balanced late-harvest whites.',
    'origin and parentage': [
      'An EZERJ\u00d3 \u00d7 BOUVIER cross produced by Ferenc Kir\u00e1ly in 1951 in P\u00e9cs, south-west Hungary, making it a sibling of ZENG\u0150 and ZENIT. It was named in honour of the Greek god Zeus (Hungarian grape breeders seem to like Z).',
    ],
    'where its grown': [
      'Wines, generally made from late-harvested grapes, are full-bodied but with fine fresh acidity, tropical-fruit flavours and almost always sweet. There were just 14 ha (35 acres) in Hungary in 2008, almost all in the west of the country, in Badacsony and further north in Soml\u00f3. Recommended producers include Szeremley, a particular champion of this variety, and Borb\u00e9ly, both on the north shore of Lake Balaton.',
    ],
  },
  {
    name: 'ZGHIHAR\u0102 DE HU\u015eI',
    synonyms: [
      'Ghihar\u0103',
      'Poam\u0103 Zos\u0103neasc\u0103',
      'Sghigard\u0103 Galben\u0103',
      'Zghihar\u0103',
      'Zghihar\u0103 Galben\u0103',
      'Zghihar\u0103 Verde B\u0103tut\u0103',
    ],
    description: 'Old Romanian variety producing crisp, easy-drinking whites.',
    'origin and parentage': [
      'As suggested by its name, Zghihar\u0103 de Hu\u015fi probably comes from the region of Hu\u015fi in Romanian Moldova, where it was known long before phylloxera hit the country at the end of the nineteenth century and where it still shows a high level of morphological diversity (Galet 2000). It has often been considered to be a selection of GALBEN\u0102 DE ODOBE\u0218TI (Dejeu 2004) but this hypothesis has not yet been tested by DNA profiling.',
    ],
    'where its grown': [
      'Wines are very fresh, relatively neutral, with light notes of apple or pear, sometimes lightly acacia-scented and designed for early drinking, although they can be quite powerful. Senator and Casa de Vinuri Hu\u015fi are two of the very few producers of varietal wines. There were 95 ha (235 acres) in 2008, all in the Hu\u015fi region in the far east of Romania, close to the border with the Republic of Moldova, but some of this is for home winemaking. It is also used to make brandy.',
    ],
  },
  {
    name: 'ZIERFANDLER',
    synonyms: [
      'Cirfandli',
      'Gumpoldskirchner',
      'Roter Zierfandler',
      'Rubiner',
      'Sp\u00e4trot',
      'Zierfandler Rot',
    ],
    description:
      'Thermenregion speciality making powerful, aromatic and highly distinctive wines, typically in partnership with Rotgipfler.',
    'origin and parentage': [
      'DNA parentage analysis has suggested that Zierfandler could be a natural cross between ROTER VELTLINER and a relative of SAVAGNIN that took place near Gumpoldskirchen in Nieder\u00f6sterreich (Lower Austria; Regner 2000a; see PINOT pedigree diagram). Its supposed relationship with SILVANER, which was sometimes called Gr\u00fcner Zierfandler or Zierfandl, is not confirmed by DNA analysis (Vouillamoz).',
    ],
    'where its grown': [
      'Wines are powerful and high in extract with flavours of orange, peach, honey and spice and a golden, sometimes lightly oily, aroma. The vast majority of Austria\u2019s 102 ha (252 acres) are in the Thermenregion, particularly around the town of Gumpoldskirchen, where it is generally blended with the other local variety, ROTGIPFLER, though there are a few hectares in Wagram. Unblended, it has enough acidity to make long-lasting sweet wines \u2013 unlike its blending partner. Recommended producers include Leo Aumann, Biegler, Johanneshof Reinisch, Stadlmann and Harald Zierer.',
      'There were 18 ha (44 acres) of Cirfandli in Hungary in 2008, almost all in the P\u00e9cs wine region in the south of the country, where it was first planted in the mid 1800s. Recommended producers of this full-bodied wine include Andreas Ebner, Lisicza, Matias and Rad\u00f3.',
    ],
  },
  {
    name: '\u017dILAVKA',
    synonyms: ['Jilavka', 'Mostarska', 'Mostarska Zilavka'],
    description:
      'Herzegovina\u2019s most famous variety, producing full-bodied but nutty, well-balanced, high-quality whites.',
    'origin and parentage': [
      '\u017dilavka most likely originates in the Mostar region in southern Bosnia and Herzegovina, as suggested by some of its synonyms. Its name is said to refer to the fine veins in the berry, visible through the skin in the ripe berry. DNA profiling has suggested a genetic relationship with Teran Bijeli (PROSECCO) in Istra (Maleti\u0107 et al. 1999).',
    ],
    'where its grown': [
      '\u017dilavka is the main indigenous white wine variety in Bosnia and Herzegovina and is found predominantly in the Mostar region in the south, often blended with up to 15% KRKO\u0160IJA and BENA. Its wines are typically full-bodied, rich in extract with fresh acidity and a slightly nutty flavour, ageing well in bottle, although many are made in a more fruity, easy style, sometimes off dry. Most wines are unoaked but a few producers such as Hepok make both oaked and unoaked versions.',
      '\u017dilavka is also found in Croatia, Serbia and in the Republic of Macedonia, where it gives its best results in the warmer sites. Macedonian producers include Popova Kula.',
    ],
  },
  {
    name: '\u017dLAHTINA',
    synonyms: [
      'Vrbni\u010dka \u017dlahtina',
      '\u017dlahtina Bijela',
      '\u017dlahtina Toljani',
      '\u017dlajtina',
    ],
    description:
      'High-yielding, minor Croatian variety currently being re-evaluated and benefiting from an enthusiastic local market.',
    'origin and parentage': [
      '\u017dlahtina probably originates from the Croatian island of Krk in northern Dalmacija (Dalmatia). The name derives from the Croatian word \u017elahtno, meaning \u2018noble\u2019.',
      'The DNA profile of Rde\u010da \u017dlahtina (rde\u010da means \u2018pink\u2019) in \u0160tajner et al. (2008) is identical to CHASSELAS (Vouillamoz), which might explain why the true \u017dlahtina is sometimes considered identical to Chasselas (as it is in the Vitis International Variety Catalogue).',
    ],
    'where its grown': [
      'Authorized in the Hrvatsko Primorje (Croatian Littoral) and Istra (Istria) wine regions, \u017dlahtina is a popular variety grown mainly on the island of Krk, off the coast of northern Dalmacija in Croatia just south of Rijeka, specifically around the town of Vrbnik in the east of the island, hence the synonym Vrbni\u010dka \u017dlahtina. Along with several other autochthonous varieties, \u017dlahtina is part of a revitalization project involving clonal selection and replanting. Recommended producers include Anton Katunar, \u0160ipun, Toljani\u0107 and the Vrbnik co-operative. Wines are typically dry, refreshing, medium-bodied with floral aromas and flavours of white-fleshed fruit, and the variety is occasionally used to make sparkling wine.',
    ],
  },
  {
    name: 'ZLATARICA VRGORSKA',
    synonyms: [
      'Bila loza',
      'Dra\u010dkinja',
      'Plavka',
      'Zlatarica',
      'Zlatarica Bijela',
    ],
    description: 'Very rare Croatian variety.',
    'origin and parentage': [
      'Zlatarica Vrgorska is a Croatian variety possibly originating from southern Dalmacija (Dalmatia) \u2013 the town of Vrgorac, whence Vrgorska, is between Split and Dubrovnik \u2013 where it has traditionally been grown. Zlatarica means \u2018golden\u2019.',
      'It should not be confused with Zlatarica Blatska Bijeli, which has a completely distinct DNA profile (Edi Maleti\u0107, personal communication) and is a parent of PO\u0160IP BIJELI (Piljac et al. 2002) but is no longer cultivated.',
    ],
    'where its grown': [
      'Although Zlatarica Vrgorska is rare, it is still found in Croatia\u2019s Dalmatian hinterland around Vrgorska, where it is cultivated by producers such as Vjekoslav Opa\u010dak and Ante Frani\u010d.',
    ],
  },
  {
    name: 'ZWEIGELT',
    synonyms: ['Blauer Zweigelt', 'Rotburger', 'Zweigeltrebe'],
    description:
      'Most common red wine grape in Austria producing firm, full-bodied wines if yields are controlled.',
    'origin and parentage': [
      'A BLAUFR\u00c4NKISCH \u00d7 Saint-Laurent (ie SANKT LAURENT) cross obtained in 1922 by Fritz Zweigelt at the Klosterneuburg research centre in Austria. Initially called Rotburger by Zweigelt himself, it was later named Zweigelt in honour of the breeder. Given the pedigrees of its parents, Zweigelt turns out to be a grandchild of GOUAIS BLANC and PINOT, two of the most influential varieties in the genetics of Western European grape varieties.',
      'Zweigelt was used to breed CABERNET MORAVIA in the Czech Republic and ROESLER in Austria.',
    ],
    'where its grown': [
      'Although there are experimental plantings of around 100 ha (247 acres) in Germany and it is planted on a much more minor scale in the UK, Zweigelt is most famous in Austria. It is the country\u2019s most widely planted dark-skinned variety: its 6,511 ha (16,089 acres) of vines are almost double the figure for BLAUFR\u00c4NKISCH and the total has increased by nearly 50% since 2000. There are major plantings in Nieder\u00f6sterreich (Lower Austria), particularly in the Weinviertel north of Wien (Vienna), and in Burgenland, especially in Neusiedlersee. However, regions best known for their white wines such as Kamptal can also produce very good examples, as Schloss Gobelsburg have shown.',
      'Wines are red-violet when young and show particularly exuberant fruit in the purple berry spectrum. As with Austria\u2019s signature white wine variety, GR\u00dcNER VELTLINER, styles can vary enormously, largely dependent on yield, but simple, unoaked Zweigelt has its charms. If yields are kept low Zweigelt can be full-bodied and ageworthy, tasting of sour or black cherries and responding well to barrel ageing. It is also favoured in red blends for its rich fruit. Best producers include K + K Kirnbauer, Franz Leth, Claus Preisinger, Johanneshof Reinisch and Umathum.',
      'Just over the border from Austria\u2019s red-wine heartland in Burgenland, in the Sopron wine region of Hungary, R\u00e1spi makes exceptional Zweigelt but it is not a common Hungarian variety. It is also found in the Czech Republic (860 ha/2,125 acres mainly in the south) and Slovakia, where it is known as Zweigeltrebe.',
      'Rather more unexpectedly, Zweigelt is planted on a minor scale in British Columbia, Canada, and in Japan (231 ha/571 acres, almost all in Hokkaido).',
    ],
  },
].map(({ name, synonyms }) => ({
  label: name
    .split(' ')
    .map((w) => capitalize(w))
    .join(' '),
  value: name,
  synonyms,
}));
