import { Outlet, useNavigate } from 'react-router-dom';
import { HEADER_HEIGHT, Header } from '../components/Header';

const Website = ({ children }) => {
  // Determine whether to show the back button, its content, and path
  const showBackButton = window.location.pathname.includes('/bundles');
  const backButtonPath = window.location.pathname.includes('/bundles/')
    ? '/bundles'
    : '/';
  const backButtonContent = window.location.pathname.includes('/bundles/')
    ? 'Bundles'
    : 'Home';
  return (
    <div
      style={{
        position: 'relative',
        paddingTop: HEADER_HEIGHT,
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Header
        showBackButton={showBackButton}
        backButtonContent={backButtonContent}
        backButtonPath={backButtonPath}
      />
      {children}
    </div>
  );
};

export default function BundlesView() {
  return (
    <Website>
      <Outlet />
    </Website>
  );
}
