import { useSelector } from "react-redux";
import RecipeBody from "../components/RecipeBody/RecipeBody";
import Sidebar from "../components/Sidebar/Sidebar";
import colors from "../constants/colors";
import { selectActiveView } from "../redux/selectors";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/Header";

export default function PairingView({ ...props }) {
  const view = useSelector(selectActiveView);
  const category = useSelector((state) => state.category);
  const showBackButton = window.location.pathname.includes("/pairing");
  const backButtonPath = window.location.pathname.includes("/pairing/")
    ? "/Pairing"
    : "/";
  const backButtonContent = window.location.pathname.includes("/pairing/")
    ? "Pairing"
    : "Home";
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header
        showBackButton={showBackButton}
        backButtonContent={backButtonContent}
        backButtonPath={backButtonPath}
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: colors.LIGHTER,
        }}
      >
        <Sidebar />
        {view !== undefined && <RecipeBody view={view} key={category} />}
      </div>
    </div>
  );
}
