import { ACCEPTED } from '../../constants/feedbackTypes';
import { API_VERSION, stagingClient } from '../client';

const client = stagingClient;

const SERVICE = 'api/pairing-tool';

const mode =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'TESTING'
    : 'PROD';

export default {
  fetchStats: () =>
    client
      .get(`${SERVICE}/${API_VERSION}/pairing-stats`, {
        credentials: 'same-origin',
      })
      .json(),
  fetchCategories: () =>
    client
      .get(`${SERVICE}/${API_VERSION}/pairing-categories`, {
        credentials: 'same-origin',
      })
      .json(),

  submitPairing: (recipe, wines) =>
    client.post(`${SERVICE}/${API_VERSION}/recipe-pairing`, {
      credentials: 'same-origin',
      json: {
        wines: wines.map(({ url, source, id }) => ({
          url,
          feedback: ACCEPTED,
          source: source,
          info: '',
          id,
        })),
        recipeId: recipe.id,
        info: 'PROD',
      },
    }),

  submitValidation: (recipe, wines) =>
    client.post(`${SERVICE}/${API_VERSION}/recipe-pairing`, {
      credentials: 'same-origin',
      json: {
        wines: wines.map(({ url, feedback, id }) => ({
          url,
          feedback,
          source: 'ai',
          info: '',
          id: id,
        })),
        id: recipe.id,
        info: mode,
      },
    }),
};
