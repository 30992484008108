import { CgCopy, CgGlobe, CgGlobeAlt } from 'react-icons/cg';
import { FaGlobeAfrica, FaLeaf } from 'react-icons/fa';
import colors from '../constants/colors';
import { alkoLinkToImageUrl } from '../helpers/image';
import { capitalize } from '../helpers/string';
import Grape from '../assets/grape.svg';
import Barrel from '../assets/barrel.png';
import { useEffect, useState } from 'react';
import api from '../api';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Logo from '../assets/full_white.svg';
import Vegan from '../assets/vegan.svg';
import Organic from '../assets/organic.svg';
import WinePlate from '../components/RecipeBody/WinePlate/WinePlate';
import { motion } from 'framer-motion';

export default function WineView({ ...props }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [wine, setWine] = useState(null);
  const [similarWines, setSimilarWines] = useState([]);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  const initialize = async () => {
    try {
      const wineInfo = await api.WineCellar.fetchInfoById(
        searchParams.get('id')
      );
      console.log(wineInfo);
      setWine(wineInfo);

      // const response = await api.WineCellar.fetchSimilar([wineInfo.url]);
      // setSimilarWines(response);
    } catch (err) {
      const { message } = await err.response.json();
      console.log(message);
      setError(message);
    }
  };

  useEffect(() => {
    if (wine === null) {
      initialize();
    }

    if (wine?.title) {
      document.title = wine?.title;
    }
  }, [wine?.title]);

  const Tag = ({ children, size = 'sm', ...props }) => (
    <div
      style={{ marginRight: '.5em' }}
      className={`p-1 px-2 bg-white border d-inline-block my-1 text-${size} font-weight-500`}
      {...props}
    >
      {children}
    </div>
  );

  const CircleTag = ({ children, ...props }) => (
    <div
      className='rounded-circle d-inline-flex text-white justify-content-center align-items-center my-2'
      style={{
        background: '#6db04d',
        width: '1.8em',
        height: '1.8em',
        fontSize: '1.2em',
        border: '1px solid black',
      }}
    >
      <FaLeaf />
    </div>
  );

  const DescRow = ({ children, ...props }) => (
    <div className='my-2 d-flex align-items-center' {...props}>
      {children}
    </div>
  );

  const ProductDetailRow = ({ field, value, ...props }) => (
    <div className='w-100 d-flex text-md font-weight-400 text-start'>
      <div style={{ flex: 1, color: '#808080' }}>{field.toUpperCase()}</div>
      <div style={{ flex: 1, fontWeight: 700 }}>{value}</div>
    </div>
  );

  return (
    <div>
      <div className='w-100 bg-black mb-5' style={{ height: '7vh' }}>
        <div
          className='h-100 d-flex justify-content-center align-items-center'
          style={{
            width: '60%',
            margin: 'auto',
            maxWidth: 1920,
            minWidth: 800,
          }}
        >
          <img height='40%' src={Logo} />
        </div>
      </div>
      <div
        style={{
          width: '60%',
          maxWidth: 1920,
          minWidth: 800,
          margin: 'auto',
        }}
      >
        {error ? (
          <div
            className='d-flex justify-content-center align-items-center w-100'
            style={{ height: '93vh' }}
          >
            {error}
          </div>
        ) : wine === null ? (
          <div
            className='d-flex justify-content-center align-items-center w-100'
            style={{ height: '93vh' }}
          >
            <Spinner animation='border' />
          </div>
        ) : (
          <div>
            <div className='d-flex w-100 position-relative'>
              <div id='wine-desc' style={{ flex: 1 }}>
                <div className='mb-5'>
                  <h2 className='font-weight-800'>{wine.title}</h2>
                  <h4 className='font-weight-300' style={{ fontSize: '1.3em' }}>
                    {wine?.type} wine
                  </h4>
                </div>

                <div className='text-sm'>
                  <DescRow className='my-3'>
                    {wine.vegan && (
                      <img src={Vegan} style={{ height: '2.6em' }} />
                    )}
                    {wine.organic && (
                      <img src={Organic} style={{ height: '2.6em' }} />
                    )}
                  </DescRow>
                  <DescRow>
                    <b style={{ fontSize: '1.2em' }}>
                      {capitalize(
                        ['tannic', 'acidic', 'bodied', 'sweet']
                          .map((t) => wine?.[t + 'Taste'])
                          .filter((t) => t)
                          .join(', ')
                      )}
                    </b>
                  </DescRow>
                  <DescRow>
                    <FaGlobeAfrica
                      size='1.4em'
                      style={{ marginRight: '.3em' }}
                    />
                    &nbsp;
                    {['country', 'region', 'subregion']
                      .map((r) => wine?.[r])
                      .filter((r) => r)
                      .map((r) => (
                        <Tag>{r}</Tag>
                      ))}
                  </DescRow>
                  <DescRow>
                    <img
                      src={Grape}
                      style={{
                        width: '1.4em',
                        height: '1.4em',
                        marginRight: '.3em',
                      }}
                    />
                    &nbsp;
                    {wine?.grapes?.map((g) => (
                      <Tag>{g}</Tag>
                    ))}
                  </DescRow>
                  <br />
                  <b>Notes & hints</b>
                  <br />
                  {[
                    'fermentation',
                    'flowers',
                    'fruits',
                    'herbal',
                    'spices',
                    'tertiary',
                    'minerality',
                  ]
                    .filter((n) => wine?.[n])
                    .map((n) => capitalize(n))
                    .join(', ')}
                </div>
              </div>
              <div
                id='wine-image'
                className='d-flex flex-column align-items-center p-5 position-relative'
                style={{ flex: 1 }}
              >
                <div
                  className='position-absolute'
                  style={{ top: 0, right: 0, fontSize: '1.3em' }}
                >
                  <div>
                    <span style={{ fontSize: '2.5em' }}>
                      {wine?.price?.toFixed(0)}
                    </span>
                    <span style={{ fontSize: '1.4em' }}>
                      .{String((wine?.price % 1).toFixed(2)).slice(2)}
                    </span>
                  </div>
                  <div
                    className='text-end'
                    style={{ fontSize: '1.1em', color: '#808080' }}
                  >
                    {wine.volume} l
                  </div>
                </div>
                <img
                  style={{ height: 340, width: '100%', objectFit: 'contain' }}
                  src={alkoLinkToImageUrl(wine.url)}
                />

                <div
                  className='border p-3 d-flex'
                  style={{
                    marginTop: '2em',
                    borderRadius: '1.5em',
                    fontSize: '0.8em',
                  }}
                >
                  <div
                    className='d-flex flex-column'
                    style={{ paddingRight: '2em' }}
                  >
                    <div
                      className='font-weight-600'
                      style={{ fontSize: '0.8em', color: '#a0a0a0' }}
                    >
                      ALKO.FI URL
                    </div>
                    <div>
                      <a href={wine.url}>{wine.url}</a>
                    </div>
                  </div>
                  <div className='d-flex flex-column'>
                    <div
                      className='font-weight-600 text-center'
                      style={{
                        fontSize: '0.8em',
                        color: '#a0a0a0',
                        width: '5em',
                      }}
                    >
                      {copied ? 'COPIED!' : 'COPY'}
                    </div>
                    <motion.div
                      className='d-flex justify-content-center align-items-center clickable'
                      animate={{ opacity: 0.4 }}
                      whileHover={{ opacity: 1 }}
                      onClick={() => {
                        navigator.clipboard.writeText(wine.url);
                        setCopied(true);
                      }}
                      style={{ flex: 1, fontSize: '1.6em' }}
                    >
                      <CgCopy />
                    </motion.div>
                  </div>
                </div>
                <div
                  className='px-3 py-1 d-flex flex-column align-items-start justify-content-start w-100'
                  style={{
                    marginTop: '.5em',
                    borderRadius: '1.5em',
                    fontSize: '0.8em',
                  }}
                >
                  <div
                    className='font-weight-600'
                    style={{ fontSize: '0.8em', color: '#a0a0a0' }}
                  >
                    VIVINO URL
                  </div>
                  <div>
                    <a
                      href={`https://www.vivino.com/search/wines?q=${wine.title}`}
                    >{`https://www.vivino.com/search/wines?q=${wine.title}`}</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='w-100 d-flex border p-4'
              style={{ background: colors.LIGHTER }}
            >
              <div style={{ flex: 1 }}>
                <ProductDetailRow
                  field={'alcohol'}
                  value={wine.alcohol + '%'}
                />
                {wine?.sugar && (
                  <ProductDetailRow
                    field={'sugar'}
                    value={parseFloat(wine.sugar).toFixed(1) + ' g/l'}
                  />
                )}
                {wine?.acids && (
                  <ProductDetailRow
                    field={'acids'}
                    value={wine.acids.toFixed(1) + ' g/l'}
                  />
                )}
                <ProductDetailRow field={'volume'} value={wine.volume + ' l'} />
                <ProductDetailRow
                  field={'price / litre'}
                  value={`${wine.pricePerLitre} ${wine.currency}`}
                />
              </div>
              <div style={{ flex: 1 }}>
                <ProductDetailRow field={'packaging'} value={wine.packaging} />
                <ProductDetailRow field={'producer'} value={wine.producer} />
                <ProductDetailRow field={'supplier'} value={wine.supplier} />
              </div>
            </div>

            <br />

            <div style={{ paddingBottom: '10vh' }}>
              <h4 className='mb-4 mt-5'>Similar wines</h4>
              <div className='w-100 d-flex flex-column'>
                {similarWines?.map((simWine) => (
                  <div className='p-2' style={{ width: '50%' }}>
                    <WinePlate wine={simWine} nonInteractive />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
