export const FULFILLED = 'fulfilled'
export const REJECTED = 'rejected'
export const PENDING = 'pending'
export const IDLE = 'idle'
export const CHANGED = 'changed'

export default {
    FULFILLED,
    REJECTED,
    PENDING,
    IDLE,
    CHANGED
}