import { motion } from "framer-motion";
import { BiFoodMenu, BiBox } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Header } from "../components/Header";

export default function LandingPage() {
  const navigate = useNavigate();
  const showBackButton = false;

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Header
        showBackButton={showBackButton}
        // backButtonContent={backButtonContent}
        // backButtonPath={backButtonPath}
      />
      <div className="d-flex">
        {["pairing", "bundles"].map((path) => (
          <motion.div
            className="rounded border mx-4 d-flex justify-content-center align-items-center flex-column"
            style={{
              width: 200,
              height: 200,
              fontWeight: 500,
              fontSize: 18,
              cursor: "pointer",
            }}
            initial={false}
            animate={{
              background: "#f0f5fa",
              color: "#000",
            }}
            whileHover={{
              background: "#e5eaef",
              color: "#000",
              scale: 1.05,
            }}
            onClick={() => {
              navigate(path);
            }}
            transition={{
              type: "tween",
              ease: "easeOut",
              duration: 0.2,
            }}
          >
            {path === "bundles" ? (
              <BiBox size={30} style={{ marginBottom: 4 }} />
            ) : (
              <BiFoodMenu size={30} style={{ marginBottom: 4 }} />
            )}
            {path}
          </motion.div>
        ))}
      </div>
    </div>
  );
}
