import RecipeBanner from './RecipeBanner';
import Overview from './Tabs/Overview';
import Pairing from './Tabs/Pairing';
import Validation from './Tabs/Validation';
import { useSelector } from 'react-redux';
import { CHANGED, FULFILLED } from '../../constants/statusTypes';
import { selectActiveView, selectCategoryStatus } from '../../redux/selectors';
import { IoCheckmarkSharp, IoAlertSharp } from 'react-icons/io5';
import colors from '../../constants/colors';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { NAV_HEIGHT, SIDEBAR_WIDTH } from '../../constants/dimensions';

const MyTab = ({ title, selected, status, ...props }) => {
  const [hovered, setHovered] = useState(false);

  const Badge = (status) =>
    status === FULFILLED ? (
      <IoCheckmarkSharp
        style={{ right: '0px' }}
        className='mx-1 position-absolute'
        size={16}
      />
    ) : status === CHANGED ? (
      <IoAlertSharp
        style={{ right: '0px' }}
        className='mx-1 position-absolute'
        color={colors.PRIMARY}
        size={14}
      />
    ) : null;

  return (
    <div
      {...props}
      
      initial={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}
      className='clickable center-content position-relative text-xs'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        width: '120px',
        height: NAV_HEIGHT + 'px',
        // backgroundColor: selected ? colors.SECONDARY_LIGHTER : '',
      }}
    >
      <motion.span
        animate={{ y: hovered && !selected ? -2 : 0 }}
        transition={{ ease: 'easeOut' }}
      >
        {title}
      </motion.span>
      {selected && (
        <motion.div
          layoutId='underline'
          className='w-100 position-absolute'
          style={{
            height: '1px',
            background: colors.SECONDARY,
            left: 0,
            right: 0,
            bottom: '0px',
          }}
        />
      )}
      {Badge(status)}
    </div>
  );
};

export default function RecipeBody(props) {
  const view = useSelector(selectActiveView);
  const categoryStatus = useSelector(selectCategoryStatus);
  const feedbackStatus = {
    Validation: view?.validationStatus,
    Pairing: view?.pairingStatus,
  };
  const [selected, setSelected] = useState('Overview');

  let tabs = ['Overview'].concat(
    categoryStatus === 'review' ? ['Validation', 'Pairing'] : ['Pairing']
  );

  return (
    <motion.div
      className='d-flex flex-column w-100'
      style={{
        flex: 1,
        marginLeft: SIDEBAR_WIDTH + 'px',
      }}
    >
      <RecipeBanner key={view.recipe.title}/>
      <div className='w-100 center-content'>
        {tabs.map((title) => (
          <MyTab
            status={feedbackStatus[title]}
            key={'tab_' + title}
            title={title}
            selected={selected === title}
            onClick={() => setSelected(title)}
          />
        ))}
      </div>
      <div className='flex-grow-1 w-100'>
        {selected === 'Overview' && <Overview view={view} />}
        {selected === 'Pairing' && <Pairing view={view} />}
        {selected === 'Validation' && categoryStatus === 'review' && (
          <Validation view={view} />
        )}
      </div>
    </motion.div>
  );
}
