export const spawnAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: 'easeInOut', duration: 0.7 },
  exit: { opacity: 0 },
};

export const liAnimation = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
  transition: { duration: 0.4 },
};

export const tabAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.4 },
  exit: { opacity: 0 },
};

export const bitAnimation = {
  initial: { opacity: 0, x: -10 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0 },
  transition: {
    opacity: { duration: 0.3 },
    x: {
      duration: 0.5,
      type: 'spring',
      stiffnes: 0,
    },
  },
};

export const transition = {
  transition: { ease: 'easeOut', duration: 0.3 },
};

export default {
  spawnAnimation,
};

// export default {
//     spawnAnimation: {
//         initial: { opacity: 0, x: -10 },
//         animate: { opacity: 1, x: 0 },
//         transition: { x: { duration: 0.33 }, opacity: { duration: 0.5 }, translateX: { duration: 0.1 } }
//     }
// };
