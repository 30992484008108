import client, { API_VERSION } from '../client';

const SERVICE = 'a/sommelier';

const services = {
  fetchWines: (title, steps, ingredients) =>
    client
      .post(`${SERVICE}/${API_VERSION}/recipe/list`, {
        headers: {
          'x-user-role': 'sommelier',
        },
        credentials: 'same-origin',
        json: {
          title,
          steps,
          ingredients,
          model: 'julie',
          filter: {
            streamline: true,
            limit: 5,
          }
        },
      })
      .json(),
};

export default services;
