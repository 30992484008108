import _ from 'lodash';
import { ACCEPTED, DECLINED } from '../constants/feedbackTypes';
import * as status from '../constants/statusTypes';

export const selectCategoryStatus = (state) =>
  selectActiveCategory(state)?.status;

export const selectViewsByCategory = (category) => (state) =>
  state.tree[category].views;

export const selectActiveCategory = (state) => state.tree[state.category];

export const selectActiveIngredients = (state) =>
  selectActiveRecipe(state).ingredients;

export const selectActiveSteps = (state) => selectActiveRecipe(state).steps;

export const selectActiveRecipe = (state) => selectActiveView(state).recipe;

export const selectUserWines = (state) => selectActiveView(state).userWines;

export const selectCategories = (state) =>
  _.map(state.tree, ({ status }, name) => ({
    name,
    status,
  }));

export const selectSimWines = (state) => selectActiveView(state).simWines;

export const selectActiveCounts = (state) =>
  state.counts.find(({ name }) => name === state.category)?.pairings;

export const selectActiveMaxCounts = (state) =>
  Math.min(
    state.counts.find(({ name }) => name === state.category)?.recipeCount,
    200
  );

export const selectActiveView = (state) => {
  if (state.index === -1) {
    return undefined;
  }

  return state.tree[state.category].views[state.index];
};

export const selectRecipes = (state) => {
  return state.tree[state.category].views.map(({ recipe }) => recipe);
};

export const recipeIsLoading = (state) => {
  return state.loadables.fetchRecipe.status === status.PENDING;
};

export const similarWinesIsLoading = (state) => {
  return state.loadables.fetchSimilarWines.status === status.PENDING;
};

export const selectViews = (state) =>
  state.category ? state.tree[state.category].views : [];

export const selectCpuWines = (state) => selectActiveView(state).cpuWines;
export const selectAcceptedCpuWines = (state) =>
  selectCpuWines(state).filter((w) => w.feedback === ACCEPTED);
export const selectDeclinedCpuWines = (state) =>
  selectCpuWines(state).filter((w) => w.feedback === DECLINED);

export const selectPairingStatus = (state) =>
  selectActiveView(state).pairingStatus;
export const selectValidationStatus = (state) =>
  selectActiveView(state).validationStatus;

// export const recipeDisplayable = state => {
//     if (state.status === status.IDLE)
//         return false

//     if (state.)
// }
