import { API_VERSION, stagingClient } from '../client';

const SERVICE = 'a/wine';
const client = stagingClient;

export default {
  fetchSimilar: (urls) =>
    client
      .post(`${SERVICE}/${API_VERSION}/similar`, {
        json: { urls },
      })
      .json(),
  fetchInfo: (url) =>
    client
      .get(`${SERVICE}/${API_VERSION}/url?wine_url=${url}`, {
        // headers: {
        //   'x-user-role': 'sommelier',
        // },
        // credentials: 'include',
        // json: { url },
      })
      .json(),

  fetchInfoById: (id) => client.get(`${SERVICE}/${API_VERSION}/${id}`).json(),

  fetchRandom: () =>
    client
      .get(`${SERVICE}/${API_VERSION}/random-wine`, {
        // headers: {
        //   'x-user-role': 'sommelier',
        // },
      })
      .json(),

  fetchBundles: (companyId) =>
    client
      .get(
        `${SERVICE}/${API_VERSION}/bundle/company/${companyId}?display_all=true`,
        {
          headers: {
            'x-user-role': 'sommelier',
          },
        }
      )
      .json(),

  fetchBundleDetail: (bundleId) =>
    client.get(`${SERVICE}/${API_VERSION}/bundle/${bundleId}`).json(),

  createBundle: ({ title, description, products, companyId }) =>
    client
      .post(`${SERVICE}/${API_VERSION}/bundle`, {
        json: {
          title,
          description,
          products,
          companyId,
        },
      })
      .json(),

  editBundle: ({ title, description, products, companyId, id }) =>
    client
      .patch(`${SERVICE}/${API_VERSION}/bundle/${id}`, {
        json: {
          title,
          description,
          products,
          companyId,
        },
      })
      .json(),

  fetchWines: ({ offset = 0, limit = 60, query, companyId, filters }) =>
    client
      .post(
        `${SERVICE}/${API_VERSION}/company/cellar?offset=${offset}&limit=${limit}`,
        {
          json: {
            companyId,
            filter: {
              ...filters,
              title: query,
            },
          },
          // credentials: 'include',
          headers: {
            'x-user-role': 'sommelier',
            'x-company-id': companyId,
            // 'x-permissions': 'upload-file',
          },
        }
      )
      .json(),
};
