import { useDispatch, useSelector } from 'react-redux';
import {
  selectAcceptedCpuWines,
  selectCpuWines,
  selectDeclinedCpuWines,
  selectValidationStatus,
} from '../../../redux/selectors';
import Tab from './Tab';
import WinePlate from '../WinePlate/WinePlateValidation';
import { AnimatePresence, motion } from 'framer-motion';
import IconButton from '../../IconButton';
import { FULFILLED, PENDING } from '../../../constants/statusTypes';
import { validationSubmitted } from '../../../redux/pairingSlice';
import { tabAnimation } from '../../../constants/animations';
import { TAB_MIN_WIDTH } from '../../../constants/dimensions';
import { DECLINED } from '../../../constants/feedbackTypes';

export default function Validation({ view }) {
  const cpuWines = useSelector(selectCpuWines);
  const accepted = useSelector(selectAcceptedCpuWines);
  const declined = useSelector(selectDeclinedCpuWines);
  const dispatch = useDispatch();

  const submitStatus = useSelector(selectValidationStatus);
  const loadingSubmit = submitStatus === PENDING;
  const submitted = submitStatus === FULFILLED;

  const handleRefreshClick = () => {
    // dispatch(refreshCpuWines())
  };

  const handleSubmit = () => {
    dispatch(validationSubmitted(view));
  };

  const submitButtonContent = () =>
    loadingSubmit
      ? 'Submitting'
      : submitted
      ? 'Submitted'
      : accepted.length + declined.length
      ? `Submit ${accepted.length + declined.length}`
      : 'Pass all';

  return (
    <motion.div
      {...tabAnimation}
      className='tab d-flex flex-column'
      style={{ width: TAB_MIN_WIDTH + 'px' }}
    >
      <div className='flex-grow-1 d-flex flex-column'>
        <span className='text-md w-100 p-2 mb-1 text-start'>
          Select appropriate wines
        </span>

        <AnimatePresence>
          {cpuWines.map((w, i) => (
            <WinePlate
              key={'sim-wine-' + w.id}
              wine={w}
              index={i}
              submitted={submitted}
            />
          ))}
        </AnimatePresence>
      </div>
      <br />
      <div className='center-content'>
        <IconButton
          loading={loadingSubmit}
          disabled={submitted}
          onClick={handleSubmit}
        >
          {submitButtonContent()}
        </IconButton>
      </div>
    </motion.div>
  );
}
