import {
  Form,
  Button,
  InputGroup,
  FormGroup,
  OverlayTrigger,
  Tooltip,
  Popover,
  PopoverHeader,
} from 'react-bootstrap';
import Tab from './Tab';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
  pairingSubmitted,
  userWineAdded,
  userWineRemoved,
} from '../../../redux/pairingSlice';
import {
  selectPairingStatus,
  selectSimWines,
  selectUserWines,
  similarWinesIsLoading,
} from '../../../redux/selectors';
import Loadable from '../../Loadable';
import { isWineLink } from '../../../helpers/string';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  bitAnimation,
  liAnimation,
  tabAnimation,
} from '../../../constants/animations';
import api from '../../../api';
import _ from 'lodash';
import IconButton from '../../IconButton';
import { FULFILLED, PENDING } from '../../../constants/statusTypes';
import Recommendations from './Recommendations';
import { TAB_MIN_WIDTH } from '../../../constants/dimensions';
import colors from '../../../constants/colors';
import WineColor from '../WinePlate/WineColor';
import { responseToWineObject } from '../../../helpers';
import WinePlate from '../WinePlate/WinePlate';
import WineContent from '../WinePlate/WineContent';
import { useEffect } from 'react';

const PAIRING_LIMIT = 10;

const WineBit = ({ wine, index, animation, submitted, ...props }) => {
  useEffect(() => {
    console.log(wine);
  }, []);

  const dispatch = useDispatch();

  const handleRemoveWineClick = () => {
    dispatch(userWineRemoved(index));
  };

  const renderTooltip = (type) => (
    <Tooltip id='wine-color-tooltip'>
      {type} wine
      {/* <WinePlate wine={wine} /> */}
    </Tooltip>
  );

  const wineColor = (type) => {
    switch (type) {
      case 'red':
        return colors.SECONDARY;
      case 'white':
        return colors.OFF_WHITE;
      case 'rose':
        return 'pink';
    }
  };

  return (
    <motion.div
      {...animation}
      animate={{ ...animation.animate, height: '30px' }}
      transition={{ duration: 0.2 }}
      className={'text-xs overflow-hidden' + props.className}
      style={{
        borderRadius: '25px',
        border: '1px solid ' + (submitted ? colors.ACCEPTED : colors.LIGHT),
        background: submitted ? colors.ACCEPTED_BG : '',
        float: 'left',
        margin: '2px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        ...props.style,
      }}
    >
      <div
        className='center-content'
        onClick={handleRemoveWineClick}
        style={{
          lineHeight: '0px',
          padding: '0px',
          width: '25px',
        }}
      >
        <IoClose className='clickable hover-dark' size={16} />
      </div>
      {wine.type ? (
        <OverlayTrigger
          placement='bottom'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(wine.type)}
        >
          <div
            className='rounded-circle'
            style={{
              border: '1px solid black',
              width: '8px',
              height: '8px',
              backgroundColor: wineColor(wine.type),
              marginRight: '5px',
            }}
          />
        </OverlayTrigger>
      ) : null}
      <div
        className='center-content'
        style={{
          maxWidth: '350px',
          paddingRight: '10px',
        }}
      >
        <OverlayTrigger
          placement='bottom-start'
          delay={{ show: 200, hide: 300 }}
          overlay={
            <Popover
              id='wine-plate-tooltip'
              className='p-2'
              style={{
                width: '300px',
                height: '75px',
                background: 'white',
                marginTop: '10px',
                // border: '1px solid black',
              }}
            >
              {wine.id ? (
                <WineContent wine={wine} />
              ) : (
                <div className='w-100 h-100 center-content'>
                  <span style={{ color: 'lightgray' }}>No info.</span>
                </div>
              )}
            </Popover>
          }
        >
          <span
            onClick={() => window.open(wine.url, '_blank')}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            className='text-xs hover-underline clickable'
          >
            {wine.title}
          </span>
        </OverlayTrigger>
      </div>
    </motion.div>
  );
};

export default function Pairing({ view }) {
  const dispatch = useDispatch();
  const userWines = useSelector(selectUserWines);
  const simWines = useSelector(selectSimWines)?.length;
  const simWinesLoading = useSelector(similarWinesIsLoading);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [input, setInput] = useState('');

  const submitStatus = useSelector(selectPairingStatus);
  const loadingSubmit = submitStatus === PENDING;
  const submitted = submitStatus === FULFILLED;
  const limitMet = userWines.length === PAIRING_LIMIT;

  const hovered = useSelector((state) => state.hovered);

  const dummyName = (url) =>
    url.includes('www.alko.fi')
      ? _.chain(url).trimEnd('/').split('/').last().value().replaceAll('-', ' ')
      : url;

  const dummyWine = (url) => ({
    title: dummyName(url),
    url,
    id: null,
    source: 'user',
  });

  const handleAddWineClick = async () => {
    const url = input.trim();
    setLoadingAdd(true);

    let response;
    try {
      response = await api.WineCellar.fetchInfo(url);

      console.log(response);
    } catch (error) {}

    // const wine = responseToWineObject(response);
    const wine = response;

    dispatch(
      userWineAdded(wine ? { ...wine, source: 'user' } : dummyWine(url))
    );

    setLoadingAdd(false);
    setInput('');
  };

  const addButtonDisabled = !input.length || !isWineLink(input) || loadingAdd;

  const handleSubmit = () => {
    dispatch(pairingSubmitted(view));
  };

  return (
    <motion.div
      {...tabAnimation}
      className='tab d-flex flex-column position-relative overflow-hidden'
      style={{ width: TAB_MIN_WIDTH + 'px' }}
    >
      <span className='text-md w-100 mb-1 p-2 text-start'>Input pairings</span>
      <FormGroup className='px-2'>
        <InputGroup size='sm'>
          <Form.Control
            className='rounded-0'
            id='pairing-input'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder='Enter wine link'
          />
          <Button
            disabled={addButtonDisabled}
            style={{ width: '50px' }}
            className='text-sm center-content p-0 rounded-0'
            onClick={handleAddWineClick}
          >
            <Loadable loading={loadingAdd}>Add</Loadable>
          </Button>
        </InputGroup>
      </FormGroup>

      <ul className='p-3 w-full'>
        <AnimatePresence initial={false}>
          {userWines.map((wine, index) => (
            <WineBit
              key={`winebit_${wine.id}_${wine.url}`}
              animation={liAnimation}
              wine={wine}
              index={index}
              submitted={submitted}
            />
          ))}
        </AnimatePresence>
        {/* {
                    hovered &&
                    <WineBit key={`winebit_2_${hovered.name}`} animation={liAnimation} index={-1} wine={hovered} style={{ opacity: 0.3 }} />
                } */}
      </ul>

      <div className='center-content'>
        <IconButton
          style={{
            outline: 'solid 2px ' + (limitMet ? colors.SECONDARY : 'white'),
            outlineOffset: '3px',
          }}
          loading={loadingSubmit}
          disabled={submitted || !userWines.length}
          onClick={handleSubmit}
          className='px-4 text-sm mb-2'
        >
          {submitted
            ? 'Submitted'
            : `Submit ${userWines.length && userWines.length}`}
          {submitted ? (
            ''
          ) : (
            <span className='text-xxs' style={{ marginLeft: '3px' }}>
              /10
            </span>
          )}
        </IconButton>
      </div>

      <AnimatePresence initial={false}>
        {userWines?.length && <Recommendations limitMet={limitMet} />}
      </AnimatePresence>
    </motion.div>
  );
}
