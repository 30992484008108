import { motion } from 'framer-motion';
import { deburr } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { FixedSizeList as List } from 'react-window';

export default function SelectMenu({
  options,
  active,
  close,
  setActive,
  searchable = false,
  multi = false,
}) {
  const [query, setQuery] = useState(''); // TODO: implement search
  const ref = useRef(null);
  const [displayOptions, setDisplayOptions] = useState(options);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    // mount listener after 100ms to prevent triggering immediately and closing the menu
    setTimeout(() => {
      document.addEventListener('mousedown', handleClickOutside);
    }, 100);

    const search = document.getElementById('search-input');
    search && setTimeout(() => search.focus(), 100);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // filter options on query change and sort by relevance
    setDisplayOptions(
      options
        .filter(
          (option) =>
            deburr(option.label.toLowerCase()).includes(
              deburr(query.toLowerCase())
            ) ||
            option?.synonyms?.some((synonym) =>
              deburr(synonym.toLowerCase()).includes(
                deburr(query.toLowerCase())
              )
            )
        )
        .sort((a, b) => {
          // if query === '', keep original order
          if (query === '') return 0;

          const aLabel = deburr(a.label.toLowerCase());
          const bLabel = deburr(b.label.toLowerCase());

          const aIndex = aLabel.indexOf(deburr(query.toLowerCase()));
          const bIndex = bLabel.indexOf(deburr(query.toLowerCase()));

          if (aIndex === bIndex) {
            // sort alphabetically if both have the same index
            return aLabel.localeCompare(bLabel);
          }

          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;

          return aIndex - bIndex;
        })
    );
  }, [query]);

  useEffect(() => {
    setQuery('');
  }, [active]);

  const noneActive = () => {
    if (Array.isArray(active)) {
      return active.length === 0;
    } else {
      return active === null || active === undefined;
    }
  };

  const isActive = (option) => {
    if (noneActive()) return false;

    if (Array.isArray(active)) {
      return active.some((a) => a?.value === option.value);
    } else {
      return active?.value === option.value;
    }
  };

  const handleOptionClick = (option) => {
    if (multi) {
      if (isActive(option)) {
        if (Array.isArray(active)) {
          setActive(active.filter((a) => a.value !== option.value));
        } else {
          setActive([]);
        }
      } else {
        if (Array.isArray(active)) {
          setActive([...active, option]);
        } else {
          setActive(active ? [active, option] : [option]);
        }
      }
    } else {
      setActive(option);
      close();
    }
  };

  const formatLabel = (label) => {
    // if query in label, bold query

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: label.replace(
            new RegExp(query, 'gi'),
            (match) => `<b style='color: #000'>${match}</b>`
          ),
        }}
      ></div>
    );
  };

  return (
    <motion.div
      ref={ref}
      id='dropdown-menu'
      className='d-flex flex-column justify-content-start'
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 20, opacity: 0 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      style={{
        position: 'absolute',
        left: 0,
        top: '120%',
        minWidth: 130,
        // maxWidth: 200,
        width: '100%',
        maxHeight: 250,
        background: '#fafbfc',
        borderRadius: 2,
        fontSize: 13,
        overflow: 'auto',
        overscrollBehavior: 'contain',
        zIndex: 99,
        boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.40)',
        userSelect: 'none',
        transformOrigin: 'bottom',
      }}
    >
      {searchable && (
        <motion.input
          id={'search-input'}
          key={'search-input'}
          className='d-flex justify-content-start align-items-center px-2 py-1'
          placeholder='Search...'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          //   onBlur={() => setTimeout(() => setQuery(''), 100)}
          style={{
            minHeight: 30,
            border: 'none',
            outline: 'none',
            background: '#f0f2f4',
          }}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            color: '#555',
            background: '#ffffff',
          }}
          whileFocus={{
            background: '#f0f2f4',
          }}
          exit={{ opacity: 0 }}
        />
      )}

      <motion.div
        key={'option_any'}
        className='d-flex justify-content-start align-items-center px-2 py-1'
        style={{
          cursor: 'pointer',
          minHeight: 30,
          borderBottom: '1px solid #c0c5ca',
        }}
        onClick={() => setActive(multi ? [] : undefined)}
        initial={{ opacity: 0, background: '#ffffff' }}
        animate={{
          color: '#555',
          opacity: 1,
          background: noneActive() ? '#f0f2f4' : '#fafbfc',
          // fontWeight: isActive(option) ? 500 : 400,
        }}
        exit={{ opacity: 0 }}
        whileHover={
          !noneActive() && {
            background: '#f5f7f9',
            color: '#000',
          }
        }
      >
        {noneActive() ? (
          <BiCheck style={{ marginRight: 5 }} />
        ) : (
          <BiX style={{ marginRight: 5 }} />
        )}
        <span>{!noneActive() ? 'Clear all' : 'Any'}</span>
      </motion.div>

      <List
        height={300} // Adjust this value based on your design
        itemCount={displayOptions.length}
        itemSize={30} // Adjust this value based on your item height
        width={'100%'} // Adjust this value based on your design
      >
        {({ index, style }) => (
          <motion.div
            key={'option_' + index}
            className='d-flex justify-content-start align-items-center px-2 py-1'
            style={{
              cursor: 'pointer',
              minHeight: 30,
              maxHeight: 30,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              ...style,
            }}
            onClick={() => handleOptionClick(displayOptions[index])}
            initial={false}
            animate={{
              color: isActive(displayOptions[index]) ? '#00050a' : '#50555a',
              opacity: 1,
              background: isActive(displayOptions[index])
                ? '#f0f2f4'
                : '#fafbfc',
              // fontWeight: isActive(option) ? 500 : 400,
            }}
            exit={{ opacity: 0 }}
            whileHover={
              !isActive(displayOptions[index]) && {
                background: '#f5f7f9',
                color: '#000',
              }
            }
          >
            {isActive(displayOptions[index]) && (
              <BiCheck style={{ marginRight: 5 }} />
            )}{' '}
            {displayOptions[index].icon}
            <span style={{ marginLeft: displayOptions[index].icon ? 6 : 0 }}>
              {formatLabel(displayOptions[index].label)}
            </span>
          </motion.div>
        )}
      </List>

      {/* {displayOptions.map((option, i) => (
        <motion.div
          key={'option_' + i}
          className='d-flex justify-content-start align-items-center px-2 py-1'
          style={{
            cursor: 'pointer',
            minHeight: 30,
            maxHeight: 30,
          }}
          onClick={() => handleOptionClick(option)}
          initial={{ opacity: 0, background: '#ffffff' }}
          animate={{
            color: isActive(option) ? '#00050a' : '#50555a',
            opacity: 1,
            background: isActive(option) ? '#f0f2f4' : '#fafbfc',
            // fontWeight: isActive(option) ? 500 : 400,
          }}
          exit={{ opacity: 0 }}
          whileHover={
            !isActive(option) && {
              background: '#f5f7f9',
              color: '#000',
            }
          }
        >
          {isActive(option) && <BiCheck style={{ marginRight: 5 }} />}{' '}
          {option.icon}
          <span style={{ marginLeft: option.icon ? 6 : 0 }}>
            {option.label}
          </span>
        </motion.div>
      ))} */}
    </motion.div>
  );
}
