import { useSelector } from 'react-redux';
import colors from '../../../constants/colors';
import {
  selectSimWines,
  similarWinesIsLoading,
} from '../../../redux/selectors';
import WinePlate from '../WinePlate/WinePlate';
import { motion, AnimatePresence } from 'framer-motion';
import { Spinner } from 'react-bootstrap';
import { liAnimation } from '../../../constants/animations';
import { useState } from 'react';
import { BiCaretDown, BiCaretUp, BiFilter } from 'react-icons/bi';
import _ from 'lodash';

const FilterPill = ({ selected, disabled, children, ...props }) => {
  const disabledClick = disabled ? { onClick: () => {} } : {};

  return (
    <motion.div
      {...props}
      {...disabledClick}
      // whileTap={{y: 2}}
      // transition={{duration:0.1}}
      className={
        'text-xxs p-1 px-2 d-inline-block btn-outline' +
        (selected && !disabled ? ' selected' : '') +
        (disabled ? ' disabled' : '')
      }
      style={{ marginLeft: '-1px', opacity: disabled ? 0.3 : 1 }}
    >
      {children}
    </motion.div>
  );
};

export default function Recommendations({ limitMet, ...props }) {
  const loadingSimWines = useSelector(similarWinesIsLoading);
  const simWines = useSelector(selectSimWines);
  const [filterToggled, setFilterToggled] = useState(false);
  const filterList = [
    { title: 'Red', fn: (wine) => wine.type === 'red' },
    { title: 'Rose', fn: (wine) => wine.type === 'rose' },
    { title: 'White', fn: (wine) => wine.type === 'white' },
    // { title: 'Sparkling', fn: (wine) => wine.type === 'sparkling' },
    // { title: 'Dessert', fn: (wine) => wine.type === 'dessert' },
    { title: 'Fortified', fn: (wine) => wine.type === 'fortified' },
    { title: 'Orange', fn: (wine) => wine.type === 'orange' },

    // { title: 'Cider', fn: (wine) => wine.type === 'cider' },
    // { title: 'Beer', fn: (wine) => wine.type === 'beer' },
  ];
  const [filters, setFilters] = useState(filterList);

  const handleFilterClick = (clickedFilter) => {
    if (filterIsSelected(clickedFilter)) {
      setFilters(filters.filter((f) => f.title !== clickedFilter.title));
    } else {
      setFilters([...filters, clickedFilter]);
    }
  };
  const filterIsSelected = (filter) =>
    filters.map((f) => f.title).includes(filter.title);
  const filterIsDisabled = (filter) =>
    !simWines.some((wine) => filter.fn(wine));

  const filteredWines = (wines) => {
    return wines.filter((wine) => filters.some((filter) => filter.fn(wine)));
  };

  const FilterControl = () => {
    return (
      <span
        onClick={() => setFilterToggled(!filterToggled)}
        className='clickable text-xs'
      >
        filters
        {filterToggled ? <BiCaretUp /> : <BiCaretDown />}
      </span>
    );
  };

  return (
    <motion.div {...liAnimation}>
      <div
        style={{ flexDirection: 'column', display: 'flex', overflow: 'hidden' }}
      >
        <div className='d-flex flex-column mt-3'>
          <div className='text-md px-2 text-start w-100 d-flex user-select-none align-items-end'>
            <span>
              Recommended
              <Spinner
                variant='primary'
                className='mx-2'
                animation='border'
                size='sm'
                style={{ visibility: loadingSimWines ? 'visible' : 'hidden' }}
              />
            </span>
            <div className='flex-grow-1' />
            <FilterControl />
          </div>
          <AnimatePresence>
            {filterToggled && (
              <motion.div
                {...liAnimation}
                transition={{ duration: 0.15 }}
                className='px-2 overflow-hidden'
              >
                <div style={{ height: '5px' }} />
                {/* <BiFilter className='m-1' /> */}
                {filterList.map((f) => (
                  <FilterPill
                    key={f.title}
                    onClick={() => handleFilterClick(f)}
                    selected={filterIsSelected(f)}
                    disabled={filterIsDisabled(f)}
                  >
                    {f.title}
                  </FilterPill>
                ))}
              </motion.div>
            )}
            {/* <FilterBar key='filter-bar' /> */}
          </AnimatePresence>
        </div>
        <div
          className='mt-3 center-vertically'
          style={{ maxHeight: '100%', overflowY: 'auto' }}
        >
          <AnimatePresence initial={false}>
            {loadingSimWines && !simWines.length ? null : simWines.length ? (
              filteredWines(simWines).map((w, i) => (
                <WinePlate
                  key={'sim-wine-' + w.id}
                  wine={w}
                  index={i}
                  disabled={limitMet}
                />
              ))
            ) : (
              <div
                className='text-sm text-center'
                style={{ color: colors.LIGHT }}
              >
                No similar wines found.
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
}
