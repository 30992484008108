import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';
import * as feedback from '../constants/feedbackTypes';
import * as status from '../constants/statusTypes';
import {
  selectActiveView,
  selectCategoryStatus,
  selectUserWines,
  selectViews,
} from './selectors';
import { alkoLinkToImageUrl, loadImage } from '../helpers/image';
import he from 'he';
import _ from 'lodash';

export const emptyView = () => ({
  recipe: {},
  userWines: [],
  simWines: [],
  cpuWines: [],
  cpuWinesRejected: [],
  features: [],

  validationStatus: status.IDLE,
  pairingStatus: status.IDLE,
});

const defaultStatus = {
  status: 'idle',
  error: null,
  instances: 0,
};

const createTree = (categories) =>
  Object.fromEntries(
    categories.map(({ name, status }) => [
      name,
      {
        views: [],
        status,
      },
    ])
  );

export const fetchStats = createAsyncThunk('pairing/fetchStats', async () => {
  console.log('Getting stats...');
  const { stats } = await api.PairingTool.fetchStats();

  return { stats };
});

export const pairingSubmitted = createAsyncThunk(
  'pairing/pairingSubmitted',
  async (view, { getState, dispatch }) => {
    const { category, index } = getState();

    await api.PairingTool.submitPairing(view.recipe, view.userWines);
    dispatch(fetchStats());
    return { category, index };
  }
);

export const validationSubmitted = createAsyncThunk(
  'pairing/validationSubmitted',
  async (view, { getState }) => {
    const { category, index } = getState();

    console.log(view.cpuWines);

    await api.PairingTool.submitValidation(view.recipe, view.cpuWines);

    return { category, index };
  }
);

export const fetchWineInfo = createAsyncThunk(
  'pairing/fetchWineInfo',
  async (url) => {
    const info = await api.WineCellar.fetchInfo(url);

    return info;
  }
);

export const fetchRecipeWines = createAsyncThunk(
  'pairing/fetchRecipeWines',
  async (recipe) => {
    let cpuWines = [];
    try {
      cpuWines = await api.Sommelier.fetchWines(
        recipe.title,
        recipe.steps,
        recipe.ingredients
      );
      console.log(cpuWines);
      cpuWines = cpuWines.map((w) => ({ ...w, feedback: feedback.NONE }));
    } catch (error) {
      const { message } = await error.response.json();
      console.log(message);
    }

    return {
      cpuWines,
    };
  }
);

export const fetchRecipe = createAsyncThunk(
  'pairing/fetchRecipe',
  async (category, { getState }) => {
    const state = getState();
    const recipeIds = selectViews(state).map((view) => view.recipe.id);
    const [recipe] = await api.Pantry.fetchRandom(category, recipeIds);
    console.log(recipe);

    let cpuWines = [];

    if (selectCategoryStatus(state) === 'review') {
      try {
        const { wines } = await api.Sommelier.fetchWines(
          recipe.title,
          recipe.steps,
          recipe.ingredients
        );
        cpuWines = wines.map((w) => ({ ...w, feedback: feedback.NONE }));
      } catch (error) {
        const { message } = await error.response.json();
        console.log(message);
      }
    }

    return {
      recipe: { ...recipe, title: he.decode(recipe.title) },
      category,
      cpuWines,
    };
  }
);

export const refreshCpuWines = createAsyncThunk(
  'pairing/refreshCpuWines',
  async (_, { getState }) => {
    const state = getState();
    const cpuWines = selectActiveView(state).cpuWines;

    const rejected = cpuWines
      .filter((w) => w.feedback !== feedback.ACCEPTED)
      .concat(selectActiveView(state).cpuWinesRejected);
    const accepted = cpuWines.filter((w) => w.feedback === feedback.ACCEPTED);
    const wines = await api.WineCellar.fetchSimilar(accepted.map((w) => w.url));

    console.log(accepted, rejected, wines);
    const imageLinks = wines
      .map((w) => w.url)
      .map((url) => alkoLinkToImageUrl(url));
    await Promise.all(imageLinks.map((url) => loadImage(url)));

    return {
      wines: accepted
        .concat(
          wines.filter((w) => !rejected.map((r) => r.url).includes(w.url))
        )
        .slice(0, 5),
      rejected,
    };
  }
);

export const fetchSimilarWines = createAsyncThunk(
  'pairing/fetchSimilar',
  async ({ index, category, urls }, { getState }) => {
    const instanceId = getState().loadables.fetchSimilarWines.instances;
    const responseList = await api.WineCellar.fetchSimilar(urls);
    const wines = _.uniqBy(responseList, 'id');

    const imageLinks = wines
      .map((w) => w.url)
      .map((url) => alkoLinkToImageUrl(url));
    await Promise.all(imageLinks.map((url) => loadImage(url)));

    return { wines, instanceId, index, category };
  }
);

export const userWineAdded = createAsyncThunk(
  'pairing/userWineAdded',
  async (wine, { getState, dispatch }) => {
    const state = getState();
    const { index, category } = state;

    const urls = selectUserWines(state).map((wine) => wine.url);

    dispatch(fetchSimilarWines({ index, category, urls: [wine.url, ...urls] }));
    return wine;
  }
);

export const userWineRemoved = createAsyncThunk(
  'pairing/userWineRemoved',
  async (id, { getState, dispatch }) => {
    const state = getState();
    const { index, category } = state;

    const userWines = selectUserWines(state);
    const userWinesSliced = userWines
      .slice(0, id)
      .concat(userWines.slice(id + 1));
    const urls = userWinesSliced.map((wine) => wine.url);

    dispatch(fetchSimilarWines({ index, category, urls }));
    return userWinesSliced;
  }
);

export const fetchCategories = createAsyncThunk(
  'pairing/fetchCategories',
  async (_, { dispatch }) => {
    const { categories } = await api.PairingTool.fetchCategories();
    dispatch(categoriesInitialized(categories));

    return true;
  }
);

const resetLoadables = (state) => {
  // maps all loadables to default values.
  // state.loadables = _.merge(state.loadables, _(state.loadables).mapValues(v => ({ ...v, error: '' })).value());
};

const resetPairingStatus = (state) => {
  selectActiveView(state).pairingStatus = status.CHANGED;
};

const resetValidationStatus = (state) => {
  selectActiveView(state).validationStatus = status.CHANGED;
};

export const pairingSlice = createSlice({
  name: 'pairing',
  initialState: {
    tree: {},
    category: '',
    index: -1,
    counts: [],

    loadables: {
      fetchRecipe: defaultStatus,
      fetchSimilarWines: defaultStatus,
      fetchCpuWines: defaultStatus,
    },
  },
  reducers: {
    recipeFetched: (state, action) => {
      const { recipe, category } = action.payload;

      state.loadables.fetchRecipe.status = status.FULFILLED;
      state.tree[category].views.push({
        ...emptyView(),
        recipe,
      });
      state.index = state.tree[state.category].views.length - 1;
    },
    cpuWinesFetched: (state, action) => {
      const { category, index, cpuWines } = action.payload;

      state.loadables.fetchCpuWines.status = status.FULFILLED;
      state.tree[category].views[index].cpuWines = cpuWines;
    },

    categoriesInitialized: (state, action) => {
      const categories = action.payload;
      state.tree = createTree(categories);
      state.category = categories[0].name;
      return;
    },
    viewRemoved: (state, action) => {
      const recipeId = action.payload;

      Object.keys(state.tree).forEach(
        (category) =>
          (state.tree[category].views = state.tree[category].views.filter(
            (view) => view.recipe.id !== recipeId
          ))
      );
      state.index = state.tree[state.category].views.length - 1;
      return;
    },
    indexChanged: (state, action) => {
      const { index } = action.payload;
      state.index = index;

      resetLoadables(state);
      return;
    },
    categoryChanged: (state, action) => {
      const { category } = action.payload;
      state.category = category;
      state.index = state.tree[category].views.length - 1;

      resetLoadables(state);
      return;
    },
    cpuWineFeedbackChanged: (state, action) => {
      const { index, newFeedback } = action.payload;
      selectActiveView(state).cpuWines[index].feedback = newFeedback;

      resetValidationStatus(state);
      return;
    },
    cpuWineAccepted: (state, action) => {
      const index = action.payload;
      selectActiveView(state).cpuWines[index].feedback = feedback.ACCEPTED;

      resetValidationStatus(state);
      return;
    },
    cpuWineDeclined: (state, action) => {
      const index = action.payload;
      selectActiveView(state).cpuWines[index].feedback = feedback.DECLINED;

      resetValidationStatus(state);
      return;
    },
    simWinesUpdated: (state, action) => {
      selectActiveView(state).simWines = action.payload;
      return;
    },
    simWineRemoved: (state, action) => {
      const id = action.payload;
      const filtered = selectActiveView(state).simWines.filter(
        (w) => w.id !== id
      );
      selectActiveView(state).simWines = filtered;
      return;
    },
    featuresUpdated: (state, action) => {
      selectActiveView(state).features = action.payload;
      return;
    },
    featureAccepted: (state, action) => {
      const { index } = action.payload;
      selectActiveView(state).features[index].feedback = feedback.ACCEPTED;

      resetPairingStatus(state);
      return;
    },
    featureDeclined: (state, action) => {
      const { index } = action.payload;
      selectActiveView(state).features[index].feedback = feedback.DECLINED;

      resetPairingStatus(state);
      return;
    },

    simWineHovered: (state, action) => {
      const wine = action.payload;

      state.hovered = wine;
      return;
    },
    simWineUnhovered: (state, action) => {
      state.hovered = null;
      return;
    },
  },
  extraReducers: {
    [validationSubmitted.pending]: (state) => {
      selectActiveView(state).validationStatus = status.PENDING;
    },
    [validationSubmitted.fulfilled]: (state, action) => {
      const { category, index } = action.payload;
      state.tree[category].views[index].validationStatus = status.FULFILLED;
    },
    [validationSubmitted.rejected]: (state, action) => {
      selectActiveView(state).validationStatus = status.REJECTED;
    },

    [pairingSubmitted.pending]: (state) => {
      selectActiveView(state).pairingStatus = status.PENDING;
    },
    [pairingSubmitted.fulfilled]: (state, action) => {
      const { category, index } = action.payload;
      state.tree[category].views[index].pairingStatus = status.FULFILLED;
    },
    [pairingSubmitted.rejected]: (state, action) => {
      selectActiveView(state).validationStatus = status.REJECTED;
    },

    [fetchRecipe.pending]: (state, action) => {
      state.loadables.fetchRecipe.status = status.PENDING;
    },
    [fetchRecipe.fulfilled]: (state, { payload }) => {
      const { recipe, category, cpuWines } = payload;

      state.loadables.fetchRecipe.status = status.FULFILLED;
      state.tree[category].views.push({
        ...emptyView(),
        recipe,
        cpuWines,
      });
      state.index = state.tree[state.category].views.length - 1;
    },
    [fetchRecipe.rejected]: (state, action) => {
      state.loadables.fetchRecipe.status = status.REJECTED;
      state.loadables.fetchRecipe.error = action.error.message;
    },

    [fetchSimilarWines.pending]: (state, action) => {
      state.loadables.fetchSimilarWines.instances++;
      state.loadables.fetchSimilarWines.status = status.PENDING;
    },
    [fetchSimilarWines.fulfilled]: (state, action) => {
      const { wines, index, instanceId, category } = action.payload;

      if (instanceId === state.loadables.fetchSimilarWines.instances) {
        state.loadables.fetchSimilarWines.status = status.FULFILLED;
        state.tree[category].views[index].simWines = wines;
      }
    },
    [fetchSimilarWines.rejected]: (state, action) => {
      // const { instanceId } = action.payload;
      // if (instanceId === state.loadables.fetchSimilarWines.instances) {
      state.loadables.fetchSimilarWines.status = status.REJECTED;
      state.loadables.fetchSimilarWines.error = action.error.message;
      // }
    },

    [userWineAdded.fulfilled]: (state, { payload }) => {
      const wine = payload;
      resetPairingStatus(state);
      selectUserWines(state).push(wine);
    },
    [userWineRemoved.fulfilled]: (state, { payload }) => {
      const userWinesSliced = payload;
      resetPairingStatus(state);
      selectActiveView(state).userWines = userWinesSliced;
    },
    [refreshCpuWines.fulfilled]: (state, { payload }) => {
      const { wines, rejected } = payload;

      selectActiveView(state).cpuWines = wines;
      selectActiveView(state).cpuWinesRejected.push(rejected);
    },

    [fetchStats.fulfilled]: (state, action) => {
      const { stats } = action.payload;
      // console.log(stats);

      state.counts = stats;
    },
  },
});

export const {
  recipeFetched,
  cpuWinesFetched,
  categoriesInitialized,
  viewRemoved,
  indexChanged,
  categoryChanged,
  cpuWineFeedbackChanged,
  cpuWineAccepted,
  cpuWineDeclined,
  simWinesUpdated,
  simWineRemoved,
  featuresUpdated,
  featureAccepted,
  featureDeclined,
  simWineHovered,
  simWineUnhovered,
} = pairingSlice.actions;
export default pairingSlice.reducer;
