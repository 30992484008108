import { BsPlusCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import api from '../api';

export default function BundleList() {
  // fetch bundles

  const [companies, setCompanies] = useState([
    // { name: 'Sommify AI', id: 5, bundles: [] },
    // { name: 'Tesco', id: 26, bundles: [] },
    // { name: 'Bottlescouts', id: 25, bundles: [] },
    { name: 'Vindict (Marley Spoon)', id: 33, bundles: [] },
  ]);
  const navigate = useNavigate();

  async function fetchDataForCompanies(companies) {
    const fetchPromises = companies.map(async (company) => {
      const bundles = await api.WineCellar.fetchBundles(company.id);
      setCompanies((prev) => {
        const newCompanies = [...prev];
        const companyIndex = newCompanies.findIndex((c) => c.id === company.id);
        newCompanies[companyIndex].bundles = bundles;
        return newCompanies;
      });
    });

    await Promise.all(fetchPromises);
  }

  useEffect(() => {
    fetchDataForCompanies(companies).then(() => {
      console.log(companies);
    });
  }, []);

  return (
    <div>
      <br />
      <br />
      {companies.map((company, i) => (
        <div className='p-4' key={'company_' + i}>
          <div
            style={{
              borderBottom: '1px solid #f0f5fa',
              marginBottom: 10,
              width: 400,
            }}
          >
            <h5>{company?.name}</h5>
          </div>
          <div className='d-flex flex-wrap' style={{ minHeight: 110 }}>
            {company?.bundles?.map((bundle) => (
              <motion.div
                key={'bundle_' + bundle.id}
                className='border rounded d-flex justify-content-center align-items-center'
                initial={false}
                animate={{
                  background: '#fafafa',
                }}
                whileHover={{
                  background: '#f4f4f4',
                }}
                style={{
                  width: 200,
                  padding: 20,
                  fontSize: 12,
                  height: 100,
                  marginRight: 10,
                  marginBottom: 10,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`edit/${company.id}/${bundle.id}`);
                }}
              >
                {bundle.title}
              </motion.div>
            ))}
            <div className='d-flex align-items-center justify-content-center px-4'>
              <div
                className='d-flex p-2 align-items-center'
                style={{ cursor: 'pointer', fontSize: 14 }}
                onClick={() => {
                  navigate('create/' + company.id);
                }}
              >
                <BsPlusCircleFill size={25} style={{ marginRight: 8 }} />
                <span style={{ lineHeight: 1 }}>Create bundle</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
