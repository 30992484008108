import React, { useState } from 'react';
import colors from '../../../constants/colors';
import {
  BsFillLockFill,
  BsFillUnlockFill,
  BsHandThumbsDown,
  BsHandThumbsDownFill,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
} from 'react-icons/bs';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import {
  cpuWineAccepted,
  cpuWineDeclined,
  cpuWineFeedbackChanged,
} from '../../../redux/pairingSlice';
import WineContent from './WineContent';
import { ACCEPTED, DECLINED, NONE } from '../../../constants/feedbackTypes';
import { CgLink } from 'react-icons/cg';
import animations from '../../../constants/animations';
import { useNavigate } from 'react-router-dom';

const FeedbackControl = ({ active, iconActive, icon, toggle, ...props }) => {
  return (
    <AnimatePresence>
      {active ? (
        <motion.div
          className='clickable'
          initial={{ opacity: 0 }}
          animate={{ opacity: active ? 1 : 0.3 }}
          whileTap={{ scale: 1.2 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.1, ease: 'easeInOut' }}
          onClick={toggle}
        >
          {iconActive}
        </motion.div>
      ) : (
        <motion.div
          className='clickable'
          initial={{ opacity: 0 }}
          animate={{ opacity: active ? 1 : 0.3 }}
          whileTap={{ scale: 1.2 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.1, ease: 'easeInOut' }}
          onClick={toggle}
        >
          {icon}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default function WinePlate({ wine, index, submitted }) {
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const selected = wine.feedback === ACCEPTED;
  const declined = wine.feedback === DECLINED;

  const handleDeclineClick = () => {
    dispatch(
      cpuWineFeedbackChanged({
        index,
        newFeedback: declined ? NONE : DECLINED,
      })
    );
  };

  const handleAcceptClick = () => {
    dispatch(
      cpuWineFeedbackChanged({
        index,
        newFeedback: selected ? NONE : ACCEPTED,
      })
    );
  };

  const handleClick = () => {
    if (!selected) {
      dispatch(cpuWineAccepted(index));
    } else {
      dispatch(cpuWineDeclined(index));
    }
  };

  const navigate = useNavigate();

  return (
    <div className='position-relative d-flex w-100'>
      {/* <motion.div
        animate={{ opacity: selected ? 1 : 0.4, color: 'rgb(256,256,256)' }}
        onClick={() => window.open(wine.url, '_blank')}
        whileHover={{
          color: 'rgb(252, 171, 255)',
          transition: { duration: 0.1 },
        }}
        className='clickable bg-black d-flex justify-content-center align-items-center'
        style={{
          position: 'absolute',
          left: '-1.5em',
          width: '1.5em',
          borderTopLeftRadius: '100%',
          borderBottomLeftRadius: '100%',
          height: '70%',
          top: 0,
          bottom: 0,
          margin: 'auto',
        }}
      >
        <CgLink size='1.1em' />
      </motion.div> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: selected || declined ? 1 : 0.35 }}
        transition={{
          ease: 'easeOut',
          duration: 0.4,
          scale: { duration: 0.1 },
        }}
        // whileHover={{ opacity: 1 }}
        // whileTap={{ scale: 1.02 }}
        layout
        onClick={() => window.open(`/wine?id=${wine.id}`, '_blank')}
        className='card user-select-none position-relative overflow-hidden clickable'
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        style={{
          flex: 1,
          // borderColor: '#333',
          // borderWidth: 0,
          borderColor:
            submitted && selected
              ? colors.ACCEPTED
              : submitted && declined
              ? colors.DECLINED
              : '#444',
          marginBottom: '2px',
        }}
      >
        <motion.div
          id='overlay'
          className='w-100 h-100 position-absolute'
          initial={{ opacity: 0 }}
          animate={{
            opacity: submitted ? 0.1 : 0,
          }}
          transition={{ ease: 'easeOut', duration: 0.5 }}
          style={{
            backgroundColor: selected
              ? colors.ACCEPTED
              : declined
              ? colors.DECLINED
              : 'rgba(256,256,256)',
          }}
        />

        <div
          id='wine-basics'
          style={{
            height: '80px',
            // padding: '6px',
            display: 'flex',
          }}
        >
          <div style={{ padding: '6px' }}>
            <WineContent hovered={hovered} wine={wine} />
          </div>

          <div className='flex-grow-1' />

          <motion.div
            animate={{ opacity: hovered ? 1 : 0 }}
            transition={{ duration: 0.1 }}
            className='p-2 d-flex justify-content-center align-items-center'
          >
            <CgLink />
          </motion.div>

          {/* <div className='center-vertical'>
            {selected ? (
              <BsFillLockFill color={colors.PRIMARY} />
            ) : (
              <BsFillUnlockFill
                color={hovered ? colors.PRIMARY : ''}
                style={{ opacity: 0.4 }}
              />
            )}
          </div> */}
          <motion.div
            className='h-100'
            animate={{
              background: selected
                ? colors.ACCEPTED
                : declined
                ? colors.DECLINED
                : 'rgba(0,0,0,0)',
            }}
            style={{
              width: '.5em',
            }}
          />
        </div>
      </motion.div>
      <div className='d-flex flex-column'>
        <div
          className='d-flex justify-content-center align-items-end p-2'
          style={{ flex: 1 }}
        >
          <FeedbackControl
            active={selected}
            toggle={handleAcceptClick}
            icon={<BsHandThumbsUp />}
            iconActive={<BsHandThumbsUpFill />}
          />
        </div>
        <div
          className='d-flex justify-content-center align-items-start p-2'
          style={{ flex: 1 }}
        >
          <FeedbackControl
            active={declined}
            toggle={handleDeclineClick}
            icon={<BsHandThumbsDown />}
            iconActive={<BsHandThumbsDownFill />}
          />
        </div>
      </div>
    </div>
  );
}
