import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RECIPE_BANNER_HEIGHT,
  SIDEBAR_WIDTH,
} from "../../constants/dimensions";
import { selectActiveRecipe } from "../../redux/selectors";
import { AnimatePresence, motion } from "framer-motion";
import { IoFlagSharp } from "react-icons/io5";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import api from "../../api";
import { viewRemoved } from "../../redux/pairingSlice";
import colors from "../../constants/colors";

const recSiteTagToRecSiteName = {
  BRITISH: "Great British Chefs",
  SERIOUSEATS: "Serious Eats",
  SPOON: "Spoon",
  DELISH: "Delish",
  GOOD_FOOD: "BBC Good Food",
};

export default function RecipeBanner() {
  const title = useSelector(selectActiveRecipe).title;
  const id = useSelector(selectActiveRecipe).id;
  const src = useSelector(selectActiveRecipe).source;

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFlag = async () => {
    setLoading(true);
    await api.Pantry.flagRecipe(id);
    dispatch(viewRemoved(id));
    setLoading(false);
    handleClose();
  };

  return (
    <div
      className="w-100 py-3"
      style={{ height: RECIPE_BANNER_HEIGHT + "px", marginTop: "90px" }}
    >
      <AnimatePresence>
        <motion.h1
          key="recipe-title"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeOut", duration: 0.3 }}
          className="text-xlg serif-bold text-center"
        >
          {title}
          <motion.div
            key="recipe-title-flag-icon"
            className="d-inline"
            animate={{ opacity: 1 }}
            whileHover={{ opacity: 0.6 }}
            transition={{ duration: 0.4 }}
            style={{ width: "16px", height: "16px" }}
            onClick={handleShow}
          >
            <IoFlagSharp size={20} className="mx-3 clickable" />
          </motion.div>
        </motion.h1>
        <motion.div
          key="recipe-title-source"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeOut", duration: 0.3 }}
          className="center-content"
        >
          <div
            className="mt-1 px-4 py-1 text-xs"
            style={{
              border: "1px solid " + colors.LIGHT,
              color: colors.DARK,
              background: "white",
              marginRight: "10px",
            }}
          >
            {recSiteTagToRecSiteName[src] || src}
          </div>
        </motion.div>
      </AnimatePresence>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header className="border-0 rounded-0" closeButton>
          <Modal.Title className="text-lg">
            Do you want to flag this recipe?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-sm">
          This recipe will no longer appear in the pairing tool.
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <Button
            style={{ width: "100px" }}
            variant="primary"
            className="rounded-0"
            onClick={handleFlag}
          >
            {loading ? <Spinner size="sm" animation="border" /> : "Flag"}
          </Button>
          <Button
            style={{ width: "100px" }}
            variant="primary-outline"
            className="rounded-0"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
