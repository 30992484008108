import Select from './Select';

export default function SelectRange({ title, min, max }) {
  return (
    <div className='d-flex mb-2'>
      {title && (
        <div
          className='d-flex align-items-center justify-content-start px-2'
          style={{
            lineHeight: 1,
            fontSize: 10,
            fontWeight: 600,
            color: '#00000075',
            userSelect: 'none',
            minWidth: 70,
            // borderBottom: '1px solid #30353a',
          }}
        >
          {title?.toUpperCase()}
        </div>
      )}
      <Select
        // title={min?.title}
        active={min?.active}
        setActive={(value) => {
          min?.setActive(value);
        }}
        options={min?.options}
      />
      <div className='d-flex justify-content-center align-items-center px-3'>
        -
      </div>
      <Select
        // title={max?.title}
        active={max?.active}
        setActive={(value) => {
          max?.setActive(value);
        }}
        options={max?.options}
      />
    </div>
  );
}
