import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import RecipeBody from './components/RecipeBody/RecipeBody';
import Sidebar from './components/Sidebar/Sidebar';
import colors from './constants/colors';
import { selectActiveView } from './redux/selectors';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import PairingView from './pages/PairingView';
import WineView from './pages/WineView';
import LandingPage from './pages/LandingPage';
import BundlesView from './pages/BundlesView';
import BundleList from './pages/BundleList';
import BundleDetail from './pages/BundleDetail';

function App() {
  const view = useSelector(selectActiveView);
  const category = useSelector((state) => state.category);

  useEffect(() => {
    const mode =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 'TESTING ENVIRONMENT'
        : 'PRODUCTION ENVIRONMENT';
    console.log(mode);
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: '/wine',
      element: <WineView />,
    },
    {
      path: '/pairing',
      element: <PairingView />,
    },
    {
      path: '/bundles',
      element: <BundlesView />,
      children: [
        {
          path: '',
          element: <BundleList />,
        },
        {
          path: 'create/:companyId',
          element: <BundleDetail />,
        },
        {
          path: 'edit/:companyId/:id',
          element: <BundleDetail />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
