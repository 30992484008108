import client, { API_VERSION } from '../client';

const SERVICE = 'pantry';

const services = {
  flagRecipe: (id) =>
    client.get(
      `api/${SERVICE}/${API_VERSION}/flag-recipe?recipeId=${id}&flagValue=1`,
      { credentials: 'same-origin' }
    ),
  fetchRandom: (category, ids) =>
    client
      .post(`api/${SERVICE}/${API_VERSION}/random-recipe`, {
        credentials: 'same-origin',
        json: {
          loaded: ids,
          categories: [category],
          enableImages: true,
          // sources: ['Delish', 'GOOD_FOOD', 'SPOON'],
        },
        retry: {
          limit: 3,
          methods: ['get'],
        },
        timeout: false,
      })
      .json(),
};

export default services;
