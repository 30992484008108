import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Theme.scss'
import './App.scss'

import { Provider } from 'react-redux'
import store from './redux/store'
import { fetchCategories, fetchStats } from './redux/pairingSlice';

const root = ReactDOM.createRoot(document.getElementById('root'));


store.dispatch(fetchCategories())
store.dispatch(fetchStats())

root.render(
  <Provider store={store}>
    <App />
  </Provider>

  // <React.StrictMode>
  //   <Provider store={store}>
  //     <App />
  //   </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
