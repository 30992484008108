import { countryToAlpha2 } from 'country-to-iso';
import { CircleFlag } from 'react-circle-flags';
import countries from '../constants/countries';
import _ from 'lodash';

export default _.map(countries, (iso, country) => ({
  value: iso,
  label: country,
  icon: <CircleFlag width={12} countryCode={iso.toLowerCase()} />,
}));
