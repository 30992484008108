import { applyMiddleware, configureStore } from '@reduxjs/toolkit'
import { composeWithDevTools } from 'redux-devtools-extension';
import pairingReducer from './pairingSlice';
import thunk from 'redux-thunk';


export default configureStore({
  reducer: pairingReducer
}, composeWithDevTools(
  // applyMiddleware(thunk.withExtraArgument()),
  // other store enhancers if any
))