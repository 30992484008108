import { Button, Spinner } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';

export default function IconButton({
  children,
  icon,
  loading,
  disabled,
  size = 'sm',
  ...props
}) {
  //   const onClick =
  //     disabled || loading ? { onClick: () => {} } : { onClick: props.onClick };

  return (
    <Button
      {...props}
      onClick={props.onClick}
      disabled={disabled || loading}
      style={{
        borderRadius: '25px',
        padding: '5px 12px',
        overflow: 'hidden',
        border: 'none',
        ...props.style
      }}
      className='center-content'
    >
      <div
        className='d-inline-flex justify-content-start align-items-start'
        style={{
          width: icon ? '20px' : '',
        }}
      >
        {loading ? (
          <Spinner animation='border' variant='light' size='sm' />
        ) : (
          icon
        )}
      </div>
      {children ? (
        <div className={'d-inline-flex center-content text-' + size}>
          {children}
        </div>
      ) : null}
    </Button>
  );
}
