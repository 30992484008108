import { useEffect, useRef, useState } from 'react';
import {
  BsCaretDown,
  BsCaretDownFill,
  BsCaretUpFill,
  BsCheck,
  BsLink45Deg,
  BsPlus,
  BsX,
} from 'react-icons/bs';
import { FaCheck, FaSave } from 'react-icons/fa';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import api from '../api';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import Select from '../components/Select/Select';
import countries from '../data/countries';
import grapes from '../data/grapes';
import regions from '../data/regions';
import SelectRange from '../components/Select/SelectRange';
import { HEADER_HEIGHT } from '../components/Header';

const SIDEBAR_WIDTH = 440;

const ItemButton = ({ onClick, children }) => {
  return (
    <motion.div
      onClick={onClick}
      initial={false}
      animate={{
        background: '#ffffff05',
        color: '#fff',
      }}
      whileHover={{
        background: '#ffffffff',
        color: '#000',
      }}
      transition={{
        duration: 0.2,
        ease: 'easeOut',
        type: 'tween',
      }}
      style={{
        border: '1px solid #fff',
        padding: '4px 8px',
        borderRadius: 99,
        marginBottom: 4,
      }}
    >
      {children}
    </motion.div>
  );
};

const Item = ({ item, onClick, isAdded }) => {
  // on hover darken the item and show a plus icon in the center

  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: 160,
        height: 300,
        padding: 15,
        borderRadius: 10,
        border: '1px solid #eee',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 11,
        margin: 5,
        fontWeight: 'bold',
        color: '#000',
        cursor: 'pointer',
        position: 'relative',
        // overflow: 'hidden',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        background: '#fff',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 8,
          background: '#f0f0f0',
        }}
      >
        {item.price} {item.currency}
      </div>

      <img
        style={{ maxWidth: '100%', objectFit: 'cover' }}
        height={200}
        src={item?.imgUrl}
      />
      <br />
      <div style={{ height: 100, overflow: 'hidden' }}>{item.title}</div>

      <AnimatePresence>
        {(hover || isAdded) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              background: isAdded ? 'rgba(0,0,0,0.55)' : 'rgba(0,0,0,0.4)',
            }}
            // whileHover={{ background: 'rgba(0,0,0,0.55)' }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeOut', type: 'tween' }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: 10,
              color: 'white',
              fontSize: 14,
            }}
            className='d-flex align-items-center justify-content-center'
          >
            {hover ? (
              <div className='d-flex flex-column w-100 mx-2'>
                <ItemButton
                  onClick={() => {
                    // navigate to wine page, target _blank
                    window.open(`/wine?id=${item.wineId}`, '_blank');
                  }}
                >
                  <BsLink45Deg size={15} /> View details
                </ItemButton>
                <ItemButton onClick={onClick}>
                  {isAdded ? (
                    <span>
                      <BsX size={15} /> Remove
                    </span>
                  ) : (
                    <span>
                      <BsPlus size={15} /> Add to bundle
                    </span>
                  )}
                </ItemButton>
              </div>
            ) : (
              <BsCheck size={60} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ItemBundled = ({ item, removeItem }) => {
  const [hover, setHover] = useState(false);
  const size = 25;

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: 120,
        height: 220,
        padding: 8,
        borderRadius: 10,
        border: '1px solid #eee',
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
        fontSize: 20,
        margin: 5,
        fontWeight: 'bold',
        color: '#333',
        position: 'relative',
        cursor: 'default',
        fontSize: 10,
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 8,
          background: '#f0f0f0',
        }}
      >
        {item.price} {item.currency}
      </div>

      <div>
        <img
          height={130}
          width={'auto'}
          style={{
            objectFit: 'cover',
          }}
          src={item?.imgUrl}
        />
      </div>

      <br />
      <motion.div
        onClick={() => window.open(`/wine?id=${item.wineId}`, '_blank')}
        style={{
          height: 50,
          cursor: 'pointer',
          // limit to 2 lines max
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
        }}
        animate={{
          textDecoration: 'none',
        }}
        whileHover={{
          textDecoration: 'underline',
        }}
      >
        {item.title}
      </motion.div>

      <AnimatePresence>
        {hover && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, background: '#50555a' }}
            whileHover={{ background: '#20252a' }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeOut', type: 'tween' }}
            style={{
              position: 'absolute',
              top: -size / 2,
              right: -size / 2,
              width: size,
              height: size,
              // background: '#30353a',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              cursor: 'pointer',
            }}
            onClick={() => removeItem(item.id)}
          >
            <BsX size={size} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const BundleInfo = ({ bundle, setBundle, items, removeItem, companyId }) => {
  const navigate = useNavigate();
  const [editSaved, setEditSaved] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [submitted, setSubmitted] = useState(queryParams.get('submitted'));

  useEffect(() => {
    // if bundle title, description, or items change, set submitted to false
    setSubmitted(false);
  }, [bundle?.title, bundle?.description, items]);

  const submit = () => {
    // find out whether we're editing or creating

    const updateBundle = bundle?.id
      ? api.WineCellar.editBundle
      : api.WineCellar.createBundle;

    updateBundle({
      id: bundle?.id,
      title: bundle.title,
      description: bundle.description,
      companyId,
      products: items.map(({ id }) => ({
        id,
      })),
    }).then((res) => {
      navigate(`/bundles/edit/${companyId}/${res.id}?submitted=true`);
      setSubmitted(true);
    });
  };

  const buttonDisabled = !bundle?.title || !items?.length;
  const buttonColor = '#f5f5f5';

  return (
    <div
      style={{
        maxWidth: SIDEBAR_WIDTH,
        width: SIDEBAR_WIDTH,
        height: `calc(100% - ${HEADER_HEIGHT}px)`,
        position: 'fixed',
        left: 0,
        top: HEADER_HEIGHT,
        borderRight: '1px solid #eee',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 0 20px rgba(0,0,0,0.2)',
        zIndex: 10,
      }}
    >
      <input
        type='text'
        placeholder='Bundle name'
        value={bundle?.title}
        onChange={(e) => setBundle({ ...bundle, title: e.target.value })}
        style={{
          border: 0,
          borderBottom: '1px solid #eee',
          width: '100%',
          padding: 20,
        }}
      />
      <textarea
        placeholder='Bundle description'
        value={bundle?.description}
        onChange={(e) => setBundle({ ...bundle, description: e.target.value })}
        style={{
          border: 0,
          borderBottom: '1px solid #eee',
          width: '100%',
          padding: 20,
          height: 150,
          resize: 'none',
        }}
      />
      <div style={{ height: 60, padding: '20px 20px' }}>
        <span style={{ color: '#30353a' }}>
          Bundle wines{items?.length ? ` (${items.length})` : ''}
        </span>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'start',
          alignContent: 'start',
          justifyContent: 'start',
          padding: 20,
          overflowY: 'scroll',
        }}
      >
        {/* grid of items */}
        {items.map((item, index) => (
          <ItemBundled item={item} key={index} removeItem={removeItem} />
        ))}
      </div>
      {/* total price counter */}
      <div
        style={{
          borderTop: '1px solid #eee',
          padding: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className='text-lg'>Total price:</div>
        <div className='text-lg'>
          ${' '}
          {items
            .reduce((acc, item) => acc + parseFloat(item.price), 0)
            .toFixed(2)}
        </div>
      </div>

      {/* submit button */}
      <motion.div
        onClick={() => {
          if (buttonDisabled) return;
          if (submitted) return;
          submit();
        }}
        style={{
          height: 66,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: buttonDisabled || submitted ? 'default' : 'pointer',
          background: buttonColor,
          zIndex: 999,
        }}
        animate={{
          background: submitted ? '#11ad3b' : buttonColor,
          color: submitted ? '#ffffff' : '#30353a',
          opacity: buttonDisabled ? 0.2 : 1,
        }}
        whileHover={{
          background: submitted
            ? '#11ad3b'
            : buttonDisabled
            ? buttonColor
            : '#e5e5e5',
        }}
      >
        {submitted ? (
          <span>
            <FaCheck style={{ marginRight: 4 }} /> Submitted
          </span>
        ) : (
          <span>
            <FaSave style={{ marginRight: 4 }} /> Save bundle
          </span>
        )}
      </motion.div>
    </div>
  );
};

const FilterTag = ({ title, value, onClick }) => {
  const [hover, setHover] = useState(false);

  const displayValue = () => {
    if (Array.isArray(value)) {
      return value.map((v) => v.label).join(', ');
    } else {
      return value.label;
    }
  };

  return (
    <motion.div
      style={{
        // height: 24,
        borderRadius: 6,
        color: '#fff',
        background: '#30353a',
        display: 'inline-flex',
        marginRight: 4,
        marginBottom: 4,
        fontSize: 12,
        padding: '4px 8px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <motion.div
        className='d-inline-flex align-items-center justify-content-center'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, background: '#30353a' }}
        exit={{ opacity: 0 }}
        whileHover={{ background: '#000000' }}
        style={{ borderRadius: 4, marginRight: 4 }}
        onClick={onClick}
      >
        <BsX size={16} />
      </motion.div>
      <span style={{ fontSize: 8, opacity: 0.6, marginRight: 4 }}>
        {title.toUpperCase()}
      </span>{' '}
      {displayValue()}
    </motion.div>
  );
};

export default function BundleDetail() {
  const [bundle, setBundle] = useState({ title: '', description: '' });
  const [items, setItems] = useState(null);
  const { id, companyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingAdditional, setLoadingAdditional] = useState(false); // true when loading more items
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const scrollRef = useRef(null);
  const filtersRef = useRef(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [endReached, setEndReached] = useState(false);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const [filters, setFilters] = useState({});

  const handleScroll = (e) => {
    // check if scrollRef is scrolled to bottom
    const scrollRefEl = scrollRef.current;

    if (
      scrollRefEl.scrollTop + scrollRefEl.clientHeight >=
      scrollRefEl.scrollHeight - 4
    ) {
      // scrolled to bottom
      if (loadingAdditional || endReached) return;
      loadMore();
    }
  };

  useEffect(() => {
    scrollRef.current.addEventListener('scroll', handleScroll);

    return () => {
      scrollRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [loading, loadingAdditional, items]);

  const processFiltersObject = (f) => {
    let modifiedFilters = {};

    // Use lodash to update the modifiedFilters object
    _.forEach(f, (value, key) => {
      if (_.isArray(value)) {
        modifiedFilters[key] = value.map((v) => v.value);
      } else if (_.isObject(value) && value.value) {
        modifiedFilters[key] = value.value;
      }
    });

    return modifiedFilters;
  };

  const delayedSearch = useRef(
    _.debounce((query) => {
      api.WineCellar.fetchWines({
        companyId,
        query,
        filters: processFiltersObject(filters),
      }).then(({ results, total }) => {
        const newItems = results;

        console.log(results?.length);
        if (results?.length < 60) {
          setEndReached(true);
          console.log('END REACHED');
        }

        setItems(newItems);
        setTotal(total);
        setOffset(0);
      });

      // dispatch(updateWines({ query }));
    }, 750)
  ).current;

  useEffect(() => {
    if (items === null) return;
    setLoading(false);
    setLoadingAdditional(false);
  }, [items]);

  useEffect(() => {
    return () => {
      delayedSearch.cancel();
    };
  }, [delayedSearch]);

  useEffect(() => {
    setLoading(true);
    setEndReached(false);
    api.WineCellar.fetchWines({
      companyId,
      query,
      filters: processFiltersObject(filters),
    }).then(({ results, total }) => {
      const newItems = results;

      console.log(results?.length);
      if (results?.length < 60) {
        setEndReached(true);
        console.log('END REACHED');
      }

      setItems(newItems);
      setTotal(total);
      setOffset(0);
    });
  }, [filters]);

  const handleQueryChange = (e) => {
    setLoading(true);
    setQuery(e.target.value);
    setEndReached(false);
    delayedSearch(e.target.value);
  };

  useEffect(() => {
    id &&
      api.WineCellar.fetchBundleDetail(id).then((res) => {
        setBundle(res);
        setAddedItems(res.wines);
      });
  }, [id]);

  useEffect(() => {
    companyId &&
      api.WineCellar.fetchWines({ companyId }).then(({ results, total }) => {
        const newItems = results;

        if (results?.length < 60) {
          setEndReached(true);
          console.log('END REACHED');
        }

        setItems(newItems);
        setTotal(total);
      });
  }, [companyId]);

  const [addedItems, setAddedItems] = useState([]); // [{ id: 1, name: 'test' }]
  const toggleItem = (item) => {
    // if already added, remove it
    // if not added, add it
    const index = addedItems.findIndex((i) => i.id === item.id);
    if (index !== -1) {
      // remove it
      const newItems = [...addedItems.filter((i) => i.id !== item.id)];
      setAddedItems(newItems);
    } else {
      // add it
      const newItems = [...addedItems, item];
      setAddedItems(newItems);
    }
  };

  const isActive = (value) => {
    if (Array.isArray(value)) {
      return value?.length > 0;
    } else {
      return (
        value?.label !== 'Any' &&
        value?.value !== null &&
        value?.value !== undefined
      );
    }
  };

  const activeFilters = () => {
    return _.chain(filters)
      .map((value, key) => ({ value, key }))
      .filter(({ value }) => isActive(value))
      .value();
  };

  // function that fetches more items and is cancellable
  const loadMore = () => {
    setLoadingAdditional(true);
    const newOffset = offset + 60;
    api.WineCellar.fetchWines({
      companyId,
      offset: newOffset,
      limit: 60,
      filter: processFiltersObject(filters),
      query,
    }).then(({ results, total }) => {
      const newItems = results;

      if (results?.length < 60) {
        setEndReached(true);
        console.log('END REACHED');
      }

      setItems([...items, ...newItems]);
      setTotal(total);
      setOffset(newOffset);
    });
  };

  return companyId ? (
    <div
      className='d-flex'
      style={{
        height: `calc(100% - ${HEADER_HEIGHT}px)`,
        position: 'relative',
      }}
    >
      <BundleInfo
        companyId={companyId}
        bundle={bundle}
        setBundle={setBundle}
        items={addedItems}
        removeItem={(id) => {
          const newItems = [...addedItems.filter((i) => i.id !== id)];
          setAddedItems(newItems);
        }}
      />
      <div
        style={{
          flex: 1,
          background: '#fafafa',
          // overflowY: 'scroll',
          // padding: 20,
          marginLeft: SIDEBAR_WIDTH,
          // minHeight: 150,
          // paddingInline: '5vw',
          // paddingTop: 180,
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          ref={filtersRef}
          className='d-flex flex-column'
          style={{
            // position: 'fixed',
            top: HEADER_HEIGHT,
            left: SIDEBAR_WIDTH,
            width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
            background: 'white',
            borderBottom: '1px solid #eee',
            zIndex: 2,
            padding: 25,
          }}
        >
          <div className='d-flex align-items-center' style={{ height: 50 }}>
            <input
              onClick={() => {
                console.log(processFiltersObject(filters));
              }}
              type='text'
              placeholder='Search wines'
              onChange={handleQueryChange}
              value={query}
              style={{
                width: 300,
                height: 40,
                paddingInline: 16,
                border: '1px solid #eee',
                borderRadius: 99,
              }}
            />
            <button
              onClick={toggleFilters}
              style={{
                background: 'transparent',
                border: 'none',
                outline: 'none',
                marginLeft: 'auto',
                fontSize: 15,
              }}
            >
              {filtersOpen ? <BsCaretUpFill /> : <BsCaretDownFill />}
              <div
                style={{
                  minWidth: 80,
                  marginLeft: 4,
                  display: 'inline-block',
                  textAlign: 'start',
                }}
              >
                {filtersOpen ? 'hide filters' : 'show filters'}
              </div>
            </button>
          </div>
          {filtersOpen && (
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div
                  id='filter_col_1'
                  style={{
                    width: 450,
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 24,
                  }}
                >
                  <Select
                    className='mb-2'
                    title={'Country'}
                    active={filters.country}
                    setActive={(value) => {
                      setFilters({ ...filters, country: value });
                    }}
                    options={countries}
                    // multi={true}
                    searchable={true}
                  />
                  <Select
                    className='mb-2'
                    title={'Grape'}
                    active={filters.grapes}
                    setActive={(value) => {
                      setFilters({ ...filters, grapes: value });
                    }}
                    options={grapes}
                    multi={true}
                    searchable={true}
                  />
                  <Select
                    className='mb-2'
                    title={'Region'}
                    active={filters.region}
                    setActive={(value) => {
                      setFilters({ ...filters, region: value });
                    }}
                    options={regions}
                    multi={false}
                    searchable={true}
                  />
                  <Select
                    title={'type'}
                    className='mb-2'
                    active={filters.type}
                    setActive={(value) => {
                      setFilters({ ...filters, type: value });
                    }}
                    options={[
                      { value: 'red', label: 'Red' },
                      { value: 'white', label: 'White' },
                      { value: 'rose', label: 'Rose' },
                      { value: 'sparkling', label: 'Sparkling' },
                      { value: 'dessert', label: 'Dessert' },
                    ]}
                  />
                </div>
                <div
                  id='filter_col_2'
                  style={{
                    width: 450,
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 24,
                  }}
                >
                  <SelectRange
                    title='Alc %'
                    className='mb-2'
                    min={{
                      title: 'Min alc %',
                      active: filters.minAlcohol,
                      setActive: (value) => {
                        setFilters({ ...filters, minAlcohol: value });
                      },
                      options: _.range(0, 10, 0.5).map((i) => ({
                        value: i,
                        label: i.toFixed(1) + '%',
                      })),
                    }}
                    max={{
                      title: 'Max alc %',
                      active: filters.maxAlcohol,
                      setActive: (value) => {
                        setFilters({ ...filters, maxAlcohol: value });
                      },
                      options: _.range(10, 20, 0.5).map((i) => ({
                        value: i,
                        label: i.toFixed(1) + '%',
                      })),
                    }}
                  />

                  <SelectRange
                    title='Price'
                    min={{
                      title: 'Min price',
                      active: filters.minPrice,
                      setActive: (value) => {
                        setFilters({ ...filters, minPrice: value });
                      },
                      options: _.range(0, 1000, 10).map((i) => ({
                        value: i,
                        label: '$' + i,
                      })),
                    }}
                    max={{
                      title: 'Max price',
                      active: filters.maxPrice,
                      setActive: (value) => {
                        setFilters({ ...filters, maxPrice: value });
                      },
                      options: _.range(0, 1000, 10).map((i) => ({
                        value: i,
                        label: '$' + i,
                      })),
                    }}
                  />

                  <SelectRange
                    title='Vintage'
                    min={{
                      title: 'Min Vintage',
                      active: filters.minVintage,
                      setActive: (value) => {
                        setFilters({ ...filters, minVintage: value });
                      },
                      options: _.range(1970, 2023, 1).map((i) => ({
                        value: i,
                        label: String(i),
                      })),
                    }}
                    max={{
                      title: 'Max Vintage',
                      active: filters.maxVintage,
                      setActive: (value) => {
                        setFilters({ ...filters, maxVintage: value });
                      },
                      options: _.range(1970, 2023, 1).map((i) => ({
                        value: i,
                        label: String(i),
                      })),
                    }}
                  />

                  {/* <SelectRange
                    title='Acids'
                    min={{
                      title: 'Min Acids',
                      active: filters.minAcids,
                      setActive: (value) => {
                        setFilters({ ...filters, minAcids: value });
                      },
                      options: _.range(4, 10, 0.5).map((i) => ({
                        value: i,
                        label: i.toFixed(1) + ' g/L',
                      })),
                    }}
                    max={{
                      title: 'Max Acids',
                      active: filters.maxAcids,
                      setActive: (value) => {
                        setFilters({ ...filters, maxAcids: value });
                      },
                      options: _.range(4, 10, 0.5).map((i) => ({
                        value: i,
                        label: i.toFixed(1) + ' g/L',
                      })),
                    }}
                  /> */}

                  {/* <SelectRange
                    title='Sugars'
                    min={{
                      title: 'Min Sugars',
                      active: filters.minSugars,
                      setActive: (value) => {
                        setFilters({ ...filters, minSugars: value });
                      },
                      options: _.range(0, 45, 1).map((i) => ({
                        value: i,
                        label: i.toFixed(1) + ' g/L',
                      })),
                    }}
                    max={{
                      title: 'Max Sugars',
                      active: filters.maxSugars,
                      setActive: (value) => {
                        setFilters({ ...filters, maxSugars: value });
                      },
                      options: _.range(0, 45, 1).map((i) => ({
                        value: i,
                        label: i.toFixed(1) + ' g/L',
                      })),
                    }}
                  /> */}
                </div>
                {/* <div
                  id='filter_col_3'
                  style={{
                    width: 450,
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 24,
                  }}
                >
                </div> */}
              </div>
            </div>
          )}
          {activeFilters().length > 0 && (
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 30 }}>
              {activeFilters().map(({ value, key }, i) => (
                <FilterTag
                  key={'filter_tag_' + i}
                  title={key}
                  value={value}
                  onClick={() => {
                    setFilters({ ...filters, [key]: undefined });
                  }}
                />
              ))}

              {activeFilters().length > 0 && (
                <div
                  onClick={() => setFilters({})}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    marginLeft: 10,
                    // height: 26,
                    // marginRight: 4,
                    // marginBottom: 4,
                    // display: 'inline',
                  }}
                >
                  <BsX size={12} />
                  <div
                    style={{
                      fontSize: 12,
                      marginLeft: 4,
                      display: 'inline-block',
                      textAlign: 'start',
                    }}
                  >
                    Clear filters
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* <div style={{ flex: 1, background: 'red' }}>yo</div> */}
        <div
          style={{
            margin: 'auto',
            padding: 50,
            flex: 1,
            minHeight: 0,
            overflow: 'scroll',
          }}
          ref={scrollRef}
        >
          {!loading && items && (
            <div className='p-2 mb-3' style={{ fontSize: 13, color: '#999' }}>
              {total === 0 ? `No results` : `Found ${total} results...`}
            </div>
          )}
          {!loading &&
            items.map((item, index) => (
              <Item
                item={item}
                isAdded={addedItems.findIndex((i) => i.id === item.id) !== -1}
                onClick={() => toggleItem(item)}
                key={index}
              />
            ))}

          <div
            className='d-flex justify-content-center align-items-center'
            style={{ width: '100%', height: 200 }}
          >
            {(loadingAdditional || loading) && <Spinner animation='border' />}

            {endReached && (
              <span style={{ fontSize: 13, color: '#999' }}>
                No more results
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>loading...</div>
  );
}
