import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import SelectMenu from './SelectMenu';

export default function Select({
  title,
  active,
  setActive,
  options = [],
  disabled,
  maxActiveDisplay = 5,
  multi = false,
  searchable = false,
  className,
  style,
}) {
  const [open, setOpen] = useState(false);

  disabled = disabled || options.length <= 1;
  const background = '#ffffff';
  const backgroundHighlight = '#fafafa';

  const isActive = () => {
    if (Array.isArray(active)) {
      return active?.length > 0;
    } else {
      return active;
    }
  };

  return (
    <div
      className={'d-flex align-items-center px-1 ' + className}
      style={{
        flex: 1,
        height: 34,
        maxHeight: 34,
        minHeight: 34,
        marginRight: 6,
      }}
    >
      {title && (
        <span
          style={{
            lineHeight: 1,
            fontSize: 10,
            fontWeight: 600,
            color: '#00000075',
            userSelect: 'none',
            minWidth: 70,
          }}
        >
          {title.toUpperCase()}
        </span>
      )}
      <motion.div
        className='position-relative h-100 px-2'
        style={{
          borderBottom: '1px solid #30353a',
          // borderRadius: 99,
          background,
          cursor: disabled ? 'default' : 'pointer',
          color: disabled ? '#999' : 'black',
          flex: 1,
          ...style,
        }}
        animate={{
          background: isActive()
            ? '#f5f5f5'
            : open
            ? backgroundHighlight
            : background,
        }}
        whileHover={{
          background: disabled ? background : backgroundHighlight,
        }}
      >
        <AnimatePresence>
          {open && (
            <SelectMenu
              options={options}
              close={() => setOpen(false)}
              active={active}
              setActive={setActive}
              multi={multi}
              searchable={searchable}
            />
          )}
        </AnimatePresence>
        <div
          className='w-100 h-100 d-flex justify-content-start align-items-center'
          onMouseDown={() => {
            !disabled && !open && setOpen(true);
          }}
          onMouseUp={(e) => e.preventDefault()}
        >
          <span
            style={{
              lineHeight: 1.3,
              marginRight: 4,
              // marginLeft: 8,
              fontWeight: 500,
              fontSize: 12,
              userSelect: 'none',
              // if overflow, show ellipsis
              // maxWidth: 120,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight:
                (Array.isArray(active) && active?.length === 0) ||
                active === null ||
                active === undefined
                  ? 400
                  : 700,

              color:
                (Array.isArray(active) && active?.length === 0) ||
                active === null ||
                active === undefined
                  ? '#000000a0'
                  : '#000000',
            }}
          >
            {(Array.isArray(active) && active?.length === 0) ||
            active === null ||
            active === undefined
              ? 'Any'
              : Array.isArray(active)
              ? active.length > maxActiveDisplay
                ? `(${active.length}) ${active
                    .slice(0, maxActiveDisplay)
                    .map((a) => a.label)
                    .join(', ')}, ...`
                : active.map((a) => a?.label).join(', ')
              : active?.label}
          </span>
          <div style={{ flex: 1 }} />
          {open ? <BiCaretUp /> : <BiCaretDown />}
        </div>
      </motion.div>
    </div>
  );
}
