import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import {
  NAV_HEIGHT,
  RECIPE_BANNER_HEIGHT,
  TAB_MIN_WIDTH,
} from '../../../constants/dimensions';

const tabStyle = {
  background: 'white',
  padding: '20px',
  boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.08)',
  minWidth: TAB_MIN_WIDTH + 'px',
  maxHeight: `calc(100vh - ${RECIPE_BANNER_HEIGHT + NAV_HEIGHT})`,
};

export default function Tab({ children, style, ...props }) {
  return (
    <div {...props} style={{ ...tabStyle, ...style }}>
      <AnimatePresence initial={false}>{children}</AnimatePresence>
    </div>
  );
}
