export const isWineLink = (str) => {
  var expression =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);

  if (str.match(regex)) {
    return true;
  } else return false;
};

export const stringToList = (str) => {
  return str
    .match(RegExp(/(?:'([^,]+)'|"([^,]+)")/g))
    .map((e) => e.slice(1, -1));
};

export const capitalize = (string) =>
  !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);

export const formatLabelStr = (category) =>
  capitalize(category.replace('_', ' '));
