import { Spinner } from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';
import colors from '../constants/colors';

export default function Loadable({ loading, style, children, variant = 'light', size = 'sm' }) {
    return loading ?
        // <BeatLoader size={10} color={colors.LIGHT} speedMultiplier={0.5} />
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner style={style} animation="border" variant={variant} size={size} />
        </div>
        : children
} 