import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import colors from '../../../constants/colors';
import { AiOutlinePlus } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  simWineHovered,
  simWineRemoved,
  simWineUnhovered,
  userWineAdded,
} from '../../../redux/pairingSlice';
import { motion } from 'framer-motion';
import WineContent from './WineContent';

export default function WinePlate({ wine, disabled, nonInteractive }) {
  // const [hovered, setHovered] = useState(false)
  const hovered = useSelector((state) => state.hovered)?.id === wine.id;
  const dispatch = useDispatch();

  const handleAddButtonClick = () => {
    dispatch(userWineAdded({ ...wine, source: 'similar' }));
    dispatch(simWineRemoved(wine.id));
  };

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      transition={{ ease: 'easeOut', duration: 0.3 }}
      exit={{ height: 0, opacity: 0 }}
      className='card overflow-hidden'
      onMouseEnter={() => {
        dispatch(simWineHovered(wine));
      }}
      onMouseLeave={() => {
        dispatch(simWineUnhovered());
      }}
      style={{
        borderColor: hovered ? colors.PRIMARY : colors.OFF_WHITE,
        // width: '100%',
        marginBottom: '2px',
      }}
    >
      <div
        id='wine-basics'
        style={{
          height: '80px',
          padding: '6px',
          display: 'flex',
        }}
      >
        <WineContent hovered={hovered} wine={wine} />
        <div style={{ flex: 1 }}></div>
        {!nonInteractive && (
          <div className='center-content' style={{ width: '70px' }}>
            <Button
              disabled={disabled}
              onClick={handleAddButtonClick}
              style={{
                borderRadius: '50%',
                display: 'flex',
                padding: '0px',
                opacity: disabled ? 0.3 : 1,
              }}
            >
              <AiOutlinePlus size={16} />
            </Button>
          </div>
        )}
      </div>
    </motion.div>
  );
}
