import PairingTool from './services/PairingTool';
import Pantry from './services/Pantry';
import Sommelier from './services/Sommelier';
import WineCellar from './services/WineCellar';

export default {
  PairingTool,
  Pantry,
  Sommelier,
  WineCellar,
};
